import { SYS_CONCURRENCY_CONFLICT } from "psims/constants/system-alerts";
import { all } from "psims/lib/collections";
import { asString } from "psims/lib/string";
import { isSuccesfulRowResult, RecordResult } from "psims/models/api/record-result";

// Determine the overall result of an update
export function getAggregatedUpdateStatus(recordResults: Array<RecordResult>) {
    return all(recordResults.map(rr => isSuccesfulRowResult(rr)), x => x) ? 'saved_full' : 'saved_partial';
}

export type SavedStatus = ReturnType<typeof getAggregatedUpdateStatus>;

export function mapToUserMessage(recordResult: RecordResult) {
    switch(recordResult.rowResult) {
        case 'ConcurrencyError':
            return SYS_CONCURRENCY_CONFLICT;
        default:
            return asString(recordResult.message);
    }
}