import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import ReactSelect, {Props as ReactSelectProps} from 'react-select';
import styled from 'styled-components';
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react';

import { fieldStyle } from 'psims/style/field';
import FormField, { FormFieldProps } from './form-field';
import useFocusable from 'psims/react/util/use-focusable';
import useIsDirty from 'psims/react/util/use-is-dirty';
import UserInputBox from './user-input-box';
import useIsFocused from '../util/use-is-focused';
import randomID from 'psims/lib/random-id';

export interface Option<T> {
    label: ReactNode;
    value: T;
}

interface SelectPropsBase<T> extends ReactSelectProps<Option<T>, false> {
    borderless?: boolean;
    error?: string | null;
    focusless?: boolean;
    forceError?: boolean;
    id: string;
    info?: string | null;
    inline?: boolean;
    label: string;
    minWidth?: string;
    setFocused?: boolean;
    unstyled?: boolean;
    useTooltip?: boolean;
}

interface SelectPropsBare<T> extends SelectPropsBase<T> {
    bare: true;
}

interface SelectPropsLabeled<T> extends SelectPropsBase<T>, FormFieldProps {}

type SelectProps<T> = SelectPropsBare<T> | SelectPropsLabeled<T>;

const myCache = createCache({
  key: 'lfg-emotion-cache',
  nonce: window.__webpack_nonce__,
});

const SelectBase = <T,>({borderless, className, focusless, info, isDirty, label, onBlur, onFocus, setFocused, useTooltip, ...rest}: SelectPropsBase<T> & {isDirty?: boolean}) => {
    const {setRef} = useFocusable({setFocused});
	const {isFocused, handleBlur, handleFocus} = useIsFocused({onBlur, onFocus});
    const [floatingId] = useState(randomID());

    const renderedSelect = (
        <CacheProvider value={myCache}>
            <ReactSelect
                menuPlacement='auto'
                aria-errormessage={rest.error != null ? (useTooltip && isFocused ? floatingId : `${rest.id}_error`) : undefined}
                aria-label={label}
                aria-invalid={rest.error != null}
                onBlur={handleBlur}
                onFocus={handleFocus}
                {...rest}
                className={`react-select-container${className ? ' ' + className : ''}`}
                classNamePrefix='react-select'
                ref={setRef}
            />
        </CacheProvider>
    );

	if (useTooltip) {
		return <UserInputBox
			borderless={borderless}
			error={rest.error || undefined}
			floatingId={floatingId}
            focusless={focusless}
			info={info || undefined}
			isFocused={isFocused}
		>
			{renderedSelect}
		</UserInputBox>
	}

    return renderedSelect;
}

const Select = <T,>({className, error, forceError, info, onBlur, ...props}: SelectProps<T>) => {
    const dirtyCtrl = useIsDirty(false, onBlur);
    const cn = `${className || ''}${props.unstyled ? ' select--unstyled' : ''}`;

    const passedError = (dirtyCtrl.isDirty || forceError) && Boolean(error) ? error : undefined;

    if (isBare(props)) {
        return <StyledSelect className={cn} error={passedError} info={info} onFocus={dirtyCtrl.handleFocus} {...props} />
    }

    return (
		<FormField error={passedError} {...props}>
            <StyledSelect className={cn} error={passedError} onFocus={dirtyCtrl.handleFocus} {...props} />
        </FormField>
    )
}

const StyledSelect = styled(SelectBase)`
    &.select--unstyled .react-select__control {
        &,
        &:hover,
        &:focus {
            border: none;
            box-shadow: none;
        }
    }
    
    & .react-select__control {
        ${fieldStyle}

        ${props => `
        ${props.minWidth ? `
        min-width: ${props.minWidth};
        ` : ''}
        `}

        ${(props: SelectPropsBase<unknown>) => `
        ${props.inline ? `
        padding: 0 4px;
        height: auto;
        border: none;
        border-bottom: 1px solid #333;

        &:hover {
            border: none;
            border-bottom: 1px solid #333;
        }
        ` : ''}
        `}

        & .react-select__value-container {
            padding: 0;
        }

        & .react-select__indicator-separator {
            width: 0;
        }

        & .react-select__dropdown-indicator {
            color: var(--color-primary-interactive);
            padding-right: 0;
        }

        &.react-select__control--is-disabled {
            background-color: transparent;
            border-color: var(--color-field-border);
            & .react-select__single-value--is-disabled {
                color: var(--color-black-90);
            }

            & .react-select__dropdown-indicator,
            & .react-select__placeholder {
                display: none;
            }
        }

        & .react-select__placeholder {
            color: #333;
        }
    }

    & .react-select__menu {
        border: 1px solid var(--color-blue-45);
        border-radius: 0;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        margin-bottom: 4px;
        margin-top: 4px;
        z-index: 2;

        & .react-select__option--is-focused,
        & .react-select__option--is-focused.react-select__option--is-selected {
            background-color: var(--color-link);
            color: var(--color-white);
        }

        & .react-select__option--is-selected {
            background-color: var(--color-white);
            color: var(--color-black);
            font-weight: 600;
        }
    }
` as typeof SelectBase;

export default Select;

interface UseSelectControllerProps<T> {
    value: T;
    onChange: (val: T | null) => any;
    options: Array<Option<T>>;
}

export function useSelectController<T> (props: UseSelectControllerProps<T>) {
    const value = useMemo(() => {
        return props.options.find(o => o.value === props.value) || null;
    }, [props.value, props.options]);

    const onChange = useCallback((option: Option<T> | null) => {
        props.onChange(option?.value == null ? null : option.value);
    }, [props]);

    return {
        onChange,
        options: props.options,
        value,
    };
}

export type SelectController = ReturnType<typeof useSelectController>;

function isBare<T>(props: SelectProps<T>): props is SelectPropsBare<T> {
    return Boolean((props as SelectPropsBare<T>).bare);
}
