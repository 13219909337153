import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import useResizeListener from '../util/use-resize-listener';

import Button from './button';
import Tick from './icons/tick';
import { BoxedSpan } from './layout';

type Status = 'pending' | 'active' | 'complete';

type Step = {
    Label?: ReactNode;
    status: Status;
    label: string;
}

interface SubmissionProgressBarProps {
    isComplete: boolean;
    onStepClick: (index: number) => any;
    steps: Array<Step>;
}

const StyledProgressBar = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    position: relative;
    width: 100%;
    max-width: 100%;

    & button {
        width: 250px;
    }
`;

const StyledProgressLineContainer = styled.div`${(props: ProgressLine) => `
    background: var(--color-black-50);
    position: absolute;
    left: ${props.$x}px;
    top: ${props.$y}px;
    width: ${props.$width}px;
    height: 3px;
    z-index: -1;
`}`;

const StyledProgressLineProgress = styled.div`${({percent}: {percent: number}) => `
    background: var(--color-primary);
    height: 100%;
    width: ${percent}%;
    transition: width 300ms ease-in-out;
`}`;

const SubmissionProgressBar = (props: SubmissionProgressBarProps) => {
    const vm = useSubmissionProgressBarVM(props);

    if (props.steps.length < 1) {
        return null;
    }

    return (
        <StyledProgressBar role="navigation" aria-label="Data submission steps">
            {
                vm.progressLine != null &&
                <StyledProgressLineContainer {...vm.progressLine}>
                    <StyledProgressLineProgress percent={vm.progressPercent} />
                </StyledProgressLineContainer>
            }

            {
                props.steps.map((step, index) => <ProgressStep
                    key={index}
                    circleRef={vm.circleRefForIndex(index)}
                    onClick={() => props.onStepClick(index)}
                    step={step}
                    stepIndex={index}
                />)
            }
        </StyledProgressBar>
    );
}

type ProgressLine = {
    $x: number;
    $y: number;
    $width: number;
}

function useSubmissionProgressBarVM(props: SubmissionProgressBarProps) {
    const circleEls = useRef<{[key: number]: HTMLDivElement | null}>({});
    const [progressLine, setProgressLine] = useState<ProgressLine | null>(null);
    const {rect} = useResizeListener({});

    const circleRefForIndex = useCallback((index: number) => {
        return (el: HTMLDivElement | null) => {
            circleEls.current = {
                ...circleEls.current,
                [index]: el
            };
        }
    }, [circleEls]);

    const progressPercent = useMemo(() => {
        if (props.isComplete) {
            return 100;
        }
        
        const activeIndex = props.steps.findIndex(s => s.status === 'active');
        return (activeIndex / (props.steps.length - 1)) * 100;
    }, [props.isComplete, props.steps]);

    useEffect(() => {
        const startRect = circleEls.current[0];
        const endRect = circleEls.current[props.steps.length - 1];

        if (!startRect || !endRect) {
            return;
        }

        setProgressLine({
            $x: startRect.offsetLeft + (startRect.offsetWidth / 2),
            $y: startRect.offsetHeight / 2,
            $width: endRect.offsetLeft - startRect.offsetLeft,
        });
    }, [rect, props.steps.length]);

    return {
        circleEls,
        circleRefForIndex,
        progressLine,
        progressPercent,
    }
}

const StepCircle = styled.span`${(props: {status: Step['status']}) => `
    align-items: center;
    background: white;
    border-radius: 30px;
    border: 2.5px solid var(--color-primary);
    box-sizing: border-box;
    display: flex;
    font-weight: 700;
    height: 38px;
    justify-content: center;
    overflow: hidden;
    width: 38px;

    ${props.status === 'active' ? `
    border-color: var(--color-primary);
    color: var(--color-primary);
    `: ''}

    ${props.status === 'complete' ? `
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-white);
    `: ''}

    ${props.status === 'pending' ? `
    background-color: var(--color-white);
    border-color: var(--color-black-50);
    color: var(--color-black-70);
    `: ''}
`}`;

interface ProgressStepProps {
    circleRef: (el: HTMLDivElement | null) => any;
    onClick: (index: number) => any;
    step: Step;
    stepIndex: number;
}

const ProgressStep = (props: ProgressStepProps) => {
    const isActive = props.step.status === 'active';
    return (
        <Button
            $kind='unstyled'
            aria-label={isActive ? 'You are here' : `Go to ${props.step.label}`}
            onClick={() => props.onClick(props.stepIndex)}
            aria-current={isActive ? 'step' : undefined}
        >
            <BoxedSpan box={{alignItems: 'center', flex: 'column'}}>
                <StepCircle status={props.step.status} ref={props.circleRef}>
                    {
                        props.step.status === 'complete' ?
                        <Tick aria-hidden='true' color='white' size='md' /> :
                        props.stepIndex + 1
                    }
                </StepCircle>

                <BoxedSpan box={{color: 'primary-interactive', marginTop: 1}}>
                    {props.step.Label}
                </BoxedSpan>
            </BoxedSpan>
        </Button>
    )
}

export default SubmissionProgressBar;
