import { OrganisationContactVM, UpdateOrganisationContactVM } from "psims/gen/xapi-client";

// Note we shouldn't need to export the array of type names - they get exposed as a union type
const ORGANISATION_CONTACT_TYPE_NAMES = [
    'Primary',
    'Secondary',
] as const;

export type OrganisationContactTypeName = ArrayElement<typeof ORGANISATION_CONTACT_TYPE_NAMES>;

export type UpdateOrganisationContact = PopulatedProps<UpdateOrganisationContactVM,
// List all the non-nullable and app-relevant values for this entity
'email' | 'firstName' | 'lastName'
> & {
// Add any typed unions
contactType: OrganisationContactTypeName;
};

export type OrganisationContact = PopulatedProps<OrganisationContactVM,
    // List all the non-nullable and app-relevant values for this entity
    'id' | 'email' | 'firstName' | 'lastName'
> & {
    // Add any typed unions
    contactType: OrganisationContactTypeName;
};

export function isOrganisationContact(maybe?: OrganisationContactVM | null): maybe is OrganisationContact {
    return (
        maybe != null &&
        maybe.id != null && 
        maybe.email != null &&
        maybe.firstName != null &&
        maybe.lastName != null &&
        maybe.contactType != null &&
        ORGANISATION_CONTACT_TYPE_NAMES.includes(maybe.contactType as OrganisationContactTypeName)
    )
}

export function isUpdateOrganisationContact(maybe?: UpdateOrganisationContactVM | null): maybe is UpdateOrganisationContact {
    return (
        maybe != null &&
        maybe.email != null &&
        maybe.firstName != null &&
        maybe.lastName != null &&
        maybe.contactType != null &&
        maybe.recordAction != null &&
        ORGANISATION_CONTACT_TYPE_NAMES.includes(maybe.contactType as OrganisationContactTypeName)
    )
}
