import { recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { UpdateMsoAnnualActivityRefinerDraft, UpdateMsoAnnualActivityRefinerField } from "psims/models/submission-types/mso/annual-activity/refiner/update-mso-annual-activity-refiner";
import { UseMsoAnnualActivityRefData } from "../../shared/use-mso-annual-activity-ref-data";

const MAX_CAPACITY = 100000000;
const MAX_VOLUME   = 100000000;

export type ValidationResult = ({
  key: UpdateMsoAnnualActivityRefinerField;
} & (
  {
    code: 'invalid_integer' | 'invalid_integer_range';
    max: number;
    min: number;
  } |
  {
    code: 'invalid_required';
  }
)) | {
  key: 'delete';
  code: 'inactive_product';
};

export function validateRefinerAnnualActivity(annualActivity: UpdateMsoAnnualActivityRefinerDraft, refData: UseMsoAnnualActivityRefData): Array<ValidationResult> {
  const product = refData.productIdMap[annualActivity.msoProductId];

  let messages: Array<ValidationResult> = [];

  if (product?.productStatus === 'inactive') {
    if (recordActionFromEnum(annualActivity.recordAction) === 'Delete' || annualActivity.id == null) {
      return messages;
    }

    messages.push({
      key: 'delete',
      code: 'inactive_product'
    });

    return messages;
  }

  // totalStorageCapacity
  if (annualActivity.totalStorageCapacity == null) {
    messages.push({
      key: 'totalStorageCapacity',
      code: 'invalid_required'
    });
  } else if (isNaN(Number(annualActivity.totalStorageCapacity)) || String(annualActivity.totalStorageCapacity).indexOf('.') > -1) {
      messages.push({
        key: 'totalStorageCapacity',
        code: 'invalid_integer',
        max: MAX_CAPACITY,
        min: 0,
      });
    } else if (annualActivity.totalStorageCapacity < 0 || annualActivity.totalStorageCapacity > MAX_CAPACITY) {
      messages.push({
        key: 'totalStorageCapacity',
        code: 'invalid_integer_range',
        max: MAX_CAPACITY,
        min: 0,
      });
  }

  // totalAnnualVolume
  if (annualActivity.totalAnnualVolume == null) {
    messages.push({
      key: 'totalAnnualVolume',
      code: 'invalid_required'
    });
  } else if (isNaN(Number(annualActivity.totalAnnualVolume)) || String(annualActivity.totalAnnualVolume).indexOf('.') > -1) {
      messages.push({
        key: 'totalAnnualVolume',
        code: 'invalid_integer',
        max: MAX_VOLUME,
        min: 0,
      });
    } else if (annualActivity.totalAnnualVolume < 0 || annualActivity.totalAnnualVolume > MAX_VOLUME) {
      messages.push({
        key: 'totalAnnualVolume',
        code: 'invalid_integer_range',
        max: MAX_VOLUME,
        min: 0,
      });
  }

  return messages;
}
