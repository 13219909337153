import { DefActivity, DefActivityField } from "psims/models/submission-types/def/def-activity";
import { isValidationAlert, ValidationAlert } from "psims/models/api/submission/validation-alert";
import { CommentsFocusField, DeclarationFocusField } from "../shared/types";
import { DefProduct } from "psims/models/ref-data/def-product";
import { DataSubmission, DataSubmissionTypeName } from "psims/models/data-submission";

type PersistedDataFocusField = {
  id: number;
}

type DraftDataFocusField = {
  _id: symbol;
}

export type DataFocusField = {
  field: DefActivityField;
  kind: 'data';
} & (PersistedDataFocusField | DraftDataFocusField);

export type DeleteFocusField = {
  id: number;
  field: 'delete';
  kind: 'data';
}

export type FocusField = DataFocusField | CommentsFocusField | DeclarationFocusField | DeleteFocusField;

export function isPersistedDataFocusField(maybe?: unknown): maybe is PersistedDataFocusField {
  const maybeAs = maybe as PersistedDataFocusField;

  return (
    maybeAs != null &&
    maybeAs.id != null
  );
}

export function isDraftDataFocusField(maybe?: unknown): maybe is DraftDataFocusField {
  const maybeAs = maybe as DraftDataFocusField;

  return (
    maybeAs != null &&
    maybeAs._id != null
  );
}

export function isDeleteFocusField(maybe?: unknown): maybe is DeleteFocusField {
  const maybeAs = maybe as DeleteFocusField;

  return (
    maybeAs != null &&
    maybeAs.field === 'delete'
  );
}


export type DefValidationAlert = ValidationAlert<typeof DEF_VALIDATION_ALERTS[number]>;

const DEF_VALIDATION_ALERTS = ['DerivedFromOtherProductionVarianceApplied', 'SalesVarianceApplied', 'StockVarianceApplied'] as const ;

export function isDefValidationAlert(maybe?: unknown): maybe is DefValidationAlert {
  const maybeAs = maybe as DefValidationAlert;

  return (
    maybeAs != null &&
    DEF_VALIDATION_ALERTS.includes(maybeAs.validationAlert)
  );
}

type SameAsPreviousValidationAlertView = {
  target: 'dataSubmission',
  validationAlert: ValidationAlert<'SameAsPrevious'>;
}

type DefRelativityValidationAlertView = {
  target: 'dataSubmission',
  validationAlert: ValidationAlert<'DefRelativity'>;
}

type BaseValidationAlertView = {
  source: 'ui' | 'api';
}

export type DefDataSubmissionValidationAlertView = BaseValidationAlertView & {
  target: 'dataSubmission';
  validationAlert: ValidationAlert<'DefRelativity' | 'SameAsPrevious'>;
};

export function isDefDataSubmissionValidationAlert(maybe?: unknown): maybe is ValidationAlert<'DefRelativity' | 'SameAsPrevious'> {
  return (
    isValidationAlert(maybe) && (
      maybe.validationAlert === 'DefRelativity' ||
      maybe.validationAlert === 'SameAsPrevious'
    )
  );
}


export function isDefDataSubmissionValidationAlertView(maybe?: unknown): maybe is DefDataSubmissionValidationAlertView {
  const maybeAs = maybe as DefDataSubmissionValidationAlertView;

  return (
    maybeAs != null &&
    maybeAs.source != null &&
    maybeAs.target === 'dataSubmission' && (
      maybeAs.validationAlert.validationAlert === 'DefRelativity' ||
      maybeAs.validationAlert.validationAlert === 'SameAsPrevious'
    )
  );
}


type VarianceValidationAlertView = {
  target: 'activity',
  dataSubmission: DataSubmission<DataSubmissionTypeName>; // Needed for variances
  activity: DefActivity;
  product: DefProduct | undefined,
  validationAlert: DefValidationAlert;
}

export type ValidationAlertView = BaseValidationAlertView & (
  DefRelativityValidationAlertView |
  SameAsPreviousValidationAlertView |
  VarianceValidationAlertView
);

export function isVarianceValidationAlertView(maybe?: unknown): maybe is VarianceValidationAlertView {
  const maybeAs = maybe as VarianceValidationAlertView;

  return (
    maybeAs != null &&
    maybeAs.activity != null &&
    maybeAs.dataSubmission != null &&
    maybeAs.product != null &&
    maybeAs.target === 'activity' &&
    isDefValidationAlert(maybeAs.validationAlert)
  );
}
