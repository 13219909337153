import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { asString } from 'psims/lib/string';
import { AllReferenceData, assertAllReferenceData } from 'psims/models/ref-data';
import { useAPI } from '.';
import { useLogger } from '../logging';

type Context = {
	status: 'init' | 'fetching' | 'error';
	data: null;
} | {
	status: 'ready';
	data: AllReferenceData;
}

const ctx = createContext<Context | null>(null);

const ReferenceDataProvider = ({children}: PropsWithChildren<{}>) => {
    const api = useAPI();
	const {exception} = useLogger({source: 'AllReferenceDataProvider'});
	const [ctxValue, setCtxValue] = useState<Context>({data: null, status: 'init'});

	useEffect(() => {
		if (api.status === 'ready' && ctxValue.status === 'init') {
			setCtxValue({data: null, status: 'fetching'});
			api.api.getAllRefData()
				.then(response => {
					assertAllReferenceData(response);
						setCtxValue({data: {
							...response,
							countries: response.countries.sort((a, b) => asString(a.absCountryName).toLowerCase() > asString(b.absCountryName).toLowerCase() ? 1 : -1),
							states: response.states.sort((a, b) => asString(a.name).toLowerCase() > asString(b.name).toLowerCase() ? 1 : -1),
						}, status: 'ready'});
				})
				.catch(e => {
					exception(e, {
						Category: 'REFERENCE_DATA',
						Detail: (e as Error).message,
					});
					setCtxValue({data: null, status: 'error'})
				})
		}
	}, [api, ctxValue.status, exception]);

	return <ctx.Provider value={{...ctxValue}}>{children}</ctx.Provider>
}

export function useReferenceData() {
	const c = useContext(ctx);

	if (c === null) {
		throw new Error('useReferenceData must be used in ReferenceDataProvider');
	}

	if (c.status === 'error') {
		throw new Error('Failed to load reference data');
	}

	return c;
}

export default ReferenceDataProvider;
