import { useMemo } from "react";

import { isOPAWithProductionArea, OPAWithProductionArea } from "psims/models/ref-data/organisation-production-area";
import { useReferenceData } from "psims/react/providers/api/reference-data";
import { useOrganisationProductionAreas } from "psims/react/providers/api/organisation-production-areas";
import { isProductionProduct, ProductionProduct } from "psims/models/ref-data/production-product";
import { isValidForPeriod } from "psims/models/ref-data/util";
import { isProductionProductGroup, ProductionProductGroup } from "psims/models/ref-data/production-product-group";
import { isArrayOfType } from "psims/lib/collections";
import { isPopulatedDataSubmission } from "psims/models/data-submission";
import { ProductionSubmission } from "psims/models/submission-types/production";
import { SubmissionType } from "psims/models/ref-data/submission-type";

export type ProductionProductRefData = ProductionProduct & {
  isExpired: boolean;
}

type ProductionProductGroupRefData = ProductionProductGroup & {
  isExpired: boolean;
}

export type ProductGroupRefData = {
  refData: ProductionProductGroupRefData;
  products: Array<ProductionProductRefData>;
};

function isProductionProductGroupRefData(maybe: unknown): maybe is ProductGroupRefData {
  const maybeAs = maybe as ProductGroupRefData;
  return (
      maybeAs != null &&
      maybeAs.refData != null &&
      isArrayOfType(isProductionProduct, maybeAs.products)
  );
}

export type UseProductionRefData = {
    groups: Array<ProductGroupRefData>;
    organisationProductionAreas: Array<OPAWithProductionArea>;
    submissionType: SubmissionType;
}

type UseProductionRefDataProps<TGroup extends ProductionProductGroup> = {
  submission: ProductionSubmission | null;
  groupAsserter?: TypeAssertion<unknown, TGroup>;
}

function useProductionRefData<TGroup extends ProductionProductGroup>({groupAsserter = ((m: unknown) => true) as TypeAssertion<unknown, TGroup>, submission}: UseProductionRefDataProps<TGroup>) {
    const {data: _refData} = useReferenceData();

    const groups = useMemo<Array<ProductGroupRefData>>(() => {
      if (_refData == null || submission == null) {
        return [];
      }

      const {dataSubmission} = submission;

      if (!isPopulatedDataSubmission(dataSubmission)) {
        return []
      }

      const productionTypeName = dataSubmission.submissionTypeName;
      const {reportingPeriodFrom, reportingPeriodTo} = dataSubmission;

      return (_refData?.productionProductGroups || [])
      .filter(isProductionProductGroup)
      .filter(groupAsserter)
      .filter(g => g.productionTypeName === productionTypeName)
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(g => {
        const isGroupExpired = !isValidForPeriod(g, reportingPeriodFrom, reportingPeriodTo) || Boolean(g.isInternal);
        return {
          refData: {
            ...g,
            isExpired: isGroupExpired,
          },
          products: _refData.productionProducts
            .filter(isProductionProduct)
            .filter(p => p.productionProductGroupId === g.id)
            .map(p => ({
              ...p,
              isExpired: isGroupExpired || !isValidForPeriod(p, reportingPeriodFrom, reportingPeriodTo) || Boolean(p.isInternal),
            }))
        };
      });
    }, [_refData, groupAsserter, submission]);

    const {organisationProductionAreas} = useOrganisationProductionAreas();

    const hydratedOrgProdAreas = useMemo(() => {
        if (organisationProductionAreas == null || _refData == null) {
            return null;
        }

        return organisationProductionAreas.map(opa => {
            const pa = _refData.productionAreas.find(p => p.id === opa.productionAreaId);

            return {
                ...opa,
                productionArea: pa,
            };
        }).filter(isOPAWithProductionArea)
    }, [organisationProductionAreas, _refData]);

    const submissionType = useMemo(() => {
      if (submission == null || _refData == null) {
        return null;
      }

      return _refData.submissionTypes.find(st => st.name === submission.dataSubmission.submissionTypeName);
    }, [_refData, submission]);

    return {
      groups,
      organisationProductionAreas: hydratedOrgProdAreas,
      submissionType,
    };
}

export default useProductionRefData;

type MaybeUseProductionRefData = ReturnType<typeof useProductionRefData>;

export function isUseProductionRefData(maybe: MaybeUseProductionRefData): maybe is UseProductionRefData {
    return (
      maybe != null &&
      maybe.groups != null &&
      isArrayOfType(isProductionProductGroupRefData, maybe.groups) &&
      maybe.organisationProductionAreas != null &&
      maybe.submissionType != null
    );
}
