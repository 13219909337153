import { useMemo } from "react";
import { SubmissionStatus } from "psims/react/pages/primary-pages/data-submissions/shared/api";

interface UseSubmissionLoadStateProps<TSubmission> {
    isDepsReady: boolean;
    loadStatus: SubmissionStatus;
    submission?: TSubmission | null;
}

function useSubmissionLoadState<TSubmission>({isDepsReady, loadStatus, submission}: UseSubmissionLoadStateProps<TSubmission>) {
    const state = useMemo(() => {
        if (loadStatus === 'fetch_failed') {
            return 'error';
        }

        if (submission != null && isDepsReady) {
            return 'loaded';
        }

        return 'loading';
    }, [isDepsReady, loadStatus, submission]);

    return state;
}

export default useSubmissionLoadState
