import randomID from 'psims/lib/random-id';
import React, { isValidElement, PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import Button from '../button';
import Chevron from '../icons/chevron';
import { BoxedDiv } from '../layout';
import Text from '../text';

interface ExpandableProps {
    collapsedLabel: string;
    contentPlacement?: 'above' | 'below';
    expandedLabel: string;
    initialState?: 'collapsed' | 'expanded';
    noWrap?: boolean;
}

interface StyleProps {
    contentPlacement: 'above' | 'below';
    expanded: boolean;
    noWrap?: boolean;
}

const StyledButton = styled.span`
    align-items: center;
    color: var(--color-primary-interactive);
    display: flex;
    transition: border-color var(--transition-duration-fast) ease-in-out;

    &:hover {
        border-bottom-color: var(--color-primary);
    }

    & .icon {
        color: var(--color-primary);
        margin-right: 6px;
        transform: ${(props: StyleProps) => `rotateZ(${props.expanded ? (props.contentPlacement === 'above' ? 0 : 180) : 90}deg)`};
        transition: transform var(--transition-duration-xfast) ease-in-out;
        width: 10px;
    }

    & .text {
        font-size: var(--font-size-140);
        text-decoration: underline;
        ${props => `
        ${props.noWrap ?
        'white-space: nowrap;' :
        ''}
        `}
    }
`;

const Expandable = (props: PropsWithChildren<ExpandableProps>) => {
    const vm = useVM(props);

    return <BoxedDiv box={{alignItems: 'flex-start', flex: 'column'}}>
        {
            vm.isExpanded && vm.contentPlacement === 'above' &&
            props.children
        }

        <Button $kind='unstyled' title={vm.label} onClick={vm.handleClick} aria-controls={vm.regionId} aria-expanded={vm.isExpanded}>
            <StyledButton
                contentPlacement={vm.contentPlacement}
                expanded={vm.isExpanded}
                noWrap={vm.noWrap}
            >
                <Chevron aria-hidden='true' size='sm' />

                <Text>
                    {
                        vm.label
                    }
                </Text>
            </StyledButton>
        </Button>

        {
            vm.isExpanded && vm.contentPlacement === 'below' &&
            <Collapsible id={vm.regionId}>
                {props.children}
            </Collapsible>
        }

    </BoxedDiv>
}

function useVM(props: ExpandableProps) {
    const [isExpanded, setIsExpanded] = useState(props.initialState === 'expanded');
    const [regionId] = useState(randomID());

    const handleClick = () => setIsExpanded(!isExpanded);

    const label = isExpanded ? props.expandedLabel : props.collapsedLabel;

    return {
        contentPlacement: props.contentPlacement || 'above',
        handleClick,
        isExpanded,
        label,
        noWrap: props.noWrap,
        regionId,
    };
}

type CollapsibleProps = {
    id: string;
}

const Collapsible = ({id, children}: React.PropsWithChildren<CollapsibleProps>) => {
    if (isValidElement(children)) {
        return React.cloneElement(children, {
            id,
            role: 'region',
        });
    }

    return null;
};

export default Expandable;
