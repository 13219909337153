/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserVM } from '../models/CreateUserVM';
import type { RegisterUserVM } from '../models/RegisterUserVM';
import type { UpdateUserVM } from '../models/UpdateUserVM';
import type { UserVMApiResponse } from '../models/UserVMApiResponse';
import type { UserVMListApiResponse } from '../models/UserVMListApiResponse';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @returns UserVMApiResponse Success
     * @throws ApiError
     */
    public static async getUser(): Promise<UserVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/users`,
        });
        return result.body;
    }

    /**
     * @returns UserVMListApiResponse Success
     * @throws ApiError
     */
    public static async getUsersByOrganisation({
organisationId,
}: {
organisationId?: number,
}): Promise<UserVMListApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/users/users-by-organisation`,
            query: {
                'organisationId': organisationId,
            },
        });
        return result.body;
    }

    /**
     * @returns UserVMApiResponse Success
     * @throws ApiError
     */
    public static async login(): Promise<UserVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/users/login`,
        });
        return result.body;
    }

    /**
     * @returns UserVMApiResponse Success
     * @throws ApiError
     */
    public static async registerUser({
requestBody,
}: {
requestBody?: RegisterUserVM,
}): Promise<UserVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/users/register`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns UserVMApiResponse Success
     * @throws ApiError
     */
    public static async updateUser({
requestBody,
}: {
requestBody?: UpdateUserVM,
}): Promise<UserVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/users/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns UserVMApiResponse Success
     * @throws ApiError
     */
    public static async createUser({
requestBody,
}: {
requestBody?: CreateUserVM,
}): Promise<UserVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/users/create`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns UserVMApiResponse Success
     * @throws ApiError
     */
    public static async deactivateUser({
userId,
}: {
userId?: number,
}): Promise<UserVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/users/deactivate`,
            query: {
                'userId': userId,
            },
        });
        return result.body;
    }

    /**
     * @returns UserVMApiResponse Success
     * @throws ApiError
     */
    public static async reactivateUser({
userId,
}: {
userId?: number,
}): Promise<UserVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/users/reactivate`,
            query: {
                'userId': userId,
            },
        });
        return result.body;
    }

    /**
     * @returns UserVMApiResponse Success
     * @throws ApiError
     */
    public static async userAcceptTermsConditions(): Promise<UserVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/users/accept-tcs`,
        });
        return result.body;
    }

}