import { FsspSubmissionFormDataVM } from "psims/gen/xapi-client";

export type FspPageData = PopulatedProps<
  FsspSubmissionFormDataVM,
  'dataSubmissionId' | 'fuelSecurityServicesPaymentPageSaved'
>;

export function isFspPageData(maybe?: unknown): maybe is FspPageData {
  const maybeAs = maybe as FspPageData;

  return (
    maybeAs != null &&
    maybeAs.dataSubmissionId != null &&
    maybeAs.fuelSecurityServicesPaymentPageSaved != null
  );
}
