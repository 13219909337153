import { useCallback, useMemo } from "react";

import { WholesaleSubmission } from "psims/models/submission-types/wholesaling";
import StepLabel from "psims/react/pages/primary-pages/data-submissions/shared/step-label";
import { ViewMode } from "psims/react/pages/primary-pages/data-submissions/shared/use-view-mode";
import { UseFormForType } from "./use-form-for-type";
import { UseWholesalingRefData } from "./use-wholesaling-ref-data";
import { UseWholeSalingSave } from "./use-wholesaling-save";
import { Step, UseWholesalingSteps } from "./use-wholesaling-steps";
import { UseWholesalingSubmission } from "./use-wholesaling-submission";
import { attemptScrollToSelector } from "psims/react/pages/primary-pages/data-submissions/shared/view-utils";
import { SELECTOR_NOTIFICATIONS } from "psims/constants/selectors";
import { isEmpty } from "psims/lib/empty";

interface UseWholesalingNavigationProps {
    formCtrl: UseFormForType;
    saveCtrl: UseWholeSalingSave;
    stepCtrl: UseWholesalingSteps;
    refData: UseWholesalingRefData;
    submission: WholesaleSubmission;
    submissionCtrl: UseWholesalingSubmission;
    viewMode: ViewMode;
}

/*
    Manages the progress bar data. 
*/
function useWholesalingProgress({formCtrl, stepCtrl, refData, saveCtrl, submission, submissionCtrl, viewMode}: UseWholesalingNavigationProps) {
    const {currentStep, steps} = stepCtrl;
    const {wholesalesUpdate: {submissionFormData: {manualTotals}}} = formCtrl;

    const navItems = useMemo(() => {
        return steps.map((step) => ({
            ...step,
            Label: StepLabel({
                hasData: stepHasData(step, submission, refData),
                label: labelForStep(step, manualTotals),
            }),
        }))
    }, [manualTotals, refData, steps, submission]);

    const currentNavItem = useMemo(() => {
        return navItems[currentStep.index];
    }, [currentStep.index, navItems]);

    const hasUnsavedChanges = useMemo(() => {
        return formCtrl.changedState === 'unsaved_changes' || submissionCtrl.changedState === 'unsaved_changes';
    }, [formCtrl.changedState, submissionCtrl.changedState])

    const handleNavItemClick = useCallback((index: number) => {
        if (viewMode !== 'edit' || (!hasUnsavedChanges && isEmpty(formCtrl.view?.comments.validationError?.notification.message))) {
            stepCtrl.goToStep(index);
            return;
        }

        if (formCtrl.view != null && !formCtrl.view?.isPageValid) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
            return;
        }

        saveCtrl.handleSaveBeforeStepNavigation(index);
    }, [formCtrl.view, hasUnsavedChanges, saveCtrl, stepCtrl, viewMode]);

    return {
        // data
        currentNavItem,
        navItems,

        // actions
        handleNavItemClick,
    };
}

export default useWholesalingProgress;

export type UseWholesalingProgress = ReturnType<typeof useWholesalingProgress>;

function labelForStep(step: Step, manualTotals: boolean) {
    if (step.kind === 'Total') {
        return `${step.label}${manualTotals ? '' : ' (calculated)'}`;
    }

    return step.label;
}

function stepHasData(step: Step, submission: WholesaleSubmission, refData: UseWholesalingRefData) {
    if (step.kind === 'Submit' || step.kind === 'Total') {
        return true;
    }

    const typeId = refData[step.kind].wholesaleType.id;
    const targetWholesales = submission.wholesales.filter(w => w.wholesaleTypeId === typeId);

    return targetWholesales.length > 0 || submission.wholesaleComments.find(c => c.wholesaleTypeId === typeId) != null;
}
