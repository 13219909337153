import { StockholdingDomesticVM, StockholdingOnWaterPortVM, StockholdingOnWaterVM, StockholdingOverseasVM, StockholdingSubmissionFormDataVM } from 'psims/gen/xapi-client';
import { StockholdingSubmission } from "psims/models/submission-types/stockholding";
import { UseImportReferenceData } from "../use-import-reference-data";
import { ImportFunctionResult, ImportTemplateData, TemplateImporter, TemplateImportState, Tuple } from "../types";
import { getNumberCellValue, getStringCellValue } from '../utils';
import { StockTypeName } from 'psims/models/ref-data/stock-type';
import { HasReportingPeriod } from 'psims/react/pages/primary-pages/data-submissions/shared/types';
import { isValidForPeriod } from 'psims/models/ref-data/util';

const headerKeyColumns = ['Product Group', 'Product'];
const pageNameDomestic = 'In Australia';
const pageNameOverseas = 'Overseas';
const pageNameOnWater = 'On Water';
const pageNameOnWaterPorts = 'Foreign locations or ports';

const getStockCountryId = (
    page: string,
    refData: UseImportReferenceData,
    columns: Array<Tuple<string, number | null>>,
    row: any[],
    dataSubmission: HasReportingPeriod
): ImportFunctionResult => {
    const quantity = getNumberCellValue(row, columns, 'quantity', 'integer');
    const countryName = getStringCellValue(row, columns, 'Location', true);
    const country = refData.getCountry(countryName);

    if (country == null) {
        return quantity == null ? {
            isSuccessful: false,
            error: {
                error: `Country not found [${country}]`,
                page,
            }
        } : {
            isSuccessful: true,
            result: null,
        };
    }

    // Check country isn't expired
    const isExpired = !(
        isValidForPeriod(country, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo)
    );

    if (isExpired) {
        return {
            isSuccessful: false,
            error: {
                error: `The country ${country.absCountryName} is not active for the reporting period.`,
                isTerminalError: true,
                page,
                recommendTemplateRequest: true,
            }
        }
    }

    const result = country.id;

    return {
        isSuccessful: true,
        result
    };
};

const getStockProductId = (
    page: string,
    refData: UseImportReferenceData,
    columns: Array<Tuple<string, number | null>>,
    row: any[],
    dataSubmission: HasReportingPeriod
): ImportFunctionResult => {
    const productGroup = getStringCellValue(row, columns, 'Product Group', true);
    const product = getStringCellValue(row, columns, 'Product', true);
 
    const stockType = page === pageNameOnWater || page === pageNameOnWaterPorts ? 'On water' : page;
    const stockProduct = refData.getStockProduct(stockType, (productGroup ?? '').trim(), (product ?? '').trim());
    const stockProductGroup = refData.getStockProductGroup(stockType, (productGroup ?? '').trim().trim());

    if (stockProduct == null || stockProductGroup == null) {
        return {
            isSuccessful: false,
            error: {
                error: `Product not found [${product}]`,
                page,
            }
        };
    }

    // Check product isn't expired
    const isExpired = !(
        isValidForPeriod(stockProductGroup, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo) &&
        isValidForPeriod(stockProduct, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo)
    );

    if (isExpired) {
        return {
            isSuccessful: false,
            error: {
                error: `The product ${stockProduct.productName} for ${stockProductGroup.stockTypeName} is not active for the reporting period.`,
                isTerminalError: true,
                page,
                recommendTemplateRequest: true,
            }
        }
    }

    const result = stockProduct.id;

    return {
        isSuccessful: true,
        result
    };
};

const stockholdingImport = (): TemplateImporter<StockholdingSubmission> => {
    const newSubmissionBuilder: () => StockholdingSubmission = () => ({
        domesticStockholdings: [], 
        overseasStockholdings: [],
        onWaterStockholdings: [],            
        submissionFormData: {
            australiaPageSaved: false,
            onWaterPageSaved: false,
            overseasPageSaved: false
        }
    });

    const importStateBuilder: () => TemplateImportState<StockholdingSubmission> = () => ({
        templateImportDialogState: 'processing',
        unsavedChanges: false,
        data: newSubmissionBuilder(),
    });

    const domestic: ImportTemplateData<StockholdingDomesticVM> =  {
        dataKey: 'domesticStockholdings',
        page: {
            name: pageNameDomestic,
            headerRowKeys: headerKeyColumns,
            columns: [
                { dataKey: 'stockProductId', dataType: 'string', column: 'Product', colFunction: getStockProductId },
                { dataKey: 'nswStockVolume', dataType: 'integer', column: 'NSW' },
                { dataKey: 'vicStockVolume', dataType: 'integer', column: 'VIC' },
                { dataKey: 'qldStockVolume', dataType: 'integer', column: 'QLD' },
                { dataKey: 'saStockVolume', dataType: 'integer', column: 'SA' },
                { dataKey: 'waStockVolume', dataType: 'integer', column: 'WA' },
                { dataKey: 'tasStockVolume', dataType: 'integer', column: 'TAS' },
                { dataKey: 'ntStockVolume', dataType: 'integer', column: 'NT' }
            ],
            data: [],
        } 
    };

    const overseas: ImportTemplateData<StockholdingOverseasVM> =  {
        dataKey: 'overseasStockholdings',
        page: {
            name: pageNameOverseas,
            headerRowKeys: headerKeyColumns,
            columns: [
                { dataKey: 'stockProductId', dataType: 'string', column: 'Product', colFunction: getStockProductId },
                { dataKey: 'countryId', dataType: 'string', column: 'Location', colFunction: getStockCountryId },
                { dataKey: 'quantity', dataType: 'integer', column: 'Quantity' }
            ],
            data: []
        } 
    };

    type ImportOnWaterPort =  (StockholdingOnWaterPortVM & {stockProductId?: number});
    const initOnWaterPorts: ImportOnWaterPort[] = [];

    const stockholdingOnWaterPortFilter = (data: StockholdingOnWaterVM, extra: ImportOnWaterPort[]): StockholdingOnWaterPortVM[] => {
        const result: StockholdingOnWaterPortVM[] = [];
        
        extra.forEach(x => {
            if (data.stockProductId && x.stockProductId && data.stockProductId === x.stockProductId) {
                result.push({
                    stockholdingOnWaterId: data.id,
                    countryId: x.countryId,
                    quantity: x.quantity
                });
            }
        })
            
        return result;
    }
    
    const onWater: ImportTemplateData<StockholdingOnWaterVM> =  {
        dataKey: 'onWaterStockholdings',
        page: {
            name: pageNameOnWater,
            headerRowKeys: headerKeyColumns,
            columns: [
                { dataKey: 'stockProductId', dataType: 'string', column: 'Product', colFunction: getStockProductId },
                { dataKey: 'highSeas', dataType: 'integer', column: 'High seas' },
                { dataKey: 'eez', dataType: 'integer', column: 'EEZ' },
                { dataKey: 'domesticShipping', dataType: 'integer', column: 'Domestic shipping' }
            ],
            data: []
        },
        extraFilter: stockholdingOnWaterPortFilter,
        extraDataKey: 'stockholdingOnWaterPorts',
        extra: {
            name: pageNameOnWaterPorts,
            headerRowKeys: headerKeyColumns,
            columns: [
                { dataKey: 'stockProductId', dataType: 'string', column: 'Product', colFunction: getStockProductId },
                { dataKey: 'countryId', dataType: 'string', column: 'Location', colFunction: getStockCountryId },
                { dataKey: 'quantity', dataType: 'integer', column: 'Quantity'}
            ],
            data: initOnWaterPorts
        }
    };

    const SAVED_PAGES_MAP: {[k in string]: keyof StockholdingSubmissionFormDataVM} = {
        "Australia": 'australiaPageSaved',
        "Overseas": 'overseasPageSaved',
        "On water": 'onWaterPageSaved',
    };
    
    const setStepSaved = (importState: TemplateImportState<StockholdingSubmission>, stepName: any): TemplateImportState<StockholdingSubmission> => {
        if (stepName !== 'Submit' && importState.data && importState.data.submissionFormData) {
            const typeName = stepName as StockTypeName;
            const newSubmissionFormData = {...importState.data.submissionFormData, [SAVED_PAGES_MAP[typeName]]: true };
            let unsavedChanges = !(newSubmissionFormData.australiaPageSaved === true &&
                newSubmissionFormData.overseasPageSaved === true &&
                newSubmissionFormData.onWaterPageSaved === true  &&
                importState.submitSaved === true                 
            );
            if (!unsavedChanges) {
                return {
                    templateImportDialogState: 'idle',
                    unsavedChanges: false,
                    submitSaved: false,
                    data: undefined
                };
            }
            return {
                ...importState,
                data: {
                    ...importState.data,
                    submissionFormData: newSubmissionFormData
                },
                unsavedChanges: unsavedChanges
            };
        }
        if (stepName === 'Submit' && importState.data && importState.data.submissionFormData) {
            let unsavedChanges = !(importState.data.submissionFormData.australiaPageSaved === true &&
                importState.data.submissionFormData.overseasPageSaved === true &&
                importState.data.submissionFormData.onWaterPageSaved === true
            );
            if (!unsavedChanges) {
                return {
                    templateImportDialogState: 'idle',
                    unsavedChanges: false,
                    submitSaved: false,
                    data: undefined
                };
            }
            return {
                ...importState,
                submitSaved: true
            };
        }
        return importState;
    }

    const savedSteps = (importState: TemplateImportState<StockholdingSubmission>) => {
        return importState?.data?.submissionFormData;
    }

    const importer: TemplateImporter<StockholdingSubmission> = {
        importStateBuilder,
        dataElements: [domestic, overseas, onWater],
        savedSteps,
        setStepSaved
    }

    return importer;
}

export default stockholdingImport;
