const FLD_REF_CODES = [
    'PRO-FP-NOG-NG-MR_NATGAS',
    'PRO-FP-NOG-E-MR_ETHANE',
    'PRO-FP-NOG-ONO-MR_OTHNATGAS',
    'PRO-FP-LPG-FLM-MR_LPG_MIX',
    'PRO-FP-ICO-ICO-MR_CRUDE',
    'PRO-FP-FCW-FC-MR_CONDENSATE',
    'PRO-FP-LPG-FB-MR_LPG_BUTANE',
    'PRO-FP-LPG-FP-MR_LPG_PROPANE',
] as const;

type FldReferenceCode = typeof FLD_REF_CODES[number];

type ProductRefCodeName = 'fc' | 'ng' | 'prop' | 'ico';

const PRODUCT_REF_CODE_MAP: {[key in ProductRefCodeName]: FldReferenceCode} = {
    fc: 'PRO-FP-FCW-FC-MR_CONDENSATE',
    ico: 'PRO-FP-ICO-ICO-MR_CRUDE',
    ng: 'PRO-FP-NOG-NG-MR_NATGAS',
    prop: 'PRO-FP-LPG-FP-MR_LPG_PROPANE',
};

export function getFieldProductionProductReferenceCode(key: ProductRefCodeName) {
    return PRODUCT_REF_CODE_MAP[key];
}
