import { isEmpty } from "psims/lib/empty";
import { recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { UpdateMsoStockholdingImportDraft, UpdateMsoStockholdingImportField } from "psims/models/submission-types/mso/annual-activity/importer/update-mso-stockholding-import";
import { UseMsoAnnualActivityRefData } from "../../shared/use-mso-annual-activity-ref-data";

const CHAR_LIMIT = 200;
const MAX_VOLUME = 1000000;

export type ValidationResult = ({
  key: Exclude<UpdateMsoStockholdingImportField, 'isOnBehalf'>;
} & (
  {
    code: 'invalid_integer' | 'invalid_integer_range';
    max: number;
    min: number;
  } |
  {
    code: 'character_limit',
    limit: number;
  } |
  {
    code: 'invalid_required';
  }
)) | {
  key: 'delete';
  code: 'inactive_product';
};

export function validateImport(imp: UpdateMsoStockholdingImportDraft, refData: UseMsoAnnualActivityRefData): Array<ValidationResult> {
  const product = refData.productIdMap[imp.msoProductId];

  let messages: Array<ValidationResult> = [];

  if (product?.productStatus === 'inactive') {
    if (recordActionFromEnum(imp.recordAction) === 'Delete' || imp.id == null) {
      return messages;
    }

    messages.push({
      key: 'delete',
      code: 'inactive_product'
    });

    return messages;
  }

  // Entity name
  if (isEmpty(imp.entityName)) {
    messages.push({
      key: 'entityName',
      code: 'invalid_required'
    });

  } else if ((imp.entityName?.length || 0) > CHAR_LIMIT) {
    messages.push({
      key: 'entityName',
      code: 'character_limit',
      limit: CHAR_LIMIT,
    });
  }

  // Volume
  if (isEmpty(imp.amountImported)) {
    messages.push({
      key: 'amountImported',
      code: 'invalid_required',
    })
  } else if (isNaN(Number(imp.amountImported)) || String(imp.amountImported).indexOf('.') > -1) {
    messages.push({
      key: 'amountImported',
      code: 'invalid_integer',
      max: MAX_VOLUME,
      min: 0,
    });
  } else if (Number(imp.amountImported) < 0 || Number(imp.amountImported) > MAX_VOLUME) {
    messages.push({
      key: 'amountImported',
      code: 'invalid_integer_range',
      max: MAX_VOLUME,
      min: 0,
    });
  }

  return messages;
}
