import Production from '../productions-shared';
import { ProductionProductGroup } from 'psims/models/ref-data/production-product-group';
import Text from "psims/react/components/text";
import { ApplicableFields } from '../productions-shared/util';
import { BoxedDiv } from 'psims/react/components/layout';
import { isBiofuelProductionGroup } from 'psims/models/ref-data/biofuel-production-group';

interface BiofuelProductionProps {
    id: number;
}

const applicableFields: ApplicableFields = () => [
    {
        field: 'organisationProductionAreaId',
        columnHeader: 'Facility name',
        help: (
            <BoxedDiv box={{flex: 'column', width: '350px'}}>
                <p >A processing facility where hydrocarbon production streams, typically mixtures of gas and gas liquids, are separated into different marketable products for domestic sale or export.</p>
                <p>If an operator is reporting on behalf of all the owners of fuel produced by the plant, they should report the total production from the plant regardless of ownership.</p>
            </BoxedDiv>
        ),
        helpId: 'production_area_help',
        label: 'facility name',
    }, {
        field: 'productionDensity',
        columnHeader: 'Average density (Kg/L)',
        help: (
            <BoxedDiv box={{flex: 'column', width: '350px'}}>
                <p>Report the weighted average density in kilograms per litre (Kg/L) for each product during the month. To convert API to Kg/L: Density (Kg/L) = 141.5 ÷ (131.5 + API).</p>
                <p>If the density for each product is not readily available each month, the most recent density measurement should be used. If no measurement is available, a typical or average value may be entered, which should be reviewed and updated as appropriate.</p>
            </BoxedDiv>
        ),
        helpId: 'density_help',
        label: 'average density',
    }, {
        field: 'openingStocks',
        columnHeader: 'Opening stocks (Kilolitres)',
        help: (
            <BoxedDiv box={{flex: 'column', width: '350px'}}>
                <p>Report the total physical amount of product that you own held in storage at the beginning of the month. For reporting purposes, the opening stock of the current month should be identical to the closing stock of the previous month.</p>
                <p>When reporting stock levels, include all stocks you own, including stocks stored off-site from the plant, such as stocks stored for blending at import terminals.</p>
            </BoxedDiv>
        ),
        helpId: 'opening_stocks_help',
        label: 'opening stocks',
    }, { 
        field: 'produced',
        columnHeader: 'Total production (Kilolitres)',
        help: (
            <BoxedDiv box={{flex: 'column', width: '350px'}}>
                <p>The total volume of each finished product that was produced by the plant for sale or export.</p>
            </BoxedDiv>
        ),
        helpId: 'produced_help',
        label: 'produced at the plant',
    }, {
        field: 'closingStocks',
        columnHeader: 'Closing stocks (Kilolitres)',
        help: (
            <BoxedDiv box={{flex: 'column', width: '350px'}}>
                <p>Report the total physical amount of product that you own held in storage at the end of the month.</p>
                <p>When reporting stock levels, include all stocks you own, including stocks stored off-site from the plant, such as stocks stored for blending at import terminals.</p>
            </BoxedDiv>
        ),
        helpId: 'closing_stocks_help',
        label: 'closing stocks'
    },
];

const FrontMatter = () => {
    return (
        <>
            <p>
                <Text>Please report all information in kilolitres, adjusted to standard temperature and pressure conditions (15 degrees C and 1 atmosphere pressure (101.325 kPa)).</Text>
            </p>
            <p>
                <Text>When reporting stock levels, include all stocks you own, including stocks stored off-site from the plant, such as stocks stored for blending at import terminals.</Text>
            </p>
        </>
    );
};

function getGroupHeader(group: ProductionProductGroup) {
    return group.name;
}

const BiofuelProduction = ({id}: BiofuelProductionProps) => {
    return <Production
        id={id}
        productionTypeConfig={{
            applicableFields,
            FrontMatter,
            getGroupHeader,
            groupAsserter: isBiofuelProductionGroup,
            productionTypeName: 'Biofuel production',
        }}
    />
}

export default BiofuelProduction;
