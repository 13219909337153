import { useOrganisationProductionAreas } from "psims/react/providers/api/organisation-production-areas";
import { useReferenceData } from "psims/react/providers/api/reference-data";
import { useMemo } from "react";

function useImportReferenceData() {
    const {organisationProductionAreas} = useOrganisationProductionAreas();
    const {data: refData} = useReferenceData();
    const {
        countries,
        productionProductGroups, productionProducts, productionTypes,
        refineryProducts, refineryProductGroups, refineryTypes,
        stockProducts, stockProductGroups,
        wholesaleProductGroups, wholesaleProducts, wholesaleTypes,
    } = refData || {};

    const stockProductData = useMemo(() => {
        return stockProducts ? stockProducts : [];
    },[stockProducts]);

    const stockProductGroupData = useMemo(() => {
        return stockProductGroups ? stockProductGroups : [];
    },[stockProductGroups]);

    const refineryProductData = useMemo(() => {
        return refineryProducts ? refineryProducts : [];
    },[refineryProducts]);

    const refineryProductGroupData = useMemo(() => {
        return refineryProductGroups != null ? refineryProductGroups : [];
    },[refineryProductGroups]);

    const refineryTypeData = useMemo(() => {
        return refineryTypes != null ? refineryTypes : [];
    },[refineryTypes]);

    const countryData = useMemo(() => {
        return countries ? countries : [];
    },[countries]);

    const wholesaleProductGroupData = useMemo(() => {
        return wholesaleProductGroups != null ? wholesaleProductGroups : [];
    },[wholesaleProductGroups]);

    const wholesaleProductData = useMemo(() => {
        return wholesaleProducts != null ? wholesaleProducts : [];
    },[wholesaleProducts]);

    const wholesaleTypeData = useMemo(() => {
        return wholesaleTypes != null ? wholesaleTypes : [];
    },[wholesaleTypes]);

    const productionProductGroupData = useMemo(() => {
        return productionProductGroups != null ? productionProductGroups : [];
    },[productionProductGroups]);

    const productionTypeData = useMemo(() => {
        return productionTypes != null ? productionTypes : [];
    },[productionTypes]);

    const organisationProductionAreaData = useMemo(() => {
        return organisationProductionAreas != null ? organisationProductionAreas : [];
    },[organisationProductionAreas]);

    const getStockProduct = (stockType: string, productGroup: string, product: string) => {
        const pgId = stockProductGroupData.find(x => x.stockTypeName?.toLowerCase() === stockType.toLowerCase() && x.name?.toLowerCase() === productGroup.toLowerCase())?.id ?? 0;
        return stockProductData.find(x => x.productName?.toLowerCase() === product.toLowerCase() && x.stockProductGroupId === pgId);
    }

    const getStockProductId = (stockType: string, productGroup: string, product: string) => {
        return getStockProduct(stockType, productGroup, product)?.id ?? 0;
    }

    const getStockProductGroup = (stockType: string, productGroup: string) => {
        return stockProductGroupData.find(x => x.stockTypeName?.toLowerCase() === stockType.toLowerCase() && x.name?.toLowerCase() === productGroup.toLowerCase());
    }

    const getStockProductGroupId = (stockType: string, productGroup: string) => {
        return getStockProductGroup(stockType, productGroup)?.id ?? 0;
    }

    const getCountry = (countryName: string | undefined) =>{
        return countryName != null ? countryData.find(x => x.absCountryName?.toLowerCase() === countryName.toLowerCase()) : undefined;
    }

    const getCountryId = (countryName: string) =>{
        return getCountry(countryName)?.id;
    }

    const getWholesaleProductGroup = (wholesaleType: string, productGroup: string) => {
        return wholesaleProductGroupData.find(x => x.wholesaleTypeName?.toLowerCase() === wholesaleType.toLowerCase() && x.name?.toLowerCase() === productGroup.toLowerCase());
    }

    const getWholesaleProductGroupsInTotalPage = () => {
        return wholesaleProductGroupData.filter(x => x.wholesaleTypeName?.toLowerCase() === 'total');
    }

    const getWholesaleProductGroupId = (wholesaleType: string, productGroup: string) => {
        return getWholesaleProductGroup(wholesaleType, productGroup)?.id ?? 0;
    }

    const getWholesaleProduct = (wholesaleType: string, productGroup: string, product: string) => {
        const pgId = getWholesaleProductGroupId(wholesaleType, productGroup);
        return wholesaleProductData.find(x => x.productName?.toLowerCase() === product.toLowerCase() && x.wholesaleProductGroupId === pgId);
    }

    const getWholesaleProductsInTotalPage = () => {
        const wholesaleProductGroupIdsInTotalPage = getWholesaleProductGroupsInTotalPage().map(x => x.id);
        return wholesaleProductData.filter(x => wholesaleProductGroupIdsInTotalPage.find(y => y === x.wholesaleProductGroupId) != null);
    }

    const getWholesaleProductId = (wholesaleType: string, productGroup: string, product: string) => {
        return getWholesaleProduct(wholesaleType, productGroup, product)?.id ?? 0;
    }


    const getWholesaleTypeId = (wholesaleType: string) =>{
        const wholesaleTypeId = wholesaleTypeData.find(x => x.name.toLowerCase() === wholesaleType.toLowerCase())?.id;
        return wholesaleTypeId;
    }

    const getRefineryProductGroup = (refineryType: string, productGroup: string) => {
        return refineryProductGroupData.find(x => x.refineryTypeName?.toLowerCase() === refineryType.toLowerCase() && x.name?.toLowerCase() === productGroup.toLowerCase());
    }

    const getRefineryProductGroupId = (refineryType: string, productGroup: string) => {
        return getRefineryProductGroup(refineryType, productGroup)?.id ?? 0;
    }

    const getRefineryProduct = (refineryType: string, productGroup: string, product: string) => {
        const pgId = getRefineryProductGroupId(refineryType, productGroup);
        return refineryProductData.find(x => x.productName?.toLowerCase() === product.toLowerCase() && x.refineryProductGroupId === pgId);
    }

    const getRefineryProductId = (refineryType: string, productGroup: string, product: string) => {
        return getRefineryProduct(refineryType, productGroup, product)?.id || null;
    }
    
    const getRefineryTypeId = (refineryType: string) =>{
        const refineryTypeId = refineryTypeData.find(x => x.name.toLowerCase() === refineryType.toLowerCase())?.id;
        return refineryTypeId;
    }

    const getProductionProduct = (productionType: string, productGroup: string, product: string) => {
        const pgId = getProductionProductGroup(productionType, productGroup)?.id;
        const productionProduct = productionProducts?.find(x => x.productName?.toLowerCase() === product.toLowerCase().trim() && x.productionProductGroupId === pgId) || null;

        return productionProduct;
    }

    const getProductionProductGroup = (productionType: string, productGroup: string) => {
        const ptId = getProductionTypeId(productionType);
        const pg = productionProductGroupData.find(x => x.productionTypeId && x.productionTypeId === ptId && x.name?.toLowerCase() === productGroup.toLowerCase().trim()) || null;
        return pg;                
    }

    const getProductionTypeId = (productionType: string) =>{
        const productionTypeId = productionTypeData.find(x => x.name?.toLowerCase() === productionType.toLowerCase().trim())?.id;
        return productionTypeId;
    }

    const getOrganisationProductionAreaId = (productionArea: string) =>{
        const opaId = organisationProductionAreaData.find(x => x.productionAreaName?.toLowerCase() === productionArea.toLowerCase().trim())?.id;
        return opaId;
    }

    return {
        getStockProductGroup,
        getStockProductGroupId,
        getStockProduct,
        getStockProductId,
        getCountry,
        getCountryId,
        getWholesaleProductGroup,
        getWholesaleProductGroupsInTotalPage,
        getWholesaleProductGroupId,
        getWholesaleProduct,
        getWholesaleProductsInTotalPage,
        getWholesaleProductId,
        getWholesaleTypeId,
        getRefineryProduct,
        getRefineryProductId,
        getRefineryProductGroup,
        getRefineryProductGroupId,
        getRefineryTypeId,
        getProductionTypeId,
        getProductionProductGroup,
        getProductionProduct,
        getOrganisationProductionAreaId
    }
}

export default useImportReferenceData

export type UseImportReferenceData = ReturnType<typeof useImportReferenceData>;
