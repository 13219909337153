import { MsoStockRefineryHeldVM, UpdateMsoStockRefineryHeldVM } from "psims/gen/xapi-client";
import { isWithUpdateable, WithUpdatable } from "psims/models/api/update";

type MaybeMsoStockRefineryHeld = MsoStockRefineryHeldVM;

export type MsoStockRefineryHeld = WithUpdatable<
    PopulatedProps<MaybeMsoStockRefineryHeld, 'dataSubmissionId' | 'msoProductId'>
>;

export function isMsoStockRefineryHeld(maybe?: MaybeMsoStockRefineryHeld | null): maybe is MsoStockRefineryHeld {
    return (
        maybe != null &&
        isWithUpdateable<MaybeMsoStockRefineryHeld>(maybe) &&
        maybe.dataSubmissionId != null &&
        maybe.msoProductId!= null
    );
}

type MaybeUpdateMsoStockRefineryHeld = UpdateMsoStockRefineryHeldVM;

export type UpdateMsoStockRefineryHeld = PopulatedProps<MaybeUpdateMsoStockRefineryHeld, "dataSubmissionId" | "msoProductId">;

export type UpdateMsoStockRefineryHeldField = Exclude<keyof UpdateMsoStockRefineryHeld, 'dataSubmissionId' | 'msoProductId' | 'id' | 'recordAction' | 'concurrencyToken'>;

export function isUpdateMsoStockRefineryHeld(maybe?: MaybeUpdateMsoStockRefineryHeld | null): maybe is UpdateMsoStockRefineryHeld {
    return (
        maybe != null &&
        maybe.dataSubmissionId != null &&
        maybe.msoProductId != null
    );
}

