import { ContentDTO } from "psims/gen/xapi-client/models/ContentDTO";
import { assertNotEmpty } from "psims/lib/empty";

export type Content = PopulatedProps<ContentDTO, 'contentKey' | 'id' | 'inlineContent'>;

export function assertContent(maybe?: unknown): asserts maybe is Content {
    const asContent = maybe as Content;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asContent, hint('content'));
    assertNotEmpty(asContent.id, hint('content.id'));
    assertNotEmpty(asContent.contentKey, hint('content.contentKey'));
    assertNotEmpty(asContent.inlineContent, hint('content.inlineContent'));
}