import React, { useContext, useEffect, useState } from 'react';

import { Configuration } from 'psims/config/env';

type Context = {
    configuration: Configuration | null;
}

const context = React.createContext<Context | null>(null);

const ConfigurationProvider = ({children}: React.PropsWithChildren<{}>) => {
    const [configuration, setConfiguration] = useState<Configuration | null>(null);

    useEffect(() => {
        fetch('/env.json')
        .then(r => r.json())
        .then(setConfiguration)
        .catch(error => {
            console.error('Failed to load environment config');
        })
    }, []);

    return <context.Provider value={{configuration}}>{children}</context.Provider>
}

export function useConfiguration() {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error('useConfiguration must be used within a ConfigurationProvider');
    }

    return {
        ...ctx
    };
}

export default ConfigurationProvider