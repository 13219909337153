import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { UseStockholding } from './use-stockholding';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { H3 } from 'psims/react/components/typography';
import { Table, TD, TH, THead, TR } from 'psims/react/components/table';
import { ColumnHeader, DeleteActionCellTD, DeleteActionCellTH, DeleteColumnHeader } from '../shared/data-table-components';
import { AddButton, IconButton } from 'psims/react/components/button';
import { FocusField, GroupInput, GroupSelect, isDeleteFocusField, StyledTBody, TotalCell, useHelpForGroup, useHelpForProduct } from './shared';
import { TooltipHelp } from '../shared/tooltip-help';
import { useSelectController } from 'psims/react/components/select';
import Textarea from 'psims/react/components/textarea';
import { asString } from 'psims/lib/string';
import { localeNumberWithFixed } from 'psims/lib/formatters/numbers';
import { asNumber } from 'psims/lib/number';
import { INFO_NEGATIVE_VALUE_COMMENTS } from 'psims/constants/info-messages';
import { isPortFocusField, isProductFocusField, PortFormData, ProductFormData, UsePageOnWater } from './use-page-on-water';
import { recordActionFromEnum } from 'psims/models/api/data-submission-record-action';
import { isEmpty } from 'psims/lib/empty';
import ReactMarkdown from 'react-markdown';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import { EXPIRED_OR_INTERNAL_PRODUCT } from 'psims/constants/validation-messages';
import FloatingMessage from 'psims/react/components/floating-message';
import useFocusable from 'psims/react/util/use-focusable';

interface OnWaterEditorProps {
    ctrl: UseStockholding;
}

const StyledTR = styled(TR)`
    border: none !important;

    & {
        td:first-child,
        th:first-child {
            border-left: none;
        }

        th:last-child {
            border-right: none !important;
        }
    }
    & th {
        border-bottom: 1px solid var(--color-table-border);
    }

    thead & th {
        border-bottom: none;
        border-top: none;
    }
`;


const OnWaterEditor = ({ctrl}: OnWaterEditorProps) => {
    const {onWaterCtrl} = ctrl;
    const {view} = onWaterCtrl;

    const isCommentsFocused = useMemo(() => {
        const focusedField = onWaterCtrl.focusedFieldCtrl.focusedField;

        return focusedField === 'comments';
    }, [onWaterCtrl.focusedFieldCtrl.focusedField]);

    return (
        <BoxedDiv box={{flex: 'column'}}>
            {
                view.groups
                .filter(g => g.groupStatus !== 'expired' && g.groupStatus !== 'empty')
                .map((group, i) => (
                    <ProductGroupTable
                        key={group.productGroup.id}
                        stockholdingCtrl={ctrl}
                        group={group}
                        groupIndex={i}
                    />
                ))
            }

            <BoxedDiv box={{marginV: 2}}>
                <Textarea
                    error={ctrl.disableInputs ? undefined : view.comments.errorMessage}
                    disabled={ctrl.disableInputs}
                    forceError={true}
                    id='comments_onWater'
                    label={`Comments${!view.isCommentsRequired ? ' (optional)' : ''}`}
                    value={(asString(view.comments.data?.comments))}
                    setFocused={isCommentsFocused}
                    onChange={e => onWaterCtrl.updateComments(e.target.value)}
                />
            </BoxedDiv>
        </BoxedDiv>
    );
}

interface ProductGroupTableProps {
    group: ArrayElement<UsePageOnWater['view']['groups']>;
    groupIndex: number;
    stockholdingCtrl: UseStockholding;
}

const ProductGroupTable = ({group, groupIndex, stockholdingCtrl}: ProductGroupTableProps) => {
    const {portalDataAPICtrl} = stockholdingCtrl.onWaterCtrl;

    const hasExpiredData = (
        !stockholdingCtrl.disableInputs &&
        group.products.some(p => p.productStatus === 'expired_with_data')
    );

    const helpForGroup = useHelpForGroup(portalDataAPICtrl, group.productGroup, 'On water');

    return (
        <BoxedDiv box={{flex: 'column', marginV: 2, overflowX: 'hidden'}}>

            <BoxedDiv box={{flex: 'column', alignSelf: 'stretch', overflowX: 'auto'}}>
                <H3 marginBottom={3}>
                    <BoxedDiv box={{flex: 'row', alignItems: 'center'}}>
                        {group.productGroup.name}
                        {
                            (helpForGroup) &&
                            <TooltipHelp
                                Help={<ReactMarkdown>{helpForGroup}</ReactMarkdown>}
                            />
                        }
                    </BoxedDiv>
                </H3>
                
                <Table caption='Your stockholding data for on water' compact={true} stickyHeader={true}>
                    <THead>
                        <StyledTR>
                            <TH scope='col' $width='300px'>Product</TH>

                            <ColumnHeader
                                label='In foreign ports'
                                Help='Stocks that you own (or will own after unloading in Australia) at the end of the month that are loaded on-board a vessel in an overseas port where the product is destined for Australia. Add extra locations where stock is held in multiple countries.'
                                $align='center'
                                scope='column'
                                $width='122px'
                            />

                            <ColumnHeader
                                label='Location/s'
                                Help='Country of the foreign port.  Enter a separate total for each country where stock is held.'
                                $align='center'
                                scope='column'
                                $width='350px'
                            />

                            <DeleteColumnHeader $width='30px'>Actions</DeleteColumnHeader>

                            <ColumnHeader
                                label='High seas'
                                Help='Stocks which are held on-board a vessel that is at sea, but outside Australia’s Exclusive Economic Zone.  If the location of the vessel is not known and it is not at a port or in domestic shipping, stock should be reported either here or under ‘EEZ’, whichever you believe to be more likely.'
                                $align='center'
                                scope='column'
                                $width='122px'
                            />
                            
                            <ColumnHeader
                                label='EEZ'
                                Help='Stocks which are held on-board a vessel that is at sea inside Australia’s Exclusive Economic Zone, but is not covered by the definition of ‘Domestic shipping’.  If the location of the vessel is not known and it is not at a aport or in domestic shipping, stocks shoud be reported either here or under ‘High seas’, whichever you believe to be more likely.'
                                $align='center'
                                scope='column'
                                $width='122px'
                            />

                            <ColumnHeader
                                label='Domestic shipping'
                                Help='Stocks which are held on-board a vessel that is either in an Australian port, travelling between two Australian ports, or for which a ‘notice of readiness’ has been given in relation to unloading at an Australian port.'
                                $align='center'
                                scope='column'
                                $width='122px'
                            />

                            <TH $align='right' scope='col' $width='150px'>Total on water</TH>   
                                                    
                            {
                                hasExpiredData ?
                                <DeleteActionCellTH>
                                    <VisuallyHidden>Actions</VisuallyHidden>
                                </DeleteActionCellTH> :
                                null
                            }
                        </StyledTR>
                    </THead>

                    {
                        group.products
                            .filter(p => p.productStatus !== 'expired')
                            .map((product, i) => (
                            <ProductGroupTableSection
                                key={product.product.id}
                                stockholdingCtrl={stockholdingCtrl}
                                groupIndex={groupIndex}
                                product={product}
                                productIndex={i}
                            />
                        ))
                    }
                </Table>
            </BoxedDiv>
        </BoxedDiv>
    );
}

interface ProductGroupTableSectionProps {
    groupIndex: number;
    product: ProductFormData;
    productIndex: number;
    stockholdingCtrl: UseStockholding;
}

const ProductGroupTableSection = ({groupIndex, product, productIndex, stockholdingCtrl}: ProductGroupTableSectionProps) => {
    const {onWaterCtrl: {addPort}} = stockholdingCtrl;
    const hasExpiredData = useMemo(() => {
        return product.productStatus === 'expired_with_data';

    }, [product.productStatus]);

    const showAddButton = useMemo(() => {
        return (
            !stockholdingCtrl.disableInputs &&
            !hasExpiredData
        );
    }, [hasExpiredData, stockholdingCtrl.disableInputs]);

    return (
        <StyledTBody>
            {product?.ports
                .filter(port => recordActionFromEnum(port.data.recordAction) !== 'Delete')
                .map((port, i) => (
                    <PortRow
                        autofocus={groupIndex === 0 && productIndex === 0 && port.rowIndex === 0}
                        isFirstRow={i === 0}
                        key={port.rowIndex}
                        stockholdingCtrl={stockholdingCtrl}
                        product={product}
                        port={port}
                        hasExpiredData={hasExpiredData}
                    />
            ))}

            <TR>
                <TD $align='right' colSpan={3}>
                    {
                        showAddButton ?
                        <BoxedDiv box={{marginRight: 4}}>
                            <AddButton
                                label='Add location'
                                onClick={() => addPort(product.product.id!!)}
                            >Add location</AddButton>
                        </BoxedDiv> :
                        null
                    }
                </TD>

                <TD colSpan={5}/>
            </TR>

        </StyledTBody>
    )
}

interface PortRowProps {
    autofocus: boolean;
    isFirstRow: boolean;
    port: PortFormData;
    product: ProductFormData;
    stockholdingCtrl: UseStockholding;
    hasExpiredData: boolean;
}

const PortRow = ({autofocus, isFirstRow, port, product, hasExpiredData, stockholdingCtrl}: PortRowProps) => {
    const [deleteRef, setDeleteRef] = useState<HTMLElement | null>(null);

    const {onWaterCtrl: ctrl, disableInputs} = stockholdingCtrl;

    const {deletePort, focusedFieldCtrl, updatePort, updateProduct} = ctrl;

    const {product: stockProduct} = product;

    const Help = useHelpForProduct(
        stockholdingCtrl.onWaterCtrl.portalDataAPICtrl,
        'On water',
        product.product.referenceCode
    );
    
    const countrySelectCtrl = useSelectController({
        options: (stockholdingCtrl.countries || []).filter(c => c.isInternal === false || port.data.countryId === c.id).map(c => ({label: c.absCountryName, value: c.id!!})),
        onChange: (countryId => updatePort({
            rowIndex: port.rowIndex,
            value: countryId!!,
            field: 'countryId',
            stockProductId: stockProduct.id!!,
        })),
        value: port.data.countryId || null,
    });

    const portsCount = product.ports
        .filter(p => recordActionFromEnum(p.data.recordAction) !== 'Delete')
        .length;

    const portFocusedField = useMemo(() => {
        return ((
                isPortFocusField(focusedFieldCtrl.focusedField) &&
                focusedFieldCtrl.focusedField.stockProductId === stockProduct.id &&
                focusedFieldCtrl.focusedField.rowIndex === port.rowIndex
            ) ?
                focusedFieldCtrl.focusedField.field :
                null
        );
    }, [focusedFieldCtrl.focusedField, port, stockProduct.id]);

    const productFocusedField = useMemo(() => {
        return (
                isProductFocusField(focusedFieldCtrl.focusedField) &&
                focusedFieldCtrl.focusedField.stockProductId === stockProduct.id
            ) ?
            focusedFieldCtrl.focusedField.field :
            null;
    }, [focusedFieldCtrl.focusedField, stockProduct.id]);

    const canDelete = useMemo(() => {
        return (
            !stockholdingCtrl.disableInputs &&
            (hasExpiredData || (!port.validationAlerts.find(va => va.validationAlert === 'PreviouslyReportedAlert') && (
                portsCount > 1 ||
                !isEmpty({ quantity: port.data.quantity, countryId: port.data.countryId }))
            ))
        )
    }, [port, portsCount, hasExpiredData, stockholdingCtrl.disableInputs])

    const showExpiredWarning = useMemo(() => {
        return (
            !stockholdingCtrl.disableInputs &&
            hasExpiredData
        )
    }, [stockholdingCtrl.disableInputs, hasExpiredData]);

    return <>
        <TR>
            {/* Product label for first row */}
            {
                isFirstRow &&
                <TH rowSpan={portsCount} $width='350px' scope='row'>
                    <BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
                        {stockProduct.productName}                           
                        {
                            showExpiredWarning ? <BoxedSpan box={{marginBottom: -0.5, marginLeft: 1}}>
                            <FloatingMessage
                                content={EXPIRED_OR_INTERNAL_PRODUCT}
                                kind="warning"
                                role="alert"
                            />
                            </BoxedSpan> : (
                                Help && <>
                                <TooltipHelp
                                    Help={<ReactMarkdown>{Help}</ReactMarkdown>}
                                />
                            </>
                          )
                        }
                    </BoxedSpan>
                </TH>
            }

            <TD>
                <GroupInput
                    align='right'
                    autoFocus={autofocus || port.isAddedRow}
                    bare={true}
                    disabled={disableInputs}
                    error={port.errorMessages?.quantity?.message}
                    forceError={stockholdingCtrl.onWaterCtrl.shouldForceErrors}
                    formatter={val => localeNumberWithFixed(val as number)}
                    info={port.infoMessages.quantity?.message}
                    id={`product${stockProduct.id}_${port.rowIndex}_quantity`}
                    label={`Quantity of ${stockProduct.productName} for this location`}
                    onChange={e => updatePort({
                        field: 'quantity',
                        stockProductId: stockProduct.id!!,
                        rowIndex: port.rowIndex,
                        value: e.target.value
                    })}
                    setFocused={portFocusedField === 'quantity'}
                    type='number'
                    unstyled={true}
                    useTooltip={true}
                    value={port.data.quantity}
                />
            </TD>

            <TD>
                <GroupSelect
                    bare={true}
                    borderless={true}
                    focusless={true}
                    forceError={stockholdingCtrl.validationChecked || stockholdingCtrl.onWaterCtrl.shouldForceErrors}
                    isDisabled={disableInputs}
                    error={port.errorMessages?.countryId?.message}
                    id={`product${stockProduct.id}_${port.rowIndex}_countryid`}
                    label={`Country where this ${stockProduct.productName} is held`}
                    setFocused={portFocusedField === 'countryId'}
                    value={countrySelectCtrl.value}
                    onChange={countrySelectCtrl.onChange}
                    options={countrySelectCtrl.options}
                    useTooltip={true}
                    width='350px'
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                />
            </TD>

            <TD padding='0' $width='30px'>
                {
                    canDelete && !hasExpiredData &&
                    <BoxedSpan box={{alignItems: 'center', height: '100%', flex: 'row'}}>
                        <IconButton
                            color='dark-grey'
                            icon='trash'
                            label='delete row'
                            onClick={() => deletePort(stockProduct.id!, port.rowIndex, deleteRef)}
                            ref={setDeleteRef}
                            size='sm'
                            styles={{padding: '4px', width: '100%'}}
                        />
                    </BoxedSpan>
                }
            </TD>

            {/* Product details for first row*/}
            {
                isFirstRow && <>
                    <TD>
                        <GroupInput
                            align='right'
                            bare={true}
                            disabled={disableInputs}
                            error={product.errorMessages?.highSeas?.message}
                            forceError={stockholdingCtrl.onWaterCtrl.shouldForceErrors}
                            formatter={val => localeNumberWithFixed(val as number)}
                            id={`product${stockProduct.id}_highseas`}
                            info={asNumber(product.data.highSeas) < 0 ? INFO_NEGATIVE_VALUE_COMMENTS : undefined}
                            label={`Quantity of ${stockProduct.productName} on high seas`}
                            onChange={e => updateProduct({
                                field: 'highSeas',
                                stockProductId: stockProduct.id!!,
                                value: e.target.value
                            })}
                            setFocused={productFocusedField === 'highSeas'}
                            type='number'
                            unstyled={true}
                            useTooltip={true}
                            value={product.data.highSeas}
                            width='130px'
                        />
                    </TD>

                    <TD>
                        <GroupInput
                            align='right'
                            bare={true}
                            disabled={disableInputs}
                            error={product.errorMessages?.eez?.message}
                            forceError={stockholdingCtrl.onWaterCtrl.shouldForceErrors}
                            formatter={val => localeNumberWithFixed(val as number)}
                            id={`product${stockProduct.id}_eez`}
                            info={asNumber(product.data.eez) < 0 ? INFO_NEGATIVE_VALUE_COMMENTS : undefined}
                            label={`Quantity of ${stockProduct.productName} in EEZ`}
                            onChange={e => updateProduct({
                                field: 'eez',
                                stockProductId: stockProduct.id!!,
                                value: e.target.value
                            })}
                            setFocused={productFocusedField === 'eez'}
                            type='number'
                            unstyled={true}
                            useTooltip={true}
                            value={product.data.eez}
                            width='130px'
                        />
                    </TD>

                    <TD>
                        <GroupInput
                            align='right'
                            bare={true}
                            disabled={disableInputs}
                            error={product.errorMessages?.domesticShipping?.message}
                            forceError={stockholdingCtrl.onWaterCtrl.shouldForceErrors}
                            formatter={val => localeNumberWithFixed(val as number)}
                            id={`product${stockProduct.id}_domesticshipping`}
                            info={asNumber(product.data.domesticShipping) < 0 ? INFO_NEGATIVE_VALUE_COMMENTS : undefined}
                            label={`Quantity of ${stockProduct.productName} in domestic shipping`}
                            onChange={e => updateProduct({
                                field: 'domesticShipping',
                                stockProductId: stockProduct.id!!,
                                value: e.target.value
                            })}
                            setFocused={productFocusedField === 'domesticShipping'}
                            type='number'
                            unstyled={true}
                            useTooltip={true}
                            value={product.data.domesticShipping}
                            width='130px'
                        />
                    </TD>

                    <TotalCell
                        info={product.infoMessages.total?.message}
                        rowSpan={portsCount}
                        value={localeNumberWithFixed(product.total)}
                    />
                    
                    {
                        (isFirstRow && showExpiredWarning) ?
                        <DeleteActionCellTD rowSpan={portsCount}>
                            {
                                <BoxedDiv box={{ alignItems: 'center', flex: 'row', marginLeft: 1 }}>
                                    <DeleteRowButton
                                        focusedField={focusedFieldCtrl.focusedField}
                                        onClick={() => ctrl.deleteAllProductRows(product.product.id, deleteRef)}
                                        productId={product.product.id}
                                        ref={setDeleteRef}
                                    />
                                </BoxedDiv>
                            }
                        </DeleteActionCellTD> :
                        null
                    }
                </>
            }
        </TR>
    </>
}

interface DeleteRowProps {
    focusedField: FocusField | null;
    onClick: () => any;
    productId: number;
}

const DeleteRowButton = React.forwardRef(({focusedField, onClick, productId}: DeleteRowProps, ref) => {
    const focusable = useFocusable({
        setFocused: (
            isDeleteFocusField(focusedField) &&
            focusedField.field === 'delete' &&
            focusedField.stockProductId === productId
        ),
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <IconButton
            color='dark-grey'
            icon='trash'
            label='Delete all rows for product'
            onClick={onClick}
            ref={setRef}
            size='sm'
        />
    );
});


export default OnWaterEditor;
