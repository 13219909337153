import { useAPI } from "psims/react/providers/api";
import useDataSubmissionAPI from "psims/react/pages/primary-pages/data-submissions/shared/use-data-submission-api";
import { isSuccesfulRowResult } from "psims/models/api/record-result";
import { getRecordResults, isMsoAnnualActivityRefinerSubmission, isMsoAnnualActivityRefinerSubmitted, MsoAnnualActivityRefinerSubmission } from "psims/models/submission-types/mso/annual-activity/refiner/mso-annual-activity-refiner-submission";

interface UseRefinerAnnualActivityAPIProps {
    id: number;
}

function useRefinerAnnualActivityAPI({id}: UseRefinerAnnualActivityAPIProps) {
    const {api} = useAPI();

    return useDataSubmissionAPI({
        actionFetch: api.getMsoAnnualActivityRefinerSubmission,
        actionSubmit: api.submitMsoAnnualActivityRefiner,
        actionUpdate: api.updateMsoAnnualActivityRefiner,
        actionUpdateDataSubmission: api.updateMsoAnnualActivityRefinerDataSubmission,
        actionClearAllData: api.clearAllMsoAnnualActivityRefiner,
        dataSubmissionId: id,
        name: 'MSO refiner - Annual activity',
        submissionAssertion: isMsoAnnualActivityRefinerSubmission,
        submitSuccessAssertion: isMsoAnnualActivityRefinerSubmitted,
        updateResponseTransform: removeDeleted,
        updateSuccessAssertion,
    });
}

export default useRefinerAnnualActivityAPI;

export type UseRefinerAnnualActivityAPI = ReturnType<typeof useRefinerAnnualActivityAPI>;

function updateSuccessAssertion(result?: unknown) {
    if (result == null) {
        return false;
    }

    return getRecordResults(result)
        .filter(r => !isSuccesfulRowResult(r)).length === 0;
}

function removeDeleted(result: MsoAnnualActivityRefinerSubmission): MsoAnnualActivityRefinerSubmission {
    const transformed = {
        ...result,
        stockRefineriesHeld: result.annualActivities.filter(a => a.recordResult?.rowResult !== 'Deleted'),
        stockholdings: result.storageSites.filter(s => s.recordResult?.rowResult !== 'Deleted'),
    };

    return transformed;
}
