/** This is probably the worst technical approach possible. WTAF was I thinking. */
import { ReactNode } from "react";

import {
    DYN_INVALID_CALCULATED_QUANTITY_SYMMETRIC_RANGE,
    DYN_INVALID_QUANTITY_SYMMETRIC_RANGE,
    DYN_INVALID_POSITIVE_DECIMAL,
    DYN_NOTIFICATION_INVALID_CALCULATED_QUANTITY,
    DYN_NOTIFICATION_INVALID_ENTITY_NAME,
    DYN_NOTIFICATION_INVALID_QUANTITY_SYMMETRIC_RANGE,
    DYN_NOTIFICATION_INVALID_DENSITY_ASYMMETRIC_RANGE,
    INLINE_INVALID_MSO_NOT_MET_REQUIRES_COMMENTS,
    INVALID_ENTITY_NAME,
    INVALID_MSO_NOT_MET_REQUIRES_COMMENTS,
    INVALID_AMOUNT_EMPTY,
    INVALID_ENTITY_NAME_TEXT,
    INLINE_INVALID_ACQUITTAL_REQUIRES_COMMENTS,
    INVALID_OWNERSHIP_TYPE_EMPTY,
    INVALID_ENTITY_NAME_ON_BEHALF_EMPTY,
    INVALID_ENTITY_NAME_ARRANGEMENT_EMPTY,
    EXPIRED_OR_INTERNAL_PRODUCT
} from "psims/constants/validation-messages";
import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { isBetween, isInteger } from "psims/lib/validation/number";
import { isValidEntityNameText, isValidStringLength } from "psims/lib/validation/string";
import { validateComment, ValidationMessageWithCode } from "../../shared/validation";
import { validateDecimalInRange } from "../../shared/validation";
import { recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { MsoProduct } from "psims/models/ref-data/mso-product";
import { UpdateMso } from "psims/models/submission-types/mso/mso";
import { UpdateMsoComment } from "psims/models/submission-types/mso/mso-comment";
import { UpdateMsoStockOnBehalf } from "psims/models/submission-types/mso/mso-stock-on-behalf";
import { UpdateMsoStockholding } from "psims/models/submission-types/mso/mso-stockholding";
import { UpdateMsoStockholdingOwnership } from "psims/models/submission-types/mso/mso-stockholding-ownership";
import { OrganisationMsoSetting } from "psims/models/submission-types/mso/organisation-mso-setting";
import NotificationMessage from "../../shared/notification-message";
import { UseMsoImporterFocus } from "../importer/use-mso-importer";
import { UseMso } from "./use-mso";
import { UseMsoRefData } from "./use-mso-ref-data";
import { is } from "psims/lib/type-assertions";
import { UpdateMsoStockRefineryHeld } from "psims/models/submission-types/mso/mso-stock-refinery";
import { UseFocusedField } from "psims/react/util/use-focused-field";
import { StockRefineryHeldFocusField } from './types';
import { UseMsoRefinerFocus } from "../refiner/use-mso-refiner";
import { attemptScrollToSelector } from "psims/react/pages/primary-pages/data-submissions/shared/view-utils";
import { asNumber } from "psims/lib/number";
import { isEmptyUpdateMsoStockholding, isEmptyUpdateMsoStockOnBehalf } from "./utils";

type PropertyValidation = {
    tooltip: {
        message: string;
    };
    notification: {
        content: ReactNode;
    }
}

export type ModelValidation<TFields extends string> = {
    [key in TFields]: PropertyValidation | undefined;
}

const DENSITY_LOWER_BOUND = 0;
const DENSITY_UPPER_BOUND = 5;
const DENSITY_UPPER_BOUND_STR = "5";
const DENSITY_DECIMAL_PLACES = 10;
const ENTITY_NAME_CHARS = 200;
const QTY_LOWER_BOUND = 0;
const QTY_UPPER_BOUND = 1000000;
const IS_BETWEEN_OPTS = {max: QTY_UPPER_BOUND, min: QTY_LOWER_BOUND};

export type InvalidEntityNameCode = 'invalid_required' | 'invalid_characters' | 'invalid_length';

function validateEntityName(val: string | null | undefined, isRequired?: boolean): ValidationMessageWithCode<InvalidEntityNameCode> | undefined {
    if (val == null) {
        return isRequired ? {
            message: INVALID_ENTITY_NAME,
            code: 'invalid_required',
         } : undefined;
    }

    const msg: ValidationMessageWithCode<InvalidEntityNameCode> | undefined = !isValidEntityNameText(val) ? {
        message: INVALID_ENTITY_NAME_TEXT,
        code: 'invalid_characters',
    } : !isValidStringLength(val, {max: 200, min: 1}) ? {
        message: INVALID_ENTITY_NAME,
        code: 'invalid_length',
    } : undefined;

    return msg;
}

function validateDensity(val: number | null | undefined, msg?: string, isRequired?: boolean) {
    const message = msg || DYN_INVALID_POSITIVE_DECIMAL(localeNumberWithFixed(DENSITY_UPPER_BOUND), DENSITY_DECIMAL_PLACES);

    if (val == null) {
        return isRequired ? message : undefined;
    }

    return validateDecimalInRange(val, DENSITY_UPPER_BOUND, DENSITY_LOWER_BOUND, DENSITY_DECIMAL_PLACES, message);
}

function validateQuantity(val: number | null | undefined, msg?: string, isRequired?: boolean) {
    const message = msg || DYN_INVALID_QUANTITY_SYMMETRIC_RANGE(localeNumberWithFixed(QTY_UPPER_BOUND), false);

    if (val == null) {
        return isRequired ? message : undefined;
    }

    return (!isBetween(val, IS_BETWEEN_OPTS) || (!isInteger(val)) ? message : undefined)
}

function validateCalculatedQuantity(val: number | null | undefined, max: number, min?: number, msg?: string) {
    if (val == null) {
        return undefined;
    }

    return (!isBetween(val, {max, min: min == null ? -Infinity : min}) || (!isInteger(val)) ? msg || DYN_INVALID_CALCULATED_QUANTITY_SYMMETRIC_RANGE(localeNumberWithFixed(max, 0), min != null && min < 0) : undefined)
}

function validateOwnershipType(val: number | null | undefined, msg?: string, isRequired?: boolean) {
    return (val == null ? (msg || INVALID_OWNERSHIP_TYPE_EMPTY) : undefined);
}

function validateStockAmount(val: number | null | undefined, coValueEntered: boolean, msg?: string, isRequired?: boolean) {
    if (coValueEntered && val == null) {
        return INVALID_AMOUNT_EMPTY;
    }

    return validateQuantity(val, msg, isRequired);
}

function validateEntityNameArrangement(val: string | null | undefined, coValueEntered: boolean, isRequired?: boolean) {
    if (val == null) {
        return {
            code: 'invalid_required' as 'invalid_required',
            message: INVALID_ENTITY_NAME_ARRANGEMENT_EMPTY,
        };
    }

    return validateEntityName(val, isRequired);
}

function validateEntityNameOnBehalf(val: string | null | undefined, coValueEntered: boolean, isRequired?: boolean) {
    if (val == null) {
        return {
            code: 'invalid_required' as 'invalid_required',
            message: INVALID_ENTITY_NAME_ON_BEHALF_EMPTY,
        };
    }

    return validateEntityName(val, isRequired);
}

// Form controller helpers
function getMsoValidationMessages(
    mso: UpdateMso,
    msoSetting: OrganisationMsoSetting,
    product: MsoProduct,
    focusFieldCtrl: FocusCtrl,
    enforceMandatoryFields: boolean,
    comments?: string | null
) {
    const acquittedMsg = msoSetting?.msoAcquitted ? validateQuantity(mso.acquittedAmount, undefined, true) : undefined;
    const differenceMsg = validateCalculatedQuantity(mso.difference, 1000000, -1000000) || (msoSetting == null ? undefined : (mso.difference || 0) < 0 && !comments ? INLINE_INVALID_MSO_NOT_MET_REQUIRES_COMMENTS : undefined);
    const suppliedMsg = validateCalculatedQuantity(mso.supplied, 1000000);

    return {
        ...mso,
        validationMessages: {
            acquittedAmount: acquittedMsg ? {
                tooltip: {
                    message: acquittedMsg
                },
                notification: {
                    content: NotificationMessage({parts: buildInvalidQuantityNotificationContent(product, 'Acquittal amount', () => focusFieldCtrl.setFocusedField({kind: 'mso', field: 'acquittedAmount', msoProductId: product.id}))}),
                }
            } : undefined,
            difference: differenceMsg ? {
                tooltip: {
                    message: differenceMsg
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidDifferenceNotificationContent(
                            () => focusFieldCtrl.setFocusedField({kind: 'comments'}),
                            () => attemptScrollToSelector('[data-mso-summary'),
                            product
                        )
                    }),
                }
            } : undefined,
            supplied: suppliedMsg ? {
                tooltip: {
                    message: suppliedMsg
                },
                notification: {
                    content: NotificationMessage({parts: buildInvalidCalculatedNotificationContent(
                        product,
                        'supplied',
                        '1,000,000')}),
                }
            } : undefined,
        }
    }
}

function getStockholdingValidationMessages(
    stockholding: UpdateMsoStockholding,
    product: UseMsoRefData['products'][number],
    focusFieldCtrl: FocusCtrl,
    excludeEEZ: boolean
) {
    const isDeleted = recordActionFromEnum(stockholding.recordAction) === 'Delete';
    // First validate expired products...
    if (product.productStatus === 'inactive') {
        if (!isDeleted && !isEmptyUpdateMsoStockholding(stockholding)) {
            return {
                ...stockholding,
                stockholdingOwnerships: (
                    stockholding.stockholdingOwnerships as Array<UpdateMsoStockholdingOwnership>)
                        .map((o, rowIndex) => getStockholdingOwnershipValidationMessages(o, product, focusFieldCtrl, rowIndex, isDeleted)
                ),
                validationMessages: {
                    density: undefined,
                    eez: undefined,
                    holderOwnerStockVolume: undefined,
                    pipeline: undefined,
                    inactiveProduct: {
                        tooltip: {
                            message: EXPIRED_OR_INTERNAL_PRODUCT,
                        },
                        notification: {
                            content: NotificationMessage({
                                parts: buildInvalidInactiveProductNotificationContent(
                                    () => focusFieldCtrl.setFocusedField({
                                        kind: 'stockholding',
                                        field: 'delete',
                                        msoProductId: product.id
                                    }),
                                    product,
                                )
                            }),
                        }
                    }
                }
            }
        }
    }

    // ...otherwise, standard validation
    const densityMsg = isDeleted ? undefined : validateDensity(stockholding.density);
    const eezMsg = (excludeEEZ || isDeleted) ? undefined : validateQuantity(stockholding.eez, undefined, true);
    const ownerHolderMsg = isDeleted ? undefined : validateQuantity(stockholding.holderOwnerStockVolume, undefined, true);
    const pipelineMsg = isDeleted ? undefined : validateQuantity(stockholding.pipeline, undefined, true);

    return {
        ...stockholding,
        stockholdingOwnerships: (
            stockholding.stockholdingOwnerships as Array<UpdateMsoStockholdingOwnership>)
                .map((o, rowIndex) => getStockholdingOwnershipValidationMessages(o, product, focusFieldCtrl, rowIndex, isDeleted)
        ),
        msoProductId: product.id,
        validationMessages: {
            eez: eezMsg ? {
                tooltip: {
                    message: eezMsg
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidQuantityNotificationContent(
                            product,
                            'EEZ stock',
                            () => focusFieldCtrl.setFocusedField({kind: 'stockholding', field: 'eez', msoProductId: product.id})
                        )
                    }),
                }
            } : undefined,

            density: densityMsg ? {
                tooltip: {
                    message: densityMsg
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidDensityNotificationContent(
                            product,
                            'Density',
                            () => focusFieldCtrl.setFocusedField({kind: 'stockholding', field: 'density', msoProductId: product.id})
                        )
                    }),
                }
            } : undefined,

            holderOwnerStockVolume: ownerHolderMsg ? {
                tooltip: {
                    message: ownerHolderMsg
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidQuantityNotificationContent(
                            product,
                            'Holder and owner of stocks (s22)',
                            () => focusFieldCtrl.setFocusedField({kind: 'stockholding', field: 'holderOwnerStockVolume', msoProductId: product.id})
                        )
                    }),
                }
            } : undefined,

            pipeline: pipelineMsg ? {
                tooltip: {
                    message: pipelineMsg
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidQuantityNotificationContent(
                            product,
                            'Pipeline',
                            () => focusFieldCtrl.setFocusedField({kind: 'stockholding', field: 'pipeline', msoProductId: product.id})
                        )
                    }),
                }
            } : undefined,
        }
    }
}

function getStockholdingOwnershipValidationMessages(
    ownership: UpdateMsoStockholdingOwnership,
    product: UseMsoRefData['products'][number],
    focusFieldCtrl: FocusCtrl,
    rowIndex: number,
    isParentDeleted: boolean
) {
    const isInactive = product.productStatus === 'inactive';
    const isDeleted = isParentDeleted || recordActionFromEnum(ownership.recordAction) === 'Delete';

    // validation can be skipped if product is inactive, as there will be a validation on the
    // parent msoStockholding record
    const skipValidation = isInactive || isDeleted;

    const entityNameMsg = skipValidation ? undefined : validateEntityNameArrangement(ownership.entityName, ownership.stockVolume != null, true);
    const stockVolumeMsg = skipValidation ? undefined : validateStockAmount(ownership.stockVolume, Boolean(ownership.entityName), undefined, true);
    const ownershipTypeMsg = skipValidation ? undefined : validateOwnershipType(ownership.msoOwnershipType);

    return {
        ...ownership,
        msoProductId: product.id,
        validationMessages: {
            entityName: entityNameMsg ? {
                tooltip: {
                    message: entityNameMsg.message
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidEntityNameNotificationContent(
                            product,
                            'Name of entity the arrangement is with',
                            () => focusFieldCtrl.setFocusedField({
                                kind: 'ownership',
                                field: 'entityName',
                                rowIndex,
                                msoProductId: product.id,
                            }),
                            entityNameMsg.code
                        )
                    })
                }
            } : undefined,

            stockVolume: stockVolumeMsg ? {
                tooltip: {
                    message: stockVolumeMsg
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidQuantityNotificationContent(
                            product,
                            'Stock amount',
                            () => focusFieldCtrl.setFocusedField({
                                kind: 'ownership',
                                field: 'stockVolume',
                                rowIndex,
                                msoProductId: product.id,
                            })
                        )
                    })
                }
            } : undefined,

            msoOwnershipType: ownershipTypeMsg ? {
                tooltip: {
                    message: ownershipTypeMsg
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidOwnershipNotificationContent(
                            product,
                            'Stock ownership',
                            () => focusFieldCtrl.setFocusedField({
                                kind: 'ownership',
                                field: 'msoOwnershipType',
                                rowIndex,
                                msoProductId: product.id,
                            })
                        )
                    })
                }
            } : undefined,
        }
    }
}

function getStockOnBehalfValidationMessages(
    sob: UpdateMsoStockOnBehalf,
    product: UseMsoRefData['products'][number],
    focusFieldCtrl: FocusCtrl,
    rowIndex: number
) {
    const isDeleted = recordActionFromEnum(sob.recordAction) === 'Delete';
    // First validate expired products...
    if (product.productStatus === 'inactive') {
        if (!isDeleted && !isEmptyUpdateMsoStockOnBehalf(sob)) {
            return {
                ...sob,
                validationMessages: {
                    amountHeld: undefined,
                    entityOnBehalf: undefined,
                    msoOwnershipType: undefined,
                    inactiveProduct: {
                        tooltip: {
                            message: EXPIRED_OR_INTERNAL_PRODUCT,
                        },
                        notification: {
                            content: NotificationMessage({
                                parts: buildInvalidInactiveProductNotificationContent(
                                    () => focusFieldCtrl.setFocusedField({
                                        field: 'delete',
                                        kind: 'onBehalf',
                                        msoProductId: product.id
                                    }),
                                    product,
                                )
                            }),
                        }
                    }
                }
            }
        }
    }

    const entityOnBehalfMsg = isDeleted ? undefined : validateEntityNameOnBehalf(sob.entityOnBehalf, sob.amountHeld != null, true);
    const amountHeldMsg = isDeleted ? undefined : validateStockAmount(sob.amountHeld, Boolean(sob.entityOnBehalf), undefined, true);
    const ownershipTypeMsg = isDeleted ? undefined : validateOwnershipType(sob.msoOwnershipType);

    return {
        ...sob,
        validationMessages: {
            amountHeld: amountHeldMsg ? {
                tooltip: {
                    message: amountHeldMsg,
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidQuantityNotificationContent(
                            product,
                            'Amount held',
                            () => focusFieldCtrl.setFocusedField({kind: 'stockOnBehalf', field: 'amountHeld', rowIndex, msoProductId: product.id})
                        )
                    })
                }
            } : undefined,
            
            entityOnBehalf: entityOnBehalfMsg ? {
                tooltip: {
                    message: entityOnBehalfMsg.message,
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidEntityNameNotificationContent(
                            product,
                            'Entity held on behalf of',
                            () => focusFieldCtrl.setFocusedField({kind: 'stockOnBehalf', field: 'entityOnBehalf', rowIndex, msoProductId: product.id}),
                            entityOnBehalfMsg.code
                            )
                    })
                }
            } : undefined,

            msoOwnershipType: ownershipTypeMsg ? {
                tooltip: {
                    message: ownershipTypeMsg
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidOwnershipNotificationContent(
                            product,
                            'ownership type',
                            () => focusFieldCtrl.setFocusedField({
                                kind: 'stockOnBehalf',
                                field: 'msoOwnershipType',
                                rowIndex,
                                msoProductId: product.id,
                            })
                        )
                    })
                }
            } : undefined,
        }
    }
}

function getRefineryHeldValidationMessages(
    refineryHeld: UpdateMsoStockRefineryHeld,
    product: UseMsoRefData['products'][number],
    focusFieldCtrl: UseFocusedField<StockRefineryHeldFocusField>
) {
    const crudeMsg = validateQuantity(refineryHeld.crudeStockVolume, undefined, product.productStatus === 'active');
    const unfinishedMsg = validateQuantity(refineryHeld.unfinishedStockVolume, undefined, product.productStatus === 'active');

    return {
        ...refineryHeld,
        validationMessages: {
            crudeStockVolume: crudeMsg ? {
                tooltip: {
                    message: crudeMsg
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidQuantityNotificationContentForRefineryHeld(
                            product,
                            'Crude',
                            () => focusFieldCtrl.setFocusedField({kind: 'stockRefineryHeld', field: 'crudeStockVolume', msoProductId: product.id})
                            )
                    }),
                }
            } : undefined,

            unfinishedStockVolume: unfinishedMsg ? {
                tooltip: {
                    message: unfinishedMsg
                },
                notification: {
                    content: NotificationMessage({
                        parts: buildInvalidQuantityNotificationContentForRefineryHeld(
                            product,
                            'Unfinished',
                            () => focusFieldCtrl.setFocusedField({kind: 'stockRefineryHeld', field: 'unfinishedStockVolume', msoProductId: product.id})
                        )
                    }),
                }
            } : undefined,
        }
    }
}


function getMsoCommentValidationMessages(msoComment: UpdateMsoComment | null, msoCtrl: UseMso, focusFieldCtrl: FocusCtrl) {
    const unmetMso = msoCtrl.msos.find(mso => (mso.mso.difference || 0) < 0);
    const nonZeroAcquittal = msoCtrl.msos.find(mso => (mso.mso.acquittedAmount || 0) > 0);
    const commentValidationResult = validateComment(
        msoComment?.comments,
        unmetMso != null ?
            INLINE_INVALID_MSO_NOT_MET_REQUIRES_COMMENTS :
            nonZeroAcquittal != null ?
                INLINE_INVALID_ACQUITTAL_REQUIRES_COMMENTS :
                undefined
    );

    const commentMsg = commentValidationResult?.message;
    
    return {
        ...(msoComment || {}),
        validationMessages: {
            comments: commentMsg ? {
                tooltip: {
                    message: commentMsg,
                },
                notification: {
                    // Notification not required for unmet - handled by 'difference' field in mso table
                    content: unmetMso ? null : NotificationMessage({
                        parts: buildInvalidCommentNotificationContent(
                            commentMsg,
                            () => focusFieldCtrl.setFocusedField({kind: 'comments'})
                        )
                    }) ,
                }
            } : undefined,
        }
    }
}

function buildInvalidQuantityNotificationContent(product: MsoProduct, field: string, onTargetClick: () => any) {
    const [prefix, suffix] = DYN_NOTIFICATION_INVALID_QUANTITY_SYMMETRIC_RANGE(localeNumberWithFixed(QTY_UPPER_BOUND), false);

    return [
        `For ${product.name}: `,
        prefix,
        {label: `Go to ${field} field`, onClick: onTargetClick, text: field},
        suffix,
    ];
}

function buildInvalidQuantityNotificationContentForRefineryHeld(product: MsoProduct, field: string, onTargetClick: () => any) {
    const [prefix, suffix] = DYN_NOTIFICATION_INVALID_QUANTITY_SYMMETRIC_RANGE(localeNumberWithFixed(QTY_UPPER_BOUND), false);
    const fieldForRefineryHeld = `Intended for ${product.name}`;
    const productForRefineryHeld = field;
    return [
        `For ${productForRefineryHeld}: `,
        prefix,
        { label: `Go to ${fieldForRefineryHeld} field`, onClick: onTargetClick, text: fieldForRefineryHeld },
        suffix,
    ];
}

function buildInvalidDensityNotificationContent(product: MsoProduct, field: string, onTargetClick: () => any) {
    const [prefix, suffix] = DYN_NOTIFICATION_INVALID_DENSITY_ASYMMETRIC_RANGE(DENSITY_LOWER_BOUND, DENSITY_UPPER_BOUND, DENSITY_DECIMAL_PLACES, 
        DENSITY_UPPER_BOUND_STR, product.name);

    return [
        prefix,
        {label: `Go to ${field} field`, onClick: onTargetClick, text: field},
        suffix,
    ]
}

function buildInvalidCalculatedNotificationContent(product: MsoProduct, fieldName: string, range: string, negativeAllowed?: boolean) {
    const [prefix, suffix] = DYN_NOTIFICATION_INVALID_CALCULATED_QUANTITY(range, negativeAllowed);
    return [
        `For ${product.name}: `,
        prefix,        
        `${fieldName}`,
        suffix
    ];
}

function buildInvalidEntityNameNotificationContent(product: MsoProduct, field: string, onTargetClick: () => any, code: InvalidEntityNameCode) {
    const [prefix, suffix] = DYN_NOTIFICATION_INVALID_ENTITY_NAME(ENTITY_NAME_CHARS, code, true);

    return [
        `For ${product.name}: `,
        prefix,
        {label: `Go to ${field} field`, onClick: onTargetClick, text: field},
        suffix,
    ];
}

function buildInvalidOwnershipNotificationContent(product: MsoProduct, field: string, onTargetClick: () => any) {
    return [
        `For ${product.name}: `,
        'You have created an ownership row without selecting an ownership type. Please select an ',
        {label: `Go to ${field} field`, onClick: onTargetClick, text: 'Ownership type'},
        ' for this record.',
    ];
}

function buildInvalidCommentNotificationContent(message: string, onTargetClick: () => any) {
    return [
        'For ',
        {label: `Go to comments field`, onClick: onTargetClick, text: 'Comments'},
        `: ${message}`,
    ];
}

function buildInvalidDifferenceNotificationContent(onTargetClick: () => any, onAssociatedTargetClick: () => any, product: MsoProduct) {
    return [
        {label: 'View MSO not met difference', onClick: onAssociatedTargetClick, text: product.name},
        `: ${INVALID_MSO_NOT_MET_REQUIRES_COMMENTS} `,
        {label: `Go to comments field`, onClick: onTargetClick, text: 'Comments field'},
        ' above.'
    ];
}

function buildInvalidInactiveProductNotificationContent(onTargetClick: () => any, product: MsoProduct) {
    return [
        `For ${product.name}: `,
        'This product is inactive. Please enter these details into an active product if applicable. Delete ',
        {label: `Go to delete data button`, onClick: onTargetClick, text: 'product data'},
        ' to continue.',
    ];
}

type FocusCtrl = UseMsoImporterFocus | UseMsoRefinerFocus;

type DataValidationArgs = {
    enforceMandatoryFields: boolean;
    excludeEEZ: boolean;
    focusFieldCtrl: FocusCtrl; 
    msoComment: UpdateMsoComment;
    msoCtrl: UseMso;
    msos: Array<UpdateMso>;
    refData: UseMsoRefData;
    stockholdings: Array<UpdateMsoStockholding>;
    stocksOnBehalf: Array<UpdateMsoStockOnBehalf>;
    stockRefineriesHeld?: Array<UpdateMsoStockRefineryHeld>;
}

export function getDataValidationMessages({
    enforceMandatoryFields, excludeEEZ, focusFieldCtrl, msoComment, msoCtrl, msos, refData,
    stockholdings, stocksOnBehalf, stockRefineriesHeld
}: DataValidationArgs) {
    return {
        msoComment: getMsoCommentValidationMessages(msoComment, msoCtrl, focusFieldCtrl),
        msos: msos.map(m => {
            const withProd = msoCtrl.msos.find(mso => mso.mso.msoOrganisationSettingId === m.msoOrganisationSettingId);
            return getMsoValidationMessages(m as UpdateMso, withProd?.setting as OrganisationMsoSetting, withProd?.product as MsoProduct, focusFieldCtrl, enforceMandatoryFields, msoComment?.comments);
        }),
        stockholdings: stockholdings.map(s => getStockholdingValidationMessages(s as UpdateMsoStockholding, refData.productIdMap[s.msoProductId as number], focusFieldCtrl, excludeEEZ)),
        stocksOnBehalf: stocksOnBehalf.map((sob, rowIndex) => getStockOnBehalfValidationMessages(sob as UpdateMsoStockOnBehalf, refData.productIdMap[sob.msoProductId as number], focusFieldCtrl, rowIndex)),
        stockRefineriesHeld: (stockRefineriesHeld || []).map(r => getRefineryHeldValidationMessages(r as UpdateMsoStockRefineryHeld, refData.productIdMap[r.msoProductId as number], focusFieldCtrl as UseFocusedField<StockRefineryHeldFocusField>)),
    }
}

type DataNotificationsArgs = {
    msoProducts: Array<MsoProduct>;
    validationMessages: ReturnType<typeof getDataValidationMessages>;
}

export function getDataNotifications({msoProducts, validationMessages}: DataNotificationsArgs) {
    const ns = [
        ...([...validationMessages.stockholdings])
            .sort(compareProductOrder(msoProducts, s => s.msoProductId))
            .map(s => [
                s.validationMessages.density,
                s.validationMessages.holderOwnerStockVolume,
                ...s.stockholdingOwnerships.map(o => [o.validationMessages.stockVolume, o.validationMessages.entityName, o.validationMessages.msoOwnershipType].filter(is)).flat(),
                s.validationMessages.pipeline,
                s.validationMessages.eez,
                s.validationMessages.inactiveProduct,
            ].filter(is)),
        ...([...validationMessages.stockRefineriesHeld || []])
            .sort(compareProductOrder(msoProducts, s => s.msoProductId))
            .map(r => [r.validationMessages.crudeStockVolume,  r.validationMessages.unfinishedStockVolume].filter(is)),
        ...([...validationMessages.stocksOnBehalf])
            .sort(compareProductOrder(msoProducts, s => s.msoProductId))
            .map(sob => [
                sob.validationMessages.amountHeld,
                sob.validationMessages.entityOnBehalf,
                sob.validationMessages.msoOwnershipType,
                sob.validationMessages.inactiveProduct,
            ].filter(is)),
        ...([...validationMessages.msos])
            .sort(compareProductOrder(msoProducts, s => asNumber(s.msoProductId)))
            .map(mso => [mso.validationMessages.acquittedAmount, mso.validationMessages.difference, mso.validationMessages.supplied].filter(is)),
        ...([validationMessages.msoComment.validationMessages.comments].filter(is))
    ]
    const vms = ns.flat().map(v => v.notification);
    return vms;
}

type SubmitValidationArgs = {
    focusFieldCtrl: FocusCtrl; 
    goToStep: (index: number) => any;
    refData: UseMsoRefData;
    stockholdings: Array<UpdateMsoStockholding>;
}

export function getSubmitValidationMessages({ focusFieldCtrl, goToStep, refData, stockholdings, }: SubmitValidationArgs) {
    return {
        stockholdings: stockholdings.map(s => {
            const product = refData.productIdMap[s.msoProductId];
            const mustBeRemoved = product.productStatus === 'inactive' && recordActionFromEnum(s.recordAction) !== 'Delete';
            const inactiveProductMsg = mustBeRemoved ? EXPIRED_OR_INTERNAL_PRODUCT : undefined;

            return {
                ...s,
                stockholdingOwnerships: [],
                validationMessages: {
                    density: undefined,
                    eez: undefined,
                    holderOwnerStockVolume: undefined,
                    pipeline: undefined,
                    inactiveProduct: inactiveProductMsg == null ? undefined : {
                        tooltip: {
                            message: inactiveProductMsg,
                        },
                        notification: {
                            content: NotificationMessage({
                                parts: buildInvalidInactiveProductNotificationContent(
                                    () => {
                                        focusFieldCtrl.setFocusedField({
                                            kind: 'stockholding',
                                            field: 'delete',
                                            msoProductId: product.id
                                        });
                                        goToStep(0);
                                    },
                                    product,
                                )
                            }),
                        }
                    }
                }
            }
        }),
        // Empty records for submit page
        msoComment: null,
        msos: [],
        stockRefineriesHeld: [],
        stocksOnBehalf: [],
    }
}

type SubmitNotificationsArgs = {
    msoProducts: Array<MsoProduct>;
    validationMessages: ReturnType<typeof getDataValidationMessages>;
}

export function getSubmitNotifications({msoProducts, validationMessages}: SubmitNotificationsArgs) {
    const ns = [
        ...([...validationMessages.stockholdings])
            .sort(compareProductOrder(msoProducts, s => s.msoProductId))
            .map(s => [
                s.validationMessages.inactiveProduct,
            ].filter(is)),
    ];

    return ns.flat().map(v => v.notification);
}

type IDGetter<T> = (t: T) => number;

function compareProductOrder<T>(products: Array<MsoProduct>, getter: IDGetter<T>) {
    return (a: T, b: T) => {
        const prodA = products.find(p => p.id === getter(a));
        const prodB = products.find(p => p.id === getter(b));

        if (prodA == null || prodB == null) {
            return prodA === prodB ? 0 :
                prodA == null ? 1 : -1;
        }

        return prodA.displayOrder - prodB.displayOrder;
    }
}