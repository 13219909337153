import React from 'react';

import BaseSVG, { SVGProps } from './_base';

const ChevronLeft = (props: SVGProps) => {
	return (
		<BaseSVG name='chevron-left' viewBox='0 0 7.5 12' {...props}>
			<g data-name="Icon / left" transform="translate(7.5 12) rotate(180)">
				<path data-name="Chevron / Left" d="M4.521,6,0,10.513,1.489,12,7.5,6,1.489,0,0,1.487Z" fill="currentColor"/>
			</g>
		</BaseSVG>
	);
};

export default ChevronLeft;
