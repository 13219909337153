import { ReportingHistoryItem } from "./reporting-history-item";
import { FilterReportHistories } from "./use-reporting-history-filter";

interface UseReportingHistoryResultProps {
    query: FilterReportHistories,
    reportingHistoryItems: Array<ReportingHistoryItem>
}

function useReportingHistoryResult({query, reportingHistoryItems}: UseReportingHistoryResultProps) {
    return reportingHistoryItems.filter((history) => {
        if (query.reportType != null && history.submissionTypeNameExtended !== query.reportType) {
            return false;
        }

        if(query.reportEntityId != null && history.reportingOrganisationId !== query.reportEntityId){
            return false;
        }

        if (query.statusId != null && history.externalReportStatusId !== query.statusId) {
            return false;
        }

        if (query.reportFreq != null && history.reportFrequencyTypeId !== query.reportFreq) {
            return false;
        }

        if (history.type === "Pofr"){
            if (query.reportMonth != null && history.reportingMonth !== query.reportMonth) {
                return false;
            }
    
            if (query.reportYear != null && history.reportingYear !== query.reportYear) {
                return false;
            }
        }
        else if (history.type === "Mso") {
            if (history.obligationDate != null) {
                const obligationDate = new Date(history.obligationDate!);

                if (query.reportMonth != null && obligationDate.getMonth() + 1 !== query.reportMonth) {
                    return false;
                }

                if (query.reportYear != null && obligationDate.getFullYear() !== query.reportYear) {
                    return false;
                }
            }

            if (history.reportFrequencyTypeName === 'Annually') {
                if (query.reportMonth != null) {
                    return false;
                }

                if (query.reportYear && history.reportingYear !== query.reportYear) {
                    return false;
                }
            }
        }

        return true;
    });
}

export default useReportingHistoryResult;
