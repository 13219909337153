import React from 'react';
import { Link } from 'react-router-dom';

import {useUser} from 'psims/react/providers/user';
import EditUser from 'psims/react/pages/primary-pages/user-management/edit-user';
import useEditUser from 'psims/react/pages/primary-pages/user-management/use-edit-user';
import Dialog from 'psims/react/components/dialog';
import UserIcon from 'psims/react/components/icons/user';
import { Menu, MenuButton, MenuItem, MenuLink, MenuPopover } from 'psims/react/components/menu-button';
import { positionRight } from "@reach/popover";
import Text from 'psims/react/components/text';
import Button from 'psims/react/components/button';
import { useAuth } from 'psims/react/providers/auth';
import { useAppOverlay } from 'psims/react/providers/app-overlay';
import Logout from 'psims/react/components/icons/logout';
import { useOrgUsers } from 'psims/react/providers/api/org-users';
import { User as UserModel } from 'psims/models/user';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';

interface UserProps {}

const User = (props: UserProps) => {
    const { status } = useAuth();

    if (status !== 'authenticated') {
        return null;
    }

    return <AuthenticatedUser {...props} />
}; 

const AuthenticatedUser = (props: UserProps) => {
    const vm = useAuthenticatedUserVM(props);

    if (vm.user?.status === 'Registered') {
        return (
            <ActiveUser {...props} />
        )
    }
    
    return (
        <BoxedDiv box={{alignItems: 'center', flex: 'row'}}>
            <BoxedDiv box={{flex: 'column'}}>
                <Text $color='white'>{vm.user?.email}</Text>
                <Text $color='white'>{vm.user?.organisation?.name}</Text>
                <BoxedDiv box={{alignSelf: 'flex-start', marginTop: 0.5}}>
                    <Button $kind='unstyled' onClick={vm.logout}>
                        <Text $color='white'>
                            <BoxedSpan box={{alignItems:'center', flex:'row'}}>
                                <Logout size='md' />
                                &nbsp; Sign out
                            </BoxedSpan>
                        </Text>
                    </Button>
                </BoxedDiv>
            </BoxedDiv>
            
            <BoxedDiv box={{marginLeft: 4}}>
                <UserIcon color='white' size='xl' />
            </BoxedDiv>
        </BoxedDiv>
    );
}

function useAuthenticatedUserVM(props: UserProps) {
    const user = useUser();
    const { logout } = useAuth();

    return {
        logout,
        user: user.user,
        setUser: user.setUser,
        refetch: user.refetch,
    };
}

const ActiveUser = (props: UserProps) => {
    const vm = useActiveUserVM(props);
	const { setOverlay } = useAppOverlay();
    
	const onServiceSuccess = (user: UserModel) => {
		setOverlay(null);
        vm.setUser({
            ...vm.user,
            ...user,
        });
        vm.refetchOrgUsers();
	}

	const editUserCtrl = useEditUser({onSuccess: onServiceSuccess, isMyDetails: true});
    const showEditUserDialog = editUserCtrl.user !== null;

	const detailsEditable = vm.user?.status === 'Invited' || vm.user?.status === 'Registered';

    const onSelect = () => {
        if (vm.user) {
            editUserCtrl.selectUser(vm.user);
        }
    };

    return (
        <>
            <Menu>
                <MenuButton as={Button} $kind='unstyled' label='Logged in user actions'>
                    <BoxedSpan box={{alignItems: 'flex-end', flex:'row'}}>
                        <BoxedSpan box={{flex: 'column'}}>
                            <Text $align='right' $color='white'>{vm.user?.email}</Text>
                            <Text $align='right' $color='white'>{vm.user?.organisation?.name}</Text>
                        </BoxedSpan>
                        <BoxedSpan box={{marginLeft: 4}}>
                            <UserIcon color='white' size='xl' />
                        </BoxedSpan>
                    </BoxedSpan>
                </MenuButton>
                <MenuPopover portal={true} position={positionRight}>
                    <MenuItem disabled={!detailsEditable} onSelect={onSelect}>My details</MenuItem>
                    <MenuLink as={Link} to='/sign-out'>Sign out</MenuLink>
                </MenuPopover>
            </Menu>            
            <Dialog aria-label='Edit your details' isOpen={showEditUserDialog} onDismiss={editUserCtrl.cancel}>
                <EditUser ctrl={editUserCtrl} />
            </Dialog>
        </>
    );
}

function useActiveUserVM(props: UserProps) {
    const user = useUser();
    const {refetch: refetchOrgUsers} = useOrgUsers();

    return {
        user: user.user,
        setUser: user.setUser,
        refetchOrgUsers,
    };
}

export default User;
