import React, { PropsWithChildren } from 'react';
import styled from 'styled-components'

import { BoxedSpan } from '../layout';

interface DotProps {
    $color?: string;
    $size: ShirtSize;
}

const SHIRT_SIZE_MAP: {[size in ShirtSize]: string} = {
    0: '0',
    xs: '14px',
    sm: '22px',
    md: '30px',
    lg: '36px',
    xl: '50px',
    xxl: '62px',
} as const;

const Circle = styled.div`
    align-items: center;
    background-color: ${(props: DotProps) => props.$color ? `var(--color-${props.$color})}` : 'currentColor'};
    border-radius: 50%;
    display: flex;
    height: ${(props: DotProps) => SHIRT_SIZE_MAP[props.$size]};
    justify-content: center;
    width: ${(props: DotProps) => SHIRT_SIZE_MAP[props.$size]};
`;

const Dot = ({children, $color, $size}: PropsWithChildren<DotProps>) => {
    return (
        <BoxedSpan box={{alignItems: 'center', flex: 'column', justifyContent: 'center'}}>
            <Circle $color={$color} $size={$size}>
                {children}
            </Circle>
        </BoxedSpan>
    )
}

export default Dot;
