/*
    Base model for shared entity properties and assertions
*/

type MaybeEntity<T extends {}> = T & {
    id?: number | null;
    concurrencyToken?: string | null;
}

type Entity<T extends {}> = PopulatedProps<MaybeEntity<T>, 'id' | 'concurrencyToken'>;

export function isEntity<T extends {}>(maybe?: MaybeEntity<T> | null): maybe is Entity<T> {
    return maybe != null &&
           maybe.id != null &&
           maybe.concurrencyToken != null;
}
