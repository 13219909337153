import { isValidCommentCharacters, isValidCommentMaxLength } from "psims/lib/validation/comments";
import { UpdateMsoComment } from "psims/models/submission-types/mso/mso-comment";

export type ValidationResult = {
  key: 'comments';
  code: 'invalid_chars' | 'invalid_length' | 'invalid_required';
}

export function validateMsoComment(msoComment: UpdateMsoComment, isRequired: boolean): Array<ValidationResult> {
  let messages: Array<ValidationResult> = [];
  const trimmedComments = (msoComment.comments || '').trim();

  if (isRequired && trimmedComments.length === 0) {
    messages.push({
      key: 'comments',
      code: 'invalid_required',
    });
  } else if (!isValidCommentMaxLength(trimmedComments)) {
    messages.push({
      key: 'comments',
      code: 'invalid_length'
    });
  } else if (!isValidCommentCharacters(trimmedComments)) {
    messages.push({
      key: 'comments',
      code: 'invalid_chars',
    });
  }

  return messages;
}
