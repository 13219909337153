import { ProductionTypeDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

export type ProductionType = PopulatedProps<ProductionTypeDTO, 'displayOrder' | 'id' | 'name'>;

export function assertProductionType(maybe?: unknown): asserts maybe is ProductionType {
    const asProductionType = maybe as ProductionType;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asProductionType, hint('productionType'));
    assertNotEmpty(asProductionType.displayOrder, hint('productionType.displayOrder'));
    assertNotEmpty(asProductionType.id, hint('productionType.id'));
    assertNotEmpty(asProductionType.name, hint('productionType.name'));
}
