import { RefineryProductDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { RefProductGroupReferenceCode } from "./refinery-product-group";

export type RefineryProduct = PopulatedProps<RefineryProductDTO, 'id' | 'displayOrder' | 'effectiveFrom' | 'isActive' | 'productCode' | 'productId' | 'productName' | 'refineryProductGroupId' | 'refineryProductGroupName' | 'refineryProductGroupReferenceCode'> & {
    refineryProductGroupReferenceCode: RefProductGroupReferenceCode;
};

export function assertRefineryProduct(maybe?: unknown): asserts maybe is RefineryProduct {
    const asRefineryProduct = maybe as RefineryProduct;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asRefineryProduct, hint('refineryProduct'));
    assertNotEmpty(asRefineryProduct.displayOrder, hint('refineryProduct.displayOrder'));
    assertNotEmpty(asRefineryProduct.id, hint('refineryProduct.id'));
    assertNotEmpty(asRefineryProduct.productCode, hint('refineryProduct.productCode'));
    assertNotEmpty(asRefineryProduct.productId, hint('refineryProduct.productId'));
    assertNotEmpty(asRefineryProduct.productName, hint('refineryProduct.productName'));
    assertNotEmpty(asRefineryProduct.refineryProductGroupId, hint('refineryProduct.refineryProductGroupId'));
    assertNotEmpty(asRefineryProduct.refineryProductGroupName, hint('refineryProduct.refineryProductGroupName'));
}
