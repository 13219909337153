import { useCallback, useEffect, useMemo, useState } from "react";

import Checkbox from "psims/react/components/checkbox";
import { BoxedDiv } from "psims/react/components/layout";
import Text from "psims/react/components/text";
import ConfirmationDialog from "psims/react/components/confirmation-dialog";
import { ViewMode } from "../shared/use-view-mode";
import { UseFormForType } from "./use-form-for-type";
import { UseWholesalingAPI } from "./use-wholesaling-api";
import { UseWholesalingUpdateResponse } from "./use-wholesaling-update-response";

interface ToggleManualTotalsProps {
    apiCtrl: UseWholesalingAPI;
    formCtrl: UseFormForType;
    updateResponse: UseWholesalingUpdateResponse;
    viewMode: ViewMode;
}

const ToggleManualTotals = (props: ToggleManualTotalsProps) => {
    const vm = useVM(props);

    return (
        <BoxedDiv box={{marginBottom: 4}}>
            <BoxedDiv box={{flex: 'column', marginTop: 2}} >
                <Checkbox
                    disabled={vm.viewMode !== 'edit'}
                    name='manualTotals'
                    label='I want to manually enter my totals'
                    onChange={vm.handleCheckboxChange}
                    value={vm.isManualTotals}
                    shouldFocus={true}
                />
                <Text></Text>
            </BoxedDiv>

            <ConfirmationDialog
                ariaLabel={
                    vm.isManualTotals
                        ? 'Confirm you wish to switch to calculated values'
                        : 'Confirm you wish to switch to manually entered values'
                }
                body={
                    vm.isManualTotals
                        ? 'This will reset the values on this page to the calculated values from the previous pages.  Are you sure you wish to reset these values?'
                        :
                        <BoxedDiv box={{ flex: 'column' }}>
                            <p>If you proceed, this action will stop automatic calculation of totals for any wholesales entered on previous pages and you will have to manually enter the totals on this page.</p>

                            <BoxedDiv box={{marginTop: 2}}>
                                <p>Would you like to proceed with entering totals manually?</p>
                            </BoxedDiv>
                        </BoxedDiv>                    
                }
                isOpen={vm.showDialog}
                onCancel={vm.onCancel}
                onConfirm={vm.onProceed}
                title='Are you sure?'
                disableControls={vm.disableButtons}
                controlsState={vm.loadingState}
            />
        </BoxedDiv>
    );
}

type ConfirmState = 'hidden' | 'confirming';
 
function useVM({apiCtrl, formCtrl, updateResponse, viewMode}: ToggleManualTotalsProps) {
    const {submissionFormData: {manualTotals}} = formCtrl.wholesalesUpdate;
    const [confirmState, setConfirmState] = useState<ConfirmState>('hidden');
    const [isManualTotals, setIsManualTotals] = useState(manualTotals);

    const loadingState = useMemo(() => {
        return updateResponse.status === 'pending' ? 'loading' : 'normal';
    }, [updateResponse.status]);

    const showDialog = useMemo(() => {
        return confirmState !== 'hidden';
    }, [confirmState]);

    const disableButtons = useMemo(() => {
        return loadingState === 'loading';
    }, [loadingState]);

    const onCancel = useCallback(() => {
        setConfirmState('hidden');
    }, []);

    const updateManualTotals = formCtrl.updateManualTotals;

    const onProceed = useCallback(() => {
        setConfirmState('hidden');
        updateManualTotals(!isManualTotals);

        // TODO: put form into loading state, and call XAPI endpoint to update totals
        const { wholesalesUpdate, view } = formCtrl;
        if (wholesalesUpdate != null && view != null && view.wholesaleType != null) {
            apiCtrl.updateWholesale({
                dataSubmissionId: wholesalesUpdate.dataSubmissionId,
                submissionFormData: {
                    concurrencyToken: wholesalesUpdate.submissionFormData.concurrencyToken,
                    id: wholesalesUpdate.submissionFormData.id,
                    manualTotals: !isManualTotals,
                },
                wholesaleTypeId: view.wholesaleType.id,
                wholesales: [],
            });
        }
    }, [apiCtrl, formCtrl, isManualTotals, updateManualTotals]);

    const handleCheckboxChange = useCallback((val: boolean) => {
        setConfirmState('confirming');        
    }, []);

    useEffect(() => {
        setIsManualTotals(manualTotals);
    }, [manualTotals]);

    return {
        confirmState,
        disableButtons,
        isManualTotals,
        loadingState: loadingState as 'loading' | 'normal',
        showDialog,
        viewMode,
        handleCheckboxChange,
        onCancel,
        onProceed,
        updateManualTotals,
    }
}

export default ToggleManualTotals;
