import { useEffect, useMemo, useRef, useState } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import PageNotifications from '../shared/page-notifications';
import { UseRefineryProgress } from './use-refinery-progress';
import { UseRefineryForm } from './use-refinery-form';
import { UseRefineryServiceResponse } from './use-service-response';
import { mapToUserMessage } from 'psims/models/api/submission/update/record-result';
import { UseRefinerySubmit } from './use-refinery-submit';
import { ErrorUnsavedPage, InactiveProduct, InfoSameAsPreviousMessage } from 'psims/react/pages/primary-pages/data-submissions/shared/messages';
import { UseRefinerySave } from './use-refinery-save';
import { attemptScrollToSelector } from "psims/react/pages/primary-pages/data-submissions/shared/view-utils";
import { uniq } from 'psims/lib/collections';
import { SELECTOR_NOTIFICATIONS } from 'psims/constants/selectors';
import { UseRefineryRefData } from './use-refinery-ref-data';

interface RefineryNotificationsProps {
    formCtrl: UseRefineryForm;
    progressCtrl: UseRefineryProgress;
    saveCtrl: UseRefinerySave;
    serviceResponse: UseRefineryServiceResponse;
    submitCtrl: UseRefinerySubmit;
}

const RefineryNotifications = (props : RefineryNotificationsProps) => {
    const vm = useVM(props);

    return (
        <BoxedDiv box={{}} data-notifications={true}>
            {/* UI error messages */}
            {
                vm.validationMessages.length > 0 &&
                <PageNotifications kind='validation' items={vm.validationMessages.map(v => v.notification.content)} />
            }

            {/* System alerts */}
            {
                vm.systemAlerts.length > 0 &&
                <PageNotifications kind='system_alert' items={vm.systemAlerts} />
            }

            {/* Info messages */}
            {
                vm.infoMessages.length > 0 &&
                <PageNotifications kind='info' items={vm.infoMessages.map(v => v.notification.content)} />
            }

        </BoxedDiv>
    )
}

function useVM({formCtrl, progressCtrl, saveCtrl, serviceResponse, submitCtrl}: RefineryNotificationsProps) {
    const hasMessages = useRef(false);
    const [systemAlerts, setSystemAlerts] = useState<Array<string>>([]);

    const currentRefineryTypeName = useMemo<Exclude<keyof UseRefineryForm['view'], 'calculatedLossesGains' | 'hasAnyExpiredData' | 'hasUnsavedChanges' | 'isValid' | 'isDisabled' | 'totalLosses' | 'focusedField' | 'shouldForceErrors'>>(() => {
        return progressCtrl.currentStep.kind === 'data' ? progressCtrl.currentStep.refData.refineryType.name : 'Submit';
    }, [progressCtrl.currentStep]);

    const isSameAsPrevSubmission = useMemo(() => {
        return serviceResponse.submission?.dataSubmission?.validationAlerts && serviceResponse.submission?.dataSubmission?.validationAlerts.filter(a => a.validationAlert === 'SameAsPrevious').length > 0;
    }, [serviceResponse.submission?.dataSubmission?.validationAlerts]);

    const infoMessages = useMemo(() => {
        return [
            ...(currentRefineryTypeName === 'Submit' ?
                [
                    ...(isSameAsPrevSubmission ? [{ notification: { content: <InfoSameAsPreviousMessage onTargetClick={() => formCtrl.setFocusedField('comments')} /> }}] : []),
                ] :
                formCtrl.view[currentRefineryTypeName].groups
                .map(g => g.products
                    .map(p => p.infoMessages)
                    .reduce((memo, infos) => [...memo, ...infos], [])
                )
                .reduce((memo, infosArr) => [...memo, ...infosArr], []) || []
            ),
        ];
    }, [currentRefineryTypeName, formCtrl, isSameAsPrevSubmission]);

    const validationMessages = useMemo(() => {
        const commentsValidationError = (
            currentRefineryTypeName === 'Submit' ?
            formCtrl.view.Submit.commentsValidationError :
            formCtrl.view[currentRefineryTypeName].data.comments.validationError
        );

        const totalLossesValidationError = formCtrl.view.totalLosses.validationMessage;

        const expiredProductNotifications: { notification: {content: JSX.Element }}[] = [];
        
        ['Refinery Input', 'Refinery Output', 'Gases-Unfin-Petrochem-Losses']
        .forEach((t, stepIndex) => formCtrl.view[t as keyof UseRefineryRefData].groups
            .forEach(g => g.products
                .filter(p => p.productStatus === 'expired_with_data')
                .forEach(p => {
                    expiredProductNotifications.push({
                        notification: {
                            content: InactiveProduct({
                                onTargetClick: () => {
                                    saveCtrl.checkChangesAndGoToPage(stepIndex);
                                    formCtrl.setFocusedField({field: 'delete', productId: p.id});
                                },
                                label: p.productName
                            }),
                        }
                    });
                })));

        return [
            ...(currentRefineryTypeName === 'Submit' ?
                [
                    ...(submitCtrl.declarationError ? [{
                        notification: { content: submitCtrl.declarationError }
                    }] : []),
                    ...submitCtrl.unsavedPages.map(uss => ({
                            notification: {
                                content: ErrorUnsavedPage({
                                    onTargetClick: () => saveCtrl.checkChangesAndGoToPage(uss.index),
                                    label: uss.label
                                }),
                            }
                        })),
                    ...expiredProductNotifications,
                ] : [
                    ...formCtrl.view[currentRefineryTypeName].groups
                        .map(g => g.products
                            .map(p => p.validationErrors)
                            .reduce((memo, validationErrors) => [...memo, ...validationErrors], [])
                        )
                        .reduce((memo, infosArr) => [...memo, ...infosArr], []) || [],
                    ...(totalLossesValidationError != null ? [totalLossesValidationError] : []),
                ]
            ),
            ...(commentsValidationError != null ? [commentsValidationError] : [])
        ];
    }, [currentRefineryTypeName, formCtrl, saveCtrl, submitCtrl.declarationError, submitCtrl.unsavedPages]);

    useEffect(() => {
        const {serverErrorMessages, validationMessages, notOKRecordResults} = serviceResponse;

        setSystemAlerts(uniq([
            ...(serverErrorMessages == null ? [] : serverErrorMessages),
            ...(validationMessages == null ? [] : validationMessages.map(v => v.errorMessage as string)),
            ...(notOKRecordResults == null ? [] : notOKRecordResults.map(mapToUserMessage)),
        ]));
    }, [serviceResponse]);

    useEffect(() => {
        if (formCtrl.view.isValid) {
            setSystemAlerts([]);
        }
    }, [formCtrl.view.isValid])

    useEffect(() => {
        hasMessages.current = (
            infoMessages.length > 0 ||
            systemAlerts.length > 0 ||
            validationMessages.length > 0
        );
    }, [infoMessages.length, systemAlerts, validationMessages.length]);

    // Scroll when update/submit fails
    useEffect(() => {
        if ((serviceResponse.status === 'update_failed' || serviceResponse.status === 'submit_failed') && hasMessages.current) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
        }
    }, [serviceResponse.status]);

    // Scroll when system alerts length changes and there are messages
    useEffect(() => {
        if ((serviceResponse.notOKRecordResults?.length || 0) > 0 || (serviceResponse.serverErrorMessages?.length || 0) > 0) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
        }
    }, [serviceResponse.notOKRecordResults?.length, serviceResponse.serverErrorMessages?.length]);

    return {
        infoMessages,
        systemAlerts,
        validationMessages,
    };
}

export default RefineryNotifications;
