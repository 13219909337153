import { ProductionProductDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

export type ProductionProduct = Omit<PopulatedProps<
    ProductionProductDTO,
    'displayOrder' | 'effectiveFrom' | 'id' | 'isActive' | 'productCode' | 'productId' |
    'productName' | 'productionTypeId' | 'productionTypeName' | 'productionProductGroupId' |
    'productionProductGroupName' | 'productionProductGroupReferenceCode' | 'referenceCode'
>, 'name'>;

export function isProductionProduct(maybe?: unknown): maybe is ProductionProduct {
    const asProductionProduct = maybe as ProductionProduct;
    return (
        asProductionProduct != null &&
        asProductionProduct.displayOrder != null &&
        asProductionProduct.effectiveFrom != null &&
        asProductionProduct.id != null &&
        asProductionProduct.productCode != null &&
        asProductionProduct.productId != null &&
        asProductionProduct.productName != null &&
        asProductionProduct.productionTypeId != null &&
        asProductionProduct.productionTypeName != null &&
        asProductionProduct.productionProductGroupId != null &&
        asProductionProduct.productionProductGroupName != null &&
        asProductionProduct.productionProductGroupReferenceCode != null &&
        asProductionProduct.referenceCode != null
    );
}

export function assertProductionProduct(maybe?: unknown): asserts maybe is ProductionProduct {
    const asProductionProduct = maybe as ProductionProduct;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asProductionProduct, hint('productionProduct'));
    assertNotEmpty(asProductionProduct.displayOrder, hint('productionProduct.displayOrder'));
    assertNotEmpty(asProductionProduct.effectiveFrom, hint('productionProduct.effectiveFrom'));
    assertNotEmpty(asProductionProduct.id, hint('productionProduct.id'));
    assertNotEmpty(asProductionProduct.productCode, hint('productionProduct.productCode'));
    assertNotEmpty(asProductionProduct.productId, hint('productionProduct.productId'));
    assertNotEmpty(asProductionProduct.productName, hint('productionProduct.productName'));
    assertNotEmpty(asProductionProduct.productionTypeId, hint('productionProduct.productionTypeId'));
    assertNotEmpty(asProductionProduct.productionTypeName, hint('productionProduct.productionTypeName'));
    assertNotEmpty(asProductionProduct.productionProductGroupId, hint('productionProduct.productionProductGroupId'));
    assertNotEmpty(asProductionProduct.productionProductGroupName, hint('productionProduct.productionProductGroupName'));
}
