import { StockProductGroupDTO } from "psims/gen/xapi-client"
import { assertNotEmpty } from "psims/lib/empty";

// TODO: update with real referenceCode when available
const STK_GROUP_REF_CODES = [
    'STK-O-LPG',
    'STK-IA-AG',
    'STK-IA-BU',
    'STK_IA_AF',
    'STK-IA-FO',
    'STK-IA-OP',
    'STK-O-AG',
    'STK-O-D',
    'STK-O-AF',
    'STK-O-FO',
    'STK-O-BU',
    'STK-O-ODF',
    'STK-IA-ODF',
    'STK-IA-D',
    'STK-IA-LPG',
    'STK-IA-RFN',
    'STK-O-RFN',
    'STK-O-GLO',
    'STK-OW-OW',
    'STK-IA-GLO',
    'STK-O-OP',
] as const;

type StkProductGroupReferenceCode = typeof STK_GROUP_REF_CODES[number];

export type StockProductGroup = PopulatedProps<
    StockProductGroupDTO,
    'displayOrder' | 'effectiveFrom' | 'id' | 'name' | 'stockTypeId' | 'stockTypeName'
> & {
    referenceCode: StkProductGroupReferenceCode;
};

export function assertStockProductGroup(maybe?: unknown): asserts maybe is StockProductGroup {
    const asStockProductGroup = maybe as StockProductGroup;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asStockProductGroup, hint('stockProductGroup'));
    assertNotEmpty(asStockProductGroup.displayOrder, hint('stockProductGroup.displayOrder'));
    assertNotEmpty(asStockProductGroup.id, hint('stockProductGroup.id'));
    assertNotEmpty(asStockProductGroup.isActive, hint('stockProductGroup.isActive'));
    assertNotEmpty(asStockProductGroup.name, hint('stockProductGroup.name'));
    assertNotEmpty(asStockProductGroup.stockTypeId, hint('stockProductGroup.stockTypeId'));
    assertNotEmpty(asStockProductGroup.stockTypeName, hint('stockProductGroup.stockTypeName'));
}
