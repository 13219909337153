import { UseImportReferenceData } from "../use-import-reference-data";
import { ImportFunctionResult, ImportTemplateData, TemplateImporter, TemplateImportState, Tuple } from "../types";
import { getStringCellValue } from '../utils';
import { RefineryTypeName, REFINERY_TYPE_NAMES } from "psims/models/ref-data/refinery-type";
import { SAVED_PAGES_MAP } from "psims/react/pages/primary-pages/data-submissions/ref/use-refinery-steps";
import { RefiningSubmissionVM, RefiningVM } from "psims/gen/xapi-client";
import { isValidForPeriod } from "psims/models/ref-data/util";
import { HasReportingPeriod } from "psims/react/pages/primary-pages/data-submissions/shared/types";

const headerKeyColumns = ['Refinery type', 'Product group', 'Product'];

const getRefineryTypeId = (page: string, refData: UseImportReferenceData, columns: Array<Tuple<string, number | null>>, row: any[]): ImportFunctionResult => {
    const refineryType = getStringCellValue(row, columns, 'Refinery type', true);
    const result = refData.getRefineryTypeId(refineryType ?? '');

    if (result == null) {
        return {
            isSuccessful: false,
            error: {
                error: `Refinery type not found [${refineryType}]`,
                page,
            }
        };
    }

    return {
        isSuccessful: true,
        result
    };
};

const getRefineryProductId = (
    page: string,
    refData: UseImportReferenceData,
    columns: Array<Tuple<string, number | null>>,
    row: any[],
    dataSubmission: HasReportingPeriod
): ImportFunctionResult => {
    const productGroup = getStringCellValue(row, columns, 'Product group', true);
    const product = getStringCellValue(row, columns, 'Product', true);
    const refineryType = getStringCellValue(row, columns, 'Refinery type', true);
    const refineryProduct = refData.getRefineryProduct(refineryType || page, (productGroup ?? '').trim(), (product ?? '').trim());
    const refineryProductGroup = refData.getRefineryProductGroup(refineryType || page, (productGroup ?? '').trim().trim());

    if (refineryProduct == null || refineryProductGroup == null) {
        return {
            isSuccessful: false,
            error: {
                error: `Product not found [${product}]`,
                page,
            }
        };
    }

    // Check product isn't expired
    const isExpired = !(
        isValidForPeriod(refineryProductGroup, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo) &&
        isValidForPeriod(refineryProduct, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo)
    );

    if (isExpired) {
        return {
            isSuccessful: false,
            error: {
                error: `The product ${refineryProduct.productName} is not active for the reporting period.`,
                isTerminalError: true,
                page,
                recommendTemplateRequest: true,
            }
        }
    }

    const result = refineryProduct.id;

    return {
        isSuccessful: true,
        result
    };
};

const refineryImport = (): TemplateImporter<Partial<RefiningSubmissionVM>> => {
    const newSubmissionBuilder: () => Partial<RefiningSubmissionVM> = () => ({
        refinings: [],                    
        submissionFormData: {
            refineryInputsPageSaved: false,
            refineryOutputsPageSaved: false,
            gasesIntermediatePetrochemLossesSaved: false,
        }
    });

    const importStateBuilder: () => TemplateImportState<Partial<RefiningSubmissionVM>> = () => ({
        templateImportDialogState: 'processing',
        unsavedChanges: false,
        data: newSubmissionBuilder(),
    });
    
    const refineryInputs: ImportTemplateData<Partial<RefiningVM>> =  {
        dataKey: 'refinings',
        page: {
            name: 'Refining',
            headerRowKeys: headerKeyColumns,
            columns: [
                { dataKey: 'refineryTypeId', dataType: 'string', column: 'Refinery type', colFunction: getRefineryTypeId },
                { dataKey: 'refineryProductId', dataType: 'string', column: 'Product', colFunction: getRefineryProductId },
                { dataKey: 'density', dataType: 'decimal', column: 'Average density (Kg/L)', decimalPoints: 10 },
                { dataKey: 'openingStocks', dataType: 'integer', column: 'Opening stocks' },
                { dataKey: 'totalReceipts', dataType: 'integer', column: 'Total receipts (deliveries to refinery)' },
                { dataKey: 'refineryInputs', dataType: 'integer', column: 'Refinery process inputs (excluding where used as fuel)' },
                { dataKey: 'production', dataType: 'integer', column: 'Production' },
                { dataKey: 'consumed', dataType: 'integer', column: 'Consumed as fuel' },
                { dataKey: 'closingStocks', dataType: 'integer', column: 'Closing stocks' }
            ],
            data: []
        } 
    };

    const mergeRows = (importState: TemplateImportState<Partial<RefiningSubmissionVM>>, data: Array<Partial<RefiningVM>>): Array<Partial<RefiningVM>> => {
        const newData: Array<Partial<RefiningVM>> = data;
        const existingRows = importState.data?.refinings ?? [];
        existingRows.forEach(d => {
            const idx = newData.findIndex(x => (x.refineryProductId ?? 0) === (d.refineryProductId ?? 0) && (x.refineryTypeId ?? 0) === (d.refineryTypeId ?? 0));
            if (idx === -1) {
                newData.push(d);
            }
        })
        return newData;
    };

    const setStepSaved = (importState: TemplateImportState<Partial<RefiningSubmissionVM>>, stepName: any): TemplateImportState<Partial<RefiningSubmissionVM>> => {
        if (REFINERY_TYPE_NAMES.includes(stepName as RefineryTypeName) && importState.data && importState.data.submissionFormData) {
            const typeName = stepName as RefineryTypeName;
            const newSubmissionFormData = {...importState.data.submissionFormData, [SAVED_PAGES_MAP[typeName]]: true };
            let unsavedChanges = !(newSubmissionFormData.gasesIntermediatePetrochemLossesSaved === true &&
                newSubmissionFormData.refineryInputsPageSaved === true &&
                newSubmissionFormData.refineryOutputsPageSaved === true   &&
                importState.submitSaved === true                                  
            );
            if (!unsavedChanges) {
                return {
                    templateImportDialogState: 'idle',
                    unsavedChanges: false,
                    submitSaved: false,
                    data: undefined
                };
            }
            return {
                ...importState,
                data: {
                    ...importState.data,
                    submissionFormData: newSubmissionFormData
                },
                unsavedChanges: unsavedChanges
            };
        }
        if (stepName === 'Submit' && importState.data && importState.data.submissionFormData) {
            let unsavedChanges = !(importState.data.submissionFormData.gasesIntermediatePetrochemLossesSaved === true &&
                importState.data.submissionFormData.refineryInputsPageSaved === true &&
                importState.data.submissionFormData.refineryOutputsPageSaved === true
            );
            if (!unsavedChanges) {
                return {
                    templateImportDialogState: 'idle',
                    unsavedChanges: false,
                    submitSaved: false,
                    data: undefined
                };
            }
            return {
                ...importState,
                submitSaved: true
            };
        }
        return importState;
    }

    const savedSteps = (importState: TemplateImportState<Partial<RefiningSubmissionVM>>) => {
        return importState?.data?.submissionFormData;
    }

    const importer: TemplateImporter<Partial<RefiningSubmissionVM>> = {
        importStateBuilder,
        dataElements: [refineryInputs],
        mergeRows,
        savedSteps,
        setStepSaved
    }

    return importer;
};

export default refineryImport