import { useMemo } from "react";

import { SubmissionType } from "psims/models/ref-data/submission-type";
import usePortalDataAPI from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api";

interface UseDefPortalDataProps {
  submissionType: SubmissionType;
}

function useDefPortalData({submissionType}: UseDefPortalDataProps) {
  const portalDataAPICtrl = usePortalDataAPI({submissionType});
  
  const productPortalData = useMemo(() => {
    return (portalDataAPICtrl.portalData?.referenceTypeData || [])
      .filter(rtd => rtd.referenceTypeName === submissionType.name)
      .map(rtd => rtd.productData)
      .flat();
  }, [portalDataAPICtrl.portalData, submissionType.name])

  return useMemo(() => ({
    productPortalData,
  }), [productPortalData]);
}

export default useDefPortalData

export type UseDefPortalData = ReturnType<typeof useDefPortalData>;
