import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { BoxedDiv } from 'psims/react/components/layout';
import Loading from 'psims/react/components/loading';


const IndustryRedirectPage = () => {
    const history = useHistory();

    useEffect(() => {
        // Replace history so that if the user clicks the back button,
        // they will come back to portal (rather than get stuck in a
        // loop redirecting to industry home page).
        history.replace('/');
        window.location.href = 'https://www.industry.gov.au';
    }, [history]);

    return (
        <BoxedDiv box={{alignItems: 'center', flex: 'column', height: '100%'}}>
            <BoxedDiv box={{alignItems: 'center', flexGrow: 1, flex: 'column', justifyContent: 'center', marginV: 4}}>
                <Loading>Redirecting...</Loading>
            </BoxedDiv>
        </BoxedDiv>
    )
}

export default IndustryRedirectPage;
