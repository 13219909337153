import { createContext, PropsWithChildren, ReactNode, useContext, useState } from "react";
import styled from 'styled-components';

type Context = {
    overlay: ReactNode | null;
    setOverlay: (overlay: ReactNode | null) => void;
}

const context = createContext<Context | null>(null);

const Overlay = styled.div`
    align-items: center;
    background-color: var(--color-overlay);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
`;

const AppOverlayProvider = ({ children }: PropsWithChildren<{}>) => {
    const [overlay, setOverlay] = useState<ReactNode | null>(null);

    return (
        <context.Provider value={{overlay, setOverlay}}>
            {
                overlay !== null &&
                <Overlay>
                    {overlay}
                </Overlay>
            }

            {children}
        </context.Provider>
    )
}

export default AppOverlayProvider;

export function useAppOverlay() {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error('useAppOverlay must be used within AppOverlayProvider');
    }

    return {
        ...ctx,
    };
}
