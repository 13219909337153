import produce from "immer";

import { UseImportReferenceData } from "../use-import-reference-data";
import { ImportFunctionResult, ImportTemplateData, ProductionImportState, TemplateImporter, TemplateImportState, Tuple } from "../types";
import { getStringCellValue } from '../utils';
import { Production } from "psims/models/submission-types/production";
import { isValidForPeriod } from "psims/models/ref-data/util";
import { HasReportingPeriod } from "psims/react/pages/primary-pages/data-submissions/shared/types";

const headerKeyColumns = ['Production type', 'Product group', 'Product'];

const getPlantProductionProductId = (
    page: string,
    refData: UseImportReferenceData,
    columns: Array<Tuple<string, number | null>>,
    row: any[],
    dataSubmission: HasReportingPeriod
): ImportFunctionResult => {
    const productGroup = getStringCellValue(row, columns, 'Product group', true);
    const product = getStringCellValue(row, columns, 'Product', true);
    const productionType = getStringCellValue(row, columns, 'Production type', true);
    const productionProduct = refData.getProductionProduct(productionType?.trim() ?? '', productGroup?.trim() ?? '', product?.trim() ?? '');
    const productionProductGroup = refData.getProductionProductGroup(productionType?.trim() ?? '', (productGroup ?? '').trim().trim());

    if (productionProduct == null || productionProductGroup == null) {
        return {
            isSuccessful: false,
            error: {
                error: `Product not found [${product}]`,
                page,
            }
        };
    }

    // Check product isn't expired
    const isExpired = !(
        isValidForPeriod(productionProduct, dataSubmission.reportingPeriodFrom as string, dataSubmission.reportingPeriodTo as string) &&
        isValidForPeriod(productionProductGroup, dataSubmission.reportingPeriodFrom as string, dataSubmission.reportingPeriodTo as string)
    );

    if (isExpired) {
        return {
            isSuccessful: false,
            error: {
                error: `The product ${productionProduct.productName} is not active for the reporting period.`,
                page,
                recommendTemplateRequest: true,
                isTerminalError: true,
            }
        }
    }

    const result = productionProduct?.id;

    return {
        isSuccessful: true,
        result
    };
};

const getPlantProductionOrganisationProductionAreaId = (page: string, refData: UseImportReferenceData, columns: Array<Tuple<string, number | null>>, row: any[]): ImportFunctionResult => {
    const organisationProductionArea = getStringCellValue(row, columns, 'Production area', true);
    const result = refData.getOrganisationProductionAreaId(organisationProductionArea?.trim() ?? '')

    if (result == null) {
        return {
            isSuccessful: false,
            error: {
                error: `Organisation production area not found [${organisationProductionArea}]`,
                page,
            }
        };
    }

    return {
        isSuccessful: true,
        result
    };
};

const plantProductionImport = (): TemplateImporter<ProductionImportState> => {
    const newSubmissionBuilder: () => ProductionImportState = () => ({
        productions: [],
        pageData: {
            pageSaved: false,
        },
    });
    
    const importStateBuilder: () => TemplateImportState<ProductionImportState> = () => ({
        templateImportDialogState: 'processing',
        unsavedChanges: false,
        data: newSubmissionBuilder(),
    });
    
    const productionInputs: ImportTemplateData<Partial<Production>> =  {
        dataKey: 'productions',
        page: {
            name: 'Plant production',
            headerRowKeys: headerKeyColumns,
            columns: [
                { dataKey: 'productionProductId', dataType: 'string', column: 'Product', colFunction: getPlantProductionProductId },
                { dataKey: 'organisationProductionAreaId', dataType: 'string', column: 'Production Area', colFunction: getPlantProductionOrganisationProductionAreaId},
                { dataKey: 'productionDensity', dataType: 'decimal', column: 'Average density (Kg/L)', decimalPoints: 10 },
                { dataKey: 'openingStocks', dataType: 'integer', column: 'Opening stocks (Kilolitres for liquid fuels) (000m3 for natural gas)' },
                { dataKey: 'produced', dataType: 'integer', column: 'Produced at the plant (Kilolitres for liquid fuels) (000m3 for natural gas)' },
                { dataKey: 'consumed', dataType: 'integer', column: 'Liquid fuels consumed as fuel (Kilolitres) Natural gas field and plant usage (000m3)' },
                { dataKey: 'delivered', dataType: 'integer', column: 'Delivered from the plant (Kilolitres for liquid fuels) (000m3 for natural gas)' },
                { dataKey: 'closingStocks', dataType: 'integer', column: 'Closing stocks (Kilolitres for liquid fuels) (000m3 for natural gas)' },
                { dataKey: 'grossCalorificValue', dataType: 'decimal', column: 'Gross calorific value (Mj/m3)', decimalPoints: 3 }
            ],
            data: []
        } 
    };

    const mergeRows = (importState: TemplateImportState<ProductionImportState>, data: Array<Partial<Production>>): Array<Partial<Production>> => {
        const newData: Array<Partial<Production>> = data;
        const existingRows = importState.data?.productions ?? [];
        existingRows.forEach(d => {
            const rowIndex = newData.findIndex(x => (x.productionProductId ?? 0) === (d.productionProductId ?? 0) && (x.organisationProductionAreaId ?? 0) === (d.organisationProductionAreaId ?? 0));
            if (rowIndex === -1) {
                newData.push(d);
            }
        })
        return newData;
    };

    const setStepSaved = (importState: TemplateImportState<ProductionImportState>, stepName: unknown): TemplateImportState<ProductionImportState> => {
        if (stepName === 'Plant production' && importState.data && importState.data.pageData) {
            const newpageData = produce(importState.data.pageData, draft => {
                draft.pageSaved = true;
            });
            
            return {
                ...importState,
                data: {
                    ...importState.data,
                    pageData: newpageData,
                },
                unsavedChanges: false,
            };
        }
        if (stepName === 'Submit' && importState.data && importState.data.pageData) {
            const hasUnsavedChanges = !importState.data.pageData.pageSaved;

            if (!hasUnsavedChanges) {
                return {
                    templateImportDialogState: 'idle',
                    unsavedChanges: false,
                    submitSaved: false,
                    data: undefined
                };
            }
            return {
                ...importState,
                submitSaved: true
            };
        }
        return importState;
    }

    const savedSteps = (importState: TemplateImportState<ProductionImportState>) => {
        return importState?.data?.pageData;
    }

    const importer: TemplateImporter<ProductionImportState> = {
        importStateBuilder,
        dataElements: [productionInputs],
        mergeRows,
        savedSteps,
        setStepSaved
    }
    return importer;
};

export default plantProductionImport