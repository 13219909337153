import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Text from 'psims/react/components/text';
import { APP_NAME } from 'psims/constants/app';
import User from 'psims/react/blocks/user';
import Navbar from 'psims/react/blocks/navbar';
import { isWithPath, PageName, PAGES } from 'psims/constants/pages';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { ApplicationTitle } from 'psims/react/components/typography';
import VisuallyHidden from '@reach/visually-hidden';

interface GlobalHeaderProps {
	breadcrumbs?: Array<PageName>;
	hideUserBlock?: boolean;
	hideNavbar?: boolean;
};

const StyledGlobalHeader = styled(BoxedDiv)`
	& .global-header_diser-logo {
		height: 80px;
	}

	& .global-header_title {
		font-size: 24px;
	}
`;

const StyledLogoLink = styled(Link)`
	&:focus,
	&:active {
		outline: 1px solid var(--color-white);
		outline-offset: 8px;
	}
`;

const GlobalHeader = (props: GlobalHeaderProps) => {
	return (
		<header>
			<StyledGlobalHeader box={{background: 'primary', flex:'column', flexGrow: 1}}>
				<BoxedDiv box={{alignItems: 'center', flex: 'row', flexGrow: 1, justifyContent: 'space-between'}} className='container'>
					<BoxedDiv box={{alignItems: 'center', flex: 'row'}}>
						<StyledLogoLink to='/industry-redirect' title='Go to Department of Industry, Science and Resources home page'>
							<img
								alt='Go to Department of Industry, Science and Resources home page'
								className='global-header_diser-logo'
								src='/images/diser_logo_jul_2022.png' />
							
						</StyledLogoLink>

						<BoxedDiv box={{alignSelf: 'stretch', background: 'primary-mid', marginH: 3, marginV: 4, width: '1px'}}></BoxedDiv>

						<BoxedDiv box={{alignItems: 'center', flex: 'row'}}>
							<ApplicationTitle>
								{APP_NAME}
							</ApplicationTitle>
						</BoxedDiv>
					</BoxedDiv>

					<div>
						{
							!props.hideUserBlock &&
							<User />
						}
					</div>
				</BoxedDiv>

				{
					!props.hideNavbar &&
					<Navbar />
				}

				{
					props.breadcrumbs &&
					<Breadcrumbs breadcrumbs={props.breadcrumbs} />
				}

			</StyledGlobalHeader>
		</header>
	);
};

const BreadcrumbsOL = styled.ol`
	align-items: center;
	display: flex;
	justify-content: flex-start;
	list-style: none;
	padding: 0;
`;

const BreadcrumbsLI = styled.li`
	padding: 0;
`;

interface BreadcrumbsProps {
	breadcrumbs: Array<PageName>;
}

const Breadcrumbs = ({breadcrumbs}: BreadcrumbsProps) => {
	const links = [...breadcrumbs];
	const lastName = links.pop();
	const last = PAGES.find(p => p.name === lastName);
	return (
		<BoxedDiv box={{background: 'white', height: '52px', paddingV: 2}}>
			<BoxedDiv box={{flex: 'row'}} className='container'>
				<BreadcrumbsOL>
					{
						links.map(link => {
							const page = PAGES.find(p => p.name === link);

							if (page === undefined) {
								return null;
							}

							return <BreadcrumbsLI key={link}>
								<Text $size={14} weight='semibold'>
									{
										isWithPath(page) ?
										<Link to={page.path}>{page.label}</Link> :
										`${page.label}`
									}
								</Text>
								<BoxedSpan box={{marginH: 1}}><Text $color='black-80'>&gt;</Text></BoxedSpan>
							</BreadcrumbsLI>
						})
					}

					{
						last &&
						<BreadcrumbsLI>
							<VisuallyHidden><label htmlFor='breadcrumb_current'>You are here</label></VisuallyHidden>
							<Text id='breadcrumb_current' $size={14} weight='semibold'>{last.label}</Text>
						</BreadcrumbsLI>
					}
				</BreadcrumbsOL>
			</BoxedDiv>

		</BoxedDiv>
	)

}

export default GlobalHeader;
