import React from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import { UseWholesalingSubmission } from './use-wholesaling-submission';
import SubmitTerms from '../shared/submit-terms';
import Textarea from 'psims/react/components/textarea';
import { asString } from 'psims/lib/string';
import { UseFormForType } from './use-form-for-type';

interface SubmissionProps {
    formCtrl: UseFormForType;
    submitCtrl: UseWholesalingSubmission;
}

const Submission = ({formCtrl, submitCtrl}: SubmissionProps) => {
    return (
        <BoxedDiv box={{marginV: 4}}>
            <SubmitTerms
                shouldFocusDeclaration={true}
                disabled={submitCtrl.isDisabled}
                hasAgreed={submitCtrl.declaration}
                onHasAgreedChange={submitCtrl.setDeclaration}
            />

            <BoxedDiv box={{}}>
                <Textarea
                    disabled={submitCtrl.isDisabled}
                    error={submitCtrl.commentsMessage}
                    id='comments'
                    label='Comments'
                    onChange={e => submitCtrl.updateComments(e.target.value)}
                    setFocused={formCtrl.focusedField?.field === 'comments'}
                    value={asString(submitCtrl.comments)}
                />
            </BoxedDiv>

        </BoxedDiv>
    )

}

export default Submission;
