import { ReportingObligationVM } from "psims/gen/xapi-client";
import { DataSubmissionTypeName, isSubmissionTypeName } from "./data-submission";

export type ReportingObligation = PopulatedProps<
	ReportingObligationVM,
	'id' | 'reportFrequencyTypeId' | 'reportFrequencyTypeName' | 'submissionTypeId' | 'submissionTypeName'
> & {
	submissionTypeName: DataSubmissionTypeName;
};

export function isArrayOfReportObligation(maybeArrayOfReportOblgation?: unknown): maybeArrayOfReportOblgation is Array<ReportingObligation> {
	const asArrayOfReportObligation = maybeArrayOfReportOblgation as Array<ReportingObligation>;

	return Array.isArray(asArrayOfReportObligation);
}

export function isReportingObligation(maybe: unknown): maybe is ReportingObligation {
	const maybeAs = maybe as ReportingObligation;

	return (
		maybeAs != null &&
		maybeAs.id != null &&
		maybeAs.reportFrequencyTypeId != null &&
		maybeAs.reportFrequencyTypeName != null &&
		maybeAs.submissionTypeId != null &&
		isSubmissionTypeName(maybeAs.submissionTypeName)
	);
}
