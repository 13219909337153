/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PortalReferenceDataVMApiResponse } from '../models/PortalReferenceDataVMApiResponse';
import type { SubmissionPortalDataVMApiResponse } from '../models/SubmissionPortalDataVMApiResponse';
import type { UpdatePortalReferenceDataVM } from '../models/UpdatePortalReferenceDataVM';
import { request as __request } from '../core/request';

export class PortalDataService {

    /**
     * @returns SubmissionPortalDataVMApiResponse Success
     * @throws ApiError
     */
    public static async getForSubmissionType({
submissionTypeId,
}: {
submissionTypeId?: number,
}): Promise<SubmissionPortalDataVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/portal-data/get-for-submission-type`,
            query: {
                'submissionTypeId': submissionTypeId,
            },
        });
        return result.body;
    }

    /**
     * @returns PortalReferenceDataVMApiResponse Success
     * @throws ApiError
     */
    public static async updatePortalData({
requestBody,
}: {
requestBody?: UpdatePortalReferenceDataVM,
}): Promise<PortalReferenceDataVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/portal-data/update`,
            body: requestBody,
        });
        return result.body;
    }

}