import { useState } from "react";

interface UseFiltersProps {
    initialShowExpired: boolean;
}

function useFilters({initialShowExpired}: UseFiltersProps) {
    const [showExpired, setShowExpired] = useState(initialShowExpired);

    function toggleShowExpired() {
        setShowExpired(!showExpired);
    }

    return {
        showExpired,
        toggleShowExpired,
    };
}

export default useFilters

export type UseFilters = ReturnType<typeof useFilters>;
