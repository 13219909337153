import { SPACE_BASE } from 'psims/constants/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import ArrowRight from './icons/arrow-right';
import { BoxedDiv } from './layout';

interface ULProps {
    padding?: string;
}

const UL = styled.ul`${({padding}: ULProps) => `
    padding-left: ${padding != null ? padding : '20px'};
`}`;

export const LI = styled.li`
    padding-left: 8px;
`;

interface ArrowLIProps extends React.HTMLAttributes<HTMLLIElement> {
    color?: 'negative' | 'primary';
}

const StyledArrowLI = styled(LI)`
    line-height: 28px;
    list-style: none;
    margin-bottom: ${SPACE_BASE * 2}px;

    & svg.icon-arrow-right {
        position: absolute;
        left: 0px;
        top: 7px;
    }
`;

const StyledNoMarkerLI = styled(LI)`
    line-height: 28px;
    list-style: none;
`;

export const ArrowLI = ({children, color, ...rest}: PropsWithChildren<ArrowLIProps>) => {
    return (
        <StyledArrowLI {...rest}>
            <BoxedDiv box={{alignItems: 'flex-start', flex: 'row'}}>
                <ArrowRight size='sm' color={color === 'negative' ? 'warning' : 'primary'} />

                <BoxedDiv box={{marginLeft: 3.5}}>
                    {children}
                </BoxedDiv>
            </BoxedDiv>
        </StyledArrowLI>
    )
}

export const NoMarkerLI = ({children, color, ...rest}: PropsWithChildren<ArrowLIProps>) => {
    return (
        <StyledNoMarkerLI {...rest}>
            <BoxedDiv box={{alignItems: 'flex-start', flex: 'row'}}>
                <BoxedDiv box={{marginLeft: 0}}>
                    {children}
                </BoxedDiv>
            </BoxedDiv>
        </StyledNoMarkerLI>
    )
}

export default UL;
