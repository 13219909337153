import produce from "immer";
import { INFO_NEGATIVE_VALUE_COMMENTS, INFO_PREVIOUSLY_REPORTED_COMMENTS_WHOLESALES, INFO_REPORT_VARIANCE } from "psims/constants/info-messages";
import { INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH, INVALID_COMMENTS_REQUIRED, INVALID_PRODUCTION_EXPIRED } from "psims/constants/validation-messages";
import { findWithIndex, sumByKey, toDictionary } from "psims/lib/collections";
import { isEmpty } from "psims/lib/empty";
import { asNumber } from "psims/lib/number";
import { RecordAction, recordActionAsEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { ValidationAlert } from "psims/models/api/submission/validation-alert";
import { WholesaleProduct } from "psims/models/ref-data/wholesale-product";
import { ReportVariancesVM } from "psims/gen/xapi-client";
import { UpdateWholesale, UpdateWholesaleComment, updateWholesaleToWholesaleData, Wholesale, WholesaleField, WholesaleSubmission } from "psims/models/submission-types/wholesaling";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CommentsErrorMessage, CommentsRequiredErrorMessage, ErrorExpiredProduct, InfoNegativeValueMessage, InfoPreviouslyReportedMessage, InfoReportVarianceMessage, QuantityInvalidErrorMessage } from "psims/react/pages/primary-pages/data-submissions/shared/messages";
import useFocusedField from "psims/react/util/use-focused-field";
import { validateQuantity } from "psims/react/pages/primary-pages/data-submissions/shared/validation";
import { InfoLessThanSumMessage } from "./messages";
import { FocusField, updateWholesaleSubmissionFromSubmission } from "./shared";
import { SAVED_PAGES_MAP, UseWholesalingSteps } from "./use-wholesaling-steps";
import { ChangedState } from "psims/react/pages/primary-pages/data-submissions/shared/save-state";
import { isValidCommentCharacters, isValidCommentMaxLength } from "psims/lib/validation/comments";
import { Message } from "psims/react/pages/primary-pages/data-submissions/shared/field-messages";
import { UseTemplateImport } from "psims/react/blocks/import/use-template-import";
import { WholesaleTypeName, WHOLESALE_TYPE_NAMES } from "psims/models/ref-data/wholesale-type";
import { isValidForPeriod, IsWholesaleProductExpiredInTotalPage } from "psims/models/ref-data/util";
import { EntityStatus } from "../shared/types";
import { DeleteRequestState, idleDeleteRequest } from "../shared/delete-confirmation";
import { focusNext } from "psims/lib/focus-util";

export type TotalInfoMessage = {
    field: 'Total',
    reason: 'variance' | 'other';
}

type InfoMessage = Message<WholesaleField | TotalInfoMessage>;

type ErrorMessage = Message<WholesaleField | 'Comments' | 'delete'>;

interface UseFormForTypeProps {
    stepCtrl: UseWholesalingSteps;
    submission: WholesaleSubmission;
    importCtrl: UseTemplateImport<Partial<WholesaleSubmission>>;
}

const isStepSaved = (data: Partial<WholesaleSubmission>, stepName: any) => {
    let saved = false;
    if (WHOLESALE_TYPE_NAMES.includes(stepName as WholesaleTypeName) && data && data.submissionFormData) {
        const typeName = stepName as WholesaleTypeName;
        const stepSaved = data.submissionFormData[SAVED_PAGES_MAP[typeName]];

        saved = stepSaved !== undefined && stepSaved != null && stepSaved === true ? true : false;
    }
    return saved;
}

function useFormForType({stepCtrl, submission, importCtrl}: UseFormForTypeProps) {
    const {focusedField, setFocusedField} = useFocusedField<FocusField | null>();
    const [changedState, setChangedState] = useState<ChangedState>('unchanged');
    const changedIDs = useRef(new Set<number>());
    const [deleteRequestState, setDeleteRequestState] = useState<DeleteRequestState>(idleDeleteRequest);

    const {currentStep: {refData: typeRefData}} = stepCtrl;

    const wholesaleType = typeRefData?.wholesaleType;

    const [wholesalesUpdate, setWholesalesUpdate] = useState(
        updateWholesaleSubmissionFromSubmission(submission, typeRefData)
    );

    const dataSubmission = useMemo(() => ({
        reportingPeriodFrom: submission.dataSubmission.reportingPeriodFrom,
        reportingPeriodTo: submission.dataSubmission.reportingPeriodTo
    }), [
        submission.dataSubmission.reportingPeriodFrom,
        submission.dataSubmission.reportingPeriodTo
    ]);

    const deleteProductRow = useCallback((wholesaleProductId: number | undefined, source: HTMLElement | null) => {
        setDeleteRequestState({
            deleteState: 'showing_dialog',
            id: wholesaleProductId,
            message: 'The Wholesaling record for this product will be deleted.',
            source,
        });
    }, []);

    // Construct dictionary of wholesale row updates keyed by product ID to prevent excessive array searching
    const wholesalesUpdateDictionary = useMemo(
        () => {
            return (wholesalesUpdate == null ?
                null :
                toDictionary(
                    wholesalesUpdate.wholesales,
                    'wholesaleProductId'
                )
            );
        },
        [wholesalesUpdate]
    );

    // Construct dictionary of wholesale rows of underlying submission keyed by db id
    const submissionWholesalesDictionary = useMemo(
        () => {
            return (submission == null ?
                null :
                toDictionary(submission?.wholesales, 'id')
            );
        },
        [submission]
    );

    const IsProductExpiredInTotalPage = useCallback((product: WholesaleProduct) => {
        let groupsInTotalPage = stepCtrl.steps
            .find(s => s.kind === 'Total')?.refData?.groups;
        if (groupsInTotalPage != null && groupsInTotalPage.length > 0) {
            //Check if the product expired in the Total page
            let isProductExpiredInTotalPage = IsWholesaleProductExpiredInTotalPage(groupsInTotalPage.flatMap(g => g.products), groupsInTotalPage.flatMap(g => g.productGroup), product.genericProductCode, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo) ;
            return isProductExpiredInTotalPage;
        }
        else
            return false;
    }, [dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo, stepCtrl.steps]);
    
    const view = useMemo(() => {
        if (typeRefData == null || wholesalesUpdateDictionary == null || wholesalesUpdate == null) {
            // TODO: handle Submit page - here or separately?
            return null;
        }

        let isPageValid = true;
        let commentsRequired = false;

        const wholesalesView = {
            groups: typeRefData.groups
            .map(g => ({
                ...g,
                isExpired: !isValidForPeriod(g.productGroup, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo),
            }))
            .map((g, groupIndex) => {
                let reportVarianceWholesaleType = ReportVarianceForWholesaleType(submission.dataSubmission.reportVariances, g.productGroup.wholesaleTypeName);
                let australiaTotal = 0;
                let foundFirstActiveRow = false;
                let hasExpiredWithData = false;
                let hasNonEmpty = false;
                const products = g.products
                .map(p => {
                    let productUpdate = wholesalesUpdateDictionary[p.id] || {};
                    let isProductExpiredInTotalPage = g.productGroup.wholesaleTypeName !== 'Total' ?
                        IsProductExpiredInTotalPage(p) :
                        false;

                    const isExpired = g.isExpired ||
                        isProductExpiredInTotalPage ||
                        !isValidForPeriod(p, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo);
                    
                    let isFirstRow = false;
                    if (!isExpired && !foundFirstActiveRow && groupIndex === 0) {
                        isFirstRow = true;
                        foundFirstActiveRow = true;
                    }

                    let wholesaleForProduct: Wholesale | null = null;
                    for (const key in submissionWholesalesDictionary) {
                        let value = submissionWholesalesDictionary[Number(key)];
                        if (value.wholesaleProductId === p.id) {
                            wholesaleForProduct = value;
                            break;
                        }
                    }
                    const validationAlerts = wholesaleForProduct != null ? wholesaleForProduct.validationAlerts : [];
                    const total = totalForProduct(productUpdate);                    
                    australiaTotal += total;
                    const skipValidation = g.productGroup.wholesaleTypeName === 'Total' && !wholesalesUpdate.submissionFormData.manualTotals;

                    const productStatus: EntityStatus = !isExpired ? 'active' :
                        (isEmpty(productUpdate) || productUpdate.id === undefined || productUpdate.id == null|| recordActionFromEnum(productUpdate.recordAction) === 'Delete') ?
                            'expired' : 'expired_with_data';

                    if (productStatus === 'expired_with_data') {
                        hasNonEmpty = true;
                        hasExpiredWithData = true;
                    } 
                    if (productStatus === 'active') {
                        hasNonEmpty = true;
                    } 

                    const validationErrors = 
                        skipValidation ?
                        [] :
                        validationMessagesForWholesale(productUpdate, productStatus, setFocusedField, p);

                    const infoMessages = infoMessagesForWholesale(
                        productUpdate,
                        setFocusedField,
                        p,
                        validationAlerts as Array<ValidationAlert>,
                        changedIDs.current,
                        reportVarianceWholesaleType
                    );
                    isPageValid = isPageValid && validationErrors.length === 0;
                    commentsRequired = commentsRequired || infoMessages.length > 0;

                    return {
                        ...p,
                        isFirstRow,
                        formData: {
                            ...productUpdate,
                            wholesaleProductId: p.id,
                        },
                        infoMessages,
                        isExpired,
                        validationAlerts,
                        validationErrors,
                        total,
                        productStatus
                    }
                });

                return {
                ...g,
                products,
                groupStatus: g.isExpired ?
                    hasExpiredWithData ? 'expired_with_data' : 'expired' : 
                    hasNonEmpty ? 'active' : 'empty' as EntityStatus | 'empty',
                totals: {
                    australia: australiaTotal, 
                    nsw: sumByKey(products.map(x => x.formData), 'nswWholesaleVolume'),
                    nt: sumByKey(products.map(x => x.formData), 'ntWholesaleVolume'),
                    qld: sumByKey(products.map(x => x.formData), 'qldWholesaleVolume'),
                    sa: sumByKey(products.map(x => x.formData), 'saWholesaleVolume'),
                    tas: sumByKey(products.map(x => x.formData), 'tasWholesaleVolume'),
                    vic: sumByKey(products.map(x => x.formData), 'vicWholesaleVolume'),
                    wa: sumByKey(products.map(x => x.formData), 'waWholesaleVolume'),
                }
            }
                
            })
        };

        const commentsVal = (wholesalesUpdate.wholesaleComment?.comments || '').trim();
        const commentsValidationError = commentsRequired && (commentsVal || '').length === 0 ? {
            notification: {
                content: CommentsRequiredErrorMessage({
                    onTargetClick: () => setFocusedField({field: 'comments'}),
                }),
                message: INVALID_COMMENTS_REQUIRED,
            }
        } : !isValidCommentMaxLength(commentsVal) ? {
            notification: {
                content: CommentsErrorMessage({
                    onTargetClick: () => setFocusedField({field: 'comments'}),
                }),
                message: INVALID_COMMENTS_LENGTH,
            }
        } : !isValidCommentCharacters(commentsVal) ? {
            notification: {
                content: CommentsErrorMessage({
                    onTargetClick: () => setFocusedField({field: 'comments'}),
                }),
                message: INVALID_COMMENTS_CHARACTERS,
            }
        } : null;         
        
        isPageValid = isPageValid && commentsValidationError === null;

        const commentsView = {
            formData: {
                value: wholesalesUpdate.wholesaleComment?.comments,
            },
            validationError: commentsValidationError,
        };

        return {
            comments: commentsView,
            focusedField,
            isPageValid,
            shouldForceErrors: importCtrl.templateImportState.templateImportDialogState === 'processing',
            wholesales: wholesalesView,
            wholesaleType,
        };
    }, [
        dataSubmission,
        focusedField,
        importCtrl.templateImportState.templateImportDialogState,
        IsProductExpiredInTotalPage,
        setFocusedField,
        submission,
        submissionWholesalesDictionary,
        typeRefData,
        wholesalesUpdate,
        wholesalesUpdateDictionary,
        wholesaleType
    ]);

    const updateWholesale = useCallback(
        <K extends keyof UpdateWholesale>(
            wholesaleProductId: number, field: K, value: string
        ) => {
        if (wholesalesUpdate == null) {
            return
        }

        // Compare update to persisted data before triggering unsaved change
        const serverWholesales = submission.wholesales.find(w => w.wholesaleProductId === wholesaleProductId);
        if (serverWholesales) {
            const serverValue = serverWholesales[field as keyof Wholesale];
            const vNum = value as unknown as number;
            if (vNum !== serverValue) {
                setChangedState('unsaved_changes');
            }
        } else if (value.length > 0) {
            setChangedState('unsaved_changes');
        }

        changedIDs.current.add(wholesaleProductId);

        setWholesalesUpdate(prev => produce(prev, draft => {
            const [wholesale, index] = findWithIndex(prev!!.wholesales, w => w.wholesaleProductId === wholesaleProductId);

            let nextWholesale = {
                ...(wholesale || {}),
                wholesaleProductId,
                [field]: value.replaceAll(',', ''),
            };

            const action = recordActionForWholesaleUpdate(nextWholesale);

            if (action === null) {
                draft.wholesales = draft.wholesales.filter((w, i) => i !== index) as Array<UpdateWholesale>;
                return;
            }

            nextWholesale = {
                ...nextWholesale,
                recordAction: recordActionAsEnum(action),
            };

            if (index === -1) {
                draft.wholesales.push(nextWholesale);
            } else {
                draft.wholesales[index] = nextWholesale;
            }
        }));
    }, [submission.wholesales, wholesalesUpdate]);

    const updateComment = useCallback((comments: string | undefined) => {
        if (wholesaleType?.id == null || wholesalesUpdate == null) {
            return;
        }

        setChangedState('unsaved_changes');

        setWholesalesUpdate(prev => produce(prev, draft => {
            if (prev == null || draft == null) {
                return;
            }

            const nextComment = {
                ...(prev.wholesaleComment || {}),
                wholesaleTypeId: wholesaleType.id,
                comments,
            }

            const action = recordActionForCommentUpdate(nextComment);

            if (action === null) {
                draft.wholesaleComment = undefined;
                return;
            }
        
            draft.wholesaleComment = {
                ...nextComment,
                recordAction: recordActionAsEnum(action),
            };
        }));
    }, [wholesalesUpdate, wholesaleType?.id]);

    const updateManualTotals = useCallback((val: boolean) => {
        setChangedState('unsaved_changes');

        setWholesalesUpdate(prev => produce(prev, draft => {
            draft.submissionFormData.manualTotals = val;
        }));
    }, [])

    useEffect(() => {
        let isImportStepSaved = false;
        if (importCtrl.templateImportState.data) {
            isImportStepSaved = isStepSaved(importCtrl.templateImportState.data, stepCtrl.currentStep.typeName as string)
        }
        if (importCtrl.templateImportState.data && importCtrl.templateImportState.data.wholesales && typeRefData != null &&
                importCtrl.templateImportState.templateImportDialogState === 'processing' && !isImportStepSaved) {    
            changedIDs.current.clear();
            let changed = !isImportStepSaved;
            const result = updateWholesaleSubmissionFromSubmission(submission, typeRefData);
            const submissionData = result.wholesales; 
            const submissionCommentData = result.wholesaleComment;     

            const importWholesales = (importCtrl.templateImportState.data.wholesales || [])
                .filter(w => w.wholesaleTypeId === stepCtrl.currentStep.refData?.wholesaleType.id);

            if (stepCtrl.currentStep.typeName === 'Total' && importWholesales.length > 0) {
                result.submissionFormData.manualTotals = true;
            }

            result.wholesales = [];
            typeRefData.groups?.forEach(g => {
                g.products.forEach(p => {                    
                    let data = importWholesales?.find(w => w.wholesaleProductId === p.id);
                    let existingData = submissionData?.find(w => w.wholesaleProductId === p.id);
                    
                    if (data !== undefined) {      
                        changed = true;          
                        if (existingData !== undefined) {
                            result.wholesales.push({
                                ...data,
                                id: existingData.id == null ? undefined : existingData.id,
                                concurrencyToken: existingData.concurrencyToken == null ? undefined : existingData.concurrencyToken,
                                recordAction: recordActionAsEnum('Update')
                            });
                        } else {
                            result.wholesales.push({
                                ...data,
                                recordAction: recordActionAsEnum('Create')
                            });
                        }
                    } else {
                        if (existingData !== undefined) {
                            // Don't delete existing rows for calculated totals
                            if (stepCtrl.currentStep.typeName === 'Total' && !result.submissionFormData.manualTotals) {
                                result.wholesales.push({
                                    ...existingData,
                                })
                            } else {
                                result.wholesales.push({
                                    ...existingData,
                                    nswWholesaleVolume: null,
                                    vicWholesaleVolume: null,
                                    qldWholesaleVolume: null,
                                    saWholesaleVolume: null,
                                    waWholesaleVolume: null,
                                    tasWholesaleVolume: null,
                                    ntWholesaleVolume: null,
                                    recordAction: recordActionAsEnum('Delete')
                                });
                            }
                        }
                    }
                })
            });

            if (submissionCommentData !== undefined)
            {
                result.wholesaleComment = {
                    ...submissionCommentData,
                    comments: '',
                    recordAction:  recordActionAsEnum('Delete')
                }
            }
                        
            setChangedState(changed ? 'unsaved_changes' : 'unchanged');
            setWholesalesUpdate(result);

        } else {
            const nextData = updateWholesaleSubmissionFromSubmission(submission, typeRefData);
            if (nextData != null) {
                setChangedState('unchanged');
                changedIDs.current.clear();
                setWholesalesUpdate(nextData);
            }
        }

    }, [
        importCtrl.savedSteps,
        importCtrl.templateImportState.data,
        importCtrl.templateImportState.templateImportDialogState,
        stepCtrl.currentStep.typeName,
        stepCtrl.currentStep.refData?.wholesaleType.id,
        submission,
        typeRefData
    ]);
    
    const actionDeleteProductRow = useCallback((wholesaleProductId: number | undefined) => {
        if (wholesaleProductId == null) {
            return;
        }
        if (wholesalesUpdate == null) {
            return
        }

        setChangedState('unsaved_changes');
        
        setWholesalesUpdate(prev => produce(prev, draft => {            
            const [wholesale, index] = findWithIndex(prev!!.wholesales, w => w.wholesaleProductId === wholesaleProductId);

            if (index === -1 || (wholesale?.recordAction !== undefined && wholesale.recordAction === recordActionAsEnum('Delete'))) {
                return;                
            }

            let nextWholesale = {
                ...(wholesale || {}),
                wholesaleProductId,
                recordAction: recordActionAsEnum('Delete')
            };

            draft.wholesales[index] = nextWholesale;            
        }));
    }, [wholesalesUpdate]);

    useEffect(() => {
        switch (deleteRequestState.deleteState) {
            case 'cancelled':
                setDeleteRequestState(idleDeleteRequest);
                break;
            case 'confirmed':
                if (deleteRequestState.id !== undefined) {
                    actionDeleteProductRow(deleteRequestState.id);
                    
                    if (deleteRequestState.source != null) {
                        focusNext(deleteRequestState.source);
                    }
                }
                setDeleteRequestState(idleDeleteRequest);
                break;
        }
    }, [actionDeleteProductRow, deleteRequestState]);

    return {
        changedState,
        focusedField,
        wholesalesUpdate,
        view,
        setFocusedField,
        updateComment,
        updateManualTotals,
        updateWholesale,
        deleteProductRow,
        deleteRequestState,
        setDeleteRequestState
    }
}

export default useFormForType;

export type UseFormForType = ReturnType<typeof useFormForType>;

function totalForProduct(product: UpdateWholesale) {
    return (
        asNumber(product.nswWholesaleVolume) +
        asNumber(product.vicWholesaleVolume) +
        asNumber(product.qldWholesaleVolume) +
        asNumber(product.saWholesaleVolume) +
        asNumber(product.waWholesaleVolume) +
        asNumber(product.tasWholesaleVolume) +
        asNumber(product.ntWholesaleVolume)
    );
}

function recordActionForWholesaleUpdate(update: UpdateWholesale): RecordAction | null {
    const updateIsPopulated = !isEmptyUpdateWholesale(update);
    const updateIsPersisted = update?.id != null;

    if (updateIsPersisted) {
        return updateIsPopulated ? 'Update' : 'Delete';
    }

    // null handles case where user clears comments field after entering comments, but never saved
    return updateIsPopulated ? 'Create' : null;
}

function recordActionForCommentUpdate(update: UpdateWholesaleComment): RecordAction | null {
    const updateIsPopulated = Boolean(update.comments);
    const updateIsPersisted = update?.id != null;

    if (updateIsPersisted) {
        return updateIsPopulated ? 'Update' : 'Delete';
    }

    // null handles case where user clears comments field after entering comments, but never saved
    return updateIsPopulated ? 'Create' : null;
}

function isEmptyUpdateWholesale(wholesale: UpdateWholesale) {
    return isEmpty(updateWholesaleToWholesaleData(wholesale));
}

const fieldDisplayOrder: {[field in WholesaleField]: number} = {
    nswWholesaleVolume: 1,
    vicWholesaleVolume: 2,
    qldWholesaleVolume: 3,
    saWholesaleVolume: 4,
    waWholesaleVolume: 5,
    tasWholesaleVolume: 6,
    ntWholesaleVolume: 7,
};

function validationMessagesForWholesale(wholesale: UpdateWholesale, productStatus: EntityStatus, onClick: (field: FocusField) => any, product: WholesaleProduct): Array<ErrorMessage> {
    const data = updateWholesaleToWholesaleData(wholesale);
    const validationMessages: Array<ErrorMessage> = [];
    const entries = Object.entries(data);
    
    let fieldIndex = 0;

    entries.forEach(([f, value]) => {
        const field = f as WholesaleField;
        
        if (productStatus === 'expired_with_data' && fieldIndex === 0) {
            validationMessages.push({
                notification: {
                    content: ErrorExpiredProduct({
                        label: product.productName,
                        onTargetClick: () => window.setTimeout(
                            onClick({wholesaleProductId: product.id, field: 'delete'}),
                            0
                        ),
                    }),
                },
                tooltip: {
                    target: 'delete',
                    content: INVALID_PRODUCTION_EXPIRED,
                }
            });
        }
        
        fieldIndex = fieldIndex + 1;

        const msg = validateQuantity(value);
        if (msg != null) {
            const state = field.replace('WholesaleVolume', '').toUpperCase();
            const label = `${product.productName} in the ${state} column`;
            validationMessages.push({
                notification: {
                    content: QuantityInvalidErrorMessage({
                        label: label,
                        onClick: () => onClick({wholesaleProductId: product.id, field})
                    }),
                },
                tooltip: {
                    target: field,
                    content: msg,
                }
            });
        }
    })

    return validationMessages.sort((a, b) => fieldDisplayOrder[a.tooltip.target as WholesaleField] - fieldDisplayOrder[b.tooltip.target as WholesaleField]);
}

function ReportVarianceForWholesaleType(reportVariances: ReportVariancesVM | undefined, wholesaleType: string): number | null | undefined {
    let reportVariance =
        wholesaleType === "Wholesalers" ? reportVariances?.wholesalers
            : wholesaleType === "Other wholesales" ? reportVariances?.otherWholesales
                : wholesaleType === "Bulk" ? reportVariances?.bulkWholesale
                    : wholesaleType === "Total" ? reportVariances?.totalWholesale
                        : reportVariances?.retailersWholesale;
    return reportVariance;
}

function infoMessagesForWholesale(
    wholesale: UpdateWholesale,
    onClick: (field: FocusField) => any,
    product: WholesaleProduct,
    validationAlerts: Array<ValidationAlert>,
    changedIDs: Set<number>,
    variance?: number | null
): Array<InfoMessage> {
    const data = updateWholesaleToWholesaleData(wholesale);
    const infoMessages: Array<InfoMessage> = [];
    const entries = Object.entries(data);
    let total = 0;

    // Negative value info messages/tooltips
    entries.forEach(([f, value]) => {
        const field = f as WholesaleField;
        const v = asNumber(value);
        total += v;
        if (v < 0) {
            const state = field.replace('WholesaleVolume', '').toUpperCase();
            const label = `${product.productName} in the ${state} column`;
            infoMessages.push({
                notification: {
                    content: InfoNegativeValueMessage({
                        label: label,
                        onCommentTargetClick: () => onClick({field: 'comments'}),
                        onTargetClick: () => {
                            onClick({wholesaleProductId: product.id, field});
                        }
                    })
                },
                tooltip: {
                    content: INFO_NEGATIVE_VALUE_COMMENTS,
                    target: field,
                }
            });
        }
    });

    // Previously reported info/tooltips
    if (
        validationAlerts.find(va => va.validationAlert === 'PreviouslyReportedAlert') &&
        total === 0
    ) {
        infoMessages.push({
            notification: {
                content: InfoPreviouslyReportedMessage({
                    label: product.productName,
                    onCommentTargetClick: () => onClick({field: 'comments'}),
                    onTargetClick: () => {
                        onClick({wholesaleProductId: product.id, field: 'nswWholesaleVolume'});
                    },
                    reportingTypeLabel: 'wholesales'
                })
            },
            tooltip: {
                content: INFO_PREVIOUSLY_REPORTED_COMMENTS_WHOLESALES,
                target: {
                    field: 'Total',
                    reason: 'other'
                },
            }
        });
    }

    // Variance info/tooltips
    if (
        validationAlerts.find(va => va.validationAlert === 'PercentVarianceApplied') &&
        variance != null &&
        recordActionFromEnum(wholesale.recordAction) !== 'Update'
    ) {
        infoMessages.push({
            notification: {
                content: InfoReportVarianceMessage({
                    label: product.productName,
                    onCommentTargetClick: () => onClick({field: 'comments'}),
                    onTargetClick: () => {
                        onClick({wholesaleProductId: product.id, field: 'nswWholesaleVolume'});
                    },
                    variance,
                })
            },
            tooltip: {
                content: INFO_REPORT_VARIANCE(variance),
                target: {
                    field: 'Total',
                    reason: 'variance',
                }
            }
        });
    }

    // Less than sum of breakdown info/tooltips
    const sumAlert = validationAlerts.find(isTotalsValidationAlert);
    if (sumAlert != null && !changedIDs.has(wholesale.wholesaleProductId)) {
        infoMessages.push({
            notification: {
                content: InfoLessThanSumMessage({
                    label: product.productName,
                    onCommentTargetClick: () => onClick({field: 'comments'}),
                    onTargetClick: () => onClick({field: 'nswWholesaleVolume', wholesaleProductId: product.id}),
                })
            },
            tooltip: {
                content: sumAlert.message,
                target: {
                    field: 'Total',
                    reason: 'other',
                },
            }
        });
    }

    return infoMessages;
}

function isTotalsValidationAlert(va: ValidationAlert) {
    return va.validationAlert === 'LessThanOtherWholesalesAlert' || va.validationAlert === 'LessThanSumOfBreakdownAlert';
}
        

