import { DocumentStatusDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { prefixedThrower } from "psims/lib/exception";

const TYPE_NAMES = [
  'Compliance plan',
  'Enforceable arrangements',
] as const;

type DocumentTypeName = typeof TYPE_NAMES[number];

export type DocumentType = PopulatedProps<DocumentStatusDTO,  'id' | 'name'> & {
  name: DocumentTypeName
};

export function assertDocumentTypeName(maybe?: unknown): asserts maybe is DocumentTypeName {
  const maybeAs = maybe as DocumentTypeName;

  const err = prefixedThrower(`Failed to assert documentTypeName: ${JSON.stringify(maybe)}: `);

  if (!TYPE_NAMES.includes(maybeAs)) err(`unknown type name: ${maybeAs}`);
}

export function assertDocumentType(maybe?: unknown): asserts maybe is DocumentType {
  const maybeAs = maybe as DocumentType;

  const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

  assertNotEmpty(maybeAs, hint('documentType'));
  assertNotEmpty(maybeAs.id, hint('documentType.id'));
  assertNotEmpty(maybeAs.name, hint('documentType.name'));
  assertDocumentTypeName(maybeAs.name);
}

export function isDocumentType(maybe?: unknown): maybe is DocumentType {
  assertDocumentType(maybe);
  return true;
}
