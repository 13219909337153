import styled, { css } from "styled-components";
import React, { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import randomID from "psims/lib/random-id";
import FloatingMessage from "psims/react/components/floating-message";
import { BoxedDiv, BoxedSpan } from "psims/react/components/layout";
import useDisplayValue from "psims/react/util/use-display-value";
import { useIsFocusedAlt as useIsFocused } from "psims/react/util/use-is-focused";
import useFocusable from "psims/react/util/use-focusable";
import useSelectAllOnFocus from "psims/react/util/use-select-all-on-focus";
import { TooltipHelp } from "psims/react/pages/primary-pages/data-submissions/shared/tooltip-help";
import Text from "psims/react/components/text";
import useIsDirty from "psims/react/util/use-is-dirty";
import Select, { SelectController } from "psims/react/components/select";
import { OptionProps } from "react-select";
import VisuallyHidden from "psims/react/components/visually-hidden";

type TableStyleProps = {
    customMinWidth?: string;
    customWidth?: string;
    fixed?: boolean;
}

const StyledTable = styled.table`${(props: TableStyleProps) => `
    flex: 1;
    table-layout: ${props.fixed};
    width: ${props.customWidth ? props.customWidth : '100%'};
    min-width:${props.customMinWidth ? props.customMinWidth : '100%'};
`}`;

type TableProps = TableStyleProps & {
    caption: string;
}

export const Table = ({caption, children, fixed = true, ...rest}: React.PropsWithChildren<TableProps>) => {
    return <StyledTable fixed={fixed} {...rest}>
        <caption>
            <VisuallyHidden>
                {caption}
            </VisuallyHidden>
        </caption>
        {children}
    </StyledTable>
}

type TRProps = {
    hide?: boolean;
}

export const TR = styled.tr`${(props: TRProps) => `
    ${(props.hide ? 'visibility: collapse;' : '')}
`}`;

interface CellProps extends Omit<React.TdHTMLAttributes<HTMLTableCellElement>, 'align' | 'style' | 'onChange' | 'width'> {
    $align?: 'center' | 'left' | 'right';
    fixedWidth?: string;
    isReadonly?: boolean;
    minimumWidth?: string;
    verticalAlign?: string;
    $width?: string;
}

const Cell = css`${(props: CellProps) => `
    background-color: ${props.isReadonly ? 'var(--color-table-background)' : 'white'};
    border: 1px solid var(--color-table-border);
    box-sizing: border-box;
    padding: 16px;
    text-align: ${(props.$align ? props.$align : 'left')};
    vertical-align: ${(props.verticalAlign ? props.verticalAlign : 'middle')};
    &:first-child {
        border-left: none;
    }
    &:last-child {
        border-right: none;
    }

    ${props.$width ? `
    width: ${props.$width};
    ` : ''}

    ${props.fixedWidth ? `
    max-width: ${props.fixedWidth};
    min-width: ${props.fixedWidth};
    width: ${props.fixedWidth};
    ` : ''}

    ${props.minimumWidth ? `
    width: ${props.minimumWidth};
    min-width: ${props.minimumWidth};
    ` : ''}
`}`;

export const TH = styled.th`
    ${Cell}
    thead & {
        background: var(--color-table-background);
        box-shadow: inset 0 4px 0 var(--color-table-border), inset 0 -1px 0 var(--color-table-border);
        font-size: 12px;
        position: sticky;
        vertical-align: top;
        z-index: 1;
        [aria-hidden] & {
            z-index: 0;
        }
        top: 0;
    }
`;

interface ColumnHeaderProps extends CellProps {
    disableAriaDescribedBy?: boolean;
    label: ReactNode;
    Help?: string | JSX.Element;
    helpId?: string;
    noWrap?: boolean;
}

export const ColumnHeader = ({
    children, disableAriaDescribedBy, Help, helpId, label, noWrap, ...rest
}: React.PropsWithChildren<ColumnHeaderProps>) => {
    const hasHelp = Help != null;
    return (
        <TH scope='col' {...rest}>
            {
                hasHelp ?
                <BoxedSpan box={{alignItems: rest.$align === 'center' ? 'center' : 'flex-start', flex: 'column'}}>
                    <BoxedSpan box={{alignItems: 'center', flex: 'row', noWrap}}>
                        <Text $align={rest.$align || 'left'}>{label}</Text>
                        {children}
                        <TooltipHelp
                            // disable aria-describedby for tooltips within table headers - the content is
                            // read by default in TH
                            disableAriaDescribedBy={true}
                            Help={Help}
                            helpId={helpId}
                        />
                    </BoxedSpan>
                </BoxedSpan> :
                <BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
                    {label}
                    {children}
                </BoxedSpan>
            }
        </TH>
    );
}

export const TD = styled.td`
    ${Cell};
`;

interface FormControlTDProps extends CellProps {
    disabled?: boolean;
    error?: boolean;
}

const ControlTD = styled.td`
    ${Cell};
    padding-right: 24px;
    &:focus-within {
        border: 1px double var(--color-primary);
        box-shadow: var(--box-shadow-focus-inset);
    }

    ${({error}: FormControlTDProps) => error ? `
        &:not(:focus-within) {
            border: 1px double var(--color-error-border);
            box-shadow: var(--box-shadow-error-inset);
        }
    ` : ''}

    ${({disabled}: FormControlTDProps) => disabled ? `
        background-color: var(--color-table-background);
    ` : ''}
`;

interface CellControlProps<T extends InputValue> extends CellProps {
    disabled?: boolean;
    error?: string;
    forceError?: boolean;
    help?: string;
    helpId?: string;
    id?: string;
    info?: string;
    label: string;
    shouldFocus: boolean;
    value: T;
}

const InputTD = ControlTD;

type InputValue = number | string | undefined | null;

interface StyledCellInputProps {
    align?: 'center' | 'left' | 'right';
}

const StyledCellInput = styled.input`
    border: none;
    box-sizing: border-box;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    max-width: 100%;
    padding-right: 4px;
    text-align: ${({align}: StyledCellInputProps) => align == null ? 'auto' : align};
    &:focus,
    &:active {
        outline: none;
    }
    &:disabled {
        background: transparent;
    }
`;

const StyledLabel = styled.label`
    align-items: center;
    display: flex;
`;

interface CellInputProps<T extends InputValue> extends CellControlProps<T> {
    decimalPlaces?: number;
    onChange: (val: string) => any;
}

export const CellInput = <T extends InputValue>({decimalPlaces, disabled, error, forceError, info, shouldFocus, label, onChange, value, ...cellProps}: CellInputProps<T>) => {
    const {displayValue, setRef} = useDisplayValue({formatter: v => localeNumberWithFixed(v, decimalPlaces), value: value});
    const {setRef: setRefSelect} = useSelectAllOnFocus();
	const {setRef: setFocusableRef} = useFocusable({setFocused: shouldFocus});
    const {setRef: setRefIsFocused, isFocused} = useIsFocused()
    const [describedbyId] = useState(randomID());
    const {handleFocus: isDirtyHandleFocus, handleChange: isDirtyHandleChange, isDirty, reset: isDirtyReset} = useIsDirty(false, undefined);

    const showError = useMemo(() => error != null && (forceError || isDirty), [error, forceError, isDirty]);

    const handleChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        isDirtyHandleChange(evt);
        onChange(evt.target.value);
    }, [isDirtyHandleChange, onChange]);

    const handleBlur = useCallback((evt: React.FocusEvent<HTMLInputElement>) => {
        // Register an empty value change on blur
        if (value === undefined) {
            onChange('');
        }
    }, [onChange, value])

    const consolidatedRef = (el: Parameters<typeof setRef>[0] & Parameters<typeof setRefSelect>[0] & Parameters<typeof setFocusableRef>[0]) => {
        setRef(el);
        setRefSelect(el);
        setFocusableRef(el);
        setRefIsFocused(el);
    }

    // Reset dirty if value is undefined
    useEffect(() => {
        if (value === undefined) {
            isDirtyReset();
        }
    }, [isDirtyReset, value]);

    const pattern = decimalPlaces == null ? '-?(([1-9][0-9]{0,2}(,[0-9]{3})+)|[0-9]*)' : `-?[0-9]+.[0-9]{${decimalPlaces}}`;

    return (
        <InputTD disabled={disabled} error={showError} {...cellProps}>
            <StyledLabel>
                <VisuallyHidden>{label}</VisuallyHidden>
                <StyledCellInput
                    align="right"
                    aria-invalid={error != null ? true : undefined}
                    aria-errormessage={showError ? describedbyId : undefined}
                    disabled={disabled}
                    inputMode='decimal'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={isDirtyHandleFocus}
                    pattern={pattern}
                    ref={consolidatedRef}
                    type={'text'}
                    value={displayValue ?? ''}
                />
                {
                    showError ? 

                    <FloatingMessage
                        content={error}
                        id={describedbyId}
                        kind="warning"
                        role="alert"
                        showContent={isFocused}
                    /> :

                    info ? <FloatingMessage
                        content={info}
                        id={describedbyId}
                        kind="info"
                        role="alert"
                        showContent={disabled ? undefined : isFocused}
                    /> :

                    null
                }
            </StyledLabel>
        </InputTD>
    );
}

const SelectTD = styled(ControlTD)`
    padding-right: 2px;
`;

interface CellSelectProps<T extends InputValue> extends Omit<CellControlProps<T>, 'value'> {
    autoFocus?: boolean;
    ctrl: SelectController;
    id: string;
    optionCtrl?: (optionProps: OptionProps<any>) => JSX.Element;
}

export const CellSelect = <T extends InputValue>({autoFocus, ctrl, disabled, error, info, id, shouldFocus, label, optionCtrl, ...cellProps}: CellSelectProps<T>) => {
    const components = optionCtrl ? { Option: optionCtrl} : undefined;
    return (
        <SelectTD disabled={disabled} error={error != null} {...cellProps}>
            <BoxedDiv box={{alignItems: 'center', flex: 'row', flexGrow: 1}}>
                <Select
                    autoFocus={autoFocus}
                    bare={true}
                    borderless={true}
                    isDisabled={disabled}
                    error={error}
                    info={info}
                    focusless={true}
                    id={id}
                    label={label}
                    setFocused={shouldFocus}
                    value={ctrl.value}
                    onChange={ctrl.onChange}
                    options={ctrl.options}
                    useTooltip={true}
                    unstyled={true}
                    width='100%'
                    menuPortalTarget={document.body} 
                    menuShouldBlockScroll={true}
                    components={components}
                />
            </BoxedDiv>
        </SelectTD>
    );
}

export const DeleteActionCellTH = styled(TH)`
    table tr & {
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
        max-width: 40px;
        width: 40px;
        padding: 0 !important;
    }
`;

export const DeleteActionCellTD = styled(TD)`
    table tr & {
        border: none !important;
        max-width: 40px;
        width: 40px;
        padding: 0 !important;
    }
`;

export const DeleteColumnHeader = styled(TH)`
    text-align: center;
    color: transparent !important;
    max-width: 20px;
`;

export const ActionCellTH = styled(TH)`
    table tr & {
        background: transparent;
        border: none !important;
        box-shadow: none;
        max-width: 40px;
        width: 40px;
        padding: 0 !important;
    }
`;

export const ActionCellTD = styled(TD)`
    table tr & {
        border: none !important;
        max-width: 40px;
        width: 40px;
        padding: 0 !important;
    }
`;
