/**
 * This controller encapsulates the standard controller for tracking
 * data submission progress. Consumers provide DS-specific implementations
 * of determining the save status of a step, whether a step has data,
 * and which step should be shown first for editable data submissions
 */
import { useCallback, useMemo, useState } from "react";

import { ViewMode } from "psims/react/pages/primary-pages/data-submissions/shared/use-view-mode";
import StepLabel from "psims/react/pages/primary-pages/data-submissions/shared/step-label";
import { DataSubmissionStatus } from "psims/models/data-submission";

type StepStatus = 'active' | 'complete' | 'pending';

interface Step {
  index: number;
  kind: 'data' | 'submit';
  label: string;
}

interface StepsController<TStep extends Step> {
  steps: Array<TStep>;
}

interface Submission {
  dataSubmission: {
    status: DataSubmissionStatus;
  }
}

interface UseDataSubmissionProgressProps<TSubmission extends Submission, TStep extends Step> {
  stepsCtrl: StepsController<TStep>;
  submission: TSubmission;
  viewMode: ViewMode;
  doesStepHaveData: (step: TStep, submission: TSubmission) => boolean;
  getDataStepStatus: (step: TStep, submission: TSubmission) => 'complete' | 'pending';
  getFirstUnsavedStepIndex: (submission: TSubmission) => number;
}

function useDataSubmissionProgress<TSubmission extends Submission, TStep extends Step>({stepsCtrl, submission, viewMode, doesStepHaveData, getDataStepStatus, getFirstUnsavedStepIndex}: UseDataSubmissionProgressProps<TSubmission, TStep>) {
    const [currentStepIndex, setCurrentStepIndex] = useState(determineFirstPageIndex(submission, viewMode, getFirstUnsavedStepIndex));
    const [navAttemptedForStep, setNavAttemptedForStep] = useState(false);

    const progressSteps = useMemo(() => {
        return stepsCtrl.steps.map(step => ({
            ...step,
            Label: StepLabel({
                hasData: stepHasData(step, submission, doesStepHaveData),
                label: step.label,
            }),
            status: (currentStepIndex === step.index ? 'active' : statusForStep(step, submission, viewMode, getDataStepStatus)) as StepStatus,
        }));
    }, [currentStepIndex, doesStepHaveData, getDataStepStatus, stepsCtrl.steps, submission, viewMode]);

    const currentStep = useMemo(() => {
        return progressSteps[currentStepIndex];
    }, [currentStepIndex, progressSteps]);

    const goToStep = useCallback((index: number) => {
        setCurrentStepIndex(index);
        setNavAttemptedForStep(false);
    }, []);

    const onNavAttempt = () => setNavAttemptedForStep(true);

    return {
        currentStep,
        navAttemptedForStep,
        progressSteps,
        goToStep,
        onNavAttempt,
    }
}

export default useDataSubmissionProgress

export type UseDataSubmissionProgress = ReturnType<typeof useDataSubmissionProgress>;

// Helpers
function determineFirstPageIndex<TSubmission extends Submission>(submission: TSubmission, viewMode: ViewMode, getFirstUnsavedStep: (submission: TSubmission) => number) {
    if (viewMode !== 'edit' || submission.dataSubmission.status === 'Action required') {
        return 0;
    }

    return getFirstUnsavedStep(submission);
}

function stepHasData<TSubmission extends Submission, TStep extends Step>(step: TStep, submission: TSubmission, doesStepHaveData: (step: TStep, submission: TSubmission) => boolean) {
    if (step.kind === 'submit') {
        return true;
    }

    return doesStepHaveData(step, submission);
}

function statusForStep<TSubmission, TStep extends Step>(step: TStep, submission: TSubmission, viewMode: ViewMode, getDataStepStatus: (step: TStep, submission: TSubmission) => 'complete' | 'pending') {
    if (viewMode !== 'edit') {
        return 'complete';
    }

    if (step.kind === 'submit') {
        return 'pending';
    }

    return getDataStepStatus(step, submission);
}
