import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { UpdateMsoAnnualActivityRefinerField } from "psims/models/submission-types/mso/annual-activity/refiner/update-mso-annual-activity-refiner";
import { NotificationPart } from "../../../shared/notification-message";
import { ValidationResult } from "./refiner-annual-activities-validation";

const FIELD_LABELS: {[key in UpdateMsoAnnualActivityRefinerField | 'delete']: string} = {
  delete: 'delete row',
  enforceableArrangements: 'enforceable arrangements',
  totalAnnualVolume: 'total annual volume',
  totalStorageCapacity: 'total storage capacity',
};

export function getNotificationMessage(
  fieldName: UpdateMsoAnnualActivityRefinerField | 'delete',
  onTargetClick: () => any,
  productName: string,
  validationResult: ValidationResult | null
) {
  if (validationResult == null) {
    return undefined;
  }

  const label = FIELD_LABELS[fieldName];

  const btn = {label: `Go to ${label} field`, onClick: onTargetClick, text: label};

  const parts: Array<NotificationPart> = [
    `For ${productName}: `,
  ]

  switch (validationResult.code) {
    case 'invalid_integer':
    case 'invalid_integer_range':
      parts.push('the value in ');
      parts.push(btn);
      parts.push(` must be ${validationResult.min === 0 ? 'a positive integer ' : 'an integer'} (zero is permitted) with a maximum value of ${validationResult.min < 0 ? '+/-' : ''}${localeNumberWithFixed(validationResult.max)}.`);
      break;

    case 'invalid_required':
      parts.push('a value for ');
      parts.push(btn);
      parts.push(' is required.')
      break;

    case 'inactive_product':
      parts.push('This product is inactive. Please enter these details into an active product if applicable. Delete ');
      parts.push({label: `Go to delete data button`, onClick: onTargetClick, text: 'product data'});
      parts.push(' to continue.');
      break;
  }

  return parts;
}