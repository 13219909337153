import React, { useCallback, useMemo } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import { Redirect } from 'react-router-dom';
import Loading from 'psims/react/components/loading';
import Notification from 'psims/react/components/notification';
import RefineryEditor from './refinery-editor';
import Button from 'psims/react/components/button';
import useRefineryAPI, { UseRefineryAPI } from './use-refinery-api';
import { useNavigation } from 'psims/react/providers/router';
import { useReferenceData } from 'psims/react/providers/api/reference-data';

interface RefineryProps {
	id: number;
};

const RefineryPage = (props: RefineryProps) => {
    const isInvalidParams = isNaN(props.id);

    if (isInvalidParams) {
        return <Redirect to='/' />;
    }

    return <EnsureSubmission {...props} />;
};

const LoadingPlaceholder = () => (
    <BoxedDiv box={{alignItems: 'center', flex: 'column', flexGrow: 1, justifyContent: 'center'}}>
        <Loading>Loading</Loading>
    </BoxedDiv>
)

interface ErrorPlaceholderProps {
    handleTryAgain: () => any;
}
const ErrorPlaceholder = ({handleTryAgain}: ErrorPlaceholderProps) => (
    <div className='container'>
        <BoxedDiv box={{alignItems: 'center', flex: 'column', marginV: 4}}>
            <BoxedDiv box={{alignItems: 'center', flex: 'column', width: '600px'}}>
                <Notification align='center' kind='warning' >
                    <p>
                        There was a problem loading this refining data submission, please navigate back to your <Button
                            $kind='text'
                            aria-label='Go back to home page'
                            onClick={handleTryAgain}
                        >'Home' page</Button> and try again.
                    </p>
                </Notification>
            </BoxedDiv>
        </BoxedDiv>
    </div>

)

interface WithSubmission<T> {
    submission: Exclude<T, null | undefined>;
}

function hasSubmission<T>(maybe?: unknown): maybe is WithSubmission<T> {
    const maybeAs = maybe as WithSubmission<T>
    return (
        maybeAs != null && maybeAs.submission != null
    )
}

const EnsureSubmission = (props: RefineryProps) => {
	const vm = useEnsureSubmission({id: props.id});
    const {apiCtrl} = vm;

    if (vm.viewMode === 'loading') {
        return <LoadingPlaceholder />
    }

    if (vm.viewMode === 'error') {
        return <ErrorPlaceholder  handleTryAgain={vm.handleTryAgain}/>
    }

    if (hasSubmission(apiCtrl)) {
        // WTAF TS
        return <EnsureRefData apiCtrl={apiCtrl as PopulatedProps<UseRefineryAPI, 'submission'>} />;
    }

    return null;
}

function useEnsureSubmission({id}: RefineryProps) {
    const navigation = useNavigation();
    const apiCtrl = useRefineryAPI({dataSubmissionId: id}); 

    const handleTryAgain = useCallback(() => {
        navigation.goToHomePage();
    }, [navigation]);

    const viewMode = useMemo(() => {
        if (apiCtrl.loadStatus === 'fetch_failed') {
            return 'error';
        }

        if (apiCtrl.submission != null) {
            return 'loaded';
        }

        return 'loading';
    }, [apiCtrl.loadStatus, apiCtrl.submission]);

    return {
        handleTryAgain,
        apiCtrl,
        viewMode,
    };
}

interface EnsureRefDataProps {
    apiCtrl: PopulatedProps<UseRefineryAPI, 'submission'>;
}

const EnsureRefData = ({apiCtrl}: EnsureRefDataProps) => {
    const {data: _refData, status} = useReferenceData()
    const navigation = useNavigation();

    const handleTryAgain = useCallback(() => {
        navigation.goToHomePage();
    }, [navigation]);
    
    if (status === 'fetching' || status === 'init') {
        return <LoadingPlaceholder />
    }

    if (status === 'error' || _refData == null) {
        return <ErrorPlaceholder handleTryAgain={handleTryAgain} />
    }

    return <RefineryEditor apiCtrl={apiCtrl} allRefData={_refData} />
}

export default RefineryPage;
