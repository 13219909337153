import { FsspEligibleProductionVM, UpdateFsspEligibleProductionVM } from "psims/gen/xapi-client";

export type FspEligibleProduction = PopulatedProps<
  FsspEligibleProductionVM,
  'dataSubmissionId' |  'fsspProductId' | 'fsspProductName'
>;

export function isFspEligibleProduction(maybe?: unknown): maybe is FspEligibleProduction {
  const maybeAs = maybe as FspEligibleProduction;

  return (
    maybeAs != null &&
    maybeAs.dataSubmissionId != null &&
    maybeAs.fsspProductId != null &&
    maybeAs.fsspProductName != null
  );
}

export type UpdateFspEligibleProduction = PopulatedProps<
  UpdateFsspEligibleProductionVM,
  'dataSubmissionId' | 'recordAction' | 'volumeConfirmation' | 'fsspProductId'
>;

export function isUpdateFspEligibleProduction(maybe?: unknown): maybe is UpdateFspEligibleProduction {
  const maybeAs = maybe as UpdateFspEligibleProduction;

  return (
    maybeAs != null &&
    maybeAs.dataSubmissionId != null &&
    maybeAs.fsspProductId != null &&
    maybeAs.volumeConfirmation != null &&
    maybeAs.recordAction != null
  );
}

export type UpdateFspEligibleProductionField = 'volumeConfirmation';
