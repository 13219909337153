/*
    Manages the saving and navigation logic of the data submission:
    - tracks next page to go to if save succeeds
    - tracks whether current view has unsaved changes
*/
import produce from "immer";
import { WholesaleSubmission } from "psims/models/submission-types/wholesaling";
import { UseTemplateImport } from "psims/react/blocks/import/use-template-import";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isBusyStatus } from "../shared/api";
import { ViewMode } from "../shared/use-view-mode";
import { updateWholesaleCommentFromSubmission } from "./shared";
import { UseFormForType } from "./use-form-for-type";
import { UseWholesalingAPI } from "./use-wholesaling-api";
import { UseWholesalingSteps } from "./use-wholesaling-steps";
import { UseWholesalingSubmission } from "./use-wholesaling-submission";
import { UseWholesalingUpdateResponse } from "./use-wholesaling-update-response";

interface UseWholeSalingSaveProps {
    apiCtrl: UseWholesalingAPI;
    formCtrl: UseFormForType;
    stepCtrl: UseWholesalingSteps;
    importCtrl: UseTemplateImport<Partial<WholesaleSubmission>>;
    submissionCtrl: UseWholesalingSubmission;
    updateResponse: UseWholesalingUpdateResponse;
    viewMode: ViewMode;
}

type ConfirmState = 'confirming' | 'inactive' | 'saving';

function useWholesalingSave({apiCtrl, formCtrl, stepCtrl, submissionCtrl, importCtrl, updateResponse, viewMode}: UseWholeSalingSaveProps) {
    const nextPageIndex = useRef<number | null>(null);
    const stepCtrlRef = useUpdatedRef(stepCtrl);
    const [confirmState, setConfirmState] = useState<ConfirmState>('inactive');

    const isApiBusy = useMemo(() => {
        return isBusyStatus(apiCtrl.loadStatus);
    }, [apiCtrl.loadStatus]);

    const doImportTemplateSaved = useMemo(() => {
        return viewMode !== 'view' && importCtrl && importCtrl.templateImportState.templateImportDialogState === 'processing';
    }, [importCtrl, viewMode]);
    
    const setImportStepSaved = useCallback(() => {
        if (viewMode !== 'view' &&importCtrl && importCtrl.templateImportState.templateImportDialogState === 'processing') {
            importCtrl.saveStep(stepCtrl.currentStep.typeName as string);
            importCtrl.setTemplateImportState(prev => produce(prev, draft => {
                const newData = draft.data as Partial<WholesaleSubmission>;
                draft.unsavedChanges = !(newData?.wholesales === undefined || 
                            (newData?.submissionFormData?.bulkPageSaved === true &&
                                newData?.submissionFormData?.otherPageSaved === true &&
                                newData?.submissionFormData?.retailersPageSaved === true &&
                                newData?.submissionFormData?.totalPageSaved === true &&
                                newData?.submissionFormData?.wholesalersPageSaved === true));
            }));
        }        
    }, [importCtrl, stepCtrl.currentStep.typeName, viewMode]);

    const handlePrevious = useCallback(() => {
        if (viewMode === 'view') {
            stepCtrl.previous();
            return;
        }

        nextPageIndex.current = stepCtrl.currentStep.index - 1;
        if (stepCtrl.currentStep.typeName === 'Submit') {
            submissionCtrl.update();
        } else {
            if (doImportTemplateSaved) {
                apiCtrl.updateWholesale({
                    ...formCtrl.wholesalesUpdate,
                    isTemplateImport: true
                });

            } else {
                apiCtrl.updateWholesale(formCtrl.wholesalesUpdate);
            }
            setImportStepSaved();
        }
    }, [apiCtrl, doImportTemplateSaved, formCtrl.wholesalesUpdate, setImportStepSaved, stepCtrl, submissionCtrl, viewMode]);

    const handleSaveAndContinue = useCallback(() => {
        if (viewMode === 'view') {
            stepCtrl.next();
            return;
        }

        nextPageIndex.current = stepCtrl.currentStep.index + 1;

        if (doImportTemplateSaved) {
            apiCtrl.updateWholesale({
                ...formCtrl.wholesalesUpdate,
                isTemplateImport: true
            });

        } else {
            apiCtrl.updateWholesale(formCtrl.wholesalesUpdate);
        }
        setImportStepSaved();
    }, [apiCtrl, doImportTemplateSaved, formCtrl.wholesalesUpdate, setImportStepSaved, stepCtrl, viewMode]);

    const handleSaveAndGoToPage = useCallback((index: number) => {
        if (viewMode === 'view') {
            stepCtrl.next();
            return;
        }

        nextPageIndex.current = index;

        if (stepCtrl.currentStep.typeName === 'Submit') {
            submissionCtrl.update();
            setImportStepSaved();
        } else {
            if (doImportTemplateSaved) {
                apiCtrl.updateWholesale({
                    ...formCtrl.wholesalesUpdate,
                    isTemplateImport: true
                });

            } else {
                apiCtrl.updateWholesale(formCtrl.wholesalesUpdate);
            }
            setImportStepSaved();
        }
    }, [apiCtrl, doImportTemplateSaved, formCtrl.wholesalesUpdate, setImportStepSaved, stepCtrl, submissionCtrl, viewMode]);

    const handleSaveBeforeStepNavigation = useCallback((stepIndex: number) => {
        nextPageIndex.current = stepIndex;
        setConfirmState('confirming');
    }, []);

    const handleDiscardAndContinue = useCallback(() => {
        if (confirmState !== 'confirming') {
            return;
        }

        setConfirmState('inactive');
        setImportStepSaved();
        if (nextPageIndex.current != null) {
            stepCtrlRef.current.goToStep(nextPageIndex.current);
            nextPageIndex.current = null;
        }
    }, [confirmState, setImportStepSaved, stepCtrlRef]);

    const handleSaveConfirm = useCallback(() => {
        if (confirmState !== 'confirming') {
            return;
        }

        setConfirmState('saving');

        if (stepCtrl.currentStep.typeName === 'Submit') {
            submissionCtrl.update();
        } else {
            if (doImportTemplateSaved) {
                apiCtrl.updateWholesale({
                    ...formCtrl.wholesalesUpdate,
                    isTemplateImport: true
                });

            } else {
                apiCtrl.updateWholesale(formCtrl.wholesalesUpdate);
            }
            setImportStepSaved();
        }
    }, [apiCtrl, confirmState, doImportTemplateSaved, formCtrl.wholesalesUpdate, setImportStepSaved, stepCtrl.currentStep.typeName, submissionCtrl]);

    const handleSaveCancel = useCallback(() => {
        if (confirmState !== 'confirming') {
            return;
        }

        setConfirmState('inactive');
        nextPageIndex.current = null;
    }, [confirmState]);

    // When updateResponse changes, navigate if requested
    useEffect(() => {
        if (nextPageIndex.current != null && isUpdateNavigationPermitted(updateResponse, stepCtrlRef.current.currentStep.refData?.wholesaleType.id)) {
            stepCtrlRef.current.goToStep(nextPageIndex.current);
        }

        if (updateResponse.status !== 'pending') {
            nextPageIndex.current = null;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateResponse.status]);

    return {
        confirmState,
        isApiBusy,
        handleDiscardAndContinue,
        handlePrevious,
        handleSaveAndContinue,
        handleSaveAndGoToPage,
        handleSaveBeforeStepNavigation,
        handleSaveCancel,
        handleSaveConfirm,
    };
}

export default useWholesalingSave;

export type UseWholeSalingSave = ReturnType<typeof useWholesalingSave>;

function isUpdateNavigationPermitted(updateResponse: UseWholesalingUpdateResponse, wholesaleTypeId: number | null | undefined) {
    if (
        updateResponse.processedResponse?.errorMessages?.length ||
        updateResponse.processedResponse?.validationMessages?.length ||
        updateResponse.processedResponse?.submission == null ||
        (updateResponse.status !== 'saved_full' && updateResponse.status !== 'saved_partial')
    ) {
        return false;
    }

    // Skip validation alert checking on submit page save
    if (wholesaleTypeId == null) {
        return true;
    }

    const {processedResponse: {submission}} = updateResponse;

    // If there are any validation alerts on the current page, then comments are required
    const validationAlerts = submission.wholesales
        .filter(w => w.wholesaleTypeId === wholesaleTypeId && (w.validationAlerts || []).length > 0)
        .map(w => w.validationAlerts);

    if (validationAlerts.length === 0) {
        return true;
    }

    const comments = updateWholesaleCommentFromSubmission(submission, wholesaleTypeId);

    if ((comments?.comments || '').length > 0) {
        return true;
    }

    return false;
}
