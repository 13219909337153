import { useCallback, useMemo, useState } from 'react';

import { useReferenceData } from 'psims/react/providers/api/reference-data';
import { useUserOrgs } from 'psims/react/providers/api/user-orgs';
import { useSelectController } from 'psims/react/components/select';

export interface FilterDocumentsQuery {
    organisationId: number | undefined | null;
    documentTypeName: string | undefined | null;
}

function useDocumentsFilter() {
  const {data: refData} = useReferenceData();
  const {orgs} = useUserOrgs();
  
  const [query, setQuery] = useState<FilterDocumentsQuery>(defaultQuery());
  
  const documentTypeOptions = useMemo(() => {
    if (refData == null) {
      return [];
    }

    return refData.documentTypes.map(dt => ({
      label: dt.name,
      value: dt.name,
    }));
  }, [refData]);
  
  const organisationOptions = useMemo(() => {
    if (orgs == null) {
      return [];
    }

    return orgs.map(org => ({
      label: org.name,
      value: org.id,
    }));
  }, [orgs]);

  const documentTypeSelectCtrl = useSelectController({
    onChange: v => setQuery(prev => ({
      ...prev,
      documentTypeName: v
    })),
    options: [
      {label: 'All', value: null},
      ...documentTypeOptions
    ],
    value: query.documentTypeName,
  });

  const organisationSelectCtrl = useSelectController({
    onChange: v => setQuery(prev => ({
      ...prev,
      organisationId:  v
    })),
    options: [
      {label: 'All', value: null},
      ...organisationOptions
    ],
    value: query.organisationId,
  });

  const reset = useCallback(() => {
    setQuery(defaultQuery());
  }, []);

  return {
    documentTypeOptions,
    documentTypeSelectCtrl,
    organisationOptions,
    organisationSelectCtrl,
    query,

    reset,
  };
}

function defaultQuery(): FilterDocumentsQuery {
  return {
    documentTypeName: null,
    organisationId: null,
  };
}

export default useDocumentsFilter

export type UseDocumentsFilter = ReturnType<typeof useDocumentsFilter>;
