import Button from 'psims/react/components/button';
import { Table, TBody, TD, TH, THead, TR } from 'psims/react/components/table';
import CompanyHeader from 'psims/react/blocks/company-header';
import { H1 } from 'psims/react/components/typography';
import { Organisation, organisationContact } from 'psims/models/organisation';
import { Menu, MenuButton, MenuItem, MenuList } from 'psims/react/components/menu-button';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import Ellipsis from 'psims/react/components/icons/ellipsis';
import { UseEditCompany } from './use-edit-company';
import Dialog from 'psims/react/components/dialog';
import EditCompany from './edit-company';
import { formatAbn } from 'psims/lib/formatters/abn';
import Text from 'psims/react/components/text';

interface CompanyOverviewProps {
	organisations: Array<Organisation> | null,
	editCompanyCtrl: UseEditCompany,
	showEditCompanyDialog: boolean
};

const CompanyOverview = (props: CompanyOverviewProps) => {
	return (
		<BoxedDiv box={{alignSelf: 'stretch', flex: 'column', flexGrow: 1, justifySelf: 'center'}}>
			<CompanyHeader />

			<div className='container'>
				<H1>Company overview</H1>
				
				<p>The following details have been recorded on your behalf, please contact the department if your registered details are incorrect.</p>

				{
					<BoxedDiv box={{alignItems: 'flex-end', flex: 'column', overflowX: 'hidden'}}>
						<BoxedDiv box={{alignSelf: 'stretch', marginTop: 2.5, overflowX: 'auto'}}>
							<Table caption='Your connected company(s)' fullWidth={true}>
								<THead>
									<TR>
										<TH scope='col' minWidth='150px'>Company name</TH>
										<TH scope='col' minWidth='120px'>Type</TH>
										<TH scope='col' minWidth='120px'>ABN</TH>
										<TH scope='col'>Primary contact</TH>
										<TH scope='col'>Secondary contact</TH>
										<TH $align='center' scope='col'>Actions</TH>
									</TR>
								</THead>

								{ <TBody>
									{
										props.organisations !== null && props.organisations?.map(organisation => <CompanyRow
											key={organisation!.id}
											onClickEdit={props.editCompanyCtrl.selectOrganisation}
											organisation={organisation!}
										/>)
									}
								</TBody> }
							</Table>
						</BoxedDiv>
					</BoxedDiv>
				}

				<Dialog aria-label='Edit company' isOpen={props.showEditCompanyDialog} onDismiss={props.editCompanyCtrl.cancel}>
                	<EditCompany ctrl={props.editCompanyCtrl} />
            	</Dialog>
			</div>
		</BoxedDiv>
	);
};

export default CompanyOverview;

interface OrganisationRowProps {
	onClickEdit: (organisation: Organisation) => any;
	organisation: Organisation;
}

const CompanyRow = ({
	onClickEdit, 
	organisation
}: OrganisationRowProps) => {

	const primaryContact = organisationContact(organisation, 'Primary');
	const secondaryContact = organisationContact(organisation, 'Secondary');
	return (
		<TR key={organisation.id}>
			<TH scope='row'>{organisation.name}</TH>
			<TD>{organisation.organisationType}</TD>
			<TD>{organisation.abn ? formatAbn(organisation.abn) : ''}</TD>
			<TD><Text $noWrap={true}>{primaryContact ? `${primaryContact?.firstName} ${primaryContact?.lastName}` : ''}</Text></TD>
			<TD><Text $noWrap={true}>{secondaryContact ? `${secondaryContact?.firstName} ${secondaryContact?.lastName}` : ''}</Text></TD>
			<TD $align='center'>
				<OrganisationActions
					onClickEdit={onClickEdit}
					organisation={organisation}
				/>
			</TD>
		</TR>
	);
}

interface OrganisationActionsProps {
	onClickEdit: (organisation: Organisation) => any;
	organisation: Organisation;
}

const OrganisationActions = ({
	onClickEdit, 
	organisation
}: OrganisationActionsProps) => {
	
	return (
		<Menu>
			<MenuButton as={Button} $kind='unstyled' aria-label='Actions for this company'>
				<BoxedSpan box={{paddingH: 1.5, paddingV: 0.5}} >
					<Ellipsis color='primary' size='md' />
				</BoxedSpan>
			</MenuButton>

			<MenuList>
				{
					<MenuItem onSelect={() => onClickEdit(organisation)}>Edit company details</MenuItem>
				}
			</MenuList>
		</Menu>
	)
}