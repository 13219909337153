import { useCallback, useEffect, useState } from "react";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { UpdateDataSubmission } from "psims/models/data-submission";

interface UseDataSubmissionSaveCheckProps<TUpdate> {
    apiCtrl: {
        update: (d: TUpdate) => any;
        updateDataSubmission: (ds: UpdateDataSubmission) => any;
        loadStatus: string | 'updated' | 'update_failed';
    };
    hasUnsavedChanges: boolean;
    updateDataSubmission: UpdateDataSubmission;
    updateSubmission: TUpdate;
}

type SaveState = 'idle' | 'confirming' | 'saving';

type SaveKind = 'submission' | 'data_submission';

type ProceedChoice = 'save' | 'no_save';

/**
 * Generic controller to manage the dialog state and decision action for unsaved changes 
 */
function useDataSubmissionSaveCheck<TUpdate>({apiCtrl, hasUnsavedChanges, updateDataSubmission, updateSubmission}: UseDataSubmissionSaveCheckProps<TUpdate>) {
    const [saveState, setSaveState] = useState<SaveState>('idle');
    const [saveKind, setSaveKind] = useState<SaveKind | null>(null);
    const [successOrProceedAction, setSuccessOrProceedAction] = useState<((choice: ProceedChoice) => any) | null>(null);
    const [cancelAction, setCancelAction] = useState<(() => any) | null>(null);
    const [failAction, setFailAction] = useState<(() => any) | null>(null);
    const saveStateRef = useUpdatedRef(saveState);

    const reset = useCallback(() => {
        setSaveKind(null);
        setSaveState('idle');
        setSuccessOrProceedAction(null);
        setCancelAction(null);
        setFailAction(null);
    }, []);

    const checkAndConfirm = useCallback((kind: SaveKind, onSuccessOrProceed: (choice: ProceedChoice) => any, onCancel: () => any, onFail?: () => any) => {
        if (saveState !== 'idle') {
            // confirmation already in flight
            return;
        }

        if (hasUnsavedChanges) {
            setSaveKind(kind);
            setSaveState('confirming');
            setSuccessOrProceedAction(() => onSuccessOrProceed);
            setCancelAction(() => onCancel);
            setFailAction(() => (onFail || null));
        } else {
            onSuccessOrProceed('no_save');
        }
    }, [hasUnsavedChanges, saveState]);

    const cancel = useCallback(() => {
        if (saveState === 'confirming') {
            cancelAction && cancelAction();
            reset();
        }
    }, [cancelAction, reset, saveState]);

    const proceed = useCallback(() => {
        if (saveState === 'confirming') {
            successOrProceedAction && successOrProceedAction('no_save');
            reset();
        }
    }, [successOrProceedAction, reset, saveState]);

    const save = useCallback((kind?: SaveKind, onSuccess?: () => any) => {
        if (kind) {
            setSaveKind(kind);
        } else if (saveKind == null) {
            return;
        } else {
            kind = saveKind;
        }

        if (saveState === 'confirming' || saveState === 'idle') {
            if (onSuccess != null) {
                setSuccessOrProceedAction(() => onSuccess);
            }
            if (kind === 'data_submission') {
                apiCtrl.updateDataSubmission(updateDataSubmission)
            } else if (kind === 'submission') {
                apiCtrl.update(updateSubmission);
            }
            setSaveState('saving');
        }
    }, [apiCtrl, saveKind, saveState, updateDataSubmission, updateSubmission]);

    useEffect(() => {
        if (saveStateRef.current  === 'saving') {
            if (apiCtrl.loadStatus === 'update_failed') {
                (failAction && failAction()) || (cancelAction && cancelAction());
                reset();
            } else if (apiCtrl.loadStatus === 'updated') {
                successOrProceedAction && successOrProceedAction('save');
                reset();
            }
        }
    }, [apiCtrl.loadStatus, cancelAction, failAction, reset, saveStateRef, successOrProceedAction]);

    return {
        saveState,
        cancel,
        checkAndConfirm,
        proceed,
        save,
    }
}

export default useDataSubmissionSaveCheck;
