import { getRecordResults, isMsoRefiner, isMsoRefinerSubmitted, MaybeMsoRefinerSubmission, MsoRefinerSubmission } from "psims/models/submission-types/mso/mso-refiner-submission";
import { useAPI } from "psims/react/providers/api";
import useDataSubmissionAPI from "psims/react/pages/primary-pages/data-submissions/shared/use-data-submission-api";
import { isSuccesfulRowResult } from "psims/models/api/record-result";

interface UseMsoImportAPIProps {
    id: number;
}

function useMsoRefinerAPI({id}: UseMsoImportAPIProps) {
    const {api} = useAPI();

    return useDataSubmissionAPI({
        actionFetch: api.getMsoRefinerSubmission,
        actionSubmit: api.submitMsoRefiner,
        actionUpdate: api.updateMsoRefiner,
        actionUpdateDataSubmission: api.updateMsoRefinerDataSubmission,
        actionClearAllData: api.clearAllMsoRefiner,
        dataSubmissionId: id,
        name: 'MSO Refiner',
        submissionAssertion: isMsoRefiner,
        submitSuccessAssertion: isMsoRefinerSubmitted,
        updateResponseTransform: removeDeleted,
        updateSuccessAssertion,
    });
}

export default useMsoRefinerAPI;

export type UseMsoRefinerAPI = ReturnType<typeof useMsoRefinerAPI>;

function updateSuccessAssertion(result: MaybeMsoRefinerSubmission | null | undefined) {
    if (result == null) {
        return false;
    }

    return getRecordResults(result)
        .filter(r => !isSuccesfulRowResult(r)).length === 0;
}

function removeDeleted(result: MsoRefinerSubmission): MsoRefinerSubmission {
    const transformed = {
        ...result,
        stockholdings: result.stockholdings.filter(s => s.recordResult?.rowResult !== 'Deleted'),
        stockRefineriesHeld: result.stockRefineriesHeld.filter(r => r.recordResult?.rowResult !== 'Deleted'),
    };

    return transformed;
}