import { MsoSubmissionFormDataVM } from "psims/gen/xapi-client";

type MaybeMsoSubmissionFormData = MsoSubmissionFormDataVM;

export type MsoSubmissionFormData = PopulatedProps<MaybeMsoSubmissionFormData, 'id' | 'dataPageSaved'>;

export function isMsoSubmissionFormData(maybe?: MaybeMsoSubmissionFormData): maybe is MsoSubmissionFormData {
    return (
        maybe != null &&
        maybe.id != null &&
        maybe.dataPageSaved != null
    );
}
