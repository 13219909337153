import { isEmpty } from "psims/lib/empty";
import { isBetween, isInteger } from "psims/lib/validation/number";
import { recordActionAsEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { DefProduct } from "psims/models/ref-data/def-product";
import { DefActivity, DefActivityField } from "psims/models/submission-types/def/def-activity";
import { Draft } from "psims/react/pages/primary-pages/data-submissions/shared/use-updateable-list";
import { isTGUReferenceCode, isDEFReferenceCode, DefProductWithStatus } from "./util";

type ValidationResultMinMax = {
  code: 'invalid_non_integer' | 'invalid_out_range';
  max: number;
  min: number;
}

type ValidationResultRequired = {
  code: 'invalid_required';
}

type ValidationResultExpired = {
  code: 'product_expired';
}

export type ValidationResult = {
  field: DefActivityField;
} & (ValidationResultMinMax | ValidationResultRequired | ValidationResultExpired);

const MAX_VALUE = 100000;
const MAX_VALUE_DEF_DERIVED = 1000000;
const MIN_VALUE = 0;

export function validateActivity(
  draft: Draft<DefActivity>,
  product: DefProductWithStatus | undefined,
  allDrafts: Array<Draft<DefActivity>>,
  allProducts: Array<DefProduct>
): Array<ValidationResult> {
  const validationResults: Array<ValidationResult> = [];

  if (product == null || recordActionFromEnum(draft.recordAction) === 'Delete') {
    return validationResults;
  }

  if (product.productStatus === 'inactive_with_data'){
    return [{
      code: 'product_expired',
      field: 'closingStocks',
    }];
  }

  const { closingStocks, usedInBlending, derivedFromBlending, derivedFromOtherProduction, sales} = draft;

  // Don't validate deleted record
  if (draft.recordAction === recordActionAsEnum('Delete')) {
    return validationResults;
  }

  const hasAtLeastOneValue = (
    !isEmpty(closingStocks) ||
    !isEmpty(usedInBlending) ||
    !isEmpty(derivedFromBlending) ||
    !isEmpty(derivedFromOtherProduction) ||
    !isEmpty(sales)
  );

  const tguProduct = allProducts.find(p => isTGUReferenceCode(p.referenceCode));
  const tguDraft = allDrafts.find(d => d.defProductId === tguProduct?.id);
  const defProduct = allProducts.find(p => isDEFReferenceCode(p.referenceCode));
  const defDraft = allDrafts.find(d => d.defProductId === defProduct?.id);

  // Evaluate relativity rules - i.e. when to make a row mandatory
  const mustValidate = hasAtLeastOneValue || (
      isTGUReferenceCode(product.referenceCode) ? (
      defDraft != null && defDraft.derivedFromBlending != null && defDraft.derivedFromBlending > 0
    ) : (isDEFReferenceCode(product.referenceCode) ? (
      tguDraft != null && tguDraft.usedInBlending != null && tguDraft.usedInBlending > 0
    ) : false)
  );

  // Empty rows allowed
  if (!mustValidate && (isEmpty(closingStocks) && isEmpty(usedInBlending) && isEmpty(derivedFromBlending) && isEmpty(derivedFromOtherProduction) && isEmpty(sales))) {
    return validationResults;
  }
  
  let result = validateField('usedInBlending', usedInBlending);
  if (result != null && !isDEFReferenceCode(product.referenceCode)) validationResults.push(result);

  result = validateField('derivedFromBlending', derivedFromBlending);
  if (result != null && !isTGUReferenceCode(product.referenceCode)) validationResults.push(result);

  result = validateField('derivedFromOtherProduction', derivedFromOtherProduction);
  if (result != null && !isTGUReferenceCode(product.referenceCode)) validationResults.push(result);

  result = validateField('sales', sales);
  if (result != null) validationResults.push(result);

  result = validateField('closingStocks', closingStocks);
  if (result != null) validationResults.push(result);

  return validationResults;
}

function validateField(field: DefActivityField, val?: number | null): null | ValidationResult {
  const maxVal = field === 'derivedFromBlending' ? MAX_VALUE_DEF_DERIVED : MAX_VALUE;
  if (isEmpty(val)) {
    return {
      code: 'invalid_required',
      field,
    };
  }

  if (!isInteger(val)) {
    return {
      code: 'invalid_non_integer',
      field,
      max: maxVal,
      min: MIN_VALUE,
    };
  }

  if (!isBetween(val, {max: maxVal, min: MIN_VALUE})) {
    return {
      code: 'invalid_out_range',
      field,
      max: maxVal,
      min: MIN_VALUE,
    };
  }

  return null;
}
