import produce from "immer";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { INFO_NEGATIVE_VALUE_COMMENTS, INFO_REPORT_VARIANCE } from "psims/constants/info-messages";
import { INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH, INVALID_COMMENTS_REQUIRED } from "psims/constants/validation-messages";
import { isEmpty } from "psims/lib/empty";
import { asNumber } from "psims/lib/number";
import { StockProduct } from "psims/models/ref-data/stock-product";
import { StockProductGroup } from "psims/models/ref-data/stock-product-group";
import { StockType } from "psims/models/ref-data/stock-type";
import { removeServerFields, Stockholding, StockholdingSubmission, UpdateStockholdingComment, UpdateStockholdingDomestic, UpdateStockholdingSubmissionDomestic } from "psims/models/submission-types/stockholding";
import { DomesticRowField, DomesticRowFocusField, existsAndIsNegativeNumber, FocusField, LocationErrorMessage } from "./shared";
import { CommentsErrorMessage, CommentsRequiredErrorMessage, InfoNegativeValueMessage, InfoPreviouslyReportedMessage, InfoReportVarianceMessage, QuantityInvalidErrorMessage } from 'psims/react/pages/primary-pages/data-submissions/shared/messages';
import { validateQuantity } from 'psims/react/pages/primary-pages/data-submissions/shared/validation';
import { UseFocusedField } from "psims/react/util/use-focused-field";
import useProductGroups from './use-stock-product-groups';
import useStockType from "./use-stock-type";
import { isValidationAlert, ValidationAlert, ValidationAlertMaybe } from "psims/models/api/submission/validation-alert";
import { ChangedState } from "../shared/save-state";
import { isValidCommentCharacters, isValidCommentMaxLength } from "psims/lib/validation/comments";
import { encodeEscapeChars } from "psims/lib/validation/string";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { recordActionAsEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { UseTemplateImport } from "psims/react/blocks/import/use-template-import";
import { UpdateStockholdingDomesticVM } from "psims/gen/xapi-client";
import { sumByKey } from "psims/lib/collections";
import { EntityStatus, WithIsExpired } from "../shared/types";
import { UsePortalDataAPI } from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api";
import { DeleteRequestState, idleDeleteRequest } from "../shared/delete-confirmation";
import { focusNext } from "psims/lib/focus-util";

interface UsePageDomesticProps {
    focusedFieldCtrl: UseFocusedField<FocusField>;
    importCtrl: UseTemplateImport<StockholdingSubmission>;
    isActive: boolean;
    portalDataAPICtrl: UsePortalDataAPI;
    stockholding: Stockholding;
}

type TotalMessage = {
    kind: 'PreviouslyReported' | 'ReportVariance';
    message: string;
};

type Messages = {
    [key in DomesticRowField]?: string;
} & {
    total?: TotalMessage;
}

type ProductRowFormData = {
    data: UpdateStockholdingDomestic;
    infoMessages: Messages;
    total: number;
    validationMessages: Messages;
    validationAlerts: Array<ValidationAlert>;
}

export type ProductRow = {
    product: WithIsExpired<StockProduct>;
    form: ProductRowFormData; 
    productStatus: EntityStatus;
}

export type GroupTotals = {
    nsw: number;
    vic: number;
    qld: number;
    sa: number;
    wa: number;
    tas: number;
    nt: number;
    australia: number;
};

type View = {
    dataSubmissionId?: number;
    groups: Array<{
        productGroup: WithIsExpired<StockProductGroup>;
        products: Array<ProductRow>;
        totals: GroupTotals;
        groupStatus: EntityStatus | 'empty';
    }>;
    comments: {
        data: UpdateStockholdingComment | undefined;
        infoMessage?: string;
        validationMessage?: string;
    },
    hasData: boolean;
    hasValidationErrors: boolean;
    isCommentsRequired: boolean;
    messages: {
        errors: Array<ReactNode>;
        infos: Array<ReactNode>;
    };
}

type RowUpdate = {
    stockProductId: number;
    field: DomesticRowField;
    value: string;
}

function usePageDomestic({focusedFieldCtrl, isActive, importCtrl, portalDataAPICtrl, stockholding}: UsePageDomesticProps) {
    const stockType = useStockType({stockTypeName: 'In Australia'});
    const productGroups = useProductGroups({dataSubmission: stockholding.dataSubmission, stockTypeName: 'In Australia'});
    const [changedState, setChangedState] = useState<ChangedState>('unchanged');
    const [deleteRequestState, setDeleteRequestState] = useState<DeleteRequestState>(idleDeleteRequest);

    const domesticStockTypeRef = useUpdatedRef(stockType);

    const [updateSubmissionDomestic, setUpdateSubmissionDomestic] = useState<UpdateStockholdingSubmissionDomestic>(
        updateSubmissionDomesticFromStockholding(stockholding, stockType)
    );

    const deleteProductRow = useCallback((stockProductId: number | undefined, source: HTMLElement | null) => {
        setDeleteRequestState({
            deleteState: 'showing_dialog',
            id: stockProductId,
            message: 'The Australia - held on land record for this product will be deleted.',
            source,
        });
    }, []);

    const view = useMemo<View>(() => {
        const v: View = {
            dataSubmissionId: updateSubmissionDomestic.dataSubmissionId,
            groups: [],
            comments: {
                data: updateSubmissionDomestic.stockholdingComment,
            },
            hasData: updateSubmissionDomestic.stockholdingComment?.comments != null ||
                     (updateSubmissionDomestic.domesticStockholdings?.length || 0) > 0,
            hasValidationErrors: false,
            isCommentsRequired: false,
            messages: {
                errors: [],
                infos: [],
            }
        };

        if (!isActive || productGroups.stockProductGroups == null) {
            return v;
        }

        // build form data view by merging product groups and updateSubmission data
        v.groups = productGroups.stockProductGroups
            .map(spg => {
                let hasNonEmpty = false;
                let hasExpiredWithData = false;

                const products = spg.stockProducts
                    .map(sp => {
                        let data = updateSubmissionDomestic.domesticStockholdings
                            ?.find(ds => ds.stockProductId === sp.id);

                        let hasData = true;

                        if (data == null) {
                            hasData = false;
                            data = {
                                stockProductId: sp.id,
                            };
                        }
                        
                        v.hasData = v.hasData || hasData;
                        let total = totalForRow(data);
                        let dataId = data.id;

                        const validationAlerts = stockholding.domesticStockholdings
                            ?.find(ds => ds.id === dataId)
                            ?.validationAlerts
                            ?.filter<ValidationAlert>(((maybe) => isValidationAlert(maybe)) as TypeAssertion<ValidationAlertMaybe, ValidationAlert>) || [];

                        const infoMessages = infoMessagesForRow(data, validationAlerts, stockholding.dataSubmission.reportVariances?.domesticStockholdings);
                        if (!isEmpty(infoMessages)) {
                            v.isCommentsRequired = true;
                            // Add Info message components for page
                            v.messages.infos = [
                                ...v.messages.infos,
                                ...infoMessageComponents(
                                    infoMessages,
                                    sp,
                                    ({field}) => focusedFieldCtrl.setFocusedField({stockProductId: asNumber(sp.id), field}),
                                    () => focusedFieldCtrl.setFocusedField('comments'),
                                    stockholding.dataSubmission.reportVariances?.domesticStockholdings
                                )
                            ]
                        }

                        const validationMessages = validationMessagesForRow(data);
                        if (!isEmpty(validationMessages)) {
                            v.hasValidationErrors = true;
                            // Add Error message component for page
                            v.messages.errors = [
                                ...v.messages.errors,
                                ...errorMessageComponents(
                                    validationMessages,
                                    sp,
                                    ({field}) => focusedFieldCtrl.setFocusedField({stockProductId: asNumber(sp.id), field})
                                )                 
                            ];
                        }
                        
                        const productStatus: EntityStatus = !sp.isExpired ? 'active' :
                            (isEmpty(data) || data.id === undefined || data.id == null|| recordActionFromEnum(data.recordAction) === 'Delete') ?
                                'expired' : 'expired_with_data';

                        if (productStatus === 'expired_with_data') {
                            hasNonEmpty = true;
                            hasExpiredWithData = true;
                            v.messages.errors = [
                                ...v.messages.errors,
                                expiredProductMessageComponent(
                                    sp, 
                                    ({field}) => focusedFieldCtrl.setFocusedField({stockProductId: asNumber(sp.id), field}),
                                    () => deleteProductRow(sp.id, null)
                                )
                            ]
                        } 
                        if (productStatus === 'active') {
                            hasNonEmpty = true;
                        } 

                        // Add validation/info to row
                        return {
                            productStatus,
                            product: sp,
                            form: {
                                data,
                                infoMessages: infoMessages,
                                total,
                                validationMessages: validationMessages,
                                validationAlerts,
                            }
                        }
                    })
 
            return {
                productGroup: spg,
                products,
                groupStatus:  spg.isExpired ?
                    hasExpiredWithData ? 'expired_with_data' : 'expired' :
                    hasNonEmpty ? 'active' : 'empty',
                totals: {
                    australia: sumByKey(products.map(x => x.form), 'total'),
                    nsw: sumByKey(products.map(x => x.form.data), 'nswStockVolume'),
                    nt: sumByKey(products.map(x => x.form.data), 'ntStockVolume'),
                    qld: sumByKey(products.map(x => x.form.data), 'qldStockVolume'),
                    sa: sumByKey(products.map(x => x.form.data), 'saStockVolume'),
                    tas: sumByKey(products.map(x => x.form.data), 'tasStockVolume'),
                    vic: sumByKey(products.map(x => x.form.data), 'vicStockVolume'),
                    wa: sumByKey(products.map(x => x.form.data), 'waStockVolume'),
                }
            };
        });

        // Validate comments
        const trimmedComments = (v.comments.data?.comments || '').trim();
        if (v.isCommentsRequired && !trimmedComments) {
            v.comments.validationMessage = INVALID_COMMENTS_REQUIRED;
            v.hasValidationErrors = true;
            v.messages.errors = [
                ...v.messages.errors,
                <CommentsRequiredErrorMessage onTargetClick={() => focusedFieldCtrl.setFocusedField('comments')} />
            ];
        } else if (v.comments.data?.comments && trimmedComments.length > 0) {
            if (!isValidCommentMaxLength(trimmedComments)) {
                v.comments.validationMessage = INVALID_COMMENTS_LENGTH;
                v.messages.errors = [
                    ...v.messages.errors,
                    <CommentsErrorMessage onTargetClick={() => focusedFieldCtrl.setFocusedField('comments')} />
                ];
            }
            
            if (!v.comments.validationMessage && !isValidCommentCharacters(trimmedComments)) {
                v.comments.validationMessage = INVALID_COMMENTS_CHARACTERS;
                v.messages.errors = [
                    ...v.messages.errors,
                    <CommentsErrorMessage onTargetClick={() => focusedFieldCtrl.setFocusedField('comments')} />
                ];
            }
        }

        return v;
    }, [
        focusedFieldCtrl,
        updateSubmissionDomestic, 
        isActive, 
        productGroups.stockProductGroups, 
        stockholding, 
        deleteProductRow,
    ]);

    // Update row value
    const updateRow = useCallback((update: RowUpdate) => {
        setUpdateSubmissionDomestic(prev => produce(prev, draft => {
            if (draft.domesticStockholdings == null) {
                return;
            }

            setChangedState('unsaved_changes');
            const productIndex = draft.domesticStockholdings.findIndex(ds => ds.stockProductId === update.stockProductId);

            const value = update.value?.replaceAll(',', '');

            // Create row if it doesn't exist
            if (productIndex == null || productIndex < 0) {
                draft.domesticStockholdings = [
                    ...draft.domesticStockholdings,
                    {
                        stockProductId: update.stockProductId,
                        [update.field]: value,
                        recordAction: recordActionAsEnum('Create'),
                    }
                ]
                return;
            }

            draft.domesticStockholdings[productIndex] = {
                ...draft.domesticStockholdings[productIndex],
                [update.field]: value,
                recordAction: recordActionAsEnum((draft.domesticStockholdings[productIndex].id || 0) > 0 ? 'Update' : 'Create'),
            }
        }));
    }, []);

    // Update comments value
    const updateComments = useCallback((comments: string) => {
        setChangedState('unsaved_changes');

        setUpdateSubmissionDomestic(prev => produce(prev, draft => {
            draft.stockholdingComment = {
                ...(draft.stockholdingComment || {}),
                comments,
                stockTypeId: domesticStockTypeRef.current?.id,
                recordAction: recordActionAsEnum(draft.stockholdingComment?.id != null ? (
                    // Set empty comments to Delete recordAction
                        (Boolean(comments) ? 'Update' : 'Delete')
                    ) : (Boolean(comments) ? 'Create' : 'Delete')
                )
            }
        }))
    }, [domesticStockTypeRef]);

    const actionDeleteProductRow = useCallback((stockProductId: number | undefined) => {
        if (stockProductId == null) {
            return;
        }

        setChangedState('unsaved_changes');
        
        setUpdateSubmissionDomestic(prev => produce(prev, draft => {            
            draft.domesticStockholdings = (draft.domesticStockholdings || [])
                .map((ds) => {
                    const isTargetProduct = ds.stockProductId === stockProductId;
                    return {
                        ...ds,
                        recordAction: isTargetProduct ? recordActionAsEnum('Delete') : ds.recordAction,
                    };
                });
        }));
    }, []);

    const getUpdateRequestBody = useCallback(() => {
        const escapedComments: UpdateStockholdingComment | undefined = view.comments.data ?
            {
                ...view.comments.data,
                comments: encodeEscapeChars(view.comments.data?.comments?.trim())
            } :
            undefined;

        // Convert formData back to submissionVM and remove empty rows
        const domesticStockholdings = view.groups
            .map(group => group.products)
            .reduce((memo, products) => [...memo, ...products], [])
            .map(products => products.form.data)
            .filter(d => {
                const {concurrencyToken, id, stockProductId, recordAction, ...rest} = d;
                // Drop any non-persisted stockholdings that were added then deleted
                return id != null || (!isEmpty(rest) && !(id == null && recordActionFromEnum(recordAction) === 'Delete'));
            })
            .map(d => {
                const {concurrencyToken, id, stockProductId, recordAction, ...data} = d;
                return {
                    ...d,
                    nswStockVolume: !isEmpty(d.nswStockVolume) ? Number(d.nswStockVolume) : null,
                    vicStockVolume: !isEmpty(d.vicStockVolume) ? Number(d.vicStockVolume) : null,
                    qldStockVolume: !isEmpty(d.qldStockVolume) ? Number(d.qldStockVolume) : null,
                    waStockVolume: !isEmpty(d.waStockVolume) ? Number(d.waStockVolume) : null,
                    saStockVolume: !isEmpty(d.saStockVolume) ? Number(d.saStockVolume) : null,
                    tasStockVolume: !isEmpty(d.tasStockVolume) ? Number(d.tasStockVolume) : null,
                    ntStockVolume: !isEmpty(d.ntStockVolume) ? Number(d.ntStockVolume) : null,
                    // Add missing create recordActions, e.g. from import
                    recordAction:
                        (d.id == null && d.recordAction == null) ?
                        recordActionAsEnum('Create') :
                            d.id != null && isEmpty(data) ? recordActionAsEnum('Delete') : d.recordAction,
                }
            })
            // Don't send unchanged rows
            .filter(d => d.recordAction != null);
                    
        return {
            dataSubmissionId: view.dataSubmissionId,
            domesticStockholdings,
            stockholdingComment: (
                escapedComments?.id == null && recordActionFromEnum(escapedComments?.recordAction) === 'Delete' ?
                    undefined :
                    escapedComments
            ),
        };
    }, [view]);

    // Update form data when server vm is updated
    useEffect(() => {
        if (isActive) {
            let newChangeState: ChangedState = 'unchanged';
            const currentData = updateSubmissionDomesticFromStockholding(stockholding, domesticStockTypeRef.current);
            if (importCtrl.templateImportState.templateImportDialogState === 'processing' && importCtrl.templateImportState && importCtrl.templateImportState.data && 
                importCtrl.templateImportState.data.domesticStockholdings && importCtrl.templateImportState.data.submissionFormData?.australiaPageSaved !== true) {
                const submissionData = currentData.domesticStockholdings ?? [];
                const submissionCommentData = currentData.stockholdingComment;
                currentData.domesticStockholdings = [];
                const newData: UpdateStockholdingDomesticVM[] = [];
                
                productGroups.stockProductGroups?.forEach(spg => {
                    spg.stockProducts.forEach(sp => {
                        let data = importCtrl.templateImportState.data?.domesticStockholdings?.find(p => p.stockProductId === sp.id);
                        let existingData = submissionData?.find(p => p.stockProductId === sp.id);
                        
                        if (data !== undefined) {    
                            if (existingData !== undefined) {
                                newData.push({
                                    ...data,
                                    id: existingData.id == null ? undefined : existingData.id,
                                    concurrencyToken: existingData.concurrencyToken == null ? undefined : existingData.concurrencyToken,
                                    recordAction: recordActionAsEnum('Update')
                                });
                            } else {
                                newData.push({
                                    ...data,
                                    recordAction: recordActionAsEnum('Create')
                                });
                            }
                        } else {
                            if (existingData !== undefined) {
                                newData.push({
                                    ...existingData,
                                    nswStockVolume: null,
                                    ntStockVolume: null,
                                    qldStockVolume: null,
                                    saStockVolume: null,
                                    tasStockVolume: null,
                                    vicStockVolume: null,
                                    waStockVolume: null,
                                    recordAction: recordActionAsEnum('Delete')
                                });
                            }
                        }
                    });
                });
                currentData.domesticStockholdings = newData;

                if (submissionCommentData !== undefined)
                {
                    currentData.stockholdingComment = {
                        ...submissionCommentData,
                    }
                }
                
                newChangeState = 'unsaved_changes';
            }

            setUpdateSubmissionDomestic(currentData);
            setChangedState(newChangeState);        
        }
    }, [domesticStockTypeRef, importCtrl.templateImportState, isActive, productGroups.stockProductGroups, stockholding]);

    const shouldForceErrors = useMemo(() => (
        importCtrl.templateImportState.templateImportDialogState === 'processing'
    ), [importCtrl.templateImportState.templateImportDialogState]);

    useEffect(() => {
        switch (deleteRequestState.deleteState) {
            case 'cancelled':
                setDeleteRequestState(idleDeleteRequest);
                break;
            case 'confirmed':
                if (deleteRequestState.id !== undefined) {
                    actionDeleteProductRow(deleteRequestState.id);
                    
                    if (deleteRequestState.source != null) {
                        focusNext(deleteRequestState.source);
                    }
                }
                setDeleteRequestState(idleDeleteRequest);
                break;
        }
    }, [actionDeleteProductRow, deleteRequestState]);

    return useMemo(() => ({
        changedState,
        focusedFieldCtrl,
        portalDataAPICtrl,
        shouldForceErrors,
        stockType,
        view,
        getUpdateRequestBody,
        updateComments,
        updateRow,
        deleteProductRow,
        deleteRequestState,
        setDeleteRequestState
    }), [
        changedState,
        focusedFieldCtrl,
        getUpdateRequestBody,
        portalDataAPICtrl,
        shouldForceErrors,
        stockType,
        updateComments,
        updateRow,
        view,
        deleteProductRow,
        deleteRequestState,
        setDeleteRequestState
    ]);
}

export default usePageDomestic;

export type UsePageDomestic = ReturnType<typeof usePageDomestic>;

function expiredProductMessageComponent(
    product: StockProduct, 
    onClick: (args: MessageClickArgs) => any,
    clearRow: () => any
    ): ReactNode {
    const field = 'nswStockVolume';

    //TODO
    //clearRow={clearRow}
    return <LocationErrorMessage
                key={field}
                onClick={() => onClick({
                    field: field as DomesticRowField,
                })}
                productName={product.productName as string}
                validationCode='INACTIVE_INTERNAL_PRODUCT'
            />;
}

function updateSubmissionDomesticFromStockholding(stockholding: Stockholding, stockType: StockType | undefined): UpdateStockholdingSubmissionDomestic {
    let stockholdingComment = stockholding.stockholdingComments
        ?.find(c => c.stockTypeId === stockType?.id && c.recordResult?.rowResult !== 'Deleted');
    if (stockholdingComment != null) {
        stockholdingComment = removeServerFields(stockholdingComment);
    }
    return {
        dataSubmissionId: stockholding.dataSubmission.id,
        stockholdingComment,
        domesticStockholdings: stockholding.domesticStockholdings
            ?.filter(d => d.recordResult?.rowResult !== 'Deleted')
            .map(removeServerFields),
    }
}

function infoMessagesForRow(row: UpdateStockholdingDomestic, validationAlerts: Array<ValidationAlert>, variance?: number | null): Messages {
    const previouslyReportedAlert = validationAlerts.find(va => isValidationAlert(va, 'PreviouslyReportedAlert') && !rowHasNonZero(row));
    const reportVarianceAlert = validationAlerts.find(va => isValidationAlert(va, 'PercentVarianceApplied'));
    return {
        ...(existsAndIsNegativeNumber(row.nswStockVolume) ? {nswStockVolume: INFO_NEGATIVE_VALUE_COMMENTS} : {}),
        ...(existsAndIsNegativeNumber(row.vicStockVolume) ? {vicStockVolume: INFO_NEGATIVE_VALUE_COMMENTS} : {}),
        ...(existsAndIsNegativeNumber(row.qldStockVolume) ? {qldStockVolume: INFO_NEGATIVE_VALUE_COMMENTS} : {}),
        ...(existsAndIsNegativeNumber(row.saStockVolume) ? {saStockVolume: INFO_NEGATIVE_VALUE_COMMENTS} : {}),
        ...(existsAndIsNegativeNumber(row.waStockVolume) ? {waStockVolume: INFO_NEGATIVE_VALUE_COMMENTS} : {}),
        ...(existsAndIsNegativeNumber(row.tasStockVolume) ? {tasStockVolume: INFO_NEGATIVE_VALUE_COMMENTS} : {}),
        ...(existsAndIsNegativeNumber(row.ntStockVolume) ? {ntStockVolume: INFO_NEGATIVE_VALUE_COMMENTS} : {}),
        ...(previouslyReportedAlert ? {total: { kind: 'PreviouslyReported', message: previouslyReportedAlert.message}} : (
            (reportVarianceAlert && recordActionFromEnum(row.recordAction) !== 'Update' && variance != null) ?
            {total: {kind: 'ReportVariance', message: INFO_REPORT_VARIANCE(variance)}} :
            {}
        )),
    }
}

function validationMessagesForRow(row: UpdateStockholdingDomestic): Messages {
    return {
        nswStockVolume: validateQuantity(row.nswStockVolume),
        vicStockVolume: validateQuantity(row.vicStockVolume),
        qldStockVolume: validateQuantity(row.qldStockVolume),
        waStockVolume: validateQuantity(row.waStockVolume),
        saStockVolume: validateQuantity(row.saStockVolume),
        tasStockVolume: validateQuantity(row.tasStockVolume),
        ntStockVolume: validateQuantity(row.ntStockVolume),
    }
}

type MessageClickArgs = {
    field: DomesticRowField;
}

function errorMessageComponents(messages: Messages, product: StockProduct, onClick: (args: MessageClickArgs) => any): Array<ReactNode> {
    const entries = Object.entries(messages)
        .filter(([_, v]) => v != null);

    return [
        ...(entries.map(([field]) => {
            const state = field.replace('StockVolume', '').toUpperCase();
            const label = `${product.productName} in the ${state} column`
            return (
            <QuantityInvalidErrorMessage
                key={field}
                onClick={() => onClick({
                    field: field as DomesticRowField,
                })}
                label={label}
            />
            );
            }))
    ]
}

function infoMessageComponents(
    messages: Messages,
    product: StockProduct,
    onClick: (args: MessageClickArgs) => any,
    onCommentClick: () => any,
    variance?: number | null
): Array<ReactNode> {
    const entries = Object.entries(messages)
        .filter(([_, v]) => v != null);

    return [
        ...(entries.map(([field, value]) => {
            if (field === 'total' && isTotalMessage(value)) {            
                return (
                    value.kind === 'PreviouslyReported' ?
                    <InfoPreviouslyReportedMessage
                        onCommentTargetClick={onCommentClick}
                        onTargetClick={() => onClick({
                            field: 'nswStockVolume',
                        })}
                        label={product.productName as string}
                        reportingTypeLabel='stockholding'
                    /> : (
                        (value.kind === 'ReportVariance' && variance != null) ?
                        <InfoReportVarianceMessage
                            onCommentTargetClick={onCommentClick}
                            onTargetClick={() => onClick({
                                field: 'nswStockVolume',
                            })}
                            label={product.productName as string}
                            variance={variance}
                        /> : null
                    )
                );
            } else {
                const state = field.replace('StockVolume', '').toUpperCase();
                const label = `${product.productName} in the ${state} column`;
                return (
                    <InfoNegativeValueMessage
                        onCommentTargetClick={onCommentClick}
                        onTargetClick={() => onClick({
                            field: field as DomesticRowField,
                        })}
                        label={label}
                    />);
            }
        }))
    ]
}

export function isRowFocusField(maybe: FocusField | null): maybe is DomesticRowFocusField {
    return maybe != null && (maybe as DomesticRowFocusField).field != null;
}

function totalForRow(row: UpdateStockholdingDomestic) {
    return asNumber(row.nswStockVolume) +
            asNumber(row.vicStockVolume) +
            asNumber(row.qldStockVolume) +
            asNumber(row.saStockVolume) +
            asNumber(row.waStockVolume) +
            asNumber(row.tasStockVolume) +
            asNumber(row.ntStockVolume);
}

function rowHasNonZero(row: UpdateStockholdingDomestic) {
    return asNumber(row.nswStockVolume) !== 0 ||
           asNumber(row.vicStockVolume) !== 0 ||
           asNumber(row.qldStockVolume) !== 0 ||
           asNumber(row.saStockVolume) !== 0 ||
           asNumber(row.waStockVolume) !== 0 ||
           asNumber(row.tasStockVolume) !== 0 ||
           asNumber(row.ntStockVolume) !== 0;
}

function isTotalMessage(maybe?: unknown): maybe is TotalMessage {
    const maybeAs = maybe as TotalMessage;

    return (
        maybeAs != null &&
        (maybeAs.kind === 'PreviouslyReported' || maybeAs.kind === 'ReportVariance') &&
        maybeAs.message != null
    );
}
