import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPI } from "psims/react/providers/api";
import { useLogger } from "psims/react/providers/logging";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { Status } from "psims/types/async";
import { isOrganisation, Organisation } from "psims/models/organisation";
import { mapApiResponseToReportingHistoryItems, ReportingHistoryItem } from "./reporting-history-item";

function useReportingHistoryAPI() {
    const {api} = useAPI();
    const logger = useLogger({source: 'useReportingHistoryAPI'});
    const [reportHistoryLoadStatus, setReportHistoriesLoadStatus] = useState<Status>('init');
    const [reportEntitiesLoadStatus, setReportEntitiesLoadStatus] = useState<Status>('init');
    const loggerRef = useUpdatedRef(logger);
    const [reportingHistories, setReportingHistories] = useState<Array<ReportingHistoryItem>> ([]);
    const [reportingEntities, setReportingEntities] = useState<Array<Organisation> | null>(null);

    const fetch = useCallback(() => {

        setReportHistoriesLoadStatus('loading');

        api.getReportingHistory()
        .then(response => {
            if (response !== null && response.isSuccessful && response.result != null) {
                setReportingHistories(mapApiResponseToReportingHistoryItems(response.result));

                setReportHistoriesLoadStatus('fulfilled');
            } else {
                setReportHistoriesLoadStatus('error');
            }
        })
        .catch(() => {
            loggerRef.current.warn('Failed to fetch reporting history');
            setReportHistoriesLoadStatus('error');
        });

        setReportEntitiesLoadStatus('loading');

        api.getOrganisations()
        .then(response => {
            if (response !== null && response.isSuccessful && response.result != null) {
                setReportingEntities(response.result.filter(isOrganisation).sort((a, b) => {
                    return a.organisationType.localeCompare(b.organisationType) || a.name.localeCompare(b.name);
                }));

                setReportEntitiesLoadStatus('fulfilled');
            } else {
                setReportEntitiesLoadStatus('error');
            }
        })
        .catch(() => {
            loggerRef.current.warn('Failed to fetch reporting entities');
            setReportEntitiesLoadStatus('error');
        });
    }, [api, loggerRef]);

    const loadStatus = useMemo(() => {
        if(reportHistoryLoadStatus === 'error' || reportEntitiesLoadStatus ==='error'){
            return 'error';
        }
        if(reportHistoryLoadStatus === 'fulfilled' && reportEntitiesLoadStatus ==='fulfilled'){
            return 'fulfilled';
        }

        return 'loading';

    }, [reportHistoryLoadStatus, reportEntitiesLoadStatus]);

    useEffect(() => {
        fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        loadStatus,
        reportingHistories,
        reportingEntities
    }
}

export default useReportingHistoryAPI;