export const SELECTIONS = [
  {
    dataKey: 'productGroupData' as 'productGroupData',
    label: 'Biofuel production - product groups',
    submissionTypeName: 'Biofuel production',
  },
  {
    dataKey: 'productData' as 'productData',
    label: 'Biofuel production - products',
    submissionTypeName: 'Biofuel production',
  },
  {
    dataKey: 'productGroupData' as 'productGroupData',
    label: 'Field production - product groups',
    submissionTypeName: 'Field production',
  },
  {
    dataKey: 'productData' as 'productData',
    label: 'Field production - products',
    submissionTypeName: 'Field production',
  },
  {
    dataKey: 'productGroupData' as 'productGroupData',
    label: 'Plant production - product groups',
    submissionTypeName: 'Plant production',
  },
  {
    dataKey: 'productData' as 'productData',
    label: 'Plant production - products',
    submissionTypeName: 'Plant production',
  },
  {
    dataKey: 'productGroupData' as 'productGroupData',
    label: 'Stockholding - product groups',
    submissionTypeName: 'Stockholding',
  },
  {
    dataKey: 'productData' as 'productData',
    label: 'Stockholding - products',
    submissionTypeName: 'Stockholding',
  },
  {
    dataKey: 'productGroupData' as 'productGroupData',
    label: 'Refinery - product groups',
    submissionTypeName: 'Refining',
  },
  {
    dataKey: 'productData' as 'productData',
    label: 'Refinery - products',
    submissionTypeName: 'Refining',
  },
  {
    dataKey: 'productGroupData' as 'productGroupData',
    label: 'Wholesaling - product groups',
    submissionTypeName: 'Wholesaling',
  },
  {
    dataKey: 'productData' as 'productData',
    label: 'Wholesaling - products',
    submissionTypeName: 'Wholesaling',
  },
  {
    dataKey: 'productData' as 'productData',
    label: 'DEF - products',
    submissionTypeName: 'Diesel exhaust fluid',
  },
  {
    dataKey: 'productData' as 'productData',
    label: 'FSSP - products',
    submissionTypeName: 'FSSP s19A',
  },
  {
    dataKey: 'productData' as 'productData',
    label: 'MSO - products',
    submissionTypeName: 'MSO refiner',
  }// we are using MSO refiner as a proxy for MSO importer 
]