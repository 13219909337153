import { UseEditCompany } from "./use-edit-company";
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import Button from 'psims/react/components/button';
import Divider from 'psims/react/components/divider';
import Notification from 'psims/react/components/notification';
import { H2, H3, H4 } from 'psims/react/components/typography';
import Box from 'psims/react/components/box';
import { PropertyLabel, PropertyRow, PropertyValue } from "psims/react/components/labeled-property";
import EditCompanyContact from "./edit-company-contact";
import Trash from "psims/react/components/icons/trash";
import {InputNew as Input} from "psims/react/components/input";
import { asString } from "psims/lib/string";
import Select from "psims/react/components/select";
import { formatAbn } from "psims/lib/formatters/abn";
import { formatAcn } from "psims/lib/formatters/acn";
import { formatArbn } from "psims/lib/formatters/arbn";

interface EditCompanyProps {
    ctrl: UseEditCompany;
}

const EditCompany = (props: EditCompanyProps) => {
    const vm = useVM(props);

    if (vm.updateOrganisation === null) {
        return null;
    }

    const editCompanyCaption = 'Company contact details';

    return (
        <BoxedDiv box={{flex: 'column'}}>
            <H2>{editCompanyCaption}</H2>

            <Box alignItems='flex-start' flex='column' marginTop='md'>
                <PropertyRow>
                    <PropertyLabel>Company name</PropertyLabel>
                    <PropertyValue>{vm.updateOrganisation?.name}</PropertyValue>
                </PropertyRow>

                {
                    Boolean(vm.updateOrganisation?.abn) &&
                    <PropertyRow>
                        <PropertyLabel>ABN</PropertyLabel>
                        <PropertyValue>{vm.updateOrganisation?.abn ? formatAbn(vm.updateOrganisation.abn) : ''}</PropertyValue>
                    </PropertyRow>
                }

                {
                    Boolean(vm.updateOrganisation?.acn) &&
                    <PropertyRow>
                        <PropertyLabel>ACN</PropertyLabel>
                        <PropertyValue>{vm.updateOrganisation?.acn ? formatAcn(vm.updateOrganisation.acn) : ''}</PropertyValue>
                    </PropertyRow>
                }

                {
                    Boolean(vm.updateOrganisation?.arbn) &&
                    <PropertyRow>
                        <PropertyLabel>ARBN</PropertyLabel>
                        <PropertyValue>{vm.updateOrganisation?.arbn ? formatArbn(vm.updateOrganisation.arbn) : ''}</PropertyValue>
                    </PropertyRow>
                }

                <PropertyRow>
                    <PropertyLabel>Type</PropertyLabel>
                    <PropertyValue>{vm.updateOrganisation?.organisationType}</PropertyValue>
                </PropertyRow>
            </Box>

            <div>
            <BoxedDiv box={{flex: 'row'}}>
                    <BoxedDiv box={{flex: 'column', flexGrow: 1}}>
                        <Input
                            id='companyAddressLine1'
                            error={vm.validationMessages.addressLine1}
                            label='Address line 1'
                            onChange={e => vm.editOrganisation('addressLine1', e.target.value)}
                            value={asString(vm.updateOrganisation?.addressLine1)}
                            forceError={vm.saveAttempted}
                        />
                    </BoxedDiv>
                </BoxedDiv>

                {
                    vm.updateOrganisation?.addressLine1 &&
                    <BoxedDiv box={{flex: 'row'}}>
                        <BoxedDiv box={{flex: 'column', flexGrow: 1}}>
                            <Input
                                id='companyAddressLine2'
                                error={vm.validationMessages.addressLine2}
                                label='Address line 2 (Optional)'
                                onChange={e => vm.editOrganisation('addressLine2', e.target.value)}
                                value={vm.updateOrganisation?.addressLine1 ? asString(vm.updateOrganisation?.addressLine2) : ''}
                                forceError={vm.saveAttempted}
                            />
                        </BoxedDiv>
                    </BoxedDiv>
                }

                <BoxedDiv box={{flex: 'column'}}>
                    <BoxedDiv box={{flex: 'row'}}>
                        <Input
                            id='companycity'
                            error={vm.validationMessages.city}
                            label='Suburb'
                            flexGrow={3}
                            marginRight={3}
                            onChange={e => vm.editOrganisation('city', e.target.value)}
                            value={asString(vm.updateOrganisation?.city)}
                            forceError={vm.saveAttempted}
                        />
                        <Select
                            error={vm.validationMessages.stateId}
                            id='companyState'
                            label='State'
                            flexGrow={2}
                            marginLeft={3}
                            marginRight={3}
                            placeholder='Select'
                            options={vm.selectStateCtrl.options}
                            onChange={vm.selectStateCtrl.onChange}
                            value={vm.selectStateCtrl.value}
                            forceError={vm.saveAttempted}
                        />
                        <Input
                            id='companyPostcode'
                            error={vm.validationMessages.postcode}
                            label='Postcode'
                            marginLeft={3}
                            flexGrow={1}
                            onChange={e => vm.editOrganisation('postcode', e.target.value)}
                            value={asString(vm.updateOrganisation?.postcode)}
                            forceError={vm.saveAttempted}
                        />
                    </BoxedDiv>
                </BoxedDiv>
            </div>
            <H3 marginTop={5}>Contact details</H3> 

            <BoxedDiv box={{flex: 'row', alignItems: "flex-start", marginTop: 2, justifyContent: 'space-between'}} >
                <H4>Primary contact</H4>
            </BoxedDiv>

            <EditCompanyContact contact={vm.primaryContact} ctrl={vm.editCompanyPrimaryContactControl} saveAttempted={vm.saveAttempted}></EditCompanyContact>
            
            {
                <BoxedDiv box={{flex: 'row', alignItems: "flex-start", marginTop: 5, justifyContent: 'space-between'}} >
                    <H4>Secondary contact</H4>
                    {
                        vm.secondaryContact?.id &&
                        <Button $kind="text" onClick={() => {vm.removeSecondaryContact()}}><BoxedSpan box={{marginRight: 1}}><Trash size="sm"/></BoxedSpan> Delete secondary contact </Button>
                    }
                </BoxedDiv>
            }
            
            {
                <EditCompanyContact contact={vm.secondaryContact} ctrl={vm.editCompanySecondaryContactControl} saveAttempted={vm.saveAttempted}></EditCompanyContact>
            }

            <BoxedDiv box={{marginTop: 6}}>
                <Divider />
            </BoxedDiv>

            {
                vm.serviceError &&

                <BoxedDiv box={{marginV: 1}}>
                    <Notification align='center' kind='warning'>
                        {vm.serviceError}
                    </Notification>
                </BoxedDiv>
            }


            <BoxedDiv box={{flex: 'row-reverse', justifyContent: 'flex-start', marginTop: 3.5}}>
                {
                    <Button
                        disabled={vm.serviceStatus === 'loading'}
                        $kind='primary'
                        marginLeft={6}
                        onClick={vm.save}
                        state={vm.serviceStatus === 'loading' ? 'loading' : 'normal'}
                    >Save</Button>
                }

                <Button $kind='text' onClick={vm.cancel}>Cancel</Button>
            </BoxedDiv>
        </BoxedDiv>
    )
}

function useVM(
    {
        ctrl: {
                updateOrganisation, 
                cancel, 
                isCompanyDetails, 
                save, 
                removeSecondaryContact,
                saveAttempted, 
                selectStateCtrl,
                editOrganisation,
                serviceError, 
                serviceStatus, 
                primaryContact,
                secondaryContact,
                validationMessages,
                editCompanyPrimaryContactControl,
                editCompanySecondaryContactControl
            }}: EditCompanyProps) {
    return {
        cancel,
        isCompanyDetails,
        save,
        removeSecondaryContact,
        saveAttempted,
        selectStateCtrl,
        editOrganisation,
        serviceError,
        serviceStatus,
        updateOrganisation,
        primaryContact,
        secondaryContact,
        validationMessages,
        editCompanyPrimaryContactControl,
        editCompanySecondaryContactControl
    };
}

export default EditCompany;