import { useMemo, useState } from "react";

import { INVALID_ROLE_TYPE, INVALID_STATE } from "psims/constants/validation-messages";
import { validateEmail, validateFirstName, validatePhone, validateAddress1, validateAddress2, validateLastName, validateCity, validatePostcode, isAddressSectionEmpty } from "psims/lib/validation/user";
import { isUser, UpdateUser, User } from "psims/models/user";
import { useReferenceData } from "psims/react/providers/api/reference-data";
import { asString } from "psims/lib/string";
import { Status } from "psims/types/async";
import { isEmpty } from "psims/lib/empty";
import { useAPI } from "psims/react/providers/api";
import { useSelectController } from "psims/react/components/select";
import { encodeEscapeChars } from "psims/lib/validation/string";
import { isForbiddenError, stringifyForbiddenWAFError } from "psims/lib/server-error";

export type ValidationMessages = {
    [key in keyof User]?: string;
}

interface UseEditUserProps {
    onSuccess: (user: User) => any;
    isMyDetails?: boolean;
}

function useEditUser({onSuccess, isMyDetails}: UseEditUserProps) {
    const { api } = useAPI();
    const {data: refData} = useReferenceData();
    const {roleTypes, states, userStatuses} = refData || {};

    const [user, setUser] = useState<User | null>(null);
    const [serviceError, setServiceError] = useState<string | null>(null);
    const [serviceStatus, setServiceStatus] = useState<Status>('init');
    const [saveAttempted, setSaveAttempted] = useState(false);

    const roleOptions = useMemo(() => {
        if (roleTypes == null) {
            return [];
        }

        return roleTypes.map(roleType => ({
            data: roleType,
            label: asString(roleType.name),
            value: Number(roleType.id),
        }));
    }, [roleTypes]);

    const stateOptions = useMemo(() => {
        if (states == null) {
            return [];
        }

        return [
            {
              data: null,
              label: 'Select',
              value: null,
            },
            ...(states
            .filter(state => state.name !== 'Other')
            .map(state => ({
                data: state,
                label: asString(state.name),
                value: state.id as number,
            })))
        ];
    }, [states]);

    const statusOptions = useMemo(() => {
        if (userStatuses == null) {
            return [];
        }

        return userStatuses
            // .filter(userStatus => userStatus.name === 'New')
            .map(userStatus => ({
                data: userStatus,
                label: asString(userStatus.name),
                value: Number(userStatus.id),
            }));
    }, [userStatuses]);

    const validationMessages = useMemo(() => {
        const messages: ValidationMessages = {};
        
        const emailMsg = validateEmail(user?.email);
        if (emailMsg !== null) {
            messages.email = emailMsg;
        }

        const firstNameMsg = validateFirstName(user?.firstName);
        if (firstNameMsg !== null) {
            messages.firstName = firstNameMsg;
        }

        const lastNameMsg = validateLastName(user?.lastName);
        if (lastNameMsg !== null) {
            messages.lastName = lastNameMsg;
        }

        if (!Boolean(user?.roleTypeId)) {
            messages.roleType = INVALID_ROLE_TYPE;
        }

        // See PBI 359720 - this validation must occur last, as it
        // returns messages early for empty address details
        if (user?.status === 'Registered') {
            const phoneMsg = validatePhone(user?.phoneNumber);
            if (phoneMsg !== null) {
                messages.phoneNumber = phoneMsg;
            }

            // For address - only apply mandatory validation if at least one candidate field is populated.
            if (isAddressSectionEmpty({
                ...user,
                suburb: user.city,
            })){
                return messages;
            }

            const address1Msg = validateAddress1(user?.addressLine1);
            if (address1Msg !== null) {
                messages.addressLine1 = address1Msg;
            }

            const address2Msg = validateAddress2(user?.addressLine2, user?.addressLine1);
            if (address2Msg !== null) {
                messages.addressLine2 = address2Msg;
            }

            const cityMsg = validateCity(user?.city);
            if (cityMsg !== null) {
                messages.city = cityMsg;
            }

            if (!Boolean(user?.stateId)) {
                messages.stateId = INVALID_STATE;
            }

            const postcodeMsg = validatePostcode(user?.postcode);
            if (postcodeMsg !== null) {
                messages.postcode = postcodeMsg;
            }
        }

        return messages;
    }, [user]);

    function updateUser<K extends keyof User>(field: K, value: User[K]) {
        setUser(old => {
            if (old === null) {
                return null;
            }

            return {
                ...old,
                [field]: value,
            };
        });
    }

    const selectRoleCtrl = useSelectController({
        value: user?.roleTypeId || null,
        onChange: roleId => updateUser('roleTypeId', roleId || undefined),
        options: roleOptions,
    });

    const selectStateCtrl = useSelectController({
        value: user?.stateId || null,
        onChange: stateId => updateUser('stateId', stateId || undefined),
        options: stateOptions,
    });

    function save() {
        setSaveAttempted(true);

        if (isEmpty(validationMessages) && user !== null) {
            setServiceError(null);
            setServiceStatus('loading');
            var updateUser = toCreateUser(user);
            updateUser.id = Boolean(isMyDetails) ? undefined : updateUser.id;

            const userEncoded = {
                ...updateUser,
                addressLine1: encodeEscapeChars(updateUser.addressLine1),
                addressLine2: encodeEscapeChars(updateUser.addressLine2),
                city: encodeEscapeChars(updateUser.city),
                firstName: encodeEscapeChars(updateUser.firstName),
                lastName: encodeEscapeChars(updateUser.lastName)
            };

            api.updateUser({requestBody: userEncoded})
            .then(response => {
                if (response?.isSuccessful && isUser(response.result)) {
                    onSuccess(response.result);
                    setUser(null);
                    setServiceStatus('fulfilled');
                }
            })
            .catch(e => {
                const message = isForbiddenError(e) ?
                            stringifyForbiddenWAFError(e) :
                            `Failed to save user:  ${e}`;
                setServiceError(message);
                setServiceStatus('error');
            });
        }
    }

    function cancel() {
        setUser(null);
        setSaveAttempted(false);
        setServiceError(null);
        setServiceStatus('init');
    }

    function selectUser(selectedUser: User) {
        setUser(selectedUser);
    }

    return {
        cancel,
        roleOptions,
        save,
        saveAttempted,
        serviceError,
        serviceStatus,
        selectRoleCtrl,
        selectStateCtrl,
        selectUser,
        stateOptions,
        statusOptions,
        updateUser,
        user,
        validationMessages,
        isMyDetails,
    };
}

export default useEditUser;

export type UseEditUser = ReturnType<typeof useEditUser>;

function toCreateUser(user: User): UpdateUser {
    if (user.id === null) {
        throw new Error('User ID required to update user');
    }
    const createUser: UpdateUser = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        roleTypeId: user.roleTypeId,
        concurrencyToken: user.concurrencyToken,
    };

    if (user.status === 'Registered') {
        createUser.addressLine1 = user.addressLine1;
        createUser.addressLine2 = user.addressLine2;
        createUser.city = user.city;
        createUser.postcode = user.postcode;
        createUser.phoneNumber = user.phoneNumber;
        createUser.stateId = user.stateId;
    }

    return createUser;
}