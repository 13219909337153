/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MsoRefinerSubmissionVMApiResponse } from '../models/MsoRefinerSubmissionVMApiResponse';
import type { UpdateDataSubmissionVM } from '../models/UpdateDataSubmissionVM';
import type { UpdateMsoRefinerSubmissionVM } from '../models/UpdateMsoRefinerSubmissionVM';
import { request as __request } from '../core/request';

export class MsoRefinerService {

    /**
     * @returns MsoRefinerSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async getMsoRefinerSubmission({
id,
}: {
id?: number,
}): Promise<MsoRefinerSubmissionVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/mso-refiner`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns MsoRefinerSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateMsoRefiner({
requestBody,
}: {
requestBody?: UpdateMsoRefinerSubmissionVM,
}): Promise<MsoRefinerSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-refiner/Update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoRefinerSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateMsoRefinerDataSubmission({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoRefinerSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-refiner/Update-DataSubmission`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoRefinerSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async submitMsoRefiner({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoRefinerSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-refiner/Submit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoRefinerSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async clearAllMsoRefiner({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoRefinerSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-refiner/Clear-All`,
            body: requestBody,
        });
        return result.body;
    }

}