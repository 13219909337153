import { SpacingProps, spacingStyle } from 'psims/style/spacing';
import React, { forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components';

import './text.css';

const VARIANTS = {
	'field-error': {
		color: 'var(--color-negative-100)',
	},
	help: {
		color: 'var(--color-black-70)',
		size: 'var(--font-size-140)',
	},
	muted: {
		color: 'var(--color-black-70)',
	},
}

type Variant = keyof typeof VARIANTS;

const WEIGHTS = {
	normal: 400,
	semibold: 600,
	bold: 700,
};

type Weight = keyof typeof WEIGHTS;

interface TextProps  {
	$align?: 'center' | 'left' | 'right';
	className?: string;
	$color?: 'black-100' | 'black-90' | 'black-80' | 'blue-70' | 'link' | 'red-70' | 'red-100' | 'white';
	fullWidth?: boolean;
	$noWrap?: boolean;
	$size?: 12 | 13 | 14 | 16 | 18 | 21 | 24 | 32 | 36 | 44 | 48;
	spacing?: SpacingProps;
	variant?: Variant;
	weight?: Weight;
};

const textStyle = (props: TextProps) => ({
	...(props.spacing ? spacingStyle(props.spacing) : {}),
	...(props.$align ? {textAlign: props.$align} : {}),
	...(props.$color ? {color: `var(--color-${props.$color})`} : {}),
	...(props.fullWidth ? {width: '100%'} : {}),
	...(props.$noWrap ? {
		['whiteSpace' as 'WhiteSpace']: 'nowrap',
		overflow: 'hidden',
		'text-overflow': 'ellipsis',
	} : {}),
	...(props.$size ? {fontSize: `${props.$size}px`} : {}),
	...(props.variant ? VARIANTS[props.variant] : {}),
	...(props.weight ? {fontWeight: WEIGHTS[props.weight]} : {}),
});

// const StyledText = styled(
// 	({color, noWrap,spacing, size, ...passedProps}: React.PropsWithRef<React.PropsWithChildren<TextProps> & {ref: React.ForwardedRef<HTMLSpanElement>}>) => <span {...passedProps}/>)(textStyle);
const StyledText = styled.span(textStyle);

const Text = forwardRef<HTMLSpanElement, PropsWithChildren<TextProps & React.HTMLAttributes<HTMLSpanElement>>> ((props, ref) => {
	const {$align, className, color, fullWidth, $noWrap, $size, spacing, variant, weight, ...rest} = props;

	const styleProps = {$align, color, fullWidth, $noWrap, $size, spacing, variant, weight};

	return (
		<StyledText {...styleProps} className={`text${className ? ' ' + className : ''}`} {...rest} ref={ref}>{props.children}</StyledText>
	);
});

export default Text;
