import { RoleTypeDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { prefixedThrower } from "psims/lib/exception";

const ROLE_NAMES = ['Company Admin', 'Reporter', 'LFG Portal Admin'] as const;

export type RoleTypeName = typeof ROLE_NAMES[number];

export type RoleType = PopulatedProps<RoleTypeDTO & {name: RoleTypeName}, 'id' | 'name'>

export function isRoleTypeName(maybe?: unknown): maybe is RoleTypeName {
    return typeof maybe === 'string' && ROLE_NAMES.includes(maybe as RoleTypeName);
}

export function assertRoleType(maybe?: unknown): asserts maybe is RoleType {
    const asRoleType = maybe as RoleType;

    const err = prefixedThrower(`Failed to assert roleType: ${JSON.stringify(maybe)}: `);

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asRoleType, hint('roleType'));
    assertNotEmpty(asRoleType.id, hint('roleType.id'));
    assertNotEmpty(asRoleType.name, hint('roleType.name'));

    if (!ROLE_NAMES.includes(asRoleType.name)) err(`unknown type name: ${asRoleType.name}`);
}
