import { isValidCommentMaxLength, isValidCommentCharacters, validateCommentCharacters, validateCommentMaxLength } from "psims/lib/validation/comments";
import { INVALID_VOLUME_RANGE, INVALID_VOLUME_INTEGER, INVALID_COMMENTS_REQUIRED, INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH } from "psims/constants/validation-messages";
import { isBetween, isInteger, isWithinMaxDecimalPlaces } from "psims/lib/validation/number";
import { CommentsErrorMessage, CommentsRequiredErrorMessage } from "./messages";

export type ValidationMessageWithCode<TCode extends string> = {
    message: string;
    code: TCode;
}

const IS_BETWEEN_OPTS = {max: 100000000, min: -100000000};

export function validateQuantity(val: number | null | undefined) {
    return val != null ? (
        (!isBetween(val, IS_BETWEEN_OPTS) ? INVALID_VOLUME_RANGE : undefined) ||
        (!isInteger(val) ? INVALID_VOLUME_INTEGER : undefined)
    ) : undefined;
}

export function validateInRange(val: number | null | undefined, max: number, min: number, msg: string) {
    const validRange = {max, min};
    return val != null ? (
        (!isBetween(val, validRange) ? msg : undefined)
    ) : undefined;
}

export function validateDecimalInRange(val: number | null | undefined, max: number, min: number, decimalPlaces: number, msg: string) {
    if (val == null) {
        return undefined;
    }
    
    const notInRangeMessage = validateInRange(val, max, min, msg);

    if (notInRangeMessage != null) {
        return notInRangeMessage;
    }

    if (!isWithinMaxDecimalPlaces(val, decimalPlaces)) {
        return msg;
    }

    return undefined;
}

export function validateComments(comments: string | null | undefined, onClick: () => any, isRequired?: boolean) {
    const trimmedComments = (comments || '').trim();

    if (isRequired && trimmedComments.length === 0) {
        return { notification: {
            content: CommentsRequiredErrorMessage({
                onTargetClick: onClick,
            }),
            message: INVALID_COMMENTS_REQUIRED,
        } }
    }

    return !isValidCommentMaxLength(trimmedComments) ? {
        notification: {
            content: CommentsErrorMessage({
                onTargetClick: onClick,
            }),
            message: INVALID_COMMENTS_LENGTH,
        }
    } : !isValidCommentCharacters(trimmedComments) ? {
        notification: {
            content: CommentsErrorMessage({
                onTargetClick: onClick,
            }),
            message: INVALID_COMMENTS_CHARACTERS,
        }
    } : null;
}

export type CommentValidationResult = {
    code: 'invalid_required' | 'invalid_chars' | 'invalid_length';
    message: string;
}

export function validateComment(comments: string | null | undefined, isRequiredMsg?: string): CommentValidationResult | undefined {
    const trimmedComments = (comments || '').trim();
    if (!trimmedComments) {
        if (!isRequiredMsg) {
            return undefined;
        }
        return {
            code: 'invalid_required',
            message: isRequiredMsg
        };
    }

    let message: string | null  = validateCommentMaxLength(trimmedComments)
    if (message != null) {
        return {
            code: 'invalid_length',
            message,
        }
    }
    
    message = validateCommentCharacters(trimmedComments);

    if (message != null) {
        return {
            code: 'invalid_chars',
            message,
        }
    }

    return undefined;
}
