import { MsoSubmissionFormDataVM } from "psims/gen/xapi-client";
import { prefixedThrower } from "psims/lib/exception";

export type MsoAnnualActivitySubmissionFormData = PopulatedProps<
  MsoSubmissionFormDataVM,
  'dataPageSaved' | 'dataSubmissionId' | 'id'
>;

export function assertMsoAnnualActivitySubmissionFormData(maybe?: unknown): asserts maybe is MsoAnnualActivitySubmissionFormData {
  const maybeAs = maybe as MsoAnnualActivitySubmissionFormData;

  const assertionThrower = prefixedThrower('Failed to assert msoAnnualActivitySubmissionFormData: ');

  if (maybeAs == null) {
    assertionThrower('argument is null');
  }

  if (maybeAs.dataSubmissionId == null) {
    assertionThrower('dataSubmissionId is null');
  }

  if (maybeAs.id == null) {
    assertionThrower('id is null');
  }

  if (maybeAs.dataPageSaved == null) {
    assertionThrower('.annualActivityPageSaved is null');
  }
}
