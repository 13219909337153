/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataSubmissionTemplateRequestVM } from '../models/DataSubmissionTemplateRequestVM';
import type { DataSubmissionVMApiResponse } from '../models/DataSubmissionVMApiResponse';
import { request as __request } from '../core/request';

export class DataSubmissionService {

    /**
     * @returns DataSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async getDataSubmissionById({
id,
}: {
id?: number,
}): Promise<DataSubmissionVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/data-submission`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static async dataSubmissionTemplateRequest({
requestBody,
}: {
requestBody?: DataSubmissionTemplateRequestVM,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/data-submission/Template-Request`,
            body: requestBody,
        });
        return result.body;
    }

}