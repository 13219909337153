/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentContentVMApiResponse } from '../models/DocumentContentVMApiResponse';
import type { DocumentListVMApiResponse } from '../models/DocumentListVMApiResponse';
import type { DocumentVMApiResponse } from '../models/DocumentVMApiResponse';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * @returns DocumentListVMApiResponse Success
     * @throws ApiError
     */
    public static async getDocumentList(): Promise<DocumentListVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/document/list`,
        });
        return result.body;
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static async getDocument({
blobName,
}: {
blobName?: string,
}): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/document`,
            query: {
                'blobName': blobName,
            },
        });
        return result.body;
    }

    /**
     * @returns DocumentContentVMApiResponse Success
     * @throws ApiError
     */
    public static async getDocumentWithMetadata({
blobName,
}: {
blobName?: string,
}): Promise<DocumentContentVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/document/get-document`,
            query: {
                'blobName': blobName,
            },
        });
        return result.body;
    }

    /**
     * @returns DocumentVMApiResponse Success
     * @throws ApiError
     */
    public static async postDocumentService({
requestBody,
}: {
requestBody?: any,
}): Promise<DocumentVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/document/upload`,
            body: requestBody,
        });
        return result.body;
    }

}