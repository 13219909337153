import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { extend } from 'psims/react/util/classname';
import FieldContainer, { FieldContainerProps } from './field-container';
import useFocusable from 'psims/react/util/use-focusable';
import FormField, { FormFieldProps } from './form-field';
import { FieldProps, fieldStyle } from 'psims/style/field';
import useIsDirty from 'psims/react/util/use-is-dirty';
import { isEmpty } from 'psims/lib/empty';
import useIsFocused from '../util/use-is-focused';
import UserInputBox from './user-input-box';
import randomID from 'psims/lib/random-id';
import VisuallyHidden from './visually-hidden';

interface InputProps extends FieldContainerProps, React.InputHTMLAttributes<HTMLInputElement> {
	fieldSize?: 'sm' | 'full';
	minWidth?: string;
	setFocused?: boolean;
};

const StyledOldInput = styled.input`${(props: InputProps) => `
	${props.minWidth ? `min-width: ${props.minWidth};` : ''}
	${props.type === 'number' ? 'text-align: right;' : ''}
	width: ${props.fieldSize === 'sm' ? '150px' : '100%'};
`}`;

const Input = (props: InputProps) => {
	const {error, fieldSize, fullWidth, hideErrorMessage, hideLabel, minWidth, label, LabelComponent, setFocused, showError, value, ...rest} = props;

	const {setRef} = useFocusable({setFocused});
	
	return (
		<FieldContainer
			error={error}
			fullWidth={fullWidth}
			hideErrorMessage={hideErrorMessage}
			hideLabel={hideLabel}
			label={label}
			LabelComponent={LabelComponent}
			showError={showError}
		>
			<StyledOldInput
				className={extend('form-field input', props.className)}
				value={isEmpty(value) ? '' : value}
				{...rest}
				ref={setRef}
			/>
		</FieldContainer>
	);
};

export default Input;

interface InputBaseProps extends InputNewProps {
	id: string;
	label: string;
}

const StyledInput = styled.input`${(props: InputBaseProps) => `
`}${fieldStyle}`;

const InputBase = ({borderless, formatter, info, onBlur, onFocus, shadow, type, useTooltip, value, ...props}: InputBaseProps) => {
	const [ref, setRef] = useState<HTMLInputElement | null>(null);
	const {setRef: setFocusableRef} = useFocusable({setFocused: props.setFocused});
	const {isFocused, handleBlur, handleFocus} = useIsFocused({onBlur, onFocus});
	const hasCustomFormatting = typeof formatter === 'function';
    const [floatingId] = useState(randomID());

	const consolidatedRef = useCallback((el: HTMLInputElement | null) => {
		setRef(el);
		setFocusableRef(el);
	}, [setFocusableRef]);

	const displayValue = useMemo(() => {
		return hasCustomFormatting && !isEmpty(value) ? formatter!(value) : value ?? '';
	}, [formatter, hasCustomFormatting, value]);

	useLayoutEffect(() => {
		if (ref != null && isFocused) {
			ref.select();
		}
	}, [ref, isFocused]);

	const showError = props.error != null;

	const renderedInput = <>
	 	{
	 		props.bare ? <VisuallyHidden>{props.label}</VisuallyHidden> : null
		}

		<StyledInput
			aria-invalid={showError ? 'true' : 'false'}
			aria-label={props.label}
			aria-describedby={props['aria-describedby']}
			aria-errormessage={showError ? (useTooltip ? floatingId : `${props.id}_error`) : undefined}
			inputMode={type === 'number' ? 'decimal' : type === 'email' ? 'email' : 'text'}
			title={props.label}
			onBlur={handleBlur}
			onFocus={handleFocus}
			{...props}
			ref={consolidatedRef}
			type={type === 'number' ? 'text' : type}
			value={isFocused ? (value ?? '') : displayValue}
		/>
	</>;

	if (useTooltip) {
		return <UserInputBox
			borderless={borderless}
			error={props.disabled ? undefined :  props.error || undefined}
			floatingId={floatingId}
			info={info || undefined}
			isFocused={isFocused}
			shadow={shadow}
		>
			{renderedInput}
		</UserInputBox>
	}

	return renderedInput;
}

type InputValue = string | number | null | undefined;

interface InputNewProps extends FieldProps, FormFieldProps, Omit<React.InputHTMLAttributes<HTMLInputElement>, 'height' | 'width' | 'value'> {
	bare?: boolean;
	borderless?: boolean;
	error?: string | null;
	useTooltip?: boolean;
	forceError?: boolean;
	formatter?: (val: InputValue) => string;
	hideLabel?: boolean;
	id: string;
	info?: string | null;
	initialDirty?: boolean;
	label: string;
	setFocused?: boolean;
	shadow?: 'inset' | 'outset';
	value: InputValue;
}

export const InputNew = ({bare, error, forceError, initialDirty, onBlur, onFocus, value, ...rest}: InputNewProps) => {
	const dirtyCtrl = useIsDirty(initialDirty, onBlur);
	const passedError = forceError || dirtyCtrl.isDirty ? error : undefined;

	if (bare) {
		return <InputBase bare={bare} error={passedError} onFocus={dirtyCtrl.handleFocus} value={value ?? ''} {...rest} />
	}

	return (
		<FormField error={passedError} {...rest}>
			<InputBase error={passedError} onBlur={dirtyCtrl.handleFocus} value={value ?? ''} {...rest} />
		</FormField>
	);
}
