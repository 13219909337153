import { Kind } from "psims/react/pages/primary-pages/data-submissions/shared/page-notifications";

type NotificationType = 'info' | 'warning';

const KIND_MAP: {[Key in Kind]: NotificationType} = {
    info: 'info',
    system_alert: 'warning',
    validation: 'warning',
};

export const SELECTOR_NOTIFICATIONS = '[data-notifications]' as const;
// Individual notification:
export const SELECTOR_NOTIFICATION = (kind: Kind) => `[data-notification-item=${KIND_MAP[kind]}]` as const;
// Notification class container:
export const SELECTOR_NOTIFICATION_KIND = (kind: Kind) => `[data-notification-kind=${KIND_MAP[kind]}]` as const;
