/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FsspCommitmentVMListApiResponse } from '../models/FsspCommitmentVMListApiResponse';
import { request as __request } from '../core/request';

export class FsspCommitmentService {

    /**
     * @returns FsspCommitmentVMListApiResponse Success
     * @throws ApiError
     */
    public static async getSummary(): Promise<FsspCommitmentVMListApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/fssp-commitment`,
        });
        return result.body;
    }

}