import produce from "immer";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { INFO_NEGATIVE_VALUE_COMMENTS, INFO_REPORT_VARIANCE } from "psims/constants/info-messages";
import { INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH, INVALID_COMMENTS_REQUIRED, INVALID_COUNTRY, INVALID_COUNTRY_CONFLICT, INVALID_COUNTRY_ISINTERNAL_SELECTED, INVALID_QUANTITY_REQUIRED, INVALID_VOLUME_INTEGER, INVALID_VOLUME_RANGE } from "psims/constants/validation-messages";
import { isEmpty } from "psims/lib/empty";
import { asNumber } from "psims/lib/number";
import { isBetween, isInteger } from "psims/lib/validation/number";
import { StockProduct } from "psims/models/ref-data/stock-product";
import { StockProductGroup } from "psims/models/ref-data/stock-product-group";
import { StockType } from "psims/models/ref-data/stock-type";
import { removeServerFields, Stockholding, StockholdingSubmission, UpdateStockholdingComment, UpdateStockholdingOverseas, UpdateStockholdingSubmissionOverseas } from "psims/models/submission-types/stockholding";
import { FocusField, LocationErrorMessage, OverseasRowField, OverseasRowFocusField, StockholdingLocationValidationCode } from "./shared";
import { CommentsErrorMessage, CommentsRequiredErrorMessage, InfoNegativeValueMessage, InfoPreviouslyReportedMessage, InfoReportVarianceMessage } from 'psims/react/pages/primary-pages/data-submissions/shared/messages';
import { UseFocusedField } from "psims/react/util/use-focused-field";
import useProductGroups from './use-stock-product-groups';
import useStockType from "./use-stock-type";
import { isValidationAlert, ValidationAlert, ValidationAlertMaybe } from "psims/models/api/submission/validation-alert";
import { ChangedState } from "../shared/save-state";
import { isValidCommentCharacters, isValidCommentMaxLength } from "psims/lib/validation/comments";
import { encodeEscapeChars } from "psims/lib/validation/string";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { DeleteRequestState, idleDeleteRequest } from "../shared/delete-confirmation";
import { focusNext } from "psims/lib/focus-util";
import { recordActionAsEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { UseTemplateImport } from "psims/react/blocks/import/use-template-import";
import { UpdateStockholdingOverseasVM } from "psims/gen/xapi-client";
import { EntityStatus, WithIsExpired } from "../shared/types";
import { UsePortalDataAPI } from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api";
import { Country } from "psims/models/ref-data/country";


interface UsePageOverseasProps {
    focusedFieldCtrl: UseFocusedField<FocusField>
    importCtrl: UseTemplateImport<StockholdingSubmission>;
    isActive: boolean;
    portalDataAPICtrl: UsePortalDataAPI;
    stockholding: Stockholding;
    countries: Array<Country>;
}


type InfoMessages = {
    [key in OverseasRowField]?: {
        code: 'negativeValue' | 'previouslyReported';
        message: string;
    };
} & {
    total?: {
        code: 'previouslyReported' | 'reportVariance',
        message: string;
    }
};

type ErrorMessages = {
    [key in OverseasRowField]?: {
        code: StockholdingLocationValidationCode;
        message: string;
    };
}

export type ProductRowFormData = {
    data: UpdateStockholdingOverseas;
    errorMessages: ErrorMessages;
    infoMessages: InfoMessages;
    isAddedRow: boolean;
    rowIndex: number;
    validationAlerts: Array<ValidationAlert>;
}

export type Product = {
    product: StockProduct;
    productStatus: EntityStatus;
    rows: Array<ProductRowFormData>; 
    total: number;
}

type View = {
    dataSubmissionId?: number;
    groups: Array<{
        productGroup: WithIsExpired<StockProductGroup>;
        products: Array<Product>
        groupStatus: EntityStatus | 'empty';
    }>;
    comments: {
        data: UpdateStockholdingComment | undefined;
        infoMessage?: string;
        errorMessage?: string;
    },
    hasData: boolean;
    hasValidationErrors: boolean;
    isCommentsRequired: boolean;
    messages: {
        errors: Array<ReactNode>;
        infos: Array<ReactNode>;
    }
}

type RowUpdate = {
    field: OverseasRowField;
    rowIndex: number;
    stockProductId: number;
    value: string | number | undefined;
}

function usePageOverseas({focusedFieldCtrl, isActive, importCtrl, portalDataAPICtrl, stockholding, countries}: UsePageOverseasProps) {
    const stockType = useStockType({stockTypeName: 'Overseas'});
    const productGroups = useProductGroups({dataSubmission: stockholding.dataSubmission, stockTypeName: 'Overseas'});
    const [changedState, setChangedState] = useState<ChangedState>('unchanged');
    const [deleteRequestState, setDeleteRequestState] = useState<DeleteRequestState>(idleDeleteRequest);

    const overseasStockTypeRef = useUpdatedRef(stockType);

    const [updateSubmissionOverseas, setUpdateSubmissionOverseas] = useState<UpdateStockholdingSubmissionOverseas>(
        updateSubmissionOverseasFromStockholding(stockholding, stockType)
    );

    const deleteAllProductRows = useCallback((stockProductId: number | undefined, source: HTMLElement | null) => {
        setDeleteRequestState({
            deleteState: 'showing_dialog',
            id: stockProductId,
            message: 'All overseas location records for this product will be deleted.',
            source,
        });
    }, []);

    const view = useMemo<View>(() => {
        const v: View = {
            dataSubmissionId: updateSubmissionOverseas.dataSubmissionId,
            groups: [],
            comments: {
                data: updateSubmissionOverseas.stockholdingComment,
            },
            hasData: updateSubmissionOverseas.stockholdingComment?.comments != null ||
                     (updateSubmissionOverseas.overseasStockholdings?.length || 0) > 0,
            hasValidationErrors: false,
            isCommentsRequired: false,
            messages: {
                errors: [],
                infos: [],
            }
        };

        if (!isActive || productGroups.stockProductGroups == null) {
            return v;
        }

        // build form data view by merging product groups and updateSubmission data
        v.groups = productGroups.stockProductGroups
        .map(spg => {
            let hasNonEmpty = false;
            let hasExpiredWithData = false;

            const products = spg.stockProducts
                .map(sp => {
                    let data = updateSubmissionOverseas.overseasStockholdings
                        ?.filter(ds => ds.stockProductId === sp.id);

                    let hasData = true;
                    // add empty row if no data exists for product
                    if (data == null || data.length === 0) {
                        hasData = false;
                        data = [{
                                stockProductId: sp.id
                        }];
                    }

                    v.hasData = v.hasData || hasData;
                    const dataNotDeleted = data.filter(d => recordActionFromEnum(d.recordAction) !== 'Delete');
                    const total = dataNotDeleted.reduce((t, d) => t += asNumber(d.quantity), 0);
                    const allZero = dataNotDeleted.reduce((prev, d) => prev && asNumber(d.quantity) === 0, true);
                    const anyRowsChanged = dataNotDeleted.filter(d => recordActionFromEnum(d.recordAction) === 'Update').length > 0;
                    const seenCountryIds = new Set<number>();
                    const osHoldings = (stockholding.overseasStockholdings || [])
                        .filter(h => h.stockProductId === sp.id);


                    // Only 1 previously reported alert per product
                    const productPreviouslyReportedAlerts = osHoldings
                        ?.map(h => h.validationAlerts)
                        ?.reduce((memo, alerts) => [...(memo || []), ...(alerts || [])], [])
                        ?.filter<ValidationAlert>(((maybe) => (
                            isValidationAlert(maybe, 'PreviouslyReportedAlert') ||
                            isValidationAlert(maybe, 'PercentVarianceApplied')
                        )) as TypeAssertion<ValidationAlertMaybe, ValidationAlert>) || [];
                    
                    const productInfoMessages: InfoMessages = {
                        ...(Boolean(productPreviouslyReportedAlerts.find(a => a.validationAlert === 'PreviouslyReportedAlert')) && allZero ? {
                            total: {
                                code: 'previouslyReported',
                                message: productPreviouslyReportedAlerts.find(a => a.validationAlert === 'PreviouslyReportedAlert')!!.message,
                            }
                        } : (Boolean(productPreviouslyReportedAlerts.find(a => a.validationAlert === 'PercentVarianceApplied') && !anyRowsChanged && stockholding.dataSubmission.reportVariance != null) ? {
                            total: {
                                code: 'reportVariance',
                                message: INFO_REPORT_VARIANCE(stockholding.dataSubmission.reportVariances?.overseasStockholdings as number),
                            }
                        } : {}))
                    };

                    const addEmptyRow = (data || []).filter(p => recordActionFromEnum(p.recordAction) !== 'Delete').length === 0;

                    const productStatus: EntityStatus = !sp.isExpired ? 'active' :
                        data.some(d => !isEmpty(d) && d.id !== undefined && d.id != null && recordActionFromEnum(d.recordAction) !== 'Delete') ?
                        'expired_with_data' : 'expired';                       

                    if (productStatus === 'expired_with_data') {
                        hasNonEmpty = true;
                        hasExpiredWithData = true;
                    } 
                    if (productStatus === 'active') {
                        hasNonEmpty = true;
                    } 

                    const rows = [
                        ...data,
                        ...(addEmptyRow ? [{
                            id: -1,
                            stockProductId: sp.id,
                            recordAction: recordActionAsEnum('Create'),
                        }] : [])
                    ].map((row, rowIndex) => {
                        const validationAlerts = osHoldings
                            .filter(h => h.id === row.id)
                            .map(h => h.validationAlerts)
                            .reduce((memo, alerts) => [...(memo || []), ...(alerts || [])], [])
                            ?.filter<ValidationAlert>(((maybe) => isValidationAlert(maybe)) as TypeAssertion<ValidationAlertMaybe, ValidationAlert>) || [];

                        const infoMessages = {
                            ...infoMessagesForRow(row),
                            ...(rowIndex === 0 ? productInfoMessages : {}),
                        };

                        if (!isEmpty(infoMessages)) {
                            v.isCommentsRequired = true;
                            // Add Info message components for page
                            v.messages.infos = [
                                ...v.messages.infos,
                                ...infoMessageComponents(
                                    infoMessages,
                                    sp,
                                    ({field}) => focusedFieldCtrl.setFocusedField({stockProductId: asNumber(sp.id), field, rowIndex}),
                                    () => focusedFieldCtrl.setFocusedField('comments'),
                                    stockholding.dataSubmission.reportVariances?.overseasStockholdings
                                )
                            ]
                        }

                        const errorMessages = errorMessagesForRow(row, seenCountryIds, countries);
                        if (!isEmpty(errorMessages)) {
                            v.hasValidationErrors = true;
                            // Add Error message component for page
                            v.messages.errors = [
                                ...v.messages.errors,
                                ...errorMessageComponents(
                                    errorMessages,
                                    sp,
                                    ({field}) => focusedFieldCtrl.setFocusedField({stockProductId: asNumber(sp.id), field, rowIndex})
                                )
                            ]
                        }

                        if (rowIndex === 0 && productStatus === 'expired_with_data') {
                            v.messages.errors = [
                                ...v.messages.errors,
                                expiredProductMessageComponent(
                                    sp, 
                                    ({field}) => focusedFieldCtrl.setFocusedField({stockProductId: asNumber(sp.id), field, rowIndex}),
                                    () => deleteAllProductRows(sp.id, null)
                                )
                            ]
                        }

                        return {
                            data: row,
                            infoMessages,
                            isAddedRow: row.id === -1,
                            rowIndex,
                            errorMessages,
                            validationAlerts,
                        }
                    });

                    return {
                        product: sp,
                        productStatus,
                        rows,
                        total,
                    }
                });
            
            return {
                products,
                productGroup: spg,
                groupStatus:  spg.isExpired ?
                    hasExpiredWithData ? 'expired_with_data' : 'expired' :
                    hasNonEmpty ? 'active' : 'empty',                
            };
        });

        // Validate comments
        const trimmedComments = (v.comments.data?.comments || '').trim();

        if (v.isCommentsRequired && !trimmedComments) {
            v.comments.errorMessage = INVALID_COMMENTS_REQUIRED;
            v.hasValidationErrors = true;
            v.messages.errors = [
                ...v.messages.errors,
                <CommentsRequiredErrorMessage
                    onTargetClick={() => focusedFieldCtrl.setFocusedField('comments')}
                />
            ];
        } else if (v.comments.data?.comments && trimmedComments.length > 0) {
            if (!isValidCommentMaxLength(trimmedComments)) {
                v.comments.errorMessage = INVALID_COMMENTS_LENGTH;
                v.messages.errors = [
                    ...v.messages.errors,
                    <CommentsErrorMessage onTargetClick={() => focusedFieldCtrl.setFocusedField('comments')} />
                ];
            }
            
            if (!v.comments.errorMessage && !isValidCommentCharacters(trimmedComments)) {
                v.comments.errorMessage = INVALID_COMMENTS_CHARACTERS;
                v.messages.errors = [
                    ...v.messages.errors,
                    <CommentsErrorMessage onTargetClick={() => focusedFieldCtrl.setFocusedField('comments')} />
                ];
            }
        }

        return v;
    }, [
        updateSubmissionOverseas.dataSubmissionId, 
        updateSubmissionOverseas.stockholdingComment, 
        updateSubmissionOverseas.overseasStockholdings, 
        isActive, productGroups.stockProductGroups, 
        stockholding.overseasStockholdings, 
        stockholding.dataSubmission.reportVariance, 
        stockholding.dataSubmission.reportVariances?.overseasStockholdings, 
        countries, 
        focusedFieldCtrl, 
        deleteAllProductRows]);

    // Update row value
    const updateRow = useCallback((update: RowUpdate) => {
        setUpdateSubmissionOverseas(prev => produce(prev, draft => {
            if (draft.overseasStockholdings == null) {
                return;
            }

            let idx = 0;

            setChangedState('unsaved_changes');

            let val = update.field === 'quantity' ? String(update.value).replaceAll(',', '') : update.value;

            for (let i = 0; i < prev.overseasStockholdings!!.length || 0; i++) {
                const holding = prev.overseasStockholdings!![i];
                if (holding.stockProductId === update.stockProductId) {
                    if (idx === update.rowIndex) {
                        draft.overseasStockholdings!![i][update.field] = val as number;
                        draft.overseasStockholdings!![i].recordAction = recordActionAsEnum((draft.overseasStockholdings!![i].id || 0) > 0 ? 'Update' : 'Create');
                        return;
                    }
                    idx++;
                }
            }

            draft.overseasStockholdings?.push({
                stockProductId: update.stockProductId,
                [update.field]: val as number,
                recordAction: recordActionAsEnum('Create'),
            });
        }));
    }, []);

    // Update comments value
    const updateComments = useCallback((comments: string) => {
        setChangedState('unsaved_changes');

        setUpdateSubmissionOverseas(prev => produce(prev, draft => {
            draft.stockholdingComment = {
                ...(draft.stockholdingComment || {}),
                comments,
                stockTypeId: overseasStockTypeRef.current?.id,
                recordAction: recordActionAsEnum(draft.stockholdingComment?.id == null ? 'Create' : 'Update'),
            }
        }))
    }, [overseasStockTypeRef]);

    const addProductRow = useCallback((stockProductId: number | undefined) => {
        if (stockProductId == null) {
            return;
        }

        setChangedState('unsaved_changes');

        setUpdateSubmissionOverseas(prev => produce(prev, draft => {
            draft.overseasStockholdings?.push({
                id: -1,
                stockProductId,
                recordAction: recordActionAsEnum('Create'),
            });
        }));
    }, []);

    const actionDeleteProductRow = useCallback((stockProductId: number | undefined, rowIndex: number) => {
        if (stockProductId == null) {
            return;
        }

        setChangedState('unsaved_changes');
        
        setUpdateSubmissionOverseas(prev => produce(prev, draft => {
            let targetProductIndex = 0;

            draft.overseasStockholdings = (draft.overseasStockholdings || [])
                .map((os) => {
                    const isTargetProduct = os.stockProductId === stockProductId 
                    const exclude = !isTargetProduct || targetProductIndex !== rowIndex
                    targetProductIndex += isTargetProduct ? 1 : 0;
                    return {
                        ...os,
                        recordAction: !exclude ? recordActionAsEnum('Delete') : os.recordAction,
                    };
                });
        }));
    }, []);

    const actionDeleteAllProductRows = useCallback((stockProductId: number | undefined) => {
        if (stockProductId == null) {
            return;
        }

        setChangedState('unsaved_changes');
        
        setUpdateSubmissionOverseas(prev => produce(prev, draft => {            
            draft.overseasStockholdings = (draft.overseasStockholdings || [])
                .map((os) => {
                    const isTargetProduct = os.stockProductId === stockProductId;
                    return {
                        ...os,
                        recordAction: isTargetProduct ? recordActionAsEnum('Delete') : os.recordAction,
                    };
                });
        }));
    }, []);

    const deleteProductRow = useCallback((stockProductId: number | undefined, rowIndex: number, source: HTMLElement | null) => {
        setDeleteRequestState({
            deleteState: 'showing_dialog',
            id: stockProductId,
            rowIndex: rowIndex,
            message: 'This overseas location record will be deleted.',
            source,
        });
    }, []);

    const getUpdateRequestBody = useCallback(() => {
        const escapedComments: UpdateStockholdingComment | undefined = view.comments.data ?
            {
                ...view.comments.data,
                comments: encodeEscapeChars(view.comments.data?.comments?.trim())
            } :
            undefined;
        // Convert formData back to submissionVM and remove empty rows
        const overseasStockholdings = view.groups
            .map(group => group.products)
            .reduce((memo, products) => [...memo, ...products], [])
            .map(products => products.rows)
            .reduce((memo, rows) => [...memo, ...rows], [])
            .map(row => {
                const {id, concurrencyToken, recordAction, stockProductId, ...data} = row.data;
                return {
                    ...data,
                    quantity: !isEmpty(data.quantity) ? Number(data.quantity) : null,
                    id,
                    concurrencyToken,
                    stockProductId,
                    recordAction: !isEmpty(data) ?
                        // Add missing recordActions, e.g. for imported data
                        ((id === -1 && recordAction == null) || (!id && !recordAction) ? recordActionAsEnum('Create') : recordAction) :
                        recordActionAsEnum('Delete'),
                };
            })
            .filter(d => {
                return (d.id || -1) > -1 ?
                    d.recordAction != null :
                    // Keep unpersisted rows if not deleted
                    recordActionFromEnum(d.recordAction) !== 'Delete';
            })
            .map(d => {
                if (d.id === -1) {
                    const {id, ...rest} = d;
                    return {...rest};
                }
                return d;
            });
        
        return {
            dataSubmissionId: view.dataSubmissionId,
            overseasStockholdings,
            // Uset comments if empty and no ID
            stockholdingComment: escapedComments != null ? ((!escapedComments.comments && !escapedComments.id) ? undefined : escapedComments) : escapedComments,
        };
    }, [view]);

    // Update form data when server vm is updated
    useEffect(() => {
        if (isActive) {
            let newChangeState: ChangedState = 'unchanged';
            const currentData = updateSubmissionOverseasFromStockholding(stockholding, overseasStockTypeRef.current);
            if (importCtrl.templateImportState.templateImportDialogState === 'processing' && importCtrl.templateImportState && importCtrl.templateImportState.data && 
                importCtrl.templateImportState.data.overseasStockholdings && importCtrl.templateImportState.data.submissionFormData?.overseasPageSaved !== true) {
                const submissionData = currentData.overseasStockholdings ?? [];
                const submissionCommentData = currentData.stockholdingComment;
                currentData.overseasStockholdings = [];
                const newData: UpdateStockholdingOverseasVM[] = [];
                
                productGroups.stockProductGroups?.forEach(spg => {
                    spg.stockProducts.forEach(sp => {
                        const allData = importCtrl.templateImportState.data?.overseasStockholdings?.filter(p => p.stockProductId === sp.id) ?? [];
                        const existingAllData = submissionData?.filter(p => p.stockProductId === sp.id) ?? [];
                        const matchedExistingIds: number[] = [];

                        if (allData.length > 0) {
                            allData.forEach(ad => {
                                const existingData = existingAllData.find(x => x.countryId !== undefined && x.countryId === ad.countryId);
                                if (existingData !== undefined) {
                                    existingData.id !== undefined && existingData.id != null && matchedExistingIds.push(existingData.id);
                                    newData.push({
                                        ...ad,
                                        id: existingData.id == null ? undefined : existingData.id,
                                        concurrencyToken: existingData.concurrencyToken == null ? undefined : existingData.concurrencyToken,
                                        recordAction: recordActionAsEnum('Update')
                                    });
                                } else {
                                    newData.push({
                                        ...ad,
                                        recordAction: recordActionAsEnum('Create')
                                    });
                                }
                            });
                        } 
                        
                        existingAllData.filter(x => !matchedExistingIds.includes(x.id ?? 0)).forEach(ed => {
                            newData.push({
                                ...ed,
                                quantity: null,
                                countryId: null,
                                recordAction: recordActionAsEnum('Delete')
                            });
                        });
                    });
                });

                currentData.overseasStockholdings = newData;

                if (submissionCommentData != null)
                {
                    currentData.stockholdingComment = {
                        ...submissionCommentData,
                    }
                }

                newChangeState = 'unsaved_changes';
            }

            setUpdateSubmissionOverseas(currentData);
            setChangedState(newChangeState);        
        }
    }, [importCtrl.templateImportState, isActive, overseasStockTypeRef, productGroups.stockProductGroups, stockholding]);

    useEffect(() => {
        switch (deleteRequestState.deleteState) {
            case 'cancelled':
                setDeleteRequestState(idleDeleteRequest);
                break;
            case 'confirmed':
                if (deleteRequestState.id !== undefined) {
                    deleteRequestState.rowIndex !== undefined ? 
                        actionDeleteProductRow(deleteRequestState.id, deleteRequestState.rowIndex) :
                        actionDeleteAllProductRows(deleteRequestState.id);
                    
                    if (deleteRequestState.source != null) {
                        focusNext(deleteRequestState.source);
                    }
                }
                setDeleteRequestState(idleDeleteRequest);
                break;
        }
    }, [actionDeleteAllProductRows, actionDeleteProductRow, deleteRequestState]);

    const shouldForceErrors = useMemo(() => (
        importCtrl.templateImportState.templateImportDialogState === 'processing'
    ), [importCtrl.templateImportState.templateImportDialogState]);
    
    return useMemo(() => ({
        addProductRow,
        changedState,
        deleteProductRow,
        deleteAllProductRows,
        deleteRequestState,
        focusedFieldCtrl,
        getUpdateRequestBody,
        portalDataAPICtrl,
        setDeleteRequestState,
        shouldForceErrors,
        stockType,
        updateComments,
        updateRow,
        view,
    }), [
        addProductRow,
        changedState,
        deleteProductRow,
        deleteAllProductRows,
        deleteRequestState,
        focusedFieldCtrl,
        getUpdateRequestBody,
        portalDataAPICtrl,
        setDeleteRequestState,
        shouldForceErrors,
        stockType,
        updateComments,
        updateRow,
        view,
    ]);
}

export default usePageOverseas;

export type UsePageOverseas = ReturnType<typeof usePageOverseas>;

function updateSubmissionOverseasFromStockholding(stockholding: Stockholding, stockType: StockType | undefined): UpdateStockholdingSubmissionOverseas {
    let stockholdingComment = stockholding.stockholdingComments
        ?.find(c => c.stockTypeId === stockType?.id && c.recordResult?.rowResult !== 'Deleted');
    if (stockholdingComment != null) {
        stockholdingComment = removeServerFields(stockholdingComment);
    }

    return {
        dataSubmissionId: stockholding.dataSubmission.id,
        stockholdingComment,
        overseasStockholdings: (stockholding.overseasStockholdings || [])
            .filter(os => os.recordResult?.rowResult !== 'Deleted')
            .map(removeServerFields),
    }
}

function existsAndIsNegativeNumber(val: number | undefined | null) {
    if (val == null) {
        return false;
    }

    return val < 0;
}

function infoMessagesForRow(row: UpdateStockholdingOverseas): InfoMessages {
    if (recordActionFromEnum(row.recordAction) === 'Delete') {
        return {};
    }

    return {
        ...(existsAndIsNegativeNumber(row.quantity) ? {
            quantity: {
                code: 'negativeValue',
                message: INFO_NEGATIVE_VALUE_COMMENTS,
            }
        } : { }),
    }
}

const IS_BETWEEN_OPTS = {max: 100000000, min: -100000000};

function validateQuantity(val: number | null | undefined) {
    return val != null ? (
        (!isBetween(val, IS_BETWEEN_OPTS) ? INVALID_VOLUME_RANGE : undefined) ||
        (!isInteger(val) ? INVALID_VOLUME_INTEGER : undefined)
    ) : undefined;
}

function errorMessagesForRow(row: UpdateStockholdingOverseas, seenCountryIds: Set<number>, allCountries: Array<Country>): ErrorMessages {
    let quantityValidation: ValidationError<StockholdingLocationValidationCode> | undefined = undefined;
    let countryIdValidation: ValidationError<StockholdingLocationValidationCode> | undefined = undefined;

    if (recordActionFromEnum(row.recordAction) === 'Delete') {
        return {};
    }

    if (!isEmpty(row.quantity)) {
        const quantityMsg = validateQuantity(row.quantity);
        if (quantityMsg != null) {
            quantityValidation = {
                code: 'QUANTITY_INVALID',
                message: quantityMsg,
            }
        }

        if (isEmpty(row.countryId)) {
            countryIdValidation = {
                code: 'COUNTRY_REQUIRED',
                message: INVALID_COUNTRY,
            }
        }
    } else if (!isEmpty(row.countryId)) { 
        quantityValidation = {
            code: 'QUANTITY_REQUIRED',
            message: INVALID_QUANTITY_REQUIRED,
        }
    }

    if (!isEmpty(row.countryId)) {
        if (seenCountryIds.has(row.countryId as number)) {
            countryIdValidation = {
                code: 'COUNTRY_CONFLICT',
                message: INVALID_COUNTRY_CONFLICT,
            }
        }
        seenCountryIds.add(row.countryId as number);

        if (allCountries.some(c => c.id === row.countryId && c.isInternal === true)){
            countryIdValidation = {
                code: 'INVALID_COUNTRY_ISINTERNAL_SELECTED',
                message: INVALID_COUNTRY_ISINTERNAL_SELECTED,
            }
        }
    }

    return {
        countryId: countryIdValidation,
        quantity: quantityValidation,
    };

}

type MessageClickArgs = {
    field: OverseasRowField;
}

function errorMessageComponents(messages: ErrorMessages, product: StockProduct, onClick: (args: MessageClickArgs) => any): Array<ReactNode> {
    const entries = Object.entries(messages)
        .filter(([_, v]) => v != null);

    return [
        ...(entries.map(([field, error]) =>
            <LocationErrorMessage
                key={field}
                onClick={() => onClick({
                    field: field as OverseasRowField,
                })}
                productName={product.productName as string}
                validationCode={error.code}
            />
        ))
    ]
}


function expiredProductMessageComponent(
    product: StockProduct, 
    onClick: (args: MessageClickArgs) => any,
    clearRow: () => any
    ): ReactNode {
    const field = 'quantity';

    //TODO
    //clearRow={clearRow}
    return <LocationErrorMessage
                key={field}
                onClick={() => onClick({
                    field: field as OverseasRowField,
                })}
                productName={product.productName as string}
                validationCode='INACTIVE_INTERNAL_PRODUCT'
            />;
}

function infoMessageComponents(
    messages: InfoMessages,
    product: StockProduct,
    onClick: (args: MessageClickArgs) => any,
    onCommentClick: () => any,
    variance?: number | null
): Array<ReactNode> {
    const entries = Object.entries(messages)
        .filter(([_, v]) => v != null);

    return [
        ...(entries.map(([field, msg]) => (
            msg.code === 'negativeValue' ?
            <InfoNegativeValueMessage
                onCommentTargetClick={onCommentClick}
                onTargetClick={() => onClick({
                    field: field as OverseasRowField,
                })}
                label={product.productName as string}
            /> : (
                msg.code === 'previouslyReported' ?
                <InfoPreviouslyReportedMessage
                    onCommentTargetClick={onCommentClick}
                    onTargetClick={() => onClick({
                        field: 'quantity',
                    })}
                    label={product.productName as string}
                    reportingTypeLabel="stockholding"
                /> : (
                    (msg.code === 'reportVariance' && variance != null) ?
                    <InfoReportVarianceMessage
                        onCommentTargetClick={onCommentClick}
                        onTargetClick={() => onClick({
                            field: 'quantity',
                        })}
                        label={product.productName as string}
                        variance={variance}
                    /> :
                    null
                )
            )
        )))
    ]
}

export function isRowFocusField(maybe: FocusField | null): maybe is OverseasRowFocusField {
    return maybe != null && (maybe as OverseasRowFocusField).field != null;
}
