import { useState } from "react";

interface UseProductionStepsProps {
    dataPageName: string;
}

type StepCommon = {
    index: number;
    label: string;
}

type StepData = StepCommon & {
    kind: 'data';
    name: string;
}

type StepSubmit = StepCommon & {
    kind: 'submit';
    name: 'Submit'
}

export type Step = StepData | StepSubmit;

export type UseProductionSteps = ReturnType<typeof useProductionSteps>;

function useProductionSteps({dataPageName}: UseProductionStepsProps) {
    const [steps] = useState(buildSteps(dataPageName));
    
    return {
        steps,
    };
}

export default useProductionSteps

function buildSteps(dataPageName: string): Array<Step> {
    return [
        {
            index: 0,
            kind: 'data',
            label: dataPageName,
            name: dataPageName,
        },
        {
            index: 1,
            kind: 'submit',
            label: 'Declaration',
            name: 'Submit',
        }
    ]
}
