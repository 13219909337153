/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateDataSubmissionVM } from '../models/UpdateDataSubmissionVM';
import type { UpdateWholesaleSubmissionVM } from '../models/UpdateWholesaleSubmissionVM';
import type { WholesaleSubmissionVMApiResponse } from '../models/WholesaleSubmissionVMApiResponse';
import { request as __request } from '../core/request';

export class WholesalesService {

    /**
     * @returns WholesaleSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async getWholesaleSubmission({
id,
}: {
id?: number,
}): Promise<WholesaleSubmissionVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/wholesale/get`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns WholesaleSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateWholesale({
requestBody,
}: {
requestBody?: UpdateWholesaleSubmissionVM,
}): Promise<WholesaleSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/wholesale/Update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns WholesaleSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateWholesaleDataSubmission({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<WholesaleSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/wholesale/Update-DataSubmission`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns WholesaleSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async submitWholesale({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<WholesaleSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/wholesale/Submit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns WholesaleSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async clearAllWholesale({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<WholesaleSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/wholesale/Clear-All`,
            body: requestBody,
        });
        return result.body;
    }

}