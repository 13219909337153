import { WithDataSubmission } from "psims/models/submission-types/data-submission";
import { useMemo } from "react";

function useViewMode<TSubmission extends WithDataSubmission>({dataSubmission}: TSubmission) {
    const mode = useMemo(() => {
        switch (dataSubmission.status) {
            case 'Action required':
            case 'Draft':
            case 'Not started':
                return 'edit';
            case 'Inactive':
                return 'inactive';
            default:
                return 'view'
        }
    }, [dataSubmission.status]);

    return mode;
}

export default useViewMode;

export type ViewMode = ReturnType<typeof useViewMode>;
