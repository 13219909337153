const EXTENSION_MAP = {
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf',
} as const;

export function getContentTypes() {
  return Object.values(EXTENSION_MAP)
    .join(', ');
}

export function getContentTypeForExtension(extension: string) {
  const contentType = EXTENSION_MAP[extension as keyof typeof EXTENSION_MAP];

  return contentType;
}