import Button from 'psims/react/components/button';
import Text from 'psims/react/components/text';
import { INCOMPLETE_ROW, INVALID_DECLARATION } from 'psims/constants/validation-messages';

interface CommentsRequiredErrorMessageProps {
    onTargetClick: () => any;
}

export const CommentsRequiredErrorMessage = ({onTargetClick}: CommentsRequiredErrorMessageProps) => (
    <Text>
        {'Please enter a reason in the '}

        <Button
            $kind='text'
            onClick={onTargetClick}
        >comments</Button>

        {' field to confirm your data is correct.'}
    </Text>
);

interface CommentsErrorMessageProps {
    onTargetClick?: () => any;
}

export const CommentsErrorMessage = (props: CommentsErrorMessageProps) => {
    const { onTargetClick } = props;
    if (!onTargetClick) {
        return <Text>{'Please correct the text in the comments field'}</Text>
    }
    return (
    <Text>
        {'Please correct the text in the '}
        <Button
            $kind='text'
            onClick={onTargetClick}
        >comments</Button>

        {' field'}
    </Text>
    );
};

interface MessageTargetButtonProps {
    onClick: () => any;
    label: string | JSX.Element;
}

export const TargetButton = ({onClick, label}: MessageTargetButtonProps) => (
    <Button
        $kind='text'
        onClick={onClick}
    >{label}</Button>
);

type QuantityOpts = {
    maxDescription?: string;
}

export const QuantityInvalidErrorMessage = (props: MessageTargetButtonProps & QuantityOpts) => (
    <Text>
        {'The value you entered in '}
        <TargetButton {...props} />
        {` must be an integer (zero is permitted) with a maximum value of ${props.maxDescription || '+/-100,000,000'}`}
    </Text>
);

type DensityOpts = {
    maxValue?: string;
}

export const DensityInvalidErrorMessage = (props: MessageTargetButtonProps & DensityOpts) => (
    <Text>
        {'The value you entered in '}
        <TargetButton {...props} />
        {` must be maximum value of 5 held to 10 decimal places.`}
    </Text>
);

export const CalorificValueInvalidErrorMessage = (props: MessageTargetButtonProps & DensityOpts) => (
    <Text>
        {'The value you entered in '}
        <TargetButton {...props} />
        {` must be a maximum value of ${props.maxValue || 100} held to 3 decimal places.`}
    </Text>
);

export const DuplicateValueErrorMessage = (props: MessageTargetButtonProps) => (
    <Text>
        {'The value you entered in '}
        <TargetButton {...props} />
        {` has already been added. Please edit the record.`}
    </Text>
);

export const InactiveProductionAreaErrorMessage = (props: MessageTargetButtonProps) => (
    <Text>
        {'The value you entered in '}
        <TargetButton {...props} />
        {` is inactive. Please edit or remove this record.`}
    </Text>
);

export const RowIncompleteInvalidErrorMessage = (props: MessageTargetButtonProps) => (
    <Text>
        {INCOMPLETE_ROW}
        {'. A value for '}
        <TargetButton {...props} />
        {' is required.'}
    </Text>
);

export interface InfoMessageProps {
    onCommentTargetClick: () => any;
    onTargetClick: () => any;
    onClearRowClick?: () => any;
    label: string | JSX.Element;
}

export const InfoOpeningClosingStocksMismatchMessage = ({label, onCommentTargetClick, onTargetClick}: InfoMessageProps) => (
    <Text>
        {"The value you entered for "}

        <TargetButton
            onClick={onTargetClick}
            label={label}
        />

        {' must match the closing stocks for last reporting period. You are required to provide a reason in the '}

        <Button
            $kind='text'
            onClick={onCommentTargetClick}
        >comments</Button>

        {' for this value.'}
    </Text>
);
interface InfoSameAsPreviousMessageProps {
    onTargetClick: () => any;
}

export const InfoSameAsPreviousMessage = ({ onTargetClick }: InfoSameAsPreviousMessageProps) => (
    <Text>
        {'Your submission is the same as the previous month. You are required to enter a reason in the '}

        <Button
            $kind='text'
            onClick={onTargetClick}
        >comments</Button>

        {' field to confirm your data is correct.'}

    </Text>
);

export const InfoNegativeValueMessage = ({label, onCommentTargetClick, onTargetClick}: InfoMessageProps) => (
    <Text>
        {"The value you entered for "}

        <TargetButton
            onClick={onTargetClick}
            label={label}
        />

        {' is a negative value, you are required to provide a reason in the '}

        <Button
            $kind='text'
            onClick={onCommentTargetClick}
        >comments</Button>

        {' for this value.'}
    </Text>
);

export const InfoOtherProductionAreaValueMessage = ({label, onCommentTargetClick, onTargetClick}: InfoMessageProps) => (
    <Text>
        {"The value you selected in the "}

        <TargetButton
            onClick={onTargetClick}
            label={label}
        />

        {" requires a "}

        <Button
            $kind='text'
            onClick={onCommentTargetClick}
        >comment</Button>

        {' that explains your reason.'}
    </Text>
);

interface PreviouslyReportedInfoMessageProps extends InfoMessageProps {
    reportingTypeLabel: string;
}

export const InfoPreviouslyReportedMessage = ({onCommentTargetClick, onTargetClick, label, reportingTypeLabel}: PreviouslyReportedInfoMessageProps) => (
    <Text>
        {`You are reporting no ${reportingTypeLabel} for `}

        <TargetButton
            onClick={onTargetClick}
            label={label}
        />

        {`, but you reported a ${reportingTypeLabel} in the previous report.  If this is correct, please provide a reason in the `}

        <Button
            $kind='text'
            onClick={onCommentTargetClick}
        >comments</Button>

        {' field.'}
    </Text>
);

interface ReportVarianceInfoMessageProps extends InfoMessageProps {
    variance: number;
}

export const InfoReportVarianceMessage = ({onCommentTargetClick, onTargetClick, label, variance}: ReportVarianceInfoMessageProps) => (
    <Text>
        The total for 

        <TargetButton
            onClick={onTargetClick}
            label={label}
        />

        {` is more than ${variance}% outside of your previous submission. Please enter a reason in the `}

        <Button
            $kind='text'
            onClick={onCommentTargetClick}
        >comments</Button>

        {' field to confirm your data is correct.'}
    </Text>
);


type ErrorUnsavedPageProps = Omit<InfoMessageProps, 'onCommentTargetClick'>;

export const ErrorUnsavedPage = ({onTargetClick, label}: ErrorUnsavedPageProps) => (
    <Text>
        {'The '}

        <TargetButton
            onClick={onTargetClick}
            label={label}
        />

        {' page is incomplete. Please review before submitting.'}
    </Text>
);

type InactiveProductionAreaPageProps = Omit<InfoMessageProps, 'onCommentTargetClick'>;

export const InactiveProductionAreaPage = ({onTargetClick, label}: InactiveProductionAreaPageProps) => (
    <Text>
        {'The '}

        <TargetButton
            onClick={onTargetClick}
            label={label}
        />

        {' page is using an inactive Production Area. Please review before submitting.'}
    </Text>
);

type InactiveProductProps = Omit<InfoMessageProps, 'onCommentTargetClick'>;

export const InactiveProduct = ({onTargetClick, label}: InactiveProductProps) => (
    <Text>
        {'The '}

        <TargetButton
            onClick={onTargetClick}
            label={label}
        />

        {' product is inactive. Please review before submitting.'}
    </Text>
);


type ErrorEmptyCommentsOnPageProps = Omit<InfoMessageProps, 'onCommentTargetClick'>;

export const ErrorEmptyCommentsOnPage = ({onTargetClick, label}: ErrorEmptyCommentsOnPageProps) => (
    <Text>
        {'You must provide comments on the '}

        <TargetButton
            onClick={onTargetClick}
            label={label}
        />

        {' page to confirm your data is correct.'}
    </Text>
);

type ErrorExpiredProductProps = Omit<InfoMessageProps, 'onCommentTargetClick'>;

export const ErrorExpiredProduct = ({onTargetClick, onClearRowClick, label}: ErrorExpiredProductProps) => (
    <Text>
        {'You have provided data for '}

        <TargetButton
            onClick={onTargetClick}
            label={label}
        />

        {' which is inactive. Please enter these details into an active product if applicable. Delete '}
        { onClearRowClick ? 
            <TargetButton
                onClick={onClearRowClick}
                label='the row'
            /> : 'the row' }
        {' to continue.'}
    </Text>
);

export const DeclarationErrorMessage = () => (
    <Text>
        {INVALID_DECLARATION}
    </Text>
);