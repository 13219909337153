import { RefineryProduct } from "psims/models/ref-data/refinery-product";
import { useMemo } from "react";
import { UseRefineryProgress } from "./use-refinery-progress";
import { Step } from "./use-refinery-steps";
import { UseRefineryServiceResponse } from "./use-service-response";

interface UseRefineryValidationAlertsProps {
    progressCtrl: UseRefineryProgress;
    serviceResponse: UseRefineryServiceResponse;
}

function useRefineryValidationAlerts({progressCtrl, serviceResponse}: UseRefineryValidationAlertsProps) {
    const validationAlertsForCurrentStep = useMemo(() => {
        const currentStep = progressCtrl.currentStep;
        if (currentStep.kind === 'submit') {
            // TODO: if we need to expose any validation alerts on the submit page, do it here
            return [];
        }

        if (serviceResponse.validationAlerts == null) {
            return [];
        }

        const productMap = getProductsForStep(currentStep);

        return serviceResponse.validationAlerts.filter(validationAlert => productMap[validationAlert.productId] != null);
    }, [progressCtrl.currentStep, serviceResponse.validationAlerts]);

    return {
        validationAlertsForCurrentStep,
    };
}

export default useRefineryValidationAlerts

export type UseRefineryValidationAlerts = ReturnType<typeof useRefineryValidationAlerts>;

// Helpers
type ProductMap = {[key: number]: RefineryProduct};

function getProductsForStep(step: Step) {
    if (step.kind === 'submit') {
        return [];
    }
    
    return step.refData.groups
        .reduce((products, group) => {
            const groupProducts = group.products
                .reduce((acc, product) => ({
                    ...acc,
                    [product.id]: product,
                }), {} as ProductMap);
            
            return {
                ...products,
                ...groupProducts,
            }
        }, {} as ProductMap);
}