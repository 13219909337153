import { useEffect, useMemo, useState } from "react";

import { isFspPreconditionNotMet } from "psims/models/submission-types/fsp/fsp-precondition-not-met";
import EnsureId from "../shared/ensure-id";
import LoadSubmissionFailed from "../shared/load-submission-failed";
import LoadingSubmission from "../shared/loading-submission";
import useSubmissionLoadState from "../shared/use-submission-load-state";
import FSPEditor from "./fsp-editor";
import FspPreconditionNotMetNotification from "./fsp-precondition-not-met-notification";
import useFspAPI, { UseFspAPI } from "./use-fsp-api";
import useFspRefData, { isUseFspRefData, UseFspRefData } from "./use-fsp-ref-data";
import { useLogger } from "psims/react/providers/logging";

interface FspProps {
  id: number;
}

const FspPage = (props: FspProps) => {
  const vm = useVM(props);

  switch (vm.loadState) {
    case 'error':
      return (
        vm.prerequisiteRefining ?
        <FspPreconditionNotMetNotification
          fsspDataSubmission={vm.prerequisiteRefining.fsspDataSubmission}
          refiningDataSubmission={vm.prerequisiteRefining.refiningDataSubmission}
        /> :
        <LoadSubmissionFailed submissionName='FSSP s19A' />
      )
    case 'loaded':
      return <FSPEditor
        // TODO: should type assert apiCtrl
        apiCtrl={vm.apiCtrl as PopulatedProps<UseFspAPI, 'submission'>}
        refData={vm.refData as UseFspRefData}
      />;
    default:
      return <LoadingSubmission submissionName='FSSP s19A' />;
  }
}

function useVM(props: FspProps) {
  const apiCtrl = useFspAPI({ id: props.id });
  const refData = useFspRefData({submission: apiCtrl.submission});
  const logger = useLogger({source: 'fsp-index'});
  const [loaded, setLoaded] = useState(false);

  const ready = useMemo(() => {
    return loaded && isUseFspRefData(refData) && refData.fspProducts.length > 0;
  }, [loaded, refData])

  const loadState = useSubmissionLoadState({
    submission: apiCtrl.submission,
    isDepsReady: ready,
    loadStatus: apiCtrl.loadStatus
  });

  const loadStateWithRefDataCheck = useMemo(() => {
    if (refData.status === 'ready' && refData.fspProducts.length < 1) {
      return 'error';
    }

    return loadState;
  }, [loadState, refData.status, refData.fspProducts.length]);

  const prerequisiteRefining = useMemo(() => {
    if (isFspPreconditionNotMet(apiCtrl.fetchErrorResult)) {
      const earliestRefining = apiCtrl.fetchErrorResult.refiningDataSubmissions
        .sort((a, b) => new Date(a.reportingPeriodFrom || '').getTime() - new Date(b.reportingPeriodFrom || '').getTime())
        [0];

      if (earliestRefining == null) {
        const logException = logger.exception;
        
        const err = new Error('Unexpected empty array of Refining submissions when attempting to determine FSSP preconditions');

        logException(err);
      }
      
      return {
        refiningDataSubmission: earliestRefining,
        fsspDataSubmission: apiCtrl.fetchErrorResult.fsspSubmission,
      }
    } else {
      return null;
    }
  }, [apiCtrl.fetchErrorResult, logger.exception]);

  useEffect(() => {
    if (apiCtrl.loadStatus === 'fetched') {
      setLoaded(true);
    }
  }, [apiCtrl.loadStatus]);

  return {
    apiCtrl,
    loadState: loadStateWithRefDataCheck as typeof loadState,
    prerequisiteRefining,
    refData,
  }
}

const Page = (props: FspProps) => <EnsureId id={props.id} Component={FspPage} />;

export default Page;