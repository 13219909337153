import produce from "immer";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { utils, WorkBook } from "xlsx";
import { fileOpen, fileOpenLegacy, FileWithHandle } from "browser-fs-access";

import { ImplementedDataSubmissionTypes, Sheet, TemplateImportState, UseTemplateImportProps } from "./types";
import useImportReferenceData, { UseImportReferenceData } from "./use-import-reference-data";
import { processImport } from "./utils";
import FileNotSupported from "psims/react/blocks/import/file-not-supported";
import UnknownError from "psims/react/blocks/import/unknown-error";
import { useLogger } from "psims/react/providers/logging";

export const idleTemplateImportState: () => TemplateImportState<any> = () => ({
    templateImportDialogState: 'idle',
    unsavedChanges: false,
    submitSaved: false,
    data: undefined,
    errors: undefined,
    totalErrors: undefined,
    totalRows: undefined,
})

const parse = (workbook: WorkBook) => {
    const sheets = Object.keys(workbook.Sheets).map((name) => {
      const sheet = workbook.Sheets[name];
      return {
        name,
        data: utils.sheet_to_json(sheet, {
          header: 1,
          raw: true,
        }),
      };
    });
    return JSON.parse(JSON.stringify(sheets)) as Array<Sheet>;
  };

type FileOpener = {
    fileOpen: typeof fileOpen;
    mode: 'standard' | 'legacy';
}

type ReactMouseEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

// I was trying to get legacy file picker mode to work,
// but it was just resulting in a browser notification
// message instead of our custom message, so disabling
// with a flag for now in case we have time to revisit
const DEV_FLAG_TRY_LEGACY = false;

const useTemplateImport = <TSubmission,>({dataSubmission, importerBuilder, preProcess}: UseTemplateImportProps<TSubmission>) => {
    const useImportRefData = useImportReferenceData();
    const {startTrackPage} = useLogger({source: 'useTemplateImport'});
    const [templateImportState, setTemplateImportState] = useState<TemplateImportState<TSubmission>>(idleTemplateImportState());
    const fileOpener = useRef<FileOpener>({fileOpen, mode: 'standard'});
    const [file, setFile] = useState<FileWithHandle | null>(null);
    const filename = useRef<string | null>(null);
    const doPreProcess = preProcess !== undefined && preProcess === true;
    const typeName = dataSubmission.submissionTypeName;

    const importer = useMemo(() => {
        return importerBuilder();
    }, [importerBuilder])

    const handleTemplateImportInner = useCallback((event: ReactMouseEvent| MouseEvent | undefined) => {
        setTemplateImportState(prev => produce(prev, draft => {
            draft.templateImportDialogState = 'request_file'
        }));

        fileOpener.current.fileOpen()
        .then(f => {
            filename.current = f.handle?.name || null;     
            setTemplateImportState(prev => produce(prev, draft => {
                draft.templateImportDialogState = doPreProcess ? 'preprocess' : 'importing'
                draft.data = undefined
            }));
            setFile(f);
        })
        .catch( (err: any) => { 
            if (err.name === 'NotAllowedError' || err.name === 'SecurityError'){
                if (fileOpener.current.mode === 'standard' && DEV_FLAG_TRY_LEGACY) {
                    fileOpener.current = {fileOpen: fileOpenLegacy, mode: 'legacy'};
                    return handleTemplateImportInner((event as ReactMouseEvent).nativeEvent || event);
                }

                // Custom message for not allowed
                return setTemplateImportState({
                    templateImportDialogState: 'error',
                    totalErrors: 1,
                    errors: [{
                        error: 'Your browser security settings may be preventing the file upload. Please check your browser security settings and re-try or use a different browser.',
                        page: ''
                    }]
                });
            }
            const ex = err as DOMException;

            if (ex && ex.name) {
                if (ex.name === 'AbortError') {
                    setTemplateImportState(idleTemplateImportState);
                } else {
                    setTemplateImportState({
                        templateImportDialogState: 'error',
                        totalErrors: 1,
                        errors: [{
                            error: `Unexpected error. [${ex.name} - ${ex.message}]`,
                            page: ''
                        }]
                    });
                }
                return;
            }

            setTemplateImportState({
                templateImportDialogState: 'error',
                totalErrors: 1,
                errors: [{
                    error: `Unexpected error. [${err}]`,
                    page: ''
                }]
            });
            return; 
        });
    }, [doPreProcess]);

    const handleTemplateImport = useCallback((event: ReactMouseEvent| MouseEvent | undefined) => {
        if (doPreProcess) {
            setTemplateImportState(prev => produce(prev, draft => {
                draft.templateImportDialogState = 'confirming'
            }));
        } else {
            handleTemplateImportInner(event);
        }
    }, [doPreProcess, handleTemplateImportInner]);
        
    useEffect(() => {
        if (templateImportState.templateImportDialogState === 'confirmed') {
            handleTemplateImportInner(undefined);
        }
      }, [handleTemplateImportInner, templateImportState.templateImportDialogState]);

    const process = useCallback((workbook: WorkBook) => {
        const key = typeName as ImplementedDataSubmissionTypes;

        if (key) {
            const sheets = parse(workbook) ;
            if (sheets && sheets.length > 0) {   
                try {
                    const data = processImport(sheets, importer, useImportRefData, dataSubmission, filename.current);
                    setTemplateImportState(data);
                } catch (e) {
                    setTemplateImportState({
                        templateImportDialogState: 'error',
                        totalErrors: 1,
                        errors: [{
                            error: <UnknownError/>,
                            page: ''
                        }]
                    });
                }                           
            } else {
                setTemplateImportState({
                    templateImportDialogState: 'error',
                    totalErrors: 1,
                    errors: [{
                        error: <FileNotSupported fileName={filename.current} />,
                        page: ''
                    }]
                });
            }
        } else {
            setTemplateImportState({
                templateImportDialogState: 'error',
                totalErrors: 1,
                errors: [{
                    error: <FileNotSupported fileName={filename.current} />,
                    page: ''
                }]
            });
        }
    }, [dataSubmission, importer, typeName, useImportRefData]);

    const saveStep = useCallback((step: string) => {   
        const key = typeName as ImplementedDataSubmissionTypes;
        if (key) {
            if (importer.setStepSaved) {
                setTemplateImportState(importer.setStepSaved(templateImportState, step));
                if (templateImportState.templateImportDialogState === 'processing') {
                    startTrackPage('Imported data saved', {caseId: dataSubmission.caseId})();
                }
            }
        }
    },[dataSubmission, startTrackPage, templateImportState, importer, typeName]);

    const savedSteps = useCallback(() => {
        const key = typeName as ImplementedDataSubmissionTypes;
        if (key) {
            if (importer.savedSteps) {
                return importer.savedSteps(templateImportState);
            }
        }

        return undefined;
    },[templateImportState, importer, typeName]);

    function clearFile() {
        setFile(null);
    }

    function resetFile() {
        setFile(null);
        filename.current = null;
    }

    const reset = useCallback(() => {
        setTemplateImportState(idleTemplateImportState);
        resetFile();
    }, []);

    return {
        file,
        filename: filename.current,
        fileOpen: fileOpener.current.fileOpen,
        clearFile,
        process,
        reset,
        resetFile,
        saveStep,
        savedSteps,
        useImportRefData,
        handleTemplateImport,
        templateImportState,
        setTemplateImportState
    };
}

export default useTemplateImport;

export interface UseTemplateImport<TSubmission> {
    process: (workbook: WorkBook) => void;
    reset: () => void;
    templateImportState: TemplateImportState<TSubmission>;
    setTemplateImportState: Dispatch<SetStateAction<TemplateImportState<TSubmission>>>;
    useImportRefData: UseImportReferenceData;
    handleTemplateImport: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
    saveStep: (step: string) => void;
    savedSteps: () => any;
};
