import { useCallback, useEffect, useMemo, useState } from "react";

import useConfirmNavigation from "psims/react/util/use-confirm-navigation";
import { ViewMode } from "../../shared/use-view-mode";
import useDataSubmissionSaveCheck from "../../shared/use-data-submission-save-check";
import { UseMsoImporterAPI } from "./use-mso-importer-api";
import { UseMsoImporterForm } from "./use-mso-importer-form";
import { UseMsoImporterProgress } from "./use-mso-importer-progress";
import { UseMsoImporterValidation } from "./use-mso-importer-validation";
import { attemptScrollToSelector } from "psims/react/pages/primary-pages/data-submissions/shared/view-utils";
import { UseMso } from "../shared/use-mso";
import {cleanSubmission} from "../shared/update-data-utils";
import { UseSubmit } from "../../shared/use-submit";
import { SELECTOR_NOTIFICATIONS } from "psims/constants/selectors";

/**
 * Controller to manage in-form and browser navigation, e.g. unsaved
 * change checking and validation alert dialogs where necessary.
 */
interface UseMsoImporterNavigationProps {
    apiCtrl: UseMsoImporterAPI;
    formCtrl: UseMsoImporterForm;
    msoCtrl: UseMso;
    progressCtrl: UseMsoImporterProgress;
    submitCtrl: UseSubmit;
    validationCtrl: UseMsoImporterValidation;
    viewMode: ViewMode;
}

function useMsoImporterNavigation({apiCtrl, formCtrl, msoCtrl, progressCtrl, submitCtrl, validationCtrl, viewMode}: UseMsoImporterNavigationProps) {
    const hasUnsavedChanges = progressCtrl.currentStep.kind === 'data' ? formCtrl.hasUnsavedChanges : submitCtrl.hasUnsavedChanges;
    const saveCheckCtrl = useDataSubmissionSaveCheck({
        apiCtrl,
        hasUnsavedChanges,
        updateDataSubmission: submitCtrl.dataSubmissionUpdate,
        updateSubmission: cleanSubmission(formCtrl.submissionUpdate),
    });
    const browserNavCtrl = useConfirmNavigation({when: hasUnsavedChanges && viewMode === 'edit'});
    const [lastSavedPageIndex, setLastSavedPageIndex] = useState<number | null>(null);
    const [lastSaveAttemptIndex, setLastSaveAttemptIndex] = useState<number | null>(null);

    useEffect(() => {
        if (browserNavCtrl.showPrompt && saveCheckCtrl.saveState === 'idle') {
            saveCheckCtrl.checkAndConfirm(
                progressCtrl.currentStep.kind === 'data' ? 'submission' : 'data_submission',
                () => browserNavCtrl.handleDecision(true),
                () => browserNavCtrl.handleDecision(false)
            )
        }
    }, [browserNavCtrl, progressCtrl, saveCheckCtrl]);

    const unsavedChangesDialogCtrl = useMemo(() => ({
        controlsState: (saveCheckCtrl.saveState === 'saving' ? 'loading' : 'normal') as 'loading' | 'normal',
        isOpen: saveCheckCtrl.saveState === 'confirming',
        onCancel: saveCheckCtrl.cancel,
        onConfirm: () => saveCheckCtrl.save(),
        onSecondary: saveCheckCtrl.proceed,
    }), [saveCheckCtrl]);

    const onSave = useCallback((index: number) => {
        setLastSavedPageIndex(index);
        progressCtrl.goToStep(index);
        formCtrl.reset();
    }, [formCtrl, progressCtrl]);

    const ensureValid = useCallback(() => {
        const result = validationCtrl.isValidForSave();
        if (!result) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
        }

        return result;
    }, [validationCtrl]);

    const attemptStepNavigation = useCallback((index: number) => {
        if (viewMode !== 'edit') {
            progressCtrl.goToStep(index);
            return;
        }

        if (!ensureValid()) {
            return;
        }

        saveCheckCtrl.checkAndConfirm(
            progressCtrl.currentStep.kind === 'data' ? 'submission' : 'data_submission',
            () => onSave(index),
            () => {}
        )
    }, [ensureValid, onSave, progressCtrl, saveCheckCtrl, viewMode]);

    const saveAndGoToPage = useCallback((index: number) => {
        setLastSaveAttemptIndex(progressCtrl.currentStep.index);
        
        if (!ensureValid()) {
            return;
        }
        saveCheckCtrl.save(progressCtrl.currentStep.kind === 'data' ? 'submission' : 'data_submission', () => onSave(index));
    }, [ensureValid, onSave, progressCtrl, saveCheckCtrl]);

    const attemptSubmit = useCallback(() => {
        submitCtrl.attemptSubmit();
    }, [submitCtrl]);

    // Clear last save attempt on page change
    useEffect(() => {
        setLastSaveAttemptIndex(null);
    }, [progressCtrl.currentStep.index]);

    return {
        lastSaveAttemptIndex,
        lastSavedPageIndex,
        unsavedChangesDialogCtrl,
        attemptStepNavigation,
        attemptSubmit,
        saveAndGoToPage,
    };
}

export default useMsoImporterNavigation

export type UseMsoImporterNavigation = ReturnType<typeof useMsoImporterNavigation>;
