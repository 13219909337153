import React from 'react';

import Button from 'psims/react/components/button';
import Eraser from 'psims/react/components/icons/eraser';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import Select, { SelectController } from 'psims/react/components/select';
import { H2 } from 'psims/react/components/typography';

interface DocumentsFilterProps {
  documentTypeSelectCtrl: SelectController;
  organisationSelectCtrl: SelectController;
  reset: () => any;
}

const DocumentsFilter = ({ documentTypeSelectCtrl, organisationSelectCtrl, reset }: DocumentsFilterProps) => {
  return (

    <BoxedDiv box={{ background: 'grey-faded', borderTop: { color: 'border-primary-faded', width: 3 }, flex: 'column', paddingV: 3, paddingLeft: 3 }}>
      <BoxedDiv box={{ flex: 'row', justifyContent: 'space-between', alignItems: "flex-start" }}>
        <H2 marginV={1}>Filters</H2>
        <Button
          $kind="text"
          onClick={reset}
          marginRight={4}
        >
          <BoxedSpan box={{ marginRight: 1 }}>
            <Eraser size="sm" />
          </BoxedSpan> Reset
        </Button>

      </BoxedDiv>
      <BoxedDiv box={{ flex: 'row' }}>
        <Select
          placeholder='All'
          id='documentType'
          label='Document type'
          options={documentTypeSelectCtrl.options}
          onChange={documentTypeSelectCtrl.onChange}
          value={documentTypeSelectCtrl.value}
          width='300px'
        />

        <Select
          placeholder='All'
          id='organisationId'
          label='Entity name'
          marginLeft={2}
          options={organisationSelectCtrl.options}
          onChange={organisationSelectCtrl.onChange}
          value={organisationSelectCtrl.value}
          width='300px'
        />
      </BoxedDiv>
    </BoxedDiv>
  )
}

export default DocumentsFilter;
