import React from 'react';
import BaseSVG, { SVGProps } from './_base';

const Warning = (props: SVGProps) => {
    return (
        <BaseSVG name='warning' viewBox='0 0 96.273 82.534' {...props}>
            <g transform="translate(-1635 -5565.411)">
                <path id="Path" d="M47.886,0A5.378,5.378,0,0,0,43.48,2.619L.711,74.725a5.093,5.093,0,0,0,.013,5.212,5.387,5.387,0,0,0,4.643,2.6H90.906a5.387,5.387,0,0,0,4.643-2.6,5.093,5.093,0,0,0,.013-5.212L52.757,2.619A5.392,5.392,0,0,0,47.886,0Z" transform="translate(1635 5565.411)" fill="currentColor" />
                <g transform="translate(1676 5583.504)">
                    <path d="M9.969,4.652A4.827,4.827,0,0,1,4.984,9.3,4.825,4.825,0,0,1,0,4.652,4.825,4.825,0,0,1,4.984,0,4.828,4.828,0,0,1,9.969,4.652Z" transform="translate(1.888 44.926)" fill="#fff" />
                    <path d="M13.72,6.185a6.7,6.7,0,0,0-1.5-3.588A6.939,6.939,0,0,0,6.872,0,6.93,6.93,0,0,0,1.641,2.458,6.714,6.714,0,0,0,.024,6.185a6.44,6.44,0,0,0,0,1.116l2.835,30.3a3.638,3.638,0,0,0,1.16,2.324,4.246,4.246,0,0,0,5.477.2A3.667,3.667,0,0,0,10.885,37.6L13.72,7.3A6.37,6.37,0,0,0,13.72,6.185Z" transform="translate(0)" fill="#fff" />
                </g>
            </g>
        </BaseSVG>
    );
};

export default Warning;