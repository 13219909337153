import { useCallback, useEffect, useMemo, useState } from "react";

import { DefActivity, DefActivityField, UpdateDefActivity } from "psims/models/submission-types/def/def-activity";
import useUpdateable from "../../shared/use-updateable-list";
import { UseDefRefData } from "../use-def-ref-data";
import { validateActivity } from "./validate-activity";
import { getValidationAlertsForActivity } from "./activity-validation-alerts";
import { DefSubmission } from "psims/models/submission-types/def/def-submission";
import { isValidForPeriod } from "psims/models/ref-data/util";
import { DATA_FIELDS, DefProductWithStatus, isDEFProduct } from "./util";
import { recordActionAsEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { isEmpty } from "psims/lib/empty";
import { UseDefPortalData } from "../use-def-portal-data";
import { DeleteRequestState, idleDeleteRequest } from "../../shared/delete-confirmation";
import { focusNext } from "psims/lib/focus-util";
import { ViewMode } from "../../shared/use-view-mode";

interface UseActivitiesProps {
  initialData: Array<DefActivity>;
  portalDataCtrl: UseDefPortalData;
  refData: UseDefRefData;
  submission: DefSubmission;
  viewMode: ViewMode;
}

function useActivities({ initialData, portalDataCtrl, refData, submission, viewMode }: UseActivitiesProps) {
  const [deleteRequestState, setDeleteRequestState] = useState<DeleteRequestState>(idleDeleteRequest);

  const { dataSubmission } = submission;

  const products = useMemo(() => {
    return refData.defProducts
      .map<DefProductWithStatus>(p => {
        const productPortalData = portalDataCtrl.productPortalData.find(pd => pd?.referenceCode === p.referenceCode);

        return {
          ...p,
          description: (productPortalData?.content != null && !isEmpty(productPortalData?.content)) ?
            productPortalData.content :
            '',
          productStatus: (
            isValidForPeriod(p, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo) ?
              'active' : (
                initialData.some(a => a.defProductId === p.id) ?
                  'inactive_with_data' :
                  'inactive'
              ))
        }
      })
      .filter(p => p.productStatus !== 'inactive');
  }, [
    dataSubmission.reportingPeriodFrom,
    dataSubmission.reportingPeriodTo,
    initialData,
    portalDataCtrl.productPortalData,
    refData.defProducts
  ]);

  const updateCtrl = useUpdateable({
    dataFields: DATA_FIELDS,
    initialData,
    refData: products,
    createPlaceholders: {
      creator: product => product.productStatus === 'active' ? ({ dataSubmissionId: dataSubmission.id, defProductId: product.id }) : null,
      matcher: (product, data) => product.id === data.defProductId,
    },
  });

  const deleteActivityRequest = useCallback((id: number, source: HTMLElement | null) => {
    setDeleteRequestState({
      deleteState: 'showing_dialog',
      id,
      rowIndex: 0,
      message: 'This activity record will be deleted.',
      source,
    });
  }, []);

  const dataView = useMemo(() => {
    const allData = [...updateCtrl.data];

    return updateCtrl.data
      .map(data => {
        const product = products.find(d => d.id === data.defProductId);
        return {
          data,
          refData: product,
          validation: validateActivity(data, product, allData, products),
          validationAlerts: product?.productStatus === 'active' ? getValidationAlertsForActivity(data, allData, products, submission) : [],
        }
      })
      .filter(d => recordActionFromEnum(d.data.recordAction) !== 'Delete')
      .sort((a, b) => {
        // Consistent sort if display order is not unique
        const displayOrderDiff = (a.refData?.displayOrder || 1000) - (b.refData?.displayOrder || 1000);

        return displayOrderDiff === 0 ? (
          (a.refData?.name.toLowerCase() || 'zzz') < (b.refData?.name.toLowerCase() || 'zzz') ? -1 : 1
        ) : displayOrderDiff;
      });
  }, [products, submission, updateCtrl.data]);

  const commentsRequired = useMemo(() => {
    return dataView.some(d => d.validationAlerts.length > 0);
  }, [dataView]);

  const hasExpiredData = useMemo(
    () => viewMode === 'edit' && dataView.some(d => d.refData?.productStatus === 'inactive_with_data'),
    [dataView, viewMode]
  );

  const validationAlerts = useMemo(
    () => dataView.map(d => d.validationAlerts).flat(),
    [dataView]
  );

  const firstField = useMemo<DefActivityField>(() => {
    const firstData = dataView[0];
    return (
      isDEFProduct(firstData?.refData) ?
        'derivedFromBlending' :
        'usedInBlending'
    )
  }, [dataView]);

  const getRequestPayload = useCallback(() => {
    return updateCtrl.data
      .map(d => {
        if (shouldDeleteActivity(d)) {
          return {
            ...d,
            recordAction: recordActionAsEnum('Delete'),
          }
        }

        return d
      })
      // Don't send records with no record action
      .filter(d => d.recordAction != null)
      // Don't send unpersisted records if they are empty, regardless of recordAction
      .filter(d => !(d.id == null && isEmptyActivity(d)))
      .map(d => {
        const { _id, validationAlerts, fieldsState, ...payload } = d;
        return payload;
      });
  }, [updateCtrl.data]);

  useEffect(() => {
    switch (deleteRequestState.deleteState) {
      case 'cancelled':
        setDeleteRequestState(idleDeleteRequest);
        break;
      case 'confirmed':
        if (deleteRequestState.id != null) {
          const del = updateCtrl.deleteItem;
          const itemToDelete = dataView.find(d => d.data.id === deleteRequestState.id);
          if (itemToDelete != null) {
            del(itemToDelete.data);
          }

          if (deleteRequestState.source != null) {
            focusNext(deleteRequestState.source);
          }
        }
        setDeleteRequestState(idleDeleteRequest);
        break;
    }
  }, [dataView, deleteRequestState, updateCtrl.deleteItem]);

  return {
    changeStatus: updateCtrl.changeStatus,
    commentsRequired,
    data: dataView,
    deleteActivityRequest,
    deleteRequestState,
    firstField,
    hasExpiredData,
    validationAlerts,
    clearRow: updateCtrl.deleteItem,
    getRequestPayload,
    setDeleteRequestState,
    update: updateCtrl.updateItem,
  }
}

function isEmptyActivity(activity: Partial<UpdateDefActivity>) {
  const { closingStocks, derivedFromBlending, derivedFromOtherProduction, sales, usedInBlending } = activity;

  return (
    isEmpty(closingStocks) &&
    isEmpty(derivedFromBlending) &&
    isEmpty(derivedFromOtherProduction) &&
    isEmpty(sales) &&
    isEmpty(usedInBlending)
  );
}

function shouldDeleteActivity(activity: Partial<UpdateDefActivity>) {
  return (
    activity.id != null &&
    isEmptyActivity(activity)
  )
}

export default useActivities

export type UseActivities = ReturnType<typeof useActivities>;
