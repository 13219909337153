import { BoxedDiv } from 'psims/react/components/layout';
import Header from 'psims/react/blocks/data-submission-header';
import SubmissionProgressBar from 'psims/react/components/submission-progress-bar';
import { H2 } from 'psims/react/components/typography';
import ScrollTo from 'psims/react/components/scroll-to';
import { UseProductionAPI } from './use-production-api';
import { ViewMode } from '../shared/use-view-mode';
import Button from 'psims/react/components/button';
import ConfirmSaveChanges from 'psims/react/components/confirm-save-changes';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';
import { DeleteConfirmationDialog } from '../shared/delete-confirmation';
import DataSubmissionPrimaryControls from 'psims/react/components/data-submission-primary-controls';
import { UseProductionRefData } from '../productions-shared/use-production-ref-data';
import ProductionNotifications from './production-notifications';
import ProductionSubmit from './production-submit';
import useProduction from './use-production-main';
import { ProductionTypeConfig } from './util';
import ProductionTypeEditor from './production-type-editor';
import { ProductionProductGroup } from 'psims/models/ref-data/production-product-group';
import MainContainer from 'psims/react/pages/primary-pages/data-submissions/shared/main-container';

interface ProductionEditorProps<TGroup extends ProductionProductGroup> {
    apiCtrl: PopulatedProps<UseProductionAPI, 'submission'>;
    productionTypeConfig: ProductionTypeConfig<TGroup>;
    refData: UseProductionRefData;
}

const ProductionEditor = <TGroup extends ProductionProductGroup> (props: ProductionEditorProps<TGroup>) => {
    const vm = useVM(props);
    const {
        apiCtrl,
        controlsCtrl: {primaryControl, secondaryControl},
        formCtrl,
        importCtrl,
        portalDataCtrl,
        progressCtrl,
        saveCtrl,
        serviceResponse,
        submission,
        submitCtrl,
        viewMode
    } = vm.ctrl;

    const {FrontMatter} = vm.productionTypeConfig;

    const isSubmitPage = progressCtrl.currentStep.kind === 'submit';
    const shouldShowExtraButtons = progressCtrl.currentStep.index === 0 && viewMode === 'edit';

    return (
        <MainContainer dataSubmission={apiCtrl.submission.dataSubmission}>
            <BoxedDiv box={{}}>
                <Header
                    dataSubmission={submission.dataSubmission}
                    clearFile={importCtrl.clearFile}
                    file={importCtrl.file}
                    handleClearData={vm.ctrl.clearDataCtrl.handleClearData}
                    showExtraButtons={shouldShowExtraButtons}
                    handleTemplateImport={importCtrl.handleTemplateImport}
                    process={importCtrl.process}
                    setTemplateImportState={importCtrl.setTemplateImportState}
                    templateImportState={importCtrl.templateImportState} 
                    currentStepIndex={vm.ctrl.progressCtrl.currentStep.index}         
                />

                <BoxedDiv className='container' box={{marginV: 4}}>
                    
                    {/* Progress indicator */}
                    <BoxedDiv box={{marginV: 6}}>
                        <SubmissionProgressBar
                            isComplete={viewMode === 'view'}
                            steps={progressCtrl.progressSteps}
                            onStepClick={saveCtrl.checkChangesAndGoToPage}
                        />
                    </BoxedDiv>

                    {/* Step frontmatter */}
                    {
                        progressCtrl.currentStep.kind === 'data' ? <>
                            <BoxedDiv box={{marginTop: 4}}>
                                <FrontMatter />
                            </BoxedDiv>
                        </> :
                        <H2>Declaration</H2>
                    }

                    {/* Main form editor */}
                    {
                        progressCtrl.currentStep.kind === 'data' &&
                        <ProductionTypeEditor
                            formCtrl={formCtrl}
                            portalDataCtrl={portalDataCtrl}
                            productionTypeConfig={vm.productionTypeConfig}
                            viewMode={viewMode as ViewMode}
                        />
                    }

                    {/* Submit page */}
                    {
                        isSubmitPage &&
                        <ProductionSubmit
                            formCtrl={formCtrl}
                            viewMode={viewMode as ViewMode}
                        />
                    }

                    {/* Page messages */}
                    {
                        viewMode === 'edit' &&
                        <ProductionNotifications
                            formCtrl={formCtrl}
                            progressCtrl={progressCtrl}
                            saveCtrl={saveCtrl}
                            serviceResponse={serviceResponse}
                            submitCtrl={submitCtrl}
                        />
                    }

                    {/* Controls */}
                    <DataSubmissionPrimaryControls>
                        <BoxedDiv box={{alignItems: 'center', flex: 'row-reverse'}}>
                            {
                                primaryControl.isShown &&
                                <Button
                                    disabled={primaryControl.isDisabled}
                                    $kind='primary'
                                    onClick={primaryControl.action}
                                    state={primaryControl.state}
                                >{primaryControl.label}</Button>
                            }

                            {
                                secondaryControl.isShown &&
                                <Button
                                    disabled={secondaryControl.isDisabled}
                                    $kind='ghost'
                                    marginRight={2}
                                    onClick={secondaryControl.action}
                                    state={secondaryControl.state}
                                >{secondaryControl.label}</Button>
                            }

                        </BoxedDiv>
                    </DataSubmissionPrimaryControls>

                    {/* Unsaved changes confirmation */}
                    <ConfirmSaveChanges
                        controlsState={apiCtrl.isBusy ? 'loading' : 'normal'}
                        isOpen={saveCtrl.unsavedChangesState === 'showing_dialog'}
                        onCancel={saveCtrl.cancelSave}
                        onConfirm={saveCtrl.confirmSave}
                        onSecondary={saveCtrl.proceedWithoutSave}
                    />

                    {/* Review alerts dialog */}
                    <ConfirmationDialog
                        ariaLabel='Your data contains alerts - please review before continuing'
                        body={
                            <BoxedDiv box={{flex: 'column'}}>
                                <p>The data on this page has triggered one or more alerts, which require you to provide a comment.</p>
                                <p>If you want to review these alerts and review or add a comment, please click Review alerts.</p>
                                <p>If you have addressed these and entered a comment, click Proceed to continue to the next page.</p>
                            </BoxedDiv>
                        }
                        cancelLabel='Review alerts'
                        isOpen={saveCtrl.validationAlertsState === 'showing_dialog'}
                        onCancel={saveCtrl.cancelValidationAlerts}
                        onConfirm={saveCtrl.confirmValidationAlerts}
                        title='Your data contains alerts - please review before continuing'
                    />

                    {/* Confirm Clear all dialog */}
                    {
                        shouldShowExtraButtons &&
                        <ConfirmationDialog
                            ariaLabel='Clear all data confirm'
                            body={vm.ctrl.clearDataCtrl.confirmCtlr.message}
                            title={vm.ctrl.clearDataCtrl.confirmCtlr.title}
                            onCancel={vm.ctrl.clearDataCtrl.confirmCtlr.cancel}
                            onConfirm={vm.ctrl.clearDataCtrl.confirmCtlr.confirm}
                            isOpen={vm.ctrl.clearDataCtrl.confirmCtlr.confirmState === 'confirming'}
                        />
                    }

                    {/* Confirm deletes dialog */}
                    <DeleteConfirmationDialog
                        title='Confirm record deletion'
                        deleteRequestState={formCtrl.deleteRequestState}
                        setDeleteRequestState={formCtrl.setDeleteRequestState}
                    />
                    
                    {/* Scroll helper */}
                    <ScrollTo />
                </BoxedDiv>
            </BoxedDiv>
        </MainContainer>
    );
}

function useVM<TGroup extends ProductionProductGroup>({apiCtrl, productionTypeConfig, refData}: ProductionEditorProps<TGroup>) {
    const ctrl = useProduction({apiCtrl, refData, ...productionTypeConfig});

    return {
        ctrl,
        productionTypeConfig,
    };
}

export default ProductionEditor;
