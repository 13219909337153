import React, { useCallback, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';

import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { H2 } from 'psims/react/components/typography';
import { ActionCellTD, ActionCellTH, ColumnHeader, Table, TD, TH, TR } from 'psims/react/pages/primary-pages/data-submissions/shared/data-table-components';
import { UseImporterAnnualActivities } from './use-importer-annual-activities';
import Input from 'psims/react/pages/primary-pages/data-submissions/shared/input';
import Checkbox from 'psims/react/components/checkbox';
import { UpdateMsoAnnualActivityImporterField } from 'psims/models/submission-types/mso/annual-activity/importer/update-mso-annual-activity-importer';
import { UseFocusedField } from 'psims/react/util/use-focused-field';
import { FocusField, isDataFocusField, isDeleteProductFocusField, MsoProductView } from '../../shared/types';
import { UseConfirm } from '../../../shared/use-confirm';
import { recordActionFromEnum } from 'psims/models/api/data-submission-record-action';
import useFocusable from 'psims/react/util/use-focusable';
import { IconButton } from 'psims/react/components/button';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import FloatingMessage from 'psims/react/components/floating-message';
import { EXPIRED_OR_INTERNAL_PRODUCT } from 'psims/constants/validation-messages';
import { TooltipHelp } from '../../../shared/tooltip-help';
import { isEmpty } from 'psims/lib/empty';

interface AnnualActivitiesEditorProps {
  annualActivitiesCtrl: UseImporterAnnualActivities;
  deleteCtrl: UseConfirm;
  focusFieldCtrl: UseFocusedField<FocusField>;
  forceErrors: boolean;
  isDisabled: boolean;
  products: Array<MsoProductView>;
}

const AnnualActivitiesEditor = (props: AnnualActivitiesEditorProps) => {
  const vm = useAnnualActivitiesEditorVM(props);

  return (
    <BoxedDiv box={{flex: 'column'}}>
      <BoxedDiv box={{marginTop: 6}}>
        <H2>MSO annual activity</H2>

        <p>
          This section allows you to fill in the total import volumes for the previous calendar year, which will be used to set your future MSO quantities. Importing volumes (for self) must meet the definition under section 5 of the <em>Fuel Security Act 2022</em> and section 5(3) of the <em>Fuel Security (Minimum Stockholding Obligation) Rules 2022</em>. The 'Total import volume (by other entities)' and 'Total import volume (on behalf of)' columns will be populated from the data entered in the tables below. 
        </p>

        <p>
          Quantities should be reported to the nearest <strong>megalitre (ML)</strong>.
        </p>
      </BoxedDiv>

      <BoxedDiv box={{marginTop: 2}}>

        <Table caption='Data for annual activities' customWidth='fit-content'>
          <thead>
            <TR>
              <ColumnHeader label='MSO Product' fixedWidth='230px' />

              <ColumnHeader
                $align='center'
                label='Total import volume (for self)'
                fixedWidth='230px'
                Help={
                  <div>
                    <p>The total volumes you have imported for your entity (exclude volumes that are either imported for another entity or on behalf of another entity (for section 19F(2)(e) of the POFR Rules)).</p>
                    <p>Note: Importing volumes must meet the definition under section 5 of the <em>Fuel Security Act 2022</em> and section 5(3) of the <em>Fuel Security (Minimum Stockholding Obligation) Rules 2022</em>.</p>
                    <p>Quantities should be reported to the nearest <strong>megalitre (ML)</strong>.</p>
                  </div>
                }
              />

              <ColumnHeader
                $align='center'
                label='Total import volume (by other entities)'
                fixedWidth='230px'
                Help={<p>This figure will be populated from the data entered in the tables below. It should correspond to the total volume that has been imported by another entity/entities for you (for section 19F(2)(e) of the POFR Rules).</p>}
              />

              <ColumnHeader
                $align='center'
                label='Total import volume (on behalf of)'
                fixedWidth='230px'
                Help={<p>This figure will be populated from the data entered in the tables below. It should correspond to the total volume that you have imported on behalf of another entity (for section 19F(2)(e) of the POFR Rules).</p>}
              />

              <ColumnHeader
                $align='center'
                label='Total annual volume'
                fixedWidth='230px' 
                Help={<>
                  <p>The aggregate importing volumes for your entity. </p>
                  <p>The volume is calculated using the formula: Total annual volume = total import volume (for self) + total import volume (by other entities) minus total import volume (on behalf of).</p>
                </>}
              />

              <ColumnHeader $align='center'
                label='Total storage capacity'
                fixedWidth='230px'
                Help='The total storage capacity for each MSO product for your entity (section 19F(2)(c) of the POFR Rules) at a point in time at the end of the calendar year.'
              />

              <ColumnHeader
                label='Enforceable arrangements?'
                fixedWidth='330px'
                Help={<div>
                  <p>
                    In the past 12 months, did you use an enforceable arrangement for any MSO product under section 23 or section 24 of the <em>Fuel Security Act 2022</em> to help meet your MSO?
                  </p>
                  <p>
                    If yes, please upload the details as per paragraph 19F(2)(e) of the MSO Rules to the system.
                  </p>
                </div>}
              />

              {
                vm.hasExpiredData ?
                <ActionCellTH>
                  <VisuallyHidden>Actions</VisuallyHidden>
                </ActionCellTH> :
                null
              }
            </TR>
          </thead>

          <tbody>
            {
              vm.data.map((data, index) => (
                <DataRow
                  annualActivitiesCtrl={vm.annualActivitiesCtrl}
                  data={data}
                  deleteCtrl={vm.deleteCtrl}
                  focusFieldCtrl={vm.focusFieldCtrl}
                  forceErrors={vm.forceErrors}
                  index={index}
                  isDisabled={vm.isDisabled}
                  msoProduct={vm.products.find(p => p.id === data.data.msoProductId) as MsoProductView}
                  key={index}
                />
              ))
            }
          </tbody>

        </Table>
      </BoxedDiv>
    </BoxedDiv>
  );
}

function useAnnualActivitiesEditorVM ({
  annualActivitiesCtrl,
  deleteCtrl,
  focusFieldCtrl,
  forceErrors,
  isDisabled,
  products
}: AnnualActivitiesEditorProps) {

  const data = useMemo(() => {
    return annualActivitiesCtrl.updateAnnualActivities
      .filter(a => products.some(p => p.id === a.data.msoProductId))
  }, [annualActivitiesCtrl.updateAnnualActivities, products]);

  const hasExpiredData = useMemo(() => {
    return !isDisabled && products.some(p => {
      return data.find(d => (
        d.data.msoProductId === p.id &&
        p.productStatus === 'inactive' &&
        recordActionFromEnum(d.data.recordAction) !== 'Delete'
      ));
    });
  }, [data, isDisabled, products]);

  return {
    annualActivitiesCtrl,
    data,
    deleteCtrl,
    focusFieldCtrl,
    forceErrors,
    hasExpiredData,
    isDisabled,
    products,
  }
}

type UpdateAnnualActivitiesDraft = UseImporterAnnualActivities['updateAnnualActivities'][number];

interface DataRowProps {
  annualActivitiesCtrl: UseImporterAnnualActivities;
  data: UpdateAnnualActivitiesDraft;
  deleteCtrl: UseConfirm;
  focusFieldCtrl: UseFocusedField<FocusField>;
  forceErrors: boolean;
  index: number;
  isDisabled: boolean;
  msoProduct: MsoProductView;
}

const DataRow = (props: DataRowProps) => {
  const vm = useDataRowVM(props);

  if (vm.hideProduct) {
    return null;
  }

  return <TR>
    <TH scope='row' verticalAlign='middle'>
      <BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
        {vm.msoProduct.name}

        {
          vm.showRowError ?
            <BoxedSpan box={{ marginBottom: -0.5, marginLeft: 1 }}>
              <FloatingMessage
                content={EXPIRED_OR_INTERNAL_PRODUCT}
                kind="warning"
                role="alert"
              />
            </BoxedSpan> :
            !isEmpty(vm.msoProduct.Help?.content) ?
            <TooltipHelp
              Help={<ReactMarkdown>{vm.msoProduct.Help?.content as string}</ReactMarkdown>}
            /> :
            null
        }
      </BoxedSpan>
    </TH>

    <TD>
      <Input
        disabled={vm.isDisabled}
        error={vm.validations.totalImportVolumeSelf?.inline}
        forceError={vm.forceErrors}
        kind='number'
        label='Total import volume (for self)'
        onChange={val => vm.updateField('totalImportVolumeSelf', val)}
        shouldFocus={vm.rowFocusField?.field === 'totalImportVolumeSelf'}
        value={vm.data.totalImportVolumeSelf}
      />
    </TD>

    <TD>
      <Input
        disabled={true}
        error={vm.validations.totalImportVolumeOthers?.inline}
        forceError={vm.forceErrors}
        info={vm.infos.totalImportVolumeOthers?.inline}
        kind='number'
        label='Total import volume (by other entities)'
        onChange={() => {}}
        shouldFocus={vm.rowFocusField?.field === 'totalImportVolumeOthers'}
        value={vm.data.totalImportVolumeOthers}
      />
    </TD>

    <TD>
      <Input
        disabled={true}
        error={vm.validations.totalImportVolumeOnBehalf?.inline}
        forceError={vm.forceErrors}
        info={vm.infos.totalImportVolumeOnBehalf?.inline}
        kind='number'
        label='Total import volume (on behalf of)'
        onChange={() => {}}
        shouldFocus={vm.rowFocusField?.field === 'totalImportVolumeOnBehalf'}
        value={vm.data.totalImportVolumeOnBehalf}
      />
    </TD>

    <TD>
      <Input
        disabled={true}
        info={vm.infos.totalAnnualVolume?.inline}
        kind='number'
        label='Total annual volume'
        onChange={() => {}}
        shouldFocus={false}
        value={vm.totalAnnualVolume}
      />
    </TD>

    <TD>
      <Input
        disabled={vm.isDisabled}
        error={vm.validations.totalStorageCapacity?.inline}
        forceError={vm.forceErrors}
        kind='number'
        label='Total storage capacity'
        onChange={val => vm.updateField('totalStorageCapacity', val)}
        shouldFocus={vm.rowFocusField?.field === 'totalStorageCapacity'}
        value={vm.data.totalStorageCapacity}
      />
    </TD>

    <TD>
      <Checkbox
        disabled={vm.isDisabled}
        label='Enforceable arrangements'
        name={`enforceable_arrangements_${vm.index}`}
        onChange={val => vm.updateField('enforceableArrangements', val)}
        value={vm.data.enforceableArrangements || false}
      />
    </TD>

    {
      vm.showDeleteButton ?
      <ActionCellTD>
        <DeleteProductButton
          focusedField={vm.focusFieldCtrl.focusedField}
          onClick={() => vm.deleteCtrl.requestConfirmation(
            'Are you sure you wish to delete this data?',
            'This annual activity record will be deleted.',
            () => vm.annualActivitiesCtrl.deleteAnnualActivityByProductId(vm.msoProduct.id)
          )}
          productId={vm.msoProduct.id}
        />
      </ActionCellTD> :
      null
    }
  </TR>
}

function useDataRowVM({
  annualActivitiesCtrl,
  data,
  deleteCtrl,
  focusFieldCtrl,
  forceErrors,
  index,
  isDisabled,
  msoProduct
}: DataRowProps) {
  const rowFocusField = useMemo(() => {
    return isDataFocusField(focusFieldCtrl.focusedField) && focusFieldCtrl.focusedField?._id === data.data._id ? focusFieldCtrl.focusedField : null
  }, [data.data._id, focusFieldCtrl]);

  const updateField = useCallback(
    <TField extends UpdateMsoAnnualActivityImporterField, TVal extends UpdateAnnualActivitiesDraft['data'][TField]>
    (field: TField, val: TVal) => {
    annualActivitiesCtrl.updateAnnualActivity({
      ...data.data,
      [field]: val
    })
  }, [data, annualActivitiesCtrl]);

  const hasExpiredData = useMemo(() => {
    return msoProduct.productStatus === 'inactive' && recordActionFromEnum(data.data.recordAction) !== 'Delete';
  }, [msoProduct, data])

  const hideProduct = useMemo(() => {
    return msoProduct.productStatus === 'inactive' && data.data.id == null;
  }, [msoProduct, data.data.id]);

  const showRowError = useMemo(() => {
    return hasExpiredData && !isDisabled;
  }, [hasExpiredData, isDisabled]);

  const showDeleteButton = useMemo(() => {
    return hasExpiredData && !isDisabled
  }, [hasExpiredData, isDisabled]);

  return {
    annualActivitiesCtrl,
    data: data.data,
    deleteCtrl,
    focusFieldCtrl,
    forceErrors,
    hideProduct,
    index,
    infos: data.validationAlerts,
    isDisabled,
    msoProduct,
    rowFocusField,
    showDeleteButton,
    showRowError,
    totalAnnualVolume: data.totalAnnualVolume,
    updateField,
    validations: data.validations,
  }
}

interface DeleteProductProps {
    focusedField: unknown;
    onClick: () => any;
    productId: number;
}

const DeleteProductButton = React.forwardRef(({ focusedField, onClick, productId }: DeleteProductProps, ref) => {
    const focusable = useFocusable({
        setFocused: (
            isDeleteProductFocusField(focusedField) &&
            focusedField.kind === 'annualActivities' &&
            focusedField.productId === productId
        ),
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <BoxedSpan box={{marginLeft: 1}}>
          <IconButton
              color='dark-grey'
              icon='trash'
              label='Delete row for product'
              onClick={onClick}
              ref={setRef}
              size='sm'
          />
        </BoxedSpan>
    );
});

export default AnnualActivitiesEditor;
