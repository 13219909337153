import { useAPI } from "psims/react/providers/api";
import useDataSubmissionAPI from "psims/react/pages/primary-pages/data-submissions/shared/use-data-submission-api";
import { isSuccesfulRowResult } from "psims/models/api/record-result";
import { getRecordResults, isMsoAnnualActivityImporterSubmission, isMsoAnnualActivityImporterSubmitted, MsoAnnualActivityImporterSubmission } from "psims/models/submission-types/mso/annual-activity/importer/mso-annual-activity-importer-submission";

interface UseImporterAnnualActivityAPIProps {
    id: number;
}

function useImporterAnnualActivityAPI({id}: UseImporterAnnualActivityAPIProps) {
    const {api} = useAPI();

    return useDataSubmissionAPI({
        actionFetch: api.getMsoAnnualActivityImporterSubmission,
        actionSubmit: api.submitMsoAnnualActivityImporter,
        actionUpdate: api.updateMsoAnnualActivityImporter,
        actionUpdateDataSubmission: api.updateMsoAnnualActivityImporterDataSubmission,
        actionClearAllData: api.clearAllMsoAnnualActivityImporter,
        dataSubmissionId: id,
        name: 'MSO importer - Annual activity',
        submissionAssertion: isMsoAnnualActivityImporterSubmission,
        submitSuccessAssertion: isMsoAnnualActivityImporterSubmitted,
        updateResponseTransform: removeDeleted,
        updateSuccessAssertion,
    });
}

export default useImporterAnnualActivityAPI;

export type UseImporterAnnualActivityAPI = ReturnType<typeof useImporterAnnualActivityAPI>;

function updateSuccessAssertion(result?: unknown) {
    if (result == null) {
        return false;
    }

    return getRecordResults(result)
        .filter(r => !isSuccesfulRowResult(r)).length === 0;
}

function removeDeleted(result: MsoAnnualActivityImporterSubmission): MsoAnnualActivityImporterSubmission {
    const transformed = {
        ...result,
        stockRefineriesHeld: result.annualActivities.filter(a => a.recordResult?.rowResult !== 'Deleted'),
        stockholdings: result.storageSites.filter(s => s.recordResult?.rowResult !== 'Deleted'),
    };

    return transformed;
}
