import { Document } from "psims/models/document";
import { useMemo } from "react";
import { FilterDocumentsQuery } from "./use-documents-filter";

interface UseDocumentsResultsProps {
  documents: Array<Document> | null;
  query: FilterDocumentsQuery;
}

function useDocumentsResults({documents, query}: UseDocumentsResultsProps) {
  const results = useMemo(() => {
    if (documents == null) {
      return [];
    }

    return documents
      .filter(d => query.documentTypeName == null || d.documentTypeName === query.documentTypeName)
      .filter(d => query.organisationId == null || d.organisationId === query.organisationId)
      .sort((a, b) => (new Date(b.portalUploadOn)).getTime() - (new Date(a.portalUploadOn)).getTime());
    
  }, [documents, query]);

  return {
    results,
  };
}

export default useDocumentsResults

export type UseDocumentsResults = ReturnType<typeof useDocumentsResults>;
