import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { InvalidEntityNameCode } from "psims/react/pages/primary-pages/data-submissions/mso/shared/validation";

/** Shared */
export const INVALID_DECLARATION = 'You must agree to the declaration in order to submit' as const;
export const INVALID_EMAIL = 'Please enter a valid email (e.g. firstname.lastname@industry.gov.au)' as const;
export const INVALID_ENTITY_NAME_TEXT = 'Invalid text entered, please use only letters, numbers, hyphens, apostrophes, and parentheses.' as const;
export const INVALID_NAME_TEXT = 'Invalid text entered, please use only letters, numbers, hyphens, and apostrophes.' as const;
export const INVALID_ADDRESS_TEXT = 'Invalid text entered, please use only letters, numbers, hyphens, apostrophes, commas, fullstops, colons and ampersand (&).' as const;
export const INVALID_HELP_TEXT_LENGTH = 'Must be 4000 characters or fewer' as const;
export const INVALID_COMMENTS_LENGTH = 'Must be 4000 characters or fewer' as const;
export const INVALID_COMMENTS_REQUIRED = 'Please enter a reason in the comments field to confirm your data is correct.' as const;
export const INVALID_COMMENTS_CHARACTERS = 'Invalid text entered, please use only letters, number and standard keyboard characters.' as const;
export const INVALID_COMMENTS_REQUIRED_WHEN_SAME_AS_PREVIOUS = 'Please enter a reason in the comments field to confirm your data is correct.';
export const GENERIC_SAVE_ERROR = 'Save failed. Please check values and try again and contact support if the issue persists.'
export const INCOMPLETE_ROW = 'Where a product has a value in a row, all fields in the row are required';

/** Contact registration */
export const INVALID_PHONE_NUMBER = 'Please enter a numeric phone number between 10 and 18 characters' as const;
export const INVALID_ADDRESS_LINE1 = 'Please enter an address 100 characters or fewer' as const;
export const INVALID_ADDRESS_LINE2 = 'Must be 100 characters or fewer' as const;
export const INVALID_ADDRESS_LINE2_NA = 'Address line 2 must be blank where address line 1 is blank' as const;
export const INVALID_POSTCODE = 'Please enter a valid 4 digit Australian postcode' as const;
export const INVALID_STATE = 'Please select a state' as const;
export const INVALID_SUBURB = 'Please enter a suburb' as const;

/** Production */
export const INACTIVE_PRODUCTIONAREA_ERROR = 'This production area is no longer active for your organisation, please remove this record or correct the production area to an active one.';
export const INACTIVE_PRODUCTIONAREA_FACILITY_NAME_ERROR = 'This facility name is no longer active for your organisation, please remove this record or correct the facility name to an active one.';
export const INVALID_AVERAGEDENSITY_RANGE = 'Please enter a decimal (zero is permitted) with a maximum value of 5 held to 10 decimal places' as const;
export const INVALID_GROSSCALORIFICVALUE_RANGE = 'Please enter a decimal (zero is permitted) with a maximum value of 100 held to 3 decimal places' as const;
export const INVALID_PRODUCTION_AREA_FACILITY_NAME_CONFLICT = 'A record for this facility name exists on this page for this product, please remove this record or edit the existing record' as const;
export const INVALID_PRODUCTION_EXPIRED = 'This product is inactive, please remove all records for the product to continue' as const;

/** Stockholding */
export const INVALID_COUNTRY = 'Please select a country' as const;
export const INVALID_COUNTRY_ISINTERNAL_SELECTED = 'This country is no longer active. Please remove this record or correct the country to an active one.' as const;
export const INVALID_COUNTRY_CONFLICT = 'You have another record with this location, please edit that record' as const;
export const INVALID_QUANTITY = 'Please enter an integer (zero is permitted) with a maximum value of +/- 100,000,000' as const;
export const INVALID_QUANTITY_REQUIRED = 'Please enter a quantity' as const;
export const INVALID_VOLUME_INTEGER = 'Please enter an integer (zero is permitted) with a maximum value of +/- 100,000,000' as const;
export const INVALID_VOLUME_RANGE = 'Please enter an integer (zero is permitted) with a maximum value of +/- 100,000,000' as const;

/** Refining */
export const INVALID_DENSITY_RANGE = 'Please enter a decimal (zero is permitted) with a maximum value of 5 held to 10 decimal places' as const;

/** DEF */
export const INVALID_DEF_PRODUCT_EXPIRED = 'This product is inactive, please enter details into an active product if applicable and then remove the row to continue' as const;

/** Expired/Internal Product */
export const EXPIRED_OR_INTERNAL_PRODUCT = 'This product is inactive, please enter details into an active product if applicable. Delete the row to continue.' as const;
export const EXPIRED_OR_INTERNAL_PRODUCT_COLUMN = 'This product is inactive, please enter details into an active product if applicable. Delete the values in this column to continue.' as const;
export const EXPIRED_OR_INTERNAL_PRODUCT_MULTI_ROW = 'This product is inactive, please enter details into an active product if applicable. Delete the row(s) for this product to continue.' as const;

/** User */
export const INVALID_FIRST_NAME = 'Please enter a first name 50 characters or fewer' as const;
export const INVALID_LAST_NAME = 'Please enter a last name 50 characters or fewer' as const;
export const INVALID_ROLE_TYPE = 'Please select a role type' as const;

/** Organisation contact */
export const INVALID_POSITION = 'Please enter a position 50 characters or fewer' as const;
export const DUPLICATE_EMAIL = 'The email address for the primary and secondary contacts of an organisation must be unique' as const;

/** MSO */
export const INVALID_OWNERSHIP_TYPE_EMPTY = 'Please select a stock ownership type' as const;
export const INVALID_AMOUNT_EMPTY = 'Please enter an amount' as const;
export const INVALID_ENTITY_NAME = 'Please enter an entity name with 200 characters or fewer' as const;
export const INVALID_ENTITY_NAME_ARRANGEMENT_EMPTY = 'Please enter the name of the entity this arrangement is with' as const;
export const INVALID_ENTITY_NAME_ON_BEHALF_EMPTY = 'Please enter the name of the entity this stock is held on behalf of' as const;
export const INLINE_INVALID_MSO_NOT_MET_REQUIRES_COMMENTS = 'You have not met the minimum stockholding obligation set for your organisation by the Secretary. You are required to provide a reason in the comments field.' as const;
export const INLINE_INVALID_ACQUITTAL_REQUIRES_COMMENTS = 'You have provided an acquittal amount for one or more products. Please provide supporting information of the acquittal, in accordance with your compliance plan.' as const;
export const INVALID_MSO_NOT_MET_REQUIRES_COMMENTS = 'You have not met the minimum stockholding obligation set for your organisation by the Secretary. If this is correct, please provide a reason in the'

/** Dynamic message generators */
export const DYN_INVALID_QUANTITY_SYMMETRIC_RANGE = (range: number | string, negativeAllowed?: boolean) => `Please enter ${negativeAllowed ? 'an integer' : 'a positive integer'} (zero is permitted) with a maximum value of ${negativeAllowed ? '+/-' : ''} ${range}.`;
export const DYN_INVALID_CALCULATED_QUANTITY_SYMMETRIC_RANGE = (range: number | string, negativeAllowed?: boolean) =>
    `The calculated value exceeds the maximum permitted value (${negativeAllowed ? '+/- ' : ''}${range}), please review the amounts you entered and correct before saving this page.`

export const DYN_INVALID_POSITIVE_DECIMAL = (upperBound: string, decimalPlaces: number) =>
    `Please enter a decimal (zero is permitted) with a maximum value of ${upperBound} held to ${decimalPlaces} decimal places.`;

export const DYN_NOTIFICATION_INVALID_QUANTITY_SYMMETRIC_RANGE = (range: number | string, negativeAllowed?: boolean) => [
    'The value you entered in ',
    ` must be ${negativeAllowed ? 'an integer' : 'a positive integer'} (zero is permitted) with a maximum value of ${negativeAllowed ? '+/-' : ''} ${range}.`
];

export const DYN_NOTIFICATION_INVALID_DENSITY_ASYMMETRIC_RANGE = (lowerBound: number | string, upperBound: number | string, 
    decimalPlacesBound: number | string, upperBoundStr: string, fieldDescription: string) => [
    `The value you entered in ${fieldDescription} in the `,
    ` column must be a maximum value of${0 > lowerBound && 0 < upperBound ? ' (zero is permitted) ' : ' '} ${upperBoundStr} held to ${localeNumberWithFixed(decimalPlacesBound)} decimal places.`
];

export const DYN_NOTIFICATION_INVALID_ENTITY_NAME = (maxChars: number, code: InvalidEntityNameCode, excludeValueFor?: boolean) => {
    const isRequiredMsg = code !== 'invalid_characters' && code !== 'invalid_length';

    if (isRequiredMsg) {
        return [
            'Please enter a value for',
            '.'
        ];
    }

    return [
    `The${excludeValueFor ? '' : ' value for'} `,
    ...(
        code === 'invalid_characters' ? [' must only be letters, numbers, hyphens, apostrophes, or parentheses.'] :
        code === 'invalid_length' ? [` must be ${maxChars} characters or fewer.`] :
        [' is required.']
    ),
    ]
};

export const DYN_NOTIFICATION_INVALID_CALCULATED_QUANTITY = (range: number | string, negativeAllowed?: boolean) => [
    'The calculated ',
    ` exceeds the maximum permitted value (${negativeAllowed ? '+/- ' : ''}${range}), please review the amounts you entered and correct before saving this page.`
];