/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganisationVMApiResponse } from '../models/OrganisationVMApiResponse';
import type { OrganisationVMIEnumerableApiResponse } from '../models/OrganisationVMIEnumerableApiResponse';
import type { UpdateOrganisationVM } from '../models/UpdateOrganisationVM';
import type { UpdateOrganisationVMApiResponse } from '../models/UpdateOrganisationVMApiResponse';
import { request as __request } from '../core/request';

export class OrganisationsService {

    /**
     * @returns OrganisationVMIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static async getOrganisationsService(): Promise<OrganisationVMIEnumerableApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/organisations`,
        });
        return result.body;
    }

    /**
     * @returns OrganisationVMApiResponse Success
     * @throws ApiError
     */
    public static async getOrganisation({
id,
}: {
id?: number,
}): Promise<OrganisationVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/organisations/organisation-by-id`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns UpdateOrganisationVMApiResponse Success
     * @throws ApiError
     */
    public static async getOrganisationForEdit({
id,
}: {
id?: number,
}): Promise<UpdateOrganisationVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/organisations/organisation-by-id-edit`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns OrganisationVMApiResponse Success
     * @throws ApiError
     */
    public static async updateOrganisation({
requestBody,
}: {
requestBody?: UpdateOrganisationVM,
}): Promise<OrganisationVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/organisations/Update`,
            body: requestBody,
        });
        return result.body;
    }

}