import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import CompanyHeader from 'psims/react/blocks/company-header';
import { BoxedDiv } from 'psims/react/components/layout';
import { H1 } from 'psims/react/components/typography';
import useDocumentList from './use-document-list';
import DocumentList from './document-list';
import Button from 'psims/react/components/button';
import DocumentUpload from './document-upload';
import { Document } from 'psims/models/document';
import Notification from 'psims/react/components/notification';
import SupportLink from 'psims/react/components/support-link';
import useDocumentsFilter from './use-documents-filter';
import useDocumentsResults from './use-documents-results';
import DocumentsFilter from './documents-filter';
import { SelectController } from 'psims/react/components/select';
import usePagination from 'psims/react/blocks/pagination/use-pagination';
import Pagination from 'psims/react/blocks/pagination/pagination';

interface DocumentsPageProps { }

const DocumentsPage = (props: DocumentsPageProps) => {
  const vm = useVM();

  return (
    <BoxedDiv box={{ alignSelf: 'stretch', flex: 'column', flexGrow: 1, justifySelf: 'center' }}>
      <CompanyHeader />

      <div className='container'>
        <H1>Documents</H1>

        <BoxedDiv box={{flex: 'row'}}>

          <BoxedDiv box={{flexGrow: 1}}>
            <p>The place for uploading and storing documents provided to the Department.</p>
          </BoxedDiv>

          <BoxedDiv box={{}}>
            <Button
              $kind='primary'
              onClick={vm.onClickUpload}
            >Upload document</Button>
          </BoxedDiv>
        </BoxedDiv>

        {
          vm.hasAnyDocuments ?

          <BoxedDiv box={{marginV: 4}}>
            <DocumentsFilter
              documentTypeSelectCtrl={vm.filterCtrl.documentTypeSelectCtrl as SelectController}
              organisationSelectCtrl={vm.filterCtrl.organisationSelectCtrl as SelectController}
              reset={vm.filterCtrl.reset}
            />
          </BoxedDiv> :

          null
        }

        {
          vm.successMessage &&
          <BoxedDiv box={{marginV: 2}}>
            <Notification
              align='center'
              kind='confirmation'
            >
              {vm.successMessage}
            </Notification>
          </BoxedDiv>
        }

        {
          vm.downloadErrorMessage &&
          <BoxedDiv box={{marginV: 2}}>
            <Notification
              align='center'
              kind='warning'
            >
              {vm.downloadErrorMessage}
            </Notification>
          </BoxedDiv>
        }

        <BoxedDiv box={{ alignItems: 'flex-end', flex: 'column', marginBottom: 1 }}>
          <BoxedDiv box={{ alignSelf: 'stretch', marginTop: 2.5 }}>
            <DocumentList
              documents={vm.documents}
              hasAnyDocuments={vm.hasAnyDocuments}
              onDownloadAttempt={vm.onDownloadAttempt }
              onDownloadError={vm.onDownloadError}
            />

            <Pagination paginationCtrl={vm.paginationCtrl} />
          </BoxedDiv>
        </BoxedDiv>

        <DocumentUpload
          documentTypeOptions={vm.documentTypeOptions}
          isShown={vm.showUpload}
          organisationOptions={vm.organisationOptions}
          onDismiss={vm.onDismissUpload}
          onSaved={vm.onUploadSuccess}
        />
      </div>
    </BoxedDiv>
  );
}

const PAGE_SIZE_OPTIONS = [
	{ value: 10 },
	{ value: 25 },
	{ value: 50 },
	{ label: 'Show all', value: Infinity },
]

function useVM() {
  const documentsListCtrl = useDocumentList();
  const [showUpload, setShowUpload] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [downloadErrorMessage, setDownloadErrorMessage] = useState<ReactNode | null>(null);
  
  const filterCtrl = useDocumentsFilter();

  const resultsCtrl = useDocumentsResults({
    documents: documentsListCtrl.documents, 
    query: filterCtrl.query
  });

  const onClickUpload = useCallback(() => {
    setShowUpload(true);
    setSuccessMessage(null);
    setDownloadErrorMessage(null);
  }, []);

  const onDismissUpload = useCallback(() => {
    setShowUpload(false);
  }, []);

  const paginationCtrl = usePagination({
		items: resultsCtrl.results,
		initialPageSize: 50,
		pageSizeOptions: PAGE_SIZE_OPTIONS,
		resultsLabel: 'Document result'
	});

  const onUploadSuccess = useCallback((document: Document) => {
    documentsListCtrl.addDocument(document);
    setSuccessMessage('Your upload has been successful.');
    setShowUpload(false);
  }, [documentsListCtrl]);

  const onDownloadError = useCallback((doc: Document) => {
    setDownloadErrorMessage(
      <span>Failed to get download for {doc.name}. Please try again or contact <SupportLink /> if the problem persists.</span>
    );
  }, []);

  const onDownloadAttempt = useCallback((doc: Document) => {
    setDownloadErrorMessage(null);
  }, []);

  const hasAnyDocuments = useMemo(() => {
    return (documentsListCtrl.documents?.length || 0) > 0;
  }, [documentsListCtrl.documents?.length]);

  return {
    documents: paginationCtrl.pageData,
    documentTypeOptions: filterCtrl.documentTypeOptions,
    organisationOptions: filterCtrl.organisationOptions,
    filterCtrl,
    hasAnyDocuments,
    showUpload,
    successMessage,
    downloadErrorMessage,
    paginationCtrl,
    onClickUpload,
    onDismissUpload,
    onDownloadAttempt,
    onDownloadError,
    onUploadSuccess,
  };
}

export default DocumentsPage;
