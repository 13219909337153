import { DocumentStatusDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { prefixedThrower } from "psims/lib/exception";

const STATUS_NAMES = [
  'Uploaded',
  'Submitted',
  'Deleted',
] as const;

type DocumentStatusName = typeof STATUS_NAMES[number];

export type DocumentStatus = PopulatedProps<DocumentStatusDTO,  'id' | 'name'> & {
  name: DocumentStatusName
};

export function assertDocumentStatusName(maybe?: unknown): asserts maybe is DocumentStatusName {
  const maybeAs = maybe as DocumentStatusName;

  const err = prefixedThrower(`Failed to assert documentStatusName: ${JSON.stringify(maybe)}: `);

  if (!STATUS_NAMES.includes(maybeAs)) err(`unknown status name: ${maybeAs}`);
}

export function assertDocumentStatus(maybe?: unknown): asserts maybe is DocumentStatus {
  const maybeAs = maybe as DocumentStatus;

  const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

  assertNotEmpty(maybeAs, hint('documentStatus'));
  assertNotEmpty(maybeAs.id, hint('documentStatus.id'));
  assertNotEmpty(maybeAs.name, hint('documentStatus.name'));
  assertDocumentStatusName(maybeAs.name);
}

export function isDocumentStatus(maybe?: unknown): maybe is DocumentStatus {
  assertDocumentStatus(maybe);
  return true;
}
