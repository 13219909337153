import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from "react";

import { useAPI } from ".";
import { Status } from "psims/types/async";
import { Dashboard, isDashboard } from "psims/models/dashboard";

type DashboardContext = {
    refetch: () => void;
    status: Status;
    dashboard: Dashboard | null
}

const context = createContext<DashboardContext | null>(null);

const DashboardProvider = ({children}: PropsWithChildren<{}>) => {
    const {api} = useAPI();
    const [dashboard, setDashboard] = useState<Dashboard | null>(null);
    const [status, setStatus] = useState<Status>('init');
    // Quick-and-dirty ad-hoc debounce - probably should hookify if needed elsewhere
    const debouncedId = useRef<number | null>(null);

    const fetch = useCallback(() => {
        if (debouncedId.current != null) {
            return;
        }

        setStatus('loading');

        debouncedId.current = window.setTimeout(() => debouncedId.current = null, 100);

        api.getDashboard()
        .then(response => {
            if (response?.isSuccessful && isDashboard(response.result)) {
                setDashboard({
                    ...response.result,
                    dataSubmissions: [
                        ...(response.result.dataSubmissions || []),
                    ]
                });
                setStatus('fulfilled');
            } else {
                setStatus('error');
            }
        })
        .catch(() => {
            setStatus('error');
        });
    }, [api]);

    useEffect(() => {
        if (dashboard !== null) {
            return;
        }

        fetch();
    }, [dashboard, fetch]);

    return <context.Provider value={{dashboard, status, refetch: fetch}}>
        {children}
    </context.Provider>
}

export default DashboardProvider;

export function useDashboard() {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error('useDashboard must be used within DashboardProvider');
    }

    return {
        ...ctx,
    };
}
