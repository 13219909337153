import React, { PropsWithChildren } from 'react';

import { BoxedDiv } from './layout';
import Spinner from './spinner';

interface LoadingProps {}

const Loading = ({children}: PropsWithChildren<LoadingProps>) => {
    return (
        <BoxedDiv box={{alignItems: 'center', flex: 'row'}}>
            <Spinner size='md' />

            <BoxedDiv box={{marginLeft: 2}}>
                {children}
            </BoxedDiv>
        </BoxedDiv>
    )
}

export default Loading;
