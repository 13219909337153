import { useEffect, useState } from "react";

interface UseKeyHandlerProps {
  onKeyup: () => any;
  key: string;
}

function useKeyHandler({onKeyup, key}: UseKeyHandlerProps) {
  const [el, setEl] = useState<HTMLElement | null>(null);
  
  useEffect(() => {
    if (el == null) {
      return;
    }

    function handler(e: KeyboardEvent) {
      if (e.key === key) {
        onKeyup();
      }
    }

    el.addEventListener('keyup', handler);

    return () => {
      el.removeEventListener('keyup', handler);
    }
  }, [el, onKeyup, key]);

  return {
    setEl,
  }
}

export default useKeyHandler

export type UseKeyHandler = ReturnType<typeof useKeyHandler>;
