import { ProductionProductGroup } from "./production-product-group";

// TODO: update with real referenceCode when available
const FLD_GROUP_REF_CODES = [
    'PRO-FP-ICO',
    'PRO-FP-FCW',
    'PRO-FP-LPG',
    'PRO-FP-NOG'
] as const;

type FldGroupReferenceCode = typeof FLD_GROUP_REF_CODES[number];

type FieldProductionGroup = PopulatedProps<
    ProductionProductGroup & {
        displayName: string;
        referenceCode: FldGroupReferenceCode;
    }, 'id' | 'displayOrder' | 'isActive' | 'name' | 'referenceCode'
>;

export function isFieldProductionGroup(maybe?: unknown): maybe is FieldProductionGroup {
    const maybeAs = maybe as FieldProductionGroup;
    return maybeAs != null && 
    typeof maybeAs.id === 'number' &&
    maybeAs.productionTypeName === 'Field production' &&
    maybeAs.name != null
}

type FldProductionGroupRefCodeName = 'ico' | 'fc' | 'lpg' | 'ng';

const FLD_PRODUCTION_GROUP_REF_CODE_MAP: { [key in FldProductionGroupRefCodeName]: FldGroupReferenceCode } = {
  ico: 'PRO-FP-ICO',
  fc: 'PRO-FP-FCW',
  lpg: 'PRO-FP-LPG',
  ng: 'PRO-FP-NOG',
}

export function getFieldProductionGroupReferenceCode(key: FldProductionGroupRefCodeName) {
  return FLD_PRODUCTION_GROUP_REF_CODE_MAP[key];
}