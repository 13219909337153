import { isValidForPeriod } from "psims/models/ref-data/util";
import { ProductionSubmission } from "psims/models/submission-types/production";
import { ViewMode } from "psims/react/pages/primary-pages/data-submissions/shared/use-view-mode";
import useDataSubmissionProgress from "../shared/use-data-submission-progress";
import { UseProductionRefData } from "./use-production-ref-data";
import { Step, UseProductionSteps } from "./use-production-steps";

interface UseProductionProgressProps {
    refData: UseProductionRefData;
    stepsCtrl: UseProductionSteps;
    submission: ProductionSubmission;
    viewMode: ViewMode;
}

function useProductionProgress({refData, stepsCtrl, submission, viewMode}: UseProductionProgressProps) {
    return useDataSubmissionProgress({
        stepsCtrl,
        submission,
        viewMode,
        doesStepHaveData,
        getDataStepStatus,
        getFirstUnsavedStepIndex: (s) => getFirstUnsavedStepIndex({submission: s, refData}),
    });
}

export default useProductionProgress

export type UseProductionProgress = ReturnType<typeof useProductionProgress>;

interface GetFirstUnsavedStepIndexProps {
    submission: ProductionSubmission,
    refData: UseProductionRefData;
}

// Helpers
function getFirstUnsavedStepIndex({submission: {dataSubmission, pageData, productions}, refData}: GetFirstUnsavedStepIndexProps) {
    if (!pageData.pageSaved) {
        return 0;
    }

    const nonExpiredProducts = refData.groups
        .filter(g => {
            const withEffectiveDates = {
                effectiveFrom: g.refData.effectiveFrom,
                effectiveTo: g.refData.effectiveTo,
            }
            return isValidForPeriod(withEffectiveDates, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo)
        })
        .map(g => g.products)
        .flat()
        .filter(p => {
            const withEffectiveDates = {
                effectiveFrom: p.effectiveFrom,
                effectiveTo: p.effectiveTo,
            }
            return isValidForPeriod(withEffectiveDates, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo)
        });

    const atLeastOnePersistedNonExpired = productions.find(p => {
        return nonExpiredProducts.find(nep => nep.id === p.id) != null;
    })

    return atLeastOnePersistedNonExpired == null ? 1 : 0;
}

function doesStepHaveData(step: Step, submission: ProductionSubmission) {
    if (step.kind === 'data') {
        return submission.productions.length > 0;
    }

    return false;
}

function getDataStepStatus(step: Step, {pageData}: ProductionSubmission) {
    return step.kind === 'data' && pageData.pageSaved ? 'complete' : 'pending';
}
