import { useCallback, useMemo, useState } from "react";

import { asString } from "psims/lib/string";
import { Organisation } from "psims/models/organisation";
import { useSelectController } from "psims/react/components/select";
import { useReferenceData } from "psims/react/providers/api/reference-data";
import { getReportYear, ReportingHistoryItem } from "./reporting-history-item";

interface UseFilterReportingHistoryProps {
    reportingEntities: Array<Organisation> | null;
    reportingHistoryItems: Array<ReportingHistoryItem> | null;
}

export interface FilterReportHistories {
    reportType: string | undefined | null;
    reportYear: number | undefined | null;
    reportFreq: number | undefined | null;
    reportMonth: number | undefined | null;
    reportEntityId: number | undefined | null;
    statusId: number | undefined | null;
}

type ReportMonthOption = {
    value: number | null,
    label: string
}

const REPORT_MONTH_OPTIONS: Array<ReportMonthOption> = [
    {label: 'All', value: null},
    {label: 'January', value: 1},
    {label: 'February', value: 2},
    {label: 'March', value: 3},
    {label: 'April', value: 4},
    {label: 'May', value: 5},
    {label: 'June', value: 6},
    {label: 'July', value: 7},
    {label: 'August', value: 8},
    {label: 'September', value: 9},
    {label: 'October', value: 10},
    {label: 'November', value: 11},
    {label: 'December', value: 12},
]

const REPORT_FREQ_OPTIONS: Array<ReportMonthOption> = [
    {label: 'All', value: null},
    {label: 'Monthly', value: 1},
    {label: '6 Monthly', value: 2},
    {label: 'Fortnightly', value: 3},
    {label: 'Annually', value: 4},
    {label: 'Quarterly', value: 5},
]

type ReportTypeOption = {
    value: string | null,
    label: string
}

const REPORT_TYPE_OPTIONS: Array<ReportTypeOption> = [
    {label: 'All', value: null},
    {
        value: "Biofuel production",
        label: "Biofuel production"
    },
    {
        value: "Diesel exhaust fluid",
        label: "Diesel exhaust fluid"
    },
    {
        value: "Field production",
        label: "Field production"
    },
    {
        value: "FSSP s19A",
        label: "FSSP s19A"
    },
    {
        value: "MSO importer",
        label: "MSO importer"
    },
    {
        value: "MSO importer - Annual activity",
        label: "MSO importer - Annual activity"
    },
    {
        value: "MSO refiner",
        label: "MSO refiner"
    },
    {
        value: "MSO refiner - Annual activity",
        label: "MSO refiner - Annual activity"
    },
    {
        value: "Plant production",
        label: "Plant production"
    },
    {
        value: "Refining",
        label: "Refining"
    },
    {
        value: "Stockholding",
        label: "Stockholding"
    },
    {
        value: "Wholesaling",
        label: "Wholesaling"
    }
]

function useReportingHistoryFilter({reportingEntities, reportingHistoryItems}: UseFilterReportingHistoryProps) {
    const {data: refData} = useReferenceData();
    const [query, setQuery] = useState<FilterReportHistories>(getDefaultQuery);

    const statusOptions = useMemo(() => {
        if (refData?.externalReportStatuses == null) {
            return [];
        }

        let options = refData.externalReportStatuses
        .filter(status => status.name !== 'Inactive')
        .map(status => ({
            label: asString(status.name),
            value: status.id as number || null,
        }));

        options.unshift({label: 'All', value: null});
        return options;
    }, [refData]);

    const updateFilterField = useCallback(<K extends keyof FilterReportHistories>(field: K, value: FilterReportHistories[K]) => {
        // Clear month filter if Annually selected
        setQuery(prv => {
            return {
                ...prv,
                [field]: value,
                ...(field === 'reportFreq' && value === 4 ? {reportMonth: null} : {})
            };
        });
    }, []);

    const yearOptions = useMemo(() => {
        if (reportingHistoryItems === null || reportingHistoryItems.length === 0) {
            return [];
        }

        const years = reportingHistoryItems.reduce((s, item) => {
            const y = getReportYear(item);
            if (y != null) {
                s.add(y);
            }

            return s;
        }, new Set<number>());

        let options: Array<{label: string, value: number | null}> = Array.from(years).sort().map(y => ({label: String(y), value: y}));

        options.unshift({label: 'All', value: null});

        return options;
    }, [reportingHistoryItems]);

    const selectFreqCtrl = useSelectController({
        value: query.reportFreq, 
        onChange: reportFreq => updateFilterField('reportFreq', reportFreq || undefined),
        options: REPORT_FREQ_OPTIONS,
    });

    const selectYearCtrl = useSelectController({
        value: query.reportYear, 
        onChange: reportYear => updateFilterField('reportYear', reportYear || undefined),
        options: yearOptions,
    });

    const reportingEntityOptions = useMemo(() => {
        if (reportingEntities === null) {
            return [];
        }

        let options = reportingEntities
        .map(reportingEntity => ({
            label: asString(reportingEntity.name),
            value: reportingEntity.id as number || null,
        }));

        options.unshift({label: 'All', value: null});

        return options;
    }, [reportingEntities]);
    
    const selectStatusCtrl = useSelectController({
        value: query.statusId, 
        onChange: statusId => updateFilterField('statusId', statusId || undefined),
        options: statusOptions,
    });

    const selectReportingEntityCtrl = useSelectController({
        value: query.reportEntityId, 
        onChange: reportEntityId => updateFilterField('reportEntityId', reportEntityId || undefined),
        options: reportingEntityOptions,
    });

    const selectReportTypeCtrl = useSelectController({
        value: query.reportType,
        onChange: reportType => updateFilterField('reportType', reportType || undefined),
        options: REPORT_TYPE_OPTIONS,
    });

    const selectReportMonthCtrl = useSelectController({
        value: query.reportMonth, 
        onChange: reportMonth => updateFilterField('reportMonth', reportMonth || undefined),
        options: REPORT_MONTH_OPTIONS,
    });

    function resetFilters(){
        setQuery(getDefaultQuery());
    }

    return {
        selectReportTypeCtrl,
        selectReportMonthCtrl,
        selectReportingEntityCtrl,
        selectStatusCtrl,
        selectYearCtrl,
        selectFreqCtrl,
        query,
        actions: {updateFilterField},
        resetFilters
    }

} 

export default useReportingHistoryFilter;

export type UseFilterReportingHistory = ReturnType<typeof useReportingHistoryFilter>;
    
function getDefaultQuery(): FilterReportHistories{
    return {
        reportType : undefined,
        reportYear : undefined,
        reportFreq: undefined,
        reportMonth : undefined,
        reportEntityId : undefined,
        statusId : undefined,
    };
}