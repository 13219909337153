import {
    Menu as ReachMenu,
    MenuButton as ReachMenuButton,
    MenuLink as ReachMenuLink,
    MenuList as ReachMenuList,
    MenuItem as ReachMenuItem,
    MenuItems as ReachMenuItems,
    MenuPopover as ReachMenuPopover,
    MenuPopoverProps as ReachMenuPopoverProps,
} from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import { SPACE_BASE } from 'psims/constants/styles';
import styled from 'styled-components';

export const Menu = ReachMenu;

export const MenuButton = ReachMenuButton;

const StyledMenuPopover = styled(ReachMenuPopover)`
    box-shadow: var(--box-shadow-mid);
    border-color: var(--color-blue-45);
    border-radius: 6px;
    overflow: hidden;
    background: var(--color-white);
    
    & [data-reach-menu-items] {
        border: none;
    }

    & [data-reach-menu-item] {
        padding-bottom: 6px;
        padding-top: 6px;
        color: var(--color-blue-70);
    }

    & [data-reach-menu-item][data-selected] {
        background: var(--color-link);
        color: var(--color-white);
    }
`;

export const MenuPopover = (props: ReachMenuPopoverProps) => {
    const { portal, position, children, ...rest }= props;
    return (
    <StyledMenuPopover portal={portal} position={position} >
        <ReachMenuItems {...rest}>
            {children}
        </ReachMenuItems>
    </StyledMenuPopover>
    );
};

const StyledMenuList = styled(ReachMenuList)`
    box-shadow: var(--box-shadow-mid);
    border-color: var(--color-blue-45);
    border-radius: 6px;
    overflow: hidden;
    padding: ${SPACE_BASE}px 0;

    & [data-reach-menu-item] {
        padding-bottom: 6px;
        padding-top: 6px;
        color: var(--color-blue-70);
    }

    & [data-reach-menu-item][data-selected] {
        background: var(--color-link);
        color: var(--color-white);
    }
`;

export const MenuList = StyledMenuList;
export const MenuLink = ReachMenuLink;
export const MenuItem = ReachMenuItem;
