import produce from "immer";
import { isEmpty } from "psims/lib/empty";
import { recordActionAsEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { MsoAnnualActivityRefiner } from "psims/models/submission-types/mso/annual-activity/refiner/mso-annual-activity-refiner";
import { UpdateMsoAnnualActivityRefiner, UpdateMsoAnnualActivityRefinerField } from "psims/models/submission-types/mso/annual-activity/refiner/update-mso-annual-activity-refiner";
import { UseFocusedField } from "psims/react/util/use-focused-field";
import { useCallback, useEffect, useMemo, useState } from "react";
import { cleanSubmissionEntityList } from "../../../shared/update-data-utils";
import useTrackChanges from "../../../shared/use-track-changes";
import { FieldValidation, FocusField } from "../../shared/types";
import { UseMsoAnnualActivityRefData } from "../../shared/use-mso-annual-activity-ref-data";
import { getInlineMessage } from "./refiner-annual-activities-inline-messages";
import { getNotificationMessage } from "./refiner-annual-activities-notifications";
import { validateRefinerAnnualActivity, ValidationResult } from "./refiner-annual-activities-validation";

interface ProgressController {
  currentStep: {
    kind: 'data' | 'submit';
  };
  goToStep: (index: number) => any;
}

interface UseRefinerAnnualActivitiesProps {
  dataSubmissionId: number;
  focusFieldCtrl: UseFocusedField<FocusField>;
  initialAnnualActivities: Array<MsoAnnualActivityRefiner>;
  progressCtrl: ProgressController;
  refData: UseMsoAnnualActivityRefData;
}

type UpdateMsoAnnualActivityRefinerDraft = Partial<UpdateMsoAnnualActivityRefiner> & {
  msoProductId: number;
  _id: symbol
};

function useRefinerAnnualActivities({dataSubmissionId, focusFieldCtrl, initialAnnualActivities, progressCtrl, refData}: UseRefinerAnnualActivitiesProps) {
  const initialAnnualActivitiesAsUpdate = useMemo(() => {
    return refData.products.map(({id}) => {
      const annualActivity = initialAnnualActivities.find(aa => aa.msoProductId === id);
      return toUpdateMsoAnnualActivityRefinerDraft(dataSubmissionId ,id, annualActivity);
    })
  }, [dataSubmissionId, initialAnnualActivities, refData.products]);

  const [data, setData] = useState(initialAnnualActivitiesAsUpdate);

  const changeCtrl = useTrackChanges({
    currentData: data,
    dataFields: ['enforceableArrangements', 'totalAnnualVolume', 'totalStorageCapacity'],
    initialData: initialAnnualActivitiesAsUpdate,
  });

  const updateAnnualActivity = useCallback((annualActivity: UpdateMsoAnnualActivityRefinerDraft) => {
    setData(prev => produce(prev, draft => {
      const idx = data.findIndex(s => s._id === annualActivity._id);
      draft[idx] = {
        ...annualActivity,
        recordAction: recordActionAsEnum(annualActivity.id == null ? 'Create' : 'Update'),
      }
    }));
  }, [data]);

  const deleteAnnualActivityByProductId = useCallback((productId: number) => {
    setData(prev => {
      return prev
        .filter(d => d.msoProductId !== productId || d.id != null)
        .map(d => {
          if (d.msoProductId !== productId) {
            return d;
          }

          return {
            ...d,
            recordAction: recordActionAsEnum('Delete'),
          }
        })
    })
  }, []);
  
  const dataView = useMemo(() => {
    return data
      .filter(d => recordActionFromEnum(d.recordAction) !== 'Delete')
      .map(a => ({
        data: a,
        validations: validateRefinerAnnualActivity(a, refData),
      }))
      .map(s => ({
        data: s.data,
        validations: s.validations.reduce((map, validationResult) => ({
          ...map,
          [validationResult.key]: {
            inline: getInlineMessage({...validationResult}),
            notification: {
              parts: getNotificationMessage(
                validationResult.key,
                () => {
                  const {code} = validationResult;
                  if (code === 'inactive_product') {
                    if (progressCtrl.currentStep.kind === 'submit') {
                      progressCtrl.goToStep(0);
                    }
                    focusFieldCtrl.setFocusedField({field: 'delete', kind: 'annualActivities', productId: s.data.msoProductId})
                  } else {
                    focusFieldCtrl.setFocusedField({_id: s.data._id, field: validationResult.key, kind: 'data'})
                  }
                },
                refData.productIdMap[s.data.msoProductId].name,
                validationResult
              )
            },
            validationResult
          }
        }), {} as {[key in UpdateMsoAnnualActivityRefinerField | 'delete']?: FieldValidation<ValidationResult>})
      }))
  }, [data, focusFieldCtrl, progressCtrl, refData]);

  const requestEntities = useMemo(() => cleanSubmissionEntityList(data), [data]);

  const anyDeleted = useMemo(
    () => data.some(d => recordActionFromEnum(d.recordAction) === 'Delete'), 
    [data]
  );

  const anyInvalid = useMemo(
    () => dataView.some(v => !isEmpty(v.validations)),
    [dataView]
  );

  useEffect(() => {
    setData(initialAnnualActivitiesAsUpdate)
  }, [initialAnnualActivitiesAsUpdate]);

  // Reset on page change
  useEffect(() => {
    if (progressCtrl.currentStep.kind !== 'data') {
      setData(initialAnnualActivitiesAsUpdate);
    }
  }, [initialAnnualActivitiesAsUpdate, progressCtrl]);

  return {
    anyInvalid,
    hasChanges: changeCtrl.hasChanges || anyDeleted,
    requestEntities,
    updateAnnualActivities: dataView,
    deleteAnnualActivityByProductId,
    updateAnnualActivity,
  }
}

function toUpdateMsoAnnualActivityRefinerDraft(dataSubmissionId: number, msoProductId: number, annualActivity?: MsoAnnualActivityRefiner): UpdateMsoAnnualActivityRefinerDraft {
  let {recordResult, ...data} = (annualActivity || {});

  if (isEmpty(data)) {
    data = {
      dataSubmissionId,
      msoProductId,
      totalAnnualVolume: null,
      totalStorageCapacity: null,
      enforceableArrangements: false
    }
  }

  return {
    ...data as UpdateMsoAnnualActivityRefinerDraft,
    enforceableArrangements: (data as UpdateMsoAnnualActivityRefinerDraft).enforceableArrangements || false,
    recordAction: undefined,
    _id: Symbol(),
  };
}

export default useRefinerAnnualActivities

export type UseRefinerAnnualActivities = ReturnType<typeof useRefinerAnnualActivities>;