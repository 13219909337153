import { INVALID_ADDRESS_LINE1, INVALID_PHONE_NUMBER, INVALID_POSTCODE, INVALID_STATE, INVALID_SUBURB } from "psims/constants/validation-messages";
import { isEmpty } from "psims/lib/empty";
import { ContactDetails, DraftContactDetails } from "psims/models/contact-details";
import { User } from "psims/models/user";
import { validatePhone, validateAddress1, validateAddress2, validateCity, validatePostcode, isAddressSectionEmpty } from "psims/lib/validation/user";

export type UserWithContactDetails = User & {
    contactDetails: DraftContactDetails;
}

export function newRegistration(user: User): UserWithContactDetails {
    return {
        ...user,
        contactDetails: {
            phone: '',
            address: {
                line1: '',
                line2: '',
                postcode: '',
                stateId: undefined,
                suburb: '',
            }
        }
    }
}

export function updatePhone(state: UserWithContactDetails, phone: string) {
    return {
        ...state,
        contactDetails: {
            ...state.contactDetails,
            phone,
        }
    };
}

export function updateAddress<K extends keyof ContactDetails['address'], V extends ContactDetails['address'][K]>(state: UserWithContactDetails, field: K, value: V | undefined) {
    return {
        ...state,
        contactDetails: {
            ...state.contactDetails,
            address: {
                ...state.contactDetails.address,
                [field]: value
            },
        },
    };
}

export function updateBulk(state: UserWithContactDetails, update: Partial<UserWithContactDetails>) {
    return {
        ...state,
        ...update,
    };
}

type ValidationMessages = {
    phone?: string;
    address: {
        line1?: string;
        line2?: string;
        postcode?: string;
        stateId?: string;
        suburb?: string;
    }
}

// See PBI 359720 - change so that mandatory fields are only mandatory if at least on of them
// is populated
export function getValidationMessages(state: UserWithContactDetails) {
    const messages: ValidationMessages = {address: {}};
    const {contactDetails} = state;

    // Always validate phone required
    const phoneMsg = !contactDetails.phone ? INVALID_PHONE_NUMBER : validatePhone(contactDetails.phone);

    if (phoneMsg != null) {
        messages.phone = phoneMsg ;
    }

    // For address - only apply mandatory validation if at least one candidate field is populated.
    if (isAddressSectionEmpty({
        addressLine1: contactDetails.address.line1,
        addressLine2: contactDetails.address.line2,
        postcode: contactDetails.address.postcode,
        stateId: contactDetails.address.stateId,
        suburb: contactDetails.address.suburb,
    })) {
        return messages;
    }
    
    const address1Msg = !contactDetails.address.line1 ? INVALID_ADDRESS_LINE1 : validateAddress1(contactDetails.address.line1);
    if (address1Msg != null) {
        messages.address.line1 = address1Msg;
    }

    const address2Msg = validateAddress2(contactDetails.address.line2, contactDetails.address.line1);
    if (address2Msg != null) {
        messages.address.line2 = address2Msg;
    }

    const postcodeMsg = !contactDetails.address.postcode ? INVALID_POSTCODE : validatePostcode(contactDetails.address.postcode);
    if (postcodeMsg != null) {
        messages.address.postcode = postcodeMsg;
    }

    if (!contactDetails.address.stateId) {
        messages.address.stateId = INVALID_STATE;
    }

    const cityMsg = !contactDetails.address.suburb ? INVALID_SUBURB : validateCity(contactDetails.address.suburb);
    if (cityMsg != null) {
        messages.address.suburb = cityMsg;
    }

    return messages;
}

export function isSubmittable(state: UserWithContactDetails) {
    const validationMessages = getValidationMessages(state);

    return !validationMessages.phone && isEmpty(validationMessages.address);
}
