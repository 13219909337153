import { useCallback, useEffect, useMemo, useState } from "react";

import { isEmpty } from "psims/lib/empty";
import { encodeEscapeChars } from "psims/lib/validation/string";
import { recordActionAsEnum, RecordActionEnum } from "psims/models/api/data-submission-record-action";
import { isUpdateDefMarketExpectations, MaybeDefMarketExpectations } from "psims/models/submission-types/def/def-market-expectations";

import { validateMarketExpectations } from "./validate-market-expectations";

interface UseMarketExpectationsProps {
  initialData: MaybeDefMarketExpectations | undefined;
}

function useMarketExpectations({ initialData }: UseMarketExpectationsProps) {
  const [data, setData] = useState(toUpdate(initialData));

  const dataView = useMemo(() => {
    return {
      data,
      validation: validateMarketExpectations(data?.marketExpectationsComments),
    }
  }, [data]);

  const update = useCallback((val?: string) => setData(prev => {
    const recordAction = prev.id ? (
      isEmpty(val) ? recordActionAsEnum('Delete') : recordActionAsEnum('Update')
    ) : (
      isEmpty(val) ? null : recordActionAsEnum('Create')
    );

    return {
      ...prev,
      marketExpectationsComments: val,
      recordAction,
    }
  }), []);

  const getRequestPayload = useCallback(() => {
    return (isUpdateDefMarketExpectations(data) && data.recordAction != null) ? {
      ...data,
      marketExpectationsComments: encodeEscapeChars(data.marketExpectationsComments),
     } : undefined;
  }, [data]);

  useEffect(() => {
    setData(toUpdate(initialData));
  }, [initialData]);

  return {
    data: dataView,
    getRequestPayload,
    update,
  }
}

function toUpdate(maybe: MaybeDefMarketExpectations | undefined) {
  return {
    ...(maybe || {}),
    recordAction: null as RecordActionEnum | null,
  };
}

export default useMarketExpectations

export type UseMarketExpectations = ReturnType<typeof useMarketExpectations>;
