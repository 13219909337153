import React, { useMemo } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import Header from 'psims/react/blocks/data-submission-header';
import { UseDefRefData } from './use-def-ref-data';
import DefRetailEditor from './def-data-editor';
import SubmissionProgressBar from 'psims/react/components/submission-progress-bar';
import SubmitPage from '../shared/submit-page';
import { H2 } from 'psims/react/components/typography';
import DataSubmissionPrimaryControls from 'psims/react/components/data-submission-primary-controls';
import Button from 'psims/react/components/button';
import Notifications from '../shared/notifications';
import useDef, { CUSTOM_DECLARATION } from './use-def';
import { UseDefAPI } from './use-def-api';
import ScrollTo from 'psims/react/components/scroll-to';
import ConfirmSaveChanges from 'psims/react/components/confirm-save-changes';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';
import MainContainer from '../shared/main-container';
import { DeleteConfirmationDialog } from '../shared/delete-confirmation';

interface DefEditorProps {
  apiCtrl: UseDefAPI;
  refData: UseDefRefData;
}

const DefEditor = (props: DefEditorProps) => {
  const vm = useVM(props);
  const { apiCtrl, controlsCtrl } = vm.ctrl;
  const { primaryControl, secondaryControl } = controlsCtrl;

  return (
    <MainContainer ariaDescribedby='frontmatter' dataSubmission={vm.ctrl.apiCtrl.submission.dataSubmission}>
      <BoxedDiv box={{ flex: 'column' }}>
        <Header
          dataSubmission={apiCtrl.submission.dataSubmission}
          showExtraButtons={vm.shouldShowExtraButtons}
          handleClearData={vm.ctrl.clearDataCtrl.handleClearData}
          currentStepIndex={vm.ctrl.progressCtrl.currentStep.index}
        />

        <BoxedDiv box={{ marginV: 4 }}>
          <SubmissionProgressBar
            isComplete={false}
            onStepClick={vm.ctrl.navCtrl.attemptStepNavigation}
            steps={vm.ctrl.progressCtrl.progressSteps}
          />
        </BoxedDiv>

        <BoxedDiv box={{ flex: 'column' }} className='container'>
          {
            vm.ctrl.progressCtrl.currentStep.index === 0 &&
            <DefRetailEditor ctrl={vm.ctrl} />
          }

          {
            vm.ctrl.progressCtrl.currentStep.index === 1 && <>
              <H2>Declaration</H2>

              <SubmitPage
                CustomDeclaration={CUSTOM_DECLARATION}
                CustomTerms={
                  <BoxedDiv box={{marginV: 2}}>
                    <p>Thank you for your continued engagement with the Department of Climate Change, Energy, the Environment and Water to develop the DEF evidence base. Your information will be combined with other respondent's information to develop a picture of the DEF supply chain for industry and government decision making.</p>
                    <br />
                    <p>Information collected through this survey is treated as commercial in confidence by the Department of Climate Change, Energy, the Environment and Water. It will not be released publicly in a way that will identify an individual or company.</p>
                    <br />
                  </BoxedDiv>
                }
                ctrl={{
                  ...vm.ctrl.submitCtrl,
                  isDisabled: vm.ctrl.isDisabled,
                  dataSubmission: vm.ctrl.apiCtrl.submission.dataSubmission
                }}
                focusedFieldCtrl={vm.ctrl.focusFieldCtrl}
                viewMode={vm.ctrl.viewMode}
              />
            </>
          }

          {/* Notifications */}
          {
            vm.ctrl.viewMode === 'edit' ?
            <Notifications
              infoNotifications={vm.ctrl.notifcationsCtrl.validationAlerts}
              systemNotifications={vm.ctrl.notifcationsCtrl.serviceNotifications}
              validationNotifications={vm.ctrl.notifcationsCtrl.validationMessages}
            /> :
            null
          }

          <DataSubmissionPrimaryControls>
            <BoxedDiv box={{ alignItems: 'center', flex: 'row-reverse', marginTop: 2 }}>
              {
                primaryControl.isShown &&
                <Button
                  disabled={primaryControl.isDisabled}
                  $kind='primary'
                  marginLeft={2}
                  onClick={primaryControl.action}
                  state={primaryControl.state}
                >{primaryControl.label}</Button>
              }

              {
                secondaryControl.isShown &&
                <Button
                  disabled={secondaryControl.isDisabled}
                  $kind='ghost'
                  onClick={secondaryControl.action}
                  state={secondaryControl.state}
                >{secondaryControl.label}</Button>
              }
            </BoxedDiv>
          </DataSubmissionPrimaryControls>

        </BoxedDiv>

        <ConfirmSaveChanges {...vm.ctrl.navCtrl.unsavedChangesDialogCtrl} />

        {/* Review alerts dialog */}
        <ConfirmationDialog
          ariaDescribedby='validation_alert_dialog'
          ariaLabel='Your data contains alerts - please review before continuing'
          body={
            <BoxedDiv box={{ flex: 'column' }} id='validation_alert_dialog'>
              <p>The data on this page has triggered one or more alerts, which require you to provide a comment.</p>
              <p>If you want to review these alerts and review or add a comment, please click Review alerts.</p>
              <p>If you have addressed these and entered a comment, click Proceed to continue to the next page.</p>
            </BoxedDiv>
          }
          cancelLabel='Review alerts'
          isOpen={vm.ctrl.validationAlertsConfirmCtrl.confirmState === 'confirming'}
          onCancel={vm.ctrl.validationAlertsConfirmCtrl.cancel}
          onConfirm={vm.ctrl.validationAlertsConfirmCtrl.confirm}
          title='Your data contains alerts - please review before continuing'
        />

        {/* Confirm Clear all dialog */}
        {
          vm.ctrl.progressCtrl.currentStep.index === 0 &&
          <ConfirmationDialog
            ariaLabel='Clear all data confirm'
            body={vm.ctrl.clearDataCtrl.confirmCtlr.message}
            title={vm.ctrl.clearDataCtrl.confirmCtlr.title}
            onCancel={vm.ctrl.clearDataCtrl.confirmCtlr.cancel}
            onConfirm={vm.ctrl.clearDataCtrl.confirmCtlr.confirm}
            isOpen={vm.ctrl.clearDataCtrl.confirmCtlr.confirmState === 'confirming'}
          />
        }
        {/* Confirm delete dialog */}

        <DeleteConfirmationDialog
            title='Confirm record deletion'
            deleteRequestState={vm.ctrl.activitiesCtrl.deleteRequestState}
            setDeleteRequestState={vm.ctrl.activitiesCtrl.setDeleteRequestState}
        />

        {/* Scroll helper */}
        <ScrollTo />

      </BoxedDiv>
    </MainContainer>
  )
}

function useVM({ apiCtrl, refData }: DefEditorProps) {
  const ctrl = useDef({ refData, apiCtrl: apiCtrl as PopulatedProps<UseDefAPI, 'submission'> });

  const shouldShowExtraButtons = useMemo(() => (
    ctrl.progressCtrl.currentStep.index === 0 && ctrl.viewMode === 'edit'),
    [ctrl.progressCtrl.currentStep.index, ctrl.viewMode]
  );

  return {
    ctrl,
    shouldShowExtraButtons,
  };
}

export default DefEditor;
