import { HistoryItemVM, HistoryListVM } from "psims/gen/xapi-client";
import { DataSubmissionTypeName, ReportFrequencyTypeName } from "./data-submission";
import { ExternalReportStatus } from "./ref-data/external-report-status";

export type HistoryList = HistoryListVM;

export type HistoryItem = PopulatedProps<
    HistoryItemVM,
    'caseId' | 'externalReportStatusId' | 'id' |'reportFrequencyTypeId' | 'reportFrequencyTypeName' | 
    'reportingYear' | 'responsibleOrganisationId' | 'responsibleOrganisationName' | 'reportingOrganisationId' |
    'reportingOrganisationName' | 'status' | 'submissionTypeId' | 'submissionTypeName'
> & {
    reportFrequencyTypeName: ReportFrequencyTypeName;
    status: ExternalReportStatus;
    submissionTypeName: DataSubmissionTypeName;
};

export function isHistoryItem(maybe?: unknown): maybe is HistoryItem {
    const maybeAs = maybe as HistoryItem
    return (
        maybeAs != null &&
        maybeAs.id != null && 
        maybeAs.caseId != null &&
        maybeAs.submissionTypeId != null &&
        maybeAs.responsibleOrganisationId != null &&
        maybeAs.responsibleOrganisationName != null &&
        maybeAs.externalReportStatusId != null &&
        maybeAs.reportingOrganisationId != null && 
        maybeAs.reportingOrganisationName != null &&
        ((maybeAs.reportingYear != null && maybeAs.reportingMonth != null) || maybeAs.obligationDate != null) &&
        maybeAs.status != null 
    )
}

