import { useMemo } from "react"
import styled from "styled-components"

import { localeNumberWithFixed } from "psims/lib/formatters/numbers"
import {InputNew as Input} from "psims/react/components/input"
import Select from "psims/react/components/select"
import { TBody, TD } from "psims/react/components/table"
import Button from "psims/react/components/button"
import { SPACE_BASE } from "psims/constants/styles"
import { BoxedDiv } from "psims/react/components/layout"
import Text from "psims/react/components/text"
import { asNumber } from "psims/lib/number"
import { INFO_NEGATIVE_VALUE_COMMENTS } from "psims/constants/info-messages"
import stepConfig from "./step-config"
import { ResponseStatus } from "psims/models/api/submission/update/response"
import { StockholdingSubmission, UpdateStockholdingDomestic, UpdateStockholdingOnWater, UpdateStockholdingOnWaterPorts, UpdateStockholdingOverseas } from "psims/models/submission-types/stockholding"
import { RecordResult } from "psims/models/api/record-result"
import { ValidationMessage } from "psims/models/api/response"
import InfoIcon from "psims/react/components/info-icon"
import { QuantityInvalidErrorMessage } from "psims/react/pages/primary-pages/data-submissions/shared/messages"
import { StockTypeName } from "psims/models/ref-data/stock-type"
import { UsePortalDataAPI } from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api"
import { WithIsExpired } from "../shared/types"
import { StockProductGroup } from "psims/models/ref-data/stock-product-group"

export type PageKey = keyof ReturnType<typeof stepConfig>

type StockholdingDomesticMetaFields = 'id' | 'stockProductId' | 'concurrencyToken';

export type DomesticRowField = Exclude<keyof UpdateStockholdingDomestic, StockholdingDomesticMetaFields>;

export type DomesticRowFocusField = {
    stockProductId: number;
    field: DomesticRowField;
} 

type StockholdingOverseasMetaFields = 'id' | 'stockProductId' | 'concurrencyToken' | 'recordAction';

export type OverseasRowField = Exclude<keyof UpdateStockholdingOverseas, StockholdingOverseasMetaFields>;

export type OverseasRowFocusField = {
    stockProductId: number;
    field: OverseasRowField;
    rowIndex: number;
} 

type StockholdingOnWaterMetaFields = 'id' | 'stockProductId' | 'concurrencyToken' | 'stockholdingOnWaterPorts' | 'recordAction';

type StockholdingOnWaterPortsMetaFields = 'id' | 'stockProductId' | 'concurrencyToken' | 'stockholdingOnWaterId' | 'recordAction';

export type OnWaterField = Exclude<keyof UpdateStockholdingOnWater, StockholdingOnWaterMetaFields>;

export type OnWaterPortsField = Exclude<keyof UpdateStockholdingOnWaterPorts, StockholdingOnWaterPortsMetaFields>;

export type OnWaterRowFocusField = {
    stockProductId: number;
    field: OnWaterField;
}

export type OnWaterPortsRowFocusField = {
    stockProductId: number;
    field: OnWaterPortsField;
    rowIndex: number;
}

export type DeleteFocusField = {
    stockProductId: number;
    field: 'delete';
}

export type FocusField = DomesticRowFocusField | OnWaterRowFocusField | OnWaterPortsRowFocusField | DeleteFocusField | 'comments';

export function isDeleteFocusField(maybe?: unknown): maybe is DeleteFocusField {
    const maybeAs = maybe as DeleteFocusField;

    return (
        maybeAs != null &&
        maybeAs.field === 'delete' &&
        maybeAs.stockProductId != null
    );
}

export const STEPS = [
    {name: 'Australia' as PageKey},
    {name: 'Overseas' as PageKey},
    {name: 'On water' as PageKey},
    {name: 'Submit' as PageKey},
] as const;

export const PAGE_INDEX = [
    {pageIndex: 0 as number}, // Australia page index
    {pageIndex: 1 as number}, // Overseas page index
    {pageIndex: 2 as number}, // On water page index
    {pageIndex: 3 as number}, // Submit page index
] as const;

export const StyledTBody = styled(TBody)`
    & tr {
        border-color: transparent;
    }
    & tr:last-child {
        border-color: var(--color-table-border);
        table.compact & td {
            padding-bottom: ${SPACE_BASE * 2}px;
        }
    }
    & tr:first-child {
        table.compact & td {
            padding-top: ${SPACE_BASE * 2}px;
        }
    }
`;

export const GroupInput = styled(Input)`
    height: 36px;
    box-sizing: content-box;
    padding: 0;
    &:disabled {
        background-color: transparent;
        color: var(--color-black-90);
    }
`;

export const GroupSelect = styled(Select)`
    &,
    & .react-select__control {
        height: 48px;
    }

    & .react-select__control {
        padding: 0 16px;
        min-width: 100%;
    }
` as typeof Select;

interface CellEditorProps {
    autofocus?: boolean;
    disabled: boolean;
    error?: string | null;
    forceError?: boolean;
    id: string;
    label: string;
    infoOnNegative?: boolean;
    onUpdate: (val: string) => any;
    setFocus: boolean;
    value: number | undefined;
}

export const CellEditor = (props: CellEditorProps) => {
    const info = props.infoOnNegative && asNumber(props.value) < 0 ? INFO_NEGATIVE_VALUE_COMMENTS : null;
    return (
        <TD $align='right' error={Boolean(props.error)} notFocusable={true} padding='0'>
                <Input
                    align='right'
                    autoFocus={props.autofocus}
                    bare={true}
                    borderless={true}
                    disabled={props.disabled}
                    error={props.disabled ? undefined : props.error}
                    forceError={props.forceError}
                    formatter={val => localeNumberWithFixed(val as number)}
                    $height='42px'
                    id={props.id}
                    info={info}
                    label={props.label}
                    onChange={e => props.onUpdate(e.target.value)}
                    setFocused={props.setFocus}
                    shadow='inset'
                    type='number'
                    unstyled={true}
                    useTooltip={true}
                    value={props.value}
                    width='100%'
                />
        </TD>
    );
}

interface TotalCellProps {
    info?: string;
    rowSpan?: number;
    value: string;
}

export const TotalCell = (props: TotalCellProps) => {
    return (
        <TD $align='right' notFocusable={true} rowSpan={props.rowSpan} padding='0'>
            <BoxedDiv box={{alignItems: 'center', flex: 'row', justifyContent: 'flex-end'}}>
                <Text weight="bold">{props.value}</Text>
                <InfoIcon info={props.info} />
            </BoxedDiv>
        </TD>
    )
}

interface MessageTargetButtonProps {
    onClick: () => any;
    productName: string;
}

const TargetButton = ({onClick, productName}: MessageTargetButtonProps) => (
    <Button
        $kind='text'
        aria-label={`Go to field: ${productName}`}
        onClick={onClick}
    >{productName}</Button>
);

const ClearRowButton = ({onClick, productName}: MessageTargetButtonProps) => (
    <Button
        $kind='text'
        aria-label={`Remove row for: ${productName}`}
        onClick={onClick}
    >the row</Button>
);

export type StockholdingLocationValidationCode = 'INACTIVE_INTERNAL_PRODUCT' | 'COUNTRY_CONFLICT' | 'COUNTRY_REQUIRED' | 'QUANTITY_REQUIRED' | 'QUANTITY_INVALID' | 'INVALID_COUNTRY_ISINTERNAL_SELECTED';

const CountryConflictErrorMessage = (props: MessageTargetButtonProps) => (
    <Text>
        {'The country you selected in '}
        <TargetButton {...props} />
        {' already has a value for this product. Please select a different location for that record.'}
    </Text>
);

const CountryRequiredErrorMessage = (props: MessageTargetButtonProps) => (
    <Text>
        {'You have entered a quantity for '}
        <TargetButton {...props} />
        {' without a location. Please select a location for this record.'}
    </Text>
);

const QuantityRequiredErrorMessage = (props: MessageTargetButtonProps) => (
    <Text>
        {'You have selected a location for '}
        <TargetButton {...props} />
        {' without a quantity. Please enter a quantity for this record.'}
    </Text>
);

const InvalidCountryIsInternalErrorMessage = (props: MessageTargetButtonProps) => (
    <Text>
        {'The country you selected in '}
        <TargetButton {...props} />
        {' column is inactive.  Please edit or remove this record.'}
    </Text>
);

const InvalidOrInternalProductErrorMessage = (props: LocationErrorMessageProps) => {
    const {validationCode, clearRow, ...rest} = props;
    return (
        <Text>
            {'You have provided data for '}
            <TargetButton {...rest} />
            {' which is inactive. Please enter these details into an active product if applicable. Delete '}
            { clearRow ? <ClearRowButton onClick={clearRow} productName={rest.productName}/> : 'the row' }
            {' to continue.'}
        </Text>
    );
};
interface LocationErrorMessageProps {
    onClick: () => any;
    productName: string;
    validationCode: StockholdingLocationValidationCode;
    clearRow?: () => any
}

export const LocationErrorMessage = ({validationCode, ...targetButtonProps}: LocationErrorMessageProps) => {
    if (validationCode === 'COUNTRY_CONFLICT') {
        return <CountryConflictErrorMessage {...targetButtonProps} />
    }

    if (validationCode === 'INVALID_COUNTRY_ISINTERNAL_SELECTED') {
        return <InvalidCountryIsInternalErrorMessage {...targetButtonProps} />
    }

    if (validationCode === 'COUNTRY_REQUIRED') {
        return <CountryRequiredErrorMessage {...targetButtonProps} />
    }

    if (validationCode === 'QUANTITY_REQUIRED') {
        return <QuantityRequiredErrorMessage {...targetButtonProps} />
    }

    if (validationCode === 'INACTIVE_INTERNAL_PRODUCT') {
        const props = {
            validationCode,
            ...targetButtonProps
        }
        return <InvalidOrInternalProductErrorMessage {...props} />
    }

    return <QuantityInvalidErrorMessage onClick={targetButtonProps.onClick} label={targetButtonProps.productName} />
}

// Validation helpers
export function existsAndIsNegativeNumber(val: number | undefined | null) {
    if (val == null) {
        return false;
    }

    return val < 0;
}

type ResponseTypeWithResult = 'saved_full' | 'saved_partial' | 'successful';

export type StockholdingUpdateSubmissionResult = {
    errorMessages: Array<string>;
    recordResults: Array<RecordResult>;
    validationMessages: Array<ValidationMessage>;
} & ({
    responseStatus: ResponseTypeWithResult;
    stockholdingSubmission: StockholdingSubmission;
} | {
    responseStatus: Exclude<ResponseStatus, ResponseTypeWithResult>;
    stockholdingSubmission: undefined;
});

export function useHelpForGroup (portalDataAPICtrl: UsePortalDataAPI, productGroup: WithIsExpired<StockProductGroup>, typeName: StockTypeName) {
    const helpForGroup = useMemo(() => {
        return (portalDataAPICtrl.portalData?.referenceTypeData || [])
        .filter(rtd => rtd.referenceTypeName === typeName)
        .map(rtd => rtd.productGroupData)
        .flat()
        .find(h => h?.referenceCode === productGroup.referenceCode)?.content;
    }, [portalDataAPICtrl.portalData, productGroup.referenceCode, typeName]);
    
    return helpForGroup;
}

export function useHelpForProduct (portalDataAPICtrl: UsePortalDataAPI, typeName: StockTypeName, referenceCode?: string | null) {
    const helpForGroup = useMemo(() => {
        return (portalDataAPICtrl.portalData?.referenceTypeData || [])
        .filter(rtd => rtd.referenceTypeName === typeName)
        .map(rtd => rtd.productData)
        .flat()
        .find(h => referenceCode != null && h?.referenceCode === referenceCode)?.content;
    }, [portalDataAPICtrl.portalData, referenceCode, typeName]);
    
    return helpForGroup;
}
