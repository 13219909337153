import React, { FocusEventHandler, forwardRef, MouseEventHandler, useLayoutEffect, useState } from 'react';

import QuestionCircle from './icons/question-circle';
import VisuallyHidden from './visually-hidden';
import styled from 'styled-components';
import { useFloating, autoUpdate, offset, arrow, shift } from '@floating-ui/react-dom';
import { FloatingPortal } from '@floating-ui/react-dom-interactions';
import randomID from 'psims/lib/random-id';
import { BoxedSpan } from './layout';

interface TooltipProps {
    disableAriaDescribedBy?: boolean; // Allow delegation to semantic structure
    Help: string | JSX.Element;
    helpId?: string;
}

export const Tooltip = (props: TooltipProps) => {
    const vm = useTooltipVM(props);
    const [arrowId] = useState(randomID());

    return <BoxedSpan box={{alignItems: 'center', flex: 'row', position: 'relative'}}>
        <HelpIconTooltipTrigger
            disableAriaDescribedBy={props.disableAriaDescribedBy}
            Help={vm.Help}
            onBlur={() => vm.setIsShown(false)}
            onClick={() => vm.setIsShown(prev => !prev)}
            onFocus={() => vm.setIsShown(true)}
            onMouseEnter={() => vm.setIsShown(true)}
            onMouseLeave={() => vm.setIsShown(false)}
            ref={vm.reference}
        />

        <FloatingPortal>
            {
                vm.isShown &&
                <TooltipContent
                    id={vm.helpId}
                    ref={vm.floating}
                    strategy={vm.strategy}
                    x={vm.x}
                    y={vm.y}
                >
                    {vm.Help}
                    <Arrow
                        id={arrowId}
                        ref={vm.setArrowRef}
                        $x={vm.middlewareData.arrow?.x}
                        $y={vm.middlewareData.arrow?.y}
                    />
                </TooltipContent >
            }
        </FloatingPortal>
    </BoxedSpan>;
}

function useTooltipVM({Help, helpId}: TooltipProps) {
    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
    const {x, y, reference, floating, strategy, middlewareData, update} = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            shift(),
            offset(10),
            ...(arrowRef != null ? [arrow({element: arrowRef})] : [])
        ],
    });

    const [isShown, setIsShown] = useState(false);

    useLayoutEffect(() => {
        if (arrowRef != null) {
            update();
        }
    }, [arrowRef, update]);

    return {
        arrowRef,
        floating,
        Help,
        helpId,
        isShown,
        middlewareData,
        reference,
        setArrowRef,
        setIsShown,
        strategy,
        x,
        y,
    };
}

type UseFloating = ReturnType<typeof useFloating>;

type Strategy = UseFloating['strategy'];

interface TooltipContentProps {
    strategy: Strategy;
    x?: number | null;
    y?: number | null;
}

const TooltipContent = styled.div<TooltipContentProps>`${props => `
    background: var(--color-tooltip-background);
    color: white;
    border: none;
    border-radius: 4px;
    left: ${props.x ?? 0}px;
    max-width: 350px;
    padding: 1em 2em;
    position: ${props.strategy};
    top: ${props.y ?? 0}px;
    z-index: 1;
`}`;

const Trigger = styled.div`
    display: inline-block;
    margin-left: 4px;
    tab-index: 0;
`;

interface HelpIconTooltipTriggerProps {
    onBlur: FocusEventHandler;
    onClick: MouseEventHandler;
    onFocus: FocusEventHandler;
    onMouseEnter: MouseEventHandler;
    onMouseLeave: MouseEventHandler;
    disableAriaDescribedBy?: boolean;
    Help: string | JSX.Element;
    title?: string;
}

const HelpIconTooltipTrigger = forwardRef<HTMLDivElement, HelpIconTooltipTriggerProps> (({disableAriaDescribedBy, Help, ...rest}, ref) => {
    const [helpId] = useState(disableAriaDescribedBy ? undefined : randomID());
    return (
        <Trigger aria-describedby={helpId} {...rest} ref={ref} role='tooltip' tabIndex={0}>
            <VisuallyHidden id={helpId}>{Help}</VisuallyHidden>
            <QuestionCircle color='primary' size='sm' />
        </Trigger>
    )
});

interface ArrowProps {
    $x?: number | null;
    $y?: number | null;
}

const Arrow = styled.div`${({$x, $y}: ArrowProps) => `
    background: var(--color-tooltip-background);
    height: 16px;
    position: absolute;
    width: 16px;
    ${$x != null ? `
    left: ${$x}px;
    ` : ''}
    top: ${($y ?? 0) - 4}px;
    transform: rotate(45deg);
    z-index: 1;
`}`;
