import React from 'react';

import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';

import { UsePagination } from './use-pagination';
import styled from 'styled-components';
import Text from 'psims/react/components/text';
import ChevronDoubleLeft from 'psims/react/components/icons/chevron-double-left';
import ChevronDoubleRight from 'psims/react/components/icons/chevron-double-right';
import ChevronLeft from 'psims/react/components/icons/chevron-left';
import ChevronRight from 'psims/react/components/icons/chevron-right';
import Select, { useSelectController } from 'psims/react/components/select';

interface PaginationProps {
    paginationCtrl: UsePagination;
}

const Pagination = ({paginationCtrl}: PaginationProps) => {
    const selectCtrl = useSelectController({
        onChange: size => size != null && paginationCtrl.handlePageSizeChange(size),
        value: paginationCtrl.pageSize,
        options: paginationCtrl.pageSizeOptions,
    });

    return (
        <BoxedDiv box={{flex: 'column'}}>
            {/* Paging buttons */}
            {
                paginationCtrl.pageCount > 1 &&
                <nav role='navigation' aria-label={`${paginationCtrl.resultsLabel} pagination`}>
                    <BoxedDiv box={{flex: 'row', justifyContent: 'center'}}>
                        <BoxedDiv box={{flex: 'row', flexGrow: 1, justifyContent: 'flex-end'}}>
                            {
                                paginationCtrl.firstPage &&
                                <PageButton
                                    aria-label='Go to first page'
                                    isActive={false}
                                    onClick={paginationCtrl.firstPage}
                                >
                                    <ChevronDoubleLeft size='xs'/>
                                    <BoxedSpan box={{marginLeft: 0.5}}>First</BoxedSpan>
                                </PageButton>
                            }

                            {
                                paginationCtrl.previousPage &&
                                <PageButton
                                    aria-label='Go to previous page'
                                    isActive={false}
                                    onClick={paginationCtrl.previousPage}
                                >
                                    <ChevronLeft size='xs'/>
                                    <BoxedSpan box={{marginLeft: 0.5}}>Previous</BoxedSpan>
                                </PageButton>
                            }
                        </BoxedDiv>

                        {
                            paginationCtrl.pageSelectors?.map((ps, i) => (
                                <PageButton
                                    key={i}
                                    aria-current={ps.isActive}
                                    aria-label={`Go to page ${ps.label}${ps.isActive ? ', current page' : ''}`}
                                    isActive={ps.isActive}
                                    onClick={ps.onClick}
                                >{ps.label}</PageButton>
                            ))
                        }

                        <BoxedDiv box={{flex: 'row', flexGrow: 1, justifyContent: 'flex-start'}}>
                            {
                                paginationCtrl.nextPage &&
                                <PageButton
                                    aria-label='Go to next page'
                                    isActive={false}
                                    onClick={paginationCtrl.nextPage}
                                >
                                    <BoxedSpan box={{marginRight: 0.5}}>Next</BoxedSpan>
                                    <ChevronRight size='xs'/>
                                </PageButton>
                            }

                            {
                                paginationCtrl.lastPage &&
                                <PageButton
                                    aria-label='Go to last page'
                                    isActive={false}
                                    onClick={paginationCtrl.lastPage}
                                >
                                    <BoxedSpan box={{marginRight: 0.5}}>Last</BoxedSpan>
                                    <ChevronDoubleRight size='xs'/>
                                </PageButton>
                            }
                        </BoxedDiv>
                    </BoxedDiv>
                </nav>
            }

            {/* Paging details */}
            {
                paginationCtrl.currentPage !== 0 &&

                <BoxedDiv box={{borderTop: {color: 'border-primary-faded', width: 1}, flex: 'row', justifyContent: 'space-between', marginTop: 1, paddingTop: 1, }}>
                    <BoxedSpan box={{marginRight: 8}}>
                        <Text $color='black-90'>Page {paginationCtrl.currentPage} of {paginationCtrl.pageCount}</Text>
                    </BoxedSpan>

                    <BoxedDiv box={{alignItems: 'baseline', flex: 'row', marginLeft: 8}}>
                        <Text $color='black-90'>Results per page </Text>
                        <BoxedSpan box={{marginLeft: 1}}>
                            <Select
                                bare={true}
                                id='pagesize'
                                inline={true}
                                label='Select page size'
                                minWidth='105px'
                                onChange={selectCtrl.onChange}
                                options={selectCtrl.options}
                                value={selectCtrl.value}
                            />
                        </BoxedSpan>
                    </BoxedDiv>
                </BoxedDiv>
            }
        </BoxedDiv>
    );
}

interface PageButtonProps {
    isActive: boolean;
}

const PageButton = styled.button`${(props: PageButtonProps) => `
    align-items: center;
    border: none;
    border-radius: 2px;
    background: ${props.isActive ? 'var(--color-blue-50)' : 'transparent'};
    color: ${props.isActive ? 'var(--color-black-90)' : 'var(--color-primary-interactive)'};
    cursor: pointer;
    display: inline-flex;
    margin: 0 6px;
    padding: 6px 10px;

    &:focus {
        background: var(--color-primary);
        color: white;
    }

    &:hover {
        background: var(--color-primary-interactive);
        color: white;
    }
`}`;

export default Pagination;
