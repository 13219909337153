import { isArrayOfType } from "psims/lib/collections";
import { isRecordResult, RecordResult } from "psims/models/api/record-result";
import { isPopulatedDataSubmission, isSubmittedDataSubmission, PopulatedDataSubmission } from "psims/models/data-submission"
import { DefActivity, isDefActivity } from "./def-activity";
import { DefMarketExpectations } from "./def-market-expectations";
import { DefPageData, isDefPageData } from "./def-page-data";

export type DefDataSubmissionName = 'Diesel exhaust fluid';

export type DefSubmission = {
  dataSubmission: PopulatedDataSubmission<DefDataSubmissionName>;
  marketExpectations: DefMarketExpectations | undefined;
  activities: Array<DefActivity>;
  pageData: DefPageData
}

export function isDefSubmission(maybe?: unknown): maybe is DefSubmission {
  const maybeAs = maybe as DefSubmission;

  return (
    maybeAs != null &&
    isPopulatedDataSubmission(maybeAs.dataSubmission) &&
    isArrayOfType(isDefActivity, maybeAs.activities) &&
    isDefPageData(maybeAs.pageData)
  );
}

export function isDefSubmitted(maybe?: unknown): maybe is DefSubmission & {dataSubmission: {status: 'Submitted'}} {
    return isDefSubmission(maybe) && isSubmittedDataSubmission(maybe.dataSubmission);
}

export function getRecordResults(maybe: unknown): Array<RecordResult> {
    if (!isDefSubmission(maybe)) {
        return [];
    }

    return [
        ...(maybe.dataSubmission.recordResult != null ? [maybe.dataSubmission.recordResult] : []).filter(isRecordResult),
        ...(maybe.activities || []).map(a => a.recordResult).filter(isRecordResult),
        ...(maybe.marketExpectations != null ? [maybe.marketExpectations.recordResult] : []).filter(isRecordResult),
    ];
}
