import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { ValidationResult } from "./importer-imports-validation";

export function getInlineMessage(validationResult: ValidationResult | null) {
  if (validationResult == null) {
    return undefined;
  }

  switch (validationResult.code) {
    case 'invalid_integer':
    case 'invalid_integer_range':
      return `Must be ${validationResult.min === 0 ? 'a positive' : 'an'} integer (zero is permitted) with a maximum value of ${validationResult.min < 0 ? '+/-' : ''}${localeNumberWithFixed(validationResult.max)}`;

    case 'invalid_required':
      return 'A value is required for this field';

    case 'character_limit':
      return `Must be ${localeNumberWithFixed(validationResult.limit)} characters or fewer`;
  }
}