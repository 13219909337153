import React, { useEffect, useMemo } from 'react';

import Section from 'psims/react/components/section';
import Text from 'psims/react/components/text';
import Button from 'psims/react/components/button';
import ExclamationTriangle from 'psims/react/components/icons/exclamation-triangle';
import { useNavigation } from 'psims/react/providers/router';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import CompanyHeader from 'psims/react/blocks/company-header';
import { Table, TBody, TD, TH, THead, TR } from 'psims/react/components/table';
import { H1 } from 'psims/react/components/typography';
import { useDashboard } from 'psims/react/providers/api/dashboard';
import { getMonthNameFull, humanDate, MonthNumber } from 'psims/lib/formatters/datetime';
import Loading from 'psims/react/components/loading';
import { DataSubmissionTypeName, isPopulatedDataSubmission, PopulatedDataSubmission, ReportFrequencyTypeName } from 'psims/models/data-submission';
import { getUserSubmissionTypeNameForDashboard } from 'psims/constants/data-submissions';
import { asNumber } from 'psims/lib/number';

interface HomeProps {};

const Home = (props: HomeProps) => {
	const vm = useVM(props);
	
	return (
		<BoxedDiv box={{alignSelf: 'stretch', flex: 'column', flexGrow: 1}} >
			<CompanyHeader />

			<BoxedDiv className='container' box={{alignSelf: 'stretch', flex: 'column', flexGrow: 1}} >
				<H1 marginBottom={1} marginTop={4}>Data submissions</H1>

				<BoxedDiv box={{flex: 'row', alignSelf: 'stretch'}}>
					<BoxedDiv box={{flex: 'column', flexGrow: 1}}>
						{
							vm.status === 'loading' ?

							<BoxedDiv box={{alignItems: 'center', flex: 'column', flexGrow: 1, marginV: 2}}>
								<Loading>
									<Text>Loading home page data</Text>
								</Loading>
							</BoxedDiv> :
							vm.reports
							.map((monthlyReport, index) => (
								<Section key={index} headerStyle='h2' title={`${getMonthNameFull((monthlyReport.month as number) as MonthNumber)} ${monthlyReport.year}`}>
									<BoxedDiv box={{marginTop: 2, flex: 'column', alignSelf: 'stretch', overflowX: 'auto'}}>
										<MonthlyReportsTable 
											reports={monthlyReport.reports}
										/>
									</BoxedDiv>
								</Section>

							))
						}
					</BoxedDiv>

				</BoxedDiv>
			</BoxedDiv>
		</BoxedDiv>
	);
};


function useVM(props: HomeProps) {
	const {dashboard, refetch, status} = useDashboard();

	const reportsByMonth = useMemo(() => {
		return (dashboard?.dataSubmissions || [])
		 	.filter(ds => isPopulatedDataSubmission(ds))
			.map(d => {
				const dataSubmission: DataSubmission = {reportFrequencyTypeName: d.reportFrequencyTypeName! as ReportFrequencyTypeName, reportingYear: d.reportingYear!, reportingMonth: d.reportingMonth!, dueDate: d.dueDate!, obligationDate: d.obligationDate!};
				const year = getSubmissionYear(dataSubmission);
				const month = getSubmissionMonth(dataSubmission);
				return {
					data: d as PopulatedDataSubmission<DataSubmissionTypeName>,
					month,
					year,
					key: `${year}/${month}`
				};
			})
			.reduce((map, withMY) => {
				return {
					...map,
					[withMY.key]: [...(map[withMY.key] || []), withMY]
				}
			}, {} as {[key: string] : Array<{data: PopulatedDataSubmission<DataSubmissionTypeName>, year: number, month: number, key: string}>});

	}, [dashboard]);

	const reports = useMemo(() => {
		// We want to always show the current month plus preceeding 2 months on the homepage,
		// regardless of whether there are any data submissions in those months.
		const monthIndexes = [-1, 0, 1, 2];
		const baseDate = new Date();
		baseDate.setDate(1);
		return monthIndexes
		 	.map(i => {
				const date = new Date(baseDate);
				date.setMonth(baseDate.getMonth() - i, 1);
				const month = date.getMonth();
				const year = date.getFullYear();
				const key = `${year}/${month}`;
				
				const _reports = reportsByMonth[key] ? 
									reportsByMonth[key].map(({data}) => data)
										: [];

				return {
					month,
					year,
					reports: _reports,
				};
			})
			//Do not show future month if there is no DS
			.filter(m => {
				const now = new Date();
				return m.reports.length > 0 || (new Date(`${m.year}-${m.month+1}-${1}`).getTime() <= now.getTime())
			   })
			.sort((a, b) => a.year !== b.year ? b.year - a.year : b.month - a.month)
	}, [reportsByMonth]);

	// Refetch dashboard on entering page
	useEffect(() => {
		refetch();
	}, [refetch]);

	return {
		dashboard,
		reports,
		status,
	};
}


export default Home;

export type ReportName = DataSubmissionTypeName;

interface MonthlyReportsTableProps {
	reports: Array<PopulatedDataSubmission<DataSubmissionTypeName>>;
}

const MonthlyReportsTable = ({reports}: MonthlyReportsTableProps) => {
	const nav = useNavigation();
	
	const filteredAndSortedReports: Array<PopulatedDataSubmission<DataSubmissionTypeName>> = reports
	 	.filter(r => isPopulatedDataSubmission(r) )
		.sort((a, b) => (
			a.submissionTypeName!.localeCompare(b.submissionTypeName!) ||
			a.reportingOrganisationName!.localeCompare(b.reportingOrganisationName!) ||
			b.dueDate!.localeCompare(a.dueDate!)
		))

	if (filteredAndSortedReports.length === 0) {
		return (
			<BoxedDiv box={{}}>
				<Text variant='muted'>There are no reports currently due for this reporting period</Text>
			</BoxedDiv>
		);
	}

	return (
		<Table caption='Latest data submissions for your organisation(s)'>
			<THead>
				<TR>
					<TH >Report</TH>
					<TH >Due</TH>
					<TH>Reporting entity</TH>
					<TH>Frequency</TH>
					<TH minWidth='120px'>Status</TH>
					<TH minWidth='170px'>Submitted</TH>
					<TH minWidth='120px'>Case ID</TH>
					<TH $align='center' $width='100px'>Actions</TH>
				</TR>
			</THead>

			<TBody>
				{filteredAndSortedReports.map((report, index) => {
					const actionMode = (report.status === 'Not started' ||
									   report.status === 'Draft' ||
									   report.status === 'Action required') ? 'edit' : 'view'
					return (
						<TR key={index}>
							<TD>{getUserSubmissionTypeNameForDashboard(report)}</TD>
							<TD $width='200px'>
								{
									// This status check can be removed once the XAPI correctly omits dueStatus
									// for submitted reports
									(actionMode === 'edit') &&
									<BoxedSpan box={{alignItems: 'flex-start', flex: 'column'}}>
										<Text $noWrap={true}>{humanDate(new Date(report.dueDate as string))}</Text>

										{
											report.dueStatus === 'Overdue' &&
											<Text $color='red-100' $noWrap={true} $size={12} weight='semibold'>
												<BoxedSpan box={{alignItems: 'center', flex: 'row', marginTop: 1}}>
													<ExclamationTriangle size='sm'/>&nbsp;Overdue!
												</BoxedSpan>
											</Text>
										}

										{
											report.dueStatus === 'Due soon' &&
											<Text $color='blue-70' $noWrap={true} $size={12} weight='semibold'>
												<BoxedSpan box={{alignItems: 'center', flex: 'row', marginTop:1}}>
													<ExclamationTriangle size='sm'/>&nbsp;Due soon!
												</BoxedSpan>
											</Text>
										}

									</BoxedSpan>
								}
							</TD>

							<TD>{report.reportingOrganisationName}</TD>

							<TD>{report.reportFrequencyTypeName}</TD>

							<TD>
								<BoxedSpan box={{alignItems: 'flex-start', flex: 'column'}}>
									<Text $noWrap={true}>
										{report.status}
									</Text>
									{
										report.msoOutcome === 'Not Met' &&
										<Text $color='blue-70' $noWrap={true} $size={12} weight='semibold'>
											<BoxedDiv box={{alignItems: 'center', flex: 'row', marginTop: 1}}>
												<ExclamationTriangle size='sm' />&nbsp;MSO not met
											</BoxedDiv>
										</Text>
									}
								</BoxedSpan>
							</TD>

							<TD>
								<Text $noWrap={true}>{
									report.submittedAt != null && humanDate(new Date(report.submittedAt))
								}</Text>
							</TD>

							<TD>{report.caseId}</TD>

							<TD $align='center'>
								{
									(actionMode === 'edit') &&
									<Button
										$kind={report.status === 'Not started' ? 'primary' : 'text'}
										$minWidth='120px'
										onClick={() => nav.goToReport(report)}
										$size='sm'
									>
										{report.status === 'Not started' ? 'Begin report' : 'Resume'}
									</Button>
								}

								{
									(actionMode === 'view') &&
										<Button
											$kind='text'
											aria-label='View report'
											$minWidth='120px'
											onClick={() => nav.goToReport(report)}
											$size='sm'
										>View</Button>
								}
							</TD>
						</TR>
					);
				})}
			</TBody>
		</Table>
	)

}

interface DataSubmission {
	reportFrequencyTypeName: ReportFrequencyTypeName;
	dueDate?: string;
	obligationDate?: string;
	reportingMonth?: number;
	reportingYear: number;
  }
  
function getSubmissionMonth(dataSubmission: DataSubmission): number
{
	if(dataSubmission.reportFrequencyTypeName === 'Annually' || dataSubmission.reportFrequencyTypeName === 'Quarterly'){
		return new Date(dataSubmission.dueDate!).getMonth();
	}
	else if(dataSubmission.obligationDate){
		return new Date(dataSubmission.obligationDate).getMonth();
	}

	return asNumber(dataSubmission.reportingMonth) - 1;
}

function getSubmissionYear(dataSubmission: DataSubmission): number
{
if(dataSubmission.reportFrequencyTypeName === 'Annually' || dataSubmission.reportFrequencyTypeName === 'Quarterly'){
		return new Date(dataSubmission.dueDate!).getFullYear();
	}
	else if(dataSubmission.obligationDate){
		return new Date(dataSubmission.obligationDate).getFullYear();
	}

	return asNumber(dataSubmission.reportingYear);
}