import Text from "psims/react/components/text";
import Expandable from "psims/react/components/expandable";
import { BoxedDiv } from "psims/react/components/layout";
import HelpBlock from "psims/react/components/help";

const CONTENT_MAP = {
    Retailers: {
        title: 'Wholesales to retailers',
        Help: () => {
            return (<>
                <p>
                    <Text>Please report all wholesales made to your own-branded service stations, franchised service stations, and other companies’ service stations, including independents.</Text>
                </p>
                <p>
                    <Text><strong>Report in kilolitres</strong>. Volumetric information should be adjusted to standard temperature and pressure conditions (15°C &amp; 1 atmosphere pressure (101.325kPa)).</Text>
                </p>

                <p>
                    <Text><strong>Only report the volumes entered into home consumption</strong> (when excise became payable) or, for non-excisable products, the equivalent action (removing from an import terminal or domestic refinery)</Text>	
                </p>
            </>);
        }
    },

    Wholesalers: {
        title: 'Wholesales to other wholesalers',
        Help: () => {
            return (<>
                <p>
                    <Text>Please report all wholesales to other wholesalers, resellers and distributers.</Text>
                </p>
                <p>
                    <Text><strong>Report in kilolitres</strong>. Volumetric information should be adjusted to standard temperature and pressure conditions (15°C &amp; 1 atmosphere pressure (101.325kPa)).</Text>
                </p>

                <p>
                    <Text><strong>Only report the volumes entered into home consumption</strong> (when excise became payable) or, for non-excisable products, the equivalent action (removing from an import terminal or domestic refinery)</Text>	
                </p>
            </>);
        }
    },

    Bulk: {
        title: 'Bulk wholesales',
        Help: () => {
            return (<>
                <p>
                    <Text>Please report all bulk sales to large users for example, mining sites, agriculture, and public transport.</Text>
                </p>
                <p>
                    <Text><strong>Report in kilolitres</strong>. Volumetric information should be adjusted to standard temperature and pressure conditions (15°C &amp; 1 atmosphere pressure (101.325kPa)).</Text>
                </p>

                <p>
                    <Text><strong>Only report the volumes entered into home consumption</strong> (when excise became payable) or, for non-excisable products, the equivalent action (removing from an import terminal or domestic refinery)</Text>	
                </p>
            </>);
        }
    },

    'Other wholesales': {
        title: 'Other wholesales',
        Help: () => {
            return (<>
                <p>
                    <Text><strong>Report in kilolitres</strong>. Volumetric information should be adjusted to standard temperature and pressure conditions (15°C &amp; 1 atmosphere pressure (101.325kPa)).</Text>	
                </p>

                <p>
                    <Text><strong>Only report the volumes entered into home consumption</strong> (when excise became payable) or, for non-excisable products, the equivalent action (removing from an import terminal or domestic refinery)</Text>	
                </p>
            </>);
        }
    },

    Total: {
        title: 'Total wholesales',
        Help: () => {
            return (<>
                <p>
                    <Text>Please report all wholesales to all customer types.</Text>
                </p>
                <p>
                    <Text><strong>Report in kilolitres</strong>. Volumetric information should be adjusted to standard temperature and pressure conditions (15°C &amp; 1 atmosphere pressure (101.325kPa)).</Text>
                </p>

                <p>
                    <Text><strong>Only report the volumes entered into home consumption</strong> (when excise became payable) or, for non-excisable products, the equivalent action (removing from an import terminal or domestic refinery)</Text>	
                </p>
                
                <BoxedDiv box={{marginV: 2}}>
                    <Expandable
                        collapsedLabel='Show more information on what wholesales you should be reporting'
                        expandedLabel='Hide more information on what wholesales you should be reporting'
                        contentPlacement='below'
                    >
                        <HelpBlock box={{flex: 'column', marginBottom: 0.5, marginTop: 1}}>
                            <p>The POFR Act defines wholesaling as either:<br/>
                            (a) entering a covered product for home consumption (within the meaning of the Customs Act 1901 or the Excise Act 1901); or<br/>
                            (b) if a covered product is not subject to duty of excise or duty of customs—removing the covered product from an import terminal or domestic production facilities (such as a refinery)</p>
                            <p>For covered products <strong>subject to excise or customs duty,</strong> the wholesale reporting obligation applies to the business that is authorised under either customs or excise legislation to enter covered products (subject to duty) into home consumption.</p>
                            <p>A wholesale typically occurs when the covered product is physically delivered from a place licensed by the Commonwealth to a place that is not licensed by the Commonwealth. In most instances the entity reporting the wholesale will hold a Periodic Settlement Permission (PSP) for those covered products wholesaled.</p>
                            <p>Businesses are required to report all volumes that have been physically delivered from a licensed place to a non-licensed place during the reporting period, <strong>whether the PSP has been lodged or not.</strong> The intention here is to capture all reportable deliveries made in e.g. the calendar month rather than those that appear on the weekly PSP submissions.</p>
                            <p>For covered products <strong>not subject to excise or customs duty,</strong> the wholesale reporting obligation applies to the business that first removed the product from an import terminal or domestic production facility (e.g. a refinery).</p>
                            <p>Businesses are required to report the volume of each non-dutiable covered product that they removed from an import terminal or domestic refinery during the reporting period.</p>
                            <br/>
                            <p><strong>Differences between POFR Act requirements and customs or excise requirements</strong></p>
                            <p>There are key differences between the wholesale reporting obligations under the POFR Act and the reporting requirements under customs or excise legislation. For example under the POFR Act:</p>
                            <ul>
                                <li>A business must report wholesales that are not always dutiable, such as non-automotive LPG, bitumen, bunker fuel for use on overseas journeys, fuel exclusively for military use, etc.</li>
                                <li>A business must report on disaggregated product types for example gasoline needs to be reported by grade (ULP, 95RON, 98RON, E10).</li>
                                <li>State splits are required rather than an aggregated national total.</li>
                            </ul>
                            <p>Reports are usually required for each calendar month (for some products, the reporting period is half-yearly or yearly), rather than the duration specified in a business's PSP (usually weekly) and should only include products entered for home consumption during that month.</p>
                            <p><strong>Petroleum-based greases, lubricants, oils, waxes and solvents (GLOWS) and petroleum coke</strong></p>
                            <p>The POFR Rules provide a reduced reporting frequency for petroleum-based greases, lubricants, lubricating oil base stock, paraffin waxes and solvents (GLOWS) and petroleum coke.</p>
                            <p>The minimum reporting requirement for GLOWS is two reports per year. The first report must cover wholesales between 1 January and 30 June and is due by 31 July, and the second report must cover wholesales between 1 July and 31 December and is due by 31 January. GLOWS wholesalers should report using the wholesales template, noting the last reportable month as the reportable month in the template.</p>
                            <p>Businesses that wholesale other reportable products as well as GLOWS are encouraged to report GLOWS wholesales monthly alongside their other products.</p>
                            <p>Wholesales of petroleum coke need only to be reported annually. The minimum reporting requirement is to submit a report on wholesales over the preceding financial year by 31 October.</p>
                        </HelpBlock>
                    </Expandable>
                </BoxedDiv>   
                
                <p>
                    <Text>The below has been calculated based on the data you have entered on previous sheets</Text>	
                </p>

                <p>
                    <Text>Do you wish to manually enter your totals, doing so will stop the recalculation of the totals on this page</Text>	
                </p>
                
                <BoxedDiv box={{marginTop: 2}}>
                    <Expandable
                        collapsedLabel='Show more information on the way you can report your totals'
                        expandedLabel='Hide more information on the way you can report your totals'
                        contentPlacement='below'
                    >
                        <HelpBlock box={{flex: 'column', marginBottom: 0.5, marginTop: 1}}>
                            <p>Two ways to report.</p>
                            <ol>
                                <li>Data for total wholesales can be entered in on this page manually; Or</li>
                                <li>Data can be split according to customer type and entered into sections on the earlier pages in this form (Wholesales to retailers, Wholesales to other wholesalers, Bulk wholesales, Other wholesales) which will aggregate to autocomplete this page.                            
                                    <p>The data in these earlier pages is very useful to many users of the Australian Petroleum Statistics (APS) and Australian Energy Statistics.</p>
                                    <p>Reporting using option 2 is not mandatory but the data will enable the department to continue to publish the detail currently available in the Australia Petroleum Statistics.</p>
                                </li>
                            </ol>
                        </HelpBlock>
                    </Expandable>
                </BoxedDiv>   
            </>);
        }
    },

    Submit: {
        title: 'Declaration',
        Help: () => {
            return (null);
        }
    },
}

export default CONTENT_MAP;
