import { recordActionAsEnum } from "psims/models/api/data-submission-record-action";
import { isRecordResult } from "psims/models/api/record-result";
import { isMso, Mso, UpdateMso } from "psims/models/submission-types/mso/mso";
import { MsoImporterSubmission, UpdateMsoImporterSubmission } from "psims/models/submission-types/mso/mso-importer-submission";
import { isMsoRefiner, MsoRefinerSubmission, UpdateMsoRefinerSubmission } from "psims/models/submission-types/mso/mso-refiner-submission";
import { isMsoStockOnBehalf, MsoStockOnBehalf, UpdateMsoStockOnBehalf } from "psims/models/submission-types/mso/mso-stock-on-behalf";
import { isMsoStockRefineryHeld, MsoStockRefineryHeld, UpdateMsoStockRefineryHeld } from "psims/models/submission-types/mso/mso-stock-refinery";
import { isMsoStockholding, MsoStockholding, UpdateMsoStockholding } from "psims/models/submission-types/mso/mso-stockholding";
import { MsoStockholdingOwnership, UpdateMsoStockholdingOwnership } from "psims/models/submission-types/mso/mso-stockholding-ownership";
import { isOrganisationMsoSetting, OrganisationMsoSetting } from "psims/models/submission-types/mso/organisation-mso-setting";
import { UseMsoRefData } from "./use-mso-ref-data";

// Common MSO submission properties - for now use MSO importer as base as its shape is common
// to all currently known MSO submission types
type MsoSubmission = MsoImporterSubmission | MsoRefinerSubmission;

type UpdateMsoSubmission = UpdateMsoImporterSubmission | UpdateMsoRefinerSubmission;

type Submission<TKind extends {}> = TKind & MsoSubmission; 

function transformSubmissionToUpdateSubmission<TKind>(
    submission: Submission<TKind>,
    products: UseMsoRefData['products']
): UpdateMsoSubmission {
    const settings = submission.msoSettings.filter(isOrganisationMsoSetting);
    const dataSubmissionId = submission.dataSubmission.id;
    const {recordResult, ...commentsData} = (submission.msoComment || {});

    const update: UpdateMsoSubmission = {
        dataSubmissionId: dataSubmissionId,
        msoComment: commentsData,
        msos: transformMso(submission.msos.filter(isMso), dataSubmissionId, settings, products),
        stocksOnBehalf: submission.stocksOnBehalf
            .filter(isMsoStockOnBehalf)
            .filter(sob => sob.recordResult?.rowResult !== 'Deleted')
            .map(transformStockOnBehalf),
        stockholdings: transformStockholdings(
            submission.stockholdings
                .filter(isMsoStockholding)
                .filter(s => s.recordResult?.rowResult !== 'Deleted'),
            dataSubmissionId, products
        ),
    };

    if (isMsoRefiner(submission)) {
        (update as UpdateMsoRefinerSubmission).stockRefineriesHeld = transformRefineriesHeld(submission.stockRefineriesHeld.filter(isMsoStockRefineryHeld), dataSubmissionId, products);
    }
    
    return update;
}

export default transformSubmissionToUpdateSubmission;

// Helpers
function transformMso(msos: Array<Mso>, dataSubmissionId: number, settings: Array<OrganisationMsoSetting>, products: UseMsoRefData['products']): Array<UpdateMso> {
    return settings
        .filter(setting => {
            const product = products.find(p => p.id === setting.msoProductId);
            return product && product.productStatus === 'active';
        })
        .map(setting => {
        const existingMso = msos.find(mso => mso.msoOrganisationSettingId === setting.id);
        if (existingMso != null) {
            const {recordResult, validationAlerts, ...data} = existingMso;
            return { ...data, msoProductId: setting.msoProductId };
        }

        return {
            dataSubmissionId,
            minimumStockObligation: setting.minimumStockObligation,
            msoOrganisationSettingId: setting.id,
            msoProductId: setting.msoProductId,
        };
    })
}

function transformOwnership(ownership: MsoStockholdingOwnership): UpdateMsoStockholdingOwnership {
    const {recordResult, ...data} = ownership;
    return { ...data };
}

function transformStockholdings(
    stockholdings: Array<MsoStockholding>,
    dataSubmissionId: number,
    products: UseMsoRefData['products']
): Array<UpdateMsoStockholding> {
    return products
        .filter(product => {
            const existingStockholding = stockholdings.find(stockholding => stockholding.msoProductId === product.id);
            return existingStockholding != null || product.productStatus === 'active';
        })
        .map(product => {
            const existingStockholding = stockholdings.find(stockholding => stockholding.msoProductId === product.id);
            if (existingStockholding != null) {
                const {recordResult, ...data} = existingStockholding;
                return {
                    ...data,
                    stockholdingOwnerships: (existingStockholding.stockholdingOwnerships || [])
                        .filter(o => !isRecordResult(o.recordResult) || o.recordResult.rowResult !== 'Deleted')
                        .map(o => transformOwnership(o as MsoStockholdingOwnership))};
            }

            return {
                dataSubmissionId,
                msoProductId: product.id,
                stockholdingOwnerships: [],
                recordAction: recordActionAsEnum('Create'),
            };
        });
}

function transformStockOnBehalf(stockOnBehalf: MsoStockOnBehalf): UpdateMsoStockOnBehalf {
    const {recordResult, ...data} = stockOnBehalf;
    return { ...data };
}

function transformRefineriesHeld(
    refineriesHeld: Array<MsoStockRefineryHeld>,
    dataSubmissionId: number,
    products: UseMsoRefData['products']
): Array<UpdateMsoStockRefineryHeld> {
    return products
        .filter(product => {
            const existingRefineryHeld = refineriesHeld.find(r => r.msoProductId === product.id);
            return existingRefineryHeld != null || product.productStatus === 'active';
        })
        .map(product => {
            const existingRefineryHeld = refineriesHeld.find(r => r.msoProductId === product.id);
            if (existingRefineryHeld != null) {
                const {recordResult, ...data} = existingRefineryHeld;
                return { ...data };
            }

            return {
                dataSubmissionId,
                msoProductId: product.id,
                recordAction: recordActionAsEnum('Create'),
            };
        });
}
