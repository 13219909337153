import React, { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { TERMS_AND_CONDITIONS_CONTENT_KEY } from 'psims/constants/app';
import { BoxedDiv } from 'psims/react/components/layout';
import { useReferenceData } from 'psims/react/providers/api/reference-data';
import { asString } from 'psims/lib/string';
import Loading from 'psims/react/components/loading';
import ExternalLink from 'psims/react/components/external-link';
import { useLogger } from "psims/react/providers/logging";

const StyledTAndCs = styled.div`
    ol {
        counter-reset: item;
        list-style-position: outside;

        & > li {
            counter-increment: item;
            margin-bottom: 12px;

            &::marker {
                padding-right: 0.6em; 
                content: counters(item, ".") ". ";
            }
        }
    }

    ol:first-child {
        &,
        & > li {
            padding-left: 0;
        }

        & > li {
            margin-bottom: 36px;
            margin-left: 24px;

            & > *:not(:first-child) {
                margin-left: -24px;
            }

            & h3 {
                display: inline-block;
                margin-bottom: 24px;
            }
        
            &::marker {
                font-size: 21px;
                font-weight: 600;
            }

            // 2nd-level ordered list
            & > ol {
                padding-left: 0;

                & > li {
                    margin-left: 32px;

                    // 3rd-level ordered list
                    & > ol {
                        list-style: lower-alpha;

                        & > li {
                            &::marker {
                                content: initial;
                            }

                            // 4th-level ordered list
                            & > ol {
                                list-style: lower-roman;

                                & > li {
                                    &::marker {
                                        content: initial;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        & > li:last-child {
            margin-left: 36px;
        }

        // Defintions
        & ul {
            &,
            & > li {
                padding-left: 0;
                margin-bottom: 12px;
            }
            list-style: none;

            & ol {
                list-style: lower-alpha;
                padding-left: 4em;

                & > li {
                    margin-bottom: 12px;
                    &::marker {
                        content: initial;
                    }
                }
            }
        }
    }
`;

interface TermsAndConditionsProps {}

const TermsAndConditions = (props: TermsAndConditionsProps) => {
    const {warn} = useLogger({source: 'termsAndConditionsPage'});
    const vm = useVM(props);

    if (vm.loadError != null) {
        warn(`Failed to load terms and conditions data: ${vm.loadError}`)
		return <Redirect to='/' />;
    }

    return (
        <BoxedDiv box={{flex: 'column', flexGrow: 1}}>
            {
                vm.tAndCsMarkdown == null ?

                <BoxedDiv box={{alignItems: 'center', flexGrow: 1, flex: 'column', justifyContent: 'center'}}>
                    <Loading>Loading terms and conditions</Loading>
                </BoxedDiv> :

                <StyledTAndCs>
                    <ReactMarkdown
                        components={{
                            a: ({node, ...props}) => <ExternalLink {...props} />
                        }}
                    >
                        {asString(vm.tAndCsMarkdown)}
                    </ReactMarkdown>
                </StyledTAndCs>
            }
        </BoxedDiv>
    );
}

function useVM(props: TermsAndConditionsProps) {
    const termsAndConditionsContent = useTermsAndConditionsContent();

    return {
        ...termsAndConditionsContent,
    };
}

export function useTermsAndConditionsContent() {
    const {data: refData} = useReferenceData();
    const [loadError, setLoadError] = useState<string | null>(null);

    const content = useMemo(() => {
        return refData?.content;
    }, [refData]);

    const tAndCsMarkdown = useMemo(() => {
        if (content == null) {
            return undefined;
        }

        const tAndCsContent = content.find(c => c.contentKey === TERMS_AND_CONDITIONS_CONTENT_KEY);

        if (tAndCsContent == null || tAndCsContent.inlineContent == null) {
            setLoadError('Failed to load terms and conditions content')
            return undefined;
        }

        return tAndCsContent.inlineContent
    }, [content]);

    return {
        loadError,
        tAndCsMarkdown,
    };
}

export default TermsAndConditions;
