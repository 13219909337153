import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";

import { BoxedDiv, BoxedSpan } from "psims/react/components/layout";
import usePortalDataAPI, { UsePortalDataAPI } from "./use-portal-data-api";
import type { Selection } from './types';
import { asString } from "psims/lib/string";
import { TooltipHelp } from "psims/react/pages/primary-pages/data-submissions/shared/tooltip-help";
import Button from "psims/react/components/button";
import { isArrayOfType } from "psims/lib/collections";
import Expandable from "psims/react/components/expandable";
import HelpBlock from "psims/react/components/help";
import Textarea from "psims/react/components/textarea";
import Notification from "psims/react/components/notification";
import { humanDate } from "psims/lib/formatters/datetime";
import FormattingGuidance from "./formatting-guidance";
import SupportLink from "psims/react/components/support-link";
import Text from "psims/react/components/text";
import { encodeEscapeChars } from "psims/lib/validation/string";
import { MaybeUpdatePortalReferenceData } from "psims/models/portal-reference-data";
import { RecordActionEnum } from "psims/models/api/data-submission-record-action";
import { compare } from "psims/lib/compare";
import { validateHelpText } from "./validation";

interface PortalDataViewProps {
  onChangedFieldCount: (count: number) => any;
  selection: Selection;
}

type RowNotification = {
  referenceCode: string;
  kind: 'error' | 'success' | 'invalid';
  message: JSX.Element | string;
}

const PortalDataView = (props: PortalDataViewProps) => {
  const vm = usePortalDataViewVM(props);

  return (
    <BoxedDiv box={{alignItems: 'stretch', flex: 'column', flexGrow: 1, justifyContent: 'flex-start'}}>
      {
        vm.portalData && isArrayOfType(isRow, vm.portalData) && <>
          <BoxedDiv box={{marginBottom: 4}}>
            <FormattingGuidance />
          </BoxedDiv>

          <DataTable
            onCancelRowChange={vm.cancelRowChanges}
            onChangeRow={vm.changeRow}
            onRowContentBlur={vm.blurContent}
            onSaveRow={vm.saveRow}
            rows={vm.portalData || []}
            status={vm.status}
          />
        </>
      }

    </BoxedDiv>
  )
}

function usePortalDataViewVM({onChangedFieldCount, selection}: PortalDataViewProps) {
  const {submissionType} = selection;
  const [rowNotifications, setRowNotifications] = useState<Array<RowNotification>>([]);
  const changedRows = useRef<Set<string>>(new Set());
  const portalDataAPICtrl = usePortalDataAPI({submissionType});

  const portalData = useMemo(() => {
    if (portalDataAPICtrl.portalData == null) {
      return [];
    }

    return portalDataAPICtrl.portalData.referenceTypeData
      .sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))
      .map(x => {
        const data = x[selection.dataKey];

        const withParentDisplayOrder = (data || [])
          .map(d => {
            const parentDisplayOrder = selection.dataKey === 'productData' ?
              x.productGroupData?.find(pgd => d.referenceCode && pgd.referenceCode ? d.referenceCode.indexOf(pgd.referenceCode) === 0 : 0)?.displayOrder || 0 :
              x.displayOrder || 0;
            return {
              ...d,
              parentDisplayOrder,
            }
          });

        return withParentDisplayOrder
          .sort((a, b) => {
            if (a.parentDisplayOrder === b.parentDisplayOrder) {
              return (a.displayOrder || 0) - (b.displayOrder || 0);
            }
            return a.parentDisplayOrder - b.parentDisplayOrder;
          })
          .map(d => {
            const maybeRowNotification = rowNotifications.find(rn => rn.referenceCode === d.referenceCode);
            return {
              ...d,
              previewKind: selection.dataKey === 'productGroupData' && (
                selection.submissionTypeName === 'Biofuel production' ||
                selection.submissionTypeName === 'Field production' ||
                selection.submissionTypeName === 'Plant production'
              ) ? 'help-text' : 'tooltip',
              referenceTypeName: x.referenceTypeName as string,
              referenceTypeNameLabel: x.referenceTypeName === 'MSO refiner' ? 'MSO' : x.referenceTypeName as string,
              notification: maybeRowNotification ? {
                kind: maybeRowNotification.kind,
                message: maybeRowNotification.message,
              } : null,
            }
          })
      })
      .flat()
      .filter(isRow)
      .flat()
      .map<Row>(d => d as Row);
  }, [portalDataAPICtrl, rowNotifications, selection]);

  const clearRowNotification = useCallback((row: Row) => {
    setRowNotifications(prev => prev.filter(rn => rn.referenceCode !== row.referenceCode));
  }, []);

  const addChangedRow = useCallback((row: Row) => {
    changedRows.current.add(row.referenceCode);
    onChangedFieldCount(changedRows.current.size);
  }, [onChangedFieldCount]);

  const blurContent = useCallback((row: Row) => {
    if (row.notification?.kind === 'invalid') {
      return;
    }
    clearRowNotification(row);
  }, [clearRowNotification])

  const clearChangedRow = useCallback((row: Row) => {
    changedRows.current.delete(row.referenceCode)
    onChangedFieldCount(changedRows.current.size);
  }, [onChangedFieldCount]);

  const changeRow = useCallback((row: Row, isChanged: boolean) => {
    const nextRowNotifications = rowNotifications.filter(rn => rn.referenceCode !== row.referenceCode);
    const contentValidationMessage = validateHelpText(row.content);
    if (contentValidationMessage != null) {
      nextRowNotifications.push({
        kind: 'invalid',
        message: contentValidationMessage,
        referenceCode: row.referenceCode,
      })
    }
    setRowNotifications(nextRowNotifications);
    if (isChanged){
      addChangedRow(row);
    } else {
      clearChangedRow(row);
    }
  }, [addChangedRow, clearChangedRow, rowNotifications]);

  const cancelRowChanges = useCallback((row: Row) => {
    clearRowNotification(row);
    clearChangedRow(row);
  }, [clearChangedRow, clearRowNotification]);

  const saveRow = useCallback(async (row: Row) => {
    if (row.notification?.kind === 'invalid') {
      return;
    }

    clearRowNotification(row);

    try {
      await portalDataAPICtrl.update({
        requestBody: {
          ...buildUpdateRequest(row)
        },
      });
      portalDataAPICtrl.fetch();
      setRowNotifications(prev => [
        ...prev,
        {
        kind: 'success',
        message: 'Your changes have been saved.',
        referenceCode: row.referenceCode,
        },
      ]);
      clearChangedRow(row);
    } catch (e) {
      setRowNotifications(prev => [
        ...prev,
        {
          kind: 'error',
          message: <span>Your changes failed to save. Please try again or contact <SupportLink /> if the issue persists.</span>,
          referenceCode: row.referenceCode,
        }
      ]);
    }
  }, [clearChangedRow, clearRowNotification, portalDataAPICtrl]);

  // Clear changed field count on selection change
  useEffect(() => {
    changedRows.current = new Set();
    onChangedFieldCount(0);
  }, [onChangedFieldCount, selection]);

  return {
    blurContent,
    cancelRowChanges,
    changeRow,
    clearRowNotification,
    portalData,
    portalDataAPICtrl,
    saveRow,
    status: portalDataAPICtrl.portalDataView.status,
    submissionType,
  };
}

const buildUpdateRequest= (row: Row): MaybeUpdatePortalReferenceData => {
  const escapedContent: string | null | undefined = 
    row.content != null && row.content !== undefined ?
        encodeEscapeChars(row.content?.trim()) :
        row.content;

  return {
    concurrencyToken: row.concurrencyToken,
    content: escapedContent,
    recordAction: row.recordAction,
    id: row.id,
    referenceCode: row.referenceCode,
    referenceDataTypeId: row.referenceDataTypeId,
  }
}

interface Row {
  content?: string | null;
  displayOrder: number;
  effectiveFrom?: string;
  effectiveTo?: string;
  notification?: {
    kind: 'success' | 'error' | 'invalid';
    message: string;
  } | null;
  id: number;
  name: string;
  previewKind: 'tooltip' | 'help-text';
  productCode?: string;
  referenceCode: string;
  referenceDataTypeId: number;
  referenceTypeName: string;
  referenceTypeNameLabel: string;
  concurrencyToken?: string | null;
  recordAction?: RecordActionEnum;
}

function isRow(maybe?: unknown): maybe is Row {
  const maybeAs = maybe as Row;
  return (
    maybeAs != null &&
    maybeAs.name != null &&
    maybeAs.previewKind != null &&
    maybeAs.referenceCode != null &&
    maybeAs.referenceDataTypeId != null &&
    maybeAs.referenceTypeName != null
  );
}

interface DataTableProps {
  onCancelRowChange: (row: Row) => any;
  onChangeRow: (row: Row, isChanged: boolean) => any;
  onRowContentBlur: (row: Row) => any;
  onSaveRow: (row: Row) => any;
  rows: Array<Row>;
  status: UsePortalDataAPI['portalDataView']['status'];
}

const DataTable = ({onCancelRowChange, onChangeRow, onRowContentBlur, onSaveRow, rows, status}: DataTableProps) => {
  if (status === 'fetching') {
    return <BoxedDiv box={{alignItems: 'center', flex: 'row', flexGrow: 1, justifyContent: 'center'}}>
      <strong>Fetching portal data</strong>
    </BoxedDiv>
  }

  if (rows.length === 0) {
    return <BoxedDiv box={{alignItems: 'center', flex: 'row', flexGrow: 1, justifyContent: 'center'}}>
      <strong>No reference data found for selected type</strong>
    </BoxedDiv>
  }

  return (
    <BoxedDiv box={{marginBottom: 4}}>
      <table className="manage-ref-data full-width">
        <thead>
          <tr className="header">
            <th className="left no-wrap details-column">Reference data details</th>
            <th className="left no-wrap content-column">Help text</th>
            <th className="center no-wrap preview-column">Preview</th>
          </tr>
        </thead>

        <tbody>
          {
            rows.map(row => <TableRow
              key={row.referenceCode}
              onCancel={onCancelRowChange}
              onChange={onChangeRow}
              onContentBlur={onRowContentBlur}
              onSave={onSaveRow}
              row={row}
            />)
          }

        </tbody>
      </table>

    </BoxedDiv>
  )
}

interface TableRowProps {
  onCancel: (row: Row) => any;
  onChange: (row: Row, isChanged: boolean) => any;
  onContentBlur: (row: Row) => any;
  onSave: (row: Row) => any;
  row: Row;
}

const TableRow = ({onCancel, onChange, onContentBlur, onSave, row}: TableRowProps) => {
  const [content, setContent] = useState(row.content || '');
  const [isContentFocused, setIsContentFocused] = useState(false);

  const hasChanges = useMemo(() => content !== row.content, [content, row.content]);

  function handleContentBlur() {
    onContentBlur(row);
    setIsContentFocused(false);
  }

  function handleContentChange(val: string) {
    setContent(val);
    onChange({...row, content: val}, !compare(row.content, val, true));
  }

  function handleContentSave(row: Row) {
    onSave(row);
    setTimeout(() => setIsContentFocused(true), 0);
  }

  return (
    <tr className="body" >
      <td>
        <BoxedDiv box={{alignItems: 'flex-start', flex: 'column'}}>
          <LabeledValue label='Reference type'>
            {row.referenceTypeNameLabel}
          </LabeledValue>

          <LabeledValue label="Name">
            {row.name}
          </LabeledValue>

          {
            row.productCode &&
            <LabeledValue label="Product code">
              {row.productCode}
            </LabeledValue>
          }

          <LabeledValue label="Reference code">
            {row.referenceCode}
          </LabeledValue>

          {
            row.effectiveFrom &&
            <LabeledValue label="Effective from">
              {humanDate(row.effectiveFrom)}
            </LabeledValue>
          }

          {
            row.effectiveTo &&
            <LabeledValue label="Effective to">
              {humanDate(row.effectiveTo)}
            </LabeledValue>
          }
        </BoxedDiv>
      </td>

      <td>
        <BoxedDiv box={{flex: 'column', width: '400px'}}>
          <Textarea
            bare={true}
            disableResize={true}
            error={row.notification?.kind === 'invalid' ? row.notification.message : undefined}
            id={`${row.referenceTypeName}_${row.id}`}
            label='New content'
            onBlur={handleContentBlur}
            onChange={e => handleContentChange(e.target.value)}
            rows={8}
            setFocused={isContentFocused}
            style={{maxWidth: '400px'}}
            value={content}
          />

          {
            hasChanges &&
            <BoxedDiv box={{flex: 'row-reverse', justifyContent: 'space-between', marginTop: 1}}>
              <Button
                $kind="primary"
                onClick={() => handleContentSave({...row, content})}
              >Save</Button>

              <Button
                $kind="ghost"
                onClick={() => {
                  setContent(row.content || '');
                  onCancel(row);
                }}
              >Cancel</Button>
            </BoxedDiv>
          }

          {
            row.notification &&
            <BoxedDiv box={{marginV: 1}}>
              <Notification
                align="center"
                kind={
                  (row.notification.kind === 'error' || row.notification.kind === 'invalid') ?
                  'warning' :
                  'confirmation'
                }
              >
                {row.notification.message}
              </Notification>
            </BoxedDiv>
          }
        </BoxedDiv>
      </td>

      <td>
        {
          row.previewKind === 'tooltip' ?
          
          <BoxedDiv box={{flex: 'column', alignItems: 'center'}}>
            <TooltipHelp
              Help={<ReactMarkdown>{asString(content)}</ReactMarkdown>}
            />
          </BoxedDiv> :

          <Expandable
              collapsedLabel='Show more information on what data to supply on this page'
              expandedLabel='Hide more information on what data to supply on this page'
              contentPlacement='below'
              noWrap={true}
          >
              <HelpBlock box={{flex: 'column', marginBottom: 0.5, marginTop: 1}}>
                  <BoxedDiv box={{flex: 'column'}}>
                      <ReactMarkdown>{asString(content)}</ReactMarkdown>
                  </BoxedDiv>
              </HelpBlock>
          </Expandable>
        }
      </td>
    </tr>
  );
}

export default PortalDataView;

interface LabeledValueProps {
  label: string;
}

const LabeledValue = ({children, label}: PropsWithChildren<LabeledValueProps>) => {
  return (
    <BoxedSpan box={{alignItems: 'flex-start', flex: 'column', marginTop: 1}}>
      <strong>{label}:</strong>
      <Text $size={13}>{children}</Text>
    </BoxedSpan>
  );
}
