import { useEffect, useRef, useState } from "react";

type Status = 'error' | 'fulfilled' | 'pending';

type UsePromiseReturned<TData> = {
    data: TData | null;
    error: string | null;
    status: Status;
}

function usePromise<TResolved>(promiseFn: () => Promise<TResolved>): UsePromiseReturned<TResolved>;

function usePromise<TResolved, TMapped>(promiseFn: () => Promise<TResolved>, mapper: (resolved: TResolved) => TMapped): UsePromiseReturned<TMapped>;

function usePromise<TResolved, TAsserted>(promiseFn: () => Promise<TResolved>, asserter: TypeAssertion<TResolved, TAsserted>): UsePromiseReturned<TAsserted>;

function usePromise<TResolved, TMapped, TAsserted>(promiseFn: () => Promise<TResolved>, mapper: (resolved: TResolved) => TMapped, asserter: TypeAssertion<TMapped, TAsserted>): UsePromiseReturned<TAsserted>;

function usePromise<
    TResolved, TMapped, TMapper extends ((r: TResolved) => TMapped) | undefined, TAsserted, TAsserter extends TypeAssertion<unknown, TAsserted>
>(promiseFn: () => Promise<TResolved>, mapper?: TMapper | undefined, asserter?: TAsserter | undefined) {
    const promise = useRef(promiseFn);
    const invoked = useRef(false);
    const [data, setData] = useState<TResolved | TMapped | TAsserted | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState<Status>('pending');

    useEffect(() => {
        if (invoked.current) {
            return;
        }

        invoked.current = true;

        promise.current()
        .then(r => mapper ? mapper(r) : r)
        .then(r => {
            if (typeof asserter === 'undefined') {
                setData(r);
            } else {
                if (asserter(r)) {
                    setData(r)
                } else {
                    setError('Failed to assert resolved data');
                }
            }
        })
        .then(() => setStatus('fulfilled'))
        .catch(e => {
            setError(e);
            setStatus('error');
        });
    }, [asserter, invoked, mapper, promise]);

    return {data, error, status};
}

export default usePromise;
