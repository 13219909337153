import React, { useMemo } from 'react';
import { DataSubmission } from 'psims/models/data-submission';
import { UpdateMsoStockRefineryHeld, UpdateMsoStockRefineryHeldField } from 'psims/models/submission-types/mso/mso-stock-refinery';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { H3 } from 'psims/react/components/typography';
import UL, { NoMarkerLI } from 'psims/react/components/unordered-list';
import { Table, TR, TD, ColumnHeader, TH } from '../../shared/data-table-components';
import { UseMsoRefData } from './use-mso-ref-data';
import { ModelValidation } from './validation';
import Input from '../../shared/input';
import { TooltipHelp } from 'psims/react/pages/primary-pages/data-submissions/shared/tooltip-help';
import { StockRefineryHeldFocusField } from './types';
import FloatingMessage from 'psims/react/components/floating-message';
import { EXPIRED_OR_INTERNAL_PRODUCT_COLUMN } from 'psims/constants/validation-messages';
import { recordActionFromEnum } from 'psims/models/api/data-submission-record-action';

interface Actions {
    updateHeldAtRefinery: <TField extends UpdateMsoStockRefineryHeldField>(msoProductId: number, field: TField, value: UpdateMsoStockRefineryHeld[TField]) => any;
}

type UpdateMsoStockRefineryHeldView = UpdateMsoStockRefineryHeld & {validationMessages: ModelValidation<UpdateMsoStockRefineryHeldField>};

interface MsoHeldAtRefineryEditorProps {
    actions: Actions;
    dataSubmission: DataSubmission<'MSO refiner'>;
    focusedField: unknown | null;
    isDisabled: boolean;
    refData: UseMsoRefData;
    saveAttempted: boolean;
    stockRefineriesHeld: Array<UpdateMsoStockRefineryHeldView>;
}

type ProductHeaderProps = {
    data: Array<UpdateMsoStockRefineryHeldView>;
    isDisabled: boolean;
    product: UseMsoRefData['products'][number];
}

const ProductHeader = ({data, isDisabled, product}: ProductHeaderProps) => {
    const isInactiveProduct = product.productStatus === 'inactive';

    if (
        isInactiveProduct && (
            data.length === 0 ||
            data.filter(d => recordActionFromEnum(d.recordAction) !== 'Delete').length === 0
        )
    ) {
        return null;
    }

    let label = `Intended for ${product.name}`;
    let Tooltip = (
        <BoxedDiv box={{flex: 'column', width: '350px'}}>
            <p>Intended for {product.name}</p>
        </BoxedDiv>);
    
    if (product.code) {
        if (product.code === 'MSO_AUTO_GASOLINE') {
            label = `Intended for automotive gasoline`;
            Tooltip = (
                <BoxedDiv box={{flex: 'column', width: '350px'}}>
                    <p>The quantity of automotive gasoline that is expected to be produced from refinery feedstock under section 25 of the <em>Fuel Security Act</em>.</p>
                    <p>The yields are specified under s12 of the Fuel Security Services Payment Rule or based on at least a month of production volumes at the current yields for the refinery.</p>
                </BoxedDiv>);
        }
        if (product.code === 'MSO_AUTO_DIESEL') {
            label = `Intended for automotive diesel`;
            Tooltip = (
                <BoxedDiv box={{flex: 'column', width: '350px'}}>
                    <p>The quantity of automotive diesel that is expected to be produced from refinery feedstock under section 25 of the <em>Fuel Security Act</em>.</p>
                    <p>The yields are specified under s12 of the Fuel Security Services Payment Rule or based on at least a month of production volumes at the current yields for the refinery.</p>
                </BoxedDiv>);
        }
        if (product.code === 'MSO_AVKERO') {
            label = `Intended for aviation kerosene`;
            Tooltip = (
                <BoxedDiv box={{flex: 'column', width: '350px'}}>
                    <p>The quantity of aviation kerosene that is expected to be produced from refinery feedstock under section 25 of the <em>Fuel Security Act</em>.</p>
                    <p>The yields are specified under s12 of the Fuel Security Services Payment Rule or based on at least a month of production volumes at the current yields for the refinery.</p>
                </BoxedDiv>);
        }    
    }
    
    return (
        <ColumnHeader
            $align='center'
            label= {label}
            Help={isInactiveProduct ? undefined : Tooltip}
        >        
            {
                !isDisabled && isInactiveProduct ?
                <BoxedSpan box={{marginBottom: -0.5, marginLeft: 1}}>
                    <FloatingMessage
                        content={EXPIRED_OR_INTERNAL_PRODUCT_COLUMN}
                        kind="warning"
                        role="alert"
                    />
                </BoxedSpan> :
                null
            }
        </ColumnHeader>
    );
};

const MsoHeldAtRefineryEditor = ({actions, dataSubmission, focusedField, isDisabled, refData, saveAttempted, stockRefineriesHeld}: MsoHeldAtRefineryEditorProps) => {
    return (
        <BoxedDiv box={{flex: 'column'}}>
            <BoxedDiv box={{marginTop: 6}}>
                <H3>Held at refinery (s25)</H3>
            </BoxedDiv>

            <BoxedDiv box={{marginBottom: -4, marginTop: 2}}>
                <p>This section details any stock expected to be produced from refinery feedstock (Crude or Unfinished) that you are counting towards your MSO.</p>
                <p>Quantities should be reported to the nearest <strong>megalitre (ML)</strong>.</p>
            </BoxedDiv>

            <BoxedDiv box={{marginBottom: 2, overflow: 'auto', paddingTop: 7}}>
                <Table caption='Data for stock held at refinery'>
                    <thead>
                        <TR>
                            <ColumnHeader label='MSO Product' fixedWidth='200px' />
                            {
                                refData.products.map(product => (
                                    <ProductHeader
                                        key={product.id}
                                        data={stockRefineriesHeld.filter(srh => srh.msoProductId === product.id)}
                                        isDisabled={isDisabled}
                                        product={product}
                                    />
                                ))
                            }
                        </TR>
                    </thead>

                    <tbody>

                        <ProductRow
                            actions={actions}
                            dataSubmissionId={dataSubmission.id}
                            field='crudeStockVolume'
                            focusedField={focusedField}
                            isDisabled={isDisabled}
                            refData={refData}
                            refineriesHeld={stockRefineriesHeld}
                            saveAttempted={saveAttempted}
                        />

                        <ProductRow
                            actions={actions}
                            dataSubmissionId={dataSubmission.id}
                            field='unfinishedStockVolume'
                            focusedField={focusedField}
                            isDisabled={isDisabled}
                            refData={refData}
                            refineriesHeld={stockRefineriesHeld}
                            saveAttempted={saveAttempted}
                        />
                    </tbody>
                </Table>

            </BoxedDiv>
        </BoxedDiv>
    );
}

const FIELD_LABEL_MAP: {[key in UpdateMsoStockRefineryHeldField]: string} = {
    crudeStockVolume: 'Crude',
    unfinishedStockVolume: 'Unfinished',
};

interface ProductRowProps {
    actions: Actions;
    dataSubmissionId: number;
    field: UpdateMsoStockRefineryHeldField;
    focusedField: unknown | null;
    isDisabled: boolean;
    refData: UseMsoRefData;
    refineriesHeld?: Array<UpdateMsoStockRefineryHeldView>;
    saveAttempted: boolean;
}

const ProductRow = ({actions, dataSubmissionId, field, focusedField, isDisabled, refData, refineriesHeld, saveAttempted}: ProductRowProps) => {
    const fieldLabel = FIELD_LABEL_MAP[field];

    const refineryFF = useMemo(() =>(
        isRefineryHeldFocusField(focusedField) && focusedField.field === field ? focusedField : null
    ), [focusedField, field]);

    const CrudeHelp = 
        <BoxedDiv box={{flex: 'column', width: '350px'}}>
            <p><strong>Includes</strong></p>
            <p>The quantity of diesel, gasoline and kerosene per megalitre of crude oil is to be calculated by applying: </p>
            <UL>
                <NoMarkerLI>(a) the yields for the refinery applicable under section 12 of the FSSP Rule; or</NoMarkerLI>
                <NoMarkerLI>(b) the current yields for the refinery, based on at least a month of production volumes.</NoMarkerLI>
            </UL>
            <p>Crude oil (a mineral oil of natural origin comprising a mixture of hydrocarbons and associated impurities, such as sulphur. If any condensate or LPG is commingled with the crude oil stream then that should be included in this category. Include separately marketed streams of crude as well as commingled streams). </p>
            <p>Field condensate (a mixture of low molecular weight hydrocarbons that are recovered from surface separation facilities at or near the field. Include separately marketed streams of field condensate only).</p>
            <p><strong>Excludes</strong></p>
            <p>Plant condensate.</p>
		</BoxedDiv>;

    const UnfinishedHelp = 
        <BoxedDiv box={{flex: 'column', width: '350px'}}>
            <p>The quantity of diesel, gasoline and kerosene per megalitre of unfinished refinery products is to be calculated by applying a methodology set out in the entity’s MSO compliance plan.</p>
            <p><strong>Includes</strong></p>
            <p>Partially refined products that are not finished products and are intended to be further refined within the refinery.</p>
            <p>Unfinished refinery products are prescribed if:</p>
            <UL>
                <NoMarkerLI>(a) they are reasonably expected to be transformed into diesel, gasoline and kerosene at the refinery; and</NoMarkerLI>
                <NoMarkerLI>(b) after transformation into diesel, gasoline and kerosene, the volume of those products is expected to be more than 80% of the volume of the unfinished refinery products being stored at the refinery.</NoMarkerLI>
            </UL>
            <p><strong>Excludes</strong></p>
            <p>Any unfinished products manufactured in the refinery that are intended for blending outside the refinery.</p>
        </BoxedDiv>;

    const Help = field === 'crudeStockVolume' ? CrudeHelp : field === 'unfinishedStockVolume' ? UnfinishedHelp : null;

    return (
        <TR>
            <TH scope='row' verticalAlign='top'>
                <BoxedSpan box={{alignItems: 'center', flex: 'row', height: '52px'}}>
                    {fieldLabel}
                    {
                        Help != null &&
                        <TooltipHelp Help={Help} />
                    }
                </BoxedSpan>
            </TH>

            {
                refData.products
                    .filter(product => {
                        const refineryHeld = refineriesHeld?.find(r => r.msoProductId === product.id)

                        return refineryHeld != null || product.productStatus === 'active';
                    })
                    .map(product => {
                        const refineryHeld = refineriesHeld?.find(r => r.msoProductId === product.id)

                        if (recordActionFromEnum(refineryHeld?.recordAction) === 'Delete') {
                            return null;
                        }

                        return (
                            <TD key={product.id}>
                                <Input
                                    kind='number'
                                    disabled={isDisabled}
                                    error={refineryHeld?.validationMessages ? refineryHeld.validationMessages[field]?.tooltip.message : undefined}
                                    forceError={saveAttempted}
                                    label={`${fieldLabel} intended for ${product.name}`}
                                    value={refineryHeld ? refineryHeld[field] : undefined}
                                    onChange={v => actions.updateHeldAtRefinery(product.id, field, v)}
                                    shouldFocus={refineryFF?.msoProductId === product.id}
                                />
                            </TD>
                        );
                    })
            }
        </TR>
    )
}

export default MsoHeldAtRefineryEditor;

function isRefineryHeldFocusField(maybe?: unknown | null): maybe is StockRefineryHeldFocusField {
    if (maybe == null) {
        return false;
    }

    const asF = maybe as StockRefineryHeldFocusField;

    return  (
        asF.kind === 'stockRefineryHeld' &&
        asF.field != null &&
        asF.msoProductId != null
    );
}
