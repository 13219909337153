import React from 'react';
import BaseSVG, { SVGProps } from './_base';

const Ellipsis = (props: SVGProps) => {
	return (
		<BaseSVG name='ellipsis' viewBox='0 0 512 512' {...props}>
			<path fill="currentColor" d="M304 256c0 26.5-21.5 48-48 48s-48-21.5-48-48 21.5-48 48-48 48 21.5 48 48zm120-48c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48zm-336 0c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z"></path>
		</BaseSVG>
	);
};

export default Ellipsis;
