import Button from 'psims/react/components/button';
import { BoxedDiv } from 'psims/react/components/layout';
import React, { useCallback, useMemo, useState } from 'react';
import Notification from 'psims/react/components/notification';
import Loading from 'psims/react/components/loading';
import { Organisation } from 'psims/models/organisation';
import CompanyOverview from './company-overview';
import useOrganisationAPI from './use-organisation-api';
import { useNavigation } from 'psims/react/providers/router';
import useEditCompany from './use-edit-company';
import { useAppOverlay } from 'psims/react/providers/app-overlay';
import { Redirect } from 'react-router-dom';
import { useUser } from 'psims/react/providers/user';

const CompanyOverviewPage = () => {
	 const vm = useVM();

     if (vm.user?.roleType !== 'Company Admin') {
		return <Redirect to='/' />;
	}
    
	if (vm.viewMode === 'loading') {
        return (
            <BoxedDiv box={{alignItems: 'center', flex: 'column', flexGrow: 1, justifyContent: 'center'}}>
                <Loading>Loading</Loading>
            </BoxedDiv>
        )
    }

    if (vm.viewMode === 'error') {
        return (
            <div className='container'>
                <BoxedDiv box={{alignItems: 'center', flex: 'column', marginV: 4}}>
                    <BoxedDiv box={{alignItems: 'center', flex: 'column', width: '600px'}}>
                        <Notification align='center' kind='warning' >
                            <p>
                                There was a problem loading Company overview, please navigate back to your <Button $kind='text' aria-label='Go back to home page' onClick={vm.handleTryAgain}>'Home' page</Button> and try again.
                            </p>
                        </Notification>
                    </BoxedDiv>
                </BoxedDiv>
            </div>
        )
    }

    if (
        vm.viewMode === 'loaded' 
    ) {
        return <CompanyOverview organisations={vm.organisations} showEditCompanyDialog={vm.showEditCompanyDialog} editCompanyCtrl={vm.editCompanyCtrl}></CompanyOverview>;
    }

    return null;
};

export default CompanyOverviewPage;

function useVM() {
    const { user } = useUser();
    const { setOverlay } = useAppOverlay();
    const apiCtrl = useOrganisationAPI();
    const {loadStatus} = apiCtrl;
    const navigation = useNavigation();
    const [organisations, setOrganisations] = useState<Array<Organisation> | null>(null);
    const handleTryAgain = useCallback(() => {
        navigation.goToHomePage();
    }, [navigation]);

    const onServiceSuccess = () => {
		apiCtrl.fetch(); 
		setOverlay(null);
	}

    const editCompanyCtrl = useEditCompany({onSuccess: onServiceSuccess});
    const showEditCompanyDialog = editCompanyCtrl.updateOrganisation !== null;

    const viewMode = useMemo(() => {
        if (loadStatus === 'fetch_failed') {
            return 'error';
        }

         if (apiCtrl.organisations != null) {
            setOrganisations(apiCtrl.organisations);
            return 'loaded';
         }

        return 'loading';
    }, [loadStatus, apiCtrl.organisations]);

    return {
        apiCtrl,
        handleTryAgain,
        loadStatus,
        viewMode,
        organisations,
        editCompanyCtrl,
        showEditCompanyDialog,
        user 
    };
}