import { MsoStockOnBehalfVM, UpdateMsoStockOnBehalfVM } from "psims/gen/xapi-client";
import { isWithUpdateable, WithUpdatable } from "psims/models/api/update";

type MaybeMsoStockOnBehalf = MsoStockOnBehalfVM;

export type MsoStockOnBehalf = WithUpdatable<
    PopulatedProps<MaybeMsoStockOnBehalf, 'dataSubmissionId' | 'msoProductId'>
>;

export function isMsoStockOnBehalf(maybe?: MaybeMsoStockOnBehalf | null): maybe is MsoStockOnBehalf {
    return (
        isWithUpdateable<MaybeMsoStockOnBehalf>(maybe) &&
        maybe.dataSubmissionId != null &&
        maybe.msoProductId != null
    );
}

type MaybeUpdateMsoStockOnBehalf = UpdateMsoStockOnBehalfVM;

export type UpdateMsoStockOnBehalf = PopulatedProps<MaybeUpdateMsoStockOnBehalf, 'dataSubmissionId' | 'msoProductId'>;

export type UpdateMsoStockOnBehalfField = Exclude<keyof UpdateMsoStockOnBehalf, 'dataSubmissionId' | 'msoProductId' | 'id' | 'recordAction' | 'concurrencyToken'>;

export function isUpdateMsoStockOnBehalf(maybe: unknown): maybe is UpdateMsoStockOnBehalf {
    const maybeAs = maybe as UpdateMsoStockOnBehalf;

    return (
        maybeAs != null &&
        maybeAs.dataSubmissionId != null &&
        maybeAs.msoProductId != null
    );
}
