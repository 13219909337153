export function attemptScrollToSelector(selector: string, delay: number = 0) {
    setTimeout(() => {
        // Scroll to notifications
        const validationMessageEl = document.querySelector(selector);
        if (validationMessageEl != null) {
            validationMessageEl.scrollIntoView({block: 'center', behavior: 'smooth'})
        }
    }, delay);
}

type Focusable = {
    focus: () => any;
}

export function attemptFocusOnSelector(selector: string, delay: number = 0) {
    setTimeout(() => {
        // Scroll to notifications
        const target = document.querySelector(selector);

        if (target != null) {
            target.scrollIntoView({block: 'center', behavior: 'smooth'})
            if (isFocusable(target)) {
                target.focus();
            }
        }
    }, delay);
}

function isFocusable(maybe: unknown): maybe is Focusable {
    return typeof ((maybe as Focusable).focus) === 'function';
}
