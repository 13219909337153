import { RefineryCommentVM, RefiningSubmissionFormDataVM, RefiningSubmissionVM, RefiningVM, UpdateRefineryCommentVM, UpdateRefiningSubmissionVM, UpdateRefiningVM } from "psims/gen/xapi-client";
import { UpdateRefiningSubmissionFormDataVM } from "psims/gen/xapi-client/models/UpdateRefiningSubmissionFormDataVM";
import { isArrayOfType } from "psims/lib/collections";
import { isEntity } from "../api/entity";
import { UpdateFields, WithUpdatable } from "../api/update";
import { isPopulatedDataSubmission } from "../data-submission";
import { WithDataSubmission } from "./data-submission";

// Entity models
type MaybeRefining = RefiningVM;
export type MaybeRefiningSubmissionFormData = RefiningSubmissionFormDataVM;
type MaybeRefiningComment = RefineryCommentVM;
export type MaybeRefiningSubmission = RefiningSubmissionVM;

export type Refining = WithUpdatable<
    PopulatedProps<MaybeRefining, 'refineryTypeId' | 'refineryProductId'>
>;
export type RefiningSubmissionFormData = PopulatedProps<
    MaybeRefiningSubmissionFormData,
    'dataSubmissionId' | 'calculatedLossesOrGains' | 'concurrencyToken' | 'id' | 'gasesIntermediatePetrochemLossesSaved' | 'refineryInputsPageSaved' | 'refineryOutputsPageSaved'
>;
export type RefiningComment = WithUpdatable<
    PopulatedProps<MaybeRefiningComment, 'refineryTypeId'>
>;
export type RefiningSubmission = WithDataSubmission<'Refining', {
    submissionFormData: RefiningSubmissionFormData;
    refinings: Array<Refining>;
    refineryComments: Array<RefiningComment>;
}>;

// Update data
type RefiningData = Omit<UpdateRefining, UpdateFields | 'dataSubmissionId' | 'refineryProductGroupId' | 'refineryProductId'>;

export type RefiningField = keyof RefiningData;

// Update models
type MaybeUpdateRefining = UpdateRefiningVM;
type MaybeUpdateRefiningComment = UpdateRefineryCommentVM;
type MaybeUpdateRefiningSubmission = UpdateRefiningSubmissionVM;
type MaybeUpdateRefiningSubmissionFormData = UpdateRefiningSubmissionFormDataVM;

export type UpdateRefining = PopulatedProps<MaybeUpdateRefining, 'refineryProductId'>;
export type UpdateRefiningComment = PopulatedProps<MaybeUpdateRefiningComment, 'refineryTypeId'>;
export type UpdateRefiningSubmissionFormData = PopulatedProps<MaybeUpdateRefiningSubmissionFormData, 'dataSubmissionId' | 'id' | 'calculatedLossesOrGains' | 'concurrencyToken'>;
export type UpdateRefiningSubmission = PopulatedProps<MaybeUpdateRefiningSubmission, 'dataSubmissionId' | 'refinings'> & {
    refinings: Array<UpdateRefining>;
    refineryComment?: UpdateRefiningComment | null;
    submissionFormData: UpdateRefiningSubmissionFormData;
};

function isRefiningSubmissionFormData(maybe?: MaybeRefiningSubmissionFormData): maybe is RefiningSubmissionFormData {
    return maybe != null &&
           maybe.dataSubmissionId != null &&
           maybe.gasesIntermediatePetrochemLossesSaved != null &&
           maybe.refineryInputsPageSaved != null &&
           maybe.refineryOutputsPageSaved != null;
}

function isRefining(maybe?: MaybeRefining | null): maybe is Refining {
    return isEntity(maybe) &&
           maybe.refineryProductId != null;
}

function isRefiningComment(maybe?: MaybeRefiningComment | null): maybe is RefiningComment {
    return isEntity(maybe) &&
           maybe.refineryTypeId != null;
}

export function isRefiningSubmission(maybe?: MaybeRefiningSubmission | null): maybe is RefiningSubmission {
    return maybe != null &&
           isPopulatedDataSubmission<'Refining'>(maybe.dataSubmission) &&
           isRefiningSubmissionFormData(maybe.submissionFormData) &&
           isArrayOfType(isRefining, maybe.refinings) &&
           isArrayOfType(isRefiningComment, maybe.refineryComments);
}

export function updateRefiningToRefiningData(updateRefining: UpdateRefining | null): RefiningData {
    const { ...refiningData} = updateRefining;
    return refiningData;
}
