import { useCallback, useMemo, useState } from "react";

import {INVALID_ROLE_TYPE } from "psims/constants/validation-messages";
import { validateEmail, validateFirstName, validateLastName } from "psims/lib/validation/user";
import { CreateUser, isUser, User } from "psims/models/user";
import { useReferenceData } from "psims/react/providers/api/reference-data";
import { isEmpty } from "psims/lib/empty";
import { useAPI } from "psims/react/providers/api";
import { Status } from "psims/types/async";
import { asString } from "psims/lib/string";
import { isForbiddenError, isWithErrorMessages, stringifyForbiddenWAFError } from "psims/lib/server-error";
import { useSelectController } from "psims/react/components/select";
import { encodeEscapeChars } from "psims/lib/validation/string";

type ValidationMessages = {
    [key in keyof User]?: string;
}

interface UseAddUserProps {
    onSuccess: (user: User) => any;
}

function useAddUser(props: UseAddUserProps) {
    const { api } = useAPI();
    const {data: refData} = useReferenceData();
    const {roleTypes, userStatuses} = refData || {};

    const [user, setUser] = useState<CreateUser | null>(null);
    const [serviceError, setServiceError] = useState<string | null>(null);
    const [serviceStatus, setServiceStatus] = useState<Status>('init');
    const [saveAttempted, setSaveAttempted] = useState(false);

    const roleOptions = useMemo(() => {
        if (roleTypes == null) {
            return [];
        }

        return roleTypes.map(roleType => ({
            data: roleType,
            label: asString(roleType.name),
            value: Number(roleType.id)
        }));
    }, [roleTypes]);

    const statusOptions = useMemo(() => {
        if (userStatuses == null) {
            return [];
        }

        return userStatuses
            .filter(userStatus => userStatus.name === 'New')
            .map(userStatus => ({
                data: userStatus,
                label: asString(userStatus.name),
                value: Number(userStatus.id),
            }));
    }, [userStatuses]);

    const validationMessages = useMemo(() => {
        const messages: ValidationMessages = {};

        const emailMsg = validateEmail(user?.email);
        if (emailMsg !== null) {
            messages.email = emailMsg;
        }

        const firstNameMsg = validateFirstName(user?.firstName);
        if (firstNameMsg !== null) {
            messages.firstName = firstNameMsg;
        }

        const lastNameMsg = validateLastName(user?.lastName);
        if (lastNameMsg !== null) {
            messages.lastName = lastNameMsg;
        }

        if (!Boolean(user?.roleTypeId)) {
            messages.roleType = INVALID_ROLE_TYPE;
        }

        return messages;
    }, [user]);

    function newUser() {
        setServiceError(null);
        setUser({});
    }

    const updateUser = useCallback(<K extends keyof CreateUser>(field: K, value: CreateUser[K]) => {
        setUser(old => {
            if (old === null) {
                return null;
            }

            return {
                ...old,
                [field]: value,
            };
        });
    }, [setUser]);

    const selectRoleCtrl = useSelectController({
        value: user?.roleTypeId || null,
        onChange: roleId => updateUser('roleTypeId', roleId || undefined),
        options: roleOptions,
    });

    function save() {
        setSaveAttempted(true);

        if (isEmpty(validationMessages) && user !== null) {
            setServiceError(null);
            setServiceStatus('loading');
            
            const userEncoded = {
                ...user,
                firstName: encodeEscapeChars(user.firstName),
                lastName: encodeEscapeChars(user.lastName)
            };

            api.createUser({requestBody: userEncoded})
            .then(response => {
                if (response?.isSuccessful && isUser(response.result)) {
                    props.onSuccess(response.result);
                    setUser(null);
                    setServiceStatus('fulfilled');
                }
            })
            .catch(e => {
                setServiceStatus('error');

                if (isForbiddenError(e)) {
                    setServiceError(stringifyForbiddenWAFError(e));
                } else {
                    if (isWithErrorMessages(e)) {
                        setServiceError(e.body.errorMessages[0]);
                    } else {
                        setServiceError(`${e}`);
                    }
                }
            });
        }
    }

    function cancel() {
        setUser(null);
        setSaveAttempted(false);
    }

    return {
        cancel,
        newUser,
        roleOptions,
        save,
        saveAttempted,
        selectRoleCtrl,
        serviceError,
        serviceStatus,
        statusOptions,
        updateUser,
        user,
        validationMessages,
    };
}

export default useAddUser;

export type UseAddUser = ReturnType<typeof useAddUser>;
