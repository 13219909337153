import { useEffect, useRef } from "react";

// Simple wrapper that keeps a target tracked by a ref. Useful,
// for example, to keep a dependency up-to-date but not having
// chages to that dependency trigger effects
function useUpdatedRef<T>(target: T) {
    const ref = useRef(target);

    useEffect(() => {
        ref.current = target;
    }, [target]);

    return ref;
}

export default useUpdatedRef;
