import React, { createContext, useContext, useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react';

import { User, userVMToUser, Status as UserStatus } from 'psims/models/user';
import { useAuth } from './auth';
import { useAPI } from './api';

type Status = 'Uninitialised' | 'Unauthenticated' | 'Authenticating' | 'Not_Found' | 'Service_Error' | UserStatus;

type UserContext = {
	setUser: (user: User | null) => void;
	status: Status;
	user: User | null;
	refetch: () => void;
	setStatus: Dispatch<SetStateAction<Status>>;
};

const ctx = createContext<UserContext | null>(null);

const UserProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const { account, status: authStatus } = useAuth();
	const [user, setUser] = useState<User | null>(null);
	const [status, setStatus] = useState<Status>('Uninitialised');
	const { api, status: apiStatus } = useAPI();

	function updateUser(user: User | null, status?: Status) {
		setUser(user);
		setStatus(status || (user === null ? 'Not_Found' : user.status));
	}

	useEffect(() => {
		if (authStatus === 'unauthenticated') {
			setStatus('Unauthenticated');
		}
	}, [authStatus]);

	useEffect(() => {
		if (apiStatus === 'ready' && account !== null && account !== undefined && !user) {
			api.getUser()
				.then(user => {
					const u = user?.result || null;

					updateUser(u === null ? null : userVMToUser(u));
				})
				.catch(e => {
					updateUser(null, e.status === 404 ? 'Not_Found' : 'Service_Error');
				});
		}
	}, [api, apiStatus, account, user]);

	const refetch = useCallback(() => {
		if (apiStatus === 'ready' && account !== null && account !== undefined) {
			api.getUser()
				.then(user => {
					const u = user?.result || null;

					updateUser(u === null ? null : userVMToUser(u));
				})
				.catch(e => {
					updateUser(null, e.status === 404 ? 'Not_Found' : 'Service_Error');
				});
		}

		return null;
	}, [account, api, apiStatus]);

	return (
		<ctx.Provider value={{ user, setUser: updateUser, status, refetch, setStatus }}>
			{children}
		</ctx.Provider>
	);
};

export default UserProvider;

export function useUser() {
	const c = useContext(ctx);

	if (c === null) {
		throw new Error('useUser must be used in UserProvider');
	}

	return {
		...c
	};
}
