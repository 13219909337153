import { OrganisationMsoSettingHistoryVM } from "psims/gen/xapi-client";

export type MinimumStockObligationSetting = PopulatedProps<
    OrganisationMsoSettingHistoryVM,
    'id' | 'effectiveFrom' | 'isActive' | 'minimumStockObligation' | 'msoClassName' | 'msoProductName' | 'reportingEntityName'
>;

export function isMinimumStockObligationSetting(maybe?: unknown): maybe is MinimumStockObligationSetting {
    const maybeAs = maybe as MinimumStockObligationSetting;

    return (
        maybeAs != null &&
        maybeAs.id != null &&
        maybeAs.effectiveFrom != null &&
        maybeAs.isActive != null &&
        maybeAs.minimumStockObligation != null &&
        maybeAs.msoClassName != null &&
        maybeAs.msoProductName != null &&
        maybeAs.reportingEntityName != null
    );
}
