import { StateDTO } from 'psims/gen/xapi-client';
import { assertNotEmpty } from 'psims/lib/empty';

export type State = PopulatedProps<StateDTO, 'id' | 'name' | 'sortOrder'>;

export function assertState(maybe?: unknown): asserts maybe is State {
    const asState = maybe as State;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asState, hint('state'));
    assertNotEmpty(asState.id, hint('state.id'));
    assertNotEmpty(asState.name, hint('state.name'));
    assertNotEmpty(asState.sortOrder, hint('state.sortOrder'));
}
