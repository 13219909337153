/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MsoAnnualActivityImporterSubmissionVMApiResponse } from '../models/MsoAnnualActivityImporterSubmissionVMApiResponse';
import type { UpdateDataSubmissionVM } from '../models/UpdateDataSubmissionVM';
import type { UpdateMsoAnnualActivityImporterSubmissionVM } from '../models/UpdateMsoAnnualActivityImporterSubmissionVM';
import { request as __request } from '../core/request';

export class MsoAnnualActivityImporterService {

    /**
     * @returns MsoAnnualActivityImporterSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async getMsoAnnualActivityImporterSubmission({
id,
}: {
id?: number,
}): Promise<MsoAnnualActivityImporterSubmissionVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/mso-annual-activity-importer`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns MsoAnnualActivityImporterSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateMsoAnnualActivityImporter({
requestBody,
}: {
requestBody?: UpdateMsoAnnualActivityImporterSubmissionVM,
}): Promise<MsoAnnualActivityImporterSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-annual-activity-importer/Update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoAnnualActivityImporterSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateMsoAnnualActivityImporterDataSubmission({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoAnnualActivityImporterSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-annual-activity-importer/Update-DataSubmission`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoAnnualActivityImporterSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async submitMsoAnnualActivityImporter({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoAnnualActivityImporterSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-annual-activity-importer/Submit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoAnnualActivityImporterSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async clearAllMsoAnnualActivityImporter({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoAnnualActivityImporterSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-annual-activity-importer/Clear-All`,
            body: requestBody,
        });
        return result.body;
    }

}