import { PropsWithChildren } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import styled from 'styled-components';
import { SPACE_BASE } from 'psims/constants/styles';
import { H2, H3 } from './typography';

interface SectionProps {
	className?: string;
	title?: string;
	headerStyle?: 'h2' | 'h3';
};

const StyledSection = styled.section`
	margin-top: ${SPACE_BASE * 4}px;
`;

const Section = ({children, className, title, headerStyle}: PropsWithChildren<SectionProps>) => {
	const HeaderElement = headerStyle ? headerStyle === 'h2' ? H2 : H3 : H3;
	return (
		<StyledSection className={className} title={typeof title === 'string' ? title : ''}>
			<BoxedDiv box={{alignItems: 'flex-start', alignSelf: 'stretch', flex: 'column'}}>
				<BoxedDiv box={{alignSelf: 'stretch'}}>
					<HeaderElement>{title}</HeaderElement>
					{/* <h3>{title}</h3> */}
				</BoxedDiv>

				{children}
			</BoxedDiv>
		</StyledSection>
	);
};

export default Section;
