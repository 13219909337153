import React, { useMemo } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import { H2 } from 'psims/react/components/typography';
import { ColumnHeader, Table, TD} from 'psims/react/pages/primary-pages/data-submissions/shared/data-table-components';
import { UseTotalQuarterlyElibibleFssp } from './use-total-quarterly-eligible-fssp';
import Textarea from 'psims/react/components/textarea';
import { UseFocusedField } from 'psims/react/util/use-focused-field';
import { FocusField } from '../types';
import { UseFspNavigation } from '../use-fsp-navigation';

interface TotalQuarterlyEligibleFsspProps {
  ctrl: UseTotalQuarterlyElibibleFssp;
  focusedFieldCtrl: UseFocusedField<FocusField>;
  navCtrl: UseFspNavigation;
}

const TotalQuarterlyEligibleFssp = (props: TotalQuarterlyEligibleFsspProps) => {
  const vm = useVM(props);

  return (
    <BoxedDiv box={{flex: 'column'}}>
      <H2>Total quarterly production eligible for fuel security services payment</H2>

      <BoxedDiv box={{alignItems: 'flex-start', flex: 'column', marginTop: 2}}>
        <Table
          caption='Your total quarterly production eligible for Fuel Security Services Payment summary'
          customMinWidth='800px'
          customWidth='800px'
        >
          <thead>
            <tr>
              <ColumnHeader
                label='Total eligible production (T)'
                helpId='total_eligible_production_t'
                Help={
                  <BoxedDiv box={{flex: 'column'}}>
                    <p>This is a calculated field.</p>
                    <p>Total eligible production (T) = sum eligible production (T) of all listed products.</p>
                  </BoxedDiv>
                }
                $width='300px'
              />

              <ColumnHeader
                label='Total eligible production (KL)'
                helpId='total_eligible_production_kl'
                Help={
                  <BoxedDiv box={{flex: 'column'}}>
                    <p>This is a calculated field.</p>
                    <p>Total eligible production (KL) = sum eligible production (KL) of all listed products.</p>
                  </BoxedDiv>
                }
                $width='300px'
              />
            </tr>
          </thead>

          <tbody>
            <tr>
              <TD>{vm.ctrl.view.totalTonnes}</TD>
              <TD>{vm.ctrl.view.totalKilolitres}</TD>
            </tr>
          </tbody>
        </Table>
      </BoxedDiv>

      {/* Comments */}
      <BoxedDiv box={{marginTop: 4}}>
        <Textarea
            disabled={vm.ctrl.viewMode !== 'edit'}
            error={vm.commentsError}
            id='comments'
            label={`Comments ${vm.ctrl.isCommentsRequired ? '(required)' : '(optional)'}`}
            setFocused={vm.focusedFieldCtrl.focusedField?.kind === 'comments'}
            value={vm.ctrl.comments || ''}
            onChange={evt => vm.ctrl.setComments(evt.target.value)} // Noop - handled by commentsCtrl
            ref={vm.ctrl.commentsCtrl.setEl}
        />
      </BoxedDiv>
    </BoxedDiv>
  )
}

function useVM({ctrl, focusedFieldCtrl, navCtrl}: TotalQuarterlyEligibleFsspProps) {
  const commentsError = useMemo(() => {
    if (navCtrl.navState !== 'initial' || ctrl.commentsCtrl.focusState !== 'unvisited') {
      return ctrl.commentsError;
    }

    return undefined;
  }, [ctrl.commentsError, ctrl.commentsCtrl.focusState, navCtrl.navState]);

  return {
    commentsError,
    ctrl,
    focusedFieldCtrl,
  };
}

export default TotalQuarterlyEligibleFssp;
