import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { User } from 'psims/models/user';
import Page from 'psims/react/blocks/page';
import useContactRegistration from 'psims/react/util/use-contact-registration';
import Box from 'psims/react/components/box';
import {InputNew as Input} from 'psims/react/components/input';
import Select, { useSelectController } from 'psims/react/components/select';
import Button from 'psims/react/components/button';
import { useUser } from 'psims/react/providers/user';
import Text from 'psims/react/components/text';
import { PropertyRow, PropertyLabel, PropertyValue } from 'psims/react/components/labeled-property';
import { useAppOverlay } from 'psims/react/providers/app-overlay';
import Modal from 'psims/react/components/modal';
import Spinner from 'psims/react/components/spinner';
import Notification from 'psims/react/components/notification';
import { useReferenceData } from 'psims/react/providers/api/reference-data';
import { asString } from 'psims/lib/string';
import { formatAbn } from 'psims/lib/formatters/abn';
import { H2 } from '../components/typography';

interface ContactRegistrationProps {
    user: User;
}

const ContactRegistration = (props: ContactRegistrationProps) => {
    const vm = useVM(props);

    return (
        <Page hideNavbar={true}>
            <main>
                <Box alignItems='center' flex='column' className='container' flexGrow={1} paddingBottom='lg'>
                    <Box flex='column' minWidth='600px'>
                        <Box aria-describedby='recorded-details-title' role='region' flex='column' marginBottom='xxl'>
                            <H2 className='text-align--center'>User registration</H2>

                            <Box marginBottom='lg'>
                                <h3 id='recorded-details-tile'>We have the following details recorded for you</h3>
                            </Box>

                            <dl>
                                <PropertyRow>
                                    <PropertyLabel>Name</PropertyLabel>
                                    <PropertyValue>{vm.formCtrl.contactRegistration.firstName} {vm.formCtrl.contactRegistration.lastName}</PropertyValue>
                                </PropertyRow>

                                <PropertyRow>
                                    <PropertyLabel>Reporting entity</PropertyLabel>
                                    <PropertyValue>{vm.formCtrl.contactRegistration.organisation?.name} {Boolean(vm.formCtrl.contactRegistration.organisation?.abn) && '(ABN: ' + formatAbn(vm.formCtrl.contactRegistration.organisation!.abn!) + ')'}</PropertyValue>
                                </PropertyRow>

                                <PropertyRow>
                                    <PropertyLabel>Email address</PropertyLabel>
                                    <PropertyValue>{vm.formCtrl.contactRegistration.email}</PropertyValue>
                                </PropertyRow>

                                <PropertyRow>
                                    <PropertyLabel>Role type</PropertyLabel>
                                    <PropertyValue>{vm.formCtrl.contactRegistration.roleType}</PropertyValue>
                                </PropertyRow>
                            </dl>
                        </Box>

                        <Box marginBottom='lg'>
                            <h3 id='form-title' aria-describedby='recorded-details'>We need the following extra details from you</h3>
                        </Box>

                        <form aria-describedby='form-title' onSubmit={vm.handleSubmit} title='Registration details'>
                            <h4 id='contact-details-title' className='margin-v--0'>Contact details</h4>

                            <Box>
                                <Input
                                    aria-describedby='contact-details-title'
                                    error={vm.formCtrl.validationMessages.phone}
                                    id='phone'
                                    label='Phone number'
                                    onChange={e => vm.formCtrl.setPhone(e.target.value)}
                                    forceError={vm.showValidation}
                                    value={vm.formCtrl.contactRegistration.contactDetails.phone || ''}
                                />

                            </Box>

                            <h4 className='margin-bottom--0'>Address</h4>
                            <section aria-describedby='address-help'>
                                <Box marginBottom='sm' marginTop='md'>
                                    <Text id='address-help' $size={14} variant='muted'>This is the Australian address where mail correspondence could be sent to you</Text>
                                </Box>

                                <Input
                                    error={vm.formCtrl.validationMessages.address.line1}
                                    id='address1'
                                    label='Address line 1'
                                    onChange={e => vm.formCtrl.setAddressField('line1', e.target.value)}
                                    forceError={vm.showValidation}
                                    value={vm.formCtrl.contactRegistration.contactDetails.address.line1}
                                    width={'100%'} />

                                <Input
                                    error={vm.formCtrl.validationMessages.address.line2}
                                    id='address2'
                                    label='Address line 2 (optional)'
                                    onChange={e => vm.formCtrl.setAddressField('line2', e.target.value)}
                                    forceError={vm.showValidation}
                                    value={vm.formCtrl.contactRegistration.contactDetails.address.line2}
                                    width={'100%'} />

                                <Input
                                    error={vm.formCtrl.validationMessages.address.suburb}
                                    id='suburb'
                                    label='Suburb' onChange={e => vm.formCtrl.setAddressField('suburb', e.target.value)}
                                    forceError={vm.showValidation}
                                    value={vm.formCtrl.contactRegistration.contactDetails.address.suburb}
                                    width={'100%'} />

                                <Box flex='row' flexGrow={1}>
                                    <Box>
                                        <Select
                                            id='state'
                                            error={vm.formCtrl.validationMessages.address.stateId}
                                            label='State'
                                            placeholder='Select state'
                                            onChange={vm.selectStateCtrl.onChange}
                                            options={vm.selectStateCtrl.options}
                                            forceError={vm.showValidation}
                                            value={vm.selectStateCtrl.value}
                                            width='160px'
                                        />
                                    </Box>

                                    <Box marginLeft='lg'>
                                        <Input
                                            error={vm.formCtrl.validationMessages.address.postcode}
                                            id='postcode'
                                            label='Postcode'
                                            onChange={e => vm.formCtrl.setAddressField('postcode', e.target.value)}
                                            forceError={vm.showValidation}
                                            value={vm.formCtrl.contactRegistration.contactDetails.address.postcode} />
                                    </Box>

                                </Box>
                            </section>

                            {
                                vm.submissionStatus === 'failed_server_error' &&
                                <Box alignItems='center' flex='column' marginTop='xxl'>
                                    <Notification kind='warning'>
                                        <Box flex='column'>
                                            <p>There was a problem submitting your registration details.</p>
                                            <p>Please try again or contact support if the issue persists.</p>
                                        </Box>
                                    </Notification>
                                </Box>
                            }

                            <Box alignItems='flex-end' flex='column' marginTop='xxl'>
                                <Button $kind='primary' type='submit'>Register my details</Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </main>
        </Page>
    )
}


function useVM(props: ContactRegistrationProps) {
    const {data: refData} = useReferenceData();
    const {setOverlay} = useAppOverlay();
    const formCtrl = useContactRegistration(props.user);
    const user = useUser();
    const history = useHistory();

    const {handleSubmit, showValidation, submissionStatus} = formCtrl;

    const stateOptions = useMemo(() => {
        if (refData?.states == null) {
            return [];
        }

        return [
            {
                data: null,
                label: 'Select',
                value: null,
            },
            ...(refData.states
            .filter(state => state.name !== 'Other')
            .sort((a, b) => {
                return (a.name || '') > (b.name || '') ? 1 : -1;
            })
            .map(state => ({
                data: state,
                label: asString(state.name),
                value: state.id as number,
            })))
        ];
    }, [refData]);

    const selectStateCtrl = useSelectController<number | null>({
        value: formCtrl.contactRegistration.contactDetails.address.stateId || null,
        onChange: stateId => formCtrl.setAddressField('stateId', stateId || undefined),
        options: stateOptions,
    });

    useEffect(() => {
        if (formCtrl.submissionStatus === 'posting') {
            setOverlay(<Modal>
                <Box alignItems='center' flex='row'>
                    <Spinner size='lg' />
                    <Box alignItems='center' flex='row' marginLeft='md'>
                        <Text>Updating registration details...</Text>
                    </Box>
                </Box>
            </Modal>);
        } else {
            setOverlay(null);
        }
    }, [formCtrl.submissionStatus, setOverlay]);

    useEffect(() => {
        if (user.status === 'Registered') {
          history.push('/');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.status]);

    return {
        formCtrl,
        handleSubmit,
        showValidation,
        selectStateCtrl,
        submissionStatus,
    };
}

export default ContactRegistration;
