import { useCallback, useMemo } from "react";

import { BoxedDiv } from 'psims/react/components/layout';
import { useNavigation } from "psims/react/providers/router";
import useFsspCommitmentAPI from './use-fssp-commitment-api';
import useFsspCessations from './use-fssp-cessations'
import usePagination from "psims/react/blocks/pagination/use-pagination";
import Loading from "psims/react/components/loading";
import Notification from 'psims/react/components/notification';
import Button from 'psims/react/components/button';
import CompanyHeader from "psims/react/blocks/company-header";
import { H1 } from "psims/react/components/typography";
import Pagination from "psims/react/blocks/pagination/pagination";
import ResultFsspCommitment from "./fssp-commitment-result";

const FSSPCommitmentPage = () => {
  const vm = useVM();

  if (vm.viewMode === 'loading') {
		return (
			<BoxedDiv box={{ alignItems: 'center', flex: 'column', flexGrow: 1, justifyContent: 'center' }}>
				<Loading>Loading</Loading>
			</BoxedDiv>
		)
	}

  if (vm.viewMode === 'loaded') {
    return (
      <BoxedDiv box={{ alignSelf: 'stretch', flex: 'column', flexGrow: 1, justifySelf: 'center' }}>
				<CompanyHeader />

				<div className='container'>
					<H1>Fuel security services commitment</H1>
					
					<p>This page details the fuel security services period your company has committed to. This includes any adjustments made during the commitment period. Please contact the Department if you believe any of the details are incorrect.</p>
					
					<ResultFsspCommitment hasNoItems={vm.hasNoItems} fsspCommitmentItems={vm.fsspCommitments} fsspCessationsCtrl={vm.fsspCessationsCtrl} showFsspCessationsDialog={vm.showFsspCessationsDialog}></ResultFsspCommitment>

					<Pagination paginationCtrl={vm.paginationCtrl} />
				</div>
			</BoxedDiv>
    );
  }

  return (
		<div className='container'>
			<BoxedDiv box={{ alignItems: 'center', flex: 'column', marginV: 4 }}>
				<BoxedDiv box={{ alignItems: 'center', flex: 'column', width: '600px' }}>
					<Notification align='center' kind='warning' >
						<p>
							There was a problem loading FSSP commitment, please navigate back to your <Button $kind='text' label='Try again' onClick={vm.handleTryAgain}>'Home' page</Button> and try again.
						</p>
					</Notification>
				</BoxedDiv>
			</BoxedDiv>
		</div>
	)
}

const PAGE_SIZE_OPTIONS = [
	{ value: 10 },
	{ value: 25 },
	{ value: 50 },
	{ label: 'Show all', value: Infinity },
]

function useVM(){
	const apiCtrl = useFsspCommitmentAPI();
	const { loadStatus } = apiCtrl;
	const navigation = useNavigation();

  const handleTryAgain = useCallback(() => {
		navigation.goToHomePage();
	}, [navigation]);

  const paginationCtrl = usePagination({
		items: apiCtrl.fsspCommitments,
		initialPageSize: 50,
		pageSizeOptions: PAGE_SIZE_OPTIONS,
		resultsLabel: 'FSSP commitments'
	});

  const viewMode = useMemo(() => {
		if (loadStatus === 'error') {
			return 'error';
		}

		if (loadStatus === 'fulfilled') {
			return 'loaded';
		}

		return 'loading';
	}, [loadStatus]);

  const hasNoItems = useMemo(() => {
		return viewMode === 'loaded' && apiCtrl.fsspCommitments.length === 0;
	}, [viewMode, apiCtrl.fsspCommitments.length]);

	const fsspCessationsCtrl = useFsspCessations();

	const showFsspCessationsDialog = fsspCessationsCtrl.selectedFsspCommitment !== null;

  return {
		paginationCtrl,
		handleTryAgain,
		hasNoItems,
		viewMode,
		fsspCessationsCtrl,
		showFsspCessationsDialog,
		fsspCommitments: paginationCtrl.pageData
	};
}

export default FSSPCommitmentPage;

