import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { ValidationResult } from "./importer-annual-activities-validation";
import { ValidationAlertResult } from "./importer-annual-activities-validation-alerts";
import { MAX_VOLUME } from "./shared";

export function getInlineValidationMessage(validationResult: ValidationResult | null) {
  if (validationResult == null) {
    return undefined;
  }

  switch (validationResult.code) {
    case 'invalid_integer':
    case 'invalid_integer_range':
      return `Must be ${validationResult.min === 0 ? 'a positive' : 'an'} integer (zero is permitted) with a maximum value of ${validationResult.min < 0 ? '+/-' : ''}${localeNumberWithFixed(validationResult.max)}`;

    case 'invalid_required':
      return 'A value is required for this field';
  }
}

export function getInlineInfoMessage(validationAlertResult: ValidationAlertResult | null) {
  if (validationAlertResult == null) {
    return undefined;
  }

  switch (validationAlertResult.code) {
    case 'MSOImportVolumeByOthersOutOfRange':
    case 'MSOImportVolumeOnBehalfOutOfRange':
      return `This value is greater than ${localeNumberWithFixed(MAX_VOLUME)}. Please enter a reason in the comments field to confirm your data is correct`;

    case 'MSOTotalImportVolumeNegative':
      return 'The total annual volume calculated is a negative value. Please enter a reason in the comments field to confirm your data is correct.';
  }
}