import React, { useCallback, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { H2 } from 'psims/react/components/typography';
import { Table } from 'psims/react/components/table';
import { ActionCellTD, ActionCellTH, ColumnHeader, TD, TH } from '../../shared/data-table-components';
import Input from '../../shared/input';
import { UseActivities } from './use-activities';
import { TooltipHelp } from '../../shared/tooltip-help';
import Text from 'psims/react/components/text';
import { DefActivityField } from 'psims/models/submission-types/def/def-activity';
import { ValidationResult } from './validate-activity';
import { UseFocusedField } from 'psims/react/util/use-focused-field';
import { FocusField, isDeleteFocusField, isDraftDataFocusField, isPersistedDataFocusField } from '../types';
import { ValidationAlertResult } from './activity-validation-alerts';
import { getInlineInfoMessage, getInlineValidationMessage } from './activity-inline-messages';
import FloatingMessage from 'psims/react/components/floating-message';
import { INVALID_DEF_PRODUCT_EXPIRED } from 'psims/constants/validation-messages';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import { IconButton } from 'psims/react/components/button';
import { isDEFProduct, isTGUProduct } from './util';
import useIsAutoFocused from 'psims/react/util/use-is-autofocused';
import useFocusable from 'psims/react/util/use-focusable';

interface ActivitiesProps {
  ctrl: UseActivities;
  focusFieldCtrl: UseFocusedField<FocusField>;
  forceErrors: boolean;
  isDisabled: boolean;
}

const Activities = ({ctrl, focusFieldCtrl, forceErrors, isDisabled}: ActivitiesProps) => {
  return (
    <BoxedDiv box={{flex: 'column'}}>
      <H2>End-of-month totals</H2>
    
      <Table caption='Diesel exhaust fluid report data'>
        <thead>
          <tr>
            <ColumnHeader
              label='Product'
              $width='300px'
            />

            <ColumnHeader
              label='Units'
              $width='100px'
            />

            <ColumnHeader
              label='Used in blending'
              helpId='help_used_in_blending'
              Help='Please report the total quantity of TGU used in blending during the month'
              $width='240px'
            />

            <ColumnHeader
              label='Derived from blending'
              helpId='help_derived_from_blending'
              Help='Please report the total quantity of DEF derived from blending during the month'
              $width='240px'
            />

            <ColumnHeader
              label='Derived from other production'
              helpId='help_derived_from_other'
              Help='Please report the total quantity of DEF derived from other production during the month'
              $width='240px'
            />

            <ColumnHeader
              label='Sales'
              helpId='help_sales'
              Help='Please report the total sales quantity for each product during the month'
              $width='240px'
            />

            <ColumnHeader
              label='Closing stocks'
              helpId='help_closing_stocks'
              Help={<>
                <p>Please report the total quantity that you own of each product held at a production or storage facility in Australia or in transit in Australia, at the end of the month.</p>
                <p><strong>Do not</strong> report any stocks that you hold or own outside Australia.</p>
              </>}
              $width='240px'
            />

            {
              ctrl.hasExpiredData ?
              <ActionCellTH><VisuallyHidden>Actions</VisuallyHidden></ActionCellTH> :
              null
            }
          </tr>
        </thead>

        <tbody>
          {
            ctrl.data.map((data, index) => (
              <ProductRow
                key={index}
                dataView={data}
                firstField={ctrl.firstField}
                focusFieldCtrl={focusFieldCtrl}
                forceErrors={forceErrors}
                hasActionColumn={ctrl.hasExpiredData}
                isDisabled={isDisabled}
                rowIndex={index}
                onChange={ctrl.update}
                onDelete={ctrl.deleteActivityRequest}
              />
            ))
          }
        </tbody>

      </Table>
    </BoxedDiv>
  );
}

interface ProductRowProps {
  dataView: UseActivities['data'][number];
  firstField: UseActivities['firstField'];
  focusFieldCtrl: UseFocusedField<FocusField>;
  forceErrors: boolean;
  hasActionColumn: boolean;
  isDisabled: boolean;
  rowIndex: number;
  onChange: UseActivities['update'];
  onDelete: UseActivities['deleteActivityRequest'];
}

const ProductRow = ({dataView: {data, refData, validation, validationAlerts}, firstField, focusFieldCtrl, forceErrors, hasActionColumn, isDisabled, rowIndex, onChange, onDelete}: ProductRowProps) => {
  const [deleteRef, setDeleteRef] = useState<HTMLElement | null>(null);

  const shouldAutoFocus = useIsAutoFocused(
    rowIndex === 0 && refData?.productStatus !== 'inactive_with_data'
  );

  const rowFocusedField = useMemo(() => {
    return focusFieldCtrl.focusedField?.kind === 'data' && (
        (isDraftDataFocusField(focusFieldCtrl.focusedField) && focusFieldCtrl.focusedField._id === data._id) ||
        (isPersistedDataFocusField(focusFieldCtrl.focusedField) && focusFieldCtrl.focusedField.id === data.id)
      ) ? focusFieldCtrl.focusedField : null;
  }, [data._id, data.id, focusFieldCtrl.focusedField]);

  const focusedField = useMemo(() => (
    rowFocusedField == null ? (
      shouldAutoFocus ? firstField : null
    ) : rowFocusedField.field
  ), [firstField, rowFocusedField, shouldAutoFocus]);

  if (refData == null) {
    return null;
  }

  return (
    <tr>
      <TH scope='row'>
          <BoxedSpan box={{alignItems: 'center', flex: 'row',}}>
              <Text title={refData.name}>{refData.name}</Text>
              {
                (!isDisabled && refData?.productStatus === 'inactive_with_data') ? <BoxedSpan box={{marginBottom: -0.5, marginLeft: 1}}>
                  <FloatingMessage
                      content={INVALID_DEF_PRODUCT_EXPIRED}
                      kind="warning"
                      role="alert"
                  />
                </BoxedSpan> : (
                  refData.description &&
                  <TooltipHelp
                    Help={<ReactMarkdown>{refData.description}</ReactMarkdown>}
                  />
                )
              }
          </BoxedSpan>
      </TH>

      <TD>{refData.unitOfMeasurement}</TD>

      <TD>
        {
          !isDEFProduct(refData) &&
          <Input
              kind='number'
              disabled={isDisabled}
              error={inlineValidationMessage('usedInBlending', validation)}
              forceError={forceErrors}
              info={validationAlerts?.find(va => va.field === 'usedInBlending')?.code}
              label='Used in blending'
              value={data.usedInBlending}
              onChange={v => onChange(data, 'usedInBlending', v == null ? null : v)}
              shouldFocus={focusedField === 'usedInBlending'}
          />
        }
      </TD>

      <TD>
        {
          !isTGUProduct(refData) &&
          <Input
              kind='number'
              disabled={isDisabled}
              error={inlineValidationMessage('derivedFromBlending', validation)}
              forceError={forceErrors}
              info={inlineInfoMessage('derivedFromBlending', validationAlerts)}
              label='Derived from blending'
              value={data.derivedFromBlending}
              onChange={v => onChange(data, 'derivedFromBlending', v == null ? null : v)}
              shouldFocus={focusedField === 'derivedFromBlending'}
          />
        }
      </TD>

      <TD>
        {
          !isTGUProduct(refData) &&
          <Input
              kind='number'
              disabled={isDisabled}
              error={inlineValidationMessage('derivedFromOtherProduction', validation)}
              forceError={forceErrors}
              info={inlineInfoMessage('derivedFromOtherProduction', validationAlerts)}
              label='Derived from other production'
              value={data.derivedFromOtherProduction}
              onChange={v => onChange(data, 'derivedFromOtherProduction', v == null ? null : v)}
              shouldFocus={focusedField === 'derivedFromOtherProduction'}
          />
        }
      </TD>

      <TD>
        <Input
            kind='number'
            disabled={isDisabled}
            error={inlineValidationMessage('sales', validation)}
            forceError={forceErrors}
            info={inlineInfoMessage('sales', validationAlerts)}
            label='Sales to business'
            value={data.sales}
            onChange={v => onChange(data, 'sales', v == null ? null : v)}
            shouldFocus={focusedField === 'sales'}
        />
      </TD>

      <TD>
        <Input
            kind='number'
            disabled={isDisabled}
            error={inlineValidationMessage('closingStocks', validation)}
            forceError={forceErrors}
            info={inlineInfoMessage('closingStocks', validationAlerts)}
            label='closingStocks to business'
            value={data.closingStocks}
            onChange={v => onChange(data, 'closingStocks', v == null ? null : v)}
            shouldFocus={focusedField === 'closingStocks'}
        />
      </TD>
    
      {
        hasActionColumn ?
          <ActionCellTD>
          {
            refData.productStatus === 'inactive_with_data' ?
              <BoxedDiv box={{ alignItems: 'center', flex: 'row', marginLeft: 1 }}>
                <DeleteRowButton
                  id={data.id}
                  focusedField={focusFieldCtrl.focusedField}
                  onClick={() => {
                    if (data.id != null) {
                      onDelete(data.id, deleteRef);
                    }
                  }}
                  ref={setDeleteRef}
                />
              </BoxedDiv>
             :
            null
          }
        </ActionCellTD> :
        null
      }
    </tr>
  )
}

interface DeleteRowProps {
    focusedField: FocusField | null;
    onClick: () => any;
    id: number | undefined;
}

const DeleteRowButton = React.forwardRef(({focusedField, onClick, id}: DeleteRowProps, ref) => {
    const focusable = useFocusable({
        setFocused: (
            isDeleteFocusField(focusedField) &&
            focusedField.id === id
        ),
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <IconButton
            color='dark-grey'
            icon='trash'
            label='Delete row for product'
            onClick={onClick}
            ref={setRef}
            size='sm'
        />
    );
});


export default Activities;

function inlineValidationMessage(field: DefActivityField, validationResults: Array<ValidationResult>) {
  const validationResult = validationResults.find(vr => vr.field === field);

  return getInlineValidationMessage(validationResult);
}

function inlineInfoMessage(field: DefActivityField, validationAlertResults: Array<ValidationAlertResult>) {
  const validationAlertResult = validationAlertResults.find(va => va.field === field) || null;

  return getInlineInfoMessage(validationAlertResult);
}
