import { useMemo } from "react";

import useFocusedField from "psims/react/util/use-focused-field";
import useConfirm from "../../shared/use-confirm";
import useMsoAnnualActivityRefData from "../shared/use-mso-annual-activity-ref-data";
import useStorageSites from "../shared/storage-sites/use-storage-sites";
import useImporterAnnualActivities from "./annual-activities/use-importer-annual-activities";
import { UseImporterAnnualActivityAPI } from "./use-importer-annual-activity-api";
import { FocusField } from "../shared/types";
import useImporterAnnualActivityNotifications from "./use-importer-annual-activity-notifications";
import useImporterAnnualActivityProgress from "./use-importer-annual-activity-progress";
import useViewMode, { ViewMode } from "../../shared/use-view-mode";
import useImporterAnnualActivityNavigation from "./use-importer-annual-activity-navigation";
import useSubmit from '../../shared/use-submit';
import useImporterAnnualActivityControls from "./use-importer-annual-activity-controls";
import useMsoComment from "../shared/mso-comment/use-mso-comment";
import { isMsoComment } from "psims/models/submission-types/mso/mso-comment";
import useServiceMessages from "../../shared/use-service-messages";
import { getRecordResults } from "psims/models/submission-types/mso/annual-activity/importer/mso-annual-activity-importer-submission";
import useImporterImports from "./imports/use-importer-imports";
import useClearData from 'psims/react/pages/primary-pages/data-submissions/shared/use-clear-data';

interface UseImporterAnnualActivityProps {
  apiCtrl: PopulatedProps<UseImporterAnnualActivityAPI, 'submission'>;
}

function useImporterAnnualActivity({apiCtrl}: UseImporterAnnualActivityProps) {
  const viewMode = useViewMode(apiCtrl.submission);

  const refData = useMsoAnnualActivityRefData({dataSubmission: apiCtrl.submission.dataSubmission});

  const progressCtrl = useImporterAnnualActivityProgress({apiCtrl, viewMode});

  const focusFieldCtrl = useFocusedField<FocusField>();

  const deleteCtrl = useConfirm();

  const storageSitesCtrl = useStorageSites({
    dataSubmissionId: apiCtrl.submission.dataSubmission.id,
    focusFieldCtrl,
    initialSites: apiCtrl.submission.storageSites,
    progressCtrl,
    refData,
  });

  const importsCtrl = useImporterImports({
    dataSubmissionId: apiCtrl.submission.dataSubmission.id,
    focusFieldCtrl,
    initialData: apiCtrl.submission.imports,
    progressCtrl,
    refData,
  });

  const annualActivitiesCtrl = useImporterAnnualActivities({
    dataSubmissionId: apiCtrl.submission.dataSubmission.id,
    focusFieldCtrl,
    imports: importsCtrl.updateImports.map(i => i.data),
    initialAnnualActivities: apiCtrl.submission.annualActivities,
    progressCtrl,
    refData,
  });

  const commentsCtrl = useMsoComment({
    dataSubmissionId: apiCtrl.submission.dataSubmission.id,
    focusFieldCtrl,
    initialComment: isMsoComment(apiCtrl.submission.msoComment) ? apiCtrl.submission.msoComment : null,
    isRequired: annualActivitiesCtrl.requiresComments,
    progressCtrl,
  });

  const serviceMessages = useServiceMessages({
    response: apiCtrl.updateResponse,
    responseStatus: apiCtrl.loadStatus,
    serverError: apiCtrl.updateError,
    extractRecordResults: getRecordResults
  });

  const anyInvalid = useMemo(() => {
    return (
      annualActivitiesCtrl.anyInvalid ||
      importsCtrl.anyInvalid ||
      storageSitesCtrl.anyInvalid
    );
  }, [annualActivitiesCtrl, importsCtrl, storageSitesCtrl]);

  const submitCtrl = useSubmit({
    apiCtrl,
    dataSubmission: apiCtrl.submission.dataSubmission,
    focusFieldCtrl,
    hasExternalErrors: anyInvalid,
  });

  const pageChanges = useMemo(() => {
    return [
      // data page
      [
        storageSitesCtrl.hasChanges,
        annualActivitiesCtrl.hasChanges,
        importsCtrl.hasChanges,
        commentsCtrl.hasChanges
      ],
      // submit page
      [submitCtrl.hasUnsavedChanges]
    ]
  }, [
    annualActivitiesCtrl.hasChanges,
    commentsCtrl.hasChanges,
    importsCtrl.hasChanges,
    storageSitesCtrl.hasChanges,
    submitCtrl.hasUnsavedChanges,
  ]);

  const navCtrl = useImporterAnnualActivityNavigation({
    annualActivitiesCtrl, apiCtrl, commentsCtrl, importsCtrl,
    pageChanges, progressCtrl, storageSitesCtrl, submitCtrl, viewMode
  });

  const notificationsCtrl = useImporterAnnualActivityNotifications({
    annualActivitiesCtrl,
    commentsCtrl,
    importsCtrl,
    navCtrl,
    progressCtrl,
    serviceMessages,
    storageSitesCtrl,
    submitCtrl,
  });

  const controlsCtrl = useImporterAnnualActivityControls({apiCtrl, navCtrl, progressCtrl, viewMode});

  const clearDataCtrl = useClearData({
      onConfirm: () => {
          apiCtrl.clearAllData();
          submitCtrl.updateDeclaration(false);
      },
      submission: apiCtrl.submission?.dataSubmission,
  });

  return useMemo(() => ({
    annualActivitiesCtrl,
    apiCtrl,
    clearDataCtrl,
    commentsCtrl,
    controlsCtrl,
    deleteCtrl,
    focusFieldCtrl,
    isDisabled: viewMode === 'view',
    importsCtrl,
    navCtrl,
    notificationsCtrl,
    progressCtrl,
    refData,
    submitCtrl,
    storageSitesCtrl,
    viewMode: viewMode as ViewMode,
  }), [
    annualActivitiesCtrl,
    apiCtrl,
    clearDataCtrl,
    commentsCtrl,
    controlsCtrl,
    deleteCtrl,
    focusFieldCtrl,
    importsCtrl,
    navCtrl,
    notificationsCtrl,
    progressCtrl,
    refData,
    submitCtrl,
    storageSitesCtrl,
    viewMode,
  ]);
}
export default useImporterAnnualActivity
