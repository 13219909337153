import { MsoVM, UpdateMsoVM } from "psims/gen/xapi-client";
import { isWithUpdateable, WithUpdatable } from "psims/models/api/update";

type MaybeMso = MsoVM;

export type Mso = WithUpdatable<
    PopulatedProps<MaybeMso, 'dataSubmissionId' | 'minimumStockObligation' | 'msoOrganisationSettingId'>
>;

export function isMso(maybe?: MaybeMso | null): maybe is Mso {
    return (
        maybe != null &&
        isWithUpdateable<MaybeMso>(maybe) &&
        maybe.dataSubmissionId != null &&
        maybe.msoOrganisationSettingId != null &&
        maybe.minimumStockObligation != null
    );
}

export type MaybeUpdateMso = UpdateMsoVM;

export type UpdateMso = PopulatedProps<MaybeUpdateMso, "dataSubmissionId" | "msoOrganisationSettingId">;

export type UpdateMsoField = Exclude<keyof UpdateMso, 'dataSubmissionId' | 'msoOrganisationSettingId' | 'minimumStockObligation' | 'stockholdingOwnerships' | 'id' | 'recordAction' | 'concurrencyToken' | 'msoProductId'>;
