import { useEffect, useRef, useState } from "react";

interface UseSectionFocusoutProps {
  callbackDelay?: number;
  onFocusout?: (e: FocusEvent) => any;
}

/**
 * This controller is used to invoke a callback when a target DOM tree loses focus.
 * It is implemented by listening for both focusin and focusout events on the target,
 * using a setTimeout to ensure the callback is only called when the target sub-tree
 * loses focus.
 * 
 * In short the event handlers do the following:
 * 
 * - focusin: cancel the pending setTimeout ID, if it has been queued (i.e. the ID is not null)
 * 
 * - focusout: queue the callback using setTimeout, keeping a reference to the timeout ID
 * 
 * This leverages the fact that focusin/out bubbles such that when focus moves between 2
 * elements within the target, a focusout event will fire, immediately followed by a focusin event.
 * 
 * When focus changes to an element outside the target, only a focusout event fires.
 */
function useSectionFocusout({callbackDelay, onFocusout}: UseSectionFocusoutProps) {
  const [el, setEl] = useState<HTMLElement | null>(null);
  
  const [delay] = useState(callbackDelay == null ? 10 : callbackDelay);

  const timer = useRef<number | null>(null);

  useEffect(() => {
    if (el == null || onFocusout == null) {
      return;
    }

    function handleFocusin(e: FocusEvent) {
      if (timer.current != null) {
        window.clearTimeout(timer.current);
        timer.current = null;
      }
    }

    function handleFocusout(e: FocusEvent) {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }

      timer.current = window.setTimeout(() => {
        onFocusout != null && onFocusout(e);
      }, delay);
    }

    el.addEventListener('focusin', handleFocusin);
    el.addEventListener('focusout', handleFocusout);

    return () => {
      el.removeEventListener('focusin', handleFocusin);
      el.removeEventListener('focusout', handleFocusout);
    }

  }, [delay, el, onFocusout]);

  return {
    setEl
  };
}

export default useSectionFocusout

export type UseSectionFocusout = ReturnType<typeof useSectionFocusout>;
