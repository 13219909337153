import React from 'react';
import BaseSVG, { SVGProps } from './_base';

const ArrowUp = (props: SVGProps) => {
	return (
		<BaseSVG name='arrow-up' viewBox='0 0 91.118 96.273' {...props}>
  			<path d="M36.538,29.045,11.487,54.035,0,42.547,45.564,0,91.118,42.547,79.556,54.035,54.59,29.054V96.273H36.538Z" fill="currentColor"/>
		</BaseSVG>
	);
};

export default ArrowUp;
