import CompanyHeader from "psims/react/blocks/company-header";
import { BoxedDiv } from "psims/react/components/layout";
import { H1 } from "psims/react/components/typography";
import { useNavigation } from "psims/react/providers/router";
import { useUser } from "psims/react/providers/user";
import { useCallback, useMemo } from "react";
import ReportingHistoryFilter from "./reporting-history-filter";
import ResultReportingHistory from "./reporting-history-result";
import useReportingHistoryFilter from "./use-reporting-history-filter";
import useReportingHistoryAPI from "./use-reporting-history-api";
import useReportingHistoryResult from "./use-reporting-history-result";
import Loading from "psims/react/components/loading";
import Notification from 'psims/react/components/notification';
import Button from 'psims/react/components/button';
import usePagination from "psims/react/blocks/pagination/use-pagination";
import Pagination from "psims/react/blocks/pagination/pagination";

const ReportingHistoryPage = () => {
	const vm = useVM();

	if (vm.viewMode === 'loading') {
		return (
			<BoxedDiv box={{ alignItems: 'center', flex: 'column', flexGrow: 1, justifyContent: 'center' }}>
				<Loading>Loading</Loading>
			</BoxedDiv>
		)
	}

	if (vm.viewMode === 'loaded') {
		return (
			<BoxedDiv box={{ alignSelf: 'stretch', flex: 'column', flexGrow: 1, justifySelf: 'center' }}>
				<CompanyHeader />

				<div className='container'>
					<H1>Reporting history</H1>

					<p>Past data submissions your company has submitted or was obligated to submit to the department can be searched on this page.  Please contact the department if you believe any of the details in a data submission are incorrect.</p>

					{
						vm.hasNoItems ?

						null :

						<BoxedDiv box={{ alignItems: 'flex-end', flex: 'column', marginBottom: 1 }}>
							<BoxedDiv box={{ alignSelf: 'stretch', marginTop: 2.5 }}>
								<ReportingHistoryFilter ctrl={vm.filterReportingHistoryCtrl}></ReportingHistoryFilter>
							</BoxedDiv>
						</BoxedDiv>
					}

					<ResultReportingHistory hasNoItems={vm.hasNoItems} reportingHistoryItems={vm.reportingHistories}></ResultReportingHistory>

					<Pagination paginationCtrl={vm.paginationCtrl} />
				</div>
			</BoxedDiv>
		);
	}

	return (
		<div className='container'>
			<BoxedDiv box={{ alignItems: 'center', flex: 'column', marginV: 4 }}>
				<BoxedDiv box={{ alignItems: 'center', flex: 'column', width: '600px' }}>
					<Notification align='center' kind='warning' >
						<p>
							There was a problem loading Reporting history, please navigate back to your <Button $kind='text' label='Try again' onClick={vm.handleTryAgain}>'Home' page</Button> and try again.
						</p>
					</Notification>
				</BoxedDiv>
			</BoxedDiv>
		</div>
	)
};

const PAGE_SIZE_OPTIONS = [
	{ value: 10 },
	{ value: 25 },
	{ value: 50 },
	{ label: 'Show all', value: Infinity },
]

function useVM() {
	const { user } = useUser();
	const apiCtrl = useReportingHistoryAPI();
	const { loadStatus } = apiCtrl;
	const navigation = useNavigation();

	const handleTryAgain = useCallback(() => {
		navigation.goToHomePage();
	}, [navigation]);

	const filterReportingHistoryCtrl = useReportingHistoryFilter({
		reportingEntities: apiCtrl.reportingEntities,
		reportingHistoryItems: apiCtrl.reportingHistories
	});

	const reportingHistoryResultCtrl = useReportingHistoryResult({
		query: filterReportingHistoryCtrl.query,
		reportingHistoryItems: apiCtrl.reportingHistories
	});

	const paginationCtrl = usePagination({
		items: reportingHistoryResultCtrl,
		initialPageSize: 50,
		pageSizeOptions: PAGE_SIZE_OPTIONS,
		resultsLabel: 'Reporting history result'
	});

	const viewMode = useMemo(() => {
		if (loadStatus === 'error') {
			return 'error';
		}

		if (loadStatus === 'fulfilled') {
			return 'loaded';
		}

		return 'loading';
	}, [loadStatus]);

	const hasNoItems = useMemo(() => {
		return viewMode === 'loaded' && apiCtrl.reportingHistories.length === 0;
	}, [viewMode, apiCtrl.reportingHistories.length]);

	return {
		filterReportingHistoryCtrl,
		paginationCtrl,
		handleTryAgain,
		hasNoItems,
		user,
		viewMode,
		reportingHistories: paginationCtrl.pageData,
	};
}

export default ReportingHistoryPage;
