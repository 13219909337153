import { ProductionProductGroupDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

export type ProductionProductGroup = PopulatedProps<
    ProductionProductGroupDTO,
    'displayOrder' | 'effectiveFrom' | 'id' | 'name' | 'productionTypeId' | 'productionTypeName'
>;

export function isProductionProductGroup(maybe?: unknown): maybe is ProductionProductGroup {
    const asProductionProductGroup = maybe as ProductionProductGroup;
    return (
        asProductionProductGroup != null &&
        asProductionProductGroup.displayOrder != null &&
        asProductionProductGroup.effectiveFrom != null &&
        asProductionProductGroup.id != null &&
        asProductionProductGroup.name != null &&
        asProductionProductGroup.productionTypeId != null &&
        asProductionProductGroup.productionTypeName != null
    );
}

export function assertProductionProductGroup(maybe?: unknown): asserts maybe is ProductionProductGroup {
    const asProductionProductGroup = maybe as ProductionProductGroup;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asProductionProductGroup, hint('productionProductGroup'));
    assertNotEmpty(asProductionProductGroup.displayOrder, hint('productionProductGroup.displayOrder'));
    assertNotEmpty(asProductionProductGroup.effectiveFrom, hint('productionProductGroup.effectiveFrom'));
    assertNotEmpty(asProductionProductGroup.id, hint('productionProductGroup.id'));
    assertNotEmpty(asProductionProductGroup.name, hint('productionProductGroup.name'));
    assertNotEmpty(asProductionProductGroup.productionTypeId, hint('productionProductGroup.productionTypeId'));
    assertNotEmpty(asProductionProductGroup.productionTypeName, hint('productionProductGroup.productionTypeName'));
}
