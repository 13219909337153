import React from 'react';

import { useUser } from 'psims/react/providers/user';
import PageHeader, { PageHeaderDetailsTable, PageHeaderTitle } from 'psims/react/components/page-header';
import { formatAbn } from 'psims/lib/formatters/abn';
import { formatArbn } from 'psims/lib/formatters/arbn';
import { formatAcn } from 'psims/lib/formatters/acn';

interface CompanyHeaderProps {}

const CompanyHeader = (props: CompanyHeaderProps) => {
    const vm = useVM();

    return (
        <PageHeader>
            <PageHeaderTitle>{vm.user?.organisation?.name}</PageHeaderTitle>

            <PageHeaderDetailsTable>

                {
                    vm.user?.organisation?.abn &&
                    <tr>
                        <th><strong>ABN: </strong></th>
                        <td>{formatAbn(vm.user.organisation.abn)}</td>
                    </tr>
                }
                {
                    vm.user?.organisation?.acn &&
                    <tr>
                        <th><strong>ACN: </strong></th>
                        <td>{formatAcn(vm.user.organisation.acn)}</td>
                    </tr>
                }
                {
                    vm.user?.organisation?.arbn &&
                    <tr>
                        <th><strong>ARBN: </strong></th>
                        <td>{formatArbn(vm.user.organisation.arbn)}</td>
                    </tr>
                }
            </PageHeaderDetailsTable>
        </PageHeader>
    )
}

function useVM() {
	const {user} = useUser();

	return {
		user,
	};
}

export default CompanyHeader;
