import React from 'react';

import BaseSVG, { SVGProps } from './_base';

const FilePDF = (props: SVGProps) => {
	return (
		<BaseSVG name='file-pdf' viewBox='0 0 384 512' {...props}>
			<path fill='currentColor' d="M224 0H0V512H384V160H224V0zm32 0V128H384L256 0zM64 224H88c30.9 0 56 25.1 56 56s-25.1 56-56 56H80v32 16H48V368 320 240 224H64zm24 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H80v48h8zm72-80h16 24c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H176 160V368 240 224zm32 128h8c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16h-8v96zm96-128h48 16v32H336 304v32h32 16v32H336 304v48 16H272V368 304 240 224h16z"/>
		</BaseSVG>
	);
};

export default FilePDF;
