/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefSubmissionVMApiResponse } from '../models/DefSubmissionVMApiResponse';
import type { UpdateDataSubmissionVM } from '../models/UpdateDataSubmissionVM';
import type { UpdateDefSubmissionVM } from '../models/UpdateDefSubmissionVM';
import { request as __request } from '../core/request';

export class DefService {

    /**
     * @returns DefSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async getDefSubmission({
id,
}: {
id?: number,
}): Promise<DefSubmissionVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/diesel-exhaust-fluid`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns DefSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateDef({
requestBody,
}: {
requestBody?: UpdateDefSubmissionVM,
}): Promise<DefSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/diesel-exhaust-fluid/Update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns DefSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateDefDataSubmission({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<DefSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/diesel-exhaust-fluid/Update-DataSubmission`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns DefSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async submitDef({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<DefSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/diesel-exhaust-fluid/Submit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns DefSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async clearAllDef({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<DefSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/diesel-exhaust-fluid/Clear-All`,
            body: requestBody,
        });
        return result.body;
    }

}