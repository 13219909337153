import produce from "immer";
import { compare } from "psims/lib/compare";
import { encodeEscapeChars } from "psims/lib/validation/string";
import { recordActionAsEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { MsoComment, UpdateMsoComment } from "psims/models/submission-types/mso/mso-comment";
import { UseFocusedField } from "psims/react/util/use-focused-field";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FocusField } from "../types";
import { getInlineMessage } from "./mso-comment-inline-messages";
import { getNotificationMessage } from "./mso-comment-notifications";
import { validateMsoComment } from "./mso-comment-validation";

interface ProgressController {
  currentStep: {
    kind: 'data' | 'submit';
  };
  goToStep: (index: number) => any;
}

interface UseMsoCommentProps {
  dataSubmissionId: number;
  focusFieldCtrl: UseFocusedField<FocusField>;
  initialComment: MsoComment | null;
  isRequired: boolean;
  progressCtrl: ProgressController;
}

function useMsoComment({dataSubmissionId, focusFieldCtrl, initialComment, isRequired, progressCtrl}: UseMsoCommentProps) {
  const [data, setData] = useState(toUpdateMsoComment(initialComment, dataSubmissionId));

  const updateComment = useCallback((val: string) => {
    setData(prev => produce(prev, draft => {
      if (val === '') {
        if (prev.id) {
          draft.recordAction = recordActionAsEnum('Delete');
        }
      } else {
        draft.recordAction = prev.id ? recordActionAsEnum('Update') : recordActionAsEnum('Create');
      }

      draft.dataSubmissionId = dataSubmissionId;
      draft.comments = val;
    }));
  }, [dataSubmissionId]);

  const validations = useMemo(() => {
    return validateMsoComment(data, isRequired)
      .map(v => ({
        inline: getInlineMessage(v),
        notification: {parts: getNotificationMessage(() => focusFieldCtrl.setFocusedField({kind: 'comments'}), v)}
      }));
  }, [data, focusFieldCtrl, isRequired]);

  const hasChanges = useMemo(
    () => !compare(data.comments, initialComment?.comments, true),
    [data.comments, initialComment]
  );

  const isFocused = useMemo(() => {
    return focusFieldCtrl.focusedField?.kind === 'comments';
  }, [focusFieldCtrl.focusedField]);

  const requestPayload = useMemo(() => {
    if (
      (recordActionFromEnum(data.recordAction) === 'Create' || recordActionFromEnum(data.recordAction) === 'Update') &&
      (data.comments != null)
    ) {
      return {
        ...data,
        comments: encodeEscapeChars(data.comments.trim()),
      }
    }
  }, [data]);

  useEffect(() => {
    setData(toUpdateMsoComment(initialComment, dataSubmissionId));
  }, [dataSubmissionId, initialComment]);

  // Reset on page change
  useEffect(() => {
    if (progressCtrl.currentStep.kind !== 'data') {
      setData(toUpdateMsoComment(initialComment, dataSubmissionId));
    }
  }, [dataSubmissionId, initialComment, progressCtrl]);

  return {
    data,
    hasChanges,
    isFocused,
    requestPayload,
    updateComment,
    validations,
  }
}

function toUpdateMsoComment(msoComment: MsoComment | null, dataSubmissionId: number): UpdateMsoComment {
  if (msoComment == null) {
    return {
      dataSubmissionId,
      comments: '',
    }
  }

  const {recordResult, ...data} = msoComment;

  return data;
}

export default useMsoComment

export type UseMsoComment = ReturnType<typeof useMsoComment>;
