import { useMemo } from "react";

import { ButtonState } from "psims/react/components/button/button";
import { UseRefineryAPI } from "./use-refinery-api";
import { UseRefineryForm } from "./use-refinery-form";
import { UseRefineryProgress } from "./use-refinery-progress";
import { UseRefinerySave } from "./use-refinery-save";
import { UseRefinerySubmit } from "./use-refinery-submit";

interface UseRefineryControlsProps {
    apiCtrl: UseRefineryAPI;
    formCtrl: UseRefineryForm;
    progressCtrl: UseRefineryProgress;
    saveCtrl: UseRefinerySave,
    submitCtrl: UseRefinerySubmit;
}

function useRefineryControls({apiCtrl, formCtrl, progressCtrl, saveCtrl, submitCtrl}: UseRefineryControlsProps) {
    const {isBusy, viewMode} = apiCtrl;

    const primaryControl = useMemo(() => {
        const {currentStep: {index, kind}} = progressCtrl;
        const nextStep = index + 1;
        const label = viewMode === 'edit' ?
            (kind === 'data' ? 'Save and continue' : 'Submit') :
            'Continue';
        const action = viewMode === 'edit' ? (kind === 'data' ?
                    () => saveCtrl.saveAndGoToPage(nextStep) : 
                    submitCtrl.submit
                ) :
                () => progressCtrl.goToStep(nextStep);
        const state: ButtonState = isBusy ? 'loading' : 'normal';

        const isDisabled = isBusy;

        const isShown = viewMode === 'edit' ? true : (
            kind !== 'submit'
        )

        return {
            action,
            isDisabled,
            isShown,
            label,
            state,
        }
    }, [isBusy, progressCtrl, saveCtrl, submitCtrl, viewMode]);

    const secondaryControl = useMemo(() => {
        const {currentStep: {index}} = progressCtrl;
        const nextStep = index - 1;
        const action = viewMode === 'edit' ?
            () => saveCtrl.saveAndGoToPage(nextStep) :
            () => progressCtrl.goToStep(nextStep);
        const isDisabled = isBusy;
        const isShown = index > 0;
        const label = 'Previous';
        const state: ButtonState = isBusy ? 'loading' : 'normal';

        return {
            action,
            isDisabled,
            isShown,
            label,
            state,
        }
    }, [isBusy, progressCtrl, saveCtrl, viewMode]);


    return {
        primaryControl,
        secondaryControl,
    };
}

export default useRefineryControls
