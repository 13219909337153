import { MsoStockOwnershipTypeDto } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { prefixedThrower } from "psims/lib/exception";

const MSO_STOCK_OWNERSHIP_NAMES = ['Entitled', 'Reserved'] as const;

type MsoStockOwnershipTypeName = ArrayElement<typeof MSO_STOCK_OWNERSHIP_NAMES>;

export type MsoStockOwnershipType = PopulatedProps<MsoStockOwnershipTypeDto, 'id' | 'sortOrder' | 'name'>;

export function assertMsoStockOwnershipType(maybe?: unknown): asserts maybe is MsoStockOwnershipType    {
    const asMsoStockOwnershipType = maybe as MsoStockOwnershipType ;

    const err = prefixedThrower(`Failed to assert msoStockOwnershipType: ${JSON.stringify(maybe)}: `);

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asMsoStockOwnershipType , hint('msoStockOwnershipType'));
    assertNotEmpty(asMsoStockOwnershipType.id, hint('msoStockOwnershipType.id'));
    assertNotEmpty(asMsoStockOwnershipType.sortOrder, hint('msoStockOwnershipType.sortOrder'));
    if (!MSO_STOCK_OWNERSHIP_NAMES.includes(asMsoStockOwnershipType.name as MsoStockOwnershipTypeName)) err(`unknown type name: ${asMsoStockOwnershipType.name}`);
}
