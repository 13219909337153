import { createContext, PropsWithChildren, useContext } from "react";

import { useAPI } from ".";
import { buildAssertArrayOf } from "psims/lib/type-assertions";
import usePromise from "psims/react/util/use-promise";
import { FsspCommitmentItem, isFsspCommitmentItem } from "psims/models/fssp-commitment";

type FsspCommitmentsContext = {
    error: string | null;
    fsspCommitments: Array<FsspCommitmentItem> | null;
    status: ReturnType<typeof usePromise>['status'];
}

const context = createContext<FsspCommitmentsContext | null>(null);

const FsspCommitmentsProvider = ({children}: PropsWithChildren<{}>) => {
    const {api} = useAPI();
    const {data: fsspCommitments, error, status} = usePromise(
        () => api.getFsspCommitments(), x => x?.result, buildAssertArrayOf(isFsspCommitmentItem)
    );

    return <context.Provider
        value={{
            error,
            fsspCommitments,
            status,
        }}>
        {children}
    </context.Provider>
}

export default FsspCommitmentsProvider;

export function useFsspCommitments() {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error('useFsspCommitments must be used within FsspCommitmentsProvider');
    }

    return ctx;
}
