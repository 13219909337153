import { ProductionAreaStateDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

export type ProductionAreaState = PopulatedProps<ProductionAreaStateDTO, 'id' | 'name'>;

export function assertProductionAreaState(maybe?: unknown): asserts maybe is ProductionAreaState {
    const asProductionAreaState = maybe as ProductionAreaState;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asProductionAreaState, hint('productionAreaState'));
    assertNotEmpty(asProductionAreaState.id, hint('productionAreaState.id'));
    assertNotEmpty(asProductionAreaState.name, hint('productionAreaState.name'));
}
