import { DashboardVM } from "psims/gen/xapi-client";

export type Dashboard = DashboardVM;

export type DataSubmission = ArrayElement<Dashboard['dataSubmissions']> & {
	reportingFrequencyTypeName?: string;
};

export function isDashboard(maybeDashboard?: unknown): maybeDashboard is Dashboard {
	const asDashboard = maybeDashboard as Dashboard;

	return Array.isArray(asDashboard?.dataSubmissions)/* && isStatus(asUser?.status)*/;
}
