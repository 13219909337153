import { useCallback, useEffect, useState } from "react";
import produce from "immer";

import { MsoImporterSubmission } from "psims/models/submission-types/mso/mso-importer-submission";
import transformSubmissionToUpdateSubmission from "../shared/transform-submission-to-update-submission";
import { UpdateMsoStockholding, UpdateMsoStockholdingField } from "psims/models/submission-types/mso/mso-stockholding";
import { findWithIndex } from "psims/lib/collections";
import { recordActionAsEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { UpdateMsoStockOnBehalf, UpdateMsoStockOnBehalfField } from "psims/models/submission-types/mso/mso-stock-on-behalf";
import { UpdateMsoStockholdingOwnership, UpdateMsoStockholdingOwnershipField } from "psims/models/submission-types/mso/mso-stockholding-ownership";
import { UseMsoRefData } from "../shared/use-mso-ref-data";
import { UpdateMso } from "psims/models/submission-types/mso/mso";
import useMso from "../shared/use-mso";
import { OrganisationMsoSetting } from "psims/models/submission-types/mso/organisation-mso-setting";
import {mergeMsos} from "../shared/update-data-utils";
import { focusNext } from "psims/lib/focus-util";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { wrap } from "../shared/utils";

interface UseMsoImporterFormProps {
    refData: UseMsoRefData;
    submission: MsoImporterSubmission;
}

function useMsoImporterForm({refData, submission}: UseMsoImporterFormProps) {
    const [submissionUpdate, setSubmissionUpdate] = useState(transformSubmissionToUpdateSubmission(submission, refData.products));
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [changeSerial, setChangeSerial] = useState(0);
    const msoCtrl = useMso({
        dataSubmissionId: submission.dataSubmission.id,
        msoSettings: submission.msoSettings as Array<OrganisationMsoSetting>,
        msos: submissionUpdate.msos as Array<UpdateMso>,
        refData,
        stockholdings: submissionUpdate.stockholdings
    });

    const registerChange = useCallback(() => {
        setHasUnsavedChanges(true);
        setChangeSerial(prev => prev + 1);
    }, []);

    const addStockholdingOwnership = useCallback((msoProductId: number) => {
        setSubmissionUpdate(prev => produce(prev, draft => {
            const stockholdingIndex = prev.stockholdings.findIndex(s => s.msoProductId === msoProductId);
            const sh = prev.stockholdings[stockholdingIndex];
            if (sh == null) {
                // TODO: log
                return;
            }
            draft.stockholdings[stockholdingIndex].recordAction = recordActionFromEnum(sh.recordAction) === 'Create' ? sh.recordAction : recordActionAsEnum('Update');
            draft.stockholdings[stockholdingIndex].stockholdingOwnerships.push({
                recordAction: recordActionAsEnum('Create'),
            });
        }));
    }, []);

    const deleteStockholdingOwnership = useCallback((msoProductId: number, rowIndex: number, source: HTMLElement | null) => {
        setSubmissionUpdate(prev => produce(prev, draft => {
            const stockholdingIndex = prev.stockholdings.findIndex(s => s.msoProductId === msoProductId);
            const sh = prev.stockholdings[stockholdingIndex];
            if (sh == null) {
                // TODO: log
                return;
            }

            const o = sh.stockholdingOwnerships[rowIndex];

            if (o == null) {
                return;
            }

            // Ensure record action for parent
            draft.stockholdings[stockholdingIndex].recordAction = recordActionFromEnum(sh.recordAction) === 'Create' ? sh.recordAction : recordActionAsEnum('Update');

            draft.stockholdings[stockholdingIndex].stockholdingOwnerships[rowIndex] = {
                ...o,
                recordAction: recordActionAsEnum('Delete')
            } as UpdateMsoStockholdingOwnership;

            focusNext(source);
        }));

    }, []);

    const updateAcquitted = useCallback((msoProductId: number, amount: UpdateMso['acquittedAmount']) => {
        const setting = submission.msoSettings.find(s => s.msoProductId === msoProductId);

        if (setting == null || !setting.msoAcquitted) {
            return;
        }

        setSubmissionUpdate(prev => produce(prev, draft => {
            const [mso, index] = findWithIndex(prev.msos, m => m.msoOrganisationSettingId === setting.id);

            if (mso == null) {
                return;
            }

            draft.msos[index] = {
                ...mso,
                acquittedAmount: amount,
                recordAction: recordActionAsEnum(mso.id == null ? 'Create': 'Update')
            }
        }));

    }, [submission]);

    const updateComments = useCallback((val: string) => {
        setSubmissionUpdate(prev => produce(prev, draft => {
            if (val.length === 0) {
                draft.msoComment.comments = null;
                if (prev.msoComment.id != null) {
                    draft.msoComment.recordAction = recordActionAsEnum('Delete');
                }
                return;
            }

            draft.msoComment = prev.msoComment || {
                dataSubmissionId: submission.dataSubmission.id
            };
            draft.msoComment.comments = val;
            draft.msoComment.recordAction = recordActionAsEnum(draft.msoComment.id == null ? 'Create' : 'Update');
            draft.msoComment.dataSubmissionId = submission.dataSubmission.id;
        }));
    }, [submission.dataSubmission.id]);

    const updateStockholdingOwnership = useCallback(
        <TField extends UpdateMsoStockholdingOwnershipField>
        (msoProductId: number, ownershipIndex: number, field: TField, value: UpdateMsoStockholdingOwnership[TField]
        ) => {
        setSubmissionUpdate(prev => produce(prev, draft => {
            const stockholdingIndex = prev.stockholdings.findIndex(s => s.msoProductId === msoProductId);
            const sh = prev.stockholdings[stockholdingIndex];
            if (sh == null) {
                // TODO: log
                return;
            }

            const o = sh.stockholdingOwnerships[ownershipIndex];

            if (o == null) {
                return;
            }

            // Ensure record action for parent
            draft.stockholdings[stockholdingIndex].recordAction = recordActionFromEnum(sh.recordAction) === 'Create' ? sh.recordAction : recordActionAsEnum('Update');

            draft.stockholdings[stockholdingIndex].stockholdingOwnerships[ownershipIndex] = {
                ...o,
                [field]: value,
                recordAction: recordActionAsEnum(o.id == null ? 'Create': 'Update')
            } as UpdateMsoStockholdingOwnership;
        }));
    }, []);

    const updateStockholding = useCallback(<TField extends UpdateMsoStockholdingField>(msoProductId: number, field: TField, value: UpdateMsoStockholding[TField]) => {
        setSubmissionUpdate(prev => produce(prev, draft => {
            const [sh, index] = findWithIndex(prev.stockholdings, s => s.msoProductId === msoProductId);

            if (sh === null) {
                return;
            }

            draft.stockholdings[index] = {
                ...sh,
                [field]: value,
                recordAction: recordActionAsEnum(sh.id == null ? 'Create': 'Update')
            }
        }));
    }, []);

    const deleteStockholding = useCallback((msoProductId: number, source: HTMLElement | null) => {
        setSubmissionUpdate(prev => produce(prev, draft => {
            const stockholdingToDeleteIndex = prev.stockholdings.findIndex(s => s.msoProductId === msoProductId);

            if (stockholdingToDeleteIndex < 0) {
                return;
            }

            draft.stockholdings[stockholdingToDeleteIndex] = {
                ...prev.stockholdings[stockholdingToDeleteIndex],
                recordAction: recordActionAsEnum('Delete')
            } as UpdateMsoStockholding;

            focusNext(source);
        }));
    }, []);

    const addStockOnBehalf = useCallback((msoProductId: number) => {
        setSubmissionUpdate(prev => produce(prev, draft => {
            draft.stocksOnBehalf.push({
                dataSubmissionId: submission.dataSubmission.id,
                msoProductId,
                recordAction: recordActionAsEnum('Create'),
            })
        }));
    }, [submission.dataSubmission.id]);

    const deleteStockOnBehalf = useCallback((msoProductId: number, rowIndex: number, source: HTMLElement | null) => {
        setSubmissionUpdate(prev => produce(prev, draft => {
            const sobToDelete = prev.stocksOnBehalf[rowIndex];

            if (sobToDelete == null) {
                return;
            }

            draft.stocksOnBehalf[rowIndex] = {
                ...sobToDelete,
                recordAction: recordActionAsEnum('Delete')
            } as UpdateMsoStockOnBehalf;

            focusNext(source);
        }));
    }, []);
    
    const deleteAllStockOnBehalfForProduct = useCallback((msoProductId: number, source: HTMLElement | null) => {
        setSubmissionUpdate(prev => produce(prev, draft => {
            const sobsToDelete = prev.stocksOnBehalf
                .map((sob, index) => ({
                    sob,
                    index
                }))
                .filter(d => d.sob.msoProductId === msoProductId);

            if (sobsToDelete.length === 0) {
                return;
            }

            sobsToDelete.forEach(sob => {
                draft.stocksOnBehalf[sob.index] = {
                    ...sob.sob,
                    recordAction: recordActionAsEnum('Delete')
                } as UpdateMsoStockOnBehalf;
            });

            focusNext(source);
        }));
    }, []);

    const updateStockOnBehalf = useCallback(<TField extends UpdateMsoStockOnBehalfField>(msoProductId: number, rowIndex: number, field: TField, value: UpdateMsoStockOnBehalf[TField]) => {
        setSubmissionUpdate(prev => produce(prev, draft => {
            const sobToUpdate = prev.stocksOnBehalf[rowIndex];

            if (sobToUpdate === null) {
                return;
            }

            draft.stocksOnBehalf[rowIndex] = {
                ...sobToUpdate,
                [field]: value,
                recordAction: recordActionAsEnum(sobToUpdate.id == null ? 'Create': 'Update')
            } as UpdateMsoStockOnBehalf;
        }));
    }, []);

    // Utility function to mark all data for a product for deletion,
    // specifically intended to support deleting expired product deletion
    const deleteAllDataForProduct = useCallback((msoProductId: number, source: HTMLElement | null) => {
        deleteStockholding(msoProductId, source);
        deleteAllStockOnBehalfForProduct(msoProductId, source);
    }, [deleteAllStockOnBehalfForProduct, deleteStockholding]);


    const reset = useCallback(() => {
        setSubmissionUpdate(transformSubmissionToUpdateSubmission(submission, refData.products));
        setHasUnsavedChanges(false);
    }, [refData, submission]);

    // What a tangled mess...this shouldn't be necessary
    const resetRef = useUpdatedRef(reset);

    useEffect(() => {
        resetRef.current();
    }, [resetRef]);

    // This is the worst, but I don't care right now
    useEffect(() => {
        resetRef.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submission]);

    return {
        changeSerial,
        hasUnsavedChanges,
        msoCtrl,
        submissionUpdate: mergeMsos(submissionUpdate, msoCtrl.msos.map(m => m.mso)),
        addStockholdingOwnership: wrap(addStockholdingOwnership, registerChange),
        addStockOnBehalf: wrap(addStockOnBehalf, registerChange),
        deleteAllDataForProduct: wrap(deleteAllDataForProduct, registerChange),
        deleteAllStockOnBehalfForProduct: wrap(deleteAllStockOnBehalfForProduct, registerChange),
        deleteStockholding: wrap(deleteStockholding, registerChange),
        deleteStockholdingOwnership: wrap(deleteStockholdingOwnership, registerChange),
        deleteStockOnBehalf: wrap(deleteStockOnBehalf, registerChange),
        reset,
        updateAcquitted: wrap(updateAcquitted, registerChange),
        updateComments: wrap(updateComments, registerChange),
        updateStockholdingOwnership: wrap(updateStockholdingOwnership, registerChange),
        updateStockholding: wrap(updateStockholding, registerChange),
        updateStockOnBehalf: wrap(updateStockOnBehalf, registerChange),
    }
}

export default useMsoImporterForm

export type UseMsoImporterForm = ReturnType<typeof useMsoImporterForm>;
