import { useAPI } from "psims/react/providers/api";
import useDataSubmissionAPI from "psims/react/pages/primary-pages/data-submissions/shared/use-data-submission-api";
import { isSuccesfulRowResult } from "psims/models/api/record-result";
import { getRecordResults, isDefSubmission, isDefSubmitted } from "psims/models/submission-types/def/def-submission";

interface UseDefAPIProps {
    id: number;
}

function useDefAPI({id}: UseDefAPIProps) {
    const {api} = useAPI();

    return useDataSubmissionAPI({
        actionFetch: api.getDefSubmission,
        actionSubmit: api.submitDef,
        actionUpdate: api.updateDef,
        actionUpdateDataSubmission: api.updateDefDataSubmission,
        actionClearAllData: api.clearAllDef,
        dataSubmissionId: id,
        name: 'Diesel exhaust fluid',
        submissionAssertion: isDefSubmission,
        submitSuccessAssertion: isDefSubmitted,
        updateSuccessAssertion,
    });
}

export default useDefAPI;

export type UseDefAPI = ReturnType<typeof useDefAPI>;

function updateSuccessAssertion(result: unknown) {
    if (result == null) {
        return false;
    }

    return getRecordResults(result)
        .filter(r => !isSuccesfulRowResult(r)).length === 0;
}
