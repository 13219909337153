import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';

import { INFO_NEGATIVE_VALUE_COMMENTS, INFO_PREVIOUSLY_REPORTED_COMMENTS_REFINING } from 'psims/constants/info-messages';
import { INCOMPLETE_ROW, INVALID_DENSITY_RANGE, INVALID_PRODUCTION_EXPIRED } from 'psims/constants/validation-messages';
import { isEmpty } from 'psims/lib/empty';
import { asNumber } from 'psims/lib/number';
import { isWithinMaxDecimalPlaces } from 'psims/lib/validation/number';
import { RecordAction, recordActionAsEnum, recordActionFromEnum } from 'psims/models/api/data-submission-record-action';
import { RefineryProduct } from 'psims/models/ref-data/refinery-product';
import { getRefineryProductGroupReferenceCode, RefineryProductGroup, RefProductGroupReferenceCode } from 'psims/models/ref-data/refinery-product-group';
import { RefineryType, RefineryTypeName, REFINERY_TYPE_NAMES } from 'psims/models/ref-data/refinery-type';
import { Refining, RefiningComment, RefiningField, RefiningSubmission, UpdateRefining, UpdateRefiningComment, UpdateRefiningSubmissionFormData, updateRefiningToRefiningData } from "psims/models/submission-types/refining";
import { ErrorMessage, InfoMessage } from 'psims/react/pages/primary-pages/data-submissions/shared/field-messages';
import useFocusedField from 'psims/react/util/use-focused-field';
import { DensityInvalidErrorMessage, ErrorExpiredProduct, InfoNegativeValueMessage, InfoPreviouslyReportedMessage, QuantityInvalidErrorMessage, RowIncompleteInvalidErrorMessage } from 'psims/react/pages/primary-pages/data-submissions/shared/messages';
import { validateComments, validateInRange, validateQuantity } from 'psims/react/pages/primary-pages/data-submissions/shared/validation';
import { CLOSING_STOCKS_ROW_HEADING, CONSUMED_ROW_HEADING, DENSITY_ROW_HEADING, OPENING_STOCK_ROW_HEADING, PRODUCTION_ROW_HEADING, REFINERY_INPUTS_ROW_HEADING, TOTAL_RECEIPTS_ROW_HEADING } from './shared';
import { RefineryTypeRefDataProduct, UseRefineryRefData } from './use-refinery-ref-data';
import { UseRefineryValidationAlerts } from './use-refinery-validation-alerts';
import { UseTemplateImport } from 'psims/react/blocks/import/use-template-import';
import { DataSubmissionRecordActionEnum, RefiningSubmissionVM, RefiningVM } from 'psims/gen/xapi-client';
import { SAVED_PAGES_MAP } from './use-refinery-steps';
import { UseRefineryProgress } from './use-refinery-progress';
import { UseRefineryAPI } from './use-refinery-api';
import { EntityStatus } from '../shared/types';
import { DeleteRequestState, idleDeleteRequest } from '../shared/delete-confirmation';
import { focusNext } from 'psims/lib/focus-util';

interface UseRefineryFormProps {
    apiCtrl: UseRefineryAPI;
    currentStep: RefineryTypeName | 'Submit';
    importCtrl: UseTemplateImport<Partial<RefiningSubmissionVM>>;
    progressCtrl: UseRefineryProgress;
    refData: UseRefineryRefData;
    validationAlerts: UseRefineryValidationAlerts;
}

export type ProductView = RefineryTypeRefDataProduct & {
    data: UpdateRefining | null;
    calculatedInput?: number;
    group: RefineryProductGroup;
    productStatus: EntityStatus;
    infoMessages: Array<InfoMessage<RefiningField>>;
    validationErrors: Array<ErrorMessage<RefiningField | 'product'>>;
}

type Totals = {
    [key in RefiningField]?: number;
} 

export type GroupView = {
    productGroup: RefineryProductGroup;
    products: Array<ProductView>;
    tableKind: TableKind;
    totals: Totals;
    groupStatus: EntityStatus | 'empty';
}

type TypeView = {
    groups: Array<GroupView>;
    data: {
        comments: {
            data: UpdateRefiningComment | null;
            isRequired: boolean;
            validationError: {
                notification: {
                    content: JSX.Element;
                    message: string;
                }
            } | null;
        }
    };
    refineryType: RefineryType;
}

type DataView = {
    [key in keyof UseRefineryRefData]: TypeView;
}

type FocusFieldRefining = {
    field: RefiningField,
    productId: number;
}

export type FocusFieldDeleteRefining = {
    field: 'delete',
    productId: number;
}

type RefiningMapType = {[refineryProductId: number]: UpdateRefining | null};

type RefiningCommentMapType = {[refineryTypeId: number]: UpdateRefiningComment | null};

type FocusField = FocusFieldRefining | FocusFieldDeleteRefining | 'comments' | 'totalLosses';

export const isStepSaved = (data: Partial<RefiningSubmissionVM>, stepName: any) => {
    let saved = false;
    if (REFINERY_TYPE_NAMES.includes(stepName as RefineryTypeName) && data && data.submissionFormData) {
        const typeName = stepName as RefineryTypeName;
        const stepSaved = data.submissionFormData[SAVED_PAGES_MAP[typeName]];

        saved = stepSaved !== undefined && stepSaved != null && stepSaved === true ? true : false;
    }
    return saved;
}

function useRefineryForm({
    apiCtrl,
    currentStep,
    importCtrl,
    progressCtrl,
    refData,
    validationAlerts
}: UseRefineryFormProps) {
    const {focusedField, setFocusedField} = useFocusedField<FocusField | null>();
    const [refiningMap, setRefiningMap] = useState<RefiningMapType>({});
    const [refiningCommentsMap, setRefiningCommentsMap] = useState<RefiningCommentMapType>({});
    const {submission, viewMode} = apiCtrl;
    const [submissionComments, setSubmissionComments] = useState(submission?.dataSubmission.comments);
    const [submissionCommentChanged, setSubmissionCommentChanged] = useState(false);
    const [updateFormData, setUpdateFormData] = useState(updateFormDataFromSubmission(submission));
    const [declaration, setDeclaration] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [deleteRequestState, setDeleteRequestState] = useState<DeleteRequestState>(idleDeleteRequest);

    const isDisabled = useMemo(() => {
        return viewMode !== 'edit';
    }, [viewMode]);

    const [workingStockProductId] = useState(() => {
        const workingGroup = refData['Gases-Unfin-Petrochem-Losses'].groups.find(g => g.productGroup.referenceCode === getRefineryProductGroupReferenceCode('workingstock'));
        return workingGroup != null ? (workingGroup.products[0] || {id: null}).id : null;
    });

    const refDataByType = useMemo(() => {
        const typeNames = Object.keys(refData) as Array<RefineryTypeName>;
        return typeNames.map(tn => refData[tn])
     }, [refData]);
    
    useEffect(() => {
        let isImportStepSaved = false;

        if (importCtrl.templateImportState.data) {
            isImportStepSaved = isStepSaved(importCtrl.templateImportState.data, currentStep as string)
        }

        if (importCtrl.templateImportState.data && importCtrl.templateImportState.data.refinings && refDataByType != null &&
                submission?.refinings && importCtrl.templateImportState.templateImportDialogState === 'processing' &&
                currentStep !== 'Submit' && !isImportStepSaved) {    
            let changed = !isStepSaved(importCtrl.templateImportState.data, currentStep as string);
            const submissionData = submission.refinings;
            const submissionRefineryCommentsData = submission.refineryComments;      
            const importRefinings = importCtrl.templateImportState.data.refinings;
            
            let refiningMap: RefiningMapType = {};  
            let refiningCommentsMap: RefiningCommentMapType = {}; 

            refDataByType.forEach(g => g.groups
                .filter(g => !g.isExpired)
                .forEach(g => {
                    g.products
                        .filter(p => !p.isExpired)
                        .forEach(p => {
                            const data = importRefinings
                                ?.find(r => r.refineryProductId === p.id && r.refineryTypeId === g.productGroup.refineryTypeId);
                            const existingData = submissionData
                                ?.find(r => r.refineryProductId === p.id && r.refineryTypeId === g.productGroup.refineryTypeId && !p.isExpired);

                            // Remove non-required data
                            let newImportData: RefiningVM = { ...data };
                            if (data !== undefined) {
                                if(g.productGroup.refineryTypeName === "Refinery Input") {
                                    newImportData = { ...data, production: null };
                                }
                                else if(g.productGroup.refineryTypeName === "Refinery Output") {
                                    newImportData = { ...data, refineryInputs: null };
                                }
                                else if(g.productGroup.refineryTypeName === "Gases-Unfin-Petrochem-Losses") {
                                    if (p.refineryProductGroupReferenceCode === getRefineryProductGroupReferenceCode('petrochemicals')) {
                                        newImportData = { ...data, consumed: null, refineryInputs: null, totalReceipts: null };
                                    }
                                    else if(p.productCode === "MR_UNFIN_INTER") {
                                        newImportData = { ...data, consumed: null, density: null, production: null, totalReceipts: null };
                                    }
                                }
                            }

                            let updateRefining : UpdateRefining = { refineryProductId: 0};

                            if (data !== undefined && existingData !== undefined) {
                                updateRefining = {
                                    ...refiningVmToUpdateRefining(newImportData, recordActionAsEnum('Update'), existingData),
                                }
                            } else if (data !== undefined && existingData === undefined) {
                                updateRefining = {
                                    ...refiningVmToUpdateRefining(newImportData, recordActionAsEnum('Create')),
                                }
                            } else if (data === undefined && existingData !== undefined) {
                                const emptyData: RefiningVM = { refineryProductId: existingData.refineryProductId, };
                                updateRefining = {
                                    ...refiningVmToUpdateRefining(emptyData, recordActionAsEnum('Delete'), existingData),
                                }
                            }

                            if (updateRefining.refineryProductId !== 0) {
                                refiningMap = {
                                    ...refiningMap,
                                    [p.id]: updateRefining
                                };
                            }
                        });

                    const refineryTypeId = g.productGroup.refineryTypeId;
                    const existingComment = submissionRefineryCommentsData?.find(c => c.refineryTypeId === refineryTypeId);
                    if (existingComment !== undefined) {
                        const updateComment : UpdateRefiningComment = {
                            refineryTypeId: refineryTypeId,
                            comments: existingComment.comments,
                            concurrencyToken: existingComment.concurrencyToken,
                            id: existingComment.id,
                        }
                        refiningCommentsMap = {
                            ...refiningCommentsMap,
                            [refineryTypeId]: updateComment
                        };
                    }
                }));

            setHasUnsavedChanges(changed);           
            setRefiningMap(refiningMap);
            setRefiningCommentsMap(refiningCommentsMap);
        }
    }, [
        currentStep,
        importCtrl.templateImportState.data,
        importCtrl.templateImportState.templateImportDialogState,
        refDataByType,
        submission?.refineryComments,
        submission?.refinings,
    ]);

    useEffect(() => {
        if (importCtrl.templateImportState.templateImportDialogState === 'complete' && declaration === true) {
            setDeclaration(false);
            setHasUnsavedChanges(true);
        }
    }, [declaration, importCtrl.templateImportState.templateImportDialogState]);

    const view = useMemo(() => {
        const typeNames = Object.keys(refData) as Array<RefineryTypeName>;
        // Derive data pages view
        let isCurrentPageValid = true;
        let hasAnyExpiredData = false;
        const dataPages = typeNames.reduce((viewData, typeName) => {
            let commentsRequired = false;
            let isPageValid = true;
            const isCurrentStep = typeName === currentStep;
            const typeView: TypeView = {
                ...refData[typeName],
                groups: refData[typeName].groups
                    .map(group => {
                        const tableKind = getTableKind(group.productGroup.referenceCode, group.productGroup.refineryTypeName);
                        let hasNonEmpty = false;
                        let hasExpiredWithData = false;
                        const products = group.products
                            .map(product => {
                                const p = {
                                    ...product,
                                    data: refiningMap[product.id],
                                    group: group.productGroup,
                                    productStatus: getProductStatus(product.isExpired, refiningMap[product.id]),
                                    validationErrors: (
                                        (isCurrentStep && viewMode === 'edit') ? validationMessagesForRefining(refiningMap[product.id], setFocusedField, product, tableKind, product.isExpired) : []
                                    ),
                                    infoMessages: infoMessagesForRefining(refiningMap[product.id], setFocusedField, product, validationAlerts),
                                    ...(group.productGroup.referenceCode === getRefineryProductGroupReferenceCode('workingstock') ? {
                                        calculatedInput: calculateInput(refiningMap[product.id])
                                    }: {})
                                }
                                
                                if (p.productStatus === 'expired_with_data') {
                                    hasNonEmpty = true;
                                    hasExpiredWithData = true;
                                    hasAnyExpiredData = true;
                                } 

                                if (p.productStatus === 'active') {
                                    hasNonEmpty = true;
                                } 

                                isPageValid = isPageValid && p.validationErrors.length === 0;
                                commentsRequired = commentsRequired || p.infoMessages.length > 0;
                                return p;
                            });

                        return {
                            ...group,
                            groupStatus: group.isExpired ?
                                hasExpiredWithData ? 'expired_with_data' : 'expired' :
                                hasNonEmpty ? 'active' : 'empty',
                            products,
                            tableKind,
                            totals: calculateTotalsForProductGroup(products),
                            isPageValid,
                        };
                    }),
                data: {
                    comments: {
                        data: refiningCommentsMap[refData[typeName].refineryType.id],
                        isRequired: commentsRequired,
                        validationError: null,
                    },
                },
            };

            // Validate comments
            const pageComments = typeView.data.comments;
            
            typeView.data.comments.validationError = validateComments(pageComments.data?.comments, () => setFocusedField('comments'), pageComments.isRequired);

            isPageValid = isPageValid && typeView.data.comments.validationError == null;

            if (isCurrentStep) {
                isCurrentPageValid = isPageValid;
            }

            return {
                ...viewData,
                [typeName]: typeView
            }
        }, {} as DataView);

        let comments = submissionComments;
        if (viewMode === 'edit' && currentStep === 'Submit' && 
            importCtrl.templateImportState.templateImportDialogState === 'processing' &&
            (importCtrl.templateImportState.submitSaved === undefined || importCtrl.templateImportState.submitSaved !== true) && 
            submissionCommentChanged !== true) {
            comments = null;
        }

        const isSubmitCommentRequired = submission?.dataSubmission?.validationAlerts && submission?.dataSubmission?.validationAlerts.filter(a => a.validationAlert === 'SameAsPrevious').length > 0 ? true : false;
        // Derive submitPage view
        const submitPage = {
            comments: comments,
            commentsValidationError: validateComments(comments, () => setFocusedField('comments'), isSubmitCommentRequired),
            declaration: viewMode === 'edit' ? declaration : true,
        }

        // Validate total losses
        const totalLosses = updateFormData.totalLosses;
        const totalLossesMsg = validateQuantity(totalLosses);

        isCurrentPageValid = isCurrentPageValid && totalLossesMsg == null;

        const totalLossesValidationMessage = totalLossesMsg != null ? {
            notification: {
                content: QuantityInvalidErrorMessage({
                    label: 'Total losses',
                    onClick: () => setFocusedField('totalLosses'),
                }),
                message: totalLossesMsg,
            }
        } : null

        const calculatedLossesGains = calculateLossesGains(dataPages);

        return {
            ...dataPages,
            Submit: submitPage,
            calculatedLossesGains,
            focusedField,
            hasAnyExpiredData,
            hasUnsavedChanges,
            isValid: isCurrentPageValid,
            isDisabled,
            shouldForceErrors: (
                progressCtrl.saveAttemptedForStep ||
                importCtrl.templateImportState.templateImportDialogState === 'processing'
            ),
            totalLosses: {
                data: updateFormData.totalLosses,
                validationMessage: totalLossesValidationMessage,
            }
        }
    }, [
        refData,
        submissionComments,
        viewMode,
        currentStep,
        importCtrl.templateImportState.templateImportDialogState,
        importCtrl.templateImportState.submitSaved,
        submissionCommentChanged,
        submission?.dataSubmission?.validationAlerts,
        declaration,
        updateFormData.totalLosses,
        focusedField,
        hasUnsavedChanges,
        isDisabled,
        progressCtrl.saveAttemptedForStep,
        refiningCommentsMap,
        refiningMap,
        setFocusedField,
        validationAlerts
]);

    const updateRefining = useCallback(
        <K extends RefiningField >(
            refineryProductId: number, field: K, value: string
        ) => {
        setRefiningMap(prev => produce(prev, draft => {
            // Handle empty update
            const refining = {
                ...(refiningMap[refineryProductId] || {
                    refineryProductId,
                })
            };

            // TODO: fix type assumption
            refining[field] = value.length === 0 ? null : value.replaceAll(',', '') as unknown as number;

            // Special handling of working stock
            if (refining.refineryProductId === workingStockProductId) {
                // Clear calculated if empty working opening/closing
                if (isEmpty(refining.closingStocks) && isEmpty(refining.openingStocks)) {
                    refining.refineryInputs = null;
                } else {
                    refining.refineryInputs = calculateInput(refining);
                }
            }

            if (isEmptyUpdateRefining(refining)) {
                draft[refining.refineryProductId] = refining.id ? {
                    ...refining,
                    recordAction: recordActionAsEnum('Delete')
                } : null;
            } else {
                const recordAction: RecordAction = refining.id ? 'Update' : 'Create';
                draft[refining.refineryProductId] = {
                    ...refining,
                    recordAction: recordActionAsEnum(recordAction),
                }
            }
        }));
        
        // Compare update to persisted data before triggering unsaved change
        const serverRefining = submission?.refinings.find(r => r.refineryProductId === refineryProductId);
        if (serverRefining) {
            const serverValue = serverRefining[field];
            const vNum = value as unknown as number;
            if (vNum !== serverValue) {
                setHasUnsavedChanges(true);
            }
        } else if (value.length > 0) {
            setHasUnsavedChanges(true);
        }
    }, [refiningMap, submission, workingStockProductId]);

    const deleteRefining = useCallback((productId: number) => {
        setRefiningMap(prev => {
            return {
                ...prev,
                [productId]: {
                    ...prev[productId],
                    refineryProductId: productId,
                    recordAction: recordActionAsEnum('Delete'),
                }
            }
        });

        setHasUnsavedChanges(true);
    }, []);

    const deleteRefiningRequest = useCallback((productId: number, source: HTMLElement | null) => {
        setDeleteRequestState({
            deleteState: 'showing_dialog',
            id: productId,
            rowIndex: 0,
            message: 'This refining record will be deleted.',
            source,
        });
    }, []);

    const updateRefiningComment = useCallback((comments: string, refineryTypeId: number) => {
        setRefiningCommentsMap(prev => produce(prev, draft => {
            const comment = {
                ...(refiningCommentsMap[refineryTypeId] || {
                    refineryTypeId,
                })
            };

            comment.comments = comments;

            if (isEmptyUpdateRefiningComment(comment)) {
                draft[refineryTypeId] = comment.id ? {
                    ...comment,
                    recordAction: recordActionAsEnum('Delete')
                } : null;
            } else {
                const recordAction: RecordAction = comment.id ? 'Update' : 'Create';
                draft[refineryTypeId] = {
                    ...comment,
                    recordAction: recordActionAsEnum(recordAction),
                }
            }
        }));

        setHasUnsavedChanges(true);
    }, [refiningCommentsMap]);

    const updateRefiningLosses = useCallback((losses: string) => {
        setUpdateFormData(prev => produce(prev, draft => {
            draft.totalLosses = losses.length === 0 ? null : losses.replaceAll(',', '') as unknown as number;
        }));

        setHasUnsavedChanges(true);
    }, []);

    const updateDataSubmissionComment = useCallback((comments: string | undefined) => {
        setSubmissionComments(comments);
        setSubmissionCommentChanged(true);
        setHasUnsavedChanges(true);
    }, []);

    const resetForm = useCallback(() => {
        setRefiningMap(updateRefiningMap(submission));
        setRefiningCommentsMap(updateRefiningCommentsMap(submission));
        setSubmissionComments(submission?.dataSubmission.comments);
        setSubmissionCommentChanged(false);
        setHasUnsavedChanges(false);
    }, [submission]);

    const clearDeclaration = useCallback(() => {
        setDeclaration(false);
    }, []);

    useEffect(() => {
        switch (deleteRequestState.deleteState) {
            case 'cancelled':
                setDeleteRequestState(idleDeleteRequest);
                break;
            case 'confirmed':
                if (deleteRequestState.id !== undefined && deleteRequestState.rowIndex !== undefined) {
                    deleteRefining(deleteRequestState.id);

                    if (deleteRequestState.source != null) {
                        focusNext(deleteRequestState.source);
                    }
                }
                setDeleteRequestState(idleDeleteRequest);
                break;
        }
    }, [deleteRefining, deleteRequestState]);

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return {
        deleteRequestState,
        updateFormData,
        view,
        clearDeclaration,
        deleteRefiningRequest,
        resetForm,
        setDeleteRequestState,
        setFocusedField,
        updateDataSubmissionComment,
        updateDeclaration: setDeclaration,
        updateRefining,
        updateRefiningComment,
        updateRefiningLosses,
    };
}

export default useRefineryForm

export type UseRefineryForm = ReturnType<typeof useRefineryForm>;

export function isFocusFieldRefining(maybe: FocusField): maybe is FocusFieldRefining {
    return (maybe as FocusFieldRefining)?.field != null;
}

// Helpers
type TableKind = 'input' | 'output' | 'gases' | 'petro' | 'working';

function getProductStatus(isExpired: boolean, refining: UpdateRefining | null): EntityStatus {
    if (!isExpired) {
        return 'active';
    }
    if (refining == null || refining.id == null || refining.id === undefined || recordActionFromEnum(refining.recordAction) === 'Delete') {
        return 'expired';
    }
    return 'expired_with_data';
}

function getTableKind(groupRefCode: RefProductGroupReferenceCode, refineryTypeName: RefineryTypeName): TableKind {
    if (refineryTypeName === 'Refinery Input') {
        return 'input';
    }

    if (refineryTypeName === 'Refinery Output') {
        return 'output';
    }

    if (getRefineryProductGroupReferenceCode('workingstock') === groupRefCode) {
        return 'working';
    }

    // The following seems an unrobust way to determine the table kind, but
    // the ref data doesn't expose an environment-agnostic way to identify
    // a specific product group by canonical ID, so we are stuck with it.
    if (getRefineryProductGroupReferenceCode('gases') === groupRefCode) {
        return 'gases';
    }

    if (getRefineryProductGroupReferenceCode('petrochemicals')) {
        return 'petro';
    }

    throw new Error(`Unable to determine tableKind for reference code: ${groupRefCode}`)
}

/*
    From PSIMS FS - Portal - Data & Analytics document (BR-REF-010):
    ri = Refinery input totals (refinery input all products)
    ro = Refinery output totals (production all products) 
    gi = Gases & working stock Input (gas refinery  input total + working stock inputs).  
    go = Gases & working stock output (gas production + pertrochemical production)

    Value = ri + gi – (ro + go)
*/
function calculateLossesGains(viewData: DataView): number {
    
    let ri = viewData['Refinery Input'].groups.map(x => x.totals.refineryInputs).reduce(calculateSum, 0);
    let ro = viewData['Refinery Output'].groups.map(x => x.totals.production).reduce(calculateSum, 0);
    let workingStockInput = viewData['Gases-Unfin-Petrochem-Losses'].groups.filter(x => x.productGroup.referenceCode === getRefineryProductGroupReferenceCode('workingstock')).map(x => (asNumber(x.totals.openingStocks) - asNumber(x.totals.closingStocks))).reduce(calculateSum, 0);
    let gasRefineryInput = viewData['Gases-Unfin-Petrochem-Losses'].groups.filter(x => x.productGroup.referenceCode === getRefineryProductGroupReferenceCode('gases')).map(x => asNumber(x.totals.refineryInputs)).reduce(calculateSum, 0);

    let gi = workingStockInput + gasRefineryInput;
    
    let gasProduction = viewData['Gases-Unfin-Petrochem-Losses'].groups.filter(x => x.productGroup.referenceCode === getRefineryProductGroupReferenceCode('gases')).map(x => asNumber(x.totals.production)).reduce(calculateSum, 0);
    let petroProduction = viewData['Gases-Unfin-Petrochem-Losses'].groups.filter(x => x.productGroup.referenceCode === getRefineryProductGroupReferenceCode('petrochemicals')).map(x => asNumber(x.totals.production)).reduce(calculateSum, 0);
    
    let go = gasProduction + petroProduction;
    
    let val = asNumber(ri) + asNumber(gi) - (asNumber(ro) + asNumber(go));

    return val;
}

/*
    From PSIMS FS - Portal - Data & Analytics document (BR-REF-003/BR-REF-004):

    Totals - integers:  Calculate the totals for the product group for integer columns but summing the value for each product.
                        Where row has no data, treat as 0 (zero)
                        Where all row values are blank the total will be displayed as 0

    Totals - density - refinery input page:   Calculate the average density for the product group 
                        Eg I have 2 products 
                        Product 1 has a density of 0.500 and a refinery input of 85
                        Product 2 has a density of 0.600 and a refinery input of 42

                        1.	For each product calculate the refinery input (RI) = product refinery input/ product group refinery input
                        Eg 85/127 = 0.669291
                        Eg 42/127 = 0.330709

                        2.	Then use this to work out the density to be added to the product group (PD) = product average density * RI
                        Eg 0.669291 *0.500 = 0.334646
                        Eg 0.330709 *0.600 = 0.198425

                        3.	Then add the calculated PD to get the average density of  the product group (round to 3 decimals)
                        Eg 0.334646 + 0.198425 = 0.533071 
                        and round to 3 decimals = 0.533

    Total - density - refinery output and gases page: Calculate the average density for the product group
                        Eg I have 2 products
                        Product 1 has a density of 0.500 and a refinery input of 85
                        Product 2 has a density of 0.600 and a refinery input of 42

                        1. For each product calculate the production (PO) = product production/ product group production
                        Eg 85/127 = 0.669291
                        Eg 42/127 = 0.330709

                        2. Then use this to work out the density to be added to the product group (PD) = product average density * PO
                        Eg 0.669291 *0.500 = 0.334646
                        Eg 0.330709 *0.600 = 0.198425

                        3. Then add the calculated PD to get the average density of  the product group (round to 3 decimals)
                        Eg 0.334646 + 0.198425 = 0.533071
                        and round to 3 decimals = 0.533
*/
function calculateTotalsForProductGroup(products: Array<ProductView>): Totals {
    const totals: Totals = {};
    
    totals.openingStocks = products.map(item => asNumber(item.data?.openingStocks)).reduce(calculateSum, 0);
    totals.closingStocks = products.map(item => asNumber(item.data?.closingStocks)).reduce(calculateSum, 0);
    totals.refineryInputs = products.map(item => asNumber(item.data?.refineryInputs)).reduce(calculateSum, 0);
    totals.totalReceipts = products.map(item => asNumber(item.data?.totalReceipts)).reduce(calculateSum, 0);
    totals.production = products.map(item => asNumber(item.data?.production)).reduce(calculateSum, 0);
    totals.consumed = products.map(item => asNumber(item.data?.consumed)).reduce(calculateSum, 0);
    totals.density = products.map(item => item).reduce(calculateDensity, 0);

    function calculateDensity(sum?: number, product?:ProductView):number {
        let pd = 0;

        if (product?.group?.refineryTypeName === 'Refinery Input') {
            let ri = 0;
            if (asNumber(totals.refineryInputs) !== 0 && product?.data?.refineryInputs != null && product?.data?.density != null)
            {
                ri = product.data.refineryInputs / asNumber(totals.refineryInputs);
                pd = product.data.density * ri;
            }
        }
        else {
            let po = 0;
            if (asNumber(totals.production) !== 0 && product?.data?.production != null && product?.data?.density != null)
            {
                po = product.data.production / asNumber(totals.production);
                pd = product.data.density * po;
            }
        }

        return calculateSum(sum, pd);
    }

    return totals;
}

function calculateSum(sum?: number, val?: number):number {
    return (asNumber(sum)) + (asNumber(val));
}

function calculateInput(product?: UpdateRefining | null):number {
    return (asNumber(product?.openingStocks) - asNumber(product?.closingStocks));
}

function refiningToUpdateRefining(refining: Refining): UpdateRefining {
    const {recordResult, refineryTypeId, validationAlerts, ...rest} = refining;
    return rest;
}

export function refiningVmToUpdateRefining(refining: RefiningVM, recordAction?: DataSubmissionRecordActionEnum,  existingRefining?: Refining): UpdateRefining {
    const updateRefining : UpdateRefining = {
        ...refining,
        recordAction: recordAction,
        refineryProductId: refining?.refineryProductId ? refining?.refineryProductId : 0,
        id: existingRefining?.id,
        concurrencyToken: existingRefining?.concurrencyToken
    };
    return updateRefining;
}

function refiningCommentToUpdateRefiningComment(refiningComment: RefiningComment): UpdateRefiningComment {
    const {recordResult, ...rest} = refiningComment;
    return rest;
}

function updateRefiningMap(submission: RefiningSubmission | undefined): {[refineryProductId: number]: UpdateRefining | null} {    
    return submission ? submission.refinings
        .filter(r => r.recordResult?.rowResult !== 'Deleted')
        .reduce((mapping, refining) => ({
            ...mapping,
            [refining.refineryProductId]: refiningToUpdateRefining(refining),
        }), {}) : {};
}

function updateRefiningCommentsMap(submission: RefiningSubmission | undefined): {[refineryTypeId: number]: UpdateRefiningComment | null} {
    return submission ? submission.refineryComments
        .filter(c => c.recordResult?.rowResult !== 'Deleted')
        .reduce((mapping, refineryComment) => ({
            ...mapping,
            [refineryComment.refineryTypeId]: refiningCommentToUpdateRefiningComment(refineryComment),
        }), {}) : {};
}

function updateFormDataFromSubmission(submission: RefiningSubmission | undefined): UpdateRefiningSubmissionFormData {
    if (!submission) {
        return {
            calculatedLossesOrGains: 0,
            concurrencyToken: '',
            dataSubmissionId: 0,
            id: 0,
            totalLosses: 0
        };
    }
    const {dataSubmissionId, calculatedLossesOrGains, concurrencyToken, id, totalLosses} = submission.submissionFormData;
    return {
        calculatedLossesOrGains,
        concurrencyToken,
        dataSubmissionId,
        id,
        totalLosses
    }
}

function isEmptyUpdateRefining(refining: UpdateRefining) {
    const {refineryProductId, concurrencyToken, dataSubmissionId, id, recordAction, ...data} = refining;
    return isEmpty(data);
}

function isEmptyUpdateRefiningComment(refiningComment: UpdateRefiningComment) {
    const {concurrencyToken, id, recordAction, refineryTypeId, ...data} = refiningComment;
    return isEmpty(data);
}

type AllOrNothingStatus = 'start' | 'all' | 'nothing' | 'invalid';

function validateDensity(val: number | null) {
    if (val == null) {
        return undefined;
    }

    const notInRangeMessage = validateInRange(val, 5, 0, INVALID_DENSITY_RANGE);

    if (notInRangeMessage != null) {
        return notInRangeMessage;
    }

    if (!isWithinMaxDecimalPlaces(val, 10)) {
        return INVALID_DENSITY_RANGE;
    }

    return undefined;
}

const fieldDisplayOrder: {[field in (RefiningField | 'product')]: number} = {
    product: 0.5,
    density: 1,
    openingStocks: 2,
    totalReceipts: 3,
    production: 4,
    refineryInputs: 4,
    consumed: 5,
    closingStocks: 7,
};

function validationMessagesForRefining(
    refining: UpdateRefining | null,
    onClick: (field: FocusField) => any,
    product: RefineryProduct,
    tableKind: TableKind,
    isExpired: boolean
) : Array<ErrorMessage<RefiningField | 'product'>> | [] {
    const validationMessages: Array<ErrorMessage<RefiningField | 'product'>> = [];

    if (refining != null) {
        if (recordActionFromEnum(refining.recordAction) === 'Delete') {
            return validationMessages;
        }

        const applicableFields = getFieldsForTableKind(tableKind);
        
        const data = updateRefiningToRefiningData(refining);
        const entries = Object.entries(data);
        
        if (isExpired) {
            const field = 'product';
            validationMessages.push({
                notification: {
                    content: ErrorExpiredProduct({
                        label: product.productName,
                        onTargetClick: () =>  onClick({productId: product.id, field: 'delete'}),
                    }),
                },
                tooltip: {
                    target: field,
                    content: INVALID_PRODUCTION_EXPIRED,
                }
            });

            return validationMessages;
        }
        
        entries.forEach(([f, value]) => {
            const field = f as RefiningField;
            let msg: string | undefined;

            if (field === 'refineryInputs' && tableKind === 'working') {
                return;
            }

            if (field.toUpperCase() === 'DENSITY') {
                msg = validateDensity(value);
            }
            else if (field.toUpperCase() !== 'CONCURRENCYTOKEN') {
                msg = validateQuantity(value);
            }

            if (msg != null) {
                const col = getRefiningRowHeading(field);
                const label = `${product.productName} in the ${col} column`;
                const quantityContent = QuantityInvalidErrorMessage({
                    label: label,
                    onClick: () => onClick({productId: product.id, field})
                });

                const densityContent = DensityInvalidErrorMessage({
                    label: label,
                    onClick: () => onClick({productId: product.id, field})
                });

                const notificationContent = msg === INVALID_DENSITY_RANGE ? densityContent : quantityContent;

                validationMessages.push({
                    notification: {
                        content: notificationContent,
                    },
                    tooltip: {
                        target: field,
                        content: msg,
                    }
                });
            }
        });

        // validate 'all-or-nothing' for each row. This will probably require refactoring
        // the activeColumns config from refinery-type-editor so that the columns shown and expected
        // mandatory fields are kept in-sync. Note - this CAN'T be the best way to do this. I need a break
        const allOrNothingStatus = applicableFields.reduce((status, field) => {
            switch (status) {
                case 'invalid':
                    return 'invalid';
            
                case 'start': {
                    const data = entries.find(([dataField]) => dataField === field);
                    return (data == null || data[1] == null) ? 'nothing' : 'all';
                }

                case 'nothing': {
                    const data = entries.find(([dataField]) => dataField === field);
                    return (data == null || data[1] == null) ? 'nothing' : 'invalid';
                }

                case 'all': {
                    const data = entries.find(([dataField]) => dataField === field);
                    return (data == null || data[1] == null) ? 'invalid' : 'all';
                }

                default:
                    return status;
            }
        }, 'start' as AllOrNothingStatus);

        if (allOrNothingStatus === 'invalid') {
            applicableFields.filter(field => {
                const data = entries.find(([dataField]) => dataField === field);
                return data == null || data[1] == null;
            }).forEach(f => {
                const field = f as RefiningField;
                const col = getRefiningRowHeading(field);
                const label = `${product.productName} in the ${col} column`;
                validationMessages.push({
                    notification: {
                        content: RowIncompleteInvalidErrorMessage({
                            label,
                            onClick: () => onClick({productId: product.id, field})
                        })
                    },
                    tooltip: {
                        target: field,
                        content: INCOMPLETE_ROW,
                    }
                });
            });
        }
        
    }

    return validationMessages.sort((a, b) => fieldDisplayOrder[a.tooltip.target] - fieldDisplayOrder[b.tooltip.target]);
}

export function hasDensity(tableKind: TableKind) {
    return tableKind !== 'working';
}

export function hasTotalReceipts(tableKind: TableKind) {
    return tableKind !== 'working' && tableKind !== 'petro';
}

export function hasRefineryInputs(tableKind: TableKind) {
    return tableKind === 'input' || tableKind === 'gases';
}

export function hasProduction(tableKind: TableKind) {
    return tableKind === 'output' || tableKind === 'gases' || tableKind === 'petro';
}

export function hasConsumed(tableKind: TableKind) {
    return tableKind !== 'working' && tableKind !== 'petro';
}

export function hasCalculatedInput(tableKind: TableKind) {
    return tableKind === 'working';
}

function getFieldsForTableKind(tableKind: TableKind): Array<RefiningField> {
    return [
        'openingStocks',
        ...(hasDensity(tableKind) ? ['density' as 'density'] : []),
        ...(hasTotalReceipts(tableKind) ? ['totalReceipts' as 'totalReceipts'] : []),
        ...(hasRefineryInputs(tableKind) ? ['refineryInputs' as 'refineryInputs'] : []),
        ...(hasProduction(tableKind) ? ['production' as 'production'] : []),
        ...(hasConsumed(tableKind) ? ['consumed' as 'consumed'] : []),
        'closingStocks',
    ]
}

function getRefiningRowHeading(field: RefiningField) {
    let heading: string = '';
    if (field.toUpperCase() === 'DENSITY') {
        heading = DENSITY_ROW_HEADING;
    }
    else if (field.toUpperCase() === 'OPENINGSTOCKS') {
        heading = OPENING_STOCK_ROW_HEADING;
    }
    else if (field.toUpperCase() === 'TOTALRECEIPTS') {
        heading = TOTAL_RECEIPTS_ROW_HEADING;
    }
    else if (field.toUpperCase() === 'PRODUCTION') {
        heading = PRODUCTION_ROW_HEADING;
    }
    else if (field.toUpperCase() === 'REFINERYINPUTS') {
        heading = REFINERY_INPUTS_ROW_HEADING;
    }
    else if (field.toUpperCase() === 'CONSUMED') {
        heading = CONSUMED_ROW_HEADING;
    }
    else if (field.toUpperCase() === 'CLOSINGSTOCKS') {
        heading = CLOSING_STOCKS_ROW_HEADING;
    }

    return heading;
}

function infoMessagesForRefining(refining: UpdateRefining | null, onClick: (field: FocusField) => any, product: RefineryProduct, validationAlerts: UseRefineryValidationAlerts) : Array<InfoMessage<RefiningField>> | [] {
    const infoMessages: Array<InfoMessage<RefiningField>> = [];

    if (refining == null) {
        return infoMessages;
    }
    
    const entries = Object.entries(refining);

    // Negative value info messages/tooltips
    entries.forEach(([f, value]) => {
        const field = f as RefiningField;
        const v = asNumber(value);

        // Skip for working stock input - it's calculated
        if (field === 'refineryInputs' && getRefineryProductGroupReferenceCode('workingstock') === product.refineryProductGroupReferenceCode) {
            return;
        }

        if (v < 0) {
            const col = getRefiningRowHeading(field);
            const label = `${product.productName} in the ${col} column`;
            infoMessages.push({
                notification: {
                    content: InfoNegativeValueMessage({
                        label: label,
                        onCommentTargetClick: () => onClick('comments'),
                        onTargetClick: () => {
                            onClick({productId: product.id, field});
                        }
                    })
                },
                tooltip: {
                    content: INFO_NEGATIVE_VALUE_COMMENTS,
                    target: field,
                }
            });
        }
    });

    // Exclude negative validation alerts, as we check that above
    const previouslyReportedValidationAlert = validationAlerts.validationAlertsForCurrentStep
        .find(va => va.productId === product.id && va.validationAlert.validationAlert === 'PreviouslyReportedAlert');

    if (previouslyReportedValidationAlert != null) {
        infoMessages.push({
            notification: {
                content: InfoPreviouslyReportedMessage({
                    label: product.productName,
                    onCommentTargetClick: () => onClick('comments'),
                    onTargetClick: () => {
                        // TODO: can't rely on density for all product types. Use 'activeColumns'
                        // when it is refactored to support 'all-or-nothing' validation
                        onClick({field: 'density', productId: product.id});
                    },
                    reportingTypeLabel: 'refining'
                })
            },
            tooltip: {
                content: INFO_PREVIOUSLY_REPORTED_COMMENTS_REFINING,
                target: 'density',
            }
        });
    }

    return infoMessages.sort((a, b) => fieldDisplayOrder[a.tooltip.target] - fieldDisplayOrder[b.tooltip.target]);
}
