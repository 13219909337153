import produce from "immer";
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";

import { useLogger } from "psims/react/providers/logging";
import useConfirmNavigation from "psims/react/util/use-confirm-navigation";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { asNumber, numberFieldValue } from "psims/lib/number";
import { is } from "psims/lib/type-assertions";
import { ViewMode } from "../shared/use-view-mode";
import { attemptFocusOnSelector, attemptScrollToSelector } from "psims/react/pages/primary-pages/data-submissions/shared/view-utils";
import { SELECTOR_NOTIFICATION, SELECTOR_NOTIFICATIONS } from "psims/constants/selectors";
import { isEmpty } from "psims/lib/empty";
import { recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { UseTemplateImport } from "psims/react/blocks/import/use-template-import";
import { assertArrayOfType } from "psims/lib/collections";
import { UseProductionForm } from "../productions-shared/use-production-form";
import { ProductionImportState } from "psims/react/blocks/import/types";
import { UseProductionAPI } from "./use-production-api";
import { UseProductionProgress } from "./use-production-progress";
import { UseProductionServiceResponse } from "./use-production-service-response";
import { UseProductionValidationAlerts } from "./use-production-validation-alerts";
import { assertUpdateProduction, ProductionDataSubmissionName, ProductionSubmission, UpdateProductionDraft, UpdateProductionSubmission } from "psims/models/submission-types/production";
import { buildUpdateDataSubmissionRequest, SHORTNAME_MAP } from "./util";
import { encodeEscapeChars } from "psims/lib/validation/string";

interface UseProductionSaveProps {
    apiCtrl: UseProductionAPI;
    formCtrl: UseProductionForm;
    importCtrl: UseTemplateImport<ProductionImportState>;
    productionTypeName: ProductionDataSubmissionName;
    progressCtrl: UseProductionProgress;
    serviceResponse: UseProductionServiceResponse;
    validationAlerts: UseProductionValidationAlerts;
    viewMode: ViewMode;
}

type UnsavedChangesState = 'idle' | 'showing_dialog' | 'saving' | 'save_success' | 'save_failed';

type ValidationAlertsState = 'idle' | 'showing_dialog' | 'confirmed' | 'cancelled';

function useProductionSave({apiCtrl, formCtrl, productionTypeName, progressCtrl, serviceResponse, importCtrl, validationAlerts, viewMode}: UseProductionSaveProps) {
    const shortName = SHORTNAME_MAP[productionTypeName];
    const logger = useLogger({source: `use${shortName}ProductionSave`});
    const loggerRef = useUpdatedRef(logger);
    const [unsavedChangesState, dispatchUnsavedChangesAction] = useReducer(unsavedChangesReducer, 'idle');
    const [validationAlertsState, setValidationAlertsState] = useState<ValidationAlertsState>('idle');
    const requestedPageIndex = useRef<number | null>(null);
    const navPrompt = useConfirmNavigation({
        when: formCtrl.view.hasUnsavedChanges,
    });

    const navigateToRequestedPage = useCallback(() => {
        if (requestedPageIndex.current != null) {
            const isValid = progressCtrl.currentStep.kind === 'submit' ?
                formCtrl.view.Submit.isValid :
                formCtrl.view.dataPageView.isValid;
            if (isValid) {
                progressCtrl.goToStep(requestedPageIndex.current);
            } else {
                attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
            }
            requestedPageIndex.current = null;
        }
    }, [formCtrl.view.Submit.isValid, formCtrl.view.dataPageView.isValid, progressCtrl]);

    const attemptNavigation = useCallback((stepIndex: number) => {
        requestedPageIndex.current = stepIndex;
        progressCtrl.onNavAttempt();
        if (
            viewMode === 'edit' &&
            validationAlerts.validationAlertsForCurrentStep.length > 0 &&
            formCtrl.view.dataPageView.isValid
        ) {
            setValidationAlertsState('showing_dialog');
            return;
        }

        navigateToRequestedPage();
    }, [
        formCtrl.view.dataPageView.isValid,
        navigateToRequestedPage,
        progressCtrl,
        validationAlerts.validationAlertsForCurrentStep,
        viewMode,
    ]);

    const setImportStepSaved = useCallback(() => {
        const {currentStep} = progressCtrl;
        if (viewMode !== 'view' && importCtrl && importCtrl.templateImportState.templateImportDialogState === 'processing') {
            importCtrl.saveStep(currentStep.name as string);
            importCtrl.setTemplateImportState(prev => produce(prev, draft => {
                draft.unsavedChanges = !prev.data?.pageData.pageSaved;
            }));
        }        
    }, [importCtrl, progressCtrl, viewMode]);

    const doImportTemplateSaved = useMemo(() => {
        return viewMode !== 'view' && importCtrl && importCtrl.templateImportState.templateImportDialogState === 'processing';
    }, [importCtrl, viewMode]);
    
    const save = useCallback((thenPageIndex?: number) => {
        const {currentStep} = progressCtrl;

        if (apiCtrl.submission == null) {
            loggerRef.current.warn('Failed to save submission - null submission from API controller');
            return;
        }
        
        if (thenPageIndex != null) {
            requestedPageIndex.current = thenPageIndex;
        }

        dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'saving'});

        if (currentStep.kind === 'submit') {
            let request = buildUpdateDataSubmissionRequest(formCtrl, apiCtrl.submission);
            
            if (doImportTemplateSaved) {
                request = {...request, isTemplateImport: true};
            }
            apiCtrl.updateDataSubmission(request);
            setImportStepSaved();
        } else {
            let request = buildUpdateProductionRequest(
                formCtrl,
                apiCtrl.submission,
            );
            
            if (doImportTemplateSaved) {
                request = {...request, isTemplateImport: true};
            }
            apiCtrl.update(request);
            setImportStepSaved();
            if (navPrompt.showPrompt) {
                navPrompt.handleDecision(true);
            }
        }
    }, [doImportTemplateSaved, apiCtrl, formCtrl, loggerRef, navPrompt, progressCtrl, setImportStepSaved]);

    const saveAndGoToPage = useCallback((index: number) => {       
        progressCtrl.onNavAttempt();

        const isValid = progressCtrl.currentStep.kind === 'submit' ?
            formCtrl.view.Submit.isValid :
            formCtrl.view.dataPageView.isValid;

        if (!isValid) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
            return;
        }
        requestedPageIndex.current = index;
        save();
    }, [formCtrl.view, progressCtrl, save]);

    const cancelSave = useCallback(() => {
        requestedPageIndex.current = null;
        dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
        if (navPrompt.showPrompt) {
            navPrompt.handleDecision(false);
        }
    }, [navPrompt]);

    const confirmSave = useCallback(() => {
        if (navPrompt.showPrompt) {
            const isValid = progressCtrl.currentStep.kind === 'submit' ?
                formCtrl.view.Submit.isValid :
                formCtrl.view.dataPageView.isValid;
            if (!isValid) {
                dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
                navPrompt.handleDecision(false);
            } else {
                save();
            }
        } else if (requestedPageIndex.current != null) {
            saveAndGoToPage(requestedPageIndex.current);
        } else  {
            loggerRef.current.warn('Unexpected null page index when attempting to confirm save');
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
        }
    }, [
        formCtrl.view,
        loggerRef,
        navPrompt,
        progressCtrl.currentStep.kind,
        save,
        saveAndGoToPage
    ]);

    const proceedWithoutSave = useCallback(() => {
        formCtrl.resetForm();
        if (navPrompt.showPrompt) {
            navPrompt.handleDecision(true);
        } else if (requestedPageIndex.current != null) {
            setImportStepSaved();
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
            navigateToRequestedPage();
        }
    }, [formCtrl, navPrompt, setImportStepSaved, navigateToRequestedPage]);

    const checkChangesAndGoToPage = useCallback((index: number) => {
        if (!formCtrl.view.hasUnsavedChanges) {
            attemptNavigation(index);
            return;
        }

        progressCtrl.onNavAttempt();

        const isValid = progressCtrl.currentStep.kind === 'submit' ?
            formCtrl.view.Submit.isValid :
            formCtrl.view.dataPageView.isValid;

        if (!isValid) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
            return;
        }

        // Otherwise, show dialog
        requestedPageIndex.current = index;
        dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'showing_dialog'});
    }, [attemptNavigation, formCtrl, progressCtrl]);

    // Validation alert actions
    const confirmValidationAlerts = useCallback(() => {
        setValidationAlertsState('confirmed');
    }, []);

    const cancelValidationAlerts = useCallback(() => {
        setValidationAlertsState('cancelled');
        attemptFocusOnSelector(`${SELECTOR_NOTIFICATION('info')}`);
    }, []);

    // Send all api status changes to reducer
    useEffect(() => {
        let serviceStatus: SubmissionStatusChangeValue = (serviceResponse.notOKRecordResults || []).length > 0 ?
            'update_partial':
            serviceResponse.status;
        dispatchUnsavedChangesAction({type: 'SUBMISSION_STATUS_CHANGE', value: serviceStatus});
    }, [serviceResponse]);

    // Handle save attempt result
    useEffect(() => {
        if (unsavedChangesState === 'save_failed') {
            if (navPrompt.showPrompt) {
                navPrompt.handleDecision(false);
            }
            requestedPageIndex.current = null;
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
        } else if (unsavedChangesState === 'save_success') {
            if (navPrompt.showPrompt) {
                navPrompt.handleDecision(true);
            } else if (requestedPageIndex.current != null) {
                attemptNavigation(requestedPageIndex.current);
            }
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
        }
    }, [attemptNavigation, navPrompt, unsavedChangesState, progressCtrl]);

    // Show dialog if nav prompt detects browser navigation
    useEffect(() => {
        if (navPrompt.showPrompt) {
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'showing_dialog'});
        } else {
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
        }
    }, [navPrompt.showPrompt]);

    // Handle validation alert decision
    useEffect(() => {
        switch (validationAlertsState) {
            case 'cancelled': {
                requestedPageIndex.current = null;
                setValidationAlertsState('idle');
                return;
            }

            case 'confirmed': {
                navigateToRequestedPage();
                setValidationAlertsState('idle');
                return;
            }

            default: return;
        }
    }, [navigateToRequestedPage, validationAlertsState]);

    return {
        unsavedChangesState,
        validationAlertsState,
        cancelSave,
        cancelValidationAlerts,
        checkChangesAndGoToPage,
        confirmSave,
        confirmValidationAlerts,
        proceedWithoutSave,
        saveAndGoToPage,
    }
}

export default useProductionSave

export type UseProductionSave = ReturnType<typeof useProductionSave>;

type SubmissionStatusChangeValue = UseProductionAPI['loadStatus'] | 'update_partial';

// Unsaved changes reducer actions
type SubmissionStatusChangeAction = {
    type: 'SUBMISSION_STATUS_CHANGE',
    value: SubmissionStatusChangeValue;
}

type SetStateAction = {
    type: 'SET_STATE',
    value: UnsavedChangesState;
}

type UnsavedChangesAction = SubmissionStatusChangeAction | SetStateAction;

function unsavedChangesReducer(state: UnsavedChangesState, action: UnsavedChangesAction): UnsavedChangesState {
    switch (action.type) {
        case 'SET_STATE': {
            return action.value;
        }

        case 'SUBMISSION_STATUS_CHANGE': {
            switch (state) {
                case 'saving': {
                    switch (action.value) {
                        case 'update_failed': 
                        case 'update_partial': 
                            return 'save_failed';
                        case 'updated':
                            return 'save_success';
                        default:
                            return state;
                    }
                }
                default: {
                    return state;
                }
            }
        }
        
        default: {
            console.warn('Unknown action dispatched to unsavedChangesReducer');
            return state;
        }
    }
}

// Helpers
function buildUpdateProductionRequest(
    formCtrl: UseProductionForm,
    submission: ProductionSubmission,
): UpdateProductionSubmission {
    const pageData = formCtrl.getDataPageComments();
    const productions = formCtrl.getUpdateProductions()
        .map((production) => {
            if (production == null || production.recordAction == null) {
                return null;
            }

            return cleanProduction(production);
        })
        .filter(is);

    let pageDataCommentEncoded = pageData;
    if (pageDataCommentEncoded) {
        pageDataCommentEncoded = {
            ...pageDataCommentEncoded,
            comment: encodeEscapeChars(pageDataCommentEncoded.comment?.trim())
        }
    }
    assertArrayOfType(assertUpdateProduction, productions);

    return {
        dataSubmissionId: submission.dataSubmission.id as number,
        productions,
        pageData: pageDataCommentEncoded,
    };
}

function cleanProduction({
    productionProductId,
    closingStocks,
    concurrencyToken,
    consumed,
    delivered,
    grossCalorificValue,
    id,
    openingStocks,
    organisationProductionAreaId,
    produced,
    productionDensity,
    recordAction
}: UpdateProductionDraft): UpdateProductionDraft | null {
    const cleaned = {
        productionProductId: asNumber(productionProductId),
        closingStocks: numberFieldValue(closingStocks),
        consumed: numberFieldValue(consumed),
        ...(concurrencyToken == null ? {} : {concurrencyToken}),
        delivered: numberFieldValue(delivered),
        grossCalorificValue: numberFieldValue(grossCalorificValue),
        ...(id == null ? {} : {id: asNumber(id)}),
        openingStocks: numberFieldValue(openingStocks),
        organisationProductionAreaId: numberFieldValue(organisationProductionAreaId),
        produced: numberFieldValue(produced),
        productionDensity: numberFieldValue(productionDensity),
        recordAction,
    }

    // Remove empty rows with Create record action
    const {productionProductId: cleanedProductionProductId, recordAction: cleanedRecordAction, ...cleanedRest} = cleaned;
    if (recordActionFromEnum(cleanedRecordAction as number) === 'Create' && isEmpty(cleanedRest)) {
        return null;
    }

    return cleaned;
}
