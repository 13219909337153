import React, { useCallback } from 'react';

import Button from 'psims/react/components/button';
import { BoxedDiv } from 'psims/react/components/layout';
import Notification from 'psims/react/components/notification';
import { useNavigation } from 'psims/react/providers/router';

interface LoadSubmissionFailedProps {
    submissionName: string;
}

const LoadSubmissionFailed = ({submissionName}: LoadSubmissionFailedProps) => {
    const nav = useNavigation();

    const handleTryAgain = useCallback(() => {
        nav.goToHomePage();
    }, [nav]);

    return (
        <div className='container'>
            <BoxedDiv box={{alignItems: 'center', flex: 'column', marginV: 4}}>
                <BoxedDiv box={{alignItems: 'center', flex: 'column', width: '600px'}}>
                    <Notification align='center' kind='warning' >
                        <p>
                            There was a problem loading this {submissionName} data submission, please navigate back to your
                            {' '}<Button $kind='text' aria-label='Go back to home page' onClick={handleTryAgain}>'Home' page</Button> and try again.
                        </p>
                    </Notification>
                </BoxedDiv>
            </BoxedDiv>
        </div>
    )
}

export default LoadSubmissionFailed;
