import { deExponentialise } from "../number";

const TRAILING_PERIOD_REGEX = /\.$/;

export function localeNumberWithFixed(val: number | string | undefined | null, fixed?: number) {
    if (val == null || val === '') {
        return '';
    }

    const asNumber = Number(val);
    let asString = String(val);

    if (isNaN(asNumber) || asString[asString.length - 1] === '.') {
        return asString; 
    }

    if (fixed != null) {
        asString = deExponentialise(asNumber, fixed);
    }

    let [whole, decimal] = asString.split('.');

    // Format whole number with thousand separators
    whole = Number(whole).toLocaleString();

    return [whole, decimal].join('.').replace(TRAILING_PERIOD_REGEX, '');
}