import React from 'react';

import BaseSVG, { SVGProps } from './_base';

const FileDownload = (props: SVGProps) => {
	return (
		<BaseSVG name='file-download' viewBox='0 0 384 512' {...props}>
			<path fill='currentColor' d="M224 0H0V512H384V160H224V0zm32 0V128H384L256 0zM216 232V334.1l31-31 17-17L297.9 320l-17 17-72 72-17 17-17-17-72-72-17-17L120 286.1l17 17 31 31V232 208h48v24z"/>
		</BaseSVG>
	);
};

export default FileDownload;
