export interface FlexProps {
	alignItems?: 'baseline' | 'center' | 'flex-start' | 'flex-end' | 'stretch';
	alignSelf?: 'center' | 'flex-start' | 'flex-end' | 'stretch';
	flex?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
	flexGrow?: 1 | 2 | 3 | 4;
	flexWrap?: 'wrap' | 'wrap-reverse';
	justifyContent?: 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-evenly' | 'space-between' | 'stretch';
	justifySelf?: 'center' | 'flex-end' | 'flex-start';
}

export const flexStyle = (props: FlexProps) => ({
    ...(props.alignItems ? {alignItems: props.alignItems} : {}),
    ...(props.alignSelf ? {alignSelf: props.alignSelf} : {}),
    ...(props.flex ? {
        display: 'flex',
        flexDirection: props.flex,
    } : {}),
    ...(props.flexGrow ? {flex: props.flexGrow} : {}),
    ...(props.flexWrap ? {flexWrap: props.flexWrap} : {}),
    ...(props.justifyContent ? {justifyContent: props.justifyContent} : {}),
    ...(props.justifySelf ? {justifySelf: props.justifySelf} : {}),
});
