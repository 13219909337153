import React from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import Loading from 'psims/react/components/loading';

interface LoadingSubmissionProps {
    submissionName: string;
}

const LoadingSubmission = ({submissionName}: LoadingSubmissionProps) => {
    return (
        <BoxedDiv box={{alignItems: 'center', flex: 'column', flexGrow: 1, justifyContent: 'center'}}>
            <Loading>Loading {submissionName}</Loading>
        </BoxedDiv>
    );
}

export default LoadingSubmission;
