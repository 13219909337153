type StringLengthOptions = {
    max?: number;
    min?: number;
}

// LARGETEXT_REGEX based on regex from RDTI project (regExAlphanumericExtended) and modified to meet PSIMS requirements
// for allow alphanumeric, spaces, hypens (long & short), apostophe's (various) and keyboard characters 
const LARGETEXT_REGEX = /^(([0-9A-Za-z\- \t\n\r'\u0027\u002D\u0060\u2032\u2033\u2035\u02BC_&#4$%!?<>+:;/()+,@=^~*`"|{}[\],.:/\\])|([\u2012-\u2015])|([\u2018-\u201E]))*$/;

const ENTITY_NAME_TEXT_REGEX = /^(([0-9A-Za-z\- '\u0027\u002D\u0060\u2032\u2035\u02BC()])|([\u2012-\u2015])|([\u2018-\u201B]))*$/;

const NAME_TEXT_REGEX = /^(([0-9A-Za-z\- '\u0027\u002D\u0060\u2032\u2035\u02BC])|([\u2012-\u2015])|([\u2018-\u201B]))*$/;

const ADDRESS_TEXT_REGEX = /^(([0-9A-Za-z\- '\u0027\u002D\u0060\u2032\u2035\u02BC,.:&/\\])|([\u2012-\u2015])|([\u2018-\u201B]))*$/;


export function isValidLargeText(val: string) {
    const match = Boolean(val.match(LARGETEXT_REGEX));
    return val.length === 0 || match;
}

export function isValidNameText(val: string) {
    const match = Boolean(val.match(NAME_TEXT_REGEX));
    return val.length === 0 || match;
}

export function isValidEntityNameText(val: string) {
    const match = Boolean(val.match(ENTITY_NAME_TEXT_REGEX));
    return val.length === 0 || match;
}

export function isValidAddressText(val: string) {
    const match = Boolean(val.match(ADDRESS_TEXT_REGEX));
    return val.length === 0 || match;
}

export function isValidStringLength(val: string, opts: StringLengthOptions) {
    if (opts.max === undefined) {
        if (opts.min === undefined) {
            return true;
        }

        return val.length >= opts.min
    }

    if (opts.min === undefined) {
        return val.length <= opts.max;
    }

    return val.length >= opts.min && val.length <= opts.max;
}

const escapeWordList: string[] = [
    '(o|O)(r|R)(d|D)(e|E)(r|R) (b|B)(y|Y)',
    '(i|I)(s|S) (n|N)(o|O)(t|T)',
    '(s|S)(e|E)(l|L)(e|E)(c|C)(t|T)',
    '(f|F)(r|R)(o|O)(m|M)',
    '(r|R)(e|E)(f|F)'
];

export function escapeWord(val: string) {
    let result = '';
    for (let i = 0; i < val.length; i++) {
        const charInt = val.charCodeAt(i);
        if ((charInt >= 65 && charInt <= 90) || (charInt >= 97 && charInt <= 122)) {
            result = result + "&#" + charInt + ";";
        } else {
            result = result + val[i];
        }
      }

    return result;
}

export function encodeEscapeChars(r: string | null | undefined){
    if (r === undefined || r === null) {
        return r;
    }
    let value = r.replace(/[\x26<>'"`*/()[\],~+=_?\\|}{#%^;:\-#]/g, r => {return"&#"+r.charCodeAt(0)+";"})

    escapeWordList.forEach(word => {
        const regExToUse = new RegExp(word);
        const match = value.match(regExToUse);
        if (match !== null) {
            value = value.replace(regExToUse, r => {return escapeWord(r)});
        }
    });

    return value;
}
    