import React, { useCallback, useMemo, useState } from 'react';

import { UseStockholding } from './use-stockholding';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { H3 } from 'psims/react/components/typography';
import { Table, TD, TH, THead, TR } from 'psims/react/components/table';
import { AddButton, IconButton } from 'psims/react/components/button';
import { GroupInput, GroupSelect, StyledTBody, TotalCell, useHelpForGroup, useHelpForProduct, isDeleteFocusField, FocusField } from './shared';
import { TooltipHelp } from '../shared/tooltip-help';
import { useSelectController } from 'psims/react/components/select';
import Textarea from 'psims/react/components/textarea';
import { asString } from 'psims/lib/string';
import { localeNumberWithFixed } from 'psims/lib/formatters/numbers';
import { UsePageOverseas, Product, ProductRowFormData, isRowFocusField } from './use-page-overseas';
import { recordActionFromEnum } from 'psims/models/api/data-submission-record-action';
import { isEmpty } from 'psims/lib/empty';
import ReactMarkdown from 'react-markdown';
import FloatingMessage from 'psims/react/components/floating-message';
import { EXPIRED_OR_INTERNAL_PRODUCT } from 'psims/constants/validation-messages';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import useFocusable from 'psims/react/util/use-focusable';
import { ColumnHeader, DeleteActionCellTD, DeleteActionCellTH, DeleteColumnHeader } from '../shared/data-table-components';

interface OverseasEditorProps {
    ctrl: UseStockholding;
}

const OverseasEditor = ({ctrl}: OverseasEditorProps) => {
    const {overseasCtrl} = ctrl;
    const {view} = overseasCtrl;

    const isCommentsFocused = useMemo(() => {
        const focusedField = overseasCtrl.focusedFieldCtrl.focusedField;

        return focusedField === 'comments';
    }, [overseasCtrl.focusedFieldCtrl.focusedField]);

    return (
        <BoxedDiv box={{flex: 'column'}}>
            {
                view.groups
                .filter(g => g.groupStatus !== 'expired' && g.groupStatus !== 'empty')
                .map((spg, i) => (
                    <ProductGroupTable
                        key={spg.productGroup.id}
                        stockholdingCtrl={ctrl}
                        group={spg}
                        groupIndex={i}
                    />
                ))
            }

            <BoxedDiv box={{marginV: 2}}>
                <Textarea
                    error={ctrl.disableInputs ? undefined : view.comments.errorMessage}
                    disabled={ctrl.disableInputs}
                    forceError={true}
                    id='comments_overseas'
                    label={`Comments${!view.isCommentsRequired ? ' (optional)' : ''}`}
                    value={(asString(view.comments.data?.comments))}
                    rows={3}
                    setFocused={isCommentsFocused}
                    onChange={e => overseasCtrl.updateComments(e.target.value)}
                />
            </BoxedDiv>
        </BoxedDiv>
    );
}

interface ProductGroupTableProps {
    stockholdingCtrl: UseStockholding;
    group: ArrayElement<UsePageOverseas['view']['groups']>;
    groupIndex: number;
}

const ProductGroupTable = ({stockholdingCtrl, group, groupIndex}: ProductGroupTableProps) => {
    const {portalDataAPICtrl} = stockholdingCtrl.overseasCtrl;

    const hasExpiredData = (
        !stockholdingCtrl.disableInputs &&
        group.products.some(p => p.productStatus === 'expired_with_data')
    );

    const helpForGroup = useHelpForGroup(portalDataAPICtrl, group.productGroup, 'Overseas');

    return (
        <BoxedDiv box={{flex: 'column', marginV: 2, overflowX: 'hidden'}}>
            <BoxedDiv box={{flex: 'column', alignSelf: 'stretch', overflowX: 'auto'}}>
                <H3 marginBottom={2}>
                    <BoxedDiv box={{flex: 'row', alignItems: 'center'}}>
                        {group.productGroup.name}
                        {
                            (helpForGroup) &&
                            <TooltipHelp
                                Help={<ReactMarkdown>{helpForGroup}</ReactMarkdown>}
                            />
                        }
                    </BoxedDiv>
                </H3>

                <Table caption='Your stockholding data for overseas' compact={true} stickyHeader={true}>
                    <THead>
                        <TR>
                            <ColumnHeader label='Product' $width='250px'>Product</ColumnHeader>
                            <ColumnHeader label='Quantity' $width='264px'>Quantity</ColumnHeader>
                            <ColumnHeader label='Location' $width='350px'>Location</ColumnHeader>
                            <DeleteColumnHeader $width='60px'>Actions</DeleteColumnHeader>
                            <ColumnHeader label='Total overseas' $align='right' $width='150px'>Total overseas</ColumnHeader>                           
                            {
                                hasExpiredData ?
                                <DeleteActionCellTH>
                                    <VisuallyHidden>Actions</VisuallyHidden>
                                </DeleteActionCellTH> :
                                null
                            }
                        </TR>
                    </THead>

                    {
                        group.products
                        .filter(p => p.productStatus !== 'expired')
                        .map((product, i) => (
                            <ProductGroupTableSection
                                key={product.product.id}
                                stockholdingCtrl={stockholdingCtrl}
                                groupIndex={groupIndex}
                                product={product}
                                productIndex={i}
                            />
                        ))
                    }

                </Table>
            </BoxedDiv>
        </BoxedDiv>
    );
}

interface ProductGroupTableSectionProps {
    stockholdingCtrl: UseStockholding;
    groupIndex: number;
    product: Product;
    productIndex: number;
}

const ProductGroupTableSection = ({stockholdingCtrl, groupIndex, product, productIndex}: ProductGroupTableSectionProps) => {
    const hasExpiredData = useMemo(() => {
        return product.productStatus === 'expired_with_data';

    }, [product.productStatus]);

    const showAddButton = useMemo(() => {
        return (
            !stockholdingCtrl.disableInputs &&
            !hasExpiredData
        );
    }, [hasExpiredData, stockholdingCtrl.disableInputs]);

    return (
        <StyledTBody>
            {product.rows
            .filter(r => recordActionFromEnum(r.data.recordAction) !== 'Delete')
            .map((r, index) => (
                <ProductRow
                    autofocus={groupIndex === 0 && productIndex === 0 && r.rowIndex === 0}
                    isFirstRow={index === 0}
                    key={r.rowIndex}
                    product={product}
                    row={r}
                    stockholdingCtrl={stockholdingCtrl}
                    hasExpiredData={hasExpiredData}
                />
            ))}

            <TR>
                <TD $align='right' colSpan={3}>
                    {
                        showAddButton ?
                        <BoxedDiv box={{marginRight: 4}}>
                            <AddButton
                                label='Add location'
                                onClick={() => stockholdingCtrl.overseasCtrl.addProductRow(product.product.id)}
                            >Add location</AddButton>
                        </BoxedDiv> :
                        null
                    }
                </TD>

                <TD />
                <TD />
            </TR>

        </StyledTBody>
    )
}

interface ProductRowProps {
    autofocus: boolean;
    isFirstRow: boolean;
    product: Product;
    row: ProductRowFormData;
    stockholdingCtrl: UseStockholding;
    hasExpiredData: boolean;
}

const ProductRow = ({autofocus, isFirstRow, product, row,  stockholdingCtrl, hasExpiredData}: ProductRowProps) => {
    const [deleteRef, setDeleteRef] = useState<HTMLElement | null>(null);
    const {overseasCtrl} = stockholdingCtrl;
    const Help = useHelpForProduct(
        stockholdingCtrl.overseasCtrl.portalDataAPICtrl,
        'Overseas',
        product.product.referenceCode
    );
    
    const countrySelectCtrl = useSelectController({
        options: (stockholdingCtrl.countries || []).filter(c => c.isInternal === false || row.data.countryId === c.id).map(c => ({label: c.absCountryName, value: c.id!!})),
        onChange: (countryId => overseasCtrl.updateRow({
            rowIndex: row.rowIndex,
            value: countryId!!,
            field: 'countryId',
            stockProductId: product.product.id!!,
        })),
        value: row.data.countryId || null,
    });

    const productRowCount = product.rows.filter(r => recordActionFromEnum(r.data.recordAction) !== 'Delete').length;

    const {overseasCtrl: ctrl} = stockholdingCtrl;

    const {focusedFieldCtrl, updateRow} = ctrl;

    const rowFocusedField = useMemo(() => {
        return ((
                isRowFocusField(focusedFieldCtrl.focusedField) &&
                focusedFieldCtrl.focusedField.stockProductId === product.product.id &&
                focusedFieldCtrl.focusedField.rowIndex === row.rowIndex
            ) ?
            focusedFieldCtrl.focusedField.field :
            null);
    }, [focusedFieldCtrl.focusedField, product.product.id, row]);

    const canDelete = useMemo(() => {
        return (
            !stockholdingCtrl.disableInputs &&
            (hasExpiredData ||
                !row.validationAlerts.find(va => va.validationAlert === 'PreviouslyReportedAlert')) && (
                productRowCount > 1 ||
                !isEmpty({ quantity: row.data.quantity, countryId: row.data.countryId })
            )
        )
    }, [hasExpiredData, productRowCount, row, stockholdingCtrl.disableInputs])

    const showExpiredWarning = useMemo(() => {
        return (
            !stockholdingCtrl.disableInputs &&
            hasExpiredData
        )
    }, [stockholdingCtrl.disableInputs, hasExpiredData]);

    return <>
        <TR>
            {/* Product label for first row */}
            {
                isFirstRow &&
                <TH rowSpan={productRowCount} scope='row'>
                    <BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
                        {product.product.productName}
                        {
                            showExpiredWarning ? <BoxedSpan box={{marginBottom: -0.5, marginLeft: 1}}>
                            <FloatingMessage
                                content={EXPIRED_OR_INTERNAL_PRODUCT}
                                kind="warning"
                                role="alert"
                            />
                            </BoxedSpan> : (
                                Help && <>
                                <TooltipHelp
                                    Help={<ReactMarkdown>{Help}</ReactMarkdown>}
                                />
                            </>
                          )
                        }
                    </BoxedSpan>
                </TH>
            }

            <TD>
                <GroupInput
                    align='right'
			        aria-invalid={Boolean(row.errorMessages.quantity?.message)}
			        aria-errormessage={row.errorMessages.quantity?.message ? `product${product.product.id}_${row.rowIndex}_quantity_error` : undefined}
                    autoFocus={autofocus || row.isAddedRow}
                    bare={true}
                    disabled={stockholdingCtrl.disableInputs}
                    error={row.errorMessages.quantity?.message}
                    forceError={stockholdingCtrl.overseasCtrl.shouldForceErrors}
                    formatter={val => localeNumberWithFixed(val as number)}
                    id={`product${product.product.id}_${row.rowIndex}_quantity`}
                    info={row.infoMessages.quantity?.message}
                    label={`Quantity of ${product.product.productName} for this location`}
                    onChange={e => updateRow({
                        field: 'quantity',
                        stockProductId: product.product.id!!,
                        rowIndex: row.rowIndex,
                        value: e.target.value
                    })}
                    setFocused={rowFocusedField === 'quantity'}
                    type='number'
                    unstyled={true}
                    useTooltip={true}
                    value={row.data.quantity}
                    width='300px'
                />
            </TD>

            <TD>
                <GroupSelect
                    bare={true}
                    borderless={true}
                    isDisabled={stockholdingCtrl.disableInputs}
                    error={row.errorMessages.countryId?.message}
                    focusless={true}
                    forceError={stockholdingCtrl.validationChecked || stockholdingCtrl.overseasCtrl.shouldForceErrors}
                    id={`product${product.product.id}_${row.rowIndex}_countryid`}
                    label={`Country where this ${product.product.productName} is held`}
                    setFocused={rowFocusedField === 'countryId'}
                    value={countrySelectCtrl.value}
                    onChange={countrySelectCtrl.onChange}
                    options={countrySelectCtrl.options}
                    useTooltip={true}
                    menuPortalTarget={document.body} 
                    menuShouldBlockScroll={true}
                />
            </TD>

            <TD padding='0' $width='60px'>
                {
                    canDelete && !hasExpiredData &&
                    <BoxedSpan box={{alignItems: 'center', height: '100%', flex: 'row'}}>
                        <IconButton
                            color='dark-grey'
                            icon='trash'
                            label='delete row'
                            onClick={() => ctrl.deleteProductRow(product.product.id, row.rowIndex, deleteRef)}
                            ref={setDeleteRef}
                            size='sm'
                            styles={{padding: '4px', width: '100%'}}
                        />
                    </BoxedSpan>
                }
            </TD>

            {/* Total for first row */}
            {
                isFirstRow &&
                <TotalCell
                    info={row.infoMessages.total?.message}
                    rowSpan={productRowCount}
                    value={localeNumberWithFixed(product.total)}
                />
            }
            
            {
                (isFirstRow && showExpiredWarning) ?
                <DeleteActionCellTD rowSpan={productRowCount}>
                    {
                        <BoxedDiv box={{ alignItems: 'center', flex: 'row', marginLeft: 1 }}>
                            <DeleteRowButton
                                focusedField={focusedFieldCtrl.focusedField}
                                onClick={() => ctrl.deleteAllProductRows(product.product.id, deleteRef)}
                                productId={product.product.id}
                                ref={setDeleteRef}
                            />
                        </BoxedDiv>
                    }
                </DeleteActionCellTD> :
                null
            }
        </TR>
    </>
}

interface DeleteRowProps {
    focusedField: FocusField | null;
    onClick: () => any;
    productId: number;
}

const DeleteRowButton = React.forwardRef(({focusedField, onClick, productId}: DeleteRowProps, ref) => {
    const focusable = useFocusable({
        setFocused: (
            isDeleteFocusField(focusedField) &&
            focusedField.field === 'delete' &&
            focusedField.stockProductId === productId
        ),
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <IconButton
            color='dark-grey'
            icon='trash'
            label='Delete all rows for product'
            onClick={onClick}
            ref={setRef}
            size='sm'
        />
    );
});

export default OverseasEditor;
