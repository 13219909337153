import { getRecordResults, isMsoImporter, isMsoImporterSubmitted, MaybeMsoImporterSubmission, MsoImporterSubmission } from "psims/models/submission-types/mso/mso-importer-submission";
import { useAPI } from "psims/react/providers/api";
import useDataSubmissionAPI from "psims/react/pages/primary-pages/data-submissions/shared/use-data-submission-api";
import { isSuccesfulRowResult } from "psims/models/api/record-result";

interface UseMsoImportAPIProps {
    id: number;
}

function useMsoImporterAPI({id}: UseMsoImportAPIProps) {
    const {api} = useAPI();

    return useDataSubmissionAPI({
        actionFetch: api.getMsoImporterSubmission,
        actionSubmit: api.submitMsoImporter,
        actionUpdate: api.updateMsoImporter,
        actionUpdateDataSubmission: api.updateMsoImporterDataSubmission,
        actionClearAllData: api.clearAllMsoImporter,
        dataSubmissionId: id,
        name: 'MSO Importer',
        submissionAssertion: isMsoImporter,
        submitSuccessAssertion: isMsoImporterSubmitted,
        updateResponseTransform: removeDeleted,
        updateSuccessAssertion,
    });
}

export default useMsoImporterAPI;

export type UseMsoImporterAPI = ReturnType<typeof useMsoImporterAPI>;

function updateSuccessAssertion(result: MaybeMsoImporterSubmission | null | undefined) {
    if (result == null) {
        return false;
    }

    return getRecordResults(result)
        .filter(r => !isSuccesfulRowResult(r)).length === 0;
}

function removeDeleted(result: MsoImporterSubmission): MsoImporterSubmission {
    const transformed = {
        ...result,
        stockholdings: result.stockholdings.filter(s => s.recordResult?.rowResult !== 'Deleted'),
    };

    return transformed;
}