import { useCallback, useEffect, useMemo, useState } from "react";
import { UseRefineryAPI } from "./use-refinery-api";
import { UseRefineryForm } from "./use-refinery-form";
import { UseRefineryProgress } from "./use-refinery-progress";
import { buildUpdateDataSubmissionRequest } from './shared';
import { useNavigation } from "psims/react/providers/router";
import { asNumber } from "psims/lib/number";
import { isEmpty } from "psims/lib/empty";
import { INVALID_DECLARATION } from "psims/constants/validation-messages";
import { attemptFocusOnSelector } from "../shared/view-utils";
import { SELECTOR_NOTIFICATIONS } from "psims/constants/selectors";

interface UseRefinerySubmitProps {
    apiCtrl: UseRefineryAPI;
    formCtrl: UseRefineryForm;
    progressCtrl: UseRefineryProgress;
}

function useRefinerySubmit({apiCtrl, formCtrl, progressCtrl}: UseRefinerySubmitProps) {
    const nav = useNavigation();
    const [declarationError, setDeclarationError] = useState<string | null>(null);
    const [shownUnsavedPages, setShownUnsavedPages] = useState<Array<{label: string, index: number}>>([]);
    const {submission, isBusy, submit: apiSubmit, loadStatus} = apiCtrl;

    const unsavedPages = useMemo(() => {
        if (submission == null || submission === undefined) {
            return [];
        }

        const {submissionFormData} = submission;

        return [
            ...(submissionFormData.gasesIntermediatePetrochemLossesSaved !== true ? [{
                label: 'Gases, intermediates, petrochemical feedstocks & losses',
                index: 2,
            }] : []),
            ...(submissionFormData.refineryInputsPageSaved !== true ? [{
                label: 'Refinery input',
                index: 0,
            }] : []),
            ...(submissionFormData.refineryOutputsPageSaved !== true ? [{
                label: 'Refinery output of finished products', 
                index: 1,
            }] : []),
        ]
    }, [submission]);

    const submit = useCallback(() => {
        if (!formCtrl.view.Submit.declaration) {
            setDeclarationError(INVALID_DECLARATION);
            return;
        }

        const isSubmitCommentRequired = submission?.dataSubmission?.validationAlerts && submission?.dataSubmission?.validationAlerts.filter(a => a.validationAlert === 'SameAsPrevious').length > 0 ? true : false;
        if (isSubmitCommentRequired && isEmpty(formCtrl.view.Submit.comments)) {
            return;
        }

        if (unsavedPages.length > 0) {
            setShownUnsavedPages(unsavedPages);
            return;
        }

        if (formCtrl.view.hasAnyExpiredData) {
            attemptFocusOnSelector(SELECTOR_NOTIFICATIONS);
            return;
        }

        if (isBusy || submission == null) {
            return;
        }

        const request = buildUpdateDataSubmissionRequest(formCtrl, submission)
        apiSubmit(request);
    }, [apiSubmit, formCtrl, isBusy, submission, unsavedPages]);

    useEffect(() => {
        // Clear declaration and unsaved errors on page entry
        if (progressCtrl.currentStep.kind === 'submit') {
            setDeclarationError(null);
            setShownUnsavedPages([]);
        }
    }, [progressCtrl.currentStep]);

    useEffect(() => {
        if (formCtrl.view.Submit.declaration) {
            // Clear declaration error when user has agreed to terms
            setDeclarationError(null);
        }
    }, [formCtrl.view.Submit.declaration]);
    
    useEffect(() => {
        if (loadStatus === 'submitted') {
            // Set timeout gives the navigation confirmation a frame to unblock
            setTimeout(() => {
			    nav.goToReportSubmittedPage(asNumber(submission?.dataSubmission.id));
            }, 0);
        }
    }, [loadStatus, submission, nav]);

    return {
        declarationError,
        submit,
        unsavedPages: shownUnsavedPages,
    };
}

export default useRefinerySubmit

export type UseRefinerySubmit = ReturnType<typeof useRefinerySubmit>;
