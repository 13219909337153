import React, { useMemo } from 'react';

import FileExcel from 'psims/react/components/icons/file-excel';
import styled from 'styled-components';
import FileWord from 'psims/react/components/icons/file-word';
import FilePDF from 'psims/react/components/icons/file-pdf';
import FileUnknown from 'psims/react/components/icons/file-unknown';

interface FileIconProps {
  filename: string;
}

const Excel = styled(FileExcel)`
  color: var(--color-green-100);
`;

const PDF = styled(FilePDF)`
  color: var(--color-red-100);
`;

const Word = styled(FileWord)`
  color: var(--color-blue-100);
`;

const Unknown = styled(FileUnknown)`
  color: var(--color-black-100);
`;

const FileIcon = (props: FileIconProps) => {
  const vm = useVM(props);

  switch (vm.extension) {
    case 'docx':
      return <Word size='md' />;
    case 'pdf':
      return <PDF size='md' />;
    case 'xlsx':
      return <Excel size='md' />;
    default:
      return <Unknown size='md' />
  }
}

function useVM({filename}: FileIconProps) {
  const extension = useMemo(() => {
    const fileExtSeparatorIndex = filename.lastIndexOf('.');
    if (fileExtSeparatorIndex === -1) {
      return 'unkown';
    }
    return filename.substring(fileExtSeparatorIndex + 1);
  }, [filename]);

  return {
    extension,
  };
}

export default FileIcon;
