import { MsoImporterSubmissionVM, UpdateMsoCommentVM, UpdateMsoImporterSubmissionVM } from "psims/gen/xapi-client";
import { isArrayOfType } from "psims/lib/collections";
import { isRecordResult, RecordResult } from "psims/models/api/record-result";
import { WithUpdatable } from "psims/models/api/update";
import { isDataSubmission, isSubmittedDataSubmission } from "psims/models/data-submission";
import { WithDataSubmission } from "../data-submission";
import { isMso } from "./mso";
import { isMsoStockOnBehalf, UpdateMsoStockOnBehalf } from "./mso-stock-on-behalf";
import { isMsoStockholding, UpdateMsoStockholding } from "./mso-stockholding";
import { isMsoSubmissionFormData } from "./mso-submission-form-data";
import { isOrganisationMsoSetting } from "./organisation-mso-setting";

export type MaybeMsoImporterSubmission = MsoImporterSubmissionVM;

export type MsoImporterSubmission = WithDataSubmission<'MSO importer', WithUpdatable<
    PopulatedProps<MaybeMsoImporterSubmission, 'dataSubmission' | 'msoComment' | 'msoSettings' | 'msos' | 'stockholdings' | 'stocksOnBehalf' | 'submissionFormData'>
>>;

export function isMsoImporter(maybe?: MaybeMsoImporterSubmission | null): maybe is MsoImporterSubmission {
    return (
        maybe != null &&
        isDataSubmission(maybe.dataSubmission, 'MSO importer') &&
        isMsoSubmissionFormData(maybe.submissionFormData) &&
        isArrayOfType(isMso, maybe.msos) &&
        isArrayOfType(isMsoStockholding, maybe.stockholdings) &&
        isArrayOfType(isMsoStockOnBehalf, maybe.stocksOnBehalf) &&
        isArrayOfType(isOrganisationMsoSetting, maybe.msoSettings)
    );
}

export function isMsoImporterSubmitted(maybe?: MaybeMsoImporterSubmission | null | undefined): maybe is MaybeMsoImporterSubmission & {dataSubmission: {status: 'Submitted'}} {
    return isMsoImporter(maybe) && isSubmittedDataSubmission(maybe.dataSubmission);
}

type MaybeUpdateMsoImporterSubmission = UpdateMsoImporterSubmissionVM;

export type UpdateMsoImporterSubmission = PopulatedProps<MaybeUpdateMsoImporterSubmission, "dataSubmissionId" | "msos"> & {
    msoComment: UpdateMsoCommentVM;
    stockholdings: Array<UpdateMsoStockholding>;
    stocksOnBehalf: Array<UpdateMsoStockOnBehalf>;
};

export function getRecordResults(maybe?: MaybeMsoImporterSubmission): Array<RecordResult> {
    if (!isMsoImporter(maybe)) {
        return [];
    }

    return [
        ...[maybe.dataSubmission?.recordResult].filter(isRecordResult),
        ...[maybe.msoComment?.recordResult].filter(isRecordResult),
        ...(maybe.msos || []).map(mso => mso.recordResult).filter(isRecordResult),
        ...(maybe.stockholdings || []).map(s => s.recordResult).filter(isRecordResult),
        ...(maybe.stocksOnBehalf || []).map(s => s.recordResult).filter(isRecordResult),
    ];
}
