import { useCallback, useMemo, useState } from "react"

import { isReportingObligation, ReportingObligation } from "psims/models/reporting-obligation"
import CompanyHeader from "psims/react/blocks/company-header"
import Button from "psims/react/components/button"
import Close from "psims/react/components/icons/close"
import { BoxedDiv, BoxedSpan } from "psims/react/components/layout"
import Loading from "psims/react/components/loading"
import { Table, TBody, TD, TH, THead, TR } from "psims/react/components/table"
import Text from "psims/react/components/text"
import { H1, H3 } from "psims/react/components/typography"
import VisuallyHidden from "psims/react/components/visually-hidden"
import { useAPI } from "psims/react/providers/api"
import { useReportingObligations } from "psims/react/providers/api/reporting-obligations"
import { Status } from "psims/types/async"
import { TooltipHelp } from "../data-submissions/shared/tooltip-help"
import Notification from 'psims/react/components/notification';
import UL, { ArrowLI } from "psims/react/components/unordered-list"
import SupportLink from "psims/react/components/support-link"
import { DataSubmissionTypeName } from "psims/models/data-submission"

const RequestTemplatesPage = () => {
  const vm = useVM();

  return (
    <BoxedDiv box={{ alignSelf: 'stretch', flex: 'column', flexGrow: 1 }} >
      <CompanyHeader />

      <BoxedDiv className='container' box={{ alignSelf: 'stretch', flex: 'column', flexGrow: 1 }} >
        <H1 marginBottom={1} marginTop={4}>Request templates</H1>
        {
          vm.tableStatus === 'ready' &&

          <BoxedDiv box={{ alignItems: 'stretch', flex: 'column' }}>
            {/* Request result notification */}
            {
              vm.notificationStatus === 'success' &&
              <BoxedDiv box={{ marginTop: 5 }}>
                <Notification align='flex-start' kind='confirmation'>
                  <BoxedDiv box={{ flex: 'column', paddingTop: 1 }}>
                    <BoxedDiv box={{ flex: 'row', justifyContent: 'space-between' }}>
                      <H3>Your request has been successful</H3>

                      <Button
                        $kind='unstyled'
                        marginLeft={-2}
                        marginRight={2}
                        onClick={vm.onDismissNotification}
                      >
                        <Text $color='blue-70' weight='semibold'>
                          <BoxedSpan box={{ alignItems: 'center', flex: 'row' }}>
                            <Close size='md' />
                          </BoxedSpan>
                        </Text>
                      </Button>
                    </BoxedDiv>

                    <BoxedDiv box={{ marginTop: 2 }}>
                      <UL padding='0'>
                        <ArrowLI color='primary'>
                          An email with the template will be sent to you and the registered contacts for your organisation shortly. If you haven't received the email in 15 minutes, try again or contact <SupportLink>PSIMSSupport@industry.gov.au</SupportLink>.
                        </ArrowLI>
                      </UL>
                    </BoxedDiv>
                  </BoxedDiv>
                </Notification>
              </BoxedDiv>
            }

            {
              vm.notificationStatus === 'fail' &&
              <BoxedDiv box={{ marginTop: 5 }}>
                <Notification align='flex-start' kind='warning'>
                  <BoxedDiv box={{ flex: 'column', paddingTop: 1 }}>

                    <BoxedDiv box={{ flex: 'row', justifyContent: 'space-between' }}>
                      <H3>Your request has failed</H3>

                      <Button
                        $kind='unstyled'
                        marginLeft={-2}
                        marginRight={2}
                        onClick={vm.onDismissNotification}
                      >
                        <Text $color='blue-70' weight='semibold'>
                          <BoxedSpan box={{ alignItems: 'center', flex: 'row' }}>
                            <Close size='md' />
                          </BoxedSpan>
                        </Text>
                      </Button>
                    </BoxedDiv>

                    <BoxedDiv box={{ marginTop: 2 }}>
                      <UL padding='0'>
                        <ArrowLI color='primary'>
                          Please try again, or contact <SupportLink>PSIMSSupport@industry.gov.au</SupportLink> if the problem persists.
                        </ArrowLI>
                      </UL>
                    </BoxedDiv>
                  </BoxedDiv>
                </Notification>
              </BoxedDiv>
            }

            <BoxedDiv box={{ alignSelf: 'stretch', marginTop: 2.5 }}>
              <Table caption='Reporting obligations for your connected company(s)' fullWidth={true} stickyHeader={true}>
                <THead>
                  <TR>
                    <TH scope='col'>Data submission</TH>
                    <TH $align='center' scope='col'>
                      <BoxedSpan box={{ alignItems: 'center', flex: 'row' }}>
                        Reported by
                        <TooltipHelp
                          Help={'This is the company (which may be a subsidiary) which collects the data on behalf of the head company.'}
                        />
                      </BoxedSpan>
                    </TH>
                    <TH scope='col' $width="200px"><VisuallyHidden>Request template action</VisuallyHidden></TH>
                  </TR>
                </THead>

                <TBody>
                  {
                    vm.reportingObligations !== null && vm.reportingObligations.map(ro => <TemplateRequestRow
                      key={ro.id}
                      reportingObligation={ro}
                      onRequestResult={vm.onRequestResult}
                    />)
                  }
                </TBody>
              </Table>
            </BoxedDiv>
          </BoxedDiv>
        }

        {
          vm.tableStatus === 'loading' &&

          <BoxedDiv box={{ alignItems: 'center', flex: 'column', marginV: 6 }}>
            <Loading>
              <Text>Loading data submission types...</Text>
            </Loading>
          </BoxedDiv>
        }
      </BoxedDiv>
    </BoxedDiv>
  )
}

type RequestResult = {
  result: 'success' | 'fail' | null;
  dataSubmissionTypeName: string;
}

type TableStatus = 'error' | 'loading' | 'ready';

function useVM() {
  const { reportingObligations, status } = useReportingObligations();

  const [notificationStatus, setNotificationStatus] = useState<'success' | 'fail' | null>(null);

  const tableStatus = useMemo(() => {
    return getTableStatus(reportingObligations, status);
  }, [reportingObligations, status]);

  const requiredReportingObligations = useMemo(() => {
    return (reportingObligations || [])
      .filter(isTemplateRequestable)
      .filter(r => r.isReportable)
  }, [reportingObligations]);

  const onRequestResult = useCallback((result: RequestResult | null) => {
    setNotificationStatus(result?.result || null);
  }, []);

  const onDismissNotification = useCallback(() => {
    setNotificationStatus(null);
  }, []);

  return {
    notificationStatus,
    onDismissNotification,
    onRequestResult,
    reportingObligations: requiredReportingObligations,
    tableStatus,
  };
}


interface TemplateRequestRowProps {
  onRequestResult: (result: RequestResult | null) => any;
  reportingObligation: ReportingObligation;
}

type TemplateRequestStatus = 'not_requested' | 'loading' | 'failed' | 'successfully_requested';

const TemplateRequestRow = ({ onRequestResult, reportingObligation }: TemplateRequestRowProps) => {
  const { api } = useAPI();

  const [status, setStatus] = useState<TemplateRequestStatus>('not_requested');

  const handleRequestDataTemplate = useCallback(() => {
    setStatus('loading');
    onRequestResult(null);
    api.dataSubmissionTemplateRequest({
      requestBody: {
        dataSubmissionTypeId: reportingObligation.submissionTypeId,
        reportingOrganisationId: reportingObligation.reportingOrganisationId,
        responsibleOrganisationId: reportingObligation.responsibleOrganisationId,
      }
    })
      .then(res => {
        setStatus('successfully_requested');
        onRequestResult({
          dataSubmissionTypeName: String(reportingObligation.submissionTypeName),
          result: 'success',
        });
      })
      .catch(e => {
        setStatus('failed');
        onRequestResult({
          dataSubmissionTypeName: String(reportingObligation.submissionTypeName),
          result: 'fail',
        });
      });
  }, [
    api,
    onRequestResult,
    reportingObligation.submissionTypeId,
    reportingObligation.submissionTypeName,
    reportingObligation.reportingOrganisationId,
    reportingObligation.responsibleOrganisationId
  ]);

  return (
    <TR key={reportingObligation.id}>
      <TH scope='row'><strong>{reportingObligation.submissionTypeName}</strong></TH>
      <TD>{reportingObligation.reportingOrganisationName}</TD>
      <TD>
        {
          (status === 'not_requested' || status === 'failed') ?
            <Button
              $kind="text"
              onClick={handleRequestDataTemplate}
            >Request template</Button> :
            (status === 'loading' ?
              <Text>Requesting template...</Text> :
              <Text>Template sent via email</Text>
            )
        }
      </TD>
    </TR>
  );
}

function getTableStatus(reportingObligations: Array<ReportingObligation> | null, reportingObligationsStatus: Status): TableStatus {
  if (reportingObligations !== null) {
    return 'ready';
  }

  if (reportingObligationsStatus === 'error') {
    return 'error';
  }

  return 'loading';
}

const REQUESTABLE_SUBMISSION_TYPE_NAMES: Array<DataSubmissionTypeName> = [
  'Biofuel production',
  'Field production',
  'Plant production',
  'Refining',
  'Stockholding',
  'Wholesaling',
];

function isTemplateRequestable(reportingObligation: unknown) {
  if (isReportingObligation(reportingObligation)) {
    return REQUESTABLE_SUBMISSION_TYPE_NAMES.includes(reportingObligation.submissionTypeName);
  }

  return false;
}

export default RequestTemplatesPage;
