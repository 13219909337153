import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import api from 'psims/services/api';
import { useAuth } from '../auth';
import { useLogger } from '../logging';
import useUpdatedRef from 'psims/react/util/use-updated-ref';
import { isBadGatewayError } from 'psims/lib/server-error';

type Status = 'not_ready' | 'ready';

type Context = {
    api: typeof api.api;
    status: Status;
}

const ctx = createContext<Context | null>(null);

const APIProvider = ({children}: PropsWithChildren<{}>) => {
    const {getToken} = useAuth();
    const logger = useLogger({source: 'APIProvider'});
    const [apiInstance, setApiInstance] = useState({...api.api});
    const [status, setStatus] = useState<Status>('not_ready');

    const loggerRef = useUpdatedRef(logger);

    useEffect(() => {
        api.config.setServiceErrorHandler(e => {
            loggerRef.current.exception(e);

            if (isBadGatewayError(e)) {
                window.location.pathname = '/bad-gateway';
            }
            throw e;
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getToken) {
            api.config.setAccessTokenGetter(getToken);
            setStatus('ready');
        } else {
            setStatus('not_ready');
        }

        setApiInstance({...api.api})
    }, [getToken]);

    return <ctx.Provider value={{api: apiInstance, status}}>{children}</ctx.Provider>
}

export default APIProvider;

export function useAPI() {
	const c = useContext(ctx);

	if (c === null) {
		throw new Error('useAPI must be used in APIProvider');
	}

	return c;
}
