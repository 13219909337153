import Header from 'psims/react/blocks/data-submission-header';
import { BoxedDiv } from 'psims/react/components/layout';
import { UseMsoRefinerAPI } from './use-mso-refiner-api';
import useMsoRefiner from './use-mso-refiner';
import SubmissionProgressBar from 'psims/react/components/submission-progress-bar';
import MsoRefinerDataEditor from './mso-refiner-data-editor';
import SubmitPage from '../../shared/submit-page';
import Button from 'psims/react/components/button';
import Notifications from '../../shared/notifications';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';
import ConfirmSaveChanges from 'psims/react/components/confirm-save-changes';
import NotificationMessage from '../../shared/notification-message';
import ScrollTo from 'psims/react/components/scroll-to';
import { H2 } from 'psims/react/components/typography';
import DataSubmissionPrimaryControls from 'psims/react/components/data-submission-primary-controls';
import useSubmissionProcessingOverlay from 'psims/react/pages/primary-pages/data-submissions/shared/use-submission-processing-overlay';
import MainContainer from 'psims/react/pages/primary-pages/data-submissions/shared/main-container';

interface MsoRefinerEditorProps {
    apiCtrl: PopulatedProps<UseMsoRefinerAPI, 'submission'>;
}

const MsoRefinerEditor = (props: MsoRefinerEditorProps) => {
    const vm = useVM(props);

    const {primaryControl, secondaryControl} = vm.controlsCtrl;
    const {confirmCtlr, handleClearData} = vm.clearDataCtrl;

    const dataPage = vm.progressCtrl.currentStep.index === 0;
    
    return (
        <MainContainer
            dataSubmission={vm.apiCtrl.submission.dataSubmission}
        >
            <BoxedDiv box={{}}>
                <Header
                    customName='Minimum stockholding obligation - Refiner'
                    dataSubmission={vm.dataSubmission} 
                    showExtraButtons={dataPage && vm.viewMode === 'edit'} 
                    handleClearData={handleClearData}  
                    currentStepIndex={vm.progressCtrl.currentStep.index}              
                />

                {/* Progress indicator */}
                <BoxedDiv box={{marginV: 6}}>
                    <SubmissionProgressBar
                        isComplete={vm.viewMode === 'view'}
                        steps={vm.progressCtrl.progressSteps}
                        onStepClick={vm.navCtrl.attemptStepNavigation}
                    />
                </BoxedDiv>

                <BoxedDiv box={{}} className='container'>
                    {/* Switch on current page */}
                    {
                        vm.progressCtrl.currentStep.index === 0 &&
                        <MsoRefinerDataEditor
                            apiCtrl={vm.apiCtrl}
                            deleteCtrl={vm.deleteCtrl}
                            focusedFieldCtrl={vm.focusFieldCtrl}
                            formCtrl={vm.formCtrl}
                            isDisabled={vm.isDisabled}
                            navCtrl={vm.navCtrl}
                            portalDataCtrl={vm.portalDataCtrl}
                            refData={vm.refData}
                            validationCtrl={vm.validationCtrl}
                            validationAlertsCtrl={vm.validationAlertsCtrl}
                        />
                    }

                    {
                        vm.progressCtrl.currentStep.index === 1 &&
                        <>
                        <BoxedDiv box={{marginTop: 2}}>
                            <H2>Declaration</H2>
                        </BoxedDiv>

                        <SubmitPage
                            ctrl={{
                                ...vm.submitCtrl,
                                isDisabled: vm.isDisabled,
                                commentsError: undefined,
                                dataSubmission: vm.apiCtrl.submission.dataSubmission,
                            }}
                            focusedFieldCtrl={vm.focusFieldCtrl}
                            isMso={true}
                            viewMode={vm.viewMode}
                        />
                        </>
                    }

                    {/* Notifications */}
                    {
                        !vm.isDisabled &&
                        <Notifications
                            infoNotifications={vm.validationAlertsCtrl.map(va => va.notification)}
                            systemNotifications={vm.serviceMessages.map(m => ({content: NotificationMessage({parts: [m]})}))}
                            validationNotifications={
                                vm.progressCtrl.currentStep.kind === 'data' ?
                                vm.validationCtrl.notifications :
                                [...vm.submitCtrl.notifications, ...vm.validationCtrl.notifications]
                            }
                        />
                    }

                    {/* Controls */}
                    <DataSubmissionPrimaryControls>
                        <BoxedDiv box={{alignItems: 'center', flex: 'row-reverse', marginTop: 2}}>
                            {
                                primaryControl.isShown &&
                                <Button
                                    disabled={primaryControl.isDisabled}
                                    $kind='primary'
                                    marginLeft={2}
                                    onClick={primaryControl.action}
                                    state={primaryControl.state}
                                >{primaryControl.label}</Button>
                            }

                            {
                                secondaryControl.isShown &&
                                <Button
                                    disabled={secondaryControl.isDisabled}
                                    $kind='ghost'
                                    onClick={secondaryControl.action}
                                    state={secondaryControl.state}
                                >{secondaryControl.label}</Button>
                            }
                        </BoxedDiv>
                    </DataSubmissionPrimaryControls>

                </BoxedDiv>

                {/* Confirm Clear all dialog */}
                {
                    dataPage &&
                    <ConfirmationDialog
                        ariaLabel='Clear all data confirm'
                        body={confirmCtlr.message}
                        title={confirmCtlr.title}
                        onCancel={confirmCtlr.cancel}
                        onConfirm={confirmCtlr.confirm}
                        isOpen={confirmCtlr.confirmState === 'confirming'}
                    />
                }

                <ConfirmationDialog
                    ariaLabel='Record delete confirmation'
                    isOpen={vm.deleteCtrl.confirmState === 'confirming'}
                    body={vm.deleteCtrl.message}
                    onCancel={vm.deleteCtrl.cancel}
                    onConfirm={vm.deleteCtrl.confirm}
                    title={vm.deleteCtrl.title}
                    confirmLabel='Yes'
                    cancelLabel='No'
                />

                <ConfirmSaveChanges {...vm.navCtrl.unsavedChangesDialogCtrl} />
                
                {/* Scroll helper */}
                <ScrollTo />
            </BoxedDiv>
        </MainContainer>
    );
}

function useVM({apiCtrl}: MsoRefinerEditorProps) {
    const ctrl = useMsoRefiner({apiCtrl});

    useSubmissionProcessingOverlay({submissionStatus: apiCtrl.loadStatus});

    const {dataSubmission} = apiCtrl.submission;

    return {
        ...ctrl,
        dataSubmission,
    };
}


export default MsoRefinerEditor;
