import { FormEvent, useMemo, useState } from "react";

import { Address } from "psims/models/contact-details";
import { User, userVMToUser } from "psims/models/user";
import { getValidationMessages, isSubmittable, newRegistration, updateAddress, updateBulk, updatePhone, UserWithContactDetails } from "psims/services/contact-registration";
import { useAPI } from "psims/react/providers/api";
import { RegisterUserVM, ValidationMessageVM } from "psims/gen/xapi-client";
import { useUser } from "psims/react/providers/user";
import { INVALID_ADDRESS_LINE1, INVALID_ADDRESS_LINE2, INVALID_PHONE_NUMBER, INVALID_POSTCODE, INVALID_STATE, INVALID_SUBURB } from "psims/constants/validation-messages";
import { isWithValidationMessages } from "psims/lib/server-error";
import { encodeEscapeChars } from "psims/lib/validation/string";

type SubmissionStatus = 'not_submitted' | 'posting' | 'failed_server_error' | 'failed_validation' | 'succeeded';

function useContactRegistration(user: User) {
    const {api} = useAPI();
    const {setUser} = useUser();
    const [contactRegistration, setContactRegistration] = useState(newRegistration(user));
    const [showValidation, setShowValidation] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>('not_submitted');
    const [serverValidationErrors, setServerValidationErrors] = useState<Array<ValidationMessageVM> | null>(null);

    const validationMessages = useMemo(() => {
        return mergeServerValidationMessages(getValidationMessages(contactRegistration), serverValidationErrors);
    }, [contactRegistration, serverValidationErrors]);

    const isFormValid = useMemo(() => {
        return isSubmittable(contactRegistration);
    }, [contactRegistration]);

    function setPhone (phone: string) {
        setContactRegistration(updatePhone(contactRegistration, phone));
        setServerValidationErrors(old => removeServerValidationMessage(old, 'phone'));
    }

    function setAddressField<K extends keyof Address, V extends Address[K]>(field: K, value: V | undefined) {
        setContactRegistration(updateAddress(contactRegistration, field, value));
        setServerValidationErrors(old => removeServerValidationMessage(old, field));
    }

    function setBulk(phone: string, address: Address) {
        setContactRegistration(updateBulk(contactRegistration, {contactDetails: {phone, address}}))
    }

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setShowValidation(true);
        if (isFormValid) {
            try {
                setSubmissionStatus('posting');
                const response = await api.registerUser(formToAPIPayload(contactRegistration));

                if (response?.isSuccessful) {
                    setSubmissionStatus('succeeded');
                    setUser(response.result ? userVMToUser(response.result) : null);
                }
            } catch (e) {
                if (isWithValidationMessages(e)) {
                    if (e.body.validationMessages.length > 0) {
                        setServerValidationErrors(e.body.validationMessages);
                        setSubmissionStatus('failed_validation');
                    }
                } else {
                    setSubmissionStatus('failed_server_error');
                }
            }
        }
    }

    return {
        contactRegistration,
        handleSubmit,
        isFormValid,
        setBulk,
        setPhone,
        setAddressField,
        showValidation,
        submissionStatus,
        validationMessages,
    };
}

export default useContactRegistration;

function formToAPIPayload(form: UserWithContactDetails): {requestBody: RegisterUserVM} {
    return {
        requestBody: {
            userId: form.id === null ? undefined : form.id,
            concurrencyToken: form.concurrencyToken,
            phoneNumber: form.contactDetails.phone,
            addressLine1: encodeEscapeChars(form.contactDetails.address.line1),
            addressLine2: encodeEscapeChars(form.contactDetails.address.line2),
            postcode: form.contactDetails.address.postcode,
            city: encodeEscapeChars(form.contactDetails.address.suburb),
            stateId: form.contactDetails.address.stateId,
        }

    };
}

type LocalValidationMessages = ReturnType<typeof getValidationMessages>;

function mergeServerValidationMessages(localMessages: LocalValidationMessages, serverMessages: Array<ValidationMessageVM> | null): LocalValidationMessages {
    const messages: LocalValidationMessages = {
        address: {
            line1: localMessages.address.line1 || (Boolean(serverMessages?.find(m => m.propertyName === 'AddressLine1')) ? INVALID_ADDRESS_LINE1 : undefined),
            line2: localMessages.address.line2 || (Boolean(serverMessages?.find(m => m.propertyName === 'AddressLine2')) ? INVALID_ADDRESS_LINE2 : undefined),
            postcode: localMessages.address.postcode || (Boolean(serverMessages?.find(m => m.propertyName === 'Postcode')) ? INVALID_POSTCODE : undefined),
            suburb: localMessages.address.suburb || (Boolean(serverMessages?.find(m => m.propertyName === 'City')) ? INVALID_SUBURB : undefined),
            stateId: localMessages.address.stateId || (Boolean(serverMessages?.find(m => m.propertyName === 'StateId')) ? INVALID_STATE : undefined),
        },
        phone: localMessages.phone || (Boolean(serverMessages?.find(m => m.propertyName === 'PhoneNumber')) ? INVALID_PHONE_NUMBER : undefined),
    };

    return messages;
}

function mapFormFieldToServerValidationField(formField: string): string {
    switch (formField) {
        case 'phone': return 'PhoneNumber';
        case 'line1': return 'AddressLine1';
        case 'line2': return 'AddressLine2';
        case 'postcode': return 'Postcode';
        case 'stateId': return 'StateId';
        case 'suburb': return 'Suburb';
        default: return 'Unknown';
    }
}

function removeServerValidationMessage(messages: Array<ValidationMessageVM> | null, field: string) {
    if (messages === null) {
        return null;
    }

    return messages.filter(m => m.propertyName !== mapFormFieldToServerValidationField(field));
}
