import React, { useEffect, useRef } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import Loading from 'psims/react/components/loading';
import { useAuth } from 'psims/react/providers/auth';

interface SignOutPageProps {}

const SignOutPage = (props: SignOutPageProps) => {
    const auth = useAuth();
    const logoutInitiated = useRef(false);

    useEffect(() => {
        if (!logoutInitiated.current) {
            logoutInitiated.current = true;
            auth.logout()
        }
    }, [auth, logoutInitiated]);

    return (
        <BoxedDiv box={{alignItems: 'center', flex: 'column', height: '100%'}}>
            <BoxedDiv box={{alignItems: 'center', flexGrow: 1, flex: 'column', justifyContent: 'center', marginV: 4}}>
                <Loading>Signing out...</Loading>
            </BoxedDiv>
        </BoxedDiv>
    )
}

export default SignOutPage;
