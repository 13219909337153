import React from 'react';

import { UseFsp } from './use-fsp';
import EligibleProductions from './eligible-productions/eligible-productions';
import { BoxedDiv } from 'psims/react/components/layout';
import Expandable from 'psims/react/components/expandable';
import HelpBlock from 'psims/react/components/help';
import PeriodSummaryTable from './period-summary-table';
import TotalQuarterlyEligibleFssp from './total-quarterly-eligible-fssp/total-quarterly-eligible-fssp';

interface FspEditorProps {
  ctrl: UseFsp;
}

const FspDataEditor = ({ ctrl }: FspEditorProps) => {
  return <>
    <BoxedDiv box={{ marginV: 2 }}>
      <p>
        This section 19A quarterly report must be completed in accordance with the requirements set out in section 19A of the <em>Petroleum and Other Fuels Reporting Rules 2017 (POFR Rules)</em> and submitted in accordance with paragraph 19A(1)(d) of the POFR Rules.
      </p>

      <Expandable
        collapsedLabel='Show more information on what data to supply on this page'
        expandedLabel='Hide more information on what data to supply on this page'
        contentPlacement='below'
      >
        <HelpBlock box={{ flex: 'column', marginBottom: 0.5, marginTop: 1 }}>
          <BoxedDiv box={{ flex: 'column' }}>
            <p>
              The quarterly totals should equal the sum of your monthly POFR submissions for the three months of that quarter. The volumes for each month have been populated with data previously provided to the department in your monthly POFR submissions. All calculations have been done based on this monthly POFR data. Please check that the values previously provided are correct.
            </p>
          </BoxedDiv>
        </HelpBlock>
      </Expandable>
    </BoxedDiv>

    <BoxedDiv box={{}}>
      {
        ctrl.refineryOutputCtrl.tableView ?
        <PeriodSummaryTable
          {...ctrl.refineryOutputCtrl.tableView}
        >
          The quantity of each fuel reported for refinery output must meet the requirements of sections 5 and 13 of the <em>Fuel Security (Fuel Security Services Payment) Rule 2021</em> (FSSP Rule) for the quarter, as required under paragraph 19A(2)(a) of the POFR Rules.
        </PeriodSummaryTable> :
        null
      }
    </BoxedDiv>

    <BoxedDiv box={{}}>
      {
        ctrl.hydrocarbonBlendStocksCtrl.tableView ?
        <PeriodSummaryTable
          {...ctrl.hydrocarbonBlendStocksCtrl.tableView}
        >
          The quantity reported must represent the total volume of blendstock (as defined in section 4 of the FSSP Rule) used at the refinery in the quarter, as required under paragraph 19A(2)(c) of the POFR Rules.
        </PeriodSummaryTable> :
        null
      }
    </BoxedDiv>

    <BoxedDiv box={{ marginV: 2 }}>
      <EligibleProductions
        eligibleProductionsCtrl={ctrl.eligibleProductionsCtrl}
        focusFieldCtrl={ctrl.focusFieldCtrl}
      />
    </BoxedDiv>

    <TotalQuarterlyEligibleFssp
      ctrl={ctrl.totalQuarterlyEligibleFsspCtrl}
      focusedFieldCtrl={ctrl.focusFieldCtrl}
      navCtrl={ctrl.navCtrl}
    />

  </>
}

export default FspDataEditor;