import { useEffect, useMemo, useRef } from "react";

import { useReferenceData } from "psims/react/providers/api/reference-data";
import { useLogger } from "psims/react/providers/logging";
import { DefSubmission } from "psims/models/submission-types/def/def-submission";

interface UseDefRefDataProps {
  submission: DefSubmission | null;
}

function useDefRefData({submission}: UseDefRefDataProps) {
    const logger = useLogger({source: 'use-def-ref-data'});
    const alreadyLoggedUnexpectedEmptyProducts = useRef(false);

    const {data: refData, status} = useReferenceData();

    const submissionType = useMemo(() => {
      if (submission == null || refData == null) {
        return null;
      }

      return refData.submissionTypes.find(st => st.name === submission.dataSubmission.submissionTypeName);
    }, [refData, submission]);

    const defProducts = useMemo(() => {
        return (refData?.defProducts || []).sort((a, b) => a.displayOrder - b.displayOrder);
    }, [refData]);

    useEffect(() => {
        if ((refData?.defProducts || []).length < 1 && status === 'ready') {
            if (!alreadyLoggedUnexpectedEmptyProducts.current) {
                logger.exception(new Error('Unexpected empty DefProducts list from reference data call'));
                alreadyLoggedUnexpectedEmptyProducts.current = true
            }
        }

    }, [logger, refData, status]);

    return {
        defProducts,
        status,
        submissionType,
    };
}

export default useDefRefData

export type UseDefRefData = PopulatedProps<ReturnType<typeof useDefRefData>, 'submissionType'>;

export function isUseDefRefData(maybe: unknown): maybe is UseDefRefData {
    const maybeAs = maybe as UseDefRefData;

    return (
      maybeAs != null &&
      maybeAs.defProducts != null &&
      maybeAs.submissionType != null
    );
}
