import { BoxedSpan } from "psims/react/components/layout";
import Text from "psims/react/components/text";

interface StepLabelProps {
    hasData: boolean;
    label: string;
}

const StepLabel = ({hasData, label}: StepLabelProps) => (
    <BoxedSpan box={{alignItems: 'center', flex: 'column'}}>
        <Text>{label}</Text>
        {
            !hasData &&
            <Text>(No data)</Text>
        }
    </BoxedSpan>
);

export default StepLabel;
