import { useEffect, useMemo, useRef } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import { UseFormForType } from './use-form-for-type';
import { UseWholesalingServiceMessages } from './use-wholesaling-service-messages';
import PageNotifications from 'psims/react/pages/primary-pages/data-submissions/shared/page-notifications';
import { UseWholesalingSubmission } from './use-wholesaling-submission';
import { UseWholesalingProgress } from './use-wholesaling-progress';
import { UseWholesalingUpdateResponse } from './use-wholesaling-update-response';
import { CommentsRequiredErrorMessage, ErrorEmptyCommentsOnPage, ErrorUnsavedPage, InactiveProduct, InfoSameAsPreviousMessage } from 'psims/react/pages/primary-pages/data-submissions/shared/messages';
import { ExpiredOrInternalProductsWithData } from 'psims/react/pages/primary-pages/data-submissions/shared/types';
import { SELECTOR_NOTIFICATIONS } from 'psims/constants/selectors';
import { attemptScrollToSelector } from '../shared/view-utils';
import { INVALID_COMMENTS_REQUIRED } from 'psims/constants/validation-messages';
import { isEmpty } from 'psims/lib/empty';
import { UseWholesalingRefData } from './use-wholesaling-ref-data';
import { WholesaleSubmission } from 'psims/models/submission-types/wholesaling';
import { isValidForPeriod, IsWholesaleProductExpiredInTotalPage } from 'psims/models/ref-data/util';
import { EntityStatus } from '../shared/types';
import { useReferenceData } from 'psims/react/providers/api/reference-data';
import { AllReferenceData } from 'psims/models/ref-data';

interface WholesalingNotificationsProps {
    formCtrl: UseFormForType;
    progressCtrl: UseWholesalingProgress;
    refDataCtrl: UseWholesalingRefData;
    submission: WholesaleSubmission;
    submissionCtrl: UseWholesalingSubmission;
    serviceMessages: UseWholesalingServiceMessages;
    updateResponse: UseWholesalingUpdateResponse;
}

const WholesalingNotifications = (props : WholesalingNotificationsProps) => {
    const vm = useVM(props);

    return (
        <BoxedDiv box={{}} data-notifications={true}>
            {/* UI error messages */}
            {
                vm.validationMessages.length > 0 &&
                <PageNotifications kind='validation' items={vm.validationMessages.map(v => v.notification.content)} />
            }

            {/* System alerts */}
            {
                vm.systemAlerts.length > 0 &&
                <PageNotifications kind='system_alert' items={vm.systemAlerts} />
            }

            {/* Info messages */}
            {
                vm.infoMessages.length > 0 &&
                <PageNotifications kind='info' items={vm.infoMessages.map(v => v.notification.content)} />
            }

        </BoxedDiv>
    )
}

const AllInactiveProductsWithData = (
    submission: WholesaleSubmission,
    refData: AllReferenceData,
    navItems: {index: number; typeName: string;}[],
    whlRefData: UseWholesalingRefData
) => {   
    const allInactiveProducts: ExpiredOrInternalProductsWithData[] = [];
    
    const groupsInTotalPage = refData.wholesaleProductGroups.filter(g => g.wholesaleTypeName === 'Total');

    refData.wholesaleProductGroups.forEach(g => {
        const isGroupExpired = !isValidForPeriod(g, submission.dataSubmission.reportingPeriodFrom, submission.dataSubmission.reportingPeriodTo);
        const products = refData.wholesaleProducts.filter(p => p.wholesaleProductGroupId === g.id);
        const totalsProducts = whlRefData.Total.groups.map(g => g.products).flat();
        const stepIndex = navItems.find(x => x.typeName === g.wholesaleTypeName)?.index;

        if (stepIndex == null) {
            return;
        } 

        products.forEach(p => {
            const isProductExpiredInTotalPage = g.wholesaleTypeName !== 'Total' && groupsInTotalPage.length > 0 ? 
                IsWholesaleProductExpiredInTotalPage(
                    totalsProducts, 
                    groupsInTotalPage, 
                    p.genericProductCode, 
                    submission.dataSubmission.reportingPeriodFrom, 
                    submission.dataSubmission.reportingPeriodTo) : false;

            const isExpired = isGroupExpired ||
                isProductExpiredInTotalPage ||
                !isValidForPeriod(p, submission.dataSubmission.reportingPeriodFrom, submission.dataSubmission.reportingPeriodTo);
                
            let wholesaleForProduct = submission.wholesales.find(x => (
                x.recordResult?.rowResult !== 'Deleted' &&
                x.wholesaleProductId === p.id)
            );
            
            const productStatus: EntityStatus = !isExpired ? 'active' :
                (wholesaleForProduct === undefined || isEmpty(wholesaleForProduct) || wholesaleForProduct.id === undefined || wholesaleForProduct.id == null) ?
                    'expired' : 'expired_with_data';

            allInactiveProducts.push({productId: p.id, typeName: g.wholesaleTypeName, productName: p.productName, stepIndex, productStatus});
        });
    });

    return allInactiveProducts.filter(x => x.productStatus === 'expired_with_data');
}

function useVM({formCtrl, progressCtrl, refDataCtrl, serviceMessages, submission, submissionCtrl, updateResponse}: WholesalingNotificationsProps) { 
    const {data: _refData} = useReferenceData();
    const hasMessages = useRef(false);
    const isSubmitPage = useMemo(() => {
        return progressCtrl.currentNavItem.kind === 'Submit';
    }, [progressCtrl]);

    const hasValidationErrorInSubmitPage = useMemo(() => {
        return isSubmitPage && submissionCtrl.stepsRequiringComments.length > 0;
    }, [submissionCtrl.stepsRequiringComments, isSubmitPage]);

    const isSameAsPrevSubmission = useMemo(() => {
        return submissionCtrl.isSameAsPrevSubmission
    }, [submissionCtrl.isSameAsPrevSubmission]);

    const infoMessages = useMemo(() => {
        return [
            ...formCtrl.view?.wholesales.groups
                .map(g => g.products
                    .map(p => p.infoMessages)
                    .reduce((memo, infos) => [...memo, ...infos], [])
                )
                .reduce((memo, infosArr) => [...memo, ...infosArr], []) || [],            
            ...(isSubmitPage && isSameAsPrevSubmission ? [{ notification: { content: <InfoSameAsPreviousMessage onTargetClick={() => formCtrl.setFocusedField({ field: 'comments' })} /> }}] : []),
        ];
    }, [formCtrl, isSameAsPrevSubmission, isSubmitPage]);
    
    const validationMessages = useMemo(() => {
        return [
            ...formCtrl.view?.wholesales.groups
            .map(g => g.products
                .map(p => p.validationErrors)
                .reduce((memo, validations) => [...memo, ...validations], [])
            )
            .reduce((memo, validationsArr) => [...memo, ...validationsArr], []) || [],
            ...(formCtrl.view?.comments.validationError != null ? [formCtrl.view.comments.validationError] : []),
            ...(isSubmitPage && submissionCtrl.declarationMessage ? [{notification: {content: submissionCtrl.declarationMessage}}] : []),
            ...(isSubmitPage && isSameAsPrevSubmission && isEmpty(submissionCtrl.comments) ? [{
                notification: {
                    content: CommentsRequiredErrorMessage({
                        onTargetClick: () => formCtrl.setFocusedField({field: 'comments'}),
                    }),
                    message: INVALID_COMMENTS_REQUIRED,
                }
                }] : []),
            ...(isSubmitPage ? [
                ...submissionCtrl.unsavedSteps.map(uss => ({
                    notification: {
                        content: ErrorUnsavedPage({
                            onTargetClick: () => progressCtrl.handleNavItemClick(uss.index),
                            label: uss.label
                        }),
                    }
                })),         
                ...(_refData != null ?
                    AllInactiveProductsWithData(
                        submission,
                        _refData,
                        progressCtrl.navItems.map(x => ({
                            index: x.index,
                            typeName: x.typeName
                        })),
                        refDataCtrl
                    )
                    .sort((a, b) => a.stepIndex - b.stepIndex)
                    .map(src => ({
                        notification: {
                            content: InactiveProduct({
                                onTargetClick: () => {
                                    progressCtrl.handleNavItemClick(src.stepIndex);
                                    setTimeout(() => formCtrl.setFocusedField({field: 'delete', wholesaleProductId: src.productId}))
                                },
                                label: src.productName,
                            })
                        }
                        })) :
                    []
                ),
                ...submissionCtrl.stepsRequiringComments.map(src =>({
                    notification: {
                        content: ErrorEmptyCommentsOnPage({
                            onTargetClick: () => progressCtrl.handleNavItemClick(src.index),
                            label: src.label,
                        })
                    }
                }))
             ] : [])
        ];
    }, [
        _refData,
        formCtrl,
        isSubmitPage,
        isSameAsPrevSubmission,
        progressCtrl,
        refDataCtrl,
        submission,
        submissionCtrl.declarationMessage,
        submissionCtrl.comments,
        submissionCtrl.stepsRequiringComments,
        submissionCtrl.unsavedSteps,
    ]);

    useEffect(() => {
        hasMessages.current = (
            infoMessages.length > 0 ||
            serviceMessages.messages.systemAlerts.length > 0 ||
            validationMessages.length > 0
        );
    }, [infoMessages.length, serviceMessages.messages.systemAlerts.length, validationMessages.length]);

    useEffect(() => {
        if (updateResponse.status !== 'saved_full' && updateResponse.status !== 'idle' && hasMessages.current) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS)
        }
    }, [updateResponse.status])

    return {
        infoMessages,
        systemAlerts: hasValidationErrorInSubmitPage ? [] : serviceMessages.messages.systemAlerts,
        validationMessages,
    };
}

export default WholesalingNotifications;

