import { DataSubmissionVM, UpdateDataSubmissionVM } from "psims/gen/xapi-client";

const DATA_SUBMISSION_TYPE_NAMES = [
    'Plant production',
    'Biofuel production',
    'Field production',
    'FSSP s19A',
    'Importing',
    'Stockholding',
    'Refining',
    'Wholesaling',
    'MSO importer',
    'MSO refiner',
    'Diesel exhaust fluid'
] as const;

export type DataSubmissionTypeName = ArrayElement<typeof DATA_SUBMISSION_TYPE_NAMES>;

const EXTENDED_DATA_SUBMISSION_TYPE_NAMES = [
    ...DATA_SUBMISSION_TYPE_NAMES,
    'MSO importer - Annual activity',
    'MSO refiner - Annual activity',
] as const;

export type ExtendedDataSubmissionTypeName = ArrayElement<typeof EXTENDED_DATA_SUBMISSION_TYPE_NAMES>;

const DATA_SUBMISSION_STATUSES = [
    'Not started',
    'Draft',
    'Submitted',
    'In review',
    'Action required',
    'Approved',
    'Inactive',
    'Received',
] as const;

export type DataSubmissionStatus = ArrayElement<typeof DATA_SUBMISSION_STATUSES>;

const REPORTING_FREQUENCIES = [
    '6 monthly',
    'Annually',
    'Fortnightly',
    'Monthly',
    'Quarterly',
] as const

export type ReportFrequencyTypeName = ArrayElement<typeof REPORTING_FREQUENCIES>;

function isReportFrequencyTypeName(maybe?: unknown): maybe is ReportFrequencyTypeName {
    return (
        typeof maybe === 'string' &&
        REPORTING_FREQUENCIES.includes(maybe as ReportFrequencyTypeName)
    );
}
export type DataSubmission<TName extends DataSubmissionTypeName> = DataSubmissionVM & {
    id: number;
    reportFrequencyTypeId: number;
    reportFrequencyTypeName: ReportFrequencyTypeName;
    submissionTypeName: TName;
    status: DataSubmissionStatus;
}

export type UpdateDataSubmission = UpdateDataSubmissionVM;

export type PopulatedDataSubmission<TName extends DataSubmissionTypeName> = PopulatedProps<
    DataSubmission<TName>,
    'caseId' | 'id' | 'dueDate' | 'reportingMonth' | 'reportingYear' | 'reportingOrganisationName' |
    'reportingPeriodFrom' | 'reportingPeriodTo' | 'status' | 'submissionTypeId' | 'concurrencyToken' |
    'reportFrequencyTypeId' | 'reportFrequencyTypeName'
> & {
    reportFrequencyTypeName: ReportFrequencyTypeName;
};

export function isSubmissionTypeName(maybe?: string | null): maybe is DataSubmissionTypeName {
    return maybe != null && DATA_SUBMISSION_TYPE_NAMES.includes(maybe as DataSubmissionTypeName);
}

function isSubmissionStatus(maybe?: string | null): maybe is DataSubmissionStatus {
    return maybe != null && DATA_SUBMISSION_STATUSES.includes(maybe as DataSubmissionStatus);
}

export function isAnyDataSubmission<TName extends DataSubmissionTypeName>(maybe?: unknown): maybe is DataSubmission<TName> {
    if (maybe == null) {
        return false;
    }

    const asVM = maybe as DataSubmissionVM;

    return (
        isSubmissionTypeName(asVM.submissionTypeName) &&
        isReportFrequencyTypeName(asVM.reportFrequencyTypeName) &&
        isSubmissionStatus(asVM.status)
    );
}

export function isDataSubmission<TName extends DataSubmissionTypeName>(maybe: unknown, submissionTypeName: TName): maybe is DataSubmission<TName> {
    return isAnyDataSubmission(maybe) && maybe.submissionTypeName === submissionTypeName;
}

export function isPopulatedDataSubmission<TName extends DataSubmissionTypeName>(maybe?: unknown, submissionTypeName?: TName): maybe is PopulatedDataSubmission<TName> {
    return (
        ((submissionTypeName != null && isDataSubmission(maybe, submissionTypeName)) || isAnyDataSubmission(maybe)) && (
            maybe.caseId != null &&
            maybe.id != null &&
            maybe.dueDate != null &&
            (
                (maybe.reportingMonth != null && maybe.reportingYear != null) ||
                maybe.reportFrequencyTypeName === 'Annually'
            ) &&
            maybe.reportingOrganisationName != null &&
            maybe.reportingPeriodFrom != null &&
            maybe.reportingPeriodTo != null &&
            maybe.status != null &&
            maybe.submissionTypeId!= null &&
            maybe.concurrencyToken != null
        )
    );
}

export function assertDataSubmission<TName extends DataSubmissionTypeName>(maybe?: unknown, submissionTypeName?: TName): asserts maybe is PopulatedDataSubmission<TName> {
    if (!isPopulatedDataSubmission(maybe, submissionTypeName)) {
        throw new Error(`Failed to assert populated dataSubmission: ${JSON.stringify(maybe)}`)
    }
}

type SubmittedDataSubmission<TType extends DataSubmissionTypeName> = PopulatedDataSubmission<TType> & {
    status: 'Submitted';
}

export function isSubmittedDataSubmission<TName extends DataSubmissionTypeName>(maybeDataSubmission?: DataSubmissionVM, submissionTypeName?: TName): maybeDataSubmission is SubmittedDataSubmission<TName> {
    return isPopulatedDataSubmission(maybeDataSubmission) && maybeDataSubmission.status === 'Submitted';
}
