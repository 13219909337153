import React from 'react';

import { UseDef } from './use-def';
import Activities from './activities/activities';
import { BoxedDiv } from 'psims/react/components/layout';
import MarketExpectations from './market-expectations/market-expectations';
import Expandable from 'psims/react/components/expandable';
import HelpBlock from 'psims/react/components/help';

interface DefEditorProps {
  ctrl: UseDef;
}

const DefDataEditor = ({ ctrl }: DefEditorProps) => {
  return <>
    <BoxedDiv box={{ marginV: 2 }} id='frontmatter'>
      <p>
        Only provide information in this submission that relates to activities within the calendar month required.
      </p>

      <p>
        Quantities should be reported in metric tonnes for TGU and in Kilolitres (000's litres) for DEF.
      </p>

      <Expandable
        collapsedLabel='Show more information on what data to supply on this page'
        expandedLabel='Hide more information on what data to supply on this page'
        contentPlacement='below'
      >
        <HelpBlock box={{ flex: 'column', marginBottom: 0.5, marginTop: 1 }}>
          <BoxedDiv box={{ flex: 'column' }}>
            <p>
              When recording data relevant to your business, please enter whole numbers. If you have nothing to report in a particular month, please place a zero in the cell. Only leave cells blank where data items are not relevant to your business.
            </p>

            <p>
              The Department appreciates that precise quantities may not always be available. If that is the case we would be grateful if you would provide your best estimates.
            </p>

            <p>
              Use the comments section on the declaration page to provide any additional relevant information.
            </p>

          </BoxedDiv>
        </HelpBlock>
      </Expandable>

    </BoxedDiv>

    <BoxedDiv box={{ marginV: 2 }}>
      <Activities ctrl={ctrl.activitiesCtrl} focusFieldCtrl={ctrl.focusFieldCtrl} forceErrors={ctrl.forceErrors} isDisabled={ctrl.isDisabled} />
    </BoxedDiv>


    <BoxedDiv box={{}}>
      <MarketExpectations ctrl={ctrl.marketExpectationsCtrl} forceErrors={ctrl.forceErrors} isDisabled={ctrl.viewMode !== 'edit'} />
    </BoxedDiv>
  </>
}

export default DefDataEditor;
