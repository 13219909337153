import React from 'react';
import styled from 'styled-components';
import Button from './button/button';
import ArrowDown from './icons/arrow-down';
import ArrowUp from './icons/arrow-up';

import { BoxedDiv } from './layout';
import Text from './text';
import VisuallyHidden from './visually-hidden';

interface ScrolltoProps {}

const StyledScrollTo = styled(Button)`
    align-items: center;
    background: var(--color-white);
    border-color: var(--color-primary);
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    color: var(--color-primary);
    cursor: pointer;
    display: flex;
    font-weight: 600;
    height: 52px;
    justify-content: center;
    padding: 0;
    width: 52px;
    border-radius: 6px;
    margin-bottom: 8px;

    &:focus,
    &:hover,
    &:active {
        box-shadow: var(--box-shadow-focus); 
    }
`;

const ScrollTo = (props: ScrolltoProps) => {
    function scrollTo(where: 'top' | 'bottom') {
        const pageEl = document.querySelector('#page');

        if (pageEl == null) {
            return;
        }

        switch(where) {
            case 'bottom':
                pageEl.scrollTo(0, pageEl.scrollHeight);
                return;
            case 'top':
                pageEl.scrollTo({behavior: 'smooth', top: 0});
        }
    }

    return (
        <BoxedDiv box={{flex: 'column', position: 'fixed', right: '30px', top: '45%'}}>
            <StyledScrollTo $kind='unstyled' onClick={() => scrollTo('top')} title='Scroll to top of page'>
                <Text $color='blue-70'>
                    <VisuallyHidden>Scroll to top of page</VisuallyHidden>
                    <ArrowUp size='sm' />
                </Text>
            </StyledScrollTo>

            <StyledScrollTo $kind='unstyled' onClick={() => scrollTo('bottom')} title='Scroll to bottom of page'>
                <Text $color='blue-70'>
                    <VisuallyHidden>Scroll to bottom of page</VisuallyHidden>
                    <ArrowDown size='sm' />
                </Text>
            </StyledScrollTo>
        </BoxedDiv>
    )
}

export default ScrollTo;
