import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import useFocusable from '../util/use-focusable';
import { useIsFocusedAlt as useIsFocused } from '../util/use-is-focused';
import FloatingMessage from './floating-message';
import { BoxedSpan } from './layout';
import randomID from 'psims/lib/random-id';
import VisuallyHidden from './visually-hidden';

interface StyleProps {
    width?: string;
}

interface CheckboxProps extends StyleProps {
    bare?: boolean;
    disabled?: boolean;
    error?: string;
    helpId?: string;
    label: string;
    name: string;
    onChange: (val: boolean) => any;
    shouldFocus?: boolean;
    value: boolean;
    autoFocus?: boolean
}

const StyledCheckbox = styled.div<{error?: string; bare?: boolean}>`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;

    & input {
        position: absolute;
        left: -9999px;

        &:checked + label {
            color: var(--color-primary);
            font-weight: 700;
            &:before {
                border-color: var(--color-primary);
            }

            &:after {
                border-color: var(--color-primary);
            }
        }

        &:focus + label {
            box-shadow: var(--box-shadow-focus);
        }
    }

    & label {
        cursor: pointer;
        line-height: 32px;
        padding: ${(props) => `4px 4px 4px ${props.bare ? '36px' : '48px'}`};
        ${props => props.bare ? `height: 32px;` : ''}

        &:before {
            border: ${(props) => `2px solid var(--color-${props.error ? 'negative-100' : 'black-50'})`};
            border-radius: 2px;
            content: '';
            height: 28px;
            left: 4px;
            position: absolute;
            transition: all var(--transition-duration-xfast) ease-in-out;
            width: 28px;
        }

        &:after {
            border-bottom: 2px solid;
            border-left: 2px solid;
            border-color: var(--color-transparent);
            content: '';
            position: absolute;
            height: 7px;
            left: 12px;
            top: 12px;
            transform: rotateZ(-45deg);
            transition: all var(--transition-duration-xfast) ease-in-out;
            width: 15px;
        }
    }
`;

const Checkbox = ({bare, error, helpId, label, name, onChange, value, autoFocus, disabled, shouldFocus, ...style}: CheckboxProps) => {
    const [floatingId] = useState(randomID());
	const {setRef: setFocusableRef} = useFocusable({setFocused: shouldFocus});
    const {isFocused, setRef: isFocusedRef} = useIsFocused();

    const consolidatedRef = useCallback((el: HTMLElement | null) => {
        setFocusableRef(el);
        isFocusedRef(el);
    }, [setFocusableRef, isFocusedRef])

    return <StyledCheckbox bare={bare} error={error} {...style}>
        <input
            aria-describedby={helpId || undefined}
            aria-errormessage={error != null ? floatingId : undefined}
            aria-invalid={error != null ? 'true' : undefined}
            id={name}
            type='checkbox'
            onChange={() => onChange(!value)}
            checked={value}
            disabled={disabled}
            autoFocus={autoFocus}
            ref={consolidatedRef}
        />

        <label htmlFor={name}>
            <BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
                
                {
                    bare === true ?
                    <VisuallyHidden>{label}</VisuallyHidden> :
                    label
                }

                {
                    error &&
                    <BoxedSpan box={{alignItems: 'center', flex: 'row', marginLeft: 1}}>
                        <FloatingMessage
                            id={floatingId}
                            content={error}
                            kind='warning'
                            role='alert'
                            showContent={isFocused}
                        />
                    </BoxedSpan>
                }
            </BoxedSpan>
        </label>

    </StyledCheckbox>
}

export default Checkbox;
