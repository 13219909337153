import { useEffect, useState } from "react";

interface UseFocusableProps {
    setFocused?: boolean;
}

interface Focusable {
	focus: () => any;
	scrollIntoView?: (opts: {behavior: 'smooth'; block: 'center'}) => any;
}

function useFocusable({setFocused}: UseFocusableProps) {
	const [ref, setRef] = useState<Focusable | null>(null);

	useEffect(() => {
		setTimeout(() => {
			if (ref !== null && setFocused) {
				ref.focus();
				ref.scrollIntoView?.({behavior: 'smooth', block: 'center'});
			}
		}, 0)
	}, [ref, setFocused])

    return {
        setRef,
    };
}

export default useFocusable;
