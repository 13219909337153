import { RefineryTypeDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { prefixedThrower } from "psims/lib/exception";

// Needs to be updated if ref data name change
export const REFINERY_TYPE_NAMES = ['Gases-Unfin-Petrochem-Losses', 'Refinery Input', 'Refinery Output'] as const;

export type RefineryTypeName = ArrayElement<typeof REFINERY_TYPE_NAMES>;

export const REFINERY_DISPLAY_NAMES: {[key in RefineryTypeName]: string} = {
    "Gases-Unfin-Petrochem-Losses": 'Gases, intermediates, petrochemical feedstocks & losses',
    "Refinery Input": 'Refinery input',
    "Refinery Output": 'Refinery output of finished products',
} as const

export type RefineryType = PopulatedProps<
    RefineryTypeDTO & {
        displayName: string;
        name: RefineryTypeName;
    }, 'id' | 'displayOrder' | 'isActive' | 'name'
>;

export function withDisplayName(refineryType: RefineryType): RefineryType {
    return {
        ...refineryType,
        displayName: REFINERY_DISPLAY_NAMES[refineryType.name],
    }
}

export function assertRefineryType(maybe?: unknown): asserts maybe is RefineryType {
    const asRefineryType = maybe as RefineryType;

    const err = prefixedThrower(`Failed to assert refineryType: ${JSON.stringify(maybe)}: `);

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asRefineryType, hint('refineryType'));
    assertNotEmpty(asRefineryType.displayOrder, hint('refineryType.displayOrder'));
    assertNotEmpty(asRefineryType.id, hint('refineryType.id'));
    assertNotEmpty(asRefineryType.name, hint('refineryType.name'));

    if (!REFINERY_TYPE_NAMES.includes(asRefineryType.name)) err(`unknown type name: ${asRefineryType.name}`);
}
