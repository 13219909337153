import React from 'react';

import Header from 'psims/react/blocks/data-submission-header';
import Button from 'psims/react/components/button';
import ConfirmSaveChanges from 'psims/react/components/confirm-save-changes';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';
import DataSubmissionPrimaryControls from 'psims/react/components/data-submission-primary-controls';
import { BoxedDiv } from 'psims/react/components/layout';
import ScrollTo from 'psims/react/components/scroll-to';
import SubmissionProgressBar from 'psims/react/components/submission-progress-bar';
import { H2 } from 'psims/react/components/typography';
import Notifications from '../shared/notifications';
import SubmitPage from '../shared/submit-page';
import FspDataEditor from './fsp-data-editor';
import useFsp from './use-fsp';
import { UseFspAPI } from './use-fsp-api';
import { UseFspRefData } from './use-fsp-ref-data';

interface FSPEditorProps {
  apiCtrl: PopulatedProps<UseFspAPI, 'submission'>;
  refData: UseFspRefData;
}

const FSPEditor = (props: FSPEditorProps) => {
  const vm = useVM(props);
  const { primaryControl, secondaryControl } = vm.ctrl.controlsCtrl;

  return (
    <BoxedDiv box={{ flex: 'column' }}>
      <Header
        customName='Fuel security services payment (FSSP) s19A'
        dataSubmission={vm.ctrl.apiCtrl.submission.dataSubmission}
      />

      <BoxedDiv box={{ marginV: 4 }}>
        <SubmissionProgressBar
          isComplete={false}
          onStepClick={vm.ctrl.navCtrl.handleNavBarClick}
          steps={vm.ctrl.progressCtrl.progressSteps}
        />
      </BoxedDiv>

      <BoxedDiv box={{ flex: 'column' }} className='container'>
        {
          vm.ctrl.progressCtrl.currentStep.index === 0 &&
          <FspDataEditor ctrl={vm.ctrl} />
        }

        {
          vm.ctrl.progressCtrl.currentStep.index === 1 && <>
            <H2>Declaration</H2>

            <SubmitPage
              ctrl={{
                ...vm.ctrl.submitCtrl,
                isDisabled: vm.ctrl.isDisabled,
                dataSubmission: vm.ctrl.apiCtrl.submission.dataSubmission,
              }}
              focusedFieldCtrl={vm.ctrl.focusFieldCtrl}
              viewMode={vm.ctrl.viewMode}
            />
          </>
        }

        {/* Notifications */}
        {
          vm.ctrl.viewMode === 'edit' ?
          <Notifications
            infoNotifications={vm.ctrl.notificationsCtrl.infoMessages}
            systemNotifications={vm.ctrl.notificationsCtrl.serviceNotifications}
            validationNotifications={vm.ctrl.notificationsCtrl.validationMessages}
          /> :
          null
        }

        <DataSubmissionPrimaryControls>
          <BoxedDiv box={{ alignItems: 'center', flex: 'row-reverse', marginTop: 2 }}>
            {
              primaryControl.isShown &&
              <Button
                disabled={primaryControl.isDisabled}
                $kind='primary'
                marginLeft={2}
                onClick={primaryControl.action}
                state={primaryControl.state}
              >{primaryControl.label}</Button>
            }

            {
              secondaryControl.isShown &&
              <Button
                disabled={secondaryControl.isDisabled}
                $kind='ghost'
                onClick={secondaryControl.action}
                state={secondaryControl.state}
              >{secondaryControl.label}</Button>
            }
          </BoxedDiv>
        </DataSubmissionPrimaryControls>
      </BoxedDiv>

      <ConfirmSaveChanges {...vm.ctrl.unsavedChangesDialogCtrl} />

      {/* Review alerts dialog */}
      <ConfirmationDialog
        ariaLabel='Your data contains alerts - please review before continuing'
        body={
          <BoxedDiv box={{ flex: 'column' }}>
            <p>The data on this page has triggered one or more alerts, which require you to provide a comment.</p>
            <p>If you want to review these alerts and review or add a comment, please click Review alerts.</p>
            <p>If you have addressed these and entered a comment, click Proceed to continue to the next page.</p>
          </BoxedDiv>
        }
        cancelLabel='Review alerts'
        isOpen={vm.ctrl.navCtrl.navState === 'confirming_validation_alerts'}
        onCancel={() => vm.ctrl.navCtrl.handleValidationAlertDialogDecision('review_alerts')}
        onConfirm={() => vm.ctrl.navCtrl.handleValidationAlertDialogDecision('proceed')}
        title='Your data contains alerts - please review before continuing'
      />

      {/* Confirm Clear all dialog */}
      {
        vm.ctrl.progressCtrl.currentStep.index === 0 &&
        <ConfirmationDialog
          ariaLabel='Clear all data confirm'
          body={vm.ctrl.clearDataCtrl.confirmCtlr.message}
          title={vm.ctrl.clearDataCtrl.confirmCtlr.title}
          onCancel={vm.ctrl.clearDataCtrl.confirmCtlr.cancel}
          onConfirm={vm.ctrl.clearDataCtrl.confirmCtlr.confirm}
          isOpen={vm.ctrl.clearDataCtrl.confirmCtlr.confirmState === 'confirming'}
        />
      }

      {/* Scroll helper */}
      <ScrollTo />
    </BoxedDiv>
  )
}

function useVM({ apiCtrl, refData }: FSPEditorProps) {
  const ctrl = useFsp({ apiCtrl, refData })

  return {
    ctrl,
  };
}

export default FSPEditor;
