import { DataSubmissionRecordActionEnum, ProductionVM } from 'psims/gen/xapi-client';
import { isArrayOfType } from 'psims/lib/collections';
import { isEntity } from '../api/entity';
import { isMaybeWithRecordResult, isRecordResult, RecordResult } from '../api/record-result';
import { UpdateFields } from '../api/update';
import { isPopulatedDataSubmission, isSubmittedDataSubmission } from '../data-submission';
import { WithDataSubmission } from './data-submission';
import { isPlaceholderProductionPageData, isProductionPageData, ProductionPageData, UpdateProductionPageData } from './production-page-data';

export type ProductionDataSubmissionName = 'Biofuel production' | 'Field production' | 'Plant production';

export type MaybeProduction = ProductionVM;
export type Production = PopulatedProps<ProductionVM, 'concurrencyToken' | 'id' | 'organisationProductionAreaId' | 'productionProductId' | 'recordResult'>;

interface UpdateProduction {
    id?: number | null;
    productionProductId: number;
    concurrencyToken?: string | null;
    organisationProductionAreaId?: number | null;
    grossCalorificValue?: number | null;
    productionDensity?: number | null;
    consumed?: number | null;
    closingStocks?: number | null;
    delivered?: number | null;
    openingStocks?: number | null;
    produced?: number | null;
    recordAction: DataSubmissionRecordActionEnum;
}

function isUpdateProduction(maybe: unknown): maybe is UpdateProduction {
    const maybeAs = maybe as UpdateProduction;
    return (
        maybeAs != null &&
        maybeAs.productionProductId != null &&
        maybeAs.recordAction != null
    );
}

export function assertUpdateProduction(maybe: unknown): asserts maybe is UpdateProduction {
    if (!isUpdateProduction(maybe)) {
        throw new Error(`Failed to assert UpdateProduction: ${JSON.stringify(maybe)}`);
    }
}

export type UpdateProductionDraft = Partial<Omit<UpdateProduction, 'recordAction'>> & {
    productionProductId: number;
    recordAction: DataSubmissionRecordActionEnum | null;
}

export interface UpdateProductionSubmission {
    dataSubmissionId: number;
    pageData?: UpdateProductionPageData;
    productions: Array<UpdateProduction>;
    isTemplateImport?: boolean | null;
};

export type ProductionSubmission = WithDataSubmission<ProductionDataSubmissionName, {
    pageData: ProductionPageData;
    productions: Array<Production>;
}>;

type ProductionData = Omit<Partial<UpdateProduction>, UpdateFields | 'dataSubmissionId' | 'productionProductId' | 'plantName'>;

export type ProductionField = keyof ProductionData;

function isProduction(maybe?: unknown): maybe is Production {
    const maybeAs = maybe as Production;
    
    return isEntity(maybeAs) &&
        maybeAs.productionProductId != null;
}

export function isProductionSubmission(maybe: unknown): maybe is ProductionSubmission {
    const maybeAs = maybe as ProductionSubmission;

    return maybeAs != null &&
           isPopulatedDataSubmission<ProductionDataSubmissionName>(maybeAs.dataSubmission) &&
           maybeAs.pageData != null &&
           (isProductionPageData(maybeAs.pageData) || isPlaceholderProductionPageData(maybeAs.pageData))&&
           isArrayOfType(isProduction, maybeAs.productions)
}

type ProductionSubmissionWithRecordResults = Omit<ProductionSubmission, 'productions'> & {
    productions: Array<{
        recordResult: RecordResult | null;
    }>;
}

export function isProductionSubmissionWithRecordResults(maybe: unknown): maybe is ProductionSubmissionWithRecordResults {
    const maybeAs = maybe as ProductionSubmission;

    return maybeAs != null &&
        isPopulatedDataSubmission<ProductionDataSubmissionName>(maybeAs.dataSubmission) &&
        isArrayOfType(isMaybeWithRecordResult, maybeAs.productions)
}

export function isProductionSubmitted(maybe?: unknown): maybe is ProductionSubmission & {dataSubmission: {status: 'Submitted'}} {
    return isProductionSubmission(maybe) && isSubmittedDataSubmission(maybe.dataSubmission);
}

export function updateProductionToProductionData(updateProduction: UpdateProductionDraft | null): ProductionData {
    const { ...productionData } = updateProduction;
    return productionData;
}

export function getRecordResults(maybe: unknown): Array<RecordResult> {
    if (!isProductionSubmission(maybe)) {
        return [];
    }

    // convert pageData to standard record result shape
    const pageDataRecordResult = isProductionPageData(maybe.pageData) ?
        maybe.pageData.formDataResult == null ?
            null : {
                rowResult: maybe.pageData.formDataResult.result,
                message: maybe.pageData.formDataResult.message,
            } :
            null;

    return [
        ...(maybe.dataSubmission.recordResult != null ? [maybe.dataSubmission.recordResult] : []).filter(isRecordResult),
        ...(maybe.productions || []).map(p => p.recordResult).filter(isRecordResult),
        ...(maybe.pageData != null && isRecordResult(pageDataRecordResult) ? [pageDataRecordResult] : []),
    ];
}
