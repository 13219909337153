/*
  Expose a different value when the control is not focused
*/

import { deExponentialise, isExponent } from "psims/lib/number";
import { useMemo } from "react";
import {useIsFocusedAlt as useIsFocused} from "./use-is-focused";

interface UseDisplayValueProps<T> {
    value: T | undefined | null;
    formatter?: (val: T | undefined | null) => string;
}

function useDisplayValue<T>({formatter, value}: UseDisplayValueProps<T>) {
    const {setRef, isFocused} = useIsFocused();

    const displayValue = useMemo(() => {
        if (isExponent(value)) {
            return deExponentialise(Number(value));
        }

        if (isFocused || formatter == null || value == null) {
            return value;
        }

        return formatter(value);
    }, [formatter, isFocused, value]);

    return {
        displayValue,
        setRef,
    }
}

export default useDisplayValue;
