import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';

import Box from './box';
import Text from './text';

export const PropertyRow = ({children}: PropsWithChildren<{}>) => {
    return <Box flex='row' marginBottom='sm'>
        {children}
    </Box>
}

const StyledDT = styled.dt`
    min-width: 220px;
`;

export const PropertyLabel = ({children}: PropsWithChildren<{}>) => {
    return <StyledDT>
        <Text weight='semibold'>{children}</Text>
    </StyledDT>
}

export const PropertyValue = ({children}: PropsWithChildren<{}>) => {
    return <dt>
        <Text>{children}</Text>
    </dt>
}
