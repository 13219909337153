/*
    Manages the response from update request for use by other controllers
*/

import { GENERIC_SAVE_ERROR } from "psims/constants/validation-messages";
import { stringifyServerError } from "psims/lib/server-error";
import { isRecordResult, RecordResult } from "psims/models/api/record-result";
import { APIResponse, getErrorMessages, getValidationMessages } from "psims/models/api/response";
import { getAggregatedUpdateStatus, SavedStatus } from "psims/models/api/submission/update/record-result";
import { isWholesaleSubmission, MaybeWholesaleSubmission, WholesaleSubmission } from "psims/models/submission-types/wholesaling";
import { useLogger } from "psims/react/providers/logging";
import { useEffect, useRef, useState } from "react";
import { SubmissionStatus } from "../shared/api";
import { ServiceMessages } from "./shared";

interface UseWholesalingUpdateResponseProps {
    submissionStatus: SubmissionStatus
    serviceError: unknown;
    response: APIResponse<MaybeWholesaleSubmission> | null;
}

type Status = 'init' | 'idle' | 'pending' | 'null_response' | 'error' | SavedStatus | 'submitted';

type ProcessedResponse = ServiceMessages & {
    submission: WholesaleSubmission | null;
}

function useWholesalingUpdateResponse({serviceError, response, submissionStatus}: UseWholesalingUpdateResponseProps) {
    const {warn} = useLogger({source: 'useWholesalingUpdateResponse'});
    const [status, setStatus] = useState<Status>('init');
    const [processedResponse, setProcessedResponse] = useState<ProcessedResponse | null>(null);
    const [processedError, setProcessedError] = useState<string | null>(null);
    const warnRef = useRef(warn) ;

    useEffect(() => {
        switch (submissionStatus) {
            case 'fetching':
            case 'submitting':
            case 'updating': {
                setStatus('pending');
                break;
            }
            case 'fetch_failed': {
                setStatus('error');
                break;
            }
            default:
        }
    }, [submissionStatus]);

    useEffect(() => {
        if (response == null) {
            setStatus(prev => prev === 'init' ? 'idle' : 'null_response');
            return;
        }

        setProcessedError(null);

        const errorMessages = getErrorMessages(response);
        const validationMessages = getValidationMessages(response);

        if (!isWholesaleSubmission(response.result)) {
            setProcessedResponse({
                errorMessages,
                recordResults: null,
                submission: null,
                validationMessages,
            });

            setStatus('error');
            setProcessedError(GENERIC_SAVE_ERROR);

            warnRef.current(`Could not parse submission from update response: ${JSON.stringify(response)}`);

            return;
        }

        if (response.result.dataSubmission.status === 'Submitted') {
            setProcessedResponse({
                errorMessages: null,
                recordResults: null,
                submission: response.result,
                validationMessages: null,
            });

            setStatus('submitted');
            
            return;
        }

        const recordResults = getRecordResults(response.result);

        if ((errorMessages?.length || 0) === 0 || (validationMessages?.length || 0) === 0) {
            const saveStatus = getAggregatedUpdateStatus(recordResults);
            setStatus(saveStatus);
        } else {
            setStatus('error');
        }

        setProcessedResponse({
            errorMessages,
            recordResults,
            submission: {
                ...response.result,
                wholesales: response.result?.wholesales
                    .filter(w => w.recordResult?.rowResult !== 'Deleted')
            },
            validationMessages,
        });
    }, [response]);

    useEffect(() => {
        setProcessedError(stringifyServerError(serviceError));
    }, [serviceError]);

    return {
        processedError,
        processedResponse,
        status: status as Status,
    };
}

export default useWholesalingUpdateResponse;

export type UseWholesalingUpdateResponse = ReturnType<typeof useWholesalingUpdateResponse>;

function getRecordResults(submission: WholesaleSubmission) {
    return [
        ...submission.wholesaleComments
            .filter(c => isRecordResult(c.recordResult))
            .map(c => c.recordResult as RecordResult),
        ...submission.wholesales
            .filter(w => isRecordResult(w.recordResult))
            .map(w => w.recordResult as RecordResult)
    ];
}