import { SubmissionType } from "psims/models/ref-data/submission-type";
import usePortalDataAPI from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api";
import { useMemo } from "react";

interface UseFspPortalDataProps {
  submissionType: SubmissionType;
}

function useFspPortalData({submissionType}: UseFspPortalDataProps) {
  const portalDataAPICtrl = usePortalDataAPI({submissionType});
  
  const productPortalData = useMemo(() => {
    return (portalDataAPICtrl.portalData?.referenceTypeData || [])
      .filter(rtd => rtd.referenceTypeName === submissionType.name)
      .map(rtd => rtd.productData)
      .flat();
  }, [portalDataAPICtrl.portalData, submissionType.name])

  return useMemo(() => ({
    productPortalData,
  }), [productPortalData]);
}

export default useFspPortalData

export type UseFspPortalData = ReturnType<typeof useFspPortalData>;
