const ALLOW_COMMAS_REGEX = /^-?(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/;

export function asNumber(maybeNumber: unknown): number {
    const nbr = Number(maybeNumber);

    if (isNaN(nbr)) {
        return 0;
    }

    return nbr;
}

export const isNumeric = (value: any) => {
    return value && value !== '' && !isNaN(Number(value));
};

export function numberFieldValue(val?: number | string | null) {
    if (val == null || val === '') {
        return undefined;
    }

    return asNumber(val);
}

export function parseNumber(value: number | string) {
    if (typeof value === 'number') {
        return value;
    }

    if (isNumeric(value)) {
        return Number(value);
    }

    // Check if number is valid with commas
    if (typeof value === 'string' && value.match(ALLOW_COMMAS_REGEX) !== null) {
        const removedCommas = Number(value.replaceAll(',', ''));
        if (!isNaN(removedCommas)) {
            return removedCommas;
        }
    }

    throw new Error(`Could not parse ${value} as number`);
}

export function isExponent(value: unknown) {
    const asNumber = Number(value);

    // Only concerned with negative exponents for now
    if (value != null && asNumber.toString().indexOf('e-') > -1) {
        return true;
    }

    return false
}

// Ensure a number is always shown in decimal form (high-precision numbers are
// somewhat arbitrarily represented in exponent form when stringified)
export function deExponentialise(value: number | string, decimalPlaces?: number) {
    if (typeof value === 'string') {
        return value;
    }

    const asStr = value.toString();

    // handle decimal exponents
    if (asStr.indexOf('e-') > -1) {
        const decimalParts = asStr.split('.');
        const expParts = decimalParts.length === 1 ? decimalParts[0] : decimalParts[1];
        const parts = expParts.split('e-');
        const precision = parts[0].length + Number(parts[1]);
        return value.toFixed(precision - (decimalParts.length === 1 ? 1 : 0));
    }

    return decimalPlaces == null ? asStr : toFixedNoRounding(value, decimalPlaces);
}

function toFixedNoRounding(value: number, preferredDecimalPlaces?: number) {
    if (preferredDecimalPlaces == null) {
        return value.toString();
    }

    const asFixed = value.toFixed(preferredDecimalPlaces);

    // Don't use fixed if it results in different value
    if (Number(asFixed) !== value) {
        // Manually take decimal places
        const str = value.toString()
        if (str.indexOf('.') === -1) {
            return str;
        }
        let [whole, dec] = str.split('.');
        dec = dec.substring(0, preferredDecimalPlaces);
        // Pad trailing 0s if necessary
        const diff = preferredDecimalPlaces - dec.length;
        if (diff > 0) {
            dec = `${dec}${'0'.repeat(diff)}`;
        }
        return `${whole}.${dec}`;
    }

    return asFixed;
}