import React, { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';

import useSubmissionLoadState from 'psims/react/pages/primary-pages/data-submissions/shared/use-submission-load-state';
import LoadingSubmission from 'psims/react/pages/primary-pages/data-submissions/shared/loading-submission';
import LoadSubmissionFailed from 'psims/react/pages/primary-pages/data-submissions/shared/load-submission-failed';
import useProductionRefData, { isUseProductionRefData, UseProductionRefData } from '../productions-shared/use-production-ref-data';
import useProductionAPI, { UseProductionAPI } from './use-production-api';
import ProductionEditor from './production-editor';
import { ProductionTypeConfig } from './util';
import { ProductionProductGroup } from 'psims/models/ref-data/production-product-group';

interface ProductionProps<TGroup extends ProductionProductGroup> {
    id: number;
    productionTypeConfig: ProductionTypeConfig<TGroup>;
}

const Production = <TGroup extends ProductionProductGroup>(props: ProductionProps<TGroup>) => {
    const vm = useVM(props);

    switch(vm.loadState) {
        case 'error':
            return <LoadSubmissionFailed submissionName={vm.productionTypeName} />
        case 'loaded':
            return <ProductionEditor
                apiCtrl={vm.apiCtrl as PopulatedProps<UseProductionAPI, 'submission'>}
                productionTypeConfig={props.productionTypeConfig}
                refData={vm.refData as UseProductionRefData}
            />;
        default:
            return <LoadingSubmission submissionName={vm.productionTypeName} />;

    }
}

function useVM<TGroup extends ProductionProductGroup>({id, productionTypeConfig}: ProductionProps<TGroup>) {
    const {groupAsserter, productionTypeName} = productionTypeConfig;
    const apiCtrl = useProductionAPI({id, productionTypeName});
    const refData = useProductionRefData({groupAsserter, submission: apiCtrl.submission});
    const [loaded, setLoaded] = useState(false);

    const ready = useMemo(() => {
        return loaded && isUseProductionRefData(refData);
    }, [loaded, refData])

    const loadState = useSubmissionLoadState({submission: apiCtrl.submission, isDepsReady: ready, loadStatus: apiCtrl.loadStatus})

    useEffect(() => {
        if (apiCtrl.loadStatus === 'fetched') {
            setLoaded(true);
        }
    }, [apiCtrl.loadStatus]);

    return {
        apiCtrl,
        loadState: loadState as typeof loadState,
        refData,
        productionTypeName,
    }
}

const Page = <TGroup extends ProductionProductGroup>(props: ProductionProps<TGroup>) => {
    const isInvalidParams = isNaN(props.id);

    return isInvalidParams ? <Redirect to='/' /> : <Production {...props} />;
}

export default Page;
