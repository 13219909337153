import { all } from "./collections";

export function is<T>(maybe: T | null | undefined): maybe is T {
    return maybe != null;
}

export function isWithPopultatedProp<T, K extends keyof T>(maybe: T, prop: K): maybe is PopulatedProps<T, K> {
    return maybe != null && maybe[prop] != null;
}

export function assertFilter<TAssert>(
	array: unknown,
	typeAssertion: (maybe: unknown) => maybe is TAssert,
	typeName: string,
	failureLogger: (msg: string) => any = (msg: string) => {}
): Array<TAssert> {
	if (!Array.isArray(array)) {
		failureLogger(`Failed type assertion for ${typeName}: passed non-array argument`);
		return [];
	}

	return (array || []).filter(item => {
		const result = typeAssertion(item);
		if (!result) {
			failureLogger(`Failed type assertion for ${typeName}: ${JSON.stringify(item)}`);
		}
		return result;
	}) as Array<TAssert>;
}

export function buildAssertArrayOf<T>(asserter: TypeAssertion<unknown, T>): (maybe?: unknown) => maybe is Array<T> {
	return ((maybe?: unknown) => {
		if (!Array.isArray(maybe)) {
			return false;
		}
		const result = all(maybe, asserter)

		return result;
	}) as TypeAssertion<unknown, Array<T>>
}
