import { SubmissionPortalDataVM } from "psims/gen/xapi-client";

export type SubmissionPortalData = PopulatedProps<SubmissionPortalDataVM, 'referenceTypeData'>

export function isSubmissionPortalData(maybe?: unknown): maybe is SubmissionPortalData {
  const maybeAs = maybe as SubmissionPortalData;

  return (
    maybeAs != null &&
    maybeAs.referenceTypeData != null
  )
}