// Date format: dd/mm/yyyy
export const LOCAL_DATE_REG_EX = /^([\d]{1,2})\/([\d]{1,2})\/([\d]{4})$/

export function isValidLocalDate(str: string) {
  const match = str.match(LOCAL_DATE_REG_EX);

  if (match == null) {
    return false;
  }

  const [, dd, mm, yyyy] = match;

  // can safely assume numbers based on regex - i.e. no need
  // to test for NaN
  const day = Number(dd);
  const month = Number(mm);
  const year = Number(yyyy);

  // Valid month
  if (month < 1 || month > 12) {
    return false;
  }

  // Valid day
  if (day < 1 || day > monthDays(month, year)) {
    return false;
  }

  return true;
}

function monthDays(month: number, year: number) {
  switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      return 31;
    
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    
    case 2: {
      return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28;
    }

    default: {
      throw new Error('Failed to get number of days in month: month must be bewtween 1 and 12 inclusive');
    }
  }
}