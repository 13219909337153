import { isEmpty } from "psims/lib/empty";
import { isValidCommentCharacters, isValidCommentMaxLength } from "psims/lib/validation/comments";

export type ValidationResult = {
  code: 'invalid_char_length' | 'invalid_chars';
  field: 'market_expectations';
}

export function validateMarketExpectations(value: string | null | undefined) {
  const validationResults: Array<ValidationResult> = [];

  if (isEmpty(value)) {
    return validationResults;
  }
  
  let code = validateField(value);

  if (code != null) validationResults.push({code, field: 'market_expectations'});

  return validationResults;
}

function validateField(val?: string | null | undefined): null | ValidationResult['code'] {
  const trimmedValue = (val || '').trim();

  if (!isValidCommentMaxLength(trimmedValue)) {
    return 'invalid_char_length';
  }

  if (!isValidCommentCharacters(trimmedValue)) {
    return 'invalid_chars';
  }

  return null;
}
