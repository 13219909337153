import { ValidationMessageVM } from "psims/gen/xapi-client";

export type ValidationMessage = ValidationMessageVM;

type ErrorMessages = Array<string> | null;

type ValidationMessages = Array<ValidationMessage> | null;

export interface APIResponse<TResult> {
    errorMessages?: ErrorMessages;
    validationMessages?: ValidationMessages;
    isSuccessful?: boolean;
    result?: TResult;
}

interface SuccessfulAPIResponse<TResult> extends APIResponse<TResult> {
    isSuccessful: true;
    result: TResult;
}

export type ResponseStatus = 'successful' | 'validation_errors' | 'service_errors' | 'null_response' | 'null_result' | 'unknown';

type APIResponseWithResult<TResult> = PopulatedProps<APIResponse<TResult>, 'result'>;

type APIResponseWithErrorMessages = PopulatedProps<APIResponse<any>, 'errorMessages'>;

type APIResponseWithValidationMessages = PopulatedProps<APIResponse<any>, 'validationMessages'>;

type ResultAssertion<TMaybeResult, TResult> = TypeAssertion<TMaybeResult, TResult> | TypeAssertionFn<TMaybeResult, TResult>;

export function getResponseStatus(response: APIResponse<any> | null): ResponseStatus {
    if (isSuccessfulAPIResponse(response)) {
        return 'successful';
    }

	if (response == null) {
		return 'null_response';
	}

	if (response.result == null) {
		return 'null_result';
	}

	if (isAPIResponseWithValidationMessages(response) && response.validationMessages.length > 0) {
		return 'validation_errors';
	}

    if (isAPIResponseWithErrorMessages(response) && response.errorMessages.length > 0) {
	    return 'service_errors';
    }

    return 'unknown';
}

export function isAPIResponse (maybe: APIResponse<any> | null | undefined): maybe is APIResponseWithResult<any> {
    return maybe != null && (
        maybe.errorMessages != null ||
        maybe.result != null ||
        maybe.validationMessages != null
    );
}

export function isAPIResponseWithResult<TMaybeResult, TResult extends TMaybeResult> (maybe: APIResponse<TMaybeResult> | null | undefined, assertion?: ResultAssertion<TMaybeResult, TResult>): maybe is APIResponseWithResult<TResult> {
    if (maybe?.result == null) {
        return false;
    }
    if (assertion != null) {
        const assertionResult = assertion(maybe.result);
        return assertionResult === undefined ? true : assertionResult;
    }

    return true;
}

function isAPIResponseWithErrorMessages<TResult> (maybe?: APIResponse<TResult> | null): maybe is APIResponseWithErrorMessages {
    return Array.isArray(maybe?.errorMessages);
}

function isAPIResponseWithValidationMessages<TResult> (maybe?: APIResponse<TResult> | null): maybe is APIResponseWithValidationMessages {
    return Array.isArray(maybe?.validationMessages);
}

function isSuccessfulAPIResponse<TResult>(maybe: APIResponse<TResult> | null | undefined): maybe is SuccessfulAPIResponse<TResult> {
    return Boolean(maybe?.isSuccessful);
}

export function isSuccessfulAPIResponseWithResult<TMaybeResult, TResult extends TMaybeResult>(maybeSuccessfulAPIResponse: APIResponse<TMaybeResult> | null | undefined, assertion: ResultAssertion<TMaybeResult, TResult>): maybeSuccessfulAPIResponse is SuccessfulAPIResponse<TResult> {
    return Boolean(maybeSuccessfulAPIResponse?.isSuccessful) && isAPIResponseWithResult(maybeSuccessfulAPIResponse, assertion);
}

export function getErrorMessages(response?: APIResponse<any>): ErrorMessages {
    return response?.errorMessages || null;
}

export function getValidationMessages(response?: APIResponse<any>): ValidationMessages {
    return response?.validationMessages || null;
}