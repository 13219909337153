import { BoxedDiv } from 'psims/react/components/layout';
import Header from 'psims/react/blocks/data-submission-header';
import SubmissionProgressBar from 'psims/react/components/submission-progress-bar';
import { H2 } from 'psims/react/components/typography';
import ScrollTo from 'psims/react/components/scroll-to';
import { UseRefineryAPI } from './use-refinery-api';
import useRefinery from './use-refinery';
import RefineryTypeEditor from './refinery-type-editor';
import { ViewMode } from '../shared/use-view-mode';
import Button from 'psims/react/components/button';
import RefinerySubmit from './refinery-submit';
import RefineryNotifications from './refinery-notifications';
import ConfirmSaveChanges from 'psims/react/components/confirm-save-changes';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';
import DataSubmissionPrimaryControls from 'psims/react/components/data-submission-primary-controls';
import { AllReferenceData } from 'psims/models/ref-data';
import MainContainer from 'psims/react/pages/primary-pages/data-submissions/shared/main-container';
import { DeleteConfirmationDialog } from '../shared/delete-confirmation';

interface RefineryEditorProps {
    apiCtrl: PopulatedProps<UseRefineryAPI, 'submission'>;
    allRefData: AllReferenceData;
}

const RefineryEditor = (props: RefineryEditorProps) => {
    const vm = useVM(props);

    const {
        content,
        controlsCtrl: {primaryControl, secondaryControl},
        formCtrl,
        importCtrl,
        progressCtrl,
        portalDataAPICtrl,
        saveCtrl,
        serviceResponse,
        submitCtrl,
        submission,
        isBusy,
        viewMode
    } = vm.ctrl;
    
    const currentStep = progressCtrl.currentStep.kind === 'data' ? progressCtrl.currentStep.refData.refineryType.name : 'submit';
    const shouldShowExtraButtons = currentStep === 'Refinery Input' && viewMode === 'edit';

    return (
        <MainContainer dataSubmission={vm.ctrl.submission.dataSubmission}>

            <BoxedDiv box={{}}>
                {submission?.dataSubmission !== undefined ? <Header 
                    clearFile={importCtrl.clearFile}
                    dataSubmission={submission.dataSubmission}
                    file={importCtrl.file}
                    showExtraButtons={shouldShowExtraButtons}
                    handleClearData={vm.ctrl.clearDataCtrl.handleClearData}   
                    handleTemplateImport={importCtrl.handleTemplateImport}
                    process={importCtrl.process}
                    setTemplateImportState={importCtrl.setTemplateImportState}
                    templateImportState={importCtrl.templateImportState}  
                    currentStepIndex={vm.ctrl.progressCtrl.currentStep.index}                       
                /> : null }

                <BoxedDiv className='container' box={{marginV: 4}}>
                    
                    {/* Progress indicator */}
                    <BoxedDiv box={{marginV: 6}}>
                        <SubmissionProgressBar
                            isComplete={viewMode === 'view'}
                            steps={progressCtrl.progressSteps}
                            onStepClick={saveCtrl.checkChangesAndGoToPage}
                        />
                    </BoxedDiv>

                    {/* Step frontmatter */}
                    {
                        content && <>
                            <H2>{content.title}</H2>
                            <BoxedDiv box={{marginTop: 4}}>
                                <vm.ctrl.content.Help />
                            </BoxedDiv>
                        </>
                    }

                    {/* Main form editor */}
                    {
                        progressCtrl.currentStep.kind === 'data' &&
                        <RefineryTypeEditor
                            formCtrl={formCtrl}
                            portalDataAPICtrl={portalDataAPICtrl}
                            typeId={progressCtrl.currentStep.refData.refineryType.id}
                            typeName={progressCtrl.currentStep.refData.refineryType.name}
                            viewMode={viewMode as ViewMode}
                        />

                    }

                    {/* Submit page */}
                    {
                        progressCtrl.currentStep.kind === 'submit' &&
                        <RefinerySubmit
                            formCtrl={formCtrl}
                            viewMode={viewMode as ViewMode}
                        />
                    }

                    {/* Page messages */}
                    {
                        viewMode === 'edit' ?
                        <RefineryNotifications
                            formCtrl={formCtrl}
                            progressCtrl={progressCtrl}
                            saveCtrl={saveCtrl}
                            serviceResponse={serviceResponse}
                            submitCtrl={submitCtrl}
                        /> :
                        null
                    }

                    {/* Controls */}
                    <DataSubmissionPrimaryControls>
                        <BoxedDiv box={{alignItems: 'center', flex: 'row-reverse', marginTop: 4}}>
                            {
                                primaryControl.isShown &&
                                <Button
                                    disabled={primaryControl.isDisabled}
                                    $kind='primary'
                                    onClick={primaryControl.action}
                                    state={primaryControl.state}
                                >{primaryControl.label}</Button>
                            }

                            {
                                secondaryControl.isShown &&
                                <Button
                                    disabled={secondaryControl.isDisabled}
                                    $kind='ghost'
                                    marginRight={2}
                                    onClick={secondaryControl.action}
                                    state={secondaryControl.state}
                                >{secondaryControl.label}</Button>
                            }

                        </BoxedDiv>
                    </DataSubmissionPrimaryControls>

                    {/* Unsaved changes confirmation */}
                    <ConfirmSaveChanges
                        controlsState={isBusy ? 'loading' : 'normal'}
                        isOpen={saveCtrl.unsavedChangesState === 'showing_dialog'}
                        onCancel={saveCtrl.cancelSave}
                        onConfirm={saveCtrl.confirmSave}
                        onSecondary={saveCtrl.proceedWithoutSave}
                    />

                    {/* Confirm Clear all dialog */}
                    {
                        shouldShowExtraButtons &&
                        <ConfirmationDialog
                            ariaLabel='Clear all data confirm'
                            body={vm.ctrl.clearDataCtrl.confirmCtlr.message}
                            title={vm.ctrl.clearDataCtrl.confirmCtlr.title}
                            onCancel={vm.ctrl.clearDataCtrl.confirmCtlr.cancel}
                            onConfirm={vm.ctrl.clearDataCtrl.confirmCtlr.confirm}
                            isOpen={vm.ctrl.clearDataCtrl.confirmCtlr.confirmState === 'confirming'}
                        />
                    }

                    {/* Review alerts dialog */}
                    <ConfirmationDialog
                        ariaLabel='Your data contains alerts - please review before continuing'
                        body={
                            <BoxedDiv box={{flex: 'column'}}>
                                <p>The data on this page has triggered one or more alerts, which require you to provide a comment.</p>
                                <p>If you want to review these alerts and review or add a comment, please click Review alerts.</p>
                                <p>If you have addressed these and entered a comment, click Proceed to continue to the next page.</p>
                            </BoxedDiv>
                        }
                        cancelLabel='Review alerts'
                        isOpen={saveCtrl.validationAlertsState === 'showing_dialog'}
                        onCancel={saveCtrl.cancelValidationAlerts}
                        onConfirm={saveCtrl.confirmValidationAlerts}
                        title='Your data contains alerts - please review before continuing'
                    />

                    {/* Confirm delete dialog */}
                    <DeleteConfirmationDialog
                        title='Confirm record deletion'
                        deleteRequestState={formCtrl.deleteRequestState}
                        setDeleteRequestState={formCtrl.setDeleteRequestState}
                    />

                    {/* Scroll helper */}
                    <ScrollTo />
                </BoxedDiv>
            </BoxedDiv>
        </MainContainer>
    );
}

function useVM({allRefData, apiCtrl}: RefineryEditorProps) {
    const ctrl = useRefinery({allRefData, apiCtrl});

    return {
        ctrl
    };
}

export default RefineryEditor;
