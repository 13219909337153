import React from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import PageNotifications from 'psims/react/pages/primary-pages/data-submissions/shared/page-notifications';

interface NotificationsProps {
    infoNotifications: Array<{content: JSX.Element | null}>;
    systemNotifications: Array<{content: JSX.Element | null}>;
    validationNotifications: Array<{content: JSX.Element | null}>;
}

const Notifications = ({infoNotifications, systemNotifications, validationNotifications}: NotificationsProps) => {
    return (
        <BoxedDiv box={{}} data-notifications={true}>
            {/* UI validation error messages */}
            {
                validationNotifications.length > 0 &&
                <PageNotifications kind='validation' items={validationNotifications.map(v => v.content).filter(c => c != null)} />
            }

            {/* System alerts */}
            {
                systemNotifications.length > 0 &&
                <PageNotifications kind='system_alert' items={systemNotifications.map(v => v.content)} />
            }

            {/* Info messages */}
            {
                infoNotifications.length > 0 &&
                <PageNotifications kind='info' items={infoNotifications.map(v => v.content)} />
            }

        </BoxedDiv>
    );
}

export default Notifications;

export function buildInvalidCommentNotificationContent(message: string, onTargetClick: () => any, onAssociatedTargetClick?: () => any) {
    return [
        'For ',
        {label: `Go to comments field`, onClick: onTargetClick, text: 'Comments'},
        `: ${message}`,
    ];
}