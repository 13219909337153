export function isEmpty(val?: unknown) {
	if (val === undefined || val === null || val === '') {
		return true;
	}

	if (typeof val === 'object') {
		const valsEmpty = Object.values(val).reduce((memo, v) => {
			return memo && isEmpty(v);
		}, true);

		if (valsEmpty) {
			return true;
		}
	}

	return false;
}

export function assertNotEmpty<T>(val?: unknown, hint?: string): asserts val is Exclude<T, null | undefined | ''> {
	if (isEmpty(val)) {
		throw new Error(`Failed to assert ${hint ? `(${hint}) ` : ''}not empty: ${JSON.stringify(val)}`);
	}
}
