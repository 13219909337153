import Production from '../productions-shared';
import { ProductionProductGroup } from 'psims/models/ref-data/production-product-group';
import Text from "psims/react/components/text";
import { ApplicableFields, FieldConfig } from '../productions-shared/util';
import { BoxedDiv } from 'psims/react/components/layout';
import { ProductionProduct } from 'psims/models/ref-data/production-product';
import { getFieldProductionProductReferenceCode } from 'psims/models/ref-data/field-production-product';
import { getFieldProductionGroupReferenceCode, isFieldProductionGroup } from 'psims/models/ref-data/field-production-group';
import { setCapitalisation } from 'psims/lib/string';

interface FieldProductionProps {
  id: number;
}

const SHORT_PRODUCT_NAME_MAP = {
  [getFieldProductionProductReferenceCode('ico')]: 'Crude oil',
  [getFieldProductionProductReferenceCode('fc')]: 'Condensate',
}

function isGas(referenceCode?: string | null) {
  return getFieldProductionGroupReferenceCode('ng') === referenceCode;
}

function getUnits(referenceCode?: string | null) {
  return isGas(referenceCode) ?
    <span>000 m<sup>3</sup></span> :
    'Kilolitres';
}

function getName({ referenceCode, productName }: ProductionProduct) {
  return SHORT_PRODUCT_NAME_MAP[referenceCode] || productName;
}

function showOpenClose(referenceCode?: string | null) {
  return !isGas(referenceCode);
}

function densityAtEnd(referenceCode?: string | null) {
  return isGas(referenceCode);
}

function showGCV(referenceCode?: string | null) {
  return isGas(referenceCode);
}

function getDensityConfig(): FieldConfig {
  return {
    field: 'productionDensity',
    columnHeader: 'Average density (Kg/L)',
    help: (
      <BoxedDiv box={{ flex: 'column', width: '350px' }}>
        <p>Report the weighted average density in kilograms per litre (Kg/L) for each product during the month.</p>
        <p>If the density is not readily available each month, the most recent density measurement should be used. If no measurement is available, a typical or average value may be entered, which should be reviewed and updated as appropriate.</p>
        <p>To convert API to Kg/L: <em>Density (Kg/L) = 141.5 ÷ (131.5 + API)</em></p>
      </BoxedDiv>
    ),
    helpId: 'density_help',
    label: 'average density',
  }
}

const applicableFields: ApplicableFields = (product, group) => {
  const { referenceCode } = product;
  return [
    {
      field: 'organisationProductionAreaId',
      columnHeader: 'Production area',
      help: (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
          <p>A hydrocarbon rich area that may be exploited by multiple wells and possibly by more than one independent business entity.</p>
          <p>If an operator is reporting on behalf of all the owners in a joint venture, they should report the total production from the field regardless of ownership.</p>
          <p>If two or more fields are linked to a common project, businesses may report at the project level. </p>
          <p>If an operator is reporting on behalf of all the owners in a joint venture, they should report the total production from the project regardless of ownership. </p>
        </BoxedDiv>
      ),
      helpId: 'production_area_help',
      label: 'production area',
    },
    ...(!densityAtEnd(group?.referenceCode) ? [getDensityConfig()] : []),
    ...(showOpenClose(group?.referenceCode) ? [{
      field: 'openingStocks',
      columnHeader: <span>Opening stocks of {setCapitalisation(getName(product), 'down')} at the field ({getUnits(group?.referenceCode)})</span>,
      help: (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
          <p>The actual physical amount held in storage at the beginning and end of the month. For both opening and closing stocks only one figure is required for each product at each field/project.</p>
          <p><strong>Include</strong> stock held in any tanks or facilities associated with the field, including stabilisation units, holding tanks, etc.</p>
          <p>The opening stock of the current month should always be identical to the closing stock for the previous month.</p>
        </BoxedDiv>
      ),
      helpId: 'opening_stocks_help',
      label: 'opening stocks',
    } as FieldConfig] : []), {
      field: 'produced',
      columnHeader: isGas(group?.referenceCode) ?
        <span>Produced for commercial usage ({getUnits(group?.referenceCode)})</span> :
        <span>{getName(product)} produced at the field ({getUnits(referenceCode)})</span>,
      help: (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
          <p>The total volume produced at the field, <strong>excluding</strong> any water, sediment or volumes that were re-injected into the formation but <strong>including</strong> dissolved gases and contaminants.</p>
        </BoxedDiv>
      ),
      helpId: 'produced_help',
      label: 'produced at the field',
    }, {
      field: 'consumed',
      columnHeader: isGas(group?.referenceCode) ?
        <span>Field and plant usage ({getUnits(group?.referenceCode)})</span> :
        <span>{getName(product)} consumed as fuel at the field ({getUnits(group?.referenceCode)})</span>,
      help: (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
          <p>The total volume of product that was produced from the field and was consumed as fuel to provide power at the field.</p>
          <p>If the fuel powers multiple fields please report the total consumed under one field and note the other fields that were powered in the comments section. <strong>Exclude</strong> any fuel that was not produced at the facility.</p>
        </BoxedDiv>
      ),
      helpId: 'consumed_help',
      label: 'consumed at the field',
    }, {
      field: 'delivered',
      columnHeader: isGas(group?.referenceCode) ?
        <span>Despatched ({getUnits(group?.referenceCode)})</span> :
        <span>{getName(product)} delivered from the field ({getUnits(group?.referenceCode)})</span>,
      help: (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
          <p>The total volume of marketable product that was delivered for domestic consumption or export.</p>
          <p><strong>Exclude</strong> any amount re-injected, flared, consumed as fuel, destroyed or otherwise disposed of.</p>
        </BoxedDiv>
      ),
      helpId: 'delivered_help',
      label: 'delivered from the field',
    }, ...(showOpenClose(group?.referenceCode) ? [{
      field: 'closingStocks',
      columnHeader: <span>Closing stocks of {setCapitalisation(getName(product), 'down')} at the field ({getUnits(group?.referenceCode)})</span>,
      help: (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
          <p>The actual physical amount held in storage at the beginning and end of the month. For both opening and closing stocks only one figure is required for each product at each field/project.</p>
          <p><strong>Include</strong> stock held in any tanks or facilities associated with the field, including stabilisation units, holding tanks, etc.</p>
          <p>The opening stock of the current month should always be identical to the closing stock for the previous month.</p>
        </BoxedDiv>
      ),
      helpId: 'closing_stocks_help',
      label: 'closing stocks'
    } as FieldConfig] : []),
    ...(densityAtEnd(group?.referenceCode) ? [getDensityConfig()] : []),
    ...(showGCV(group?.referenceCode) ? [{
      field: 'grossCalorificValue',
      help: (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
          <p>Report the weighted average GCV in megajoules per cubic metre (MJ/m3) for each product during the month.</p>
          <p>If the GCV is not readily available each month, the most recent measurement should be used. If no measurement is available, a typical or average GCV may be entered, which should be reviewed and updated as appropriate.</p>
        </BoxedDiv>
      ),
      columnHeader: <span>Gross calorific value (Mj/m<sup>3</sup>)</span>,
      label: 'gross calorific value'
    } as FieldConfig] : []),
  ]
};

const FrontMatter = () => {
  return (
    <>
      <p>
        <Text>Density and volumetric information should be adjusted to standard temperature and pressure conditions (15°C & 1 atmosphere pressure (101.325kPa)).</Text>
      </p>

      <p>
        <Text>Information provided in the table below should only relate to activities within the calendar month identified.</Text>
      </p>
    </>
  );
};

function getGroupHeader(group: ProductionProductGroup) {
  return group.name;
}

const FieldProduction = ({ id }: FieldProductionProps) => {
  return <Production
    id={id}
    productionTypeConfig={{
      applicableFields,
      FrontMatter,
      getGroupHeader,
      groupAsserter: isFieldProductionGroup,
      productionTypeName: 'Field production',
    }}
  />
}

export default FieldProduction;
