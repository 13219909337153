import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import { UpdateMso, UpdateMsoField } from 'psims/models/submission-types/mso/mso';
import { UseMsoRefData } from './use-mso-ref-data';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { H3 } from 'psims/react/components/typography';
import { ColumnHeader, Table, TD, TH, TR } from 'psims/react/pages/primary-pages/data-submissions/shared/data-table-components';
import { OrganisationMsoSetting } from 'psims/models/submission-types/mso/organisation-mso-setting';
import { TooltipHelp } from 'psims/react/pages/primary-pages/data-submissions/shared/tooltip-help';
import { asNumber } from 'psims/lib/number';
import { localeNumberWithFixed } from 'psims/lib/formatters/numbers';
import FloatingMessage from 'psims/react/components/floating-message';
import { any } from 'psims/lib/collections';
import Input from '../../shared/input';
import { ModelValidation } from './validation';
import { MsoFocusField } from './types';
import { UsePortalData } from '../../shared/use-portal-data';

interface MsoSummaryEditorActions {
    updateAcquitted: (msoProductId: number, value: UpdateMso['acquittedAmount']) => any;
}

type UpdateMsoView = UpdateMso & {validationMessages: ModelValidation<UpdateMsoField>};

const REFERENCE_TITLE_MAP: {[key in 'importer' | 'refiner']: string} = {
    importer: 'Importer section',
    refiner: 'Refiner and Held at refinery (s25) sections',
  };

interface MsoSummaryEditorProps {
    actions: MsoSummaryEditorActions;
    focusedField: unknown | null;
    isDisabled: boolean;
    msos: Array<UpdateMsoView>;
    msoSettings: Array<OrganisationMsoSetting>;
    portalData: UsePortalData;
    refData: UseMsoRefData;
    saveAttempted: boolean;
    msoTypeName: 'importer' | 'refiner';
    validationAlerts: Array<{tooltip: {message: string, target: {msoProductId: number}}}>;
}

const MsoSummaryEditor = ({
    actions,
    focusedField,
    isDisabled,
    msos,
    msoSettings,
    portalData,
    refData,
    saveAttempted,
    validationAlerts,
    msoTypeName
}: MsoSummaryEditorProps) => {
    const hasAcquittedCol = useMemo(() => {
        return any(msoSettings, s => Boolean(s.msoAcquitted));
    }, [msoSettings]);

    return (
        <BoxedDiv box={{flex: 'column'}}>
            <BoxedDiv box={{flex: 'column'}}>
                <BoxedDiv box={{marginTop: 6}}>
                    <H3>Minimum stockholding obligation</H3>
                </BoxedDiv>
            </BoxedDiv>

            <BoxedDiv box={{ marginV: 2 }}>
                <p>Automatically generated totals of each MSO Product being held towards your MSO using figures supplied in the Stockholding - {REFERENCE_TITLE_MAP[msoTypeName]} above.</p>
            </BoxedDiv>

            <BoxedDiv box={{marginV: 2}} data-mso-summary>
                <Table caption='Summary of your minimum stockholding obligation data for this data submission'>
                    <thead>
                        <TR>
                            <TH fixedWidth='230px' scope='col'>MSO Product</TH>
                            <ColumnHeader
                                $align='center'
                                Help={'The amount your entity is required to hold on an obligation day according to your most recent Notice from the Secretary.'}
                                label='Minimum stockholding obligation '
                                scope='col'
                            />
                                
                            <ColumnHeader
                                $align='center'
                                label='Total supplied on this form'
                                scope='col'
                                Help={'The aggregate amount of eligible MSO product held by your entity and reported in this form.'}
                            />
                            {
                                hasAcquittedCol &&
                                <ColumnHeader
                                    $align='center'
                                    label='Acquittal amount'
                                    scope='col'
                                    Help={<>
                                        <p>Entities operating as a refiner and an importer can acquit stocks across an aggregated MSO. For reporting purposes, each MSO activity is reported separately, with the acquittal arrangements to be specified in the comment field in line with your MSO compliance plan.</p>
                                        <p>You may only add a positive number to this section if required - do not minus the figure from the other form.</p>
                                    </>}
                                />
                            }

                            <ColumnHeader
                                $align='center'
                                scope='col'
                                label='Difference'
                                Help={'The sum of your entity\'s MSO compared to the MSO product held by your entity.'}
                            />
                        </TR>
                    </thead>

                    <tbody>
                        {
                            msoSettings.map((msoSetting, index) => {
                                const product = refData.productIdMap[msoSetting.msoProductId];

                                // Only show products that have an associated MSO Setting
                                if (product == null) {
                                    return null;
                                }

                                return <MsoRow
                                    key={index}
                                    actions={actions}
                                    focusedField={focusedField}
                                    hasAcquittedCol={hasAcquittedCol}
                                    isDisabled={isDisabled}
                                    msos={msos}
                                    msoSettings={msoSettings}
                                    portalData={portalData}
                                    product={product}
                                    saveAttempted={saveAttempted}
                                    validationAlert={validationAlerts.find(va => va.tooltip.target.msoProductId === product.id)}
                                />
                            })
                        }
                    </tbody>
                </Table>
            </BoxedDiv>
        </BoxedDiv>
    )
}

interface MsoRowProps {
    actions: MsoSummaryEditorActions;
    focusedField: unknown | null;
    hasAcquittedCol: boolean;
    isDisabled: boolean;
    msos: Array<UpdateMsoView>;
    msoSettings: Array<OrganisationMsoSetting>;
    portalData: UsePortalData;
    product: UseMsoRefData['products'][number];
    saveAttempted: boolean;
    validationAlert?: {tooltip: {message: string}};
}

interface DifferenceProps {
    isAlert: boolean;
}

const Difference = styled.span`${({isAlert}: DifferenceProps) => `
    ${isAlert ? 'color: var(--color-error-text);' : ''}
`}`;

const MsoRow = ({
    actions,
    focusedField,
    hasAcquittedCol,
    isDisabled,
    msos,
    msoSettings,
    portalData,
    product,
    saveAttempted,
    validationAlert
}: MsoRowProps) => {
    const setting = useMemo(() => {
        return  msoSettings.find(s => s.msoProductId === product.id);
    }, [msoSettings, product]);

    const mso = useMemo(() => {
        if (setting == null) {
            return null;
        }

        const data = msos.find(m => m.msoOrganisationSettingId === setting.id);
        return {
            acquittedEnabled: setting.msoAcquitted,
            data,
        }
    }, [msos, setting]);

    const msoFF = useMemo(() => (
        isMsoFocusField(focusedField) && focusedField.msoProductId === setting?.msoProductId ? focusedField : null
    ), [focusedField, setting?.msoProductId])

    if (product == null || product.productStatus === 'inactive') {
        return null;
    }

    const Help = portalData.productPortalData.find(ppd => ppd?.referenceCode === product.code)?.content

    return (
        <TR>
            <TH scope='row' verticalAlign='middle'>
                <BoxedSpan box={{alignItems: 'center', flex: 'row', height: '52px'}}>
                    {product.name}
                    {
                        Help != null ?
                            <TooltipHelp
                                Help={<ReactMarkdown>{Help}</ReactMarkdown>}
                            /> :
                            null
                    }
                </BoxedSpan>
            </TH>

            <TD verticalAlign='middle'>
                <BoxedSpan box={{alignItems: 'center', flex: 'row', justifyContent: 'flex-end'}}>
                    {mso == null || mso.data == null ? '0' : localeNumberWithFixed(mso.data.minimumStockObligation)}
                </BoxedSpan>
            </TD>

            <TD verticalAlign='middle'>
                <BoxedSpan box={{alignItems: 'center', flex: 'row', justifyContent: 'flex-end'}}>
                    {mso == null || mso.data == null ? '0' : localeNumberWithFixed(mso.data.supplied)}

                    <BoxedSpan box={{alignItems: 'center', flex: 'row', marginLeft: 0.5, width: '24px'}}>
                        {mso?.data?.validationMessages.supplied && <FloatingMessage content={mso.data.validationMessages.supplied.tooltip.message} kind='warning' />}
                    </BoxedSpan>
                </BoxedSpan>
            </TD>
            
            {
                hasAcquittedCol &&
                <TD verticalAlign='middle'>
                    {
                        mso?.acquittedEnabled && setting != null?

                        <Input
                            kind='number'
                            disabled={isDisabled}
                            error={mso?.data?.validationMessages.acquittedAmount?.tooltip.message}
                            forceError={saveAttempted}
                            label='Acquittal amount'
                            value={mso?.data?.acquittedAmount}
                            onChange={v => actions.updateAcquitted(setting?.msoProductId, v)}
                            shouldFocus={msoFF?.field === 'acquittedAmount'}
                        /> :

                        <BoxedSpan box={{alignItems: 'center', flex: 'row', justifyContent: 'flex-end'}}>0</BoxedSpan>
                    }
                </TD>
            }

            <TD verticalAlign='middle'>
                <BoxedSpan box={{alignItems: 'center', flex: 'row', justifyContent: 'flex-end'}}>
                    {
                        mso == null || mso.data == null ?
                        '0' :
                        <Difference isAlert={asNumber(mso.data.difference) < 0}>{localeNumberWithFixed(mso.data.difference)}</Difference>
                    }

                    <BoxedSpan box={{alignItems: 'center', flex: 'row', marginLeft: 0.5, width: '24px'}}>
                        {
                            validationAlert ?
                            <FloatingMessage
                                content={validationAlert.tooltip.message}
                                kind='info'
                                role='alert'
                            /> :
                            mso?.data?.validationMessages.difference &&
                                <FloatingMessage
                                    content={mso.data.validationMessages.difference.tooltip.message}
                                    kind='warning'
                                    role='alert'
                                />
                        }
                    </BoxedSpan>
                    
                </BoxedSpan>
            </TD>
        </TR>
    )
}

export default MsoSummaryEditor;

function isMsoFocusField(maybe?: unknown | null): maybe is MsoFocusField {
    if (maybe == null) {
        return false;
    }

    const asF = maybe as MsoFocusField;

    return  (
        asF.kind === 'mso' &&
        asF.field != null &&
        asF.msoProductId != null
    );
}
