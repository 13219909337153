import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppOverlay } from '../providers/app-overlay';
import { useAuth } from '../providers/auth';
import { BoxedDiv } from './layout';
import Text from './text';
import Modal from './modal';
import Button from './button';
import { SESSION_SYNC_KEY } from 'psims/constants/app';

type SessionState = 'active' | 'warning' | 'expired';

interface SessionTimeoutProps {
  sessionLimitMins: number;
  warnAfterInactiveMins: number
}

interface SessionExpirationWarningProps {
  onReset: () => any;
  onLogout: () => any;
}

const SessionExpirationWarning = ({onReset, onLogout}: SessionExpirationWarningProps) => {
  return (
  <Modal>
    <BoxedDiv box={{alignItems: 'center', flex: 'column'}}>
        <BoxedDiv box={{alignItems: 'center', flex: 'row', marginLeft: 4}}>
          <Text>
            <p>Your session will expire in 1 minute. All unsaved changes will be lost.</p>
            <p>Would you like to resume your session?</p>
          </Text>
        </BoxedDiv>
        
        <BoxedDiv box={{alignItems: 'center', alignSelf: 'stretch', flex: 'row', justifyContent: 'flex-end', marginTop: 4}}>
          <Button
            $kind='ghost'
            onClick={onLogout}
          >Logout</Button>
          <Button 
            marginLeft={3}
            $kind='primary'
            onClick={onReset}
          >Resume</Button>
        </BoxedDiv>
    </BoxedDiv>
  </Modal>

  )
}

const useSessionTimeout = ({sessionLimitMins, warnAfterInactiveMins}: SessionTimeoutProps) => {
  const {logout} = useAuth();
  const {setOverlay} = useAppOverlay();
  const [activityEvents] = useState(['click', 'load', 'keyup']);
  const [sessionLimitMs] = useState(sessionLimitMins * 60 * 1000);
  const [warnAfterInactiveMs] = useState(warnAfterInactiveMins * 60 * 1000);
  const [warnWindowMs] = useState(sessionLimitMs - warnAfterInactiveMs);
  const [sessionState, setSessionState] = useState<SessionState>('active');
  const warnAtTimeoutId = useRef(-1);
  const logoutTimeoutId = useRef(-1);
  const loggingOut = useRef(false);
  
  const resetSession = useCallback((skipPublish?: boolean) => {
    setSessionState('active');
    window.clearTimeout(warnAtTimeoutId.current);
    window.clearTimeout(logoutTimeoutId.current);
    setOverlay(null);

    warnAtTimeoutId.current = window.setTimeout(() => {
      setSessionState('warning');
    }, warnAfterInactiveMs);

    if(!skipPublish){
      localStorage.setItem(SESSION_SYNC_KEY, Date.now().toString());
    }

  }, [setOverlay, warnAfterInactiveMs]);

  const startWarningWindow = useCallback(() => {
    logoutTimeoutId.current = window.setTimeout(() => {
      setSessionState('expired');
    }, warnWindowMs);
    setOverlay(<SessionExpirationWarning onReset={resetSession} onLogout={logout}/>)
  }, [logout, resetSession, setOverlay, warnWindowMs]);

  useEffect(() => {
    const _resetSession = () => resetSession(false);
    const _activityEvents = [...activityEvents];
    _activityEvents.forEach(event => window.addEventListener(event, _resetSession));

    return () => {
      _activityEvents.forEach(event => window.removeEventListener(event, _resetSession))
    }
  }, [activityEvents, resetSession]);

  useEffect(() => {
    switch(sessionState) {
      case 'warning':
        startWarningWindow();
        break;
      case 'expired': {
        if (!loggingOut.current) {
          logout();
        }
        loggingOut.current = true;
        break;
      }
    }
    
  }, [logout, sessionState, startWarningWindow]);

  useEffect(() => {
    function storageChangeHandler(event: StorageEvent) {
      if (event.key === SESSION_SYNC_KEY) {
        resetSession(true);
      }
    }

    window.addEventListener('storage', storageChangeHandler);

    return () => {
      window.removeEventListener('storage', storageChangeHandler);
    }
  }, [resetSession]);

  // First time session start
  useEffect(() => {
    resetSession();
  }, [resetSession])

  return {
    resetSession,
    sessionState,
  }
}

export default useSessionTimeout;
