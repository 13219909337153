import React, { useMemo } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import Textarea from 'psims/react/components/textarea';
import { UseMsoComment } from './use-mso-comment';

interface MsoCommentEditorProps {
  forceErrors: boolean;
  isDisabled: boolean;
  msoCommentCtrl: UseMsoComment;
}

const MsoCommentEditor = ({forceErrors, isDisabled, msoCommentCtrl}: MsoCommentEditorProps) => {
  const errors = useMemo(() => msoCommentCtrl.validations.map(v => v.inline), [msoCommentCtrl.validations])
  const error = useMemo(() => errors.filter(e => e != null)[0], [errors]);
  return (
    <BoxedDiv box={{marginTop: 4}}>
        <Textarea
            disabled={isDisabled}
            error={error}
            forceError={forceErrors}
            id='comments'
            label='Comments'
            onChange={e => msoCommentCtrl.updateComment(e.target.value)}
            setFocused={msoCommentCtrl.isFocused}
            value={msoCommentCtrl.data.comments || ''}
        />
    </BoxedDiv>
  );
}

export default MsoCommentEditor;
