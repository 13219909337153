/*
  Provides the static content for the current page
*/

import { RefineryTypeName } from "psims/models/ref-data/refinery-type";
import { useMemo } from "react";
import CONTENT_MAP from "./page-content";

interface UseContentForTypeProps {
    typeName: RefineryTypeName | 'Submit';
}

function useContentForType({typeName}: UseContentForTypeProps) {
    const content = useMemo(() => {
        return CONTENT_MAP[typeName];
    }, [typeName]);

    return content;
}

export default useContentForType;
