import { useMemo } from "react";

import { RefineryTypeName, withDisplayName } from "psims/models/ref-data/refinery-type";
import { isValidForPeriod } from "psims/models/ref-data/util";
import { AllReferenceData } from "psims/models/ref-data";

interface DataSubmission {
    reportingPeriodFrom: string;
    reportingPeriodTo: string;
}

function useRefDataForTypeName(
    refData: AllReferenceData,
    dataSubmission: DataSubmission,
    typeName: RefineryTypeName
) {
    const refineryProductGroups = useMemo(() => {
        if (refData == null) {
            return [];
        }
        return refData.refineryProductGroups;
    }, [refData]);

    const refineryProducts = useMemo(() => {
        if (refData == null) {
            return [];
        }
        return refData.refineryProducts;
    }, [refData]);

    const refineryTypes = useMemo(() => {
        if (refData == null) {
            return [];
        }
        return refData.refineryTypes;
    }, [refData]);


    const reportingPeriodFrom = useMemo(() => {
        if (dataSubmission == null) {
            return '';
        }
        return  dataSubmission.reportingPeriodFrom;
    }, [dataSubmission]);

    const reportingPeriodTo = useMemo(() => {
        if (dataSubmission == null) {
            return '';
        }
        return  dataSubmission.reportingPeriodTo;
    }, [dataSubmission]);

    const refineryType = useMemo(() => {
        if (refData == null) {
            return {
                id: 0,
                displayName: '',
                displayOrder: 1,
                isActive: false,
                name: typeName          
            };
        }
        const refineryType = refineryTypes
            .map(withDisplayName)
            .find(rt => rt.name === typeName);

        if (refineryType == null) {
            throw new Error(`Unknown refinery type name: ${typeName}`);
        }

        return refineryType;
    }, [refData, refineryTypes, typeName])

    const groups = useMemo(() => {
        if (refineryType == null) {
            return [];
        }
        return refineryProductGroups.filter(g => g.refineryTypeId === refineryType.id)
    }, [refineryProductGroups, refineryType]);


    const groupView = useMemo(() => {
        if (groups == null || refineryProducts == null) {
            throw new Error('Unexpeced empty refninery reference data');
        }

        return groups
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map(g => {
                const groupIsExpired = !isValidForPeriod(g, reportingPeriodFrom, reportingPeriodTo);
                return {
                    isExpired: groupIsExpired,
                    productGroup: g,
                    products: refineryProducts
                        .filter(p => (
                            p.refineryProductGroupId === g.id
                        ))
                        .map(p => ({
                            ...p,
                            isExpired: !isValidForPeriod(p, reportingPeriodFrom, reportingPeriodTo) ||
                                       groupIsExpired,
                        }))
                        .sort((a, b) => a.displayOrder - b.displayOrder)
                };
            })
    }, [groups, refineryProducts, reportingPeriodFrom, reportingPeriodTo]);

    const refineryRefData = useMemo(() => {
        return {
            groups: groupView,
            refineryType,
        };
    }, [groupView, refineryType]);

    return refineryRefData;
}

type MaybeRefineryTypeRefData = ReturnType<typeof useRefDataForTypeName>;

type RefineryTypeRefData = PopulatedProps<MaybeRefineryTypeRefData, 'groups' | 'refineryType'>;

type RefineryTypeRefDataGroup = ArrayElement<RefineryTypeRefData['groups']>;

export type RefineryTypeRefDataProduct = ArrayElement<RefineryTypeRefDataGroup['products']>;

function useRefineryRefData(refData: AllReferenceData, reportingPeriodFrom: string, reportingPeriodTo: string) {
    const dataSubmission = {reportingPeriodFrom, reportingPeriodTo}
    const gas = useRefDataForTypeName(refData, dataSubmission, 'Gases-Unfin-Petrochem-Losses');
    const input = useRefDataForTypeName(refData, dataSubmission, 'Refinery Input');
    const output = useRefDataForTypeName(refData, dataSubmission, 'Refinery Output');

    const refineryRefData = useMemo(() => ({
        "Gases-Unfin-Petrochem-Losses": gas,
        "Refinery Input": input,
        "Refinery Output": output,
    }), [gas, input, output]);

    return refineryRefData;
}

export default useRefineryRefData;

type MaybeUseRefineryRefData = ReturnType<typeof useRefineryRefData>;

export type UseRefineryRefData = {
    [key in keyof MaybeUseRefineryRefData]: RefineryTypeRefData;
}
