import { SubmissionRecordValidationAlertVM } from "psims/gen/xapi-client/models/SubmissionRecordValidationAlertVM";

const VALIDATION_ALERT_CODES = [
    'LessThanOtherWholesalesAlert',
    'LessThanSumOfBreakdownAlert',
    'NegativeValueAlert',
    'PreviouslyReportedAlert',
    'MsoNotMet',
    'AcquittedAmount',
    'MSOImportVolumeOnBehalfMaximumLimitExceeded',
    'MSOImportVolumeByOthersMaximumLimitExceeded',
    'OpeningClosingStocksMismatch',
    'ReportVariance',
    'SameAsPrevious',
    'PercentVarianceApplied',
    'DefRelativity',
    'DerivedFromOtherProductionVarianceApplied',
    'SalesVarianceApplied',
    'StockVarianceApplied',
] as const;

type ValidationAlertCode = ArrayElement<typeof VALIDATION_ALERT_CODES>;

export type ValidationAlertMaybe = SubmissionRecordValidationAlertVM;

export type ValidationAlert<TCode extends ValidationAlertCode = ValidationAlertCode> = PopulatedProps<ValidationAlertMaybe, 'message' | 'validationAlert'> & {
    validationAlert: TCode;
};

export function isValidationAlert<TCode extends ValidationAlertCode = ValidationAlertCode>(maybe?: unknown, ofType?: TCode): maybe is ValidationAlert<TCode> {
    const maybeAs = maybe as ValidationAlert<TCode>;

    return (
        maybeAs != null &&
        maybeAs.message != null &&
        maybeAs.validationAlert != null &&
        (ofType != null ? (
            maybeAs.validationAlert === ofType
        ) : (
            VALIDATION_ALERT_CODES.includes(maybeAs.validationAlert as ValidationAlertCode)
        ))
    );
}

export function isAnyValidationAlert(maybe?: unknown): maybe is ValidationAlert {
    return isValidationAlert(maybe);
}

