import React from 'react';
import styled from 'styled-components';

import { BoxedDiv } from 'psims/react/components/layout';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';

interface ConfirmSaveChangesProps {
    controlsState: 'loading' | 'normal';
    isOpen: boolean;
    onCancel: () => any;
    onConfirm: () => any;
    onSecondary: () => any;
}

const UL = styled.ul`
    padding-left: 20px;
`;

const LI = styled.li`
    padding-left: 10px;
`;

const ConfirmSaveChanges = ({controlsState, isOpen, onCancel, onConfirm, onSecondary}: ConfirmSaveChangesProps) => {
    return (
        <ConfirmationDialog
            ariaLabel='You have unsaved changes on this page'
            body={
                <BoxedDiv box={{}}>
                    <p>Would you like to</p>
                    <UL>
                        <LI>cancel this action and review this page</LI>
                        <LI>discard your changes and continue to the selected navigation</LI>
                        <LI>save your changes and continue to the selected navigation</LI>
                    </UL>
                </BoxedDiv>
            }
            confirmLabel='Save and continue'
            controlsState={controlsState}
            isOpen={isOpen}
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSecondary={onSecondary}
            secondaryLabel='Discard and continue'
            title='You have unsaved changes on this page'
        />
    );
}

export default ConfirmSaveChanges;
