import { compare } from "psims/lib/compare";
import { useMemo } from "react";

interface WithSymbolId {
  _id: symbol;
}

interface UseTrackChangesProps<TData extends WithSymbolId> {
  currentData: Array<TData>;
  dataFields: Array<keyof TData>;
  initialData: Array<TData>;
}

function useTrackChanges<TData extends WithSymbolId>({currentData, dataFields, initialData}: UseTrackChangesProps<TData>) {
  const hasChanges = useMemo(() => {
    if (currentData.length !== initialData.length) {
      return true;
    }

    // Deliberately choosing `for` loop over `forEach` to allow short-circuit when change detected
    for (let i = 0; i < currentData.length; i++) {
      const currentDataItem = currentData[i];
      const initialDataItem = initialData[i];
    
      for (let j = 0; j < dataFields.length; j++) {
        const field = dataFields[j];
        if (!compare(currentDataItem[field], initialDataItem[field], true)) {
          return true;
        }
      }
    }

    return false;
  }, [currentData, dataFields, initialData]);

  return {
    hasChanges,
  };
}

export default useTrackChanges

export type UseTrackChanges = ReturnType<typeof useTrackChanges>;
