import { useCallback, useMemo, useRef, useState } from "react";

import { WholesaleTypeName } from "psims/models/ref-data/wholesale-type";
import { MaybeWholesaleSubmissionFormData, WholesaleSubmission, WholesaleSubmissionFormData } from "psims/models/submission-types/wholesaling";
import { ViewMode } from "psims/react/pages/primary-pages/data-submissions/shared/use-view-mode";
import { useLogger } from "psims/react/providers/logging";
import { UseWholesalingRefData } from "./use-wholesaling-ref-data";
import { UseWholesalingUpdateResponse } from "./use-wholesaling-update-response";
import { attemptFocusOnSelector } from "../shared/view-utils";
import { SELECTOR_NOTIFICATION } from "psims/constants/selectors";

interface UseWholesalingStepsProps {
    refData: UseWholesalingRefData;
    submission: WholesaleSubmission;
    updateResponse: UseWholesalingUpdateResponse;
    viewMode: ViewMode;
}

export type Step = {
    label: string;
    kind: WholesaleTypeName | 'Submit';
}

type ReviewAlertsStatus = 'showing_dialog' | 'hide_dialog';

const STEPS: Array<Step> = [
    { label: 'Wholesales to retailers', kind: 'Retailers', },
    { label: 'Wholesales to other wholesalers', kind: 'Wholesalers', },
    { label: 'Bulk wholesales', kind: 'Bulk', },
    { label: 'Other wholesales', kind: 'Other wholesales', },
    { label: 'Total wholesales', kind: 'Total', },
    { label: 'Declaration', kind: 'Submit', },
]

/*
    Defines the step archetypes and manages moving between pages. Note that preventing navigation is
    NOT the concern of this controller - consumers of this controller's navigation actions are
    responsible for such checks before invoking said action.
*/
function useWholesalingSteps({refData, submission, updateResponse, viewMode}: UseWholesalingStepsProps) {
    const {debug} = useLogger({source: 'useWholesalingSteps'});
    const [currentStepIndex, setCurrentStepIndex] = useState(determineFirstPageIndex(submission, viewMode));
    const [reviewAlertStatus, setReviewAlertStatus] = useState<ReviewAlertsStatus>('hide_dialog');
    const requestedStep = useRef<number | null>(null);

    const steps = useMemo(() => {
        return STEPS.map((s, index) => ({
            ...s,
            index,
            refData: s.kind === 'Submit' ? null : refData[s.kind],
            status: (index === currentStepIndex ? 'active' : statusForStep(s, submission.submissionFormData, viewMode)) as 'active' | 'complete' | 'pending',
            typeName: s.kind,
        }))
    }, [currentStepIndex, submission.submissionFormData, refData, viewMode]);

    const currentStep = useMemo(() => {
        return steps[currentStepIndex]
    }, [currentStepIndex, steps]);

    const goToStep = useCallback((index: number) => {
        if (index !== currentStep.index && index >= 0 && index < STEPS.length) {
            if (viewMode !== 'edit' || canChangePage(currentStep.refData?.wholesaleType.id, updateResponse)) {
                setCurrentStepIndex(index);
            } else {
                setReviewAlertStatus('showing_dialog');
                requestedStep.current = index;
            }
        } else {
            debug(`Noop navigation request: requested step index: ${index}, currrent step index: ${currentStep.index}, step count: ${STEPS.length}`);
        }
    }, [currentStep, debug, updateResponse, viewMode]);

    const next = useCallback(() => goToStep(currentStepIndex + 1), [currentStepIndex, goToStep]);

    const previous = useCallback(() => goToStep(currentStepIndex - 1), [currentStepIndex, goToStep]);

    const handleClickContinue = useCallback(() => {
        setReviewAlertStatus('hide_dialog');
        if (typeof requestedStep.current === 'number') {
            setCurrentStepIndex(requestedStep.current);
        }
        requestedStep.current = null;
    }, []);

    const handleClickReview = useCallback(() => {
        setReviewAlertStatus('hide_dialog');
        requestedStep.current = null;
        attemptFocusOnSelector(`${SELECTOR_NOTIFICATION('info')}`);
    }, []);

    return {
        // data
        currentStep,
        reviewAlertStatus,
        steps,

        // actions
        goToStep,
        handleClickContinue,
        handleClickReview,
        next,
        previous,
    };
}

export default useWholesalingSteps;

export type UseWholesalingSteps = ReturnType<typeof useWholesalingSteps>;

function determineFirstPageIndex({dataSubmission, submissionFormData}: WholesaleSubmission, viewMode: ViewMode) {
    if (viewMode !== 'edit' || dataSubmission.status === 'Action required') {
        return 0;
    }

    return (
        !submissionFormData.retailersPageSaved ? 0 :
        !submissionFormData.wholesalersPageSaved ? 1 :
        !submissionFormData.bulkPageSaved ? 2 :
        !submissionFormData.otherPageSaved ? 3 :
        !submissionFormData.totalPageSaved ? 4 :
        5
    );
}

export const SAVED_PAGES_MAP: {[k in WholesaleTypeName]: keyof WholesaleSubmissionFormData} = {
    "Other wholesales": 'otherPageSaved',
    Bulk: 'bulkPageSaved',
    Retailers: 'retailersPageSaved',
    Total: 'totalPageSaved',
    Wholesalers: 'wholesalersPageSaved',
};

function statusForStep(step: Step, submissionFormData: MaybeWholesaleSubmissionFormData, viewMode: ViewMode) {
    if (viewMode !== 'edit') {
        return 'complete';
    }
    
    if (step.kind === 'Submit') {
        return 'pending';
    }

    return submissionFormData[SAVED_PAGES_MAP[step.kind]] ? 'complete' : 'pending';
}

function canChangePage(wholesaleTypeId: number | undefined, updateResponse: UseWholesalingUpdateResponse) {
    const wholesalesWithAlerts = (updateResponse.processedResponse?.submission?.wholesales || [])
        .filter(w => (
            w.wholesaleTypeId === wholesaleTypeId &&
            (w.validationAlerts || []).length > 0
        ))
    return wholesalesWithAlerts.length === 0;
}
