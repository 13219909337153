export interface PositionProps {
    position?: 'absolute' | 'fixed' | 'relative';
    bottom?: string;
    left?: string;
    right?: string;
    top?: string;
}

export const positionStyle = ({bottom, left, position, right, top}: PositionProps) => ({
    bottom,
    left,
    position,
    right,
    top,
});
