import { WholesaleProductGroup } from "psims/models/ref-data/wholesale-product-group";
import { WholesaleProduct } from "psims/models/ref-data/wholesale-product";

interface WithPeriod {
  effectiveFrom: string;
  effectiveTo?: string | null;
  isInternal?: boolean;
}

export function isValidForPeriod<TEntity extends WithPeriod>(entity: TEntity, from: string, to: string) {
  // Always exclude internal entities
  if (entity.isInternal !== undefined && entity.isInternal === true) {
    return false;
  }

  const dsFrom = new Date(from);
  const dsTo = new Date(to);
  const pFrom = new Date(entity.effectiveFrom);
  const pTo = entity.effectiveTo == null ? undefined : new Date(entity.effectiveTo);

  // Current assumption: if product active for any part of the DS reporting period,
  // Then product is considered active.
  if (pFrom.getTime() <= dsTo.getTime() &&
    (pTo === undefined || pTo.getTime() >= dsFrom.getTime())
  ) {
    return true;
  }

  return false;
}

export function IsWholesaleProductExpiredInTotalPage(
    productsInTotalPage: WholesaleProduct[],
    productGroupsInTotalPage: WholesaleProductGroup[],
    genericProductCode: string | null | undefined,
    from: string,
    to: string) {
    let isProductExpiredInTotalPage = false;
    let isProductGrpupExpiredInTotalPage = false;

    if (productsInTotalPage != null && productGroupsInTotalPage != null && productsInTotalPage.length > 0 && productGroupsInTotalPage.length > 0) {
        //Check of the product expired in the Total page
        let productInTotalPage = productsInTotalPage
            .find(p => p.genericProductCode === genericProductCode);

        if (productInTotalPage != null) {
            isProductExpiredInTotalPage = !isValidForPeriod(productInTotalPage, from, to);

            //Check if ProductGroup is expired in the total page
            let productGroupInTotalPage = productGroupsInTotalPage.find(g => g.referenceCode === productInTotalPage?.wholesaleProductGroupReferenceCode);
            isProductGrpupExpiredInTotalPage = productGroupInTotalPage != null && !isValidForPeriod(productGroupInTotalPage, from, to);
        }
    }
    return isProductExpiredInTotalPage || isProductGrpupExpiredInTotalPage;
}