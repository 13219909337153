import { FsspTotalsVM, UpdateFsspTotalsVM } from "psims/gen/xapi-client";

export type FspEligibleTotals = PopulatedProps<
  FsspTotalsVM,
  'dataSubmissionId' | 'totalEligibleKilolitres' | 'totalEligibleTonnes'
>;

export function isFspEligibleTotals(maybe?: unknown): maybe is FspEligibleTotals {
  const maybeAs = maybe as FspEligibleTotals;

  return (
    maybeAs != null &&
    maybeAs.dataSubmissionId != null &&
    maybeAs.totalEligibleKilolitres != null &&
    maybeAs.totalEligibleTonnes != null
  );
}

export type UpdateFspEligibleTotals = PopulatedProps<
  UpdateFsspTotalsVM,
  'dataSubmissionId' | 'recordAction'
>;

export function isUpdateFspEligibleTotals(maybe?: unknown): maybe is UpdateFspEligibleTotals {
  const maybeAs = maybe as UpdateFspEligibleTotals;

  return (
    maybeAs != null &&
    maybeAs.dataSubmissionId != null &&
    maybeAs.recordAction != null
  );
}

export type UpdateFspEligibleTotalsField = 'reportComments';
