interface IsBetweenOpts {
    max: number;
    maxBound?: 'open' | 'closed';
    min: number;
    minBound?: 'open' | 'closed';
}

export function isBetween(val: number | undefined | null, opts: IsBetweenOpts) {
    if (val == null) {
        return false;
    }

    // Closed intervals by default
    const satisfiesMax = opts.maxBound === 'open' ? val < opts.max : val <= opts.max;
    const satisfiesMin = opts.minBound === 'open' ? val > opts.min : val >= opts.min;
    return satisfiesMax && satisfiesMin;
}

export function isInteger(val: number | undefined | null) {
    if (val == null) {
        return false
    }

    return`${val}`.indexOf('.') === -1;
}

export function isWithinMaxDecimalPlaces(val: number | undefined | null, maxDecimal: number = 3) {
    if (val == null) {
        return false
    }

    // Check higher-precision inputs than limit
    if (Number(Number(val).toFixed(maxDecimal)) !== Number(val)) {
        return false;
    }

    const decs = `${val}`.split('.')[1];

    if (decs == null || decs.length <= maxDecimal) {
        return true;
    }

    return false;
}
