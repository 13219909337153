import React, { useMemo } from 'react';
import { Redirect } from 'react-router';

import Text from 'psims/react/components/text';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import CompanyHeader from 'psims/react/blocks/company-header';
import { User } from 'psims/models/user';
import Button from 'psims/react/components/button';
import { Table, TBody, TD, TH, THead, TR } from 'psims/react/components/table';
import Plus from 'psims/react/components/icons/plus';
import { Menu, MenuButton, MenuItem, MenuList } from 'psims/react/components/menu-button';
import Ellipsis from 'psims/react/components/icons/ellipsis';
import { useUser } from 'psims/react/providers/user';
import Dialog from 'psims/react/components/dialog';
import { useOrgUsers } from 'psims/react/providers/api/org-users';
import Loading from 'psims/react/components/loading';
import Notification from 'psims/react/components/notification';
import useDeactivateUser from './use-deactivate-user';
import { Status } from 'psims/types/async';
import AddUser from './add-user';
import useAddUser from './use-add-user';
import useEditUser from './use-edit-user';
import EditUser from './edit-user';
import useReactivateUser from './use-reactivate-user';
import { useAppOverlay } from 'psims/react/providers/app-overlay';
import { asString } from 'psims/lib/string';
import { H1 } from 'psims/react/components/typography';
import VisuallyHidden from 'psims/react/components/visually-hidden';

interface UserManagementProps {};

const UserManagement = (props: UserManagementProps) => {
	const vm = useVM(props);

	if (vm.user?.roleType !== 'Company Admin') {
		return <Redirect to='/' />;
	}

	return (
		<BoxedDiv box={{alignSelf: 'stretch', flex: 'column', flexGrow: 1, justifySelf: 'center', overflowX: 'hidden'}}>
			<CompanyHeader />

			<div className='container'>
				<H1>Manage access</H1>
				
				<p>Add new user accounts, or update existing user accounts for your organisation here.</p>

				{
					vm.tableStatus === 'ready' &&

					<BoxedDiv box={{alignItems: 'flex-end', flex: 'column'}}>
						<Button
							$kind='primary'
							onClick={vm.addUserCtrl.newUser}
							rounded={true}
						>
							<Plus aria-hidden='true' size='sm' /><Text spacing={{paddingLeft: 1.5}}>Add new user</Text>
						</Button>

						<BoxedDiv box={{alignSelf: 'stretch', marginTop: 2.5, overflowX: 'auto'}}>
							<Table caption='Users for your connected company(s)' fullWidth={true}>
								<THead>
									<TR>
										<TH>Surname</TH>
										<TH>First name</TH>
										<TH>Username</TH>
										<TH>Role type</TH>
										<TH>Status</TH>
										<TH $align='center'>Actions</TH>
									</TR>
								</THead>

								<TBody>
									{
										vm.users !== null && vm.users?.map(user => <UserRow
											key={user.id}
											onClickActivate={vm.reactivateUserCtrl.handleClick}
											onClickDeactivate={vm.deactivateUserCtrl.handleClick}
											onClickEdit={vm.editUserCtrl.selectUser}
											user={user}
										/>)
									}
								</TBody>
							</Table>
						</BoxedDiv>
					</BoxedDiv>
				}

				{
					vm.tableStatus === 'loading' &&

					<BoxedDiv box={{alignItems: 'center', flex: 'column', marginV: 6}}>
						<Loading>
							<Text>Loading your organisation's users...</Text>
						</Loading>
					</BoxedDiv>
				}

				{
					vm.tableStatus === 'error' &&

					<BoxedDiv box={{alignItems: 'center', flex: 'column', marginV: 6}}>
						<Notification align='center' kind='warning'>
							<Text>Failed to load users. Please try again and contact support if the issue persists.</Text>
						</Notification>
					</BoxedDiv>
				}
			</div>

            <Dialog aria-label='Add a new user' isOpen={vm.showAddUserDialog} onDismiss={vm.addUserCtrl.cancel}>
                <AddUser ctrl={vm.addUserCtrl} />
            </Dialog>

            <Dialog aria-label='Edit a user' isOpen={vm.showEditUserDialog} onDismiss={vm.editUserCtrl.cancel}>
                <EditUser ctrl={vm.editUserCtrl} />
            </Dialog>

			<Dialog aria-label='Confirm user deactivation' isOpen={vm.showDeactivateUserConfirmation} onDismiss={vm.deactivateUserCtrl.handleCancel}>
				<ConfirmUserDeactivation
					onClose={vm.deactivateUserCtrl.handleCancel}
					onConfirm={vm.deactivateUserCtrl.handleConfirm}
					user={vm.deactivateUserCtrl.user}
				/>
			</Dialog>

			<Dialog aria-label='Confirm user reactivation' isOpen={vm.showActivateUserConfirmation} onDismiss={vm.reactivateUserCtrl.handleCancel}>
				<ConfirmUserActivation
					error={vm.reactivateUserCtrl.serviceError}
					onClose={vm.reactivateUserCtrl.handleCancel}
					onConfirm={vm.reactivateUserCtrl.handleConfirm}
					user={vm.reactivateUserCtrl.user}
				/>
			</Dialog>
		</BoxedDiv>
	);
};

type TableStatus = 'error' | 'loading' | 'ready';

function useVM(props: UserManagementProps) {
	const { setOverlay } = useAppOverlay();
	const { refetch, status: usersStatus, users } = useOrgUsers();

	const onServiceSuccess = () => {
		refetch();
		setOverlay(null);
	}

	const deactivateUserCtrl = useDeactivateUser({onSuccess: onServiceSuccess});
	const reactivateUserCtrl = useReactivateUser({onSuccess: onServiceSuccess});
	const addUserCtrl = useAddUser({onSuccess: onServiceSuccess});
	const editUserCtrl = useEditUser({onSuccess: onServiceSuccess});
	const { user } = useUser();

	const sortedUsers = useMemo(() => {
		if (users === null) {
			return null;
		}

		return [...users]
			.sort((a, b) => {
				if (a.lastName === b.lastName) {
					// sort by first name
					return asString(a.firstName) < asString(b.firstName) ? -1 : (asString(a.firstName) > asString(b.firstName) ? 1 : 0);
				}

				// sort by lastname
				return asString(a.lastName) < asString(b.lastName) ? -1 : (asString(a.lastName) > asString(b.lastName) ? 1 : 0);
			})
	}, [users]);

    const showAddUserDialog = addUserCtrl.user !== null;

    const showEditUserDialog = editUserCtrl.user !== null;

	const showActivateUserConfirmation = reactivateUserCtrl.user !== null;

	const showDeactivateUserConfirmation = deactivateUserCtrl.user !== null;

	const tableStatus = getTableStatus(users, usersStatus);

	return {
		addUserCtrl,
		deactivateUserCtrl,
		editUserCtrl,
		reactivateUserCtrl,
        showAddUserDialog,
        showEditUserDialog,
		showActivateUserConfirmation,
		showDeactivateUserConfirmation,
		tableStatus,
		user,
		users: sortedUsers,
	};
}

interface UserRowProps {
	onClickActivate: (user: User) => any;
	onClickDeactivate: (user: User) => any;
	onClickEdit: (user: User) => any;
	user: User;
}

const UserRow = ({onClickActivate, onClickDeactivate, onClickEdit, user}: UserRowProps) => {
	return (
		<TR key={user.id}>
			<TD>{user.lastName}</TD>
			<TD>{user.firstName}</TD>
			<TD>{user.email}</TD>
			<TD>{user.roleType}</TD>
			<TD>{user.status}</TD>
			<TD $align='center'>
				<UserActions
					onClickActivate={onClickActivate}
					onClickDeactivate={onClickDeactivate}
					onClickEdit={onClickEdit}
					user={user}
				/>
			</TD>
		</TR>
	);
}

interface UserActionsProps {
	onClickActivate: (user: User) => any;
	onClickDeactivate: (user: User) => any;
	onClickEdit: (user: User) => any;
	user: User;
}

const UserActions = ({onClickActivate, onClickDeactivate, onClickEdit, user}: UserActionsProps) => {
	const { user: loggedInUser } = useUser();
	const showReactivate = user.status === 'Inactive';
	const hideDeactivate = user.id === loggedInUser?.id;
	const showEdit = user.status === 'Invited' || user.status === 'Registered';

	if (user.id === loggedInUser?.id) {
		return null;
	}
	
	return (
		<Menu>
			<MenuButton as={Button} $kind='unstyled' aria-label='Actions for this user'>
				<BoxedSpan box={{paddingH: 1.5, paddingV: 0.5}} >
					<VisuallyHidden>Actions for this user</VisuallyHidden>
					<Ellipsis color='primary' size='md' />
				</BoxedSpan>
			</MenuButton>

			<MenuList>
				{
					showEdit &&
					<MenuItem onSelect={() => onClickEdit(user)}>Edit</MenuItem>
				}
				{
					showReactivate ?
					<MenuItem onSelect={() => onClickActivate(user)}>Reactivate</MenuItem> :
					(hideDeactivate ? null : <MenuItem onSelect={() => onClickDeactivate(user)}>Deactivate</MenuItem>)
				}
			</MenuList>
		</Menu>
	)
}

interface ConfirmUserActivationProps {
	error: string | null;
	onClose: () => any;
	onConfirm: (user: User) => any;
	user: User | null;
}

const ConfirmUserActivation = ({error, onClose, onConfirm, user}: ConfirmUserActivationProps) => {
	if (!user) {
		return null;
	}

	const handleConfirm = () => {
		onConfirm(user);
		// onClose();
	}

	return (
		<BoxedDiv box={{flex: 'column'}}>
			<h3>Reactivate user {user.firstName} {user.lastName}?</h3>

			<BoxedDiv box={{marginBottom: 3, marginTop: 3}}>
				<p>Reactivating this user will enable access to the Liquid Fuels Gateway.  
The user will be sent an email requesting them to register their details. They should follow the prompts to login and update their contact details to reactivate their account.</p>
			</BoxedDiv>

			{
				error != null &&
				<BoxedDiv box={{marginBottom: 3}}>
					<Notification kind='warning'>
						{error}
					</Notification>
				</BoxedDiv>
			}

			<BoxedDiv box={{alignItems: 'center', flex: 'row-reverse', justifyContent: 'flex-start'}}>
				<Button $kind='primary' onClick={handleConfirm}>Reactivate</Button>

				<Button $kind='text' onClick={onClose} marginRight={5}>Cancel</Button>
			</BoxedDiv>

		</BoxedDiv>
	)
}


interface ConfirmUserDeactivationProps {
	onClose: () => any;
	onConfirm: (user: User) => any;
	user: User | null;
}

const ConfirmUserDeactivation = ({onClose, onConfirm, user}: ConfirmUserDeactivationProps) => {
	if (!user) {
		return null;
	}

	const handleConfirm = () => {
		onConfirm(user);
		onClose();
	}

	return (
		<BoxedDiv box={{flex: 'column'}}>
			<h3>Deactivate user {user.firstName} {user.lastName}?</h3>

			<BoxedDiv box={{marginBottom: 5, marginTop: 3}}>
				<p>Deactivating this user will stop their access to the Liquid Fuels Gateway. If the user requires access at a later date you can reactivate their account.</p>
			</BoxedDiv>

			<BoxedDiv box={{alignItems: 'center', flex: 'row-reverse', justifyContent: 'flex-start'}}>
				<Button $kind='primary' onClick={handleConfirm}>Deactivate</Button>

				<Button $kind='text' onClick={onClose} marginRight={5}>Cancel</Button>
			</BoxedDiv>

		</BoxedDiv>
	)
}

export default UserManagement;

function getTableStatus(users: Array<User> | null, usersStatus: Status): TableStatus {
	if (users !== null) {
		return 'ready';
	}

	if (usersStatus === 'error') {
		return 'error';
	}

	return 'loading';
}
