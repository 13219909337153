export type Configuration = {
    APP_INSIGHTS_CONNECTION_STRING: string;
    B2C_AUTHORITY: string;
    B2C_CLIENT_ID: string;
    B2C_KNOWN_AUTHORITY: string;
    B2C_REDIRECT_URI: string;
    B2C_SCOPE: string;
    BUILD_ENVIRONMENT: 'Local' | 'Dev' | 'SIT' | 'Test' | 'UAT' | 'Prod';
    BUILD_VERSION: string;
};

const {protocol, host} = window.location;

export const APPLICATION_HOST = `${protocol}//${host}`;