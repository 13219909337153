import { useMemo, useEffect, useState, useCallback } from "react";
import produce from "immer";
import useViewMode, { ViewMode } from "../shared/use-view-mode";
import useContentForType from "./use-content-for-type";
import useFormForType from "./use-form-for-type";
import usePrimaryControls from "./use-primary-controls";
import useWholesalingSteps from "./use-wholesaling-steps";
import { UseWholesalingAPI } from "./use-wholesaling-api";
import useWholesalingProgress from "./use-wholesaling-progress";
import { UseWholesalingRefData } from "./use-wholesaling-ref-data";
import useWholesalingSave from "./use-wholesaling-save";
import useWholesalingServiceMessages from "./use-wholesaling-service-messages";
import useWholesalingSubmission from "./use-wholesaling-submission";
import useWholesalingUpdateResponse from "./use-wholesaling-update-response";
import useClearData from "../shared/use-clear-data";
import useSubmissionProcessingOverlay from "psims/react/pages/primary-pages/data-submissions/shared/use-submission-processing-overlay";
import useTemplateImport from "psims/react/blocks/import/use-template-import";
import { WholesaleSubmission } from "psims/models/submission-types/wholesaling";
import { SubmissionType } from "psims/models/ref-data/submission-type";
import usePortalDataAPI from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api";
import wholesalingImport from "psims/react/blocks/import/submissions/wholesaling";

export type GroupTotals = {
    nsw: number;
    vic: number;
    qld: number;
    sa: number;
    wa: number;
    tas: number;
    nt: number;
    australia: number;
};

interface UseWholesalingProps {
    apiCtrl: PopulatedProps<UseWholesalingAPI, 'submission'>;
    refData: UseWholesalingRefData
}

type ProcessState = 'idle' | 'active';

function useWholesaling({apiCtrl, refData}: UseWholesalingProps) {
    const {submission, clearAllData} = apiCtrl;
    const [preProcessStatus, setPreProcessStatus] = useState<ProcessState>('idle');

    // Orchestrate controllers
    const viewMode = useViewMode(submission);
    const updateResponse = useWholesalingUpdateResponse({
        response: apiCtrl.updateResponse,
        serviceError: apiCtrl.updateError,
        submissionStatus: apiCtrl.loadStatus
    });
    
    const submissionType = useMemo<SubmissionType>(() => {
        return {
            id: submission.dataSubmission.submissionTypeId,
            name: submission.dataSubmission.submissionTypeName,
            isActive: true,
            sortOrder: 0
        }
    }, [submission]);

    const lastServiceSubmission = useMemo(() => {
        return updateResponse.processedResponse?.submission || submission;
    }, [updateResponse.processedResponse?.submission, submission]);

    const stepCtrl = useWholesalingSteps({submission: lastServiceSubmission, refData, updateResponse, viewMode});
    const importCtrl = useTemplateImport<Partial<WholesaleSubmission>>({ dataSubmission: submission.dataSubmission, importerBuilder: wholesalingImport, preProcess: true });    
    const formCtrl = useFormForType({submission: lastServiceSubmission, stepCtrl, importCtrl});
    const submissionCtrl = useWholesalingSubmission({apiCtrl, stepCtrl, submission: lastServiceSubmission, viewMode, importCtrl});
    const saveCtrl = useWholesalingSave({apiCtrl, formCtrl, stepCtrl, submissionCtrl, importCtrl, updateResponse, viewMode});
    const progressCtrl = useWholesalingProgress({formCtrl, refData, saveCtrl, stepCtrl, submission: lastServiceSubmission, submissionCtrl, viewMode});
    const controlsCtrl = usePrimaryControls({apiCtrl, formCtrl, stepCtrl, saveCtrl, submissionCtrl, viewMode});
    const serviceMessages = useWholesalingServiceMessages({stepCtrl, updateResponse});
    const content = useContentForType({typeName: stepCtrl.currentStep.typeName});
    const portalDataAPICtrl = usePortalDataAPI({submissionType: submissionType});
    const clearDataCtrl = useClearData({
        onConfirm: () => {
            clearAllData();
            submissionCtrl.setDeclaration(false);
            importCtrl.reset();
        },
        submission: apiCtrl.submission.dataSubmission,
    });
    useSubmissionProcessingOverlay({submissionStatus: apiCtrl.loadStatus});
    
    const importPreProcess = useCallback(() => {
        apiCtrl.clearAllData();
        submissionCtrl.setDeclaration(false);
    }, [apiCtrl, submissionCtrl]);

    useEffect(() => {
        if (importCtrl.templateImportState.templateImportDialogState === 'preprocess') {
            switch (preProcessStatus) {
                case 'idle':
                    setPreProcessStatus('active');
                    importPreProcess();
                    break;
                case 'active':
                    switch (apiCtrl.loadStatus) {
                        case 'update_failed':
                            importCtrl.setTemplateImportState({
                                templateImportDialogState: 'error',
                                totalErrors: 1,
                                errors: [{
                                    error: 'Problem clearing existing submission data',
                                    page: ''
                                }]
                            });
                            setPreProcessStatus('idle');
                            break;
                        case 'updated'                    :
                            importCtrl.setTemplateImportState(prev => produce(prev, draft => {
                                draft.templateImportDialogState = 'importing'
                            }));
                            setPreProcessStatus('idle');
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }, [apiCtrl.loadStatus, importCtrl, importPreProcess, preProcessStatus]);

    const hasUnsavedChanges = useMemo(() => {
        return formCtrl.changedState === 'unsaved_changes' || submissionCtrl.changedState === 'unsaved_changes';
    }, [formCtrl.changedState, submissionCtrl.changedState]);

    return {
        // Controllers
        apiCtrl,
        clearDataCtrl,
        controlsCtrl,
        formCtrl,
        importCtrl,
        portalDataAPICtrl,
        progressCtrl,
        saveCtrl,
        stepCtrl,
        submissionCtrl,
        // Shared state
        content,
        hasUnsavedChanges,
        refData,
        serviceMessages,
        submission: lastServiceSubmission,
        updateResponse,
        viewMode: viewMode as ViewMode,
    };
}

export default useWholesaling;
