import { ReportFrequencyTypeDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

export type ReportFrequencyType = PopulatedProps<ReportFrequencyTypeDTO, 'id' | 'name'>;

export function assertReportFrequencyType(maybe?: unknown): asserts maybe is ReportFrequencyType {
    const maybeAs = maybe as ReportFrequencyType;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(maybeAs, hint('reportFrequencyType'));
    assertNotEmpty(maybeAs.id, hint('reportFrequencyType.id'));
    assertNotEmpty(maybeAs.name, hint('reportFrequencyType.name'));
}
