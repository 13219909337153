import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import produce from "immer";

import { Stockholding, StockholdingSubmission } from "psims/models/submission-types/stockholding";
import { UpdateDataSubmission } from "psims/models/data-submission";
import { ChangedState } from "../shared/save-state";
import { isValidCommentCharacters, isValidCommentMaxLength } from "psims/lib/validation/comments";
import { INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH, INVALID_COMMENTS_REQUIRED_WHEN_SAME_AS_PREVIOUS } from "psims/constants/validation-messages";
import { CommentsErrorMessage, CommentsRequiredErrorMessage, DeclarationErrorMessage, ErrorEmptyCommentsOnPage, ErrorUnsavedPage, InactiveProduct, InfoSameAsPreviousMessage } from 'psims/react/pages/primary-pages/data-submissions/shared/messages';
import { FocusField, PAGE_INDEX, STEPS } from "./shared";
import { UseTemplateImport } from "psims/react/blocks/import/use-template-import";
import { UseFocusedField } from "psims/react/util/use-focused-field";
import { any } from "psims/lib/collections";
import useStockType from "./use-stock-type";
import { isEmpty } from "psims/lib/empty";
import { isValidationAlert } from "psims/models/api/submission/validation-alert";
import { ExpiredOrInternalProductsWithData } from "../shared/types";

interface UsePageSubmitProps {
    focusedFieldCtrl: UseFocusedField<FocusField>;
    isActive: boolean;
    stockholding: Stockholding;
    viewMode: 'edit' | 'view';
    goToStep: (index: number) => any;
    importCtrl: UseTemplateImport<Partial<StockholdingSubmission>>;
    declarationMessage: string | null;
    expiredProductsWithData: ExpiredOrInternalProductsWithData[];
}

type View = {
    comments: {
        comment: string | null | undefined;
        infoMessage?: string;
        validationMessage?: string;
    },
    hasValidationErrors: boolean;
    isCommentsRequired: boolean;
    messages: {
        errors: Array<ReactNode>;
        infos: Array<ReactNode>;
    }
}

function usePageSubmit({focusedFieldCtrl, goToStep, isActive, stockholding, viewMode, importCtrl, expiredProductsWithData, declarationMessage}: UsePageSubmitProps) {
    const domesticStockType = useStockType({stockTypeName: 'In Australia'});
    const osStockType = useStockType({stockTypeName: 'Overseas'});
    const owStockType = useStockType({stockTypeName: 'On water'});
    const [declaration, setDeclaration] = useState(false);
    const [updateDataSubmission, setUpdateDataSubmission] = useState<UpdateDataSubmission>(
        updateDataSubmissionFromStockholding(stockholding)
    );
    const [submissionComments, setSubmissionComments] = useState<string | null | undefined>(null);

    const [changedState, setChangedState] = useState<ChangedState>('unchanged');

    const isSameAsPrevSubmission = useMemo(() => {
        return stockholding.dataSubmission.validationAlerts != null && stockholding.dataSubmission.validationAlerts.filter(a => a.validationAlert === 'SameAsPrevious').length > 0;
    }, [stockholding]);

    useEffect(() => {
        let comments = updateDataSubmission.comments;
        if (viewMode === 'edit' && importCtrl.templateImportState.templateImportDialogState === 'processing' && importCtrl.templateImportState.submitSaved !== true && changedState === 'unchanged') {
            comments = null;
        }
        setSubmissionComments(comments);
    },[changedState, importCtrl.templateImportState.submitSaved, importCtrl.templateImportState.templateImportDialogState, updateDataSubmission, viewMode]);

    const view = useMemo<View>(() => {
        const v: View = {
            comments: {
                comment: submissionComments,
            },
            hasValidationErrors: false,
            isCommentsRequired: false,
            messages: {
                errors: [],
                infos: [],
            }
        };

        if (!isActive) {
            return v;
        }

        if (declarationMessage != null) {
            v.messages.errors = [
                ...v.messages.errors,
                <DeclarationErrorMessage />
            ]
        }

        // Validate comments
        const trimmedComments = (v.comments?.comment || '').trim();
        if (v.comments.comment && trimmedComments.length > 0 && !v.comments.validationMessage) {
            if (!isValidCommentMaxLength(trimmedComments)) {
                v.comments.validationMessage = INVALID_COMMENTS_LENGTH;
                v.messages.errors = [
                    ...v.messages.errors,
                    <CommentsErrorMessage />
                ];
            }
            
            if (!v.comments.validationMessage && !isValidCommentCharacters(trimmedComments)) {
                v.comments.validationMessage = INVALID_COMMENTS_CHARACTERS;
                v.messages.errors = [
                    ...v.messages.errors,
                    <CommentsErrorMessage />
                ];
            }           
        }

        if (trimmedComments.length === 0 && isSameAsPrevSubmission) {
            v.comments.validationMessage = INVALID_COMMENTS_REQUIRED_WHEN_SAME_AS_PREVIOUS;
            v.isCommentsRequired = true;
            v.messages.infos = [
                ...v.messages.infos,
                <InfoSameAsPreviousMessage onTargetClick={() => focusedFieldCtrl.setFocusedField('comments')} />
            ];
            v.messages.errors = [
                ...v.messages.errors,
                <CommentsRequiredErrorMessage onTargetClick={() => focusedFieldCtrl.setFocusedField('comments')} />
            ];
        }

        // Validate page saved
        if (!stockholding.submissionFormData?.australiaPageSaved) {
            v.messages.errors = [
                ...v.messages.errors,
                <ErrorUnsavedPage onTargetClick={() => goToStep(PAGE_INDEX[0].pageIndex)} label={STEPS[0].name} />
            ]
        }

        if (!stockholding.submissionFormData?.overseasPageSaved) {
            v.messages.errors = [
                ...v.messages.errors,
                <ErrorUnsavedPage onTargetClick={() => goToStep(PAGE_INDEX[1].pageIndex)} label={STEPS[1].name} />
            ]
         } 

         if (!stockholding.submissionFormData?.onWaterPageSaved) {
            v.messages.errors = [
                ...v.messages.errors,
                <ErrorUnsavedPage onTargetClick={() => goToStep(PAGE_INDEX[2].pageIndex)} label={STEPS[2].name} />
            ]
        }

        if (expiredProductsWithData.length > 0) {
            v.messages.errors = [
                ...v.messages.errors,
                ...expiredProductsWithData
                    .sort((a, b) => a.stepIndex - b.stepIndex)
                    .map(x => (
                        <InactiveProduct
                            onTargetClick={() => {
                                goToStep(x.stepIndex);
                                window.setTimeout(() => {
                                    focusedFieldCtrl.setFocusedField({field: 'delete', stockProductId: x.productId});
                                }, 0);
                            }}
                            label={x.productName}
                        />
                    ))
            ]
        }

        // Validate validation alert comments
        if (
            (stockholding.domesticStockholdings || []).filter(s => any(s.validationAlerts || [], va => isValidationAlert(va) && va.validationAlert === 'PercentVarianceApplied')).length > 0 &&
            (stockholding.stockholdingComments || []).find(c => c.stockTypeId === domesticStockType?.id && !isEmpty(c.comments)) == null
        ) {
            v.messages.errors = [
                ...v.messages.errors,
                <ErrorEmptyCommentsOnPage onTargetClick={() => goToStep(PAGE_INDEX[0].pageIndex)} label={STEPS[0].name} />
            ]
        }

        if (
            (stockholding.overseasStockholdings || []).filter(s => any(s.validationAlerts || [], va => isValidationAlert(va) && va.validationAlert === 'PercentVarianceApplied')).length > 0 &&
            (stockholding.stockholdingComments || []).find(c => c.stockTypeId === osStockType?.id && !isEmpty(c.comments)) == null
        ) {
            v.messages.errors = [
                ...v.messages.errors,
                <ErrorEmptyCommentsOnPage onTargetClick={() => goToStep(PAGE_INDEX[1].pageIndex)} label={STEPS[1].name} />
            ]
        }

        if (
            (stockholding.onWaterStockholdings || []).filter(s => any(s.validationAlerts || [], va => isValidationAlert(va) && va.validationAlert === 'PercentVarianceApplied')).length > 0 &&
            (stockholding.stockholdingComments || []).find(c => c.stockTypeId === owStockType?.id && !isEmpty(c.comments)) == null
        ) {
            v.messages.errors = [
                ...v.messages.errors,
                <ErrorEmptyCommentsOnPage onTargetClick={() => goToStep(PAGE_INDEX[2].pageIndex)} label={STEPS[2].name} />
            ]
        }

        if (v.messages.errors.length > 0) {
            v.hasValidationErrors = true;
        }

        return v;
    }, [
        focusedFieldCtrl,
        submissionComments, 
        isActive, 
        declarationMessage, 
        isSameAsPrevSubmission, 
        stockholding.submissionFormData?.australiaPageSaved, 
        stockholding.submissionFormData?.overseasPageSaved, 
        stockholding.submissionFormData?.onWaterPageSaved, 
        stockholding.domesticStockholdings, 
        stockholding.stockholdingComments, 
        stockholding.overseasStockholdings, 
        stockholding.onWaterStockholdings, 
        expiredProductsWithData, 
        goToStep, 
        domesticStockType?.id, 
        osStockType?.id, 
        owStockType?.id]);

    const updateComments = useCallback((comments: string) => {
        setChangedState('unsaved_changes');
        setUpdateDataSubmission(prev => produce(prev, draft => {
            draft.comments = comments;
        }));
    }, []);

    const updateDeclaration = useCallback((val: boolean) => {
        if (viewMode === 'view') {
            return;
        }

        setDeclaration(val);
    }, [viewMode]);

    // Keep data in sync with server
    useEffect(() => {
        setUpdateDataSubmission(updateDataSubmissionFromStockholding(stockholding));
        setChangedState('unchanged');
    }, [stockholding]);

    // Set declaration if in "view" mode
    useEffect(() => {
        if (viewMode === 'view') {
            setDeclaration(true);
        }
    }, [viewMode])

    useEffect(() => {
        if (importCtrl.templateImportState.templateImportDialogState === 'complete' && declaration === true) {
            setDeclaration(false);
        }

    }, [declaration, importCtrl.templateImportState.templateImportDialogState]);

    return useMemo(() => ({
        changedState,        
        declaration,
        view,
        updateComments,
        updateDataSubmission,
        updateDeclaration,
        isSameAsPrevSubmission,
        focusedFieldCtrl,
    }), [
        changedState,
        declaration,
        updateComments,
        updateDataSubmission,
        updateDeclaration,
        view,
        isSameAsPrevSubmission,
        focusedFieldCtrl,
    ]);
}

export default usePageSubmit;

function updateDataSubmissionFromStockholding(stockholding: Stockholding): UpdateDataSubmission {
    const {dataSubmission} = stockholding;
    return {
        comments: dataSubmission.comments,
        concurrencyToken: dataSubmission.concurrencyToken,
        id: dataSubmission.id,
    };
}
