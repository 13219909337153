import { ValidationAlertResult } from "./eligible-production-validation-alerts";
import { InlineMessage } from "./shared";
import { ValidationResult } from "./validate-eligible-production";

export function getInlineMessage(validations: Array<ValidationResult>, validationAlerts: Array<ValidationAlertResult>): InlineMessage | undefined {
  if (validations.length > 0) {
    return {
      kind: 'alert',
      message: "Please select either 'Agree' or 'Disagree' to advise whether the s19A volumes are correct for the purposes of your s19A report."
    };
  }

  if (validationAlerts.length > 0) {
    return {
      kind: 'info',
      message: 'You have selected that you \'Disagree\' with the eligible production values displayed. Please enter a reason in the comments field to advise the Department of any discrepancy.'
    }
  }

  return undefined;
}