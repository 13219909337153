import { isOrganisation, Organisation, organisationContact, UpdateOrganisation } from "psims/models/organisation";
import { useEffect, useMemo, useState } from "react";
import { Status } from "psims/types/async";
import { isUpdateOrganisationContact, UpdateOrganisationContact } from "psims/models/organisation-contact";
import useEditCompanyContact from "./use-edit-company-contact";
import { isEmpty } from "psims/lib/empty";
import { useAPI } from "psims/react/providers/api";
import { encodeEscapeChars } from "psims/lib/validation/string";
import { isSuccesfulRowResult, RecordResult } from "psims/models/api/record-result";
import { validateAddress1, validateAddress2, validateCity, validatePostcode } from "psims/lib/validation/user";
import { INVALID_STATE } from "psims/constants/validation-messages";
import { recordActionAsEnum } from "psims/models/api/record-action";
import { useReferenceData } from "psims/react/providers/api/reference-data";
import { asString } from "psims/lib/string";
import { useSelectController } from "psims/react/components/select";
import { isForbiddenError, stringifyForbiddenWAFError } from "psims/lib/server-error";

interface UseEditCompanyProps {
    onSuccess: (organisation: Organisation) => any;
    isCompanyDetails?: boolean;
}

type ValidationMessages = {
    [key in keyof Organisation]?: string;
}

function useEditCompany({onSuccess, isCompanyDetails}: UseEditCompanyProps) {
    const { api } = useAPI();
    const {data: refData} = useReferenceData();
    const [organisationUpdate, setOrganisationUpdate] = useState<UpdateOrganisation | null | undefined>(null);
    const [saveAttempted, setSaveAttempted] = useState(false);
    const [serviceError, setServiceError] = useState<string | null>(null);
    const [serviceStatus, setServiceStatus] = useState<Status>('init');
    const [primaryContact, setPrimaryContact] = useState<UpdateOrganisationContact | null>();
    const [secondaryContact, setSecondaryContact] = useState<UpdateOrganisationContact | null>();
    const stateOptions = useMemo(() => {
        if (refData?.states == null) {
            return [];
        }

        let options = refData.states
        .filter(state => state.name !== 'Other')
        .map(state => ({
            label: asString(state.name),
            value: state.id as number || null,
        }));

        options.unshift({label: 'Select', value: null});
        return options;
    }, [refData]);
        
    const selectStateCtrl = useSelectController({
        value: organisationUpdate?.stateId || null, 
        onChange: stateId => editOrganisation('stateId', stateId || undefined),
        options: stateOptions,
    });

    function selectOrganisationForEdit(selectedOrganisation: Organisation) {
        api.getOrganisationForEdit({id: selectedOrganisation.id})
        .then(response => {
            if (response !== null && response.isSuccessful && response.result != null) {
                setOrganisationUpdate(response.result);
                setPrimaryContact(organisationContact(response.result, 'Primary'));
                setSecondaryContact(organisationContact(response.result, 'Secondary'));
            }
            else{
                setTimeout(() => {
                    setServiceError(`Failed to fetch company id: ${selectedOrganisation.id}`);
                    setServiceStatus('error');
                             }, 750)
            }
        })
        .catch(e => {
            setServiceError(`Failed to save company:  ${e}`);
            setServiceStatus('error');
        });
    }

    function editOrganisation<K extends keyof UpdateOrganisation>(field: K, value: UpdateOrganisation[K]) {
        setOrganisationUpdate(old => {
            if (old == null) {
                return null;
            }

            return {
                ...old,
                [field]: value,
                recordAction: recordActionAsEnum('Update')
            };
        });
    }

    const validationMessages = useMemo(() => {
        const messages: ValidationMessages = {};
        if(shouldValidateAddress(organisationUpdate!)){
            const address1Msg = validateAddress1(organisationUpdate?.addressLine1);
            if (address1Msg !== null) {
                messages.addressLine1 = address1Msg;
            }

            const address2Msg = validateAddress2(organisationUpdate?.addressLine2, organisationUpdate?.addressLine1);
                if (address2Msg !== null) {
                    messages.addressLine2 = address2Msg;
                }
                
            const cityMsg = validateCity(organisationUpdate?.city);
            if (cityMsg !== null) {
                messages.city = cityMsg;
            }
                
            if (!Boolean(organisationUpdate?.stateId)) {
                messages.stateId = INVALID_STATE;
            }
    
            const postcodeMsg = validatePostcode(organisationUpdate?.postcode);
            if (postcodeMsg !== null) {
                messages.postcode = postcodeMsg;
            }
        }
        else{
            messages.addressLine1 = '';
            messages.addressLine2 = '';
            messages.city = '';
            messages.stateId = '';
            messages.postcode = '';
        }

        return messages;
    }, [organisationUpdate]);

    const editCompanyPrimaryContactControl = useEditCompanyContact({
        organisationContact: primaryContact,
        isMandatory: true,
    });

    const editCompanySecondaryContactControl = useEditCompanyContact({
        organisationContact: secondaryContact,
        isMandatory: false
    });
    
    function removeSecondaryContact() {
        editCompanySecondaryContactControl.remove();
    }

    function save() {
        setSaveAttempted(true);

        if(isEmpty(validationMessages) &&
           isEmpty(editCompanyPrimaryContactControl.validationMessages) && 
           isEmpty(editCompanySecondaryContactControl.validationMessages) &&
           organisationUpdate !== null){

            setServiceError(null); 
            const updatePrimary = editCompanyPrimaryContactControl.organisationContactUpdate;
            let updateSecondary = editCompanySecondaryContactControl.organisationContactUpdate;
            setServiceStatus('loading');

            let toBeUpdatedOrganisation: UpdateOrganisation = {
                ...organisationUpdate,
                addressLine1: encodeEscapeChars(organisationUpdate!.addressLine1?.trim()),
                addressLine2: organisationUpdate!.addressLine1 ? encodeEscapeChars(organisationUpdate!.addressLine2?.trim()) : '',
                city: encodeEscapeChars(organisationUpdate!.city?.trim()),
                organisationContacts: [],
            };

            if(isUpdateOrganisationContact(updatePrimary)){
                toBeUpdatedOrganisation.organisationContacts!.push({
                    ...updatePrimary!,
                    addressLine1: encodeEscapeChars(updatePrimary!.addressLine1?.trim()),
                    addressLine2: updatePrimary!.addressLine1 ? encodeEscapeChars(updatePrimary!.addressLine2?.trim()) : '',
                    city: encodeEscapeChars(updatePrimary!.city?.trim())
                });
            }
            if(isUpdateOrganisationContact(updateSecondary)){
                toBeUpdatedOrganisation.organisationContacts!.push({
                    ...updateSecondary!,
                    addressLine1: encodeEscapeChars(updateSecondary!.addressLine1?.trim()),
                    addressLine2: updateSecondary!.addressLine1 ? encodeEscapeChars(updateSecondary!.addressLine2?.trim()): '',
                    city: encodeEscapeChars(updateSecondary!.city?.trim())
                });
            }
            api.updateOrganisation({requestBody: toBeUpdatedOrganisation})
                    .then(response => {
                        if (response?.isSuccessful && isOrganisation(response.result)) {
                            onSuccess(response.result);
                            setOrganisationUpdate(null);
                            setServiceStatus('fulfilled');
                        }
                        else {
                            response?.result?.organisationContacts?.forEach(contact => {
                                if(contact.recordResult && !isSuccesfulRowResult(contact.recordResult as RecordResult)){
                                    setServiceError(`Failed to save company: ${contact.recordResult?.message}`);
                                    setServiceStatus('error');
                                }
                            });
                        }
                    })
                    .catch(e => {                        
                        const message = isForbiddenError(e) ?
                            stringifyForbiddenWAFError(e) :
                            `Failed to save company:  ${e}`;
                        setServiceError(message);
                        setServiceStatus('error');
                    });            
           }
    }

    function cancel() {
        setOrganisationUpdate(null);
        setSaveAttempted(false);
        setServiceError(null);
    }
    
    useEffect(() =>{
        editCompanySecondaryContactControl.updateExcludedEmail(editCompanyPrimaryContactControl.organisationContactUpdate?.email || null);
    }, [editCompanyPrimaryContactControl.organisationContactUpdate?.email, editCompanySecondaryContactControl]);

    useEffect(() =>{
        editCompanyPrimaryContactControl.updateExcludedEmail(editCompanySecondaryContactControl.organisationContactUpdate?.email || null);
    }, [editCompanySecondaryContactControl.organisationContactUpdate?.email, editCompanyPrimaryContactControl]);

    return {
        selectOrganisation: selectOrganisationForEdit,
        cancel,
        save,
        removeSecondaryContact,
        updateOrganisation: organisationUpdate,
        isCompanyDetails,
        selectStateCtrl,
        serviceError,
        serviceStatus,
        saveAttempted,
        primaryContact,
        secondaryContact,
        validationMessages,
        editOrganisation,
        editCompanyPrimaryContactControl: editCompanyPrimaryContactControl,
        editCompanySecondaryContactControl: editCompanySecondaryContactControl,
    }
}

export default useEditCompany;

export type UseEditCompany = ReturnType<typeof useEditCompany>;

function shouldValidateAddress(organisationUpdate: UpdateOrganisation): boolean{
    if (organisationUpdate?.addressLine1 ||
        organisationUpdate?.stateId ||
        organisationUpdate?.city ||
        organisationUpdate?.postcode){
            return true;
        }

    return false;
}