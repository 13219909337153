import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import { asString } from 'psims/lib/string';
import { DataSubmissionStatus } from 'psims/models/data-submission';
import { BoxedDiv } from 'psims/react/components/layout';
import { H3 } from 'psims/react/components/typography';
import UL, { ArrowLI } from 'psims/react/components/unordered-list';
import Text from 'psims/react/components/text';
import Notification from 'psims/react/components/notification';
import VisuallyHidden from 'psims/react/components/visually-hidden';

interface DataSubmission {
  customerActionComments?: string | null;
  status: DataSubmissionStatus;
}

interface CustomerActionCommentsProps {
  dataSubmission: DataSubmission;
}

const CustomerActionCommentContent = styled.pre`
    font-family: 'Open Sans', sans-serif;
    font: size 16px;
    margin-top: 0;
    white-space: pre-wrap;
`;

const CustomerActionComments = ({dataSubmission}: CustomerActionCommentsProps) => {
  if (
    dataSubmission.customerActionComments == null || (
      dataSubmission.status !== 'Action required' &&
      dataSubmission.status !== 'Draft'
    )
  ) {
    return null;
  }
  
  return (
    <div className='container'>
        <BoxedDiv box={{marginTop: 5}}>
            <Notification align='flex-start' kind='info'>
                <BoxedDiv box={{flex: 'column', paddingTop: 1}}>
                    <H3>Changes required on this data submission</H3>

                    <BoxedDiv box={{marginTop: 2}}>
                        <UL padding='0'>
                            <ArrowLI color='primary'>
                                <CustomerActionCommentContent id='customer_action_comments'>
                                    <VisuallyHidden>Changes required on this data submission:</VisuallyHidden>
                                    <Text>
                                        <ReactMarkdown>
                                            {asString(dataSubmission.customerActionComments)}
                                        </ReactMarkdown>
                                    </Text>
                                </CustomerActionCommentContent>
                            </ArrowLI>
                        </UL>
                    </BoxedDiv>
                </BoxedDiv>
            </Notification>
        </BoxedDiv>
    </div>
  );
}

export default CustomerActionComments;
