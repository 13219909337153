import React from "react";

import { UpdateDataSubmission } from "psims/models/data-submission";
import { ProductionProduct } from "psims/models/ref-data/production-product";
import { ProductionProductGroup } from "psims/models/ref-data/production-product-group";
import { ProductionDataSubmissionName, ProductionField, ProductionSubmission } from "psims/models/submission-types/production";

type ShortName = 'Biofuel' | 'Field' | 'Plant';

export const SHORTNAME_MAP: {[key in ProductionDataSubmissionName]: ShortName} = {
    'Biofuel production': 'Biofuel',
    'Field production': 'Field',
    'Plant production': 'Plant',
}

interface FormCtrl {
    view: {
        Submit: {
            comments?: string | null
        }
    }
}

export const buildUpdateDataSubmissionRequest = (formCtrl: FormCtrl, {dataSubmission: {concurrencyToken, id}}: ProductionSubmission): UpdateDataSubmission => {
    
    return {
        comments: formCtrl.view.Submit.comments,
        concurrencyToken,
        id,
    }
}

export type ApplicableFields = (product: ProductionProduct, productGroup?: ProductionProductGroup) => Array<FieldConfig>;

export type FieldConfig = {
    columnHeader: string | JSX.Element;
    field: ProductionField;
    help?: string | JSX.Element;
    helpId?: string;
    label: string; // e.g. when used in error messages
}

export type GetGroupHeader = (group: ProductionProductGroup) => string;

type GroupAsserter<TGroup extends ProductionProductGroup> = TypeAssertion<unknown, TGroup>;

export interface ProductionTypeConfig<TGroup extends ProductionProductGroup> {
  applicableFields: ApplicableFields;
  getGroupHeader: GetGroupHeader;
  groupAsserter?: GroupAsserter<TGroup>;
  productionTypeName: ProductionDataSubmissionName;
  FrontMatter: React.FC<{}>;
}
