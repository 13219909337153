import React from 'react';

import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { UseRefineryForm } from './use-refinery-form';
import { H3 } from 'psims/react/components/typography';
import { TooltipHelp } from '../shared/tooltip-help';
import { CellInput, Table, TD, TH, TR } from '../shared/data-table-components';
import Text from 'psims/react/components/text';
import { localeNumberWithFixed } from 'psims/lib/formatters/numbers';

interface RefineryLossesGainsEditorProps {
    formCtrl: UseRefineryForm;
}

const RefineryLossesGainsEditor = ({formCtrl}: RefineryLossesGainsEditorProps) => {
    return (
        <BoxedDiv box={{marginTop: 6}}>
            <BoxedSpan box={{alignItems: 'center', flex: 'row', marginBottom: 2}}>
                <H3>Refinery losses/gains</H3>
                <TooltipHelp Help={<Help />} />
            </BoxedSpan>

            <Table caption='Your data for refinery losses/gains'>
                <thead>
                    <TR hide={true}>
                        <TH scope='col'>Loss/gain type</TH>
                        <TH fixedWidth='200px' scope='col'>Value</TH>
                    </TR>
                </thead>
                <tbody>
                    <TR>
                        <TH scope='row'>Total losses</TH>

                        <CellInput
                            disabled={formCtrl.view.isDisabled}
                            error={formCtrl.view.totalLosses.validationMessage?.notification.message}
                            shouldFocus={formCtrl.view.focusedField === 'totalLosses'}
                            value={formCtrl.view.totalLosses.data}
                            onChange={val => formCtrl.updateRefiningLosses(val)}
                            label={'Integer value for total losses'}
                            fixedWidth='200px'
                        />
                    </TR>

                    <TR>
                        <TH scope='row'>Calculated losses/(gains)</TH>
                        <TD align='right' fixedWidth='200px' isReadonly={true}>
                            <BoxedDiv box={{paddingRight: 1.5}}>
                                <Text weight="bold" fullWidth={true}>{localeNumberWithFixed(formCtrl.view.calculatedLossesGains)}</Text>
                            </BoxedDiv>
                        </TD>
                    </TR>
                </tbody>
            </Table>

        </BoxedDiv>
    )
}

const Help = () => {
    return (<>
        <p>
            The total of all losses in the month, including both accounted and unaccountable losses (flared, destroyed, evaporated, etc.)
        </p>

        <p>
            Please explain any unusually high losses/gains or discrepancies between reported and calculated losses/gains in the comments section.
        </p>

    </>)
}

export default RefineryLossesGainsEditor;
