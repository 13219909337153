import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from "react";
import useUpdatedRef from "../util/use-updated-ref";
import { getSeverity, useAppInsights } from "./app-insights";

type LogContent<TContent extends {} = {}> = TContent & {
    source?: string;
    message: string;
}

type Loggable<TContent extends {} = {}, TLevel extends LogLevel = 'debug'> = LogContent<TContent> & {
    level: TLevel;
}

interface LoggingContext {
    exception: (err: Error, properties?: {[key: string]: any}) => any;
    info: <TContent>(content: LogContent<TContent>) => any;
    log: <TContent, TLevel extends LogLevel>(data: Loggable<TContent, TLevel>) => any;
    debug: <TContent>(content: LogContent<TContent>) => any;
    warn: <TContent>(content: LogContent<TContent>) => any;
    startTrackPage: <TStaticProperties extends {}, TDeferredProperties extends {}>(name: string, extraProperties?: TStaticProperties) => (dynamicProperties?: TDeferredProperties) => any;
}

const context = createContext<LoggingContext | null>(null);

const LoggerProvider = ({children}: PropsWithChildren<{}>) => {
    const ai = useAppInsights();

    const log = useCallback(<TContent, TLevel extends LogLevel>(data: Loggable<TContent, TLevel>) => {
        const {level, source, message} = data;
        const logMethod: 'log' | 'warn' | 'error' = (
            level === 'warn' ? 'warn' : 
            level === 'error' ? 'error' :
            'log'
        )


        if (process.env.NODE_ENV === 'development') {
            console[logMethod](`${level.toUpperCase()} ${source ? '(' + source + ')' : ''}: ${message}`);
        }

        // Log message in AI if available - remove `else` if testing AI from development is necessary
        else if (ai != null) {
            ai.trackTrace({message, severityLevel: getSeverity(level)});
        }
    }, [ai]);

    const debug = useCallback(<TContent,>(content: LogContent<TContent>) => {
        log({level: 'debug', ...content});
    }, [log]);

    const info = useCallback(<TContent,>(content: LogContent<TContent>) => {
        log({level: 'info', ...content});
    }, [log]);

    const warn = useCallback(<TContent,>(content: LogContent<TContent>) => {
        log({level: 'warn', ...content});
    }, [log]);

    const exception = useCallback((err: Error, properties: {[key: string]: any} = {}) => {
        if (process.env.NODE_ENV === 'development') {
            warn({message: err.message});
        }
        // Log exception in AI if available - remove `else` if testing AI from development is necessary
        else if (ai != null) {
            ai.trackException({exception: err, properties});
        }
    }, [ai, warn]);

    const startTrackPage = useCallback(<TStaticProperties extends {}, TDeferredProperties extends {}>(name: string, extraProperties?: TStaticProperties) => {
        const start = (new Date()).getTime();
        return (deferredProperties?: TDeferredProperties) => {
            const duration = (new Date()).getTime() - start;
            const properties = {
                duration,
                ...(extraProperties || {}),
                ...(deferredProperties || {}),
            }
            if (ai == null) { 
                // noop - but can uncomment below for debugging in local dev:
                // console.log('*** tracking payload:', JSON.stringify({name, properties}));
            } else {
                ai.trackPageView({name, properties});
            };
        }
    }, [ai]);

    return <context.Provider value={{exception, info, log, debug, warn, startTrackPage}}>{children}</context.Provider>
}

export default LoggerProvider;

type LogContentOrString<TWhich extends string | {}> = TWhich extends string ? string : LogContent<TWhich>;

interface UseLoggerProps {
    source: string;
}

export function useLogger({source}: UseLoggerProps) {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error('useLogger must be use within LoggerProvider');
    }

    const ctxRef = useUpdatedRef(ctx);

    const logger = useMemo(() => {
        return {
            exception: ctxRef.current.exception,
            log: ctxRef.current.log,
            debug: <T,>(content: LogContentOrString<T>) => ctxRef.current.debug({source, message: typeof content === 'string' ? content : content.message}),
            info: <T,>(content: LogContentOrString<T>) => ctxRef.current.info({source, message: typeof content === 'string' ? content : content.message}),
            warn: <T,>(content: LogContentOrString<T>) => ctxRef.current.warn({source, message: typeof content === 'string' ? content : content.message}),
            startTrackPage: ctxRef.current.startTrackPage,
        };
    }, [ctxRef, source]);

    // Convenience wrappers for shorthand logging by consumers
    return logger;
}
