import { ProductionProductGroup } from "./production-product-group";

// Note: group ref codes are hard-coded for now, but in the future they
// could be determined by ref data, although we'll have to come up with
// another mechanism to manage known `BioProductGroupRefCodeName`s in
// the context of a fully dynamic set of group reference codes
const BIO_GROUP_REF_CODES = ['PRO-BP-B'] as const;

type BioGroupReferenceCode = typeof BIO_GROUP_REF_CODES[number];

type BiofuelProductionGroup = PopulatedProps<
    ProductionProductGroup & {
        displayName: string;
        referenceCode: BioGroupReferenceCode;
    }, 'id' | 'displayOrder' | 'isActive' | 'name' | 'referenceCode'
>;

export function isBiofuelProductionGroup(maybe?: unknown): maybe is BiofuelProductionGroup {
    const maybeAs = maybe as BiofuelProductionGroup;
    return maybeAs != null && 
    typeof maybeAs.id === 'number' &&
    maybeAs.productionTypeName === 'Biofuel production' &&
    maybeAs.name != null
}

type BioProductionGroupRefCodeName = 'bio';

const BIO_PRODUCTION_GROUP_REF_CODE_MAP: { [key in BioProductionGroupRefCodeName]: BioGroupReferenceCode } = {
  bio: 'PRO-BP-B',
}

export function getBiofuelProductionGroupReferenceCode(key: BioProductionGroupRefCodeName) {
  return BIO_PRODUCTION_GROUP_REF_CODE_MAP[key];
}