/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ValidationAlertEnum {
    NEGATIVE_VALUE_ALERT = 'NegativeValueAlert',
    PREVIOUSLY_REPORTED_ALERT = 'PreviouslyReportedAlert',
    LESS_THAN_SUM_OF_BREAKDOWN_ALERT = 'LessThanSumOfBreakdownAlert',
    LESS_THAN_OTHER_WHOLESALES_ALERT = 'LessThanOtherWholesalesAlert',
    MSO_NOT_MET = 'MsoNotMet',
    ACQUITTED_AMOUNT = 'AcquittedAmount',
    MSOIMPORT_VOLUME_ON_BEHALF_LIMIT_EXCEEDED = 'MSOImportVolumeOnBehalfMaximumLimitExceeded',
    MSOIMPORT_VOLUME_BY_OTHERS_LIMIT_EXCEEDED = 'MSOImportVolumeByOthersMaximumLimitExceeded',
    OPENING_CLOSING_STOCKS_MISMATCH = 'OpeningClosingStocksMismatch',
    SAME_AS_PREVIOUS = 'SameAsPrevious',
    PERCENT_VARIANCE_APPLIED = 'PercentVarianceApplied',
    DEF_RELATIVITY = 'DefRelativity',
    SALES_VARIANCE_APPLIED = 'SalesVarianceApplied',
    STOCK_VARIANCE_APPLIED = 'StockVarianceApplied',
    DERIVED_FROM_OTHER_PRODUCTION_VARIANCE_APPLIED = 'DerivedFromOtherProductionVarianceApplied',
}