import React, { useCallback, useMemo } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import { Redirect } from 'react-router-dom';
import Loading from 'psims/react/components/loading';
import Notification from 'psims/react/components/notification';
import WholesalingEditor from './wholesaling-editor';
import Button from 'psims/react/components/button';
import useWholesalingAPI from './use-wholesaling-api';
import { isWithPopultatedProp } from 'psims/lib/type-assertions';
import useWholesalingRefData, { isUseWholesalingRefData } from './use-wholesaling-ref-data';
import { useNavigation } from 'psims/react/providers/router';

interface WholesalingProps {
	id: number;
};

const WholesalingPage = (props: WholesalingProps) => {
    const isInvalidParams = isNaN(props.id);

    return isInvalidParams ? <Redirect to='/' /> : <WholesalingPageWithID {...props} />;
};

const WholesalingPageWithID = (props: WholesalingProps) => {
	const vm = useVM(props);

    if (vm.viewMode === 'loading') {
        return (
            <BoxedDiv box={{alignItems: 'center', flex: 'column', flexGrow: 1, justifyContent: 'center'}}>
                <Loading>Loading</Loading>
            </BoxedDiv>
        )
    }

    if (vm.viewMode === 'error') {
        return (
            <div className='container'>
                <BoxedDiv box={{alignItems: 'center', flex: 'column', marginV: 4}}>
                    <BoxedDiv box={{alignItems: 'center', flex: 'column', width: '600px'}}>
                        <Notification align='center' kind='warning' >
                            <p>
                                There was a problem loading this wholesaling data submission, please navigate back to your <Button $kind='text' aria-label='Go back to home page' onClick={vm.handleTryAgain}>'Home' page</Button> and try again.
                            </p>
                        </Notification>
                    </BoxedDiv>
                </BoxedDiv>
            </div>
        )
    }

    if (
        vm.viewMode === 'loaded' &&
        isWithPopultatedProp(vm.apiCtrl, 'submission') &&
        isUseWholesalingRefData(vm.refData)
    ) {
        return <WholesalingEditor apiCtrl={vm.apiCtrl} refData={vm.refData} />;
    }

    return null;
}

function useVM({id}: WholesalingProps) {
    const apiCtrl = useWholesalingAPI({dataSubmissionId: id});
    const refData = useWholesalingRefData();
    const {submission, loadStatus} = apiCtrl;
    const navigation = useNavigation();
    
    const handleTryAgain = useCallback(() => {
        navigation.goToHomePage();
    }, [navigation]);

    const viewMode = useMemo(() => {
        if (loadStatus === 'fetch_failed') {
            return 'error';
        }

        if (submission != null && isUseWholesalingRefData(refData)) {
            return 'loaded';
        }

        return 'loading';
    }, [loadStatus, refData, submission]);

    return {
        apiCtrl,
        handleTryAgain,
        loadStatus,
        refData,
        submission,
        viewMode,
    };
}

export default WholesalingPage;
