import React from 'react';
import BaseSVG, { SVGProps } from './_base';

const User = (props: SVGProps) => {
	return (
		<BaseSVG name='user' viewBox='0 0 448 512' {...props}>
			<g>
				<path fill="currentColor" d="M352 128A128 128 0 1 1 224 0a128 128 0 0 1 128 128z"></path>
				<path fill="currentColor" d="M313.6 288h-16.7a174.1 174.1 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6A134.43 134.43 0 0 0 313.6 288z"></path>
			</g>
		</BaseSVG>
	);
};

export default User;
