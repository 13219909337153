import { RefineryType } from "psims/models/ref-data/refinery-type";
import { RefiningSubmission, UpdateRefining, UpdateRefiningSubmission } from "psims/models/submission-types/refining";
import { useLogger } from "psims/react/providers/logging";
import useConfirmNavigation from "psims/react/util/use-confirm-navigation";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { UseRefineryAPI } from "./use-refinery-api";
import { UseRefineryForm } from "./use-refinery-form";
import { UseRefineryProgress } from "./use-refinery-progress";
import { buildUpdateDataSubmissionRequest } from './shared';
import { UseRefineryValidationAlerts } from "./use-refinery-validation-alerts";
import { attemptFocusOnSelector, attemptScrollToSelector } from "psims/react/pages/primary-pages/data-submissions/shared/view-utils";
import { UseRefineryServiceResponse } from "./use-service-response";
import { SELECTOR_NOTIFICATION, SELECTOR_NOTIFICATIONS } from "psims/constants/selectors";
import { UseTemplateImport } from "psims/react/blocks/import/use-template-import";
import { RefiningSubmissionVM } from "psims/gen/xapi-client";

interface UseRefinerySaveProps {
    apiCtrl: UseRefineryAPI;
    formCtrl: UseRefineryForm;
    progressCtrl: UseRefineryProgress;
    serviceResponse: UseRefineryServiceResponse;
    validationAlerts: UseRefineryValidationAlerts;
    importCtrl: UseTemplateImport<Partial<RefiningSubmissionVM>>;
}

type UnsavedChangesState = 'idle' | 'showing_dialog' | 'saving' | 'save_success' | 'save_failed';

type ValidationAlertsState = 'idle' | 'showing_dialog' | 'confirmed' | 'cancelled';

function useRefinerySave({apiCtrl, formCtrl, progressCtrl, importCtrl, serviceResponse, validationAlerts}: UseRefinerySaveProps) {
    const logger = useLogger({source: 'useRefinerySave'});
    const loggerRef = useUpdatedRef(logger);
    const [unsavedChangesState, dispatchUnsavedChangesAction] = useReducer(unsavedChangesReducer, 'idle');
    const [validationAlertsState, setValidationAlertsState] = useState<ValidationAlertsState>('idle');
    const requestedPageIndex = useRef<number | null>(null);
    const navPrompt = useConfirmNavigation({when: formCtrl.view.hasUnsavedChanges});
    const {viewMode, submission, updateDataSubmission, updateRefinery} = apiCtrl;

    const setImportStepSaved = useCallback(() => {
        const {currentStep} = progressCtrl;
        if (viewMode !== 'view' && importCtrl && importCtrl.templateImportState.templateImportDialogState === 'processing') {
            if (currentStep.kind === 'data') {
                importCtrl.saveStep(currentStep.refData.refineryType.name as string);
            } else {
                importCtrl.saveStep('Submit');
            }
        }            
    }, [importCtrl, progressCtrl, viewMode]);

    const navigateToRequestedPage = useCallback(() => {
        if (requestedPageIndex.current != null) {
            progressCtrl.goToStep(requestedPageIndex.current);
            requestedPageIndex.current = null;
        }
    }, [progressCtrl]);

    const attemptNavigation = useCallback((stepIndex: number) => {
        requestedPageIndex.current = stepIndex;
        if (viewMode === 'edit' && validationAlerts.validationAlertsForCurrentStep.length > 0) {
            setValidationAlertsState('showing_dialog');
            return;
        }

        navigateToRequestedPage();
    }, [navigateToRequestedPage, validationAlerts.validationAlertsForCurrentStep, viewMode]);

    const doImportTemplateSaved = useMemo(() => {
        return viewMode !== 'view' && importCtrl && importCtrl.templateImportState.templateImportDialogState === 'processing';
    }, [importCtrl, viewMode]);
    
    const save = useCallback((thenPageIndex?: number) => {
        const {currentStep} = progressCtrl;

        if (submission == null) {
            loggerRef.current.warn('Failed to save submission - null submission from API controller');
            return;
        }

        progressCtrl.onSave();

        if (!formCtrl.view.isValid) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
            return;
        }
        
        if (thenPageIndex != null) {
            requestedPageIndex.current = thenPageIndex;
        }

        dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'saving'});

        if (currentStep.kind === 'submit') {
            let request = buildUpdateDataSubmissionRequest(formCtrl, submission);
            
            if (doImportTemplateSaved) {
                request = {...request, isTemplateImport: true};
            }
            updateDataSubmission(request);
        } else {
            let request = buildUpdateRefineryRequest(formCtrl, currentStep.refData.refineryType, submission);
            
            if (doImportTemplateSaved) {
                request = {...request, isTemplateImport: true};
            }
            updateRefinery(request); 
        }           
        setImportStepSaved();
    }, [doImportTemplateSaved, formCtrl, loggerRef, progressCtrl, setImportStepSaved, submission, updateDataSubmission, updateRefinery]);

    const saveAndGoToPage = useCallback((index: number) => {
        requestedPageIndex.current = index;
        save();
    }, [save]);

    const cancelSave = useCallback(() => {
        requestedPageIndex.current = null;
        dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
        if (navPrompt.showPrompt) {
            navPrompt.handleDecision(false);
        }
    }, [navPrompt]);

    const confirmSave = useCallback(() => {
        if (navPrompt.showPrompt) {
            save();
        } else if (requestedPageIndex.current != null) {
            saveAndGoToPage(requestedPageIndex.current);
        } else  {
            loggerRef.current.warn('Unexpected null page index when attempting to confirm save');
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
        }
    }, [loggerRef, navPrompt.showPrompt, save, saveAndGoToPage]);

    const proceedWithoutSave = useCallback(() => {
        formCtrl.resetForm();
        if (navPrompt.showPrompt) {
            navPrompt.handleDecision(true);
        } else if (requestedPageIndex.current != null) {
            setImportStepSaved();
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
            navigateToRequestedPage();
        }
    }, [formCtrl, navPrompt, navigateToRequestedPage, setImportStepSaved]);

    const checkChangesAndGoToPage = useCallback((index: number) => {
        if (!formCtrl.view.hasUnsavedChanges) {
            attemptNavigation(index);
            return;
        }

        if (!formCtrl.view.isValid) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
            return;
        }

        // Otherwise, show dialog
        requestedPageIndex.current = index;
        dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'showing_dialog'});
    }, [attemptNavigation, formCtrl]);

    // Validation alert actions
    const confirmValidationAlerts = useCallback(() => {
        setValidationAlertsState('confirmed');
    }, []);

    const cancelValidationAlerts = useCallback(() => {
        setValidationAlertsState('cancelled');
        attemptFocusOnSelector(`${SELECTOR_NOTIFICATION('info')}`);
    }, []);

    // Send all api status changes to reducer
    useEffect(() => {
        let serviceStatus: SubmissionStatusChangeValue = (serviceResponse.notOKRecordResults || []).length > 0 ?
            'update_partial':
            serviceResponse.status;
        dispatchUnsavedChangesAction({type: 'SUBMISSION_STATUS_CHANGE', value: serviceStatus});
    }, [serviceResponse]);

    // Handle save attempt result
    useEffect(() => {
        if (unsavedChangesState === 'save_failed') {
            if (navPrompt.showPrompt) {
                navPrompt.handleDecision(false);
            }
            requestedPageIndex.current = null;
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
        } else if (unsavedChangesState === 'save_success') {
            if (navPrompt.showPrompt) {
                navPrompt.handleDecision(true);
            } else if (requestedPageIndex.current != null) {
                attemptNavigation(requestedPageIndex.current);
            }
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
        }
    }, [attemptNavigation, navPrompt, unsavedChangesState, progressCtrl]);

    // Show dialog if nav prompt detects browser navigation
    useEffect(() => {
        if (navPrompt.showPrompt) {
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'showing_dialog'});
        } else {
            dispatchUnsavedChangesAction({type: 'SET_STATE', value: 'idle'});
        }
    }, [navPrompt.showPrompt]);

    // Handle validation alert decision
    useEffect(() => {
        switch (validationAlertsState) {
            case 'cancelled': {
                requestedPageIndex.current = null;
                setValidationAlertsState('idle');
                return;
            }

            case 'confirmed': {
                navigateToRequestedPage();
                setValidationAlertsState('idle');
                return;
            }

            default: return;
        }
    }, [navigateToRequestedPage, validationAlertsState]);

    return {
        unsavedChangesState,
        validationAlertsState,
        cancelSave,
        cancelValidationAlerts,
        checkChangesAndGoToPage,
        confirmSave,
        confirmValidationAlerts,
        proceedWithoutSave,
        saveAndGoToPage,
    }
}

export default useRefinerySave

export type UseRefinerySave = ReturnType<typeof useRefinerySave>;

// Unsaved changes reducer actions
type SubmissionStatusChangeValue = UseRefineryAPI['loadStatus'] | 'update_partial';

type SubmissionStatusChangeAction = {
    type: 'SUBMISSION_STATUS_CHANGE',
    value: SubmissionStatusChangeValue;
}

type SetStateAction = {
    type: 'SET_STATE',
    value: UnsavedChangesState;
}

type UnsavedChangesAction = SubmissionStatusChangeAction | SetStateAction;

function unsavedChangesReducer(state: UnsavedChangesState, action: UnsavedChangesAction): UnsavedChangesState {
    switch (action.type) {
        case 'SET_STATE': {
            return action.value;
        }

        case 'SUBMISSION_STATUS_CHANGE': {
            switch (state) {
                case 'saving': {
                    switch (action.value) {
                        case 'update_failed': 
                        case 'update_partial': 
                            return 'save_failed';
                        case 'updated':
                            return 'save_success';
                        default:
                            return state;
                    }
                }
                default: {
                    return state;
                }
            }
        }
        
        default: {
            console.warn('Unknown action dispatched to unsavedChangesReducer');
            return state;
        }
    }
}

// Helpers
function buildUpdateRefineryRequest(formCtrl: UseRefineryForm, {id, name}: RefineryType, submission: RefiningSubmission): UpdateRefiningSubmission {
    const refineryComment = formCtrl.view[name].data.comments.data;
    const refinings = formCtrl.view[name].groups
        .map(g => g.products)
        .reduce((memo, productArr) => [...memo, ...productArr], [])
        .map(productView => productView.data)
        .filter(updateRefining => updateRefining != null) as Array<UpdateRefining>;
    return {
        dataSubmissionId: submission.dataSubmission.id as number,
        refinings,
        refineryComment: refineryComment || undefined,
        refineryTypeId: id,
        submissionFormData: formCtrl.updateFormData,
    }
}
