import { DefSubmission } from "psims/models/submission-types/def/def-submission";
import { useMemo } from "react";
import useProgress from "../shared/use-progress";
import { ViewMode } from "../shared/use-view-mode";

interface UseDefProps {
  submission: DefSubmission;
  viewMode: ViewMode;
}

function useDefProgress({ submission, viewMode }: UseDefProps) {
  const steps = useMemo(() => {
    return [
      {
        kind: 'data' as 'data',
        label: 'Diesel exhaust fluid',
        hasData: viewMode === 'edit' ? Boolean(submission.pageData.pageSaved) : true,
      },
      {
        kind: 'submit' as 'submit',
        label: 'Declaration',
        hasData: null,
      }
    ];
  }, [submission.pageData.pageSaved, viewMode]);

  const progressCtrl = useProgress({
    orderedFormDataPageSavedKeys: ['dataPageSaved'],
    steps,
    submissionFormData: {
      dataPageSaved: submission.pageData.pageSaved,
    },
    submissionStatus: submission.dataSubmission.status,
    viewMode,
  });

  return progressCtrl;
}

export default useDefProgress

export type UseDefProgress = ReturnType<typeof useDefProgress>;
