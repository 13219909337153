import { isEmpty } from "psims/lib/empty";
import { isString } from "psims/lib/string";
import { BoxedDiv } from "psims/react/components/layout";
import { HasReportingPeriod } from "psims/react/pages/primary-pages/data-submissions/shared/types";
import FileNotSupported from "./file-not-supported";
import { ImportError, ImportRowResult, ImportTemplateDataPage, Sheet, TemplateImporter, TemplateImportState,  Tuple, ImportColumns, ColumnDataType } from "./types";
import { UseImportReferenceData } from "./use-import-reference-data";
import { idleTemplateImportState } from "./use-template-import";

const findRow = (sheet: any[][], values: string[], start: number = 0) => {
    let row = -1;

    const found = sheet.some((r, index) => {
        row = index;

        const stringRow = r.map(x => x as string);
        let allFound = true;
        values.forEach(v => {
            allFound = allFound && stringRow.findIndex(x => isString(x) && x?.toLowerCase() === v.toLowerCase()) > -1;
        });

        return allFound;
      });

    row = found ? row : -1;

    return row;
}

const cleanupColumnName = (col: string) => {
    return col.replace(/[\r\n]/gm, ' ').replace(/[ ][ ]/gm, ' ');
}

export const getNumberCellValue = (row: any[], columns: Array<Tuple<string, number | null>>, col: string, dataType: ColumnDataType, decimalPoints?: number) => {
    const idx = columns.findIndex(def => def[0].toLowerCase() === cleanupColumnName(col).toLowerCase());
    const column = idx > -1 ? columns[idx][1] : null;
    if (column == null) {
        return null;
    }

    const val = row[column];

    if (val == null) {
        return null;
    }

    const rawNumber = Number(val)

    if (dataType === 'integer') {
        const result = Math.round(rawNumber);
        if (isNaN(result)) {
            // If integer can't be parsed, return user value and defer to validation messaging
            return val;
        }
        return result;
    }

    if (dataType === 'decimal') {
        if (isNaN(Number(val))) {
            return val;
        }
        return Number(val).toFixed(decimalPoints);
    }

    // Return raw value to preserve any 'bad data' for user to correct
    return val;
}

export const getStringCellValue = (row: any[], columns: Array<Tuple<string, number | null>>, col: string, isString: boolean = false) => {
    const idx = columns.findIndex(def => def[0].toLowerCase() === cleanupColumnName(col).toLowerCase());
    const column = idx > -1 ? columns[idx][1] : null;
    return column != null ? row[column] as string : undefined;
}

const anyValues = (row: any[], headerRowKeys: string[], columns: Array<Tuple<string, number | null>>) => {
    let result = false;
    const headerKeys = headerRowKeys.map(k => k.toLowerCase());

    columns.filter(x => !headerKeys.includes(x[0].toLowerCase())).forEach(c => {
        if (c != null && c[1] != null) {
            result = result || !isEmpty(row[c[1]]); 
        }
    });

    return result;
}

const getColumns = (data: any[][], headerRow: number, headerRowKeys: string[], columnDefs: Array<ImportColumns>) => {
    const columns: Array<Tuple<string, number | null>> = [];

    const header = data[headerRow].map(x => cleanupColumnName(x));

    headerRowKeys.forEach(value => {
        const idx = header.findIndex(cell => cleanupColumnName(cell).toLowerCase() === value.toLowerCase());
        columns.push([value, idx > -1 ? idx : null]);
    });

    columnDefs.forEach(pair => {
        const idx = header.findIndex(cell => cleanupColumnName(cell).toLowerCase() === pair.column.toLowerCase());
        columns.push([pair.column, idx > -1 ? idx : null]);
    });

    return columns;
}
 
const resultProcessor = <TSubmission, TData>(dataKey: string, importState: TemplateImportState<TSubmission>, data: Array<TData>, totalRows: number, errors: ImportError[]): TemplateImportState<TSubmission> => {     
    let newErrors = importState.errors ?? [];
    newErrors = newErrors.concat(errors);
    const totalErrors = newErrors.length;
    return {
        ...importState,
        data: {
            ...importState.data as TSubmission,
            [dataKey]: data
        },
        errors: newErrors,
        totalRows,
        totalErrors: totalErrors
    }
};

const processPageAddData = <TData extends any>(
    rowIndex: number, 
    row: any[], 
    refData: UseImportReferenceData, 
    page: ImportTemplateDataPage<TData>, 
    columns: Array<Tuple<string, number | null>>,
    dataSubmission: HasReportingPeriod
): ImportRowResult<TData> => {
    let result: ImportRowResult<TData> | undefined = undefined;

    let data: Partial<TData> = {};
    const funcColumns = page.columns.filter(x => x.colFunction !== undefined);
    const regularColumns = page.columns.filter(x => x.colFunction === undefined);

    funcColumns.every(c => {
        let funcResult = c.colFunction && c.colFunction(page.name, refData, columns, row, dataSubmission);
        if (!funcResult || (!funcResult.isSuccessful && !funcResult.error)) {            
            result = {
                isSuccessful: false,
                outcome: {
                    page: page.name,
                    error: 'Unknown data error',
                    row: rowIndex + 1
                }
            };
            return false;
        } else if (!funcResult.isSuccessful && funcResult.error) {
            result = {
                isSuccessful: funcResult.isSuccessful,
                outcome: {
                    ...funcResult.error,
                    page: page.name,
                    row: rowIndex + 1
                }
            }
            return false;
        }

        if (funcResult.isSuccessful) {
            data = {...data, [c.dataKey]: funcResult.result};
        }
        
        return true;
    });

    if (!result) {
        regularColumns.every(c => {
            const value = isDecimalDataType(c) ?
                getNumberCellValue(row, columns, c.column, c.dataType, c.decimalPoints) :
                getNumberCellValue(row, columns, c.column, c.dataType);
            data = {...data, [c.dataKey]: value == null ? undefined : value};
            return true;
        });
    } else {
        return result;
    }

    return {
        isSuccessful: true,
        outcome: data as TData
    };
};

type DecimalDataType = {
    dataType: 'decimal';
    decimalPoints: number;
}

function isDecimalDataType(maybe?: unknown): maybe is DecimalDataType {
    const maybeAs = maybe as DecimalDataType;
    return (
        maybeAs != null &&
        maybeAs.dataType === 'decimal' &&
        typeof maybeAs.decimalPoints === 'number'
    );
}

const processImportPage = <TData extends any>(
    page: ImportTemplateDataPage<TData>, 
    refData: UseImportReferenceData, 
    sheet: Sheet,
    dataSubmission: HasReportingPeriod,
    filename?: string | null,
    extra?: ImportRowResult<any>[],
    extraDataKey?: string,
    extraFilter?: (data: any, extra: any[]) => any[]
): Array<ImportRowResult<TData>> => {
    let result: Array<ImportRowResult<TData>> = [];

    const addData = page.addData ?? processPageAddData;
    if (page != null && sheet) {
        const data = sheet.data;
        const headerRow = findRow(data, page.headerRowKeys);
        if (headerRow < 0) {
            result.push({
                isSuccessful: false,
                outcome: {
                    page: page.name,
                    error: <FileNotSupported fileName={filename} />,   
                    isTerminalError: true   
                }
            });
        } else {
            const columns = getColumns(data, headerRow, page.headerRowKeys, page.columns);
            const extraData = extra?.filter(x => x.isSuccessful).map(x => x.outcome) ?? [];
            const useExtra = extra !== undefined && extraDataKey !== undefined && extraFilter !== undefined;
            for (let i = headerRow + 1; i < data.length; i++) {
                const row = data[i];
                let doPush = anyValues(row, page.headerRowKeys, columns);
                if (doPush || useExtra) {
                    let rowResult = addData(i, row, refData, page, columns, dataSubmission);
                    if (doPush) {
                        rowResult = {...rowResult, hasData: true};
                    }
                    if (useExtra && rowResult.isSuccessful) {
                        let tempData = rowResult.outcome as any;
                        const filteredExtra = extraFilter(tempData, extraData);
                        if (filteredExtra.length > 0 || doPush) {
                            doPush = true;
                            tempData = {
                                ...tempData,
                                [extraDataKey]: filteredExtra
                            };
                            rowResult = {
                                ...rowResult,
                                outcome: tempData as TData
                            }
                        }
                    } 
                    if (doPush) {
                        result.push(rowResult);
                    }
                }
            } 
        }
    }
    return result;
};

export const processImport = <TSubmission extends unknown>(
        workbook: Array<Sheet>, 
        importer: TemplateImporter<TSubmission>, 
        importRefData: UseImportReferenceData,
        dataSubmission: HasReportingPeriod,
        filename?: string | null
    ): TemplateImportState<TSubmission> => {
    let result = importer.importStateBuilder();
    let totalRows = 0;
    let errors: Array<ImportError> = [];

    if (!workbook ) {
        errors.push({
            page: '',
            error: <FileNotSupported fileName={filename} />,   
            isTerminalError: true
        });
        result = {...result, errors, templateImportDialogState: 'error', totalErrors: 1, totalRows: 0};
    } else {      
        importer.dataElements.forEach(element => {
            const dataKey = element.dataKey;
            errors = [];
            const sheet = workbook.find(x => x.name.toLowerCase() === element.page.name.toLowerCase());

            let rowResults: Array<ImportRowResult<any>> | undefined = undefined;
            let extraData: Array<ImportRowResult<any>> | undefined = undefined;

            if (sheet) {
                if (element.extra && element.extraDataKey && element.extraFilter) {
                    const extraPage = element.extra.name;
                    const extraSheet = workbook.find(x => x.name.toLowerCase() === extraPage.toLowerCase());
                    if (extraSheet) {
                        extraData = processImportPage(element.extra, importRefData, extraSheet, dataSubmission, filename);
                        totalRows = totalRows + extraData.filter(x => x.isSuccessful).length;
                        errors.push(...extraData.filter(x => !x.isSuccessful).map(x => x.outcome as ImportError));
                    }
                    rowResults = processImportPage(element.page, importRefData, sheet, dataSubmission, filename, extraData, element.extraDataKey, element.extraFilter)
                } else {
                    rowResults = processImportPage(element.page, importRefData, sheet, dataSubmission, filename);
                }
                const rowErrors = rowResults.filter(x => !x.isSuccessful).map(r => r.outcome as ImportError);
                let rowData = rowResults.filter(x => x.isSuccessful).map(r => r.outcome as any);
                const actualRows = rowResults.filter(x => !x.isSuccessful || x.hasData === true);
                totalRows = totalRows + actualRows.length;
                if (importer.mergeRows) {
                    rowData = importer.mergeRows(result, rowData);
                }
                if (errors.length > 0) {
                    rowErrors.push(...errors);
                    totalRows = totalRows + errors.length;
                }
                result = resultProcessor(dataKey, result, rowData, totalRows, rowErrors); 
            } else {
                if (result.errors === undefined) {
                    result.errors = [];
                }
                result.errors.push({
                    page: element.page.name,
                    error: <FileNotSupported fileName={filename} />,   
                    isTerminalError: true                 
                });
            }
        });
    }
    
    const terminalError = result?.errors?.findIndex(x => x.isTerminalError === true);
    if (terminalError !== undefined && terminalError > -1 && result && result.errors) {
        const error = result?.errors[terminalError];
        const newError: ImportError = {
            error: <BoxedDiv box={{flex: 'column'}}>
                {error.error}

                {
                    error.recommendTemplateRequest &&
                    <p>Please use the 'Request data template' button to generate a new template.</p>
                }
            </BoxedDiv>,
            page: result?.errors[terminalError].page
        };
        result = {
            ...idleTemplateImportState(),
            templateImportDialogState: 'error',
            errors: [newError]
        }
    } else {
        result = {
            ...result,
            templateImportDialogState: 'complete',
            unsavedChanges: totalRows > 0
        }
    }
    return result;
};
