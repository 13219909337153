import { useCallback, useEffect, useMemo, useState } from "react";

import { DataSubmission, DataSubmissionTypeName, UpdateDataSubmission } from "psims/models/data-submission";
import useDataSubmission from "./use-data-submission";
import { CommentsFocusField, DeclarationFocusField } from "./types";
import { validateComment } from "./validation";
import { INVALID_DECLARATION } from "psims/constants/validation-messages";
import NotificationMessage from "./notification-message";
import { is } from "psims/lib/type-assertions";
import { useNavigation } from "psims/react/providers/router";
import { attemptScrollToSelector } from "./view-utils";
import { SELECTOR_NOTIFICATIONS } from "psims/constants/selectors";

type FocusFieldCtrl = {
    setFocusedField: (submitFocusableField: CommentsFocusField | DeclarationFocusField) => any;
}

interface UseSubmitProps<TKind extends DataSubmissionTypeName> {
    apiCtrl: {
        loadStatus: string;
        submit: (dataSubmissionUpdate: UpdateDataSubmission) => any;
    }
    commentsRequiredMsg?: string;
    customDeclaration?: string;
    dataSubmission: DataSubmission<TKind>
    focusFieldCtrl: FocusFieldCtrl;
    hasExternalErrors?: boolean;
    isActive?: boolean;
}

function useSubmit<TKind extends DataSubmissionTypeName>({
    apiCtrl,
    commentsRequiredMsg,
    customDeclaration,
    dataSubmission,
    focusFieldCtrl,
    hasExternalErrors,
    isActive
}: UseSubmitProps<TKind>) {
    const nav = useNavigation();
    const dsCtrl = useDataSubmission({dataSubmission});
    const [declaration, setDeclaration] = useState(false);
    const [submitAttempted, setSubmitAttempted] = useState(false)

    const validationMessages = useMemo(() => {
        return getSubmitValidationMessages({
            comments: dsCtrl.dataSubmissionUpdate.comments,
            commentsRequiredMsg,
            customDeclaration,
            declaration: declaration,
            focusFieldCtrl,
            submitAttempted,
        })
    }, [commentsRequiredMsg, customDeclaration, declaration, dsCtrl, focusFieldCtrl, submitAttempted]);

    const notifications = useMemo(() => {
        if (validationMessages != null) {
            return getSubmitNotifications({validationMessages});
        }

        return [];
    }, [validationMessages]);

    const commentsError = useMemo(() => {
        return validationMessages.comments.validationMessages.comments?.tooltip.message;
    }, [validationMessages.comments.validationMessages.comments]);

    const isValidForSubmit = useCallback(() => {
        setSubmitAttempted(true);

        if (hasExternalErrors) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
            return false;
        }

        const vms = getSubmitValidationMessages({
            submitAttempted: true,
            focusFieldCtrl,
            comments: dsCtrl.dataSubmissionUpdate.comments,
            commentsRequiredMsg,
            declaration,
        });

        const notifs = getSubmitNotifications({validationMessages: vms});

        return notifs.length === 0;
    }, [commentsRequiredMsg, declaration, dsCtrl, focusFieldCtrl, hasExternalErrors]);

    const attemptSubmit = useCallback(() => {
        if (isValidForSubmit()) {
            apiCtrl.submit(dsCtrl.dataSubmissionUpdate);
        }
    }, [apiCtrl, dsCtrl, isValidForSubmit]);

    useEffect(() => {
        if (apiCtrl.loadStatus === 'submitted') {
            nav.goToReportSubmittedPage(dataSubmission.id);
        }
    }, [apiCtrl.loadStatus, dataSubmission, nav]);

    useEffect(() => {
        const reset = dsCtrl.resetComments;
        if (isActive != null && isActive === false) {
            reset();
        }
    }, [isActive, dsCtrl.resetComments, dataSubmission.comments]);

    return useMemo(() => ({
        changeSerial: dsCtrl.changeSerial,
        comments: dsCtrl.dataSubmissionUpdate.comments,
        commentsError,
        dataSubmissionUpdate: dsCtrl.dataSubmissionUpdate,  
        declaration,
        hasUnsavedChanges: dsCtrl.hasUnsavedChanges,
        notifications,
        submitAttempted,
        validationMessages,
        attemptSubmit,
        updateComments: dsCtrl.updateComments,
        updateDeclaration: setDeclaration,
    }), [
        dsCtrl.changeSerial,
        commentsError,
        dsCtrl.dataSubmissionUpdate,
        declaration,
        dsCtrl.hasUnsavedChanges,
        notifications,
        submitAttempted,
        validationMessages,
        attemptSubmit,
        dsCtrl.updateComments,
        setDeclaration,

    ]);
}

export default useSubmit

export type UseSubmit = ReturnType<typeof useSubmit>;

type SubmitValidationArgs = {
    focusFieldCtrl: FocusFieldCtrl; 
    comments: string | null | undefined;
    commentsRequiredMsg?: string;
    customDeclaration?: string;
    declaration: boolean;
    submitAttempted: boolean;
}

function getSubmitValidationMessages({
    comments,
    commentsRequiredMsg,
    customDeclaration,
    declaration,
    focusFieldCtrl,
    submitAttempted
}: SubmitValidationArgs) {
    const commentsMsg = validateComment(comments, commentsRequiredMsg);
    const declarationMsg = (submitAttempted && !declaration) ? INVALID_DECLARATION : undefined;

    return {
        comments: {
            comments,
            validationMessages: 
            {
                comments: commentsMsg != null ? {
                    tooltip: {
                        message: commentsMsg,
                    },
                    notification: {
                        content: NotificationMessage({
                            parts: [
                                'Please enter a reason in the ',
                                {
                                    label: `Go to comments field`,
                                    onClick: () => focusFieldCtrl.setFocusedField({kind: 'comments'}),
                                    text: 'comments'
                                },
                                ' to confirm your data is correct.'
                            ]
                        }),
                    }
                } : undefined,
            },
        },
        declaration:{
            declaration,
            validationMessages: {
                declaration: declarationMsg != null ? {
                    tooltip: {
                        message: declarationMsg,
                    },
                    notification:{
                        content: NotificationMessage({parts: [
                            'For ',
                            {label: 'Go to declaration confirm checkbox', onClick: () => focusFieldCtrl.setFocusedField({kind: 'declaration'}), text: customDeclaration ? customDeclaration : 'I confirm I have read and agree to the declaration'},
                            `: ${declarationMsg}`,
                        ]})
                    },
                }: undefined,
            },
        },
    }
}

type SubmitNotificationsArgs = {
    validationMessages: ReturnType<typeof getSubmitValidationMessages>;
}

function getSubmitNotifications({validationMessages}: SubmitNotificationsArgs) {
    const ns = [
        validationMessages.comments?.validationMessages.comments,
        validationMessages.declaration?.validationMessages.declaration,
    ].filter(is);
    const vms = ns.map(v => v.notification);
    return vms;
}