import { useCallback, useEffect, useMemo, useState } from "react";

import { MsoStockOnBehalf } from "psims/models/submission-types/mso/mso-stock-on-behalf";
import { UseMsoRefData } from "../shared/use-mso-ref-data";
import { getDataNotifications, getDataValidationMessages, getSubmitNotifications, getSubmitValidationMessages } from "../shared/validation";
import { UseMsoImporterFocus } from "./use-mso-importer";
import { UseMsoImporterForm } from "./use-mso-importer-form";
import { UpdateMsoComment } from "psims/models/submission-types/mso/mso-comment";
import { UseMsoImporterProgress } from "./use-mso-importer-progress";
import { UpdateMso } from "psims/models/submission-types/mso/mso";

interface UseMsoImporterValidationProps {
    focusFieldCtrl: UseMsoImporterFocus;
    formCtrl: UseMsoImporterForm;
    progressCtrl: UseMsoImporterProgress;
    refData: UseMsoRefData;
}

function useMsoImporterValidation({focusFieldCtrl, formCtrl, progressCtrl, refData}: UseMsoImporterValidationProps) {
    const [saveAttempted, setSaveAttempted] = useState(false);

    const validationMessages = useMemo(() => {
        const {currentStep} = progressCtrl;

        // Validate data entry page
        if (currentStep.kind === 'data') {
            const {msoCtrl, submissionUpdate: {msoComment, msos, stockholdings, stocksOnBehalf}} = formCtrl;
            return getDataValidationMessages({
                enforceMandatoryFields: saveAttempted,
                excludeEEZ: false,
                focusFieldCtrl,
                msoComment: msoComment as UpdateMsoComment,
                msoCtrl,
                msos: msos as Array<UpdateMso>,
                refData,
                stockholdings,
                stocksOnBehalf: stocksOnBehalf as Array<MsoStockOnBehalf>,
            })
        } else {
            // submit page validation messages
            return getSubmitValidationMessages({
                focusFieldCtrl,
                goToStep: progressCtrl.goToStep,
                refData,
                stockholdings: formCtrl.submissionUpdate.stockholdings
            });
        }
    }, [formCtrl, focusFieldCtrl, progressCtrl, refData, saveAttempted]);

    const notifications = useMemo(() => {
        if (validationMessages != null) {
            return progressCtrl.currentStep.kind === 'data' ?
              getDataNotifications({validationMessages,  msoProducts: refData.products} as Parameters<typeof getDataNotifications>[0]) :
              getSubmitNotifications({validationMessages, msoProducts: refData.products} as Parameters<typeof getSubmitNotifications>[0]);
        }

        return [];
    }, [progressCtrl.currentStep.kind, refData.products, validationMessages]);

    const isValidForSave = useCallback(() => {
        setSaveAttempted(true);

        if (progressCtrl.currentStep.kind === 'data') {
            const {msoCtrl, submissionUpdate: {msoComment, msos, stockholdings, stocksOnBehalf}} = formCtrl;
            const vms = getDataValidationMessages({
                enforceMandatoryFields: true,
                excludeEEZ: false,
                focusFieldCtrl,
                msoComment: msoComment as UpdateMsoComment,
                msoCtrl,
                msos: msos as Array<UpdateMso>,
                refData,
                stockholdings,
                stocksOnBehalf,
            });
            const notifications = getDataNotifications({msoProducts: refData.products, validationMessages: vms});

            return notifications.length === 0;
        }
        
        return true;
    }, [formCtrl, focusFieldCtrl, progressCtrl, refData]);

    useEffect(() => {
        setSaveAttempted(false);
    }, [progressCtrl.currentStep.index]);

    return {
        ...validationMessages,
        notifications,
        isValidForSave,
    }
}

export default useMsoImporterValidation

export type UseMsoImporterValidation = ReturnType<typeof useMsoImporterValidation>;
