import React, { PropsWithChildren } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import { H2 } from 'psims/react/components/typography';
import { ColumnHeader, Table, TD, TH } from 'psims/react/pages/primary-pages/data-submissions/shared/data-table-components';
import { localeNumberWithFixed } from 'psims/lib/formatters/numbers';
import { PeriodSummaryRowConfig, PeriodSummaryTableConfig } from './types';

interface PeriodSummaryTableProps extends PropsWithChildren<PeriodSummaryTableConfig> { }

const PeriodSummaryTable = (props: PeriodSummaryTableProps) => {
  const vm = useVM(props);

  return (
    <BoxedDiv box={{flex: 'column'}}>
      <H2>{vm.title}</H2>

      <p>
        {props.children}
      </p>

      <BoxedDiv box={{marginV: 2 }}>
        <Table caption={`Your period summary for ${vm.title}`}>
          <thead>
            <tr>
              <ColumnHeader
                label='Product'
                $width='300px'
              />

              <ColumnHeader
                label={`${vm.kind} (T) month 1`}
                helpId={`help_${vm.kind}_month_1`}
                Help={`${vm.kind} (T) volume previously provided in your month 1 POFR data submission for the quarter.`}
                $width='150px'
              />

              <ColumnHeader
                label={`${vm.kind} (T) month 2`}
                helpId={`help_${vm.kind}_month_2`}
                Help={`${vm.kind} (T) volume previously provided in your month 2 POFR data submission for the quarter.`}
                $width='150px'
              />

              <ColumnHeader
                label={`${vm.kind} (T) month 3`}
                helpId={`help_${vm.kind}_month_3`}
                Help={`${vm.kind} (T) volume previously provided in your month 3 POFR data submission for the quarter.`}
                $width='150px'
              />

              <ColumnHeader
                label={`${vm.kind} (T)`}
                helpId={`help_${vm.kind}_calc_t`}
                Help={
                  <BoxedDiv box={{flex: 'column'}}>
                    <p>This is a calculated field.</p>
                    <p>{vm.kind} (T) = {vm.kind.toLowerCase()} (T) (month 1 + month 2 + month 3).</p>
                  </BoxedDiv>
                }
                $width='150px'
              />

              <ColumnHeader
                label={`${vm.kind} (KL)`}
                helpId={`help_${vm.kind}_calc_kl`}
                Help={
                  <BoxedDiv box={{flex: 'column'}}>
                    <p>This is a calculated field.</p>
                    <p>{vm.kind} (KL) = month 1 ({vm.kind.toLowerCase()} (T) / ave density) + month 2 ({vm.kind.toLowerCase()} (T) / ave density) + month 3 ({vm.kind.toLowerCase()} (T) / ave density).</p>
                  </BoxedDiv>
                }
                $width='150px'
              />
            </tr>
          </thead>

          <tbody>
            {
              vm.items.map((item, index) => (
                <Row
                  key={index}
                  item={item}
                />
              ))
            }
          </tbody>
        </Table>
      </BoxedDiv>

    </BoxedDiv>
  );
}

function useVM({kind, items, title}: PeriodSummaryTableProps) {
  return {
    items,
    kind,
    title,
  }
}

interface RowProps {
  item: PeriodSummaryRowConfig;
}

const Row = ({item}: RowProps) => {
  return (
    <tr>
      <TH scope='row'>{item.product.refineryProductName}</TH>
      <TD scope='row'>{blankOrFormattedNumber(item.month1)}</TD>
      <TD scope='row'>{blankOrFormattedNumber(item.month2)}</TD>
      <TD scope='row'>{blankOrFormattedNumber(item.month3)}</TD>
      <TD scope='row'>{blankOrFormattedNumber(item.totalTonnes)}</TD>
      <TD scope='row'>{blankOrFormattedNumber(item.totalKilolitres, 3)}</TD>
    </tr>
  )
}

function blankOrFormattedNumber(val: number | null | undefined, decimals?: number) {
  if (val == null) {
    return '0';
  }

  return localeNumberWithFixed(val, decimals);
}

export default PeriodSummaryTable;
