import Checkbox from "psims/react/components/checkbox";
import { BoxedDiv } from "psims/react/components/layout";
import { UseFilters } from "./use-filters";

interface FiltersProps {
    filtersCtrl: UseFilters;
}

const Filters = (props: FiltersProps) => {
    const vm = useVM(props);

    return (
        <div>
            <BoxedDiv box={{flex: 'column'}}>
                <BoxedDiv box={{flex: 'row'}}>
                    <Checkbox
                        label="Show expired minimum stockholding obligation settings"
                        name="showexpired"
                        onChange={vm.filtersCtrl.toggleShowExpired}
                        value={vm.filtersCtrl.showExpired}
                    />
                </BoxedDiv>
            </BoxedDiv>
        </div>
    );
}

function useVM({filtersCtrl}: FiltersProps) {
    return { 
        filtersCtrl,
    };
}

export default Filters;
