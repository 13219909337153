/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MsoImporterSubmissionVMApiResponse } from '../models/MsoImporterSubmissionVMApiResponse';
import type { UpdateDataSubmissionVM } from '../models/UpdateDataSubmissionVM';
import type { UpdateMsoImporterSubmissionVM } from '../models/UpdateMsoImporterSubmissionVM';
import { request as __request } from '../core/request';

export class MsoImporterService {

    /**
     * @returns MsoImporterSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async getMsoImporterSubmission({
id,
}: {
id?: number,
}): Promise<MsoImporterSubmissionVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/mso-importer`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns MsoImporterSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateMsoImporter({
requestBody,
}: {
requestBody?: UpdateMsoImporterSubmissionVM,
}): Promise<MsoImporterSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-importer/Update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoImporterSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateMsoImporterDataSubmission({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoImporterSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-importer/Update-DataSubmission`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoImporterSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async submitMsoImporter({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoImporterSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-importer/Submit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoImporterSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async clearAllMsoImporter({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoImporterSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-importer/Clear-All`,
            body: requestBody,
        });
        return result.body;
    }

}