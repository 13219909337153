import { MinimumStockObligationSetting } from "psims/models/minimum-stock-obligation-setting";
import { UseFilters } from "./use-filters";

interface UseResultsProps {
    data: Array<MinimumStockObligationSetting> | null;
    filterCtrl: UseFilters;
}

function useResults({data, filterCtrl}: UseResultsProps) {
    const results = (data || [])
        .filter(item => !filterCtrl.showExpired ? item.isActive : true)
        .sort((a, b) => {
            const aEffectiveTo = (new Date(a.effectiveTo || '')).getTime();
            const bEffectiveTo = (new Date(b.effectiveTo || '')).getTime();
            switch(true) {
                case a.reportingEntityName !== b.reportingEntityName:
                    return a.reportingEntityName > b.reportingEntityName ? 1 : -1;
                case aEffectiveTo !== bEffectiveTo:
                    return aEffectiveTo > bEffectiveTo ? -1 : 1;
                case a.msoClassName !== b.msoClassName:
                    return a.msoClassName > b.msoClassName ? 1 : -1;
                case a.msoProductName !== b.msoProductName:
                    return a.msoProductName > b.msoProductName ? 1 : -1;
                case a.minimumStockObligation !== b.minimumStockObligation:
                    return a.minimumStockObligation > b.minimumStockObligation ? 1 : -1;
                default:
                    return 0;
            }
        });
    
    return {
        results,
    };
}

export default useResults
