import { assertNotEmpty } from "psims/lib/empty";

export type FspRefineryProduct = {
  id: number;
  name: string;
  fsspProductId: number;
  fsspProductName: string;
  refineryProductId: number;
  refineryProductName: string;
  description?: string | null
}

export function isFspRefineryProduct(maybe?: unknown): maybe is FspRefineryProduct {
  const maybeAs = maybe as FspRefineryProduct;

  return (
    maybeAs != null &&
    maybeAs.id != null &&
    maybeAs.fsspProductId != null &&
    maybeAs.fsspProductName != null &&
    maybeAs.refineryProductId != null &&
    maybeAs.refineryProductName != null
  );
}

export function assertFspRefineryProduct(maybe?: unknown): asserts maybe is FspRefineryProduct {
    const asFspRefineryProduct = maybe as FspRefineryProduct;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asFspRefineryProduct, hint('fspRefineryProduct'));
    assertNotEmpty(asFspRefineryProduct.id, hint('fspRefineryProduct.id'));
    assertNotEmpty(asFspRefineryProduct.fsspProductId, hint('fspRefineryProduct.fsspProductId'));
    assertNotEmpty(asFspRefineryProduct.fsspProductName, hint('fspRefineryProduct.fsspProductName'));
    assertNotEmpty(asFspRefineryProduct.refineryProductId, hint('fspRefineryProduct.refineryProductId'));
    assertNotEmpty(asFspRefineryProduct.refineryProductName, hint('fspRefineryProduct.refineryProductName'));
}
