const PLT_REF_CODES = [
    'PRO-PP-NG-NG-MR_NATGAS',
    'PRO-PP-NGL-NGL-MR_NGL',
    'PRO-PP-PP-PP-MR_LPG_PROPANE_PLT',
    'PRO-PP-PB-PB-MR_LPG_BUTANE_PLT',
    'PRO-PP-PLM-PLM-MR_LPG_MIX_PLT',
] as const;

type PltReferenceCode = typeof PLT_REF_CODES[number];

type PltProductionRefCodeName = 'ng' | 'prop' | 'but' | 'lpg' | 'ngl';

const PLT_PRODUCTION_REF_CODE_MAP: {[key in PltProductionRefCodeName]: PltReferenceCode} = {
    ng: 'PRO-PP-NG-NG-MR_NATGAS',
    prop: 'PRO-PP-PP-PP-MR_LPG_PROPANE_PLT',
    but: 'PRO-PP-PB-PB-MR_LPG_BUTANE_PLT',
    lpg: 'PRO-PP-PLM-PLM-MR_LPG_MIX_PLT',
    ngl: 'PRO-PP-NGL-NGL-MR_NGL',
};

export function getPlantProductionReferenceCode(key: PltProductionRefCodeName) {
    return PLT_PRODUCTION_REF_CODE_MAP[key];
}
