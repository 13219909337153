import { DataSubmissionTypeName, PopulatedDataSubmission } from "psims/models/data-submission";
import { getMonthNameFull, humanDate } from "./datetime";

export function submissionMonthLabel(month: number) {
    // Month number from XAPI is 1-indexed
    return getMonthNameFull(month - 1);
}

export function submissionPeriodLabel(dataSubmission: PopulatedDataSubmission<DataSubmissionTypeName>) {
    // drop year from periodFrom if same year as periodTo
    let periodFrom = humanDate(dataSubmission.reportingPeriodFrom);

    if (
        (new Date(dataSubmission.reportingPeriodFrom)).getFullYear() ===
        (new Date(dataSubmission.reportingPeriodTo)).getFullYear()
    ) {
        periodFrom = periodFrom.substring(-5, periodFrom.length - 5);
    }

    return `${periodFrom} - ${humanDate(dataSubmission.reportingPeriodTo)}`;
}
