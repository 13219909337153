/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MsoAnnualActivityRefinerSubmissionVMApiResponse } from '../models/MsoAnnualActivityRefinerSubmissionVMApiResponse';
import type { UpdateDataSubmissionVM } from '../models/UpdateDataSubmissionVM';
import type { UpdateMsoAnnualActivityRefinerSubmissionVM } from '../models/UpdateMsoAnnualActivityRefinerSubmissionVM';
import { request as __request } from '../core/request';

export class MsoAnnualActivityRefinerService {

    /**
     * @returns MsoAnnualActivityRefinerSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async getMsoAnnualActivityRefinerSubmission({
id,
}: {
id?: number,
}): Promise<MsoAnnualActivityRefinerSubmissionVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/mso-annual-activity-refiner`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns MsoAnnualActivityRefinerSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateMsoAnnualActivityRefiner({
requestBody,
}: {
requestBody?: UpdateMsoAnnualActivityRefinerSubmissionVM,
}): Promise<MsoAnnualActivityRefinerSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-annual-activity-refiner/Update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoAnnualActivityRefinerSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateMsoAnnualActivityRefinerDataSubmission({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoAnnualActivityRefinerSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-annual-activity-refiner/Update-DataSubmission`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoAnnualActivityRefinerSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async submitMsoAnnualActivityRefiner({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoAnnualActivityRefinerSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-annual-activity-refiner/Submit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns MsoAnnualActivityRefinerSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async clearAllMsoAnnualActivityRefiner({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<MsoAnnualActivityRefinerSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/mso-annual-activity-refiner/Clear-All`,
            body: requestBody,
        });
        return result.body;
    }

}