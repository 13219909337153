export interface SizeProps {
    height?: string;
    maxWidth?: string;
    minHeight?: string;
    minWidth?: string;
    overflow?: 'auto' | 'hidden' | 'scroll';
    overflowX?: 'auto' | 'hidden' | 'scroll';
    width?: string
}

export const sizeStyle = ({height, maxWidth = '100%', minHeight, minWidth, overflow, overflowX, width}: SizeProps) => ({
    height,
    maxWidth,
    minHeight,
    minWidth,
    overflow,
    overflowX,
    ...(overflowX === 'hidden' ? {
            overflowX,
            // Provide some extra room around content to avoid clipping and out-of-flow
            // rendering (such as box-shadow), without impacting spacing (hence the
            // negative margin offset).
            margin: '-24px',
            padding: '24px',
            maxWidth: 'unset',
        }: {}),
    width,
});
