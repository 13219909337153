import { BoxedDiv } from "psims/react/components/layout";
import Text from "psims/react/components/text";
import { useCallback } from "react";
import useConfirm from "./use-confirm";

type ClearDataRequest = {
    concurrencyToken?: string | null | undefined;
    id: number | undefined;
}

interface UseClearDataProps {
    onConfirm: (request: ClearDataRequest) => any;
    submission: ClearDataRequest | null | undefined;
}

function useClearData({onConfirm, submission}: UseClearDataProps) {
    const confirmCtlr = useConfirm();

    const handleClearData = useCallback(() => {
        confirmCtlr.requestConfirmation(
            <BoxedDiv box={{flex: 'column'}}>
                <Text>If you proceed, all the records for this data submission will be permanently deleted and you will have to re-enter the data.</Text>
                <BoxedDiv box={{marginTop: 4}}>
                    <Text>Would you like to proceed with the deletion?</Text>
                </BoxedDiv>
            </BoxedDiv> ,
            'Are you sure?',
            () => submission != null && onConfirm(submission)
        );
    }, [confirmCtlr, onConfirm, submission]);

    return {
        handleClearData,
        confirmCtlr,
    };
}

export default useClearData;
