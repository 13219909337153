import { createContext, PropsWithChildren, useContext } from "react";

import { useAPI } from ".";
import { isOrganisationProductionArea, OrganisationProductionArea } from "psims/models/ref-data/organisation-production-area";
import usePromise from "psims/react/util/use-promise";
import { buildAssertArrayOf } from "psims/lib/type-assertions";

type OrganisationProductionAreasContext = {
    organisationProductionAreas: Array<OrganisationProductionArea> | null;
}

const context = createContext<OrganisationProductionAreasContext | null>(null);

const OrganisationProductionAreasProvider = ({children}: PropsWithChildren<{}>) => {
    const {api} = useAPI();
    const {data: organisationProductionAreas} = usePromise(
        () => api.getOrganisationProductionAreasForUser(), x => x, buildAssertArrayOf(isOrganisationProductionArea)
    );

    return <context.Provider value={{organisationProductionAreas}}>
        {children}
    </context.Provider>
}

export default OrganisationProductionAreasProvider;

export function useOrganisationProductionAreas() {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error('useOrganisationProductionAreas must be used within OrganisationProductionAreasProvider');
    }

    return {
        ...ctx,
    };
}
