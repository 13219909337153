import { useCallback, useEffect, useMemo, useState } from "react";

import { SubmissionType } from "psims/models/ref-data/submission-type";
import { isSubmissionPortalData, SubmissionPortalData } from "psims/models/submission-portal-data";
import { useAPI } from "psims/react/providers/api";
import { useLogger } from "psims/react/providers/logging";

interface UsePortalDataAPIProps {
  submissionType: SubmissionType;
}

type PortalDataView = {
  status: 'init' | 'fetching' | 'error';
} | {
  status: 'updating' | 'ready';
  data: SubmissionPortalData;
}


function usePortalDataAPI({submissionType}: UsePortalDataAPIProps) {
  const {api} = useAPI();
  const logger = useLogger({source: 'usePortalDataAPI'});
  const [portalDataView, setPortalDataView] = useState<PortalDataView>({status: 'init'});

  const fetch = useCallback(() => {
    setPortalDataView({status: 'fetching'});
    api.getPortalDataForSubmissionTypeId({submissionTypeId: submissionType.id})
      .then(response => {
        const result = response;
        if (isSubmissionPortalData(result)){
          setPortalDataView({
            status: 'ready',
            data: result,
          });
        }
      })
      .catch(e => {
        const logException = logger.exception;
        setPortalDataView({status: 'error'});
        logException(e);
      })

  }, [api, submissionType.id, logger.exception])

  useEffect(() => {
    fetch();
  }, [fetch]);

  const update = useMemo(() => api.updatePortalData, [api.updatePortalData]);

  const portalData = useMemo(() => {
    if (portalDataView.status === 'ready' || portalDataView.status === 'updating') {
      return portalDataView.data;
    }

    return null;
  }, [portalDataView]);

  return {
    fetch,
    portalData,
    portalDataView,
    update,
  };
}

export default usePortalDataAPI

export type UsePortalDataAPI = ReturnType<typeof usePortalDataAPI>;
