import { useEffect, useMemo, useState } from "react";

type PageSizeOptions<V extends number> = Array<{
    label?: string;
    value: V;
}>;

interface UsePaginationProps<T, V extends number> {
    initialPageSize: V;
    items: Array<T>;
    pageSizeOptions: PageSizeOptions<V>;
    resultsLabel: string;
}

function usePagination<T, V extends number>({items, initialPageSize, pageSizeOptions, resultsLabel}: UsePaginationProps<T, V>) {
    const [currentPage, setCurrentPage] = useState(1);

    const pageSizeOptionsWithLabel = useMemo(() => [
        ...pageSizeOptions.map(o => ({
            label: o.label || `${o.value}`,
            value: o.value,
        })),
    ], [pageSizeOptions]);

    const [pageSize, setPageSize] = useState((pageSizeOptionsWithLabel.find(o => o.value === initialPageSize) || pageSizeOptionsWithLabel[0]).value);

    const pageData = useMemo(() => {
        if (pageSize == null) {
            return [];
        }
        const start = (currentPage - 1) * pageSize;
        const end = pageSize * currentPage;
        return items.slice(start, end);
    }, [currentPage, items, pageSize]);

    const pageCount = pageSize == null || pageSize === Infinity ? 1 : Math.ceil(items.length / pageSize);

    const firstPage = currentPage === 1 ?
        null :
        () => setCurrentPage(1);

    const lastPage = currentPage === pageCount ?
        null :
        () => setCurrentPage(pageCount);

    const nextPage = currentPage === pageCount ?
        null :
        () => setCurrentPage(prev => prev + 1);

    const previousPage = currentPage === 1 ?
        null :
        () => setCurrentPage(prev => prev - 1);

    const pageSelectors = useMemo(() => {
        if (pageCount === 1) {
            return null;
        }

        let ps: Array<{
            isActive: boolean;
            label: string;
            onClick: () => any;
        }> = [];
        
        for (let i = 1; i <= pageCount; i++) {
            ps.push({
                isActive: i === currentPage,
                label: `${i}`,
                onClick: () => setCurrentPage(i),
            });
        }

        return ps;
    }, [currentPage, pageCount]);

    function handlePageSizeChange(size: typeof pageSizeOptionsWithLabel[number]['value']) {
        setPageSize(size);
    }

    useEffect(() => {
        setCurrentPage(pageCount === 0 ? 0 : 1);
    }, [pageCount]);
    
    return {
        currentPage,
        pageCount,
        pageData,
        pageSelectors,
        pageSize,
        pageSizeOptions: pageSizeOptionsWithLabel,
        resultsLabel,
        firstPage,
        handlePageSizeChange,
        lastPage,
        nextPage,
        previousPage,
    };
}

export default usePagination

export type UsePagination = ReturnType<typeof usePagination>;
