import { useCallback, useEffect, useMemo, useState } from "react";
import { buildUpdateDataSubmissionRequest } from './util';
import { useNavigation } from "psims/react/providers/router";
import { asNumber } from "psims/lib/number";
import { INACTIVE_PRODUCTIONAREA_ERROR, INVALID_DECLARATION } from "psims/constants/validation-messages";
import { UseProductionForm } from "../productions-shared/use-production-form";
import { UseProductionRefData } from "../productions-shared/use-production-ref-data";
import { UseProductionAPI } from "./use-production-api";
import { UseProductionProgress } from "./use-production-progress";
import { isEmpty } from "psims/lib/empty";
import { attemptFocusOnSelector } from "../shared/view-utils";
import { SELECTOR_NOTIFICATION_KIND } from "psims/constants/selectors";

interface UseProductionSubmitProps {
    apiCtrl: UseProductionAPI;
    dataPageName: string;
    formCtrl: UseProductionForm;
    progressCtrl: UseProductionProgress;
    refData: UseProductionRefData;
}

function useProductionSubmit({apiCtrl, dataPageName, formCtrl, progressCtrl}: UseProductionSubmitProps) {
    const nav = useNavigation();
    const [declarationError, setDeclarationError] = useState<string | null>(null);
    const [shownUnsavedPages, setShownUnsavedPages] = useState<Array<{label: string, index: number}>>([]);

    const unsavedPages = useMemo(() => {
        if (apiCtrl.submission == null) {
            return [];
        }

        const {pageData} = apiCtrl.submission;

        if (pageData.pageSaved) {
            return [];
        }

        return [{
            index: 0,
            label: dataPageName,
        }];
    }, [apiCtrl.submission, dataPageName]);

    const inactiveProductionAreaGroups = useMemo(() => {
        if (formCtrl.view == null) {
            return [];
        }

        return formCtrl.view.dataPageView.groups.map(group => {
            const hasInactiveOPA = group.products
                .flat()
                .map(p => p.productions)
                .flat()
                .filter(x => x.validationErrors.length > 0 && x.validationErrors.find(x => x.tooltip.content === INACTIVE_PRODUCTIONAREA_ERROR) != null).length > 0;
            return {
                label: group.productGroupRefData.refData.name,
                index: 0,
                hasInactiveOPA,
            };
        })
        .filter(p => p.hasInactiveOPA)
        .map(p => {
            const {hasInactiveOPA, ...rest} = p;
            return rest;
        });
    }, [formCtrl.view]);

    const inactiveProductsWithData = useMemo(() => {
        return formCtrl.view.dataPageView.groups
            .map(g => g.products)
            .flat()
            .filter(p => p.productions.some(x => x.productStatus === 'expired_with_data'));
    }, [formCtrl.view]);

    const submit = useCallback(() => {
        if (!formCtrl.view.Submit.declaration) {
            setDeclarationError(INVALID_DECLARATION);
            return;
        }

        const isSubmitCommentRequired = apiCtrl.submission?.dataSubmission?.validationAlerts && apiCtrl.submission?.dataSubmission?.validationAlerts.filter(a => a.validationAlert === 'SameAsPrevious').length > 0 ? true : false;
        if (isSubmitCommentRequired && isEmpty(formCtrl.view.Submit.comments)) {
            return;
        }

        if (unsavedPages.length > 0 || inactiveProductionAreaGroups.length > 0) {
            setShownUnsavedPages(unsavedPages);
            return;
        }

        if (apiCtrl.isBusy || apiCtrl.submission == null) {
            return;
        }
        
        if (inactiveProductsWithData.length > 0) {
            attemptFocusOnSelector(SELECTOR_NOTIFICATION_KIND('validation'));
            return;
        }

        const request = buildUpdateDataSubmissionRequest(formCtrl, apiCtrl.submission)
        apiCtrl.submit(request);
    }, [apiCtrl, formCtrl, unsavedPages, inactiveProductionAreaGroups, inactiveProductsWithData]);

    useEffect(() => {
        // Clear declaration and unsaved errors on page entry
        if (progressCtrl.currentStep.kind === 'submit') {
            setDeclarationError(null);
            setShownUnsavedPages([]);
        }
    }, [progressCtrl.currentStep]);

    useEffect(() => {
        if (formCtrl.view.Submit.declaration) {
            // Clear declaration error when user has agreed to terms
            setDeclarationError(null);
        }
    }, [formCtrl.view.Submit.declaration]);
    
    useEffect(() => {
        if (apiCtrl.loadStatus === 'submitted') {
            // Set timeout gives the navigation confirmation a frame to unblock
            setTimeout(() => {
                nav.goToReportSubmittedPage(asNumber(apiCtrl.submission?.dataSubmission.id));
            }, 0);
        }
    }, [apiCtrl.loadStatus, apiCtrl.submission, nav]);

    return {
        declarationError,
        inactiveProductsWithData,
        submit,
        unsavedPages: shownUnsavedPages,
        inactiveProductionAreaGroups,
    };
}

export default useProductionSubmit

export type UseProductionSubmit = ReturnType<typeof useProductionSubmit>;
