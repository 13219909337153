import { asString } from "psims/lib/string";
import { UpdateOrganisationContact } from "psims/models/organisation-contact";
import {InputNew as Input} from "psims/react/components/input";
import { BoxedDiv } from "psims/react/components/layout";
import Select from "psims/react/components/select";
import { UseEditCompanyContact } from "./use-edit-company-contact";

interface EditCompanyContactProps {
    contact: UpdateOrganisationContact | null | undefined;
    ctrl: UseEditCompanyContact;
    saveAttempted: boolean;
}

const EditCompanyContact = (props: EditCompanyContactProps) => {

    const vm = useVm(props);

    return (
        <div>
            <BoxedDiv box={{flex: 'row'}}>
                <Input
                    id={'firstname' + vm.organisationContactUpdate?.contactType}
                    error={vm.validationMessages.firstName}
                    label='First name'
                    flexGrow={1}
                    onChange={e => vm.updateOrganisationContact('firstName', e.target.value)}
                    value={asString(vm.organisationContactUpdate?.firstName)}
                    forceError={vm.saveAttempted}
                    />

                <Input
                    id={'surname'+ vm.organisationContactUpdate?.contactType}
                    error={vm.validationMessages.lastName}
                    label='Surname'
                    flexGrow={1}
                    marginLeft={4}
                    onChange={e => vm.updateOrganisationContact('lastName', e.target.value)}
                    value={asString(vm.organisationContactUpdate?.lastName)}
                    forceError={vm.saveAttempted}
                    />
            </BoxedDiv>

            <BoxedDiv box={{flex: 'row'}}>
                <BoxedDiv box={{flex: 'column', flexGrow: 1}}>
                    <Input
                        id={'position'+ vm.organisationContactUpdate?.contactType}
                        error={vm.validationMessages.position}
                        label='Position'
                        onChange={e => vm.updateOrganisationContact('position', e.target.value)}
                        value={asString(vm.organisationContactUpdate?.position)}
                        forceError={vm.saveAttempted}
                    />
                </BoxedDiv>
            </BoxedDiv>

            <BoxedDiv box={{flex: 'row'}}>
                <BoxedDiv box={{flex: 'column', flexGrow: 1}}>
                    <Input
                        id={'phone'+ vm.organisationContactUpdate?.contactType}
                        error={vm.validationMessages.phoneNumber}
                        label='Phone number (Optional)'
                        onChange={e => vm.updateOrganisationContact('phoneNumber', e.target.value)}
                        value={asString(vm.organisationContactUpdate?.phoneNumber)}
                        forceError={vm.saveAttempted}
                    />
                </BoxedDiv>
            </BoxedDiv>

            <BoxedDiv box={{flex: 'row'}}>
                <BoxedDiv box={{flex: 'column', flexGrow: 1}}>
                    <Input
                        id={'email'+ vm.organisationContactUpdate?.contactType}
                        error={vm.validationMessages.email}
                        label='Email'
                        onChange={e => vm.updateOrganisationContact('email', e.target.value)}
                        value={asString(vm.organisationContactUpdate?.email)}
                        forceError={vm.saveAttempted}
                        type='email'
                    />
                </BoxedDiv>
            </BoxedDiv>

            <BoxedDiv box={{flex: 'row'}}>
                <BoxedDiv box={{flex: 'column', flexGrow: 1}}>
                    <Input
                        id={'addressLine1'+ vm.organisationContactUpdate?.contactType}
                        error={vm.validationMessages.addressLine1}
                        label='Address line 1'
                        onChange={e => vm.updateOrganisationContact('addressLine1', e.target.value)}
                        value={asString(vm.organisationContactUpdate?.addressLine1)}
                        forceError={vm.saveAttempted}
                    />
                </BoxedDiv>
            </BoxedDiv>

            {
                vm.organisationContactUpdate?.addressLine1 &&
                <BoxedDiv box={{flex: 'row'}}>
                    <BoxedDiv box={{flex: 'column', flexGrow: 1}}>
                        <Input
                            id={'addressLine2'+ vm.organisationContactUpdate?.contactType}
                            error={vm.validationMessages.addressLine2}
                            label='Address line 2 (Optional)'
                            onChange={e => vm.updateOrganisationContact('addressLine2', e.target.value)}
                            value={vm.organisationContactUpdate?.addressLine1 ? asString(vm.organisationContactUpdate?.addressLine2) : ''}
                            forceError={vm.saveAttempted}
                        />
                    </BoxedDiv>
                </BoxedDiv>
            }                    

            <BoxedDiv box={{flex: 'column'}}>
                <BoxedDiv box={{flex: 'row'}}>
                    <Input
                        id={'city'+ vm.organisationContactUpdate?.contactType}
                        error={vm.validationMessages.city}
                        label='Suburb'
                        flexGrow={3}
                        marginRight={3}
                        onChange={e => vm.updateOrganisationContact('city', e.target.value)}
                        value={asString(vm.organisationContactUpdate?.city)}
                        forceError={vm.saveAttempted}
                    />
                    <Select
                        error={vm.validationMessages.stateId}
                        id={'state'+ vm.organisationContactUpdate?.contactType}
                        label='State'
                        flexGrow={2}
                        marginLeft={3}
                        marginRight={3}
                        placeholder='Select'
                        options={vm.selectStateCtrl.options}
                        onChange={vm.selectStateCtrl.onChange}
                        value={vm.selectStateCtrl.value}
                        forceError={vm.saveAttempted}
                    />
                    <Input
                        id={'postcode'+ vm.organisationContactUpdate?.contactType}
                        error={vm.validationMessages.postcode}
                        label='Postcode'
                        marginLeft={3}
                        flexGrow={1}
                        onChange={e => vm.updateOrganisationContact('postcode', e.target.value)}
                        value={asString(vm.organisationContactUpdate?.postcode)}
                        forceError={vm.saveAttempted}
                    />
                </BoxedDiv>
            </BoxedDiv>
        </div>
    );
}

function useVm(
    {
        ctrl: {
            organisationContactUpdate,
            selectStateCtrl,
            validationMessages,
            updateOrganisationContact,
        },
        saveAttempted,
    }: EditCompanyContactProps
){
    return {
        organisationContactUpdate,
        selectStateCtrl,
        validationMessages,
        updateOrganisationContact,
        saveAttempted,
    };
}
export default EditCompanyContact;