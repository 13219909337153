import produce from "immer";
import { isEmpty } from "psims/lib/empty";
import { recordActionAsEnum } from "psims/models/api/data-submission-record-action";
import { MsoStockholdingImport } from "psims/models/submission-types/mso/annual-activity/importer/mso-stockholding-import";
import { UpdateMsoStockholdingImportDraft, UpdateMsoStockholdingImportField } from "psims/models/submission-types/mso/annual-activity/importer/update-mso-stockholding-import";
import { UseFocusedField } from "psims/react/util/use-focused-field";
import { useCallback, useEffect, useMemo, useState } from "react";
import { cleanSubmissionEntityList } from "../../../shared/update-data-utils";
import useTrackChanges from "../../../shared/use-track-changes";
import { FieldValidation, FocusField } from "../../shared/types";
import { UseMsoAnnualActivityRefData } from "../../shared/use-mso-annual-activity-ref-data";
import { getInlineMessage } from "./importer-imports-inline-messages";
import { getNotificationMessage } from "./importer-imports-notifications";
import { validateImport, ValidationResult } from "./importer-imports-validation";

interface ProgressController {
  currentStep: {
    kind: 'data' | 'submit';
  };
  goToStep: (index: number) => any;
}

interface UseImporterImportsProps {
  dataSubmissionId: number;
  focusFieldCtrl: UseFocusedField<FocusField>;
  initialData: Array<MsoStockholdingImport>;
  progressCtrl: ProgressController;
  refData: UseMsoAnnualActivityRefData;
}

function useImporterImports({dataSubmissionId, focusFieldCtrl, initialData, progressCtrl, refData}: UseImporterImportsProps) {
  const initialDataAsUpdate = useMemo(() => {
    return initialData
      .filter(d => d.recordResult?.rowResult !== 'Deleted')
      .map(toUpdateMsoStockholdingImportDraft)
  }, [initialData]);

  const [data, setData] = useState(initialDataAsUpdate);

  const changeCtrl = useTrackChanges({
    currentData: data,
    dataFields: ['amountImported', 'entityName', 'isOnBehalf'],
    initialData: initialDataAsUpdate,
  });

  const addImport = useCallback((msoProductId: number, isOnBehalf: boolean) => {
    setData(prev => produce(prev, draft => {
      draft.push({
        _id: Symbol(),
        dataSubmissionId,
        isOnBehalf,
        msoProductId,
        recordAction: recordActionAsEnum('Create'),
      });
    }));
  }, [dataSubmissionId]);

  const deleteImport = useCallback((imp: UpdateMsoStockholdingImportDraft) => {
    setData(prev => produce(prev, draft => {
      const idx = data.findIndex(s => s._id === imp._id);
      // When deleting a site with an id, we just update the recordAction.
      if (draft[idx].id != null) {
        draft[idx] = {
          ...imp,
          recordAction: recordActionAsEnum('Delete'),
        }

        return;
      }

      // When deleting a site without an id, i.e. unpersisted, remove from list completely
      draft.splice(idx, 1);
    }));
  }, [data]);

  const updateImport = useCallback((imp: UpdateMsoStockholdingImportDraft) => {
    setData(prev => produce(prev, draft => {
      const idx = data.findIndex(s => s._id === imp._id);
      draft[idx] = {
        ...imp,
        recordAction: recordActionAsEnum(imp.id == null ? 'Create' : 'Update'),
      }
    }));
  }, [data]);

  const deleteAllImportsForProduct = useCallback((msoProductId: number) => {
    setData(prev => {
      return prev
        .filter(d => d.id != null || d.msoProductId !== msoProductId)
        .map(d => {
          if (d.msoProductId !== msoProductId) {
            return d;
          }

          return {
            ...d,
            recordAction: recordActionAsEnum('Delete'),
          };
        })
    })
  }, []);

  const dataView = useMemo(() => {
    // Keep track of inactive errors by type so we only show 1
    let haveRecordedInactiveError = false;
    return data
      .map(a => ({
        data: a,
        validations: validateImport(a, refData),
      }))
      .map(s => ({
        data: s.data,
        validations: s.validations.reduce((map, validationResult) => {
          if (haveRecordedInactiveError && validationResult.key === 'delete') {
            return map;
          }

          const next = {
            ...map,
            [validationResult.key]: {
              inline: getInlineMessage({...validationResult}),
              notification: {
                parts: getNotificationMessage(
                  validationResult.key,
                  () => {
                    const {code} = validationResult;
                    if (code === 'inactive_product') {
                      if (progressCtrl.currentStep.kind === 'submit') {
                        progressCtrl.goToStep(0);
                      }
                      focusFieldCtrl.setFocusedField({field: 'delete', kind: 'importerImports', productId: s.data.msoProductId})
                    } else {
                      focusFieldCtrl.setFocusedField({_id: s.data._id, field: validationResult.key, kind: 'data'})
                    }
                  },
                  refData.productIdMap[s.data.msoProductId].name,
                  validationResult
                )
              },
              validationResult,
            },
          }

          if (validationResult.key === 'delete') {
            haveRecordedInactiveError = true;
          }

          return next;
        }, {} as {[key in UpdateMsoStockholdingImportField | 'delete']?: FieldValidation<ValidationResult>})
      }))
  }, [data, focusFieldCtrl, progressCtrl, refData]);

  const requestEntities = useMemo(() => cleanSubmissionEntityList(data), [data]);

  const anyInvalid = useMemo(
    () => dataView.some(v => !isEmpty(v.validations)),
    [dataView]
  );

  useEffect(() => {
    setData(initialDataAsUpdate)
  }, [initialDataAsUpdate]);

  // Reset on page change
  useEffect(() => {
    if (progressCtrl.currentStep.kind !== 'data') {
      setData(initialDataAsUpdate);
    }
  }, [initialDataAsUpdate, progressCtrl]);

  return {
    anyInvalid,
    hasChanges: changeCtrl.hasChanges,
    requestEntities,
    updateImports: dataView,
    addImport,
    deleteAllImportsForProduct,
    deleteImport,
    updateImport,
  }
}

function toUpdateMsoStockholdingImportDraft(importData: MsoStockholdingImport): UpdateMsoStockholdingImportDraft {
  let {recordResult, ...data} = importData;

  return {
    ...data as UpdateMsoStockholdingImportDraft,
    recordAction: undefined,
    _id: Symbol(),
  };
}

export default useImporterImports

export type UseImporterImports = ReturnType<typeof useImporterImports>;
