import produce from "immer";
import { isEmpty } from "psims/lib/empty";
import { recordActionAsEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { MsoAnnualActivityImporter } from "psims/models/submission-types/mso/annual-activity/importer/mso-annual-activity-importer";
import { UpdateMsoAnnualActivityImporter, UpdateMsoAnnualActivityImporterField } from "psims/models/submission-types/mso/annual-activity/importer/update-mso-annual-activity-importer";
import { UpdateMsoStockholdingImportDraft } from "psims/models/submission-types/mso/annual-activity/importer/update-mso-stockholding-import";
import { UseFocusedField } from "psims/react/util/use-focused-field";
import { useCallback, useEffect, useMemo, useState } from "react";
import { cleanSubmissionEntityList } from "../../../shared/update-data-utils";
import useTrackChanges from "../../../shared/use-track-changes";
import { FieldAlert, FieldValidation, FocusField } from "../../shared/types";
import { UseMsoAnnualActivityRefData } from "../../shared/use-mso-annual-activity-ref-data";
import { getInlineInfoMessage, getInlineValidationMessage } from "./importer-annual-activities-inline-messages";
import { getInfoNotificationMessage, getValidationNotificationMessage } from "./importer-annual-activities-notifications";
import { validateImporterAnnualActivity, ValidationResult } from "./importer-annual-activities-validation";
import { ValidationAlertResult, validationAlertsForImporterAnnualActivity } from "./importer-annual-activities-validation-alerts";

interface ProgressController {
  currentStep: {
    kind: 'data' | 'submit';
  };
  goToStep: (index: number) => any;
}

interface UseImporterAnnualActivitiesProps {
  dataSubmissionId: number;
  focusFieldCtrl: UseFocusedField<FocusField>;
  imports: Array<UpdateMsoStockholdingImportDraft>;
  initialAnnualActivities: Array<MsoAnnualActivityImporter>;
  progressCtrl: ProgressController;
  refData: UseMsoAnnualActivityRefData;
}

type UpdateMsoAnnualActivityImporterDraft = Partial<UpdateMsoAnnualActivityImporter> & {
  msoProductId: number;
  _id: symbol
};

function useImporterAnnualActivities({
  dataSubmissionId,
  focusFieldCtrl,
  imports,
  initialAnnualActivities,
  progressCtrl,
  refData
}: UseImporterAnnualActivitiesProps) {
  const initialAnnualActivitiesAsUpdate = useMemo(() => {
    return refData.products.map(({id}) => {
      const annualActivity = initialAnnualActivities.find(aa => aa.msoProductId === id);
      return toUpdateMsoAnnualActivityImporterDraft(dataSubmissionId, id, annualActivity);
    })
  }, [dataSubmissionId, initialAnnualActivities, refData.products]);

  const [data, setData] = useState(initialAnnualActivitiesAsUpdate);

  const changeCtrl = useTrackChanges({
    currentData: data,
    dataFields: ['enforceableArrangements', 'totalStorageCapacity', 'totalImportVolumeSelf'],
    initialData: initialAnnualActivitiesAsUpdate,
  });

  const updateAnnualActivity = useCallback((annualActivity: UpdateMsoAnnualActivityImporterDraft) => {
    setData(prev => produce(prev, draft => {
      const idx = data.findIndex(s => s._id === annualActivity._id);
      draft[idx] = {
        ...annualActivity,
        recordAction: recordActionAsEnum(annualActivity.id == null ? 'Create' : 'Update'),
      }
    }));
  }, [data]);

  const deleteAnnualActivityByProductId = useCallback((productId: number) => {
    setData(prev => {
      return prev
        .filter(d => d.msoProductId !== productId || d.id != null)
        .map(d => {
          if (d.msoProductId !== productId) {
            return d;
          }

          return {
            ...d,
            recordAction: recordActionAsEnum('Delete'),
          }
        })
    })
  }, []);

  const dataView = useMemo(() => {
    return data
      .filter(d => recordActionFromEnum(d.recordAction) !== 'Delete')
      .map(a => {
        const activeImports = imports.filter(i => recordActionFromEnum(i.recordAction) !== 'Delete');
        const totalImportsByOthers = calculateTotalImports(activeImports, a.msoProductId, false);
        const totalImportsOnBehalf = calculateTotalImports(activeImports, a.msoProductId, true);
        const totalAnnualVolume = calculateTotalAnnualVolume(a, totalImportsByOthers, totalImportsOnBehalf);
        const data = {
          ...a,
          totalAnnualVolume,
          totalImportVolumeOthers: totalImportsByOthers,
          totalImportVolumeOnBehalf: totalImportsOnBehalf,
        };
        return {
          data,
          totalAnnualVolume,
          validations: validateImporterAnnualActivity(data, refData.productIdMap),
          validationAlerts: validationAlertsForImporterAnnualActivity(data, totalAnnualVolume, activeImports),
        }
      })
      .map(s => ({
        data: s.data,
        validations: s.validations.reduce((map, validationResult) => ({
          ...map,
          [validationResult.key]: {
            inline: getInlineValidationMessage({...validationResult}),
            notification: {
              parts: getValidationNotificationMessage(
                validationResult.key,
                () => {
                  const {code} = validationResult;
                  if (code === 'inactive_product') {
                    if (progressCtrl.currentStep.kind === 'submit') {
                      progressCtrl.goToStep(0);
                    }
                    focusFieldCtrl.setFocusedField({
                      field: 'delete',
                      kind: 'annualActivities',
                      productId: s.data.msoProductId
                    })
                  } else {
                    focusFieldCtrl.setFocusedField({_id: s.data._id, field: validationResult.key, kind: 'data'})
                  }
                },
                refData.productIdMap[s.data.msoProductId].name,
                validationResult
              )
            },
            validationResult
          }
        }), {} as {[key in UpdateMsoAnnualActivityImporterField | 'delete']?: FieldValidation<ValidationResult>}),
        validationAlerts: s.validationAlerts.reduce((map, validationAlert) => ({
          ...map,
          [validationAlert.key]: {
            inline: getInlineInfoMessage(validationAlert),
            notification: {
              parts: getInfoNotificationMessage(
                validationAlert.key,
                () => focusFieldCtrl.setFocusedField({_id: s.data._id, field: validationAlert.key === 'totalAnnualVolume' ? 'totalImportVolumeSelf' : validationAlert.key, kind: 'data'}),
                () => focusFieldCtrl.setFocusedField({kind: 'comments'}),
                refData.productIdMap[s.data.msoProductId].name,
                validationAlert
              )
            },
            validationAlert,
          }
        }), {} as {[key in (UpdateMsoAnnualActivityImporterField | 'totalAnnualVolume')]?: FieldAlert<ValidationAlertResult>}),
        totalAnnualVolume: s.totalAnnualVolume,
      }))
  }, [data, focusFieldCtrl, imports, progressCtrl, refData]);

  const requestEntities = useMemo(() => {
    // Add update record action if dependent imports have changed
    return cleanSubmissionEntityList(
      dataView
        .map(d => {
          const imprts = imports.filter(i => (
            i.msoProductId === d.data.msoProductId &&
            i.recordAction != null
          ));

          if (d.data.recordAction != null) {
            return d.data;
          }

          return imprts.length > 0 ? {
            ...d.data,
            recordAction: d.data.id == null ? recordActionAsEnum('Create') : recordActionAsEnum('Update'),
          } : d.data;
        })
    )
  }, [dataView, imports]);

  const requiresComments = useMemo(() => {
    return dataView
      .filter(d => d.validationAlerts.totalImportVolumeOnBehalf != null || d.validationAlerts.totalImportVolumeOthers != null)
      .length > 0
  }, [dataView]);

  const anyDeleted = useMemo(
    () => data.some(d => recordActionFromEnum(d.recordAction) === 'Delete'), 
    [data]
  );

  const anyInvalid = useMemo(
    () => dataView.some(v => !isEmpty(v.validations)),
    [dataView]
  );

  useEffect(() => {
    setData(initialAnnualActivitiesAsUpdate)
  }, [initialAnnualActivitiesAsUpdate]);

  // Reset on page change
  useEffect(() => {
    if (progressCtrl.currentStep.kind !== 'data') {
      setData(initialAnnualActivitiesAsUpdate);
    }
  }, [initialAnnualActivitiesAsUpdate, progressCtrl]);

  return {
    anyInvalid,
    deleteAnnualActivityByProductId,
    hasChanges: changeCtrl.hasChanges || anyDeleted,
    requestEntities,
    requiresComments,
    updateAnnualActivities: dataView,
    updateAnnualActivity,
  }
}

function toUpdateMsoAnnualActivityImporterDraft(dataSubmissionId: number, msoProductId: number, annualActivity?: MsoAnnualActivityImporter): UpdateMsoAnnualActivityImporterDraft {
  let {recordResult, ...data} = (annualActivity || {});

  if (isEmpty(data)) {
    data = {
      dataSubmissionId,
      msoProductId,
      totalAnnualVolume: null,
      totalStorageCapacity: null,
      enforceableArrangements: false
    }
  }

  return {
    ...data as UpdateMsoAnnualActivityImporterDraft,
    enforceableArrangements: (data as UpdateMsoAnnualActivityImporterDraft).enforceableArrangements || false,
    recordAction: undefined,
    _id: Symbol(),
  };
}

function calculateTotalAnnualVolume(
  data: UpdateMsoAnnualActivityImporterDraft,
  totalImportsByOthers: number,
  totalImportsOnBehalf: number
): number {
  return (
    (data.totalImportVolumeSelf || 0) +
    (totalImportsByOthers || 0) - 
    (totalImportsOnBehalf || 0)
  );
}

function calculateTotalImports(data: Array<UpdateMsoStockholdingImportDraft>, msoProductId: number, isOnBehalf: boolean): number {
  return data
    .filter(imprt => imprt.msoProductId === msoProductId && Boolean(imprt.isOnBehalf) === isOnBehalf)
    .reduce((sum, imprt) => sum + (imprt.amountImported || 0), 0);
}

export default useImporterAnnualActivities

export type UseImporterAnnualActivities = ReturnType<typeof useImporterAnnualActivities>;