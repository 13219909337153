import { RecordActionEnum } from "psims/models/api/data-submission-record-action";
import { FspEligibleProduction } from "psims/models/submission-types/fsp/eligible-production";

export function toDraft(item: FspEligibleProduction) {
  const {concurrencyToken, dataSubmissionId, id, fsspProductId, volumeConfirmation} = item;

  return {
    _id: Symbol(),
    data: {
      concurrencyToken,
      dataSubmissionId,
      id,
      fsspProductId,
      volumeConfirmation,
      recordAction: null as RecordActionEnum | null,
    },
    ref: item,
  };
}

export type Draft = ReturnType<typeof toDraft>;

export type InlineMessage = {
  kind: 'alert' | 'info';
  message: string;
}
