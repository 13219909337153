import { MsoAnnualActivityRefinerVM } from "psims/gen/xapi-client";
import { prefixedThrower } from "psims/lib/exception";

export type MsoAnnualActivityRefiner = PopulatedProps<
  MsoAnnualActivityRefinerVM,
  'concurrencyToken' | 'dataSubmissionId' | 'enforceableArrangements' | 'id' |
  'msoProductId' | 'recordResult' | 'totalAnnualVolume' | 'totalStorageCapacity'
>

export function assertMsoAnnualActivityRefiner(maybe?: unknown): asserts maybe is MsoAnnualActivityRefiner {
  const maybeAs = maybe as MsoAnnualActivityRefiner;

  const assertionThrower = prefixedThrower('Failed to assert msoAnnualActivity: ');

  if (maybeAs == null) {
    assertionThrower('argument is null');
  }

  if (maybeAs.concurrencyToken == null) {
    assertionThrower('concurrencyToken is null');
  }
  
  if (maybeAs.dataSubmissionId == null) {
    assertionThrower('dataSubmissionId is null');
  }

  if (maybeAs.enforceableArrangements == null) {
    assertionThrower('enforceableArrangements is null');
  }

  if (maybeAs.id == null) {
    assertionThrower('id is null');
  }

  if (maybeAs.msoProductId == null) {
    assertionThrower('msoProductId is null');
  }

  // if (maybeAs.totalAnnualVolume == null) {
  //   assertionThrower('totalAnnualVolume is null');
  // }

  // if (maybeAs.totalStorageCapacity == null) {
  //   assertionThrower('totalStorageCapacity is null');
  // }
}
