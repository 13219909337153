import React from 'react';

import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import Button from 'psims/react/components/button';
import { InputNew as Input } from 'psims/react/components/input';
import Divider from 'psims/react/components/divider';
import { UseEditUser } from './use-edit-user';
import Select from 'psims/react/components/select';
import { asString } from 'psims/lib/string';
import Text from 'psims/react/components/text';
import Notification from 'psims/react/components/notification';
import { StatusField, UserAccount } from './shared';
import { H2 } from 'psims/react/components/typography';

interface EditUserProps {
    ctrl: UseEditUser;
}

const EditUser = (props: EditUserProps) => {
    const vm = useVM(props);

    if (vm.user === null) {
        return null;
    }

    const editUserCaption = vm.isMyDetails ? 'My details' : 'Edit user account';

    const RoleType = () => {
        if (vm.isMyDetails) {
            return null;
        }

        return (
            <BoxedDiv box={{flex: 'row'}}>
                <Select
                    error={vm.validationMessages.roleType}
                    id='roletypeid'
                    label='Role type'
                    flexGrow={1}
                    forceError={vm.saveAttempted}
                    marginRight={4}
                    placeholder='Select a role type'
                    options={vm.selectRoleCtrl.options}
                    onChange={vm.selectRoleCtrl.onChange}
                    value={vm.selectRoleCtrl.value}
                />

                <BoxedSpan box={{flexGrow: 1, marginLeft: 4}} />

            </BoxedDiv>
        );
    };

    return (
        <BoxedDiv box={{flex: 'column'}}>
            <H2>{editUserCaption}</H2>

            <UserAccount
                updateUser={vm.updateUser}
                user={vm.user}
                isMyDetails={vm.isMyDetails}
                validationMessages={vm.validationMessages}
            />            

            <BoxedDiv box={{flex: 'row'}}>
                <Input
                    id='firstname'
                    autoFocus={vm.isMyDetails}
                    error={vm.validationMessages.firstName}
                    label='First name'
                    flexGrow={1}
                    marginRight={4}
                    onChange={e => vm.updateUser('firstName', e.target.value)}
                    value={asString(vm.user?.firstName)}
                />

                <Input
                    id='surname'
                    error={vm.validationMessages.lastName}
                    label='Surname'
                    flexGrow={1}
                    marginLeft={4}
                    onChange={e => vm.updateUser('lastName', e.target.value)}
                    value={asString(vm.user?.lastName)}
                />
            </BoxedDiv>

           <RoleType /> 

            <StatusField status={vm.user.status} />

            {
                vm.user?.status === 'Registered' &&
                <>
                    <BoxedDiv box={{marginBottom: 2, marginTop: 2}}>
                        <h3>Contact details</h3>
                    </BoxedDiv>

                    <Input
                        error={vm.validationMessages.phoneNumber}
                        id='phone'
                        label='Phone number'
                        onChange={e => vm.updateUser('phoneNumber', e.target.value)}
                        value={asString(vm.user.phoneNumber)}
                    />

                    <BoxedDiv box={{marginBottom: 2, marginTop: 2}}>
                        <h3>Contact address</h3>
                    </BoxedDiv>

                    <Text variant='help'>This is the Australian address where mail correspondence could be sent to you</Text>

                    <Input
                        error={vm.validationMessages.addressLine1}
                        id='addressline1'
                        label='Address line 1'
                        onChange={e => vm.updateUser('addressLine1', e.target.value)}
                        value={asString(vm.user.addressLine1)}
                    />

                    <Input
                        error={vm.validationMessages.addressLine2}
                        id='addressline2'
                        label='Address line 2 (optional)'
                        onChange={e => vm.updateUser('addressLine2', e.target.value)}
                        value={asString(vm.user.addressLine2)}
                    />

                    <BoxedDiv box={{flex: 'row'}}>
                        <Input
                            error={vm.validationMessages.city}
                            flexGrow={3}
                            id='suburb'
                            label='Suburb'
                            marginRight={3}
                            onChange={e => vm.updateUser('city', e.target.value)}
                            value={asString(vm.user.city)}
                        />

                        <Select
                            error={vm.validationMessages.stateId}
                            id='state'
                            label='State'
                            flexGrow={1}
                            marginLeft={3}
                            marginRight={3}
                            placeholder='Select'
                            options={vm.selectStateCtrl.options}
                            onChange={vm.selectStateCtrl.onChange}
                            value={vm.selectStateCtrl.value}
                        />

                        <Input
                            error={vm.validationMessages.postcode}
                            flexGrow={1}
                            id='postcode'
                            label='Postcode'
                            marginLeft={3}
                            onChange={e => vm.updateUser('postcode', e.target.value)}
                            value={asString(vm.user.postcode)}
                        />
                    </BoxedDiv>
                </>
            }

            <BoxedDiv box={{marginTop: 5}}>
                <Divider />
            </BoxedDiv>

            {
                vm.serviceError &&

                <BoxedDiv box={{marginV: 1}}>
                    <Notification align='center' kind='warning'>
                        {vm.serviceError}
                    </Notification>
                </BoxedDiv>
            }


            <BoxedDiv box={{flex: 'row-reverse', justifyContent: 'flex-start', marginTop: 3.5}}>
                <Button
                    disabled={vm.serviceStatus === 'loading'}
                    $kind='primary'
                    marginLeft={6}
                    onClick={vm.save}
                    state={vm.serviceStatus === 'loading' ? 'loading' : 'normal'}
                >Save</Button>

                <Button $kind='text' onClick={vm.cancel}>Cancel</Button>
            </BoxedDiv>
        </BoxedDiv>
    )
}

function useVM({ctrl: {user, cancel, isMyDetails, roleOptions, save, saveAttempted, selectRoleCtrl, selectStateCtrl, stateOptions, serviceError, serviceStatus, statusOptions, updateUser, validationMessages}}: EditUserProps) {
    return {
        cancel,
        isMyDetails,
        roleOptions,
        save,
        saveAttempted,
        selectRoleCtrl,
        selectStateCtrl,
        serviceError,
        serviceStatus,
        stateOptions,
        statusOptions,
        updateUser,
        user,
        validationMessages,
    };
}


export default EditUser;
