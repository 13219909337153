import { assertNotEmpty } from "psims/lib/empty";
import { assertDocument, Document } from "./document";

export type DocumentDownload = Document & {
  data: string;
};

export function assertDocumentDownload(maybe?: unknown): asserts maybe is DocumentDownload {
  const maybeAs = maybe as DocumentDownload;

  const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

  assertDocument(maybeAs);
  assertNotEmpty(maybeAs.data, hint('documentDownload.data'));
}

export function isDocumentDownload(maybe?: unknown): maybe is DocumentDownload {
  assertDocumentDownload(maybe);

  return true;
}
