/**
 * This is a wrapper service around the OpenAPI generated code. It's concerns are:
 * 1. Configuring the API base URL depending on current environment
 * 2. Providing a hook to set/update the access token
 * 3. Map any generated services names to more useful names for client consumption
 * 4. Shared error handling
 */

import { APPLICATION_HOST } from 'psims/config/env';
import {
    DashboardService,
    PlantProductionsService,
    OpenAPI,
    ReferenceDataService,
    UsersService,
    StockholdingsService,
    WholesalesService,
    ReportingObligationService,
    RefiningService,
    MsoImporterService,
    MsoRefinerService,
    FieldProductionService,
    BiofuelProductionService,
    OrganisationsService,
    DataSubmissionService,
    HistoryService,
    PortalDataService,
    MsoAnnualActivityRefinerService,
    MsoAnnualActivityImporterService,
    DocumentService,
    FsspService,
    DefService,
    FsspCommitmentService,
} from 'psims/gen/xapi-client';
import { DocumentUpload } from 'psims/models/document-upload';

type AccessTokenGetter = (() => Promise<string | null>) | null;
type ErrorHandler = (error: Error) => any;

type ServiceWrapper = {
    accessTokenGetter: AccessTokenGetter;
    errorHandler: ErrorHandler;
}

const serviceWrapper: ServiceWrapper = {
    accessTokenGetter: null,
    errorHandler: error => {throw error},
}

OpenAPI.BASE = APPLICATION_HOST;

function setAccessTokenGetter(getter: AccessTokenGetter) {
    serviceWrapper.accessTokenGetter = getter;
}

function setServiceErrorHandler(handler: (error: Error) => any) {
    serviceWrapper.errorHandler = handler;
}

function wrapService<TResult, TOpts extends object | void = void>(svc: (opts: TOpts) => Promise<TResult>): (opts: TOpts) => Promise<TResult | null> {
    return async (opts: TOpts) => {
        if (serviceWrapper.accessTokenGetter === null) {
            return Promise.resolve(null);
        }

        try {
            const token = await serviceWrapper.accessTokenGetter();
            if (token === null) {
                return Promise.resolve(null);
            }
            OpenAPI.TOKEN = token;
        } catch (e) {
            const err: ErrorWithStatus = {
                ...(new Error('Failed to get token')),
                status: 401,
            };
            throw err;
        }
        return svc(opts)
            .catch(serviceWrapper.errorHandler);
    }
}

const api = {
    config: {
        setAccessTokenGetter,
        setServiceErrorHandler,
    },
    api: {
        userAcceptTermsAndConditions: UsersService.userAcceptTermsConditions,
        clearAllFssp: wrapService(FsspService.clearAllFssp),
        getFsspSubmission: wrapService(FsspService.getFsspSubmission),
        submitFssp: wrapService(FsspService.submitFssp),
        updateFssp: wrapService(FsspService.updateFssp),
        updateFsspDataSubmission: wrapService(FsspService.updateFsspDataSubmission),
        createUser: wrapService(UsersService.createUser),
        dataSubmissionTemplateRequest: wrapService(DataSubmissionService.dataSubmissionTemplateRequest),
        deactivateUser: wrapService(UsersService.deactivateUser),
        getAllRefData: wrapService(ReferenceDataService.getAllReferenceData),
        getBiofuelProductionSubmission: wrapService(BiofuelProductionService.getBiofuelProductionSubmission),
        getContent: wrapService(ReferenceDataService.getContent),
        getCountries: wrapService(ReferenceDataService.getCountries),
        getDashboard: wrapService(DashboardService.getSummaryDashboard),
        getDefSubmission: wrapService(DefService.getDefSubmission),
        getDocument: wrapService(DocumentService.getDocumentWithMetadata),
        getDocumentList: wrapService(DocumentService.getDocumentList),
        getExternalReportStatuses: wrapService(ReferenceDataService.getExternalReportStatuses),
        getDataSubmission: wrapService(DataSubmissionService.getDataSubmissionById),
        getMsoImporterSubmission: wrapService(MsoImporterService.getMsoImporterSubmission),
        getMsoRefinerSubmission: wrapService(MsoRefinerService.getMsoRefinerSubmission),
        getMsoAnnualActivityImporterSubmission: wrapService(MsoAnnualActivityImporterService.getMsoAnnualActivityImporterSubmission),
        getMsoAnnualActivityRefinerSubmission: wrapService(MsoAnnualActivityRefinerService.getMsoAnnualActivityRefinerSubmission),
        getMsoProducts: wrapService(ReferenceDataService.getMsoProducts),
        getMsoStockOwnershipTypes: wrapService(ReferenceDataService.getMsoStockOwnershipTypes),
        getFieldProductionSubmission: wrapService(FieldProductionService.getFieldProductionSubmission),
        getFsspCommitments: wrapService(FsspCommitmentService.getSummary),
        getPlantProductionSubmission: wrapService(PlantProductionsService.getPlantProductionSubmission),
        getProductionAreas: wrapService(ReferenceDataService.getProductionAreas),
        getProductionAreaStates: wrapService(ReferenceDataService.getProductionAreaStates),
        getOrganisationForEdit: wrapService(OrganisationsService.getOrganisationForEdit),
        getOrganisationMsoSettings: wrapService(HistoryService.getMsoOrganisationSettingHistoryForUser),
        getOrganisationProductionAreas: wrapService(ReferenceDataService.getOrganisationProductionAreas),
        getOrganisations: wrapService(OrganisationsService.getOrganisationsService),
        getOrganisationProductionAreasForUser: wrapService(ReferenceDataService.getOrganisationProductionAreasForUser),
        getPortalDataForSubmissionTypeId: wrapService(PortalDataService.getForSubmissionType),
        getProductionProducts: wrapService(ReferenceDataService.getProductionProducts),
        getProductionProductGroups: wrapService(ReferenceDataService.getProductionProductGroups),
        getProductionTypes: wrapService(ReferenceDataService.getProductionTypes),
        getRefiningSubmission: wrapService(RefiningService.getRefiningSubmission),
        getReportingHistory: wrapService(HistoryService.getHistoryService),
        getReportObligations: wrapService(ReportingObligationService.getSummaryReportObligations),
        getRoleTypes: wrapService(ReferenceDataService.getRoleTypes),
        getRefineryProductGroups: wrapService(ReferenceDataService.getRefineryProductGroups),
        getRefineryProducts: wrapService(ReferenceDataService.getRefineryProducts),
        getRefineryTypes: wrapService(ReferenceDataService.getRefineryTypes),
        getStates: wrapService(ReferenceDataService.getStates),
        getStockholding: wrapService(StockholdingsService.getStockholdingSubmission),
        getStockProductGroups: wrapService(ReferenceDataService.getStockProductGroups),
        getStockProducts: wrapService(ReferenceDataService.getStockProducts),
        getStockTypes: wrapService(ReferenceDataService.getStockTypes),
        getUserStatuses: wrapService(ReferenceDataService.getUserStatuses),
        getWholesaleProductGroups: wrapService(ReferenceDataService.getWholesaleProductGroups),
        getWholesaleProducts: wrapService(ReferenceDataService.getWholesaleProducts),
        getWholesaleTypes: wrapService(ReferenceDataService.getWholesaleTypes),
        getUser: wrapService(UsersService.getUser),
        getUsersForOrg: wrapService(UsersService.getUsersByOrganisation),
        getWholesaling: wrapService(WholesalesService.getWholesaleSubmission),
        login: wrapService(UsersService.login),
        reactivateUser: wrapService(UsersService.reactivateUser),
        registerUser: wrapService(UsersService.registerUser),
        submitDef: wrapService(DefService.submitDef),
        submitMsoImporter: wrapService(MsoImporterService.submitMsoImporter),
        submitMsoRefiner: wrapService(MsoRefinerService.submitMsoRefiner),
        submitMsoAnnualActivityImporter: wrapService(MsoAnnualActivityImporterService.submitMsoAnnualActivityImporter),
        submitMsoAnnualActivityRefiner: wrapService(MsoAnnualActivityRefinerService.submitMsoAnnualActivityRefiner),
        submitStockholding: wrapService(StockholdingsService.submitStockholding),
        submitWholesaling: wrapService(WholesalesService.submitWholesale),
        submitRefining: wrapService(RefiningService.submitRefining),
        submitBiofuelProduction: wrapService(BiofuelProductionService.submitBiofuelProduction),
        submitFieldProduction: wrapService(FieldProductionService.submitFieldProduction),
        submitPlantProduction: wrapService(PlantProductionsService.submitProduction),
        updateDef: wrapService(DefService.updateDef),
        updateDefDataSubmission: wrapService(DefService.updateDefDataSubmission),
        updateMsoImporter: wrapService(MsoImporterService.updateMsoImporter),
        updateMsoImporterDataSubmission: wrapService(MsoImporterService.updateMsoImporterDataSubmission),
        updateMsoRefiner: wrapService(MsoRefinerService.updateMsoRefiner),
        updateMsoAnnualActivityImporter: wrapService(MsoAnnualActivityImporterService.updateMsoAnnualActivityImporter),
        updateMsoAnnualActivityRefiner: wrapService(MsoAnnualActivityRefinerService.updateMsoAnnualActivityRefiner),
        updateMsoRefinerDataSubmission: wrapService(MsoRefinerService.updateMsoRefinerDataSubmission),
        updateMsoAnnualActivityImporterDataSubmission: wrapService(MsoAnnualActivityImporterService.updateMsoAnnualActivityImporterDataSubmission),
        updateMsoAnnualActivityRefinerDataSubmission: wrapService(MsoAnnualActivityRefinerService.updateMsoAnnualActivityRefinerDataSubmission),
        updateBiofuelProduction: wrapService(BiofuelProductionService.updateBiofuelProduction),
        updateBiofuelProductionDataSubmission: wrapService(BiofuelProductionService.updateBiofuelProductionDataSubmission),
        updateFieldProduction: wrapService(FieldProductionService.updateFieldProduction),
        updateFieldProductionDataSubmission: wrapService(FieldProductionService.updateFieldProductionDataSubmission),
        updatePlantProduction: wrapService(PlantProductionsService.updateProduction),
        updatePlantProductionDataSubmission: wrapService(PlantProductionsService.updatePlantProductionDataSubmission),
        updatePortalData: wrapService(PortalDataService.updatePortalData),
        updateRefining: wrapService(RefiningService.updateRefining),
        updateRefiningDataSubmission: wrapService(RefiningService.updateRefiningDataSubmission),
        updateStockholdingDataSubmission: wrapService(StockholdingsService.updateStockholdingDataSubmission),
        updateStockholdingDomestic: wrapService(StockholdingsService.updateStockholdingDomestic),
        updateStockholdingOverseas: wrapService(StockholdingsService.updateStockholdingOverseas),
        updateStockholdingOnWater: wrapService(StockholdingsService.updateStockholdingOnWater),
        updateWholesale: wrapService(WholesalesService.updateWholesale),
        updateWholesaleDataSubmission: wrapService(WholesalesService.updateWholesaleDataSubmission),
        updateUser: wrapService(UsersService.updateUser),
        updateOrganisation: wrapService(OrganisationsService.updateOrganisation),
        clearAllDef: wrapService(DefService.clearAllDef),
        clearAllStockholding: wrapService(StockholdingsService.clearAllStockholding),
        clearAllWholesale: wrapService(WholesalesService.clearAllWholesale),
        clearAllRefining: wrapService(RefiningService.clearAllRefining),
        clearAllMsoRefiner: wrapService(MsoRefinerService.clearAllMsoRefiner),
        clearAllMsoAnnualActivityRefiner: wrapService(MsoAnnualActivityRefinerService.clearAllMsoAnnualActivityRefiner),
        clearAllMsoImporter: wrapService(MsoImporterService.clearAllMsoImporter),
        clearAllMsoAnnualActivityImporter: wrapService(MsoAnnualActivityImporterService.clearAllMsoAnnualActivityImporter),
        clearAllBiofuelProduction: wrapService(BiofuelProductionService.clearAllBiofuelProduction),
        clearAllFieldProduction: wrapService(FieldProductionService.clearAllFieldProduction),
        clearAllPlantProduction: wrapService(PlantProductionsService.clearAllPlantProduction),
        // Upload needs special treatment as it uses formData, and the type contract is not properly interpreted by the api-gen process:
        uploadDocument: wrapService((opts: {requestBody: DocumentUpload}) => {
            const formData = new FormData();
            formData.append('formFile', opts.requestBody.formFile);
            formData.append('documentType', opts.requestBody.documentType);
            formData.append('organisationId', String(opts.requestBody.organisationId));
            formData.append('name', opts.requestBody.name);

            const headers = new Headers();
            headers.append('Authorization', `Bearer ${String(OpenAPI.TOKEN)}`);
            return fetch('/api/document/upload', {
                body: formData,
                method: 'post',
                headers,
            });
        }),
    }
};

export default api;
