import React, { useEffect, useMemo, useState } from 'react';

import EnsureId from 'psims/react/pages/primary-pages/data-submissions/shared/ensure-id';
import useSubmissionLoadState from 'psims/react/pages/primary-pages/data-submissions/shared/use-submission-load-state';
import LoadingSubmission from 'psims/react/pages/primary-pages/data-submissions/shared/loading-submission';
import LoadSubmissionFailed from 'psims/react/pages/primary-pages/data-submissions/shared/load-submission-failed';
import useMsoImporterApi, { UseMsoImporterAPI } from './use-mso-importer-api';
import MsoImporterEditor from './mso-importer-editor';

interface MsoImporterProps {
    id: number;
}

const MsoImporter = (props: MsoImporterProps) => {
    const vm = useVM(props);

    switch(vm.loadState) {
        case 'error':
            return <LoadSubmissionFailed submissionName='MSO importer' />
        case 'loaded':
            return <MsoImporterEditor
                // TODO: should type assert apiCtrl
                apiCtrl={vm.apiCtrl as PopulatedProps<UseMsoImporterAPI, 'submission'>}
            />;
        default:
            return <LoadingSubmission submissionName='MSO importer' />;

    }
}

function useVM(props: MsoImporterProps) {
    const apiCtrl = useMsoImporterApi({id: props.id});
    const [loaded, setLoaded] = useState(false);

    const ready = useMemo(() => {
        return loaded;
    }, [loaded])

    const loadState = useSubmissionLoadState({submission: apiCtrl.submission, isDepsReady: ready, loadStatus: apiCtrl.loadStatus})

    useEffect(() => {
        if (apiCtrl.loadStatus === 'fetched') {
            setLoaded(true);
        }
    }, [apiCtrl.loadStatus]);

    return {
        apiCtrl,
        loadState: loadState as typeof loadState,
    }
}

const Page = (props: MsoImporterProps) => <EnsureId id={props.id} Component={MsoImporter} />;

export default Page;
