import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Box from './box';
import { IconButton } from './button';

interface ModalProps {
    onClose?: () => any;
}

const StyledModal = styled.div`
    background: var(--color-white);
    border-top: 3px solid var(--color-primary);
    padding-bottom: var(--spacing-xxl);
`;

const Modal = ({children, onClose}: PropsWithChildren<ModalProps>) => {
    return (
        <Box flex='column'>
            <StyledModal>
                {
                    onClose &&
                    <Box alignSelf='stretch' flex='row' justifyContent='flex-end' padding='lg'>
                        <IconButton color='primary' icon='close' label='Close dialog' onClick={onClose} size='md' />
                    </Box>
                }
                <Box paddingH='xxl' paddingV='lg' marginTop={Boolean(onClose) ? 'xs' : 'xxl'}>
                    {children}
                </Box>
            </StyledModal>
        </Box>
    )
}

export default Modal;
