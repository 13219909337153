import { all } from "psims/lib/collections";
import { isEmpty } from "psims/lib/empty";
import { isUpdateMsoStockOnBehalf } from "psims/models/submission-types/mso/mso-stock-on-behalf";
import { isUpdateMsoStockholding } from "psims/models/submission-types/mso/mso-stockholding";
import { isUpdateMsoStockholdingOwnership } from "psims/models/submission-types/mso/mso-stockholding-ownership";

export function wrap<TArgs extends Array<unknown>>(fn1: (...args1: TArgs) => unknown, fn2: () => unknown) {
    return (...args: TArgs) => {
        fn1(...args);
        fn2();
    }
}

export function isEmptyUpdateMsoStockholdingOwnership(maybe: unknown) {
  if (isUpdateMsoStockholdingOwnership(maybe)) {
    return (
      isEmpty(maybe.entityName) &&
      isEmpty(maybe.stockVolume)
    );
  }

  return true;
}

export function isEmptyUpdateMsoStockholding(maybe: unknown) {
  if (isUpdateMsoStockholding(maybe)) {
    return (
      isEmpty(maybe.density) &&
      isEmpty(maybe.eez) &&
      isEmpty(maybe.holderOwnerStockVolume) &&
      isEmpty(maybe.pipeline) &&
      all(maybe.stockholdingOwnerships, isEmptyUpdateMsoStockholdingOwnership)
    )
  }

  return true;
}

export function isEmptyUpdateMsoStockOnBehalf(maybe: unknown) {
  if (isUpdateMsoStockOnBehalf(maybe)) {
    return (
      isEmpty(maybe.amountHeld) &&
      isEmpty(maybe.entityOnBehalf) &&
      isEmpty(maybe.msoOwnershipType)
    );
  }

  return true;
}
