// StockTypeName needs to be kept in sync with ref data

import { StockTypeDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { prefixedThrower } from "psims/lib/exception";


export type StockType = StockTypeDTO & {
    displayOrder: number;
    id: number;
    name: StockTypeName;
};

const STOCK_TYPE_NAMES = ['In Australia', 'Overseas', 'On water'] as const;

export type StockTypeName = ArrayElement<typeof STOCK_TYPE_NAMES>;

export function assertStockType(maybe?: unknown): asserts maybe is StockType {
    const asStockType = maybe as StockType;

    const err = prefixedThrower(`Failed to assert stockType: ${JSON.stringify(maybe)}: `);

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asStockType, hint('stockType'));
    assertNotEmpty(asStockType.id, hint('stockType.id'));
    assertNotEmpty(asStockType.name, hint('stockType.name'));

    if (!STOCK_TYPE_NAMES.includes(asStockType.name)) err(`unknown type name: ${asStockType.name}`);
}
