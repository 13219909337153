import React from 'react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { getLoginRequest } from 'psims/config/authConfig';
import Button from 'psims/react/components/button';
import { useConfiguration } from 'psims/react/providers/configuration-provider';

interface SignInButtonProps {}

const SignInButton = (props: SignInButtonProps) => {
    const { instance } = useMsal();
    const { configuration } = useConfiguration();

    if (configuration === null) {
        return null;
    }

    function handleLogin(instance: IPublicClientApplication) {
        if (configuration !== null) {
            instance.loginRedirect(getLoginRequest(configuration))
                .catch(e => console.error);
        }
    }

    return (
        <Button $kind='primary' onClick={() => handleLogin(instance)}>Sign in</Button>
    )
}

export default SignInButton;
