import { assertFspRefineryProduct } from "psims/models/ref-data/fsp-refinery-product";
import { FspDataSubmission } from "psims/models/submission-types/fsp/fsp-submission";
import { useMemo } from "react";
import { PeriodSummaryTableConfig } from "./types";
import { UseFspRefData } from "./use-fsp-ref-data";

interface UseFspHydrocarbonBlendstocksProps {
  refData: UseFspRefData;
  submission: FspDataSubmission;
}

function useFspHydrocarbonBlendstocks({refData, submission}: UseFspHydrocarbonBlendstocksProps) {
  const tableView = useMemo<PeriodSummaryTableConfig | null>(() => {
    if (refData.fspRefineryProducts.length === 0) {
      return null;
    }

    return ({
      kind: 'Input',
      title: 'Quarterly hydrocarbon blendstocks',
      items: submission.hydrocarbonBlendstocks.map(hb => {
        const product = refData.fspRefineryProducts.find(p => p.refineryProductId === hb.refineryProductId);
        assertFspRefineryProduct(product);
        return {
          month1: hb.inputMonth1,
          month2: hb.inputMonth2,
          month3: hb.inputMonth3,
          product,
          totalKilolitres: hb.totalInputKilolitres,
          totalTonnes: hb.totalInputTonnes,
        }
      })
    });
  }, [refData.fspRefineryProducts, submission]);

  return useMemo(() => ({
    tableView,
  }), [tableView]);
}

export default useFspHydrocarbonBlendstocks

export type UseFspHydrocarbonBlendstocks = ReturnType<typeof useFspHydrocarbonBlendstocks>;
