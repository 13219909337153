import { DefMarketExpectationsVM, UpdateDefMarketExpectationsVM } from "psims/gen/xapi-client";
import { isRecordActionEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";

export type MaybeDefMarketExpectations = PopulatedProps<DefMarketExpectationsVM, 'dataSubmissionId'>;

export type DefMarketExpectations = PopulatedProps<
  MaybeDefMarketExpectations,
  'id' | 'dataSubmissionId'
>;

export function isDefMarketExpectations(maybe?: unknown): maybe is DefMarketExpectations {
  const maybeAs = maybe as DefMarketExpectations;

  return (
    maybeAs != null &&
    maybeAs.id != null &&
    maybeAs.dataSubmissionId != null
  )
}

export type UpdateDefMarketExpectations = PopulatedProps<
  UpdateDefMarketExpectationsVM,
  'recordAction' | 'dataSubmissionId'
>;

export function isUpdateDefMarketExpectations(maybe?: unknown): maybe is UpdateDefMarketExpectations {
  const maybeAs = maybe as UpdateDefMarketExpectations;

  return (
    maybeAs != null &&
    maybeAs.dataSubmissionId != null &&
    isRecordActionEnum(recordActionFromEnum(maybeAs.recordAction))
  );
}
