import React, { ReactNode } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import Notification from 'psims/react/components/notification';
import { H3 } from 'psims/react/components/typography';
import UL, { ArrowLI } from 'psims/react/components/unordered-list';

interface PageNotificationsProps {
    kind: 'info' | 'validation' | 'system_alert';
    items: Array<ReactNode>;
}

export type Kind = PageNotificationsProps['kind'];

const CONTENT_MAP = {
    info: {
        kind: 'info',
        title: 'Is your data correct?',
    },
    validation: {
        kind: 'warning',
        title: 'Invalid entries detected'
    },
    system_alert: {
        kind: 'warning',
        title: 'System alerts',
    },
} as const;

const PageNotifications = ({kind, items}: PageNotificationsProps) => {
    const {kind: notificationKind, title} = CONTENT_MAP[kind];
    if (!items) {
        return null;
    }
    return (
        <BoxedDiv box={{marginV: 4}}>
            <Notification kind={notificationKind}>
                <BoxedDiv box={{flex: 'column', paddingTop: 1}} role='group'>
                    <H3 id={`notifications_${kind}_title`}>{title}</H3>

                    <BoxedDiv box={{marginTop: 2}}>
                        <UL padding='0' >
                            {items.map((msg, index) => (
                                <ArrowLI
                                    aria-describedby={`notifications_${kind}_title`}
                                    key={index}
                                    role='alert'
                                    tabIndex={0}
                                    data-notification-item={kind}
                                >
                                    {msg}
                                </ArrowLI>
                            ))}
                        </UL>
                    </BoxedDiv>
                </BoxedDiv>
            </Notification>
        </BoxedDiv>
    )
}

export default PageNotifications;
