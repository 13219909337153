import { humanDate } from "psims/lib/formatters/datetime";
import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { MinimumStockObligationSetting } from "psims/models/minimum-stock-obligation-setting";
import { BoxedDiv } from "psims/react/components/layout";
import { Table, TBody, TD, TH, THead, TR } from "psims/react/components/table";
import { H4 } from "psims/react/components/typography";

interface ResultsProps {
    data: Array<MinimumStockObligationSetting>;
}

const Results = (props: ResultsProps) => {
    const vm = useVM(props);

    return (
        <div>
            <BoxedDiv box={{flex: 'column', paddingV: 3}}>
                <BoxedDiv box={{flex: 'row'}}>
					{
						vm.data.length === 0 ?

						<BoxedDiv
							box={{
								borderBottom: {color: 'border-primary-faded', width: 1},
								borderTop: {color: 'border-primary-faded', width: 4},
								flex: 'row',
								flexGrow: 1,
								justifyContent: 'center',
								paddingBottom: 1.5,
								paddingTop: 4,
							}}
						>
							<H4>There are no results matching your search criteria</H4>
						</BoxedDiv> :

						<Table caption="Your company's minimum stockholding obligations with the entered filters applied" fullWidth={true}>
							<THead>
								<TR>
									<TH>Reporting entity</TH>
									<TH>MSO class</TH>
									<TH>MSO product</TH>
									<TH>MSO value</TH>
									<TH>Effective from</TH>
									<TH>Effective to</TH>
									<TH>Active</TH>
								</TR>
							</THead>

							<TBody>
                                { vm.data.map(i => <Row key={i.id} msoSetting={i} />) }
							</TBody>
						</Table>
					}
                </BoxedDiv>
            </BoxedDiv>
        </div>
    );
}

function useVM({data}: ResultsProps) {
    return { 
        data,
    };
}

interface RowProps {
	msoSetting: MinimumStockObligationSetting,
}

const Row = ({msoSetting}: RowProps) => { 
	return (
		<TR>
			<TD>{msoSetting.reportingEntityName}</TD>
			<TD>{msoSetting.msoClassName}</TD>
			<TD>{msoSetting.msoProductName}</TD>
			<TD>{localeNumberWithFixed(msoSetting.minimumStockObligation)}</TD>
			<TD>{humanDate(new Date(msoSetting.effectiveFrom))}</TD>
			<TD>{msoSetting.effectiveTo != null ? humanDate(new Date(msoSetting.effectiveTo)) : ''}</TD>
			<TD>{msoSetting.isActive ? 'Yes' : 'No'}</TD>
		</TR>
	);
}


export default Results;
