import { UseImportReferenceData } from "../use-import-reference-data";
import { ImportFunctionResult, ImportTemplateData, TemplateImporter, TemplateImportState, Tuple } from "../types";
import { getStringCellValue } from '../utils';
import { Wholesale, WholesaleSubmission } from "psims/models/submission-types/wholesaling";
import { WHOLESALE_TYPE_NAMES, WholesaleTypeName } from "psims/models/ref-data/wholesale-type";
import { SAVED_PAGES_MAP } from "psims/react/pages/primary-pages/data-submissions/whl/use-wholesaling-steps";
import { isValidForPeriod, IsWholesaleProductExpiredInTotalPage } from "psims/models/ref-data/util";
import { HasReportingPeriod } from "psims/react/pages/primary-pages/data-submissions/shared/types";

const headerKeyColumns = ['Wholesaling type','Product group', 'Product'];

const getWholesaleTypeId = (page: string, refData: UseImportReferenceData, columns: Array<Tuple<string, number | null>>, row: any[]): ImportFunctionResult => {
    const wholesaleType = getStringCellValue(row, columns, 'Wholesaling type', true);
    const result = refData.getWholesaleTypeId(wholesaleType ?? '');

    if (result == null) {
        return {
            isSuccessful: false,
            error: {
                error: `Wholesale type not found [${wholesaleType}]`,
                page,
            }
        };
    }

    return {
        isSuccessful: true,
        result
    };
};

const getTotalsWholesaleTypeId = (page: string, refData: UseImportReferenceData, columns: Array<Tuple<string, number | null>>, row: any[]): ImportFunctionResult => {
    const result = refData.getWholesaleTypeId('Total');
    
    if (result == null) {
        return {
            isSuccessful: false,
            error: {
                error: `Wholesale type not found [Total]`,
                page,
            }
        };
    }

    return {
        isSuccessful: true,
        result
    };
};

const getWholesaleProductId = (
    page: string,
    refData: UseImportReferenceData,
    columns: Array<Tuple<string, number | null>>,
    row: any[],
    dataSubmission: HasReportingPeriod
): ImportFunctionResult => {
    const productGroup = getStringCellValue(row, columns, 'Product Group', true);
    const product = getStringCellValue(row, columns, 'Product', true); 
    const wholesaleType = getStringCellValue(row, columns, 'Wholesaling type', true);
    const wholesaleProduct = refData.getWholesaleProduct(wholesaleType || page, (productGroup ?? '').trim(), (product ?? '').trim());
    const wholesaleProductGroup = refData.getWholesaleProductGroup(wholesaleType || page, (productGroup ?? '').trim().trim());


    if (wholesaleProduct == null || wholesaleProductGroup == null) {
        return {
            isSuccessful: false,
            error: {
                error: `Product not found [${product}]`,
                page,
            }
        };
    }

    // Check product isn't expired
    const isExpired = !(
        isValidForPeriod(wholesaleProductGroup, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo) &&
        isValidForPeriod(wholesaleProduct, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo)
    );

    //Check if the corresponding product/product group in the total page is expired or not
    let isExpiredInTotalPage = false;

    if (!isExpired) {
        const wholesaleProductGroupsInTotalPage = refData.getWholesaleProductGroupsInTotalPage();
        const wholesaleProductsInTotalPage = refData.getWholesaleProductsInTotalPage();
        isExpiredInTotalPage = IsWholesaleProductExpiredInTotalPage(wholesaleProductsInTotalPage, wholesaleProductGroupsInTotalPage, wholesaleProduct.genericProductCode, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo);
    }
    if (isExpired || isExpiredInTotalPage) {
        return {
            isSuccessful: false,
            error: {
                error: `The product ${wholesaleProduct.productName} for ${isExpired ? wholesaleProductGroup.wholesaleTypeName : 'Total Wholesales'} is not active for the reporting period.`,
                isTerminalError: true,
                page,
                recommendTemplateRequest: true,
            }
        }
    }

    const result = wholesaleProduct.id;

    return {
        isSuccessful: true,
        result
    };
};

const getTotalsWholesaleProductId = (page: string, refData: UseImportReferenceData, columns: Array<Tuple<string, number | null>>, row: any[], dataSubmission: HasReportingPeriod): ImportFunctionResult => {
    const productGroup = getStringCellValue(row, columns, 'Product Group', true);
    const product = getStringCellValue(row, columns, 'Product', true); 
    const wholesaleType = 'Total';

    const wholesaleProduct = refData.getWholesaleProduct(wholesaleType, (productGroup ?? '').trim(), (product ?? '').trim());
    const wholesaleProductGroup = refData.getWholesaleProductGroup(wholesaleType, (productGroup ?? '').trim().trim());


    if (wholesaleProduct == null || wholesaleProductGroup == null) {
        return {
            isSuccessful: false,
            error: {
                error: `Product not found [${product}]`,
                page,
            }
        };
    }

    // Check product isn't expired
    const isExpired = !(
        isValidForPeriod(wholesaleProductGroup, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo) &&
        isValidForPeriod(wholesaleProduct, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo)
    );

    if (isExpired) {
        return {
            isSuccessful: false,
            error: {
                error: `The product ${wholesaleProduct.productName} for ${wholesaleType} is not active for the reporting period.`,
                isTerminalError: true,
                page,
                recommendTemplateRequest: true,
            }
        }
    }

    const result = wholesaleProduct.id;

    return {
        isSuccessful: true,
        result
    };
};

const wholesalingImport = (): TemplateImporter<Partial<WholesaleSubmission>> => {
    const newSubmissionBuilder: () => Partial<WholesaleSubmission> = () => ({
        wholesales: [],          
        submissionFormData: {
            retailersPageSaved: false,
            wholesalersPageSaved: false,
            bulkPageSaved: false,
            otherPageSaved: false,
            totalPageSaved: false,
            manualTotals: false,
            id: 0,
            concurrencyToken: ''
        },
    });

    const importStateBuilder: () => TemplateImportState<Partial<WholesaleSubmission>> = () => ({
        templateImportDialogState: 'processing',
        unsavedChanges: false,
        data: newSubmissionBuilder(),
    });

    const wholesales: ImportTemplateData<Partial<Wholesale>> =  {
        dataKey: 'wholesales',
        page: {
            name: 'Market segment',
            headerRowKeys: headerKeyColumns,
            columns: [
                { dataKey: 'wholesaleTypeId', dataType: 'string', column: 'Wholesaling type', colFunction: getWholesaleTypeId },                
                { dataKey: 'wholesaleProductId', dataType: 'string', column: 'Product', colFunction: getWholesaleProductId },
                { dataKey: 'nswWholesaleVolume', dataType: 'integer', column: 'NSW' },
                { dataKey: 'vicWholesaleVolume', dataType: 'integer', column: 'VIC' },
                { dataKey: 'qldWholesaleVolume', dataType: 'integer', column: 'QLD' },
                { dataKey: 'saWholesaleVolume', dataType: 'integer', column: 'SA' },
                { dataKey: 'waWholesaleVolume', dataType: 'integer', column: 'WA' },
                { dataKey: 'tasWholesaleVolume', dataType: 'integer', column: 'TAS' },
                { dataKey: 'ntWholesaleVolume', dataType: 'integer', column: 'NT' }
            ],
            data: [],
        },
    };

    const totals: ImportTemplateData<Partial<Wholesale>> =  {
        dataKey: 'wholesales',
        page: {
            name: 'Total wholesales',
            headerRowKeys: ['Product group', 'Product'],
            columns: [
                { dataKey: 'wholesaleTypeId', dataType: 'string', column: 'Product', colFunction: getTotalsWholesaleTypeId },                
                { dataKey: 'wholesaleProductId', dataType: 'string', column: 'Product', colFunction: getTotalsWholesaleProductId },
                { dataKey: 'nswWholesaleVolume', dataType: 'integer', column: 'NSW' },
                { dataKey: 'vicWholesaleVolume', dataType: 'integer', column: 'VIC' },
                { dataKey: 'qldWholesaleVolume', dataType: 'integer', column: 'QLD' },
                { dataKey: 'saWholesaleVolume', dataType: 'integer', column: 'SA' },
                { dataKey: 'waWholesaleVolume', dataType: 'integer', column: 'WA' },
                { dataKey: 'tasWholesaleVolume', dataType: 'integer', column: 'TAS' },
                { dataKey: 'ntWholesaleVolume', dataType: 'integer', column: 'NT' }
            ],
            data: [],
        },
    };
    
    const mergeRows = (importState: TemplateImportState<Partial<WholesaleSubmission>>, data: Array<Partial<Wholesale>>): Array<Partial<Wholesale>> => {
        const newData: Array<Partial<Wholesale>> = data;
        const existingRows = importState.data?.wholesales ?? [];
        existingRows.forEach(d => {
            const idx = newData.findIndex(x => (x.wholesaleProductId ?? 0) === (d.wholesaleProductId ?? 0) && (x.wholesaleTypeId ?? 0) === (d.wholesaleTypeId ?? 0));
            if (idx === -1) {
                newData.push(d);
            }
        })
        return newData;
    };

    const setStepSaved = (importState: TemplateImportState<Partial<WholesaleSubmission>>, stepName: any): TemplateImportState<Partial<WholesaleSubmission>> => {
        if (WHOLESALE_TYPE_NAMES.includes(stepName as WholesaleTypeName) && importState.data && importState.data.submissionFormData) {
            const typeName = stepName as WholesaleTypeName;
            const newSubmissionFormData = {...importState.data.submissionFormData, [SAVED_PAGES_MAP[typeName]]: true };
            let unsavedChanges = !(newSubmissionFormData.bulkPageSaved === true &&
                newSubmissionFormData.otherPageSaved === true &&
                newSubmissionFormData.retailersPageSaved === true  &&
                newSubmissionFormData.totalPageSaved === true &&
                newSubmissionFormData.wholesalersPageSaved === true  &&
                importState.submitSaved === true                
            );
            if (!unsavedChanges) {
                return {
                    templateImportDialogState: 'idle',
                    unsavedChanges: false,
                    submitSaved: false,
                    data: undefined
                };
            }
            return {
                ...importState,
                data: {
                    ...importState.data,
                    submissionFormData: newSubmissionFormData
                },
                unsavedChanges: unsavedChanges
            };
        }
        if (stepName === 'Submit' && importState.data && importState.data.submissionFormData) {
            let unsavedChanges = !(importState.data.submissionFormData.bulkPageSaved === true &&
                importState.data.submissionFormData.otherPageSaved === true &&
                importState.data.submissionFormData.retailersPageSaved === true  &&
                importState.data.submissionFormData.totalPageSaved === true &&
                importState.data.submissionFormData.wholesalersPageSaved === true
            );
            if (!unsavedChanges) {
                return {
                    templateImportDialogState: 'idle',
                    unsavedChanges: false,
                    submitSaved: false,
                    data: undefined
                };
            }
            return {
                ...importState,
                submitSaved: true
            };
        }
        return importState;
    }

    const savedSteps = (importState: TemplateImportState<Partial<WholesaleSubmission>>) => {
        return importState?.data?.submissionFormData;
    }

    const importer: TemplateImporter<Partial<WholesaleSubmission>> = {
        importStateBuilder,
        dataElements: [wholesales, totals],
        mergeRows,
        savedSteps,
        setStepSaved
    };

    return importer;
}

export default wholesalingImport;
