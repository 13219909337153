import React from 'react';

import BaseSVG, { SVGProps } from './_base';

const FileWord = (props: SVGProps) => {
	return (
		<BaseSVG name='file-word' viewBox='0 0 384 512' {...props}>
			<path fill='currentColor' d="M224 0H0V512H384V160H224V0zm32 0V128H384L256 0zM107.6 240l31.7 109.3L164 257.7l4.8-17.7h18.4 9.6 18.4l4.8 17.7 24.7 91.5L276.4 240h50L271.1 430.7 266 448H248h-8H221.6l-4.8-17.7-24.8-92-24.8 92L162.4 448H144h-8H118l-5-17.3L57.7 240h50z"/>
		</BaseSVG>
	);
};

export default FileWord;
