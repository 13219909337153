export type MonthNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export function isDate(maybe: unknown): maybe is Date {
    const maybeAs = maybe as Date;
    return (
        maybeAs != null &&
        maybeAs instanceof Date &&
        !isNaN(maybeAs.getTime())
    )
}

const MONTH_NAMES_FULL = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
];

export function getMonthNameFull(month: number): ArrayElement<typeof MONTH_NAMES_FULL> {
    if (!isMonthNumber(month)) {
        throw new Error(`Cannot get month name for ${month}`);
    }

    return MONTH_NAMES_FULL[month];
}

export function getMonthNumber(month: string): MonthNumber {
    const index = MONTH_NAMES_FULL.findIndex(m => m.toLowerCase() === month.toLowerCase())

    if (!isMonthNumber(index)) {
        throw new Error(`Cannot get month number for ${month}`);
    }

    return index;
}

export function humanDate(dateOrString: Date | string) {
    let date = dateOrString;

    if (date instanceof Date === false) {
        date = new Date(date);
    }

    if (!isDate(date)) {
        return 'Invalid date';
    }

    return `${date.getDate()} ${getMonthNameFull(date.getMonth() as MonthNumber).substr(0, 3)} ${date.getFullYear()}`
}

export function humanDateNumeric(dateOrString: Date | string) {
    let date = dateOrString;

    if (date instanceof Date === false) {
        date = new Date(date);
    }

    if (!isDate(date)) {
        return 'Invalid date';
    }

    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export function getTimeAMPM(date: Date) {
    return `${
        date.getHours()%12 || 12
    }:${
        date.getMinutes() < 10 ? '0' : ''
    }${
        date.getMinutes()
    } ${
        date.getHours() / 12 >= 1 ? 'PM' : 'AM'
    }`;
}

export function humanDateTime(date: string) {
    const d = new Date(date);
    if (!isDate(d)) {
        return 'Invalid date';
    }
    
    return `${humanDate(d)} ${getTimeAMPM(d)}`
}

function isMonthNumber(maybeMonthNumber: number): maybeMonthNumber is MonthNumber {
    return maybeMonthNumber > -1 && maybeMonthNumber < 12;
}