import React, { useMemo } from 'react';

import { Stockholding } from 'psims/models/submission-types/stockholding';
import { BoxedDiv } from 'psims/react/components/layout';
import Header from 'psims/react/blocks/data-submission-header';
import useStockholding from './use-stockholding';
import stepConfig from './step-config';
import { H2, H3 } from 'psims/react/components/typography';
import AustraliaEditor from './editor-australia';
import Button from 'psims/react/components/button';
import OverseasEditor from './editor-overseas';
import OnWaterEditor from './editor-on-water';
import { asString } from 'psims/lib/string';
import Textarea from 'psims/react/components/textarea';
import UL, { ArrowLI } from 'psims/react/components/unordered-list';
import Notification from 'psims/react/components/notification';
import SubmissionProgressBar from 'psims/react/components/submission-progress-bar';
import SubmitTerms from '../shared/submit-terms';
import ScrollTo from 'psims/react/components/scroll-to';
import { isValidCommentCharacters, isValidCommentMaxLength } from 'psims/lib/validation/comments';
import { INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH } from 'psims/constants/validation-messages';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';
import ConfirmSaveChanges from 'psims/react/components/confirm-save-changes';
import { DeleteConfirmationDialog } from '../shared/delete-confirmation';
import DataSubmissionPrimaryControls from 'psims/react/components/data-submission-primary-controls';
import { SubmissionType } from 'psims/models/ref-data/submission-type';
import MainContainer from 'psims/react/pages/primary-pages/data-submissions/shared/main-container';

interface StockholdingEditorProps {
    submission: Stockholding;
    submissionType: SubmissionType;
}

const StockholdingEditor = (props: StockholdingEditorProps) => {
    const vm = useVM(props);
    const {submitCtrl, onWaterCtrl, overseasCtrl, domesticCtrl, importCtrl} = vm.ctrl;
    const comments = asString(submitCtrl.updateDataSubmission.comments);
    let commentError: string | undefined = undefined;
    if (comments.length > 0) {
        if (!isValidCommentMaxLength(comments)) {
            commentError = INVALID_COMMENTS_LENGTH;
        }
        
        if (!commentError && !isValidCommentCharacters(comments)) {
            commentError = INVALID_COMMENTS_CHARACTERS;
        }
    }

    const shouldShowExtraButtons = vm.ctrl.currentStep.name === 'Australia' && vm.ctrl.mode === 'edit';
    
    let message: string | undefined = undefined;
    let cancelLabel: string | undefined = undefined;
    let confirmLabel: string | undefined = undefined;
    let title = 'Confirm record deletion';
    let isDeleteAll = false;

    switch (vm.ctrl.currentStep.name) {
        case 'On water':
            isDeleteAll = onWaterCtrl.deleteRequestState.rowIndex === undefined;
            break;
        case 'Overseas':
            isDeleteAll = overseasCtrl.deleteRequestState.rowIndex === undefined;
            break;
        case 'Australia':
            isDeleteAll = domesticCtrl.deleteRequestState.rowIndex === undefined;
            break;
        default:
            break;
    }
    
    if (isDeleteAll) {        
        title = 'Are you sure?';
        message = 'If you proceed, all the records for this row will be permanently deleted and you will be able to continue with your data submission. ';
        cancelLabel = 'Cancel';
        confirmLabel = 'Proceed';        
    }

    return (
        <MainContainer dataSubmission={vm.ctrl.submission.dataSubmission}>
            <BoxedDiv box={{}}>
                <Header
                    clearFile={importCtrl.clearFile}
                    dataSubmission={vm.ctrl.submission.dataSubmission}
                    file={importCtrl.file}
                    showExtraButtons={shouldShowExtraButtons} 
                    handleClearData={vm.ctrl.clearDataCtrl.handleClearData}
                    handleTemplateImport={importCtrl.handleTemplateImport}
                    process={importCtrl.process}
                    setTemplateImportState={importCtrl.setTemplateImportState}
                    templateImportState={importCtrl.templateImportState}
                    currentStepIndex={vm.ctrl.currentStep.index}
                />

                <BoxedDiv className='container' box={{marginV: 4}}>
                    {/* Progress indicator */}
                    <BoxedDiv box={{marginV: 6}}>
                        <SubmissionProgressBar
                            isComplete={vm.ctrl.mode === 'view'}
                            steps={vm.ctrl.progressSteps}
                            onStepClick={vm.ctrl.handleProgressNav}
                        />
                    </BoxedDiv>

                    {/* Step frontmatter */}
                    {
                        vm.viewConfig && <>
                            <H2>{vm.viewConfig.title}</H2>
                            <BoxedDiv box={{marginV: 4}}>
                                <vm.viewConfig.Help />
                            </BoxedDiv>
                        </>
                    }

                    {/* Main form editor */}
                    {
                        vm.ctrl.currentStep.name === 'Australia' &&
                        <AustraliaEditor ctrl={vm.ctrl} />
                    }

                    {
                        vm.ctrl.currentStep.name === 'Overseas' &&
                        <OverseasEditor ctrl={vm.ctrl} />
                    }

                    {
                        vm.ctrl.currentStep.name === 'On water' &&
                        <OnWaterEditor ctrl={vm.ctrl} />
                    }

                    {/* Submit screen */}
                    {
                        vm.ctrl.currentStep.name === 'Submit' &&
                        <BoxedDiv box={{marginV: 4}}>
                            <SubmitTerms
                                disabled={vm.ctrl.disableInputs}
                                hasAgreed={vm.ctrl.submitCtrl.declaration}
                                onHasAgreedChange={vm.ctrl.submitCtrl.updateDeclaration}
                            />

                            <BoxedDiv box={{}}>
                                <Textarea
                                    disabled={vm.ctrl.disableInputs}
                                    forceError={vm.ctrl.submitCtrl.changedState === 'unsaved_changes'}
                                    error={submitCtrl.view?.comments?.validationMessage}
                                    id='comments'
                                    label='Comments'
                                    onChange={e => submitCtrl.updateComments(e.target.value)}
                                    setFocused={submitCtrl.focusedFieldCtrl.focusedField === 'comments'}
                                    value={asString(submitCtrl.view?.comments?.comment)}
                                />
                            </BoxedDiv>
                        </BoxedDiv>
                    }

                    {/* Page messages */}
                    {
                        !vm.ctrl.disableInputs && vm.ctrl.pageMessages.errors.length > 0 &&
                        <BoxedDiv box={{marginV: 2}}>
                            <Notification kind='warning' data-validation-notification>
                                <BoxedDiv box={{flex: 'column', paddingTop: 1}}>
                                    <H3>Invalid entries detected</H3>

                                    <BoxedDiv box={{marginTop: 2}}>
                                        <UL padding='0'>
                                            {
                                                vm.ctrl.pageMessages.errors.map((Message, i) => (
                                                    <ArrowLI key={i} color='negative'  role='alert' tabIndex={0} data-notification-item='validation'>
                                                        {Message}
                                                    </ArrowLI>
                                                ))
                                            }
                                        </UL>
                                    </BoxedDiv>
                                </BoxedDiv>
                            </Notification>
                        </BoxedDiv>
                    }

                    {
                        !vm.ctrl.disableInputs && vm.ctrl.pageMessages.infos.length > 0 &&
                        <BoxedDiv box={{marginV: 4}}>
                            <Notification kind='info'>
                                <BoxedDiv box={{flex: 'column', paddingTop: 1}}>
                                    <H3>Is your data correct?</H3>

                                    <BoxedDiv box={{marginTop: 2}}>
                                        <UL padding='0'>
                                            {
                                                vm.ctrl.pageMessages.infos.map((Message, i) => (
                                                    <ArrowLI key={i} color='primary' role='alert' tabIndex={0} data-notification-item='info'>
                                                        {Message}
                                                    </ArrowLI>
                                                ))
                                            }
                                        </UL>
                                    </BoxedDiv>
                                </BoxedDiv>
                            </Notification>
                        </BoxedDiv>
                    }


                    {/* Service message */}
                    {
                        vm.ctrl.systemMessages.length > 0 &&
                        <BoxedDiv box={{}}>
                            <Notification align='flex-start' kind='warning'>
                                <BoxedDiv box={{flex: 'column', paddingTop: 1}}>
                                    <H3>System alerts</H3>

                                    <BoxedDiv box={{marginTop: 2}}>
                                        <UL padding='0'>
                                            {
                                                vm.ctrl.systemMessages.map((msg, i) => (
                                                    <ArrowLI key={i} color='negative' role='alert' tabIndex={0} data-notification-item='system_alert'>
                                                        {msg}
                                                    </ArrowLI>
                                                ))
                                            }
                                        </UL>
                                    </BoxedDiv>
                                </BoxedDiv>
                            </Notification>
                        </BoxedDiv>
                    }

                    {/* Controls */}
                    <DataSubmissionPrimaryControls>
                        <BoxedDiv box={{flex: 'row-reverse', marginV: 4}}>
                            {
                                vm.ctrl.currentStep.name === 'Submit' ? (
                                    !vm.ctrl.disableInputs &&
                                    <Button
                                        $kind='primary'
                                        onClick={vm.ctrl.handleSubmit}
                                        state={vm.ctrl.status === 'saving' ? 'loading' : 'normal'}
                                    >Submit</Button>
                                ) :

                                <Button
                                    $kind='primary'
                                    onClick={vm.ctrl.handleSaveAndContinue}
                                    state={vm.ctrl.status === 'saving' ? 'loading' : 'normal'}
                                >{
                                    vm.ctrl.disableInputs ?
                                    'Continue' :
                                    'Save and continue'
                                }</Button>
                            }

                            {
                                vm.ctrl.currentStep.index > 0 &&
                                <BoxedDiv box={{marginH: 4}}>
                                    <Button
                                        $kind='ghost'
                                        onClick={vm.ctrl.handlePrevious}
                                        state={vm.ctrl.status === 'saving' ? 'loading' : 'normal'}
                                    >Previous</Button>
                                </BoxedDiv>
                            }
                        </BoxedDiv>
                    </DataSubmissionPrimaryControls>

                </BoxedDiv>
                
                <ScrollTo />

                {/* Unsaved changes confirmation */}
                <ConfirmSaveChanges
                    controlsState={vm.ctrl.status === 'processing' ? 'loading' : 'normal'}
                    isOpen={vm.ctrl.unsavedChangesStatus === 'showing_dialog'}
                    onCancel={vm.ctrl.handleUnsavedChangesDialogCancel}
                    onConfirm={vm.ctrl.handleUnsavedChangesDialogSave}
                    onSecondary={vm.ctrl.handleUnsavedChangesDialogProceedWithoutSave}
                />

                {/* Review alerts dialog */}
                <ConfirmationDialog
                    ariaLabel='Your data contains alerts - please review before continuing'
                    body={
                        <BoxedDiv box={{flex: 'column'}}>
                            <p>The data on this page has triggered one or more alerts, which require you to provide a comment.</p>
                            <p>If you want to review these alerts and review or add a comment, please click Review alerts.</p>
                            <p>If you have addressed these and entered a comment, click Proceed to continue to the next page.</p>
                        </BoxedDiv>
                    }
                    cancelLabel='Review alerts'
                    isOpen={vm.ctrl.reviewAlertStatus === 'showing_dialog'}
                    onCancel={vm.ctrl.handleAlertDialogCancel}
                    onConfirm={vm.ctrl.handleAlertDialogContinue}
                    title='Your data contains alerts - please review before continuing'
                />

                {/* Confirm Clear all dialog */}
                {
                    shouldShowExtraButtons &&
                    <ConfirmationDialog
                        ariaLabel='Clear all data confirm'
                        body={vm.ctrl.clearDataCtrl.confirmCtlr.message}
                        title={vm.ctrl.clearDataCtrl.confirmCtlr.title}
                        onCancel={vm.ctrl.clearDataCtrl.confirmCtlr.cancel}
                        onConfirm={vm.ctrl.clearDataCtrl.confirmCtlr.confirm}
                        isOpen={vm.ctrl.clearDataCtrl.confirmCtlr.confirmState === 'confirming'}
                    />
                }

                {/* Confirm deletes dialog */}
                {
                    (vm.ctrl.currentStep.name === 'On water') &&
                    <DeleteConfirmationDialog
                        title={title}
                        message={message}
                        cancelLabel={cancelLabel}
                        confirmLabel={confirmLabel}
                        deleteRequestState={onWaterCtrl.deleteRequestState}
                        setDeleteRequestState={onWaterCtrl.setDeleteRequestState}
                    />
                }

                {
                    (vm.ctrl.currentStep.name === 'Overseas') &&
                    <DeleteConfirmationDialog
                        title={title}
                        message={message}
                        cancelLabel={cancelLabel}
                        confirmLabel={confirmLabel}
                        deleteRequestState={overseasCtrl.deleteRequestState}
                        setDeleteRequestState={overseasCtrl.setDeleteRequestState}
                    />
                }

                {
                    (vm.ctrl.currentStep.name === 'Australia') &&
                    <DeleteConfirmationDialog
                        title={title}
                        message={message}
                        cancelLabel={cancelLabel}
                        confirmLabel={confirmLabel}
                        deleteRequestState={domesticCtrl.deleteRequestState}
                        setDeleteRequestState={domesticCtrl.setDeleteRequestState}
                    />
                }
            </BoxedDiv>
        </MainContainer>
    );
}

function useVM({submission, submissionType}: StockholdingEditorProps) {
    const ctrl = useStockholding({submission, submissionType});

    const viewConfig = useMemo(() => {
        return stepConfig(submission);
    }, [submission]);

    return {
        ctrl,
        viewConfig: viewConfig[ctrl.currentStep.name],
    };
}


export default StockholdingEditor;
