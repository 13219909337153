import React, { useEffect, useMemo, useState } from 'react';

import Box from 'psims/react/components/box';
import TickCircle from 'psims/react/components/icons/tick-circle';
import Page from 'psims/react/blocks/page';
import Text from '../components/text';
import { getMonthNameFull, getTimeAMPM, MonthNumber } from 'psims/lib/formatters/datetime';
import { useUser } from '../providers/user';
import Section from '../components/section';
import Button from '../components/button';
import { useNavigation } from '../providers/router';
import useQuery from '../util/use-query';
import { getSubmissionTypeLongName } from 'psims/constants/data-submissions';
import { DataSubmission, DataSubmissionTypeName, isAnyDataSubmission } from 'psims/models/data-submission';
import { useAPI } from '../providers/api';
import { asString } from 'psims/lib/string';
import { BoxedDiv } from '../components/layout';
import Loading from '../components/loading';
import LoadSubmissionFailed from './primary-pages/data-submissions/shared/load-submission-failed';

interface ReportSubmittedProps {}

const ReportSubmitted = (props: ReportSubmittedProps) => {
    const vm = useVM();
    const {view} = vm;
    return (
        <Page>
            <Box
                alignItems='center'
                className='container'
                flex='column'
                role='region'
                aria-live='polite'
            >
                {
                    vm.showLoading ?

                    <BoxedDiv box={{alignItems: 'center', flexGrow: 1, flex: 'column', justifyContent: 'center', marginV: 4}}>
                        <Loading>Loading submission result</Loading>
                    </BoxedDiv> :

                    vm.showError ?

                    <LoadSubmissionFailed submissionName='' /> :

                    <Box alignItems='flex-start' flex='column' marginTop='xxl' minWidth='800px'>
                        <Box alignItems='center' flex='row'>
                            <TickCircle color='green' size='xl' />
                            <Box marginLeft='xl'>
                                <h1>{view?.reportName} submitted</h1>
                            </Box>
                        </Box>

                        <Box flex='column' marginTop='xl'>
                            <Text>
                                Your <Text weight='semibold'>{view?.reportName}</Text> data submission with Case ID <Text weight='semibold'>
                                    {view?.caseID}
                                </Text> for <Text weight='semibold'>{view?.company} </Text> has been submitted on&nbsp;</Text>
                            <Box marginTop='md'><Text $size={18} weight='bold'>{view?.formattedSubmissionDate}&nbsp;</Text></Box>
                        </Box>

                        <Box flex='column' marginTop='xl'>
                            <Text>You can view the status of your current data submissions on the <a href='/home'>home page</a>.</Text>
                        </Box>

                        <Section title='What happens next?' headerStyle='h2'>
                            <Box marginTop='lg'>
                                <Text>
                                    Thank you for your submission, the Department has received your reported information. <br />
                                    You will receive notification once your request has been processed.
                                </Text>
                            </Box>

                            <Box marginTop='xxl'>
                                <Button $kind='ghost' label='Return to home page' onClick={vm.onDone}>Return to home page</Button>
                            </Box>
                        </Section>
                    </Box>
                }
            </Box>
        </Page>
    );
}

type LoadStatus = 'idle' | 'fetching' | 'fetched' | 'failed';

function useVM() {
    const {user} = useUser();
    const nav = useNavigation();
    const query = useQuery();
    const {api} = useAPI();
    const [submission, setSubmission] = useState<DataSubmission<DataSubmissionTypeName> | null>(null);
    const [loadStatus, setLoadStatus] = useState<LoadStatus>('idle');

    const formattedSubmissionDate = useMemo(() => {
        if (submission == null) {
            return '';
        }

        const date = new Date(asString(submission?.submittedAt));
        return `${date.getDate()} ${getMonthNameFull(date.getMonth() as MonthNumber)} ${date.getFullYear()} at ${getTimeAMPM(date)}`;
    }, [submission]);

    const view = useMemo(() => {
        if (user == null || submission == null || !isAnyDataSubmission(submission)) {
            return null;
        }

        return {
            caseID: submission.caseId,
            company: submission.reportingOrganisationName,
            email: user?.email,
            formattedSubmissionDate,
            reportingMonth: submission.reportingMonth || 11,
            reportingYear: submission.reportingYear || 2021,
            reportName: getSubmissionTypeLongName(submission),
        }
    }, [formattedSubmissionDate, submission, user])

    const showLoading = (loadStatus === 'fetching' || loadStatus === 'idle') && view == null;
    const showError = loadStatus === 'failed';

    useEffect(() => {
        if (!query.id) {
            nav.goToHomePage();
        }

        if (loadStatus !== 'idle') {
            return;
        }
    
        setLoadStatus('fetching');
        api.getDataSubmission({id: Number(query.id)})
        .then(response => {
            const {result} = (response || {});
            if (isAnyDataSubmission(result)) {
                if (result.status !== 'Submitted') {
                    nav.goToHomePage();
                    return;
                }
                setSubmission(result);
                setLoadStatus('fetched');
            } else {
                throw new Error(`Failed to parse data submission: ${JSON.stringify(response?.result)}`);
            }
        })
        .catch(e => {
            console.log(e)
            setLoadStatus('failed');
        })
    }, [api, loadStatus, nav, query.id]);

    return {
        loadStatus,
        onDone: nav.goToHomePage,
        showError,
        showLoading,
        submission,
        view,
    };
}

export default ReportSubmitted;
