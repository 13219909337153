import { WholesaleProductDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

export type WholesaleProduct = PopulatedProps<WholesaleProductDTO, 'id' | 'displayOrder' | 'effectiveFrom' | 'isActive' | 'productCode' | 'productId' | 'productName' | 'wholesaleProductGroupId' | 'wholesaleProductGroupName'>;

export function assertWholesaleProduct(maybe?: unknown): asserts maybe is WholesaleProduct {
    const asWholesaleProduct = maybe as WholesaleProduct;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asWholesaleProduct, hint('wholesaleProduct'));
    assertNotEmpty(asWholesaleProduct.displayOrder, hint('wholesaleProduct.displayOrder'));
    assertNotEmpty(asWholesaleProduct.id, hint('wholesaleProduct.id'));
    assertNotEmpty(asWholesaleProduct.productCode, hint('wholesaleProduct.productCode'));
    assertNotEmpty(asWholesaleProduct.productId, hint('wholesaleProduct.productId'));
    assertNotEmpty(asWholesaleProduct.productName, hint('wholesaleProduct.productName'));
    assertNotEmpty(asWholesaleProduct.wholesaleProductGroupId, hint('wholesaleProduct.wholesaleProductGroupId'));
    assertNotEmpty(asWholesaleProduct.wholesaleProductGroupName, hint('wholesaleProduct.wholesaleProductGroupName'));
}
