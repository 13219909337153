import { useEffect, useState } from 'react';
import { read, WorkBook } from 'xlsx';

import Text from "psims/react/components/text";

import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import produce from 'immer';
import Dialog from 'psims/react/components/dialog';
import { H3 } from 'psims/react/components/typography';
import ExclamationTriangle from 'psims/react/components/icons/exclamation-triangle';
import Button from 'psims/react/components/button';
import UL, { ArrowLI } from 'psims/react/components/unordered-list';
import { TemplateImportDialogProps } from './types';
import { idleTemplateImportState } from './use-template-import';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';
import FileNotSupported from "psims/react/blocks/import/file-not-supported";
import UnknownError from "psims/react/blocks/import/unknown-error";

const MIME_TYPES = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
];

export const TemplateImportDialog = ({
    file,
    clearFile = () => {},
    dataSubmission,
    process,
    templateImportState,
    setTemplateImportState,
}: TemplateImportDialogProps) => {
    const [workbook, setWorkbook] = useState<WorkBook | null>(null);
    const [buffer, setBuffer] = useState<any | null>(null);

    useEffect(() => {
        if (file != null && templateImportState.templateImportDialogState === 'importing') {
            if (MIME_TYPES.includes(file.type)) {
                file.arrayBuffer().then((x) => setBuffer(x));
                clearFile();
            } else {
                clearFile();
                setTemplateImportState({
                    templateImportDialogState: 'error',
                    totalErrors: 1,
                    errors: [{
                        error: <FileNotSupported fileName={file.name} />,
                        page: ''
                    }]
                });
            }
        }
      }, [clearFile, file, setTemplateImportState, templateImportState.templateImportDialogState]);

    useEffect(() => {
      if (buffer != null) {
        const workbook = read(buffer, { raw: true });
        if (workbook != null) {
            setWorkbook(workbook);
        } else {
            setTemplateImportState({
                templateImportDialogState: 'error',
                totalErrors: 1,
                errors: [{
                    error:
                        file != null ?
                            <FileNotSupported fileName={file.name} />
                            :
                            <UnknownError/>
                            ,
                    page: ''
                }]
            });
        }

        setBuffer(null);
      } 
    }, [buffer, file, setTemplateImportState]);

    useEffect(() => {
        if (workbook != null) {
            process(workbook, dataSubmission);
            setWorkbook(null);
        }
      }, [dataSubmission, process, setTemplateImportState, workbook]);

    const onConfirm = () => {
        setTemplateImportState(prev => produce(prev, draft => {
            draft.templateImportDialogState = 'processing'
        }));
    }

    const onErrorConfirm = () => {
        setTemplateImportState(idleTemplateImportState());
        clearFile();
    }

    const onImportConfirmCancel = () => {
        setTemplateImportState(idleTemplateImportState());
    }
       
    const onImportConfirm = () => {
        setTemplateImportState(prev => produce(prev, draft => {
            draft.templateImportDialogState = 'confirmed'
        }));
    }	
     
    if (templateImportState.templateImportDialogState === 'confirming') {          
        const ImportConfirmMessage = 
            <BoxedDiv box={{flex: 'column'}}>
                <Text>If you proceed, all the records for this data submission will be permanently deleted and will be replaced with the data in this upload file.</Text>
                <BoxedDiv box={{marginTop: 4}}>
                    <Text>Would you like to proceed with the upload?</Text>
                </BoxedDiv>
            </BoxedDiv>;
        return (  
                <ConfirmationDialog
                    ariaLabel='Import clear all data confirm'
                    body={ImportConfirmMessage}
                    title={'Are you sure?'}
                    onCancel={onImportConfirmCancel}
                    onConfirm={onImportConfirm}
                    isOpen={templateImportState.templateImportDialogState === 'confirming'}
                />);
    }
                
    if (templateImportState.templateImportDialogState === 'error') {  
        const error = templateImportState.errors === undefined || templateImportState.errors.length === 0 ? 'Unknown error' : templateImportState.errors[0].error;
        return (
            <Dialog aria-label='Data import complete' isOpen={templateImportState.templateImportDialogState === 'error'} onDismiss={onConfirm}>
                <BoxedDiv box={{flex: 'column', justifyContent: 'space-between', height: '60%'}}>
                    <H3>
                        <BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
                            <Text $color='blue-70'><ExclamationTriangle size="lg" /></Text>
                            <BoxedSpan box={{marginLeft: 2}}>Data import failed</BoxedSpan>
                        </BoxedSpan>
                    </H3>

                    <BoxedDiv box={{marginV: 4}}>
                        <Text>{error}</Text>
                    </BoxedDiv>

                    <BoxedDiv box={{flex: 'row-reverse', justifyContent: 'space-between'}}>
                        <BoxedDiv box={{flex: 'row-reverse'}}>
                            <Button
                                disabled={false}
                                $kind='primary'
                                onClick={onErrorConfirm}
                            >Continue</Button>
                        </BoxedDiv>
                    </BoxedDiv>                    
                </BoxedDiv>
            </Dialog>
        );
    } 

    if (templateImportState.templateImportDialogState === 'complete') {   
        const totalRows = templateImportState.totalRows ?? 0;     
        const errorCount = templateImportState.totalErrors ?? 0;
        let successes = totalRows - errorCount;
        if (successes < 0) {
            successes = 0;
        }

        const errors = templateImportState.errors === undefined || errorCount === 0 ? [] : templateImportState.errors.map(e => {
                if (e.error) {
                    if (e.page) {
                        if (e.row) {
                            return `${e.page} - Row: ${e.row} - ${e.error}`;
                        }
                        return `${e.page} - ${e.error}`;
                    }
                    return e.error;
                }
                return null;
        });
        
        return (
            <Dialog aria-label='Data import complete' isOpen={templateImportState.templateImportDialogState === 'complete'} onDismiss={onConfirm}>
                <BoxedDiv box={{flex: 'column', justifyContent: 'space-between', height: '60%'}}>
                    <H3>
                        <BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
                            <Text $color='blue-70'><ExclamationTriangle size="lg" /></Text>
                            <BoxedSpan box={{marginLeft: 2}}>Data import complete</BoxedSpan>
                        </BoxedSpan>
                    </H3>

                    <BoxedDiv box={{marginV: 4}}>
                        <Text>{`${successes} out of ${totalRows} records were successfully imported.`}</Text>
                        <Text>Please navigate through the pages to save your data. Any unsaved changes will be lost.</Text>
                    </BoxedDiv>

                    { errorCount > 0 &&
                        <BoxedDiv box={{marginTop: 1}}>
                            <Text><strong>Please review the following issues encountered during the import:</strong></Text>
                            <UL padding='0'>
                                {errors.map((msg, index) => (
                                    <ArrowLI key={index}>{msg}</ArrowLI>
                                ))}
                            </UL>
                        </BoxedDiv>
                    }
                    <BoxedDiv box={{flex: 'row-reverse', justifyContent: 'space-between'}}>
                        <BoxedDiv box={{flex: 'row-reverse'}}>
                            <Button
                                disabled={false}
                                $kind='primary'
                                onClick={onConfirm}
                            >Continue</Button>
                        </BoxedDiv>
                    </BoxedDiv>                    
                </BoxedDiv>
            </Dialog>
        );
    } 

    return <></>;
}
