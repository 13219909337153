import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { H2 } from 'psims/react/components/typography';
import { useReferenceData } from 'psims/react/providers/api/reference-data';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { SubmissionType } from 'psims/models/ref-data/submission-type';
import { SELECTIONS } from './constants';
import type { Selection } from './types';
import PortalDataView from './portal-data-view';
import useConfirmNavigation from 'psims/react/util/use-confirm-navigation';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';

interface ManageRefDataProps {}

const ManageRefData = (props: ManageRefDataProps) => {
  const vm = useVM();

  return (
    <div className='manage-ref-data'>
      <BoxedDiv box={{flex: 'row'}}>
        <BoxedDiv box={{flex: 'column', paddingRight: 8}}>
          <H2 marginTop={0}>Reference data types</H2>

          {
            vm.selections != null &&
            <ul className='bare'>
              {
                vm.selections.map(s => (
                <li className='mt-8' key={s.selectionId}> 
                  <SubmissionTypeButton
                    isActive={vm.selectedData?.selectionId === s.selectionId}
                    onClick={vm.onClickSelection}
                    selection={s}
                  />
                </li>
                ))
              }

            </ul>
          }

        </BoxedDiv>

        <BoxedDiv box={{alignItems: 'stretch', flex: 'column', flexGrow: 1, justifyContent: 'flex-start'}}>
          {
            vm.selectedData != null ?
            <PortalDataView
              onChangedFieldCount={vm.onChangedFieldCount}
              selection={vm.selectedData}
            /> :
            <BoxedSpan box={{alignItems: 'center', flex: 'row', flexGrow: 1, justifyContent: 'center'}}>
              <strong>Select a reference data type to manage reference data</strong>
            </BoxedSpan>
          }
        </BoxedDiv>
      </BoxedDiv>

      {/* Unsaved changes confirmation */}
      <ConfirmationDialog
          ariaLabel='You have unsaved changes on this page'
          body={
              <BoxedDiv box={{}}>
                <p>You have unsaved changes, any changes made will be lost.</p>
              </BoxedDiv>
          }
          controlsState='normal'
          isOpen={vm.showUnsavedChangesDialog}
          onCancel={vm.onCancelUnsavedChanges}
          onConfirm={vm.onConfirmUnsavedChanges}
          confirmLabel='OK'
          cancelLabel='Cancel'
          title='You have unsaved changes on this page'
      />
    </div>
  )
}

function useVM() {
  const { data: refData } = useReferenceData()
  const [selectedData, setSelectedData] = useState<Selection | null>(null)
  const [changedFieldCount, setChangedFieldCount] = useState(0);
  const [selectionChangeAttempt, setSelectionChangeAttempt] = useState<Selection | null>(null)
  const navPrompt = useConfirmNavigation({
      when: changedFieldCount > 0,
  });

  const submissionTypes = useMemo(() => {
    if (refData == null) {
      return null;
    }

    const {
      submissionTypes,
    } = refData;

    return submissionTypes
  }, [refData]);

  const selections = useMemo<Array<Selection>>(() => {
    return SELECTIONS.map((selection, index) => ({
      ...selection,
      selectionId: index + 1,
      submissionType: submissionTypes?.find(st => selection.submissionTypeName === st.name) as SubmissionType
    }))
    .filter(s => s.submissionType != null);
  }, [submissionTypes]);

  const selectSelection = useCallback((selection: Selection) => {
    if (selections != null) {
      const s = selections.find(s => (
        s.selectionId === selection.selectionId
      ));

      if (s != null) {
        setSelectedData(s);
      }
    }
  }, [selections]);

  const onClickSelection = useCallback((selection: Selection) => {
    setSelectionChangeAttempt(null);
    if (changedFieldCount === 0) {
      selectSelection(selection);
    } else {
      setSelectionChangeAttempt(selection);
    }
  }, [changedFieldCount, selectSelection]);

  const showUnsavedChangesDialog = useMemo(() => {
    return selectionChangeAttempt != null || navPrompt.showPrompt
  }, [selectionChangeAttempt, navPrompt.showPrompt])

  const onChangedFieldCount = useCallback((count: number) => {
    setChangedFieldCount(count)
  }, []);

  const onCancelUnsavedChanges = useCallback(() => {
    navPrompt.handleDecision(false);
    setSelectionChangeAttempt(null);
  }, [navPrompt, setSelectionChangeAttempt])

  const onConfirmUnsavedChanges = useCallback(() => {
    navPrompt.handleDecision(true);
    if (selectionChangeAttempt != null) {
      selectSelection(selectionChangeAttempt);
    }

    setSelectionChangeAttempt(null);
  }, [navPrompt, selectionChangeAttempt, selectSelection])

  useEffect(() => {
    if (changedFieldCount === 0) {
      setSelectionChangeAttempt(null);
    }
  }, [changedFieldCount]);

  return {
    navPrompt,
    onCancelUnsavedChanges,
    onChangedFieldCount,
    onClickSelection,
    onConfirmUnsavedChanges,
    selectedData,
    selections,
    showUnsavedChangesDialog,
  };
}

interface SubmissionTypeButtonProps {
  isActive: boolean;
  onClick: (selection: Selection) => any;
  selection: Selection
}

const SubmissionTypeButton = ({isActive, onClick, selection}: SubmissionTypeButtonProps) => {
  return (
    <button
      data-is-active={isActive}
      className='submission-type-button'
      onClick={() => onClick(selection)}
    >
      {selection.label}
    </button>
  )
}

export default ManageRefData;
