import produce from "immer";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { INFO_NEGATIVE_VALUE_COMMENTS, INFO_REPORT_VARIANCE } from "psims/constants/info-messages";
import { INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH, INVALID_COMMENTS_REQUIRED, INVALID_COUNTRY, INVALID_COUNTRY_CONFLICT, INVALID_COUNTRY_ISINTERNAL_SELECTED, INVALID_QUANTITY, INVALID_QUANTITY_REQUIRED } from "psims/constants/validation-messages";
import { isEmpty } from "psims/lib/empty";
import { asNumber } from "psims/lib/number";
import { isBetween, isInteger } from "psims/lib/validation/number";
import { StockProduct } from "psims/models/ref-data/stock-product";
import { StockProductGroup } from "psims/models/ref-data/stock-product-group";
import { StockType } from "psims/models/ref-data/stock-type";
import { removeServerFields, Stockholding, StockholdingSubmission, UpdateStockholdingComment, UpdateStockholdingOnWater, UpdateStockholdingOnWaterPorts, UpdateStockholdingSubmissionOnWater } from "psims/models/submission-types/stockholding";
import { FocusField, LocationErrorMessage, OnWaterField, OnWaterPortsField, OnWaterPortsRowFocusField, OnWaterRowFocusField, StockholdingLocationValidationCode } from "./shared";
import { CommentsErrorMessage, CommentsRequiredErrorMessage, InfoNegativeValueMessage, InfoPreviouslyReportedMessage, InfoReportVarianceMessage, QuantityInvalidErrorMessage } from 'psims/react/pages/primary-pages/data-submissions/shared/messages';
import { UseFocusedField } from "psims/react/util/use-focused-field";
import useProductGroups from './use-stock-product-groups';
import useStockType from "./use-stock-type";
import { isValidationAlert, ValidationAlert, ValidationAlertMaybe } from "psims/models/api/submission/validation-alert";
import { ChangedState } from "../shared/save-state";
import { isValidCommentCharacters, isValidCommentMaxLength } from "psims/lib/validation/comments";
import { encodeEscapeChars } from "psims/lib/validation/string";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { DeleteRequestState, idleDeleteRequest } from "../shared/delete-confirmation";
import { focusNext } from "psims/lib/focus-util";
import { recordActionAsEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { UseTemplateImport } from "psims/react/blocks/import/use-template-import";
import { UpdateStockholdingOnWaterVM } from "psims/gen/xapi-client";
import { all, any } from "psims/lib/collections";
import { UsePortalDataAPI } from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api";
import { EntityStatus, WithIsExpired } from "../shared/types";
import { Country } from "psims/models/ref-data/country";

interface UsePageOnWaterProps {
    focusedFieldCtrl: UseFocusedField<FocusField>;
    importCtrl: UseTemplateImport<StockholdingSubmission>;
    isActive: boolean;
    portalDataAPICtrl: UsePortalDataAPI;
    stockholding: Stockholding;
    countries: Array<Country>;
}

type InfoMessage = {
    code: 'negativeValue' | 'previouslyReported' | 'reportVariance';
    message?: string;
}

type OnWaterInfoMessages = {
    [key in OnWaterField]?: InfoMessage;
} & {
    total?: InfoMessage;
};

type OnWaterPortsInfoMessages = {
    [key in OnWaterPortsField]?: InfoMessage;
};

type OnWaterErrorMessages = {
    [key in OnWaterField]?: {
        code: StockholdingLocationValidationCode;
        message: string;
    };
}

type OnWaterPortsErrorMessages = {
    [key in OnWaterPortsField]?: {
        code: StockholdingLocationValidationCode;
        message: string;
    };
}

export type PortFormData = {
    data: UpdateStockholdingOnWaterPorts;
    infoMessages: OnWaterPortsInfoMessages;
    isAddedRow: boolean;
    rowIndex: number;
    errorMessages: OnWaterPortsErrorMessages;
    validationAlerts: Array<ValidationAlert>;
}

export type ProductFormData = {
    data: UpdateStockholdingOnWater;
    errorMessages: OnWaterErrorMessages;
    infoMessages: OnWaterInfoMessages;
    ports: Array<PortFormData>; 
    productStatus: EntityStatus;
    product: StockProduct;
    total: number;
    validationAlerts: Array<ValidationAlert>;
}

type View = {
    dataSubmissionId?: number;
    groups: Array<{
        productGroup: WithIsExpired<StockProductGroup>;
        products: Array<ProductFormData>;        
        groupStatus: EntityStatus | 'empty';
    }>;
    comments: {
        data: UpdateStockholdingComment | undefined;
        infoMessage?: string;
        errorMessage?: string;
    },
    hasData: boolean;
    hasValidationErrors: boolean;
    isCommentsRequired: boolean;
    messages: {
        errors: Array<ReactNode>;
        infos: Array<ReactNode>;
    }
}

type OnWaterUpdate = {
    field: OnWaterField;
    stockProductId: number;
    value: string | undefined;
}

type OnWaterPortsUpdate = {
    field: OnWaterPortsField;
    rowIndex: number;
    stockProductId: number;
    value: string | number | undefined;
}

function usePageOnWater({focusedFieldCtrl, importCtrl, isActive, portalDataAPICtrl, stockholding, countries}: UsePageOnWaterProps) {
    const stockType = useStockType({stockTypeName: 'On water'});
    const productGroups = useProductGroups({dataSubmission: stockholding.dataSubmission, stockTypeName: 'On water'});
    const [changedState, setChangedState] = useState<ChangedState>('unchanged');
    const [deleteRequestState, setDeleteRequestState] = useState<DeleteRequestState>(idleDeleteRequest);

    const onWaterStockTypeRef = useUpdatedRef(stockType);

    const [updateSubmissionOnWater, setUpdateSubmissionOnWater] = useState<UpdateStockholdingSubmissionOnWater>(
        updateSubmissionOnWaterFromStockholding(stockholding, stockType)
    );

    const deleteAllProductRows = useCallback((stockProductId: number | undefined, source: HTMLElement | null) => {
        setDeleteRequestState({
            deleteState: 'showing_dialog',
            id: stockProductId,
            message: 'All Foreign locations and on water records for this product will be deleted.',
            source,
        });
    }, []);

    const view = useMemo<View>(() => {
        const v: View = {
            dataSubmissionId: updateSubmissionOnWater.dataSubmissionId,
            groups: [],
            comments: {
                data: updateSubmissionOnWater.stockholdingComment,
            },
            hasData: updateSubmissionOnWater.stockholdingComment?.comments != null ||
                     (updateSubmissionOnWater.onWaterStockholdings?.length || 0) > 0,
            hasValidationErrors: false,
            isCommentsRequired: false,
            messages: {
                errors: [],
                infos: [],
            }
        };

        if (!isActive || productGroups.stockProductGroups == null) {
            return v;
        }

        // build form data view by merging product groups and updateSubmission data
        v.groups = productGroups.stockProductGroups
        .map(spg => {
            let hasNonEmpty = false;
            let hasExpiredWithData = false;
             
            const products = spg.stockProducts
                .map(sp => {
                    let data = updateSubmissionOnWater.onWaterStockholdings
                        ?.find(ds => ds.stockProductId === sp.id);

                    let hasData = true;
                    // add empty row if no data exists for product
                    if (data == null) {
                        hasData = false;
                        data = {
                            stockProductId: sp.id,
                            stockholdingOnWaterPorts: [{}]
                        }
                    }

                    v.hasData = v.hasData || hasData;
                    let total = totalForProduct(data);
                    let dataId = data.id;
                    const seenCountryIds = new Set<number>();

                    const stockholdingOnWater = stockholding.onWaterStockholdings
                        ?.find(ds => ds.id === dataId);
                    
                    const productValidationAlerts = [
                        ...stockholdingOnWater
                            ?.validationAlerts || [],
                        ...stockholdingOnWater
                            ?.stockholdingOnWaterPorts
                            ?.map(p => p.validationAlerts)
                            ?.reduce((memo, alerts) => [...(memo || []), ...(alerts || [])], []) || [],
                    ]
                    .filter<ValidationAlert>(((maybe) => (
                        isValidationAlert(maybe, 'PreviouslyReportedAlert') ||
                        isValidationAlert(maybe, 'PercentVarianceApplied')
                    )) as TypeAssertion<ValidationAlertMaybe, ValidationAlert>);

                    // Add messages for product (domesticShipping, eez, ...)
                    const productInfoMessages = infoMessagesForProduct(data, productValidationAlerts, stockholding.dataSubmission.reportVariances?.onWaterStockholdings);

                    if (!isEmpty(productInfoMessages)) {
                        v.isCommentsRequired = true;
                        v.messages.infos = [
                            ...v.messages.infos,
                            ...infoMessageComponentsForProduct(
                                productInfoMessages,
                                sp,
                                ({field}) => {
                                    if (isOnWaterField(field)) {
                                        focusedFieldCtrl.setFocusedField({stockProductId: asNumber(sp.id), field})
                                    }
                                },
                                () => focusedFieldCtrl.setFocusedField('comments'),
                                stockholding.dataSubmission.reportVariances?.onWaterStockholdings
                            )
                        ]
                    }

                    const productErrorMessages = errorMessagesForProduct(data);
                    if (!isEmpty(productErrorMessages)) {
                        v.hasValidationErrors = true;
                        v.messages.errors = [
                            ...v.messages.errors,
                            ...errorMessageComponentsForProduct(
                                productErrorMessages,
                                sp,
                                ({field}) => {
                                    if (isOnWaterField(field)) {
                                        focusedFieldCtrl.setFocusedField({stockProductId: asNumber(sp.id), field})
                                    }
                                }
                            )
                        ]
                    }

                    const addEmptyPort = (data.stockholdingOnWaterPorts || []).filter(p => recordActionFromEnum(p.recordAction) !== 'Delete').length === 0;
                    const ports = [
                        ...(
                            data.stockholdingOnWaterPorts && data.stockholdingOnWaterPorts.length > 0 ?
                            data.stockholdingOnWaterPorts :
                            []
                        ),
                        ...(addEmptyPort ? [{stockholdingOnWaterId: data.id}] : []),
                    ]
                    .map((port, rowIndex) => {
                        const portValidationAlerts = stockholdingOnWater
                            ?.stockholdingOnWaterPorts
                            ?.find(p => p.id === port.id)
                            ?.validationAlerts
                            ?.filter<ValidationAlert>(((maybe) => isValidationAlert(maybe)) as TypeAssertion<ValidationAlertMaybe, ValidationAlert>) || [];

                        const portInfoMessages = infoMessagesForPort(port);
                        if (!isEmpty(portInfoMessages)) {
                            v.isCommentsRequired = true;
                            // Add Info message components for page
                            v.messages.infos = [
                                ...v.messages.infos,
                                ...infoMessageComponentsForPort(
                                    portInfoMessages,
                                    sp,
                                    ({field}) => focusedFieldCtrl.setFocusedField({stockProductId: asNumber(sp.id), field, rowIndex}),
                                    () => focusedFieldCtrl.setFocusedField('comments')
                                )
                            ]
                        }

                        const portErrorMessages = errorMessagesForPort(port, seenCountryIds, countries!);
                        if (!isEmpty(portErrorMessages)) {
                            v.hasValidationErrors = true;
                            // Add Error message component for page
                            v.messages.errors = [
                                ...v.messages.errors,
                                ...errorMessageComponentsForPort(
                                    portErrorMessages,
                                    sp,
                                    ({field}) => focusedFieldCtrl.setFocusedField({stockProductId: asNumber(sp.id), field, rowIndex})
                                )
                            ]
                        }

                        return {
                            data: port,
                            infoMessages: portInfoMessages,
                            isAddedRow: port.id === -1,
                            rowIndex,
                            errorMessages: portErrorMessages,
                            validationAlerts: portValidationAlerts,
                        }
                    })

                    const productStatus: EntityStatus = !sp.isExpired ? 'active' :
                        data.id !== undefined && data.id != null && recordActionFromEnum(data.recordAction) !== 'Delete' ?
                        'expired_with_data' : 'expired';                    
                   
                    if (productStatus === 'expired_with_data') {
                        hasNonEmpty = true;
                        hasExpiredWithData = true;
                        v.messages.errors = [
                            ...v.messages.errors,
                            expiredProductMessageComponent(
                                sp, 
                                ({field}) => {
                                    focusedFieldCtrl.setFocusedField({stockProductId: asNumber(sp.id), field})
                                },
                            )
                        ]
                    } 
                    if (productStatus === 'active') {
                        hasNonEmpty = true;
                    } 
                    // Add validation/info to row
                    return {
                        data,
                        errorMessages: productErrorMessages,
                        infoMessages: productInfoMessages,
                        product: sp,
                        productStatus,
                        ports,
                        total,
                        validationAlerts: productValidationAlerts,
                    }
                });                

            return {
                productGroup: spg,
                products,
                groupStatus: spg.isExpired ?
                    hasExpiredWithData ? 'expired_with_data' : 'expired' :
                    hasNonEmpty ? 'active' : 'empty', 
            };
        });

        // Validate comments
        const trimmedComments = (v.comments.data?.comments || '').trim();
        if (v.isCommentsRequired && !trimmedComments) {
            v.comments.errorMessage = INVALID_COMMENTS_REQUIRED;
            v.hasValidationErrors = true;
            v.messages.errors = [
                ...v.messages.errors,
                <CommentsRequiredErrorMessage onTargetClick={() => focusedFieldCtrl.setFocusedField('comments')} />
            ];
        } else if (v.comments.data?.comments && trimmedComments.length > 0) {
            if (!isValidCommentMaxLength(trimmedComments)) {
                v.comments.errorMessage = INVALID_COMMENTS_LENGTH;
                v.messages.errors = [
                    ...v.messages.errors,
                    <CommentsErrorMessage onTargetClick={() => focusedFieldCtrl.setFocusedField('comments')} />
                ];
            }
            
            if (!v.comments.errorMessage && !isValidCommentCharacters(trimmedComments)) {
                v.comments.errorMessage = INVALID_COMMENTS_CHARACTERS;
                v.messages.errors = [
                    ...v.messages.errors,
                    <CommentsErrorMessage onTargetClick={() => focusedFieldCtrl.setFocusedField('comments')} />
                ];
            }
        }

        return v;
    }, [
        focusedFieldCtrl,
        updateSubmissionOnWater, 
        isActive, 
        productGroups.stockProductGroups, 
        stockholding.onWaterStockholdings, 
        stockholding.dataSubmission.reportVariances?.onWaterStockholdings, 
        countries, 
    ]);
    
    const updateProduct = useCallback(({field, stockProductId, value}: OnWaterUpdate) => {
        setChangedState('unsaved_changes');
        setUpdateSubmissionOnWater(prev => produce(prev, draft => {
            const rowIndex = prev.onWaterStockholdings?.findIndex(ds => ds.stockProductId === stockProductId) ?? -1;
            const val = value?.replaceAll(',', '');
            if (rowIndex > -1) {
                draft.onWaterStockholdings!![rowIndex] = {
                    ...draft.onWaterStockholdings!![rowIndex],
                    [field]: val,
                    recordAction: recordActionAsEnum((draft.onWaterStockholdings!![rowIndex].id || 0) > 0 ? 'Update' : 'Create'),
                };
            } else {
                draft.onWaterStockholdings?.push({
                    stockProductId,
                    [field]: val,
                    recordAction: recordActionAsEnum('Create'),
                });
            }
        }));
    }, []);

    const updatePort = useCallback(({field, rowIndex, stockProductId, value}: OnWaterPortsUpdate) => {
        setUpdateSubmissionOnWater(prev => produce(prev, draft => {
            const draftProduct = draft.onWaterStockholdings?.find(ows => ows.stockProductId === stockProductId);

            setChangedState('unsaved_changes');

            let val = field === 'quantity' ? String(value).replaceAll(',', '') : value;

            if (draftProduct == null) {
                draft.onWaterStockholdings?.push({
                    stockProductId,
                    recordAction: recordActionAsEnum('Create'),
                    stockholdingOnWaterPorts: [{
                        [field]: val,
                        recordAction: recordActionAsEnum('Create'),
                    }]
                });
                return;
            }

            if (draftProduct.stockholdingOnWaterPorts == null) {
                draftProduct.recordAction = recordActionAsEnum('Update');
                draftProduct.stockholdingOnWaterPorts = [{
                    [field]: val,
                    recordAction: recordActionAsEnum('Create'),
                }];
                return;
            }

            if (draftProduct.stockholdingOnWaterPorts![rowIndex] != null) {
                draftProduct.stockholdingOnWaterPorts![rowIndex] = {
                    ...draftProduct.stockholdingOnWaterPorts![rowIndex],
                    [field]: val,
                    recordAction: recordActionAsEnum((draftProduct.stockholdingOnWaterPorts![rowIndex].id || 0) > 0 ? 'Update' : 'Create'),
                };
            } else {
                draftProduct.stockholdingOnWaterPorts!.push({
                    [field]: val,
                    recordAction: recordActionAsEnum('Create'),
                });
            }

            draftProduct.recordAction = recordActionAsEnum((draftProduct.id || 0) > 0 ? 'Update' : 'Create');
        }));
    }, []);

    // Update comments value
    const updateComments = useCallback((comments: string) => {
        setChangedState('unsaved_changes');

        setUpdateSubmissionOnWater(prev => produce(prev, draft => {
            draft.stockholdingComment = {
                ...(draft.stockholdingComment || {}),
                comments,
                stockTypeId: onWaterStockTypeRef.current?.id,
                recordAction: recordActionAsEnum(
                    draft.stockholdingComment?.id ?
                    'Update' :
                    'Create'
                ),
            };
        }));
    }, [onWaterStockTypeRef]);

    const addPort = useCallback((stockProductId: number | undefined) => {
        if (stockProductId == null) {
            return;
        }

        setChangedState('unsaved_changes');

        setUpdateSubmissionOnWater(prev => produce(prev, draft => {
            const draftProduct = draft.onWaterStockholdings?.find(ows => ows.stockProductId === stockProductId);

            const newPort = {
                id: -1,
                stockholdingOnWaterId: draftProduct?.id,
                recordAction: recordActionAsEnum('Create'),
            };

            if (draftProduct === undefined) {
                draft.onWaterStockholdings?.push({
                    stockProductId,
                    stockholdingOnWaterPorts: [newPort],
                    recordAction: recordActionAsEnum('Create')
                })
            } else {
                draftProduct.stockholdingOnWaterPorts?.push(newPort);
                draftProduct.recordAction = recordActionAsEnum((draftProduct.id || 0) > 0 ? 'Update' : 'Create');
            }
        }));
    }, []);

    const actionDeletePort = useCallback((stockProductId: number | undefined, rowIndex: number) => {
        if (stockProductId == null) {
            return;
        }

        setUpdateSubmissionOnWater(prev => produce(prev, draft => {
            const draftProduct = draft.onWaterStockholdings?.find(ows => ows.stockProductId === stockProductId);

            if (
                draftProduct == null ||
                draftProduct.stockholdingOnWaterPorts == null ||
                draftProduct.stockholdingOnWaterPorts[rowIndex] == null
            ) {
                return;
            }

            setChangedState('unsaved_changes');

            if (!draftProduct.stockholdingOnWaterPorts[rowIndex].id) {
                draftProduct.stockholdingOnWaterPorts.splice(rowIndex, 1);
            }
            else {
                draftProduct.stockholdingOnWaterPorts[rowIndex] = {
                    ...draftProduct.stockholdingOnWaterPorts[rowIndex],
                    recordAction: recordActionAsEnum('Delete'),
                };
            }
            if (draftProduct.id) {                
                draftProduct.recordAction = recordActionAsEnum('Update');
            }
            
        }))
    }, []);

    const actionDeleteAllProductRows = useCallback((stockProductId: number | undefined) => {
        if (stockProductId == null) {
            return;
        }

        setChangedState('unsaved_changes');
        
        setUpdateSubmissionOnWater(prev => produce(prev, draft => {            
            const draftProduct = draft.onWaterStockholdings?.find(ows => ows.stockProductId === stockProductId);

            if (
                draftProduct == null
            ) {
                return;
            }

            setChangedState('unsaved_changes');

            if (draftProduct.stockholdingOnWaterPorts != null) {
                draftProduct.stockholdingOnWaterPorts =
                    draftProduct.stockholdingOnWaterPorts
                    .filter(x => x.id !== undefined)
                    .map(x => {
                        return {
                            ...x,
                            recordAction: recordActionAsEnum('Delete')
                        };
                    });
            }
            if (draftProduct.id) {                
                draftProduct.recordAction = recordActionAsEnum('Delete');
            }
        }));
    }, []);

    const deletePort = useCallback((stockProductId: number | undefined, rowIndex: number, source: HTMLElement | null) => {
        setDeleteRequestState({
            deleteState: 'showing_dialog',
            id: stockProductId,
            rowIndex: rowIndex,
            message: 'This on water location record will be deleted.',
            source,
        });
    }, []);

    const getUpdateRequestBody = useCallback(() => {
        const escapedComments: UpdateStockholdingComment | undefined = view.comments.data ?
            {
                ...view.comments.data,
                comments: encodeEscapeChars(view.comments.data?.comments?.trim())
            } :
            undefined;
        // Convert formData back to submissionVM and remove empty rows
        const onWaterStockholdings = view.groups
            .map(group => group.products)
            .reduce((memo, products) => [...memo, ...products], [])
            .map(product => ({
                ...product.data,
                domesticShipping: !isEmpty(product.data.domesticShipping) ? Number(product.data.domesticShipping) : null,
                eez: !isEmpty(product.data.eez) ? Number(product.data.eez) : null,
                highSeas: !isEmpty(product.data.highSeas) ? Number(product.data.highSeas) : null,
                stockholdingOnWaterPorts: product.ports
                    .map(port => port.data)
                    .map(port => {
                        const {id, stockholdingOnWaterId, concurrencyToken, recordAction, ...rest} = port;
                        return {
                            ...port,
                            quantity: !isEmpty(port.quantity) ? Number(port.quantity) : null,
                            recordAction: isEmptyPort(rest) ?
                                recordActionAsEnum('Delete') :
                                // Add missing recordActions, e.g. for imported data
                                (!isPersisted(port) && port.recordAction == null ? recordActionAsEnum('Create') : port.recordAction),
                        };
                    })
                    .filter(port => {
                        return isPersisted(port) ?
                            port.recordAction != null :
                            // Keep unpersisted, non-empty ports if not deleted
                            recordActionFromEnum(port.recordAction) !== 'Delete' && !isEmptyPort(port);
                    })
                    .filter(port => port.recordAction != null)
                    .map(port => {
                        const {id, ...rest} = port;
                        if (id === -1) {
                            return {...rest};
                        }

                        return port;
                    }),
                portCount: (product.data.stockholdingOnWaterPorts || []).length,
            }))
            .map(d => {
                const {id, concurrencyToken, stockProductId, recordAction, stockholdingOnWaterPorts, portCount, ...rest} = d;
                return {
                    ...d,
                    stockholdingOnWaterPorts,
                    recordAction: !isEmptyOnWater(rest) ?
                        (!isPersisted(d) && d.recordAction == null ?
                            recordActionAsEnum('Create') :
                            d.recordAction
                        ) :
                        stockholdingOnWaterPorts.length > 0 ? 
                            (isPersisted(d) ?
                                (all(stockholdingOnWaterPorts, p => recordActionFromEnum(p.recordAction) === 'Delete' || isEmptyPort(p)) ?
                                    recordActionAsEnum('Delete') :
                                    recordActionAsEnum('Update')
                                ) :
                                recordActionAsEnum('Create')
                            ) :
                            // Delete only if there are no unchanged child ports
                            portCount > stockholdingOnWaterPorts.length ? undefined : recordActionAsEnum('Delete'),
                };
            })
            .map(d => {
                const {portCount, ...data} = d;
                return data;
            })
            .filter(ow => isPersisted(ow) ?
                ow.recordAction != null :
                ow.recordAction != null && recordActionFromEnum(ow.recordAction) !== 'Delete'
            )
            .filter(ow => ow.recordAction != null);
        
        return {
            dataSubmissionId: view.dataSubmissionId,
            onWaterStockholdings,
            stockholdingComment: (
                escapedComments?.id == null && recordActionFromEnum(escapedComments?.recordAction) === 'Delete' ?
                    undefined :
                    escapedComments
            ),
        };
    }, [view]);

    // Update form data when server vm is updated
    useEffect(() => {
        if (isActive) {
            let newChangeState: ChangedState = 'unchanged';
            const currentData = updateSubmissionOnWaterFromStockholding(stockholding, onWaterStockTypeRef.current);
            if (importCtrl.templateImportState.templateImportDialogState === 'processing' && importCtrl.templateImportState && importCtrl.templateImportState.data && 
                importCtrl.templateImportState.data.onWaterStockholdings && importCtrl.templateImportState.data.submissionFormData?.onWaterPageSaved !== true) {
                const submissionData = currentData.onWaterStockholdings ?? [];
                const submissionCommentData = currentData.stockholdingComment;
                currentData.onWaterStockholdings = [];
                const newData: UpdateStockholdingOnWaterVM[] = [];
                
                productGroups.stockProductGroups?.forEach(spg => {
                    spg.stockProducts.forEach(sp => {
                        let data = importCtrl.templateImportState.data?.onWaterStockholdings?.find(p => p.stockProductId === sp.id);
                        let existingData = submissionData?.find(p => p.stockProductId === sp.id);
                        
                        if (data !== undefined) {    
                            if (existingData !== undefined) {                            
                                const newOWP: UpdateStockholdingOnWaterPorts[] = data?.stockholdingOnWaterPorts?.map(owp => {
                                    const existingOWP = existingData?.stockholdingOnWaterPorts?.find(x => x.countryId === owp.countryId);
                                    const { recordResult, validationAlerts, ...updateOWP } = owp;
                                    if (existingOWP) {
                                        return {
                                            ...updateOWP,
                                            id: existingOWP.id == null ? undefined : existingOWP.id,
                                            concurrencyToken: existingOWP.concurrencyToken == null ? undefined : existingOWP.concurrencyToken,
                                            recordAction: recordActionAsEnum('Update')
                                        }
                                    }
                                    return {
                                        ...updateOWP,
                                        recordAction: recordActionAsEnum('Create')
                                    }
                                }) ?? [];
                                const matchedIds = newOWP?.filter(x => x.id !== undefined && x.id != null)?.map(x => x.id ?? 0) ?? [];
                                if (existingData.stockholdingOnWaterPorts) {
                                    existingData.stockholdingOnWaterPorts.filter(x => !matchedIds.includes(x.id ?? 0)).forEach(owp => {
                                        newOWP.push({
                                            ...owp,
                                            recordAction: recordActionAsEnum('Delete')
                                        })
                                    })
                                }
                                newData.push({
                                    ...data,
                                    stockholdingOnWaterPorts: newOWP,
                                    id: existingData.id == null ? undefined : existingData.id,
                                    concurrencyToken: existingData.concurrencyToken == null ? undefined : existingData.concurrencyToken,
                                    recordAction: recordActionAsEnum('Update')
                                });
                            } else {
                                const newOWP = data?.stockholdingOnWaterPorts?.map(owp => {
                                    return {
                                        ...owp,
                                        recordAction: recordActionAsEnum('Create')
                                    }
                                });
                                newData.push({
                                    ...data,
                                    stockholdingOnWaterPorts: newOWP,
                                    recordAction: recordActionAsEnum('Create')
                                });
                            }
                        } else {
                            if (existingData !== undefined) {
                                const newOWP = existingData?.stockholdingOnWaterPorts?.map(owp => {
                                    return {
                                        ...owp,
                                        recordAction: recordActionAsEnum('Delete')
                                    }
                                });
                                newData.push({
                                    ...existingData,
                                    domesticShipping: null,
                                    eez: null,
                                    highSeas: null,
                                    stockholdingOnWaterPorts: newOWP,
                                    recordAction: recordActionAsEnum('Delete')
                                });
                            }
                        }
                    });
                });
                currentData.onWaterStockholdings = newData;

                if (submissionCommentData !== undefined)
                {
                    currentData.stockholdingComment = {
                        ...submissionCommentData,
                    }
                }
                
                newChangeState = 'unsaved_changes';
            }

            setUpdateSubmissionOnWater(currentData);
            setChangedState(newChangeState);        
        }
    }, [importCtrl.templateImportState, isActive, onWaterStockTypeRef, productGroups.stockProductGroups, stockholding]);

    useEffect(() => {
        switch (deleteRequestState.deleteState) {
            case 'cancelled':
                setDeleteRequestState(idleDeleteRequest);
                break;
            case 'confirmed':
                if (deleteRequestState.id !== undefined) {
                    deleteRequestState.rowIndex !== undefined ? 
                        actionDeletePort(deleteRequestState.id, deleteRequestState.rowIndex) :
                        actionDeleteAllProductRows(deleteRequestState.id);
                    
                    if (deleteRequestState.source != null) {
                        focusNext(deleteRequestState.source);
                    }
                }
                setDeleteRequestState(idleDeleteRequest);
                break;
        }
    }, [actionDeletePort, deleteRequestState, actionDeleteAllProductRows]);

    const shouldForceErrors = useMemo(() => (
        importCtrl.templateImportState.templateImportDialogState === 'processing'
    ), [importCtrl.templateImportState.templateImportDialogState]);

    return useMemo(() => ({
        addPort,
        changedState,
        deletePort,
        deleteRequestState,
        focusedFieldCtrl,
        getUpdateRequestBody,
        portalDataAPICtrl,
        setDeleteRequestState,
        deleteAllProductRows,
        shouldForceErrors,
        stockType,
        updateComments,
        updatePort,
        updateProduct,
        updateSubmissionOnWater,
        view,
    }), [
        addPort,
        changedState,
        deletePort,
        deleteRequestState,
        focusedFieldCtrl,
        getUpdateRequestBody,
        portalDataAPICtrl,
        setDeleteRequestState,
        deleteAllProductRows,
        shouldForceErrors,
        stockType,
        updateComments,
        updatePort,
        updateProduct,
        updateSubmissionOnWater,
        view,
    ]);
}

export default usePageOnWater;

export type UsePageOnWater = ReturnType<typeof usePageOnWater>;

function expiredProductMessageComponent(
    product: StockProduct, 
    onClick: (args: {field: 'delete'}) => any,
    ): ReactNode {

    return <LocationErrorMessage
        key={product.id}
        onClick={() => window.setTimeout(() => {
            onClick({
                field: 'delete',
            })}, 100
        )}
        productName={product.productName as string}
        validationCode='INACTIVE_INTERNAL_PRODUCT'
    />;
}

function updateSubmissionOnWaterFromStockholding(stockholding: Stockholding, stockType: StockType | undefined): UpdateStockholdingSubmissionOnWater {
    let stockholdingComment = stockholding.stockholdingComments
        ?.find(c => c.stockTypeId === stockType?.id && c.recordResult?.rowResult !== 'Deleted');
    if (stockholdingComment != null) {
        stockholdingComment = removeServerFields(stockholdingComment);
    }

    return {
        dataSubmissionId: stockholding.dataSubmission.id,
        stockholdingComment,
        onWaterStockholdings: (stockholding.onWaterStockholdings || [])
            .filter(os => os.recordResult?.rowResult !== 'Deleted')
            .map (os => ({
                ...os,
                stockholdingOnWaterPorts: os.stockholdingOnWaterPorts
                    ?.filter(port => port.recordResult?.rowResult !== 'Deleted')
                    ?.map(removeServerFields)
            }))
            .map(removeServerFields),
    }
}

function totalForProduct(product: UpdateStockholdingOnWater) {
    return (
        asNumber(product.domesticShipping) +
        asNumber(product.eez) +
        asNumber(product.highSeas) +
        (product.stockholdingOnWaterPorts || [])
            .filter(port => recordActionFromEnum(port.recordAction) !== 'Delete')
            .reduce((sum, port) => sum + asNumber(port.quantity), 0)
    );
}

function existsAndIsNegativeNumber(val: number | undefined | null) {
    if (val == null) {
        return false;
    }

    return val < 0;
}

function infoMessageForProductField(product: UpdateStockholdingOnWater, field: OnWaterField): OnWaterInfoMessages {
    return {
        ...(existsAndIsNegativeNumber(product[field]) ? {
            [field]: {
                code: 'negativeValue',
                message: INFO_NEGATIVE_VALUE_COMMENTS,
            }
         } : {}),
    };
}

function infoMessagesForProduct(product: UpdateStockholdingOnWater, validationAlerts: Array<ValidationAlert>, variance?: number | null): OnWaterInfoMessages {
    if (recordActionFromEnum(product.recordAction) === 'Delete') {
        return {};
    }

    const allZero = asNumber(product.domesticShipping) === 0 &&
                    asNumber(product.eez) === 0 &&
                    asNumber(product.highSeas) === 0 &&
                    (product.stockholdingOnWaterPorts || [])
                        .filter(port => recordActionFromEnum(port.recordAction) !== 'Delete')
                        .reduce((prev, port) => prev && asNumber(port.quantity) === 0, true);

    const anyRowsChanged = recordActionFromEnum(product.recordAction) === 'Update' ||
                           any(product.stockholdingOnWaterPorts || [], p => recordActionFromEnum(p.recordAction) === 'Update');

    const previouslyReportedAlert = validationAlerts.find(va => isValidationAlert(va, 'PreviouslyReportedAlert') && allZero);
    const reportVarianceAlert = validationAlerts.find(va => isValidationAlert(va, 'PercentVarianceApplied'));

    return {
        ...infoMessageForProductField(product, 'domesticShipping'),
        ...infoMessageForProductField(product, 'eez'),
        ...infoMessageForProductField(product, 'highSeas'),
        ...(previouslyReportedAlert ? {
            total: {
                code: 'previouslyReported',
                message: previouslyReportedAlert.message,
            }
         } : ((reportVarianceAlert && !anyRowsChanged && variance != null) ? {
            total: {
                code: 'reportVariance',
                message: INFO_REPORT_VARIANCE(variance),
            }
         } : {})),
    };
}

function infoMessagesForPort(port: UpdateStockholdingOnWaterPorts): OnWaterPortsInfoMessages {
    return {
        ...((recordActionFromEnum(port.recordAction) !== 'Delete' && existsAndIsNegativeNumber(port.quantity)) ? {
            quantity: {
                code: 'negativeValue',
                message: INFO_NEGATIVE_VALUE_COMMENTS,
            }
        } : {}),
    };
}

const IS_BETWEEN_OPTS = {max: 100000000, min: -100000000};

function validateQuantity(val: number | null | undefined) {
    return val != null ? (
        (!isBetween(val, IS_BETWEEN_OPTS) || !isInteger(val) ? {
            code: 'QUANTITY_INVALID' as StockholdingLocationValidationCode,
            message: INVALID_QUANTITY,
        } : undefined)
    ) : undefined;
}

function errorMessagesForProduct(product: UpdateStockholdingOnWater): OnWaterErrorMessages {
    if (recordActionFromEnum(product.recordAction) === 'Delete') {
        return {};
    }

    return {
        domesticShipping: validateQuantity(product.domesticShipping),
        eez: validateQuantity(product.eez),
        highSeas: validateQuantity(product.highSeas),
    };
}

function errorMessagesForPort(port: UpdateStockholdingOnWaterPorts, seenCountryIds: Set<number>, allCountries: Array<Country>): OnWaterPortsErrorMessages {
    let quantityValidation: ValidationError<StockholdingLocationValidationCode> | undefined = undefined;
    let countryIdValidation: ValidationError<StockholdingLocationValidationCode> | undefined = undefined;
    
    if (recordActionFromEnum(port.recordAction) === 'Delete') {
        return {}
    }

    if (!isEmpty(port.quantity)) {
        quantityValidation = validateQuantity(port.quantity);

        if (isEmpty(port.countryId)) {
            countryIdValidation = {
                code: 'COUNTRY_REQUIRED',
                message: INVALID_COUNTRY,
            }
        }
    } else if (!isEmpty(port.countryId)) { 
        quantityValidation = {
            code: 'QUANTITY_REQUIRED',
            message: INVALID_QUANTITY_REQUIRED,
        }
    }

    if (!isEmpty(port.countryId)) {
        if (seenCountryIds.has(port.countryId as number)) {
            countryIdValidation = {
                code: 'COUNTRY_CONFLICT',
                message: INVALID_COUNTRY_CONFLICT,
            }
        }
        seenCountryIds.add(port.countryId as number);

        if (allCountries.some(c => c.id === port.countryId && c.isInternal === true)){
            countryIdValidation = {
                code: 'INVALID_COUNTRY_ISINTERNAL_SELECTED',
                message: INVALID_COUNTRY_ISINTERNAL_SELECTED,
            }
        }
    }

    return {
        countryId: countryIdValidation,
        quantity: quantityValidation,
    };
}

type ProductMessageClickArgs = {
    field: OnWaterField;
}

type PortMessageClickArgs = {
    field: OnWaterPortsField;
}

function errorMessageComponentsForProduct(
    messages: OnWaterErrorMessages,
    product: StockProduct,
    onClick: (args: ProductMessageClickArgs) => any
): Array<ReactNode> {
    const entries = Object.entries(messages)
        .filter(([_, v]) => v != null);

    return [
        ...(entries.map(([field, error]) =>
            <QuantityInvalidErrorMessage
                key={field}
                onClick={() => onClick({
                    field: field as OnWaterField,
                })}
                label={product.productName as string}
            />
        ))
    ];
}

function errorMessageComponentsForPort(
    messages: OnWaterPortsErrorMessages,
    product: StockProduct,
    onClick: (args: PortMessageClickArgs) => any
): Array<ReactNode> {
    const entries = Object.entries(messages)
        .filter(([_, v]) => v != null);

    return [
        ...(entries.map(([field, error]) =>
            <LocationErrorMessage
                key={field}
                onClick={() => onClick({
                    field: field as OnWaterPortsField,
                })}
                productName={product.productName as string}
                validationCode={error.code}
            />
        ))
    ]
}

function infoMessageComponentsForProduct(
    messages: OnWaterInfoMessages,
    product: StockProduct,
    onClick: (args: ProductMessageClickArgs) => any,
    onCommentClick: () => any,
    variance?: number | null
): Array<ReactNode> {
    const entries = Object.entries(messages)
        .filter(([_, v]) => v != null);

    return [
        ...(entries.map(([field, msg]) => (
            msg.code === 'negativeValue' ?
            <InfoNegativeValueMessage
                onCommentTargetClick={onCommentClick}
                onTargetClick={() => onClick({
                    field: field as OnWaterField,
                })}
                label={product.productName as string}
            /> : (
                msg.code === 'previouslyReported' ?
                <InfoPreviouslyReportedMessage
                    onCommentTargetClick={onCommentClick}
                    onTargetClick={() => onClick({
                        field: 'highSeas',
                    })}
                    label={product.productName as string}
                    reportingTypeLabel='stockholding'
                /> : (msg.code === 'reportVariance' && variance != null) ?
                    <InfoReportVarianceMessage
                        onCommentTargetClick={onCommentClick}
                        onTargetClick={() => onClick({
                            field: 'highSeas',
                        })}
                        label={product.productName as string}
                        variance={variance}
                    /> :
                    null
            )
        )))
    ]
}

function infoMessageComponentsForPort(
    messages: OnWaterPortsInfoMessages,
    product: StockProduct,
    onClick: (args: PortMessageClickArgs) => any,
    onCommentClick: () => any
): Array<ReactNode> {
    const entries = Object.entries(messages)
        .filter(([_, v]) => v != null);

    return [
        ...(entries.map(([field, msg]) => (
            msg.code === 'negativeValue' ?
            <InfoNegativeValueMessage
                onCommentTargetClick={onCommentClick}
                onTargetClick={() => onClick({
                    field: field as OnWaterPortsField,
                })}
                label={product.productName as string}
            /> :
            <InfoPreviouslyReportedMessage
                onCommentTargetClick={onCommentClick}
                onTargetClick={() => onClick({
                    field: 'quantity',
                })}
                label={product.productName as string}
                reportingTypeLabel='stockholding'
            />
        )))
    ]
}

type WithId = {
    id?: number | null | undefined;
}

function isPersisted(entity: WithId) {
    return (entity.id || -1) > 0;
}

function isEmptyPort(port: UpdateStockholdingOnWaterPorts) {
    return isEmpty(port.quantity) && isEmpty(port.countryId);
}

function isEmptyOnWater(ow: UpdateStockholdingOnWater) {
    return isEmpty(ow.domesticShipping) &&
           isEmpty(ow.eez) &&
           isEmpty(ow.highSeas) &&
           all(ow.stockholdingOnWaterPorts || [], isEmptyPort);
}

export function isProductFocusField(maybe: FocusField | null): maybe is OnWaterRowFocusField {
    const asOnWaterFocusField = maybe as OnWaterRowFocusField;
    return asOnWaterFocusField != null && asOnWaterFocusField.field != null && (
        asOnWaterFocusField.field === 'domesticShipping' ||
        asOnWaterFocusField.field === 'eez' ||
        asOnWaterFocusField.field === 'highSeas'
    );
}

export function isPortFocusField(maybe: FocusField | null): maybe is OnWaterPortsRowFocusField {
    const asOnWaterPortsFocusField = maybe as OnWaterPortsRowFocusField;
    return asOnWaterPortsFocusField != null && asOnWaterPortsFocusField.field != null && (
        asOnWaterPortsFocusField.field === 'quantity' ||
        asOnWaterPortsFocusField.field === 'countryId'
    );
}

function isOnWaterField(maybe?: unknown): maybe is OnWaterField {
    const maybeAs = maybe as OnWaterField;

    return (
        maybeAs != null && (
            maybeAs === 'domesticShipping' ||
            maybeAs === 'eez' ||
            maybeAs === 'highSeas'
        )
    );
}
