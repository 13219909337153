import { UpdateDefActivityVM, DefActivityVM } from "psims/gen/xapi-client";
import { isRecordActionEnum } from "psims/models/api/data-submission-record-action";
import { RecordResult } from "psims/models/api/record-result";

export type DefActivity = PopulatedProps<
  DefActivityVM,
  'id' | 'closingStocks' | 'concurrencyToken' | 'defProductId' | 'derivedFromBlending' | 'derivedFromOtherProduction' | 'sales' | 'recordResult' | 'usedInBlending'
> & {
  recordResult: RecordResult;
};

export function isDefActivity(maybe?: unknown): maybe is DefActivity {
  const maybeAs = maybe as DefActivity;

  return (
    maybeAs != null &&
    maybeAs.concurrencyToken != null &&
    maybeAs.closingStocks != null &&
    maybeAs.defProductId != null &&
    maybeAs.id != null &&
    maybeAs.sales != null
  );
}

export type UpdateDefActivity = PopulatedProps<
  UpdateDefActivityVM,
  'defProductId' | 'closingStocks' | 'sales' | 'recordAction'
>;

export function isUpdateDefActivity(maybe: unknown): maybe is UpdateDefActivity {
  const maybeAs = maybe as UpdateDefActivity;

  return (
    maybeAs != null &&
    maybeAs.closingStocks != null &&
    maybeAs.defProductId != null &&
    maybeAs.sales != null &&
    isRecordActionEnum(maybeAs.recordAction)
  );
}

export type DefActivityField = Exclude<keyof DefActivity, 'id' | 'concurrencyToken' | 'recordResult' | 'dataSubmissionId' | 'defProductId' | 'validationAlerts'>;