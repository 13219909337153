import CompanyHeader from "psims/react/blocks/company-header";
import { BoxedDiv } from "psims/react/components/layout";
import { H1, H4 } from "psims/react/components/typography";
import { useNavigation } from "psims/react/providers/router";
import { useCallback, useMemo } from "react";
import Loading from "psims/react/components/loading";
import Notification from 'psims/react/components/notification';
import Button from 'psims/react/components/button';
import Filters from "./filters";
import Results from "./results";
import { useOrganisationMsoSettings } from "psims/react/providers/api/organisation-mso-settings";
import useFilters from "./use-filters";
import useResults from "./use-results";
import Pagination from "psims/react/blocks/pagination/pagination";
import usePagination from "psims/react/blocks/pagination/use-pagination";

const MinimumStockObligationSettingsPage = () => {
    const vm = useVM();

	if (vm.viewMode === 'loading') {
        return (
            <BoxedDiv box={{alignItems: 'center', flex: 'column', flexGrow: 1, justifyContent: 'center'}}>
                <Loading>Loading</Loading>
            </BoxedDiv>
        )
    }

	if (vm.viewMode === 'loaded') {
		return (
			<BoxedDiv box={{alignSelf: 'stretch', flex: 'column', flexGrow: 1, justifySelf: 'center'}}>
				<CompanyHeader />
	
				<div className='container'>
					<H1>Minimum stockholding obligations</H1>
					
					<p>
                        This page details the minimum stockholding obligations set for your company.
						Please contact the department if you believe any of the details are incorrect,
						or to apply for an amendment to your minimum stockholding obligation amounts.
                    </p>
					{
						vm.hasResults ?
						
						<>
							<BoxedDiv box={{alignItems: 'flex-end', flex: 'column', marginBottom: 1}}>
								<BoxedDiv box={{alignSelf: 'stretch', marginTop: 2.5}}>
									<Filters filtersCtrl={vm.filterCtrl} />
								</BoxedDiv>
							</BoxedDiv>
			
							<Results data={vm.data} />

							<Pagination paginationCtrl={vm.paginationCtrl} />
						</> :

						<BoxedDiv
							box={{
								borderBottom: {color: 'border-primary-faded', width: 1},
								borderTop: {color: 'border-primary-faded', width: 4},
								flex: 'row',
								justifyContent: 'center',
								marginTop: 4,
								paddingBottom: 1.5,
								paddingTop: 4,
							}}
						>
							<H4>There are no minimum stockholding obligations set for your company</H4>
						</BoxedDiv>
					}
	
				</div>
			</BoxedDiv>
		);
	}

	return (
		<div className='container'>
			<BoxedDiv box={{alignItems: 'center', flex: 'column', marginV: 4}}>
				<BoxedDiv box={{alignItems: 'center', flex: 'column', width: '600px'}}>
					<Notification align='center' kind='warning' >
						<p>
							There was a problem loading minimum stockholding obligations, please navigate back to your <Button $kind='text' label='Try again' onClick={vm.handleTryAgain}>'Home' page</Button> and try again.
						</p>
					</Notification>
				</BoxedDiv>
			</BoxedDiv>
		</div>
	)
};

const PAGE_SIZE_OPTIONS = [
	{value: 10},
	{value: 25},
	{value: 50},
	{label: 'Show all', value: Infinity},
]

function useVM() {
    const {organisationMsoSettings, status: organisationMsoSettingsStatus} = useOrganisationMsoSettings();
    const navigation = useNavigation();

    const filterCtrl = useFilters({initialShowExpired: false});
    const resultsCtrl = useResults({data: organisationMsoSettings, filterCtrl});
    const paginationCtrl = usePagination({
		items: resultsCtrl.results,
		initialPageSize: 50,
		pageSizeOptions: PAGE_SIZE_OPTIONS,
		resultsLabel: 'Minimum stockholding obligation setting result'
	});

	const handleTryAgain = useCallback(() => {
        navigation.goToHomePage();
    }, [navigation]);

	const viewMode = useMemo(() => {
        if (organisationMsoSettingsStatus === 'error') {
            return 'error';
        }

         if (organisationMsoSettingsStatus === 'fulfilled') {
            return 'loaded';
         }

        return 'loading';
    }, [organisationMsoSettingsStatus]);

	const hasResults = useMemo(() => {
		return viewMode === 'loaded' && (organisationMsoSettings || []).length > 0;
	}, [organisationMsoSettings, viewMode]);
	
	return {
        data: paginationCtrl.pageData,
        filterCtrl,
		hasResults,
        paginationCtrl,
		viewMode,
		handleTryAgain,
	};
}

export default MinimumStockObligationSettingsPage;
