import { BoxShadow, BOX_SHADOW_MAP, Color, COLOR_MAP, SpacingSize, SPACING_MAP } from "psims/constants/styles";

interface BorderConfig {
	color: Color;
	width?: 1 | 2 | 3 | 4;
}

export interface BorderProps {
	border?: BorderConfig;
	borderBottom?: BorderConfig;
	borderLeft?: BorderConfig;
	borderRight?: BorderConfig;
	borderTop?: BorderConfig;
	borderRadius?: SpacingSize;
	boxShadow?: BoxShadow;
}

export const borderStyle = (props: BorderProps) => ({
    ...(props.border ? {
        border: `${props.border.width || 1}px solid ${COLOR_MAP[props.border.color]}`
    } : {}),
    ...(props.borderBottom ? {
        borderBottom: `${props.borderBottom.width || 1}px solid ${COLOR_MAP[props.borderBottom.color]}`
    } : {}),
    ...(props.borderLeft ? {
        borderLeft: `${props.borderLeft.width || 1}px solid ${COLOR_MAP[props.borderLeft.color]}`
    } : {}),
    ...(props.borderRight ? {
        borderRight: `${props.borderRight.width || 1}px solid ${COLOR_MAP[props.borderRight.color]}`
    } : {}),
    ...(props.borderTop ? {
        borderTop: `${props.borderTop.width || 1}px solid ${COLOR_MAP[props.borderTop.color]}`
    } : {}),
    ...(props.borderRadius ? {borderRadius: SPACING_MAP[props.borderRadius]} : {}),
    ...(props.boxShadow ? {boxShadow: BOX_SHADOW_MAP[props.boxShadow]} : {}),
});
