import { UpdateFspEligibleProductionField } from "psims/models/submission-types/fsp/eligible-production";
import { Draft } from "./shared";

type ValidationResultRequired = {
  code: 'invalid_required';
}

export type ValidationResult = {
  field: UpdateFspEligibleProductionField;
} & ValidationResultRequired

export function validateEligibleProduction(draft: Draft) {
  const validationResults: Array<ValidationResult> = [];

  if (draft.data.volumeConfirmation == null) {
    validationResults.push({
      field: 'volumeConfirmation',
      code: 'invalid_required'
    });
  }

  return validationResults;
}
