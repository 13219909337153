export type SubmissionStatus = 'init' |
    'fetching' | 'fetched' | 'fetch_failed' |
    'updating' | 'updated' | 'update_failed' |
    'submitting' | 'submitted' | 'submit_failed';

const BUSY_STATUSES = {
    fetching: true,
    updating: true,
    submitting: true,
} as const;

export function isBusyStatus(status: SubmissionStatus) {
    const k = status as keyof typeof BUSY_STATUSES;
    return BUSY_STATUSES[k] === true;
}
