import { ProductionPageDataVM, UpdateProductionPageDataVM } from "psims/gen/xapi-client";

export type ProductionPageData = PopulatedProps<
    ProductionPageDataVM,
    'id' | 'concurrencyToken' | 'dataSubmissionId' | 'pageSaved'
>;

export type PlaceholderProductionPageData = {
    id: null;
    dataSubmissionId: number;
    pageSaved: false;
}

export function isPlaceholderProductionPageData(maybe?: unknown): maybe is PlaceholderProductionPageData {
    const maybeAs = maybe as PlaceholderProductionPageData;

    return (
        maybeAs != null &&
        maybeAs.id == null &&
        maybeAs.dataSubmissionId != null &&
        maybeAs.pageSaved != null
    );
}

export function isProductionPageData(maybe?: unknown): maybe is ProductionPageData {
    const maybeAs = maybe as ProductionPageData;

    return (
        maybeAs != null &&
        maybeAs.id != null &&
        maybeAs.concurrencyToken != null &&
        maybeAs.dataSubmissionId != null &&
        maybeAs.pageSaved != null
    );
}

export type UpdateProductionPageData = PopulatedProps<
    UpdateProductionPageDataVM,
    'dataSubmissionId'
>;

export function isUpdateProductionPageData(maybe?: unknown): maybe is UpdateProductionPageData {
    const maybeAs = maybe as UpdateProductionPageData;

    return (
        maybeAs != null &&
        maybeAs.dataSubmissionId != null
    );
}
