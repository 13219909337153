/**
 * Stock product group manager 
 * Provides:
 * - Filtering groups based on type
 * - Sorting groups based on displayOrder
 * - hydrating groups with sorted stockProducts
*/
import { useMemo } from "react";

import { asNumber } from "psims/lib/number";
import { StockTypeName } from "psims/models/ref-data/stock-type";
import { useReferenceData } from "psims/react/providers/api/reference-data";
import { isValidForPeriod } from "psims/models/ref-data/util";
import { HasReportingPeriod } from "../shared/types";

interface UseProductGroupsProps {
    dataSubmission: HasReportingPeriod;
    stockTypeName: StockTypeName;
}

function useProductGroups({dataSubmission, stockTypeName}: UseProductGroupsProps) {
    const {data: refData} = useReferenceData();

    const stockProductGroups = useMemo(() => refData?.stockProductGroups, [refData]);
    const stockProducts = useMemo(() => refData?.stockProducts, [refData]);

    const filteredStockProductGroups = useMemo(() => {
        if (!stockProductGroups) {
            return undefined;
        }

        return stockProductGroups
            .filter(spg => spg.stockTypeName === stockTypeName)
            .sort((a, b) => asNumber(a.displayOrder) - asNumber(b.displayOrder));
    }, [stockProductGroups, stockTypeName]);

    const hydratedStockProductGroup = useMemo(() => {
        if (!filteredStockProductGroups || !stockProducts) {
            return undefined;
        }

        const {reportingPeriodFrom, reportingPeriodTo} = dataSubmission;

        return filteredStockProductGroups.map(fspg => {
            const isGroupExpired = !isValidForPeriod(fspg, reportingPeriodFrom, reportingPeriodTo);
            return {
                ...fspg,
                isExpired: isGroupExpired,
                stockProducts: stockProducts
                    .filter(sp => sp.stockProductGroupId === fspg.id)
                    .sort((a, b) => asNumber(a.displayOrder) - asNumber(b.displayOrder))
                    .map(sp => ({
                        ...sp,
                        isExpired: isGroupExpired || !isValidForPeriod(sp, reportingPeriodFrom, reportingPeriodTo),
                    })),
            }
        });
    }, [dataSubmission, filteredStockProductGroups, stockProducts]);

    return useMemo(() => ({
        stockProductGroups: hydratedStockProductGroup,
    }), [hydratedStockProductGroup]);
}

export default useProductGroups;
