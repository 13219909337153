import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';

import { MaybeProduction, Production, ProductionDataSubmissionName, ProductionField, ProductionSubmission, UpdateProductionDraft, updateProductionToProductionData } from "psims/models/submission-types/production";
import { RecordAction, recordActionAsEnum, RecordActionEnum, recordActionFromEnum } from 'psims/models/api/data-submission-record-action';
import { asNumber, isNumeric, numberFieldValue } from 'psims/lib/number';
import { ViewMode } from 'psims/react/pages/primary-pages/data-submissions/shared/use-view-mode';
import { ErrorMessage, InfoMessage } from 'psims/react/pages/primary-pages/data-submissions/shared/field-messages';
import useFocusedField from 'psims/react/util/use-focused-field';
import { CalorificValueInvalidErrorMessage, DensityInvalidErrorMessage, DuplicateValueErrorMessage, ErrorExpiredProduct, InactiveProductionAreaErrorMessage, InfoNegativeValueMessage, InfoOpeningClosingStocksMismatchMessage, InfoOtherProductionAreaValueMessage, InfoSameAsPreviousMessage, QuantityInvalidErrorMessage, RowIncompleteInvalidErrorMessage } from '../shared/messages';
import { validateQuantity, validateComments, validateDecimalInRange } from '../shared/validation';
import { INACTIVE_PRODUCTIONAREA_FACILITY_NAME_ERROR, INCOMPLETE_ROW, INVALID_AVERAGEDENSITY_RANGE, INVALID_GROSSCALORIFICVALUE_RANGE, INVALID_PRODUCTION_AREA_FACILITY_NAME_CONFLICT, INVALID_PRODUCTION_EXPIRED, INVALID_VOLUME_INTEGER } from 'psims/constants/validation-messages';
import { INFO_NEGATIVE_VALUE_COMMENTS, INFO_OPENING_CLOSING_STOCKS_MISMATCH_COMMENTS, INFO_PRODUCTION_OTHER_FACILITY_NAME_COMMENTS } from 'psims/constants/info-messages';
import { DeleteRequestState, idleDeleteRequest } from '../shared/delete-confirmation';
import { ProductionAreaOption, SetActiveOrganisationProductionArea } from '../shared/production-area-option';
import { focusNext } from 'psims/lib/focus-util';
import { isEmpty } from 'psims/lib/empty';
import { UseTemplateImport } from 'psims/react/blocks/import/use-template-import';
import { ProductGroupRefData, ProductionProductRefData, UseProductionRefData } from './use-production-ref-data';
import { UseDataSubmissionProgress } from '../shared/use-data-submission-progress';
import { is } from 'psims/lib/type-assertions';
import { ProductionImportState as ImportState } from 'psims/react/blocks/import/types';
import { ApplicableFields, FieldConfig } from './util';
import { UseProductionValidationAlerts } from './use-production-validation-alerts';
import { ValidationAlertView } from './use-production-service-response';
import { ValidationAlertMaybe } from 'psims/models/api/submission/validation-alert';
import { DataSubmission } from 'psims/models/data-submission';
import { ProductionPageData, UpdateProductionPageData } from 'psims/models/submission-types/production-page-data';
import { EntityStatus } from '../shared/types';

interface UseProductionFormProps {
    applicableFields: ApplicableFields;
    refData: UseProductionRefData;
    submission: ProductionSubmission;
    viewMode: ViewMode;
    importCtrl: UseTemplateImport<ImportState>;
    progressCtrl: UseDataSubmissionProgress;
    currentStep: ProductionDataSubmissionName | 'Submit';
    validationAlerts: UseProductionValidationAlerts
}

export type ProductionView = {
    data: UpdateProductionDraft | null;
    productRefData: ProductionProductRefData;
    rowIndex: number;
    selectedProductionArea: ProductionAreaOption | null;
    infoMessages: Array<InfoMessage<ProductionField>>;
    isDeleted: boolean;
    productStatus: EntityStatus;
    validationErrors: Array<ErrorMessage<ProductionField | 'delete'>>;
}

export type ProductView = {
    productions: Array<ProductionView>;
    productRefData: ProductionProductRefData;
    productGroupRefData: ProductGroupRefData;
    rowIndex: number;
    totals: Totals;
}

type TotalField = Exclude<ProductionField, 'grossCalorificValue' | 'organisationProductionAreaId' | 'productionDensity'>;

type Totals = {
    [key in TotalField]: number;
}

export type GroupView = {
    isValid: boolean;
    groupStatus: EntityStatus | 'empty';
    productGroupRefData: ProductGroupRefData;
    products: Array<ProductView>;
};

export type DataPageView = {
    groups: Array<GroupView>;
    comments: {
        data: UpdateProductionPageData | null;
        isRequired: boolean;
        validationError: {
            notification: {
                content: JSX.Element;
                message: string;
            }
        } | null;
    };
    isValid: boolean;
    productionAreaOptions: Array<ProductionAreaOption>;
}

type FocusFieldProduction = {
    field: ProductionField | 'delete',
    productId: number;
    rowIndex: number;
}

type FocusField = FocusFieldProduction | 'comments';

export type UseProductionForm = ReturnType<typeof useProductionForm>;

type UpdateProductionByProductMap = {
    [ProductionId: number]: Array<UpdateProductionDraft>
}

type AllOrNothingStatus = 'start' | 'all' | 'nothing' | 'invalid';

interface IsStepSavedData {
    pageData: {
        pageSaved: boolean;
    }
}

const isStepSaved = (data: IsStepSavedData, stepKind: ProductionDataSubmissionName | 'Submit') => {
    return stepKind === 'Submit' ? false : data.pageData.pageSaved;
}

const fieldDisplayOrder: { [field in ProductionField]: number } = {
    organisationProductionAreaId: 1,
    grossCalorificValue: 2,
    productionDensity: 3,
    openingStocks: 4,
    produced: 5,
    consumed: 6,
    delivered: 7,
    closingStocks: 8,
};

function useProductionForm({
    applicableFields, refData, submission, viewMode, progressCtrl, importCtrl, currentStep, validationAlerts
}: UseProductionFormProps) {
    const { focusedField, setFocusedField } = useFocusedField<FocusField | null>();
    const [productionMap, setProductionMap] = useState(updateProductionMapByProductId(submission));
    const [dataPageComments, setDataPageComments] = useState(updateComments(submission));
    const [submissionComments, setSubmissionComments] = useState(submission.dataSubmission.comments);
    const [submissionCommentChanged, setSubmissionCommentChanged] = useState(false);
    const [declaration, setDeclaration] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [deleteRequestState, setDeleteRequestState] = useState<DeleteRequestState>(idleDeleteRequest);

    const isDisabled = useMemo(() => {
        return viewMode !== 'edit';
    }, [viewMode]);

    const productionAreaOptions = useMemo(() => {
        return refData.organisationProductionAreas.map(opa => ({
            data: SetActiveOrganisationProductionArea(submission.dataSubmission.reportingYear as number, submission.dataSubmission.reportingMonth as number, opa),
            label: opa.productionAreaName,
            value: opa.id,
        }))
            .sort((a, b) => a.data.displayOrder - b.data.displayOrder)
    }, [refData, submission]);

    const view = useMemo(() => {
        let commentsRequired = false;
        let isDataPageInvalid = false;

        // Derive data page view
        const dataPageGroups = refData.groups
            .map(group => {
                let isGroupValid = true;
                const products = group.products
                    .sort((a, b) => a.displayOrder - b.displayOrder)
                    .map((p, productRowIndex) => {
                        const productRefData = p;
                        const productId: number = p.id;
                        const seenProductionAreaIds = new Set<number>();
                        const productionsDataForProduct: Array<UpdateProductionDraft> = productionMap[productId] || [];
                        const productions = productionsDataForProduct
                            .map((production, rowIndex) => {
                                const persistedProduction = submission.productions.find(p => p.id === production.id);
                                const selectedProductionArea = productionAreaOptions.find(pao => pao.value === Number(production.organisationProductionAreaId)) || null;
                                const isDeleted = recordActionFromEnum(production.recordAction) === 'Delete';
                                const inactiveProductionArea = selectedProductionArea == null ? false : !selectedProductionArea.data.isActive;
                                const derivedApplicableFields = applicableFields(productRefData, group.refData);
                                const productionView: ProductionView = {
                                    data: production,
                                    isDeleted,
                                    productStatus: p.isExpired ? (production.id == null || recordActionFromEnum(production.recordAction) === 'Delete' ? 'expired' : 'expired_with_data') : 'active',
                                    productRefData,
                                    rowIndex,
                                    selectedProductionArea: selectedProductionArea,
                                    validationErrors: isDeleted || viewMode !== 'edit' ?
                                        [] :
                                        validationMessagesForProduction(
                                            production,
                                            inactiveProductionArea,
                                            setFocusedField,
                                            rowIndex,
                                            seenProductionAreaIds,
                                            derivedApplicableFields,
                                            productRefData,
                                            selectedProductionArea?.label
                                        ),
                                    infoMessages: isDeleted ? [] : infoMessagesForProduction(production, persistedProduction, setFocusedField, rowIndex, derivedApplicableFields, validationAlerts.validationAlertsForCurrentStep, selectedProductionArea?.label),
                                }

                                isGroupValid = isGroupValid && productionView.validationErrors.length === 0;
                                commentsRequired = commentsRequired || productionView.infoMessages.length > 0;
                                return productionView;
                            })
                            .filter(p => !p.isDeleted);

                        // Add an empty row if productions is empty
                        if (productions.length === 0) {
                            productions.push({
                                data: {
                                    productionProductId: productId,
                                    recordAction: null,
                                },
                                infoMessages: [],
                                isDeleted: false,
                                productStatus: p.isExpired ? 'expired' : 'active',
                                selectedProductionArea: null,
                                productRefData,
                                rowIndex: 0,
                                validationErrors: [],
                            });
                        }

                        const totals = calculateTotalsForProductGroup(productions);

                        isDataPageInvalid = isDataPageInvalid || !isGroupValid;

                        return {
                            productions,
                            rowIndex: productRowIndex,
                            productRefData: p,
                            productGroupRefData: group,
                            totals,
                        };
                    });

                const groupView: GroupView = {
                    groupStatus: group.refData.isExpired ? (
                        products.some(p => p.productions.some(pp => pp.productStatus === 'expired_with_data')) ? 'expired_with_data' : 'expired') :
                        (products.filter(p => p.productions.some(pp => pp.productStatus !== 'expired')).length === 0 ? 'empty' : 'active'),
                    isValid: isGroupValid,
                    productGroupRefData: group,
                    products,
                };

                return groupView;
            });

        // Validate data page comments
        const dataPageCommentsValidationError = validateComments(dataPageComments.comment, () => setFocusedField('comments'), commentsRequired);

        const isDataPageValid = !isDataPageInvalid && dataPageCommentsValidationError == null;

        const dataPageView: DataPageView = {
            groups: dataPageGroups,
            comments: {
                data: dataPageComments,
                isRequired: commentsRequired,
                validationError: dataPageCommentsValidationError,
            },
            isValid: isDataPageValid,
            productionAreaOptions,
        }

        // Validate submit page comments
        const submitPageHasValidationAlerts = dataSubmissionHasValidationAlerts(submission.dataSubmission);
        const commentValidationErrorBySubmissionAlert = submitPageHasValidationAlerts ? validateComments(submissionComments, () => setFocusedField('comments'), submitPageHasValidationAlerts) : null;
        const commentsValidationErrorByInput = validateComments(submissionComments, () => setFocusedField('comments'), false);
        const CommentsValidationError = commentValidationErrorBySubmissionAlert != null ? commentValidationErrorBySubmissionAlert : commentsValidationErrorByInput;
        const isSubmitPageValid = commentsValidationErrorByInput != null ? false : true;

        let comments = submissionComments;
        if (viewMode === 'edit' && progressCtrl.currentStep.kind === 'submit' &&
            importCtrl.templateImportState.templateImportDialogState === 'processing' &&
            (importCtrl.templateImportState.submitSaved === undefined || importCtrl.templateImportState.submitSaved !== true) &&
            submissionCommentChanged !== true) {
            comments = null;
        }

        // Derive submitPage view
        const submitPage = {
            comments: comments,
            CommentsValidationError,
            declaration: viewMode === 'edit' ? declaration : true,
            isValid: isSubmitPageValid,
            infoNotifications: getInfoMessagesForSubmitPage(submission.dataSubmission.validationAlerts, () => setFocusedField('comments'))
        };

        // Entire data submission validity
        const isValid = (
            isDataPageValid &&
            isSubmitPageValid
        );

        return {
            dataPageView,
            Submit: submitPage,
            shouldForceErrors: (
                progressCtrl.navAttemptedForStep ||
                importCtrl.templateImportState.templateImportDialogState === 'processing'
            ),
            focusedField,
            hasUnsavedChanges,
            isValid,
            isDisabled,
        }
    }, [
        applicableFields,
        productionMap,
        dataPageComments,
        declaration,
        focusedField,
        hasUnsavedChanges,
        importCtrl.templateImportState.submitSaved,
        importCtrl.templateImportState.templateImportDialogState,
        isDisabled,
        productionAreaOptions,
        progressCtrl.currentStep.kind,
        progressCtrl.navAttemptedForStep,
        refData, setFocusedField,
        submission,
        submissionCommentChanged,
        submissionComments,
        validationAlerts.validationAlertsForCurrentStep,
        viewMode,
    ]);

    const updateProduction = useCallback(
        <K extends ProductionField>(
            productionProductId: number,
            rowIndex: number,
            field: K,
            value: string | undefined,
        ) => {
            setProductionMap(prev => produce(prev, draft => {
                draft[productionProductId] = draft[productionProductId] || [];

                let production = draft[productionProductId][rowIndex] || {
                    productionProductId,
                };

                let idx = rowIndex;
                if (recordActionFromEnum(production.recordAction) === 'Delete') {
                    production = {
                        productionProductId,
                        recordAction: recordActionAsEnum('Create'),
                    };

                    // Use new index to retain deleted row
                    idx = draft[productionProductId].length;
                }

                if (production != null) {
                    const nextProduction = { ...production };

                    nextProduction[field] = value === undefined || value === '' ? null : value?.replaceAll(',', '') as unknown as number;

                    const recordAction: RecordAction = nextProduction.id ? 'Update' : 'Create';
                    draft[productionProductId][idx] = {
                        ...nextProduction,
                        recordAction: recordActionAsEnum(recordAction),
                    }
                }
            }));

            // Compare update to persisted data before triggering unsaved change
            const serverProductions = submission.productions.filter(p => p.productionProductId === productionProductId);
            const targetProduction = serverProductions[rowIndex];

            if (targetProduction) {
                const serverValue = targetProduction[field];
                const vNum = value as unknown as number;
                if (vNum !== serverValue) {
                    setHasUnsavedChanges(true);
                }
            } else if ((value?.length || 0) > 0) {
                setHasUnsavedChanges(true);
            }
        }, [submission]);


    const updateProductionComment = useCallback((comments: string) => {
        setDataPageComments(prev => {
            const comment: UpdateProductionPageData = {
                ...(prev || {}),
                 comment: comments,
            };

            comment.comment = comments;

            if (isEmptyUpdateComment(comment)) {
                comment.recordAction = recordActionAsEnum('Delete');
            } else {
                const recordAction: RecordAction = comment.id ? 'Update' : 'Create';
                comment.recordAction = recordActionAsEnum(recordAction);
            }

            return comment;
        });

        setHasUnsavedChanges(true);
    }, []);

    const addProduction = useCallback((productionId: number) => {
        setProductionMap(prev => produce(prev, draft => {
            draft[productionId] = draft[productionId] || [];

            draft[productionId].push({
                productionProductId: productionId,
                recordAction: recordActionAsEnum('Create')
            });

        }));

        setHasUnsavedChanges(true);
    }, []);

    const actionDeleteProduction = useCallback((productId: number, rowIndex: number) => {
        setProductionMap(prev => produce(prev, draft => {
            draft[productId] = draft[productId] || [];

            const production = draft[productId][rowIndex];

            if (production != null) {
                const toBeRemovedProduction = { ...production };

                if (!toBeRemovedProduction.id) {
                    draft[productId].splice(rowIndex, 1);
                }
                else {
                    const recordAction: RecordAction = 'Delete';
                    draft[productId][rowIndex] = {
                        ...toBeRemovedProduction,
                        recordAction: recordActionAsEnum(recordAction),
                    }
                }
            }
        }));
        setHasUnsavedChanges(true);
    }, []);

    const deleteProduction = useCallback((productId: number, rowIndex: number, source: HTMLElement | null) => {
        setDeleteRequestState({
            deleteState: 'showing_dialog',
            id: productId,
            rowIndex: rowIndex,
            message: 'This facility name record will be deleted.',
            source,
        });
    }, []);

    const updateDataSubmissionComment = useCallback((comments: string | undefined) => {
        setSubmissionComments(comments);
        setSubmissionCommentChanged(true);
        setHasUnsavedChanges(true);
    }, []);

    const resetForm = useCallback(() => {
        setDeleteRequestState(idleDeleteRequest);
        setProductionMap(updateProductionMapByProductId(submission));
        setDataPageComments(updateComments(submission));
        setSubmissionComments(submission.dataSubmission.comments);
        setSubmissionCommentChanged(false);
        setHasUnsavedChanges(false);
    }, [submission]);

    const clearDeclaration = useCallback(() => {
        setDeclaration(false);
    }, []);

    // Get the current update data for a productId
    const getUpdateProductionsByProductionId = useCallback((productId: number) => {
        return productionMap[productId] || [];
    }, [productionMap]);

    // Get the current update data for a productGroupId
    const getUpdateProductions = useCallback(() => {
        return Object.values(productionMap).flat();
    }, [productionMap]);

    const getDataPageComments = useCallback(() => {
        return dataPageComments || undefined;
    }, [dataPageComments]);

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    useEffect(() => {
        let isImportStepSaved = false;
        if (importCtrl.templateImportState.data) {
            isImportStepSaved = isStepSaved(importCtrl.templateImportState.data, currentStep)
        }

        if (importCtrl.templateImportState.data && importCtrl.templateImportState.data.productions && refData != null
            && importCtrl.templateImportState.templateImportDialogState === 'processing' && currentStep !== 'Submit' && !isImportStepSaved) {
            const changed = !isStepSaved(importCtrl.templateImportState.data, currentStep);

            const refCodes = refData.groups.map(g => g.refData.referenceCode).filter(r => is<string>(r)) as string[];
            const submissionData = submission.productions;
            const importProductions = importCtrl.templateImportState.data.productions;

            let productionMap: UpdateProductionByProductMap = {};

            const result = processImportData(refCodes, refData, importProductions, submissionData, applicableFields);
            productionMap = result && result[0];

            setHasUnsavedChanges(changed);
            setProductionMap(productionMap);
        }
    }, [
        applicableFields,
        currentStep,
        importCtrl.templateImportState.data,
        importCtrl.templateImportState.templateImportDialogState,
        refData,
        submission.pageData,
        submission.productions
    ]);

    useEffect(() => {
        if (importCtrl.templateImportState.templateImportDialogState === 'complete' && declaration === true) {
            setDeclaration(false);
            setHasUnsavedChanges(true);
        }

    }, [declaration, importCtrl.templateImportState.templateImportDialogState]);

    useEffect(() => {
        switch (deleteRequestState.deleteState) {
            case 'cancelled':
                setDeleteRequestState(idleDeleteRequest);
                break;
            case 'confirmed':
                if (deleteRequestState.id !== undefined && deleteRequestState.rowIndex !== undefined) {
                    actionDeleteProduction(deleteRequestState.id, deleteRequestState.rowIndex);

                    if (deleteRequestState.source != null) {
                        focusNext(deleteRequestState.source);
                    }
                }
                setDeleteRequestState(idleDeleteRequest);
                break;
        }
    }, [actionDeleteProduction, deleteRequestState]);

    return {
        view,
        addProduction,
        clearDeclaration,
        deleteProduction,
        getDataPageComments,
        getUpdateProductionsByProductionId,
        getUpdateProductions,
        resetForm,
        setFocusedField,
        updateDataSubmissionComment,
        updateDeclaration: setDeclaration,
        updateProduction,
        updateProductionComment,
        deleteRequestState,
        setDeleteRequestState,
    };
}

export function isFocusFieldProduction(maybe?: FocusField | null): maybe is FocusFieldProduction {
    return (
        maybe != null &&
        (maybe as FocusFieldProduction)?.field != null
    );
}

function calculateTotalsForProductGroup(products: Array<ProductionView>): Totals {
    return {
        openingStocks: products.map(item => asNumber(item.data?.openingStocks)).reduce(calculateSum, 0),
        closingStocks: products.map(item => asNumber(item.data?.closingStocks)).reduce(calculateSum, 0),
        consumed: products.map(item => asNumber(item.data?.consumed)).reduce(calculateSum, 0),
        delivered: products.map(item => asNumber(item.data?.delivered)).reduce(calculateSum, 0),
        produced: products.map(item => asNumber(item.data?.produced)).reduce(calculateSum, 0),
    };
}

function calculateSum(sum?: number, val?: number): number {
    return (asNumber(sum)) + (asNumber(val));
}

function productionToUpdateProduction(Production: Production): UpdateProductionDraft {
    const { recordResult, validationAlerts, ...rest } = Production;
    return {
        ...rest,
        recordAction: null,
    };
}

function productionVmToUpdateProduction(production: MaybeProduction, recordAction?: RecordActionEnum, existingProduction?: Production): UpdateProductionDraft {
    const updateProduction = {
        id: existingProduction?.id,
        concurrencyToken: existingProduction?.concurrencyToken,
        recordAction: recordAction || null,
        productionProductId: production?.productionProductId ? production?.productionProductId : 0,
        organisationProductionAreaId: production.organisationProductionAreaId,
        productionDensity: production.productionDensity,
        closingStocks: production.closingStocks,
        consumed: production.consumed,
        delivered: production.delivered,
        grossCalorificValue: production.grossCalorificValue,
        openingStocks: production.openingStocks,
        produced: production.produced,
    };
    return updateProduction;
}

function commentToUpdateComment(comment: ProductionPageData): UpdateProductionPageData {
    const { formDataResult, pageSaved, ...rest } = comment;
    return rest;
}

function updateComments(submission: ProductionSubmission): UpdateProductionPageData {
    return commentToUpdateComment(submission.pageData);
}

function updateProductionMapByProductId(submission: ProductionSubmission): UpdateProductionByProductMap {
    return submission.productions
        .filter(p => p.recordResult?.rowResult !== 'Deleted')
        .reduce((mapping, production) => {
            const { productionProductId } = production;
            const existingProductions = mapping[productionProductId] || [];
            return {
                ...mapping,
                [productionProductId]: [...existingProductions, productionToUpdateProduction(production)],
            };
        }, {} as UpdateProductionByProductMap);
}

function isEmptyUpdateComment(pageData: UpdateProductionPageData) {
    return isEmpty(pageData.comment);
}

function checkStringValue(value: any) {
    if (!value || value === '-' || value === '.') {
        return true;
    }
    return !(typeof value === 'string' && !isNumeric(value));
}

function validationMessagesForProduction(
    production: UpdateProductionDraft | null,
    inactiveProductionArea: boolean,
    onClick: (field: FocusField) => any,
    rowIndex: number,
    seenProductionAreaIds: Set<number>,
    applicableFields: Array<FieldConfig>,
    productView: ProductionProductRefData,
    selectedProductionAreaName?: string
): Array<ErrorMessage<ProductionField | 'delete'>> | [] {
    const validationMessages: Array<ErrorMessage<ProductionField | 'delete'>> = [];
    const rowLabel = selectedProductionAreaName || `row ${rowIndex + 1}`;

    if (production != null) {
        const productId = production.productionProductId;

        if (productView.isExpired) {
            return [{
                notification: {
                    content: ErrorExpiredProduct({
                        label: productView.productName,
                        onTargetClick: () => onClick({ rowIndex, field: 'delete', productId }),
                    }),
                },
                tooltip: {
                    target: 'delete',
                    content: INVALID_PRODUCTION_EXPIRED,
                }

            }];
        }

        const data = updateProductionToProductionData(production);
        const entries = Object.entries(data);

        entries.forEach(([f, v]) => {
            const field = f as ProductionField;
            const fieldConfig = applicableFields.find(af => af.field === field);

            if (fieldConfig == null) {
                return;
            }

            let msg: string | undefined;
            const value = numberFieldValue(v);
            const stringValue = v as unknown as number;

            switch (field.toUpperCase()) {
                case 'ORGANISATIONPRODUCTIONAREAID':
                    if (inactiveProductionArea) {
                        msg = INACTIVE_PRODUCTIONAREA_FACILITY_NAME_ERROR;
                    } else if (seenProductionAreaIds.has(value as number)) {
                        msg = INVALID_PRODUCTION_AREA_FACILITY_NAME_CONFLICT;
                    }
                    else {
                        seenProductionAreaIds.add(value as number);
                    }
                    break;
                case 'PRODUCTIONDENSITY':
                    if (!checkStringValue(stringValue)) {
                        msg = INVALID_AVERAGEDENSITY_RANGE;
                    } else {
                        msg = validateDecimalInRange(value, 5, 0, 10, INVALID_AVERAGEDENSITY_RANGE);
                    }
                    break;
                case 'GROSSCALORIFICVALUE':
                    if (!checkStringValue(stringValue)) {
                        msg = INVALID_GROSSCALORIFICVALUE_RANGE;
                    } else {
                        msg = validateDecimalInRange(value, 100, 0, 3, INVALID_GROSSCALORIFICVALUE_RANGE);
                    }
                    break;
                case 'CONCURRENCYTOKEN':
                    break;
                default:
                    if (!checkStringValue(stringValue)) {
                        msg = INVALID_VOLUME_INTEGER;
                    } else {
                        msg = validateQuantity(value);
                    }
                    break;
            }

            if (msg != null) {
                const col = fieldConfig.label;
                const label = `${rowLabel} in the ${col} column`;
                const quantityContent = QuantityInvalidErrorMessage({
                    label: label,
                    onClick: () => onClick({ rowIndex, field, productId })
                });

                const densityContent = DensityInvalidErrorMessage({
                    label: label,
                    onClick: () => onClick({ rowIndex, field, productId })
                });

                const calorificValueContent = CalorificValueInvalidErrorMessage({
                    label: label,
                    onClick: () => onClick({ rowIndex, field, productId })
                });

                const duplicateValueContent = DuplicateValueErrorMessage({
                    label: label,
                    onClick: () => onClick({ rowIndex, field, productId })
                });

                const inactiveProductionAreaContent = InactiveProductionAreaErrorMessage({
                    label: label,
                    onClick: () => onClick({ rowIndex, field, productId })
                });

                let notificationContent = msg === INVALID_AVERAGEDENSITY_RANGE ?
                    densityContent : msg === INVALID_GROSSCALORIFICVALUE_RANGE ?
                        calorificValueContent : quantityContent;

                if (msg === INVALID_PRODUCTION_AREA_FACILITY_NAME_CONFLICT) {
                    notificationContent = duplicateValueContent;
                }

                if (msg === INACTIVE_PRODUCTIONAREA_FACILITY_NAME_ERROR) {
                    notificationContent = inactiveProductionAreaContent;
                }
                validationMessages.push({
                    notification: {
                        content: notificationContent,
                    },
                    tooltip: {
                        target: field,
                        content: msg,
                    }
                });
            }
        });

        const allOrNothingStatus = applicableFields.reduce((status, { field }) => {
            switch (status) {
                case 'invalid':
                    return 'invalid';

                case 'start': {
                    const data = entries.find(([dataField]) => dataField === field);
                    return (data == null || data[1] == null) ? 'nothing' : 'all';
                }

                case 'nothing': {
                    const data = entries.find(([dataField]) => dataField === field);
                    return (data == null || data[1] == null) ? 'nothing' : 'invalid';
                }

                case 'all': {
                    const data = entries.find(([dataField]) => dataField === field);
                    return (data == null || data[1] == null) ? 'invalid' : 'all';
                }

                default:
                    return status;
            }
        }, 'start' as AllOrNothingStatus);

        if (allOrNothingStatus === 'invalid') {
            applicableFields.filter(({ field }) => {
                const data = entries.find(([dataField]) => dataField === field);
                return data == null || data[1] == null;
            }).forEach(fieldConfig => {
                const label = `${rowLabel} in the ${fieldConfig.label} column`;
                validationMessages.push({
                    notification: {
                        content: RowIncompleteInvalidErrorMessage({
                            label,
                            onClick: () => onClick({ rowIndex, field: fieldConfig.field, productId })
                        })
                    },
                    tooltip: {
                        target: fieldConfig.field,
                        content: INCOMPLETE_ROW,
                    }
                });
            });
        }
    }

    return validationMessages.sort((a, b) => fieldDisplayOrder[a.tooltip.target as ProductionField] - fieldDisplayOrder[b.tooltip.target as ProductionField]);
}

function infoMessagesForProduction(
    production: UpdateProductionDraft | null,
    persistedProduction: Production | undefined,
    onClick: (field: FocusField) => any,
    rowIndex: number,
    applicableFields: Array<FieldConfig>,
    validationAlerts: Array<ValidationAlertView>,
    selectedProductionAreaName?: string,
): Array<InfoMessage<ProductionField>> | [] {
    const infoMessages: Array<InfoMessage<ProductionField>> = [];

    if (production == null) {
        return infoMessages;
    }

    const productId = production?.productionProductId;

    const entries = Object.entries(production);

    entries.forEach(([f, value]) => {
        const field = f as ProductionField;
        const fieldConfig = applicableFields.find(af => af.field === field);

        if (fieldConfig == null) {
            return infoMessages;
        }

        const v = asNumber(value);

        if (field !== 'openingStocks' &&
            field !== 'closingStocks' &&
            field !== 'consumed' &&
            field !== 'delivered' &&
            field !== 'produced' &&
            field !== 'organisationProductionAreaId') {
            return infoMessages;
        }

        if (field === 'organisationProductionAreaId' && selectedProductionAreaName === 'Other') {
            const rowLabel = selectedProductionAreaName || `row ${rowIndex + 1}`;
            const label = `${rowLabel} facility name column`;
            infoMessages.push({
                notification: {
                    content: InfoOtherProductionAreaValueMessage({
                        label: label,
                        onCommentTargetClick: () => onClick('comments'),
                        onTargetClick: () => {
                            onClick({ rowIndex, field, productId });
                        }
                    })
                },
                tooltip: {
                    content: INFO_PRODUCTION_OTHER_FACILITY_NAME_COMMENTS,
                    target: field,
                }
            });
        }

        // Negative value info messages/tooltips
        if (v < 0) {
            const col = fieldConfig.label;
            const rowLabel = selectedProductionAreaName || `row ${rowIndex + 1}`;

            const label = `${rowLabel} in the ${col} column`;

            infoMessages.push({
                notification: {
                    content: InfoNegativeValueMessage({
                        label: label,
                        onCommentTargetClick: () => onClick('comments'),
                        onTargetClick: () => {
                            onClick({ rowIndex, field, productId });
                        }
                    })
                },
                tooltip: {
                    content: INFO_NEGATIVE_VALUE_COMMENTS,
                    target: field,
                }
            });
        }

        if (
            field === 'openingStocks' &&
            (persistedProduction != null && v === persistedProduction.openingStocks) && // only show info message if opening stock is same as saved row
            validationAlerts.some(alert => { return alert.productionId === production.id && alert.validationAlert.validationAlert === 'OpeningClosingStocksMismatch' })
        ) {
            const col = fieldConfig.label;
            const rowLabel = selectedProductionAreaName || `row ${rowIndex + 1}`;

            const label = `${rowLabel} in the ${col} column`;
            infoMessages.push({
                notification: {
                    content: InfoOpeningClosingStocksMismatchMessage({
                        label: label,
                        onCommentTargetClick: () => onClick('comments'),
                        onTargetClick: () => {
                            onClick({ rowIndex, field, productId });
                        }
                    })
                },
                tooltip: {
                    content: INFO_OPENING_CLOSING_STOCKS_MISMATCH_COMMENTS,
                    target: field,
                }
            });
        }


    });

    return infoMessages.sort((a, b) => fieldDisplayOrder[a.tooltip.target] - fieldDisplayOrder[b.tooltip.target]);
}

function getInfoMessagesForSubmitPage(validationAlerts: Array<ValidationAlertMaybe> | null | undefined, onClick: () => any) {

    if (validationAlerts == null) {
        return [];
    }
    const notifications: Array<{ notification: { content: JSX.Element } }> = [];
    if (validationAlerts.some(alert => { return alert.validationAlert === 'SameAsPrevious' })) {
        notifications.push({
            notification: {
                content: InfoSameAsPreviousMessage({
                    onTargetClick: () => onClick()
                })
            }
        });

    }

    return notifications;
}

function cleanImportProduction(
    applicableFields: ApplicableFields,
    productGroup: ProductGroupRefData | undefined,
    production: MaybeProduction
) {
    const productRefData = productGroup?.products.find(p => p.id === production.productionProductId);
    if (!productRefData) {
        return production;
    }
    const fields = applicableFields(productRefData, productGroup?.refData);
    const fieldIds = fields.map(f => f.field);
    let newProduction: MaybeProduction = {
        ...production,
        grossCalorificValue: fieldIds.includes('grossCalorificValue') ? production.grossCalorificValue : null,
        productionDensity: fieldIds.includes('productionDensity') ? production.productionDensity : undefined,
        consumed: fieldIds.includes('consumed') ? production.consumed : null,
        closingStocks: fieldIds.includes('closingStocks') ? production.closingStocks : undefined,
        delivered: fieldIds.includes('delivered') ? production.delivered : null,
        openingStocks: fieldIds.includes('openingStocks') ? production.openingStocks : undefined,
        produced: fieldIds.includes('produced') ? production.produced : undefined,
    };

    return newProduction;
}

function processImportData(
    refCodes: Array<string>,
    refData: UseProductionRefData,
    importProductions: MaybeProduction[],
    submissionData: Production[],
    applicableFields: ApplicableFields
) {
    let productionMap: UpdateProductionByProductMap = {};
    const newDataToImport: UpdateProductionDraft[] = [];

    refCodes.forEach(r => {
        const groupRefData = refData.groups.find(g => g.refData.referenceCode === r);
        const groupProductIds = groupRefData?.products.map(p => p.id) || [];
        const organisationProductionAreas = refData.organisationProductionAreas;

        groupProductIds.forEach(gp => {
            organisationProductionAreas?.forEach(opa => {
                const allNewData = importProductions?.filter(p => gp === Number(p.productionProductId) && p.organisationProductionAreaId === opa.id)
                    .map(x => cleanImportProduction(applicableFields, groupRefData, x));
                const allExistingData = submissionData?.filter(p => gp === Number(p.productionProductId) && p.organisationProductionAreaId === opa.id);

                let updateProduction: UpdateProductionDraft = { productionProductId: 0, recordAction: null };

                if (allNewData.length === 0 && allExistingData.length > 0) {
                    allExistingData.forEach(p => {
                        const emptyData: MaybeProduction = { productionProductId: p.productionProductId, organisationProductionAreaId: p.organisationProductionAreaId };
                        updateProduction = {
                            ...productionVmToUpdateProduction(emptyData, recordActionAsEnum('Delete'), p),
                        }
                        newDataToImport.push(updateProduction);
                    });
                } else if (allNewData.length > 0 && allExistingData.length === 0) {
                    allNewData.forEach(p => {
                        updateProduction = {
                            ...productionVmToUpdateProduction(p, recordActionAsEnum('Create')),
                        }
                        newDataToImport.push(updateProduction);
                    });
                } else if (allNewData.length > 0 && allExistingData.length > 0) {
                    const existingData = submissionData?.find(p => gp === Number(p.productionProductId) && p.organisationProductionAreaId === opa.id);
                    let newData = importProductions?.find(p => gp === Number(p.productionProductId) && p.organisationProductionAreaId === opa.id);

                    if (newData !== undefined) {
                        newData = cleanImportProduction(applicableFields, groupRefData, newData);
                        updateProduction = {
                            ...productionVmToUpdateProduction(newData, recordActionAsEnum('Update'), existingData),
                        }
                        newDataToImport.push(updateProduction);
                    }

                    if (allNewData.length > 1) {
                        // Remove first in the list since it will be treat as an update
                        const remainingAllNewData = allNewData.splice(1, 1);

                        // Create the remaining productions
                        remainingAllNewData
                            .map(x => cleanImportProduction(applicableFields, groupRefData, x))
                            .forEach(p => {
                                updateProduction = {
                                    ...productionVmToUpdateProduction(p, recordActionAsEnum('Create')),
                                }
                                newDataToImport.push(updateProduction);
                            });
                    }
                }
            });
        });

        productionMap = newDataToImport
            .reduce((mapping, production) => {
                const { productionProductId } = production;
                const existingProductions = mapping[productionProductId] || [];
                return {
                    ...mapping,
                    [productionProductId]: [...existingProductions, production],
                };
            }, {} as UpdateProductionByProductMap);
    });

    return [productionMap] as const;
}

function dataSubmissionHasValidationAlerts(ds: DataSubmission<ProductionDataSubmissionName>) {
    return (ds.validationAlerts || []).length > 0;
}

export default useProductionForm