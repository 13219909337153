import { asString } from "../string";
import { isValidLargeText, isValidStringLength } from "./string";
import { INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH } from "psims/constants/validation-messages";

export function isValidCommentMaxLength(comment: string | null | undefined) {
    return isValidStringLength(asString(comment), {max: 4000, min: 0});
}

export function isValidCommentCharacters(comment: string | null | undefined) {
    return asString(comment).length === 0 || isValidLargeText(asString(comment));
}

export function validateCommentMaxLength(comment: string | null | undefined) {
    return isValidCommentMaxLength(comment) ?
        null :
        INVALID_COMMENTS_LENGTH;
}

export function validateCommentCharacters(comment: string | null | undefined) {
    return isValidCommentCharacters(comment) ?
        null :
        INVALID_COMMENTS_CHARACTERS;
}
