import { useEffect, useMemo, useState } from "react";

import { WholesaleProductGroup } from "psims/models/ref-data/wholesale-product-group";
import { WholesaleType, WholesaleTypeName } from "psims/models/ref-data/wholesale-type";
import { useReferenceData } from "psims/react/providers/api/reference-data";

function useRefDataForTypeName(typeName: WholesaleTypeName) {
    const {data: _refData} = useReferenceData();
    const [groups, setGroups] = useState<Array<WholesaleProductGroup> | null>(null);
    const [wholesaleType, setWholesaleType] = useState<WholesaleType | null>(null);

    const wholesaleProductGroups = _refData?.wholesaleProductGroups;
    const wholesaleProducts = _refData?.wholesaleProducts;
    const wholesaleTypes = _refData?.wholesaleTypes;

    useEffect(() => {
        if (wholesaleTypes == null) {
            return;
        }

        setWholesaleType(wholesaleTypes.find(t => t.name === typeName) || null);
    }, [typeName, wholesaleTypes]);

    useEffect(() => {
        if (wholesaleType == null || wholesaleProductGroups == null) {
            return;
        }

        setGroups(wholesaleProductGroups.filter(g => g.wholesaleTypeId === wholesaleType.id) || null);
    }, [wholesaleType, wholesaleProductGroups]);

    const groupView = useMemo(() => {
        if (groups == null || wholesaleProducts == null) {
            return null;
        }

        return groups
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map(g => ({
                productGroup: g,
                products: wholesaleProducts
                    .filter(p => p.wholesaleProductGroupId === g.id)
                    .sort((a, b) => a.displayOrder - b.displayOrder)
            }))
    }, [groups, wholesaleProducts]);

    const refData = useMemo(() => {
        return {
            groups: groupView,
            wholesaleType,
        };
    }, [groupView, wholesaleType]);

    return refData;
}

type MaybeWholesaleTypeRefData = ReturnType<typeof useRefDataForTypeName>;

export type WholesaleTypeRefData = PopulatedProps<MaybeWholesaleTypeRefData, 'groups' | 'wholesaleType'>;

function isWholesaleTypeRefData(maybe: MaybeWholesaleTypeRefData): maybe is WholesaleTypeRefData {
    return maybe.groups != null && maybe.wholesaleType != null;
}

function useWholesalingRefData(): {[key in WholesaleTypeName]: MaybeWholesaleTypeRefData} {
    const Bulk = useRefDataForTypeName('Bulk');
    const other = useRefDataForTypeName('Other wholesales');
    const Retailers = useRefDataForTypeName('Retailers');
    const Total = useRefDataForTypeName('Total');
    const Wholesalers = useRefDataForTypeName('Wholesalers');

    const wholesalingRefData = useMemo(() => ({
        Retailers,
        Wholesalers,
        Bulk,
        'Other wholesales': other,
        Total,
    }), [Bulk, other, Retailers, Total, Wholesalers]);

    return wholesalingRefData;
}

export default useWholesalingRefData;

type MaybeUseWholesalingRefData = ReturnType<typeof useWholesalingRefData>;

export type UseWholesalingRefData = {
    [key in keyof MaybeUseWholesalingRefData]: WholesaleTypeRefData;
}

export function isUseWholesalingRefData(maybe: MaybeUseWholesalingRefData): maybe is UseWholesalingRefData {
    return isWholesaleTypeRefData(maybe.Bulk) &&
           isWholesaleTypeRefData(maybe['Other wholesales']) &&
           isWholesaleTypeRefData(maybe.Retailers) &&
           isWholesaleTypeRefData(maybe.Total) &&
           isWholesaleTypeRefData(maybe.Wholesalers);
}
