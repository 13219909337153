import React from 'react';

import { TERMS_AND_CONDITIONS_LINK, PRIVACY_POLICY_LINK } from 'psims/constants/app';
import Callout from 'psims/react/components/callout';
import Checkbox from 'psims/react/components/checkbox';
import { BoxedDiv } from 'psims/react/components/layout';
import { H3 } from 'psims/react/components/typography';
import UL, { LI } from 'psims/react/components/unordered-list';

interface SubmitTermsProps {
    CustomDeclaration?: string;
    CustomTerms?: JSX.Element;
    disabled: boolean;
    error?: string;
    hasAgreed: boolean;
    isMso?: boolean;
    onHasAgreedChange: (val: boolean) => any;
    shouldFocusDeclaration?: boolean;
}

const SubmitTerms = ({
    CustomDeclaration,
    CustomTerms,
    disabled,
    error,
    hasAgreed,
    isMso,
    onHasAgreedChange,
    shouldFocusDeclaration
}: SubmitTermsProps) => {
    return (
        <Callout id='frontmatter'>
            {
                CustomTerms == null ?
                <>
                    <H3 marginBottom={2.5}>I declare and acknowledge that:</H3>
                    <UL>
                        <LI>I have previously accepted this Gateway's <a href={TERMS_AND_CONDITIONS_LINK} target='_blank' rel='noreferrer'>Terms and Conditions</a> and have read, understood and accepted the Department's <a href={PRIVACY_POLICY_LINK} target='_blank' rel="noreferrer">Privacy Policy</a>;</LI>
                        <LI>I am authorised to submit this information on behalf of the named Company;</LI>
                        <LI>to the best of my knowledge and belief, the information in this submission is true and correct and accurate in all material details. I understand that giving false or misleading information is a serious offence;</LI>
                        <LI>the information contained in this submission meets the relevant requirements of the <em>Petroleum and Other Fuels Reporting Act 2017</em>;</LI>
                        {
                        isMso ?

                        <LI>
                        the Company has maintained records that substantiate this submission, which may be audited under the <em>Petroleum and Other Fuels Reporting Act 2017</em> or <em>Fuel Security Act 2021</em>; and
                        </LI> :

                        <>
                        <LI>
                        information provided in this submission may be verified by the Department, including by requesting further information. If requested, I will do so in a reasonable amount of time after receiving a request; and
                        </LI>
                        </>
                        }
                        <LI>this submission will be treated as a confidential Commonwealth record and information in the submission will not be disclosed (unless required or permitted by law to do so).</LI>
                    </UL>
                </> :
                {...CustomTerms}
            }

            <BoxedDiv box={{alignItems: 'center', flex: 'row', marginTop: 2}}>
                <Checkbox
                    disabled={disabled}
                    error={error}
                    name='declaration'
                    label={CustomDeclaration == null ? 'I confirm I have read and agree to the declaration' : CustomDeclaration}
                    onChange={onHasAgreedChange}
                    shouldFocus={shouldFocusDeclaration}
                    value={hasAgreed}
                />
            </BoxedDiv>
        </Callout>
    );
}

export default SubmitTerms;
