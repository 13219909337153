import React  from 'react';

import {SVGProps} from 'psims/react/components/icons/_base';
import styled, { keyframes } from 'styled-components';
import SpinnerThird from './icons/spinner-third';

interface SpinnerProps extends SVGProps{}

const rotationAnimation = keyframes`
 0% { transform: rotateZ(0deg); }
 33% { transform: rotateZ(0deg); }
 34% { transform: rotateZ(120deg); }
 66% { transform: rotateZ(120deg); }
 67% { transform: rotateZ(240deg); }
 100% { transform: rotateZ(240deg); }
`;
const Styled = styled.div`
    align-items: center;
    display: flex;
    animation-name: ${rotationAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
`;

const Spinner = (props: SpinnerProps) => {
    const {color = 'primary'} = props;
    return (
        <Styled><SpinnerThird color={color} {...props} /></Styled>
    )
}

export default Spinner;
