import { useCallback, useMemo, useState } from "react";

import { MaybeRefiningSubmissionFormData, RefiningSubmission, RefiningSubmissionFormData } from "psims/models/submission-types/refining";
import { Step, UseRefinerySteps } from "./use-refinery-steps";
import { ViewMode } from "psims/react/pages/primary-pages/data-submissions/shared/use-view-mode";
import StepLabel from "psims/react/pages/primary-pages/data-submissions/shared/step-label";
import { RefineryTypeName } from "psims/models/ref-data/refinery-type";
import { UseRefineryAPI } from "./use-refinery-api";

interface UseRefineryProgressProps {
    apiCtrl: UseRefineryAPI;
    stepsCtrl: UseRefinerySteps;
}

function useRefineryProgress({apiCtrl, stepsCtrl}: UseRefineryProgressProps) {
    const { submission, viewMode} = apiCtrl; 
    const newViewMode = viewMode as ViewMode;
    const [currentStepIndex, setCurrentStepIndex] = useState(determineFirstPageIndex(submission, newViewMode));
    const [saveAttemptedForStep, setSaveAttemptedForStep] = useState(false);

    const progressSteps = useMemo(() => {
        return stepsCtrl.steps.map(step => ({
            ...step,
            Label: StepLabel({
                hasData: stepHasData(step, submission),
                label: step.label,
            }),
            status: (currentStepIndex === step.index ? 'active' : statusForStep(step, submission?.submissionFormData, newViewMode)) as 'active' | 'complete' | 'pending',
        }));
    }, [currentStepIndex, newViewMode, stepsCtrl.steps, submission]);

    const currentStep = useMemo(() => {
        return progressSteps[currentStepIndex];
    }, [currentStepIndex, progressSteps]);

    const goToStep = useCallback((index: number) => {
        setCurrentStepIndex(index);
        setSaveAttemptedForStep(false);
    }, []);

    const onSave = () => setSaveAttemptedForStep(true);

    return {
        currentStep,
        progressSteps,
        saveAttemptedForStep,
        goToStep,
        onSave,
    }
}

export default useRefineryProgress

export type UseRefineryProgress = ReturnType<typeof useRefineryProgress>;

// Helpers
function determineFirstPageIndex(submission: RefiningSubmission | undefined, viewMode: ViewMode) {
    if (viewMode !== 'edit' || submission?.dataSubmission === undefined || submission?.dataSubmission?.status === 'Action required') {
        return 0;
    }

    return (
        !submission?.submissionFormData.refineryInputsPageSaved ? 0 :
        !submission?.submissionFormData.refineryOutputsPageSaved ? 1 :
        !submission?.submissionFormData.gasesIntermediatePetrochemLossesSaved ? 2 :
        3
    );
}

function stepHasData(step: Step, submission: RefiningSubmission | undefined) {
    if (!submission) {
        return false;
    }
    if (step.kind === 'submit') {
        return true;
    }

    const typeId = step.refData.refineryType.id;
    const targetRefinings = submission.refinings.filter(r => r.refineryTypeId === typeId);

    return targetRefinings.length > 0 || submission.refineryComments.find(c => c.refineryTypeId === typeId) != null;
}

const SAVED_PAGES_MAP: {[k in RefineryTypeName]: keyof RefiningSubmissionFormData} = {
    "Gases-Unfin-Petrochem-Losses": 'gasesIntermediatePetrochemLossesSaved',
    "Refinery Input": 'refineryInputsPageSaved',
    "Refinery Output": 'refineryOutputsPageSaved',
};

function statusForStep(step: Step, submissionFormData: MaybeRefiningSubmissionFormData | undefined, viewMode: ViewMode) {
    if (!submissionFormData || viewMode === 'view')
    {
        return 'complete';
    }
    if (step.kind === 'submit') {
        return 'pending';
    }

    return submissionFormData[SAVED_PAGES_MAP[step.refData.refineryType.name]] ? 'complete' : 'pending';
}
