import { isWithErrorMessages } from "psims/lib/server-error";
import { isUser, User } from "psims/models/user";
import { useAPI } from "psims/react/providers/api";
import { useState } from "react";

interface UseDeactivateUserProps {
    onSuccess: (user: User) => any;
}

function useDeactivateUser(props: UseDeactivateUserProps) {
    const {api} = useAPI();
	const [user, setUser] = useState<User | null>(null);
    const [serviceError, setServiceError] = useState<string | null>(null);

	const handleClick = (u: User) => setUser(u);

	const handleCancel = () => {
        setServiceError(null);
        setUser(null)
    };

	const handleConfirm = () => {
        if (user?.id) {
            setServiceError(null);

            api.deactivateUser({userId: user.id})
            .then(response => {
                if (response?.isSuccessful && isUser(response.result)) {
                    props.onSuccess(response.result);
                    setUser(null);
                }
            })
            .catch(e => {
                if (isWithErrorMessages(e)) {
                    setServiceError(e.body.errorMessages[0]);
                } else {
                    setServiceError(`${e}`);
                }
            });
        }
	}

    return {
        handleCancel,
        handleConfirm,
        handleClick,
        serviceError,
        user,
    };
}

export default useDeactivateUser;
