/* Implement the navigation prompt component, using the useConfirmNavigation utility */
import React, { useCallback, useEffect, useState } from 'react';

import useConfirmNavigation from 'psims/react/util/use-confirm-navigation';
import ConfirmSaveChanges from 'psims/react/components/confirm-save-changes';
import { UseWholeSalingSave } from './use-wholesaling-save';
import { UseWholesalingUpdateResponse } from './use-wholesaling-update-response';

interface WholesalingNavigationPromptProps {
    hasUnsavedChanges: boolean;
    saveCtrl: UseWholeSalingSave;
    updateResponse: UseWholesalingUpdateResponse;
}

type DeferredState = {
    inited: boolean; // flag to switch after response status leaves 'idle' when deferring nav
    isDeferred: boolean;
}

const WholesalingNavigationPrompt = ({hasUnsavedChanges, saveCtrl, updateResponse}: WholesalingNavigationPromptProps) => {
    const navPrompt = useConfirmNavigation({when: hasUnsavedChanges});
    const [deferredNav, setDeferredNav] = useState<DeferredState>({inited: false, isDeferred: false});

    const handleCancel = useCallback(() => {
        navPrompt.handleDecision(false);
        saveCtrl.handleSaveCancel();
    }, [navPrompt, saveCtrl]);

    const handleDiscardAndContinue = useCallback(() => {
        navPrompt.handleDecision(true);
        saveCtrl.handleDiscardAndContinue();
    }, [navPrompt, saveCtrl]);

    const handleSaveAndContinue = useCallback(() => {
        saveCtrl.handleSaveAndContinue();
        setDeferredNav({inited: false, isDeferred: true});
    }, [saveCtrl]);

    // Need to defer navigation confirm callback until the save completes
    // in the case Save and continue is selected. If save fails, then navigation
    // should finalise as blocked
    useEffect(() => {
        const {inited, isDeferred} = deferredNav;
        
        // cases that can be ignored
        if (!isDeferred) {
            return;
        }

        if (!inited) {
            // Ignore initial idle state
            if (updateResponse.status === 'idle') {
                return
            }

            if (updateResponse.status === 'pending') {
                setDeferredNav({inited: true, isDeferred: true});
            }
            return;
        }

        if (updateResponse.status === 'pending') {
            return;
        }

        // invoke deferred navigation
        if (updateResponse.status === 'saved_full') {
            navPrompt.handleDecision(true);
            return;
        }

        // Clear deferred navigation due to unhappy path
        setDeferredNav({inited: false, isDeferred: false});
    }, [deferredNav, navPrompt, updateResponse.status]);

    return (
        <ConfirmSaveChanges
            controlsState={saveCtrl.isApiBusy ? 'loading' : 'normal'}
            isOpen={navPrompt.showPrompt}
            onCancel={handleCancel}
            onConfirm={handleSaveAndContinue}
            onSecondary={handleDiscardAndContinue}
        />
    );
}

export default WholesalingNavigationPrompt;
