import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { WorkBook } from 'xlsx';
import { FileWithHandle } from 'browser-fs-access';

import PageHeader, { PageHeaderDetailsTable, PageHeaderTitle} from 'psims/react/components/page-header';
import { submissionMonthLabel, submissionPeriodLabel } from 'psims/lib/formatters/data-submission';
import { humanDate } from 'psims/lib/formatters/datetime';
import Text from 'psims/react/components/text';
import { DataSubmissionTypeName, PopulatedDataSubmission } from 'psims/models/data-submission';
import { formatAbn } from 'psims/lib/formatters/abn';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import Button from 'psims/react/components/button';
import { TemplateImportDialog } from 'psims/react/blocks/import/template-import';
import Eraser from 'psims/react/components/icons/eraser';
import ImportFile from 'psims/react/components/icons/import-file';
import { H3 } from 'psims/react/components/typography';
import UL, { ArrowLI } from 'psims/react/components/unordered-list';
import Notification from 'psims/react/components/notification';
import { TemplateImportState } from 'psims/react/blocks/import/types';
import Envelope from 'psims/react/components/icons/envelope';
import { useAPI } from 'psims/react/providers/api';
import { SubmissionStatus } from 'psims/react/pages/primary-pages/data-submissions/shared/api';
import { useAppOverlay } from 'psims/react/providers/app-overlay';
import Modal from 'psims/react/components/modal';
import Spinner from 'psims/react/components/spinner';
import SupportLink from 'psims/react/components/support-link';
import Close from 'psims/react/components/icons/close';
import CustomerActionComments from 'psims/react/blocks/customer-action-comments';
import { attemptScrollToSelector } from '../pages/primary-pages/data-submissions/shared/view-utils';
import { SELECTOR_NOTIFICATION_KIND } from 'psims/constants/selectors';

interface HeaderProps {
    customName?: string;
    dataSubmission: PopulatedDataSubmission<DataSubmissionTypeName>;
    // TODO: refactor to minimise exposer to 3rd-party APIs
    file?: FileWithHandle | null;
    showExtraButtons?: boolean | false;
    clearFile?: () => any;
    handleClearData?: () => void;
    handleTemplateImport?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;  
    process?: (workbook: WorkBook) => void;
    currentStepIndex?: number;
    templateImportState?: TemplateImportState<any>;
    setTemplateImportState?: Dispatch<SetStateAction<TemplateImportState<any>>>;
}

const Header = ({customName, dataSubmission, file, showExtraButtons, clearFile, handleClearData, handleTemplateImport, process, currentStepIndex, templateImportState, setTemplateImportState}: HeaderProps) => {
    const {api} = useAPI();
    const hasTemplateImport = showExtraButtons !== undefined && showExtraButtons === true && process && templateImportState !== undefined && setTemplateImportState !== undefined;
    const hasTemplateImportUnsavedChanges = templateImportState !== undefined && templateImportState.unsavedChanges === true;
    const hasClearData = handleClearData !== undefined;
    const [requestDataTemplateStatus, setRequestDataTemplateStatus] = useState<SubmissionStatus>('init');
    const {setOverlay} = useAppOverlay();
    const handleRequestDataTemplate = useCallback(() => {
        setRequestDataTemplateStatus('submitting');
        api.dataSubmissionTemplateRequest({requestBody: {
            dataSubmissionTypeId: dataSubmission.submissionTypeId,
            reportingOrganisationId: dataSubmission.reportingOrganisationId,
            responsibleOrganisationId: dataSubmission.responsibleOrganisationId,
        }})
        .then(res => {
            setRequestDataTemplateStatus('submitted');
        })
        .catch(e => {
            setRequestDataTemplateStatus('submit_failed');
        });

    }, [api, dataSubmission.submissionTypeId, dataSubmission.reportingOrganisationId, dataSubmission.responsibleOrganisationId]);

    useEffect(() => {
        setRequestDataTemplateStatus('init');
    }, [currentStepIndex])

    useEffect(() => {
        if (requestDataTemplateStatus === 'submitting') {
            setOverlay(<Modal>
                <BoxedDiv box={{alignItems: 'center', flex: 'row'}}>
                    <Spinner size='lg' />
                    <BoxedDiv box={{alignItems: 'center', flex: 'row', marginLeft: 2}}>
                        <Text>Submitting your data template request...</Text>
                    </BoxedDiv>
                </BoxedDiv>
            </Modal>);
        } else {
            setOverlay(null);
        }

    }, [setOverlay, requestDataTemplateStatus]);
    

    useEffect(() => {
        if (dataSubmission.customerActionComments != null && (
                dataSubmission.status === 'Action required' ||
                dataSubmission.status === 'Draft'
            )
        ) {
            attemptScrollToSelector(SELECTOR_NOTIFICATION_KIND('info'), 5);
        }
    }, [dataSubmission.customerActionComments, dataSubmission.status]);

    return (
        <>
            <PageHeader>
                <PageHeaderTitle>{customName || dataSubmission.submissionTypeName}</PageHeaderTitle>
                <BoxedDiv box={{alignItems: 'flex-end', justifyContent: 'space-between', flex: 'row'}}>
                    <PageHeaderDetailsTable>
                        <tr>
                            <th scope='row'>Reporting entity</th>
                            <td><Text $size={21} weight='semibold'>{dataSubmission?.reportingOrganisationName}</Text></td>
                        </tr>

                        {
                        dataSubmission?.reportingOrganisationAbn &&
                        <tr>
                            <th scope='row'>Reporting ABN</th>
                            <td>{formatAbn(dataSubmission?.reportingOrganisationAbn)}</td>
                        </tr>
                        }

                        {
                        dataSubmission?.caseId &&
                        <tr>
                            <th scope='row'>Case ID</th>
                            <td>{dataSubmission?.caseId}</td>
                        </tr>
                        }

                        {
                            dataSubmission.reportFrequencyTypeName !== 'Annually' && <>
                                {
                                    dataSubmission?.obligationDate != null ?
                                    // Use obligation date to get month
                                    <tr>
                                        <th scope='row'>Month</th>
                                        <td>{submissionMonthLabel(new Date(dataSubmission?.obligationDate).getMonth() + 1)}</td>
                                    </tr> :
                                
                                    dataSubmission?.reportingMonth &&
                                    <tr>
                                        <th scope='row'>Month</th>
                                        <td>{submissionMonthLabel(dataSubmission?.reportingMonth as number)}</td>
                                    </tr>
                                }

                                {
                                dataSubmission?.reportingYear &&
                                <tr>
                                    <th scope='row'>Year</th>
                                    <td>{dataSubmission?.reportingYear}</td>
                                </tr>
                                }
                            </>
                        }
                        {
                        dataSubmission?.obligationDate &&
                        <tr>
                            <th scope='row'>Obligation date</th>
                            <td>{humanDate(new Date(dataSubmission?.obligationDate))}</td>
                        </tr>
                        }

                        {
                            dataSubmission.reportFrequencyTypeName === 'Annually' &&
                            <tr>
                                <th scope='row'>Reporting period</th>
                                <td>{submissionPeriodLabel(dataSubmission)}</td>
                            </tr>
                        }

                        {
                        dataSubmission?.dueDate &&
                        <tr>
                            <th scope='row'>Due date</th>
                            <td>{humanDate(new Date(dataSubmission?.dueDate))}</td>
                        </tr>
                        }
                    </PageHeaderDetailsTable>
                </BoxedDiv>
            </PageHeader>
            {
                hasTemplateImport && process &&
                <TemplateImportDialog 
                    clearFile={clearFile}
                    dataSubmission={dataSubmission}
                    file={file}
                    process={process}
                    setTemplateImportState={setTemplateImportState}
                    templateImportState={templateImportState}
                />
            }
            { showExtraButtons &&
                <BoxedDiv box={{alignItems: 'center', flex: 'row-reverse', paddingRight: 5, marginTop: 3}}>
                    {hasTemplateImport &&
                        <Button $kind="text" onClick={handleTemplateImport} marginRight={4}>
                            <BoxedSpan box={{marginRight: 1}}><ImportFile size="sm"/></BoxedSpan> Import data template
                        </Button>
                    }
                    {   
                    (hasTemplateImport && requestDataTemplateStatus !== 'submitted' && showExtraButtons) &&
                        <Button $kind="text" onClick={handleRequestDataTemplate} marginRight={4}>
                            <BoxedSpan box={{marginRight: 1}}><Envelope size="sm"/></BoxedSpan> Request data template
                        </Button>
                    }
                    {hasClearData &&
                        <Button $kind="text" onClick={handleClearData} marginRight={4}>
                            <BoxedSpan box={{marginRight: 1}}><Eraser size="sm"/></BoxedSpan> Clear all data
                        </Button>
                    }
                </BoxedDiv>
            }
            {
                hasTemplateImportUnsavedChanges && 
                <div className='container'>
                    <BoxedDiv box={{marginTop: 5}}>
                        <Notification align='flex-start' kind='info'>
                            <BoxedDiv box={{flex: 'column', paddingTop: 1}}>
                                <H3>You have unsaved changes</H3>

                                <BoxedDiv box={{marginTop: 2}}>
                                    <UL padding='0'>
                                        <ArrowLI color='primary'>
                                            Your data submission contains unsaved changes. Please ensure you save all pages before exiting your data submission to avoid loss of data.
                                        </ArrowLI>
                                    </UL>
                                </BoxedDiv>
                            </BoxedDiv>
                        </Notification>
                    </BoxedDiv>
                    
                </div>
            }

            <CustomerActionComments
                dataSubmission={dataSubmission}
            />

            {
                requestDataTemplateStatus === 'submitted' &&
                <div className='container'>
                    <BoxedDiv box={{marginTop: 5}}>
                        <Notification align='flex-start' kind='confirmation'>
                            <BoxedDiv box={{flex: 'column', paddingTop: 1}}>
                                <BoxedDiv box={{flex: 'row', justifyContent: 'space-between'}}>
                                    <H3>Your request has been successful</H3>

                                    <Button
                                        $kind='unstyled'
                                        marginLeft={-2}
                                        marginRight={2}
                                        onClick={() => {setRequestDataTemplateStatus('init')}}
                                    >
                                        <Text $color='blue-70' weight='semibold'>
                                            <BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
                                                <Close size='md' />
                                            </BoxedSpan>
                                        </Text>   
                                    </Button>
                                </BoxedDiv>

                                <BoxedDiv box={{marginTop: 2}}>
                                    <UL padding='0'>
                                        <ArrowLI color='primary'>
                                            An email with the template will be sent to you and the registered contacts for your organisation shortly. If you haven't received the email in 15 minutes, try again or contact <SupportLink>PSIMSSupport@industry.gov.au</SupportLink>.
                                        </ArrowLI>
                                    </UL>
                                </BoxedDiv>
                            </BoxedDiv>
                        </Notification>
                    </BoxedDiv>
                </div>
            }
            {
                requestDataTemplateStatus === 'submit_failed' &&
                <div className='container'>
                    <BoxedDiv box={{marginTop: 5}}>
                        <Notification align='flex-start' kind='warning'>
                            <BoxedDiv box={{flex: 'column', paddingTop: 1}}>

                            <BoxedDiv box={{flex: 'row', justifyContent: 'space-between'}}>
                                    <H3>Your request has failed</H3>

                                    <Button
                                        $kind='unstyled'
                                        marginLeft={-2}
                                        marginRight={2}
                                        onClick={() => {setRequestDataTemplateStatus('init')}}
                                    >
                                        <Text $color='blue-70' weight='semibold'>
                                            <BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
                                                <Close size='md' />
                                            </BoxedSpan>
                                        </Text>   
                                    </Button>
                                </BoxedDiv>

                                <BoxedDiv box={{marginTop: 2}}>
                                    <UL padding='0'>
                                        <ArrowLI color='primary'>
                                            Please try again, or contact <SupportLink>PSIMSSupport@industry.gov.au</SupportLink> if the problem persists.
                                        </ArrowLI>
                                    </UL>
                                </BoxedDiv>
                            </BoxedDiv>
                        </Notification>
                    </BoxedDiv>
                </div>
                
            }
        </>
    )
}

export default Header;
