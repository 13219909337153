import React, { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { isWithChilren, isWithNavbarSettings, isWithPath, Page, PAGES, WithChildren, WithNavbarSettings, WithPath } from 'psims/constants/pages';
import Text from 'psims/react/components/text';
import { useUser } from 'psims/react/providers/user';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import Chevron from '../components/icons/chevron';
import useSectionFocusout from '../util/use-section-focusout';
import useKeyHandler from '../util/use-key-handler';
import { useFsspCommitments } from '../providers/api/fssp-commitments';

interface NavbarProps { };

const StyledNav = styled(BoxedDiv)`
	border-top: 1px solid var(--color-primary-interactive);

	& nav {
		flex: 1;
	}

	& ul {
		display: flex;
		padding-right: var(--spacing-xxl);
		padding-left: 0;
		list-style: none;
	}

	& ul > li {
		padding: 0;
		position: relative;

		&:not(:first-child) {
			margin-left: 8px;
		}
	}

	& ul > li .text {
		color: var(--color-white);
		font-size: var(--font-size-160);
		font-weight: 600;
		transition: color var(--transition-duration-mid) ease-in-out;
	}

	& ul > li button {
		border: none;
		background: none;
		cursor: pointer;
		height: 100%;
	}

	& ul > li a,
	& ul > li button {
		background: var(--color-primary);
		box-sizing: border-box;
		display: inline-block;
		padding: 16px;
		text-decoration: none;

		&:hover {
			background: var(--color-primary-interactive);
		}

		&:focus,
		&:active {
			& span {
				text-decoration: underline;
			}
			background: var(--color-primary-dark);
		}
	}

	& nav > ul > li:first-child > * {
		margin-left: -16px;
	}

	& ul > li .icon {
		transform: rotateZ(180deg);
		transition: transform var(--transition-duration-xfast) ease-in-out;
	}

	& ul li button[aria-expanded="true"] .icon {
		transform: rotateZ(0deg);
	}

	// Nested items
	& ul li button[aria-expanded="true"] + ul {
		display: flex;
	}

	& ul ul {
		align-items: stretch;
		display: none;
		flex-direction: column;
		position: absolute;
		white-space: nowrap;
		z-index: 1;
	}

	& ul ul > li:not(:first-child) {
		margin-left: 0;
	}

	& ul ul > li a {
		box-sizing: content-box;
		width: 100%;

		margin-left: 0;
	}
`;

const Navbar = (props: PropsWithChildren<NavbarProps>) => {
	const vm = useVM(props);

	const { activePage } = vm;

	if (activePage == null) {
		return null;
	}

	return (
		<StyledNav box={{ flexGrow: 1 }}>
			<BoxedDiv className='container' box={{ flexGrow: 1, background: 'primary' }} >
				<nav aria-label='Main pages'>
					<ul>
						{vm.pages
							.filter(p => p.navbarSettings.level === 0)
							.map(page => (
								<li key={page.name}>
									<NavItem
										activePage={activePage}
										menuCtrl={vm.menuCtrl}
										page={page}
										pages={vm.pages}
									/>
								</li>)
							)}
					</ul>
				</nav>
			</BoxedDiv>
		</StyledNav>
	);
};

function useVM(props: NavbarProps) {
	const { pathname } = useLocation();
	const { user } = useUser();
	const { fsspCommitments, status: fsspCommitmentsStatus } = useFsspCommitments();
	const menuCtrl = useExpandableMenu();

	const activePage = useMemo(() => {
		return PAGES
			.find(p => {
				return isWithPath(p) && pathname.replace(/\?.*/, '') === p.path;
			});
	}, [pathname]);

	const pages = useMemo(() => {
		return PAGES
			.filter(isWithNavbarSettings)
			.filter(p => {
				if (p.name !== 'home' && user?.roleType === 'LFG Portal Admin') {
					return false;
				}

				if (p.name === 'user-management' && user?.roleType !== 'Company Admin') {
					return false;
				}

				if (p.name === 'company-overview' && user?.roleType !== 'Company Admin') {
					return false;
				}

				if (p.name === 'reporting-obligations' && user?.roleType !== 'Company Admin') {
					return false;
				}

				if (
					p.name === 'fssp-commitment' && (
						fsspCommitmentsStatus === 'error' || 
						(fsspCommitments != null && fsspCommitments.length === 0)
					)
				) {
					return false;
				}

				return true;
			})
	}, [fsspCommitments, fsspCommitmentsStatus, user?.roleType]);

	return {
		activePage,
		menuCtrl,
		pages,
	};
}

interface NavItemProps<TPage extends Page> {
	activePage: Page;
	menuCtrl: UseExpandableMenu;
	page: WithNavbarSettings<TPage>;
	pages: Array<Page>;
}

const NavItem = <TPage extends Page>({ activePage, menuCtrl, page, pages }: NavItemProps<TPage>) => {
	const { navbarSettings } = page;

	if (navbarSettings.kind === 'link' && isWithPath(page)) {
		return (
			<MenuLink
				activePage={activePage}
				label={page.label}
				menuCtrl={menuCtrl}
				name={page.name}
				path={page.path}
			/>
		)
	}

	return (
		isWithChilren(page) ?
			<MenuCategory
				activePage={activePage}
				page={page}
				pages={pages}
				menuCtrl={menuCtrl}
			/> :
			null
	);
}

interface MenuLinkProps {
	activePage: Page;
	label: string;
	menuCtrl: UseExpandableMenu;
	name: string;
	path: string;
}

const MenuLink = ({ activePage, label, menuCtrl, name, path }: MenuLinkProps) => {
	const isActivePage = useMemo(() => activePage.name === name, [activePage.name, name]);

	return (
		<Link
			aria-current={isActivePage ? 'page' : undefined}
			onClick={menuCtrl.closeMenu}
			to={path}
		>
			<Text>{label}</Text>
		</Link>
	)
}

interface CategoryProps<TPage extends Page> {
	activePage: Page
	menuCtrl: UseExpandableMenu;
	page: WithChildren<TPage>;
	pages: Array<Page>;
}

const MenuCategory = <TPage extends Page>({ activePage, menuCtrl, page, pages }: CategoryProps<TPage>) => {
	const el = useRef<HTMLElement | null>(null);

	const { setEl: setFocusoutRef } = useSectionFocusout({
		onFocusout: menuCtrl.closeMenu,
	});

	const { setEl: setKeyhandlerRef } = useKeyHandler({
		onKeyup: () => {
			el.current != null && el.current.focus();
			menuCtrl.closeMenu();
		},
		key: 'Escape',
	});

	const setCategoryRef = useCallback((elm: HTMLElement | null) => {
		if (elm != null) {
			setFocusoutRef(elm);
			setKeyhandlerRef(elm);
		}
	}, [setFocusoutRef, setKeyhandlerRef]);

	const setTargetRef = useCallback((elm: HTMLElement | null) => {
		if (elm != null) {
			el.current = (elm);
		}
	}, []);

	const isExpanded = useMemo(() => {
		return page.name === menuCtrl.expandedMenuName;
	}, [page.name, menuCtrl.expandedMenuName]);

	const toggleExpanded = useCallback(() => {
		if (isExpanded) {
			menuCtrl.closeMenu();
		} else {
			menuCtrl.openMenu(page.name);
		}
	}, [isExpanded, menuCtrl, page.name]);

	const childMenuItems = useMemo<Array<WithPath<Page>>>(() => {
		return pages
			.filter(p => page.navbarSettings.childrenNames.includes(p.name))
			.filter<WithPath<Page>>(isWithPath);
	}, [pages, page.navbarSettings.childrenNames]);

	return (
		<BoxedDiv box={{ height: '100%' }} ref={setCategoryRef}>
			<button
				aria-expanded={isExpanded ? 'true' : 'false'}
				aria-controls={`nav_menu_${page.label}`}
				onClick={toggleExpanded}
				ref={setTargetRef}
			>
				<BoxedSpan box={{ alignItems: 'center', flex: 'row' }}>
					<span className='text'>{page.label}</span>

					<BoxedSpan box={{ marginLeft: 1 }}>
						<Chevron color='white' size='sm' />
					</BoxedSpan>
				</BoxedSpan>
			</button>

			<ul id={`nav_menu_${page.label}`}>
				{
					childMenuItems.map((menuItem) => (
						<li key={menuItem.name}>
							<MenuLink
								activePage={activePage}
								menuCtrl={menuCtrl}
								{...menuItem}
							/>
						</li>
					))
				}
			</ul>
		</BoxedDiv>
	)
}

function useExpandableMenu() {
	const [expandedMenuName, setExpandedMenuName] = useState<string | null>(null);

	const closeMenu = useCallback(() => setExpandedMenuName(null), []);

	const openMenu = useCallback((name: string) => setExpandedMenuName(name), []);

	return {
		expandedMenuName,
		closeMenu,
		openMenu
	};
}

type UseExpandableMenu = ReturnType<typeof useExpandableMenu>;

export default Navbar;
