import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from "react-markdown";

import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { ViewMode } from '../shared/use-view-mode';
import { Table, TR, ColumnHeader, TD, CellInput, TH, CellSelect, ActionCellTD, ActionCellTH } from '../shared/data-table-components';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import Text from "psims/react/components/text";
import { localeNumberWithFixed } from 'psims/lib/formatters/numbers';
import { SelectController, useSelectController } from 'psims/react/components/select';
import { asNumber, numberFieldValue } from 'psims/lib/number';
import { AddButton, IconButton } from 'psims/react/components/button';
import useIsAutoFocused from 'psims/react/util/use-is-autofocused';
import { ProductionAreaOptionCtrl } from '../shared/production-area-option';
import { asString } from 'psims/lib/string';
import Textarea from 'psims/react/components/textarea';
import { isEmpty } from 'psims/lib/empty';
import { H2, H3 } from 'psims/react/components/typography';
import { TooltipHelp } from 'psims/react/pages/primary-pages/data-submissions/shared/tooltip-help';
import { isFocusFieldProduction, DataPageView, ProductionView, ProductView, UseProductionForm, GroupView } from '../productions-shared/use-production-form';
import { FieldConfig, ProductionTypeConfig, SHORTNAME_MAP } from '../productions-shared/util';
import { ProductionField } from 'psims/models/submission-types/production';
import { ProductionProductGroup } from 'psims/models/ref-data/production-product-group';
import { UseProductionPortalData } from './use-production-portal-data';
import FloatingMessage from 'psims/react/components/floating-message';
import { EXPIRED_OR_INTERNAL_PRODUCT } from 'psims/constants/validation-messages';
import useFocusable from 'psims/react/util/use-focusable';

interface ProductionTypeEditorProps<TGroup extends ProductionProductGroup> {
  formCtrl: UseProductionForm;
  portalDataCtrl: UseProductionPortalData;
  productionTypeConfig: ProductionTypeConfig<TGroup>;
  viewMode: ViewMode;
}

const ProductionTypeEditor = <TGroup extends ProductionProductGroup>({
  formCtrl, portalDataCtrl, productionTypeConfig, viewMode
}: ProductionTypeEditorProps<TGroup>) => {
  const { view: submissionView } = formCtrl;
  const view = submissionView.dataPageView;
  const comments = view.comments.data;
  const disableTableEntry = viewMode !== 'edit';

  if (view == null || view.productionAreaOptions == null) {
    return null;
  }

  return (
    <BoxedDiv box={{ marginBottom: 4, overflowX: 'hidden' }}>
      {
        view.groups
          .filter(g => g.groupStatus !== 'expired' && g.groupStatus !== 'empty')
          .sort((a, b) => a.productGroupRefData.refData.displayOrder - b.productGroupRefData.refData.displayOrder)
          .map(g => (
            <GroupSection
              key={g.productGroupRefData.refData.id}
              disableTableEntry={disableTableEntry}
              formCtrl={formCtrl}
              groupView={g}
              pageView={view}
              portalDataCtrl={portalDataCtrl}
              productionTypeConfig={productionTypeConfig}
            />
          ))
      }

      {/* Page comments */}
      <BoxedDiv box={{ marginTop: 6 }}>
        <Textarea
          disabled={viewMode !== 'edit'}
          error={view.comments.validationError?.notification.message}
          id='comments'
          label='Comments'
          setFocused={formCtrl.view?.focusedField === 'comments'}
          value={asString(comments?.comment)}
          onChange={e => formCtrl.updateProductionComment(e.target.value)}
        />
      </BoxedDiv>
    </BoxedDiv>
  )
}

interface ProductEditorProps <TGroup extends ProductionProductGroup> {
  disableTableEntry: boolean;
  formCtrl: UseProductionForm;
  pageView: DataPageView;
  portalDataCtrl: UseProductionPortalData;
  productionTypeConfig: ProductionTypeConfig<TGroup>;
  productView: ProductView;
}

const ProductEditor = <TGroup extends ProductionProductGroup>({
  disableTableEntry, formCtrl, pageView, portalDataCtrl, productionTypeConfig, productView
}: ProductEditorProps<TGroup>) => {
    const {applicableFields, productionTypeName} = productionTypeConfig;
    const product = productView.productRefData;
    const productGroup = productView.productGroupRefData;
    const productions = productView.productions;
    const Help = portalDataCtrl.productPortalData.find(pd => pd?.referenceCode === product.referenceCode)?.content;
    const shortName = SHORTNAME_MAP[productionTypeName];

    // assumption: production area is alway applicable and first
    const fields = applicableFields(product, productGroup.refData);
    const [paFieldConfig, ...fieldConfigs] = fields;

    const showExpiredError = product.isExpired && !disableTableEntry;

    const showAddButton = !showExpiredError && !disableTableEntry;

    return (
      <BoxedDiv box={{ overflowX: 'auto' }}>
        <BoxedDiv box={{ marginBottom: 2, marginTop: 4 }}>
          <H3>
            <BoxedSpan box={{ alignItems: 'center', flex: 'row' }}>
              {product.productName}
              {
                Help && <>
                  &nbsp;
                  <TooltipHelp
                    Help={<ReactMarkdown>{Help}</ReactMarkdown>}
                  />
                </>
              }

              {
                showExpiredError && <BoxedSpan box={{marginBottom: -0.5, marginLeft: 1}}>
                  <FloatingMessage
                      content={EXPIRED_OR_INTERNAL_PRODUCT}
                      kind="warning"
                      role="alert"
                  />
                </BoxedSpan>
              }
            </BoxedSpan>
          </H3>
        </BoxedDiv>

        <Table caption={`Your ${shortName} production data for ${product.productName}`}>
          {/* Column headers */}
          <thead>
            <TR>
              <ColumnHeader
                id={paFieldConfig.helpId}
                fixedWidth='340px'
                label={paFieldConfig?.columnHeader}
                Help={paFieldConfig?.help}
              />

              <ColumnHeader label='State' fixedWidth='80px' />

              {
                fieldConfigs.map(fg => (
                  <ColumnHeader
                    key={fg.field}
                    id={fg.helpId}
                    $align='center'
                    fixedWidth='140px'
                    label={fg.columnHeader}
                    Help={fg.help}
                  />
                ))
              }

              <ActionCellTH><VisuallyHidden>Actions</VisuallyHidden></ActionCellTH>
            </TR>
          </thead>

          <tbody>
            {
              productions
                .filter(p => p.productStatus !== 'expired')
                .map(production => (
                  <Production
                    key={`${production.productRefData.id}_${production.rowIndex}`}
                    applicableFields={fields}
                    disabled={disableTableEntry}
                    formCtrl={formCtrl}
                    pageView={pageView}
                    productionView={production}
                    productView={productView}
                  />
                ))
            }
          </tbody>

          <tfoot>
            {/* Totals row */}
            <ProductionTotalsRow applicableFields={fields} productView={productView} />
          </tfoot>
        </Table>

        {showAddButton ?
          <BoxedDiv box={{ marginLeft: 2, marginBottom: 5, marginTop: -5 }}>
            <AddButton
              label={`Add ${paFieldConfig.label}`}
              onClick={() => formCtrl.addProduction(product.id)}
            >Add {paFieldConfig.label}</AddButton>
          </BoxedDiv> :
          null
        }
      </BoxedDiv>
    )

}

interface GroupSectionProps<TGroup extends ProductionProductGroup> {
  disableTableEntry: boolean;
  formCtrl: UseProductionForm;
  groupView: GroupView;
  pageView: DataPageView;
  portalDataCtrl: UseProductionPortalData;
  productionTypeConfig: ProductionTypeConfig<TGroup>;
}

const GroupSection = <TGroup extends ProductionProductGroup>({
  disableTableEntry, formCtrl, groupView, pageView, portalDataCtrl, productionTypeConfig
}: GroupSectionProps<TGroup>) => {
  const {getGroupHeader} = productionTypeConfig;
  const productionGroup = groupView.productGroupRefData.refData;

  const Help = portalDataCtrl.groupPortalData.find(pd => pd?.referenceCode === productionGroup.referenceCode)?.content;

  return (
    <BoxedDiv box={{flex: 'column'}}>
      <H2>{getGroupHeader(productionGroup)}</H2>

      {
        Help != null ?
        <BoxedDiv box={{}}>
          <ReactMarkdown>{Help}</ReactMarkdown>
        </BoxedDiv> :
        null
      }

      {
        groupView.products
          .filter(p => (
            !p.productRefData.isExpired || 
            p.productions.some(p => p.productStatus === 'expired_with_data')
          )).map(p => (
          <ProductEditor
            key={p.productRefData.id}
            disableTableEntry={disableTableEntry}
            formCtrl={formCtrl}
            pageView={pageView}
            portalDataCtrl={portalDataCtrl}
            productView={p}
            productionTypeConfig={productionTypeConfig}
          />
        ))

      }
    </BoxedDiv>
  )
}

interface ProductValueInputCellProps {
  disabled: boolean;
  fieldConfig: FieldConfig;
  formCtrl: UseProductionForm;
  productionView: ProductionView;
}

const ProductValueInputCell = ({ disabled, fieldConfig, formCtrl, productionView }: ProductValueInputCellProps) => {
  const { field, helpId, label } = fieldConfig;
  const error = productionView.validationErrors.find(v => v.tooltip.target === field);
  const info = productionView.infoMessages.find(i => i.tooltip.target === field);

  const decimalPlaces = field === 'productionDensity' ? 10 : undefined;

  const isFocused = useMemo(() => {
    return (
      formCtrl.view.focusedField != null &&
      isFocusFieldProduction(formCtrl.view.focusedField) &&
      formCtrl.view.focusedField.field === field &&
      formCtrl.view.focusedField.productId === productionView.productRefData.id &&
      formCtrl.view.focusedField.rowIndex === productionView.rowIndex
    );
  }, [field, formCtrl.view.focusedField, productionView.productRefData.id, productionView.rowIndex]);

  return (
    <CellInput
      aria-describedby={helpId}
      decimalPlaces={decimalPlaces}
      disabled={disabled}
      error={error?.tooltip.content}
      forceError={formCtrl.view.shouldForceErrors}
      info={info?.tooltip.content}
      shouldFocus={isFocused}
      value={productionView.data ? productionView.data[field] : undefined}
      onChange={val => formCtrl.updateProduction(productionView.productRefData.id, productionView.rowIndex, field, val)}
      label={`${decimalPlaces == null ? 'Integer' : 'Decimal'} value for ${label}`}
    />
  )
}

interface ProductionProps {
  applicableFields: Array<FieldConfig>;
  disabled: boolean;
  formCtrl: UseProductionForm;
  pageView: DataPageView
  productionView: ProductionView;
  productView: ProductView;
}

const Production = ({ applicableFields, disabled, formCtrl, pageView, productionView, productView }: ProductionProps) => {
  const { data: production, selectedProductionArea, productRefData, rowIndex, validationErrors } = productionView;

  const firstGroup = useMemo(() => {
    return pageView.groups.find(g => g.groupStatus === 'active' || g.groupStatus === 'expired_with_data')
  }, [pageView.groups]);

  const shouldAutoFocus = useIsAutoFocused(
    rowIndex === 0 &&
    productView.rowIndex === 0 &&
    firstGroup?.productGroupRefData.refData.id === productView.productGroupRefData.refData.id
  );

  const [deleteRef, setDeleteRef] = useState<HTMLElement | null>(null);

  const isProductionAreaFocused = shouldAutoFocus || (
    formCtrl.view.focusedField != null &&
    isFocusFieldProduction(formCtrl.view.focusedField) &&
    formCtrl.view.focusedField.field === 'organisationProductionAreaId' &&
    formCtrl.view.focusedField.productId === productRefData.id &&
    formCtrl.view.focusedField.rowIndex === productionView.rowIndex
  );

  const isDeleteButtonFocused = (
    isFocusFieldProduction(formCtrl.view.focusedField) &&
    formCtrl.view.focusedField.field === 'delete' &&
    formCtrl.view.focusedField.productId === productRefData.id &&
    formCtrl.view.focusedField.rowIndex === productionView.rowIndex
  );

  const productionAreaOptions = selectedProductionArea != null ?
    pageView.productionAreaOptions.filter(x => x.data.isActive || selectedProductionArea.data.id === x.data.id) :
    pageView.productionAreaOptions.filter(x => x.data.isActive);

  const selectCtrl = useSelectController({
    value: numberFieldValue(production?.organisationProductionAreaId),
    onChange: (productionAreaId) => formCtrl.updateProduction(
      asNumber(production?.productionProductId),
      productionView.rowIndex,
      'organisationProductionAreaId',
      productionAreaId ? `${productionAreaId}` : undefined
    ),
    options: productionAreaOptions,
  });

  const onDelete = () => {
    formCtrl.deleteProduction(productRefData.id, rowIndex, deleteRef);
  }

  useEffect(() => {
    if (deleteRef != null && isDeleteButtonFocused) {
      deleteRef.focus();
    }
  }, [deleteRef, isDeleteButtonFocused]);

  if (production == null) {
    return null;
  }

  const stateName = selectedProductionArea?.data.productionArea.productionAreaStateName || '';

  const productions = productView.productions
    .flat()
    .filter(p => p.productRefData.id === productRefData.id);
  
  const canDelete = !disabled && (productions.length > 1 || !isEmptyRow(productionView));

  const [paField, ...fields] = applicableFields;

  return (
    <TR>
      <CellSelect
        aria-describedby={paField?.helpId}
        ctrl={selectCtrl as SelectController}
        disabled={disabled}
        error={validationErrors.find(e => e.tooltip.target === 'organisationProductionAreaId')?.tooltip.content}
        info={productionView.infoMessages.find(i => i.tooltip.target === 'organisationProductionAreaId')?.tooltip.content}
        id={`production${production.id}_productionarea`}
        label='Facility name'
        shouldFocus={isProductionAreaFocused}
        optionCtrl={ProductionAreaOptionCtrl}
      />

      <TD isReadonly={true}><Text $noWrap={true}>{stateName}</Text></TD>

      {
        fields.map(field => (
          <ProductValueInputCell
            key={field.field}
            fieldConfig={field}
            disabled={disabled}
            formCtrl={formCtrl}
            productionView={productionView}
          />
        ))
      }

      {/* Delete button */}
      <ActionCellTD>
        <BoxedDiv box={{ alignItems: 'center', flex: 'row', marginLeft: 1 }}>
          {canDelete &&
            <DeleteRowButton
              focusedField={formCtrl.view.focusedField}
              onClick={onDelete}
              productId={productView.productRefData.id}
              ref={setDeleteRef}
            />
          }
        </BoxedDiv>
      </ActionCellTD>
    </TR>
  )
}

interface ProductionTotalCellProps {
  total?: number;
}

const ProductionTotalCell = ({ total }: ProductionTotalCellProps) => {
  return (
    <TD $align="right">
      <BoxedDiv box={{ alignItems: 'center', flex: 'row', flexGrow: 1, marginRight: 1.5 }}>
        <Text weight="bold" fullWidth={true}>{localeNumberWithFixed(total)}</Text>
      </BoxedDiv>
    </TD>
  )
}

interface ProductionTotalsRowProps {
  applicableFields: Array<FieldConfig>;
  productView: ProductView;
}

const TOTAL_FIELDS: { [key in ProductionField]: boolean } = {
  closingStocks: true,
  consumed: true,
  delivered: true,
  grossCalorificValue: false,
  openingStocks: true,
  organisationProductionAreaId: false,
  produced: true,
  productionDensity: false,
};

const ProductionTotalsRow = ({ applicableFields, productView: { totals } }: ProductionTotalsRowProps) => {
  const indexOfFirstTotalField = applicableFields.findIndex(af => TOTAL_FIELDS[af.field]);
  const headSpan = indexOfFirstTotalField + 1; // Add 1 for read-only state column
  const fields = [...applicableFields];

  // Drop inital fields known not to be total fields
  for (let i = 0; i < indexOfFirstTotalField; i++) {
    fields.shift();
  }

  let emptySpan = 0;

  return (
    <TR>
      <TH key='header' $align='right' colSpan={headSpan} scope='row'><Text>Totals</Text></TH>
      {
        fields.map((f, index) => {
          // Need to do some fiddling to add correct colspans for adjacent non-total cells
          // regardless of column ordering
          const isTotalsField = TOTAL_FIELDS[f.field];
          const isLast = index === fields.length - 1;
          const renderEmptySpan = emptySpan > 0 && (isTotalsField || isLast);

          // Track span for adjacent empty cells
          emptySpan = isTotalsField ? 0 : emptySpan + 1;

          return <Fragment key={f.field}>
            {isTotalsField && <ProductionTotalCell key={f.field} total={totals[f.field as keyof typeof totals]} />}
            {renderEmptySpan && <TD colSpan={emptySpan} />}
          </Fragment>
        })
      }
    </TR>
  )
}

export default ProductionTypeEditor;

function isEmptyRow(productionView: ProductionView) {
  if (productionView.data == null) {
    return true;
  }

  const { productionProductId, concurrencyToken, id, recordAction, ...fields } = productionView.data;

  return isEmpty(fields);
}

type DeleteFocusField = {
  field: 'delete';
  productId: number;
}

interface DeleteRowProps {
    focusedField: UseProductionForm['view']['focusedField'] | null;
    onClick: () => any;
    productId: number;
}

const DeleteRowButton = React.forwardRef(({focusedField, onClick, productId}: DeleteRowProps, ref) => {
    const focusable = useFocusable({
        setFocused: (
            isDeleteFocusField(focusedField) &&
            focusedField.field === 'delete' &&
            focusedField.productId === productId
        ),
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <IconButton
            color='dark-grey'
            icon='trash'
            label='Delete row for product'
            onClick={onClick}
            ref={setRef}
            size='sm'
        />
    );
});

function isDeleteFocusField(maybe?: unknown): maybe is DeleteFocusField {
    const maybeAs = maybe as DeleteFocusField;

    return (
        maybeAs != null &&
        maybeAs.field === 'delete' &&
        maybeAs.productId != null
    );
}
