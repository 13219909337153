import { useEffect, useRef, useState } from "react";

type FocusState = 'unvisited' | 'first_focus' | 'focused' | 'visited';
type DataState = 'changed' | 'unchanged';

interface UseFieldStateProps {
  initialValue: string | undefined | null;
}

function useFieldState({initialValue}: UseFieldStateProps) {
  const referenceValue = useRef(initialValue || '');
  const [currentValue, setCurrentValue] = useState(referenceValue.current);
  const [focusState, setFocusState] = useState<FocusState>('unvisited');
  const [dataState, setDataState] = useState<DataState>('unchanged');

  const [el, setEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (el == null) {
      referenceValue.current = initialValue || '';
      setFocusState('unvisited');
      setDataState('unchanged');
      return;
    }

    function handleFocus(evt: FocusEvent)  {
      setFocusState(prev => prev === 'unvisited' ? 'first_focus' : 'focused');
    }

    function handleBlur(evt: FocusEvent)  {
      setFocusState('visited');
      if (isEvtWithValue(evt) && evt.target.value !== referenceValue.current) {
        setDataState('changed');
      } else {
        setDataState('unchanged');
      }
    }

    function handleChange(evt: unknown) {
      if (isEvtWithValue(evt)) {
        setCurrentValue(evt.target.value || '');
      }
    }

    el.addEventListener('blur', handleBlur);
    el.addEventListener('focus', handleFocus);
    el.addEventListener('change', handleChange);

    return () => {
      el.removeEventListener('blur', handleBlur);
      el.removeEventListener('focus', handleFocus);
      el.removeEventListener('change', handleChange);
    }
  }, [el, initialValue]);

  return {
    currentValue,
    dataState,
    focusState,
    setEl,
  };
}

type EvtWithValue = {
  target: {
    value: string | undefined;
  };
};

function isEvtWithValue(maybe: unknown): maybe is EvtWithValue {
  const maybeAs = maybe as EvtWithValue;

  return (
    maybeAs != null &&
    maybeAs.target != null
  );
}

export default useFieldState

export type UseFieldState = ReturnType<typeof useFieldState>;
