import { Tooltip } from 'psims/react/components/tooltip';

interface TooltipHelpProps {
    disableAriaDescribedBy?: boolean;
    Help: string | JSX.Element;
    helpId?: string;
}

export const TooltipHelp = ({disableAriaDescribedBy, Help, helpId}: TooltipHelpProps) => {
    return (
        <Tooltip
            disableAriaDescribedBy={disableAriaDescribedBy}
            Help={Help}
            helpId={helpId}
        />
    )
}
