/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StockholdingSubmissionVMApiResponse } from '../models/StockholdingSubmissionVMApiResponse';
import type { UpdateDataSubmissionVM } from '../models/UpdateDataSubmissionVM';
import type { UpdateStockholdingSubmissionDomesticVM } from '../models/UpdateStockholdingSubmissionDomesticVM';
import type { UpdateStockholdingSubmissionOnWaterVM } from '../models/UpdateStockholdingSubmissionOnWaterVM';
import type { UpdateStockholdingSubmissionOverseasVM } from '../models/UpdateStockholdingSubmissionOverseasVM';
import { request as __request } from '../core/request';

export class StockholdingsService {

    /**
     * @returns StockholdingSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async getStockholdingSubmission({
id,
}: {
id?: number,
}): Promise<StockholdingSubmissionVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/stockholding`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns StockholdingSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateStockholdingDomestic({
requestBody,
}: {
requestBody?: UpdateStockholdingSubmissionDomesticVM,
}): Promise<StockholdingSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/stockholding/Update-Domestic`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns StockholdingSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateStockholdingOverseas({
requestBody,
}: {
requestBody?: UpdateStockholdingSubmissionOverseasVM,
}): Promise<StockholdingSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/stockholding/Update-Overseas`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns StockholdingSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateStockholdingOnWater({
requestBody,
}: {
requestBody?: UpdateStockholdingSubmissionOnWaterVM,
}): Promise<StockholdingSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/stockholding/Update-OnWater`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns StockholdingSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateStockholdingDataSubmission({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<StockholdingSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/stockholding/Update-DataSubmission`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns StockholdingSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async submitStockholding({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<StockholdingSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/stockholding/Submit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns StockholdingSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async clearAllStockholding({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<StockholdingSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/stockholding/Clear-All`,
            body: requestBody,
        });
        return result.body;
    }

}