import { StorageTypeDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

export type StorageType = PopulatedProps<StorageTypeDTO, 'id' | 'isActive' | 'name'>;

export function assertStorageType(maybe?: unknown): asserts maybe is StorageType {
    const maybeAs = maybe as StorageType;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(maybeAs, hint('storageType'));
    assertNotEmpty(maybeAs.id, hint('storageType.id'));
    assertNotEmpty(maybeAs.isActive, hint('storageType.isActive'));
    assertNotEmpty(maybeAs.name, hint('storageType.name'));
}
