import React, { useState } from 'react';

import Box from 'psims/react/components/box';
import Text from 'psims/react/components/text';
import randomID from 'psims/lib/random-id';

export interface FieldContainerProps {
	error?: string;
	fullWidth?: boolean;
	help?: React.ReactNode;
	hideLabel?: boolean;
	label?: string;
	LabelComponent?: string | React.ReactElement;
	showError?: boolean;
	hideErrorMessage?: boolean;
};

const FieldContainer = (props: React.PropsWithChildren<FieldContainerProps>) => {
	const [helpID] = useState(randomID());
	const {error, fullWidth, help, hideLabel, hideErrorMessage, LabelComponent, label, showError, children} = props;
	const cn = `field-container${error && showError ? ' field-container--error' : ''}`
	return (
		<Box className={cn} flex='column' flexGrow={fullWidth ? 1 : undefined}>
			<label {...(Boolean(help) ? {"aria-describedby": helpID} : {})}>
				{
					label &&
					<Box paddingV={hideLabel ? '0' : 'md'} {...(hideLabel ? {height: 0, overflow: 'hidden'}: {})}>
						<Text $color='black-90'>{label}</Text>
					</Box> 
				}

				{ Boolean(LabelComponent) && LabelComponent }

				{
					Boolean(help) &&
					<Box id={helpID} marginTop='md'>
						<Text variant='help'>{help}</Text>
					</Box>
				}

				{children}
			</label>

			{
				error && showError && !hideErrorMessage &&
				<Box marginTop='sm'>
					<Text variant='field-error'>{error}</Text>
				</Box>
			}
		</Box>
	);
};

export default FieldContainer;
