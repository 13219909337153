import { assertNotEmpty } from "psims/lib/empty";

export type FspProduct = {
  id: number;
  referenceCode: string;
  productName: string;
  productCode: string | null;
  displayOrder: number;
  effectiveFrom: string;
  effectiveTo?: string | null;
  isActive?: boolean;
}

export function isFspProduct(maybe?: unknown): maybe is FspProduct {
  const maybeAs = maybe as FspProduct;

  return (
    maybeAs != null &&
    maybeAs.id != null &&
    maybeAs.displayOrder != null &&
    maybeAs.effectiveFrom != null &&
    maybeAs.productCode != null &&
    maybeAs.productName != null &&
    maybeAs.referenceCode != null
  );
}

export function assertFspProduct(maybe?: unknown): asserts maybe is FspProduct {
    const asFspProduct = maybe as FspProduct;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asFspProduct, hint('fspProduct'));
    assertNotEmpty(asFspProduct.displayOrder, hint('fspProduct.displayOrder'));
    assertNotEmpty(asFspProduct.id, hint('fspProduct.id'));
    assertNotEmpty(asFspProduct.effectiveFrom, hint('fspProduct.effectiveFrom'));
    assertNotEmpty(asFspProduct.productCode, hint('fspProduct.productCode'));
    assertNotEmpty(asFspProduct.productName, hint('fspProduct.productName'));
    assertNotEmpty(asFspProduct.referenceCode, hint('fspProduct.referenceCode'));
}
