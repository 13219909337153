import { useMemo } from "react";

import { DefSubmission } from "psims/models/submission-types/def/def-submission";
import { DefDataSubmissionValidationAlertView, isDefDataSubmissionValidationAlert } from "./types";
import { UseActivities } from "./activities/use-activities";
import { isDEFProduct } from "./activities/util";

interface UseDefSubmissionValidationAlertsProps {
  activitiesCtrl: UseActivities;
  submission: DefSubmission;
}

function useDefSubmissionValidationAlerts({activitiesCtrl, submission}: UseDefSubmissionValidationAlertsProps) {
  const apiValidationAlerts = useMemo<Array<DefDataSubmissionValidationAlertView>>(() => {
    return (submission.dataSubmission.validationAlerts || [])
      .filter(isDefDataSubmissionValidationAlert)
      // Remove submission-level validation alerts if data changes:
      .filter(va => !(
        va.validationAlert === 'SameAsPrevious' &&
        activitiesCtrl.data.find(d => d.data.recordAction != null)
      ))
      .filter(va => !(
        va.validationAlert === 'DefRelativity' &&
        !activitiesCtrl.data.some(d => d.refData != null && isDEFProduct(d.refData))
      ))
      .map(v => ({
        source: 'api',
        validationAlert: v,
        target: 'dataSubmission'
      }));
  }, [activitiesCtrl.data, submission]);

  return {
    validationAlerts: apiValidationAlerts,
  };
}

export default useDefSubmissionValidationAlerts

export type UseDefSubmissionValidationAlerts = ReturnType<typeof useDefSubmissionValidationAlerts>;
