import React from 'react';
import BaseSVG, { SVGProps } from './_base';

const Info = (props: SVGProps) => {
	return (
		<BaseSVG name='info' viewBox='0 0 4 20' {...props}>
            <path id="Info" d="M14.142,4V0H18V4ZM0,4V0H11.571V4Z" transform="translate(0 18) rotate(-90)" fill="currentColor"/>
		</BaseSVG>
	);
};

export default Info;
