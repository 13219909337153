import { RefineryProductGroupDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { RefineryTypeName } from "./refinery-type";

// TODO: update with real referenceCode when available
const REF_CODES = [
    'REF-RI-NHB',
    'REF-RI-BFA',
    'REF-RI-CO',
    'REF-RI-HBI',
    'REF-RO-AKJ',
    'REF-RO-LO',
    'REF-RO-AG',
    'REF-RO-PW',
    'REF-RO-N',
    'REF-GUP-WSU',
    'REF-RI-CFC',
    'REF-RI-ORF',
    'REF-RI-HBD',
    'REF-RI-NGL',
    'REF-RO-OK',
    'REF-RO-G',
    'REF-RO-HO',
    'REF-RO-D',
    'REF-RO-FO',
    'REF-GUP-G',
    'REF-RO-RFP',
    'REF-RO-OPN',
    'REF-RO-PBS',
    'REF-RO-B',
    'REF-RO-PC',
    'REF-GUP-PF',
] as const;

export type RefProductGroupReferenceCode = typeof REF_CODES[number];

export type RefineryProductGroup = PopulatedProps<RefineryProductGroupDTO, 'id' | 'displayOrder' | 'effectiveFrom' | 'isActive' | 'name' | 'refineryTypeId' | 'refineryTypeName'> & {
    referenceCode: RefProductGroupReferenceCode;
    refineryTypeName: RefineryTypeName;
};

export function assertRefineryProductGroup(maybe?: unknown): asserts maybe is RefineryProductGroup {
    const asRefineryProductGroup = maybe as RefineryProductGroup;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asRefineryProductGroup, hint('refinery.ProductGroup'));
    assertNotEmpty(asRefineryProductGroup.displayOrder, hint('refinery.ProductGroup.displayOrder'));
    assertNotEmpty(asRefineryProductGroup.id, hint('refinery.ProductGroup.id'));
    assertNotEmpty(asRefineryProductGroup.isActive, hint('refinery.ProductGroup.isActive'));
    assertNotEmpty(asRefineryProductGroup.name, hint('refinery.ProductGroup.name'));
    assertNotEmpty(asRefineryProductGroup.refineryTypeId, hint('refinery.ProductGroup.refineryTypeId'));
    assertNotEmpty(asRefineryProductGroup.refineryTypeName, hint('refinery.ProductGroup.refineryTypeName'));
}

type RefProductGroupRefCodeName = 
    'aviationgasolines' |
    'aviationkerosenes' |
    'backflows' |
    'bitumens' |
    'condensates' |
    'crudeoils' |
    'diesels' |
    'fueloils' |
    'gases' |
    'gasolines' |
    'heatingoils' |
    'hydrocarbonsdomestic' |
    'hydrocarbonsimport' |
    'lubricants' |
    'naphtha' |
    'ngls' |
    'nonhydrocarbons' |
    'otherkerosenes' |
    'otherproducts' |
    'otherfeedstocks' |
    'paraffins' |
    'petrochemicals' |
    'petroleumsolvents' |
    'petroleumcoke' |
    'refineryfeedstocks' |
    'workingstock';

const REF_CODE_MAP: {[key in RefProductGroupRefCodeName]: RefProductGroupReferenceCode} = {
    nonhydrocarbons: 'REF-RI-NHB',
    backflows: 'REF-RI-BFA',
    crudeoils: 'REF-RI-CO',
    hydrocarbonsimport: 'REF-RI-HBI',
    aviationkerosenes: 'REF-RO-AKJ',
    lubricants: 'REF-RO-LO',
    aviationgasolines: 'REF-RO-AG',
    paraffins: 'REF-RO-PW',
    naphtha: 'REF-RO-N',
    workingstock: 'REF-GUP-WSU',
    condensates: 'REF-RI-CFC',
    otherfeedstocks: 'REF-RI-ORF',
    hydrocarbonsdomestic: 'REF-RI-HBD',
    ngls: 'REF-RI-NGL',
    otherkerosenes: 'REF-RO-OK',
    gasolines: 'REF-RO-G',
    heatingoils: 'REF-RO-HO',
    diesels: 'REF-RO-D',
    fueloils: 'REF-RO-FO',
    gases: 'REF-GUP-G',
    refineryfeedstocks: 'REF-RO-RFP',
    otherproducts: 'REF-RO-OPN',
    petroleumsolvents: 'REF-RO-PBS',
    bitumens: 'REF-RO-B',
    petroleumcoke: 'REF-RO-PC',
    petrochemicals: 'REF-GUP-PF',
};

export function getRefineryProductGroupReferenceCode(key: RefProductGroupRefCodeName) {
    return REF_CODE_MAP[key];
}
