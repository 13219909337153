import { useState } from "react";

import { RefineryTypeName } from "psims/models/ref-data/refinery-type";
import { UseRefineryRefData } from "./use-refinery-ref-data";
import { RefiningSubmissionFormDataVM } from "psims/gen/xapi-client";

type RefDataItem<TTypeName extends RefineryTypeName = RefineryTypeName> = UseRefineryRefData[TTypeName];

type StepCommon = {
    index: number;
    label: string;
}

type StepData = StepCommon & {
    kind: 'data';
    refData: RefDataItem;
}

type StepSubmit = StepCommon & {
    kind: 'submit';
}

export type Step = StepData | StepSubmit;

interface UseRefineryStepsProps {
    refData: UseRefineryRefData;
}

function useRefinerySteps({refData}: UseRefineryStepsProps) {
    const [steps] = useState(buildSteps(refData));
    
    return {
        steps,
    };
}

export default useRefinerySteps

export type UseRefinerySteps = ReturnType<typeof useRefinerySteps>;

export const SAVED_PAGES_MAP: {[k in RefineryTypeName]: keyof RefiningSubmissionFormDataVM} = {
    "Refinery Input": 'refineryInputsPageSaved',
    "Refinery Output": 'refineryOutputsPageSaved',
    "Gases-Unfin-Petrochem-Losses": 'gasesIntermediatePetrochemLossesSaved',
};

function buildSteps(refData: UseRefineryRefData): Array<Step> {
    return [
        buildDataStep(refData["Refinery Input"], 0),
        buildDataStep(refData["Refinery Output"], 1),
        buildDataStep(refData["Gases-Unfin-Petrochem-Losses"], 2),
        {
            index: 3,
            kind: 'submit',
            label: 'Declaration',
        }
    ]
}

function buildDataStep<TTypeName extends RefineryTypeName>(refDataItem: RefDataItem<TTypeName>, index: number): StepData {
    return {
        index,
        kind: 'data',
        label: refDataItem.refineryType.displayName,
        refData: refDataItem,
    };
}
