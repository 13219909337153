import React, { useEffect, useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';

import Box from 'psims/react/components/box';
import Spinner from 'psims/react/components/spinner';
import Text from 'psims/react/components/text';
import APIProvider, { useAPI } from './api';
import ReferenceDataProvider from './api/reference-data';
import AppOverlayProvider from './app-overlay';
import AuthProvider from './auth';
import ConfigurationProvider from './configuration-provider';
import UserProvider, { useUser } from './user';
import { UnauthenticatedRouter } from './router';
import PageProvider from './page';
import { APP_NAME } from 'psims/constants/app';
import OrgUsersProvider from './api/org-users';
import ReportingObligationsProvider from './api/reporting-obligations';
import UserOrgsProvider from './api/user-orgs';
import DashboardProvider from './api/dashboard';
import LoggerProvider from './logging';
import AppInsightsProvider from './app-insights';
import OrganisationProductionAreasProvider from './api/organisation-production-areas';
import OrganisationMsoSettingsProvider from './api/organisation-mso-settings';
import { userVMToUser } from 'psims/models/user';
import BadGatewayPage from '../pages/bad-gateway';
import FsspCommitmentsProvider from './api/fssp-commitments';

const EnvironmentProvider = ({children}: React.PropsWithChildren<{}>) => {
	return (
		<BrowserRouter getUserConfirmation={() => {}}>
		<PageProvider>
		<AppOverlayProvider>
		<ConfigurationProvider>
		<AppInsightsProvider>
		<LoggerProvider>
		<AuthProvider>
		<APIProvider>
		<UserProvider>
			{children}
		</UserProvider>
		</APIProvider>
		</AuthProvider>
		</LoggerProvider>
		</AppInsightsProvider>
		</ConfigurationProvider>
		</AppOverlayProvider>
		</PageProvider>
		</BrowserRouter>
	);
}

function useLogin() {
	const location = useLocation();
	const {user, setUser, setStatus, status: userStatus, refetch} = useUser();
	const {api, status: apiStatus} = useAPI();
	const [enteredViaLogin, setEnteredViaLogin] = useState(false);
	const [hasLoggedIn, setHasLoggedIn] = useState(false);

	useEffect(() => {
		if (location.pathname.indexOf('/login') === 0) {
			setEnteredViaLogin(true);
		}
	}, [location]);

	useEffect(() => {
		if (hasLoggedIn) {
			return;
		}
		
		if (apiStatus === 'ready' && enteredViaLogin && user != null) {
			setStatus('Authenticating');
		}

		if (userStatus && userStatus === 'Authenticating') {
			api.login()
			.then(newUser => {
				const u = newUser?.result || null;
			
				setUser(u === null ? null : userVMToUser(u));
			})
			.catch(e => {
				// This can happen if user attempts to manually go to '/login' via address bar.
				// In this case, we don't want to register a login event anyway, so it's a serendipitous
				// failure case.
				// refetch user to refresh status
				refetch();
			});
			setHasLoggedIn(true);
		}
	}, [api, apiStatus, enteredViaLogin, hasLoggedIn, refetch, setStatus, setUser, user, userStatus]);
}

// I'm not in love with putting routing logic in providers, but for top-level views
// that depend on the current user status, it makes sense to build the provider stack
// for each view here.
const RenderProviders = ({children}: React.PropsWithChildren<{}>) => {
	const { status, user } = useUser();
	useLogin();

  if (window.location.pathname === '/bad-gateway') {
    return <BadGatewayPage />;
  }

	switch (status) {
		case 'Authenticating':
		case 'Uninitialised': {
			return <Loading />;
		}

		case 'Invited':
		case 'Registered': {
			return user?.roleType === 'LFG Portal Admin' ?
			<PortalAdminAppProviders>{children}</PortalAdminAppProviders> :
			<AppProviders>{children}</AppProviders>;
		}

		case 'Unauthenticated':
		case 'Inactive':
		case 'New':
		case 'Not_Found':
		default: {
			return <UnauthenticatedRouter />
		}
	}

}

// Providers requiring user to be authenticated
const AppProviders = ({children}: React.PropsWithChildren<{}>) => {
	return (
		<ReferenceDataProvider>
		<DashboardProvider>
		<OrgUsersProvider>
		<UserOrgsProvider>
		<OrganisationProductionAreasProvider>
		<OrganisationMsoSettingsProvider>
		<ReportingObligationsProvider>
		<FsspCommitmentsProvider>
			{children}
		</FsspCommitmentsProvider>
		</ReportingObligationsProvider>
		</OrganisationMsoSettingsProvider>
		</OrganisationProductionAreasProvider>
		</UserOrgsProvider>
		</OrgUsersProvider>
		</DashboardProvider>
		</ReferenceDataProvider>
	);
}

// Providers requiring user to be authenticated, but reduced for Portal admins
const PortalAdminAppProviders = ({children}: React.PropsWithChildren<{}>) => {
	return (
		<ReferenceDataProvider>
		<OrgUsersProvider>
		<UserOrgsProvider>
		<FsspCommitmentsProvider>
			{children}
		</FsspCommitmentsProvider>
		</UserOrgsProvider>
		</OrgUsersProvider>
		</ReferenceDataProvider>
	);
}

const Providers = ({children}: React.PropsWithChildren<{}>) => (
	<EnvironmentProvider>
		<RenderProviders>
			{children}
		</RenderProviders>
	</EnvironmentProvider>
)

export default Providers;

const Loading = () => (
	<Box alignItems='center' flex='row' justifyContent='center' height="100%">
		<Spinner size='lg' />
		<Box alignItems='center' flex='row' marginLeft='md'>
			<Text>Loading {APP_NAME}</Text>
		</Box>
	</Box>
);
