import { DataSubmission } from "psims/models/data-submission";
import useFocusedField, { UseFocusedField } from "psims/react/util/use-focused-field";
import useViewMode, { ViewMode } from "../../shared/use-view-mode";
import { CommentsFocusField, DeclarationFocusField } from "../../shared/types";
import useConfirm from "../../shared/use-confirm";
import useSubmit from "../../shared/use-submit";
import { StockholdingFocusField, OwnershipFocusField, StockOnBehalfFocusField, MsoFocusField, StockRefineryHeldFocusField, DeleteFocusField } from "../shared/types";
import useMsoRefData from "../shared/use-mso-ref-data";
import { UseMsoRefinerAPI } from "./use-mso-refiner-api";
import useMsoRefinerControls from "./use-mso-refiner-controls";
import useMsoRefinerForm from "./use-mso-refiner-form";
import useMsoRefinerNavigation from "./use-mso-refiner-navigation";
import useMsoRefinerProgress from "./use-mso-refiner-progress";
import useMsoRefinerValidation from "./use-mso-refiner-validation";
import useMsoRefinerValidationAlerts from "./use-mso-refiner-validation-alerts";
import useMsoRefinerServiceMessages from "./use-mso-refiner-service-messages";
import useClearData from "psims/react/pages/primary-pages/data-submissions/shared/use-clear-data";
import usePortalData from "../../shared/use-portal-data";

interface UseMsoRefinerProps {
    apiCtrl: PopulatedProps<UseMsoRefinerAPI, 'submission'>;
}

type FocusField = StockholdingFocusField |
                  OwnershipFocusField |
                  StockOnBehalfFocusField |
                  StockRefineryHeldFocusField |
                  MsoFocusField |
                  CommentsFocusField |
                  DeclarationFocusField |
                  DeleteFocusField;

export type UseMsoRefinerFocus = UseFocusedField<FocusField>;

function useMsoRefiner({apiCtrl}: UseMsoRefinerProps) {
    const {submission} = apiCtrl;

    const refData = useMsoRefData({dataSubmission: submission.dataSubmission});

    const portalDataCtrl = usePortalData({submissionType: refData.submissionType});

    const viewMode = useViewMode(submission);

    const progressCtrl = useMsoRefinerProgress({apiCtrl, viewMode});

    const focusFieldCtrl = useFocusedField<FocusField>();

    const formCtrl = useMsoRefinerForm({refData, submission});

    const validationCtrl = useMsoRefinerValidation({focusFieldCtrl, formCtrl, progressCtrl, refData});

    const submitCtrl = useSubmit({
        apiCtrl,
        dataSubmission: submission.dataSubmission as DataSubmission<'MSO refiner'>,
        focusFieldCtrl,
        hasExternalErrors: validationCtrl.notifications.length > 0,
    });

    const validationAlertsCtrl = useMsoRefinerValidationAlerts({focusFieldCtrl, msoCtrl: formCtrl.msoCtrl, progressCtrl});
    
    const deleteCtrl = useConfirm();

    const navCtrl = useMsoRefinerNavigation({
        apiCtrl,
        formCtrl,
        msoCtrl: formCtrl.msoCtrl,
        progressCtrl,
        submitCtrl,
        validationCtrl,
        viewMode
    });

    const controlsCtrl = useMsoRefinerControls({apiCtrl, navCtrl, progressCtrl, validationCtrl, viewMode});

    const serviceMessages = useMsoRefinerServiceMessages({
        formCtrl,
        progressCtrl,
        serverError: apiCtrl.updateError,
        submissionStatus: apiCtrl.loadStatus,
        submitCtrl,
        updateResponse: apiCtrl.updateResponse
    });

    const clearDataCtrl = useClearData({
        onConfirm: () => {
            apiCtrl.clearAllData();
            submitCtrl.updateDeclaration(false);
        },
        submission: apiCtrl.submission?.dataSubmission,
    });

    const isDisabled = viewMode !== 'edit';

    return {
        apiCtrl,
        clearDataCtrl,
        controlsCtrl,
        deleteCtrl,
        focusFieldCtrl,
        formCtrl,
        isDisabled,
        navCtrl,
        portalDataCtrl,
        progressCtrl,
        refData,
        serviceMessages,
        submitCtrl,
        validationCtrl,
        validationAlertsCtrl,
        viewMode: viewMode as ViewMode
    };
}

export default useMsoRefiner
