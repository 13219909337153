import useFocusedField from "psims/react/util/use-focused-field";
import { useMemo } from "react";
import useViewMode, { ViewMode } from "../shared/use-view-mode";
import { FocusField } from "./types";
import { UseFspAPI } from "./use-fsp-api";
import useFspNavigation from "./use-fsp-navigation";
import useFspProgress from "./use-fsp-progress";
import useSubmit from "../shared/use-submit";
import useEligibleProductions from "./eligible-productions/use-eligible-productions";
import useFspRefineryOutput from "./use-fsp-refinery-output";
import { UseFspRefData } from "./use-fsp-ref-data";
import useFspHydrocarbonBlendstocks from "./use-fsp-hydrocarbon-blendstocks";
import useTotalQuarterlyElibibleFssp from "./total-quarterly-eligible-fssp/use-total-quarterly-eligible-fssp";
import useFspControls from "./use-fsp-controls";
import useFspNotifications from "./use-fsp-notifications";
import useFspUnsavedChanges from "./use-fsp-unsaved-changes";
import useClearData from "../shared/use-clear-data";
import useServiceMessages from "../shared/use-service-messages";
import { getRecordResults } from "psims/models/submission-types/fsp/fsp-submission";
import useFspPortalData from "./use-fsp-portal-data";

interface UseFspProps {
  apiCtrl: PopulatedProps<UseFspAPI, 'submission'>;
  refData: UseFspRefData;
}

function useFsp({apiCtrl, refData}: UseFspProps) {
  const focusFieldCtrl = useFocusedField<FocusField>();

  const portalDataCtrl = useFspPortalData({submissionType: refData.submissionType});

  const submission = useMemo(() => {
    return apiCtrl.submission;
  }, [apiCtrl.submission]);

  const viewMode = useViewMode({dataSubmission: submission.dataSubmission});

  const progressCtrl = useFspProgress({submission, viewMode});

  const serviceMessages = useServiceMessages({
    response: apiCtrl.updateResponse,
    responseStatus: apiCtrl.loadStatus,
    serverError: apiCtrl.updateError,
    extractRecordResults: getRecordResults,
  });

  const epInitialData = useMemo(
    () => progressCtrl.currentStep.kind === 'data' ? submission.fsspEligibleProductions : [],
    [progressCtrl.currentStep.kind, submission.fsspEligibleProductions]
  );

  const eligibleProductionsCtrl = useEligibleProductions({
    initialData: epInitialData,
    portalDataCtrl,
    viewMode
  });

  const refineryOutputCtrl = useFspRefineryOutput({refData, submission});

  const hydrocarbonBlendStocksCtrl = useFspHydrocarbonBlendstocks({refData, submission});

  const totalQuarterlyEligibleFsspCtrl = useTotalQuarterlyElibibleFssp({
    eligibleProductionsCtrl,
    isActivePage: progressCtrl.currentStep.kind === 'data',
    submission,
    viewMode,
  });

  const submitCtrl = useSubmit({
    apiCtrl,
    dataSubmission: submission.dataSubmission,
    focusFieldCtrl,
    isActive: progressCtrl.currentStep.kind === 'submit'
  });

  const navCtrl = useFspNavigation({apiCtrl, eligibleProductionsCtrl, progressCtrl, submitCtrl, totalQuarterlyEligibleFsspCtrl, viewMode});

  const notificationsCtrl = useFspNotifications({
    dataSubmissionId: submission.dataSubmission.id,
    eligibleProductionsCtrl,
    focusFieldCtrl,
    navCtrl,
    progressCtrl,
    serviceMessages,
    submitCtrl,
    totalQuarterlyEligibleFsspCtrl,
  });

  const controlsCtrl = useFspControls({apiCtrl, navCtrl, progressCtrl, submitCtrl, viewMode});

  const unsavedChangesDialogCtrl = useFspUnsavedChanges({navCtrl});

  const clearDataCtrl = useClearData({
      onConfirm: () => {
          apiCtrl.clearAllData();
          submitCtrl.updateDeclaration(false);
      },
      submission: apiCtrl.submission?.dataSubmission,
  });


  const isDisabled = useMemo(() => viewMode !== 'edit', [viewMode]);

  return {
    apiCtrl,
    clearDataCtrl,
    controlsCtrl,
    eligibleProductionsCtrl,
    focusFieldCtrl,
    hydrocarbonBlendStocksCtrl,
    isDisabled,
    notificationsCtrl,
    navCtrl,
    progressCtrl,
    refineryOutputCtrl,
    submitCtrl,
    totalQuarterlyEligibleFsspCtrl,
    unsavedChangesDialogCtrl,
    viewMode: viewMode as ViewMode,
  };
}

export default useFsp

export type UseFsp = ReturnType<typeof useFsp>;
