import useSubmissionProcessingOverlay from "psims/react/pages/primary-pages/data-submissions/shared/use-submission-processing-overlay";
import useContentForType from "./use-content-for-type";
import useRefineryControls from "./use-refinery-controls";
import useRefineryForm from "./use-refinery-form";
import useRefineryProgress from "./use-refinery-progress";
import useRefinerySave from "./use-refinery-save";
import useRefinerySteps from "./use-refinery-steps";
import useRefinerySubmit from "./use-refinery-submit";
import useRefineryValidationAlerts from "./use-refinery-validation-alerts";
import useRefineryServiceResponse from "./use-service-response";
import useClearData from "../shared/use-clear-data";
import { RefiningSubmissionVM } from "psims/gen/xapi-client";
import useTemplateImport from "psims/react/blocks/import/use-template-import";
import { AllReferenceData } from "psims/models/ref-data";
import { UseRefineryAPI } from "./use-refinery-api";
import useRefineryRefData from "./use-refinery-ref-data";
import { SubmissionType } from "psims/models/ref-data/submission-type";
import usePortalDataAPI from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api";
import { useMemo } from "react";
import refineryImport from "psims/react/blocks/import/submissions/refinery";

interface UseRefineryProps {
    allRefData: AllReferenceData,
    apiCtrl: PopulatedProps<UseRefineryAPI, 'submission'>;
}

function useRefinery({allRefData, apiCtrl}: UseRefineryProps) {
    const {isBusy, submission, viewMode} = apiCtrl;
    const refData = useRefineryRefData(allRefData, submission.dataSubmission.reportingPeriodFrom, submission.dataSubmission.reportingPeriodTo);
    
    const submissionType = useMemo<SubmissionType>(() => {
        return {
            id: submission.dataSubmission.submissionTypeId,
            name: submission.dataSubmission.submissionTypeName,
            isActive: true,
            sortOrder: 0
        }
    }, [submission]);

    // Orchestrate controllers
    const serviceResponse = useRefineryServiceResponse({apiCtrl});
    const stepsCtrl = useRefinerySteps({refData});
    const progressCtrl = useRefineryProgress({apiCtrl, stepsCtrl});
    const validationAlerts = useRefineryValidationAlerts({progressCtrl, serviceResponse});
    const importCtrl = useTemplateImport<Partial<RefiningSubmissionVM>>({
        // TODO: assert submission
        dataSubmission: submission ? submission.dataSubmission : {caseId: '', submissionTypeName: 'Refining', reportingPeriodFrom: '', reportingPeriodTo: ''},
        importerBuilder: refineryImport
    });    
    const currentStep = progressCtrl.currentStep.kind === 'data' ? progressCtrl.currentStep.refData.refineryType.name : 'Submit';
    const formCtrl = useRefineryForm({apiCtrl, currentStep, importCtrl, progressCtrl, refData, validationAlerts});
    const saveCtrl = useRefinerySave({apiCtrl, formCtrl, progressCtrl, importCtrl, serviceResponse, validationAlerts});
    const submitCtrl = useRefinerySubmit({apiCtrl, formCtrl, progressCtrl});
    const controlsCtrl = useRefineryControls({apiCtrl, formCtrl, progressCtrl, saveCtrl, submitCtrl});
    const content = useContentForType({typeName: currentStep});
    const portalDataAPICtrl = usePortalDataAPI({submissionType: submissionType});
    const clearDataCtrl = useClearData({
        onConfirm: () => {
            apiCtrl.clearAllData();
            formCtrl.clearDeclaration();
            importCtrl.reset();
        },
        submission: submission?.dataSubmission,
    });

    useSubmissionProcessingOverlay({submissionStatus: apiCtrl.loadStatus});

    return {
        // Controllers
        clearDataCtrl,
        controlsCtrl,
        formCtrl,
        progressCtrl,
        portalDataAPICtrl,
        importCtrl,
        saveCtrl,
        stepsCtrl,
        submitCtrl,
        // Data
        content,
        refData,
        serviceResponse,
        submission,
        validationAlerts,
        viewMode,
        isBusy
    }
}

export default useRefinery;
