/*
  Select contents when focused (used to defeat issue with display values clearing select)
*/

import { useLayoutEffect } from "react";
import {useIsFocusedAlt as useIsFocused} from "./use-is-focused";

function useSelectAllOnFocus() {
    const { isFocused, ref, setRef } = useIsFocused();

    useLayoutEffect(() => {
        if (ref != null && isSelectable(ref) && isFocused) {
            ref.select()
        }
    }, [isFocused, ref]);

    return {
        setRef,
    }
}

export default useSelectAllOnFocus;

type WithSelect = HTMLElement & {
    select: () => any;
}

function isSelectable(maybe: HTMLElement): maybe is WithSelect {
    return (maybe as unknown as WithSelect).select != null;
}