import { SubmissionType } from "psims/models/ref-data/submission-type";
import usePortalDataAPI from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api";
import { useMemo } from "react";

interface UseProductionPortalDataProps {
  submissionType: SubmissionType;
}

function useProductionPortalData({submissionType}: UseProductionPortalDataProps) {
  const portalDataAPICtrl = usePortalDataAPI({submissionType});
  
  const groupPortalData = useMemo(() => {
    return (portalDataAPICtrl.portalData?.referenceTypeData || [])
      .filter(rtd => rtd.referenceTypeName === submissionType.name)
      .map(rtd => rtd.productGroupData)
      .flat();
  }, [portalDataAPICtrl.portalData, submissionType.name])

  const productPortalData = useMemo(() => {
    return (portalDataAPICtrl.portalData?.referenceTypeData || [])
      .filter(rtd => rtd.referenceTypeName === submissionType.name)
      .map(rtd => rtd.productData)
      .flat();
  }, [portalDataAPICtrl.portalData, submissionType.name])

  return useMemo(() => ({
    groupPortalData,
    productPortalData,
  }), [groupPortalData, productPortalData]);
}

export default useProductionPortalData

export type UseProductionPortalData = ReturnType<typeof useProductionPortalData>;
