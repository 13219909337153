import { APPLICATION_HOST, Configuration } from "./env";

export const getMsalConfig = (envConfig: Configuration) => ({
  auth: {
    authority: envConfig.B2C_AUTHORITY || "https://nonprodausgovcustomers.b2clogin.com/nonprodausgovcustomers.onmicrosoft.com/B2C_1_PSIMS_SignUpSignIn",
    clientId: envConfig.B2C_CLIENT_ID || "03f4411d-033b-401e-b52e-171194cf0bef",
    knownAuthorities: [envConfig.B2C_KNOWN_AUTHORITY || "nonprodausgovcustomers.b2clogin.com"],
    redirectUri: envConfig.B2C_REDIRECT_URI || `${APPLICATION_HOST}/login`,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
});

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const getLoginRequest =(envConfig: Configuration) => ({
  scopes: ["openid", `${envConfig.B2C_SCOPE || 'https://nonprodausgovcustomers.onmicrosoft.com/psims-dev-api/authorised.access'}`]
});
