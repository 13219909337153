import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import Page from 'psims/react/blocks/page';
import UncaughtError, { LoggerErrorWrapper } from 'psims/react/blocks/uncaught-error';
import { BoxedDiv } from 'psims/react/components/layout';
import { H1, H2 } from 'psims/react/components/typography';
import Callout from 'psims/react/components/callout';
import ManageRefData from './manage-ref-data';
import './style.css';
import PageHeader from 'psims/react/components/page-header';

interface PortalAdminProps {}

const PortalAdmin = (props: PortalAdminProps) => {
	return (
		<Page className='admin-portal' hideNavbar={false}>
			<UncaughtError Wrapper={LoggerErrorWrapper}>
        <PageHeader>
          <H1>Portal administration</H1>

          <BoxedDiv box={{alignItems: 'flex-start', flex: 'column'}}>
            <p>The place for managing select content directly on the Liquid Fuels Gateway.</p>
          </BoxedDiv>
        </PageHeader>

				<BoxedDiv box={{flex: 'column', flexGrow: 1, marginV: 4}}>
					<main className='container'>
            <Switch>

              <Route path='/admin/manage-reference-data'>
                <ManageRefData />
              </Route>

              <Route path='*'>
                <PageLinks />
              </Route>

            </Switch>
          
          </main>
        </BoxedDiv>
      </UncaughtError>
    </Page>
  );
}


const PageLinks = () => {
  return (
    <ul className='bare inline'>
      <li>
        <CardLink
          description='Manage data such as product help text and tooltips'
          title='Manage reference data'
          to="/admin/manage-reference-data"
        />  
      </li>
    </ul>
  );
}

interface CardLinkProps {
  description: string;
  title: string;
  to: string;
}

const CardLink = ({description, to, title}: CardLinkProps) => {
  return <Link className='full no-underline' to={to}>
    <BoxedDiv box={{flex: 'row', marginV: 4}}>
      <Callout $kind='info' className='full'>
        <H2>{title}</H2>
      
        <BoxedDiv box={{marginTop: 2}}>
          {description}
        </BoxedDiv>
      </Callout>
    </BoxedDiv>

  </Link>

}

export default PortalAdmin;
