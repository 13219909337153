/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductionSubmissionVMApiResponse } from '../models/ProductionSubmissionVMApiResponse';
import type { UpdateBiofuelProductionSubmissionVM } from '../models/UpdateBiofuelProductionSubmissionVM';
import type { UpdateDataSubmissionVM } from '../models/UpdateDataSubmissionVM';
import { request as __request } from '../core/request';

export class BiofuelProductionService {

    /**
     * @returns ProductionSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async getBiofuelProductionSubmission({
id,
}: {
id?: number,
}): Promise<ProductionSubmissionVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/biofuel-production`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns ProductionSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateBiofuelProduction({
requestBody,
}: {
requestBody?: UpdateBiofuelProductionSubmissionVM,
}): Promise<ProductionSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/biofuel-production/Update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns ProductionSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateBiofuelProductionDataSubmission({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<ProductionSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/biofuel-production/Update-DataSubmission`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns ProductionSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async submitBiofuelProduction({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<ProductionSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/biofuel-production/Submit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns ProductionSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async clearAllBiofuelProduction({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<ProductionSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/biofuel-production/Clear-All`,
            body: requestBody,
        });
        return result.body;
    }

}