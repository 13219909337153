import { useCallback, useEffect, useMemo, useState } from "react";

import { isBusyStatus, SubmissionStatus } from "psims/react/pages/primary-pages/data-submissions/shared/api";
import { useLogger } from "psims/react/providers/logging";
import { APIResponse, isAPIResponse, isSuccessfulAPIResponseWithResult } from "psims/models/api/response";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { encodeEscapeChars } from "psims/lib/validation/string";
import { isAnyDataSubmission, UpdateDataSubmission } from "psims/models/data-submission";
import { isForbiddenError, stringifyForbiddenWAFError } from "psims/lib/server-error";
import { assert } from "psims/lib/assert";

interface MinimumSubmission {
    id: number;
    concurrencyToken: string;
}

interface WithDataSubmission {
    dataSubmission: MinimumSubmission;
}

interface UseDataSubmissionAPIProps<
    // TMaybeSubmission extends MinimumSubmission,
    TMaybeSubmission,
    TSubmission extends TMaybeSubmission,
    TSubmissionUpdate,
    TSubmitSuccess,
> {
    actionFetch: ({id}: {id: number}) => Promise<APIResponse<TMaybeSubmission> | null>;
    actionSubmit: ({requestBody}: {requestBody: UpdateDataSubmission}) => Promise<APIResponse<TMaybeSubmission> | null>;
    actionUpdate: ({requestBody}: {requestBody: TSubmissionUpdate}) => Promise<APIResponse<TMaybeSubmission> | null>;
    actionUpdateDataSubmission: ({requestBody}: {requestBody: UpdateDataSubmission}) => Promise<APIResponse<TMaybeSubmission> | null>;
    actionClearAllData: ({requestBody}: {requestBody: UpdateDataSubmission}) => Promise<APIResponse<TMaybeSubmission> | null>;
    dataSubmissionId: number;
    name: string;
    submissionAssertion: TypeAssertion<TMaybeSubmission, TSubmission>;
    updateSuccessAssertion?: (result: TMaybeSubmission | null | undefined) => boolean;
    updateResponseTransform?: (result: TSubmission) => TSubmission;
    submitSuccessAssertion: TypeAssertion<TMaybeSubmission, TSubmitSuccess> | TypeAssertionFn<TMaybeSubmission, TSubmission>;
    mockOnError?: (id: number) => TSubmission;
}

function useDataSubmissionAPI<TMaybeSubmission, TSubmission extends TMaybeSubmission, TSubmissionUpdate, TSubmitSuccess>({
    actionFetch,
    actionSubmit,
    actionUpdate,
    actionUpdateDataSubmission,
    actionClearAllData,
    dataSubmissionId,
    name,
    submissionAssertion,
    submitSuccessAssertion,
    updateResponseTransform,
    updateSuccessAssertion,
    mockOnError
}: UseDataSubmissionAPIProps<TMaybeSubmission, TSubmission, TSubmissionUpdate, TSubmitSuccess>) {
    const logger = useLogger({source: `useDataSubmissionAPI_${name}`});
    const [submission, setSubmission] = useState<TSubmission | null>(null);
    const [loadStatus, setLoadStatus] = useState<SubmissionStatus>('init');
    const [updateError, setUpdateError] = useState<unknown | null>(null);
    const [updateResponse, setUpdateResponse] = useState<APIResponse<TMaybeSubmission> | null>(null);
    const [fetchErrorResult, setFetchErrorResult] = useState<unknown>(null);

    const loggerRef = useUpdatedRef(logger);

    const isBusy = useMemo(() => {
        return isBusyStatus(loadStatus)
    }, [loadStatus]);

    const trackingProperties = useMemo(() => {
        if (hasDataSubmission(submission) && isAnyDataSubmission(submission.dataSubmission)) {
            return {
                caseId: submission.dataSubmission.caseId,
            };
        }

        return {};
    }, [submission]);

    const fetch = useCallback(function() {
        if (isBusy) {
            loggerRef.current.debug(`Aborting data submission fetch due to busy status: ${loadStatus}`)
            return;
        }

        setLoadStatus('fetching');

        actionFetch({id: dataSubmissionId})
        .then(function(response) {
            if (response !== null && response.isSuccessful) {
                assert(submissionAssertion(response.result), 'Failed to assert fetched submission');
                setSubmission(response.result);
                setLoadStatus('fetched');
            } else {
                loggerRef.current.warn('Data submission fetch failed: could not unmarshall fetched response');;
                setLoadStatus('fetch_failed');
            }
        })
        .catch(e => {
            loggerRef.current.warn(`Data submission fetch failed: exception: ${e}`);

            if (e?.body?.result) {
                setFetchErrorResult(e.body.result);
            }

            if (mockOnError != null) {
                setSubmission(mockOnError(dataSubmissionId));
                setLoadStatus('fetched');
            } else {
                setLoadStatus('fetch_failed');
            }
        });
    }, [
        actionFetch,
        dataSubmissionId,
        isBusy,
        loggerRef,
        loadStatus,
        mockOnError,
        submissionAssertion
    ]);

    const updateDataSubmission = useCallback((updatePayload: UpdateDataSubmission) => {
        if (isBusy) {
            loggerRef.current.debug(`Aborting data submission update due to busy status: ${loadStatus}`)
            return;
        }

        setLoadStatus('updating');
        setUpdateError(null);

        const requestBodyEncoded = {
            ...updatePayload,
            comments: encodeEscapeChars(updatePayload.comments?.trim())
        };

        const trackPageView = loggerRef.current.startTrackPage(`Save - ${name}`, trackingProperties);

        actionUpdateDataSubmission({requestBody: requestBodyEncoded})
        .then(res => {
            if (!updateSuccessAssertion || updateSuccessAssertion(res?.result)) {
                trackPageView({saveResult: 'complete'});
                setLoadStatus('updated');
            } else {
                if (res?.isSuccessful) {
                    trackPageView({
                        saveResult: 'partial',
                    });
                }
                setLoadStatus('update_failed');
            }
            
            if (res != null) {
                setUpdateResponse(res);
                const result = res.result;
                if (res != null && submissionAssertion(result)) {
                    const transform = updateResponseTransform == null ? () => result : updateResponseTransform;
                    setSubmission(transform(result));
                }
            }
        })
        .catch(e => {
            loggerRef.current.warn(`Data submission update failed: exception: ${JSON.stringify(e)}`);
            setLoadStatus('update_failed');
            setUpdateError(e);
            
            if (isForbiddenError(e)) {
                const errorMessage = stringifyForbiddenWAFError(e);
                if (errorMessage != null) {
                    const wafError: APIResponse<TMaybeSubmission> = {
                        isSuccessful: false,
                        errorMessages: [errorMessage]                        
                    }
                    setUpdateResponse(wafError);
                }
            }
        });
    }, [
        actionUpdateDataSubmission,
        isBusy,
        loggerRef,
        loadStatus,
        name,
        submissionAssertion,
        trackingProperties,
        updateResponseTransform,
        updateSuccessAssertion
    ]);

    const update = useCallback((updatePayload: TSubmissionUpdate) => {
        if (isBusy) {
            loggerRef.current.debug(`Aborting update due to busy status: ${loadStatus}`)
            return;
        }

        setLoadStatus('updating');
        setUpdateError(null);

        const trackPageView = loggerRef.current.startTrackPage(`Save - ${name}`, trackingProperties);

        actionUpdate({requestBody: updatePayload})
            .then(res => {
                setUpdateResponse(res);
                if (!updateSuccessAssertion || updateSuccessAssertion(res?.result)) {
                    trackPageView({
                        saveResult: 'complete',
                    });
                    setLoadStatus('updated');
                    
                    const result = res?.result;
                    if (res != null && submissionAssertion(result)) {
                        const transform = updateResponseTransform == null ? () => result : updateResponseTransform;
                        setSubmission(transform(result));
                    }
                } else {
                    if (res?.isSuccessful) {
                        trackPageView({
                            saveResult: 'partial',
                        });
                    }
                    setLoadStatus('update_failed');
                }
            })
            .catch(e => {
                loggerRef.current.warn(`Update failed: exception: ${JSON.stringify(e)}`);
                if (e.body && isAPIResponse(e.body) && !isForbiddenError(e)) {
                    setUpdateResponse(e.body);
                } else if (isForbiddenError(e)) {
                    const errorMessage = stringifyForbiddenWAFError(e);
                    if (errorMessage != null) {
                        const wafError: APIResponse<TMaybeSubmission> = {
                            isSuccessful: false,
                            errorMessages: [errorMessage]                        
                        }
                        setUpdateResponse(wafError);
                    }
                }
                if (isForbiddenError(e)) {
                    const errorMessage = stringifyForbiddenWAFError(e);
                    if (errorMessage != null) {
                        const wafError: APIResponse<TMaybeSubmission> = {
                            isSuccessful: false,
                            errorMessages: [errorMessage]                        
                        }
                        setUpdateResponse(wafError);
                    }
                }
                setLoadStatus('update_failed');
                setUpdateError(e);
            });
    }, [
        actionUpdate,
        isBusy,
        loggerRef,
        loadStatus,
        name,
        submissionAssertion,
        trackingProperties,
        updateResponseTransform,
        updateSuccessAssertion
    ]);

    const submit = useCallback((submitPayload: UpdateDataSubmission) => {
        if (isBusyStatus(loadStatus)) {
            loggerRef.current.debug(`Aborting submit due to busy status: ${loadStatus}`)
            return;
        }

        setLoadStatus('submitting');
        setUpdateError(null);

        const requestBodyEncoded = {
            ...submitPayload,
            comments: encodeEscapeChars(submitPayload.comments?.trim())
        };

        actionSubmit({requestBody: requestBodyEncoded})
            .then(response => {
                if (response !== null && response.isSuccessful && submitSuccessAssertion(response.result)) {
                    setLoadStatus('submitted');
                    if (submissionAssertion(response.result)) {
                        setSubmission(response.result);
                    }
                } else {
                    setLoadStatus('submit_failed');
                }
                setUpdateResponse(response);
            })
            .catch(e => {
                loggerRef.current.warn(`Data submission submit failed: exception: ${JSON.stringify(e)}`);
                setLoadStatus('submit_failed');
                setUpdateError(e);
            });

    }, [actionSubmit, loadStatus, loggerRef, submissionAssertion, submitSuccessAssertion]);

    const clearAllData = useCallback(() => { 
        if (isBusy) {
            loggerRef.current.debug(`Aborting clear all due to busy status: ${loadStatus}`)
            return;
        }

        if (!hasDataSubmission(submission)) {
            loggerRef.current.debug(`Aborting clear all due to null submission`)
            return;
        }

        setLoadStatus('updating');
        setUpdateError(null);

        actionClearAllData({requestBody: {id: submission.dataSubmission.id, concurrencyToken: submission.dataSubmission.concurrencyToken}})
            .then(response => {
                setLoadStatus('updated');
                setUpdateResponse(response);
                if (response !== null && response.isSuccessful && submissionAssertion(response.result)) {
                    setSubmission(response.result)
                }
            })
            .catch(e => {
                loggerRef.current.warn(`Failed to clear all mso data: ${JSON.stringify(e)}`);
                if (e.body && isAPIResponse(e.body)) {
                    setUpdateResponse(e.body);
                }
                setLoadStatus('update_failed');
                setUpdateError(e);                
            });
    }, [actionClearAllData, isBusy, loadStatus, loggerRef, submission, submissionAssertion]);

    const clearAllReset = useCallback(() => {         
        setSubmission(null);
        fetch();
    }, [fetch]);

    // Fetch data submission on load
    useEffect(() => {
        fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update exposed submission when update response contains a valid submission
    useEffect(() => {
        if (isSuccessfulAPIResponseWithResult<TMaybeSubmission, TSubmission>(updateResponse, submissionAssertion)) {
            setSubmission(updateResponse.result);
        }
    }, [submissionAssertion, updateResponse]);

    return useMemo(() => ({
        fetchErrorResult,
        isBusy,
        loadStatus,
        submission,
        updateError,
        updateResponse,
        clearAllData,
        clearAllReset,
        fetch,
        submit,
        updateDataSubmission,
        update,
    }), [
        fetchErrorResult,
        isBusy,
        loadStatus,
        submission,
        updateError,
        updateResponse,
        clearAllData,
        clearAllReset,
        fetch,
        submit,
        updateDataSubmission,
        update,

    ]);
}

export default useDataSubmissionAPI;

function hasDataSubmission(maybe: unknown): maybe is WithDataSubmission {
    const maybeAs = maybe as WithDataSubmission;
    return (
        maybeAs != null &&
        maybeAs.dataSubmission != null &&
        maybeAs.dataSubmission.concurrencyToken != null &&
        maybeAs.dataSubmission.id != null
    )
}
