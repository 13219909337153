import { useEffect, useState } from "react";

import { APIResponse } from "psims/models/api/response";
import { getRecordResults, MaybeMsoImporterSubmission } from "psims/models/submission-types/mso/mso-importer-submission";
import useServiceMessages from "../../shared/use-service-messages";
import { UseSubmit } from "../../shared/use-submit";
import { UseMsoImporterForm } from "./use-mso-importer-form";
import { UseMsoImporterProgress } from "./use-mso-importer-progress";
import { SubmissionStatus } from "../../shared/api";
import { attemptScrollToSelector } from "psims/react/pages/primary-pages/data-submissions/shared/view-utils";
import { SELECTOR_NOTIFICATIONS } from "psims/constants/selectors";

interface UseMsoImporterServiceMessagesProps {
    formCtrl: UseMsoImporterForm;
    progressCtrl: UseMsoImporterProgress;
    serverError: unknown;
    submissionStatus: SubmissionStatus;
    submitCtrl: UseSubmit;
    updateResponse: APIResponse<MaybeMsoImporterSubmission> | null;
}

function useMsoImporterServiceMessages({formCtrl, progressCtrl, serverError, submissionStatus, submitCtrl, updateResponse}: UseMsoImporterServiceMessagesProps) {
    const serviceMessages = useServiceMessages({extractRecordResults: getRecordResults, response: updateResponse, responseStatus: submissionStatus, serverError});
    const [exposedMessages, setExposedMessages] = useState(serviceMessages);

    useEffect(() => {
        setExposedMessages(serviceMessages);
    }, [serviceMessages]);

    useEffect(() => {
        setExposedMessages([]);
    }, [formCtrl.changeSerial, progressCtrl.currentStep.index, submitCtrl.changeSerial]);

    useEffect(() => {
        if (exposedMessages.length > 0) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
        }
    }, [exposedMessages.length]);

    return exposedMessages;
}

export default useMsoImporterServiceMessages
