import React, { useCallback, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";

import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { asString } from "psims/lib/string";
import { WholesaleField } from "psims/models/submission-types/wholesaling";
import { BoxedDiv, BoxedSpan } from "psims/react/components/layout";
import Text from "psims/react/components/text";
import Textarea from "psims/react/components/textarea";
import { H3 } from "psims/react/components/typography";
import { ViewMode } from "../shared/use-view-mode";
import { TotalInfoMessage, UseFormForType } from "./use-form-for-type";
import ToggleManualTotals from "./toggle-manual-totals";
import { TooltipHelp } from "../shared/tooltip-help";
import FloatingMessage from "psims/react/components/floating-message";
import { UseWholesalingAPI } from "./use-wholesaling-api";
import { UseWholesalingUpdateResponse } from "./use-wholesaling-update-response";
import useIsAutoFocused from "psims/react/util/use-is-autofocused";
import { CellInput, DeleteActionCellTD, DeleteActionCellTH, Table, TD, TH, TR } from "../shared/data-table-components";
import { GroupTotals } from "./use-wholesaling";
import { UsePortalDataAPI } from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api";
import { SubmissionPortalDataTypeVM } from "psims/gen/xapi-client";
import { EXPIRED_OR_INTERNAL_PRODUCT } from "psims/constants/validation-messages";
import VisuallyHidden from "psims/react/components/visually-hidden";
import { IconButton } from "psims/react/components/button";
import useFocusable from "psims/react/util/use-focusable";
import { UseFocusedField } from "psims/react/util/use-focused-field";
import { FocusField } from "./shared";

interface WholesalingTypeEditorProps {
    apiCtrl: UseWholesalingAPI;
    formCtrl: UseFormForType;
    portalDataAPICtrl: UsePortalDataAPI;
    updateResponse: UseWholesalingUpdateResponse;
    viewMode: ViewMode
}

type GroupView = ArrayElement<Exclude<UseFormForType['view'], null>['wholesales']['groups']>;

type ProductView = ArrayElement<GroupView['products']>;

const WholesalingTypeEditor = ({apiCtrl, formCtrl, portalDataAPICtrl, updateResponse, viewMode}: WholesalingTypeEditorProps) => {
    const {view} = formCtrl;
    const {submissionFormData} = formCtrl.wholesalesUpdate;
    const disableTableEntry = (
        viewMode !== 'edit' || 
        (!submissionFormData.manualTotals && view?.wholesaleType?.name === 'Total')
    );

    const helpForType: SubmissionPortalDataTypeVM[] = useMemo(() => {
        if (portalDataAPICtrl == null || portalDataAPICtrl.portalData == null|| portalDataAPICtrl.portalData.referenceTypeData == null ||
            view?.wholesaleType?.id === undefined) {
                return [];
        }
        return portalDataAPICtrl.portalData.referenceTypeData
          .filter(rtd => rtd.referenceTypeId === view?.wholesaleType?.id);
      }, [portalDataAPICtrl, view])

    return (
        <BoxedDiv box={{overflowX: 'hidden'}}>
            {
                formCtrl.view?.wholesaleType?.name === 'Total' ?
                <ToggleManualTotals 
                    apiCtrl={apiCtrl}
                    formCtrl={formCtrl}
                    updateResponse={updateResponse}
                    viewMode={viewMode}
                /> : null
            }

            {formCtrl.view?.wholesales.groups
                .filter(g => g.groupStatus !== 'expired' && g.groupStatus !== 'empty')
                .map(group => (
                <Group key={group.productGroup.id} disabled={disableTableEntry} formCtrl={formCtrl} helpForType={helpForType} group={group} />
            ))}


            <BoxedDiv box={{marginTop: 4}}>
                <Textarea
                    disabled={viewMode !== 'edit'}
                    error={formCtrl.view?.comments.validationError?.notification.message}
                    id='comments'
                    label='Comments'
                    setFocused={formCtrl.view?.focusedField?.field === 'comments'}
                    value={asString(formCtrl.view?.comments.formData.value)}
                    onChange={e => formCtrl.updateComment(e.target.value)}
                />
            </BoxedDiv>
        </BoxedDiv>
    )
}

interface GroupProps {
    disabled: boolean;
    formCtrl: UseFormForType;
    helpForType: SubmissionPortalDataTypeVM[];
    group: GroupView;
}

const Group = ({disabled, group, formCtrl, helpForType}: GroupProps) => {
    const help = useMemo(() => {
        const allHelp = helpForType.map(rtd => rtd.productGroupData).flat();
      
        return allHelp.find(h => h?.referenceCode === group.productGroup.referenceCode)?.content;
    },[group.productGroup.referenceCode, helpForType]);
    
    const hasExpiredData = group.products.some(p => p.productStatus === 'expired_with_data');
    
    return (
        <BoxedDiv box={{marginV: 6, overflowX: 'auto'}}>
            <BoxedDiv box={{marginBottom: 2}}>
                <BoxedSpan box={{alignItems: 'center', flex: 'row',}}>
                    <H3>{group.productGroup.name}</H3>
                    {
                        help &&
                        <TooltipHelp Help={<ReactMarkdown>{help}</ReactMarkdown>} />
                    }
                </BoxedSpan>
            </BoxedDiv>
            <Table caption={`Your wholesales data for ${group.productGroup.name}`}>
                <thead><TR>
                    <TH fixedWidth='470px'>Product</TH>
                    <TH $align='center' fixedWidth='140px'>
                        <BoxedSpan box={{ alignItems: 'center', flex: 'column' }}>
                            <BoxedSpan box={{ alignItems: 'center', flex: 'row' }}>
                                NSW
                                <TooltipHelp
                                    Help={'Products should be reported against the state or territory in which they were (or will be) consumed. If the state of final consumption is not known, please report the state where the product was entered for home consumption (or equivalent). Please include any wholesales occurring in the ACT in the NSW column.'}
                                />
                            </BoxedSpan>
                        </BoxedSpan>
                    </TH>
                    <TH $align="center" fixedWidth='140px'>VIC</TH>
                    <TH $align="center" fixedWidth='140px'>QLD</TH>
                    <TH $align="center" fixedWidth='140px'>SA</TH>
                    <TH $align="center" fixedWidth='140px'>WA</TH>
                    <TH $align="center" fixedWidth='140px'>TAS</TH>
                    <TH $align="center" fixedWidth='140px'>NT</TH>
                    <TH $align="right" fixedWidth='140px'>Australia</TH>
                    {
                        hasExpiredData ?
                        <DeleteActionCellTH><VisuallyHidden>Actions</VisuallyHidden></DeleteActionCellTH> :
                        null
                    }    
                </TR></thead>

                <tbody>
                    {
                        group.products
                        .filter(p => p.productStatus !== 'expired')
                        .map(product => (
                            <Product key={product.id} disabled={disabled} formCtrl={formCtrl} helpForType={helpForType} product={product} />
                        ))
                    }

                    <GroupTotalsRow totals={group.totals} />
                </tbody>
            </Table></BoxedDiv>
    )
}

const FIELD_LABEL_MAP: {[key in WholesaleField]: string} = {
    nswWholesaleVolume: 'NSW',
    ntWholesaleVolume: 'NT',
    qldWholesaleVolume: 'QLD',
    saWholesaleVolume: 'SA',
    tasWholesaleVolume: 'TAS',
    vicWholesaleVolume: 'VIC',
    waWholesaleVolume: 'WA',
} as const;

interface ProductValueInputCellProps<TField extends WholesaleField> {
    disabled: boolean;
    product: ProductView;
    field: TField;
    formCtrl: UseFormForType;
}

const ProductValueInputCell = <TField extends WholesaleField>({disabled, field, formCtrl, product}: ProductValueInputCellProps<TField>) => {
    const error = product.validationErrors.find(v => v.tooltip.target === field);
    const info = product.infoMessages.find(i => i.tooltip.target === field);

    const shouldAutoFocus = useIsAutoFocused(
        formCtrl.view?.focusedField?.field !== 'delete' &&
        product.isFirstRow &&
        field === 'nswWholesaleVolume'
    );

    const isFocused = useMemo(() => {
        return shouldAutoFocus || (
            formCtrl.view?.focusedField != null &&
            formCtrl.view.focusedField.field === field &&
            formCtrl.view.focusedField.wholesaleProductId === product.id
        );
    }, [field, formCtrl.view?.focusedField, product.id, shouldAutoFocus]);

    return (
        <CellInput
            disabled={disabled}
            error={error?.tooltip.content}
            forceError={formCtrl.view?.shouldForceErrors}
            info={info?.tooltip.content}
            shouldFocus={isFocused}
            value={product.formData[field]}
            onChange={val => formCtrl.updateWholesale(product.id, field, val)}
            label={`Integer wholesales volume for ${FIELD_LABEL_MAP[field]}`}
        />
    )
}

interface ProductTotalCellProps {
    info?: string;
    product: ProductView;
}

const ProductTotalCell = ({info, product}: ProductTotalCellProps) => {
    return (
        <TD $align="right">
            <BoxedDiv box={{alignItems: 'center', flex: 'row', flexGrow: 1}}>
                <Text weight="bold" fullWidth={true}>{localeNumberWithFixed(product.total)}</Text>
                <BoxedDiv box={{alignItems: 'center', flex: 'row', marginLeft: 0.5, minWidth: '24px'}}>
                    {
                        info ?
                        <FloatingMessage
                            kind='info'
                            content={info}
                            role='alert'
                        /> :
                        <></>
                    }
                </BoxedDiv>
            </BoxedDiv>
        </TD>
    )
}

interface ProductProps {
    disabled: boolean;
    formCtrl: UseFormForType;
    helpForType: SubmissionPortalDataTypeVM[];
    product: ProductView;
}

const Product = ({disabled, formCtrl, helpForType, product}: ProductProps) => {
    const [deleteRef, setDeleteRef] = useState<HTMLElement | null>(null);
    
    const help = useMemo(() => {
        const allHelp = helpForType.map(rtd => rtd.productData).flat();
      
        return allHelp.find(h => h?.referenceCode === product?.referenceCode)?.content;
    },[helpForType, product?.referenceCode]);

    const hasExpiredData = product.productStatus === 'expired_with_data';
    
    const totalInfo = product.infoMessages.find(i => isTotalInfo(i.tooltip?.target));

    const canDelete = useMemo(() => {
        return !disabled && hasExpiredData;
    }, [disabled, hasExpiredData])

    return (
        <TR>
            <TH scope="row">
                <BoxedSpan box={{alignItems: 'flex-start', flex: 'row',}}>
                    <Text title={product.productName}>{product.productName}</Text>
                    {
                        hasExpiredData && !disabled ? <BoxedSpan box={{marginBottom: -0.5, marginLeft: 1}}>
                        <FloatingMessage
                            content={EXPIRED_OR_INTERNAL_PRODUCT}
                            kind="warning"
                            role="alert"
                        />
                        </BoxedSpan> : (
                            help && <>
                            <TooltipHelp
                                Help={<ReactMarkdown>{help}</ReactMarkdown>}
                            />
                        </>
                        )
                    }
                </BoxedSpan>
            </TH>
            <ProductValueInputCell field="nswWholesaleVolume" disabled={disabled} formCtrl={formCtrl} product={product} />
            <ProductValueInputCell field="vicWholesaleVolume" disabled={disabled} formCtrl={formCtrl} product={product} />
            <ProductValueInputCell field="qldWholesaleVolume" disabled={disabled} formCtrl={formCtrl} product={product} />
            <ProductValueInputCell field="saWholesaleVolume" disabled={disabled} formCtrl={formCtrl} product={product} />
            <ProductValueInputCell field="waWholesaleVolume" disabled={disabled} formCtrl={formCtrl} product={product} />
            <ProductValueInputCell field="tasWholesaleVolume" disabled={disabled} formCtrl={formCtrl} product={product} />
            <ProductValueInputCell field="ntWholesaleVolume" disabled={disabled} formCtrl={formCtrl} product={product} />
            <ProductTotalCell info={totalInfo?.tooltip.content} product={product} />                    
            
            {
                canDelete &&
                <DeleteActionCellTD>
                {
                    <BoxedDiv box={{ alignItems: 'center', flex: 'row', marginLeft: 1 }}>
                        <DeleteRowButton
                            focusedField={formCtrl.focusedField}
                            onClick={() => formCtrl.deleteProductRow(product.id, deleteRef)}
                            productId={product.id}
                            ref={setDeleteRef}
                        />
                    </BoxedDiv>
                }
                </DeleteActionCellTD> 
            }        
        </TR>
    )
}

interface DeleteRowProps {
    focusedField: UseFocusedField<FocusField>['focusedField'];
    onClick: () => any;
    productId: number;
}

const DeleteRowButton = React.forwardRef(({focusedField, onClick, productId}: DeleteRowProps, ref) => {
    const focusable = useFocusable({
        setFocused: (
            focusedField?.field === 'delete' &&
            focusedField.wholesaleProductId === productId
        ),
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <IconButton
            color='dark-grey'
            icon='trash'
            label='Delete row for product'
            onClick={onClick}
            ref={setRef}
            size='sm'
        />
    );
});

interface GroupTotalsRowProps {
    totals: GroupTotals;
}

const GroupTotalsRow = ({totals}: GroupTotalsRowProps) => {
    return (
        <TR>
            <TH $align='right' scope='row'><BoxedSpan box={{paddingRight: 2}}><strong>Totals</strong></BoxedSpan></TH>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.nsw}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.vic}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.qld}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.sa}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.wa}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.tas}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.nt}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 2}}><strong>{totals.australia}</strong></BoxedSpan></TD>
        </TR>
    )
}

function isTotalInfo(maybe?: TotalInfoMessage | unknown): maybe is TotalInfoMessage {
    const maybeAs = maybe as TotalInfoMessage;

    return (
        maybeAs != null &&
        maybeAs.field === 'Total' &&
        (maybeAs.reason === 'other' || maybeAs.reason === 'variance')
    );
}

export default WholesalingTypeEditor;
