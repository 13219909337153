import { UpdateMsoAnnualActivityImporterDraft, UpdateMsoAnnualActivityImporterField } from "psims/models/submission-types/mso/annual-activity/importer/update-mso-annual-activity-importer";
import { UpdateMsoStockholdingImportDraft } from "psims/models/submission-types/mso/annual-activity/importer/update-mso-stockholding-import";
import { MAX_VOLUME } from "./shared";

export type ValidationAlertResult = {
  key: UpdateMsoAnnualActivityImporterField | 'totalAnnualVolume';
  code: 'MSOImportVolumeOnBehalfOutOfRange' | 'MSOImportVolumeByOthersOutOfRange' | 'MSOTotalImportVolumeNegative';
}

export function validationAlertsForImporterAnnualActivity(annualActivity: UpdateMsoAnnualActivityImporterDraft, totalAnnualVolume: number, imports: Array<UpdateMsoStockholdingImportDraft>): Array<ValidationAlertResult> {
  let messages: Array<ValidationAlertResult> = [];
   
  if (annualActivity.totalImportVolumeOnBehalf != null && annualActivity.totalImportVolumeOnBehalf > MAX_VOLUME) {
    messages.push({
      key: 'totalImportVolumeOnBehalf',
      code: 'MSOImportVolumeOnBehalfOutOfRange',
    });
  }

  if (annualActivity.totalImportVolumeOthers != null && annualActivity.totalImportVolumeOthers > MAX_VOLUME) {
    messages.push({
      key: 'totalImportVolumeOthers',
      code: 'MSOImportVolumeByOthersOutOfRange',
    });
  }

  if (totalAnnualVolume < 0) {
    messages.push({
      key: 'totalAnnualVolume',
      code: 'MSOTotalImportVolumeNegative',
    })
  }

  return messages;
}
