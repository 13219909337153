import { components, OptionProps } from 'react-select';
import { OPAWithProductionArea } from 'psims/models/ref-data/organisation-production-area';

export type ProductionAreaOption = {
    data: OPAWithProductionArea,
    label: string;
    value: number | undefined | null;
}

export const SetActiveOrganisationProductionArea = (reportingYear: number | undefined, reportingMonth: number | undefined, opa: OPAWithProductionArea): OPAWithProductionArea => {
    let isActive = true;

    
    if (reportingYear && reportingMonth) {
      if (opa.effectiveFromYear && opa.effectiveFromMonth) {
        isActive = isActive && 
          (opa.effectiveFromYear < reportingYear || 
            (opa.effectiveFromYear === reportingYear && opa.effectiveFromMonth <= reportingMonth)); 
      }
      if (opa.productionArea.effectiveFromYear && opa.productionArea.effectiveFromMonth) {
        isActive = isActive && 
          (opa.productionArea.effectiveFromYear < reportingYear || 
            (opa.productionArea.effectiveFromYear === reportingYear && opa.productionArea.effectiveFromMonth <= reportingMonth)); 
      }
      if (opa.effectiveToYear && opa.effectiveToYear != null && opa.effectiveToMonth && opa.effectiveToMonth != null) {
        isActive = isActive && 
          (opa.effectiveToYear > reportingYear || 
            (opa.effectiveToYear === reportingYear && opa.effectiveToMonth >= reportingMonth)); 
      }
      if (opa.productionArea.effectiveToYear && opa.productionArea.effectiveToYear != null && opa.productionArea.effectiveToMonth && opa.productionArea.effectiveToMonth != null) {
        isActive = isActive && 
          (opa.productionArea.effectiveToYear > reportingYear || 
            (opa.productionArea.effectiveToYear === reportingYear && opa.productionArea.effectiveToMonth >= reportingMonth)); 
      }
    }

    return {
      ...opa,
      isActive,
    };
}

export const ProductionAreaOptionCtrl = (optionProps: OptionProps<ProductionAreaOption>) => {
    const { data } = optionProps;
    return (
      <components.Option {...optionProps}>
        <span>{data.label} ({data.data.productionArea.productionAreaStateName})</span>
      </components.Option>
    );
  };
  