import { WholesaleTypeDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { prefixedThrower } from "psims/lib/exception";

// Needs to be updated if ref data name change
export const WHOLESALE_TYPE_NAMES = ['Bulk', 'Other wholesales', 'Retailers', 'Total', 'Wholesalers'] as const;

export type WholesaleTypeName = ArrayElement<typeof WHOLESALE_TYPE_NAMES>;

export type WholesaleType = PopulatedProps<
    WholesaleTypeDTO & {
        name: WholesaleTypeName;
    }, 'id' | 'displayOrder' | 'isActive' | 'name'
>;

export function assertWholesaleType(maybe?: unknown): asserts maybe is WholesaleType {
    const asWholesaleType = maybe as WholesaleType;

    const err = prefixedThrower(`Failed to assert wholesaleType: ${JSON.stringify(maybe)}: `);

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asWholesaleType, hint('wholesaleType'));
    assertNotEmpty(asWholesaleType.id, hint('wholesaleType.id'));
    assertNotEmpty(asWholesaleType.name, hint('wholesaleType.name'));

    if (!WHOLESALE_TYPE_NAMES.includes(asWholesaleType.name)) err(`unknown type name: ${asWholesaleType.name}`);
}
