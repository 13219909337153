import { useMemo } from "react";

import { UseDefRefData } from "./use-def-ref-data";
import useDefProgress from "./use-def-progress";
import useFocusedField from "psims/react/util/use-focused-field";
import useSubmit from "../shared/use-submit";
import useDataSubmissionControls from "../shared/use-data-submission-controls";
import { FocusField } from "./types";
import useDefNavigation from "./use-def-navigation";
import useDefNotifications from "./use-def-notifications";
import useViewMode, { ViewMode } from "../shared/use-view-mode";
import useActivities from "./activities/use-activities";
import useMarketExpectations from "./market-expectations/use-market-expectations";
import { UseDefAPI } from "./use-def-api";
import { INVALID_COMMENTS_REQUIRED } from "psims/constants/validation-messages";
import useServiceMessages from "../shared/use-service-messages";
import { getRecordResults } from "psims/models/submission-types/def/def-submission";
import useDefSubmissionValidationAlerts from "./use-def-submission-validation-alerts";
import useConfirm from "../shared/use-confirm";
import useClearData from "../shared/use-clear-data";
import useDefPortalData from "./use-def-portal-data";

export const CUSTOM_DECLARATION = 'I have checked that my reported numbers are correct';

interface UseDefProps {
  refData: UseDefRefData;
  apiCtrl: PopulatedProps<UseDefAPI, 'submission'>;
}

function useDef({refData, apiCtrl}: UseDefProps) {
  const {submission} = apiCtrl;
  const viewMode = useViewMode({dataSubmission: submission.dataSubmission});

  const portalDataCtrl = useDefPortalData({submissionType: refData.submissionType});

  const progressCtrl = useDefProgress({submission, viewMode});

  const activitiesInitialData = useMemo(() => {
    return progressCtrl.currentStep.kind === 'data' ?
    submission.activities :
    // Below "looks" like the same as above, but it creates a new context of activities on
    // page change, so that discarded changes actually disappear. Inelegant, I know. Sorry.
    [...submission.activities];
  }, [progressCtrl.currentStep.kind, submission.activities]);

  const activitiesCtrl = useActivities({
    initialData: activitiesInitialData,
    portalDataCtrl,
    refData,
    submission,
    viewMode,
  });

  const marketExpectationsData = useMemo(() => {
    return progressCtrl.currentStep.kind === 'data' ? {
      ...(submission.marketExpectations || {}),
      dataSubmissionId: submission.dataSubmission.id
    } : {
      dataSubmissionId: submission.dataSubmission.id
    };
  }, [progressCtrl.currentStep.kind, submission.marketExpectations, submission.dataSubmission.id]);

  const marketExpectationsCtrl = useMarketExpectations({
    initialData: marketExpectationsData,
  });

  const serviceMessages = useServiceMessages({
    response: apiCtrl.updateResponse,
    responseStatus: apiCtrl.loadStatus,
    serverError: apiCtrl.updateError,
    extractRecordResults: getRecordResults
  });

  const focusFieldCtrl = useFocusedField<FocusField>();

  const submissionValidationAlertsCtrl = useDefSubmissionValidationAlerts({ activitiesCtrl, submission });

  const commentsRequired = useMemo(() => {
    return activitiesCtrl.commentsRequired || submissionValidationAlertsCtrl.validationAlerts.length > 0
  }, [activitiesCtrl.commentsRequired, submissionValidationAlertsCtrl]);

  const submitCtrl = useSubmit({
    apiCtrl,
    commentsRequiredMsg: commentsRequired ? INVALID_COMMENTS_REQUIRED : undefined,
    customDeclaration: CUSTOM_DECLARATION,
    dataSubmission: submission.dataSubmission,
    focusFieldCtrl,
    hasExternalErrors: activitiesCtrl.data.some(d => d.validation.length > 0),
    isActive: progressCtrl.currentStep.kind === 'submit',
  });

  const validationAlertsConfirmCtrl = useConfirm();

  const navCtrl = useDefNavigation({
    activitiesCtrl,
    apiCtrl,
    marketExpectationsCtrl,
    progressCtrl,
    submissionValidationAlertsCtrl,
    submitCtrl,
    validationAlertsConfirmCtrl,
    viewMode
  });

  const controlsCtrl = useDataSubmissionControls({
    apiCtrl,
    formCtrl: {view: {Submit: {isValid: submitCtrl.commentsError == null}}},
    progressCtrl,
    saveCtrl: {saveAndGoToPage: navCtrl.saveAndGoToPage},
    submitCtrl: {submit: submitCtrl.attemptSubmit},
    viewMode,
  });

  const notifcationsCtrl = useDefNotifications({
    activitiesCtrl,
    focusFieldCtrl,
    navCtrl,
    progressCtrl,
    serviceMessages,
    submitCtrl,
    submissionValidationAlertsCtrl
  });

  const clearDataCtrl = useClearData({
      onConfirm: () => {
          apiCtrl.clearAllData();
          submitCtrl.updateDeclaration(false);
      },
      submission: apiCtrl.submission?.dataSubmission,
  });

  const forceErrors = useMemo(
    () => navCtrl.saveAttempted || navCtrl.navAttempted,
    [navCtrl.navAttempted, navCtrl.saveAttempted]
  );

  return {
    apiCtrl,
    clearDataCtrl,
    controlsCtrl,
    marketExpectationsCtrl,
    focusFieldCtrl,
    forceErrors,
    isDisabled: viewMode !== 'edit',
    navCtrl,
    notifcationsCtrl,
    progressCtrl,
    activitiesCtrl,
    submitCtrl,
    submissionValidationAlertsCtrl,
    validationAlertsConfirmCtrl,
    viewMode: viewMode as ViewMode,
  };
}

export default useDef

export type UseDef = ReturnType<typeof useDef>;
