import { OrganisationMsoSettingVM } from "psims/gen/xapi-client";

type MaybeOrganisationMsoSetting = OrganisationMsoSettingVM;

export type OrganisationMsoSetting = PopulatedProps<MaybeOrganisationMsoSetting, 'id' | 'minimumStockObligation' | 'msoProductId' | 'organisationId'>;

export function isOrganisationMsoSetting(maybe?: MaybeOrganisationMsoSetting | null): maybe is OrganisationMsoSetting {
    return (
        maybe != null &&
        maybe.id != null &&
        maybe.minimumStockObligation != null &&
        maybe.msoProductId != null &&
        maybe.organisationId != null
    );
}
