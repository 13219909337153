import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";

import { isArrayOfUser, User } from "psims/models/user";
import { useUser } from "../user";
import { useAPI } from ".";
import { Status } from "psims/types/async";

type OrgUsersContext = {
    refetch: () => void;
    status: Status;
    users: Array<User> | null
}

const context = createContext<OrgUsersContext | null>(null);

const OrgUsersProvider = ({children}: PropsWithChildren<{}>) => {
    const {api} = useAPI();
    const { user } = useUser();
    const [users, setUsers] = useState<Array<User> | null>(null);
    const [status, setStatus] = useState<Status>('init');

    const fetch = useCallback(() => {
        if (!user?.organisation?.id || !api) {
            return;
        }

        setStatus('loading');

        api.getUsersForOrg({organisationId: user.organisation.id})
        .then(response => {
            if (response?.isSuccessful && isArrayOfUser(response.result)) {
                setUsers(response.result);
                setStatus('fulfilled');
            } else {
                setStatus('error');
            }
        })
        .catch(() => setStatus('error'));
    }, [api, user?.organisation?.id]);

    useEffect(() => {
        if (!user?.organisation?.id || users !== null) {
            return;
        }

        fetch();
    }, [fetch, user?.organisation?.id, users]);

    return <context.Provider value={{users, status, refetch: fetch}}>
        {children}
    </context.Provider>
}

export default OrgUsersProvider;

export function useOrgUsers() {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error('useOrgUsers must be used within OrgUsersProvider');
    }

    return {
        ...ctx,
    };
}
