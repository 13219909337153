import React from 'react';

import Button from 'psims/react/components/button';
import Text from 'psims/react/components/text';
import { InfoMessageProps, TargetButton } from 'psims/react/pages/primary-pages/data-submissions/shared/messages';
import { ValidationAlert } from 'psims/models/api/submission/validation-alert';

export const InfoLessThanSumMessage = ({onCommentTargetClick, onTargetClick, label}: InfoMessageProps) => (
    <Text>
        {'You are reporting a lower total stock for '}

        <TargetButton
            onClick={onTargetClick}
            label={label}
        />

        {' as compared to the breakdown of this product on earlier pages. If this is correct, please provide a reason in the '}

        <Button
            $kind='text'
            aria-label='Go to comments field'
            onClick={onCommentTargetClick}
        >comments field</Button>
        
        {'.'}
    </Text>
);

type InfoSubmitPageValidationAlertProps = Omit<InfoMessageProps, 'onCommentTargetClick'> & {
    validationAlert: ValidationAlert;
    variance?: number | null;
};

export const InfoSubmitPageValidationAlert = ({label, onTargetClick, validationAlert, variance}: InfoSubmitPageValidationAlertProps) => {
    switch (validationAlert.validationAlert) {
        case 'LessThanSumOfBreakdownAlert':
            return <>
                <p>
                    {'There are one or more products on your '}
                    <TargetButton
                        onClick={onTargetClick}
                        label='Total wholesales page'
                    />
                    {' which have a value less than the sum of the previous pages.'}
                </p>

                <p>Re-check these values and if they are correct, please provide comments.</p>
            </>

        case 'PercentVarianceApplied':
            return <>
                <p>
                    {'The total for '}

                <TargetButton
                    onClick={onTargetClick}
                    label={label}
                />

                {` is more than ${variance}% outside of your previous submission. Please enter a reason in the comments field of that page.`}

                </p>

            </>;

        default:
            return null;
    }
};
