import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { Document } from 'psims/models/document';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { Table, TD, TH } from '../data-submissions/shared/data-table-components';
import FileIcon from './file-icon';
import { humanDateNumeric } from 'psims/lib/formatters/datetime';
import Button from 'psims/react/components/button';
import { useAPI } from 'psims/react/providers/api';
import { assertDocumentDownload } from 'psims/models/document-download';
import { getContentTypeForExtension } from './file-types';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import FileDownload from 'psims/react/components/icons/file-download';
import Text from 'psims/react/components/text';

interface DocumentListProps {
  documents: Array<Document>;
  hasAnyDocuments: boolean;
  onDownloadError: (doc: Document) => any;
  onDownloadAttempt: (doc: Document) => any;
}

const DocumentList = (props: DocumentListProps) => {
  const vm = useVM(props);

  if (vm.documents.length === 0) {
    return (
      <BoxedDiv box={{
          borderTop: {color: 'border-primary-faded', width: 4},
          flex: 'row',
          flexGrow: 1,
          justifyContent: 'flex-start',
          marginTop: 4,
          paddingTop: 4,
      }}>
        <p>
          <Text weight='semibold'> There are no documents { vm.hasAnyDocuments ? 'that match your filters' : 'uploaded for your organisation'}.</Text>
        </p>
      </BoxedDiv>
    )
  }

  return (
    <Table caption='Uploaded documents' customWidth='fixed'>
      <thead>
        <tr>
          <TH $width='530px'>Document name</TH>
          <TH>Document type</TH>
          <TH>Entity name</TH>
          <TH>Uploaded date</TH>
          <TH>Uploaded by</TH>
          <TH $align='center'>Download document</TH>
        </tr>
      </thead>

      <tbody>
        {vm.documents.map(d => (
        <DocumentRow
          key={d.blobName}
          doc={d}
          onDownloadAttempt={vm.onDownloadAttempt}
          onDownloadError={vm.onDownloadError}
        />
        ))}
      </tbody>

    </Table>
  )
}

function useVM({documents, hasAnyDocuments, onDownloadAttempt, onDownloadError}: DocumentListProps) {
  return {
    documents,
    hasAnyDocuments,
    onDownloadAttempt,
    onDownloadError,
  };
}

interface DocumentRowProps {
  doc: Document;
  onDownloadAttempt: (doc: Document) => any;
  onDownloadError: (doc: Document) => any;
}

const Muted = styled.span`
  color: var(--color-text-muted);
  font-size: 14px;
`;

const DocumentRow = (props: DocumentRowProps) => {
  const vm = useDocumentRow(props);

  return (
    <tr>
      <TD>
        <BoxedDiv box={{alignItems: 'center', flex: 'row'}}>
          <FileIcon filename={vm.document.name} />
          
          <BoxedSpan box={{marginLeft: 1}}>
            {vm.document.name}
          </BoxedSpan>
        </BoxedDiv>
      </TD>
      <TD>{vm.document.documentTypeName}</TD>
      <TD>{vm.document.organisationName}</TD>
      <TD>{humanDateNumeric(vm.document.portalUploadOn)}</TD>
      <TD>{vm.document.portalUploadBy}</TD>
      <TD $align='center'>
        {
          vm.downloadLink != null ?
          <a href={vm.downloadLink} download={vm.document.name} title='Download document'>
            <VisuallyHidden>Download document with name {vm.document.name}</VisuallyHidden>
            <BoxedSpan box={{color: 'primary-interactive'}}>
              <FileDownload size='md' />
            </BoxedSpan>
          </a> : (
            vm.status === 'generating' ?

            <Muted>Getting link...</Muted> :

            <Button
              $kind='text'
              onClick={vm.getDownloadLink}
              $size='sm'
            >Get download link</Button>
          )
        }
      </TD>
    </tr>
  )
}

type Status = 'idle' | 'generating' | 'generated' | 'error';

function useDocumentRow({doc, onDownloadAttempt, onDownloadError}: DocumentRowProps) {
  const {api} = useAPI();

  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [status, setStatus] = useState<Status>('idle');

  const getDownloadLink = useCallback(() => {
    setStatus('generating');

    onDownloadAttempt(doc);

    api.getDocument({blobName: doc.blobName})
      .then(r => {
        const result = r?.result;
        assertDocumentDownload(result);
        const contentType = getContentTypeForExtension(result.fileType);
        if (contentType == null) {
          throw new Error(`Unknown filetype: ${result.fileType}`);
        }
        const decodedData = window.atob(result.data);
        const uInt8Array = new Uint8Array(decodedData.length);

        for (let i = 0; i < decodedData.length; ++i) {
          uInt8Array[i] = decodedData.charCodeAt(i);
        }

        const blob = new File([uInt8Array], result.name, { type: contentType });
        const blobUrl = URL.createObjectURL(blob);

        setDownloadLink(blobUrl);
        setStatus('generated');
      })
      .catch(() => {
        onDownloadError(doc);
        setStatus('error');
      })
  }, [api, doc, onDownloadAttempt, onDownloadError]);

  return {
    document: doc,
    downloadLink,
    status,
    getDownloadLink,
  };
}

export default DocumentList;
