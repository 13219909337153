/**
 * Extracts useable service messages from the various places they might be hiding
 * in a response
 */

import { ERR_UNKNOWN_SAVE_SUBMISSION } from "psims/constants/error-messages";
import { GENERIC_SAVE_ERROR } from "psims/constants/validation-messages";
import { uniq } from "psims/lib/collections";
import { stringifyServerError } from "psims/lib/server-error";
import { is } from "psims/lib/type-assertions";
import { isSuccesfulRowResult, RecordResult } from "psims/models/api/record-result";
import { APIResponse, getErrorMessages, getValidationMessages } from "psims/models/api/response";
import { mapToUserMessage } from "psims/models/api/submission/update/record-result";
import { useMemo } from "react";
import { SubmissionStatus } from "./api";

interface UseServiceMessagesProps<TResult> {
    extractRecordResults?: (result: TResult) => Array<RecordResult>;
    response: APIResponse<TResult> | null | undefined;
    responseStatus: SubmissionStatus;
    serverError: unknown;
}

function useServiceMessages<TResult>({extractRecordResults, response, responseStatus, serverError}: UseServiceMessagesProps<TResult>) {
    const messages = useMemo(() => {
        if (response == null && serverError == null) {
            if (responseStatus === 'update_failed') {
                return [ERR_UNKNOWN_SAVE_SUBMISSION];
            }
            return [];
        }

        const serverErrorStringOrNull = stringifyServerError(serverError);
        const msgs = uniq([
            ...(response == null ? [] : getErrorMessages(response) || []),
            ...(serverErrorStringOrNull != null ? [serverErrorStringOrNull] : []),
            ...(response == null ? [] : getValidationMessages(response) || []).map(v => v.errorMessage).filter(is),
            ...(extractRecordResults && response?.result ? extractRecordResults(response.result) : [])
                .filter(rr => !isSuccesfulRowResult(rr, true))
                .map(mapToUserMessage)
                .filter(is),
        ]);

        if (msgs.length === 0 && isFailedResponseStatus(responseStatus)) {
            return [GENERIC_SAVE_ERROR];
        }

        return msgs;
    }, [extractRecordResults, response, responseStatus, serverError]);
    
    return messages;
}

export default useServiceMessages

function isFailedResponseStatus(status: SubmissionStatus) {
    return (
        status === 'fetch_failed' ||
        status === 'submit_failed' ||
        status === 'update_failed'
    );
}
