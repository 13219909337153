import React, { useCallback, useMemo, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import { UpdateMsoStockOnBehalf, UpdateMsoStockOnBehalfField } from 'psims/models/submission-types/mso/mso-stock-on-behalf';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { Table, TD, TR, ColumnHeader, TH, DeleteActionCellTH } from 'psims/react/pages/primary-pages/data-submissions/shared/data-table-components';
import { H3 } from 'psims/react/components/typography';
import { UseMsoRefData } from './use-mso-ref-data';
import { TooltipHelp } from 'psims/react/pages/primary-pages/data-submissions/shared/tooltip-help';
import Input from '../../shared/input';
import { ModelValidation } from './validation';
import { AddButton, IconButton } from 'psims/react/components/button';
import { recordActionAsEnum } from 'psims/models/api/data-submission-record-action';
import { isDeleteFocusField, StockOnBehalfFocusField } from './types';
import { UseConfirm } from '../../shared/use-confirm';
import Select, { useSelectController } from 'psims/react/components/select';
import FloatingMessage from 'psims/react/components/floating-message';
import { EXPIRED_OR_INTERNAL_PRODUCT } from 'psims/constants/validation-messages';
import useFocusable from 'psims/react/util/use-focusable';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import { UsePortalData } from '../../shared/use-portal-data';

interface MsoStockOnBehalfEditorActions {
    addStockOnBehalf: (msoProductId: number) => any;
    deleteStockOnBehalf: (msoProductId: number, rowIndex: number, source: HTMLElement | null) => any;
    deleteAllDataForProduct: (msoProductId: number, source: HTMLElement | null) => any;
    updateStockOnBehalf: <TField extends UpdateMsoStockOnBehalfField>(msoProductId: number, rowIndex: number, field: TField, value: UpdateMsoStockOnBehalf[TField]) => any
}

type UpdateMsoStockOnBehalfView = UpdateMsoStockOnBehalf & {validationMessages: ModelValidation<UpdateMsoStockOnBehalfField>};

interface MsoStockholdingEditorProps {
    actions: MsoStockOnBehalfEditorActions;
    deleteCtrl: UseConfirm;
    focusedField: unknown | null;
    isDisabled: boolean;
    portalData: UsePortalData;
    refData: UseMsoRefData;
    saveAttempted: boolean;
    stocksOnBehalf: Array<UpdateMsoStockOnBehalfView>;
}

const MsoStockOnBehalfEditor = ({
    actions,
    deleteCtrl,
    focusedField,
    isDisabled,
    portalData,
    refData,
    saveAttempted,
    stocksOnBehalf,
}: MsoStockholdingEditorProps) => {
    const hasExpiredData = useMemo(() => {
        return stocksOnBehalf.some(s => refData.productIdMap[s.msoProductId]?.productStatus === 'inactive');
    }, [refData, stocksOnBehalf]);

    return (
        <BoxedDiv box={{flex: 'column'}}>
            <BoxedDiv box={{marginTop: 6}}>
                <H3>Held on behalf of another entity</H3>
            </BoxedDiv>

            <BoxedDiv box={{marginV: 2}}>
                <p>This section details any stock you hold/own on another entity's behalf (which does not count towards your MSO, but towards another entity's MSO instead).</p>
                <p>Quantities should be reported to the nearest <strong>megalitre (ML)</strong>.</p>
            </BoxedDiv>

            <BoxedDiv box={{marginV: 2}}>
                <Table caption='Data for stock held at on behalf of another entity'>
                    <thead>
                        <TR>                   
                            <ColumnHeader label='MSO Product' fixedWidth='250px'/>
                            <ColumnHeader
                                $align='center'
                                label= 'Amount held'
                                Help={
                                    <BoxedDiv box={{flex: 'column', width: '350px'}}>
                                        <p>Total volume being held on behalf of the listed entity.</p> 
                                    </BoxedDiv>
                                }
                                fixedWidth='150px'
                            />                    
                            <ColumnHeader
                                $align='center'
                                label= 'Entity held on behalf of'
                                Help={
                                    <BoxedDiv box={{flex: 'column', width: '350px'}}>
                                        <p>Name of the entity listed in the legally enforceable arrangement (for section 23 or 24 of the <em>Fuel Security Act</em>).</p>
                                        <p>A new row will need to be added for each arrangement that is in place.</p>
                                    </BoxedDiv>
                                }
                            />

                            <ColumnHeader
                                $align='center'
                                $width='400px'
                                label= 'Stock ownership'
                                Help={
                                    <BoxedDiv box={{flex: 'column', width: '350px'}}>
                                        <ul>
                                            <li><strong>Entitled to take ownership of stock (s23)</strong><br/>Stock that you currently own, but you have a legally enforceable arrangement in place which allows another entity to count it towards their MSO under section 23 of the <em>Fuel Security Act</em>.</li>
                                            <li><strong>Reserved or quarantined stock (s24)</strong><br/>Stock that you own, but you have a legally enforceable arrangement in place which allows another entity to count it towards their MSO under section 24 of the <em>Fuel Security Act</em> (eg. oil stock tickets).</li>
                                        </ul>
                                        <br />
                                        <p>
                                            Note: This stock will not contribute towards your MSO.
                                        </p>

                                        <p>
                                            A new row will need to be added for each arrangement that is in place.
                                        </p>
                                    </BoxedDiv>
                                }
                            />

                            {
                                hasExpiredData ?
                                    <DeleteActionCellTH>
                                        <VisuallyHidden>Actions</VisuallyHidden>
                                    </DeleteActionCellTH> :
                                    null
                            }
                        </TR>
                    </thead>

                    <tbody>
                        {
                            refData.products.map((product, index) => (
                                <ProductRow
                                    key={index}
                                    actions={actions}
                                    onDelete={deleteCtrl.requestConfirmation}
                                    focusedField={focusedField}
                                    isDisabled={isDisabled}
                                    portalData={portalData}
                                    product={product}
                                    saveAttempted={saveAttempted}
                                    stocksOnBehalf={stocksOnBehalf}
                                />
                            ))
                        }
                    </tbody>
                </Table>
            </BoxedDiv>
        </BoxedDiv>
    )
}

type StockOnBehalfProps = {
    actions: MsoStockOnBehalfEditorActions;
    isDisabled: boolean;
    onBehalfFF: StockOnBehalfFocusField | null;
    onDelete: UseConfirm['requestConfirmation'];
    product: UseMsoRefData['products'][number] | null | undefined;
    rowIndex: number;
    saveAttempted: boolean;
    sob: UpdateMsoStockOnBehalfView;
}

const StockOnBehalf = ({
    actions,
    isDisabled,
    onBehalfFF,
    onDelete,
    product,
    rowIndex,
    saveAttempted,
    sob,
}: StockOnBehalfProps) => {
    const [deleteRef, setDeleteRef] = useState<HTMLElement | null>(null);

    const ownershipSelectCtrl = useSelectController({
        value: sob.msoOwnershipType,
        options: [
            {label: 'Entitled to take ownership (s23)', value: 1},
            {label: 'Reserved or quarantined stock (s24)', value: 2},
        ],
        onChange: v => actions.updateStockOnBehalf(sob.msoProductId, rowIndex, 'msoOwnershipType', !isNaN(Number(v)) ? Number(v) : undefined)
    })

    return (
        <BoxedDiv box={{alignItems: 'center', flex: 'row', marginBottom: 1.5}} key={rowIndex}>

            <BoxedSpan box={{alignItems: 'center', flex: 'row', width: '150px', paddingRight: 4}}>
                <Input
                    autoFocus={sob.id == null}
                    kind='number'
                    disabled={isDisabled}
                    error={sob.validationMessages.amountHeld?.tooltip.message}
                    forceError={saveAttempted}
                    label='Amount held'
                    onChange={v => actions.updateStockOnBehalf(sob.msoProductId, rowIndex, 'amountHeld', v)}
                    shouldFocus={onBehalfFF?.field === 'amountHeld' && onBehalfFF.rowIndex === rowIndex}
                    value={sob.amountHeld}
                />

            </BoxedSpan>

            <BoxedSpan box={{alignItems: 'center', flex: 'row', flexGrow: 1, paddingLeft: 1}}>
                <Input
                    error={sob.validationMessages.entityOnBehalf?.tooltip.message}
                    forceError={saveAttempted}
                    kind='string'
                    label='Entity held on behalf of'
                    onChange={v => actions.updateStockOnBehalf(sob.msoProductId, rowIndex, 'entityOnBehalf', v)}
                    shouldFocus={onBehalfFF?.field === 'entityOnBehalf' && onBehalfFF.rowIndex === rowIndex}
                    value={sob.entityOnBehalf}
                />

                <BoxedSpan box={{minWidth: '350px', marginLeft: 2}}>
                    <Select
                        bare={true}
                        borderless={true}
                        error={sob.validationMessages.msoOwnershipType?.tooltip.message}
                        focusless={true}
                        id={`${sob.msoProductId}_${rowIndex}`}
                        isDisabled={isDisabled}
                        label='Stock ownership'
                        options={ownershipSelectCtrl.options}
                        onChange={ownershipSelectCtrl.onChange}
                        setFocused={onBehalfFF?.field === 'msoOwnershipType' && onBehalfFF?.rowIndex === rowIndex}
                        useTooltip={true}
                        value={ownershipSelectCtrl.value}
                    />
                </BoxedSpan>

                {
                    // Only show delete button if form is editable and product is active
                    (!isDisabled && product?.productStatus === 'active') &&
                    <IconButton
                        color='dark-grey'
                        icon='trash'
                        label='Delete entity'
                        onClick={() => onDelete(
                            'Are you sure you wish to delete this data?',
                            'Held on behalf of another entity record will be deleted',
                            () => actions.deleteStockOnBehalf(sob.msoProductId, rowIndex, deleteRef)
                        )}
                        ref={setDeleteRef}
                        size='sm'
                        styles={{marginLeft: '6px', marginRight: '-12px', padding: '4px'}}
                    />
                }
            </BoxedSpan>

        </BoxedDiv>
    );
}

interface ProductRowProps {
    actions: MsoStockOnBehalfEditorActions;
    focusedField: unknown | null;
    isDisabled: boolean;
    onDelete: UseConfirm['requestConfirmation'];
    portalData: UsePortalData
    product: UseMsoRefData['products'][number] | null | undefined;
    saveAttempted: boolean;
    stocksOnBehalf: Array<UpdateMsoStockOnBehalfView>;
}

const ProductRow = ({actions, focusedField, isDisabled, onDelete, portalData, product, saveAttempted, stocksOnBehalf}: ProductRowProps) => {
    const [deleteRef, setDeleteRef] = useState<HTMLElement | null>(null);

    const onBehalfFF = useMemo(() => (
        isStockOnBehalfFocusField(focusedField) && focusedField.msoProductId === product?.id ? focusedField : null
    ), [focusedField, product?.id]);

    if (product == null) {
        // TODO: log missing product
        return null;
    }

    const Help = portalData.productPortalData.find(ppd => ppd?.referenceCode === product.code)?.content

    const sobs = stocksOnBehalf
        .map((sob, rowIndex) => ({
            rowIndex,
            data: sob,
        }))
        .filter(sob => sob.data.msoProductId === product.id && sob.data.recordAction !== recordActionAsEnum('Delete'));
    
    // Don't show inactive products with no data
    if (sobs.length === 0 && product.productStatus === 'inactive') {
        return null;
    }

    return (
        <TR>
            <TH scope='row' verticalAlign='top'>
                <BoxedSpan box={{alignItems: 'center', flex: 'row', height: '52px'}}>
                    {product.name}
                    {
                        !isDisabled && product.productStatus === 'inactive' ?
                        <BoxedSpan box={{marginBottom: -0.5, marginLeft: 1}}>
                            <FloatingMessage
                                content={EXPIRED_OR_INTERNAL_PRODUCT}
                                kind="warning"
                                role="alert"
                            />
                        </BoxedSpan> :
                        Help != null ?
                        <TooltipHelp
                            Help={<ReactMarkdown>{Help}</ReactMarkdown>}
                        /> :
                        null
                    }
                </BoxedSpan>
            </TH>

            <TD colSpan={3} verticalAlign='top'>
                <BoxedDiv box={{flex: 'column'}}>
                    {
                        sobs.map(({data: sob, rowIndex}) => (
                            <StockOnBehalf
                                key={rowIndex}
                                actions={actions}
                                isDisabled={isDisabled}
                                onBehalfFF={onBehalfFF}
                                onDelete={onDelete}
                                product={product}
                                rowIndex={rowIndex}
                                saveAttempted={saveAttempted}
                                sob={sob}
                            />
                        ))
                    }

                    {
                        (!isDisabled && product.productStatus !== 'inactive') ?
                        <BoxedSpan box={{alignItems: 'center', flex: 'row', height: '52px'}}>
                            <AddButton
                                label='Add Entity'
                                onClick={() => actions.addStockOnBehalf(Number(product.id))}
                            >Add Entity</AddButton>
                        </BoxedSpan> :
                        null
                    }
                </BoxedDiv>
            </TD>

            {
                !isDisabled && product.productStatus === 'inactive' ?
                    <TD>
                        <DeleteRowButton
                            focusedField={focusedField}
                            onClick={() => onDelete(
                                'All data for this product will be deleted.',
                                'Are you sure you wish to delete this data?',
                                () => actions.deleteAllDataForProduct(product.id, deleteRef)
                            )}
                            productId={product.id}
                            ref={setDeleteRef}
                        />
                    </TD> :
                    null
            }

        </TR>
    );
}

export default MsoStockOnBehalfEditor;

interface DeleteRowProps {
    focusedField: unknown;
    onClick: () => any;
    productId: number;
}

const DeleteRowButton = React.forwardRef(({ focusedField, onClick, productId }: DeleteRowProps, ref) => {
    const focusable = useFocusable({
        setFocused: (
            isDeleteFocusField(focusedField) &&
            focusedField.kind === 'onBehalf' &&
            focusedField.msoProductId === productId
        ),
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <IconButton
            color='dark-grey'
            icon='trash'
            label='Delete row for product'
            onClick={onClick}
            ref={setRef}
            size='sm'
        />
    );
});

function isStockOnBehalfFocusField(maybe?: unknown | null): maybe is StockOnBehalfFocusField {
    if (maybe == null) {
        return false;
    }

    const asF = maybe as StockOnBehalfFocusField;

    return  (
        asF.kind === 'stockOnBehalf' &&
        asF.field != null &&
        asF.msoProductId != null &&
        asF.rowIndex != null
    );
}
