/** 
 *  Apologies to future me and other poor souls who need to maintain this submission editor.
 *  The level of indirection in controllers is unnecessarily complex, due to
 *  just-in-time requirement changes, data/view structural differences and failed
 *  attempts to simplify the impacts of these constraints.
 * 
 *  Ideally, development of the next submission type will have a much clearer
 *  structure, which can be retrofitted to Stockholding when time permits.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router';

import { useAPI } from 'psims/react/providers/api';
import { Status } from 'psims/types/async';
import { BoxedDiv } from 'psims/react/components/layout';
import Loading from 'psims/react/components/loading';
import Notification from 'psims/react/components/notification';
import Button from 'psims/react/components/button';
import StockholdingEditor from './stockholding-editor';
import { Stockholding } from 'psims/models/submission-types/stockholding';
import { useReferenceData } from 'psims/react/providers/api/reference-data';
import { useNavigation } from 'psims/react/providers/router';
import { isSubmissionType } from 'psims/models/ref-data/submission-type';

interface StockholdingPageProps {
    id: number;
}

// Root wrapper that ensures the passed id prop is a number,
// and performs redirection to home page if not
const StockholdingPage = (props: StockholdingPageProps) => {
    const isInvalidParams = isNaN(props.id);

    return isInvalidParams ? <Redirect to='/' /> : <StockholdingPageWithID {...props} />;
}

// Wrapper that ensures base dependencies are loaded, removing this
// logic from the underlying submission view, so it can be concerned
// only with business logic
const StockholdingPageWithID = (props: StockholdingPageProps) => {
    const vm = useVM(props);

    if (vm.viewMode === 'loading') {
        return (
            <BoxedDiv box={{alignItems: 'center', flex: 'column', flexGrow: 1, justifyContent: 'center'}}>
                <Loading>Loading</Loading>
            </BoxedDiv>
        )
    }

    if (vm.viewMode === 'error') {
        return (
            <div className='container'>
                <BoxedDiv box={{alignItems: 'center', flex: 'column', marginV: 4}}>
                    <BoxedDiv box={{alignItems: 'center', flex: 'column', width: '600px'}}>
                        <Notification align='center' kind='warning' >
                            <p>
                                There was a problem loading this stockholding data submission, please navigate back to your <Button $kind='text' aria-label='Go back to home page' onClick={vm.handleTryAgain}>'Home' page</Button> and try again.
                            </p>
                        </Notification>
                    </BoxedDiv>
                </BoxedDiv>
            </div>
        )
    }

    if (vm.viewMode === 'loaded' && vm.stockholding != null && isSubmissionType(vm.submissionType)) {
        return <StockholdingEditor submission={vm.stockholding} submissionType={vm.submissionType} />;
    }

    return null;
}

function useVM({id}: StockholdingPageProps) {
    const {api} = useAPI();
    const {data: refData} = useReferenceData();
    const [stockholding, setStockholding] = useState<Stockholding | null>(null);
    const [loadStatus, setLoadStatus] = useState<Status>('init');
    const navigation = useNavigation();

    const stockTypes = refData?.stockTypes;

    const loadDataSubmission = useCallback(() => {
        if (loadStatus === 'loading') {
            return;
        }

        setLoadStatus('loading');

        api.getStockholding({id})
        .then(response => {
            if (response !== null && response.isSuccessful && response.result !== undefined) {
                setStockholding(response.result as Stockholding);
                setLoadStatus('fulfilled');
            } else {
                setTimeout(() => {
                    setLoadStatus('error');
                }, 750)
            }
        })
        .catch(() => {
            setTimeout(() => {
                setLoadStatus('error');
            }, 750)
        });
    }, [api, id, loadStatus]);

    const handleTryAgain = useCallback(() => {
        navigation.goToHomePage();
    }, [navigation]);

    useEffect(() => {
        loadDataSubmission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submissionType = useMemo(() => refData?.submissionTypes.find(st => st.name === 'Stockholding'), [refData]);

    const viewMode = useMemo(() => {
        if (loadStatus === 'error') {
            return 'error';
        }

        if (stockholding != null && stockTypes != null && isSubmissionType(submissionType)) {
            return 'loaded';
        }

        return 'loading';
    }, [loadStatus, stockholding, stockTypes, submissionType]);

    return {
        handleTryAgain,
        loadStatus,
        stockholding,
        stockTypes,
        submissionType,
        viewMode,
    };
}

export default StockholdingPage;
