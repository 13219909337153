import { ChangeEvent, ChangeEventHandler, FocusEvent, FocusEventHandler, useCallback, useState } from "react";

function useIsDirty(
    initialIsDirty?: boolean,
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
) {
    const [isDirty, setIsDirty] = useState(Boolean(initialIsDirty));

    const handleFocus = useCallback((evt: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsDirty(true);

        if (onBlur) {
            onBlur(evt);
        }
    }, [onBlur]);

    const handleChange = useCallback((evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsDirty(true);

        if (onChange) {
            onChange(evt);
        }
    }, [onChange]);

    const reset = useCallback(() => {
        setIsDirty(false);
    }, []);

    return {
        isDirty,
        handleFocus,
        handleChange,
        reset,
    };
}

export default useIsDirty;
