import { ERR_FORBIDDEN_SAVE_SUBMISSION, ERR_UNKNOWN_SAVE_SUBMISSION } from "psims/constants/error-messages";
import { GENERIC_SAVE_ERROR } from "psims/constants/validation-messages";
import { ValidationMessageVM } from "psims/gen/xapi-client";
import { asString } from "./string";

type WithMessage = {
    message: string;
}

export function isWithMessage(e: unknown): e is WithMessage {
    const as = e as WithMessage;
    return as != null &&
        typeof as.message === 'string';
}

type WithBody<T extends string | {}> = {
    body: T;
}

export function isWithBody<T extends string | {}>(e: unknown): e is WithBody<T> {
    const as = e as WithBody<T>;
    return as != null && (
        typeof as.body === 'string' ||
        typeof as.body === 'object'
    );
}
type WithValidationMessages = {
    body: {
        validationMessages: Array<ValidationMessageVM>;
    }
};

type WithSystemError = {
    status: number;
    statusText: string;
};

export function isWithValidationMessages(e: unknown): e is WithValidationMessages {
    return (e as WithValidationMessages)?.body?.validationMessages !== undefined;
}

type WithErrorMessages = {
    body: {
        errorMessages: Array<string>
    }
}

export function isWithErrorMessages(e: unknown): e is WithErrorMessages {
    return (e as WithErrorMessages)?.body?.errorMessages != null;
}

export function isForbiddenError(e: unknown): e is WithSystemError {
    return (e as WithSystemError)?.status === 403;
}

export function isBadGatewayError(e: unknown): e is WithSystemError {
    return (e as WithSystemError)?.status === 502;
}

type StructuredServerError = {
    type: string;
    title: string;
    status: number;
    errors: {
        [key: string]: Array<string>;
    }
}

export function isStructuredServerError(e: unknown): e is StructuredServerError {
    const as = e as StructuredServerError;
    return (
        as != null &&
        typeof as.type === 'string' &&
        typeof as.status === 'number' &&
        as.errors != null &&
        typeof as.errors === 'object'
    );
}

export function stringifyServerError(e: unknown): string | null {
    if (e == null) {
        return null;
    }

    // String errors
    if (typeof e === 'string') {
        return preventStackErrors(e);
    }

    if (isWithBody(e)) {
        let body = e.body
        if (typeof e.body === 'string' && !e.body.startsWith('<')) {
            try {
                body = JSON.parse(e.body);
            } catch (err) {
                return preventStackErrors(e.body);
            }
        }

        if (isWithValidationMessages(e) && e.body.validationMessages.length > 0) {
            return asString(e.body.validationMessages[0].errorMessage);
        }

        if (isWithErrorMessages(e) && e.body.errorMessages.length > 0) {
            return asString(e.body.errorMessages[0]);
        }

        if (isStructuredServerError(body)) {
            return body.title;
        }

        // TODO: add other known structures
    }

    if (isForbiddenError(e)) {
        return ERR_FORBIDDEN_SAVE_SUBMISSION;
    }

    if (isWithMessage(e)) {
        return e.message;
    }

    return 'Unknown error';
}

export function stringifyForbiddenWAFError(e: unknown): string | null {
    if (e == null) {
        return null;
    }

    // String errors
    if (typeof e === 'string') {
        return e;
    }

    if (isForbiddenError(e) && isWithBody(e) && typeof e.body === 'string' && e.body.startsWith('<')) {
        return ERR_FORBIDDEN_SAVE_SUBMISSION;
    }

    return GENERIC_SAVE_ERROR;
}

function preventStackErrors(err: string) {
    // This is a bit naive, but we want to avoid stack traces showing as errors:
    if (err.indexOf('Microsoft.Asp') > -1) {
        return ERR_UNKNOWN_SAVE_SUBMISSION;
    }

    return err;
}
