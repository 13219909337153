import { useMemo } from "react";
import { ViewMode } from "../shared/use-view-mode";
import { UseFormForType } from "./use-form-for-type";
import { UseWholesalingAPI } from "./use-wholesaling-api";
import { UseWholeSalingSave } from "./use-wholesaling-save";
import { UseWholesalingSteps } from "./use-wholesaling-steps";
import { UseWholesalingSubmission } from "./use-wholesaling-submission";

interface UsePrimaryControlsProps {
    apiCtrl: UseWholesalingAPI;
    formCtrl: UseFormForType;
    stepCtrl: UseWholesalingSteps;
    saveCtrl: UseWholeSalingSave;
    submissionCtrl: UseWholesalingSubmission;
    viewMode: ViewMode;
}

function usePrimaryControls({apiCtrl, formCtrl, stepCtrl, saveCtrl, submissionCtrl, viewMode}: UsePrimaryControlsProps) {
    const previousControl = useMemo(() => {
        if (stepCtrl.currentStep.index === 0) {
            return null;
        }

        return {
            disabled: saveCtrl.isApiBusy || (stepCtrl.currentStep.typeName !== 'Submit' && !formCtrl.view?.isPageValid),
            label: 'Previous',
            onClick: () => saveCtrl.handlePrevious(),
        };
    }, [formCtrl, stepCtrl, saveCtrl])

    const saveControl = useMemo(() => {
        if (stepCtrl.currentStep.typeName === 'Submit') {
            return null;
        }

        return {
            disabled: saveCtrl.isApiBusy || !formCtrl.view?.isPageValid,
            label: `${viewMode === 'edit' ? 'Save and continue' : 'Continue'}`,
            onClick: () => saveCtrl.handleSaveAndContinue(),
        };
    }, [formCtrl, stepCtrl, saveCtrl, viewMode])

    const submitControl = useMemo(() => {
        if (stepCtrl.currentStep.typeName !== 'Submit' || viewMode === 'view') {
            return null;
        }

        return {
            disabled: saveCtrl.isApiBusy,
            label: 'Submit',
            onClick: submissionCtrl.submit,
        };
    }, [saveCtrl.isApiBusy, stepCtrl, submissionCtrl.submit, viewMode])

    return {
        previousControl,
        saveControl,
        submitControl,
    };
}

export default usePrimaryControls;
