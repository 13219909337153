
import { isWithPath, Page, PAGES } from "psims/constants/pages";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useLocation } from "react-router";
import { useNavigation } from "./router";

type PageContext = {
    page: Page;
}

const context = createContext<PageContext | null>(null);

const PageProvider = ({children}: PropsWithChildren<{}>) => {
    const {pathname} = useLocation();
    const nav = useNavigation();

    const page = useMemo(() => {
        return PAGES.find(p => isWithPath(p) && p.path === pathname) ||
            PAGES.find(p => p.name === 'not-found') as Page;
    }, [pathname]);

    if (page.name === 'not-found' && pathname !== page.path) {
        nav.goToNotFound();
    }

    return <context.Provider value={{page}}>{children}</context.Provider>;
}

export function usePage() {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error('usePage must be used with PageProvider');
    }

    return {
        ...ctx,
    };
}

export default PageProvider;