import { FocusEvent, FocusEventHandler, useCallback, useEffect, useState } from "react";

interface UseIsFocusedProps {
    onBlur?: FocusEventHandler<HTMLElement>;
    onFocus?: FocusEventHandler<HTMLElement>;
}

function useIsFocused({onBlur, onFocus}: UseIsFocusedProps) {
    const [isFocused, setIsFocused] = useState(false);

    const handleBlur = useCallback((evt: FocusEvent<HTMLElement>) => {
        setIsFocused(false);

        if (typeof onBlur === 'function') {
            onBlur(evt);
        }
    }, [onBlur]);

    const handleFocus = useCallback((evt: FocusEvent<HTMLElement>) => {
        setIsFocused(true);

        if (typeof onFocus === 'function') {
            onFocus(evt);
        }
    }, [onFocus]);

    return {
        handleBlur,
        handleFocus,
        isFocused,
    };
}

export default useIsFocused;

// Alternative implementation that doesn't delegate binding focus to consumer
export function useIsFocusedAlt() {
    const [ref, setRef] = useState<HTMLElement | null>(null);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (ref == null) {
            setIsFocused(false);
            return;
        }

        function handleBlur() {
            setIsFocused(false);
        }

        function handleFocus() {
            setIsFocused(true);
        }

        ref.addEventListener('blur', handleBlur);
        ref.addEventListener('focus', handleFocus);

        return () => {
            ref.removeEventListener('blur', handleBlur);
            ref.removeEventListener('focus', handleFocus);
        }
    }, [ref]);

    return {
        isFocused,
        ref,
        setRef,
    }
}