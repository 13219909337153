import { DataSubmissionTypeName, ExtendedDataSubmissionTypeName, ReportFrequencyTypeName } from "psims/models/data-submission";

export const STOCKHOLDING_TYPES = {
    'australia': 'Australia',
    'overseas': 'Overseas',
    'on_water': 'On water',
} as const;

const ROW_RESULT_ENUM = [
    'Created',
    'Deleted',
    'Unchanged',
    'Updated',
    'ConcurrencyError',
    'SystemError',
    'TranslationError',
] as const;

export type RowResultEnum = ArrayElement<typeof ROW_RESULT_ENUM>;

export function getUserSubmissionTypeName(typeName: DataSubmissionTypeName) {
    switch (typeName) {
        case 'MSO importer': return 'Minimum stockholding obligation - importer';
        case 'MSO refiner': return 'Minimum stockholding obligation - refiner';
        default: return typeName;
    }
}

export function getUserSubmissionTypeNameForDashboard({reportFrequencyTypeName, submissionTypeName}: DataSubmission) {
    let msoName = '';
    switch (submissionTypeName) {
        case 'MSO importer': {
            msoName = 'MSO - Importer';
            break;
        }
        case 'MSO refiner': {
            msoName = 'MSO - Refiner';
            break;
        }
        default: 
            return submissionTypeName
    }

    return reportFrequencyTypeName === 'Annually' ?
        getSubmissionTypeName({reportFrequencyTypeName, submissionTypeName}) :
        msoName;
}

interface DataSubmission {
    reportFrequencyTypeName: ReportFrequencyTypeName
    submissionTypeName: DataSubmissionTypeName;
}

export function getSubmissionTypeName({reportFrequencyTypeName, submissionTypeName}: DataSubmission): ExtendedDataSubmissionTypeName {
    if (submissionTypeName.indexOf('MSO') > -1 && reportFrequencyTypeName === 'Annually') {
        return `${submissionTypeName as 'MSO refiner' | 'MSO importer'} - Annual activity`;
    }

    return submissionTypeName;
}
export function getSubmissionTypeLongName({reportFrequencyTypeName, submissionTypeName}: DataSubmission) {
    if (submissionTypeName.indexOf('MSO') > -1 && reportFrequencyTypeName === 'Annually') {
        return `${submissionTypeName} - Annual activity`;
    }

    return getUserSubmissionTypeName(submissionTypeName);
}
