import { useCallback, useMemo, useState } from "react";

import { ViewMode } from "psims/react/pages/primary-pages/data-submissions/shared/use-view-mode";
import StepLabel from "psims/react/pages/primary-pages/data-submissions/shared/step-label";
import { DataSubmissionStatus } from "psims/models/data-submission";

export type PageSavedMap<TFormDataKey extends string> = {[key in TFormDataKey]: boolean;}

type ProgressStep = {
    hasData: boolean | null;
    kind: 'submit' | 'data';
    label: string;
}

interface UseProgressProps<TFormDataKey extends string> {
    orderedFormDataPageSavedKeys: Array<TFormDataKey>;
    steps: Array<ProgressStep>;
    submissionFormData: PageSavedMap<TFormDataKey>;
    submissionStatus: DataSubmissionStatus;
    viewMode: ViewMode;
}

function useProgress<TFormDataKey extends string>({
    orderedFormDataPageSavedKeys, steps, submissionFormData, submissionStatus, viewMode
}: UseProgressProps<TFormDataKey>) {
    const [currentStepIndex, setCurrentStepIndex] = useState(determineFirstPageIndex(submissionFormData, submissionStatus, orderedFormDataPageSavedKeys, viewMode));

    const progressSteps = useMemo(() => {
        return steps.map((step, index) => ({
            ...step,
            index,
            Label: StepLabel({
                hasData: step.kind === 'submit' ? true : Boolean(step.hasData),
                label: step.label,
            }),
            status: (currentStepIndex === index ? 'active' : statusForStep(step, submissionFormData, orderedFormDataPageSavedKeys[index], viewMode)) as 'active' | 'complete' | 'pending',
        }));
    }, [currentStepIndex, orderedFormDataPageSavedKeys, steps, submissionFormData, viewMode]);

    const currentStep = useMemo(() => {
        return progressSteps[currentStepIndex];
    }, [currentStepIndex, progressSteps]);

    const goToStep = useCallback((index: number) => {
        setCurrentStepIndex(index);
    }, []);

    return useMemo(() => ({
        currentStep,
        progressSteps,
        goToStep,
    }), [currentStep, progressSteps, goToStep]);
}

export default useProgress

// Helpers
function determineFirstPageIndex<TFormDataKey extends string>(submissionFormData: PageSavedMap<TFormDataKey>, submissionStatus: DataSubmissionStatus, orderedFormDataPageSavedKeys: Array<TFormDataKey>, viewMode: ViewMode) {
    if (viewMode !== 'edit' || submissionStatus === 'Action required') {
        return 0;
    }

    let i = 0;

    for (; i < orderedFormDataPageSavedKeys.length; i++) {
        if (!submissionFormData[orderedFormDataPageSavedKeys[i]]) {
            return 0;
        }
    }

    return i;
}

function statusForStep<TFormDataKey extends string>(step: ProgressStep, submissionFormData: PageSavedMap<TFormDataKey>, savedPageKey: TFormDataKey, viewMode: ViewMode) {
    if (viewMode === 'view')
    {
        return 'complete';
    }

    if (step.kind === 'submit') {
        return 'pending';
    }

    return submissionFormData[savedPageKey] ? 'complete' : 'pending';
}
