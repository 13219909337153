import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { isPopulatedStockholding, Stockholding, StockholdingSubmission, StockholdingSubmissionResponse, StockholdingSubmissionUpdateResponse } from "psims/models/submission-types/stockholding";
import Text from "psims/react/components/text";
import { BoxedSpan } from "psims/react/components/layout";
import { useNavigation } from "psims/react/providers/router";
import { asNumber } from "psims/lib/number";
import { useAPI } from "psims/react/providers/api";
import { getResponseStatus, isAPIResponseWithResult } from "psims/models/api/response";
import { getAggregatedUpdateStatus, mapToUserMessage } from "psims/models/api/submission/update/record-result";
import { isSuccesfulRowResult, RecordResult } from "psims/models/api/record-result";
import { isForbiddenError, isWithErrorMessages, isWithValidationMessages } from "psims/lib/server-error";
import { ERR_FORBIDDEN_SAVE_SUBMISSION, ERR_UNKNOWN_SAVE_SUBMISSION } from "psims/constants/error-messages";
import { ResponseStatus } from "psims/models/api/submission/update/response";
import { useReferenceData } from "psims/react/providers/api/reference-data";
import { FocusField, PageKey, PAGE_INDEX, STEPS, StockholdingUpdateSubmissionResult } from "./shared";
import usePageDomestic from "./use-page-domestic";
import usePageOverseas from "./use-page-overseas";
import usePageOnWater from "./use-page-on-water";
import usePageSubmit from "./use-page-submit";
import { StockType } from "psims/models/ref-data/stock-type";
import { encodeEscapeChars } from "psims/lib/validation/string";
import useConfirmNavigation from "psims/react/util/use-confirm-navigation";
import { uniq } from "psims/lib/collections";
import { attemptFocusOnSelector, attemptScrollToSelector } from "psims/react/pages/primary-pages/data-submissions/shared/view-utils";
import { SELECTOR_NOTIFICATION, SELECTOR_NOTIFICATION_KIND } from "psims/constants/selectors";
import useClearData from "../shared/use-clear-data";
import useSubmissionProcessingOverlay from "../shared/use-submission-processing-overlay";
import { SubmissionStatus } from "../shared/api";
import useTemplateImport from "psims/react/blocks/import/use-template-import";
import { useLogger } from "psims/react/providers/logging";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { isValidForPeriod } from "psims/models/ref-data/util";
import usePortalDataAPI from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api";
import { SubmissionType } from "psims/models/ref-data/submission-type";
import stockholdingImport from "psims/react/blocks/import/submissions/stockholding";
import { isEmpty } from "psims/lib/empty";
import { INVALID_DECLARATION } from "psims/constants/validation-messages";
import { ExpiredOrInternalProductsWithData } from "../shared/types";
import useFocusedField from "psims/react/util/use-focused-field";

interface UseStockholdingProps {
    submission: Stockholding;
    submissionType: SubmissionType;
}

type Status = 'idle' | 'processing' | 'saving' | 'submitting' | 'submitted';

type ReviewAlertsStatus = 'showing_dialog' | 'hide_dialog';

type UnsavedChangesStatus = 'idle' | 'showing_dialog';

function useStockholding({submission: initialSubmission, submissionType}: UseStockholdingProps) {
    const {api} = useAPI();
    const {data: refData} = useReferenceData();
    const portalDataAPICtrl = usePortalDataAPI({submissionType: submissionType});
    const nav = useNavigation();
    const logger = useLogger({source: 'use-stockholding'});
    const loggerRef = useUpdatedRef(logger);
    const focusedFieldCtrl = useFocusedField<FocusField>();
    const [status, setStatus] = useState<Status>('idle');
    const [stepIndex, setStepIndex] = useState(0);
    const [submission, setSubmission] = useState(initialSubmission);
    const {dataSubmission: {status: submissionStatus}} = submission;
    const mode = useMemo(() => (submissionStatus === 'Not started' || submissionStatus === 'Draft' || submissionStatus === 'Action required') ? 'edit' : 'view', [submissionStatus]);
    const [systemMessages, setSystemMessages] = useState<Array<string>>([]);
    const [declarationMessage, setDeclarationMessage] = useState<string | null>(null);
    const [requestedStepIndex, setRequestedStepIndex] = useState<number | null>(null);
    const [updateResult, setUpdateResult] = useState<StockholdingUpdateSubmissionResult | null>(null);
    const [submissionResponse, setSubmissionResponse] = useState<StockholdingSubmissionResponse | null>(null);
    const [reviewAlertStatus, setReviewAlertStatus] = useState<ReviewAlertsStatus>('hide_dialog');
    const [unsavedChangesStatus, setUnsavedChangesStatus] = useState<UnsavedChangesStatus>('idle');
    const [validationChecked, setValidationChecked] = useState(false);
    const haveSetInitialPage = useRef(false);

    const countries = useMemo(() => {
        if (refData == null) {
            return undefined;
        }

        return refData.countries.filter(country => isValidForPeriod(
            country,
            submission.dataSubmission.reportingPeriodFrom,
            submission.dataSubmission.reportingPeriodTo)
        );
    }, [
        submission.dataSubmission.reportingPeriodFrom,
        submission.dataSubmission.reportingPeriodTo,
        refData
    ]);

    const currentStep = useMemo(() => ({ index: stepIndex, name: STEPS[stepIndex].name,}), [stepIndex]);

    const expiredProductsWithData = useMemo(() => {        
        const allExpiredProducts: ExpiredOrInternalProductsWithData[] = [];
        const updatedStk = updateResult?.stockholdingSubmission
        const stk = isPopulatedStockholding(updatedStk) ? updatedStk : submission;
        
        refData?.stockProductGroups?.forEach(g => {
                const isGroupExpired = !isValidForPeriod(g, stk.dataSubmission.reportingPeriodFrom, stk.dataSubmission.reportingPeriodTo);
                const products = refData?.stockProducts?.filter(p => p.stockProductGroupId === g.id) ?? [];
                let stepIndex = -1;
    
                products.forEach(p => {
                    const isExpired = isGroupExpired ||
                        !isValidForPeriod(p, stk.dataSubmission.reportingPeriodFrom, stk.dataSubmission.reportingPeriodTo);
                        
                    if (isExpired) {
                        if (g.stockTypeName === 'In Australia') {
                            stepIndex = 0;
                            const data = stk?.domesticStockholdings?.find(x => x.stockProductId === p.id && x.recordResult?.rowResult !== 'Deleted');
                            if (!(data === undefined || isEmpty(data) || data.id === undefined || data.id == null)) {
                                allExpiredProducts.push({productId: p.id, typeName: g.stockTypeName, productName: p.productName, stepIndex, productStatus: 'expired_with_data'});
                            }
                        }
                        if (g.stockTypeName === 'Overseas') {
                            stepIndex = 1;
                            const data = stk?.overseasStockholdings?.find(x => x.stockProductId === p.id && x.recordResult?.rowResult !== 'Deleted');
                            if (!(data === undefined || isEmpty(data) || data.id === undefined || data.id == null)) {
                                allExpiredProducts.push({productId: p.id, typeName: g.stockTypeName, productName: p.productName, stepIndex, productStatus: 'expired_with_data'});
                            }
                        }
                        if (g.stockTypeName === 'On water') {
                            stepIndex = 2;
                            const data = stk?.onWaterStockholdings?.find(x => x.stockProductId === p.id && x.recordResult?.rowResult !== 'Deleted');
                            if (!(data === undefined || isEmpty(data) || data.id === undefined || data.id == null)) {
                                allExpiredProducts.push({productId: p.id, typeName: g.stockTypeName, productName: p.productName, stepIndex, productStatus: 'expired_with_data'});
                            }
                        }
                    }                    
                })
        });
    
        return allExpiredProducts;
    }, [
        refData?.stockProductGroups,
        refData?.stockProducts,
        submission,
        updateResult,
    ]);

    // Go directly to step if possible 
    const goToStep = useCallback((step: number) => {
        if (stepIndex === step) {
            return;
        }

        setSystemMessages([]);
        setDeclarationMessage(null);
        setRequestedStepIndex(null);

        if (step < STEPS.length && step > -1) {
            setStepIndex(step);
        }
    }, [stepIndex]);

    const importCtrl = useTemplateImport<StockholdingSubmission>({
        dataSubmission: submission.dataSubmission,
        importerBuilder: stockholdingImport
    });

    const domesticCtrl = usePageDomestic({
        focusedFieldCtrl,
        importCtrl,
        isActive: currentStep.name === 'Australia',
        portalDataAPICtrl,
        stockholding: submission,
    });

    const overseasCtrl = usePageOverseas({
        focusedFieldCtrl,
        importCtrl,
        isActive: currentStep.name === 'Overseas',
        portalDataAPICtrl,
        stockholding: submission,
        countries: countries || [],
    });

    const onWaterCtrl = usePageOnWater({
        focusedFieldCtrl,
        importCtrl,
        isActive: currentStep.name === 'On water',
        portalDataAPICtrl,
        stockholding: submission,
        countries: countries || [],
    });
    
    const submitCtrl = usePageSubmit({
        focusedFieldCtrl,
        goToStep,
        isActive: currentStep.name === 'Submit',
        stockholding: submission,
        viewMode: mode,
        importCtrl: importCtrl,
        declarationMessage,
        expiredProductsWithData
    });

    useSubmissionProcessingOverlay({submissionStatus: statusToSubmissionStatus(status)})

    const currentStockType = useMemo(() => {
        switch (currentStep.name) {
            case 'Australia': return domesticCtrl.stockType;
            case 'On water': return onWaterCtrl.stockType;
            case 'Overseas': return overseasCtrl.stockType;
            default: return;
        }
    }, [currentStep.name, domesticCtrl, onWaterCtrl, overseasCtrl]);

    const hasUnsavedChanges = useMemo(() => {
        return status === 'idle' && (
            domesticCtrl.changedState === 'unsaved_changes' ||
            overseasCtrl.changedState === 'unsaved_changes' ||
            onWaterCtrl.changedState === 'unsaved_changes' ||
            submitCtrl.changedState === 'unsaved_changes'
        );
    }, [domesticCtrl.changedState, overseasCtrl.changedState, onWaterCtrl.changedState, submitCtrl.changedState, status]);

    const pageHasUnsavedChanges = useMemo(() => {
        if (status !== 'idle') {
            return false;
        }

        switch (currentStep.name) {
            case 'Australia': return domesticCtrl.changedState === 'unsaved_changes';
            case 'On water': return onWaterCtrl.changedState === 'unsaved_changes';
            case 'Overseas': return overseasCtrl.changedState === 'unsaved_changes';
            case 'Submit': return submitCtrl.changedState === 'unsaved_changes';
            default: return false;
        }
    }, [currentStep.name, domesticCtrl, onWaterCtrl, overseasCtrl, submitCtrl, status]);

    const navPrompt = useConfirmNavigation({when: pageHasUnsavedChanges});

    const stepsHasData = useMemo(() => {
        return [
            // Australia
            domesticCtrl.view.hasData,

            // OS
            overseasCtrl.view.hasData,

            // On water
            onWaterCtrl.view.hasData,

            // Submit
            true
        ]
    }, [domesticCtrl.view.hasData, overseasCtrl.view.hasData, onWaterCtrl.view.hasData]);

    const stepLabels = useMemo(() => {
        return STEPS.map((s, index) => ({
            label: <BoxedSpan box={{alignItems: 'center', flex: 'column'}}>
                <Text>{s.name === 'Submit' ? 'Declaration' : s.name}</Text>
                {
                    !stepsHasData[index] && currentStep.index !== index &&
                    <Text>(No data)</Text>
                }
            </BoxedSpan>
        }))
    }, [currentStep, stepsHasData]);

    const progressSteps = useMemo(() => {
        if (isPopulatedStockholding(submission)) {
            const {submissionFormData: formData} = submission;
            return [
                {Label: stepLabels[0]?.label, status: mode === 'view' ? 'complete' as 'complete' :
                    (formData.australiaPageSaved ? 'complete' as 'complete' : 'pending' as 'pending'), label: STEPS[0].name},
                {Label: stepLabels[1]?.label, status: mode === 'view' ? 'complete' as 'complete' :
                    (formData.overseasPageSaved ? 'complete' as 'complete' : 'pending' as 'pending'), label: STEPS[1].name},
                {Label: stepLabels[2]?.label, status: mode === 'view' ? 'complete' as 'complete' :
                    (formData.onWaterPageSaved ? 'complete' as 'complete' : 'pending' as 'pending'), label: STEPS[2].name},
                {Label: stepLabels[3]?.label, status: mode === 'view' ? 'complete' as 'complete' : ('pending' as 'pending'), label: STEPS[3].name},
            ].map((s, i) => i === currentStep.index ? ({...s, status: 'active' as 'active'}) : s);
        }

        return [];
    }, [currentStep, mode, stepLabels, submission]);

    const pageMessages = useMemo(() => {
        switch (currentStep.name) {
            case 'Australia':
                return domesticCtrl.view.messages;
            case 'Overseas':
                return overseasCtrl.view.messages;
            case 'On water':
                return onWaterCtrl.view.messages;
            case 'Submit':
                return submitCtrl.view.messages;
            default:
                return {
                    errors: [],
                    infos: [],
                }
        }
    }, [currentStep.name, domesticCtrl.view.messages, overseasCtrl.view.messages, onWaterCtrl.view.messages, submitCtrl.view.messages]);

    const checkValidation = useCallback(() => {
        setValidationChecked(true);
        if (status !== 'idle') {
            return false;
        }

        let stepHasErrors = false;
        switch (currentStep.name) {
            case 'Australia': stepHasErrors = domesticCtrl.view.hasValidationErrors; break;
            case 'Overseas': stepHasErrors = overseasCtrl.view.hasValidationErrors; break;
            case 'On water': stepHasErrors = onWaterCtrl.view.hasValidationErrors; break;
            default: {}
        }

        if (stepHasErrors) {
            setTimeout(() => {
                // Scroll to first validation error, if exists
                const validationMessageEl = document.querySelector('[data-validation-notification]');
                if (validationMessageEl != null) {
                    validationMessageEl.scrollIntoView({block: 'center', behavior: 'smooth'})
                }
            }, 0);
        }

        return !stepHasErrors;
    }, [status, currentStep.name, domesticCtrl.view.hasValidationErrors, overseasCtrl.view.hasValidationErrors, onWaterCtrl.view.hasValidationErrors]);

    const hasInfoMessages = useCallback(() => {
        let isCommentsRequired = false;
        switch (currentStep.name) {
            case 'Australia': isCommentsRequired = domesticCtrl.view.isCommentsRequired; break;
            case 'Overseas': isCommentsRequired = overseasCtrl.view.isCommentsRequired; break;
            case 'On water': isCommentsRequired = onWaterCtrl.view.isCommentsRequired; break;
            case 'Submit': isCommentsRequired = submitCtrl.view.isCommentsRequired; break;
            default: {}
        }
        return isCommentsRequired;
    }, [currentStep.name, domesticCtrl.view.isCommentsRequired, onWaterCtrl.view.isCommentsRequired, overseasCtrl.view.isCommentsRequired, submitCtrl.view.isCommentsRequired]);

    // Attempt to go to step (i.e. if validation succeeds)
    const attemptGoToStep = useCallback((step: number) => {
        if (mode === 'edit') {
            // Don't validate submit page - user may be trying to navigate to specific
            // page causing the error
            if (!isSubmitStep(stepIndex) && !checkValidation()) {
                setRequestedStepIndex(null);
                return;
            }
            setRequestedStepIndex(step);
            if (pageHasUnsavedChanges) {
                setUnsavedChangesStatus('showing_dialog');
                return;
            } else if (hasInfoMessages()) {
                setReviewAlertStatus('showing_dialog');
                return;
            }
        }
        goToStep(step);
    }, [checkValidation, goToStep, hasInfoMessages, pageHasUnsavedChanges, mode, stepIndex]);

    const handleAlertDialogContinue = useCallback(() => {
        setReviewAlertStatus('hide_dialog');
        
        if (requestedStepIndex != null) {
            goToStep(requestedStepIndex);
        }
    }, [goToStep, requestedStepIndex]);

    const handleAlertDialogCancel = useCallback(() => {
        setReviewAlertStatus('hide_dialog');
        setRequestedStepIndex(null);
        attemptFocusOnSelector(`${SELECTOR_NOTIFICATION('info')}`);
    }, []);

    const doImportTemplateSaved = useMemo(() => {
        return mode !== 'view' && importCtrl && importCtrl.templateImportState.templateImportDialogState === 'processing';
    }, [importCtrl, mode]);
    
    const save = useCallback((nextStep: number) => {
        setSystemMessages([]);
    
        if (!checkValidation()) {
            return;
        }

        setStatus('saving');
        setRequestedStepIndex(nextStep);
        
        let promise: Promise<StockholdingSubmissionUpdateResponse | null> | null = null;


        const trackPageView = loggerRef.current.startTrackPage('Save - Stockholding');

        switch (currentStep.name) {
            case 'Australia': {
                promise = api.updateStockholdingDomestic({
                    requestBody: {
                        ...domesticCtrl.getUpdateRequestBody(),
                        isTemplateImport: doImportTemplateSaved ? true : null
                    }
                });
                break;
            }

            case 'Overseas': {
                promise = api.updateStockholdingOverseas({
                    requestBody: {
                        ...overseasCtrl.getUpdateRequestBody(),
                        isTemplateImport: doImportTemplateSaved ? true : null
                    }
                });
                break;
            }

            case 'On water': {
                promise = api.updateStockholdingOnWater({
                    requestBody: {
                        ...onWaterCtrl.getUpdateRequestBody(),
                        isTemplateImport: doImportTemplateSaved ? true : null
                    }
                });
                break;
            }

            case 'Submit': {
                const requestBodyEncoded = {
                    ...submitCtrl.updateDataSubmission,
                    isTemplateImport: doImportTemplateSaved ? true : null,
                    comments: encodeEscapeChars(submitCtrl.updateDataSubmission.comments?.trim())
                };

                promise = api.updateStockholdingDataSubmission({
                    requestBody: requestBodyEncoded,
                });
                break;
            }
            default: {}
        }

        if (promise != null) {
            promise
                .then((response) => {
                    trackPageView();
                    if (navPrompt.showPrompt) {
                        setStatus('idle')
                        navPrompt.handleDecision(true);
                    } else {
                        setUpdateResult(getUpdateResult(response, currentStep.name, currentStockType));
                    }
                    
                    importCtrl.saveStep(currentStep.name as string);
                })
                .catch(e => {
                    setRequestedStepIndex(null);

                    if (isWithErrorMessages(e)) {
                        setSystemMessages(e.body.errorMessages);
                    } else if (isWithValidationMessages(e)) {
                        setSystemMessages(e.body.validationMessages
                            .filter(msg => msg.errorMessage != null)
                            .map(msg => msg.errorMessage as string)
                        );
                    } else if (isForbiddenError(e)) {
                        setSystemMessages([ERR_FORBIDDEN_SAVE_SUBMISSION]);
                    } else {
                        setSystemMessages([ERR_UNKNOWN_SAVE_SUBMISSION]);
                    }
                    if (navPrompt.showPrompt) {
                        navPrompt.handleDecision(false);
                    }
                    setStatus('idle');
                })
        } else {
            if (navPrompt.showPrompt) {
                navPrompt.handleDecision(false);
            }
            setStatus('idle');
        }
    }, [doImportTemplateSaved, checkValidation, currentStep.name, api, loggerRef, domesticCtrl, overseasCtrl, onWaterCtrl, submitCtrl.updateDataSubmission, navPrompt, importCtrl, currentStockType])

    const clearAllData = useCallback(() => {        
        setSystemMessages([]);

        setStatus('saving');
        api.clearAllStockholding({requestBody: {id: submission.dataSubmission.id, concurrencyToken: submission.dataSubmission.concurrencyToken}})
            .then(response => {
                setUpdateResult(getUpdateResult(response, currentStep.name, currentStockType));
                submitCtrl.updateDeclaration(false);
            })
            .catch(e => {
                setRequestedStepIndex(null);

                if (isWithErrorMessages(e)) {
                    setSystemMessages(e.body.errorMessages);
                } else if (isWithValidationMessages(e)) {
                    setSystemMessages(e.body.validationMessages
                        .filter(msg => msg.errorMessage != null)
                        .map(msg => msg.errorMessage as string)
                    );
                } else if (isForbiddenError(e)) {
                    setSystemMessages([ERR_FORBIDDEN_SAVE_SUBMISSION]);
                } else {
                    setSystemMessages([ERR_UNKNOWN_SAVE_SUBMISSION]);
                }

                attemptScrollToSelector(SELECTOR_NOTIFICATION_KIND('system_alert'));
            })
            .finally(() => {
                setStatus('idle');
                importCtrl.reset();
            });
    }, [api, currentStep.name, currentStockType, importCtrl, submission.dataSubmission.concurrencyToken, submission.dataSubmission.id, submitCtrl]);

    const clearDataCtrl = useClearData({onConfirm: clearAllData, submission: submission.dataSubmission});
    
    const handleProgressNav = useCallback((step: number) => {
        // TODO: make it clearer to user why navigation failed
        attemptGoToStep(step);
    }, [attemptGoToStep]);

    const handlePrevious = useCallback(() => {
        const nextStep = stepIndex - 1;
        if (mode === 'view') {
            goToStep(nextStep);
            return;
        }

        save(nextStep);
    }, [goToStep, mode, save, stepIndex]);

    const handleSaveAndContinue = useCallback(() => {
        const nextStep = stepIndex + 1;
        if (mode === 'view') {
            goToStep(nextStep);
            return;
        }

        save(nextStep);
    }, [goToStep, mode, stepIndex, save]);

    const handleSubmit = useCallback(() => {
        if (!submitCtrl.declaration) {
            setDeclarationMessage(INVALID_DECLARATION);
            return;
        }

        if (submitCtrl.isSameAsPrevSubmission && isEmpty(submitCtrl.view.comments.comment)) {
            return;
        }

        setSystemMessages([]);

        if (!checkValidation()) {
            return;
        }

        setStatus('submitting');

        const requestBodyEncoded = {
            ...submitCtrl.updateDataSubmission,
            comments: encodeEscapeChars(submitCtrl.updateDataSubmission.comments)
        };

        api.submitStockholding({requestBody: requestBodyEncoded})
            .then(response => setSubmissionResponse(response))
            .catch(e => {
                setRequestedStepIndex(null);

                if (isWithErrorMessages(e)) {
                    setSystemMessages(e.body.errorMessages);
                } else if (isWithValidationMessages(e)) {
                    setSystemMessages(e.body.validationMessages
                        .filter(msg => msg.errorMessage != null)
                        .map(msg => msg.errorMessage as string)
                    );
                } else if (isForbiddenError(e)) {
                    setSystemMessages([ERR_FORBIDDEN_SAVE_SUBMISSION]);
                } else {
                    setSystemMessages([ERR_UNKNOWN_SAVE_SUBMISSION]);
                }
                setSubmissionResponse(e.body);
            });
    }, [api, checkValidation, submitCtrl.declaration, submitCtrl.isSameAsPrevSubmission, submitCtrl.updateDataSubmission, submitCtrl.view.comments.comment]);
    
    const handleUnsavedChangesDialogCancel = useCallback(() => {
        setUnsavedChangesStatus('idle');
        if (navPrompt.showPrompt) {
            navPrompt.handleDecision(false);
        }
    }, [navPrompt]);

    const handleUnsavedChangesDialogSave = useCallback(() => {
        setUnsavedChangesStatus('idle');
        // Just use 0 as placeholder for page navigation requests
        save(requestedStepIndex || 0);
    }, [requestedStepIndex, save]);

    const handleUnsavedChangesDialogProceedWithoutSave = useCallback(() => {
        setUnsavedChangesStatus('idle');
        if (navPrompt.showPrompt) {
            navPrompt.handleDecision(true);
        } else {
            if (requestedStepIndex != null) {                   
                importCtrl.saveStep(currentStep.name as string);             
                goToStep(requestedStepIndex)
            }
        }
    }, [currentStep.name, goToStep, importCtrl, navPrompt, requestedStepIndex]);

    useEffect(() => {
        if(updateResult != null) {
            setStatus('processing');
        }
    }, [updateResult]);

    // Handle update result change
    useEffect(() => {
        if (status !== 'processing') {
            return;
        }

        if (updateResult == null) {
            setStatus('idle');
            return;
        }

        // Update submission source-of-truth
        if (isUpdateResponseStatus(updateResult.responseStatus) && isPopulatedStockholding(updateResult.stockholdingSubmission)){
            setSubmission(updateResult.stockholdingSubmission);
        }

        if (updateResult.responseStatus !== 'saved_full' && requestedStepIndex !== null) {
            setRequestedStepIndex(null);
        }

        setTimeout(() => {
            setStatus('idle');
        });

        // Apply any system messages
        const notOkRecordResultMessages = updateResult.recordResults
            .filter(rr => !isSuccesfulRowResult(rr) && rr.message != null)
            .map(rr => mapToUserMessage(rr));

        if (notOkRecordResultMessages.length > 0) {
            setSystemMessages(uniq(notOkRecordResultMessages));
            return;
        }

        const validationMessages = updateResult.validationMessages
            .filter(msg => msg.errorMessage != null)
            .map(msg => msg.errorMessage as string);
        if (validationMessages.length > 0) {
            setSystemMessages(validationMessages);
            return;
        }

        const errorMessages = updateResult.errorMessages
            .filter(msg => msg != null)
            .map(msg => msg as string);
        if (errorMessages.length > 0) {
            setSystemMessages(errorMessages);
            return;
        }
    }, [importCtrl, requestedStepIndex, status, updateResult]);

    // Handle submission change with respect to navigation
    useEffect(() => {
        if (submission == null || requestedStepIndex == null || status !== 'idle') {
            return;
        }

        attemptGoToStep(requestedStepIndex);
    }, [attemptGoToStep, requestedStepIndex, status, submission]);

    // Handle submission response change
    useEffect(() => {
        if (submissionResponse == null) {
            return;
        }

        if (submissionResponse.result?.dataSubmission?.recordResult != null) {
            if (!isSuccesfulRowResult(submissionResponse.result.dataSubmission.recordResult as RecordResult, true)) {
                setStatus('idle');
                return;
            }
        }

        if (submissionResponse.isSuccessful) {
            setStatus('submitted');
        } else {
            if (submissionResponse.result && submissionResponse.result !== null ) {
                setSubmission(submissionResponse.result as Stockholding);
            }
        }
    }, [submissionResponse]);

    // Ensure submitting status is canceled on server errors
    useEffect(() => {
        if (status === 'submitting' && systemMessages.length > 0) {
            setStatus('idle');
        }
    }, [systemMessages.length, status]);

    useEffect(() => {
        if (status === 'submitted') {
			nav.goToReportSubmittedPage(asNumber(submission.dataSubmission.id));
        }
    }, [nav, status, submission]);

    useEffect(() => {
        if (submitCtrl.declaration) {
            setDeclarationMessage(null);
        }
    }, [submitCtrl.declaration]);

    // Resume submission at earliest incomplete page
    useEffect(() => {
        if (mode === 'edit' && isPopulatedStockholding(submission) && haveSetInitialPage.current === false) {
            haveSetInitialPage.current = true;
            const {submissionFormData: formData} = submission;
            if (!formData.australiaPageSaved || submission.dataSubmission.status === 'Action required') {
                return;
            }

            if (!formData.overseasPageSaved) {
                goToStep(PAGE_INDEX[1].pageIndex);
                return;
            }

            if (!formData.onWaterPageSaved) {
                goToStep(PAGE_INDEX[2].pageIndex);
                return;
            }

            goToStep(PAGE_INDEX[3].pageIndex);
        }
    }, [goToStep, mode, submission]);

    // Show dialog if nav prompt detects browser navigation
    useEffect(() => {
        if (navPrompt.showPrompt) {
            setUnsavedChangesStatus('showing_dialog');
        } else {
            setUnsavedChangesStatus('idle');
        }
    }, [navPrompt.showPrompt]);

    // Reset validation check flag on page change
    useEffect(() => {
        setValidationChecked(false);
    }, [currentStep.index]);

    return {
        domesticCtrl,
        overseasCtrl,
        onWaterCtrl,
        submitCtrl,
        clearDataCtrl,
        importCtrl,
        currentStep,
        countries,
        disableInputs: mode === 'view',
        handlePrevious,
        handleProgressNav,
        handleSaveAndContinue,
        handleSubmit,
        handleAlertDialogCancel,
        handleAlertDialogContinue,
        handleUnsavedChangesDialogCancel,
        handleUnsavedChangesDialogSave,
        handleUnsavedChangesDialogProceedWithoutSave,
        hasUnsavedChanges,
        reviewAlertStatus,
        mode,
        pageMessages,
        progressSteps,
        systemMessages,
        status,
        stepLabels,
        submission,
        unsavedChangesStatus,
        validationChecked,
    };
}

export default useStockholding;

export type UseStockholding = ReturnType<typeof useStockholding>;

function getUpdateResult(
    response: StockholdingSubmissionUpdateResponse | null,
    currentPage: PageKey,
    stockType: StockType | undefined
): StockholdingUpdateSubmissionResult {
    const apiResponseStatus = getResponseStatus(response);
    const errorMessages = response?.errorMessages || []
    const validationMessages = response?.validationMessages || [];

    if (apiResponseStatus !== 'successful') {
        return {
            errorMessages,
            recordResults: [],
            responseStatus: apiResponseStatus,
            stockholdingSubmission: undefined,
            validationMessages,
        };
    }

    if (!isAPIResponseWithResult(response)) {
        return {
            errorMessages,
            recordResults: [],
            responseStatus: 'unknown',
            stockholdingSubmission: undefined,
            validationMessages,
        };
    }

    const recordResults = [
        ...(currentPage === 'Australia' ? (response.result.domesticStockholdings || []).map(row => row.recordResult) : []),
        ...(currentPage === 'Overseas' ? (response.result.overseasStockholdings || []).map(row => row.recordResult) : []),
        ...(currentPage === 'On water' ? ([
            ...(response.result.onWaterStockholdings || []).map(row => row.recordResult),
            ...(response.result.onWaterStockholdings || [])
                .map(row => row.stockholdingOnWaterPorts || [])
                .reduce((memo, ports) => [...memo, ...ports], [])
                .map(port => port.recordResult)
        ]) : []),
        ...(response.result.stockholdingComments || [])
            .filter(c => c.stockTypeId === stockType?.id)
            .map(c => c.recordResult),
        ...(response.result.dataSubmission?.recordResult ? [response.result.dataSubmission.recordResult] : []),
    ].filter(r => r != null) as Array<RecordResult>;

    return {
        errorMessages,
        recordResults,
        responseStatus: getAggregatedUpdateStatus(recordResults),
        stockholdingSubmission: response.result,
        validationMessages,
    };
}

function isUpdateResponseStatus(responseStatus: ResponseStatus) {
    switch (responseStatus) {
        case 'saved_full':
        case 'saved_partial':
        case 'successful':
            return true;
        default:
            return false;
    }
}

// TODO: naive mapping - ideally port the standard API controller pattern from 
// other DS types to STK in the near future
function statusToSubmissionStatus(status: Status): SubmissionStatus {
    switch (status) {
        case 'saving':
            return 'updating';

        case 'processing':
            return 'fetching';

        case 'idle':
            return 'init';

        default:
            return status;
    }
}

function isSubmitStep(stepIndex: number) {
    return stepIndex === 3;
}
