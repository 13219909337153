import React from 'react';
import BaseSVG, { SVGProps } from './_base';

const ImportFile = (props: SVGProps) => {
	return (
		<BaseSVG name='import-file' viewBox='0 0 16 16' {...props}>
            <path d="M0,16V9H2v5H14V9h2v7ZM3.354,7.06,4.768,5.647,7,7.879V0H9V8l2.353-2.353L12.767,7.06,8.06,11.768Z" fill="currentcolor"/>
		</BaseSVG>
	);
};

export default ImportFile;