import { INVALID_HELP_TEXT_LENGTH } from "psims/constants/validation-messages";
import { isEmpty } from "psims/lib/empty";
import { isValidStringLength } from "psims/lib/validation/string";

export function validateHelpText(val: string | null | undefined) {
    const trimmedVal = (val || '').trim();
    if (trimmedVal == null || isEmpty(trimmedVal)) {
      return undefined;
    }

    if (!isValidStringLength(trimmedVal, {min: 0, max: 4000})) {
      return INVALID_HELP_TEXT_LENGTH;

    }

    return undefined;
}