import { MsoProductDto } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

export type MsoProduct = PopulatedProps<MsoProductDto, 'id' | 'displayOrder' | 'name' | 'effectiveFrom'>;

export function assertMsoProduct(maybe?: unknown): asserts maybe is MsoProduct {
    const asMsoProduct = maybe as MsoProduct;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asMsoProduct, hint('msoProduct'));
    assertNotEmpty(asMsoProduct.displayOrder, hint('msoProduct.displayOrder'));
    assertNotEmpty(asMsoProduct.id, hint('msoProduct.id'));
    assertNotEmpty(asMsoProduct.name, hint('msoProduct.name'));
    assertNotEmpty(asMsoProduct.effectiveFrom, hint('msoProduct.effectiveFrom'));
}
