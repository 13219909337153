import React, { useMemo } from 'react';
import styled from 'styled-components';

import { BoxedDiv } from 'psims/react/components/layout';
import Text from 'psims/react/components/text';
import { ApplicationTitle } from 'psims/react/components/typography';
import { APP_NAME, PRIVACY_POLICY_LINK } from 'psims/constants/app';
import SupportLink from 'psims/react/components/support-link';
import { useConfiguration } from 'psims/react/providers/configuration-provider';
import { Configuration } from 'psims/config/env';

interface GlobalFooterProps {
	
};

const StyledLogoLink = styled.a`
	&:focus,
	&:active {
		outline: 1px solid var(--color-white);
		outline-offset: 8px;
	}
`;

const SHOW_BUILD_FOR_ENVS: Array<Configuration['BUILD_ENVIRONMENT']> = [
	'Dev', 'SIT', 'Test', 'UAT'
];

const GlobalFooter = (props: GlobalFooterProps) => {
	const config = useConfiguration();

	const buildInfo = useMemo(() => {
		if (
			config === null ||
			config.configuration == null ||
			!config.configuration.BUILD_ENVIRONMENT ||
			!SHOW_BUILD_FOR_ENVS.includes(config.configuration.BUILD_ENVIRONMENT)
		) {
			return '';
		}

		return `Version: ${config.configuration.BUILD_VERSION} - ${config.configuration.BUILD_ENVIRONMENT}`;
	}, [config]);

	return (
		<footer>
			<BoxedDiv box={{background: 'primary', marginTop: 6, paddingV: 4}}>
				<BoxedDiv className='container' box={{flex: 'row'}}>
					<BoxedDiv box={{flex: 'row',  justifyContent: 'space-between', flexGrow: 1}}>
						<a href='https://www.industry.gov.au/accessibility' rel='noreferrer' target='_blank'><Text $color='white'>Accessibility</Text></a>
						<a href={PRIVACY_POLICY_LINK} rel='noreferrer' target='_blank'><Text $color='white'>Privacy</Text></a>
						<a href='https://www.industry.gov.au/disclaimer' rel='noreferrer' target='_blank'><Text $color='white'>Disclaimer</Text></a>
						<SupportLink><Text $color='white'>Contact us</Text></SupportLink>
					</BoxedDiv>
					<BoxedDiv box={{flex: 'row',  justifyContent: 'flex-end' , flexGrow: 1}}>
						<a href="tel:1800955830"><Text $color='white'>Call us on 1800 955 830</Text></a>
					</BoxedDiv>
				</BoxedDiv>
			</BoxedDiv>

			<BoxedDiv box={{background: 'primary-dark', paddingV: 4}}>
				<BoxedDiv className='container' box={{flex: 'column'}}>
					<BoxedDiv box={{alignItems: 'center', flex: 'row'}}>
						<StyledLogoLink href='https://www.industry.gov.au' rel='noreferrer' title='Go to Department of Industry, Science and Resources home page'>
							<img
								alt='Go to Department of Industry, Science and Resources home page'
								className='global-header_diser-logo'
								src='/images/diser_logo_jul_2022.png' 
								height={80}/>
							
						</StyledLogoLink>

						<BoxedDiv box={{alignSelf: 'stretch', background: 'primary-mid', marginH: 3, marginV: 4, width: '1px'}}></BoxedDiv>

						<BoxedDiv box={{alignItems: 'center', flex: 'row'}}>
							<ApplicationTitle>
								{APP_NAME}
							</ApplicationTitle>
						</BoxedDiv>
					</BoxedDiv>
					<BoxedDiv box={{alignItems: 'center', flex: 'row', flexGrow: 1}}>
						<Text $color='white'>We recognise the First Peoples of this nation and their ongoing connection to culture and country. We acknowledge First Nations Peoples as the Traditional Owners, Custodians and Lore Keepers of the world's oldest living culture and pay respects to their Elders past, present and emerging.</Text>
					</BoxedDiv>
					<BoxedDiv box={{alignItems: 'center', flex: 'row', flexGrow: 1, justifyContent: 'space-between', marginTop: 4}}>
						<Text $color='white'>&copy; Commonwealth of Australia.</Text>

						<Text $color='white' $size={14}>{buildInfo}</Text>
					</BoxedDiv>
				</BoxedDiv>
			</BoxedDiv>
		</footer>
	);
};

export default GlobalFooter;
