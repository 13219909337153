import { isArrayOfType } from "psims/lib/collections";
import { DataSubmission, PopulatedDataSubmission } from "psims/models/data-submission"

export type FspPreconditionNotMet = {
  refiningDataSubmissions: Array<DataSubmission<'Refining'>>;
  fsspSubmission: PopulatedDataSubmission<'FSSP s19A'>;
}

type RefiningSubmission = {
  id: number;
  submissionTypeName: "Refining",
  reportingPeriodFrom?: string | undefined;
}

function isRefiningSubmission(maybe?: unknown): maybe is RefiningSubmission {
  const maybeAs = maybe as RefiningSubmission;

  return (
    maybeAs != null &&
    maybeAs.id != null &&
    maybeAs.reportingPeriodFrom != null &&
    maybeAs.submissionTypeName === 'Refining'
  );
}

export function isFspPreconditionNotMet(maybe?: unknown): maybe is FspPreconditionNotMet {
  const maybeAs = maybe as FspPreconditionNotMet;

  const result = (
    maybeAs != null &&
    maybeAs.fsspSubmission != null &&
    maybeAs.fsspSubmission.submissionTypeName === 'FSSP s19A' &&
    maybeAs.refiningDataSubmissions != null &&
    isArrayOfType(isRefiningSubmission, maybeAs.refiningDataSubmissions)
  );

  return result;
}
