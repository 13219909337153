import { MsoAnnualActivityImporterSubmissionVM } from "psims/gen/xapi-client";
import { prefixedThrower } from "psims/lib/exception";
import { isDataSubmission, isSubmittedDataSubmission, PopulatedDataSubmission } from "psims/models/data-submission";
import { isRecordResult, RecordResult } from "psims/models/api/record-result";
import { assertMsoAnnualActivitySubmissionFormData } from "../mso-annual-activity-submission-form-data";
import { assertMsoStorageSite, MsoStorageSite } from "../mso-storage-site";
import { assertMsoAnnualActivityImporter, MsoAnnualActivityImporter } from "./mso-annual-activity-importer";
import { assertMsoStockholdingImport, MsoStockholdingImport } from "./mso-stockholding-import";

export type MsoAnnualActivityImporterSubmission = PopulatedProps<
  MsoAnnualActivityImporterSubmissionVM,
  'annualActivities' | 'dataSubmission' | 'storageSites' | 'submissionFormData' | 'imports' | 'msoComment'
> & {
  annualActivities: Array<MsoAnnualActivityImporter>;
  dataSubmission: PopulatedDataSubmission<'MSO importer'>;
  imports: Array<MsoStockholdingImport>;
  storageSites: Array<MsoStorageSite>;
};


export function assertMsoAnnualActivityImporterSubmission(maybe?: unknown): asserts maybe is MsoAnnualActivityImporterSubmission {
  const maybeAs = maybe as MsoAnnualActivityImporterSubmission;

  const assertionThrower = prefixedThrower('Failed to assert msoAnnualActivityImporterSubmission: ');

  if (maybeAs == null) {
    assertionThrower('argument is null');
  }

  if (!isDataSubmission(maybeAs.dataSubmission, 'MSO importer')) {
    assertionThrower('dataSubmission assertion failed');
  }

  try {
    assertMsoAnnualActivitySubmissionFormData(maybeAs.submissionFormData);
  } catch (e) {
    assertionThrower(`submissionFormData assertion failed: ${e}`);
  }

  if (!isDataSubmission(maybeAs.dataSubmission, 'MSO importer')) {
    assertionThrower('dataSubmission assertion failed');
  }

  if (maybeAs.annualActivities == null) {
    assertionThrower('annualActivities is null');
  }

  maybeAs.annualActivities.forEach(assertMsoAnnualActivityImporter);

  if (maybeAs.storageSites == null) {
    assertionThrower('storageSites is null');
  }

  maybeAs.storageSites.forEach(assertMsoStorageSite);

  if (maybeAs.imports == null) {
    assertionThrower('imports is null');
  }

  maybeAs.imports.forEach(assertMsoStockholdingImport);
}

export function isMsoAnnualActivityImporterSubmission(maybe?: unknown): maybe is MsoAnnualActivityImporterSubmission {
  try {
    assertMsoAnnualActivityImporterSubmission(maybe);
    return true
  } catch (e) {
    return false;
  }
}

export function assertMsoAnnualActivityImporterSubmitted(maybe?: unknown): asserts maybe is MsoAnnualActivityImporterSubmission {
  assertMsoAnnualActivityImporterSubmission(maybe);
  if (!isSubmittedDataSubmission(maybe.dataSubmission)) {
    throw new Error(`Failed to assert isSubmitted for dataSubmission: ${JSON.stringify(maybe.dataSubmission)}`)
  }
}

export function isMsoAnnualActivityImporterSubmitted(maybe?: unknown): maybe is MsoAnnualActivityImporterSubmission {
  try {
    assertMsoAnnualActivityImporterSubmitted(maybe);
    return true;
  } catch (e) {
    return false;
  }
}

export function getRecordResults(maybe?: unknown): Array<RecordResult> {
  assertMsoAnnualActivityImporterSubmission(maybe);

    return [
        ...[maybe.dataSubmission?.recordResult].filter(isRecordResult),
        ...[maybe.msoComment?.recordResult].filter(isRecordResult),
        ...(maybe.annualActivities || []).map(x => x.recordResult).filter(isRecordResult),
        ...(maybe.storageSites || []).map(s => s.recordResult).filter(isRecordResult),
        ...(maybe.imports || []).map(s => s.recordResult).filter(isRecordResult),
    ];
}
