import { ProductionProductGroup } from "./production-product-group";

const PLT_GROUP_REF_CODES = [
    'PRO-PP-PP',
    'PRO-PP-PLM',
    'PRO-PP-NGL',
    'PRO-PP-NG',
    'PRO-PP-PB',
] as const;

type PltGroupReferenceCode = typeof PLT_GROUP_REF_CODES[number];

type PlantProductionGroup = PopulatedProps<
    ProductionProductGroup & {
        referenceCode: PltGroupReferenceCode;
    }, 'id' | 'displayOrder' | 'isActive' | 'name' | 'referenceCode'
>;

export function isPlantProductionGroup(maybe?: unknown): maybe is PlantProductionGroup {
    const maybeAs = maybe as PlantProductionGroup;
    return maybeAs != null && 
    typeof maybeAs.id === 'number' &&
    maybeAs.productionTypeName === 'Plant production' &&
    maybeAs.name != null
}

type PltProductionGroupRefCodeName = 'ng' | 'prop' | 'but' | 'lpg' | 'ngl';

const PLT_PRODUCTION_GROUP_REF_CODE_MAP: { [key in PltProductionGroupRefCodeName]: PltGroupReferenceCode } = {
    but: 'PRO-PP-PB',
    lpg: 'PRO-PP-PLM',
    ng: 'PRO-PP-NG',
    ngl: 'PRO-PP-NGL',
    prop: 'PRO-PP-PP',
    // Test
    // ico: 'PRO-PP-ICO',
}

export function getPlantProductionGroupReferenceCode(key: PltProductionGroupRefCodeName) {
  return PLT_PRODUCTION_GROUP_REF_CODE_MAP[key];
}