import { DataSubmission, DataSubmissionTypeName } from "psims/models/data-submission";
import { UpdateDataSubmission } from "psims/models/data-submission";

function transformToUpdateDataSubmission<TKind extends DataSubmissionTypeName>(dataSubmission: DataSubmission<TKind>): UpdateDataSubmission {
    return {
        comments: dataSubmission.comments,
        concurrencyToken: dataSubmission.concurrencyToken,
        id: dataSubmission.id,
    }
}

export default transformToUpdateDataSubmission;
