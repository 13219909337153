import { useCallback, useEffect, useMemo, useState } from "react";

import useConfirmNavigation from "psims/react/util/use-confirm-navigation";
import { ViewMode } from "../../shared/use-view-mode";
import useDataSubmissionSaveCheck from "../../shared/use-data-submission-save-check";
import { attemptScrollToSelector } from "psims/react/pages/primary-pages/data-submissions/shared/view-utils";
import { UseSubmit } from "../../shared/use-submit";
import { SELECTOR_NOTIFICATIONS } from "psims/constants/selectors";
import { UseImporterAnnualActivityAPI } from "./use-importer-annual-activity-api";
import { UseImporterAnnualActivityProgress } from "./use-importer-annual-activity-progress";
import { any } from "psims/lib/collections";
import { UseStorageSites } from "../shared/storage-sites/use-storage-sites";
import { UseImporterAnnualActivities } from "./annual-activities/use-importer-annual-activities";
import { UseMsoComment } from "../shared/mso-comment/use-mso-comment";
import { UseImporterImports } from "./imports/use-importer-imports";
import { isEmpty } from "psims/lib/empty";

/**
 * Controller to manage in-form and browser navigation, e.g. unsaved
 * change checking and validation alert dialogs where necessary.
 */
interface UseImporterAnnualActivityNavigationProps {
    annualActivitiesCtrl: UseImporterAnnualActivities;
    apiCtrl: UseImporterAnnualActivityAPI;
    commentsCtrl: UseMsoComment;
    importsCtrl: UseImporterImports;
    pageChanges: Array<Array<boolean>>;
    progressCtrl: UseImporterAnnualActivityProgress;
    submitCtrl: UseSubmit;
    storageSitesCtrl: UseStorageSites;
    viewMode: ViewMode;
}

function useImporterAnnualActivityNavigation({
    annualActivitiesCtrl,
    apiCtrl,
    commentsCtrl,
    importsCtrl,
    pageChanges,
    progressCtrl,
    storageSitesCtrl,
    submitCtrl,
    viewMode
}: UseImporterAnnualActivityNavigationProps) {
    const hasUnsavedChanges = useMemo(() => {
        const changes = pageChanges[progressCtrl.currentStep.index];
        if (changes != null) {
            return any(changes, c => c)
        }

        return false;
    }, [pageChanges, progressCtrl.currentStep.index]);

    const saveCheckCtrl = useDataSubmissionSaveCheck({
        apiCtrl,
        hasUnsavedChanges,
        updateDataSubmission: submitCtrl.dataSubmissionUpdate,
        updateSubmission: {
            dataSubmissionId: apiCtrl.submission?.dataSubmission.id,
            annualActivities: annualActivitiesCtrl.requestEntities,
            imports: importsCtrl.requestEntities,
            msoComment: commentsCtrl.requestPayload,
            storageSites: storageSitesCtrl.requestEntities,
        }
    });

    const browserNavCtrl = useConfirmNavigation({when: hasUnsavedChanges && viewMode === 'edit'});
    const [lastSavedPageIndex, setLastSavedPageIndex] = useState<number | null>(null);
    const [lastSaveAttemptIndex, setLastSaveAttemptIndex] = useState<number | null>(null);

    const isPageValid = useMemo(() => {
        switch (progressCtrl.currentStep.index) {
            case 0: {
                return [
                    ...annualActivitiesCtrl.updateAnnualActivities.filter(aa => !isEmpty(aa.validations)),
                    ...storageSitesCtrl.updateStorageSites.filter(ss => !isEmpty(ss.validations)),
                    ...importsCtrl.updateImports.filter(imp => !isEmpty(imp.validations)),
                    ...(isEmpty(commentsCtrl.validations) ? [] : [commentsCtrl]),
                ].length === 0
            }

            case 1: {
                return isEmpty(submitCtrl.validationMessages.comments.validationMessages);
            }

            default: return true;
        }
    }, [progressCtrl.currentStep.index, annualActivitiesCtrl, importsCtrl, storageSitesCtrl, commentsCtrl, submitCtrl])

    useEffect(() => {
        if (browserNavCtrl.showPrompt && saveCheckCtrl.saveState === 'idle') {
            saveCheckCtrl.checkAndConfirm(
                progressCtrl.currentStep.kind === 'data' ? 'submission' : 'data_submission',
                () => browserNavCtrl.handleDecision(true),
                () => browserNavCtrl.handleDecision(false)
            )
        }
    }, [browserNavCtrl, progressCtrl, saveCheckCtrl]);

    const unsavedChangesDialogCtrl = useMemo(() => ({
        controlsState: (saveCheckCtrl.saveState === 'saving' ? 'loading' : 'normal') as 'loading' | 'normal',
        isOpen: saveCheckCtrl.saveState === 'confirming',
        onCancel: saveCheckCtrl.cancel,
        onConfirm: () => saveCheckCtrl.save(),
        onSecondary: saveCheckCtrl.proceed,
    }), [saveCheckCtrl]);

    const onSave = useCallback((index: number) => {
        setLastSavedPageIndex(index);
        progressCtrl.goToStep(index);
    }, [progressCtrl]);

    const ensureValid = useCallback(() => {
        if (!isPageValid) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
        }

        return isPageValid;
    }, [isPageValid]);

    const attemptStepNavigation = useCallback((index: number) => {
        if (viewMode !== 'edit') {
            progressCtrl.goToStep(index);
            return;
        }

        if (!ensureValid()) {
            return;
        }

        saveCheckCtrl.checkAndConfirm(
            progressCtrl.currentStep.kind === 'data' ? 'submission' : 'data_submission',
            () => onSave(index),
            () => {}
        )
    }, [ensureValid, onSave, progressCtrl, saveCheckCtrl, viewMode]);

    const saveAndGoToPage = useCallback((index: number) => {
        setLastSaveAttemptIndex(progressCtrl.currentStep.index);
        
        if (!ensureValid()) {
            return;
        }
        saveCheckCtrl.save(progressCtrl.currentStep.kind === 'data' ? 'submission' : 'data_submission', () => onSave(index));
    }, [ensureValid, onSave, progressCtrl, saveCheckCtrl]);

    const attemptSubmit = useCallback(() => {
        submitCtrl.attemptSubmit();
    }, [submitCtrl]);

    // Clear last save attempt on page change
    useEffect(() => {
        setLastSaveAttemptIndex(null);
    }, [progressCtrl.currentStep.index]);

    return {
        lastSaveAttemptIndex,
        lastSavedPageIndex,
        unsavedChangesDialogCtrl,
        attemptStepNavigation,
        attemptSubmit,
        saveAndGoToPage,
    };
}

export default useImporterAnnualActivityNavigation

export type UseImporterAnnualActivityNavigation = ReturnType<typeof useImporterAnnualActivityNavigation>;
