import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "psims/constants/styles";
import { SizeProps, sizeStyle } from "psims/style/size";
import { SpacingProps, spacingStyle } from "psims/style/spacing";
import { PropsWithChildren } from "react";

interface HeadingProps extends PropsWithChildren<SizeProps & SpacingProps> {}

export const LargeTitle = styled.div`${() => `
    font-size: 44px;
    line-height: 56px;
    font-weight: 600;

    @media (max-width: ${MOBILE_BREAKPOINT}) {
        font-size: 24px;
        line-height: 36px;
    }
`}
`;

export const ApplicationTitle = styled.div`${(props: HeadingProps) => `
    font-size: 24px;
    line-height: 56px;
    font-weight: bold;
    padding-top: 48px;
    padding-bottom: 47px;
    color: white;

    @media (max-width: ${MOBILE_BREAKPOINT}) {
        font-size: 24px;
        line-height: 36px;
    }
`}
`;

export const H1 = styled.h1`${(props: HeadingProps) => `
    font-size: 44px;
    line-height: 56px;
    
    @media (max-width: ${MOBILE_BREAKPOINT}) {
        font-size: 32px;
        line-height: 36px;
    }
`}
    ${sizeStyle}
    ${spacingStyle}
`;

const StyledH2 = styled.h2`${(props: HeadingProps) => `
    font-size: 32px;
    line-height: 40px;

    @media (max-width: ${MOBILE_BREAKPOINT}) {
        font-size: 24px;
        line-height: 30px;
    }
`}
    ${spacingStyle}
`;

// export const H2 = (props: HeadingProps) => <StyledH2 {...props} />
export const H2 = StyledH2;
 
export const H3 = styled.h3`${(props: HeadingProps) => `
    font-size: 21px;
    line-height: 28px;

    @media (max-width: ${MOBILE_BREAKPOINT}) {
        line-height: 30px;
    }
`}
    ${sizeStyle}
    ${spacingStyle}
`;

export const H4 = styled.h4`${(props: HeadingProps) => `
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-top: 0;
`}
    ${sizeStyle}
    ${spacingStyle}
`;

export const U = styled.strong`
    font-weight: 400;
    text-decoration: underline;
`;
