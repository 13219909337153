import { UserStatusDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { prefixedThrower } from "psims/lib/exception";

const USER_STATUSES = ['Inactive', 'Invited', 'New', 'Registered'] as const;

type Status = typeof USER_STATUSES[number];

export type UserStatus = PopulatedProps<UserStatusDTO & {name: Status}, 'id' | 'name'>;

export function assertUserStatus(maybe?: unknown): asserts maybe is UserStatus {
    const asUserStatus = maybe as UserStatus;

    const err = prefixedThrower(`Failed to assert userStatus: ${JSON.stringify(maybe)}: `);

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asUserStatus, hint('userStatus'));
    assertNotEmpty(asUserStatus.id, hint('userStatus.id'));
    assertNotEmpty(asUserStatus.name, hint('userStatus.name'));

    if (!USER_STATUSES.includes(asUserStatus.name)) err(`unknown status name: ${asUserStatus.name}`);
}
