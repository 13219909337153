import { MsoStockholdingImportVM } from "psims/gen/xapi-client";
import { prefixedThrower } from "psims/lib/exception";

export type MsoStockholdingImport = PopulatedProps<
  MsoStockholdingImportVM,
  'amountImported' | 'concurrencyToken' | 'dataSubmissionId' | 'entityName' |
  'id' | 'isOnBehalf' | 'msoProductId'
>;


export function assertMsoStockholdingImport(maybe?: unknown): asserts maybe is MsoStockholdingImport {
  const maybeAs = maybe as MsoStockholdingImport;

  const assertionThrower = prefixedThrower('Failed to assert msoAnnualActivity: ');

  if (maybeAs == null) {
    assertionThrower('argument is null');
  }

  if (maybeAs.concurrencyToken == null) {
    assertionThrower('concurrencyToken is null');
  }
  
  if (maybeAs.dataSubmissionId == null) {
    assertionThrower('dataSubmissionId is null');
  }

  if (maybeAs.id == null) {
    assertionThrower('id is null');
  }

  if (maybeAs.msoProductId == null) {
    assertionThrower('msoProductId is null');
  }

  if (maybeAs.amountImported == null) {
    assertionThrower('amountImported');
  }

  if (maybeAs.entityName == null) {
    assertionThrower('entityName is null');
  }

  if (maybeAs.isOnBehalf == null) {
    assertionThrower('isOnBehalf is null');
  }
}
