import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useUpdatedRef from "./use-updated-ref";

interface UseConfirmNavigationProps {
    insteadOfDialog?: () => any;
    when: boolean;
}

function useConfirmNavigation({insteadOfDialog, when}: UseConfirmNavigationProps) {
    const history = useHistory();
    const historyRef = useUpdatedRef(history);
    const requestedPath = useRef<string | null>(null);
    const [showPrompt, setShowPrompt] = useState(false);
    const blockRef = useRef<ReturnType<typeof history.block> | null>(null);

    const unblock = useCallback(() => {
        if (blockRef.current) {
            blockRef.current();
            blockRef.current = null;
        }
    }, [blockRef]);

    useEffect(() => {
       blockRef.current = blockRef.current || historyRef.current.block(prompt => {
            if (when) {
                requestedPath.current = prompt.pathname;
                if (insteadOfDialog != null) {
                    insteadOfDialog();
                } else {
                    setShowPrompt(true);
                }
                return 'true';
            } 
        });

        // unblock on unmount/re-effect
        return () => {
            unblock();
        }
    }, [blockRef, historyRef, insteadOfDialog, unblock, when]);

    const handleDecision = useCallback((decision: boolean) => {
        if (decision && requestedPath.current) {
            unblock();
            historyRef.current.push(requestedPath.current);
        }

        setShowPrompt(false);
    }, [requestedPath, unblock, historyRef]);

    return useMemo(() => ({
        handleDecision,
        showPrompt,
    }), [handleDecision, showPrompt]);
}

export default useConfirmNavigation;
