import { UpdateDataSubmission } from "psims/models/data-submission";
import { RefiningSubmission } from "psims/models/submission-types/refining";
import { UseRefineryForm } from "./use-refinery-form";

export const buildUpdateDataSubmissionRequest = (formCtrl: UseRefineryForm, {dataSubmission: {concurrencyToken, id}}: RefiningSubmission): UpdateDataSubmission => {
    return {
        comments: formCtrl.view.Submit.comments,
        concurrencyToken,
        id,
    }
}

export const DENSITY_ROW_HEADING = 'Average density (Kg/L)' as const;
export const OPENING_STOCK_ROW_HEADING = 'Opening stocks' as const;
export const TOTAL_RECEIPTS_ROW_HEADING = 'Total receipts (deliveries to refinery)' as const;
export const REFINERY_INPUTS_ROW_HEADING = 'Refinery process inputs (excluding where used as fuel)' as const;
export const PRODUCTION_ROW_HEADING = 'Production' as const;
export const CONSUMED_ROW_HEADING = 'Consumed as fuel' as const;
export const CLOSING_STOCKS_ROW_HEADING = 'Closing stocks' as const;


