/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FsspSubmissionVMApiResponse } from '../models/FsspSubmissionVMApiResponse';
import type { UpdateDataSubmissionVM } from '../models/UpdateDataSubmissionVM';
import type { UpdateFsspSubmissionVM } from '../models/UpdateFsspSubmissionVM';
import { request as __request } from '../core/request';

export class FsspService {

    /**
     * @returns FsspSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async getFsspSubmission({
id,
}: {
id?: number,
}): Promise<FsspSubmissionVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/fuel-security-services-payment`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns FsspSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateFssp({
requestBody,
}: {
requestBody?: UpdateFsspSubmissionVM,
}): Promise<FsspSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/fuel-security-services-payment/Update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns FsspSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateFsspDataSubmission({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<FsspSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/fuel-security-services-payment/Update-DataSubmission`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns FsspSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async submitFssp({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<FsspSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/fuel-security-services-payment/Submit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns FsspSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async clearAllFssp({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<FsspSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/fuel-security-services-payment/Clear-All`,
            body: requestBody,
        });
        return result.body;
    }

}