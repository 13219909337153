import { useEffect, useState } from "react";

interface UseResizeListenerProps {
    selector?: string;
}

function useResizeListener({selector}: UseResizeListenerProps) {
    const [observedTarget] = useState(document.querySelector(selector || 'body'));
    const [rect, setRect] = useState(observedTarget?.getBoundingClientRect());

    const [observer] = useState(new ResizeObserver(entries => {
        if (entries.length === 0) {
            return
        }

        setRect(entries[0].contentRect);
    }));

    useEffect(() => {
        if (observedTarget != null) {
            observer.observe(observedTarget);
        }

        return function() {
            observer.disconnect();
        }
    }, [observedTarget, observer])

    return {
        rect,
    };

}

export default useResizeListener;
