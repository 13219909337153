import React, { useMemo, useState } from 'react';

import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { H2 } from 'psims/react/components/typography';
import { ColumnHeader, Table, TD, TH, TR } from 'psims/react/pages/primary-pages/data-submissions/shared/data-table-components';
import { UseEligibleProductions } from './use-eligible-productions';
import { Draft, InlineMessage } from './shared';
import { localeNumberWithFixed } from 'psims/lib/formatters/numbers';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import styled from 'styled-components';
import { UseFocusedField } from 'psims/react/util/use-focused-field';
import { FocusField } from '../types';
import useFocusable from 'psims/react/util/use-focusable';
import FloatingMessage from 'psims/react/components/floating-message';
import randomID from 'psims/lib/random-id';
import { TooltipHelp } from '../../shared/tooltip-help';
import { isEmpty } from 'psims/lib/empty';

interface EligibleProductionsProps {
  eligibleProductionsCtrl: UseEligibleProductions;
  focusFieldCtrl: UseFocusedField<FocusField>;
}

const EligibleProductions = (props: EligibleProductionsProps) => {
  const vm = useVM(props);

  return (
    <BoxedDiv box={{}}>
      <H2>Quarterly production eligible for fuel security services payment</H2>

      <p>
        Please review the quarterly production eligible for FSSP and select either ‘Agree’ or ‘Disagree’.
      </p>

      <BoxedDiv box={{marginV: 2}}>
        <Table caption='Your FSSP s19A eligible payments summary'>
          <thead>
            <TR>
              <ColumnHeader
                label='Product'
              />

              <ColumnHeader
                label='Eligible production (T)'
                helpId='eligible_production_t'
                Help={
                  <BoxedDiv box={{flex: 'column'}}>
                    <p>This is a calculated field.</p>
                    <p>Eligible production (T) = sum of output production (T) minus sum of blendstock production (T).</p>
                  </BoxedDiv>
                }
                $width='250px'
              />

              <ColumnHeader
                label='Eligible production (KL)'
                helpId='eligible_production_kl'
                Help={
                  <BoxedDiv box={{flex: 'column'}}>
                    <p>This is a calculated field.</p>
                    <p>Eligible production (KL) = production (KL) minus blendstock (KL).</p>
                  </BoxedDiv>
                }
                $width='250px'
              />

              <TH>Are these volumes correct for the purposes of your s19A report?</TH>
            </TR>
          </thead>

          <tbody>
            {
              vm.ctrl.data.map((d, i)=> (
              <Row
                key={i}
                data={d}
                focusedFieldCtrl={vm.focusFieldCtrl}
                inlineMessage={d.inlineMessage}
                isDisabled={vm.ctrl.viewMode !== 'edit'}
                onChangeAgree={vm.ctrl.update}
              />))
            }
          </tbody>

        </Table>
      </BoxedDiv>
    </BoxedDiv>
  )
}

function useVM({ eligibleProductionsCtrl, focusFieldCtrl }: EligibleProductionsProps) {

  return {
    ctrl: eligibleProductionsCtrl,
    focusFieldCtrl,
  };
}

interface RowProps {
  data: UseEligibleProductions['data'][number];
  focusedFieldCtrl: UseFocusedField<FocusField>;
  inlineMessage?: InlineMessage;
  isDisabled: boolean;
  onChangeAgree: (d: Draft, nextVal: boolean) => any;
}

// For future me or other poor soul, FSSP was somehow the first DS
// to contain a radio group control, yet the last form to be delivered
// So Radio definition lives here for now by the policy of YAGNI - move
// to shared components as soon as another page/block/components wants
// what FSSP is having (i.e. a radio group)
const Fieldset = styled.fieldset`
  border: none;
`;

const Label = styled.label`
  padding: 8px 16px 8px 0;
  &:focus-within {
    box-shadow: var(--box-shadow-focus);
  }
`;

const Radio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid var(--color-primary-interactive);
  border-radius: 50%;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 0.75em;
    height: 0.75em;
    margin: 3px;
    transition: background-color 200ms ease;
  }

  // Weird hack to get check to update (as opposed to using
  // native :checked css selector, which doesn't seem to update
  // reliably)
  ${(props: {isChecked: boolean}) => `
  ${props.isChecked ? `
  &::after {
    background-color: var(--color-primary-interactive);
  }
  `: ''}`}

  &:hover:not(:checked) {
    ::after {
      background-color: var(--color-blue-50);
    }
  }

  &:focus {
    outline: none;
  }
`;

const Row = ({data, focusedFieldCtrl, inlineMessage, isDisabled, onChangeAgree}: RowProps) => {
  const {draft} = data;
  const [floatingId] = useState(randomID());

  const isFocused = useMemo(() => {
    return (
      focusedFieldCtrl.focusedField?.kind === 'data' &&
      focusedFieldCtrl.focusedField._id === draft._id
    );
  }, [draft._id, focusedFieldCtrl.focusedField]);

  const {setRef} = useFocusable({setFocused: isFocused});

  return (
    <TR>
      <TH scope='row'>
        <BoxedSpan box={{flex: 'row'}}>
          {draft.ref.fsspProductName}
          {
            (data.productPortalData?.content != null && !isEmpty(data.productPortalData.content)) ? 
              <TooltipHelp Help={data.productPortalData.content} /> :
              null
          }
        </BoxedSpan>
      </TH>
      <TD>{localeNumberWithFixed(draft.ref.eligibleProductionTonnes, 3)}</TD>
      <TD>{localeNumberWithFixed(draft.ref.eligibleProductionKilolitres, 3)}</TD>
      <TD>
        <Fieldset aria-describedby={inlineMessage?.message}>
          <legend><VisuallyHidden>Are these volumes correct for the purposes of your s19A report?</VisuallyHidden></legend>

          <BoxedDiv box={{flex: 'row'}}>
            <Label>
              <BoxedSpan box={{alignItems: 'center', flex: 'row', marginLeft: 2}}>
                <VisuallyHidden>Agree</VisuallyHidden>
                <Radio
                  disabled={isDisabled}
                  ref={setRef}
                  isChecked={draft.data.volumeConfirmation === true}
                  type='radio'
                  defaultChecked={draft.data.volumeConfirmation === true}
                  name={`decision_${draft.ref.fsspProductId}`}
                  onClick={() => onChangeAgree(draft, true)}
                  value='yes'
                />

                <BoxedSpan box={{marginLeft: 1}}>Agree</BoxedSpan>
              </BoxedSpan>
            </Label>

            <Label>
              <BoxedSpan box={{alignItems: 'center', flex: 'row', marginLeft: 2}}>
                <Radio
                  disabled={isDisabled}
                  isChecked={draft.data.volumeConfirmation === false}
                  type='radio'
                  defaultChecked={draft.data.volumeConfirmation === false}
                  name={`decision_${draft.ref.fsspProductId}`}
                  onClick={() => onChangeAgree(draft, false)}
                  value='no'
                />
                <BoxedSpan box={{marginLeft: 1}}>Disagree</BoxedSpan>
              </BoxedSpan>
            </Label>

            {
              inlineMessage ? 
              <BoxedSpan box={{alignItems: 'center', flex: 'row', marginLeft: 2}}>
                  <FloatingMessage
                      id={floatingId}
                      content={inlineMessage.message}
                      kind={inlineMessage.kind === 'alert' ? 'warning' : 'info'}
                      role='alert'
                      showContent={isFocused}
                  />
              </BoxedSpan> :
              null
            }
          </BoxedDiv>
        </Fieldset>

      </TD>
    </TR>
  )
}

export default EligibleProductions;
