import React from 'react';
import { Redirect } from 'react-router-dom';

interface EnsureIdProps {
    id: number;
    Component: React.FC<{id: number}>;
}

const EnsureId = ({Component, ...props}: EnsureIdProps) => {
    const isInvalidParams = isNaN(props.id);

    return isInvalidParams ? <Redirect to='/' /> : <Component {...props} />;
}

export default EnsureId;
