import React from 'react';
import BaseSVG, { SVGProps } from './_base';

const ArrowDown = (props: SVGProps) => {
	return (
		<BaseSVG name='arrow-down' viewBox='0 0 91.118 96.273' {...props}>
  			<path d="M67.228,54.58H0V36.529H67.219L42.238,11.563,53.726,0,96.273,45.554,53.726,91.118,42.238,79.631Z" transform="translate(91.118) rotate(90)" fill="currentColor"/>
		</BaseSVG>
	);
};

export default ArrowDown;
