import { FsspRefineryOutputVM } from "psims/gen/xapi-client";

export type FspRefineryOutput = PopulatedProps<
  FsspRefineryOutputVM,
  'dataSubmissionId' |  'refineryProductId' | 'refineryProductName'
>;

export function isFspRefineryOutput(maybe?: unknown): maybe is FspRefineryOutput {
  const maybeAs = maybe as FspRefineryOutput;

  return (
    maybeAs != null &&
    maybeAs.dataSubmissionId != null &&
    maybeAs.refineryProductId != null &&
    maybeAs.refineryProductName != null
  );
}
