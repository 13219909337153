import React from 'react';

import { extend } from 'psims/react/util/classname';

import './box.css';
import styled, { css } from 'styled-components';

type Color = 'accent' | 'black' | 'grey' | 'negative' | 'positive' | 'primary' | 'primary-faded' | 'primary-mid' | 'transparent' | 'white';

interface BorderConfig {
	color: Color;
	width?: 1 | 2 | 3 | 4;
}

interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
	alignItems?: 'center' | 'flex-start' | 'flex-end';
	alignSelf?: 'center' | 'flex-start' | 'flex-end' | 'stretch';
	background?: Color;
	border?: BorderConfig;
	borderBottom?: BorderConfig;
	borderLeft?: BorderConfig;
	borderRadius?: ShirtSize;
	boxShadow?: 'lo' | 'mid' | 'hi';
	color?: Color;
	className?: string;
	flex?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
	flexGrow?: 1 | 2 | 3 | 4;
	flexWrap?: 'wrap' | 'wrap-reverse';
	height?: number | string;
	justifyContent?: 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-evenly' | 'space-between';
	justifySelf?: 'center' | 'flex-end' | 'flex-start';
	margin?: ShirtSize;
	marginBottom?: ShirtSize;
	marginH?: ShirtSize;
	marginLeft?: ShirtSize;
	marginRight?: ShirtSize;
	marginTop?: ShirtSize;
	marginV?: ShirtSize;
	maxWidth?: string;
	minWidth?: string;
	overflow?: 'hidden';
	padding?: ShirtSize;
	paddingBottom?: ShirtSize;
	paddingH?: ShirtSize;
	paddingTop?: ShirtSize;
	paddingV?: ShirtSize;
};

const StyledBox = styled.div`
	${(props: BoxProps) => `
		${(props.borderLeft != null ? css`
			border-left-style: solid;
			${(props.borderLeft.color ? css`border-left-color: var(--color-${props.borderLeft.color});` : '')}
		` : '')}
		${(props.height != null ? `height: ${props.height};` : '')}
		${(props.minWidth != null ? `min-width: ${props.minWidth};` : '')}
		${(props.maxWidth != null ? `max-width: ${props.maxWidth};` : '')}
		${(props.overflow != null? `overflow: ${props.overflow};` : '')}
	`}
`;

const Box = (props: React.PropsWithChildren<React.PropsWithRef<BoxProps>>) => {
	const boxClassNames = useBoxClassNames(props);

	const {borderLeft, height, maxWidth, minWidth, overflow} = props;
	const styleProps = {borderLeft, height, maxWidth, minWidth, overflow};

	return (
		<StyledBox
			className={extend('box', boxClassNames)}
			{...styleProps}
			tabIndex={props.tabIndex}
			aria-live={props['aria-live']}
			role={props.role}
		>{props.children}</StyledBox>
	);
};

function useBoxClassNames(props: BoxProps) {
	const cn = [
		...(props.alignItems ? [`flex-items--${props.alignItems}`] : []),
		...(props.alignSelf ? [`flex-self-align--${props.alignSelf}`] : []),
		...(props.background ? [`background--${props.background}`] : []),
		...(props.border ? [`border border-color--${props.border.color} border-width--${props.border.width ? props.border.width : '1'}`] : []),
		...(props.borderBottom ? [`border-bottom border-bottom-color--${props.borderBottom.color} border-bottom-width--${props.borderBottom.width ? props.borderBottom.width : '1'}`] : []),
		...(props.borderRadius ? [`border-radius--${props.borderRadius}`] : []),
		...(props.boxShadow ? [`box-shadow--${props.boxShadow}`] : []),
		...(props.className ? [`${props.className}`] : []),
		...(props.color ? [`color--${props.color}`] : []),
		...(props.flex ? ['flex', `flex--${props.flex}`] : []),
		...(props.flexGrow ? [`flex-grow--${props.flexGrow}`] : []),
		...(props.flexWrap ? [`flex-wrap--${props.flexWrap}`] : []),
		...(props.justifyContent ? [`flex-content--${props.justifyContent}`] : []),
		...(props.justifySelf ? [`flex-self-justify--${props.justifySelf}`] : []),
		...(props.margin ? [`margin--${props.margin}`] : []),
		...(props.marginBottom ? [`margin-bottom--${props.marginBottom}`] : []),
		...(props.marginH ? [`margin-h--${props.marginH}`] : []),
		...(props.marginLeft ? [`margin-left--${props.marginLeft}`] : []),
		...(props.marginRight ? [`margin-right--${props.marginRight}`] : []),
		...(props.marginTop ? [`margin-top--${props.marginTop}`] : []),
		...(props.marginV ? [`margin-v--${props.marginV}`] : []),
		...(props.padding ? [`padding--${props.padding}`] : []),
		...(props.paddingBottom ? [`padding-bottom--${props.paddingBottom}`] : []),
		...(props.paddingH ? [`padding-h--${props.paddingH}`] : []),
		...(props.paddingTop ? [`padding-top--${props.paddingTop}`] : []),
		...(props.paddingV ? [`padding-v--${props.paddingV}`] : []),
	];

	return cn.join(' ');
}

export default Box;
