import React, { useCallback, useMemo } from 'react';

import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { H2 } from 'psims/react/components/typography';
import { ActionCellTD, ActionCellTH, ColumnHeader, Table, TD, TH, TR } from 'psims/react/pages/primary-pages/data-submissions/shared/data-table-components';
import { UseStorageSites } from './use-storage-sites';
import { recordActionFromEnum } from 'psims/models/api/data-submission-record-action';
import { AddButton, IconButton } from 'psims/react/components/button';
import Input from '../../../shared/input';
import { StorageType } from 'psims/models/ref-data/mso-storage-type';
import Select, { useSelectController } from 'psims/react/components/select';
import { UseConfirm } from '../../../shared/use-confirm';
import { UpdateMsoStorageSiteDraft, UpdateMsoStorageSiteField } from 'psims/models/submission-types/mso/annual-activity/update-mso-storage-site';
import { UseFocusedField } from 'psims/react/util/use-focused-field';
import { FocusField, isDataFocusField, isDeleteProductFocusField, MsoProductView } from '../types';
import FloatingMessage from 'psims/react/components/floating-message';
import { EXPIRED_OR_INTERNAL_PRODUCT_MULTI_ROW } from 'psims/constants/validation-messages';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import useFocusable from 'psims/react/util/use-focusable';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { TooltipHelp } from '../../../shared/tooltip-help';
import { isEmpty } from 'psims/lib/empty';

interface StorageSitesEditorProps {
  deleteCtrl: UseConfirm;
  focusFieldCtrl: UseFocusedField<FocusField>;
  isDisabled: boolean;
  products: Array<MsoProductView>;
  storageTypes: Array<StorageType>;
  storageSitesCtrl: UseStorageSites;
  isRefiner: boolean;
}

const StorageSitesEditor = (props: StorageSitesEditorProps) => {
  const vm = useStorageSitesEditorVM(props);

  return (
    <BoxedDiv box={{ flex: 'column' }}>
      <BoxedDiv box={{ marginTop: 6 }}>
        <H2>Storage sites</H2>
        <p>
          {vm.labels.header}
        </p>
      </BoxedDiv>

      <BoxedDiv box={{ marginTop: 2 }}>

        <Table caption='Data for storage sites' customWidth='fit-content'>
          <thead>
            <TR>
              <ColumnHeader label='MSO Product' fixedWidth='230px' />

              <ColumnHeader label='Storage site' fixedWidth='230px' Help={<>
                <p>
                  The site where the MSO products were stored in the last 12 months. For example, an on-site tank, terminal, at a refinery, etc.
                </p>
                <p>
                  Add a new row to the table for each storage site.
                </p>
              </>} />

              <ColumnHeader label='Storage type' fixedWidth='370px' Help={'The nature of the ownership of the storage site.'} />

              <ColumnHeader label='Storage location' fixedWidth='230px' Help={'The facility where the storage site is located.'} />

              <ColumnHeader label='Storage from date' fixedWidth='230px' Help={'The date the storage was acquired (if it was within the past 12 months - otherwise leave it blank).'} />

              <ColumnHeader label='Storage to date' fixedWidth='230px' Help={<>
                <p>The date the storage expires (if applicable).</p>
                <p>Note: You are not required to fill this box out if your storage is wholly owned by your entity.</p>
              </>} />

              <ColumnHeader label='Storage price ($)' fixedWidth='230px' helpId='storage_price_help' Help={<>
                <p>The price your entity commonly offered to other entities (calculated by determining the total annual price that would be paid for a single megalitre of storage leased at that site).</p>
                <p>Enter amount in whole dollars.</p>
                <p>Note: This is only applicable if this site would have allowed others to store MSO product here.</p>
              </>} />

              {
                vm.hasExpiredData ?
                <ActionCellTH>
                  <VisuallyHidden>Actions</VisuallyHidden>
                </ActionCellTH> :
                null
              }

            </TR>
          </thead>

          {
            vm.products.map(p => (
              <ProductSection
                key={p.id}
                deleteCtrl={vm.deleteCtrl}
                focusFieldCtrl={vm.focusFieldCtrl}
                isDisabled={vm.isDisabled}
                product={p}
                storageSitesCtrl={vm.storageSitesCtrl}
                storageTypes={vm.storageTypes}
              />))
          }

        </Table>

      </BoxedDiv>
    </BoxedDiv>
  );
}

function useStorageSitesEditorVM({
  deleteCtrl,
  focusFieldCtrl,
  isDisabled,
  isRefiner,
  products,
  storageSitesCtrl,
  storageTypes,
}: StorageSitesEditorProps) {
  const labels = useMemo(() => ({
    header: isRefiner ?
      'This section outlines the storage information for MSO products owned or acquired through a leasing arrangement by your entity at a point in time at the end of the calendar year.' :
      'This section outlines the storage information for MSO products owned or acquired through a leasing arrangement at a point of time at the end of the calendar year.',
    storageFromDateHelp: 'The date the storage was acquired.'
  }), [isRefiner]);

  const hasExpiredData = useMemo(() => {
    return !isDisabled && products.some(p => {
      return storageSitesCtrl.updateStorageSites.find(s => (
        s.data.msoProductId === p.id &&
        p.productStatus === 'inactive' &&
        recordActionFromEnum(s.data.recordAction) !== 'Delete'
      ));
    });
  }, [isDisabled, products, storageSitesCtrl]);

  return {
    deleteCtrl,
    focusFieldCtrl,
    hasExpiredData,
    isDisabled,
    isRefiner,
    labels,
    products,
    storageSitesCtrl,
    storageTypes,
  }
}

interface ProductSectionProps {
  deleteCtrl: UseConfirm;
  focusFieldCtrl: UseFocusedField<FocusField>;
  isDisabled: boolean;
  product: MsoProductView;
  storageTypes: Array<StorageType>
  storageSitesCtrl: UseStorageSites;
}

const ProductSection = (props: ProductSectionProps) => {
  const vm = useProductSectionVM(props);

  if (vm.hideRow) {
    return null;
  }

  return <tbody>
    {
      vm.rowData.map((row, index) => <TR key={index}>
        {
          index === 0 && <TH scope='row' verticalAlign='top' rowSpan={vm.rowSpan}>
            <BoxedSpan box={{ alignItems: 'center', flex: 'row', height: '52px' }}>
              {vm.product.name}
              {
                vm.showRowError ?
                  <BoxedSpan box={{ marginBottom: -0.5, marginLeft: 1 }}>
                    <FloatingMessage
                      content={EXPIRED_OR_INTERNAL_PRODUCT_MULTI_ROW}
                      kind="warning"
                      role="alert"
                    />
                  </BoxedSpan> :
                  !isEmpty(vm.product.Help?.content) ?
                  <TooltipHelp
                    Help={<ReactMarkdown>{vm.product.Help?.content as string}</ReactMarkdown>}
                  /> :
                  null
              }
            </BoxedSpan>
          </TH>
        }

        {
          row.kind === 'add_button' ?
            // Add button
            (
              vm.showAddButton ? <TD colSpan={vm.colSpan}>
                <AddButton
                  label='Add storage site'
                  onClick={() => vm.storageSitesCtrl.addStorageSite(vm.product.id)}
                >Add storage site</AddButton></TD> :
                null
            ) :

            // Data row
            <DataRow
              data={row.data}
              focusFieldCtrl={vm.focusFieldCtrl}
              index={index}
              isDisabled={vm.isDisabled}
              onDelete={vm.deleteCtrl.requestConfirmation}
              product={vm.product}
              storageSitesCtrl={vm.storageSitesCtrl}
              storageTypes={vm.storageTypes}
            />
        }

        {
          index === 0 && vm.showRowError ?
          <ActionCellTD colSpan={vm.colSpan}>
            <DeleteProductButton
              focusedField={vm.focusFieldCtrl.focusedField}
              onClick={() => vm.deleteCtrl.requestConfirmation(
                'Are you sure you wish to delete this data?',
                'All storage sites for this product will be deleted.',
                () => vm.storageSitesCtrl.deleteAllStorageSitesForProduct(vm.product.id)
              )}
              productId={vm.product.id}
            />
          </ActionCellTD> :
          null
        }
      </TR>)
    }
  </tbody>
}

function useProductSectionVM({
  deleteCtrl,
  focusFieldCtrl,
  isDisabled,
  product,
  storageSitesCtrl,
  storageTypes
}: ProductSectionProps) {
  const updateStorageSites = useMemo(() => {
    return storageSitesCtrl.updateStorageSites
      .filter(s => s.data.msoProductId === product.id && recordActionFromEnum(s.data.recordAction) !== 'Delete')
  }, [product.id, storageSitesCtrl.updateStorageSites]);

  const rowSpan = useMemo(() => {
    return updateStorageSites.length + 1;
  }, [updateStorageSites.length]);

  const rowData = useMemo(() => {
    let data = [
      ...updateStorageSites.map(s => ({ kind: 'data' as 'data', data: s })),
      { kind: 'add_button' as 'add_button' },
    ];

    if (isDisabled && data.length === 1) {
      // Add empty row
      data = [{ data: { data: { _id: Symbol(), msoProductId: product.id }, validations: {} }, kind: 'data' }, ...data];
    }

    return data;
  }, [isDisabled, product, updateStorageSites]);

  const hasExpiredData = useMemo(() => {
    return product.productStatus === 'inactive' && updateStorageSites.length > 0;
  }, [product, updateStorageSites.length])

  const hideProduct = useMemo(() => {
    return product.productStatus === 'inactive' && updateStorageSites.length === 0;
  }, [product, updateStorageSites.length]);

  const showAddButton = useMemo(() => {
    return !isDisabled && !hasExpiredData;
  }, [hasExpiredData, isDisabled]);

  const showRowError = useMemo(() => {
    return hasExpiredData && !isDisabled;
  }, [hasExpiredData, isDisabled]);

  return {
    colSpan: hasExpiredData ? 7 : 6, // Will need to be updated if column count changes
    deleteCtrl,
    focusFieldCtrl,
    hasExpiredData,
    hideRow: hideProduct,
    isDisabled,
    product,
    rowData,
    rowSpan,
    showAddButton,
    showRowError,
    storageSitesCtrl,
    storageTypes,
    updateStorageSites,
  };
}

type UpdateStorageSiteView = UseStorageSites['updateStorageSites'][number];

interface DataRowProps {
  data: UpdateStorageSiteView;
  focusFieldCtrl: UseFocusedField<FocusField>;
  index: number;
  isDisabled: boolean;
  onDelete: UseConfirm['requestConfirmation'];
  product: MsoProductView;
  storageSitesCtrl: UseStorageSites;
  storageTypes: Array<StorageType>;
}

const DataRow = (props: DataRowProps) => {
  const vm = useDataRowVM(props);

  return <>
    <TD>
      <Input
        disabled={vm.isDisabled}
        kind='string'
        label='Storage site'
        error={vm.validations.storageSite?.inline}
        onChange={val => vm.updateField('storageSite', val)}
        shouldFocus={vm.rowFocusField?.field === 'storageSite'}
        value={vm.data.storageSite}
      />
    </TD>

    <TD>
      <Select
        bare={true}
        borderless={true}
        focusless={true}
        id={`${vm.data.msoProductId}_${vm.index}`}
        isDisabled={vm.isDisabled}
        label='Storage type'
        options={vm.storageTypeSelectCtrl.options}
        onChange={vm.storageTypeSelectCtrl.onChange}
        setFocused={false}
        useTooltip={true}
        value={vm.storageTypeSelectCtrl.value}
      />
    </TD>

    <TD>
      <Input
        disabled={vm.isDisabled}
        kind='string'
        label='Storage location'
        error={vm.validations.storageLocation?.inline}
        onChange={val => vm.updateField('storageLocation', val)}
        shouldFocus={vm.rowFocusField?.field === 'storageLocation'}
        value={vm.data.storageLocation}
      />
    </TD>

    <TD>
      <Input
        disabled={vm.isDisabled}
        error={vm.validations.storageFromDate?.inline}
        kind='string'
        label='Storage from date'
        onChange={val => vm.updateField('storageFromDate', val)}
        placeholder='dd/mm/yyyy'
        shouldFocus={vm.rowFocusField?.field === 'storageFromDate'}
        value={vm.data.storageFromDate}
      />
    </TD>

    <TD>
      <Input
        disabled={vm.isDisabled}
        error={vm.validations.storageToDate?.inline}
        kind='string'
        label='Storage to date'
        onChange={val => vm.updateField('storageToDate', val)}
        placeholder='dd/mm/yyyy'
        shouldFocus={vm.rowFocusField?.field === 'storageToDate'}
        value={vm.data.storageToDate}
      />
    </TD>

    <TD>
      <BoxedDiv box={{ flex: 'row' }}>
        <Input
          disabled={vm.isDisabled}
          error={vm.validations.storagePrice?.inline}
          kind='number'
          label='Storage price'
          onChange={val => vm.updateField('storagePrice', val)}
          shouldFocus={vm.rowFocusField?.field === 'storagePrice'}
          value={vm.data.storagePrice}
        />

        {
          vm.showIndividualDelete ?
          <IconButton
            color='dark-grey'
            icon='trash'
            label='Delete entity'
            onClick={() => vm.onDelete(
              'Are you sure you wish to delete this data?',
              'Storage site will be deleted',
              () => vm.deleteRow(vm.data)
            )}
            size='sm'
            styles={{ marginLeft: '6px', marginRight: '-12px', padding: '4px' }}
          /> :
          null
        }
      </BoxedDiv>
    </TD>
  </>
}

function useDataRowVM({ 
  data,
  focusFieldCtrl,
  index,
  isDisabled,
  onDelete,
  product,
  storageSitesCtrl,
  storageTypes
}: DataRowProps) {
  const storageTypeSelectCtrl = useSelectController({
    value: data.data.storageTypeId,
    options: storageTypes.map(s => ({
      label: s.name,
      value: s.id,
    })),
    onChange: v => storageSitesCtrl.updateStorageSite({
      ...data.data,
      storageTypeId: v || undefined,
    })
  });

  const rowFocusField = useMemo(() => {
    return isDataFocusField(focusFieldCtrl.focusedField) && focusFieldCtrl.focusedField._id === data.data._id ? focusFieldCtrl.focusedField : null
  }, [data.data._id, focusFieldCtrl]);

  const updateField = useCallback(<TField extends UpdateMsoStorageSiteField, TVal extends UpdateStorageSiteView['data'][TField]>(field: TField, val: TVal) => {
    storageSitesCtrl.updateStorageSite({
      ...data.data,
      [field]: val
    })
  }, [data, storageSitesCtrl]);

  const deleteRow = useCallback((storageSite: UpdateMsoStorageSiteDraft) => {
    storageSitesCtrl.deleteStorageSite(storageSite);
  }, [storageSitesCtrl]);

  const isExpired = useMemo(() => {
    return product.productStatus === 'inactive';
  }, [product]);

  const showIndividualDelete = useMemo(() => {
    return !isDisabled && !isExpired;
  }, [isDisabled, isExpired]);

  return {
    data: data.data,
    deleteRow,
    index,
    isDisabled,
    onDelete,
    rowFocusField,
    showIndividualDelete,
    storageTypeSelectCtrl,
    updateField,
    validations: data.validations
  }
}

interface DeleteProductProps {
    focusedField: unknown;
    onClick: () => any;
    productId: number;
}

const DeleteProductButton = React.forwardRef(({ focusedField, onClick, productId }: DeleteProductProps, ref) => {
    const focusable = useFocusable({
        setFocused: (
            isDeleteProductFocusField(focusedField) &&
            focusedField.kind === 'storageSites' &&
            focusedField.productId === productId
        ),
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <BoxedSpan box={{marginLeft: 1}}>
          <IconButton
              color='dark-grey'
              icon='trash'
              label='Delete all rows for product'
              onClick={onClick}
              ref={setRef}
              size='sm'
          />
        </BoxedSpan>
    );
});


export default StorageSitesEditor;
