import { isValidLocalDate, LOCAL_DATE_REG_EX } from "./validation/date";

export function localDateStringToDate(str: string) {
  if (isValidLocalDate(str)) {
    const match = str.match(LOCAL_DATE_REG_EX);
    
    if (match == null) {
      return null;
    }

    const [,day, month, year] = match;

    return new Date(`${year}-${month}-${day}`)
  }

  return null;
}

export function dateToLocalDateString(date: Date) {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}