import React from 'react';

import BaseSVG, { SVGProps } from './_base';

const Envelope = (props: SVGProps) => {
	return (
		<BaseSVG name='envelope' viewBox='0 0 96.247 72.566' {...props}>
  		<path d="M0,68.7V5.388L9.623,13.36l35.8,29.833c1.857,1.549,3.531,1.56,5.4,0L94.888,6.5l1.283-1.04c0,.471.076.8.076,1.126V68.428c0,2.664-1.484,4.137-4.164,4.137H4.093C1.541,72.566,0,71.13,0,68.7ZM5.1,0H91.135L48.121,35.85Z" fill="currentColor"/>
		</BaseSVG>
	);
};

export default Envelope;
