import { DocumentVM } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { assertDocumentStatusName, DocumentStatus } from "./ref-data/document-status";
import { assertDocumentTypeName, DocumentType } from "./ref-data/document-type";

export type Document = PopulatedProps<
DocumentVM,
'blobName' | 'documentStatusId' | 'documentStatusName' | 'documentTypeId' | 'documentTypeName' | 'fileType' | 'name' | 'organisationId' | 'organisationName' | 'portalUploadBy' | 'portalUploadOn'
> & {
  documentStatusName: DocumentStatus['name'];
  documentTypeName: DocumentType['name'];
}

export function assertDocument(maybe?: unknown): asserts maybe is Document {
  const maybeAs = maybe as Document;

  const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

  assertNotEmpty(maybeAs, hint('document'));
  assertNotEmpty(maybeAs.blobName, hint('document.blobName'));
  assertNotEmpty(maybeAs.documentStatusId, hint('document.documentStatusId'));
  assertNotEmpty(maybeAs.documentStatusName, hint('document.documentStatusName'));
  assertDocumentStatusName(maybeAs.documentStatusName)
  assertNotEmpty(maybeAs.documentTypeId, hint('document.documentTypeId'));
  assertNotEmpty(maybeAs.documentTypeName, hint('document.documentTypeName'));
  assertDocumentTypeName(maybeAs.documentTypeName);
  assertNotEmpty(maybeAs.fileType, hint('document.fileType'));
  assertNotEmpty(maybeAs.name, hint('document.name'));
  assertNotEmpty(maybeAs.organisationId, hint('document.organisationId'));
  assertNotEmpty(maybeAs.organisationName, hint('document.organisationName'));
  assertNotEmpty(maybeAs.portalUploadBy, hint('document.portalUploadBy'));
  assertNotEmpty(maybeAs.portalUploadOn, hint('document.portalUploadOn'));
}

export function isDocument(maybe?: unknown): maybe is Document {
  assertDocument(maybe);

  return true;
}
