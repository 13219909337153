import React from 'react';

import BaseSVG, { SVGProps } from './_base';

const FileExcel = (props: SVGProps) => {
	return (
		<BaseSVG name='file-excel' viewBox='0 0 384 512' {...props}>
			<path fill='currentColor' d="M224 0H0V512H384V160H224V0zm32 0V128H384L256 0zM97.7 240h56.8L192 299.1 229.5 240h56.8L220.4 344l65.9 104H229.5L192 388.9 154.5 448H97.7l65.9-104L97.7 240z"/>
		</BaseSVG>
	);
};

export default FileExcel;
