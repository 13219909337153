import React from 'react';
import styled from 'styled-components';

interface DividerProps {}

const StyledDivider = styled.div`
    background: var(--color-blue-45);
    height: 2px;
    width: 100%;
`;

const Divider = (props: DividerProps) => {
    return <StyledDivider />
}

export default Divider;
