import Expandable from "psims/react/components/expandable"
import HelpBlock from "psims/react/components/help"
import Input from "psims/react/components/input"
import { BoxedDiv, BoxedSpan } from "psims/react/components/layout"
import Text from "psims/react/components/text"
import { asString } from "psims/lib/string";
import { User } from "psims/models/user";
import { ValidationMessages } from "psims/react/pages/primary-pages/user-management/use-edit-user";

interface StatusFieldProps {
    status: string;
}

interface UserAccountFieldProps {
    user: User | null;
    isMyDetails?: boolean;
    validationMessages: ValidationMessages;
    updateUser: <K extends keyof User>(field: K, value: User[K]) => void;
}

export const StatusField = ({status}: StatusFieldProps) => {
    return (
        <>
            <BoxedDiv box={{flex: 'row', marginTop: 2}}>
                <BoxedDiv box={{flex: 'column', flexGrow: 1, marginRight: 4}}>

                    <BoxedSpan box={{}}>
                        <Text weight='semibold'>User status</Text>
                    </BoxedSpan>

                </BoxedDiv>
            </BoxedDiv>

            <BoxedDiv box={{flex: 'row'}}>
                <BoxedDiv box={{marginBottom: 1, marginTop: 0.5}}>

                    <Expandable
                        collapsedLabel='User status definitions'
                        contentPlacement='below'
                        expandedLabel='User status definitions'
                    >
                        <BoxedDiv box={{flex: 'column', marginV: 1}}>
                            <HelpBlock>
                                <BoxedDiv box={{flex: 'column'}}>
                                <p><Text $size={14}><strong>New: </strong>user has been created and is pending registration of their user details.</Text></p>
                                <p><Text $size={14}><strong>Invited: </strong>user has been sent an invitation email to register their user details.</Text></p>
                                <p><Text $size={14}><strong>Registered: </strong>user is registered, and can access the portal.</Text></p>
                                <p><Text $size={14}><strong>Inactive: </strong>user can no longer access the portal.</Text></p>
                                </BoxedDiv>
                            </HelpBlock>
                        </BoxedDiv>
                    </Expandable>

                </BoxedDiv>
            </BoxedDiv>

            <BoxedDiv box={{flex: 'row'}}>
                <BoxedDiv box={{flex: 'column', flexGrow: 1, marginRight: 4}}>

                    <Input
                        disabled={true}
                        hideLabel={true}
                        id='status'
                        label='User status'
                        onChange={() => {}}
                        value={status}
                    />

                </BoxedDiv>
                <BoxedDiv box={{flexGrow: 1, marginLeft: 4}} />
            </BoxedDiv>
        </>
    )
}

export const UserAccount = (props: UserAccountFieldProps) => {        
    if (props.user === null) {
        return null;
    }

    if (props.isMyDetails) {
        return (
            <>
                <BoxedDiv box={{flex: 'row', marginTop: 2}}>
                    <BoxedDiv box={{flex: 'column', flexGrow: 1, marginRight: 4}}>

                        <BoxedSpan box={{}}>
                            <Text weight='semibold'>User account</Text> <Text variant="muted">(e.g. firstname.lastname@industry.gov.au)</Text>
                        </BoxedSpan>

                    </BoxedDiv>
                </BoxedDiv>
                <BoxedDiv box={{flex: 'row'}}>
                    <BoxedDiv box={{marginBottom: 1, marginTop: 0.5}}>

                        <Expandable
                            collapsedLabel='What do I do if my email has changed?'
                            contentPlacement='below'
                            expandedLabel='What do I do if my email has changed?'
                        >
                            <BoxedDiv box={{flex: 'column', marginV: 1}}>
                                <HelpBlock>
                                    <BoxedDiv box={{flex: 'column'}}>
                                    <p><Text $size={14}>If your email address has changed, please contact your company admin who will be able to inactivate the current account and create a new account for you.</Text></p>
                                    </BoxedDiv>
                                </HelpBlock>
                            </BoxedDiv>
                        </Expandable>

                    </BoxedDiv>
                </BoxedDiv>
                
                <BoxedDiv box={{flex: 'row'}}>
                    <BoxedDiv box={{flex: 'column', flexGrow: 1, marginRight: 4}}>

                        <Input
                            disabled={true}
                            hideLabel={true}
                            id='email'
                            label='User account (e.g. firstname.lastname@industry.gov.au)'
                            onChange={() => {}}
                            type='email'
                            value={asString(props.user.email)}
                        />

                    </BoxedDiv>
                    <BoxedDiv box={{flexGrow: 1, marginLeft: 4}} />
                </BoxedDiv>
            </>
        );
    }

    return (
        <BoxedDiv box={{flex: 'column', flexGrow: 1, marginBottom: 4}}>

            <BoxedSpan box={{marginBottom: 1}}>
                <Text weight='semibold'>User account</Text> <Text variant="muted">(e.g. firstname.lastname@industry.gov.au)</Text>
            </BoxedSpan>

            <Input
                autoFocus={true}
                error={props.validationMessages.email}
                hideLabel={true}
                id='email'
                label='User account (e.g. firstname.lastname@industry.gov.au)'
                onChange={e => props.updateUser('email', e.target.value)}
                readOnly={true}
                type='email'
                value={asString(props.user.email)}
            />
        </BoxedDiv>
    );
};
