import { useCallback, useEffect, useMemo, useState } from "react";

import { SELECTOR_NOTIFICATIONS } from "psims/constants/selectors";
import { UseSubmit } from "../shared/use-submit";
import { attemptScrollToSelector } from "../shared/view-utils";
import { UseEligibleProductions } from "./eligible-productions/use-eligible-productions";
import { UseFspAPI } from "./use-fsp-api";
import { UseFspProgress } from "./use-fsp-progress";
import { UseTotalQuarterlyElibibleFssp } from "./total-quarterly-eligible-fssp/use-total-quarterly-eligible-fssp";
import useConfirmNavigation from "psims/react/util/use-confirm-navigation";
import { ViewMode } from "../shared/use-view-mode";

interface UseFspNavigationProps {
  apiCtrl: UseFspAPI;
  eligibleProductionsCtrl: UseEligibleProductions;
  progressCtrl: UseFspProgress;
  submitCtrl: UseSubmit;
  totalQuarterlyEligibleFsspCtrl: UseTotalQuarterlyElibibleFssp;
  viewMode: ViewMode;
}

type NavState = 'initial' | 'confirming_unsaved_changes' | 'saving' | 'confirming_validation_alerts' | 'nav_blocked';

type UnsavedChangesDecision = 'discard_and_continue' | 'save_and_continue' | 'cancel';

type ValidationAlertDialogDecision = 'proceed' | 'review_alerts';

function useFspNavigation({apiCtrl, eligibleProductionsCtrl, progressCtrl, submitCtrl, totalQuarterlyEligibleFsspCtrl, viewMode}: UseFspNavigationProps) {
  const [navState, setNavState] = useState<NavState>('initial');
  const [nextPage, setNextPage] = useState<number | null>(null);

  // Internal functions
  const hasUnsavedChanges = useMemo(() => {
    if (progressCtrl.currentStep.kind === 'data') {
      return eligibleProductionsCtrl.hasUnsavedChanges || totalQuarterlyEligibleFsspCtrl.hasUnsavedChanges
    }

    return submitCtrl.hasUnsavedChanges;
  }, [
    eligibleProductionsCtrl.hasUnsavedChanges,
    progressCtrl.currentStep.kind,
    submitCtrl.hasUnsavedChanges,
    totalQuarterlyEligibleFsspCtrl.hasUnsavedChanges
  ]);

  const isValid = useMemo(() => {
    if (progressCtrl.currentStep.kind === 'data') {
      return eligibleProductionsCtrl.isValid && totalQuarterlyEligibleFsspCtrl.isValid
    }

    return submitCtrl.commentsError == null;
  }, [
    eligibleProductionsCtrl.isValid,
    progressCtrl.currentStep.kind,
    submitCtrl,
    totalQuarterlyEligibleFsspCtrl.isValid
  ]);

  const browserNavCtrl = useConfirmNavigation({ when: hasUnsavedChanges && viewMode === 'edit' });

  const validationAlertsForPage = useMemo(() => {
    if (progressCtrl.currentStep.kind === 'data') {
      return eligibleProductionsCtrl.data.map(d => d.validationAlerts).flat()
    }

    return [];
  }, [eligibleProductionsCtrl.data, progressCtrl.currentStep.kind]);
  
  const save = useCallback(() => {
    const getEligibleProductionsPayload = eligibleProductionsCtrl.getRequestPayload;
    const getTotalQuarterlyElibibleFsspPayload = totalQuarterlyEligibleFsspCtrl.getRequestPayload;
    setNavState('saving');
    if (progressCtrl.currentStep.kind === 'data') {
      const payload = {
        dataSubmissionId: apiCtrl.submission?.dataSubmission.id,
        fsspTotals: getTotalQuarterlyElibibleFsspPayload(),
        fsspEligibleProductions: getEligibleProductionsPayload(),
      };
      apiCtrl.update(payload);
    } else {
      const payload = {
        id: apiCtrl.submission?.dataSubmission.id,
        concurrencyToken: apiCtrl.submission?.dataSubmission.concurrencyToken,
        comments: submitCtrl.comments,
      };
      apiCtrl.updateDataSubmission(payload);
    }
  }, [
    apiCtrl,
    eligibleProductionsCtrl.getRequestPayload,
    progressCtrl.currentStep.kind,
    submitCtrl.comments,
    totalQuarterlyEligibleFsspCtrl.getRequestPayload,
  ]);

  const attemptSaveAndGoToPage = useCallback((index: number | null) => {
    if (navState === 'saving') {
      return;
    }

    if (!isValid) {
      setNavState('nav_blocked');
      attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
      if (browserNavCtrl.showPrompt) {
        browserNavCtrl.handleDecision(false);
      }
      return;
    }

    setNextPage(index);
    save();
  }, [browserNavCtrl, isValid, navState, save]);

  const goToPage = useCallback((index: number) => {
    progressCtrl.goToStep(index);
  }, [progressCtrl]);

  // Exposed functions
  const handleNavBarClick = useCallback((index: number) => {
    if (hasUnsavedChanges) {
      setNavState('confirming_unsaved_changes');
      setNextPage(index);
    } else {
      goToPage(index);
    }
  }, [goToPage, hasUnsavedChanges]);

  const handleUnsavedChangesDialogDescision = useCallback((decision: UnsavedChangesDecision) => {
    switch (decision) {
      case 'cancel':
        setNavState('nav_blocked');
        if (browserNavCtrl.showPrompt) {
          browserNavCtrl.handleDecision(false);
        }
        return;
      case 'discard_and_continue':
        if (browserNavCtrl.showPrompt) {
          browserNavCtrl.handleDecision(true);
          return;
        }
        if (nextPage != null) {
          goToPage(nextPage);
        } 
        return;
      case 'save_and_continue':
        attemptSaveAndGoToPage(nextPage)
    }
  }, [attemptSaveAndGoToPage, browserNavCtrl, goToPage, nextPage]);

  const handleValidationAlertDialogDecision = useCallback((decision: ValidationAlertDialogDecision) => {
    if (decision === 'proceed' && nextPage != null) {
      goToPage(nextPage);
    } else {
      setNavState('nav_blocked');
    }
  }, [goToPage, nextPage]);

  // handle save result
  useEffect(() => {
    if (navState === 'saving') {
      if (apiCtrl.loadStatus === 'updated') {
        if (browserNavCtrl.showPrompt) {
          browserNavCtrl.handleDecision(true);
          return;
        }

        if (validationAlertsForPage.length === 0) {
          if (nextPage != null) {
            goToPage(nextPage);
          } else {
            throw new Error('Save completed, but next page undefined');
          }
        } else {
          setNavState('confirming_validation_alerts');
        }
      } else if (apiCtrl.loadStatus === 'update_failed') {
        if (browserNavCtrl.showPrompt) {
          browserNavCtrl.handleDecision(false);
        }
        attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
        setNavState('nav_blocked');
      }
    }
  }, [apiCtrl.loadStatus, browserNavCtrl, goToPage, navState, nextPage, validationAlertsForPage]);

  // Reset local state on page change
  useEffect(() => {
    setNavState('initial');
    setNextPage(null);
  }, [progressCtrl.currentStep.index]);

  // Listen for browser nav and move to unsaved changes state
  useEffect(() => {
    if (browserNavCtrl.showPrompt) {
      setNavState('confirming_unsaved_changes');
    }
  }, [browserNavCtrl.showPrompt]);

  return useMemo(() => ({
    navState,
    attemptSaveAndGoToPage,
    handleNavBarClick,
    handleUnsavedChangesDialogDescision,
    handleValidationAlertDialogDecision
  }), [
    attemptSaveAndGoToPage,
    handleNavBarClick,
    handleUnsavedChangesDialogDescision,
    handleValidationAlertDialogDecision,
    navState,
  ]);
}

export default useFspNavigation

export type UseFspNavigation = ReturnType<typeof useFspNavigation>;