import { useCallback, useEffect, useMemo, useState } from "react";

import { MsoStockOnBehalf } from "psims/models/submission-types/mso/mso-stock-on-behalf";
import { UseMsoRefData } from "../shared/use-mso-ref-data";
import { UseMsoRefinerFocus } from "./use-mso-refiner";
import { UseMsoRefinerForm } from "./use-mso-refiner-form";
import { UpdateMsoComment } from "psims/models/submission-types/mso/mso-comment";
import { UseMsoRefinerProgress } from "./use-mso-refiner-progress";
import { UpdateMso } from "psims/models/submission-types/mso/mso";
import { getDataNotifications, getDataValidationMessages, getSubmitNotifications, getSubmitValidationMessages } from "../shared/validation";

interface UseMsoRefinerValidationProps {
    focusFieldCtrl: UseMsoRefinerFocus;
    formCtrl: UseMsoRefinerForm;
    progressCtrl: UseMsoRefinerProgress;
    refData: UseMsoRefData;
}

function useMsoRefinerValidation({focusFieldCtrl, formCtrl, progressCtrl, refData}: UseMsoRefinerValidationProps) {
    const [saveAttempted, setSaveAttempted] = useState(false);

    const validationMessages = useMemo(() => {
        const {currentStep} = progressCtrl;

        // Validate data entry page
        if (currentStep.kind === 'data') {
            const {msoCtrl, submissionUpdate: {msoComment, msos, stockholdings, stocksOnBehalf, stockRefineriesHeld}} = formCtrl;
            return getDataValidationMessages({
                enforceMandatoryFields: saveAttempted,
                excludeEEZ: true,
                focusFieldCtrl,
                msoComment: msoComment as UpdateMsoComment,
                msoCtrl,
                msos: msos as Array<UpdateMso>,
                refData,
                stockholdings,
                stocksOnBehalf: stocksOnBehalf as Array<MsoStockOnBehalf>,
                stockRefineriesHeld,
            })
        } else {
            // submit page validation messages
            return getSubmitValidationMessages({
                focusFieldCtrl,
                goToStep: progressCtrl.goToStep,
                refData,
                stockholdings: formCtrl.submissionUpdate.stockholdings
            });
        }

    }, [formCtrl, focusFieldCtrl, progressCtrl, refData, saveAttempted]);

    const notifications = useMemo(() => {
        if (validationMessages != null) {
            return progressCtrl.currentStep.kind === 'data' ?
              getDataNotifications({validationMessages,  msoProducts: refData.products} as Parameters<typeof getDataNotifications>[0]) :
              getSubmitNotifications({validationMessages, msoProducts: refData.products} as Parameters<typeof getSubmitNotifications>[0]);
        }

        return [];
    }, [progressCtrl.currentStep.kind, refData.products, validationMessages]);

    const isValidForSave = useCallback(() => {
        setSaveAttempted(true);

        if (progressCtrl.currentStep.kind === 'data') {
            const {msoCtrl, submissionUpdate: {msoComment, msos, stockholdings, stocksOnBehalf, stockRefineriesHeld}} = formCtrl;
            const vms = getDataValidationMessages({
                enforceMandatoryFields: true,
                excludeEEZ: true,
                focusFieldCtrl,
                msoComment: msoComment as UpdateMsoComment,
                msoCtrl,
                msos: msos as Array<UpdateMso>,
                refData,
                stockholdings,
                stocksOnBehalf,
                stockRefineriesHeld,
            });
            const notifications = getDataNotifications({validationMessages: vms, msoProducts: refData.products});

            return notifications.length === 0;
        }
        
        return true;
    }, [formCtrl, focusFieldCtrl, progressCtrl, refData]);

    useEffect(() => {
        setSaveAttempted(false);
    }, [progressCtrl.currentStep.index]);

    return {
        ...validationMessages,
        notifications,
        isValidForSave,
    }
}

export default useMsoRefinerValidation

export type UseMsoRefinerValidation = ReturnType<typeof useMsoRefinerValidation>;
