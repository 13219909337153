import { useMemo } from "react";

import { UseProductionProgress } from "./use-production-progress";
import { UseProductionServiceResponse } from "./use-production-service-response";

interface UseProductionValidationAlertsProps {
    progressCtrl: UseProductionProgress;
    serviceResponse: UseProductionServiceResponse;
}

function useProductionValidationAlerts({progressCtrl, serviceResponse}: UseProductionValidationAlertsProps) {
    const validationAlertsForCurrentStep = useMemo(() => {
        const currentStep = progressCtrl.currentStep;
        if (currentStep.kind === 'submit') {
            // TODO: if we need to expose any validation alerts on the submit page, do it here
            return [];
        }

        if (serviceResponse.validationAlerts == null) {
            return [];
        }

        return serviceResponse.validationAlerts;
    }, [progressCtrl.currentStep, serviceResponse.validationAlerts]);

    return {
        validationAlertsForCurrentStep,
    };
}

export default useProductionValidationAlerts

export type UseProductionValidationAlerts = ReturnType<typeof useProductionValidationAlerts>;
