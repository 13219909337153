import React from 'react';

const DataSubmissionPrimaryControls = ({children}: React.PropsWithChildren<{}>) => {
    return <nav aria-label='Data submission actions'>
        {children}
    </nav>
    
}

export default DataSubmissionPrimaryControls;
