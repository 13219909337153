import { StockProductDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

export type StockProduct = PopulatedProps<
    StockProductDTO,
    'displayOrder' | 'effectiveFrom' | 'id' | 'productCode' | 'productId' | 'productName' | 'stockProductGroupId' | 'stockProductGroupName'
>;

export function assertStockProduct(maybe?: unknown): asserts maybe is StockProduct {
    const asStockProduct = maybe as StockProduct;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asStockProduct, hint('stockProduct'));
    assertNotEmpty(asStockProduct.displayOrder, hint('stockProduct.displayOrder'));
    assertNotEmpty(asStockProduct.id, hint('stockProduct.id'));
    assertNotEmpty(asStockProduct.productCode, hint('stockProduct.productCode'));
    assertNotEmpty(asStockProduct.productId, hint('stockProduct.productId'));
    assertNotEmpty(asStockProduct.productName, hint('stockProduct.productName'));
    assertNotEmpty(asStockProduct.stockProductGroupId, hint('stockProduct.stockProductGroupId'));
    assertNotEmpty(asStockProduct.stockProductGroupName, hint('stockProduct.stockProductGroupName'));
}
