import { localDateStringToDate } from "psims/lib/date";
import { isValidLocalDate } from "psims/lib/validation/date";
import { recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { UpdateMsoStorageSiteDraft, UpdateMsoStorageSiteField } from "psims/models/submission-types/mso/annual-activity/update-mso-storage-site";
import { UseMsoAnnualActivityRefData } from "../use-mso-annual-activity-ref-data";

const CHAR_LIMIT = 200;
const MAX_STORAGE_PRICE = 10000000;

export type ValidationResult = ({
  key: UpdateMsoStorageSiteField;
} & (
  {
    code: 'character_limit';
    limit: number;
  } |
  {
    code: 'invalid_integer' | 'invalid_integer_range';
    max: number;
    min: number;
  } |
  {
    code: 'invalid_date_format' | 'invalid_date_ordering';
  }
)) | {
  key: 'delete';
  code: 'inactive_product'
}

export function validateStorageSite(storageSite: UpdateMsoStorageSiteDraft, refData: UseMsoAnnualActivityRefData): Array<ValidationResult> {
  const product = refData.productIdMap[storageSite.msoProductId];

  let messages: Array<ValidationResult> = [];

  if (product?.productStatus === 'inactive' && recordActionFromEnum(storageSite.recordAction) !== 'Delete') {
    messages.push({
      key: 'delete',
      code: 'inactive_product'
    });

    return messages;
  }

  // storage site
  if ((storageSite.storageSite?.length || 0) > CHAR_LIMIT) {
    messages.push({
      key: 'storageSite',
      code: 'character_limit',
      limit: CHAR_LIMIT,
    });
  }

  // storageLocation
  if ((storageSite.storageLocation?.length || 0) > CHAR_LIMIT) {
    messages.push({
      key: 'storageLocation',
      code: 'character_limit',
      limit: CHAR_LIMIT,
    });
  }

  let skipOrderingCheck = storageSite.storageFromDate == null || storageSite.storageToDate == null;

  // storageFromDate
  if (storageSite.storageFromDate != null && storageSite.storageFromDate !== '' && !isValidLocalDate(storageSite.storageFromDate)) {
    messages.push({
      key: 'storageFromDate',
      code: 'invalid_date_format'
    });

    skipOrderingCheck = true;
  }

  // storageToDate
  if (storageSite.storageToDate != null &&storageSite.storageFromDate !== '' && !isValidLocalDate(storageSite.storageToDate)) {
    messages.push({
      key: 'storageToDate',
      code: 'invalid_date_format'
    });

    skipOrderingCheck = true;
  }

  if (!skipOrderingCheck) {
    const from = localDateStringToDate(String(storageSite.storageFromDate));
    const to = localDateStringToDate(String(storageSite.storageToDate));

    if (from != null && to != null) {
      if (from.getTime() > to.getTime()) {
        messages.push({
          key: 'storageToDate',
          code: 'invalid_date_ordering',
        });
      }
    }
  }

  // storagePrice
  if (storageSite.storagePrice != null) {
    if (isNaN(storageSite.storagePrice) || String(storageSite.storagePrice).indexOf('.') > -1) {
        messages.push({
          key: 'storagePrice',
          code: 'invalid_integer',
          max: MAX_STORAGE_PRICE,
          min: 0,
        });
    } else {
      if (storageSite.storagePrice < 0 || storageSite.storagePrice > MAX_STORAGE_PRICE) {
        messages.push({
          key: 'storagePrice',
          code: 'invalid_integer_range',
          max: MAX_STORAGE_PRICE,
          min: 0,
        });
      }
    }
  }

  return messages;
}
