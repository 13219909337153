export function all<T>(arr: Array<T>, fn: (item: T) => boolean) {
    for (let i = 0; i < arr.length; i++) {
        if (!fn(arr[i])) {
            return false;
        }
    }

    return true;
}

export function any<T>(arr: Array<T>, fn: (item: T) => boolean) {
    for (let i = 0; i < arr.length; i++) {
        if (fn(arr[i])) {
            return true;
        }
    }

    return false;
}

// If item is in arr, remove item from arr, otherwise add item to end of arr
export function invertInclusion<T>(arr: Array<T>, item: T) {
    const without = arr.filter(i => i !== item);

    if (without.length < arr.length) {
        return without;
    }

    return [
        ...arr,
        item,
    ];
}

export function partition<T>(arr: Array<T>, fn: (item: T) => any) {
    if (arr.length === 0) {
        return [];
    }


    return arr.reduce<Array<Array<T>>>((partitions, item) => {
        let foundPartitionMembership = false;
        const key = String(fn(item));
        const next = partitions.map(p => {
            if (!foundPartitionMembership &&  String(fn(p[0])) === key) {
                foundPartitionMembership = true;
                return [
                    ...p,
                    item,
                ]
            }

            return p;
        });

        if (!foundPartitionMembership) {
            next.push([item]);
        }

        return next;
    }, []);
}

export function isArrayOfType<T>(assertion: TypeAssertion<T | null | undefined, T>, maybe?: Array<T | null | undefined> | null): maybe is Array<T> {
    return Array.isArray(maybe) &&
           all(maybe, assertion);
}

type Assert<T> = (maybe?: unknown) => asserts maybe is T;

export function assertArrayOfType<T>(assertion: Assert<T>, maybe?: unknown): asserts maybe is Array<T> {
    if (!Array.isArray(maybe)) {
        throw new Error('Failed array assertion: not an array');
    }

    maybe.forEach(assertion);
}

export function asArray<T>(maybe?: Array<T> | null): Array<T> {
    if (maybe == null) {
        return [];
    }

    return maybe;
}

export function toDictionary<K extends string, T extends {[k in K]: any}>(array: Array<T>, key: K): {[k in T[K]]: T} {
    return array.reduce((dic, element) => ({
        ...dic,
        [element[key]]: element,
    }), {} as {[k in T[K]]: T})
}

export function findWithIndex<T>(arr: Array<T>, fn: (item: T) => boolean): [T, number] | [null, -1] {
    for (let i = 0; i < arr.length; i++) {
        const candidate = arr[i];
        if (fn(candidate)) {
            return [candidate, i];
        }
    }

    return [null, -1];
}

export function uniq<T extends string | number>(arr: Array<T>): Array<T> {
    return Array.from(arr.reduce((s, v) => {
        s.add(v);
        return s;
    }, new Set<T>()));
}

export function uniqProperty<T extends {[k: string]: unknown}, K extends keyof T>(arr: Array<T>, prop: K): Array<T[K]> {
    return Array.from(arr.reduce((s, v) => {
        if (v[prop] != null) {
            s.add(v[prop]);
        }
        return s;
    }, new Set<T[K]>()));
}

export function sumByKey<T>(arr: Array<T>, key: keyof T) {
    return arr.reduce((sum, item) => sum + (isNaN(Number(item[key])) ? 0 : Number(item[key])), 0);
}
