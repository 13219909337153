import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { UpdateMsoAnnualActivityImporterField } from "psims/models/submission-types/mso/annual-activity/importer/update-mso-annual-activity-importer";
import { NotificationPart } from "../../../shared/notification-message";
import { ValidationResult } from "./importer-annual-activities-validation";
import { ValidationAlertResult } from "./importer-annual-activities-validation-alerts";
import { MAX_VOLUME } from "./shared";

const FIELD_LABELS: {[key in UpdateMsoAnnualActivityImporterField | 'totalAnnualVolume' | 'delete']: string} = {
  delete: 'delete row',
  enforceableArrangements: 'enforceable arrangements',
  totalAnnualVolume: 'total annual volume',
  totalImportVolumeOnBehalf: 'total import volume (on behalf of)',
  totalImportVolumeOthers: 'total import volume (by other entities)',
  totalImportVolumeSelf: 'total import volume (for self)',
  totalStorageCapacity: 'total storage capacity'
};

export function getValidationNotificationMessage(
  fieldName: UpdateMsoAnnualActivityImporterField | 'delete',
  onTargetClick: () => any,
  productName: string,
  validationResult: ValidationResult | null
) {
  if (validationResult == null) {
    return undefined;
  }

  const label = FIELD_LABELS[fieldName];

  const btn = {label: `Go to ${label} field`, onClick: onTargetClick, text: label};

  const parts: Array<NotificationPart> = [
    `For ${productName}: `,
  ]

  switch (validationResult.code) {
    case 'invalid_integer':
    case 'invalid_integer_range':
      parts.push('the value in ');
      parts.push(btn);
      parts.push(` must be ${validationResult.min === 0 ? 'a positive integer ' : 'an integer'} (zero is permitted) with a maximum value of ${validationResult.min < 0 ? '+/-' : ''}${localeNumberWithFixed(validationResult.max)}.`);
      break;

    case 'invalid_required':
      parts.push('a value for ');
      parts.push(btn);
      parts.push(' is required.')
      break;
    
    case 'inactive_product':
      parts.push('This product is inactive. Please enter these details into an active product if applicable. Delete ');
      parts.push({label: `Go to delete data button`, onClick: onTargetClick, text: 'product data'});
      parts.push(' to continue.');
      break;
  }

  return parts;
}

export function getInfoNotificationMessage(
  fieldName: UpdateMsoAnnualActivityImporterField | 'totalAnnualVolume',
  onTargetClick: () => any,
  onCommentsClick: () => any,
  productName: string,
  validationAlertResult: ValidationAlertResult | null
) {
  if (validationAlertResult == null) {
    return undefined;
  }

  const label = FIELD_LABELS[fieldName];

  const parts: Array<NotificationPart> = [
    `For ${productName}: `,
    {label: `Go to ${label} field`, onClick: onTargetClick, text: label},
  ];

  switch (validationAlertResult.code) {
    case 'MSOImportVolumeByOthersOutOfRange':
      parts.push(` is greater than ${localeNumberWithFixed(MAX_VOLUME)}. Please enter a reason in the `);
      parts.push({label: 'Go to comments field', onClick: onCommentsClick, text: 'comments'});
      parts.push(' field to confirm your data is correct.')
      break;

    case 'MSOImportVolumeOnBehalfOutOfRange':
      parts.push(` is greater than ${localeNumberWithFixed(MAX_VOLUME)}. Please enter a reason in the `);
      parts.push({label: 'Go to comments field', onClick: onCommentsClick, text: 'comments'});
      parts.push(' field to confirm your data is correct.')
      break;

    case 'MSOTotalImportVolumeNegative':
      parts.push(' calculated is a negative value. Please enter a reason in the ');
      parts.push({label: 'Go to comments field', onClick: onCommentsClick, text: 'comments'});
      parts.push(' field to confirm your data is correct.')
      break;
  }

  return parts;
}