import React, { PropsWithChildren, useMemo } from 'react';

import { isEmpty } from 'psims/lib/empty';
import { DataSubmissionStatus } from 'psims/models/data-submission';

/**
 * <main> container that conditionally sets the aria-describedby depending
 * on whether or the data submission has customer action comments
 */

interface MainContainerProps {
  dataSubmission: {
    customerActionComments?: string | null;
    status: DataSubmissionStatus;
  }
  'ariaDescribedby'?: string;
}

const MainContainer = ({ariaDescribedby, children, dataSubmission}: PropsWithChildren<MainContainerProps>) => {
  const _ariaDescribedBy = useMemo(() => {
    if (!isEmpty(dataSubmission.customerActionComments) && (
      dataSubmission.status === 'Draft' ||
      dataSubmission.status === 'Action required'
    )) {
      return 'customer_action_comments'
    };
    
    return ariaDescribedby;
  }, [ariaDescribedby, dataSubmission.customerActionComments, dataSubmission.status]);

  return <main
    aria-describedby={_ariaDescribedBy}
  >
    {children}
  </main>
}

export default MainContainer;
