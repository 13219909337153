import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { NotificationPart } from "../../shared/notification-message";
import { ValidationResult } from "./validate-activity";
import { ValidationAlertResult } from "./activity-validation-alerts";
import { DefActivityField } from "psims/models/submission-types/def/def-activity";
import { getActivityFieldLabel } from "./util";

export function getValidationNotificationMessage(
  fieldName: DefActivityField | 'delete',
  onTargetClick: () => any,
  productName: string,
  validationResult: ValidationResult | null,
  forPage: 'data' | 'submit',
  clearRow?: () => any
) {
  if (validationResult == null) {
    return undefined;
  }

  const label = (validationResult.code === 'product_expired' || fieldName === 'delete') ? 'product' : getActivityFieldLabel(fieldName);

  const btnGoToField = {label: `Go to ${label} field`, onClick: onTargetClick, text: label};
  const btnClearRow = {label: `Clear data for product ${productName}`, onClick: clearRow || (() => {}), text: 'remove row'};

  const parts: Array<NotificationPart> = [
    `For ${productName}: `,
  ]

  switch (validationResult.code) {
    case 'invalid_non_integer':
    case 'invalid_out_range':
      parts.push('the value in ');
      parts.push(btnGoToField);
      parts.push(` must be ${validationResult.min === 0 ? 'a positive integer ' : 'an integer'} (zero is permitted) with a maximum value of ${validationResult.min < 0 ? '+/-' : ''}${localeNumberWithFixed(validationResult.max)}.`);
      break;

    case 'invalid_required':
      parts.push('Where a product has a value in a row, all fields in the row are required. A value for ');
      parts.push(btnGoToField);
      parts.push(' is required.')
      break;
  
    case 'product_expired': {
      if (forPage === 'data') {
        parts.push('The ');
        parts.push(btnGoToField);
        parts.push(' is inactive. Please enter these details into an active product if applicable and then ');
        parts.push(btnClearRow);
        parts.push(' to continue.');
      } else  {
        parts.push('The ');
        parts.push(btnGoToField);
        parts.push(' is inactive. Please review before submitting');
      }
    }
  }

  return parts;
}

export function getInfoNotificationMessage(
  fieldName: DefActivityField,
  onTargetClick: () => any,
  productName: string,
  validationAlertResult: ValidationAlertResult | null,
  onRelatedClick?: () => any,
  relatedFieldName?: DefActivityField
) {
  if (validationAlertResult == null) {
    return [];
  }

  const label = getActivityFieldLabel(fieldName);

  const relatedLabel = relatedFieldName ? getActivityFieldLabel(relatedFieldName) : null;

  const parts: Array<NotificationPart> = [
    `For ${productName}: `,
  ];

  switch (validationAlertResult.code) {
    case 'percent_variance':
      parts.push('The value for ');
      parts.push({label: `Go to ${label} field`, onClick: onTargetClick, text: label});
      parts.push(` is ${validationAlertResult.percentVariance}% or more outside of your previous submission. Please enter a reason in the comments field on the declaration page to confirm your data is correct.`);
      break;

    case 'relativity_for_derived_def':
      parts.push('Your reported amount of DEF ');
      parts.push({label: `Go to ${label} field`, onClick: onTargetClick, text: label});
      parts.push(' is outside the expected amount based on your reported TGU ');
      parts.push({label: `Go to ${relatedLabel} field`, onClick: onRelatedClick, text: relatedLabel});
      parts.push('. Please enter a reason in the comments field on the declaration page to confirm your data is correct.');
      break;
  }

  return parts;
}