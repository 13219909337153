import { useCallback, useEffect, useState } from "react";

import { isArrayOfType } from "psims/lib/collections";
import { Document, isDocument } from "psims/models/document";
import { useAPI } from "psims/react/providers/api";
import { useLogger } from "psims/react/providers/logging";

function useDocumentList() {
  const {api} = useAPI();
  const logger = useLogger({source: 'useDocumentList'});
  const [documents, setDocuments] = useState<Array<Document> | null>(null);

  const fetch = useCallback(() => {
    api.getDocumentList()
      .then(response => {
        const result = response?.result;
        if (result != null && result.documentItems != null && isArrayOfType(isDocument, result.documentItems)){
          setDocuments(result.documentItems as Array<Document>);
        }
      })
      .catch(logger.exception)

  }, [api, logger.exception])

  const addDocument = useCallback((document: Document) => {
    const docs = documents || [];
    setDocuments([
      ...docs,
      document
    ]);
  }, [documents]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    addDocument,
    fetch,
    documents,
  };
}

export default useDocumentList

export type UseDocumentList = ReturnType<typeof useDocumentList>;
