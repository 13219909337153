import { OrganisationVM, UpdateOrganisationVM } from "psims/gen/xapi-client";
import { isArrayOfType } from "psims/lib/collections";
import { isOrganisationContact, OrganisationContact, OrganisationContactTypeName, UpdateOrganisationContact } from "./organisation-contact";

// Note we shouldn't need to export the array of type names - they get exposed as a union type
const ORGANISATION_TYPE_NAMES = ['Head Company', 'Subsidiary'] as const;
const ORGANISATION_STATUSES = ['Active', 'Inactive'] as const;

type OrganisationTypeName = (typeof ORGANISATION_TYPE_NAMES)[number]; // Same result as ArrayElement<T>

type OrganisationStatus = (typeof ORGANISATION_STATUSES)[number];

export type UpdateOrganisation = UpdateOrganisationVM;

export type Organisation = PopulatedProps<OrganisationVM, 
    // List all the non-nullable and app-relevant values for this entity
    'id' | 'name'
> & {
    // Add any typed unions
    organisationType: OrganisationTypeName;
    status: OrganisationStatus;
    // Add populated organisationContacts type overload
    organisationContacts: Array<OrganisationContact>;
};

export function isOrganisation(maybe?: OrganisationVM | null): maybe is Organisation {
    // Assert required properties exist on source
    return (
        maybe != null &&
        maybe.id != null &&
        maybe.name != null &&
        maybe.organisationType != null && ORGANISATION_TYPE_NAMES.includes(maybe.organisationType as OrganisationTypeName) &&
        maybe.status != null && ORGANISATION_STATUSES.includes(maybe.status as OrganisationStatus) &&
        isArrayOfType(isOrganisationContact, maybe.organisationContacts)
    );
}

export function organisationContact(organisation: UpdateOrganisation, contactType: OrganisationContactTypeName): UpdateOrganisationContact | null | undefined {
    // Strictly this check is no longer necessary, as the Organisation type asserts that there'll always be
    // a organisationContacts array (even if it is empty)
	if (!organisation.organisationContacts) {
        return null;
    }
    let updateContact: UpdateOrganisationContact = {
        contactType: contactType,
        firstName: '',
        lastName: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: ''
    };

    let contact = organisation.organisationContacts
                                .filter(isOrganisationContact)
                                // Now we'll get intellisense help in VSCode when entering the contactType we wish to find:
                                .find((o) => {return o.contactType === contactType});
    
	return contact || updateContact;
}
