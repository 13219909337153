import { css } from "styled-components"

import { SPACE_BASE } from "psims/constants/styles"

export interface FieldProps {
	align?: 'center' | 'left' | 'right';
	disabled?: boolean;
    error?: string | null;
	$height?: string;
	readOnly?: boolean;
	unstyled?: boolean;
	width?: string;
}

export const fieldStyle = css`${(props: FieldProps) => `
	height: 54px;
    border-radius: 0px;
	box-sizing: border-box;
	font-size: 16px;
	padding: ${SPACE_BASE}px ${SPACE_BASE * 2}px;
	text-align: ${props.align};
	transition: box-shadow var(--transition-duration-xfast) ease-in-out;
	${(Boolean(props.width) ? `width: ${props.width == null}` : '')};

	border: 1px solid ${props.unstyled ? 'transparent' : 'var(--color-black-90)'};

	&:focus,
	&:focus-within {
		outline: none;
	}

	${Boolean(props.disabled) ? `
		background-color: transparent;
		pointer-events: none;
	` : ''}

	${props.unstyled ? `
	height: ${(props.$height ? props.$height : 'auto')};
	padding: 0;
	`: ''}

	${!props.unstyled ? `
	&:hover {
		border: 1px solid var(--color-black-90);
	}

	${Boolean(props.disabled) || Boolean(props.readOnly) ? `
		border-color: var(--color-black-50);
		color: var(--color-black-70);
		opacity: 1;
	`: `
	&:focus,
	&:focus-within {
		border-color: var(--color-focus);
		box-shadow: var(--box-shadow-focus);
	}
	`}

	${Boolean(props.error) ? `
	border-color: var(--color-negative-100);
	box-shadow: var(--box-shadow-error);
	` : ''}
	` : ''}
`}`;
