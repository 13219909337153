import { useMemo } from "react";
import useDataSubmissionControls from "../shared/use-data-submission-controls";
import { UseSubmit } from "../shared/use-submit";
import { ViewMode } from "../shared/use-view-mode";
import { UseFspAPI } from "./use-fsp-api";
import { UseFspNavigation } from "./use-fsp-navigation";
import { UseFspProgress } from "./use-fsp-progress";

interface UseFspControlsProps {
  apiCtrl: UseFspAPI;
  navCtrl: UseFspNavigation;
  progressCtrl: UseFspProgress;
  submitCtrl: UseSubmit;
  viewMode: ViewMode;
}

function useFspControls({apiCtrl, navCtrl, progressCtrl, submitCtrl, viewMode}: UseFspControlsProps) {
  const formCtrl = useMemo(() => ({
    view: {
      Submit: {
        isValid: submitCtrl.commentsError == null,
      }
    }
  }), [submitCtrl.commentsError]);

  const saveCtrl = useMemo(() => {
    const saveAndGoToPage = navCtrl.attemptSaveAndGoToPage;
    return {
      saveAndGoToPage,
    };
  }, [navCtrl.attemptSaveAndGoToPage])

  const ctrl = useDataSubmissionControls({
    apiCtrl,
    formCtrl,
    progressCtrl,
    saveCtrl,
    submitCtrl: {submit: submitCtrl.attemptSubmit},
    viewMode,
  });

  return useMemo(() => ctrl, [ctrl])
}

export default useFspControls

export type UseFspControls = ReturnType<typeof useFspControls>;
