import React, { ReactNode } from 'react';

import Button from 'psims/react/components/button';
import Text from 'psims/react/components/text';

type Quicklink = {
    label: string;
    onClick: () => any;
    text: ReactNode;
}

export type NotificationPart = ReactNode | Quicklink;

interface Props {
    parts: Array<NotificationPart>;
}

const NotificationMessage = ({parts}: Props) => {
    return (
        <Text>
            {
                parts.map((part, index) => (
                    isQuicklink(part) ?
                    <Button
                        key={index}
                        $kind='text'
                        aria-label={part.label}
                        onClick={part.onClick}
                    >{part.text}</Button> :
                    part
                ))
            }
        </Text>
    )
}

function isQuicklink(maybe?: unknown): maybe is Quicklink {
    const maybeAs = maybe as Quicklink;
    return (
        maybeAs != null &&
        maybeAs.label != null &&
        maybeAs.onClick != null &&
        maybeAs.text != null
    );
}

export default NotificationMessage;
