import { ChangeEvent, FocusEvent, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";

import { BoxedSpan } from "psims/react/components/layout"
import { deExponentialise, parseNumber } from "psims/lib/number";
import styled from "styled-components";
import FloatingMessage from "psims/react/components/floating-message";
import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import randomID from "psims/lib/random-id";
import useDisplayValue from "psims/react/util/use-display-value";
import useFocusable from "psims/react/util/use-focusable";
import useIsDirty from "psims/react/util/use-is-dirty";
import useSelectAllOnFocus from "psims/react/util/use-select-all-on-focus";
import {useIsFocusedAlt as useIsFocused} from "psims/react/util/use-is-focused";
import useIsAutoFocused from "psims/react/util/use-is-autofocused";
import VisuallyHidden from "psims/react/components/visually-hidden";

type NumberInput = {
    kind: 'number';
    onChange: (val: number | undefined) => any;
    value: number | null | undefined;
};

type StringInput = {
    kind: 'string';
    onChange: (val: string | undefined) => any;
    value: string | null | undefined;
}

type InputKind = NumberInput | StringInput;

type InputProps = InputKind & {
    align?: 'center' | 'left' | 'right';
    autoFocus?: boolean;
    disabled?: boolean;
    displayFixedDecimals?: number;
    error?: string;
    forceError?: boolean;
    helpId?: string;
    info?: string;
    label?: string;
    placeholder?: string;
    shouldFocus: boolean;
}

const StyledLabel = styled.label`
    width: 100%;
`;

const MaybeWithLabel = ({children, label}: PropsWithChildren<{label?: string}>) => {
    return label ?
    <StyledLabel>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
    </StyledLabel> : <>{children}</>
}

const Input = (props: InputProps) => {
    const vm = useVM(props);

    return (
        <MaybeWithLabel label={props.label}>
            <BoxedSpan box={{alignItems: 'center', flex: 'row', flexGrow: 1}}>
                <StyledInput
                    align={props.align || (props.kind === 'number' ? 'right' : 'left')}
                    aria-errormessage={vm.showError ? vm.describedbyId : undefined}
                    aria-invalid={vm.showError ? true : undefined}
                    aria-describedby={props.helpId ? props.helpId : undefined}
                    className={`${vm.showError ? 'field-error' : ''}`}
                    disabled={props.disabled}
                    error={vm.showError}
                    inputMode={props.kind === 'number' ? 'decimal' : 'text'}
                    onBlur={vm.onBlur}
                    onChange={vm.onChange}
                    onFocus={vm.onFocus}
                    placeholder={props.placeholder}
                    ref={vm.ref}
                    type='text'
                    value={vm.displayValue ?? ''}
                />

                {
                    (vm.showError || props.info) ? 

                    <BoxedSpan box={{alignItems: 'center', flex: 'row', marginLeft: -3}}>
                        {
                            vm.showError ?

                            <FloatingMessage
                                content={props.error}
                                id={vm.describedbyId}
                                kind="warning"
                                role="alert"
                                showContent={vm.isFocused}
                            /> :

                            props.info ? <FloatingMessage
                                content={props.info}
                                id={vm.describedbyId}
                                kind="info"
                                role="alert"
                                showContent={vm.disabled ? undefined : vm.isFocused}
                            /> : null
                        }
                    </BoxedSpan> :

                    null
                }
            </BoxedSpan>
        </MaybeWithLabel>
    )
}

function useVM(props: InputProps) {
    const shouldAutoFocus = useIsAutoFocused(Boolean(props.autoFocus));
    const valueCtrl = useFieldValue(props);
    const {displayValue, setRef} = useDisplayValue({formatter: v => localeNumberWithFixed(v, props.displayFixedDecimals), value: valueCtrl.displayValue});
    const {setRef: setRefSelect} = useSelectAllOnFocus();
	const {setRef: setFocusableRef} = useFocusable({setFocused: props.shouldFocus || shouldAutoFocus});
    const {setRef: setRefIsFocused, isFocused} = useIsFocused()
    const [describedbyId] = useState(randomID());
    const {handleFocus: isDirtyHandleFocus, handleChange: isDirtyHandleChange, isDirty} = useIsDirty(false, undefined);
    
    const onBlur = useMemo(() => {
        const val = valueCtrl.dataValue;
        if (props.value === val) {
            return () => {}
        }

        return () => props.onChange(getDataValue(val, props.kind) as undefined)
    }, [props, valueCtrl]);
    
    const showError = Boolean(props.error) && (isDirty || Boolean(props.forceError));

    const consolidatedRef = (el: Parameters<typeof setRef>[0] & Parameters<typeof setRefSelect>[0] & Parameters<typeof setFocusableRef>[0]) => {
        setRef(el);
        setRefSelect(el);
        setFocusableRef(el);
        setRefIsFocused(el);
    }

    const handleBlur = useCallback((evt: FocusEvent<HTMLInputElement>) => {
        onBlur();
    }, [onBlur]);

    const handleFocus = useCallback((evt: FocusEvent<HTMLInputElement>) => {
        isDirtyHandleFocus(evt);
    }, [isDirtyHandleFocus]);

    const handleChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        isDirtyHandleChange(evt);
        valueCtrl.onChange(evt.target.value);

        // Trigger change immediately if this field has an error or info alert
        if (props.error || props.info) {
            props.onChange(getDataValue(evt.target.value, props.kind) as undefined);
        }
    }, [isDirtyHandleChange, valueCtrl, props]);

    return {
        describedbyId,
        disabled: false,
        displayValue,
        isFocused,
        onBlur: handleBlur,
        onFocus: handleFocus,
        onChange: handleChange,
        ref: consolidatedRef,
        showError,
        value: valueCtrl.displayValue,
    };
}

type UseFieldValueProps = InputKind;

function useFieldValue({kind, value}: UseFieldValueProps) {
    const [displayValue, setDisplayValue] = useState<string>(`${value ? value : ''}`);
    const [dataValue, setDataValue] = useState<number | string | undefined>(getDataValue(value, kind));

    const onChange = useCallback((val: string) => {
        setDisplayValue(val);
        setDataValue(kind === 'number' ? toNumber(val) : `${val}`);
    }, [kind]);

    // Update values when prop changes - but keep string value for invalid numbers
    useEffect(() => {
        const dataVal = getDataValue(value, kind);
        setDataValue(dataVal);

        if (kind === 'string') {
            setDisplayValue(`${value ? value : ''}`);
        } else if (dataVal != null && !isNaN(Number(dataVal))) {
            // Only update string value for valid numbers (otherwise, use inputs local state until
            // number is valid, or field is blurred)
            setDisplayValue(deExponentialise(value as number))
        } else if (dataVal === undefined) {
            setDisplayValue('');
        }

    }, [kind, value]);

    return {
        dataValue,
        displayValue,
        onChange
    };
}

function getDataValue(value: string | number | null | undefined, kind: 'number' | 'string') {
    if (value == null) {
        return undefined;
    }
    
    return kind === 'number' ? toNumber(value) : `${value}`;
}

interface StyledInputProps {
    align?: 'center' | 'left' | 'right';
    error: boolean;
}

const StyledInput = styled.input`${(props: StyledInputProps) => `
    &,
    &:focus,
    &:focus-visible {
        border: 1px solid var(--color-field-border);
        border-radius: 0;
        outline: none;
    }
    &:focus,
    &:focus-visible {
        box-shadow: var(--box-shadow-focus);
    }
    &.field-error:not(:focus) {
        border: 1px solid var(--color-error-border);
        box-shadow: var(--box-shadow-error);
    }
    flex: 1;
    font-size: 16px;
    height: 48px;
    padding-right: 28px;
    padding-left: 8px;
    text-align: ${props.align || 'left'};
    transition: box-shadow 300ms ease-in-out;
    width: 100%;
`}`;

// helpers
function toNumber(val: string | number | null | undefined): number | undefined {
    if (val == null || val === '') {
        return undefined;
    }

    try {
        return parseNumber(val);
    } catch (e) {
        return Number(val);
    }
}

export default Input;
