import { STOCKHOLDING_TYPES } from "psims/constants/data-submissions";
import { Stockholding } from "psims/models/submission-types/stockholding";
import Expandable from "psims/react/components/expandable";
import HelpBlock from "psims/react/components/help";
import { BoxedDiv } from "psims/react/components/layout";
import Text from "psims/react/components/text";
import { U } from "psims/react/components/typography";

const stepConfig = (stockholding: Stockholding) => ({
    [STOCKHOLDING_TYPES.australia]: {
        title: 'Australia - held on land',
        Help: () => {
            return (
                <>
                <p>
                    <Text>Please report the total quantity <strong>that you own</strong> of each product held in the relevant state at the end of the month.</Text>	
                </p>

                <p>
                    <Text>Quantities should be reported in <strong>metric tonnes</strong> unless volume is specified in the guidance notes. Volumetric information should be adjusted to standard temperature and pressure conditions (15°C &amp; 1 atmosphere pressure (101.325kPa)).</Text>	
                </p>

                <p>
                    <Text>Please report total stocks on land within all of Australia in this tab. Refiners <U>must</U> exclude stocks held in refineries, which should instead be reported in the Refining Report.</Text>	
                </p>

                <BoxedDiv box={{marginTop: 2}}>
                    <Expandable
                        collapsedLabel='Show more information on the stock you should be reporting'
                        expandedLabel='Hide more information on the stock you should be reporting'
                        contentPlacement='below' 
                    >
                        <HelpBlock box={{flex: 'column', marginBottom: 0.5, marginTop: 1}}>
                            <p>This section covers all stock that is owned by your business and is held, on land, in Australia. Please enter the total quantity of stock you owned by the state and territory it was located in at the end of the month. Include any stock held in the ACT in the NSW column.</p>

                            <p><strong>Including:</strong></p>

                            <ul>
                                <li>Product held at import terminals;</li>
                                <li>Product held at marketing and other bulk terminals;</li>
                                <li>Product held at refinery terminals (but only if you have taken ownership of the stock and are awaiting delivery from the refinery – Refiners should report their stocks separately in the Refining Report);</li>
                                <li>Product of aviation fuel held at airports, including joint venture arrangements such as JUHI;</li>
                                <li>Stocks of marine fuel held at marine depots.</li>
                            </ul>

                            <p><strong>Excluding:</strong></p>

                            <ul>
                                <li>Product that you <strong>do not own</strong> that is held by you on behalf of another company;</li>
                                <li>Product held wholly or principally for use by households and private individuals;</li>
                                <li>Product held at service stations or at retail stores;</li>
                                <li>Product held in a personal vehicle (e.g. a car), road tanker (e.g. fuel truck), rail tank car or fuel pipeline;</li>
                                <li>Product held in a ship’s bunker (i.e. fuel for the purpose of powering the ship);</li>
                                <li>Product held exclusively for the use of the Australian Defence Force or another military force.</li>
                            </ul>

                            <p>If you own a covered product that is commingled with other businesses’ stock (including airport joint ventures e.g. JUHI), you should only include your portion of the commingled stock. Any volume that is ‘owing’ to a commingled stock should be counted as a negative volume.</p>

                            <p><strong>Do not include</strong> any amounts belonging to other businesses in tanks or facilities that you own or operate (whether a fee for storage is charged or not).</p>

                            <p><strong>Petroleum-based greases, lubricants, oils, waxes and solvents (GLOWS) and petroleum coke.</strong> </p>
                            <p>The POFR Rules provide a reduced reporting frequency for petroleum-based greases, lubricants, lubricating oil base stock, paraffin waxes and solvents (GLOWS) and petroleum coke.</p>
                            <p>Reports for stockholdings of petroleum coke have a yearly reporting period and are due four months after the end of the financial year.</p>
                            <p>Reports for (GLOWS) have a half yearly reporting period and are due on 31 July and 31 January every year.</p>
                            <p>Reporting these quantities on a monthly basis is voluntary, but encouraged.</p>
                        </HelpBlock>
                    </Expandable>
                </BoxedDiv>
                </>
            );
        }
    },

    [STOCKHOLDING_TYPES.overseas]: {
        title: 'Overseas - held outside Australia',
        Help: () => {
            return (<>
                <p>
                    <Text><strong>Stored overseas</strong> – Stocks that you own (or will own after unloading in Australia) at the end of the month that are held in storage outside Australia where the product is destined for Australia.</Text>	
                </p>

                <p>
                    <Text>Quantities should be reported in <strong>metric tonnes</strong> unless volume is specified in the guidance notes.  Volumetric information should be adjusted to standard temperature and pressure conditions (15°C &amp; 1 atmosphere pressure (101.325kPa)).</Text>	
                </p>

                <BoxedDiv box={{marginTop: 2}}>
                    <Expandable
                        collapsedLabel='Show more information on the stock you should be reporting'
                        expandedLabel='Hide more information on the stock you should be reporting'
                        contentPlacement='below' 
                    >
                        <HelpBlock box={{flex: 'column', marginBottom: 0.5, marginTop: 1}}>
                            <p>This section covers, as at end of month, stockholdings of covered products that your business owns under contractual arrangements (or will own after unloading in Australia) that are held in storage outside Australia where the covered product is destined for Australia and for use in Australia. Please record the country or countries where this stock was stored. You can insert extra rows where required.</p>

                            <p>Please note that “held in storage” does not necessarily mean long term storage.</p>

                            <p>Do not report stock held in tanker ships already in transit to Australia. These stocks should be reported in the ‘On Water’ section.</p>

                            <p><strong>Including:</strong></p>

                            <ul>
                                <li>Product held at overseas storage, including export and refinery terminals, if that product is held for use by your business in Australia.</li>
                            </ul>

                            <p><strong>Excluding:</strong></p>

                            <ul>
                                <li>Product held in a tanker ship already in transit to Australia.</li>
                                <li>Product held overseas that is not held for use in Australia; and</li>
                                <li>Product held in a ship’s bunker (i.e. fuel for the purpose of powering the ship).</li>
                            </ul>
                            
                            <p><strong>Petroleum-based greases, lubricants, oils, waxes and solvents (GLOWS) and petroleum coke.</strong> </p>
                            <p>The POFR Rules provide a reduced reporting frequency for petroleum-based greases, lubricants, lubricating oil base stock, paraffin waxes and solvents (GLOWS) and petroleum coke.</p>
                            <p>Reports for stockholdings of petroleum coke have a yearly reporting period and are due four months after the end of the financial year.</p>
                            <p>Reports for (GLOWS) have a half yearly reporting period and are due on 31 July and 31 January every year.</p>
                            <p>Reporting these quantities on a monthly basis is voluntary, but encouraged.</p>
                        </HelpBlock>
                    </Expandable>
                </BoxedDiv>
            </>);
        }
    },

    [STOCKHOLDING_TYPES.on_water]: {
        title: 'Foreign locations and on water',
        Help: () => {
            return (<>
                <p>
                    <Text>Quantities should be reported in <strong>metric tonnes</strong> unless volume is specified in the guidance notes.  Volumetric information should be adjusted to standard temperature and pressure conditions (15°C &amp; 1 atmosphere pressure (101.325kPa)).</Text>	
                </p>

                <p>
                    <Text>Only report stocks intended to be supplied to Australia.</Text>	
                </p>

                <BoxedDiv box={{marginTop: 2}}>
                    <Expandable
                        collapsedLabel='Show more information on the stock you should be reporting'
                        expandedLabel='Hide more information on the stock you should be reporting'
                        contentPlacement='below' 
                    >
                        <HelpBlock box={{flex: 'column', marginBottom: 0.5, marginTop: 1}}>
                            <p>This section covers stocks of major covered products that are held in a seagoing tanker ship in transit to Australia. Your business should report products that you own and will continue to own upon arrival in Australia, as well as products your business does not currently own but will own when the products arrive in Australia. For example, where your stock will be owned by an overseas seller or trader until it is unloaded in Australia.</p>

                            <p>In this case, the covered product should only be reported if it is intended to be unloaded from the ship at an Australian port, and is not stored in the ship’s bunker.</p>

                            <p>Report in the appropriate category that applied at the end of the reporting period (not the current status of the stock).</p>


                            <p><strong>The reporting categories are:</strong></p>

                            <ol>
                                <li>“In Foreign Ports”: report the total quantity of stock of a covered product owned at the end of the reporting period (or that will be owned after it is unloaded in Australia) stored in tankers in foreign ports which is yet to leave the port of loading. Please record the country or countries where the tankers were located.</li>
                                <li>“High Seas”: report the total quantity of stock owned at the end of the reporting period (or that will be owned after it is unloaded in Australia) which was stored in tankers that had left a foreign port but had not yet entered Australia’s Exclusive Economic Zone (EEZ).</li>
                                <li>“EEZ”: report the total quantity of stock of a covered product owned at the end of the reporting period (or that will be owned after it is unloaded in Australia) which was stored in tankers that have entered Australia’s EEZ but were yet to meet the conditions of ‘domestic shipping’.</li>
                                <li>
                                    “Domestic Shipping”: report the total quantity of stock of a covered product owned at the end of the reporting period (or that will be owned after it is unloaded in Australia) which is stored in a tanker that:
                                    <ol type='a'>
                                        <li>is at an Australian port; or</li>
                                        <li>has issued a “notice of readiness” with respect to an Australian port in preparation for unloading; or</li>
                                        <li>is travelling between two Australian ports (if the vessel will later unload cargo in an overseas port, only the amount to be unloaded in Australia should be counted).</li>
                                    </ol>
                                </li>
                            </ol>

                            <p>If a vessel has been loaded in a foreign port but has not yet issued a notice of readiness in Australia and the exact location is not known, stocks should be reported under either 'High seas', or ‘EEZ’, whichever you reasonably believe to be more likely.</p>

                            <p>When reporting on stocks of covered products stored overseas and in foreign ports, please provide separate quantities for each country in which a product is located. You must list all the countries where stock is held and can insert extra rows if required.</p>

                            <p><strong>Including:</strong></p>

                            <ul>
                                <li>Product loaded on board a tanker that is in a foreign port, if that product is destined for Australia;</li>
                                <li>Product which is on board a tanker that is in transit to, or has arrived in, Australia, if that product is destined for Australia.</li>
                            </ul>

                            <p><strong>Excluding: </strong></p>

                            <ul>
                                <li>Product held in a ship’s bunker (i.e. fuel for the purpose of powering the ship);</li>
                                <li>Product held on-water that is not destined to be unloaded in Australia</li>
                            </ul>
                        </HelpBlock>
                    </Expandable>
                </BoxedDiv>
           </>);
        }
    },

    Submit: {
        title: 'Declaration',
        Help: () => null,
    }
});

export default stepConfig;
