import { useMemo } from "react";

import { useReferenceData } from "psims/react/providers/api/reference-data";
import { toDictionary } from "psims/lib/collections";
import { isValidForPeriod } from "psims/models/ref-data/util";
import usePortalData from "../../shared/use-portal-data";
import { SubmissionType } from "psims/models/ref-data/submission-type";

interface DataSubmission {
    reportingPeriodFrom: string;
    reportingPeriodTo: string;
    submissionTypeName: string;
}

interface UseMsoAnnualActivityRefDataProps {
    dataSubmission: DataSubmission;
}


function useMsoAnnualActivityRefData({dataSubmission}: UseMsoAnnualActivityRefDataProps) {
    const {data: refData} = useReferenceData();

    const storageTypes = useMemo(() => {
        return refData?.storageTypes || [];
    }, [refData]);

    const submissionType = useMemo(() => {
      if (dataSubmission == null || refData == null) {
        return null;
      }

      return refData.submissionTypes.find(st => st.name === dataSubmission.submissionTypeName);
    }, [refData, dataSubmission]);

    const portalDataCtrl = usePortalData({submissionType: submissionType as SubmissionType});

    const products = useMemo(() => {
        return (refData?.msoProducts || [])
            .map(p => {
                const Help = portalDataCtrl?.productPortalData.find(ppd => ppd?.referenceCode === p.code);
                return {
                    ...p,
                    Help,
                    productStatus: (
                        isValidForPeriod(p, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo) ?
                        'active' :
                        'inactive'
                    ) as 'active' | 'inactive'
                }
            })
            .sort((a, b) => a.displayOrder - b.displayOrder);
    }, [dataSubmission, portalDataCtrl, refData]);

    const productIdMap = useMemo(() => {
        return toDictionary(products, 'id')
    }, [products]);

    return {
        productIdMap,
        products,
        storageTypes,
    };
}

export default useMsoAnnualActivityRefData

export type UseMsoAnnualActivityRefData = ReturnType<typeof useMsoAnnualActivityRefData>;
