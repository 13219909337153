import { isSuccesfulRowResult, RecordResult } from "psims/models/api/record-result";
import { APIResponse, isSuccessfulAPIResponseWithResult, ValidationMessage } from "psims/models/api/response";
import { isRefiningSubmission, MaybeRefiningSubmission, RefiningSubmission } from "psims/models/submission-types/refining";
import { useCallback, useMemo } from "react";
import { SubmissionStatus } from "psims/react/pages/primary-pages/data-submissions/shared/api";
import { UseRefineryAPI } from "./use-refinery-api";
import { isValidationAlert, ValidationAlert } from "psims/models/api/submission/validation-alert";
import { RowResultEnum } from "psims/constants/data-submissions";
import { stringifyServerError } from "psims/lib/server-error";

interface UseRefinerySubmissionResponseProps {
    apiCtrl: UseRefineryAPI;
}

type ValidationAlertView = {
    validationAlert: ValidationAlert;
    productId: number;
}

type RefineryServiceResponse = {
    notOKRecordResults: Array<RecordResult> | null;
    serverErrorMessages: Array<string> | null;
    status: SubmissionStatus;
    submission: RefiningSubmission | null;
    validationAlerts: Array<ValidationAlertView> | null;
    validationMessages: Array<ValidationMessage> | null;
}

function useRefineryServiceResponse({apiCtrl}: UseRefinerySubmissionResponseProps) {
    const { isBusy, loadStatus, submission, updateError, updateResponse} = apiCtrl; 

    const buildServiceResponse = useCallback<() => RefineryServiceResponse>(() => {
        if (isBusy) {
            return {
                notOKRecordResults: null,
                serverErrorMessages: null,
                status: loadStatus,
                submission: submission ? submission : null,
                validationAlerts: null,
                validationMessages: null
            };
        }

        const newUpdateResponse = updateResponse || {};
        const recordResults = collectSubmissionRecordResults(newUpdateResponse)
        return {
            notOKRecordResults: recordResults == null ? null : recordResults.filter(rr => !isSuccesfulRowResult(rr, true)),
            serverErrorMessages: ((newUpdateResponse.errorMessages?.length || 0) > 0 && newUpdateResponse.errorMessages) || (updateError ? [stringifyServerError(updateError)] : null) || null,
            status: loadStatus,
            submission: submission ? submission : null,
            validationAlerts: submission ? collectSubmissionValidationAlerts(submission) : [],
            validationMessages: newUpdateResponse.validationMessages || null
        } as RefineryServiceResponse;
    }, [isBusy, updateResponse, updateError, loadStatus, submission]);

    const refineryServiceResponse = useMemo(() => {
        return buildServiceResponse();
    }, [buildServiceResponse]);

    return refineryServiceResponse;
}

export default useRefineryServiceResponse

export type UseRefineryServiceResponse = ReturnType<typeof useRefineryServiceResponse>;

// Helpers
function collectSubmissionRecordResults(maybeResponse?: APIResponse<MaybeRefiningSubmission>): Array<RecordResult> | null {
    if (maybeResponse == null || !isSuccessfulAPIResponseWithResult(maybeResponse, isRefiningSubmission)) {
        return null;
    }

    const refiningRecordResults = (maybeResponse.result.refinings || [])
        .map(r => r.recordResult)
        .filter(r => r != null) as Array<RecordResult>;

    const commentsRecordResults = (maybeResponse.result.refineryComments || [])
        .map(r => r.recordResult)
        .filter(r => r != null) as Array<RecordResult>;

    const dataSubmissionRecordResult = maybeResponse.result.dataSubmission?.recordResult;

    const formDataRecordResult = maybeResponse.result.submissionFormData?.formDataResult;

    const allRecordResults = [
        ...refiningRecordResults,
        ...commentsRecordResults,
        ...(dataSubmissionRecordResult == null ? [] : [dataSubmissionRecordResult as RecordResult]),
        ...(formDataRecordResult == null ? [] : [{
            rowResult: formDataRecordResult.result as RowResultEnum,
            message: formDataRecordResult.message || undefined,
        }]),
    ];

    return allRecordResults.length > 0 ? allRecordResults : null;
}

function collectSubmissionValidationAlerts(submission: RefiningSubmission | null): Array<ValidationAlertView> | null {
    if (submission == null) {
        return null;
    }

    const validationAlertViews: Array<ValidationAlertView> = [];

    submission.refinings.forEach(refining => {
        if (refining.validationAlerts == null) {
            return;
        }

        refining.validationAlerts.forEach(validationAlert => {
            if (isValidationAlert(validationAlert)) {
                validationAlertViews.push({
                    validationAlert,
                    productId: refining.refineryProductId,
                });
            }
        })
    })

    return validationAlertViews.length > 0 ? validationAlertViews : null;
}
