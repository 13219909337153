import { BoxedDiv } from 'psims/react/components/layout';
import Header from 'psims/react/blocks/data-submission-header';
import SubmissionProgressBar from 'psims/react/components/submission-progress-bar';
import useWholesaling from './use-wholesaling';
import { UseWholesalingAPI } from './use-wholesaling-api';
import { UseWholesalingRefData } from './use-wholesaling-ref-data';
import WholesalingTypeEditor from './wholesaling-type-editor';
import { H2 } from 'psims/react/components/typography';
import Button from 'psims/react/components/button/button';
import WholesalingNotifications from './wholesaling-notifications';
import Submission from './submission';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';
import ScrollTo from 'psims/react/components/scroll-to';
import ConfirmSaveChanges from 'psims/react/components/confirm-save-changes';
import WholesalingNavigationPrompt from './navigation-prompt';
import DataSubmissionPrimaryControls from 'psims/react/components/data-submission-primary-controls';
import MainContainer from 'psims/react/pages/primary-pages/data-submissions/shared/main-container';
import { DeleteConfirmationDialog } from '../shared/delete-confirmation';

interface WholesalingEditorProps {
    apiCtrl: PopulatedProps<UseWholesalingAPI, 'submission'>;
    refData: UseWholesalingRefData;
}

const WholesalingEditor = (props: WholesalingEditorProps) => {
    const vm = useVM(props);
    const {apiCtrl, content, importCtrl, controlsCtrl, formCtrl, portalDataAPICtrl, progressCtrl, saveCtrl, stepCtrl, submission, submissionCtrl, viewMode} = vm.ctrl;

    const shouldShowExtraButtons = stepCtrl.currentStep.typeName === 'Retailers' && viewMode === 'edit';
        
    return (
        <MainContainer dataSubmission={vm.ctrl.submission.dataSubmission}>
            <BoxedDiv box={{}}>
                <Header 
                    clearFile={importCtrl.clearFile}
                    dataSubmission={submission.dataSubmission} 
                    file={importCtrl.file}
                    showExtraButtons={shouldShowExtraButtons} 
                    handleClearData={vm.ctrl.clearDataCtrl.handleClearData}   
                    handleTemplateImport={vm.ctrl.importCtrl.handleTemplateImport}
                    process={importCtrl.process}
                    setTemplateImportState={importCtrl.setTemplateImportState}
                    templateImportState={importCtrl.templateImportState}  
                    currentStepIndex={stepCtrl.currentStep.index}           
                />

                <BoxedDiv className='container' box={{marginV: 4}}>
                    {/* Progress indicator */}
                    <BoxedDiv box={{marginV: 6}}>
                        <SubmissionProgressBar
                            isComplete={viewMode === 'view'}
                            steps={progressCtrl.navItems}
                            onStepClick={progressCtrl.handleNavItemClick}
                        />
                    </BoxedDiv>

                    {/* Step frontmatter */}
                    {
                        content && <>
                            <H2>{content.title}</H2>
                            <BoxedDiv box={{marginV: 4}}>
                                <vm.ctrl.content.Help />
                            </BoxedDiv>
                        </>
                    }

                    {/* Main form editor */}
                    {
                        progressCtrl.currentNavItem.typeName==='Submit' ?
                        <Submission formCtrl={formCtrl} submitCtrl={submissionCtrl} />:
                        <WholesalingTypeEditor apiCtrl={apiCtrl} formCtrl={formCtrl} portalDataAPICtrl={portalDataAPICtrl} updateResponse={vm.ctrl.updateResponse} viewMode={viewMode} />
                    }

                    {/* Page messages */}
                    {
                        vm.ctrl.viewMode === 'edit' ?
                        <WholesalingNotifications
                            formCtrl={formCtrl}
                            progressCtrl={progressCtrl}
                            refDataCtrl={vm.ctrl.refData}
                            serviceMessages={vm.ctrl.serviceMessages}
                            submission={apiCtrl.submission}
                            submissionCtrl={submissionCtrl}
                            updateResponse={vm.ctrl.updateResponse}
                        /> :
                        null
                    }

                    {/* Controls */}
                    <DataSubmissionPrimaryControls>
                        <BoxedDiv box={{alignItems: 'center', flex: 'row-reverse'}}>
                            {
                                controlsCtrl.saveControl != null &&
                                <Button
                                    disabled={controlsCtrl.saveControl.disabled}
                                    $kind='primary'
                                    onClick={controlsCtrl.saveControl.onClick}
                                >{controlsCtrl.saveControl.label}</Button>
                            }

                            {
                                controlsCtrl.submitControl != null &&
                                <Button
                                    disabled={controlsCtrl.submitControl.disabled}
                                    $kind='primary'
                                    onClick={controlsCtrl.submitControl.onClick}
                                >{controlsCtrl.submitControl.label}</Button>
                            }

                            {
                                controlsCtrl.previousControl != null &&
                                <Button
                                    disabled={controlsCtrl.previousControl.disabled}
                                    $kind='ghost'
                                    marginRight={2}
                                    onClick={controlsCtrl.previousControl.onClick}
                                >{controlsCtrl.previousControl.label}</Button>
                            }
                        </BoxedDiv>
                    </DataSubmissionPrimaryControls>

                    {/* Unsaved changes confirmation */}
                    <ConfirmSaveChanges
                        controlsState={saveCtrl.isApiBusy ? 'loading' : 'normal'}
                        isOpen={saveCtrl.confirmState === 'confirming'}
                        onCancel={saveCtrl.handleSaveCancel}
                        onConfirm={saveCtrl.handleSaveConfirm}
                        onSecondary={saveCtrl.handleDiscardAndContinue}
                    />

                    {/* Unsaved changes confirmation - browser nav */}
                    <WholesalingNavigationPrompt
                        hasUnsavedChanges={vm.ctrl.hasUnsavedChanges}
                        saveCtrl={saveCtrl}
                        updateResponse={vm.ctrl.updateResponse}
                    />
                    {/* Confirm Clear all dialog */}
                    {
                        shouldShowExtraButtons &&
                        <ConfirmationDialog
                            ariaLabel='Clear all data confirm'
                            body={vm.ctrl.clearDataCtrl.confirmCtlr.message}
                            title={vm.ctrl.clearDataCtrl.confirmCtlr.title}
                            onCancel={vm.ctrl.clearDataCtrl.confirmCtlr.cancel}
                            onConfirm={vm.ctrl.clearDataCtrl.confirmCtlr.confirm}
                            isOpen={vm.ctrl.clearDataCtrl.confirmCtlr.confirmState === 'confirming'}
                        />
                    }
                    {
                         <DeleteConfirmationDialog
                            title='Confirm record deletion'
                            deleteRequestState={formCtrl.deleteRequestState}
                            setDeleteRequestState={formCtrl.setDeleteRequestState}
                        />
                    }

                    {/* Review alerts dialog */}
                    <ConfirmationDialog
                        ariaLabel='Your data contains alerts - please review before continuing'
                        body={
                            <BoxedDiv box={{flex: 'column'}}>
                                <p>The data on this page has triggered one or more alerts, which require you to provide a comment.</p>
                                <p>If you want to review these alerts and review or add a comment, please click Review alerts.</p>
                                <p>If you have addressed these and entered a comment, click Proceed to continue to the next page.</p>
                            </BoxedDiv>
                        }
                        cancelLabel='Review alerts'
                        isOpen={stepCtrl.reviewAlertStatus === 'showing_dialog'}
                        onCancel={stepCtrl.handleClickReview}
                        onConfirm={stepCtrl.handleClickContinue}
                        title='Your data contains alerts - please review before continuing'
                    />

                    {/* Scroll helper */}
                    <ScrollTo />

                </BoxedDiv>
            </BoxedDiv>
        </MainContainer>
    );
}

function useVM({apiCtrl, refData}: WholesalingEditorProps) {
    const ctrl = useWholesaling({apiCtrl, refData});

    return {
        ctrl,
    };
}

export default WholesalingEditor;
