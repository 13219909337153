import { CountryDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

export type Country = PopulatedProps<CountryDTO, 'id' | 'absCountryCode' | 'absCountryName' | 'effectiveFrom'>;

export function assertCountry(maybe?: unknown): asserts maybe is Country {
    const asCountry = maybe as Country;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asCountry, hint('country'));
    assertNotEmpty(asCountry.absCountryCode, hint('country.absCountryCode'));
    assertNotEmpty(asCountry.absCountryName, hint('country.absCountryName'));
}