import { WholesaleProductGroupDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

// TODO: update with real referenceCode when available
const REF_CODES = [
    'WHL-R-D',
    'WHL-OW-BU',
    'WHL-B-D',
    'WHL-OW-FO',
    'WHL-OW-ODF',
    'WHL-OW-AF',
    'WHL-OW-GLO',
    'WHL-T-FO',
    'WHL-T-AF',
    'WHL-T-D',
    'WHL-T-LPG',
    'WHL-R-AG',
    'WHL-B-AG',
    'WHL-W-AG',
    'WHL-W-D',
    'WHL-W-LPG',
    'WHL-B-LPG',
    'WHL-R-LPG',
    'WHL-OW-RF',
    'WHL-OW-OP',
    'WHL-T-AG',
    'WHL-T-GLO',
    'WHL-T-BU',
    'WHL-T-ODF',
    'WHL-T-OP',
    'WHL-T-RF',
] as const;

type WhlProductGroupReferenceCode = typeof REF_CODES[number];

export type WholesaleProductGroup = PopulatedProps<WholesaleProductGroupDTO, 'id' | 'displayOrder' | 'effectiveFrom' | 'isActive' | 'name' | 'wholesaleTypeId' | 'wholesaleTypeName' | 'referenceCode'> & {
    referenceCode: WhlProductGroupReferenceCode;
};

export function assertWholesaleProductGroup(maybe?: unknown): asserts maybe is WholesaleProductGroup {
    const asWholesaleProductGroup = maybe as WholesaleProductGroup;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asWholesaleProductGroup, hint('wholesaleProductGroup'));
    assertNotEmpty(asWholesaleProductGroup.displayOrder, hint('wholesaleProductGroup.displayOrder'));
    assertNotEmpty(asWholesaleProductGroup.id, hint('wholesaleProductGroup.id'));
    assertNotEmpty(asWholesaleProductGroup.isActive, hint('wholesaleProductGroup.isActive'));
    assertNotEmpty(asWholesaleProductGroup.name, hint('wholesaleProductGroup.name'));
    assertNotEmpty(asWholesaleProductGroup.wholesaleTypeId, hint('wholesaleProductGroup.wholesaleTypeId'));
    assertNotEmpty(asWholesaleProductGroup.wholesaleTypeName, hint('wholesaleProductGroup.wholesaleTypeName'));
}
