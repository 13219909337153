import { useCallback, useEffect, useMemo, useState } from "react";

import { useAPI } from "psims/react/providers/api";
import { isBusyStatus, SubmissionStatus } from "psims/react/pages/primary-pages/data-submissions/shared/api";
import { useLogger } from "psims/react/providers/logging";
import { APIResponse, isAPIResponse, isSuccessfulAPIResponseWithResult } from "psims/models/api/response";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { numberFieldValue } from "psims/lib/number";
import { encodeEscapeChars } from "psims/lib/validation/string";
import { MaybeRefiningSubmission, UpdateRefiningSubmission, RefiningSubmission, isRefiningSubmission, UpdateRefining } from "psims/models/submission-types/refining";
import { UpdateDataSubmission } from "psims/models/data-submission";
import { useReferenceData } from "psims/react/providers/api/reference-data";
import { ViewMode } from "../shared/use-view-mode";

interface UseRefineryAPIProps {
    dataSubmissionId: number;
}

function useRefineryAPI({dataSubmissionId}: UseRefineryAPIProps) {
    const {api} = useAPI();
    const logger = useLogger({source: 'useRefineryAPI'});
    const [submission, setSubmission] = useState<RefiningSubmission>();
    const [loadStatus, setLoadStatus] = useState<SubmissionStatus>('init');
    const [updateError, setUpdateError] = useState<unknown | null>(null);
    const [updateResponse, setUpdateResponse] = useState<APIResponse<MaybeRefiningSubmission> | null>(null);

    const {status: refDataStatus, data: allRefData} = useReferenceData();
    
    const loggerRef = useUpdatedRef(logger);

    const isBusy = useMemo(() => {
        return isBusyStatus(loadStatus)
    }, [loadStatus]);

    const viewMode: ViewMode = useMemo(() => {
        const status = submission?.dataSubmission?.status;
        let mode: ViewMode = 'view';
        if (status) {
            switch (status) {
                case 'Action required':
                case 'Draft':
                case 'Not started':
                    mode = 'edit';
                    break;
                case 'Inactive':
                    mode = 'inactive';
                    break;
            }
        }
        return mode;
    }, [submission?.dataSubmission?.status]);

    const fetch = useCallback(() => {
        if (isBusy) {
            loggerRef.current.debug(`Aborting fetch due to busy status: ${loadStatus}`)
            return;
        }

        setLoadStatus('fetching');

        api.getRefiningSubmission({id: dataSubmissionId})
        .then(response => {
            if (response !== null && response.isSuccessful && response.result !== undefined) {
                setSubmission(response.result as RefiningSubmission);
                setLoadStatus('fetched');
            } else {
                setTimeout(() => {
                    setLoadStatus('fetch_failed');
                }, 750)
            }
        })
        .catch(() => {
            setTimeout(() => {
                loggerRef.current.warn('Failed to fetch refinings');;
                setLoadStatus('fetch_failed');
            }, 750)
        });
    }, [api, dataSubmissionId, isBusy, loggerRef, loadStatus])

    const updateDataSubmission = useCallback((updatePayload: UpdateDataSubmission) => {
        if (isBusy) {
            loggerRef.current.debug(`Aborting data submission (refining) update due to busy status: ${loadStatus}`)
            return;
        }

        setLoadStatus('updating');
        setUpdateError(null);

        const requestBodyEncoded = {
            ...updatePayload,
            comments: encodeEscapeChars(updatePayload.comments?.trim())
        };

        const trackPageView = loggerRef.current.startTrackPage('Save - Refining');

        api.updateRefiningDataSubmission({requestBody: requestBodyEncoded})
        .then(res => {
            trackPageView();
            setLoadStatus('updated');
            setUpdateResponse(res);
        })
        .catch(e => {
            loggerRef.current.warn(`Failed to update data submission (refining): ${JSON.stringify(e)}`);
            setLoadStatus('update_failed');
            setUpdateError(e);
        });

    }, [api, isBusy, loggerRef, loadStatus]);

    const updateRefinery = useCallback((updatePayload: UpdateRefiningSubmission) => {
        if (isBusy) {
            loggerRef.current.debug(`Aborting refining update due to busy status: ${loadStatus}`)
            return;
        }

        let commentEncoded = updatePayload.refineryComment;
        if (commentEncoded) {
            commentEncoded = {
                ...commentEncoded,
                comments: encodeEscapeChars(commentEncoded.comments?.trim())
            }
        }

        const requestBodyEncoded: UpdateRefiningSubmission = {
            ...updatePayload,
            refineryComment: commentEncoded
        };
        
        setLoadStatus('updating');
        setUpdateError(null);

        const trackPageView = loggerRef.current.startTrackPage('Save - Refining');

        api.updateRefining({requestBody: cleanUpdatePayload(requestBodyEncoded)})
            .then(res => {
                trackPageView();
                setUpdateResponse(res);
                setLoadStatus('updated');
            })
            .catch(e => {
                loggerRef.current.warn(`Failed to update refining: ${JSON.stringify(e)}`);
                if (e.body && isAPIResponse(e.body)) {
                    setUpdateResponse(e.body);
                }
                setLoadStatus('update_failed');
                setUpdateError(e);
            });
    }, [api, isBusy, loggerRef, loadStatus]);

    const submit = useCallback((submitPayload: UpdateDataSubmission) => {
        if (isBusyStatus(loadStatus)) {
            loggerRef.current.debug(`Aborting submit due to busy status: ${loadStatus}`)
            return;
        }

        setLoadStatus('submitting');
        setUpdateError(null);

        const requestBodyEncoded = {
            ...submitPayload,
            comments: encodeEscapeChars(submitPayload.comments?.trim())
        };

        api.submitRefining({requestBody: requestBodyEncoded})
            .then(res => {
                setLoadStatus('submitted');
                setUpdateResponse(res);
            })
            .catch(e => {
                loggerRef.current.warn(`Failed to submit refining: ${JSON.stringify(e)}`);
                setLoadStatus('submit_failed');
                setUpdateError(e);
            });

    }, [api, loadStatus, loggerRef]);

    const clearAllData = useCallback(() => { 
        if (submission == null) {
            loggerRef.current.warn('Failed to clear all refining data: Submission is null');
            return;
        }

        setLoadStatus('updating');
        setUpdateError(null);

        api.clearAllRefining({requestBody: {id: submission.dataSubmission.id, concurrencyToken: submission.dataSubmission.concurrencyToken}})
            .then(response => {
                setLoadStatus('updated');
                setUpdateResponse(response);
            })
            .catch(e => {
                loggerRef.current.warn(`Failed to clear all refining data: ${JSON.stringify(e)}`);
                if (e.body && isAPIResponse(e.body)) {
                    setUpdateResponse(e.body);
                }
                setLoadStatus('update_failed');
                setUpdateError(e);                
            });
    }, [api, loggerRef, submission]);

    // Fetch refinery submission on load
    useEffect(() => {
        fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update exposed submission when update response contains a valid submission
    useEffect(() => {
        if (isSuccessfulAPIResponseWithResult(updateResponse, isRefiningSubmission)) {
            setSubmission(updateResponse.result as RefiningSubmission);
        }
    }, [updateResponse]);

    return {
        isBusy,
        loadStatus,
        submission,
        updateError,
        updateResponse,
        fetch,
        submit,
        updateDataSubmission,
        updateRefinery,
        clearAllData,
        refDataStatus,
        allRefData, 
        viewMode
    }
}

export type UseRefineryAPI = ReturnType<typeof useRefineryAPI>;

export default useRefineryAPI;

function cleanUpdatePayload(payload: UpdateRefiningSubmission): UpdateRefiningSubmission {
    return {
        ...payload,
        refinings: payload.refinings.map(w => ({
            ...w,
            closingStocks: numberFieldValue(w.closingStocks),
            consumed: numberFieldValue(w.consumed),
            density: numberFieldValue(w.density),
            openingStocks: numberFieldValue(w.openingStocks),
            production: numberFieldValue(w.production),
            refineryInputs: numberFieldValue(w.refineryInputs),
            totalReceipts: numberFieldValue(w.totalReceipts)   
        }) as UpdateRefining),
        submissionFormData: {
            ...payload.submissionFormData,
            totalLosses: numberFieldValue(payload.submissionFormData.totalLosses)
        }
    }
}
