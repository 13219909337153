import React, { PropsWithChildren } from 'react';

import Page from 'psims/react/blocks/page';
import Text from 'psims/react/components/text';
import SignInButton from 'psims/react/blocks/auth/sign-in-button';
import Tick from 'psims/react/components/icons/tick';
import { useUser } from 'psims/react/providers/user';
import Notification from 'psims/react/components/notification';
import SupportLink from 'psims/react/components/support-link';
import { APP_NAME, SUPPORT_EMAIL } from 'psims/constants/app';
import { H1, H3, H4 } from '../components/typography';
import { BoxedDiv, BoxedSpan } from '../components/layout';
import Callout from '../components/callout';
import styled from 'styled-components';
import UL from '../components/unordered-list';

interface LandingProps {
	
};

const P = styled.p`
	line-height: 28px;
	color: #333333;
`;

const LI = styled.li`
	color: #333333;
`

const StyledH3 = styled(H3)`
	font-size: 32px;
`;

const Landing = (props: LandingProps) => {
	const vm = useVM(props);

	return (
		<Page hideNavbar={true} hideUserBlock={vm.status === 'Unauthenticated'}>
			<BoxedDiv className='container' box={{alignItems: 'center', flex: 'column', justifyContent: 'flex-start'}}>
				<BoxedDiv box={{alignSelf: 'flex-start'}}>
					<H1>Welcome to the {APP_NAME}</H1>
				</BoxedDiv>

				{
					vm.showNotification &&
					<BoxedDiv box={{marginBottom: 4, marginTop: 2, width: '100%'}}>
						{
							vm.status === 'New' &&
							<Notification kind='info'>
								<div>
									<P>Your account for the {APP_NAME} is currently being created, please wait for your invitation email.</P>
									<P>If do not receive this email in the next 30 minutes, please contact <SupportLink>{SUPPORT_EMAIL}</SupportLink>.</P>
								</div>
							</Notification>
						}

						{
							vm.status === 'Not_Found' &&
							<Notification kind='info'>
								<div>
									<P>Your account for the {APP_NAME} is pending creation by your company administrator. Please contact your company's administrator to obtain your invitation email.</P>
									<P>If you do not know your company administrator please contact <SupportLink>{SUPPORT_EMAIL}</SupportLink>.</P>
								</div>
							</Notification>
						}

						{
							vm.status === 'Inactive' &&
							<Notification kind='warning'>
								<div>
									<P>Your account for the {APP_NAME} has been deactivated.  Please contact your company's administrator to reactivate your account.</P>
									<P>If you do not know your company administrator please contact <SupportLink>{SUPPORT_EMAIL}</SupportLink>.</P>
								</div>
							</Notification>
						}

						{
							vm.status === 'Service_Error' &&
							<Notification kind='warning'>
								<div>
									<P>The Liquid Fuels Gateway is currently experiencing a communication issue. Please try again later.</P>
									<P>If the problem persists, contact <SupportLink>{SUPPORT_EMAIL}</SupportLink>.</P>
								</div>
							</Notification>
						}
					</BoxedDiv>
				}

				<BoxedDiv box={{flex: 'row', marginTop: 6}}>
					<BoxedDiv box={{alignItems: 'flex-start', flex: 'column', flexGrow: 2, marginRight: 6}}>
						<StyledH3>What is the {APP_NAME}?</StyledH3>

						<BoxedDiv box={{marginBottom: 4, marginTop: 3}}>
							<P>The Liquid Fuels Gateway (the portal) is an online space for you to manage your interactions with fuel security and reporting legislation administered by the Department of Climate Change, Energy, the Environment and Water (the department).</P>

							<P>You can use the portal to report your petroleum data to the department as mandated by the <a href='https://www.legislation.gov.au/Details/C2021C00512' target='_blank' rel='noreferrer'>Petroleum and Other Fuels Reporting Act 2017</a> (POFR Act) and the <a href='https://www.legislation.gov.au/Details/F2022C01122' target='_blank' rel='noreferrer'>Petroleum and Other Fuels Reporting Rules 2017</a> (POFR Rules). Data reported under the POFR Act is used to</P>
							<UL>
								<LI>monitor fuel security</LI>
								<LI>compile official statistics and other fuel market information such as the monthly <a href='https://www.energy.gov.au/government-priorities/energy-data/australian-petroleum-statistics' target='_blank' rel='noreferrer'>Australian Petroleum Statistics</a></LI>
								<LI>meet international reporting obligations. </LI>
							</UL>

							<P>You can also use the Portal to report data, and submit applications and notifications as required under the <a href='https://www.legislation.gov.au/Details/C2021C00547' target='_blank' rel='noreferrer'>Fuel Security Act 2021</a> (FS Act). The FS Act establishes the legislative framework for the Fuel Security Services Payment (FSSP) scheme and the Minimum Stockholding Obligation (MSO).  The FS Act and <a href='https://www.legislation.gov.au/Details/F2021L01011' target='_blank' rel='noreferrer'>Fuel Security (Fuel Security Services Payment) Rule 2021</a> set out requirements for the FSSP scheme which commenced on 1 July 2021. Detailed requirements for the MSO are contained in the <a href='https://www.legislation.gov.au/Details/F2023C00357' target='_blank' rel='noreferrer'>Fuel Security (Minimum Stockholding Obligations) Rules 2022</a>, which apply from 1 July 2023.</P>

						</BoxedDiv>

						<StyledH3>How do I access the portal?</StyledH3>

						<BoxedDiv box={{marginBottom: 4, marginTop: 3}}>

							<P>Your company must be registered to access the portal.</P>

							<P>When registering your company for reporting, you will nominate a company administrator who can provide other members of your company with access to the portal.</P>

							<P>For registration and assistance on using the portal please contact our support team <SupportLink>PSIMS Support</SupportLink> or visit <a href='https://www.energy.gov.au/government-priorities/energy-data/australian-petroleum-statistics' target='_blank' rel='noreferrer'>Australian Petroleum Statistics website</a>.</P>
						</BoxedDiv>
					</BoxedDiv>

					<BoxedDiv box={{alignItems: 'flex-start', flex:'column', flexGrow: 1, marginLeft: 6}}>
						<Callout>
							<BoxedDiv box={{alignItems: 'flex-start', flex: 'column'}}>
								<H4>Sign in to the portal to</H4>

								<SignInItem>Report my monthly POFR data</SignInItem>

								<SignInItem>Report my monthly DEF and TGU data</SignInItem>

								<SignInItem>Submit reports, applications and notices for the FSSP</SignInItem>

								<SignInItem>Submit notices and other actions (as required) for the MSO</SignInItem>

								<SignInItem>View historical data</SignInItem>

								<SignInItem>Create ad hoc reports</SignInItem>

								{
									vm.status === 'Unauthenticated' &&

									<BoxedDiv box={{flex: 'column', alignItems: 'center', justifyContent: 'center'}} >
										<BoxedDiv box={{marginTop: 4}}>
											<SignInButton />
										</BoxedDiv>
									</BoxedDiv>
								}
							</BoxedDiv>
						</Callout>
					</BoxedDiv>
				</BoxedDiv>

			</BoxedDiv>
		</Page>
	);
};

function useVM(props: LandingProps) {
	const { status } = useUser();

	const showNotification = status === 'Inactive' ||
							 status === 'New' ||
							 status === 'Not_Found' ||
							 status === 'Service_Error';
	return {
		showNotification,
		status,
	};
}

const SignInItem = ({children}: PropsWithChildren<{}>) => {
	return <BoxedDiv box={{alignItems: 'flex-start', alignSelf: 'stretch', flex: 'row', justifyContent: 'flex-start', marginV: 1, width: '350px'}}>
		<BoxedSpan box={{paddingTop: 0.5}}>
			<Tick color='accent' size='sm' />
		</BoxedSpan>
		<BoxedDiv box={{marginLeft: 2}}>
			<Text>{children}</Text>
		</BoxedDiv>
	</BoxedDiv>
}

export default Landing;
