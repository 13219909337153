import React, { useMemo } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import Textarea from 'psims/react/components/textarea';
import { UseMarketExpectations } from './use-market-expectations';
import { ValidationResult } from './validate-market-expectations';
import { INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH } from 'psims/constants/validation-messages';
import Text from 'psims/react/components/text';
import { H2 } from 'psims/react/components/typography';

interface MarketExpectationsProps {
  forceErrors: boolean;
  isDisabled: boolean;
  ctrl: UseMarketExpectations;
}

const MarketExpectations = ({ ctrl, forceErrors, isDisabled }: MarketExpectationsProps) => {
  const error = useMemo(() => inlineValidationMessage(ctrl.data.validation), [ctrl.data.validation]);
  return (
    <BoxedDiv box={{ flex: 'column', marginTop: 4 }}>
      <H2>Market expectations</H2>

      <Text id='market_expectations_help'>
        Please provide any additional market intelligence that you are willing to share. Do you foresee any DEF or TGU constraints to your business operations in the next six months?
      </Text>

      <BoxedDiv box={{ marginTop: 2 }}>
        <Textarea
          aria-describedby='market_expectations_help'
          disabled={isDisabled}
          error={error}
          forceError={forceErrors}
          hideLabel={true}
          id='market_expectations'
          label='Market expectations'
          onChange={e => ctrl.update(e.target.value)}
          placeholder='For example: supply chain issues, export restrictions or shipping issues.'
          setFocused={false}
          value={ctrl.data.data?.marketExpectationsComments || ''}
        />
      </BoxedDiv>
    </BoxedDiv>
  );
}

export default MarketExpectations;

function inlineValidationMessage(validationResults: Array<ValidationResult>) {
  const validationResult = validationResults[0];

  if (validationResult == null) {
    return undefined;
  }

  switch (validationResult.code) {
    case 'invalid_char_length':
      return INVALID_COMMENTS_LENGTH;

    case 'invalid_chars':
      return INVALID_COMMENTS_CHARACTERS;
  }
}
