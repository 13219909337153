import { useEffect, useMemo, useState } from "react";

import { DefProduct } from "psims/models/ref-data/def-product";
import { UseFocusedField } from "psims/react/util/use-focused-field";
import NotificationMessage from "../shared/notification-message";
import { UseActivities } from "./activities/use-activities";
import { FocusField } from "./types";
import { UseDefNavigation } from "./use-def-navigation";
import { UseDefProgress } from "./use-def-progress";
import { UseSubmit } from "../shared/use-submit";
import { getInfoNotificationMessage, getValidationNotificationMessage } from "./activities/activity-notifications";
import { isTGUReferenceCode } from "./activities/util";
import { isValidationAlertResultRelativity } from "./activities/activity-validation-alerts";
import { UseDefSubmissionValidationAlerts } from "./use-def-submission-validation-alerts";
import { is } from "psims/lib/type-assertions";

interface UseDefNotificationsProps {
  activitiesCtrl: UseActivities;
  focusFieldCtrl: UseFocusedField<FocusField>;
  navCtrl: UseDefNavigation;
  progressCtrl: UseDefProgress;
  serviceMessages: Array<string>;
  submitCtrl: UseSubmit;
  submissionValidationAlertsCtrl: UseDefSubmissionValidationAlerts;
}

function useDefNotifications({ focusFieldCtrl, navCtrl, progressCtrl, activitiesCtrl, serviceMessages, submitCtrl, submissionValidationAlertsCtrl }: UseDefNotificationsProps) {
  const [serviceNotifications, setServiceNotifications] = useState<Array<{ content: JSX.Element }>>([]);

  const validationMessages = useMemo<Array<{ content: JSX.Element }>>(() => {
    let messages: Array<{ content: JSX.Element }> = [
      // Always shown messages
      ...activitiesCtrl.data
        .filter(d => d.refData?.productStatus === 'inactive_with_data')
        .map(d => getValidationNotificationMessage(
            'closingStocks',
            () => {
              // Attempt navigation to data page if link clicked from Declaration page
              if (progressCtrl.currentStep.kind !== 'data') {
                navCtrl.attemptStepNavigation(0);
              }
              setTimeout(() => {
                if (d.data.id != null) {
                  focusFieldCtrl.setFocusedField({kind: 'data', field: 'delete', id: d.data.id});
                }
              }, 0);
            },
            d.refData?.name || '',
            {code: 'product_expired', field: 'closingStocks'},
            progressCtrl.currentStep.kind,
            () => {
              if (progressCtrl.currentStep.kind !== 'data') {
                navCtrl.attemptStepNavigation(0);
                setTimeout(() => {
                  if (d.data.id != null) {
                    focusFieldCtrl.setFocusedField({kind: 'data', field: 'delete', id: d.data.id});
                  }
                }, 0);
              } else {
                activitiesCtrl.clearRow(d.data)
              }
            }
          ))
        .filter(is)
        .map(parts => ({ content: NotificationMessage({ parts })}))
    ];

    if (progressCtrl.currentStep.kind === 'data' && (navCtrl.saveAttempted || navCtrl.navAttempted)) {
      const validations = [
        ...activitiesCtrl.data.map(d => ({ validations: d.validation, data: d.data, product: d.refData as DefProduct })).flat(),
      ]
        .filter(v => v.validations.length > 0);

      messages = [
        ...messages,
        ...validations
        .map(validation => validation.validations
          .filter(v => v.code !== 'product_expired') // always handled
          .map(v => getValidationNotificationMessage(
            v.field,
            () => focusFieldCtrl.setFocusedField({kind: 'data', field: v.field, _id: validation.data._id}),
            validation.product.name,
            v,
            progressCtrl.currentStep.kind,
            () => activitiesCtrl.clearRow(validation.data)
          ))
        )
        .flat()
        .filter(is)
        .map(parts => ({ content: NotificationMessage({ parts })}))
      ];
    }

    if (progressCtrl.currentStep.kind === 'submit' && submitCtrl.submitAttempted) {
      messages = [
        ...messages,
        ...(submitCtrl.validationMessages.declaration.validationMessages.declaration == null ? [] : [submitCtrl.validationMessages.declaration.validationMessages.declaration.notification]),
        ...(submitCtrl.validationMessages.comments.validationMessages.comments == null ? [] : [submitCtrl.validationMessages.comments.validationMessages.comments.notification]),
      ]
    }

    return messages;
  }, [
    activitiesCtrl,
    focusFieldCtrl,
    navCtrl,
    progressCtrl.currentStep.kind,
    submitCtrl.submitAttempted,
    submitCtrl.validationMessages
  ]);

  const validationAlerts = useMemo(() => {
    const submissionValidationAlerts = submissionValidationAlertsCtrl.validationAlerts;

    const activitiesValidationAlerts = [
      ...activitiesCtrl.data.map(d => ({ validationAlerts: d.validationAlerts, data: d.data, product: d.refData as DefProduct })).flat(),
    ].filter(v => v.validationAlerts.length > 0);

    const tguData = activitiesCtrl.data.find(d => isTGUReferenceCode(d.refData?.referenceCode || ''));

    const derivedValidationAlerts = [
      ...activitiesValidationAlerts
        .map(v => v.validationAlerts.map(va => getInfoNotificationMessage(
          va.field,
          () => {
            // Attempt navigation to data page if link clicked from Declaration page
            if (progressCtrl.currentStep.index !== 0) {
              navCtrl.attemptStepNavigation(0);
            }
            setTimeout(() => focusFieldCtrl.setFocusedField({ _id: v.data._id, field: va.field, kind: 'data' }), 0);
          },
          v.product.name,
          va,
          () => {
            if (va.code === 'relativity_for_derived_def' && tguData != null) {
              // Attempt navigation to data page if link clicked from Declaration page
              if (progressCtrl.currentStep.index !== 0) {
                navCtrl.attemptStepNavigation(0);
              }
              setTimeout(() => focusFieldCtrl.setFocusedField({ _id: tguData.data._id, field: va.relatedField, kind: 'data' }), 0);
            }
          },
          isValidationAlertResultRelativity(va) ? va.relatedField : undefined
        )))
        .flat()
        .map(parts => ({ content: NotificationMessage({ parts }) })),
    
        // Submission-level validation alerts - just SameAsPrevious
        ...submissionValidationAlerts
          .filter(va => va.validationAlert.validationAlert === 'SameAsPrevious')
          .map(va => ({
            content: NotificationMessage({ parts: [
              'Your submission is the same as the previous month. You are required to enter a reason in the comments field on the declaration page to confirm your data is correct.'
            ]}) 
          })),
    ];

    return derivedValidationAlerts;
  }, [
    activitiesCtrl.data,
    focusFieldCtrl,
    navCtrl,
    progressCtrl.currentStep.index,
    submissionValidationAlertsCtrl.validationAlerts,
  ]);

  useEffect(() => {
    setServiceNotifications(serviceMessages.map(message => ({
      content: NotificationMessage({ parts: [message] })
    })));
  }, [serviceMessages]);

  useEffect(() => {
    setServiceNotifications([]);
  }, [progressCtrl.currentStep.index]);

  return useMemo(() => ({
    serviceNotifications,
    validationAlerts,
    validationMessages,
  }), [serviceNotifications, validationAlerts, validationMessages]);
}

export default useDefNotifications

export type UseDefNotifications = ReturnType<typeof useDefNotifications>;
