export const MOBILE_BREAKPOINT = '576px';

// Box shadow
export const BOX_SHADOW_MAP = {
    hi: 'var(--box-shadow-hi)',
    lo: 'var(--box-shadow-lo)',
    mid: 'var(--box-shadow-mid)',
} as const;

export type BoxShadow = keyof typeof BOX_SHADOW_MAP;

// Color
export const COLOR_MAP = {
    accent: 'var(--color-accent)',
    black: 'var(--color-black-100)',
    'border-primary-faded': 'var(--color-blue-45)',
    error: 'var(--color-negative-100)',
    grey: 'var(--color-black-50)',
    'grey-faded': 'var(--color-black-30)',
    primary: 'var(--color-primary)',
    'primary-dark': 'var(--color-primary-dark)',
    'primary-faded': 'var(--color-primary-faded)',
    'primary-interactive': 'var(--color-primary-interactive)',
    'primary-mid': 'var(--color-primary-mid)',
    success: 'var(--color-positive-100)',
    transparent: 'var(--color-transparent)',
    white: 'var(--color-white)',
} as const;

export type Color = keyof typeof COLOR_MAP;

// Spacing
export const SPACE_BASE = 8;

const SPACING_SIZES = [
    0, 0.5, -0.5, 1, -1, 1.5, -1.5, 2, -2, 2.5, -2.5,
    3, -3, 3.5, -3.5, 4, -4, 4.5, -4.5, 5, -5, 5.5, -5.5,
    6, -6, 6.5, -6.5, 7, -7, 7.5, -7.5, 8, -8
] as const;

export type SpacingSize = typeof SPACING_SIZES[number];

type SpacingMap = {[size in SpacingSize]: string};

export const SPACING_MAP = SPACING_SIZES
    .reduce((memo, size) => ({
        ...memo,
        [size]: `${size * SPACE_BASE}px`,
        [`-${size}`]: `${-1 * size * SPACE_BASE}px`,
    }), {} as SpacingMap);