import React from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import SubmitTerms from '../shared/submit-terms';
import Textarea from 'psims/react/components/textarea';
import { asString } from 'psims/lib/string';
import { UseRefineryForm } from './use-refinery-form';
import { ViewMode } from '../shared/use-view-mode';

interface SubmissionProps {
    formCtrl: UseRefineryForm;
    viewMode: ViewMode
}

const RefinerySubmit = ({formCtrl, viewMode}: SubmissionProps) => {
    return (
        <BoxedDiv box={{marginV: 4}}>
            <SubmitTerms
                disabled={viewMode !== 'edit'}
                hasAgreed={formCtrl.view.Submit.declaration}
                onHasAgreedChange={formCtrl.updateDeclaration}
            />

            <BoxedDiv box={{}}>
                <Textarea
                    setFocused={formCtrl.view.focusedField === 'comments'}
                    autoFocus={true}
                    disabled={formCtrl.view.isDisabled}
                    id='comments'
                    label='Comments'
                    error={formCtrl.view.Submit.commentsValidationError?.notification.message}
                    value={asString(formCtrl.view.Submit.comments)}
                    onChange={e => formCtrl.updateDataSubmissionComment(e.target.value)}
                />
            </BoxedDiv>

        </BoxedDiv>
    )
}

export default RefinerySubmit;
