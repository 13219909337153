import React, { useMemo } from 'react';

import MainContainer from 'psims/react/pages/primary-pages/data-submissions/shared/main-container';
import { UseRefinerAnnualActivityAPI } from './use-refiner-annual-activity-api';
import useRefinerAnnualActivity from './use-refiner-annual-activity';
import useSubmissionProcessingOverlay from '../../shared/use-submission-processing-overlay';
import { BoxedDiv } from 'psims/react/components/layout';
import Header from 'psims/react/blocks/data-submission-header';
import { assertDataSubmission } from 'psims/models/data-submission';
import StorageSitesEditor from '../shared/storage-sites/storage-sites-editor';
import RefinerAnnualActivitiesEditor from './annual-activities/refiner-annual-activities-editor';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';
import Notifications from '../../shared/notifications';
import ScrollTo from 'psims/react/components/scroll-to';
import SubmissionProgressBar from 'psims/react/components/submission-progress-bar';
import { H2 } from 'psims/react/components/typography';
import SubmitPage from '../../shared/submit-page';
import DataSubmissionPrimaryControls from 'psims/react/components/data-submission-primary-controls';
import Button from 'psims/react/components/button';
import ConfirmSaveChanges from 'psims/react/components/confirm-save-changes';
import { getSubmissionTypeName } from 'psims/constants/data-submissions';
import MsoCommentEditor from '../shared/mso-comment/mso-comment-editor';

interface RefinerAnnualActivityEditorProps {
  apiCtrl: PopulatedProps<UseRefinerAnnualActivityAPI, 'submission'>;
}

const RefinerAnnualActivityEditor = (props: RefinerAnnualActivityEditorProps) => {
  const vm = useVM(props);

  const {primaryControl, secondaryControl} = vm.controlsCtrl;

  return <MainContainer dataSubmission={vm.dataSubmission}>
    <BoxedDiv box={{}}>
      <Header
        customName={getSubmissionTypeName(vm.dataSubmission)}
        dataSubmission={vm.dataSubmission}
        showExtraButtons={vm.shouldShowExtraButtons}
        handleClearData={vm.clearDataCtrl.handleClearData}
        currentStepIndex={vm.progressCtrl.currentStep.index}
      />
    </BoxedDiv>

    {/* Progress indicator */}
    <BoxedDiv box={{ marginV: 6 }}>
      <SubmissionProgressBar
        isComplete={vm.viewMode === 'view'}
        steps={vm.progressCtrl.progressSteps}
        onStepClick={vm.navCtrl.attemptStepNavigation}
      />
    </BoxedDiv>


    <div className='container'>
      {/* Switch on current page */}
      {
        vm.progressCtrl.currentStep.index === 0 && <>
          <BoxedDiv box={{flex: 'column'}}>
            <p>
            This page provides the information your entity is required to fill out under 19F(2) of the <i>Petroleum and Other Fuels Reporting (POFR) Rules 2017</i>.
            </p>
          </BoxedDiv>
          
          <RefinerAnnualActivitiesEditor
            annualActivitiesCtrl={vm.annualActivitiesCtrl}
            deleteCtrl={vm.deleteCtrl}
            focusFieldCtrl={vm.focusFieldCtrl}
            forceErrors={vm.navCtrl.lastSaveAttemptIndex != null}
            isDisabled={vm.isDisabled}
            products={vm.refData.products}
          />

          <StorageSitesEditor
            deleteCtrl={vm.deleteCtrl}
            products={vm.refData.products}
            focusFieldCtrl={vm.focusFieldCtrl}
            isDisabled={vm.isDisabled}
            storageSitesCtrl={vm.storageSitesCtrl}
            storageTypes={vm.refData.storageTypes}
            isRefiner={true}
          />

          {/* Comments */}
          <MsoCommentEditor
            forceErrors={vm.navCtrl.lastSaveAttemptIndex != null}
            isDisabled={vm.isDisabled}
            msoCommentCtrl={vm.commentsCtrl}
          />
        </>
      }

      {
        vm.progressCtrl.currentStep.index === 1 &&
        <>
          <BoxedDiv box={{ marginTop: 2 }}>
            <H2>Declaration</H2>
          </BoxedDiv>

          <SubmitPage
            ctrl={{
              ...vm.submitCtrl,
              isDisabled: vm.isDisabled,
              commentsError: undefined,
              dataSubmission: vm.apiCtrl.submission.dataSubmission,
            }}
            focusedFieldCtrl={vm.focusFieldCtrl}
            isMso={true}
            viewMode={vm.viewMode}
          />
        </>
      }


      {/* Notifications */}
      {
        !vm.isDisabled &&
        <Notifications
          infoNotifications={vm.notificationsCtrl.infoNotifications}
          systemNotifications={vm.notificationsCtrl.serviceNotifications}
          validationNotifications={vm.notificationsCtrl.validationNotifications}
        />
      }

      {/* Controls */}
      <DataSubmissionPrimaryControls>
          <BoxedDiv box={{alignItems: 'center', flex: 'row-reverse', marginTop: 2}}>
              {
                  primaryControl.isShown &&
                  <Button
                      disabled={primaryControl.isDisabled}
                      $kind='primary'
                      marginLeft={2}
                      onClick={primaryControl.action}
                      state={primaryControl.state}
                  >{primaryControl.label}</Button>
              }

              {
                  secondaryControl.isShown &&
                  <Button
                      disabled={secondaryControl.isDisabled}
                      $kind='ghost'
                      onClick={secondaryControl.action}
                      state={secondaryControl.state}
                  >{secondaryControl.label}</Button>
              }
          </BoxedDiv>
      </DataSubmissionPrimaryControls>

    </div>

    {/* Confirm Clear all dialog */}
    {
        vm.progressCtrl.currentStep.index === 0 &&
        <ConfirmationDialog
            ariaLabel='Clear all data confirm'
            body={vm.clearDataCtrl.confirmCtlr.message}
            title={vm.clearDataCtrl.confirmCtlr.title}
            onCancel={vm.clearDataCtrl.confirmCtlr.cancel}
            onConfirm={vm.clearDataCtrl.confirmCtlr.confirm}
            isOpen={vm.clearDataCtrl.confirmCtlr.confirmState === 'confirming'}
        />
    }

    <ConfirmationDialog
      ariaLabel='Record delete confirmation'
      isOpen={vm.deleteCtrl.confirmState === 'confirming'}
      body={vm.deleteCtrl.message}
      onCancel={vm.deleteCtrl.cancel}
      onConfirm={vm.deleteCtrl.confirm}
      title={vm.deleteCtrl.title}
      confirmLabel='Yes'
      cancelLabel='No'
    />
    
    <ConfirmSaveChanges {...vm.navCtrl.unsavedChangesDialogCtrl} />

    {/* Scroll helper */}
    <ScrollTo />
  </MainContainer>
}

function useVM({ apiCtrl }: RefinerAnnualActivityEditorProps) {
  const ctrl = useRefinerAnnualActivity({ apiCtrl });

  useSubmissionProcessingOverlay({ submissionStatus: apiCtrl.loadStatus });

  const dataSubmission = useMemo(() => {
    const { dataSubmission } = apiCtrl.submission;
    assertDataSubmission(dataSubmission, 'MSO refiner');

    return dataSubmission
  }, [apiCtrl.submission]);
  
  const shouldShowExtraButtons = useMemo(() => (
    ctrl.progressCtrl.currentStep.index === 0 && ctrl.viewMode === 'edit'),
    [ctrl.progressCtrl.currentStep.index, ctrl.viewMode]
  );

  return {
    ...ctrl,
    dataSubmission,
    shouldShowExtraButtons,
  };
}

export default RefinerAnnualActivityEditor;
