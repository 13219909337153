import { MsoRefinerSubmissionVM, UpdateMsoCommentVM, UpdateMsoRefinerSubmissionVM } from "psims/gen/xapi-client";
import { isArrayOfType } from "psims/lib/collections";
import { isRecordResult, RecordResult } from "psims/models/api/record-result";
import { WithUpdatable } from "psims/models/api/update";
import { isDataSubmission, isSubmittedDataSubmission } from "psims/models/data-submission";
import { WithDataSubmission } from "../data-submission";
import { isMso } from "./mso";
import { isMsoStockOnBehalf, UpdateMsoStockOnBehalf } from "./mso-stock-on-behalf";
import { isMsoStockRefineryHeld, UpdateMsoStockRefineryHeld } from "./mso-stock-refinery";
import { isMsoStockholding, UpdateMsoStockholding } from "./mso-stockholding";
import { isMsoSubmissionFormData } from "./mso-submission-form-data";
import { isOrganisationMsoSetting } from "./organisation-mso-setting";

export type MaybeMsoRefinerSubmission = MsoRefinerSubmissionVM;

export type MsoRefinerSubmission = WithDataSubmission<'MSO refiner', WithUpdatable<
    PopulatedProps<MaybeMsoRefinerSubmission, 'msoComment' | 'msoSettings' | 'msos' | 'stockRefineriesHeld' | 'stockholdings' | 'stocksOnBehalf' | 'submissionFormData'>
>>;

export function isMsoRefiner(maybe?: MaybeMsoRefinerSubmission | null): maybe is MsoRefinerSubmission {
    return (
        maybe != null &&
        isDataSubmission(maybe.dataSubmission, 'MSO refiner') &&
        isMsoSubmissionFormData(maybe.submissionFormData) &&
        isArrayOfType(isMso, maybe.msos) &&
        isArrayOfType(isMsoStockRefineryHeld, maybe.stockRefineriesHeld) &&
        isArrayOfType(isMsoStockholding, maybe.stockholdings) &&
        isArrayOfType(isMsoStockOnBehalf, maybe.stocksOnBehalf) &&
        isArrayOfType(isOrganisationMsoSetting, maybe.msoSettings)
    );
}

export function isMsoRefinerSubmitted(maybe?: MaybeMsoRefinerSubmission | null | undefined): maybe is MaybeMsoRefinerSubmission & {dataSubmission: {status: 'Submitted'}} {
    return isMsoRefiner(maybe) && isSubmittedDataSubmission(maybe.dataSubmission);
}

type MaybeUpdateMsoRefinerSubmission = UpdateMsoRefinerSubmissionVM;

export type UpdateMsoRefinerSubmission = PopulatedProps<MaybeUpdateMsoRefinerSubmission, "dataSubmissionId" | "msos"> & {
    msoComment: UpdateMsoCommentVM;
    stockholdings: Array<UpdateMsoStockholding>;
    stocksOnBehalf: Array<UpdateMsoStockOnBehalf>;
    stockRefineriesHeld: Array<UpdateMsoStockRefineryHeld>;
};

export function isUpdateMsoRefinerSubmission(maybe?: unknown): maybe is UpdateMsoRefinerSubmission {
    const maybeAs = maybe as UpdateMsoRefinerSubmission;

    return (
        maybeAs != null &&
        maybeAs.stockRefineriesHeld != null
    );
}

export function getRecordResults(maybe?: MaybeMsoRefinerSubmission): Array<RecordResult> {
    if (!isMsoRefiner(maybe)) {
        return [];
    }

    return [
        ...[maybe.dataSubmission?.recordResult].filter(isRecordResult),
        ...[maybe.msoComment?.recordResult].filter(isRecordResult),
        ...(maybe.msos || []).map(mso => mso.recordResult).filter(isRecordResult),
        ...(maybe.stockholdings || []).map(s => s.recordResult).filter(isRecordResult),
        ...(maybe.stocksOnBehalf || []).map(s => s.recordResult).filter(isRecordResult),
    ];
}
