import React, { PropsWithChildren, useEffect, useRef } from "react";

import { APP_NAME, SUPPORT_EMAIL } from "psims/constants/app";
import { BoxedDiv } from "psims/react/components/layout";
import Notification from "psims/react/components/notification";
import SupportLink from "../components/support-link";
import { useLogger } from "../providers/logging";

type ErrorWrapperProps = PropsWithChildren<{
    error?: Error;
}>

interface UncaughtErrorProps {
    Wrapper?: React.FunctionComponent<ErrorWrapperProps>;
}

// React still only seems to support error boundaries using Class Components...
class UncaughtError extends React.Component<
    UncaughtErrorProps, {
        customMessage?: React.ReactNode | undefined;
        error?: Error;
        hasError: boolean;
    }> {
    private WrapperComponent: Exclude<UncaughtErrorProps['Wrapper'], undefined> = ({children}) => <>{children}</>

	constructor(props: UncaughtErrorProps) {
		super(props);
		this.state = {
            error: undefined,
            hasError: false,
        };
        
        if (props.Wrapper) {
            this.WrapperComponent = props.Wrapper;
        }
	}

	static getDerivedStateFromError(e: Error) {
		return { 
            customMessage: e.message === 'Failed to load reference data' ? <>
                <p>The Liquid Fuels Gateway is currently unavailable due to an outage or scheduled maintenance.</p>
                <p>Please contact the Department at <SupportLink>{SUPPORT_EMAIL}</SupportLink> if you require assistance.</p>
            </>
            : undefined,
            error: e,
            hasError: true,
        };
	}

	render () {
        const W = this.WrapperComponent;

		if (this.state.hasError) {
			return (
                <W error={this.state.error}><Message customError={this.state.customMessage}/></W>
			);
		}

		return this.props.children;
	}
};

interface MessageProps {
    customError?: React.ReactNode;
}

const Message = ({customError}: MessageProps) => (
            <BoxedDiv box={{ flex: 'column', alignItems: 'center' }}>
                <BoxedDiv box={{marginV: 4}}>
                    <Notification kind='warning'>
                        <BoxedDiv box={{flex: 'column'}}>
                            {
                                customError ?
                                customError :
                                <>
                                    <p>
                                        There was an unexpected error processing your request. This has been logged for investigation by the {APP_NAME} team.
                                    </p>

                                    <p>
                                        Please go back to the <a href='/'>Home</a> page and try again.
                                    </p>
                                </>
                            }
                        </BoxedDiv>
                    </Notification>
                </BoxedDiv>
            </BoxedDiv>
);

export default UncaughtError;

export const LoggerErrorWrapper = ({children, error}: ErrorWrapperProps) => {
	const logger = useLogger({source: 'Application error'});
	const errorLogged = useRef(false);

	useEffect(() => {
		if (error != null && !errorLogged.current) {
			errorLogged.current = true;
			logger.exception(error);
		}
	}, [error, errorLogged, logger]);

	return <>{children}</>;
}