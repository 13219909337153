import { ProductionAreaDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";

export type ProductionArea = PopulatedProps<ProductionAreaDTO, 'id' | 'name' | 'productionAreaStateId' | 'productionAreaStateName'>;

export function isProductionArea(maybe?: unknown): maybe is ProductionArea {
    const asProductionArea = maybe as ProductionArea;
    const result = (
        asProductionArea != null &&
        asProductionArea.id != null &&
        asProductionArea.name != null &&
        asProductionArea.productionAreaStateId != null &&
        asProductionArea.productionAreaStateName != null
    );

    return result;
}

export function assertProductionArea(maybe?: unknown): asserts maybe is ProductionArea {
    const asProductionArea = maybe as ProductionArea;

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    assertNotEmpty(asProductionArea, hint('productionArea'));
    assertNotEmpty(asProductionArea.id, hint('productionArea.id'));
    assertNotEmpty(asProductionArea.name, hint('productionArea.name'));
    assertNotEmpty(asProductionArea.productionAreaStateId, hint('productionArea.productionAreaStateId'));
    assertNotEmpty(asProductionArea.productionAreaStateName, hint('productionArea.productionAreaStateName'));
}
