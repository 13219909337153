
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { MsalProvider as MsalProviderLib, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { useHistory } from 'react-router-dom';

import { AuthAccount } from 'psims/models/auth-account';
import { getLoginRequest, getMsalConfig } from 'psims/config/authConfig';
import { useConfiguration } from './configuration-provider';
import useUpdatedRef from '../util/use-updated-ref';
import { CustomNavigationClient } from '../util/auth-navigation-client';

type AuthStatus = 'unauthenticated' | 'authenticating' | 'authenticated';

type AuthAccountContext = {
	account: AuthAccount | null;
	logout: () => any;
	getToken: (() => Promise<string | null>) | null;
	status: AuthStatus;
};

const ctx = createContext<AuthAccountContext | null>(null);

const MsalProvider = ({children}: React.PropsWithChildren<{}>) => {
  const {configuration} = useConfiguration();
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null);
  const history = useHistory();
  const historyRef = useUpdatedRef(history);

  useEffect(() => {
    if (configuration !== null && msalInstance == null) {
	  const pca = new PublicClientApplication(getMsalConfig(configuration));
	  // Need to use a custom nav client in MSAL to correctly hook into login event
	  pca.setNavigationClient(new CustomNavigationClient(historyRef.current));
      setMsalInstance(pca);
    }
  }, [configuration, historyRef, msalInstance]);


  if (msalInstance === null) {
    return null;
  }

  return <MsalProviderLib instance={msalInstance}>{children}</MsalProviderLib>;
}

const AppAuthProvider = ({children}: React.PropsWithChildren<{}>) => {
	const isAuthenticated = useIsAuthenticated();
	const { accounts, instance, inProgress } = useMsal();
	const { configuration } = useConfiguration();
	
    const logout = () => {
        instance.logoutRedirect()
            .catch(console.error);
    };

	const account = useMemo(() => {
		return accounts ? accounts[0] as AuthAccount : null;
	}, [accounts]);

	const getToken = useMemo(() => {
		if (configuration === null || !account) {
			return null
		}

		return async () => {
			const loginRequest = getLoginRequest(configuration);

			try {
				const response =  await instance.acquireTokenSilent({
					...loginRequest,
					account,
				});

				return response?.accessToken || null;
			} catch (e) {
				instance.logout();
			}

			return null;
		}
	}, [account, configuration, instance]);

	const status = useMemo<AuthStatus>(() => {
		if (isAuthenticated) {
			return 'authenticated';
		}

		if (inProgress !== 'none') {
			return 'authenticating';
		}

		return 'unauthenticated';
	}, [inProgress, isAuthenticated]);

	return (
		<ctx.Provider value={{account, logout, getToken, status}}>
			{children}
		</ctx.Provider>
	);
};

const AuthProvider = ({children}: React.PropsWithChildren<{}>) => {
	return (
		<MsalProvider>
			<AppAuthProvider>
				{children}
			</AppAuthProvider>
		</MsalProvider>
	)
}
export default AuthProvider;

export function useAuth() {
	const c = useContext(ctx);

	if (c === null) {
		throw new Error('useAuth must be used in AuthProvider');
	}

	return {
		...c
	};
}
