/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllReferenceDataDTO } from '../models/AllReferenceDataDTO';
import type { ContentDTO } from '../models/ContentDTO';
import type { CountryDTO } from '../models/CountryDTO';
import type { ExternalReportStatusDTO } from '../models/ExternalReportStatusDTO';
import type { GeographicalAreaDTO } from '../models/GeographicalAreaDTO';
import type { MsoProductDto } from '../models/MsoProductDto';
import type { MsoStockOwnershipTypeDto } from '../models/MsoStockOwnershipTypeDto';
import type { OrganisationProductionAreaDTO } from '../models/OrganisationProductionAreaDTO';
import type { ProductDTO } from '../models/ProductDTO';
import type { ProductionAreaDTO } from '../models/ProductionAreaDTO';
import type { ProductionAreaStateDTO } from '../models/ProductionAreaStateDTO';
import type { ProductionProductDTO } from '../models/ProductionProductDTO';
import type { ProductionProductGroupDTO } from '../models/ProductionProductGroupDTO';
import type { ProductionTypeDTO } from '../models/ProductionTypeDTO';
import type { RefineryProductDTO } from '../models/RefineryProductDTO';
import type { RefineryProductGroupDTO } from '../models/RefineryProductGroupDTO';
import type { RefineryTypeDTO } from '../models/RefineryTypeDTO';
import type { RoleTypeDTO } from '../models/RoleTypeDTO';
import type { StateDTO } from '../models/StateDTO';
import type { StockProductDTO } from '../models/StockProductDTO';
import type { StockProductGroupDTO } from '../models/StockProductGroupDTO';
import type { StockTypeDTO } from '../models/StockTypeDTO';
import type { SubGeographicalAreaDTO } from '../models/SubGeographicalAreaDTO';
import type { TitleDTO } from '../models/TitleDTO';
import type { UserStatusDTO } from '../models/UserStatusDTO';
import type { WholesaleProductDTO } from '../models/WholesaleProductDTO';
import type { WholesaleProductGroupDTO } from '../models/WholesaleProductGroupDTO';
import type { WholesaleTypeDTO } from '../models/WholesaleTypeDTO';
import { request as __request } from '../core/request';

export class ReferenceDataService {

    /**
     * @returns AllReferenceDataDTO Success
     * @throws ApiError
     */
    public static async getAllReferenceData(): Promise<AllReferenceDataDTO> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/get-all`,
        });
        return result.body;
    }

    /**
     * @returns ExternalReportStatusDTO Success
     * @throws ApiError
     */
    public static async getExternalReportStatuses(): Promise<Array<ExternalReportStatusDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/external-report-status`,
        });
        return result.body;
    }

    /**
     * @returns StateDTO Success
     * @throws ApiError
     */
    public static async getStates(): Promise<Array<StateDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/state`,
        });
        return result.body;
    }

    /**
     * @returns CountryDTO Success
     * @throws ApiError
     */
    public static async getCountries(): Promise<Array<CountryDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/country`,
        });
        return result.body;
    }

    /**
     * @returns GeographicalAreaDTO Success
     * @throws ApiError
     */
    public static async getGeographicalAreas(): Promise<Array<GeographicalAreaDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/geographic-area`,
        });
        return result.body;
    }

    /**
     * @returns SubGeographicalAreaDTO Success
     * @throws ApiError
     */
    public static async getSubGeographicalAreas(): Promise<Array<SubGeographicalAreaDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/sub-geographic-area`,
        });
        return result.body;
    }

    /**
     * @returns ProductDTO Success
     * @throws ApiError
     */
    public static async getProducts(): Promise<Array<ProductDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/product`,
        });
        return result.body;
    }

    /**
     * @returns ProductionProductDTO Success
     * @throws ApiError
     */
    public static async getProductionProducts(): Promise<Array<ProductionProductDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/production-product`,
        });
        return result.body;
    }

    /**
     * @returns ProductionProductGroupDTO Success
     * @throws ApiError
     */
    public static async getProductionProductGroups(): Promise<Array<ProductionProductGroupDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/production-product-group`,
        });
        return result.body;
    }

    /**
     * @returns ProductionTypeDTO Success
     * @throws ApiError
     */
    public static async getProductionTypes(): Promise<Array<ProductionTypeDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/production-type`,
        });
        return result.body;
    }

    /**
     * @returns StockProductDTO Success
     * @throws ApiError
     */
    public static async getStockProducts(): Promise<Array<StockProductDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/stock-product`,
        });
        return result.body;
    }

    /**
     * @returns StockProductGroupDTO Success
     * @throws ApiError
     */
    public static async getStockProductGroups(): Promise<Array<StockProductGroupDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/stock-product-group`,
        });
        return result.body;
    }

    /**
     * @returns StockTypeDTO Success
     * @throws ApiError
     */
    public static async getStockTypes(): Promise<Array<StockTypeDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/stock-type`,
        });
        return result.body;
    }

    /**
     * @returns WholesaleProductDTO Success
     * @throws ApiError
     */
    public static async getWholesaleProducts(): Promise<Array<WholesaleProductDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/wholesale-product`,
        });
        return result.body;
    }

    /**
     * @returns WholesaleProductGroupDTO Success
     * @throws ApiError
     */
    public static async getWholesaleProductGroups(): Promise<Array<WholesaleProductGroupDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/wholesale-product-group`,
        });
        return result.body;
    }

    /**
     * @returns WholesaleTypeDTO Success
     * @throws ApiError
     */
    public static async getWholesaleTypes(): Promise<Array<WholesaleTypeDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/wholesale-type`,
        });
        return result.body;
    }

    /**
     * @returns RefineryProductDTO Success
     * @throws ApiError
     */
    public static async getRefineryProducts(): Promise<Array<RefineryProductDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/refinery-product`,
        });
        return result.body;
    }

    /**
     * @returns RefineryProductGroupDTO Success
     * @throws ApiError
     */
    public static async getRefineryProductGroups(): Promise<Array<RefineryProductGroupDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/refinery-product-group`,
        });
        return result.body;
    }

    /**
     * @returns RefineryTypeDTO Success
     * @throws ApiError
     */
    public static async getRefineryTypes(): Promise<Array<RefineryTypeDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/refinery-type`,
        });
        return result.body;
    }

    /**
     * @returns ProductionAreaDTO Success
     * @throws ApiError
     */
    public static async getProductionAreas(): Promise<Array<ProductionAreaDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/production-area`,
        });
        return result.body;
    }

    /**
     * @returns ProductionAreaStateDTO Success
     * @throws ApiError
     */
    public static async getProductionAreaStates(): Promise<Array<ProductionAreaStateDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/production-area-state`,
        });
        return result.body;
    }

    /**
     * @returns OrganisationProductionAreaDTO Success
     * @throws ApiError
     */
    public static async getOrganisationProductionAreas(): Promise<Array<OrganisationProductionAreaDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/organisation-production-area`,
        });
        return result.body;
    }

    /**
     * @returns OrganisationProductionAreaDTO Success
     * @throws ApiError
     */
    public static async getOrganisationProductionAreasForUser(): Promise<Array<OrganisationProductionAreaDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/organisation-production-area-user`,
        });
        return result.body;
    }

    /**
     * @returns TitleDTO Success
     * @throws ApiError
     */
    public static async getTitles(): Promise<Array<TitleDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/title`,
        });
        return result.body;
    }

    /**
     * @returns UserStatusDTO Success
     * @throws ApiError
     */
    public static async getUserStatuses(): Promise<Array<UserStatusDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/user-status`,
        });
        return result.body;
    }

    /**
     * @returns RoleTypeDTO Success
     * @throws ApiError
     */
    public static async getRoleTypes(): Promise<Array<RoleTypeDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/role-type`,
        });
        return result.body;
    }

    /**
     * @returns ContentDTO Success
     * @throws ApiError
     */
    public static async getContent(): Promise<Array<ContentDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/content`,
        });
        return result.body;
    }

    /**
     * @returns MsoProductDto Success
     * @throws ApiError
     */
    public static async getMsoProducts(): Promise<Array<MsoProductDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/mso-products`,
        });
        return result.body;
    }

    /**
     * @returns MsoStockOwnershipTypeDto Success
     * @throws ApiError
     */
    public static async getMsoStockOwnershipTypes(): Promise<Array<MsoStockOwnershipTypeDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/reference-data/mso-stock-ownership-types`,
        });
        return result.body;
    }

}