import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { BoxedDiv } from 'psims/react/components/layout';
import TermsAndConditions, { useTermsAndConditionsContent } from 'psims/react/blocks/terms-and-conditions';
import Page from 'psims/react/blocks/page';
import { H2 } from 'psims/react/components/typography';
import Button from 'psims/react/components/button/button';
import Notification from 'psims/react/components/notification';
import { useAPI } from 'psims/react/providers/api';
import { useNavigation } from 'psims/react/providers/router';
import { useUser } from '../providers/user';
import { isUser, userVMToUser } from 'psims/models/user';

interface AcceptTermsAndConditionsPageProps {}

const DimmedPage = styled(Page)`
    overflow-y: scroll;

    &::after {
        content: "";
        display: block;
        position: fixed; /* could also be absolute */ 
        top: 0;
        left: 0;
        height: 100%;
        // Don't cover scrollbar:
        width: calc(100% - 17px);
        z-index: 10;
        background-color: rgba(0,0,0,0.2);
    }
`;

const Panel = styled.div`
    background: white;
    margin: 0 auto;
    margin-top: -60px;
    max-width: 750px;
    padding: 36px 62px 36px 70px;
    z-index: 11;

`;


const AcceptTermsAndConditionsPage = (props: AcceptTermsAndConditionsPageProps) => {
    const vm = useVM(props);

    return (
        <DimmedPage hideNavbar={true} hideUserBlock={true}>
            <main className='container'>
                <BoxedDiv box={{alignSelf: 'stretch', flex: 'column', flexGrow: 1, justifySelf: 'center', marginTop:4}}>
                    <Panel>
                        <H2>Terms and conditions</H2>

                        <BoxedDiv box={{marginV: 2}}>
                            <p><em>You must read and accept these Terms and Conditions before proceeding.</em></p>

                            <p><em>By clicking on the “agree” button, you indicate your acknowledgement and agreement to the Terms and Conditions set out on this page.</em></p>
                        </BoxedDiv>

                        <BoxedDiv box={{marginV: 4}}>
                            <TermsAndConditions />
                        </BoxedDiv>

                        {
                            vm.tAndCsMarkdown != null && <>

                            <BoxedDiv box={{borderTop: {color: 'border-primary-faded', width: 2}, marginV: 2, paddingTop: 3}}>
                                <p><em>Please accept the Terms and Conditions to continue.</em></p>
                            </BoxedDiv>

                            {
                                vm.error != null &&

                                <BoxedDiv box={{marginV: 2}}>
                                    <Notification kind='warning'>
                                        {vm.error}
                                    </Notification>
                                </BoxedDiv>
                            }

                            <BoxedDiv box={{flex: 'row', justifyContent: 'flex-end'}}>
                                <Button
                                    $kind='primary'
                                    onClick={vm.onAgree}
                                    state={vm.status === 'submitting' ? 'loading' : 'normal'}
                                >Agree</Button>
                            </BoxedDiv>
                            </>
                        }
                    </Panel>
                </BoxedDiv>
            </main>
        </DimmedPage>
    );
}

type Status = 'error' | 'idle' | 'submitting' | 'success';

function useVM(props: AcceptTermsAndConditionsPageProps) {
    const {api} = useAPI();
    const {setUser} = useUser();
    const nav = useNavigation();
    const {tAndCsMarkdown} = useTermsAndConditionsContent();
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState<Status>('idle');

    const onAgree = useCallback(() => {
        setStatus('submitting');
        setError(null);

        api.userAcceptTermsAndConditions()
        .then((response) => {
            if (isUser(response.result)) {
                setUser(userVMToUser(response.result));
            } else {
                throw new Error(`Failed to unmarshal user result: ${JSON.stringify(response.result)}`);
            }
        })
        .then(r => {
            setStatus('success');
        })
        .catch(e => {
            setStatus('error');
            setError('Failed to update your acceptance of terms and conditions');
        });
    }, [api, setUser]);

    useEffect(() => {
        if (status === 'success') {
            nav.goToHomePage();
        }
    }, [nav, status]);

    return {
        error,
        onAgree,
        status,
        tAndCsMarkdown,
    };
}

export default AcceptTermsAndConditionsPage;
