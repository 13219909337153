import Text from "psims/react/components/text";
import Expandable from "psims/react/components/expandable";
import { BoxedDiv } from "psims/react/components/layout";
import HelpBlock from "psims/react/components/help";
import { REFINERY_DISPLAY_NAMES } from "psims/models/ref-data/refinery-type";

const CONTENT_MAP = {
    'Refinery Input': {
        title: REFINERY_DISPLAY_NAMES['Refinery Input'],
        Help: () => {
            return (<>
                <p>
                    <Text>
                        <strong>Report in tonnes</strong>. Density should be the average weighted density for the product during the month, careful estimates are permissible.
                    </Text>	
                </p>
                <BoxedDiv box={{marginTop: 2}}>
                    <Expandable
                        collapsedLabel='Show more information on the units of measure for reporting'
                        expandedLabel='Hide more information on the units of measure for reporting'
                        contentPlacement='below'
                    >
                        <HelpBlock box={{flex: 'column', marginBottom: 0.5, marginTop: 1}}>
                            <p>Quantities must be reported in whole metric tonnes (no decimal places) and each product’s average density should be reported in kilograms per litre.</p>
                            <p>
                                If the density for each product is not readily available each month, the most recent density measurement should be used. If no measurement is available, a typical or average value may be entered, which should be reviewed and updated as appropriate.  
                            </p>
                            <p>
                                Density information is required to derive volumetric information that the Department needs for various statistical reports. It must be adjusted to standard temperature and pressure conditions of 15°C and 1 atmosphere pressure.
                            </p>
                        </HelpBlock>
                    </Expandable>
                </BoxedDiv> 
            </>);
        }
    },

    'Refinery Output': {
        title: REFINERY_DISPLAY_NAMES['Refinery Output'],
        Help: () => {
            return (<>
                <p>
                    <Text>
                        <strong>Report in tonnes</strong>. Density should be the average weighted density for the product during the month, careful estimates are permissible.
                    </Text>	
                </p>
                <BoxedDiv box={{marginTop: 2}}>
                    <Expandable
                        collapsedLabel='Show more information on the units of measure for reporting'
                        expandedLabel='Hide more information on the units of measure for reporting'
                        contentPlacement='below'
                    >
                        <HelpBlock box={{flex: 'column', marginBottom: 0.5, marginTop: 1}}>
                            <p>Quantities must be reported in whole metric tonnes (no decimal places) and each product’s average density should be reported in kilograms per litre.</p>
                            <p>
                                If the density for each product is not readily available each month, the most recent density measurement should be used. If no measurement is available, a typical or average value may be entered, which should be reviewed and updated as appropriate.  
                            </p>
                            <p>
                                Density information is required to derive volumetric information that the Department needs for various statistical reports. It must be adjusted to standard temperature and pressure conditions of 15°C and 1 atmosphere pressure.
                            </p>
                        </HelpBlock>
                    </Expandable>
                </BoxedDiv> 
            </>);
        }
    },

    'Gases-Unfin-Petrochem-Losses': {
        title: REFINERY_DISPLAY_NAMES['Gases-Unfin-Petrochem-Losses'],
        Help: () => {
            return (<>
                <p>
                    <Text>
                        <strong>Report in tonnes</strong>. Density should be the average weighted density for the product during the month, careful estimates are permissible.
                    </Text>	
                </p>
                <BoxedDiv box={{marginTop: 2}}>
                    <Expandable
                        collapsedLabel='Show more information on the units of measure for reporting'
                        expandedLabel='Hide more information on the units of measure for reporting'
                        contentPlacement='below'
                    >
                        <HelpBlock box={{flex: 'column', marginBottom: 0.5, marginTop: 1}}>
                            <p>Quantities must be reported in whole metric tonnes (no decimal places) and each product’s average density should be reported in kilograms per litre.</p>
                            <p>
                                If the density for each product is not readily available each month, the most recent density measurement should be used. If no measurement is available, a typical or average value may be entered, which should be reviewed and updated as appropriate.  
                            </p>
                            <p>
                                Density information is required to derive volumetric information that the Department needs for various statistical reports. It must be adjusted to standard temperature and pressure conditions of 15°C and 1 atmosphere pressure.
                            </p>
                        </HelpBlock>
                    </Expandable>
                </BoxedDiv> 
            </>);
        }
    },

    Submit: {
        title: 'Declaration',
        Help: () => {
            return (null);
        }
    },
}

export default CONTENT_MAP;
