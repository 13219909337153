import { getSubmissionTypeName } from "psims/constants/data-submissions";
import { getMonthNameFull } from "psims/lib/formatters/datetime";
import { DataSubmissionTypeName, ExtendedDataSubmissionTypeName } from "psims/models/data-submission";
import { HistoryItem, HistoryList, isHistoryItem } from "psims/models/reporting-history";

const REPORTING_HISTORY_TYPE_NAMES = [
    'Mso',
    'Pofr',
] as const;

type ReportingHistoryTypeName = ArrayElement<typeof REPORTING_HISTORY_TYPE_NAMES>;

export interface ReportingHistoryItem extends HistoryItem {
    submissionTypeNameExtended: ExtendedDataSubmissionTypeName;
    type: ReportingHistoryTypeName;
}

export function mapApiResponseToReportingHistoryItems(apiResult: HistoryList | null | undefined): Array<ReportingHistoryItem> {
    if(apiResult == null || apiResult.historyItems == null){
        throw new Error('Failed to parse Mso reporting history items, Null or Undefined result');
    }

    return apiResult.historyItems.filter(isHistoryItem).map((item) => ({
        ...item,
        submissionTypeNameExtended: getSubmissionTypeName(item),
        type: getReportType(item.submissionTypeName)
    }))
}

function getReportType(reportName: DataSubmissionTypeName): ReportingHistoryTypeName {
    switch (reportName) {
        case 'MSO importer':
          return 'Mso';
        case 'MSO refiner':
          return 'Mso';
        default:
          return 'Pofr';
      }
}

export function getReportYear(item: ReportingHistoryItem) {
    if (item.obligationDate) {
        return (new Date(item.obligationDate).getFullYear());
    }

    if (item.type === 'Pofr') {
        return item.reportingYear;
    }

    if (item.type === 'Mso' && item.reportFrequencyTypeName === 'Annually') {
        return item.reportingYear;
    }
}

export function getReportMonth(item: ReportingHistoryItem) {
    if (item.obligationDate) {
        return getMonthNameFull(new Date(item.obligationDate).getMonth());
    }

    if (item.type === 'Pofr' && item.reportingMonth != null) {
        return getMonthNameFull(item.reportingMonth - 1);
    }

    return '';
}
