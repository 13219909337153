import { useAPI } from "psims/react/providers/api";
import useDataSubmissionAPI from "psims/react/pages/primary-pages/data-submissions/shared/use-data-submission-api";
import { isSuccesfulRowResult } from "psims/models/api/record-result";
import { getRecordResults, isFspSubmission, isFspSubmitted } from "psims/models/submission-types/fsp/fsp-submission";
import { useMemo } from "react";

interface UseFspAPIProps {
    id: number;
}

function useFspAPI({id}: UseFspAPIProps) {
    const {api} = useAPI();

    const fsAPI = useDataSubmissionAPI({
        actionFetch: api.getFsspSubmission,
        actionSubmit: api.submitFssp,
        actionUpdate: api.updateFssp,
        actionUpdateDataSubmission: api.updateFsspDataSubmission,
        actionClearAllData: api.clearAllFssp,
        dataSubmissionId: id,
        name: 'FSSP s19A',
        submissionAssertion: isFspSubmission,
        submitSuccessAssertion: isFspSubmitted,
        updateSuccessAssertion,
    });

    return useMemo(() => fsAPI, [fsAPI]);
}

export default useFspAPI;

export type UseFspAPI = ReturnType<typeof useFspAPI>;

function updateSuccessAssertion(result: unknown) {
    if (result == null) {
        return false;
    }

    return getRecordResults(result)
        .filter(r => !isSuccesfulRowResult(r)).length === 0;
}
