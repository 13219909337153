import { MsoSubmissionFormData } from "psims/models/submission-types/mso/mso-submission-form-data";
import { useMemo } from "react";
import { ViewMode } from "../../shared/use-view-mode";
import useProgress from "../../shared/use-progress";
import { UseMsoRefinerAPI } from "./use-mso-refiner-api";

interface UseMsoRefinerProgressProps {
    apiCtrl: PopulatedProps<UseMsoRefinerAPI, 'submission'>;
    viewMode: ViewMode;
}

function useMsoRefinerProgress({apiCtrl, viewMode}: UseMsoRefinerProgressProps) {
    const steps = useMemo(() => {
        return [
            {
                kind: 'data' as 'data',
                label: 'Stockholding - Refiner',
                hasData: Boolean(apiCtrl.submission.submissionFormData.dataPageSaved),
            },
            {
                kind: 'submit' as 'submit',
                label: 'Declaration',
                hasData: null,
            }
        ];
    }, [apiCtrl.submission.submissionFormData.dataPageSaved]);

    const progressCtrl = useProgress({
        orderedFormDataPageSavedKeys: ['dataPageSaved'],
        steps,
        submissionFormData: apiCtrl.submission.submissionFormData as MsoSubmissionFormData,
        submissionStatus: apiCtrl.submission.dataSubmission.status,
        viewMode,
    });

    return progressCtrl;
}

export default useMsoRefinerProgress

export type UseMsoRefinerProgress = ReturnType<typeof useMsoRefinerProgress>;
