/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductionSubmissionVMApiResponse } from '../models/ProductionSubmissionVMApiResponse';
import type { UpdateDataSubmissionVM } from '../models/UpdateDataSubmissionVM';
import type { UpdateFieldProductionSubmissionVM } from '../models/UpdateFieldProductionSubmissionVM';
import { request as __request } from '../core/request';

export class FieldProductionService {

    /**
     * @returns ProductionSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async getFieldProductionSubmission({
id,
}: {
id?: number,
}): Promise<ProductionSubmissionVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/field-production`,
            query: {
                'id': id,
            },
        });
        return result.body;
    }

    /**
     * @returns ProductionSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateFieldProduction({
requestBody,
}: {
requestBody?: UpdateFieldProductionSubmissionVM,
}): Promise<ProductionSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/field-production/Update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns ProductionSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async updateFieldProductionDataSubmission({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<ProductionSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/field-production/Update-DataSubmission`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns ProductionSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async submitFieldProduction({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<ProductionSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/field-production/Submit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns ProductionSubmissionVMApiResponse Success
     * @throws ApiError
     */
    public static async clearAllFieldProduction({
requestBody,
}: {
requestBody?: UpdateDataSubmissionVM,
}): Promise<ProductionSubmissionVMApiResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/field-production/Clear-All`,
            body: requestBody,
        });
        return result.body;
    }

}