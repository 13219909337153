import { UpdateMsoField } from "psims/models/submission-types/mso/mso";
import { UpdateMsoStockOnBehalfField } from "psims/models/submission-types/mso/mso-stock-on-behalf";
import { UpdateMsoStockRefineryHeldField } from "psims/models/submission-types/mso/mso-stock-refinery";
import { UpdateMsoStockholdingField } from "psims/models/submission-types/mso/mso-stockholding";
import { UpdateMsoStockholdingOwnershipField } from "psims/models/submission-types/mso/mso-stockholding-ownership";

export type StockholdingFocusField = {
    kind: 'stockholding';
    field: UpdateMsoStockholdingField | 'delete';
    msoProductId: number;
}

export type OwnershipFocusField = {
    kind: 'ownership';
    field: UpdateMsoStockholdingOwnershipField;
    rowIndex: number;
    msoProductId: number;
}

export type StockOnBehalfFocusField = {
    kind: 'stockOnBehalf';
    field: UpdateMsoStockOnBehalfField;
    msoProductId: number;
    rowIndex: number;
}

export type StockRefineryHeldFocusField = {
    kind: 'stockRefineryHeld';
    field: UpdateMsoStockRefineryHeldField;
    msoProductId: number;
}

export type MsoFocusField = {
    kind: 'mso';
    field: UpdateMsoField;
    msoProductId: number;
}

export type DeleteFocusField = {
    kind: 'stockholding' | 'onBehalf';
    field: 'delete';
    msoProductId: number;
}

export function isDeleteFocusField(maybe?: unknown): maybe is DeleteFocusField {
    const maybeAs = maybe as DeleteFocusField;

    return (
        maybeAs != null &&
        maybeAs.field === 'delete' &&
        maybeAs.msoProductId != null
    );
}

