import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { BoxedDiv } from './layout';
import { LargeTitle } from './typography';

interface PageHeaderProps {}

const PageHeader = ({children}: PropsWithChildren<PageHeaderProps>) => {
    return (
        <BoxedDiv box={{alignSelf: 'stretch', background: 'grey-faded', flex: 'column', paddingV: 6}}>
            <div className='container'>
                {children}
            </div>
        </BoxedDiv>
    );
}

export default PageHeader;

export const PageHeaderTitle = ({children}: PropsWithChildren<{}>) => {
    return (
        <BoxedDiv box={{marginBottom: 2}}>
            <LargeTitle>{children}</LargeTitle>
        </BoxedDiv>
    )
}


const StyledPageHeaderDetailsTable = styled.table`
    margin-top: 16px;

    & th {
        padding: 0 30px 4px 0;
    }

    & td {
        padding: 0 0 4px 0;
    }

`;

export const PageHeaderDetailsTable = ({children}: React.PropsWithChildren<{}>) => <StyledPageHeaderDetailsTable role='presentation'>
    <tbody>{children}</tbody>
</StyledPageHeaderDetailsTable>
