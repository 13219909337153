import { useCallback, useEffect, useMemo, useState } from "react";

import { SELECTOR_NOTIFICATION, SELECTOR_NOTIFICATIONS } from "psims/constants/selectors";
import useConfirmNavigation from "psims/react/util/use-confirm-navigation";
import useDataSubmissionSaveCheck from "psims/react/pages/primary-pages/data-submissions/shared/use-data-submission-save-check";
import { UseSubmit } from "../shared/use-submit";
import { ViewMode } from "../shared/use-view-mode";
import { attemptFocusOnSelector, attemptScrollToSelector } from "../shared/view-utils";
import { UseActivities } from "./activities/use-activities";
import { UseMarketExpectations } from "./market-expectations/use-market-expectations";
import { UseDefAPI } from "./use-def-api";
import { UseDefProgress } from "./use-def-progress";
import { isEmpty } from "psims/lib/empty";
import { UseConfirm } from "../shared/use-confirm";
import { UseDefSubmissionValidationAlerts } from "./use-def-submission-validation-alerts";
import useUpdatedRef from "psims/react/util/use-updated-ref";

interface UseDefNavigationProps {
  activitiesCtrl: UseActivities;
  apiCtrl: UseDefAPI;
  marketExpectationsCtrl: UseMarketExpectations;
  progressCtrl: UseDefProgress;
  submissionValidationAlertsCtrl: UseDefSubmissionValidationAlerts
  submitCtrl: UseSubmit;
  validationAlertsConfirmCtrl: UseConfirm;
  viewMode: ViewMode;
}

function useDefNavigation({ activitiesCtrl, apiCtrl, progressCtrl, marketExpectationsCtrl, submissionValidationAlertsCtrl, submitCtrl, validationAlertsConfirmCtrl, viewMode }: UseDefNavigationProps) {
  const [navAttempted, setNavAttempted] = useState(false);
  const hasUnsavedChanges = useMemo(() => {
    return (
      progressCtrl.currentStep.index === 0 ?
        activitiesCtrl.changeStatus === 'dirty' || marketExpectationsCtrl.data.data.recordAction != null :
        submitCtrl.hasUnsavedChanges
    );
  }, [
    activitiesCtrl.changeStatus,
    marketExpectationsCtrl.data.data.recordAction,
    progressCtrl.currentStep.index,
    submitCtrl.hasUnsavedChanges
  ]);

  const saveCheckCtrl = useDataSubmissionSaveCheck({
    apiCtrl,
    hasUnsavedChanges,
    updateDataSubmission: submitCtrl.dataSubmissionUpdate,
    updateSubmission: {
      dataSubmissionId: apiCtrl.submission?.dataSubmission.id,
      activities: activitiesCtrl.getRequestPayload(),
      marketExpectations: marketExpectationsCtrl.getRequestPayload(),
    }
  });

  const browserNavCtrl = useConfirmNavigation({ when: hasUnsavedChanges && viewMode === 'edit' });

  const [lastSavedPageIndex, setLastSavedPageIndex] = useState<number | null>(null);
  const [lastSaveAttemptIndex, setLastSaveAttemptIndex] = useState<number | null>(null);

  const isPageValid = useMemo(() => {
    switch (progressCtrl.currentStep.index) {
      case 0: {
        return [
          ...activitiesCtrl.data.map(d => d.validation).flat(),
          ...marketExpectationsCtrl.data.validation
        ].length === 0
      }

      case 1: {
        return isEmpty(submitCtrl.validationMessages.comments.validationMessages);
      }

      default: return true;
    }
  }, [
    activitiesCtrl.data,
    marketExpectationsCtrl.data,
    progressCtrl.currentStep.index,
    submitCtrl.validationMessages.comments.validationMessages
  ]);

  const hasValidationAlerts = useMemo(() => {
    return (
      (submissionValidationAlertsCtrl.validationAlerts.length + activitiesCtrl.validationAlerts.length) > 0
    );
  }, [submissionValidationAlertsCtrl.validationAlerts, activitiesCtrl.validationAlerts]);

  const hasValidationAlertsRef = useUpdatedRef(hasValidationAlerts)

  useEffect(() => {
    if (browserNavCtrl.showPrompt && saveCheckCtrl.saveState === 'idle') {
      saveCheckCtrl.checkAndConfirm(
        progressCtrl.currentStep.kind === 'data' ? 'submission' : 'data_submission',
        () => browserNavCtrl.handleDecision(true),
        () => browserNavCtrl.handleDecision(false)
      )
    }
  }, [browserNavCtrl, progressCtrl, saveCheckCtrl]);

  const unsavedChangesDialogCtrl = useMemo(() => ({
    controlsState: (saveCheckCtrl.saveState === 'saving' ? 'loading' : 'normal') as 'loading' | 'normal',
    isOpen: saveCheckCtrl.saveState === 'confirming',
    onCancel: saveCheckCtrl.cancel,
    onConfirm: () => saveCheckCtrl.save(),
    onSecondary: saveCheckCtrl.proceed,
  }), [saveCheckCtrl]);

  // Final navigator that ensures validation alerts dialog shows
  const navigate = useCallback((index: number) => {
    if (viewMode !== 'edit') {
      progressCtrl.goToStep(index);
    }

    if (progressCtrl.currentStep.kind === 'data' && hasValidationAlertsRef.current) {
      validationAlertsConfirmCtrl.requestConfirmation(
        'unused',
        'unused',
        () => progressCtrl.goToStep(index),
        () => {
          attemptFocusOnSelector(`${SELECTOR_NOTIFICATION('info')}`);
        }
      )
    } else {
      progressCtrl.goToStep(index);
    }
  }, [hasValidationAlertsRef, progressCtrl, validationAlertsConfirmCtrl, viewMode])

  const onSave = useCallback((index: number) => {
    setLastSavedPageIndex(index);
    setTimeout(() => {
      navigate(index);
    }, 0);
  }, [navigate]);

  const ensureValid = useCallback(() => {
    if (!isPageValid) {
      attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
    }

    return isPageValid;
  }, [isPageValid]);

  const attemptStepNavigation = useCallback((index: number) => {
    setNavAttempted(true);
    if (viewMode !== 'edit') {
      progressCtrl.goToStep(index);
      return;
    }

    // If on Declaration page, don't enforce validation
    const isValid = progressCtrl.currentStep.kind === 'submit' || ensureValid();

    if (!isValid) {
      return;
    }

    saveCheckCtrl.checkAndConfirm(
      progressCtrl.currentStep.kind === 'data' ? 'submission' : 'data_submission',
      decision => decision === 'save' ? onSave(index) : progressCtrl.goToStep(index),
      () => { }
    )
  }, [ensureValid, onSave, progressCtrl, saveCheckCtrl, viewMode]);

  const saveAndGoToPage = useCallback((index: number) => {
    setLastSaveAttemptIndex(progressCtrl.currentStep.index);
    setNavAttempted(true);

    // If on Declaration page, don't enforce validation
    const isValid = progressCtrl.currentStep.kind === 'submit' || ensureValid();

    if (!isValid) {
      return;
    }

    saveCheckCtrl.save(progressCtrl.currentStep.kind === 'data' ? 'submission' : 'data_submission', () => onSave(index));
  }, [ensureValid, onSave, progressCtrl, saveCheckCtrl]);

  const attemptSubmit = useCallback(() => {
    submitCtrl.attemptSubmit();
  }, [submitCtrl]);

  // Clear last save attempt on page change
  useEffect(() => {
    setLastSaveAttemptIndex(null);
    setNavAttempted(false);
  }, [progressCtrl.currentStep.index]);

  return useMemo(() => ({
      lastSaveAttemptIndex,
      lastSavedPageIndex,
      navAttempted,
      saveAttempted: lastSaveAttemptIndex != null,
      unsavedChangesDialogCtrl,
      attemptStepNavigation,
      attemptSubmit,
      saveAndGoToPage,
  }), [
    lastSaveAttemptIndex,
    lastSavedPageIndex,
    navAttempted,
    unsavedChangesDialogCtrl,
    attemptStepNavigation,
    attemptSubmit,
    saveAndGoToPage
  ]);
}

export default useDefNavigation

export type UseDefNavigation = ReturnType<typeof useDefNavigation>;
