const UPDATE_RESULT_ENUM = [
    'ConcurrencyError',
    'Created',
    'Deleted',
    'DependencyError',
    'SystemError',
    'TranslationError',
    'Unchanged',
    'Updated',
] as const;

type UpdateResultEnum = ArrayElement<typeof UPDATE_RESULT_ENUM>;

// Base raw record result 
interface APIRecordResult {
    rowResult?: string | null;
    message?: string | null;
}

export interface RecordResult {
    rowResult: UpdateResultEnum;
    message?: string;
};

type WithAPIRecordResult<TData> = TData & {
    recordResult?: APIRecordResult | null;
}

type MaybeWithRecordResult<TData> = TData & {
    recordResult: RecordResult | null;
}

export function isSuccesfulRowResult({rowResult}: RecordResult, treatEmptyRowResultAsSuccessful?: boolean) {
    return rowResult === 'Created' ||
           rowResult === 'Deleted' ||
           rowResult === 'Unchanged' ||
           rowResult === 'Updated' ||
           (!rowResult && treatEmptyRowResultAsSuccessful === true);
}

export function isRecordResult(maybe?: APIRecordResult | null): maybe is RecordResult {
    return UPDATE_RESULT_ENUM.includes(maybe?.rowResult as UpdateResultEnum) &&
           (isSuccesfulRowResult(maybe as RecordResult) ? true : maybe?.message != null);
}

function isMaybeRecordResult(maybe?: APIRecordResult | null): maybe is RecordResult | null {
    return UPDATE_RESULT_ENUM.includes(maybe?.rowResult as UpdateResultEnum) &&
           (isSuccesfulRowResult(maybe as RecordResult) ? true : maybe?.message != null);
}

export function isMaybeWithRecordResult<TData>(maybe?: WithAPIRecordResult<TData> | null): maybe is MaybeWithRecordResult<TData> {
    return maybe != null && (
           isMaybeRecordResult(maybe.recordResult) ||
           maybe.recordResult === null
    );
}
