import { useCallback, useEffect, useMemo, useState } from "react";

import { any } from "psims/lib/collections";
import { recordActionAsEnum, RecordActionEnum } from "psims/models/api/data-submission-record-action";
import { isRecordResult, RecordResult } from "psims/models/api/record-result";
import { is } from "psims/lib/type-assertions";

interface Entity {
  id?: number | null;
  concurrencyToken?: string | null;
  recordResult?: RecordResult | null;
}

export type Draft<TEntity extends Entity> = Omit<Partial<TEntity>, 'recordResult'> & {
  _id: symbol;
  recordAction?: RecordActionEnum;
}

type CreatePlaceholdersConfig<TEntity extends Entity, TRefData> = {
  creator: (refDataItem: TRefData) => Partial<TEntity> | null;
  matcher: (refDataItem: TRefData, data: TEntity) => boolean;
}

interface UseUpdateableProps<TEntity extends Entity, TRefData, TDataFields extends Readonly<Array<keyof TEntity & string>>> {
  createPlaceholders?: CreatePlaceholdersConfig<TEntity, TRefData>;
  dataFields: TDataFields;
  initialData: Array<TEntity>;
  refData?: Array<TRefData>;
}

type ChangeStatus = 'clean' | 'dirty';

function useUpdateable<TEntity extends Entity, TRefData, TDataFields extends Readonly<Array<keyof TEntity & string>>>({createPlaceholders, dataFields, initialData, refData}: UseUpdateableProps<TEntity, TRefData, TDataFields>) {
  const [_createPlaceholders] = useState(createPlaceholders);
  const [data, setData] = useState(initialiseData(initialData, refData, _createPlaceholders));
  const [changeStatus, setChangeStatus] = useState<ChangeStatus>('clean');
  
  const dataWithFieldState = useMemo(() => {
    return data
      .map(item => ({
        ...item,
        fieldsState: dataFields
          .reduce((memo, df) => ({
            ...memo,
            [df]: areFieldsEqual(item, initialData.find(d => d.id === item.id), df) ? 'unchanged' : 'changed'
          }), {} as {[key in typeof dataFields[number]]: 'changed' | 'unchanged'})
        })
      );
  }, [data, dataFields, initialData])

  useEffect(() => {
    setData(initialiseData(initialData, refData, _createPlaceholders));
  }, [initialData, refData, _createPlaceholders]);

  useEffect(() => {
    setChangeStatus('clean');
  }, [initialData]);

  const addItem = useCallback((item: Partial<TEntity>) => {
    setChangeStatus('dirty');

    const newItem = createDraft(item);
    setData(prev => {
      return [
        ...prev,
        newItem
      ];
    });

    return newItem;
  }, []);

  const deleteItem = useCallback((item: Draft<TEntity>) => {
    setChangeStatus('dirty');

    setData(prev => {
      const index = prev.findIndex(x => x._id === item._id);
      if (index < 0) {
        return prev;
      }

      const nextValue = [...prev];

      if (item.id != null) {
        nextValue.splice(index, 1, {
          ...item,
          recordAction: recordActionAsEnum('Delete'),
        });
      } else {
        nextValue.splice(index, 1);
      }

      return nextValue;
    })
  }, []);

  const updateItem = useCallback(<TField extends (typeof dataFields)[number]>(item: Draft<TEntity>, field: TField, val: TEntity[TField] | null) => {
    setChangeStatus('dirty');
    
    setData(prev => {
      const index = prev.findIndex(x => x._id === item._id);
      if (index < 0) {
        return prev;
      }

      const nextValue = [...prev];
      nextValue.splice(index, 1, {
        ...item,
        [field]: val,
        recordAction: item.id == null ? recordActionAsEnum('Create') : recordActionAsEnum('Update'),
      });

      return nextValue;
    });
  }, []);

  return {
    changeStatus,
    data: dataWithFieldState,
    addItem,
    deleteItem,
    updateItem,
  };
}

function createDraft<TEntity extends Entity>(data: TEntity): Draft<TEntity> {
  const {recordResult, ...rest} = data;
  return {
    _id: Symbol(),
    ...rest
  }
}

function addPlaceholders<TEntity extends Entity, TRefData>(
  creator: (refDataItem: TRefData) => Partial<TEntity> | null,
  existingItems: Array<TEntity>,
  matcher: (refDataItem: TRefData, data: TEntity) => boolean,
  refData: Array<TRefData>
): Array<Draft<TEntity>> {
  const newItems = refData
    .filter(i => !any(existingItems, d => matcher(i, d)))
    .map(creator)
    .filter(is)
    .map(item => ({
      ...item,
      _id: Symbol()
    }));

  return newItems;
}

function initialiseData<
  TEntity extends Entity,
  TRefData
>(
  initialData: Array<TEntity>,
  refData?: Array<TRefData>,
  createPlaceholders?: CreatePlaceholdersConfig<TEntity, TRefData>
) {
  const withoutDeleted = initialData.filter(d => isRecordResult(d.recordResult) ? d.recordResult.rowResult !== 'Deleted' : true);

  if (refData == null || createPlaceholders == null) {
    return withoutDeleted.map(createDraft);
  }

  return [
    ...withoutDeleted.map(createDraft),
    ...addPlaceholders(createPlaceholders.creator, withoutDeleted, createPlaceholders.matcher, refData),
  ];
}

function areFieldsEqual(a: unknown, b: unknown, field: string) {
  const aAs = a as {[key: typeof field]: any}
  const bAs = b as {[key: typeof field]: any}
  return (
    aAs != null &&
    bAs != null &&
    aAs[field] === bAs[field]
  );
}

export default useUpdateable

export type UseUpdateable = ReturnType<typeof useUpdateable>;
