import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { ValidationResult } from "./storage-sites-validation";

export function getInlineMessage(validationResult: ValidationResult | null) {
  if (validationResult == null) {
    return undefined;
  }

  switch (validationResult.code) {
    case 'character_limit':
      return `Must be ${localeNumberWithFixed(validationResult.limit)} characters or fewer`;
  
    case 'invalid_date_format':
      return 'Must be valid date in the format dd/mm/yyyy';

    case 'invalid_date_ordering':
      return 'Storage to date cannot be before storage from date';

    case 'invalid_integer':
    case 'invalid_integer_range':
      return `Must be ${validationResult.min === 0 ? 'a positive' : 'an'} integer (zero is permitted) with a maximum value of ${validationResult.min < 0 ? '+/-' : ''}${localeNumberWithFixed(validationResult.max)}`;
  }
}