/* Handles one-off autofocusing of field on first render */

import { useEffect, useState } from "react";

function useIsAutoFocused(isAutoFucsused: boolean) {
    const [isFocused, setIsFocused] = useState(isAutoFucsused);

    useEffect(() => {
        setTimeout(() => setIsFocused(false), 0)
    }, []);

    return isFocused;
}

export default useIsAutoFocused;
