import { useEffect } from "react";

import { BoxedDiv } from "psims/react/components/layout";
import Modal from "psims/react/components/modal";
import Spinner from "psims/react/components/spinner";
import { SubmissionStatus } from "./api";
import { useAppOverlay } from "psims/react/providers/app-overlay";
import Text from "psims/react/components/text";

interface UseSubmissionProcessingOverlayProps {
    submissionStatus: SubmissionStatus;
}

function useSubmissionProcessingOverlay({submissionStatus}: UseSubmissionProcessingOverlayProps) {
    const {setOverlay} = useAppOverlay();

    useEffect(() => {
        const loadingMessage = (
            submissionStatus === 'submitting' ? 'Submitting your data...' :
            submissionStatus === 'updating' ? 'Processing...' :
            undefined
        );

        if (loadingMessage) {
            setOverlay(<Modal>
                <BoxedDiv box={{alignItems: 'center', flex: 'row'}}>
                    <Spinner size='lg' />
                    <BoxedDiv box={{alignItems: 'center', flex: 'row', marginLeft: 2}}>
                        <Text>{loadingMessage}</Text>
                    </BoxedDiv>
                </BoxedDiv>
            </Modal>);
        } else {
            setOverlay(null);
        }

    }, [setOverlay, submissionStatus]);
}

export default useSubmissionProcessingOverlay
