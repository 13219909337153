import { assertArrayOfType } from "psims/lib/collections";
import { RecordActionEnum } from "psims/models/api/data-submission-record-action";

interface RecordDraft {
  _id: symbol,
  recordAction?: RecordActionEnum | null;
}

export function cleanSubmissionEntityList<T extends RecordDraft>(entities: Array<T>) {
  return entities
    .map(e => {
      const {_id, ...data} = e;
      return Object.entries(data).reduce((d, [k, v]) => ({
        ...d,
        [k]: String(v).length === 0 ? null : v,
      }), {} as Omit<T, '_id'>)
    })
    .filter(e => e.recordAction != null);
}

export function cleanSubmissionData<T>(submission: T, relationNames: Array<keyof T>) {
  const cleaned = relationNames.reduce((result, relationName) => {
    const relationData = submission[relationName];

    assertArrayOfType(assertRecordDraft, relationData);
    return {
      ...result,
      [relationName]: cleanSubmissionEntityList(relationData)
    }
  }, {} as {[key in typeof relationNames[number]]: ArrayElement<T[key]>});

  return {
    ...submission,
    ...cleaned,
  };
}

function assertRecordDraft<T>(maybe?: T): asserts maybe is RecordDraft & T {
  const maybeAs = maybe as unknown as RecordDraft;
  if (
    maybeAs == null ||
    maybeAs._id == null
  ) {
    throw new Error(`Failed to assert recordDraft: ${JSON.stringify(maybe)}`);
  }
}