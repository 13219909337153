import useViewMode from "../shared/use-view-mode";
import useClearData from "psims/react/pages/primary-pages/data-submissions/shared/use-clear-data";
import useSubmissionProcessingOverlay from "psims/react/pages/primary-pages/data-submissions/shared/use-submission-processing-overlay";
import useTemplateImport from "psims/react/blocks/import/use-template-import";
import useDataSubmissionControls from "../shared/use-data-submission-controls";
import { UseProductionRefData } from "../productions-shared/use-production-ref-data";
import { ProductionImportState, TemplateImporter } from "psims/react/blocks/import/types";
import { UseProductionAPI } from "../productions-shared/use-production-api";
import useProductionForm from "../productions-shared/use-production-form";
import { ApplicableFields, GetGroupHeader } from "./util";
import useProductionSteps from "../productions-shared/use-production-steps";
import useProductionProgress from "../productions-shared/use-production-progress";
import useProductionValidationAlerts from "../productions-shared/use-production-validation-alerts";
import useProductionSave from "../productions-shared/use-production-save";
import useProductionServiceResponse from "../productions-shared/use-production-service-response";
import useProductionSubmit from "../productions-shared/use-production-submit";
import { ProductionDataSubmissionName } from "psims/models/submission-types/production";
import biofuelProductionImport from "psims/react/blocks/import/submissions/biofuel-production";
import fieldProductionImport from "psims/react/blocks/import/submissions/field-production";
import plantProductionImport from "psims/react/blocks/import/submissions/plant-production";
import useProductionPortalData from "./use-production-portal-data";


interface UseProductionProps {
    apiCtrl: PopulatedProps<UseProductionAPI, 'submission'>;
    applicableFields: ApplicableFields;
    getGroupHeader: GetGroupHeader;
    productionTypeName: ProductionDataSubmissionName;
    refData: UseProductionRefData;
}

const PRODUCTION_IMPORTERS: {[key in ProductionDataSubmissionName]: () => TemplateImporter<ProductionImportState>} = {
    'Biofuel production': biofuelProductionImport,
    'Field production': fieldProductionImport,
    'Plant production': plantProductionImport,
}

function useProduction({apiCtrl, applicableFields, getGroupHeader, productionTypeName, refData}: UseProductionProps) {
    const {submission} = apiCtrl;

    // Orchestrate controllers
    const viewMode = useViewMode({dataSubmission: submission.dataSubmission});
    const serviceResponse = useProductionServiceResponse({apiCtrl});
    const stepsCtrl = useProductionSteps({dataPageName: productionTypeName});
    const importCtrl = useTemplateImport<ProductionImportState>({ dataSubmission: submission.dataSubmission, importerBuilder: PRODUCTION_IMPORTERS[productionTypeName] });    
    const progressCtrl = useProductionProgress({refData, stepsCtrl, submission, viewMode});
    const validationAlerts = useProductionValidationAlerts({progressCtrl, serviceResponse});
    const currentStep = progressCtrl.currentStep.kind === 'data' ? productionTypeName : 'Submit';
    const portalDataCtrl = useProductionPortalData({submissionType: refData.submissionType});
    const formCtrl = useProductionForm({
        applicableFields,
        currentStep,
        importCtrl,
        progressCtrl,
        refData,
        submission,
        viewMode,
        validationAlerts
    });

    const saveCtrl = useProductionSave({apiCtrl, formCtrl, productionTypeName, progressCtrl, serviceResponse, validationAlerts, viewMode, importCtrl});
    const submitCtrl = useProductionSubmit({apiCtrl, dataPageName: productionTypeName, formCtrl, progressCtrl, refData});
    const controlsCtrl = useDataSubmissionControls({apiCtrl, formCtrl, progressCtrl, saveCtrl, submitCtrl, viewMode});
    const clearDataCtrl = useClearData({
        onConfirm: () => {
            apiCtrl.clearAllData();
            formCtrl.clearDeclaration();
            importCtrl.reset();
        },
        submission: apiCtrl.submission.dataSubmission,
    });

    useSubmissionProcessingOverlay({submissionStatus: apiCtrl.loadStatus});

    return {
        // Controllers
        apiCtrl,
        clearDataCtrl,
        controlsCtrl,
        formCtrl,
        importCtrl,
        portalDataCtrl,
        progressCtrl,
        saveCtrl,
        stepsCtrl,
        submitCtrl,

        // Data
        refData,
        serviceResponse,
        submission,
        validationAlerts,
        viewMode,
    }
}

export default useProduction;
