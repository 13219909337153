import { DefSubmissionFormDataVM } from "psims/gen/xapi-client";

export type MaybeDefPageData = DefSubmissionFormDataVM;

export type DefPageData = PopulatedProps<
  MaybeDefPageData,
  'pageSaved' | 'dataSubmissionId'
>;

export function isDefPageData(maybe?: unknown): maybe is DefPageData {
  const maybeAs = maybe as DefPageData;

  return (
    maybeAs != null &&
    maybeAs.pageSaved != null &&
    maybeAs.dataSubmissionId != null
  );
}
