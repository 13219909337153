export const PAGES = [
	{
		navbarSettings: null,
		label: '',
		name: 'root',
		path: '/',
	},

	{
		navbarSettings: {
			kind: 'link',
			level: 0,
		},
		label: 'Home',
		name: 'home',
		path: '/home',
	},

	{
		navbarSettings: {
			kind: 'link',
			level: 0,
		},
		breadcrumbs: ['home', 'reporting-history'],
		label: 'Data submission history',
		name: 'reporting-history',
		path: '/reporting-history',
	},

	{
		navbarSettings: {
			kind: 'category',
			childrenNames:[
				'reporting-obligations',
				'minimum-stockholding-obligation-settings',
			],
			level: 0,
		},
		label: 'Reporting obligations',
		name: 'reporting-obligations-category',
	},

	{
		navbarSettings: {
			kind: 'link',
			level: 1,
		},
		breadcrumbs: ['home', 'reporting-obligations-category', 'reporting-obligations'],
		label: 'Reporting obligations',
		name: 'reporting-obligations',
		path: '/reporting-obligations',
	},

	{
		navbarSettings: {
			kind: 'link',
			level: 1,
		},
		breadcrumbs: ['home', 'reporting-obligations-category', 'minimum-stockholding-obligation-settings'],
		label: 'Minimum stockholding obligations',
		name: 'minimum-stockholding-obligation-settings',
		path: '/minimum-stockholding-obligation-settings',
	},

	{
		navbarSettings: {
			kind: 'category',
			childrenNames:[
				'company-overview',
				'user-management',
				'documents',
				'fssp-commitment',
			],
			level: 0,
		},
		label: 'Manage company',
		name: 'manage-company-category',
	},

	{
		navbarSettings: {
			kind: 'link',
			level: 1,
		},
		breadcrumbs: ['home', 'manage-company-category', 'company-overview'],
		label: 'Company details',
		name: 'company-overview',
		path: '/company-overview',
	},

	{
		navbarSettings: {
			kind: 'link',
			level: 1,
		},
		breadcrumbs: ['home', 'manage-company-category', 'user-management'],
		label: 'User access',
		name: 'user-management',
		path: '/user-management',
	},

	{
		navbarSettings: {
			kind: 'link',
			level: 1,
		},
		breadcrumbs: ['home', 'manage-company-category', 'documents'],
		label: 'Documents',
		name: 'documents',
		path: '/documents',
	},

	{
		navbarSettings: {
			kind: 'link',
			level: 1,
		},
		breadcrumbs: ['home', 'manage-company-category', 'fssp-commitment'],
		label: 'FSSP commitment',
		name: 'fssp-commitment',
		path: '/fssp-commitment',
	},

	{
		navbarSettings: {
			kind: 'link',
			level: 0,
		},
		breadcrumbs: ['home', 'request-templates'],
		label: 'Request templates',
		name: 'request-templates',
		path: '/request-templates',
	},

	{
		navbarSettings: null,
		breadcrumbs: ['home', 'reporting-obligations', 'modify-reporting-obligation'],
		label: 'Request to modify reporting obligations',
		name: 'modify-reporting-obligation',
		path: '/modify-reporting-obligation',
	},

	{
		isNavItem: false,
		navbarSettings: null,
		breadcrumbs: ['home', 'fuel-security-services-payment'],
		label: 'FSSP s19A',
		name: 'fuel-security-services-payment',
		path: '/data-submissions/fuel-security-services-payment',
	},

	{
		isNavItem: false,
		navbarSettings: null,
		breadcrumbs: ['home', 'plant-production-report'],
		label: 'Plant production report',
		name: 'plant-production-report',
		path: '/data-submissions/plant-production',
	},

	{
		navbarSettings: null,
		breadcrumbs: ['home', 'biofuel-production-report'],
		label: 'Biofuel production report',
		name: 'biofuel-production-report',
		path: '/data-submissions/biofuel-production',
	},

	{
		navbarSettings: null,
		breadcrumbs: ['home', 'field-production-report'],
		label: 'Field production report',
		name: 'field-production-report',
		path: '/data-submissions/field-production',
	},

	{
		navbarSettings: null,
		breadcrumbs: ['home', 'stockholding-report'],
		label: 'Stockholding report',
		name: 'stockholding-report',
		path: '/data-submissions/stockholding',
	},

	{
		navbarSettings: null,
		breadcrumbs: ['home', 'wholesaling-report'],
		label: 'Wholesales report',
		name: 'wholesaling-report',
		path: '/data-submissions/wholesaling',
	},

	{
		navbarSettings: null,
		breadcrumbs: ['home', 'refinery-report'],
		label: 'Refinery report',
		name: 'refinery-report',
		path: '/data-submissions/refinery',
	},

	{
		navbarSettings: null,
		label: 'Report submitted',
		name: 'report-submitted',
		path: '/report-submitted',
	},

	{
		navbarSettings: null,
		breadcrumbs: ['home', 'mso-importer-submission'],
		label: 'Minimum stockholding obligation',
		name: 'mso-importer-submission',
		path: '/data-submissions/mso-importer',
	},

	{
		navbarSettings: null,
		breadcrumbs: ['home', 'mso-refiner-submission'],
		label: 'Minimum stockholding obligation',
		name: 'mso-refiner-submission',
		path: '/data-submissions/mso-refiner',
	},

	{
		navbarSettings: null,
		breadcrumbs: ['home', 'mso-annual-activity-importer-submission'],
		label: 'MSO importer - Annual activity',
		name: 'mso-annual-activity-importer-submission',
		path: '/data-submissions/mso-annual-activity-importer',
	},

	{
		navbarSettings: null,
		breadcrumbs: ['home', 'mso-annual-activity-refiner-submission'],
		label: 'MSO refiner - Annual activity',
		name: 'mso-annual-activity-refiner-submission',
		path: '/data-submissions/mso-annual-activity-refiner',
	},

	{
		navbarSettings: null,
		breadcrumbs: ['home', 'def'],
		label: 'Diesel exhaust fluid',
		name: 'def',
		path: '/data-submissions/diesel-exhaust-fluid',
	},

	{
		navbarSettings: null,
		label: 'Terms and conditions',
		name: 'terms-and-conditions',
		path: '/terms-and-conditions',
	},

	{
		navbarSettings: null,
		label: 'Accept terms and conditions',
		name: 'accept-terms-and-conditions',
		path: '/accept-terms-and-conditions',
	},

	{
		navbarSettings: null,
		label: 'Register',
		name: 'register',
		path: '/register',
	},

	{
		navbarSettings: null,
		label: 'Landing',
		name: 'landing',
		path: '/landing',
	},

	{
		navbarSettings: null,
		label: 'Login',
		name: 'login',
		path: '/login',
	},

	{
		navbarSettings: null,
		label: 'Sign out',
		name: 'sign-out',
		path: '/sign-out',
	},

	{
		navbarSettings: null,
		label: 'Industry home',
		name: 'industry-redirect',
		path: '/industry-redirect',
	},

	{
		navbarSettings: null,
		label: 'Not found',
		name: 'not-found',
		path: '/not-found',
	},

	{
		navbarSettings: null,
		label: 'Bad gateway',
		name: 'bad-gateway',
		path: '/bad-gateway',
	},

	// Admin pages
	{
		navbarSettings: null,
		label: 'Portal Administration',
		name: 'portal-admin',
		path: '/admin',
	},

	{
		navbarSettings: null,
		label: 'Manage reference data',
		name: 'admin-manage-reference-data',
		path: '/admin/manage-reference-data',
	},
] as const;

export type Page = ArrayElement<typeof PAGES>;
export type PageName = Page['name'];


interface WithBreadcrumbs {
	breadcrumbs: Array<PageName> | undefined;
}

export function getBreadcrumbs(page: Page) {
	const withBreadcrumbs = page as unknown as WithBreadcrumbs;
	return withBreadcrumbs.breadcrumbs;
}

export type NavbarSettings = ExcludeNull<Page['navbarSettings']>;

export type WithNavbarSettings<TPage extends Page> = TPage & {
	navbarSettings: NavbarSettings;
}

export function isWithNavbarSettings<TPage extends Page>(page: TPage): page is WithNavbarSettings<TPage> {
	return page.navbarSettings != null;
}

export type WithPath<T> = T & {
	path: string;
}

export function isWithPath<T>(page?: unknown): page is WithPath<T> {
	const asWithPath = page as WithPath<T>;
	return asWithPath.path != null;
}

export type WithChildren<TPage extends Page> = WithNavbarSettings<TPage> & {
	navbarSettings: WithNavbarSettings<TPage>['navbarSettings'] & {
		childrenNames: Array<string>;
	}
}

export function isWithChilren<TPage extends Page>(page: TPage): page is WithChildren<TPage> {
	if (!isWithNavbarSettings(page)) {
		return false;
	}
	
	const asWithChilren = page as WithChildren<TPage>;

	return asWithChilren.navbarSettings.childrenNames != null;
}
