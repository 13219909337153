import { MsoStorageSiteVM } from "psims/gen/xapi-client";
import { prefixedThrower } from "psims/lib/exception";

export type MsoStorageSite = PopulatedProps<
  MsoStorageSiteVM,
  'concurrencyToken' | 'dataSubmissionId' | 'id' | 'msoProductId' 
>;

export function assertMsoStorageSite(maybe?: unknown): asserts maybe is MsoStorageSite {
  const maybeAs = maybe as MsoStorageSite;

  const assertionThrower = prefixedThrower('Failed to assert msoStorageSite: ');

  if (maybeAs == null) {
    assertionThrower('argument is null');
  }

  if (maybeAs.concurrencyToken == null) {
    assertionThrower('concurrencyToken is null');
  }
  
  if (maybeAs.dataSubmissionId == null) {
    assertionThrower('dataSubmissionId is null');
  }

  if (maybeAs.id == null) {
    assertionThrower('id is null');
  }

  if (maybeAs.msoProductId == null) {
    assertionThrower('msoProductId is null');
  }

  // All fields apparently optional - leaving in for now in case we decide
  // to assert some existence of data...just in case

  // if (maybeAs.storageFromDate == null) {
  //   assertionThrower('storageFromDate is null');
  // }

  // if (maybeAs.storageLocation == null) {
  //   assertionThrower('storageLocation is null');
  // }

  // if (maybeAs.storagePrice == null) {
  //   assertionThrower('storagePrice is null');
  // }

  // if (maybeAs.storageSite == null) {
  //   assertionThrower('storageSite is null');
  // }

  // if (maybeAs.storageToDate == null) {
  //   assertionThrower('storageToDate is null');
  // }

  // if (maybeAs.storageTypeId == null) {
  //   assertionThrower('storageTypeId is null');
  // }
}
