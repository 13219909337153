import React, { ChangeEvent, ReactNode, useCallback, useMemo, useState } from 'react';

import Dialog from 'psims/react/components/dialog';
import Select, { Option, useSelectController } from 'psims/react/components/select';
import { useAPI } from 'psims/react/providers/api';
import { BoxedDiv } from 'psims/react/components/layout';
import { H2 } from 'psims/react/components/typography';
import FileInput from '../data-submissions/shared/file-input';
import Button from 'psims/react/components/button';
import { DocumentUpload as DocumentUploadModel } from 'psims/models/document-upload';
import { assertDocumentTypeName } from 'psims/models/ref-data/document-type';
import { Document, isDocument } from 'psims/models/document';
import { isEmpty } from 'psims/lib/empty';
import useConfirm from '../data-submissions/shared/use-confirm';
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';
import Notification from 'psims/react/components/notification';
import SupportLink from 'psims/react/components/support-link';
import { getContentTypes } from './file-types';
import UL, { LI } from 'psims/react/components/unordered-list';

interface DocumentUploadProps {
  isShown: boolean;
  onDismiss: () => any;
  onSaved: (document: Document) => any;
  organisationOptions: Array<Option<number>>;
  documentTypeOptions: Array<Option<string>>;
}

const DocumentUpload = (props: DocumentUploadProps) => {
  const vm = useVM(props);

  return (
    <Dialog isOpen={vm.isShown} onDismiss={vm.handleDismiss} aria-label='Upload a document'>
      <BoxedDiv box={{flex: 'column'}}>
        <H2>Upload a document</H2>
        <UL>
          <LI>File size must be less than 2Mb.</LI>
          <LI>Only .docx, .xlsx and .pdf file extensions are supported.</LI>
          <LI>Keep file names as short as possible while still being clear.</LI>
          <LI>File names must include only letters, numbers, hyphens and underscores. Do not include commas or other special characters.</LI>
        </UL>
        <p>
          This document will be treated as a confidential Commonwealth record and information will not be disclosed (unless required or permitted by law to do so).
        </p>
        <Select
          error={vm.validationMessages.organisation}
          forceError={true}
          id='organisationId'
          label='Select entity'
          options={vm.organisationSelectCtrl.options}
          onChange={vm.organisationSelectCtrl.onChange}
          value={vm.organisationSelectCtrl.value}
        />

        <Select
          error={vm.validationMessages.documentType}
          forceError={true}
          id='documentTypeId'
          label='Select document type'
          options={vm.documentTypeSelectCtrl.options}
          onChange={vm.documentTypeSelectCtrl.onChange}
          value={vm.documentTypeSelectCtrl.value}
        />

        <BoxedDiv box={{marginBottom: 4, marginTop: 2}}>
          <FileInput
            accept={getContentTypes()}
            error={vm.validationMessages.formFile}
            type='file'
            onChange={vm.handleFileChange}
          >{vm.uploadFileLabel}</FileInput>
        </BoxedDiv>

        {
          vm.uploadError &&

          <BoxedDiv box={{marginBottom: 4, marginTop: 2}}>
            {vm.uploadError}
          </BoxedDiv>
        }

        <BoxedDiv box={{flex: 'row-reverse', justifyContent: 'space-between', marginV: 2}}>
          <Button $kind='primary' onClick={vm.handleClickUpload}>Upload</Button>

          <Button $kind='text' onClick={vm.handleDismiss}>Cancel</Button>
        </BoxedDiv>
      </BoxedDiv>

      <ConfirmationDialog
        ariaLabel='Confirm upload details are correct'
        isOpen={vm.confirmCtrl.confirmState === 'confirming'}
        body={vm.confirmCtrl.message}
        onCancel={vm.confirmCtrl.cancel}
        onConfirm={vm.confirmCtrl.confirm}
        title={vm.confirmCtrl.title}
        confirmLabel='Proceed'
        cancelLabel='Cancel'
      />

    </Dialog>
  )
}

type Validation = {
  messages: {
    organisation?: string | null;
    documentType?: string | null;
    formFile?: string | null;
  };
  showValidation: boolean;
}

const VALIDATION_MESSAGES = {
  organisation: 'Please select an organisation',
  documentType: 'Please select a document type',
  formFile: 'Please select a file to upload',
} as const;

function useVM({documentTypeOptions, isShown, organisationOptions, onDismiss, onSaved}: DocumentUploadProps) {
  const {api} = useAPI();
  const confirmCtrl = useConfirm();

  const [organisationId, setOrganisationId] = useState<number | null>(null);
  const [documentType, setDocumentType] = useState<string | null>(null);
  const [formFile, setFormFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<ReactNode | null>(null);

  const [validation, setValidation] = useState<Validation>({showValidation: false, messages: VALIDATION_MESSAGES});

  const validationMessages = useMemo(() => {
    if (validation.showValidation) {
      return validation.messages
    }

    return {};
  }, [validation]);

  const uploadFileLabel = useMemo(() => {
    return formFile?.name || 'No file chosen';
  }, [formFile]);

  const handleDocumentTypeChange = useCallback((dt: string | null) => {
    setDocumentType(dt);
    setValidation(prev => ({
      ...prev,
      messages: {
        ...prev.messages,
        documentType: dt == null ? VALIDATION_MESSAGES.documentType : null,
      }
    }));
  }, []);

  const handleOrganisationIdChange = useCallback((orgId: number | null) => {
    setOrganisationId(orgId);
    setValidation(prev => ({
      ...prev,
      messages: {
        ...prev.messages,
        organisation: orgId == null ? VALIDATION_MESSAGES.organisation : null,
      },
    }));
  }, []);
  
  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = (e.target.files || [])[0] || null;
    setFormFile(file);
    setValidation(prev => ({
      ...prev,
      messages: {
        ...prev.messages,
        formFile: file == null ? VALIDATION_MESSAGES.formFile : null,
      },
    }));
  }, []);

  const documentTypeSelectCtrl = useSelectController({
    onChange: v => handleDocumentTypeChange(v),
    options: documentTypeOptions,
    value: documentType
  });

  const organisationSelectCtrl = useSelectController({
    onChange: v => handleOrganisationIdChange(v),
    options: organisationOptions,
    value: organisationId,
  });

  const reset = useCallback(() => {
    setDocumentType(null)
    setOrganisationId(null);
    setFormFile(null);
    setUploadError(null);
    setValidation({
      showValidation: false,
      messages: VALIDATION_MESSAGES,
    });
  }, []);

  const handleConfirm = useCallback(() => {
    if (formFile == null || documentType == null || organisationId == null) {
      return;
    }

    assertDocumentTypeName(documentType);

    const documentUpload: DocumentUploadModel = {
      documentType,
      formFile,
      name: formFile.name,
      organisationId,
    }

    api.uploadDocument({requestBody: documentUpload})
    .then(r => {
      if ((r?.status || 0) >= 300 || (r?.status || 0) < 200) {
        throw new Error('Not OK status');
      }

      return r;
    })
    .then(r => r?.json())
    .then(r => {
      if (r.result != null && isDocument(r.result)) {
        reset();
        onSaved(r.result);
      }
    })
    .catch(e => setUploadError((
      <Notification
        align='center'
        kind='warning'
      >
        Your upload failed. Please try again, or contact <SupportLink/> if the problem persists.
      </Notification>
    )));
  }, [api, documentType, organisationId, formFile, onSaved, reset]);

  const handleClickUpload = useCallback(() => {
    setUploadError(null);

    if (!isEmpty(validation.messages)) {
      setValidation(prev => ({
        ...prev,
        showValidation: true,
      }));

      return;
    }
    
    confirmCtrl.requestConfirmation(
      <BoxedDiv box={{}}>Please check you have attached the correct document as you will not be able to delete it. Would you like to upload the document?</BoxedDiv>,
      'Are you sure?',
      handleConfirm
    );
  }, [confirmCtrl, handleConfirm, validation.messages]);

  const handleDismiss = useCallback(() => {
    reset();
    onDismiss();
  }, [onDismiss, reset]);

  return {
    confirmCtrl,
    documentType,
    documentTypeOptions,
    documentTypeSelectCtrl,
    organisationId,
    organisationSelectCtrl,
    formFile,
    isShown,
    uploadError,
    uploadFileLabel,
    validationMessages,

    handleClickUpload,
    handleConfirm,
    handleDismiss,
    handleFileChange,
  };
}

export default DocumentUpload;
