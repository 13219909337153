import { useCallback, useEffect, useMemo, useState } from "react";

import { asNumber } from "psims/lib/number";
import { ValidationAlert } from "psims/models/api/submission/validation-alert";
import { WholesaleSubmission } from "psims/models/submission-types/wholesaling";
import { useNavigation } from "psims/react/providers/router";
import { ViewMode } from "psims/react/pages/primary-pages/data-submissions/shared/use-view-mode";
import { ChangedState } from "psims/react/pages/primary-pages/data-submissions/shared/save-state";
import { UseWholesalingAPI } from "./use-wholesaling-api";
import { UseWholesalingSteps } from "./use-wholesaling-steps";
import { isValidCommentCharacters, isValidCommentMaxLength } from "psims/lib/validation/comments";
import { INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH, INVALID_COMMENTS_REQUIRED_WHEN_SAME_AS_PREVIOUS, INVALID_DECLARATION } from 'psims/constants/validation-messages';
import { UseTemplateImport } from "psims/react/blocks/import/use-template-import";
import { any } from "psims/lib/collections";
import { isEmpty } from "psims/lib/empty";
import { is } from "psims/lib/type-assertions";

interface UseWholesalingSubmissionProps {
    apiCtrl: UseWholesalingAPI;
    stepCtrl: UseWholesalingSteps
    submission: WholesaleSubmission;
    viewMode: ViewMode;
    importCtrl: UseTemplateImport<Partial<WholesaleSubmission>>;
}

function useWholesalingSubmission({apiCtrl, stepCtrl, submission, viewMode, importCtrl}: UseWholesalingSubmissionProps) {
    const nav = useNavigation();
    const [declaration, setDeclaration] = useState(viewMode === 'view');
    const [comments, setComments] = useState(submission.dataSubmission.comments)
    const [commentsMessage, setCommentsMessage] = useState<string | undefined>(undefined);
    const [declarationMessage, setDeclarationMessage] = useState<string | null>(null);
    const [changedState, setChangedState] = useState<ChangedState>('unchanged');
    const [showUnsavedSteps, setShowUnsavedSteps] = useState(false);

    const validationAlerts = useMemo(() => {
        return submission.wholesales
            .map(w => w.validationAlerts?.map(va => ({
                ...va,
                wholesaleProductId: w.wholesaleProductId,
                wholesaleTypeId: w.wholesaleTypeId
            })))
            .flat()
            .filter(is)
            .filter(va => (
                (va as ValidationAlert).validationAlert === 'LessThanSumOfBreakdownAlert') ||
                (va as ValidationAlert).validationAlert === 'PercentVarianceApplied'
            )
    }, [submission]);

    const isSameAsPrevSubmission = useMemo(() => {
        return submission.dataSubmission.validationAlerts && submission.dataSubmission.validationAlerts.filter(a => a.validationAlert === 'SameAsPrevious').length > 0;
    }, [submission]);

    const isActive = useMemo(() => stepCtrl.currentStep.kind === 'Submit', [stepCtrl.currentStep.kind]);

    const isDisabled = useMemo(() => {
        return viewMode !== 'edit'
    }, [viewMode]);

    const unsavedSteps = useMemo(() => {
        return stepCtrl.steps.filter(s => s.status === 'pending');
    }, [stepCtrl.steps]);

    const stepsRequiringComments = useMemo(() => {
        return stepCtrl.steps.filter(s => {
            const stepHasValidationAlerts = submission.wholesales
                .filter(w => w.wholesaleTypeId === s.refData?.wholesaleType.id && any(w.validationAlerts || [], va => va != null))
                .length > 0;
            
            const stepComments = submission.wholesaleComments.find(c => c.wholesaleTypeId === s.refData?.wholesaleType.id)
            
            return stepHasValidationAlerts && (stepComments == null || isEmpty(stepComments.comments));
        });
    }, [stepCtrl.steps, submission.wholesaleComments, submission.wholesales]);

    const mergedComments = useMemo(() => {
        if (!isDisabled && isActive && importCtrl.templateImportState.templateImportDialogState === 'processing' && importCtrl.templateImportState.submitSaved !== true && changedState === 'unchanged') {
            return null;
        }
        return comments;
    },[changedState, comments, importCtrl.templateImportState.submitSaved, importCtrl.templateImportState.templateImportDialogState, isActive, isDisabled]);

    const checkComments = useCallback((value: string | undefined | null) => {
        const trimmedValue = (value || '').trim();
        if (trimmedValue.length > 0) {
            if (!isValidCommentMaxLength(trimmedValue) || !isValidCommentCharacters(trimmedValue)) {
                return false;
            }
        }
        
        return true;
    }, []);
    
    const getCommentValidations = useCallback((value: string | undefined | null) => {
        let validation: string | undefined = undefined;
        const trimmedValue = (value || '').trim();
        if (trimmedValue.length > 0) {
            if (!isValidCommentMaxLength(trimmedValue)) {
                validation = INVALID_COMMENTS_LENGTH;
            }
            if (!isValidCommentCharacters(trimmedValue)) {
                validation = INVALID_COMMENTS_CHARACTERS;
            }
        }
        if (trimmedValue.length === 0 && isSameAsPrevSubmission) {
            validation = INVALID_COMMENTS_REQUIRED_WHEN_SAME_AS_PREVIOUS;
        }

        return validation;
    }, [isSameAsPrevSubmission]);

    const submit = useCallback(() => {
        if (!declaration) {
            setDeclarationMessage(INVALID_DECLARATION);
            return;
        }
        
        if (!checkComments(comments)) {
            return;
        }

        if (isSameAsPrevSubmission && isEmpty(comments)) {
            return false;
        }

        if (unsavedSteps.length > 0) {
            setShowUnsavedSteps(true);
            return;
        }

        if (stepsRequiringComments.length > 0) {
            return;
        }

        const {id, concurrencyToken} = submission.dataSubmission;

        apiCtrl.submit({
            comments,
            concurrencyToken,
            id
        });
    }, [apiCtrl, checkComments, comments, declaration, isSameAsPrevSubmission, stepsRequiringComments.length, submission.dataSubmission, unsavedSteps.length]);

    const doImportTemplateSaved = useMemo(() => {
        return viewMode !== 'view' && importCtrl && importCtrl.templateImportState.templateImportDialogState === 'processing';
    }, [importCtrl, viewMode]);
    
    const update = useCallback(() => {
        const {id, concurrencyToken} = submission.dataSubmission;

        if (!checkComments(comments)) {
            return;
        }
        if (doImportTemplateSaved) {
            apiCtrl.updateDataSubmission({
                comments,
                concurrencyToken,
                id,
                isTemplateImport: true
            });
        } else {
            apiCtrl.updateDataSubmission({
                comments,
                concurrencyToken,
                id
            });
        }
    }, [apiCtrl, checkComments, comments, doImportTemplateSaved, submission.dataSubmission]);

    const updateComments = useCallback((val: string | undefined) => {
        setComments(val);
        setChangedState('unsaved_changes');
    }, [])

    useEffect(() => {
        const validation = getCommentValidations(comments);
        setCommentsMessage(validation);
    }, [comments, getCommentValidations, isSameAsPrevSubmission]);

    // Keep comments in sync with server data
    useEffect(() => {
        setComments(submission.dataSubmission.comments);
        if (!isActive) {
            setChangedState('unchanged');
        }
    }, [isActive, submission.dataSubmission.comments]);

    // Clear declaration message if user agrees to terms or submit page is not active
    useEffect(() => {
        if (declaration || !isActive) {
            setDeclarationMessage(null);
        }
    }, [declaration, isActive]);

    // Clear unsaved steps
    useEffect(() => {
        if (unsavedSteps.length > 0) {
            setShowUnsavedSteps(false);
        }
    }, [unsavedSteps]);

    useEffect(() => {
        if (apiCtrl.loadStatus === 'submitted') {
            setChangedState('unchanged');
			nav.goToReportSubmittedPage(asNumber(apiCtrl.submission?.dataSubmission.id));
        }
    }, [nav, apiCtrl.loadStatus, apiCtrl.submission?.dataSubmission.id, submission]);

    useEffect(() => {
        if (importCtrl.templateImportState.templateImportDialogState === 'complete' && declaration === true) {
            setDeclaration(false);
        }

    }, [declaration, importCtrl.templateImportState.templateImportDialogState]);

    return {
        changedState,
        comments: mergedComments,
        commentsMessage,
        declaration,
        declarationMessage,
        validationAlerts,
        isSameAsPrevSubmission,
        isDisabled,
        stepsRequiringComments,
        unsavedSteps: showUnsavedSteps ? unsavedSteps : [],
        updateComments,
        setDeclaration,
        submit,
        update,
    }
}

export default useWholesalingSubmission;

export type UseWholesalingSubmission = ReturnType<typeof useWholesalingSubmission>;
