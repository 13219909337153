import { SpacingProps, spacingStyle } from 'psims/style/spacing';
import React, { forwardRef, Ref } from 'react';
import styled, { css, CSSObject } from 'styled-components';

export type ButtonState = 'loading' | 'normal';

interface ButtonProps extends SpacingProps, Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'style'> {
	className?: string;
	$kind: 'primary' | 'ghost' | 'text' | 'unstyled';
	label?: string;
	$minWidth?: string;
	rounded?: boolean;
	$size?: 'sm' | 'md';
	state?: ButtonState;
	styles?: React.ButtonHTMLAttributes<HTMLButtonElement>['style'];
};

const StyledButton = styled.button`
	border-radius: var(--border-radius-md);
	padding: var(--spacing-md) var(--spacing-xxl);
	text-align: center;
	font-size: var(--font-size-160);
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	box-shadow: var(--box-shadow-mid);
	transition-duration: var(--transition-duration-mid), var(--transition-duration-mid);
	transition-timing-function: ease, ease;
	transition-delay: 0s, 0s;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;

	${(props: ButtonProps) => props.$kind === 'primary' || props.$kind === 'ghost' ? `
	&:not(:disabled):hover {
  		transform: translateY(-1px);
	}

	&:not(:disabled):hover,
	&:not(:disabled):focus {
		cursor: pointer;
		box-shadow: var(--box-shadow-hi);
		outline: none;
	}
	` : ''}

	${(props: ButtonProps) => props.$kind === 'primary' ? `
	border: solid 2px var(--color-primary);
  	background-color: var(--color-primary-interactive);
  	color: var(--color-white);
	
	&:not(:disabled):hover,
		border: 2px solid var(--color-primary-dark);
  		background: var(--color-primary);
	}

	&:not(:disabled):focus,
	&:not(:disabled):active {
		background-color: var(--color-primary-dark);
		border: solid 2px var(--color-primary-dark);
		transition: box-shadow 0.1s ease, transform 0.1s ease;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
		transform: translate(0, -2px);
		color: var(--color-white);
		outline: 2px solid var(--color-primary);
		outline-offset: 2px;
	}

	&:disabled {
		opacity: 0.6;
	}
	` : ''}

	${(props: ButtonProps) => props.$kind === 'ghost' ? `
	background-color: transparent;
	border-color: var(--color-black-50);
	border-style: solid;
	border-width: 1px;

	&:disabled {
		opacity: 0.6;
	}

	&:not(:disabled):focus,
	&:not(:disabled):active {
		outline: 2px solid var(--color-primary);
		outline-offset: 2px;
	}
	` : ''}

	${(props: ButtonProps) => props.$kind === 'unstyled' || props.$kind === 'text' ? `
	background: none;
	border: 2px solid var(--color-transparent);
	box-shadow: none;
	font-weight: 400;
	margin: 0;
	padding: 0;
	cursor: pointer;

	&:active,
	&:hover,
	&:focus {
		border-color: var(--color-unstyled-focused);
	}
	` : ''}

	${(props: ButtonProps) => props.$kind === 'text' ? `
	color: var(--color-primary-interactive);
	font-weight: 600;
	letter-spacing: 0.27px;
	text-decoration: underline;
	` : ''}


	${(props: ButtonProps) => props.$minWidth ? `
	min-width: ${props.$minWidth};
	` : ''}

	${(props: ButtonProps) => props.$size === 'sm' ? `
	font-size: var(--font-size-140);
  	padding: var(--spacing-xs) var(--spacing-md);
	` : ''}

	${(props: ButtonProps) => props.rounded ? `
	&,
	&:hover,
	&:focus {
		border-color: var(--color-primary-interactive);
	}
	border-radius: 50px;
	` : ''}

	${spacingStyle}

	${(props: ButtonProps) => props.styles != null ? css(props.styles as CSSObject) : ''}
`;
    
const Button = forwardRef((props: ButtonProps, ref) => {
	const ariaLabel = props['aria-label'];
	const disabled = props.disabled || props.state === 'loading';
    return <StyledButton
		{...props}
		disabled={disabled}
		ref={ref as Ref<HTMLButtonElement>}
		aria-label={ariaLabel}
		label={props.label || undefined}
		type={props.type || 'button'}
	/>
});

export default Button;
