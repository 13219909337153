import { asNumber } from "psims/lib/number";
import { RecordResult } from "psims/models/api/record-result";
import { ValidationMessage } from "psims/models/api/response";
import { UpdateWholesaleComment, UpdateWholesaleSubmission, WholesaleField, WholesaleSubmission } from "psims/models/submission-types/wholesaling";
import { WholesaleTypeRefData } from "./use-wholesaling-ref-data";

export type ServiceMessages = {
    recordResults: Array<RecordResult> | null;
    errorMessages: Array<string> | null;
    validationMessages: Array<ValidationMessage> | null;
}

export type FocusField = {
    field: 'comments';
} | {
    field: WholesaleField | 'delete';
    wholesaleProductId: number;
};


export function updateWholesaleCommentFromSubmission(
    submission: WholesaleSubmission,
    wholesaleTypeId: number
): UpdateWholesaleComment | undefined {
    const comment = submission.wholesaleComments.find(c => c.wholesaleTypeId === wholesaleTypeId);

    if (comment == null) {
        return undefined;
    }

    // Drop non-update fields
    const {recordResult, ...result} = comment;

    return {
        ...result,
    };
}

export function updateWholesaleSubmissionFromSubmission(
    submission: WholesaleSubmission,
    refData: WholesaleTypeRefData | null
): UpdateWholesaleSubmission {
    const {id: fdId, concurrencyToken: fdConcurrencyToken, manualTotals} = submission.submissionFormData;

    const result: UpdateWholesaleSubmission = {
        dataSubmissionId: asNumber(submission.dataSubmission.id),
        submissionFormData: {
            id: fdId,
            concurrencyToken: fdConcurrencyToken,
            manualTotals,
        },
        wholesales: [],
        wholesaleComment: undefined,
    };

    if (refData == null) {
        return result;
    }

    const typeId = refData.wholesaleType.id;

    result.wholesaleTypeId = typeId;

    result.wholesaleComment = updateWholesaleCommentFromSubmission(submission, typeId)

    refData.groups?.forEach(g => {
        g.products.forEach(p => {
            const submissionData = submission.wholesales
                .find(w => w.wholesaleProductId === p.id && w.recordResult?.rowResult !== 'Deleted');
            if (submissionData != null) {
                // Drop non-update fields
                const {recordResult, validationAlerts, wholesaleTypeId, ...wholesale} = submissionData;
                result.wholesales.push(wholesale);
            }
        })
    });

    return result;
}
