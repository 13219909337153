import { NotificationPart } from "../../../shared/notification-message";
import { ValidationResult } from "./mso-comment-validation";

export function getNotificationMessage(
  onTargetClick: () => any,
  validationResult: ValidationResult | null
) {
  if (validationResult == null) {
    return undefined;
  }

  const parts: Array<NotificationPart> = [
    'For ',
    {label: `Go to comments field`, onClick: onTargetClick, text: 'comments: '},
  ]

  switch (validationResult.code) {
    case 'invalid_length':
      parts.push(' must be 4000 characters or fewer.')
      break;
  
    case 'invalid_chars':
      parts.push(' invalid text entered, please use only letters, number and standard keyboard characters.')
      break;

    default:
      parts.push(' please confirm your data is correct')
      break;
  }

  return parts;
}