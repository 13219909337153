import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { SPACE_BASE } from 'psims/constants/styles';

type Kind = 'info' | 'standard' | 'warning';

interface CalloutProps {
    $kind?: Kind;
    className?: string;
    id?: string;
}

const StyledCallout = styled.div`${({$kind}: CalloutProps) => `
    background: var(--color-callout-${$kind}-background);
    border-top: 4px solid var(--color-callout-${$kind}-border);
    padding: ${SPACE_BASE * 3}px;
`}
`;

const Callout = ({children, ...rest}: PropsWithChildren<CalloutProps>) => {
    rest.$kind = rest.$kind || 'standard';
    
    return (
        <StyledCallout {...rest}>
            {children}
        </StyledCallout>
    );
}

export default Callout;
