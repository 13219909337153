import { ExternalReportStatusDTO } from "psims/gen/xapi-client";
import { assertNotEmpty } from "psims/lib/empty";
import { prefixedThrower } from "psims/lib/exception";

const STATUS_NAME = [
    'Not started',
    'Draft',
    'Submitted',
    'In review',
    'Action required',
    'Approved',
    'Inactive',
    'Received',
] as const;

type ExternalReportStatusName = typeof STATUS_NAME[number];

export type ExternalReportStatus = PopulatedProps<ExternalReportStatusDTO & { name: ExternalReportStatusName}, 'id' | 'name'>;

export function assertExternalReportStatus(maybe?: unknown): asserts maybe is ExternalReportStatus {
    const err = prefixedThrower(`Failed to assert externalReportStatus: ${JSON.stringify(maybe)}: `);

    const hint = (name: string) => `${name} - ${JSON.stringify(maybe)}`;

    const asExternalReportStatus = maybe as ExternalReportStatus;

    assertNotEmpty(asExternalReportStatus, hint('externalReportStatus'));
    assertNotEmpty(asExternalReportStatus.id, hint('externalReportStatus.id'));
    if (!STATUS_NAME.includes(asExternalReportStatus.name)) err(`unknown status name: ${asExternalReportStatus.name}`);
}
