import React from 'react';

import Page from 'psims/react/blocks/page';
import { H2 } from 'psims/react/components/typography';
import { BoxedDiv } from '../components/layout';
import SupportLink from '../components/support-link';

const BadGatewayPage = () => {
    return (
        <Page hideNavbar={true} hideUserBlock={true}>
            <BoxedDiv box={{}} className='container'>
                <H2>Liquid Fuels Gateway under maintenance</H2>

                <p>
                Sorry, the Liquid Fuels Gateway is currently unavailable due to an outage or scheduled maintenance.
                </p>
                
                <p>
                Please contact the Department at <SupportLink>PSIMSSupport@industry.gov.au</SupportLink> if you require assistance.
                </p>
            </BoxedDiv>
        </Page>
    )
}

export default BadGatewayPage;
