import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin, useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { useConfiguration } from './configuration-provider';
import useUpdatedRef from '../util/use-updated-ref';

const AppInsightsProvider = ({children}: React.PropsWithChildren<{}>) => {
    const {configuration} = useConfiguration();
    const history = useHistory();
    const [reactPlugin, setReactPlugin] = useState<ReactPlugin | null>(null);

    const historyRef = useUpdatedRef(history);

    useEffect(() => {
        const conStr = configuration?.APP_INSIGHTS_CONNECTION_STRING;

        if (conStr == null || conStr === '') {
            return;
        }

        const reactPlugin = new ReactPlugin();

        const appInsights = new ApplicationInsights({
            config: {
                connectionString: conStr,
                disableFetchTracking: false,
                enableCorsCorrelation: true,
                autoTrackPageVisitTime: true,
                enableAutoRouteTracking: true,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: historyRef.current }
                }
            }
        });

        appInsights.loadAppInsights();

        setReactPlugin(reactPlugin);
    }, [configuration?.APP_INSIGHTS_CONNECTION_STRING, historyRef]);

    if (reactPlugin == null) {
        return <>{children}</>;
    }

    return <AppInsightsContext.Provider value={reactPlugin}>
        {children}
    </AppInsightsContext.Provider>
}

export const useAppInsights = useAppInsightsContext;

export default AppInsightsProvider;

export function getSeverity(logLevel: LogLevel) {
    switch (logLevel) {
        case 'info':
            return SeverityLevel.Information;
        case 'error':
            return SeverityLevel.Error;
        case 'warn':
            return SeverityLevel.Warning;
        default:
            return SeverityLevel.Verbose;
    }
}
