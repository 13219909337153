import React, { useCallback, useMemo, useState } from 'react';

import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { UseStockholding } from './use-stockholding';
import { H3 } from 'psims/react/components/typography';
import Text from 'psims/react/components/text';
import { Table, TBody, TH, THead, TR } from 'psims/react/components/table';
import { localeNumberWithFixed } from 'psims/lib/formatters/numbers';
import Textarea from 'psims/react/components/textarea';
import { asString } from 'psims/lib/string';
import { CellEditor, TotalCell, useHelpForGroup, useHelpForProduct, FocusField, isDeleteFocusField } from './shared';
import { TooltipHelp } from '../shared/tooltip-help';
import styled from 'styled-components';
import { GroupTotals, isRowFocusField, ProductRow as ProductRowModel, UsePageDomestic } from './use-page-domestic';
import { ColumnHeader, DeleteActionCellTD, DeleteActionCellTH, TD } from '../shared/data-table-components';
import ReactMarkdown from 'react-markdown';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import FloatingMessage from 'psims/react/components/floating-message';
import { EXPIRED_OR_INTERNAL_PRODUCT } from 'psims/constants/validation-messages';
import { IconButton } from 'psims/react/components/button';
import useFocusable from 'psims/react/util/use-focusable';

interface AustraliaEditorProps {
    ctrl: UseStockholding;
}

const AustraliaEditor = ({ctrl}: AustraliaEditorProps) => {
    const {domesticCtrl} = ctrl;
    const {view} = domesticCtrl;

    const isCommentsFocused = useMemo(() => {
        const focusedField = domesticCtrl.focusedFieldCtrl.focusedField;

        return focusedField === 'comments';
    }, [domesticCtrl.focusedFieldCtrl.focusedField]);

    return (
        <BoxedDiv box={{flex: 'column'}}>
            {
                view.groups
                    .filter(g => g.groupStatus !== 'expired' && g.groupStatus !== 'empty')
                    .map((spg, i) => (
                    <ProductGroupTable
                        key={spg.productGroup.id}
                        stockholdingCtrl={ctrl}
                        group={spg}
                        groupIndex={i}
                    />
                ))
            }

            <BoxedDiv box={{marginV: 2}}>
                <Textarea
                    disabled={ctrl.disableInputs}
                    error={ctrl.disableInputs ? undefined : view.comments.validationMessage}
                    forceError={true}
                    id='comments_domestic'
                    label={`Comments${!view.isCommentsRequired ? ' (optional)' : ''}`}
                    value={(asString(view.comments.data?.comments))}
                    rows={3}
                    setFocused={isCommentsFocused}
                    onChange={e => domesticCtrl.updateComments(e.target.value)}
                />
            </BoxedDiv>
        </BoxedDiv>
    )   
}

const StyledTR = styled(TR)`
    border: none !important;

    & {
        td:first-child,
        th:first-child {
            border-left: none;
        }

        th:last-child {
            border-right: none !important;
        }
    }
    & th {
        border-bottom: 1px solid var(--color-table-border);
    }

    thead & th {
        border-bottom: none;
        border-top: none;
    }
`;

interface ProductGroupTableProps {
    stockholdingCtrl: UseStockholding;
    group: ArrayElement<UsePageDomestic['view']['groups']>;
    groupIndex: number;
}

const ProductGroupTable = ({stockholdingCtrl, group, groupIndex}: ProductGroupTableProps) => {
    const {productGroup} = group;
    const {portalDataAPICtrl} = stockholdingCtrl.domesticCtrl;

    const hasExpiredData = group.products.some(p => p.productStatus === 'expired_with_data');
    const helpForGroup = useHelpForGroup(portalDataAPICtrl, productGroup, 'In Australia');

    return (
        <BoxedDiv box={{flex: 'column', marginV: 4, overflowX: 'hidden'}}>
            <BoxedDiv box={{flex: 'column', alignSelf: 'stretch', overflowX: 'auto'}}>
                <H3 marginBottom={3}>
                    <BoxedDiv box={{flex: 'row', alignItems: 'center'}}>
                        {productGroup.name}
                        {
                            (helpForGroup) &&
                            <TooltipHelp
                                Help={<ReactMarkdown>{helpForGroup}</ReactMarkdown>}
                            />
                        }
                    </BoxedDiv>
                </H3>

                <Table caption='Your stockholding data for in Australia' cellBorder={true} compact={true} stickyHeader={true}>
                    <THead>
                        <StyledTR>
                            <TH $align='left' $width='350px' scope='col'>Product</TH>
                            <ColumnHeader
                                label='NSW'
                                Help='Products should be reported against the state or territory in which they were (or will be) consumed. If the state of final consumption is not known, please report the state where the product was entered for home consumption (or equivalent). Please include any wholesales occurring in the ACT in the NSW column.'
                                $align='center'
                                scope='column'
                                $width='100px'
                            />
                            <TH $align='center' $width='100px'>VIC</TH>
                            <TH $align='center' $width='100px'>QLD</TH>
                            <TH $align='center' $width='100px'>SA</TH>
                            <TH $align='center' $width='100px'>WA</TH>
                            <TH $align='center' $width='100px'>TAS</TH>
                            <TH $align='center' $width='100px'>NT</TH>
                            <TH $align='center' $width='120px'>Australia</TH>
                            {
                            hasExpiredData ?
                            <DeleteActionCellTH>
                                <VisuallyHidden>Actions</VisuallyHidden>
                            </DeleteActionCellTH> :
                            null
                            }                           
                        </StyledTR>
                    </THead>

                    <TBody>
                        {
                            group.products
                                .filter(p => p.productStatus !== 'expired')
                                .map((row, i) => (
                                <ProductRow
                                    key={row.product.id}
                                    stockholdingCtrl={stockholdingCtrl}
                                    groupIndex={groupIndex}
                                    row={row}
                                    rowIndex={i}
                                    hasExpiredData={hasExpiredData}
                                /> 
                            ))
                        }

                        <GroupTotalsRow totals={group.totals} />
                    </TBody>
                </Table>
            </BoxedDiv>
        </BoxedDiv>
    )
}

interface ProductRowProps {
    stockholdingCtrl: UseStockholding;
    groupIndex: number;
    row: ProductRowModel;
    rowIndex: number;
    hasExpiredData: boolean;
}

const ProductRow = ({stockholdingCtrl, groupIndex, row, rowIndex}: ProductRowProps) => {
    const [deleteRef, setDeleteRef] = useState<HTMLElement | null>(null);
    const {domesticCtrl: ctrl} = stockholdingCtrl;
    
    const {product, form, productStatus} = row;
    const {data} = form;
    const Help = useHelpForProduct(
        stockholdingCtrl.domesticCtrl.portalDataAPICtrl,
        'In Australia',
        row.product.referenceCode
    );
    
    const {focusedFieldCtrl, updateRow} = ctrl;

    const rowFocusedField = useMemo(() => {
        return ((
                isRowFocusField(focusedFieldCtrl.focusedField) &&
                focusedFieldCtrl.focusedField.stockProductId === product.id
            ) ?
            focusedFieldCtrl.focusedField.field :
            null
        );
    }, [focusedFieldCtrl.focusedField, product]);

    const canDelete = useMemo(() => {
        return !stockholdingCtrl.disableInputs && productStatus === 'expired_with_data';
    }, [productStatus, stockholdingCtrl.disableInputs])

    return <>
        <StyledTR>
            <TH notFocusable={true} scope='row'>
                <BoxedSpan box={{alignItems: 'center', flex: 'row',}}>
                    <Text $noWrap={true}>{product.productName}</Text>
                    {
                        canDelete ? <BoxedSpan box={{marginBottom: -0.5, marginLeft: 1}}>
                        <FloatingMessage
                            content={EXPIRED_OR_INTERNAL_PRODUCT}
                            kind="warning"
                            role="alert"
                        />
                        </BoxedSpan> : (
                            Help && <>
                            <TooltipHelp
                                Help={<ReactMarkdown>{Help}</ReactMarkdown>}
                            />
                        </>
                        )
                    }
                </BoxedSpan>
            </TH>

            <CellEditor
                autofocus={groupIndex === 0 && rowIndex === 0}
			    aria-errormessage={form.validationMessages.nswStockVolume ? `product${data.stockProductId}_nsw_error` : undefined}
                disabled={stockholdingCtrl.disableInputs}
                error={form.validationMessages.nswStockVolume}
                forceError={stockholdingCtrl.domesticCtrl.shouldForceErrors}
                id={`product${data.stockProductId}_nsw`}
                infoOnNegative={true}
                label={`Volume of ${product.productName} for NSW`}
                onUpdate={value => updateRow({field: 'nswStockVolume', value, stockProductId: data.stockProductId!!})}
                setFocus={rowFocusedField === 'nswStockVolume'}
                value={data.nswStockVolume ?? undefined}
            />

            <CellEditor
                id={`product${data.stockProductId}_vic`}
                disabled={stockholdingCtrl.disableInputs}
                error={form.validationMessages.vicStockVolume}
                infoOnNegative={true}
                label={`Volume of ${product.productName} for VIC`}
                onUpdate={value => ctrl.updateRow({field: 'vicStockVolume', value, stockProductId: data.stockProductId!!})}
                setFocus={rowFocusedField === 'vicStockVolume'}
                value={data.vicStockVolume ?? undefined}
            />

            <CellEditor
                id={`product${data.stockProductId}_qld`}
                disabled={stockholdingCtrl.disableInputs}
                error={form.validationMessages.qldStockVolume}
                infoOnNegative={true}
                label={`Volume of ${product.productName} for QLD`}
                onUpdate={value => ctrl.updateRow({field: 'qldStockVolume', value, stockProductId: data.stockProductId!!})}
                setFocus={rowFocusedField === 'qldStockVolume'}
                value={data.qldStockVolume ?? undefined}
            />

            <CellEditor
                id={`product${data.stockProductId}_sa`}
                disabled={stockholdingCtrl.disableInputs}
                error={form.validationMessages.saStockVolume}
                infoOnNegative={true}
                label={`Volume of ${product.productName} for SA`}
                onUpdate={value => ctrl.updateRow({field: 'saStockVolume', value, stockProductId: data.stockProductId!!})}
                setFocus={rowFocusedField === 'saStockVolume'}
                value={data.saStockVolume ?? undefined}
            />

            <CellEditor
                id={`product${data.stockProductId}_wa`}
                disabled={stockholdingCtrl.disableInputs}
                error={form.validationMessages.waStockVolume}
                infoOnNegative={true}
                label={`Volume of ${product.productName} for WA`}
                onUpdate={value => ctrl.updateRow({field: 'waStockVolume', value, stockProductId: data.stockProductId!!})}
                setFocus={rowFocusedField === 'waStockVolume'}
                value={data.waStockVolume ?? undefined}
            />

            <CellEditor
                id={`product${data.stockProductId}_tas`}
                disabled={stockholdingCtrl.disableInputs}
                error={form.validationMessages.tasStockVolume}
                infoOnNegative={true}
                label={`Volume of ${product.productName} for TAS`}
                onUpdate={value => ctrl.updateRow({field: 'tasStockVolume', value, stockProductId: data.stockProductId!!})}
                setFocus={rowFocusedField === 'tasStockVolume'}
                value={data.tasStockVolume ?? undefined}
            />

            <CellEditor
                id={`product${data.stockProductId}_nt`}
                disabled={stockholdingCtrl.disableInputs}
                error={form.validationMessages.ntStockVolume}
                infoOnNegative={true}
                label={`Volume of ${product.productName} for NT`}
                onUpdate={value => ctrl.updateRow({field: 'ntStockVolume', value, stockProductId: data.stockProductId!!})}
                setFocus={rowFocusedField === 'ntStockVolume'}
                value={data.ntStockVolume ?? undefined}
            />

            <TotalCell
                info={form.infoMessages.total?.message}
                value={localeNumberWithFixed(form.total)}
            />                      
            
            <DeleteActionCellTD>
                {
                canDelete ?
                    <BoxedDiv box={{ alignItems: 'center', flex: 'row', marginLeft: 1 }}>
                        <DeleteRowButton
                            focusedField={focusedFieldCtrl.focusedField}
                            onClick={() => ctrl.deleteProductRow(product.id, deleteRef)}
                            productId={product.id}
                            ref={setDeleteRef}
                        />
                    </BoxedDiv> :
                    null
                }
            </DeleteActionCellTD> 
        </StyledTR>
    </>;
}

interface GroupTotalsRowProps {
    totals: GroupTotals;
}

const GroupTotalsRow = ({totals}: GroupTotalsRowProps) => {
    return (
        <StyledTR>
            <TH $align='right' scope='row'><BoxedSpan box={{paddingRight: 2}}><strong>Totals</strong></BoxedSpan></TH>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.nsw}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.vic}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.qld}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.sa}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.wa}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.tas}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 0.5}}><strong>{totals.nt}</strong></BoxedSpan></TD>
            <TD $align='right'><BoxedSpan box={{paddingRight: 2}}><strong>{totals.australia}</strong></BoxedSpan></TD>
        </StyledTR>
    )
}

interface DeleteRowProps {
    focusedField: FocusField | null;
    onClick: () => any;
    productId: number;
}

const DeleteRowButton = React.forwardRef(({focusedField, onClick, productId}: DeleteRowProps, ref) => {
    const focusable = useFocusable({
        setFocused: (
            isDeleteFocusField(focusedField) &&
            focusedField.field === 'delete' &&
            focusedField.stockProductId === productId
        ),
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <IconButton
            color='dark-grey'
            icon='trash'
            label='Delete row for product'
            onClick={onClick}
            ref={setRef}
            size='sm'
        />
    );
});

export default AustraliaEditor;
