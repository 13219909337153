import { useMemo } from "react";

import { useReferenceData } from "psims/react/providers/api/reference-data";
import { toDictionary } from "psims/lib/collections";
import { isValidForPeriod } from "psims/models/ref-data/util";
import { SubmissionType } from "psims/models/ref-data/submission-type";

interface DataSubmission {
    reportingPeriodFrom: string;
    reportingPeriodTo: string;
    submissionTypeName: string;
}

interface UseMsoRefDataProps {
    dataSubmission: DataSubmission;
}

function useMsoRefData({dataSubmission}: UseMsoRefDataProps) {
    const {data: refData} = useReferenceData();

    const products = useMemo(() => {
        return (refData?.msoProducts || [])
        .map(p => ({
            ...p,
            productStatus: (
                isValidForPeriod(p, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo) ?
                'active' :
                'inactive'
            ) as 'active' | 'inactive'
        }))
        .sort((a, b) => a.displayOrder - b.displayOrder);
    }, [refData, dataSubmission.reportingPeriodFrom, dataSubmission.reportingPeriodTo]);

    const productIdMap = useMemo(() => {
        return toDictionary(products, 'id')
    }, [products]);

    const stockOwnershipTypes = useMemo(() => {
        return (refData?.msoStockOwnershipTypes || [])
            .filter(x => x.isActive);
    }, [refData]);

    const submissionType = useMemo(() => {
      if (dataSubmission == null || refData == null) {
        return null;
      }

      return refData.submissionTypes.find(st => st.name === dataSubmission.submissionTypeName);
    }, [refData, dataSubmission]);

    return {
        productIdMap,
        products,
        stockOwnershipTypes,
        submissionType: submissionType as SubmissionType,
    };
}

export default useMsoRefData

export type UseMsoRefData = ReturnType<typeof useMsoRefData>;
