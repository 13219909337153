import React, { useEffect } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import Button from 'psims/react/components/button';
import { InputNew as Input } from 'psims/react/components/input';
import Divider from 'psims/react/components/divider';
import { UseAddUser } from './use-add-user';
import Select from 'psims/react/components/select';
import { asString } from 'psims/lib/string';
import Notification from 'psims/react/components/notification';
import { useAppOverlay } from 'psims/react/providers/app-overlay';
import Modal from 'psims/react/components/modal';
import Spinner from 'psims/react/components/spinner';
import Text from 'psims/react/components/text';
import { StatusField } from './shared';
import { H2 } from 'psims/react/components/typography';

interface AddUserProps {
    ctrl: UseAddUser;
}

const AddUser = (props: AddUserProps) => {
    const vm = useVM(props);

    if (vm.user === null) {
        return null;
    }

    return (
        <BoxedDiv box={{flex: 'column'}}>
            <H2>Add user account</H2>

            <BoxedDiv box={{flex: 'column', flexGrow: 1}}>
                <BoxedDiv box={{flex: 'row', marginTop: 2}}>
                    <Text weight='semibold'>User account</Text> <Text variant="muted">&nbsp;(e.g. firstname.lastname@industry.gov.au)</Text>
                </BoxedDiv>

                <Input
                    autoFocus={true}
                    error={vm.validationMessages.email}
                    forceError={vm.saveAttempted}
                    hideLabel={true}
                    id='email'
                    label='User account (e.g. firstname.lastname@industry.gov.au)'
                    onChange={e => vm.updateUser('email', e.target.value)}
                    type='email'
                    value={asString(vm.user.email)}
                />
            </BoxedDiv>

            <BoxedDiv box={{flex: 'row'}}>
                <Input
                    id='firstname'
                    error={vm.validationMessages.firstName}
                    forceError={vm.saveAttempted}
                    label='First name'
                    flexGrow={1}
                    marginRight={4}
                    onChange={e => vm.updateUser('firstName', e.target.value)}
                    value={asString(vm.user?.firstName)}
                />

                <Input
                    id='surname'
                    error={vm.validationMessages.lastName}
                    forceError={vm.saveAttempted}
                    label='Surname'
                    flexGrow={1}
                    marginLeft={4}
                    onChange={e => vm.updateUser('lastName', e.target.value)}
                    value={asString(vm.user?.lastName)}
                />
            </BoxedDiv>

            <BoxedDiv box={{flex: 'row'}}>
                <Select
                    error={vm.validationMessages.roleType}
                    flexGrow={1}
                    forceError={vm.saveAttempted}
                    id='roletype'
                    label='Role type'
                    marginRight={4}
                    placeholder='Select a role type'
                    options={vm.selectRoleCtrl.options}
                    onChange={vm.selectRoleCtrl.onChange}
                    value={vm.selectRoleCtrl.value}
                />

                <BoxedDiv box={{flexGrow: 1, marginLeft: 4}} />

            </BoxedDiv>

            <StatusField status='New' />

            <BoxedDiv box={{marginTop: 2}}>
                <Divider />
            </BoxedDiv>

            {
                vm.serviceError &&

                <BoxedDiv box={{marginV: 1}}>
                    <Notification align='center' kind='warning'>
                        {vm.serviceError}
                    </Notification>
                </BoxedDiv>
            }

            <BoxedDiv box={{flex: 'row-reverse', justifyContent: 'flex-start', marginTop: 3.5}}>
                <Button $kind='primary' marginLeft={6} onClick={vm.save}>Save</Button>

                <Button $kind='text' onClick={vm.cancel}>Cancel</Button>
            </BoxedDiv>
        </BoxedDiv>
    )
}

function useVM({ctrl: {user, cancel, roleOptions, save, saveAttempted, selectRoleCtrl, serviceError, serviceStatus, statusOptions, updateUser, validationMessages}}: AddUserProps) {
    const {setOverlay} = useAppOverlay();

    useEffect(() => {
        if (serviceStatus === 'loading') {
            setOverlay(<Modal>
                <BoxedDiv box={{alignItems: 'center', flex: 'row'}}>
                    <Spinner size='lg' />
                    <BoxedDiv box={{alignItems: 'center', flex: 'row', marginLeft: 1}}>
                        <Text>Saving user...</Text>
                    </BoxedDiv>
                </BoxedDiv>
            </Modal>)
        } else {
            setOverlay(null);
        }
    }, [serviceStatus, setOverlay]);

    return {
        cancel,
        roleOptions,
        serviceError,
        save,
        saveAttempted,
        selectRoleCtrl,
        statusOptions,
        updateUser,
        user,
        validationMessages,
    };
}


export default AddUser;
