import React from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import TermsAndConditions from 'psims/react/blocks/terms-and-conditions';

interface TermsAndConditionsPageProps {}

const TermsAndConditionsPage = (props: TermsAndConditionsPageProps) => {
    return (
        <main className='container'>
            <BoxedDiv box={{alignSelf: 'stretch', flex: 'column', flexGrow: 1, justifySelf: 'center', marginTop:4}}>
                <TermsAndConditions />
            </BoxedDiv>
        </main>
    );;
}

export default TermsAndConditionsPage;
