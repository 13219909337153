import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Button from 'psims/react/components/button';
import { ButtonState } from 'psims/react/components/button/button';
import Dialog from 'psims/react/components/dialog';
import Warning from 'psims/react/components/icons/warning';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import Text from 'psims/react/components/text';
import { H3 } from 'psims/react/components/typography';
import Close from 'psims/react/components/icons/close';

type ConfirmationDialogProps<TSecondaryLabel extends string | undefined> = {
    ariaLabel: string;
    ariaDescribedby?: string;
    body: ReactNode;
    cancelLabel?: string | ReactNode;
    confirmLabel?: string;
    controlsState?: ButtonState;
    disableControls?: boolean;
    isOpen: boolean;
    onCancel: () => any;
    onConfirm: () => any;
    title: ReactNode;
    secondaryLabel?: TSecondaryLabel;
} & (TSecondaryLabel extends string ? {
    onSecondary: () => any;
} : {});

const StyledDialog = styled(Dialog)`
    border-top: 4px solid var(--color-primary);
`;

const ConfirmationDialog = <TSecondaryLabel extends string | undefined>(props: ConfirmationDialogProps<TSecondaryLabel>) => {
    const {
        ariaDescribedby,
        ariaLabel,
        body,
        cancelLabel = 'Cancel',
        confirmLabel = 'Proceed',
        controlsState,
        disableControls,
        isOpen,
        onCancel,
        onConfirm,
        title,
    } = props;

    return (
        <StyledDialog
            aria-label={ariaLabel}
            aria-describedby={ariaDescribedby}
            hideCloseButton={true}
            isOpen={isOpen}
            onDismiss={onCancel}
        >
            <BoxedDiv box={{flex: 'column', justifyContent: 'space-between'}}>
                <H3>
                    <BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
                        <Text $color='blue-70'><Warning size="lg" /></Text>
                        <BoxedSpan box={{marginLeft: 2}}>{title}</BoxedSpan>
                    </BoxedSpan>
                </H3>

                <BoxedDiv box={{marginV: 4}}>
                    {
                        typeof body === 'string' ?
                        <Text>{body}</Text> :
                        body
                    }
                </BoxedDiv>

                <BoxedDiv box={{flex: 'row-reverse', justifyContent: 'space-between'}}>
                    <BoxedDiv box={{flex: 'row-reverse'}}>
                        <Button
                            disabled={disableControls}
                            $kind='primary'
                            onClick={onConfirm}
                            state={controlsState}
                        >{confirmLabel}</Button>

                        {
                            isWithSecondaryButton(props) ?

                            <Button
                                disabled={disableControls}
                                $kind='ghost'
                                marginRight={2}
                                onClick={props.onSecondary}
                                state={controlsState}
                            >{props.secondaryLabel}</Button> :

                            null
                        }
                    </BoxedDiv>

                    <Button
                        disabled={disableControls}
                        $kind='unstyled'
                        marginLeft={-2}
                        marginRight={2}
                        onClick={onCancel}
                        paddingH={2}
                    >
                        <Text $color='blue-70' weight='semibold'>
                            <BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
                                <Close size='md' />&nbsp;{cancelLabel}
                            </BoxedSpan>
                        </Text>   
                    </Button>
                </BoxedDiv>
            </BoxedDiv>
        </StyledDialog>
    )
}

export default ConfirmationDialog;

function isWithSecondaryButton(maybe: ConfirmationDialogProps<string | undefined>): maybe is ConfirmationDialogProps<string> {
    return typeof maybe.secondaryLabel === 'string';
}
