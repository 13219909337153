import { useAPI } from "psims/react/providers/api";
import useDataSubmissionAPI from "psims/react/pages/primary-pages/data-submissions/shared/use-data-submission-api";
import { isSuccesfulRowResult } from "psims/models/api/record-result";
import { getRecordResults, isProductionSubmission, isProductionSubmitted, ProductionDataSubmissionName } from "psims/models/submission-types/production";
import { SHORTNAME_MAP } from "./util";

interface UseProductionAPIProps {
    id: number;
    productionTypeName: ProductionDataSubmissionName;
}


function useProductionAPI({id, productionTypeName}: UseProductionAPIProps) {
    const {api} = useAPI();

    const shortName = SHORTNAME_MAP[productionTypeName];

    return useDataSubmissionAPI({
        actionFetch: api[`get${shortName}ProductionSubmission`],
        actionSubmit: api[`submit${shortName}Production`],
        actionUpdate: api[`update${shortName}Production`],
        actionUpdateDataSubmission: api[`update${shortName}ProductionDataSubmission`],
        actionClearAllData: api[`clearAll${shortName}Production`],
        dataSubmissionId: id,
        name: productionTypeName,
        submissionAssertion: isProductionSubmission,
        submitSuccessAssertion: isProductionSubmitted,
        updateSuccessAssertion,
    });
}

export default useProductionAPI;

export type UseProductionAPI = ReturnType<typeof useProductionAPI>;

function updateSuccessAssertion(result: unknown) {
    if (result == null) {
        return false;
    }

    return getRecordResults(result)
        .filter(r => !isSuccesfulRowResult(r)).length === 0;
}
