import { DataSubmissionRecordActionEnum } from "psims/gen/xapi-client";

// These need to be kept in sync with XAPI enum (PSIMS.Core.Enums.DataSubmissionRecordActionEnum)
const RECORD_ACTIONS = ['Create', 'Update', 'Delete'] as const;

export type RecordAction = ArrayElement<typeof RECORD_ACTIONS>;

export type RecordActionEnum = DataSubmissionRecordActionEnum;

export function recordActionAsEnum(recordAction: RecordAction): DataSubmissionRecordActionEnum {
    return RECORD_ACTIONS.findIndex(action => action === recordAction) + 1;
}

export function recordActionFromEnum(recordActionEnum: number | undefined | null): RecordAction | null {
    return recordActionEnum == null ? null : RECORD_ACTIONS[recordActionEnum - 1];
}

export function isRecordActionEnum(maybe?: unknown): maybe is RecordActionEnum {
    return recordActionAsEnum(maybe as RecordAction) > 0;
}
