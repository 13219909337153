import { useEffect, useState } from "react";

function useFocusable() {
	const [ref, setRef] = useState<Element | null>(null);
	const [isMouseover, setIsMouseover] = useState(false);

	useEffect(() => {
		if (ref == null) {
			return;
		}

		function onMouseenter() {
			setIsMouseover(true);
		}

		function onMouseleave() {
			setIsMouseover(false);
		}

		ref.addEventListener('mouseenter', onMouseenter);
		ref.addEventListener('mouseleave', onMouseleave);

		return () => {
			ref.removeEventListener('mouseenter', onMouseenter);
			ref.removeEventListener('mouseleave', onMouseleave);
		}
	}, [ref])

    return {
		isMouseover,
        setRef,
    };
}

export default useFocusable;
