import { StockholdingSubmissionFormDataVM, StockholdingSubmissionVM, UpdateStockholdingDomesticVM, UpdateStockholdingOnWaterPortsVM, UpdateStockholdingOnWaterVM, UpdateStockholdingOverseasVM, UpdateStockholdingCommentVM, UpdateStockholdingSubmissionDomesticVM, UpdateStockholdingSubmissionOverseasVM, SubmissionRecordResultVM, UpdateStockholdingSubmissionOnWaterVM, StockholdingSubmissionVMApiResponse } from "psims/gen/xapi-client";
import { APIResponse } from "../api/response";
import { ValidationAlertMaybe } from "../api/submission/validation-alert";
import { isPopulatedDataSubmission, PopulatedDataSubmission } from "../data-submission";

export type StockholdingSubmission = StockholdingSubmissionVM;

export type Stockholding = StockholdingSubmission & {
    dataSubmission: PopulatedDataSubmission<'Stockholding'>;
};

type StockholdingSubmissionFormData = StockholdingSubmissionFormDataVM;

type PopulatedStockholdingSubmissionFormData = PopulatedProps<StockholdingSubmissionFormData, 'australiaPageSaved' | 'onWaterPageSaved' | 'overseasPageSaved'>;

// Domestic types
export type UpdateStockholdingDomestic = UpdateStockholdingDomesticVM;
export type UpdateStockholdingSubmissionDomestic = UpdateStockholdingSubmissionDomesticVM;

// Overseas types
export type UpdateStockholdingOverseas = UpdateStockholdingOverseasVM;
export type UpdateStockholdingSubmissionOverseas = UpdateStockholdingSubmissionOverseasVM;

// On water types
export type UpdateStockholdingOnWater = UpdateStockholdingOnWaterVM;

export type UpdateStockholdingSubmissionOnWater = UpdateStockholdingSubmissionOnWaterVM;

export type UpdateStockholdingOnWaterPorts = UpdateStockholdingOnWaterPortsVM;

// Update response type
export type StockholdingSubmissionUpdateResponse = APIResponse<StockholdingSubmission>;

type PopulatedStockholding = Stockholding & {
	dataSubmission: PopulatedDataSubmission<'Stockholding'>;
    submissionFormData: PopulatedStockholdingSubmissionFormData;
};

export type UpdateStockholdingComment = UpdateStockholdingCommentVM;

export function isPopulatedStockholding(maybe?: StockholdingSubmissionVM): maybe is PopulatedStockholding {
	return maybe != null &&
		   isPopulatedDataSubmission(maybe.dataSubmission) &&
           isPopulatedStockholdingSubmissionFormData(maybe.submissionFormData)
}

function isPopulatedStockholdingSubmissionFormData(maybe?: StockholdingSubmissionFormDataVM | null): maybe is PopulatedStockholdingSubmissionFormData {
    return maybe != null &&
        maybe.australiaPageSaved != null &&
        maybe.onWaterPageSaved != null &&
        maybe.overseasPageSaved != null;
}

// Submission response
export type StockholdingSubmissionResponse = StockholdingSubmissionVMApiResponse;

type WithServerFields = {
    recordResult?: SubmissionRecordResultVM | null;
    validationAlerts?: Array<ValidationAlertMaybe> | null;
}

type ServerFields = keyof WithServerFields;

export function removeServerFields<T extends WithServerFields>(source: T): Omit<T, ServerFields> {
    const {recordResult, validationAlerts, ...result} = source;
    return result;
}
