import { useCallback, useMemo } from "react";

import { isSuccesfulRowResult, RecordResult } from "psims/models/api/record-result";
import { APIResponse, isSuccessfulAPIResponseWithResult, ValidationMessage } from "psims/models/api/response";
import { SubmissionStatus } from "psims/react/pages/primary-pages/data-submissions/shared/api";
import { isValidationAlert, ValidationAlert } from "psims/models/api/submission/validation-alert";
import { stringifyServerError } from "psims/lib/server-error";
import { UseProductionAPI } from "./use-production-api";
import { isProductionSubmissionWithRecordResults, ProductionSubmission } from "psims/models/submission-types/production";

interface UseProductionSubmissionResponseProps {
    apiCtrl: UseProductionAPI;
}

export type ValidationAlertView = {
    validationAlert: ValidationAlert;
    productId: number;
    productionId: number;
}

type ProductionServiceResponse = {
    notOKRecordResults: Array<RecordResult> | null;
    serverErrorMessages: Array<string> | null;
    status: SubmissionStatus;
    submission: ProductionSubmission | null;
    validationAlerts: Array<ValidationAlertView> | null;
    validationMessages: Array<ValidationMessage> | null;
}

function useProductionServiceResponse({apiCtrl}: UseProductionSubmissionResponseProps) {
    const buildServiceResponse = useCallback<() => ProductionServiceResponse>(() => {
        if (apiCtrl.isBusy) {
            return {
                notOKRecordResults: null,
                serverErrorMessages: null,
                status: apiCtrl.loadStatus,
                submission: apiCtrl.submission,
                validationAlerts: null,
                validationMessages: null
            };
        }

        const updateResponse = apiCtrl.updateResponse || {};
        const recordResults = collectSubmissionRecordResults(updateResponse);
        return {
            notOKRecordResults: recordResults == null ? null : recordResults.filter(rr => !isSuccesfulRowResult(rr)),
            serverErrorMessages: ((updateResponse.errorMessages?.length || 0) > 0 && updateResponse.errorMessages) || (apiCtrl.updateError ? [stringifyServerError(apiCtrl.updateError)] : null) || null,
            status: apiCtrl.loadStatus,
            submission: apiCtrl.submission,
            validationAlerts: collectSubmissionValidationAlerts(apiCtrl.submission),
            validationMessages: updateResponse.validationMessages || null
        } as ProductionServiceResponse;
    }, [apiCtrl.submission, apiCtrl.updateResponse, apiCtrl.isBusy, apiCtrl.loadStatus, apiCtrl.updateError]);

    const productionServiceResponse = useMemo(() => {
        return buildServiceResponse();
    }, [buildServiceResponse]);
    
    return productionServiceResponse;
}

export default useProductionServiceResponse

export type UseProductionServiceResponse = ReturnType<typeof useProductionServiceResponse>;

// Helpers
function collectSubmissionRecordResults(maybeResponse?: APIResponse<unknown>): Array<RecordResult> | null {
    if (maybeResponse == null || !isSuccessfulAPIResponseWithResult(maybeResponse, isProductionSubmissionWithRecordResults)) {
        return null;
    }

    const productionRecordResults = (maybeResponse.result.productions || [])
        .map(r => r.recordResult)
        .filter(r => r != null) as Array<RecordResult>;

    const commentRecordResult = maybeResponse.result.pageData.formDataResult;
        
    const dataSubmissionRecordResult = maybeResponse.result.dataSubmission?.recordResult

    const allRecordResults = [
        ...productionRecordResults,
        ...(dataSubmissionRecordResult == null ? [] : [dataSubmissionRecordResult as RecordResult]),
        ...(commentRecordResult == null ? [] : [{
            rowResult: commentRecordResult.result,
            message: commentRecordResult.message,
        }])
    ];

    return allRecordResults.length > 0 ? allRecordResults as Array<RecordResult> : null;
}

function collectSubmissionValidationAlerts(submission: ProductionSubmission | null): Array<ValidationAlertView> | null {
    if (submission == null) {
        return null;
    }

    const validationAlertViews: Array<ValidationAlertView> = [];

    submission.productions.forEach(production => {
        if (production.validationAlerts == null) {
            return;
        }

        production.validationAlerts.forEach(validationAlert => {
            if (isValidationAlert(validationAlert)) {
                validationAlertViews.push({
                    validationAlert,
                    productId: production.productionProductId,
                    productionId: production.id,
                });
            }
        })
    })

    return validationAlertViews.length > 0 ? validationAlertViews : null; 
}
