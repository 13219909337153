import React, { PropsWithChildren, useMemo } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import { PrimaryPages } from "psims/react/pages/primary-pages";
import ReportSubmitted from "psims/react/pages/report-submittted";
import ContactRegistration from "psims/react/pages/contact-registration";
import Landing from "psims/react/pages/landing";
import NotFoundPage from "psims/react/pages/not-found";
import AcceptTermsAndConditionsPage from "psims/react/pages/accept-terms-and-conditions";
import { useUser } from "./user";
import useUpdatedRef from "../util/use-updated-ref";
import useSessionTimeout from "../components/session-timeout";
import PortalAdmin from "../pages/portal-admin";
import { isPortalAdmin } from "psims/models/user";
import SignOutPage from "../pages/sign-out";
import { getSubmissionTypeName } from "psims/constants/data-submissions";
import { DataSubmissionTypeName, ReportFrequencyTypeName } from "psims/models/data-submission";
import IndustryRedirectPage from "../pages/industry-redirect";

interface DataSubmission {
  reportFrequencyTypeName: ReportFrequencyTypeName;
  id: number;
  submissionTypeName: DataSubmissionTypeName;
}

const AppRouter = () => {
  const {status, user} = useUser();
  useSessionTimeout({
    sessionLimitMins: 20,
    warnAfterInactiveMins: 19
  });

  if (isPortalAdmin(user)) {
    return (
      <SwitchWithSignout>
        <Route path='/admin'>
          <PortalAdmin />
        </Route>

        <Route path='*'>
          <Redirect to='/admin'/>
        </Route>
      </SwitchWithSignout>
    )
  }
  
  if (user != null && !user.termsConditionsAcknowledged) {
    return (
      <SwitchWithSignout>
        <Route exact={true} path='/accept-terms-and-conditions'>
          <AcceptTermsAndConditionsPage />
        </Route>

        <Route path='/*'>
          <Redirect to='/accept-terms-and-conditions' />
        </Route>
      </SwitchWithSignout>
    )
  }

  if (status === 'Invited') {
    return <SwitchWithSignout>
      <Route exact={true} path='/register'>
        {
          user === null ?
          null :
          <ContactRegistration user={user} />
        }
      </Route>

      <Route path='/*'>
        <Redirect to='/register' />
      </Route>
    </SwitchWithSignout>
  }

  if (status === 'Registered') {
    return (
      <>
        <SwitchWithSignout>
          <Route path='/report-submitted'>
            <ReportSubmitted />
          </Route>

          <Route path='/not-found'>
            <NotFoundPage />
          </Route>

          <Route path='/*'>
            <PrimaryPages />
          </Route>
        </SwitchWithSignout>
      </>
    );
  }

  return <Redirect to='/landing' />
}

export const UnauthenticatedRouter = () => {
  return (
    <Switch>
      <Route path='/landing'>
        <Landing />
      </Route>

      <Route path='/industry-redirect'>
        <IndustryRedirectPage />
      </Route>

      <Route path='/*'>
        <Redirect to='/landing' />
      </Route>
    </Switch>
  )
}

const SwitchWithSignout = ({children}: PropsWithChildren<{}>) => {
  return (
    <Switch>
      <Route path='/sign-out'>
        <SignOutPage />
      </Route>

      <Route path='/industry-redirect'>
        <IndustryRedirectPage />
      </Route>

      {children}
    </Switch>
  )
}

export default AppRouter;

export function useNavigation() {
  const _history = useHistory();

  const historyRef = useUpdatedRef(_history);

  const nav = useMemo(() => {
    const history = historyRef.current;

    return {
      goToContactRegistrationPage: () => history.push('/contact-registration'),
      goToCompanyOverviewPage: () => history.push('/company-overview'),
      goToReportingHistoryPage: () => history.push('/reporting-history'),
      goToHomePage: () => history.push('/home'),
      goToLoginPage: () => history.push('/login'),
      goToSignOutPage: () => history.push('/sign-out'),
      goToRequestReportingObligationsModify: (id: number) => history.push(`/modify-reporting-obligation?id=${id}`),
      goToReportingObligations: () => history.push('/reporting-obligations'),
      goToNotFound: () => history.push('/not-found'),
      goToFsspDataSubmission: (id: number) => history.push(`/data-submissions/fuel-security-services-payment?id=${id}`),
      goToMsoImporterDataSubmission: (id: number) => history.push(`/data-submissions/mso-importer?id=${id}`),
      goToMsoRefinerDataSubmission: (id: number) => history.push(`/data-submissions/mso-refiner?id=${id}`),
      goToMsoAnnualActivityImporterDataSubmission: (id: number) => history.push(`/data-submissions/mso-annual-activity-importer?id=${id}`),
      goToMsoAnnualActivityRefinerDataSubmission: (id: number) => history.push(`/data-submissions/mso-annual-activity-refiner?id=${id}`),
      goToDefDataSubmission: (id: number) => history.push(`/data-submissions/diesel-exhaust-fluid?id=${id}`),
      goToFieldProductionReport: (id: number) => history.push(`/data-submissions/field-production?id=${id}`),
      goToPlantProductionReport: (id: number) => history.push(`/data-submissions/plant-production?id=${id}`),
      goToBiofuelProductionReport: (id: number) => history.push(`/data-submissions/biofuel-production?id=${id}`),
      goToStockholdingReport: (id: number) => history.push(`/data-submissions/stockholding?id=${id}`),
      goToWholesalingReport: (id: number) => history.push(`/data-submissions/wholesaling?id=${id}`),
      goToRefineryReport: (id: number) => history.push(`/data-submissions/refinery?id=${id}`),
      goToReportSubmittedPage: (id: number) => history.push(`/report-submitted?id=${id}`),
      goToUserManagementPage: () => history.push('/user-management'),
      goToReport: (ds: DataSubmission) => {
        const {id} = ds;
        switch (getSubmissionTypeName(ds)) {
          case 'FSSP s19A':
            nav.goToFsspDataSubmission(id);
            return;

          case 'Plant production':
            nav.goToPlantProductionReport(id);
            return;
      
          case 'Biofuel production':
            nav.goToBiofuelProductionReport(id);
            return;
            
          case 'Field production':
            nav.goToFieldProductionReport(id);
            return;
      
          case 'Refining':
            nav.goToRefineryReport(id);
            return;
      
          case 'Stockholding':
            nav.goToStockholdingReport(id);
            return;
      
          case 'Wholesaling':
            nav.goToWholesalingReport(id);
            return;
      
          case 'MSO importer':
            nav.goToMsoImporterDataSubmission(id);
            return;
      
          case 'MSO refiner':
            nav.goToMsoRefinerDataSubmission(id);
            return;

          case 'MSO refiner - Annual activity':
            nav.goToMsoAnnualActivityRefinerDataSubmission(id);
            return;

          case 'MSO importer - Annual activity':
            nav.goToMsoAnnualActivityImporterDataSubmission(id);
            return;

          case 'Diesel exhaust fluid':
            nav.goToDefDataSubmission(id);
            return;
        }
      }
    };

  }, [historyRef]);

  return nav;
}