import { isSuccesfulRowResult } from "psims/models/api/record-result";
import { mapToUserMessage } from "psims/models/api/submission/update/record-result";
import { useEffect, useMemo, useRef, useState } from "react";
import { ServiceMessages } from "./shared";
import { UseWholesalingSteps } from "./use-wholesaling-steps";
import { UseWholesalingUpdateResponse } from "./use-wholesaling-update-response";

interface UseWholesalingServiceMessagesProps {
    stepCtrl: UseWholesalingSteps;
    updateResponse: UseWholesalingUpdateResponse;
}

function useWholesalingServiceMessages({stepCtrl, updateResponse}: UseWholesalingServiceMessagesProps) {
    const [messages, setMessages] = useState<ServiceMessages | null>(null);
    const clearFlag = useRef(false);

    const processedMessages = useMemo(() => {
        if (clearFlag.current) {
            return {
                systemAlerts: [],
            };
        }
        // Construct message view data
        const seenMessages = new Set<string>();
        const systemAlerts = [
            ...(messages?.errorMessages || []),
            ...(messages?.validationMessages || [])
                .filter(v => v.errorMessage != null)
                .map(v => v.errorMessage as string)
                .filter(m => {
                    const hasSeenMessage = !seenMessages.has(m);
                    seenMessages.add(m);
                    return hasSeenMessage;
                }),
            ...(messages?.recordResults || [])
                .filter(r => !isSuccesfulRowResult(r))
                .map(mapToUserMessage)
                .filter(m => {
                    const hasSeenMessage = !seenMessages.has(m);
                    seenMessages.add(m);
                    return hasSeenMessage;
                })
        ];

        if (systemAlerts.length === 0 && updateResponse.processedError != null) {
            systemAlerts.push(updateResponse.processedError)
        }

        return {
            systemAlerts,
        }
    }, [clearFlag, messages, updateResponse.processedError]);

    // Take latest update messages:
    useEffect(() => {
        clearFlag.current = false;
        if (updateResponse == null || updateResponse.processedResponse == null) {
            setMessages(null);
            return;
        }

        const {errorMessages, recordResults, validationMessages} = updateResponse.processedResponse;

        setMessages({
            errorMessages,
            recordResults,
            validationMessages,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearFlag, updateResponse.status]);

    useEffect(() => {
        clearFlag.current = true;
        setMessages(null);
    }, [stepCtrl.currentStep.index, clearFlag]);

    return {
        messages: processedMessages,
    };
}

export default useWholesalingServiceMessages;

export type UseWholesalingServiceMessages = ReturnType<typeof useWholesalingServiceMessages>;
