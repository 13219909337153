import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { DataSubmission, PopulatedDataSubmission } from 'psims/models/data-submission';
import Notification from 'psims/react/components/notification';
import { BoxedDiv } from 'psims/react/components/layout';
import Button from 'psims/react/components/button';
import { useNavigation } from 'psims/react/providers/router';
import DataSubmissionHeader from 'psims/react/blocks/data-submission-header';
import SupportLink from 'psims/react/components/support-link';
import { H1 } from 'psims/react/components/typography';

interface FspPreconditionNotMetNotificationProps {
  fsspDataSubmission: PopulatedDataSubmission<'FSSP s19A'>;
  refiningDataSubmission?: DataSubmission<'Refining'>;
}

const StyledH1 = styled(H1)`
  font-size: 36px;
  margin-top: 0;
`;

const FspPreconditionNotMetNotification = (props: FspPreconditionNotMetNotificationProps) => {
  const vm = useVM(props);

  return <div>
    <DataSubmissionHeader dataSubmission={vm.fsspDataSubmission} />

    <div className='container'>
      <BoxedDiv box={{marginTop: 4}}>
        <Notification align='flex-start' kind='info'>
          <BoxedDiv box={{alignItems: 'flex-start', flex: 'column', marginTop: vm.hasRefining ? 0 : 1.5}}>
            <StyledH1>
              Incomplete refining data submissions
            </StyledH1>

            {
              vm.hasRefining ? <>
                <p>
                  You have one or more refining data submissions for the quarter that are incomplete or require action. To open this FSSP s19A data submission, you are required to have completed the outstanding refining data submissions.
                </p>

                <p>
                  To action, click on the Open refining data submission button and you will be taken to the earliest outstanding data submission.
                </p>

              </> : <p>
                This report is not available. Please contact the Department at <SupportLink />.
              </p>
            }
            <BoxedDiv box={{flex: 'row', marginV: 4}}>

              {
                vm.hasRefining ?
                <Button $kind='primary' onClick={vm.openRefining}>Open refining data submission</Button> :
                null
              }

              <BoxedDiv box={{marginLeft: vm.hasRefining ? 4 : 0}}>
                <Button $kind={vm.hasRefining ? 'ghost' : 'primary'} onClick={vm.goHome}>Return to home page</Button>
              </BoxedDiv>

            </BoxedDiv>
          </BoxedDiv>
        </Notification>
      </BoxedDiv>
    </div>
  </div>;
}

function useVM({fsspDataSubmission, refiningDataSubmission}: FspPreconditionNotMetNotificationProps) {
  const nav = useNavigation();

  const openRefining = useCallback(() => {
    if (refiningDataSubmission) {
      nav.goToRefineryReport(refiningDataSubmission.id);
    } else {
      //noop
    }
  }, [refiningDataSubmission, nav]);

  const hasRefining = useMemo(() => refiningDataSubmission != null, [refiningDataSubmission]);

  const goHome = useCallback(() => {
    nav.goToHomePage();
  }, [nav]);

  return {
    fsspDataSubmission,
    hasRefining,
    goHome,
    openRefining,
  };
}

export default FspPreconditionNotMetNotification;
