import { FspDataSubmission } from "psims/models/submission-types/fsp/fsp-submission";
import { useMemo, useState } from "react";
import useProgress from "../shared/use-progress";
import { ViewMode } from "../shared/use-view-mode";

interface UseFspProps {
  submission: FspDataSubmission;
  viewMode: ViewMode;
}

function useFspProgress({ submission, viewMode }: UseFspProps) {
  const steps = useMemo(() => {
    return [
      {
        kind: 'data' as 'data',
        label: 'FSSP s19A',
        hasData: Boolean(submission.pageData.fuelSecurityServicesPaymentPageSaved),
      },
      {
        kind: 'submit' as 'submit',
        label: 'Declaration',
        hasData: null,
      }
    ];
  }, [submission.pageData.fuelSecurityServicesPaymentPageSaved]);

  const [orderedFormDataPageSavedKeys] = useState(['dataPageSaved']);

  const submissionFormData = useMemo(() => {
    return {
      dataPageSaved: submission.pageData.fuelSecurityServicesPaymentPageSaved || false,
    };
  }, [submission.pageData.fuelSecurityServicesPaymentPageSaved]);

  const progressCtrl = useProgress({
    orderedFormDataPageSavedKeys,
    steps,
    submissionFormData,
    submissionStatus: submission.dataSubmission.status,
    viewMode,
  });

  return progressCtrl;
}

export default useFspProgress

export type UseFspProgress = ReturnType<typeof useFspProgress>;
