import { FsspHydrocarbonBlendstockVM } from "psims/gen/xapi-client";

export type FspHydrocarbonBlendstock = PopulatedProps<
  FsspHydrocarbonBlendstockVM,
  'dataSubmissionId' | 'refineryProductId' | 'refineryProductName'
>;

export function isFspHydrocarbonBlendstock(maybe?: unknown): maybe is FspHydrocarbonBlendstock {
  const maybeAs = maybe as FspHydrocarbonBlendstock;

  return (
    maybeAs != null &&
    maybeAs.dataSubmissionId != null &&
    maybeAs.refineryProductId != null &&
    maybeAs.refineryProductName != null
  );
}