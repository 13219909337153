import { UpdateWholesaleCommentVM, UpdateWholesaleSubmissionVM, UpdateWholesaleVM, WholesaleCommentVM, WholesaleSubmissionFormDataVM, WholesaleSubmissionVM, WholesaleVM } from "psims/gen/xapi-client";
import { isArrayOfType } from "psims/lib/collections";
import { isEntity } from "../api/entity";
import { UpdateFields, WithUpdatable } from "../api/update";
import { isPopulatedDataSubmission } from "../data-submission";
import { WithDataSubmission } from "./data-submission";

type MaybeWholesale = WholesaleVM;

export type Wholesale = WithUpdatable<
    PopulatedProps<MaybeWholesale, 'wholesaleProductId' | 'wholesaleTypeId'>
>;

type MaybeWholesaleComment = WholesaleCommentVM;

type WholesaleComment = WithUpdatable<
    PopulatedProps<MaybeWholesaleComment, 'wholesaleTypeId'>
>;

export type MaybeWholesaleSubmissionFormData = WholesaleSubmissionFormDataVM;

export type WholesaleSubmissionFormData = PopulatedProps<MaybeWholesaleSubmissionFormData, 'bulkPageSaved' | 'otherPageSaved' | 'retailersPageSaved' | 'totalPageSaved' | 'wholesalersPageSaved' | 'manualTotals'> & {
    id: number;
    concurrencyToken: string;
};

// TODO: Remove when added to XAPI:
type UpdateWholesaleSubmissionFormData = {
    id: number;
    concurrencyToken: string;
    manualTotals: boolean;
}

export type MaybeWholesaleSubmission = WholesaleSubmissionVM;

export type WholesaleSubmission = WithDataSubmission<'Wholesaling', {
    submissionFormData: WholesaleSubmissionFormData;
    wholesales: Array<Wholesale>;
    wholesaleComments: Array<WholesaleComment>;
}>;

type MaybeUpdateWholesale = UpdateWholesaleVM;

export type UpdateWholesale = PopulatedProps<MaybeUpdateWholesale, 'wholesaleProductId'>;

type WholesaleData = Omit<UpdateWholesale, UpdateFields | 'wholesaleProductId'>;

export type WholesaleField = keyof WholesaleData;

type MaybeUpdateWholesaleComment = UpdateWholesaleCommentVM

export type UpdateWholesaleComment = PopulatedProps<MaybeUpdateWholesaleComment, 'wholesaleTypeId'>

type MaybeUpdateWholesaleSubmission = UpdateWholesaleSubmissionVM;

export type UpdateWholesaleSubmission = PopulatedProps<MaybeUpdateWholesaleSubmission, 'dataSubmissionId' | 'wholesales'> & {
    submissionFormData: UpdateWholesaleSubmissionFormData;
    wholesales: Array<UpdateWholesale>;
    wholesaleComment?: UpdateWholesaleComment | null;
};

function isWholesaleSubmissionFormData(maybe?: MaybeWholesaleSubmissionFormData): maybe is WholesaleSubmissionFormData {
    return maybe != null &&
           maybe.id != null &&
           maybe.bulkPageSaved != null &&
           maybe.concurrencyToken != null &&
           maybe.manualTotals != null &&
           maybe.otherPageSaved != null &&
           maybe.retailersPageSaved != null &&
           maybe.totalPageSaved !== null &&
           maybe.wholesalersPageSaved !== null;
}

function isWholesale(maybe?: MaybeWholesale | null): maybe is Wholesale {
    return (
        maybe != null && (
            isEntity(maybe) || maybe.id === 0
        ) &&
        maybe.wholesaleProductId != null
    );
}

function isWholesaleComment(maybe?: MaybeWholesaleComment | null): maybe is WholesaleComment {
    return isEntity(maybe) &&
           maybe.wholesaleTypeId != null;
}

export function isWholesaleSubmission(maybe?: MaybeWholesaleSubmission | null): maybe is WholesaleSubmission {
    return maybe != null &&
           isPopulatedDataSubmission<'Wholesaling'>(maybe.dataSubmission) &&
           isWholesaleSubmissionFormData(maybe.submissionFormData) &&
           isArrayOfType(isWholesale, maybe.wholesales) &&
           isArrayOfType(isWholesaleComment, maybe.wholesaleComments);
}

export function updateWholesaleToWholesaleData(updateWholesale: UpdateWholesale): WholesaleData {
    const {id, wholesaleProductId, concurrencyToken, recordAction, ...wholesaleData} = updateWholesale;
    return wholesaleData;
}
