import { MsoStockholdingOwnershipVM, UpdateMsoStockholdingOwnershipVM } from "psims/gen/xapi-client";
import { WithUpdatable } from "psims/models/api/update";

type MaybeMsoStockholdingOwnership = MsoStockholdingOwnershipVM;

export type MsoStockholdingOwnership = WithUpdatable<
    PopulatedProps<MaybeMsoStockholdingOwnership, 'msoOwnershipType' | 'msoStockholdingId'>
>;

type MaybeUpdateMsoStockholdingOwnership = UpdateMsoStockholdingOwnershipVM;

export type UpdateMsoStockholdingOwnership = MaybeUpdateMsoStockholdingOwnership & {
    msoStockholdingId: number;
};

export type UpdateMsoStockholdingOwnershipField = Exclude<keyof UpdateMsoStockholdingOwnership, 'dataSubmissionId' |  'msoStockholdingId' | 'id' | 'recordAction' | 'concurrencyToken'>;

export function isUpdateMsoStockholdingOwnership(maybe?: unknown): maybe is UpdateMsoStockholdingOwnership {
    const maybeAs = maybe as UpdateMsoStockholdingOwnership;

    return (
        maybeAs != null &&
        maybeAs.msoStockholdingId != null
    );
}

