import React, { useMemo } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import SubmitTerms from 'psims/react/pages/primary-pages/data-submissions/shared/submit-terms';
import Textarea from 'psims/react/components/textarea';
import { asString } from 'psims/lib/string';
import { ViewMode } from 'psims/react/pages/primary-pages/data-submissions/shared/use-view-mode';
import useFieldState from 'psims/react/util/use-field-state';
import { DataSubmissionTypeName, PopulatedDataSubmission } from 'psims/models/data-submission';
import { CommentValidationResult } from './validation';

interface SubmitPageCtrl {
    comments: string | null | undefined;
    commentsError?: CommentValidationResult;
    dataSubmission: PopulatedDataSubmission<DataSubmissionTypeName>;
    declaration: boolean;
    isDisabled: boolean;
    updateComments: (val: string) => any;
    updateDeclaration: (val: boolean) => any;
    validationMessages: {
        declaration?: {
            validationMessages: {
                declaration: {
                    tooltip: {
                        message: string | undefined;
                    }
                } | undefined;
            }
        }
    }
}

interface FocusedFieldCtrl {
    focusedField: null | {
        kind: string;
    }
}

interface SubmitPageProps {
    ctrl: SubmitPageCtrl;
    CustomDeclaration?: string;
    CustomTerms?: JSX.Element;
    focusedFieldCtrl: FocusedFieldCtrl;
    isMso?: boolean;
    viewMode: ViewMode
}

const SubmitPage = (props: SubmitPageProps) => {
    const vm = useVM(props);

    const {ctrl, focusedFieldCtrl, isMso, viewMode} = vm;

    return (
        <BoxedDiv box={{marginV: 4}}>
            <SubmitTerms
                CustomDeclaration={props.CustomDeclaration}
                CustomTerms={props.CustomTerms}
                disabled={viewMode !== 'edit'}
                error={ctrl.validationMessages.declaration?.validationMessages.declaration?.tooltip.message}
                hasAgreed={ctrl.declaration || viewMode !== 'edit'}
                isMso={isMso}
                onHasAgreedChange={ctrl.updateDeclaration}
                shouldFocusDeclaration={focusedFieldCtrl.focusedField?.kind === 'declaration'}
            />

            <BoxedDiv box={{}}>
                <Textarea
                    autoFocus={true}
                    disabled={ctrl.isDisabled}
                    id='comments'
                    label='Comments'
                    error={vm.error}
                    onChange={evt => vm.ctrl.updateComments(evt.target.value)}
                    ref={vm.commentsRef}
                    setFocused={focusedFieldCtrl.focusedField?.kind === 'comments'}
                    value={asString(ctrl.comments)}
                />
            </BoxedDiv>

        </BoxedDiv>
    )
}

function useVM(props: SubmitPageProps) {
    const {ctrl} = props;

    const commentsFieldState = useFieldState({initialValue: props.ctrl.comments});

    const error = useMemo(
        () => commentsFieldState.focusState === 'visited' || commentsFieldState.focusState === 'focused' ? ctrl.commentsError?.message : undefined,
        [commentsFieldState.focusState, ctrl.commentsError]
    );

    return {
        ...props,
        error,
        commentsRef: commentsFieldState.setEl,
        comments: commentsFieldState.currentValue
    };
}

export default SubmitPage;
