import { OrganisationProductionAreaDTO } from "psims/gen/xapi-client";
import { isProductionArea, ProductionArea } from "./production-area";

export type OrganisationProductionArea = PopulatedProps<
    OrganisationProductionAreaDTO, 'id' | 'displayOrder' | 'isActive' | 'organisationName' | 'productionAreaId' | 'productionAreaName'
>;

export type OPAWithProductionArea = OrganisationProductionArea & {
    productionArea: ProductionArea;
}
    
export function isOrganisationProductionArea(maybe?: unknown): maybe is OrganisationProductionArea {
    const maybeAs = maybe as OrganisationProductionArea;

    return maybeAs != null && 
    typeof maybeAs.id === 'number' &&
    typeof maybeAs.organisationId === 'number' &&
    typeof maybeAs.productionAreaId === 'number' &&
    typeof maybeAs.productionAreaName === 'string' &&
    typeof maybeAs.isActive === 'boolean'
}

export function isOPAWithProductionArea(maybe?: unknown): maybe is OPAWithProductionArea {
    const maybeAs = maybe as OPAWithProductionArea;

    return (
        isOrganisationProductionArea(maybeAs) &&
        isProductionArea(maybeAs.productionArea)
    );
}