import { Strategy } from '@floating-ui/core/src/types';
import { useFloating, shift, offset, arrow } from '@floating-ui/react-dom';
import randomID from 'psims/lib/random-id';
import React, { PropsWithChildren, useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import useIsFocused from '../util/use-is-focused';
import Button from './button/button';
import Callout from './callout';
import Dot from './dot';
import Info from './icons/info';
import Text from './text';

interface InfoIconProps {
    info?: string;
}

const Container = styled.div`
    height: 24px;
    width: 24px;
`;

const InfoIcon = ({info}: InfoIconProps) => {
    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
    const {x, y, reference, refs, floating, strategy, update, middlewareData: {arrow: arrowData}} = useFloating({
        placement: 'top',
        middleware: [shift(), offset(24), ...(arrowRef != null ? [arrow({element: arrowRef})] : [])],
    });
    const [showInfo, setShowInfo] = useState(false);
    const [floatingId] = useState(randomID());

    const {handleBlur, handleFocus, isFocused} = useIsFocused({
        onBlur: () => setShowInfo(false),
        onFocus: () => {
            setShowInfo(true);
        },
    });

    useEffect(() => {
        const handleUpdate = () => {
            if (!refs.floating.current || !refs.reference.current) {
                return;
            }
            update();
        }

        const page = document.querySelector('#page') || document;
        page.addEventListener('scroll', handleUpdate)
        page.addEventListener('resize', handleUpdate)

        return () => {
            page.removeEventListener('scroll', handleUpdate)
            page.removeEventListener('resize', handleUpdate)
        };
    }, [refs, update]);

    useLayoutEffect(() => {
        isFocused && arrowRef != null && setTimeout(() => update(), 0);
    }, [arrowRef, update, isFocused]);

    return (
        <Container>
            {
                info != null  ? <>
                    <Button
                        $kind='unstyled'
                        onMouseDown={() => setShowInfo(!showInfo)}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        title={info}
                    >
                        <Text $color='blue-70' ref={reference}><Dot $size='xs'><Info color='white' size='xs'/></Dot></Text>
                    </Button>

                    {
                        showInfo ?
                        <FloatingMessage
                            arrowRef={setArrowRef}
                            arrowX={arrowData?.x}
                            arrowY={arrowData?.y}
                            id={floatingId}
                            targetRef={floating}
                            position={strategy}
                            x={x}
                            y={y}
                        >
                            <Callout $kind='info'>{info}</Callout>
                        </FloatingMessage> :
                        null
                    }
                </> :
                null
            }

        </Container>
    )
}

export default InfoIcon;

// TODO: this is copy/pasted from user-input-box - good candidate for re-usable component
interface FloatingMessageProps {
    arrowRef: (el: HTMLElement | null) => any;
    arrowX?: number | null;
    arrowY?: number | null;
    id?: string;
    targetRef: (el: HTMLElement | null) => any;
    position: Strategy;
    x: number | null;
    y: number | null;
}

const FloatingMessage = ({children, arrowRef, arrowX, arrowY, id, targetRef, position, x, y}: PropsWithChildren<FloatingMessageProps>) => {
    const [arrowId] = useState(randomID());

    return (
        <div
            id={id}
            ref={targetRef}
            style={{
                textAlign: 'left',
                fontSize: '14px',
                pointerEvents: 'none',
                position: position,
                left: x ?? '',
                top: y ?? '',
                zIndex: 3,
                maxWidth: '300px',
            }}
        >
            {children}
            <Arrow id={arrowId} ref={arrowRef} x={arrowX} y={arrowY} />
        </div>
    )
}

interface ArrowProps {
    x?: number | null;
    y?: number | null;
}

const Arrow = styled.div`${({x, y}: ArrowProps) => `
    background: var(--color-callout-info-background);
    height: 16px;
    position: absolute;
    width: 16px;
    ${x != null ? `
    left: ${x}px;
    ` : ''}
    ${y != null ? `
    top: ${y}px;
    ` : `
    bottom: -8px;
    `}
    transform: rotate(45deg);
`}`;

