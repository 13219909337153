import { MsoCommentVM, UpdateMsoCommentVM } from "psims/gen/xapi-client";

export type MsoComment = PopulatedProps<MsoCommentVM, 'comments' | 'concurrencyToken' | 'dataSubmissionId' | 'id'>;

export function isMsoComment(maybe?: unknown): maybe is MsoComment {
  const maybeAs = maybe as MsoComment;
  return (
    maybeAs != null &&
    maybeAs.id != null &&
    maybeAs.comments != null &&
    maybeAs.concurrencyToken != null
  );
}

type MaybeUpdateMsoComment = UpdateMsoCommentVM;

export type UpdateMsoComment = PopulatedProps<MaybeUpdateMsoComment, 'dataSubmissionId'>;
