import { useCallback, useEffect, useMemo, useState } from "react";

import { DataSubmission, DataSubmissionTypeName } from "psims/models/data-submission";
import transformToUpdateDataSubmission from "./transform-to-update-data-submission";
import produce from "immer";

interface UseDataSubmissionProps<TKind extends DataSubmissionTypeName> {
    commentsRequired?: boolean;
    dataSubmission: DataSubmission<TKind>;
}

function useDataSubmission<TKind extends DataSubmissionTypeName>({dataSubmission}: UseDataSubmissionProps<TKind>) {
    const [dataSubmissionUpdate, setDataSubmissionUpdate] = useState(transformToUpdateDataSubmission(dataSubmission));
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [changeSerial, setChangeSerial] = useState(0);

    const registerChange = useCallback(() => {
        setHasUnsavedChanges(true);
        setChangeSerial(prev => prev + 1);
    }, []);

    const updateComments = useCallback((val: string) => {
        setDataSubmissionUpdate(prev => produce(prev, draft => {
            draft.comments = val.length > 0 ? val : null;
        }));
        registerChange();
    }, [registerChange]);

    const resetComments = useCallback(() => {
        setDataSubmissionUpdate(transformToUpdateDataSubmission(dataSubmission));
    }, [dataSubmission]);


    useEffect(() => {
        setDataSubmissionUpdate(transformToUpdateDataSubmission(dataSubmission));
        setHasUnsavedChanges(false);
    }, [dataSubmission]);

    return useMemo(() => ({
        changeSerial,
        dataSubmissionUpdate,
        hasUnsavedChanges,
        resetComments,
        updateComments,
    }), [changeSerial, dataSubmissionUpdate, hasUnsavedChanges, resetComments, updateComments])
}

export default useDataSubmission
