import { useMemo } from "react";

import { asNumber } from "psims/lib/number";
import { UpdateMso } from "psims/models/submission-types/mso/mso";
import { UpdateMsoStockRefineryHeld } from "psims/models/submission-types/mso/mso-stock-refinery";
import { UpdateMsoStockholding } from "psims/models/submission-types/mso/mso-stockholding";
import { OrganisationMsoSetting } from "psims/models/submission-types/mso/organisation-mso-setting";
import { UseMsoRefData } from "./use-mso-ref-data";
import { recordActionFromEnum } from "psims/models/api/data-submission-record-action";

interface UseMsoProps {
    dataSubmissionId: number;
    msoSettings: Array<OrganisationMsoSetting>;
    msos: Array<UpdateMso>;
    refData: UseMsoRefData;
    refineriesHeld?: Array<UpdateMsoStockRefineryHeld>;
    stockholdings: Array<UpdateMsoStockholding>;
}

function useMso({dataSubmissionId, msoSettings, msos, refData, refineriesHeld, stockholdings}: UseMsoProps) {
    const computedMsos = useMemo(() => {
        return msoSettings
        .filter(setting => {
            const product = refData.products.find(p => p.id === setting.msoProductId);
            return product && product.productStatus === 'active';
        })
        .map(setting => {
            const msoProductId = setting.msoProductId;

            let computedMso = msos.find(m => m.msoOrganisationSettingId === setting.id) || {
                dataSubmissionId,
                msoOrganisationSettingId: setting.id,
                minimumStockObligation: setting.minimumStockObligation,
            };

            computedMso = {
                ...computedMso,
                minimumStockObligation: setting.minimumStockObligation,
            };

            const stockholding = stockholdings.find(s => s.msoProductId === msoProductId);

            const supplied = asNumber(stockholding?.eez) +
                asNumber(stockholding?.holderOwnerStockVolume) +
                asNumber(stockholding?.pipeline) +
                (stockholding?.stockholdingOwnerships || [])
                    .filter(so => recordActionFromEnum(so.recordAction) !== 'Delete')
                    .reduce((sum, ownership) => (
                        sum +
                        asNumber(ownership.stockVolume)
                    ), 0) +
                (refineriesHeld || []).filter(r => r.msoProductId === msoProductId)
                    .reduce((sum, refinery) => (
                        sum + 
                        // TODO: ensure all these are to be considered for MSO
                        asNumber(refinery.crudeStockVolume) +
                        asNumber(refinery.unfinishedStockVolume)
                    ), 0);

            const acquittedAmount = asNumber(computedMso.acquittedAmount);

            return {
                mso: {
                    ...computedMso,
                    supplied,
                    difference: (supplied + acquittedAmount) - asNumber(computedMso.minimumStockObligation),
                },
                product: refData.productIdMap[msoProductId],
                setting,
            }
        });
    }, [dataSubmissionId, msoSettings, msos, refData, refineriesHeld, stockholdings]);

    return {
        msos: computedMsos,
    };
}

export default useMso

export type UseMso = ReturnType<typeof useMso>;
