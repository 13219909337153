export type SubmissionType = {
    id: number;
    isActive: boolean;
    name: string;
    sortOrder: number;
}

const SUBMISSION_TYPE_NAMES = [
    'Field production',
    'Plant production',
    'Refining',
    'Wholesaling',
    'Stockholding',
    'Biofuel production',
    'MSO importer',
    'MSO refiner',
    'FSSP s19A',
] as const;

type SubmissionTypeName = typeof SUBMISSION_TYPE_NAMES[number];

export function isSubmissionType(maybe?: unknown): maybe is SubmissionType {
    const maybeAs = maybe as SubmissionType;

    return (
        maybeAs != null &&
        maybeAs.id != null &&
        maybeAs.name != null &&
        SUBMISSION_TYPE_NAMES.includes(maybeAs.name as SubmissionTypeName)
    );
}
