import { useMemo } from "react";
import { UseFspNavigation } from "./use-fsp-navigation";

interface UseFspUnsavedChangesProps {
  navCtrl: UseFspNavigation;
}

function useFspUnsavedChanges({navCtrl}: UseFspUnsavedChangesProps) {
  return useMemo(() => ({
    controlsState: navCtrl.navState === 'saving' ? 'loading' : 'normal' as 'loading' | 'normal',
    isOpen: navCtrl.navState === 'confirming_unsaved_changes',
    onCancel: () => navCtrl.handleUnsavedChangesDialogDescision('cancel'),
    onConfirm: () => navCtrl.handleUnsavedChangesDialogDescision('save_and_continue'),
    onSecondary: () => navCtrl.handleUnsavedChangesDialogDescision('discard_and_continue'),
  }), [navCtrl]);
}

export default useFspUnsavedChanges

export type UseFspUnsavedChanges = ReturnType<typeof useFspUnsavedChanges>;
