import React, { useCallback, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { RefineryTypeName } from 'psims/models/ref-data/refinery-type';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { ViewMode } from '../shared/use-view-mode';
import { FocusFieldDeleteRefining, GroupView, hasCalculatedInput, hasConsumed, hasDensity, hasProduction, hasRefineryInputs, hasTotalReceipts, isFocusFieldRefining, ProductView, UseRefineryForm } from './use-refinery-form';
import Textarea from 'psims/react/components/textarea';
import { asString } from 'psims/lib/string';
import { H3, U } from 'psims/react/components/typography';
import { Table, TR, ColumnHeader, TD, CellInput, TH, DeleteActionCellTH, DeleteActionCellTD } from '../shared/data-table-components';
import { TooltipHelp } from '../shared/tooltip-help';
import { RefiningField } from 'psims/models/submission-types/refining';
import Text from "psims/react/components/text";
import { localeNumberWithFixed } from 'psims/lib/formatters/numbers';
import RefineryLossesGainsEditor from './refinery-losses-gains-editor';
import { CLOSING_STOCKS_ROW_HEADING, CONSUMED_ROW_HEADING, DENSITY_ROW_HEADING, OPENING_STOCK_ROW_HEADING, PRODUCTION_ROW_HEADING, REFINERY_INPUTS_ROW_HEADING, TOTAL_RECEIPTS_ROW_HEADING } from './shared';
import useIsAutoFocused from 'psims/react/util/use-is-autofocused';
import { UsePortalDataAPI } from "psims/react/pages/portal-admin/manage-ref-data/use-portal-data-api";
import { SubmissionPortalDataTypeVM } from "psims/gen/xapi-client";
import FloatingMessage from 'psims/react/components/floating-message';
import { EXPIRED_OR_INTERNAL_PRODUCT } from 'psims/constants/validation-messages';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import useFocusable from 'psims/react/util/use-focusable';
import { IconButton } from 'psims/react/components/button';

interface RefineryTypeEditorProps {
    formCtrl: UseRefineryForm;
    portalDataAPICtrl: UsePortalDataAPI;
    typeId: number;
    typeName: RefineryTypeName;
    viewMode: ViewMode;
}

const RefineryTypeEditor = ({ formCtrl, portalDataAPICtrl, typeId, typeName, viewMode }: RefineryTypeEditorProps) => {
    const { view: submissionView } = formCtrl;
    const view = submissionView[typeName];
    const comments = view.data.comments.data;
    const disableTableEntry = viewMode !== 'edit';

    const helpForType: SubmissionPortalDataTypeVM[] = useMemo(() => {
        if (portalDataAPICtrl == null || portalDataAPICtrl.portalData == null || portalDataAPICtrl.portalData.referenceTypeData == null) {
            return [];
        }
        return portalDataAPICtrl.portalData.referenceTypeData
            .filter(rtd => rtd.referenceTypeId === typeId);
    }, [portalDataAPICtrl, typeId])

    return (
        <BoxedDiv box={{ overflowX: 'hidden' }}>
            {view.groups
                .filter(g => g.groupStatus !== 'expired' && g.groupStatus !== 'empty')
                .map(group => (
                    <Group key={group.productGroup.id} disabled={disableTableEntry} formCtrl={formCtrl} helpForType={helpForType} group={group} />
                ))}

            {/* Show losses/(gains) for gases page */}
            {
                typeName === 'Gases-Unfin-Petrochem-Losses' &&
                <RefineryLossesGainsEditor
                    formCtrl={formCtrl}
                />
            }

            {/* Page comments */}
            <BoxedDiv box={{ marginTop: 6 }}>
                <Textarea
                    disabled={viewMode !== 'edit'}
                    error={view.data.comments.validationError?.notification.message}
                    id='comments'
                    label='Comments'
                    setFocused={formCtrl.view?.focusedField === 'comments'}
                    value={asString(comments?.comments)}
                    onChange={e => formCtrl.updateRefiningComment(e.target.value, view.refineryType.id)}
                />
            </BoxedDiv>
        </BoxedDiv>
    )
}

interface GroupProps {
    disabled: boolean;
    formCtrl: UseRefineryForm;
    helpForType: SubmissionPortalDataTypeVM[];
    group: GroupView;
}

const Group = ({ disabled, group, helpForType, formCtrl }: GroupProps) => {
    const help = useMemo(() => {
        const allHelp = helpForType.map(rtd => rtd.productGroupData).flat();

        return allHelp.find(h => h?.referenceCode === group.productGroup.referenceCode)?.content;
    }, [group.productGroup.referenceCode, helpForType]);

    const activeColumns = useMemo(() => {
        return getActiveColumns(group);
    }, [group]);

    const hasExpiredData = useMemo(() => {
        return group.products.some(p => p.productStatus === 'expired_with_data');
    }, [group])

    return (
        <BoxedDiv box={{ overflowX: 'auto', marginV: 4 }}>
            <BoxedDiv box={{ marginBottom: 2 }}>
                <BoxedSpan box={{ alignItems: 'center', flex: 'row', }}>
                    <H3>{group.productGroup.name}</H3>
                    {
                        help &&
                        <TooltipHelp Help={<ReactMarkdown>{help}</ReactMarkdown>} />
                    }
                </BoxedSpan>
            </BoxedDiv>

            <Table caption={`Your refinery data for ${group.productGroup.name}`}>
                {/* Column headers */}
                <thead>
                    <TR>
                        <ColumnHeader label='Product' />

                        {
                            activeColumns.density &&

                            <ColumnHeader
                                $align='center'
                                fixedWidth='120px'
                                label={DENSITY_ROW_HEADING}
                                helpId='density_help'
                                Help={
                                    <BoxedDiv box={{ flex: 'column' }}>
                                        <p>
                                            Report the weighted average density in kilograms per litre (Kg/L) for each product during the month.
                                            {' '}To convert API to Kg/L: Density (Kg/L) = 141.5 ÷ (131.5 + API)
                                        </p>

                                        <p>
                                            If the density for each product is not readily available each month, the most recent density measurement should be used.
                                            {' '}If no measurement is available, a typical or average value may be entered, which should be reviewed and updated as appropriate.
                                        </p>
                                    </BoxedDiv>
                                }
                            />
                        }

                        <ColumnHeader
                            $align='center'
                            fixedWidth='120px'
                            label={OPENING_STOCK_ROW_HEADING}
                            helpId='opening_stocks_help'
                            Help={
                                <BoxedDiv box={{ flex: 'column' }}>
                                    <p>
                                        The actual physical amount held in storage in the refinery at the beginning of the month. For reporting purposes,
                                        {' '}the opening stock of the current month should be identical to the closing stock of the previous month.
                                    </p>

                                    <p>
                                        <strong>Include</strong> stocks of products that will be used as refinery fuel.
                                    </p>
                                </BoxedDiv>
                            }
                        />

                        {
                            activeColumns.totalReceipts &&

                            <ColumnHeader
                                $align='center'
                                fixedWidth='120px'
                                label={TOTAL_RECEIPTS_ROW_HEADING}
                                helpId='receipts_help'
                                Help={
                                    <BoxedDiv box={{ flex: 'column' }}>
                                        <p>The total weight of each product that was delivered to the refinery during the month.</p>

                                        <p>
                                            <strong>Include</strong> any <U>finished</U> products delivered to the refinery for blending or commingling with refinery-produced product. If the product will be blended into multiple different products (sub-categories), please apportion the amount accordingly.
                                        </p>

                                        <p>
                                            <strong>Include</strong> any product receipts that will be exclusively used as fuel, e.g. methane bought in as fuel (and report the amount used under 'Consumed as fuel').
                                        </p>

                                        <p>
                                            <strong>Exclude</strong> any blending products that are not finished products (report these in the “Refinery input” page under the appropriate blending component category).
                                        </p>
                                    </BoxedDiv>

                                }
                            />
                        }

                        {
                            activeColumns.refineryInputs &&
                            <ColumnHeader
                                $align='center'
                                fixedWidth='120px'
                                label={REFINERY_INPUTS_ROW_HEADING}
                                helpId='inputs_help'
                                Help={
                                    <BoxedDiv box={{ flex: 'column' }}>
                                        <p>The total weight of each product that entered the refining process for the first time.</p>

                                        <p>
                                            <strong>Do not</strong> include in this category any amounts that were consumed as fuel.
                                        </p>
                                        <p>
                                            <strong>N.B.</strong> There is no 'Refinery process inputs' column in the 'Refinery output' page.
                                            {' '}Any finished products delivered for blending or commingling should be reported under 'Total receipts'
                                            {' '}with the amount accounted for in either 'Production' or as an increase in month-end stocks
                                        </p>
                                    </BoxedDiv>
                                }
                            />
                        }

                        {
                            activeColumns.calculatedInput &&
                            <ColumnHeader
                                $align='center'
                                fixedWidth='120px'
                                label={REFINERY_INPUTS_ROW_HEADING}
                                Help={
                                    <BoxedDiv box={{ flex: 'column' }}>
                                        <p>Refinery process inputs for working stock is calculated from the ‘Opening stocks’ – ‘Closing stocks’ values entered. </p>
                                    </BoxedDiv>
                                }
                            />
                        }

                        {
                            activeColumns.production &&
                            <ColumnHeader
                                $align='center'
                                fixedWidth='120px'
                                label={PRODUCTION_ROW_HEADING}
                                helpId='production_help'
                                Help={
                                    <BoxedDiv box={{ flex: 'column' }}>
                                        <p>
                                            The total weight of each finished product that was produced by the refinery for sale, export, or
                                            {' '}fuel used to power refinery processes.
                                        </p>

                                        <p>
                                            <strong>N.B.</strong> There is no 'Production' column in the “Refinery Input” page. Any blendstocks
                                            {' '}that are manufactured in the refinery for blending, sale or export elsewhere should be included
                                            {' '}in the “Refinery Output” page as production of the product into which they are most likely to be blended.
                                        </p>

                                        <p>
                                            If you are unsure which category to report blendstock production under, or need to report production of any
                                            {' '}other products in the “Refinery Input” tab please contact the Department to discuss.
                                        </p>
                                    </BoxedDiv>
                                }
                            />
                        }

                        {
                            activeColumns.consumed &&
                            <ColumnHeader
                                $align='center'
                                fixedWidth='120px'
                                label={CONSUMED_ROW_HEADING}
                                helpId='consumed_help'
                                Help={
                                    <BoxedDiv box={{ flex: 'column' }}>
                                        <p>
                                            Any products produced at the refinery that were subsequently consumed as fuel should be included
                                            {' '}in both 'Production' and 'Consumed as fuel'.
                                        </p>

                                        {
                                            group.productGroup.name === 'Gases' ?

                                                <>
                                                    <p>
                                                        E.g. if 1,000 tonnes of refinery gas is produced and all of it is consumed as fuel, then 1,000 should
                                                        {' '}be entered into both ‘Production’ and ‘Consumed as fuel’ against ‘Refinery gas’. If 400 tonnes was
                                                        {' '}consumed as fuel and 600 tonnes sent to a petrochemical plant then 400 should be entered in both
                                                        {' '}‘Production’ and ‘Consumed as fuel’ for ‘Refinery gas’ in the ‘Gases’ section and 600 should be
                                                        {' '}entered as ‘Production’ for ‘Other’ in the ‘Petrochemical feedstocks’ section.
                                                    </p>

                                                    <p>
                                                        <strong>Exclude</strong> any product that was bought in solely for use as a refinery fuel.
                                                    </p>
                                                </> :

                                                <p>
                                                    E.g. if 500 tonnes of petcoke is produced and all of it is consumed as fuel, then 500 should be
                                                    {' '}entered into both 'Production' and 'Consumed as fuel'. If 300 tonnes was consumed as fuel
                                                    {' '}and 200 tonnes sold then 500 should be entered in 'Production' and 300 in 'Consumed as fuel'.
                                                </p>
                                        }
                                    </BoxedDiv>
                                }
                            />
                        }

                        <ColumnHeader
                            $align='center'
                            fixedWidth='120px'
                            label={CLOSING_STOCKS_ROW_HEADING}
                            helpId='closing_stocks_help'
                            Help={
                                <BoxedDiv box={{ flex: 'column' }}>
                                    <p>
                                        The actual physical amount held in storage in the refinery at the end of the month.
                                    </p>

                                    <p>
                                        <strong>Include</strong> stocks of products that will be used as refinery fuel.
                                    </p>
                                </BoxedDiv>

                            }
                        />

                        {
                            (hasExpiredData && !disabled) ?
                            <DeleteActionCellTH>
                                <VisuallyHidden>Actions</VisuallyHidden>
                            </DeleteActionCellTH> :
                            null
                        }
                    </TR>
                </thead>

                <tbody>
                    {
                        group.products
                            .filter(p => p.productStatus !== 'expired')
                            .map(product => (
                                <Product
                                    key={product.id}
                                    activeColumns={activeColumns}
                                    disabled={disabled}
                                    formCtrl={formCtrl}
                                    helpForType={helpForType}
                                    product={product}
                                />
                            ))
                    }

                </tbody>

                <tfoot>
                    {/* Totals row */}
                    {
                        group.products.length > 1 &&
                        <ProductTotalsRow activeColumns={activeColumns} group={group} />
                    }
                </tfoot>
            </Table>
        </BoxedDiv>
    )
}

interface ProductValueInputCellProps<TField extends RefiningField> {
    disabled: boolean;
    product: ProductView;
    field: TField;
    formCtrl: UseRefineryForm;
    helpId?: string;
}

const FIELD_LABEL_MAP: { [key in RefiningField]: string } = {
    closingStocks: 'closing stocks',
    consumed: 'consumed as fuel',
    density: 'average density',
    openingStocks: 'opening stocks',
    production: 'production',
    refineryInputs: 'refinery process inputs',
    totalReceipts: 'total receipts',
} as const;

const ProductValueInputCell = <TField extends RefiningField>({ disabled, field, formCtrl, helpId, product }: ProductValueInputCellProps<TField>) => {
    const error = product.validationErrors.find(v => v.tooltip.target === field);
    const info = product.infoMessages.find(i => i.tooltip.target === field);

    const decimalPlaces = field === 'density' ? 10 : undefined;

    const hasProducts = formCtrl.view[product.group.refineryTypeName].groups.length > 0 && formCtrl.view[product.group.refineryTypeName].groups[0].products.length > 0;

    const shouldAutoFocus = useIsAutoFocused(product.group.displayOrder === 1 && hasProducts && product.id === formCtrl.view[product.group.refineryTypeName].groups[0].products[0].id && field === 'density');

    const isFocused = useMemo(() => {
        return shouldAutoFocus || (
            formCtrl.view.focusedField != null &&
            isFocusFieldRefining(formCtrl.view.focusedField) &&
            formCtrl.view.focusedField.field === field &&
            formCtrl.view.focusedField.productId === product.id
        );
    }, [field, formCtrl.view.focusedField, product.id, shouldAutoFocus]);

    return (
        <CellInput
            aria-describedby={helpId}
            decimalPlaces={decimalPlaces}
            disabled={disabled}
            error={error?.tooltip.content}
            forceError={formCtrl.view.shouldForceErrors}
            info={info?.tooltip.content}
            shouldFocus={isFocused}
            value={product.data ? product.data[field] : undefined}
            onChange={val => formCtrl.updateRefining(product.id, field, val)}
            label={`${decimalPlaces == null ? 'Integer' : 'Decimal'} value for ${FIELD_LABEL_MAP[field]}`}
        />
    )
}

interface ProductProps {
    activeColumns: ActiveColumns;
    disabled: boolean;
    formCtrl: UseRefineryForm;
    helpForType: SubmissionPortalDataTypeVM[];
    product: ProductView;
}

const Product = ({ activeColumns, disabled, helpForType, formCtrl, product }: ProductProps) => {
    const [deleteRef, setDeleteRef] = useState<HTMLElement | null>(null);
    const help = useMemo(() => {
        const allHelp = helpForType.map(rtd => rtd.productData).flat();

        return allHelp.find(h => h?.referenceCode === product?.referenceCode)?.content;
    }, [helpForType, product?.referenceCode]);

    const hasExpiredData = product.productStatus === 'expired_with_data';

    const showExpiredDataControls = hasExpiredData && !disabled;

    return (
        <TR>
            <TH scope='row'>
                <BoxedSpan box={{ alignItems: 'center', flex: 'row', noWrap: true}}>
                    <Text title={product.productName}>{product.productName}</Text>
                    {
                        showExpiredDataControls ? <BoxedSpan box={{ marginBottom: -0.5, marginLeft: 1 }}>
                            <FloatingMessage
                                content={EXPIRED_OR_INTERNAL_PRODUCT}
                                kind="warning"
                                role="alert"
                            />
                        </BoxedSpan> : (
                            help && <>
                                <TooltipHelp
                                    Help={<ReactMarkdown>{help}</ReactMarkdown>}
                                />
                            </>
                        )
                    }
                </BoxedSpan>
            </TH>

            {
                activeColumns.density &&
                <ProductValueInputCell field="density" disabled={disabled} formCtrl={formCtrl} helpId='density_help' product={product} />
            }

            <ProductValueInputCell field="openingStocks" disabled={disabled} formCtrl={formCtrl} helpId='opening_stocks_help' product={product} />

            {
                activeColumns.totalReceipts &&
                <ProductValueInputCell field="totalReceipts" disabled={disabled} formCtrl={formCtrl} helpId='receipts_help' product={product} />
            }

            {
                activeColumns.refineryInputs &&
                <ProductValueInputCell field="refineryInputs" disabled={disabled} formCtrl={formCtrl} helpId='inputs_help' product={product} />
            }

            {
                activeColumns.production &&
                <ProductValueInputCell field="production" disabled={disabled} formCtrl={formCtrl} helpId='production_help' product={product} />
            }

            {
                activeColumns.consumed &&
                <ProductValueInputCell field="consumed" disabled={disabled} formCtrl={formCtrl} helpId='consumed_help' product={product} />
            }

            {
                activeColumns.calculatedInput &&
                <CalculatedCell value={product.calculatedInput} />
            }
            <ProductValueInputCell field="closingStocks" disabled={disabled} formCtrl={formCtrl} helpId='closing_stocks_help' product={product} />

            {
                showExpiredDataControls ?
                <DeleteActionCellTD>
                        <BoxedDiv box={{ alignItems: 'center', flex: 'row', marginLeft: 1 }}>
                            <DeleteRowButton
                                focusedField={formCtrl.view.focusedField}
                                onClick={() => formCtrl.deleteRefiningRequest(product.id, deleteRef)}
                                productId={product.id}
                                ref={setDeleteRef}
                            />
                        </BoxedDiv>
                </DeleteActionCellTD> :
                null
            }
        </TR>
    )
}

interface ProductTotalCellProps {
    fixed?: number;
    total?: number;
}

const ProductTotalCell = ({ fixed, total }: ProductTotalCellProps) => {
    return (
        <TD $align="right">
            <BoxedDiv box={{ alignItems: 'center', flex: 'row', flexGrow: 1, marginRight: 1.5 }}>
                <Text weight="bold" fullWidth={true}>{localeNumberWithFixed(total, fixed)}</Text>
            </BoxedDiv>
        </TD>
    )
}

interface CalculatedCellProps {
    value?: number;
}

const CalculatedCell = ({ value }: CalculatedCellProps) => {
    return (
        <TD $align="right" isReadonly={true}>
            <BoxedDiv box={{ alignItems: 'center', flex: 'row', flexGrow: 1, marginRight: 1.5 }}>
                <Text fullWidth={true}>{localeNumberWithFixed(value)}</Text>
            </BoxedDiv>
        </TD>
    )
}

interface ProductTotalsRowProps {
    activeColumns: ActiveColumns;
    group: GroupView;
}

const ProductTotalsRow = ({ activeColumns, group }: ProductTotalsRowProps) => {
    if (group.productGroup.name.toLowerCase().indexOf('working') > -1)
        return null;
    return (
        <TR>
            <TH $align='right' scope='row'><Text weight='bold'>Totals</Text></TH>
            {activeColumns.density && <ProductTotalCell fixed={10} total={group.totals.density} />}
            <ProductTotalCell total={group.totals.openingStocks} />
            {activeColumns.totalReceipts && <ProductTotalCell total={group.totals.totalReceipts} />}
            {activeColumns.refineryInputs && <ProductTotalCell total={group.totals.refineryInputs} />}
            {activeColumns.production && <ProductTotalCell total={group.totals.production} />}
            {activeColumns.consumed && <ProductTotalCell total={group.totals.consumed} />}
            {activeColumns.calculatedInput}
            <ProductTotalCell total={group.totals.closingStocks} />
        </TR>
    )
}


export default RefineryTypeEditor;

// Helpers
const DYNAMIC_COLUMNS = ['density', 'totalReceipts', 'refineryInputs', 'production', 'consumed', 'calculatedInput'] as const;

type DynamicColumn = ArrayElement<typeof DYNAMIC_COLUMNS>;

type ActiveColumns = {
    [key in DynamicColumn]: boolean;
}

function getActiveColumns(group: GroupView): ActiveColumns {
    const { tableKind } = group;
    return {
        consumed: hasConsumed(tableKind),
        density: hasDensity(tableKind),
        production: hasProduction(tableKind),
        refineryInputs: hasRefineryInputs(tableKind),
        totalReceipts: hasTotalReceipts(tableKind),
        calculatedInput: hasCalculatedInput(tableKind)
    }
}

type FocusField = UseRefineryForm['view']['focusedField'];

interface DeleteRowProps {
    focusedField: FocusField | null;
    onClick: () => any;
    productId: number;
}

const DeleteRowButton = React.forwardRef(({focusedField, onClick, productId}: DeleteRowProps, ref) => {
    const focusable = useFocusable({
        setFocused: (
            isFocusFieldDeleteRefining(focusedField) &&
            focusedField.productId === productId
        ),
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <IconButton
            color='dark-grey'
            icon='trash'
            label='Delete row for product'
            onClick={onClick}
            ref={setRef}
            size='sm'
        />
    );
});

export function isFocusFieldDeleteRefining(maybe?: unknown): maybe is FocusFieldDeleteRefining {
    const maybeAs = maybe as FocusFieldDeleteRefining;

    return (
        maybeAs != null &&
        maybeAs.field === 'delete' &&
        maybeAs.productId != null
    );
}

