import { INFO_MSO_NOT_MET } from "psims/constants/info-messages";
import { INVALID_MSO_NOT_MET_REQUIRES_COMMENTS } from "psims/constants/validation-messages";
import { useMemo } from "react";
import { attemptScrollToSelector } from "psims/react/pages/primary-pages/data-submissions/shared/view-utils";
import NotificationMessage from "../../shared/notification-message";
import { UseMso } from "../shared/use-mso";
import { UseMsoImporterFocus } from "./use-mso-importer";
import { UseMsoImporterProgress } from "./use-mso-importer-progress";

interface UseMsoImporterValidationAlertsProps {
    focusFieldCtrl: UseMsoImporterFocus;
    msoCtrl: UseMso;
    progressCtrl: UseMsoImporterProgress;
}

function useMsoImporterValidationAlerts({focusFieldCtrl, msoCtrl, progressCtrl}: UseMsoImporterValidationAlertsProps) {
    const validationAlerts = useMemo(() => {
        if (progressCtrl.currentStep.kind === 'data') {
            return [...msoCtrl.msos]
                .filter(mso => mso.mso.difference < 0)
                .sort((a, b) => a.product.displayOrder - b.product.displayOrder)
                .map(mso => ({
                    tooltip: {
                        message: INFO_MSO_NOT_MET,
                        target: {entity: 'mso', field: 'difference', msoProductId: mso.product?.id},
                    },
                    notification: {
                        content: NotificationMessage({parts: [
                            'For ',
                            {label: 'View MSO not met difference', onClick: () => attemptScrollToSelector('[data-mso-summary]'), text: mso.product?.name},
                            `: ${INVALID_MSO_NOT_MET_REQUIRES_COMMENTS} `,
                            {label: `Go to comments field`, onClick: () => focusFieldCtrl.setFocusedField({kind: 'comments'}), text: 'Comments field'},
                            '.'
                        ]})
                    }
                }))
        }

        return [];
    }, [focusFieldCtrl, msoCtrl.msos, progressCtrl.currentStep]);

    return validationAlerts;
}

export default useMsoImporterValidationAlerts

export type UseMsoImporterValidationAlerts = ReturnType<typeof useMsoImporterValidationAlerts>;
