import { useMemo } from "react";
import { StockTypeName } from "psims/models/ref-data/stock-type";
import { useReferenceData } from "psims/react/providers/api/reference-data";

/**
 * Provides a stock product type by name
 */

interface UseStockTypeProps {
    stockTypeName: StockTypeName;
}

function useStockType({stockTypeName}: UseStockTypeProps) {
    const {data: refData} = useReferenceData();

    return useMemo(() => {
        if (refData) {
            return refData.stockTypes.find(st => st.name === stockTypeName && st.isActive);
        }
    }, [stockTypeName, refData]);
}

export default useStockType;
