import { MsoAnnualActivityRefinerSubmissionVM } from "psims/gen/xapi-client";
import { prefixedThrower } from "psims/lib/exception";
import { isDataSubmission, isSubmittedDataSubmission, PopulatedDataSubmission } from "psims/models/data-submission";
import { isRecordResult, RecordResult } from "psims/models/api/record-result";
import { assertMsoAnnualActivitySubmissionFormData } from "../mso-annual-activity-submission-form-data";
import { assertMsoStorageSite, MsoStorageSite } from "../mso-storage-site";
import { assertMsoAnnualActivityRefiner, MsoAnnualActivityRefiner } from "./mso-annual-activity-refiner";

export type MsoAnnualActivityRefinerSubmission = PopulatedProps<
  MsoAnnualActivityRefinerSubmissionVM,
  'annualActivities' | 'dataSubmission' | 'storageSites' | 'submissionFormData'
> & {
  annualActivities: Array<MsoAnnualActivityRefiner>;
  dataSubmission: PopulatedDataSubmission<'MSO refiner'>;
  storageSites: Array<MsoStorageSite>;
};


export function assertMsoAnnualActivityRefinerSubmission(maybe?: unknown): asserts maybe is MsoAnnualActivityRefinerSubmission {
  const maybeAs = maybe as MsoAnnualActivityRefinerSubmission;

  const assertionThrower = prefixedThrower('Failed to assert msoAnnualActivityRefinerSubmission: ');

  if (maybeAs == null) {
    assertionThrower('argument is null');
  }

  if (!isDataSubmission(maybeAs.dataSubmission, 'MSO refiner')) {
    assertionThrower('dataSubmission assertion failed');
  }

  try {
    assertMsoAnnualActivitySubmissionFormData(maybeAs.submissionFormData);
  } catch (e) {
    assertionThrower(`submissionFormData assertion failed: ${e}`);
  }

  if (!isDataSubmission(maybeAs.dataSubmission, 'MSO refiner')) {
    assertionThrower('dataSubmission assertion failed');
  }

  if (maybeAs.annualActivities == null) {
    assertionThrower('annualActivities is null');
  }

  maybeAs.annualActivities.forEach(assertMsoAnnualActivityRefiner);

  if (maybeAs.storageSites == null) {
    assertionThrower('storageSites is null');
  }

  maybeAs.storageSites.forEach(assertMsoStorageSite);
}

export function isMsoAnnualActivityRefinerSubmission(maybe?: unknown): maybe is MsoAnnualActivityRefinerSubmission {
  try {
    assertMsoAnnualActivityRefinerSubmission(maybe);
    return true
  } catch (e) {
    return false;
  }
}

export function assertMsoAnnualActivityRefinerSubmitted(maybe?: unknown): asserts maybe is MsoAnnualActivityRefinerSubmission {
  assertMsoAnnualActivityRefinerSubmission(maybe);
  if (!isSubmittedDataSubmission(maybe.dataSubmission)) {
    throw new Error(`Failed to assert isSubmitted for dataSubmission: ${JSON.stringify(maybe.dataSubmission)}`)
  }
}

export function isMsoAnnualActivityRefinerSubmitted(maybe?: unknown): maybe is MsoAnnualActivityRefinerSubmission {
  try {
    assertMsoAnnualActivityRefinerSubmitted(maybe);
    return true;
  } catch (e) {
    return false;
  }
}

export function getRecordResults(maybe?: unknown): Array<RecordResult> {
  assertMsoAnnualActivityRefinerSubmission(maybe);

    return [
        ...[maybe.dataSubmission?.recordResult].filter(isRecordResult),
        ...[maybe.msoComment?.recordResult].filter(isRecordResult),
        ...(maybe.annualActivities || []).map(x => x.recordResult).filter(isRecordResult),
        ...(maybe.storageSites || []).map(s => s.recordResult).filter(isRecordResult),
    ];
}
