import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPI } from "psims/react/providers/api";
import { useLogger } from "psims/react/providers/logging";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { Status } from "psims/types/async";
import { FsspCommitmentItem, mapApiResponseToFsspCommitmentItems } from "psims/models/fssp-commitment";

function useFsspCommitmentAPI() {
    const {api} = useAPI();
    const logger = useLogger({source: 'useFsspCommitmentAPI'});
    const [fsspCommitmentLoadStatus, setFsspCommitmentLoadStatus] = useState<Status>('init');
    const loggerRef = useUpdatedRef(logger);
    const [fsspCommitments, setFsspCommitments] = useState<Array<FsspCommitmentItem>> ([]);

    const fetch = useCallback(() => {

        setFsspCommitmentLoadStatus('loading');

        api.getFsspCommitments()
        .then(response => {
            if (response !== null && response.isSuccessful && response.result != null) {
                setFsspCommitments(mapApiResponseToFsspCommitmentItems(response.result));

                setFsspCommitmentLoadStatus('fulfilled');
            } else {
                setFsspCommitmentLoadStatus('error');
            }
        })
        .catch(() => {
            loggerRef.current.warn('Failed to fetch Fssp Commitments');
            setFsspCommitmentLoadStatus('error');
        });

    }, [api, loggerRef]);

    const loadStatus = useMemo(() => {
        if(fsspCommitmentLoadStatus === 'error'){
            return 'error';
        }
        if(fsspCommitmentLoadStatus === 'fulfilled'){
            return 'fulfilled';
        }

        return 'loading';

    }, [fsspCommitmentLoadStatus]);

    // Fetch FSSP Commitment on load
    useEffect(() => {
        fetch();
    }, [fetch]);

    return {
        loadStatus,
        fsspCommitments
    }
}

export default useFsspCommitmentAPI;