import React from 'react';
import { Link } from 'react-router-dom';

import Page from 'psims/react/blocks/page';
import { H2 } from 'psims/react/components/typography';
import { BoxedDiv } from '../components/layout';

interface NotFoundPageProps {}

const NotFoundPage = (props: NotFoundPageProps) => {
    return (
        <Page>
            <BoxedDiv box={{}} className='container'>
                <H2>Page not found</H2>

                <p>We couldn't find the page you are searching for.</p>

                <BoxedDiv box={{marginTop: 2}}>
                    <Link to='/'>Back to home page</Link>
                </BoxedDiv>
            </BoxedDiv>
        </Page>
    )
}

export default NotFoundPage;
