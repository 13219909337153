/** Standardised wrapper component for input controls */
import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { SizeProps, sizeStyle } from 'psims/style/size';
import { BoxedSpan } from './layout';
import FloatingMessage from './floating-message';

interface UserInputBoxProps extends SizeProps {
    borderless?: boolean;
    error?: string;
    floatingId?: string;
    focusless?: boolean;
    info?: string;
    isFocused: boolean;
    shadow?: 'inset' | 'outset';
}

const StyledUserInputBox = styled.label`${(props: UserInputBoxProps) => `
    ${css(sizeStyle(props))}

    align-items: center;
    ${!props.borderless ? `
    border: 1px solid var(--color-field-border);
    ` : ''}
    display: flex;
    flex: 1;
    padding: 4px 8px 4px 4px;
    transition: box-shadow 200ms ease-in-out;

    ${!props.focusless ? `
    &:focus-within {
        ${!props.borderless ? `
        border-color: var(--color-primary-interactive);
        ` : ''}
        box-shadow: var(--box-shadow-focus${props.shadow === 'inset' ? '-inset' : ''});
    }
    ` : ''}

    ${(Boolean(props.error) && !props.focusless) ? `
    &:not(:focus-within) {
        ${!props.borderless ? `
        border-color: var(--color-negative-100);
        ` : ''}
        box-shadow: var(--box-shadow-error${props.shadow === 'inset' ? '-inset' : ''});
    }
    `:''}
`}`;

const UserInputBox = ({children, floatingId, ...rest}: PropsWithChildren<UserInputBoxProps>) => {
    const {error, info, isFocused} = rest;
    
    const renderedInput = <StyledUserInputBox {...rest} >
        <BoxedSpan box={{flexGrow: 1, marginRight: 0.5}}>
            {children}
        </BoxedSpan>

        {
            error ? 

            <BoxedSpan box={{marginLeft: 1}}>
                <FloatingMessage
                    content={error}
                    id={floatingId}
                    kind="warning"
                    role='alert'
                    showContent={isFocused}
                />
            </BoxedSpan>:

            info ? <BoxedSpan box={{marginLeft: 1}}>
                <FloatingMessage
                    content={info}
                    id={floatingId}
                    kind="info"
                    role='alert'
                    showContent={isFocused}
                />
            </BoxedSpan>:

            null
        }

    </StyledUserInputBox>

    return renderedInput;
}

export default UserInputBox;
