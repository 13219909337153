/* Lifted from: https://code-examples.net/en/q/6dfce1 */
function getNextFocusableElement(target: HTMLElement | null) {
    if (target == null) {
        return null;
    }

    const queryString = [
      'a:not([disabled]):not([tabindex="-1"])',
      'button:not([disabled]):not([tabindex="-1"])',
      'input:not([disabled]):not([tabindex="-1"])',
      'select:not([disabled]):not([tabindex="-1"])',
      '[tabindex]:not([disabled]):not([tabindex="-1"])'
    ].join(',');

    const queryResult: Array<HTMLElement> = Array.prototype.filter.call(document.querySelectorAll(queryString), (elem: HTMLElement) => {
      /*check for visibility while always include the current activeElement*/
      return elem.offsetWidth > 0 || elem.offsetHeight > 0 || elem === target;
    });

    const indexedList: Array<HTMLElement> = queryResult.slice().filter(elem => {
      /* filter out all indexes not greater than 0 */
      return elem.tabIndex === 0 || elem.tabIndex === -1 ? false : true;
    }).sort((a, b) => {
      /* sort the array by index from smallest to largest */
      return a.tabIndex !== 0 && b.tabIndex !== 0 
        ? (a.tabIndex < b.tabIndex ? -1 : b.tabIndex < a.tabIndex ? 1 : 0) 
        : a.tabIndex !== 0 ? -1 : b.tabIndex !== 0 ? 1 : 0;
    });

    const focusable = [
        ...indexedList,
        ...queryResult.filter(elem => {
            /* filter out all indexes above 0 */
            return elem.tabIndex === 0 || elem.tabIndex === -1 ? true : false;
        })
    ];

  /* if reverse is true return the previous focusable element
     if reverse is false return the next focusable element */
  return (focusable[focusable.indexOf(target) + 1] || focusable[0]);
}

export function focusNext(source: HTMLElement | null) {
  const target = getNextFocusableElement(source);

    if (target == null) {
        return;
    }

    setTimeout(() => {
        target.focus();
    }, 0);
}