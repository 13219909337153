import { useState } from "react";

import { FsspCommitmentItem } from "psims/models/fssp-commitment";

function useFsspCessations(){
    const [selectedFsspCommitment, setSelectedFsspCommitment] = useState<FsspCommitmentItem | null | undefined>(null);

    function selectFsspCommitment(fsspCommitment: FsspCommitmentItem){
        setSelectedFsspCommitment(fsspCommitment);
    }

    function cancel() {
        setSelectedFsspCommitment(null);
    }

    return {
        selectedFsspCommitment,
        selectFsspCommitment,
        cancel
    }
}

export default useFsspCessations;

export type UseFsspCessations = ReturnType<typeof useFsspCessations>;
