import React from 'react';
import { Redirect } from 'react-router';

import CompanyHeader from 'psims/react/blocks/company-header';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { useUser } from 'psims/react/providers/user';
import { ReportingObligationView, useReportingObligations } from 'psims/react/providers/api/reporting-obligations';
import { ReportingObligation } from 'psims/models/reporting-obligation';
import { Status } from 'psims/types/async';
import { Table, THead, TR, TH, TBody, TD } from 'psims/react/components/table';
import { useNavigation } from 'psims/react/providers/router';
import Loading from 'psims/react/components/loading';
import Text from 'psims/react/components/text';
import { TooltipHelp } from 'psims/react/pages/primary-pages/data-submissions/shared/tooltip-help';
import { H1 } from 'psims/react/components/typography';

interface ReportingObligationsProps {}

const ReportingObligations = (props: ReportingObligationsProps) => {
	const vm = useVM(props);

	if (vm.user?.roleType !== 'Company Admin') {
		return <Redirect to='/' />;
	}

	return (
		<BoxedDiv box={{alignSelf: 'stretch', flex: 'column', flexGrow: 1, justifySelf: 'center'}}>
			<CompanyHeader />

			<div className='container'>
				<H1>Reporting obligations</H1>
				
				<p>This details the data submissions your company is currently obligated to report.  Please contact the department if you believe any of the details are incorrect.</p>

				{
					vm.tableStatus === 'ready' &&

					<BoxedDiv box={{alignItems: 'flex-end', flex: 'column'}}>
						<BoxedDiv box={{alignSelf: 'stretch', marginTop: 2.5}}>
							<Table caption='Reporting obligations for your connected company(s)' fullWidth={true} stickyHeader={true}>
								<THead>
									<TR>
										<TH scope='col'>Data submission</TH>
										<TH scope='col'>Required to report</TH>
										<TH $align='center' scope='col'>
											<BoxedSpan box={{alignItems: 'center', flex: 'row'}}>
												Reported by
												<TooltipHelp
													Help={'This is the company (which may be a subsidiary) which collects the data on behalf of the head company.'}
												/>
											</BoxedSpan>
										</TH>
										<TH scope='col'>Frequency</TH>
									</TR>
								</THead>

								<TBody>
									{
										vm.reportingObligations !== null && vm.reportingObligations.map(ro => <ReportingObligationRow
											key={ro.id}
											reportingObligation={ro}
										/>)
									}
								</TBody>
							</Table>
						</BoxedDiv>
					</BoxedDiv>
				}

                {
                    vm.tableStatus === 'loading' &&

					<BoxedDiv box={{alignItems: 'center', flex: 'column', marginV: 6}}>
						<Loading>
							<Text>Loading reporting obligation details...</Text>
						</Loading>
					</BoxedDiv>
                }
            </div>
        </BoxedDiv>
    );
}

interface ReportingObligationRowProps {
	reportingObligation: ReportingObligation;
}

const ReportingObligationRow = ({reportingObligation}: ReportingObligationRowProps) => {
	return (
		<TR key={reportingObligation.id}>
			<TH scope='row'><strong>{reportingObligation.submissionTypeName}</strong></TH>
			<TD>{getRequiredText(reportingObligation)}</TD>
			<TD>{reportingObligation.reportingOrganisationName}</TD>
			<TD>{reportingObligation.reportFrequencyTypeName}</TD>
		</TR>
	);
}

type TableStatus = 'error' | 'loading' | 'ready';

function useVM(props: ReportingObligationsProps) {
    const nav = useNavigation();
    const { user } = useUser();
	const { reportingObligations, status: reportingObligationsStatus } = useReportingObligations();

    const tableStatus = getTableStatus(reportingObligations, reportingObligationsStatus);

    function onModify(reportingObligation: ReportingObligationView) {
		nav.goToRequestReportingObligationsModify(reportingObligation.id!);
    }

    return {
        onModify,
        reportingObligations,
        tableStatus,
        user,
    };
}

export default ReportingObligations;

function getTableStatus(reportingObligations: Array<ReportingObligation> | null, reportingObligationsStatus: Status): TableStatus {
	if (reportingObligations !== null) {
		return 'ready';
	}

	if (reportingObligationsStatus === 'error') {
		return 'error';
	}

	return 'loading';
}

function getRequiredText(reportingObligation: ReportingObligation) {
    const suffix = reportingObligation.status === 'Pending' ? ' requested' : '';

    return `${reportingObligation.isReportable === true ? 'Yes' : 'No'}${suffix}`;
}
