import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import { SPACE_BASE } from 'psims/constants/styles';
import { SpacingProps, spacingStyle } from 'psims/style/spacing';
import { FlexProps, flexStyle } from 'psims/style/flex';
import { SizeProps, sizeStyle } from 'psims/style/size';
import VisuallyHidden from './visually-hidden';

type StatusText = {
    label: string;
    kind: 'subtle' | 'warn';
}

export interface FormFieldProps extends SpacingProps, FlexProps, SizeProps {
    className?: string;
    error?: string | null;
    hideLabel?: boolean;
    id: string;
    label: string;
    labelComponent?: ReactNode;
    statusText?: StatusText;
}

const StyledLabel = styled.label`
    font-weight: 600;
    margin-bottom: ${SPACE_BASE}px;
`;

const StyledError = styled.span`
    color: var(--color-error-text);
    display: inline-block;
    flex: 1;
    font-size: 14px;
    font-weight: 600;
    height: 19px;
    margin: ${SPACE_BASE}px 0;
`;

const StyledExtra = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const StyledStatusText = styled.span`${(props: StatusText) => `
	font-size: 0.875rem;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
    padding-left: 1em;
	text-align: right;
	margin: ${SPACE_BASE}px 0;

    ${(props.kind === 'warn') ? `
		color: var(--color-error-text);` : ''}
`}`;


const StyledFormField = styled.div`${(props: Omit<FormFieldProps, 'label' | 'id'>) => `
    display: flex;
    flex-direction: column;
    margin-top: ${SPACE_BASE * 2}px;
`}
${spacingStyle}
${flexStyle}
${sizeStyle}`;

const LabelContainer = ({children, hideLabel}: PropsWithChildren<{hideLabel?: boolean}>) => {
    if (hideLabel) {
        return <VisuallyHidden>{children}</VisuallyHidden>
    }

    return <>{children}</>;
}

const FormField = ({children, error, hideLabel, id, label, ...rest}: PropsWithChildren<FormFieldProps>) => {
    return (
        <StyledFormField {...rest}>
            {
                <LabelContainer hideLabel={hideLabel}>

                    <StyledLabel htmlFor={id}>{label}</StyledLabel>
                </LabelContainer>
            }

            {children}

            <StyledExtra>
                <StyledError aria-live='polite' id={`${id}_error`} role='alert'>
                    {
                        Boolean(error) && error
                    }
                </StyledError>
                {
                    rest.statusText &&
                    <StyledStatusText {...rest.statusText}>{rest.statusText.label}</StyledStatusText>
                }
            </StyledExtra>
        </StyledFormField>
    )
}

export default FormField;
