export function asString(maybeString: unknown): string {
    if (maybeString === undefined || maybeString === null) {
        return ''
    }

    return String(maybeString);
}

export function toNumberOrUndefined(str: string): number | undefined {
    if (str.length === 0) {
        return undefined;
    }

    const asNumber = Number(str);
    if (isNaN(asNumber)) {
        throw new Error(`Failed to convert value to number: ${str}`);
    }

    return asNumber;
}

export function setCapitalisation<T>(val: T, direction: 'up' | 'down'): T {
    if (typeof val === 'string' && val.length > 0) {
        const [first, ...rest] = val.split('');
        return [first[direction === 'up' ? 'toLocaleUpperCase' : 'toLocaleLowerCase'](), ...rest].join('') as unknown as T;
    }

    return val;
}

export function isString(value: any): value is string {
    return typeof value === 'string';
}
