import React from 'react';
import styled from 'styled-components';
import Close from '../icons/close';
import FileDownload from '../icons/file-download';
import PlusCircle from '../icons/plus-circle';
import Trash from '../icons/trash';
import { Size } from '../icons/_base';
import { BoxedSpan } from '../layout';

import ButtonBase from './button';

// TODO: move below css to styled
import './button.css';

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'style'> {
	kind: 'primary' | 'ghost' | 'unstyled';
	minWidth?: string;
	size?: 'sm' | 'md';
	styles?: React.ButtonHTMLAttributes<HTMLButtonElement>['style'];
};

const Button = ButtonBase;

export default Button;

const ICON_MAP = {
	close: Close,
	fileDownload: FileDownload,
	trash: Trash,
};

interface IconButtonProps extends Omit<ButtonProps, 'kind' | 'size'> {
	color: 'negative' | 'primary' | 'dark-grey';
	icon: keyof typeof ICON_MAP;
	label: string;
	size?: Size;
}

const StyledIconButtonLabel = styled.span`
	height: 0;
	overflow: hidden;
	width: 0;
`;

export const IconButton = React.forwardRef((props: IconButtonProps, ref) => {
	const {color, icon, size =  'md', children, ...buttonProps} = props;

	const Icon = ICON_MAP[icon];

	const iconCN = `icon-button icon-button-color--${color}`;
	
	return (
		<Button $kind='unstyled' {...buttonProps} title={buttonProps.title || props.label} ref={ref}>
			<StyledIconButtonLabel>{props.label}</StyledIconButtonLabel>
			<Icon className={iconCN} size={size} />
		</Button>
	)
})

interface AddButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	label: string;
}

const StyledButton = styled.span`
	& button {
		color: var(--color-black-80);
		transition: color var(--transition-duration-fast) ease-in-out;

		&.unstyled-button {
			font-weight: 600;
		}

		&:hover {
			color: var(--color-primary);

			& .icon {
				color: var(--color-primary);
			}
		}
	}

	& .icon {
		color: var(--color-primary-mid);
		transition: color var(--transition-duration-fast) ease-in-out;
	}
`;

export const AddButton = (props: AddButtonProps) => {
	const {children, ...rest} = props;
	return <StyledButton>
		<Button $kind='unstyled' {...rest}>
			<BoxedSpan box={{flex: 'row'}}>
				<BoxedSpan box={{marginRight: 1}}>
					<PlusCircle size='md' />
				</BoxedSpan>
				{children}
			</BoxedSpan>
		</Button>
	</StyledButton>
}

