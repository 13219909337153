import styled from "styled-components";

import { UseFsspCessations } from "./use-fssp-cessations";
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { H2 } from 'psims/react/components/typography';
import { Table, TBody, TD, TH, THead, TR } from 'psims/react/components/table';
import { FsspTemporaryCessation } from "psims/models/fssp-commitment";
import Button from 'psims/react/components/button';
import Text from 'psims/react/components/text';
import { humanDate } from "psims/lib/formatters/datetime";
import Box from "psims/react/components/box";
import { PropertyLabel, PropertyRow, PropertyValue } from "psims/react/components/labeled-property";

interface FsspCessationsProps {
	ctrl: UseFsspCessations
}

const StyledTH = styled(TH)`
	white-space: nowrap;
`;

const CellScroller = styled(BoxedSpan)`
	display: inline-block;
	max-height: 40px;
	overflow: auto;
`;

const FsspCessations = (props: FsspCessationsProps) => {
	const vm = useVM(props);

	const fsspCessationsCaption = 'FSSP cessations';

	return (
		<BoxedDiv box={{ flex: 'column' }} id='fssp_cessations_content'>
			<H2>{fsspCessationsCaption}</H2>

			<Box alignItems='flex-start' flex='column' marginTop='md'>
				<PropertyRow>
					<PropertyLabel>Reporting entity</PropertyLabel>
					<PropertyValue>{vm.selectedFsspCommitment?.organisationName}</PropertyValue>
				</PropertyRow>

				<PropertyRow>
					<PropertyLabel>Commitment from date</PropertyLabel>
					<PropertyValue>
						{vm.selectedFsspCommitment != null ? humanDate(vm.selectedFsspCommitment.commitmentFrom) : ''}
					</PropertyValue>
				</PropertyRow>

				<PropertyRow>
					<PropertyLabel>Commitment to date</PropertyLabel>
					<PropertyValue>
						{vm.selectedFsspCommitment != null ? humanDate(vm.selectedFsspCommitment.commitmentTo) : ''}
					</PropertyValue>
				</PropertyRow>

				<PropertyRow>
					<PropertyLabel>Permanent cessation date</PropertyLabel>
					<PropertyValue>{
						vm.selectedFsspCommitment?.permenantCessationDate != null ?
						humanDate(vm.selectedFsspCommitment.permenantCessationDate) :
						''
					}</PropertyValue>
				</PropertyRow>
			</Box>

			<BoxedDiv box={{ flex: 'row' , marginV: 4 }}>
				{
					vm.selectedFsspCommitment?.fsspTemporaryCessations.length !== 0 &&
					<Table caption='FSSP Cessations' fullWidth={true}>
						<THead>
							<TR>
								<StyledTH>Cessation from date</StyledTH>
								<StyledTH>Cessation to date</StyledTH>
								<StyledTH>Cessation type</StyledTH>
							</TR>
						</THead>

						{<TBody>
							{
								(vm.selectedFsspCommitment != null && vm.selectedFsspCommitment.fsspTemporaryCessations != null ) ?
								vm.selectedFsspCommitment.fsspTemporaryCessations.map((fsspCessation, index) => (
									<FsspCessationRow
										key={index}
										fsspTemporaryCessation={fsspCessation}
									/>
								)) :
								null
							}
						</TBody>}
					</Table>
				}

				{
					vm.selectedFsspCommitment?.fsspTemporaryCessations.length === 0 &&
					<BoxedDiv
						box={{
							borderTop: { color: 'border-primary-faded', width: 4 },
							flex: 'row',
							flexGrow: 1,
							justifyContent: 'flex-start',
							marginTop: 4,
							paddingTop: 4,
						}}
					>
						<p>
							<Text weight='semibold'>
								{
									'There are no FSSP cessations.'
								}
							</Text>
						</p>
					</BoxedDiv>
				}
			</BoxedDiv>

			<BoxedDiv box={{ flex: 'row-reverse', justifyContent: 'flex-start', marginTop: 3.5 }}>
				<Button $kind='text' onClick={vm.cancel}>Close</Button>
			</BoxedDiv>
		</BoxedDiv>
	)
}

export default FsspCessations;

interface FsspCessationRowProps {
	fsspTemporaryCessation: FsspTemporaryCessation
}

const FsspCessationRow = ({
	fsspTemporaryCessation
}: FsspCessationRowProps) => {
	return (
		<TR>
			<TD>{fsspTemporaryCessation.cessationFrom ? humanDate(new Date(fsspTemporaryCessation.cessationFrom!)) : '---'}</TD>
			<TD>{fsspTemporaryCessation.cessationTo ? humanDate(new Date(fsspTemporaryCessation.cessationTo!)) : '---'}</TD>
			<TD>
				<CellScroller box={{}}>
					{fsspTemporaryCessation.cessationType}
				</CellScroller>
			</TD>
		</TR>
	);
}

function useVM(
	{
		ctrl: {
			cancel,
			selectedFsspCommitment
		}
	}: FsspCessationsProps
) {
	return {
		cancel,
		selectedFsspCommitment
	}
}