import React, { forwardRef, Ref } from 'react';
import styled from "styled-components";

import { Color, COLOR_MAP } from 'psims/constants/styles';
import { BorderProps, borderStyle } from 'psims/style/border';
import { FlexProps, flexStyle } from 'psims/style/flex';
import { SpacingProps, spacingStyle } from 'psims/style/spacing';
import { SizeProps, sizeStyle } from 'psims/style/size';
import { PositionProps, positionStyle } from 'psims/style/position';

export type BoxProps = BorderProps & FlexProps & PositionProps & SizeProps & SpacingProps & {
    background?: Color;
    color?: Color;
    noWrap?: boolean;
};

const boxStyle = ({box}: {box: BoxProps}) => ({
    ...borderStyle(box),
    ...flexStyle(box),
    ...sizeStyle(box),
    ...positionStyle(box),
    ...spacingStyle(box),
    ...(box.background ? {background: COLOR_MAP[box.background]} : {}),
    ...(box.color ? {color: COLOR_MAP[box.color]} : {}),
    ['boxSizing' as 'BoxSizing']: 'border-box',
    ...(box.noWrap ? {'white-space': 'nowrap'} : {}),
});

type BoxedDivProps = React.HTMLAttributes<HTMLDivElement> & {
    box: BoxProps;
    className?: string;
};

const StyledBoxDiv = styled.div(boxStyle);

export const BoxedDiv = forwardRef(({box, children, ...rest}: React.PropsWithChildren<BoxedDivProps>, ref) => {
    const {color, ...passedProps} = rest;

    return (
        <StyledBoxDiv box={box} {...passedProps} ref={ref as Ref<HTMLDivElement>}>{children}</StyledBoxDiv>
    )
});

type BoxedSpanProps = React.HTMLAttributes<HTMLSpanElement> & {
    box: BoxProps;
    className?: string;
};

const StyledBoxSpan = styled.span(boxStyle);

export const BoxedSpan = forwardRef(({box, children, ...rest}: React.PropsWithChildren<BoxedSpanProps>, ref) => {
    const {color, ...passedProps} = rest;

    return (
        <StyledBoxSpan box={box} {...passedProps} ref={ref as Ref<HTMLSpanElement>}>{children}</StyledBoxSpan>
    )
});
