import React, { useEffect, useMemo, useRef, useState } from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import PageNotifications from '../shared/page-notifications';
import { mapToUserMessage } from 'psims/models/api/submission/update/record-result';
import { ErrorUnsavedPage, InactiveProduct, InactiveProductionAreaPage } from '../shared/messages';
import { attemptScrollToSelector } from "psims/react/pages/primary-pages/data-submissions/shared/view-utils";
import { uniq } from 'psims/lib/collections';
import { SELECTOR_NOTIFICATIONS } from 'psims/constants/selectors';
import { UseProductionForm } from '../productions-shared/use-production-form';
import { UseProductionProgress } from '../productions-shared/use-production-progress';
import { UseProductionServiceResponse } from '../productions-shared/use-production-service-response';
import { UseProductionSubmit } from '../productions-shared/use-production-submit';
import { UseProductionSave } from '../productions-shared/use-production-save';

interface ProductionNotificationsProps {
    formCtrl: UseProductionForm;
    progressCtrl: UseProductionProgress;
    saveCtrl: UseProductionSave;
    serviceResponse: UseProductionServiceResponse;
    submitCtrl: UseProductionSubmit;
}

const ProductionNotifications = (props: ProductionNotificationsProps) => {
    const vm = useVM(props);

    return (
        <BoxedDiv box={{}} data-notifications={true}>
            {/* UI error messages */}
            {
                vm.validationMessages.length > 0 &&
                <PageNotifications kind='validation' items={vm.validationMessages.map(v => v.notification.content)} />
            }

            {/* System alerts */}
            {
                vm.systemAlerts.length > 0 &&
                <PageNotifications kind='system_alert' items={vm.systemAlerts} />
            }

            {/* Info messages */}
            {
                vm.infoMessages.length > 0 &&
                <PageNotifications kind='info' items={vm.infoMessages.map(v => v.notification.content)} />
            }

        </BoxedDiv>
    )
}

function useVM({ formCtrl, progressCtrl, saveCtrl, serviceResponse, submitCtrl }: ProductionNotificationsProps) {
    const hasMessages = useRef(false);
    const [systemAlerts, setSystemAlerts] = useState<Array<string>>([]);

    const isSubmitPage = progressCtrl.currentStep.kind === 'submit';

    const infoMessages = useMemo(() => {
        return [
            ...(isSubmitPage ?
                formCtrl.view.Submit.infoNotifications :
                formCtrl.view.dataPageView.groups
                    .map(g => g.products)
                    .flat()
                    .map(p => p.productions)
                    .flat()
                    .map(p => p.infoMessages)
                    .reduce((memo, infos) => [...memo, ...infos], []) || []
            ),
        ];
    }, [formCtrl.view, isSubmitPage]);

    const validationMessages = useMemo(() => {
        const commentsValidationError = (
            isSubmitPage ?
                formCtrl.view.Submit.CommentsValidationError :
                formCtrl.view.dataPageView.comments.validationError
        );

        const setFocusedField = formCtrl.setFocusedField;

        return [
            ...(isSubmitPage ?
                [
                    ...(submitCtrl.declarationError ? [{
                        notification: { content: submitCtrl.declarationError }
                    }] : []),
                    ...submitCtrl.unsavedPages.map(uss => ({
                        notification: {
                            content: ErrorUnsavedPage({
                                onTargetClick: () => saveCtrl.checkChangesAndGoToPage(uss.index),
                                label: uss.label
                            }),
                        }
                    })),
                    ...submitCtrl.inactiveProductionAreaGroups.map(ipa => ({
                        notification: {
                            content: InactiveProductionAreaPage({
                                onTargetClick: () => saveCtrl.checkChangesAndGoToPage(ipa.index),
                                label: ipa.label
                            }),
                        }
                    })),
                    ...submitCtrl.inactiveProductsWithData.map(p => ({
                        notification: {
                            content: InactiveProduct({
                                onTargetClick: () => {
                                    saveCtrl.checkChangesAndGoToPage(0);
                                    setFocusedField({
                                        field: 'delete',
                                        productId: p.productRefData.id,
                                        rowIndex: p.rowIndex
                                    })
                                },
                                label: p.productRefData.productName,
                            }),
                        }
                    })),
                ] : [
                    ...formCtrl.view.dataPageView.groups
                        .map(g => g.products)
                        .flat()
                        .map(p => p.productions)
                        .flat()
                        .map(p => p.validationErrors)
                        .reduce((memo, validationErrors) => [...memo, ...validationErrors], []),
                ]
            ),
            ...(commentsValidationError != null ? [commentsValidationError] : []),
        ];
    }, [
        formCtrl.setFocusedField,
        formCtrl.view,
        isSubmitPage,
        saveCtrl,
        submitCtrl.declarationError,
        submitCtrl.inactiveProductionAreaGroups,
        submitCtrl.inactiveProductsWithData,
        submitCtrl.unsavedPages
    ]);

    useEffect(() => {
        const { serverErrorMessages, validationMessages, notOKRecordResults } = serviceResponse;

        setSystemAlerts(uniq([
            ...(serverErrorMessages == null ? [] : serverErrorMessages),
            ...(validationMessages == null ? [] : validationMessages.map(v => v.errorMessage as string)),
            ...(notOKRecordResults == null ? [] : notOKRecordResults.map(mapToUserMessage)),
        ]));
    }, [serviceResponse]);

    useEffect(() => {
        if (formCtrl.view.isValid) {
            setSystemAlerts([]);
        }
    }, [formCtrl.view.isValid])

    useEffect(() => {
        hasMessages.current = (
            infoMessages.length > 0 ||
            systemAlerts.length > 0 ||
            validationMessages.length > 0
        );
    }, [infoMessages.length, systemAlerts.length, validationMessages.length]);

    // Scroll when update/submit fails
    useEffect(() => {
        if ((serviceResponse.status === 'update_failed' || serviceResponse.status === 'submit_failed') && hasMessages.current) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
        }
    }, [serviceResponse.status]);

    // Scroll when system alerts length changes and there are messages
    useEffect(() => {
        if ((serviceResponse.notOKRecordResults?.length || 0) > 0 || (serviceResponse.serverErrorMessages?.length || 0) > 0) {
            attemptScrollToSelector(SELECTOR_NOTIFICATIONS);
        }
    }, [serviceResponse.notOKRecordResults?.length, serviceResponse.serverErrorMessages?.length]);

    return {
        infoMessages,
        systemAlerts,
        validationMessages,
    };
}

export default ProductionNotifications;
