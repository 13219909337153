import { useEffect, useMemo, useState } from "react";

import { UseFocusedField } from "psims/react/util/use-focused-field";
import NotificationMessage from "../shared/notification-message";
import { UseEligibleProductions } from "./eligible-productions/use-eligible-productions";
import { UseTotalQuarterlyElibibleFssp } from "./total-quarterly-eligible-fssp/use-total-quarterly-eligible-fssp";
import { UseFspNavigation } from "./use-fsp-navigation";
import { UseFspProgress } from "./use-fsp-progress";
import { FocusField } from './types';
import { UseSubmit } from "../shared/use-submit";

interface UseFspNotificationsProps {
  dataSubmissionId: number;
  eligibleProductionsCtrl: UseEligibleProductions;
  focusFieldCtrl: UseFocusedField<FocusField>;
  navCtrl: UseFspNavigation;
  progressCtrl: UseFspProgress;
  serviceMessages: Array<string>;
  submitCtrl: UseSubmit;
  totalQuarterlyEligibleFsspCtrl: UseTotalQuarterlyElibibleFssp;
}


function useFspNotifications({
  dataSubmissionId, eligibleProductionsCtrl, focusFieldCtrl, navCtrl, progressCtrl, serviceMessages, submitCtrl, totalQuarterlyEligibleFsspCtrl,
}: UseFspNotificationsProps) {
  const [serviceNotifications, setServiceNotifications] = useState<Array<{ content: JSX.Element }>>([]);

  const validationMessages = useMemo<Array<{content: JSX.Element}>>(() => {
    if (navCtrl.navState === 'initial' && progressCtrl.currentStep.kind === 'data') {
      return [];
    }

    if (progressCtrl.currentStep.kind === 'data') {
      // Data page validation notifications
      return [
        ...eligibleProductionsCtrl.data.map(d => {
          return d.validation.map(v => ({
            content: NotificationMessage({
              parts: [
                `For ${d.ref.fsspProductName}: please provide a response in the `,
                {
                  label: 'Are these volumes correct for the purposes of your s19A report',
                  text: 'Are these volumes correct for the purposes of your s19A report',
                  onClick: () => focusFieldCtrl.setFocusedField({kind: 'data', field: 'volumeConfirmation', _id: d.draft._id}),
                },
                ' field.',
              ]
            })}))
        }).flat(),
        ...totalQuarterlyEligibleFsspCtrl.validationResults.map(v => {
          return {
            content: NotificationMessage({
              parts: [
                'For ',
                {
                  label: 'comments',
                  text: 'comments',
                  onClick: () => focusFieldCtrl.setFocusedField({kind: 'comments'}),
                },
                `: ${v.message}`,
              ]
            })
          }
        })
      ];
    }

    // Declaration page validation notifications
    return [
      ...(submitCtrl.validationMessages.declaration.validationMessages.declaration == null ? [] : [submitCtrl.validationMessages.declaration.validationMessages.declaration.notification]),
      ...(submitCtrl.validationMessages.comments.validationMessages.comments == null ? [] : [submitCtrl.validationMessages.comments.validationMessages.comments.notification]),
    ];
  }, [
    eligibleProductionsCtrl.data,
    focusFieldCtrl,
    navCtrl.navState,
    progressCtrl.currentStep.kind,
    submitCtrl.validationMessages,
    totalQuarterlyEligibleFsspCtrl.validationResults
  ]);
  
  const infoMessages = useMemo(() => {
    if (progressCtrl.currentStep.kind === 'data') {
      return eligibleProductionsCtrl.data.map(d => d.validationAlerts.map(va => {
        return {
          content: NotificationMessage({
            parts: [
              `For ${d.ref.fsspProductName}: you have selected that you disagree with the eligible production values displayed. `,
              'You are required to provide a reason in the ',
              {
                label: 'comments',
                text: 'comments',
                onClick: () => focusFieldCtrl.setFocusedField({kind: 'comments'}),
              },
              ' field to advise the Department of any discrepancies that may require further action.',
            ]
          })
        }
      })).flat()
    }

    return []
  }, [eligibleProductionsCtrl.data, focusFieldCtrl, progressCtrl.currentStep.kind]);

  useEffect(() => {
    const refreshPage = () => {
      window.location.href = window.location.href + '&refresh=true'
    }
    setServiceNotifications(serviceMessages.map(message => mapServiceMessage(message, refreshPage)));
  }, [dataSubmissionId, serviceMessages]);

  useEffect(() => {
    setServiceNotifications([]);
  }, [progressCtrl.currentStep.index]);


  return useMemo(() => ({
    infoMessages,
    serviceNotifications,
    validationMessages,
  }), [infoMessages, serviceNotifications, validationMessages]);
}

export default useFspNotifications

export type UseFspNotifications = ReturnType<typeof useFspNotifications>;

const PRECONDITIONS_NOT_MET_SERVICE_MSG = 'You have one or more incomplete refining';

function mapServiceMessage(msg: string, handleRefresh: () => any): {content: JSX.Element} {
  if (msg.indexOf(PRECONDITIONS_NOT_MET_SERVICE_MSG) > -1) {
    return {
      content: NotificationMessage( {
        parts: [
          msg,
          ' ',
          {
            label: 'Refresh page',
            text: 'Refresh the page',
            onClick: handleRefresh,
          },
          ' to complete the required refining submissions.',
        ],
      })
    };
  }

  return {
    content: NotificationMessage({parts: [msg]}),
  };
}