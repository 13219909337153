/**
 * Facade for generated model
 */

import { CreateUserVM, UpdateUserVM, UserVM } from "psims/gen/xapi-client";
import { all } from "psims/lib/collections";
import { isRoleTypeName, RoleTypeName } from "./ref-data/role-type";

const STATUSES = ['New', 'Invited', 'Registered', 'Inactive'] as const ;

export type Status = ArrayElement<typeof STATUSES>;

export type User = UserVM & {
	status: Status;
	roleType: RoleTypeName;
	};

export type CreateUser = CreateUserVM;

export type UpdateUser = UpdateUserVM;

export function userVMToUser(userVM: UserVM): User {
	const {roleType, status} = userVM;

	if (!isStatus(status)) {
		throw new Error(`Failed to convert UserVM to User - unknown status: ${status}`)
	}

	if (!isRoleTypeName(roleType)) {
		console.log('no role, but status: ', JSON.stringify(userVM, null, 2))
		throw new Error(`Failed to convert UserVM to User - unknown role type: ${roleType}`)
	}

	return {
		...userVM,
		roleType,
		status,
	};
}

function isStatus(maybeStatus?: string | null): maybeStatus is Status {
	return Boolean(STATUSES.find(s => s === maybeStatus));
}

export function isUser(maybeUser?: unknown): maybeUser is User {
	const asUser = maybeUser as User;

	return Boolean(asUser?.id)/* && isStatus(asUser?.status)*/;
}

export function isArrayOfUser(maybeArrayOfUser?: unknown): maybeArrayOfUser is Array<User> {
	const asArrayOfUser = maybeArrayOfUser as Array<User>;

	return Array.isArray(asArrayOfUser) && all(asArrayOfUser, isUser);
}

export function isPortalAdmin(maybe?: unknown) {

	return (
		isUser(maybe) &&
		maybe.roleType === 'LFG Portal Admin'
	);
}
