import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { ValidationResult } from "./validate-activity";
import { isValidationAlertResultPercentVariance, ValidationAlertResult } from "./activity-validation-alerts";

export function getInlineValidationMessage(validationResult: ValidationResult | null | undefined) {
  if (validationResult == null) {
    return undefined;
  }

  switch (validationResult.code) {
    case 'invalid_non_integer':
    case 'invalid_out_range':
      return `Must be ${validationResult.min === 0 ? 'a positive' : 'an'} integer (zero is permitted) with a maximum value of ${validationResult.min < 0 ? '+/-' : ''}${localeNumberWithFixed(validationResult.max)}`;

    case 'invalid_required':
      return 'Where a product has a value in a row, all fields in the row are required';
  }
}

export function getInlineInfoMessage(validationAlertResult: ValidationAlertResult | null) {
  if (validationAlertResult == null) {
    return undefined;
  }

  if (isValidationAlertResultPercentVariance(validationAlertResult)) {
    const {percentVariance} = validationAlertResult;

    return `This value has greater than ${percentVariance}% variance from your previous submission. Please enter a reason in the declaration comments field to confirm your data is correct.`;
  }

  return 'Your DEF derived from blending value is outside the expected range. Please enter a reason in the declaration comments field to confirm your data is correct';
}