import React from 'react';

import Expandable from 'psims/react/components/expandable';
import HelpBlock from 'psims/react/components/help';
import { BoxedDiv } from 'psims/react/components/layout';

interface FormattingGuidanceProps {}

const FormattingGuidance = (props: FormattingGuidanceProps) => {
  return (
    <Expandable
        collapsedLabel='Show guidance on formatting help text'
        expandedLabel='Hide guidance on formatting help text'
        contentPlacement='below'
        noWrap={true}
    >
        <HelpBlock box={{flex: 'column', marginBottom: 0.5, marginTop: 1}}>
            <BoxedDiv box={{flex: 'column'}}>
              <p>Use these formatting guidelines to ensure the help text you write shows correctly on the Liquid Fuels Gateway pages.</p>

              <p>If in doubt, try to find an existing product or product group with similar help text, copy the content and make any changes you need.</p>

              <p>Use the preview to check how the help text will look on the page. You can see changes in the preview without clicking ‘Save’.</p>

              <p>Any changes are not applied until you click ‘Save’.  You can click ‘Cancel’ to revert back to the original help text content.</p>

              <br />

              <table>
                <thead>
                  <tr>
                    <th className="no-wrap">Format type</th>
                    <th className="">Rule</th>
                    <th>Example</th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="top">
                    <td>Paragraphs</td>
                    <td>Insert <strong>an extra</strong> line break between paragraphs</td>
                    <td>
                      <pre>
                      Paragraph one content.
                      <br />
                      <br />
                      Paragraph two content.
                      </pre>
                    </td>
                  </tr>

                  <tr className="top">
                    <td>Multiple blank lines</td>
                    <td>Insert a single "\" and then a line break for each blank line required</td>
                    <td>
                      <pre>
                      Content before 3 blank lines.
                      <br />
                      \
                      <br />
                      \
                      <br />
                      \
                      <br />
                      Content after 3 blank lines.
                      </pre>
                    </td>
                  </tr>

                  <tr className="top">
                    <td>Bold text</td>
                    <td>Surround text to be bolded with "**"</td>
                    <td><pre>**text to be bolded**</pre></td>
                  </tr>

                  <tr className="top">
                    <td>Italicised text</td>
                    <td>Surround text to be italicised with "*"</td>
                    <td><pre>*text to be italicised*</pre></td>
                  </tr>

                  <tr className="top">
                    <td>Dot points</td>
                    <td>
                      <p>Ensure there is <strong>an empty line after any existing text,</strong> then prefix the dot points with "- ". Separate each dot point with a new line. The <strong>space in "- " is important</strong>.</p>
                      <p><strong>Note:</strong> if any text is to follow the list, ensure an extra line break follows the last dot point</p>
                    </td>
                    <td>
                      <pre>
                        Options:
                        <br />
                        <br />
                        - Option 1
                        <br />
                        - Option 2
                        <br />
                        <br />
                        Text to come after the list
                      </pre>
                    </td>
                  </tr>

                  <tr className="top">
                    <td>Numbered lists</td>
                    <td>
                      <p>Ensure there is <strong>an empty line after any existing text,</strong> then prefix the numbered list items with "1. ". Separate each numbered list item with a new line. The <strong>space in "1. " is important</strong>.</p>
                      <p><strong>Note:</strong> if any text is to follow the list, ensure an extra line break follows the last numbered list item. The numbers can be sequential, but isn't necessary.</p>
                    </td>
                    <td>
                      <pre>
                        Numbered options:
                        <br />
                        <br />
                        1. Option 1
                        <br />
                        1. Option 2
                        <br />
                        <br />
                        Text to come after the list
                      </pre>
                    </td>
                  </tr>
                </tbody>
              </table>
            </BoxedDiv>
            <br />
        </HelpBlock>
    </Expandable>
  );
}

export default FormattingGuidance;
