import React from 'react';
import styled from 'styled-components';
import Box from './box';
import Dot from './dot';
import ExclamationTriangle from './icons/exclamation-triangle';
import Info from './icons/info';
import Tick from './icons/tick';

type Kind = 'info' | 'confirmation' | 'warning'; 

interface NotificationProps {
    align?: 'center' | 'flex-start';
    kind: Kind;
}

type ColorScheme = {
    dark: string;
    light: string;
    mid: string;
};

const COLORS: {[key in Kind]: ColorScheme} = {
    info: {
        dark: 'blue-70', light: 'blue-40', mid: 'blue-50',
    },
    confirmation: {
        dark: 'green-70', light: 'green-10', mid: 'green-30',
    },
    warning: {
        dark: 'red-70', light: 'red-10', mid: 'red-30',
    }
};

const StyledNotification = styled.div`
    align-items: ${(props: NotificationProps) => `${props.align || 'flex-start'}`};
    background: ${(props: NotificationProps) => `var(--color-${COLORS[props.kind].light})`};
    border-top: ${(props: NotificationProps) => `3px solid var(--color-${COLORS[props.kind].mid});`};
    box-sizing: border-box;
    display: flex;
    padding: var(--spacing-xl);
    width: 100%;
`;

const Content = styled.div`
    width: 100%;
    padding-left: 15px;
`;

const Notification = (props: React.PropsWithChildren<NotificationProps>) => {
    const {children, ...rest} = props;
    return (
        <StyledNotification {...rest} data-notification-kind={props.kind}>
            <Dot $color={COLORS[props.kind].dark} $size='xl'>
                {
                    props.kind === 'confirmation' && 
                    <Box color='white'>
                        <Tick size='md' />
                    </Box>
                }

                {
                    props.kind === 'info' && 
                    <Box color='white'>
                        <Info size='md' />
                    </Box>
                }

                {
                    props.kind === 'warning' && 
                    <Box color='negative'>
                        <ExclamationTriangle invert={true} size='md' />
                    </Box>
                }
            </Dot>

            <Content>
                {children}
            </Content>
        </StyledNotification>
    )
}

export default Notification;
