import { useEffect, useMemo, useRef } from "react";

import { useReferenceData } from "psims/react/providers/api/reference-data";
import { useLogger } from "psims/react/providers/logging";
import { FspDataSubmission } from "psims/models/submission-types/fsp/fsp-submission";

interface UseFspRefDataProps {
  submission: FspDataSubmission | null;
}

function useFspRefData({submission}: UseFspRefDataProps) {
    const logger = useLogger({source: 'use-fsp-ref-data'});
    const alreadyLoggedUnexpectedEmptyProducts = useRef(false);

    const {data: refData, status} = useReferenceData();

    const submissionType = useMemo(() => {
      if (submission == null || refData == null) {
        return null;
      }

      return refData.submissionTypes.find(st => st.name === submission.dataSubmission.submissionTypeName);
    }, [refData, submission]);

    const fspProducts = useMemo(() => {
        return (refData?.fsspProducts || []).sort((a, b) => a.displayOrder - b.displayOrder);
    }, [refData]);

    const fspRefineryProducts = useMemo(() => {
        return (refData?.fsspRefineryProducts || []);
    }, [refData]);

    useEffect(() => {
        if ((refData?.fsspProducts || []).length < 1 && status === 'ready') {
            if (!alreadyLoggedUnexpectedEmptyProducts.current) {
                logger.exception(new Error('Unexpected empty FsspProducts list from reference data call'));
                alreadyLoggedUnexpectedEmptyProducts.current = true
            }
        }

    }, [logger, refData, status]);

    return {
        fspProducts,
        fspRefineryProducts,
        status,
        submissionType,
    };
}

export default useFspRefData

export type UseFspRefData = PopulatedProps<ReturnType<typeof useFspRefData>, 'submissionType'>;

export function isUseFspRefData(maybe: unknown): maybe is UseFspRefData {
    const maybeAs = maybe as UseFspRefData;

    return (
      maybeAs != null &&
      maybeAs.fspProducts != null &&
      maybeAs.fspRefineryProducts != null &&
      maybeAs.submissionType != null
    );
}
