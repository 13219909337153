import React, { PropsWithChildren } from 'react';
import { BoxedDiv, BoxProps } from './layout';

interface HelpBlockProps {
    box?: BoxProps;
}

const HelpBlock = (props: PropsWithChildren<HelpBlockProps>) => {
    const {box, ...otherProps} = props;
    return <BoxedDiv box={{...box, borderLeft: {color: 'primary'}, paddingH: 2}} {...otherProps} />
}

export default HelpBlock;
