/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HistoryListVMApiResponse } from '../models/HistoryListVMApiResponse';
import type { OrganisationMsoSettingHistoryVMIEnumerableApiResponse } from '../models/OrganisationMsoSettingHistoryVMIEnumerableApiResponse';
import { request as __request } from '../core/request';

export class HistoryService {

    /**
     * @returns HistoryListVMApiResponse Success
     * @throws ApiError
     */
    public static async getHistoryService(): Promise<HistoryListVMApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/history`,
        });
        return result.body;
    }

    /**
     * @returns OrganisationMsoSettingHistoryVMIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static async getMsoOrganisationSettingHistoryForUser(): Promise<OrganisationMsoSettingHistoryVMIEnumerableApiResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/history/GetMsoOrganisationSettingHistoryForUser`,
        });
        return result.body;
    }

}