import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { UpdateMsoStorageSiteField } from "psims/models/submission-types/mso/annual-activity/update-mso-storage-site";
import { NotificationPart } from "../../../shared/notification-message";
import { ValidationResult } from "./storage-sites-validation";

const FIELD_LABELS: {[key in UpdateMsoStorageSiteField | 'delete']: string} = {
  delete: 'delete all rows for product',
  storageFromDate: 'storage from date',
  storageToDate: 'storage to date',
  storageLocation: 'storage location',
  storagePrice: 'storage price',
  storageSite: 'storage site',
  storageTypeId: 'storage type',
};

export function getNotificationMessage(
  fieldName: UpdateMsoStorageSiteField | 'delete',
  onTargetClick: () => any,
  productName: string,
  validationResult: ValidationResult | null
) {
  if (validationResult == null) {
    return undefined;
  }

  const label = FIELD_LABELS[fieldName];

  const btn = {label: `Go to ${label} field`, onClick: onTargetClick, text: label};

  const parts: Array<NotificationPart> = [
    `For ${productName}: `,
  ]

  switch (validationResult.code) {
    case 'character_limit':
      parts.push(` must be ${localeNumberWithFixed(validationResult.limit)} characters or fewer.`)
      break;
  
    case 'invalid_date_format':
      parts.push('the value in ');
      parts.push(btn);
      parts.push(' must be valid date in the format dd/mm/yyyy.')
      break;

    case 'invalid_date_ordering':
      parts.push('the value in ');
      parts.push(btn);
      parts.push(' cannot be before storage from date.')
      break;

    case 'invalid_integer':
    case 'invalid_integer_range':
      parts.push('the value in ');
      parts.push(btn);
      parts.push(` must be ${validationResult.min === 0 ? 'a positive integer ' : 'an integer'} (zero is permitted) with a maximum value of ${validationResult.min < 0 ? '+/-' : ''}${localeNumberWithFixed(validationResult.max)}.`);
      break;
    
    case 'inactive_product':
      parts.push('This product is inactive. Please enter these details into an active product if applicable. Delete ');
      parts.push({label: `Go to delete data button`, onClick: onTargetClick, text: 'product data'});
      parts.push(' to continue.');
      break;
  }

  return parts;
}