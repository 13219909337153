import { assertFspRefineryProduct } from "psims/models/ref-data/fsp-refinery-product";
import { FspDataSubmission } from "psims/models/submission-types/fsp/fsp-submission";
import { useMemo } from "react";
import { PeriodSummaryTableConfig } from "./types";
import { UseFspRefData } from "./use-fsp-ref-data";

interface UseFspRefineryOutputProps {
  refData: UseFspRefData;
  submission: FspDataSubmission;
}

function useFspRefineryOutput({refData, submission}: UseFspRefineryOutputProps) {
  const tableView = useMemo<PeriodSummaryTableConfig | null>(() => {
    if (refData.fspRefineryProducts.length === 0) {
      return null;
    }

    return ({
      kind: 'Production',
      title: 'Quarterly refinery output',
      items: submission.refineryOutputs.map(ro => {
        const product = refData.fspRefineryProducts.find(p => p.refineryProductId === ro.refineryProductId);
        assertFspRefineryProduct(product);
        return {
          month1:ro.productionMonth1,
          month2: ro.productionMonth2,
          month3: ro.productionMonth3,
          product,
          totalKilolitres: ro.totalProductionKilolitres,
          totalTonnes: ro.totalProductionTonnes,
        }
      })
    });
  }, [refData.fspRefineryProducts, submission]);

  return {
    tableView,
  };
}

export default useFspRefineryOutput

export type UseFspRefineryOutput = ReturnType<typeof useFspRefineryOutput>;
