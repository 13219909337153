import React, { PropsWithChildren } from 'react';

import { SUPPORT_EMAIL } from 'psims/constants/app';

interface SupportLinkProps {
    link?: string;
}

const SupportLink = ({children, link}: PropsWithChildren<SupportLinkProps>) => {
    return <a href={`mailto:${link || SUPPORT_EMAIL}`}>{children == null ? (link || SUPPORT_EMAIL) : children}</a>
}

export default SupportLink;
