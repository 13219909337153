import React, { useEffect, useMemo, useState } from 'react';

import EnsureId from 'psims/react/pages/primary-pages/data-submissions/shared/ensure-id';
import useSubmissionLoadState from 'psims/react/pages/primary-pages/data-submissions/shared/use-submission-load-state';
import LoadingSubmission from 'psims/react/pages/primary-pages/data-submissions/shared/loading-submission';
import LoadSubmissionFailed from 'psims/react/pages/primary-pages/data-submissions/shared/load-submission-failed';
import useMsoRefinerApi, { UseMsoRefinerAPI } from './use-mso-refiner-api';
import MsoRefinerEditor from './mso-refiner-editor';

interface MsoRefinerProps {
    id: number;
}

const MsoRefiner = (props: MsoRefinerProps) => {
    const vm = useVM(props);

    switch(vm.loadState) {
        case 'error':
            return <LoadSubmissionFailed submissionName='MSO refiner' />
        case 'loaded':
            return <MsoRefinerEditor
                // TODO: should type assert apiCtrl
                apiCtrl={vm.apiCtrl as PopulatedProps<UseMsoRefinerAPI, 'submission'>}
            />;
        default:
            return <LoadingSubmission submissionName='MSO refiner' />;

    }
}

function useVM(props: MsoRefinerProps) {
    const apiCtrl = useMsoRefinerApi({id: props.id});
    const [loaded, setLoaded] = useState(false);

    const ready = useMemo(() => {
        return loaded;
    }, [loaded]);

    const loadState = useSubmissionLoadState({submission: apiCtrl.submission, isDepsReady: ready, loadStatus: apiCtrl.loadStatus})

    useEffect(() => {
        if (apiCtrl.loadStatus === 'fetched') {
            setLoaded(true);
        }
    }, [apiCtrl.loadStatus]);

    return {
        apiCtrl,
        loadState: loadState as typeof loadState,
    }
}

const Page = (props: MsoRefinerProps) => <EnsureId id={props.id} Component={MsoRefiner} />;

export default Page;
