import { useCallback, useEffect, useMemo, useState } from "react";

import { FspEligibleProduction, isUpdateFspEligibleProduction } from "psims/models/submission-types/fsp/eligible-production";
import { validateEligibleProduction } from "./validate-eligible-production";
import { getValidationAlertsForEligibleProduction } from "./eligible-production-validation-alerts";
import { recordActionAsEnum } from "psims/models/api/data-submission-record-action";
import { Draft, toDraft } from "./shared";
import { is } from "psims/lib/type-assertions";
import { ViewMode } from "../../shared/use-view-mode";
import { getInlineMessage } from "./eligible-production-inline-message";
import { UseFspPortalData } from "../use-fsp-portal-data";

interface UseEligibleProductionsProps {
  initialData: Array<FspEligibleProduction>;
  portalDataCtrl: UseFspPortalData;
  viewMode: ViewMode;
}

function useEligibleProductions({initialData, portalDataCtrl, viewMode}: UseEligibleProductionsProps) {
  const [updateData, setUpdateData] = useState(initialData.map(toDraft));

  const dataView = useMemo(() => {
    return updateData
      .map(data => {
        const productPortalData = portalDataCtrl.productPortalData.find(
          p => p?.referenceCode === data.ref.fsspProductReferenceCode
        );
        const validation = validateEligibleProduction(data);
        const validationAlerts = getValidationAlertsForEligibleProduction(data);
        const inlineMessage = getInlineMessage(validation, validationAlerts);

        return {
          draft: data,
          data: data.data,
          inlineMessage,
          productPortalData,
          ref: data.ref,
          validation,
          validationAlerts,
        }
      });
  }, [updateData, portalDataCtrl]);

  const update = useCallback((item: Draft, doesAgree: boolean) => {
    setUpdateData(prev => {
      const idx = prev.findIndex(d => d._id === item._id);
      const target = prev[idx];

      if (target == null) {
        // TODO: log?
        return prev;
      }

      const result = [...prev];
      result.splice(idx, 1, {
        ...target,
        data: {
          ...target.data,
          volumeConfirmation: doesAgree,
          recordAction: target.ref.volumeConfirmation == null ? recordActionAsEnum('Create') : (
            target.ref.volumeConfirmation !== doesAgree ? recordActionAsEnum('Update') : null
          )
        }
      })

      return result;
    })
  }, []);

  const isValid = useMemo(() => {
    return !dataView.some(d => d.validation.length > 0)
  }, [dataView]);

  const hasUnsavedChanges = useMemo(() => {
    return dataView.some(d => d.draft.data.recordAction != null)
  }, [dataView]);

  const getRequestPayload = useCallback(() => {
    const updatedData = dataView.filter(d => d.draft.data.recordAction != null);

    if (updatedData.length === 0) {
      return [];
    }

    const payloadItems = updatedData.map(d => {
      const payload = {
        concurrencyToken: d.draft.data.concurrencyToken,
        dataSubmissionId: d.draft.data.dataSubmissionId,
        fsspProductId: d.data.fsspProductId,
        volumeConfirmation: d.draft.data.volumeConfirmation,
        recordAction: d.draft.data.recordAction,
        id: d.draft.data.id || null,
      };

      return isUpdateFspEligibleProduction(payload) ? payload : undefined;
    })
    .filter(is);

    return payloadItems;
  }, [dataView]);

  // Re-initialise base-state when initial data changes
  useEffect(() => {
    setUpdateData(initialData.map(toDraft));
  }, [initialData]);

  return {
    data: dataView,
    hasUnsavedChanges,
    isValid,
    getRequestPayload,
    update,
    viewMode,
  }
}

export default useEligibleProductions

export type UseEligibleProductions = ReturnType<typeof useEligibleProductions>;