import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { Table, TBody, TD, TH, THead, TR } from 'psims/react/components/table';
import { Menu, MenuButton, MenuItem, MenuList } from 'psims/react/components/menu-button';
import Ellipsis from "psims/react/components/icons/ellipsis";
import Button from 'psims/react/components/button';
import { useNavigation } from 'psims/react/providers/router';
import { humanDate } from "psims/lib/formatters/datetime";
import { getReportMonth, getReportYear, ReportingHistoryItem } from './reporting-history-item';
import Text from 'psims/react/components/text';

interface ResultReportingHistoryProps {
	reportingHistoryItems: Array<ReportingHistoryItem> | null,
	hasNoItems: boolean;
};

const ResultReportingHistory = (props: ResultReportingHistoryProps) => {
	return (
		<BoxedDiv box={{ flex: 'column', paddingV: 3 }}>
			<BoxedDiv box={{ flex: 'row' }}>
				{
					props.reportingHistoryItems?.length !== 0 &&
					<Table caption='Your reporting history with the entered filters applied' fullWidth={true}>
						<THead>
							<TR>
								<TH>Report</TH>
								<TH>Reporting entity</TH>
								<TH>Obligation date</TH>
								<TH>Reporting year</TH>
								<TH>Frequency</TH>
								<TH>Reporting month</TH>
								<TH>Case ID</TH>
								<TH>Status</TH>
								<TH $align='center'>Actions</TH>
							</TR>
						</THead>

						{<TBody>
							{
								props.reportingHistoryItems !== null && props.reportingHistoryItems?.map((reportHistory, index) => <ReportingHistoryRow
									key={index}
									reportingHistory={reportHistory}
								/>)
							}
						</TBody>}
					</Table>
				}

				{
					props.reportingHistoryItems?.length === 0 &&
					<BoxedDiv
						box={{
							borderTop: { color: 'border-primary-faded', width: 4 },
							flex: 'row',
							flexGrow: 1,
							justifyContent: 'flex-start',
							marginTop: 4,
							paddingTop: 4,
						}}
					>
						<p>
							<Text weight='semibold'>
								{
									props.hasNoItems ?
									'There are no data submissions for your organisation.' :
									'No results matching your search criteria.'
								}
							</Text>
						</p>
					</BoxedDiv>
				}
			</BoxedDiv>
		</BoxedDiv>
	);
};

export default ResultReportingHistory;

interface ReportingHistoryRowProps {
	reportingHistory: ReportingHistoryItem,
}

const ReportingHistoryRow = ({
	reportingHistory,
}: ReportingHistoryRowProps) => {
	return (
		<TR>
			<TD>{reportingHistory.submissionTypeNameExtended}</TD>
			<TD>{reportingHistory.reportingOrganisationName}</TD>
			<TD>{reportingHistory.type === 'Mso' ? reportingHistory.obligationDate ? humanDate(new Date(reportingHistory.obligationDate!)) : '' : ''}</TD>
			<TD>{getReportYear(reportingHistory)}</TD>
			<TD>{reportingHistory.reportFrequencyTypeName}</TD>
			<TD>{getReportMonth(reportingHistory)}</TD>
			<TD>{reportingHistory.caseId}</TD>
			<TD>{reportingHistory.status}</TD>
			<TD $align='center'>
				<ReportingHistoryActions
					reportingHistory={reportingHistory}
				/>
			</TD>
		</TR>
	);
}

interface ReportingHistoryActionsProps {
	reportingHistory: ReportingHistoryItem;
}

const ReportingHistoryActions = ({
	reportingHistory
}: ReportingHistoryActionsProps) => {
	const nav = useNavigation();

	return (
		<Menu>
			<MenuButton as={Button} $kind='unstyled' label='Actions for this data submission'>
				<BoxedSpan box={{ paddingH: 1.5, paddingV: 0.5 }} >
					<Ellipsis color='primary' size='md' />
				</BoxedSpan>
			</MenuButton>

			<MenuList>
				{
					<MenuItem onSelect={() => nav.goToReport(reportingHistory)}>{getActionLabel(reportingHistory.status!)}</MenuItem>
				}
			</MenuList>
		</Menu>
	)
}


function getActionLabel(status: string) {
	switch (status) {
		case 'Action required':
			return 'Resume'
		case 'Draft':
			return 'Resume'
		case 'Not started':
			return 'Begin report'
	}

	return 'View'
}
