import { RecordResult } from "./record-result";
import { RecordAction } from "./data-submission-record-action";

// Represents a record returned from XAPI that can be updated
type Updatable = {
    concurrencyToken: string;
    id: number;
    recordResult: RecordResult;
}

export type WithUpdatable<T extends {}> = T & Updatable;

export function isWithUpdateable<T>(maybe: unknown): maybe is WithUpdatable<T> {
    if (maybe == null) {
        return false;
    }

    const asWithUpdateable = maybe as WithUpdatable<T>;

    return asWithUpdateable.concurrencyToken != null &&
           asWithUpdateable.id != null;
}

// Represents an XAPI record update request
type Update = {
    concurrencyToken: string;
    id: number;
    recordAction: RecordAction;
}

export type UpdateFields = keyof Update;
