import { asString } from "../string";
import { isValidAddressText, isValidNameText, isValidStringLength } from "./string";
import { isValidEmail } from "./email";
import { isValidPhone as isValidPhoneValidator } from './phone';
import { INVALID_ADDRESS_LINE1, INVALID_ADDRESS_LINE2, INVALID_ADDRESS_LINE2_NA, INVALID_ADDRESS_TEXT, INVALID_EMAIL, INVALID_FIRST_NAME, INVALID_LAST_NAME, INVALID_NAME_TEXT, INVALID_PHONE_NUMBER, INVALID_POSITION, INVALID_POSTCODE, INVALID_SUBURB } from "psims/constants/validation-messages";
import { isValidPostcode } from "./postcode";
import { isEmpty } from "../empty";

export function validateEmail(val: string | null | undefined) {
    const str = asString(val);
    return str.length > 0 && isValidEmail(str) ?
        null :
        INVALID_EMAIL;
}

export function validateFirstName(val: string | null | undefined) {
    let message: string | null = isValidStringLength(asString(val), {max: 50, min: 1}) ?
        null :
        INVALID_FIRST_NAME;

    if (message === null && val && asString(val).length > 0 && !isValidNameText(asString(val))) {
        message = INVALID_NAME_TEXT;
    }

    return message;
}

export function validateLastName(val: string | null | undefined) {
    let message: string | null = isValidStringLength(asString(val), {max: 50, min: 1}) ?
        null :
        INVALID_LAST_NAME;

    if (message === null && val && asString(val).length > 0 && !isValidNameText(asString(val))) {
        message = INVALID_NAME_TEXT;
    }

    return message;
}

export function validatePosition(val: string | null | undefined) {
    let message: string | null = isValidStringLength(asString(val), {max: 50, min: 1}) ?
        null :
        INVALID_POSITION;

    if (message === null && val && asString(val).length > 0 && !isValidNameText(asString(val))) {
        message = INVALID_NAME_TEXT;
    }

    return message;
}

export function validatePhone(val: string | null | undefined) {
    const str = asString(val);
    return str.length > 0 && isValidPhoneValidator(str) ?
        null :
        INVALID_PHONE_NUMBER;
}

export function validateAddress1(val: string | null | undefined) {
    let message: string | null =  isValidStringLength(asString(val), {max: 100, min: 1}) ?
        null :
        INVALID_ADDRESS_LINE1;

    if (message === null && val && asString(val).length > 0 && !isValidAddressText(asString(val))) {
        message = INVALID_ADDRESS_TEXT;
    }

    return message;
}

export function validateAddress2(addr2: string | null | undefined, addr1: string | null | undefined) {
    const addr2Str = asString(addr2);
    const addr1Str = asString(addr1);

    if (addr2Str.length > 0 && addr1Str.length === 0) {
        return INVALID_ADDRESS_LINE2_NA;
    }

    let message: string | null = isValidStringLength(addr2Str, {max: 100, min: 0}) ?
        null :
        INVALID_ADDRESS_LINE2;

    if (message === null && addr2 && asString(addr2).length > 0 && !isValidAddressText(asString(addr2))) {
        message = INVALID_ADDRESS_TEXT;
    }

    return message;
}

export function validateCity(city: string | null | undefined) {
    let message: string | null = isValidStringLength(asString(city), {min: 1, max: 50}) ?
        null :
        INVALID_SUBURB;

    if (message === null && city && asString(city).length > 0 && !isValidAddressText(asString(city))) {
        message = INVALID_ADDRESS_TEXT;
    }

    return message;
}

export function validatePostcode(postcode: string | null | undefined) {
    const str = asString(postcode);
    return str.length > 0 && isValidPostcode(str) ?
        null :
        INVALID_POSTCODE;
}

type Address = {
    addressLine1?: string | null;
    addressLine2?: string | null;
    suburb?: string | null;
    postcode?: string | null;
    stateId?: number | null;
}

export function isAddressSectionEmpty(address: Address) {
    return (
        isEmpty(address.addressLine1) &&
        isEmpty(address.addressLine2) &&
        isEmpty(address.suburb) &&
        isEmpty(address.postcode) &&
        isEmpty(address.stateId)
    );
}
