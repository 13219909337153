import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

function queryFromLocation(location: {search: string}): {[key: string]: string} {
    return location.search.replace('?', '').split('&').reduce((qs, q) => {
        const [k, v] = q.split('=');
        return {
            ...qs,
            [k]: v,
        }
    }, {});
}

function useQuery(clearParams: boolean = false) {
    const history = useHistory();
    const location = useLocation();
    const query = useRef(queryFromLocation(location));
    const pathname = useRef(location.pathname);

    useEffect(() => {
        if (clearParams && location.search) {
            history.push(location.pathname, {});
        }
    }, [clearParams, location, history]);

    if (location.pathname !== pathname.current) {
        pathname.current = location.pathname;
        if (location.search) {
            query.current = queryFromLocation(location);
        }
    }

    return {
        ...query.current,
    };
}

export default useQuery;
