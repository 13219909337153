import { createContext, PropsWithChildren, useContext } from "react";

import { isMinimumStockObligationSetting, MinimumStockObligationSetting } from "psims/models/minimum-stock-obligation-setting";
import { useAPI } from ".";
import { buildAssertArrayOf } from "psims/lib/type-assertions";
import usePromise from "psims/react/util/use-promise";

type OrganisationMsoSettingsContext = {
    error: string | null;
    organisationMsoSettings: Array<MinimumStockObligationSetting> | null;
    status: ReturnType<typeof usePromise>['status'];
}

const context = createContext<OrganisationMsoSettingsContext | null>(null);

const OrganisationMsoSettingsProvider = ({children}: PropsWithChildren<{}>) => {
    const {api} = useAPI();
    const {data: organisationMsoSettings, error, status: organisationMsoSettingsStatus} = usePromise(
        () => api.getOrganisationMsoSettings(), x => x?.result, buildAssertArrayOf(isMinimumStockObligationSetting)
    );

    return <context.Provider
        value={{
            error,
            organisationMsoSettings,
            status: organisationMsoSettingsStatus,
        }}>
        {children}
    </context.Provider>
}

export default OrganisationMsoSettingsProvider;

export function useOrganisationMsoSettings() {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error('useOrganisationMsoSettings must be used within OrganisationMsoSettingsProvider');
    }

    return {
        ...ctx,
    };
}
