/*
    Component to show an icon and a floating message.
    Can be used either as a self-contained tooltip (i.e. as its 
    own focus target) or as a controlled message (e.g. as a 
    message that shows when a field is focused)
*/
import React, { PropsWithChildren, ReactNode, useLayoutEffect, useMemo, useState } from 'react';
import { arrow, offset, shift, Strategy } from '@floating-ui/core';
import { useFloating } from '@floating-ui/react-dom';
import { FloatingPortal } from '@floating-ui/react-dom-interactions';
import styled from 'styled-components';

import randomID from 'psims/lib/random-id';
import Text from "psims/react/components/text";
import useIsFocused from '../util/use-is-focused';
import Button from './button';
import Callout from './callout';
import Dot from './dot';
import Info from './icons/info';
import ExclamationTriangle from './icons/exclamation-triangle';
import { BoxedSpan } from './layout';
import VisuallyHidden from './visually-hidden';
import useIsMouseover from '../util/use-is-mouseover';

type Kind = 'info' | 'warning';

interface FloatingMessageProps {
    content: ReactNode;
    id?: string;
    kind: Kind;
    role?: 'alert';
    showContent?: boolean;
}

const Container = styled.div`
    align-items: center;
    display: inline-flex;
    height: 24px;
    position: relative;
    width: 24px;
`;

const FloatingMessage = (props: FloatingMessageProps) => {
    const vm = useVM(props);
    const {TargetContainer} = vm;

    return (
        <Container>
            <BoxedSpan box={{alignItems: 'center', flex: 'row'}} ref={vm.floating.reference}>
                <TargetContainer id={vm.id} isShown={vm.showInfo as boolean}  setIsShown={vm.setShowInfo}>
                    {
                        vm.kind === 'info' ?

                        <Text $color='blue-70' ref={vm.mouseoverRef}>
                            <Dot $size='xs'><Info color='white' size='xs'/></Dot>
                        </Text>:

                        <ExclamationTriangle color='warning' size='sm' ref={vm.mouseoverRef}/>
                    }
                </TargetContainer>
            </BoxedSpan>

            <FloatingPortal>
                {
                    vm.showInfo &&
                    <FloatingContent
                        arrowRef={vm.setArrowRef}
                        id={vm.id}
                        kind={vm.kind}
                        role={props.role}
                        {...vm.floating}
                    >{vm.content}</FloatingContent>
                }
            </FloatingPortal>
        </Container>
    )
}

function useVM({content, id, kind, showContent}: FloatingMessageProps) {
    const {isMouseover, setRef: mouseoverRef} = useIsMouseover();
    const [elId] = useState(id || randomID());
    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
    const floating = useFloating({
        placement: 'top',
        middleware: [shift(), offset(24), ...(arrowRef != null ? [arrow({element: arrowRef})] : [])],
    });

    const isControlled = showContent !== undefined;

    const TargetContainer = useMemo(() => {
        return isControlled ? ControlledContainer : UncontrolledContainer;
    }, [isControlled]);

    const [showInfo, setShowInfo] = useState(false);

    useLayoutEffect(() => {
        const handleUpdate = () => {
            if (!floating.refs.floating.current || !floating.refs.reference.current) {
                return;
            }
            floating.update();
        }

        const page = document.querySelector('#page') || document;
        page.addEventListener('scroll', handleUpdate)
        page.addEventListener('resize', handleUpdate)

        return () => {
            page.removeEventListener('scroll', handleUpdate)
            page.removeEventListener('resize', handleUpdate)
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floating.refs, floating.update]);

    useLayoutEffect(() => {
        arrowRef != null && floating.update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arrowRef, floating.update]);

    return {
        arrowRef,
        content,
        floating,
        id: elId,
        kind,
        setArrowRef,
        setShowInfo,
        showInfo: isMouseover || (isControlled ? showContent : showInfo),
        TargetContainer,
        mouseoverRef,
    };
}

type ContainerProps = PropsWithChildren<{
    isShown: boolean;
    setIsShown: (show: boolean) => any;
}>

const ControlledContainer = ({children}: ContainerProps) => {
    return <>{children}</>;
} 

const UncontrolledContainer = ({children, id, isShown, setIsShown}: ContainerProps & {id: string}) => {
    const {handleBlur, handleFocus} = useIsFocused({
        onBlur: () => setIsShown(false),
        onFocus: () => {
            setIsShown(true);
        },
    });

    return (
        <Button
            aria-describedby={isShown ? id : undefined}
            $kind='unstyled'
            onMouseDown={() => setIsShown(!isShown)}
            onBlur={handleBlur}
            onFocus={handleFocus}
            
        >
            <VisuallyHidden>Show tooltip</VisuallyHidden>
            {children}
        </Button>
    );
}

interface FloatingContentProps {
    arrowRef: (el: HTMLElement | null) => any;
    middlewareData: {
        arrow?: {
            x?: number | null;
            y?: number | null;
        };
    }
    id: string;
    kind: Kind;
    floating: (el: HTMLElement | null) => any;
    role?: 'alert';
    strategy: Strategy;
    x: number | null;
    y: number | null;
}

type StyledFloatingContentProps = {
    strategy: Strategy;
    x?: number | null;
    y?: number | null;
};

const StyledFloatingContent = styled.div`${(props: StyledFloatingContentProps) => `
    text-align: left;
    font-size: 14px;
    pointer-events: none;
    position: ${props.strategy};
    left: ${props.x ? props.x + 'px' : ''};
    top: ${props.y ? props.y + 'px' : ''};
    z-index: 3;
    max-width: 300px;
    min-width: 250px;
    width: 300px;
`}`;

const FloatingContent = ({children, arrowRef, middlewareData, id, kind, floating, role, strategy, x, y}: PropsWithChildren<FloatingContentProps>) => {
    const [arrowId] = useState(randomID());

    const {arrow = {}} = middlewareData;

    return (
        <StyledFloatingContent
            id={id}
            ref={floating}
            aria-live='polite'
            role={role || 'tooltip'}
            strategy={strategy}
            x={x}
            y={y}
        >
            <Callout $kind={kind}>
                {children}
            </Callout>

            <Arrow id={arrowId} $kind={kind} ref={arrowRef} $x={arrow.x} $y={arrow.y} />
        </StyledFloatingContent>
    )
}

interface ArrowProps {
    $kind: Kind;
    $x?: number | null;
    $y?: number | null;
}

const Arrow = styled.div`${({$kind, $x, $y}: ArrowProps) => `
    background: var(--color-callout-${$kind}-background);
    height: 16px;
    position: absolute;
    width: 16px;
    ${$x != null ? `
    left: ${$x}px;
    ` : ''}
    ${$y != null ? `
    top: ${$y}px;
    ` : `
    bottom: -8px;
    `}
    transform: rotate(45deg);
`}`;

export default FloatingMessage;
