import { SpacingSize, SPACING_MAP } from 'psims/constants/styles';

export interface SpacingProps {
    margin?: SpacingSize;
    marginBottom?: SpacingSize;
    marginH?: SpacingSize;
    marginLeft?: SpacingSize;
    marginRight?: SpacingSize;
    marginTop?: SpacingSize;
    marginV?: SpacingSize;
    padding?: SpacingSize;
    paddingBottom?: SpacingSize;
    paddingH?: SpacingSize;
    paddingLeft?: SpacingSize;
    paddingRight?: SpacingSize;
    paddingTop?: SpacingSize;
    paddingV?: SpacingSize;
}

export const spacingStyle = (props: SpacingProps) => ({
    ...(props.margin !== undefined ? {margin: SPACING_MAP[props.margin]} : {}),
    ...(props.marginBottom !== undefined ? {marginBottom: SPACING_MAP[props.marginBottom]} : {}),
    ...(props.marginH !== undefined ? {
        marginLeft: SPACING_MAP[props.marginH],
        marginRight: SPACING_MAP[props.marginH]
    } : {}),
    ...(props.marginLeft !== undefined ? {marginLeft: SPACING_MAP[props.marginLeft]} : {}),
    ...(props.marginRight !== undefined ? {marginRight: SPACING_MAP[props.marginRight]} : {}),
    ...(props.marginTop !== undefined ? {marginTop: SPACING_MAP[props.marginTop]} : {}),
    ...(props.marginV !== undefined ? {
        marginBottom: SPACING_MAP[props.marginV],
        marginTop: SPACING_MAP[props.marginV]
    } : {}),
    ...(props.padding !== undefined ? {padding: SPACING_MAP[props.padding]} : {}),
    ...(props.paddingBottom !== undefined ? {paddingBottom: SPACING_MAP[props.paddingBottom]} : {}),
    ...(props.paddingH !== undefined ? {
        paddingLeft: SPACING_MAP[props.paddingH],
        paddingRight: SPACING_MAP[props.paddingH],
    } : {}),
    ...(props.paddingLeft !== undefined ? {paddingLeft: SPACING_MAP[props.paddingLeft]} : {}),
    ...(props.paddingRight !== undefined ? {paddingRight: SPACING_MAP[props.paddingRight]} : {}),
    ...(props.paddingTop !== undefined ? {paddingTop: SPACING_MAP[props.paddingTop]} : {}),
    ...(props.paddingV !== undefined ? {
        paddingBottom: SPACING_MAP[props.paddingV],
        paddingTop: SPACING_MAP[props.paddingV]
    } : {}),
});
