import React from 'react';

import { BoxedDiv } from 'psims/react/components/layout';
import { UseMsoRefData } from 'psims/react/pages/primary-pages/data-submissions/mso/shared/use-mso-ref-data';
import { UseMsoImporterAPI } from './use-mso-importer-api';
import MsoStockholdingEditor from '../shared/mso-stockholding-editor';
import MsoStockOnBehalfEditor from '../shared/mso-stock-on-behalf-editor';
import MsoSummaryEditor from '../shared/mso-summary-editor';
import { OrganisationMsoSetting } from 'psims/models/submission-types/mso/organisation-mso-setting';
import Textarea from 'psims/react/components/textarea';
import { asString } from 'psims/lib/string';
import { UseMsoImporterForm } from './use-mso-importer-form';
import { UseMsoImporterValidation } from './use-mso-importer-validation';
import { UseMsoImporterValidationAlerts } from './use-mso-importer-validation-alerts';
import { UseMsoImporterFocus } from './use-mso-importer';
import { UseConfirm } from '../../shared/use-confirm';
import { UseMsoImporterNavigation } from './use-mso-importer-navigation';
import { UsePortalData } from '../../shared/use-portal-data';

interface MsoImporterDataEditorProps {
    apiCtrl: PopulatedProps<UseMsoImporterAPI, 'submission'>;
    deleteCtrl: UseConfirm;
    focusedFieldCtrl: UseMsoImporterFocus;
    formCtrl: UseMsoImporterForm;
    isDisabled: boolean;
    navCtrl: UseMsoImporterNavigation;
    portalDataCtrl: UsePortalData;
    refData: UseMsoRefData;
    validationCtrl: UseMsoImporterValidation;
    validationAlertsCtrl: UseMsoImporterValidationAlerts;
}

const MsoImporterDataEditor = ({
    apiCtrl,
    deleteCtrl,
    focusedFieldCtrl,
    formCtrl,
    isDisabled,
    navCtrl,
    portalDataCtrl,
    refData,
    validationCtrl,
    validationAlertsCtrl
}: MsoImporterDataEditorProps) => {
    return (
        <BoxedDiv box={{}}>
            <MsoStockholdingEditor
                actions={formCtrl}
                deleteCtrl={deleteCtrl}
                focusedField={focusedFieldCtrl.focusedField}
                isDisabled={isDisabled}
                msoTypeLabel='Importer'
                portalData={portalDataCtrl}
                refData={refData}
                saveAttempted={navCtrl.lastSaveAttemptIndex === 0}
                stockholdings={validationCtrl.stockholdings || []}
                dataSubmission={apiCtrl.submission.dataSubmission}
            />

            <MsoStockOnBehalfEditor
                actions={formCtrl}
                deleteCtrl={deleteCtrl}
                focusedField={focusedFieldCtrl.focusedField}
                isDisabled={isDisabled}
                portalData={portalDataCtrl}
                saveAttempted={navCtrl.lastSaveAttemptIndex === 0}
                refData={refData}
                stocksOnBehalf={validationCtrl.stocksOnBehalf || []}
            />
        
            <MsoSummaryEditor
                actions={formCtrl}
                focusedField={focusedFieldCtrl.focusedField}
                isDisabled={isDisabled}
                msoSettings={apiCtrl.submission.msoSettings as Array<OrganisationMsoSetting>}
                msos={validationCtrl.msos || []}
                portalData={portalDataCtrl}
                refData={refData}
                msoTypeName={'importer'}
                saveAttempted={navCtrl.lastSaveAttemptIndex === 0}
                validationAlerts={validationAlertsCtrl.filter(va => va.tooltip.target.entity === 'mso')}
            />

            {/* Comments */}
            <BoxedDiv box={{}}>
                <Textarea
                    disabled={isDisabled}
                    error={validationCtrl.msoComment?.validationMessages.comments?.tooltip.message}
                    id='comments'
                    label='Comments'
                    onChange={e => formCtrl.updateComments(e.target.value)}
                    setFocused={focusedFieldCtrl.focusedField?.kind === 'comments'}
                    value={asString(formCtrl.submissionUpdate.msoComment?.comments)}
                />
            </BoxedDiv>
        </BoxedDiv>
    );
}

export default MsoImporterDataEditor;
