import React, { useCallback, useMemo } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { H2 } from 'psims/react/components/typography';
import { ActionCellTD, ActionCellTH, ColumnHeader, Table, TD, TH, TR } from 'psims/react/pages/primary-pages/data-submissions/shared/data-table-components';
import { UseImporterImports } from './use-importer-imports';
import { recordActionFromEnum } from 'psims/models/api/data-submission-record-action';
import { AddButton, IconButton } from 'psims/react/components/button';
import Input from '../../../shared/input';
import { UseConfirm } from '../../../shared/use-confirm';
import { UseFocusedField } from 'psims/react/util/use-focused-field';
import { FocusField, isDataFocusField, isDeleteProductFocusField, MsoProductView } from '../../shared/types';
import { UpdateMsoStockholdingImportDraft, UpdateMsoStockholdingImportField } from 'psims/models/submission-types/mso/annual-activity/importer/update-mso-stockholding-import';
import VisuallyHidden from '@reach/visually-hidden';
import { EXPIRED_OR_INTERNAL_PRODUCT_MULTI_ROW } from 'psims/constants/validation-messages';
import FloatingMessage from 'psims/react/components/floating-message';
import useFocusable from 'psims/react/util/use-focusable';
import { isEmpty } from 'psims/lib/empty';
import { TooltipHelp } from '../../../shared/tooltip-help';

interface ImporterImportsEditorProps {
  dataSubmissionId: number;
  deleteCtrl: UseConfirm;
  focusFieldCtrl: UseFocusedField<FocusField>;
  forceErrors: boolean;
  isDisabled: boolean;
  products: Array<MsoProductView>;
  importerImportsCtrl: UseImporterImports;
  isOnBehalf: boolean;
}

const ImporterImportsEditor = (props: ImporterImportsEditorProps) => {
  const vm = useImportsEditorEditorVM(props);

  return (
    <BoxedDiv box={{ flex: 'column' }}>
      <BoxedDiv box={{ marginTop: 6 }}>
        <H2>{vm.labels.title}</H2>
        {
          vm.isOnBehalf === true &&
          <div>
            <p>
              In the past 12 months, did you import stock on behalf of another entity? This section details any stock you have imported on behalf of another entity and should be attributed to that entity's import volumes.
            </p>
          </div>
        }

        {
          vm.isOnBehalf === false &&
          <div>
            <p>
              In the past 12 months, did another entity import stock on your behalf? This section details stock that is imported by another entity but should be attributed towards your entity's importing volumes.
            </p>
          </div>
        }


      </BoxedDiv>

      <BoxedDiv box={{ marginTop: 2 }}>

        <Table caption={`Data for ${vm.labels.title}`} fixed={false} >
          <thead>
            <TR>
              <ColumnHeader label='MSO Product' fixedWidth='230px' />

              <ColumnHeader label={vm.labels.amountImported} fixedWidth='230px' Help={vm.labels.amountImportedHelp} />

              <ColumnHeader label={vm.labels.entityName} fixedWidth='230px' Help={vm.labels.entityNameHelp} />

              {
                vm.hasExpiredData ?
                <ActionCellTH fixedWidth='40px'>
                  <VisuallyHidden>Actions</VisuallyHidden>
                </ActionCellTH> :
                null
              }
            </TR>
          </thead>

          {
            vm.products.map(p => (
              <ProductSection
                key={p.id}
                dataSubmissionId={vm.dataSubmissionId}
                deleteCtrl={vm.deleteCtrl}
                focusFieldCtrl={vm.focusFieldCtrl}
                forceErrors={vm.forceErrors}
                importerImportsCtrl={vm.importerImportsCtrl}
                isOnBehalf={vm.isOnBehalf}
                isDisabled={vm.isDisabled}
                product={p}
                products={vm.products}
              />))
          }

        </Table>

      </BoxedDiv>
    </BoxedDiv>
  );
}

function useImportsEditorEditorVM ({
  dataSubmissionId,
  deleteCtrl,
  focusFieldCtrl,
  forceErrors,
  importerImportsCtrl,
  isDisabled,
  isOnBehalf,
  products,
}: ImporterImportsEditorProps) {
  const data = useMemo(() => {
    return importerImportsCtrl.updateImports
      .filter(i => products.some(p => p.id === i.data.msoProductId))
  }, [importerImportsCtrl.updateImports, products]);

  const labels = useMemo(() => {
    return isOnBehalf ? {
      title: 'Imported on behalf of another entity',
      entityName: 'Entity imported on behalf of',
      entityNameHelp: <div>
        <p>The name of the entity/entities that you imported stock for in the past 12 months (1 January - 31 December).</p>
        <p>Add a new row to the table for each entity that you imported on behalf of.</p>
      </div>,
      amountImported: 'Amount imported on behalf of',
      amountImportedHelp: <div>
        <p>
          The total MSO product you imported on behalf of another entity that should count towards their import volumes for the purposes of the MSO.
        </p>
        <p>
          Quantities should be reported to the nearest <strong>megalitre (ML)</strong>.
        </p>
      </div>
    } : {
      title: 'Imported by another entity on our behalf',
      entityName: 'Importing entity',
      entityNameHelp: <div>
        <p>The name of the entity/entities that have imported stock on your behalf in the past 12 months (1 January - 31 December).</p>
        <p>Add a new row to the table for each importing entity.</p>
      </div>,
      amountImported: 'Amount imported by another entity',
      amountImportedHelp: <div>
        <p>
          The total MSO product imported by another entity that will be counted towards your entity's import volumes for the purposes of setting an MSO.
        </p>
        <p>
          Quantities should be reported to the nearest <strong>megalitre (ML)</strong>.
        </p>
      </div>
    }
  }, [isOnBehalf]);

  const hasExpiredData = useMemo(() => {
    return !isDisabled && products.some(p => {
      return data.find(d => (
        d.data.msoProductId === p.id &&
        p.productStatus === 'inactive' &&
        recordActionFromEnum(d.data.recordAction) !== 'Delete'
      ));
    });
  }, [data, isDisabled, products]);

  return {
    dataSubmissionId,
    data,
    deleteCtrl,
    focusFieldCtrl,
    forceErrors,
    hasExpiredData,
    importerImportsCtrl,
    isDisabled,
    isOnBehalf,
    labels,
    products,
  }
}

interface ProductSectionProps {
  dataSubmissionId: number;
  deleteCtrl: UseConfirm;
  focusFieldCtrl: UseFocusedField<FocusField>;
  forceErrors: boolean;
  isDisabled: boolean;
  isOnBehalf: boolean;
  product: MsoProductView;
  products: Array<MsoProductView>;
  importerImportsCtrl: UseImporterImports;
}

const ProductSection = (props: ProductSectionProps) => {
  const vm = useProductSectionVM(props);

  if (vm.hideProduct) {
    return null;
  }

  return <tbody>
    {
      vm.rowData.map((row, index) => <TR key={index}>
        {
          index === 0 && <TH scope='row' verticalAlign='top' rowSpan={vm.rowSpan}>
            <BoxedSpan box={{ alignItems: 'center', flex: 'row', height: '52px' }}>
              {vm.product.name}
              {
                vm.showRowError ?
                  <BoxedSpan box={{ marginBottom: -0.5, marginLeft: 1 }}>
                    <FloatingMessage
                      content={EXPIRED_OR_INTERNAL_PRODUCT_MULTI_ROW}
                      kind="warning"
                      role="alert"
                    />
                  </BoxedSpan> :
                  !isEmpty(vm.product.Help?.content) ?
                  <TooltipHelp
                    Help={<ReactMarkdown>{vm.product.Help?.content as string}</ReactMarkdown>}
                  /> :
                  null
              }
            </BoxedSpan>
          </TH>

        }

        {
          row.kind === 'add_button' ?
            // Add button
            (
              vm.showAddButton ? <TD colSpan={vm.colSpan}>
                <AddButton
                  label='Add import'
                  onClick={() => vm.importerImportsCtrl.addImport(vm.product.id, vm.isOnBehalf)}
                >Add import</AddButton></TD> :
                null
            ) :

            // Data row
            <DataRow
              data={row.data}
              focusFieldCtrl={vm.focusFieldCtrl}
              forceErrors={vm.forceErrors}
              importerImportsCtrl={vm.importerImportsCtrl}
              index={index}
              isDisabled={vm.isDisabled}
              onDelete={vm.deleteCtrl.requestConfirmation}
              product={vm.product}
            />
        }

        {
          vm.hasExpiredData ?
          <ActionCellTD colSpan={vm.colSpan}>
            {
              index === 0 && vm.showDeleteButton ?
                <DeleteProductButton
                  focusedField={vm.focusFieldCtrl.focusedField}
                  onClick={() => vm.deleteCtrl.requestConfirmation(
                    'Are you sure you wish to delete this data?',
                    'All imports for this product will be deleted.',
                    () => vm.importerImportsCtrl.deleteAllImportsForProduct(vm.product.id)
                  )}
                  productId={vm.product.id}
                /> :
                null 
            }
          </ActionCellTD> :
          null
        }
      </TR>)
    }
  </tbody>
}

function useProductSectionVM({
  dataSubmissionId,
  deleteCtrl,
  focusFieldCtrl,
  forceErrors,
  isDisabled,
  isOnBehalf,
  product,
  products,
  importerImportsCtrl,
}: ProductSectionProps) {
  const updateImporterImports = useMemo(() => {
    return importerImportsCtrl.updateImports
      .filter(d => d.data.isOnBehalf === isOnBehalf)
      .filter(s => s.data.msoProductId === product.id && recordActionFromEnum(s.data.recordAction) !== 'Delete')
  }, [product.id, importerImportsCtrl.updateImports, isOnBehalf]);

  const rowSpan = useMemo(() => {
    return updateImporterImports.length + 1;
  }, [updateImporterImports.length]);

  const rowData = useMemo(() => {
    let data = [
      ...updateImporterImports.map(s => ({ kind: 'data' as 'data', data: s })),
      { kind: 'add_button' as 'add_button' },
    ];

    if (isDisabled && data.length === 1) {
      // Add empty row
      data = [{ data: { data: { _id: Symbol(), msoProductId: product.id, dataSubmissionId }, validations: {} }, kind: 'data' }, ...data];
    }

    return data;
  }, [dataSubmissionId, isDisabled, product, updateImporterImports]);

  const hasExpiredData = useMemo(() => {
    return !isDisabled && rowData
      .some(d => (
        d.kind === 'data' &&
        recordActionFromEnum(d.data.data.recordAction) !== 'Delete' &&
        d.data.data.msoProductId === product.id &&
        product.productStatus === 'inactive'
      ))
  }, [isDisabled, product, rowData])

  const hideProduct = useMemo(() => {
    return (
      product.productStatus === 'inactive' && 
      importerImportsCtrl.updateImports
        .filter(i => i.data.msoProductId === product.id)
        .filter(i => recordActionFromEnum(i.data.recordAction) !== 'Delete')
        .length === 0
    );
  }, [importerImportsCtrl.updateImports, product]);

  const showAddButton = useMemo(() => {
    return !isDisabled && !hasExpiredData;
  }, [hasExpiredData, isDisabled]);

  const showRowError = useMemo(() => {
    return hasExpiredData && !isDisabled;
  }, [hasExpiredData, isDisabled]);

  const showDeleteButton = useMemo(() => {
    return hasExpiredData && !isDisabled
  }, [hasExpiredData, isDisabled]);

  return {
    colSpan: hasExpiredData ? 3 : 2, // Will need to be updated if column count changes
    deleteCtrl,
    focusFieldCtrl,
    forceErrors,
    hasExpiredData,
    hideProduct,
    importerImportsCtrl,
    isDisabled,
    isOnBehalf,
    product,
    rowData,
    rowSpan,
    showAddButton,
    showDeleteButton,
    showRowError,
    updateImporterImports,
  };
}

type UpdateImportView = UseImporterImports['updateImports'][number];

interface DataRowProps {
  data: UpdateImportView;
  focusFieldCtrl: UseFocusedField<FocusField>;
  forceErrors: boolean;
  index: number;
  isDisabled: boolean;
  onDelete: UseConfirm['requestConfirmation'];
  product: MsoProductView;
  importerImportsCtrl: UseImporterImports;
}

const DataRow = (props: DataRowProps) => {
  const vm = useDataRowVM(props);

  return <>
    <TD>
      <Input
        disabled={vm.isDisabled}
        error={vm.validations.amountImported?.inline}
        forceError={vm.forceErrors}
        kind='number'
        label='Amount imported'
        onChange={val => vm.updateField('amountImported', val)}
        shouldFocus={vm.rowFocusField?.field === 'amountImported'}
        value={vm.data.amountImported}
      />
    </TD>

    <TD>
      <BoxedDiv box={{ flex: 'row' }}>
        <Input
          disabled={vm.isDisabled}
          error={vm.validations.entityName?.inline}
          forceError={vm.forceErrors}
          kind='string'
          label='Entity name'
          onChange={val => vm.updateField('entityName', val)}
          shouldFocus={vm.rowFocusField?.field === 'entityName'}
          value={vm.data.entityName}
        />

        {
          vm.showIndividualDelete ?
          <IconButton
            color='dark-grey'
            icon='trash'
            label='Delete entity'
            onClick={() => vm.onDelete(
              'Are you sure you wish to delete this data?',
              'Import will be deleted',
              () => vm.deleteRow(vm.data)
            )}
            size='sm'
            styles={{ marginLeft: '6px', marginRight: '-12px', padding: '4px' }}
          />:
          null
        }
      </BoxedDiv>
    </TD>
  </>
}

function useDataRowVM({
  data,
  focusFieldCtrl,
  forceErrors,
  importerImportsCtrl,
  index,
  isDisabled,
  onDelete,
  product,
}: DataRowProps) {
  const rowFocusField = useMemo(() => {
    return (
      isDataFocusField(focusFieldCtrl.focusedField) &&
      focusFieldCtrl.focusedField._id === data.data._id ? focusFieldCtrl.focusedField : null
    );
  }, [data.data._id, focusFieldCtrl]);

  const updateField = useCallback(<TField extends UpdateMsoStockholdingImportField, TVal extends UpdateImportView['data'][TField]>(field: TField, val: TVal) => {
    importerImportsCtrl.updateImport({
      ...data.data,
      [field]: val
    })
  }, [data, importerImportsCtrl]);

  const deleteRow = useCallback((imp: UpdateMsoStockholdingImportDraft) => {
    importerImportsCtrl.deleteImport(imp);
  }, [importerImportsCtrl]);

  const isExpired = useMemo(() => {
    return product.productStatus === 'inactive';
  }, [product]);

  const showIndividualDelete = useMemo(() => {
    return !isDisabled && !isExpired;
  }, [isDisabled, isExpired]);

  return {
    data: data.data,
    deleteRow,
    forceErrors,
    index,
    isDisabled,
    onDelete,
    rowFocusField,
    showIndividualDelete,
    updateField,
    validations: data.validations
  }
}

interface DeleteProductProps {
    focusedField: unknown;
    onClick: () => any;
    productId: number;
}

const DeleteProductButton = React.forwardRef(({ focusedField, onClick, productId }: DeleteProductProps, ref) => {
    const isFocused = useMemo(() => {
      return (
        isDeleteProductFocusField(focusedField) &&
        focusedField.kind === 'importerImports' &&
        focusedField.productId === productId
      )
    }, [focusedField, productId]);

    const focusable = useFocusable({
        setFocused: isFocused,
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <BoxedSpan box={{marginLeft: 1}}>
          <IconButton
              color='dark-grey'
              icon='trash'
              label='Delete all rows for product'
              onClick={onClick}
              ref={setRef}
              size='sm'
          />
        </BoxedSpan>
    );
});

export default ImporterImportsEditor;
