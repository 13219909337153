import React from 'react';

import BaseSVG, { SVGProps } from './_base';

const ChevronDoubleRight = (props: SVGProps) => {
	return (
		<BaseSVG name='chevron-double-right' viewBox='0 0 12 12' {...props}>
				<path data-name="Chevron Double / right" d="M4.5,10.513,9.021,6,4.5,1.487,5.989,0,12,6,5.989,12Zm-4.5,0L4.521,6,0,1.487,1.49,0,7.5,6,1.49,12Z" fill="currentColor"/>
		</BaseSVG>
	);
};

export default ChevronDoubleRight;
