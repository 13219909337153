import React from 'react';

import BaseSVG, { SVGProps } from './_base';

const ChevronRight = (props: SVGProps) => {
	return (
		<BaseSVG name='chevron-right' viewBox='0 0 7.5 12' {...props}>
			<path data-name="Chevron / Right" d="M4.521,6,0,10.513,1.489,12,7.5,6,1.489,0,0,1.487Z" fill="currentColor"/>
		</BaseSVG>
	);
};

export default ChevronRight;
