import { RecordActionEnum } from "psims/gen/xapi-client";

// These need to be kept in sync with XAPI enum (PSIMS.Core.Enums.RecordActionEnum)
const RECORD_ACTIONS = ['Create', 'Update', 'Delete'] as const;

type RecordAction = ArrayElement<typeof RECORD_ACTIONS>;

export function recordActionAsEnum(recordAction: RecordAction): RecordActionEnum {
    return RECORD_ACTIONS.findIndex(action => action === recordAction) + 1;
}
