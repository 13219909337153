import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";

import { useUser } from "../user";
import { Status } from "psims/types/async";
import { isOrganisation, Organisation } from "psims/models/organisation";
import { useAPI } from ".";
import { isArrayOfType } from "psims/lib/collections";

type UserOrgsContext = {
    refetch: () => void;
    status: Status;
    orgs: Array<Organisation> | null
}

const context = createContext<UserOrgsContext | null>(null);

const UserOrgsProvider = ({children}: PropsWithChildren<{}>) => {
    const { user } = useUser();
    const { api } = useAPI();
    const [orgs, setOrgs] = useState<Array<Organisation> | null>(null);
    const [status, setStatus] = useState<Status>('init');

    const fetch = useCallback(() => {
        setStatus('loading');

        api.getOrganisations()
        .then(r => {
            if (r?.result != null && isArrayOfType(isOrganisation, r.result)){
                setOrgs(r.result as Array<Organisation>);
                setStatus('fulfilled');
            } else {
                throw new Error('Failed to assert organisations');
            }
        })
        .catch(e => {
            setStatus('error');
        });
    }, [api]);

    useEffect(() => {
        if (!user?.organisation || orgs != null || status !== 'init') {
            return;
        }

        fetch();
    }, [fetch, status, user?.organisation, orgs]);

    return <context.Provider value={{orgs, status, refetch: fetch}}>
        {children}
    </context.Provider>
}

export default UserOrgsProvider;

export function useUserOrgs() {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error('useUserOrgs must be used within UserOrgsProvider');
    }

    return {
        ...ctx,
    };
}
