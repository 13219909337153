import { NotificationPart } from "../../shared/notification-message";
import { CommentsFocusField, DeclarationFocusField } from "../../shared/types";
import { UseMsoAnnualActivityRefData } from "./use-mso-annual-activity-ref-data";

export type DataFocusField = {
  _id: symbol;
  field: string;
  kind: 'data';
}

type DeleteProductFocusField = {
  kind: 'storageSites' | 'annualActivities' | 'importerImports';
  field: 'delete';
  productId: number;
}

export type FocusField = DataFocusField | CommentsFocusField | DeclarationFocusField | DeleteProductFocusField;

export function isDataFocusField(maybe?: unknown): maybe is DataFocusField {
  const maybeAs = maybe as DataFocusField;
  return (
    maybeAs != null &&
    maybeAs._id != null &&
    maybeAs.field != null &&
    maybeAs.kind === 'data'
  );
}

export function isDeleteProductFocusField(maybe?: unknown): maybe is DeleteProductFocusField {
  const maybeAs = maybe as DeleteProductFocusField;

  return (
    maybeAs != null &&
    maybeAs.field === 'delete' &&
    (maybeAs.kind === 'storageSites' || maybeAs.kind === 'annualActivities' || maybeAs.kind === 'importerImports') &&
    maybeAs.productId != null
  );
}


export type FieldMessage = {
  inline: string | undefined;
  notification: {
    parts: Array<NotificationPart> | undefined;
  }
}

export type FieldValidation<TValidationResult> = FieldMessage & {
  validationResult: TValidationResult;
}

export type FieldAlert<TValidationAlert> = FieldMessage & {
  validationAlert: TValidationAlert;
}

export type MsoProductView = UseMsoAnnualActivityRefData['products'][number];