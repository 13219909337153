import { recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { UpdateMsoAnnualActivityImporterDraft, UpdateMsoAnnualActivityImporterField } from "psims/models/submission-types/mso/annual-activity/importer/update-mso-annual-activity-importer";
import { UseMsoAnnualActivityRefData } from "../../shared/use-mso-annual-activity-ref-data";
import { MAX_VOLUME } from "./shared";

const MAX_CAPACITY = 100000000;

export type ValidationResult = ({
  key: UpdateMsoAnnualActivityImporterField;
} & (
    {
      code: 'invalid_required';
    } |
    {
      code: 'invalid_integer' | 'invalid_integer_range';
      max: number;
      min: number;
    }
  )) | {
    key: 'delete';
    code: 'inactive_product';
  };

export function validateImporterAnnualActivity(
  annualActivity: UpdateMsoAnnualActivityImporterDraft,
  refDataMap: UseMsoAnnualActivityRefData['productIdMap']
): Array<ValidationResult> {

  const product = refDataMap[annualActivity.msoProductId];

  let messages: Array<ValidationResult> = [];

  if (product?.productStatus === 'inactive') {
    if (recordActionFromEnum(annualActivity.recordAction) === 'Delete' || annualActivity.id == null) {
      return messages;
    }

    messages.push({
      key: 'delete',
      code: 'inactive_product'
    });

    return messages;
  }

  // totalStorageCapacity
  if (annualActivity.totalStorageCapacity == null) {
    messages.push({
      key: 'totalStorageCapacity',
      code: 'invalid_required'
    });
  } else if (isNaN(Number(annualActivity.totalStorageCapacity)) || String(annualActivity.totalStorageCapacity).indexOf('.') > -1) {
    messages.push({
      key: 'totalStorageCapacity',
      code: 'invalid_integer',
      max: MAX_CAPACITY,
      min: 0,
    });
  } else if (annualActivity.totalStorageCapacity < 0 || annualActivity.totalStorageCapacity > MAX_CAPACITY) {
    messages.push({
      key: 'totalStorageCapacity',
      code: 'invalid_integer_range',
      max: MAX_CAPACITY,
      min: 0,
    });
  }

  // totalImportVolumeOnBehalf
  if (annualActivity.totalImportVolumeOnBehalf == null) {
    messages.push({
      key: 'totalImportVolumeOnBehalf',
      code: 'invalid_required'
    });
  } else if (isNaN(Number(annualActivity.totalImportVolumeOnBehalf)) || String(annualActivity.totalImportVolumeOnBehalf).indexOf('.') > -1) {
    messages.push({
      key: 'totalImportVolumeOnBehalf',
      code: 'invalid_integer',
      max: MAX_VOLUME,
      min: 0,
    });
  }

  // totalImportVolumeOthers
  if (annualActivity.totalImportVolumeOthers == null) {
    messages.push({
      key: 'totalImportVolumeOthers',
      code: 'invalid_required'
    });
  } else if (isNaN(Number(annualActivity.totalImportVolumeOthers)) || String(annualActivity.totalImportVolumeOthers).indexOf('.') > -1) {
    messages.push({
      key: 'totalImportVolumeOthers',
      code: 'invalid_integer',
      max: MAX_VOLUME,
      min: 0,
    });
  }

  // totalImportVolumeSelf
  if (annualActivity.totalImportVolumeSelf == null) {
    messages.push({
      key: 'totalImportVolumeSelf',
      code: 'invalid_required'
    });
  } else if (isNaN(Number(annualActivity.totalImportVolumeSelf)) || String(annualActivity.totalImportVolumeSelf).indexOf('.') > -1) {
    messages.push({
      key: 'totalImportVolumeSelf',
      code: 'invalid_integer',
      max: MAX_VOLUME,
      min: 0,
    });
  } else if (annualActivity.totalImportVolumeSelf < 0 || annualActivity.totalImportVolumeSelf > MAX_VOLUME) {
    messages.push({
      key: 'totalImportVolumeSelf',
      code: 'invalid_integer_range',
      max: MAX_VOLUME,
      min: 0,
    });
  }

  return messages;
}
