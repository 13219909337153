import React, { useCallback, useMemo, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import { UpdateMsoStockholding, UpdateMsoStockholdingField } from 'psims/models/submission-types/mso/mso-stockholding';
import { UpdateMsoStockholdingOwnership, UpdateMsoStockholdingOwnershipField } from 'psims/models/submission-types/mso/mso-stockholding-ownership';
import { BoxedDiv, BoxedSpan } from 'psims/react/components/layout';
import { Table, TD, TR, ColumnHeader, TH, DeleteActionCellTH } from 'psims/react/pages/primary-pages/data-submissions/shared/data-table-components';
import { H2 } from 'psims/react/components/typography';
import Text from "psims/react/components/text";
import { UseMsoRefData } from './use-mso-ref-data';
import { TooltipHelp } from 'psims/react/pages/primary-pages/data-submissions/shared/tooltip-help';
import Input from '../../shared/input';
import { ModelValidation } from './validation';
import { AddButton, IconButton } from 'psims/react/components/button';
import { isDeleteFocusField, OwnershipFocusField, StockholdingFocusField } from './types';
import { UseConfirm } from '../../shared/use-confirm';
import { DataSubmission } from 'psims/models/data-submission';
import Select, { useSelectController } from 'psims/react/components/select';
import { recordActionAsEnum, recordActionFromEnum } from 'psims/models/api/data-submission-record-action';
import FloatingMessage from 'psims/react/components/floating-message';
import { EXPIRED_OR_INTERNAL_PRODUCT } from 'psims/constants/validation-messages';
import VisuallyHidden from 'psims/react/components/visually-hidden';
import useFocusable from 'psims/react/util/use-focusable';
import { UsePortalData } from '../../shared/use-portal-data';

interface MsoStockholdingEditorActions {
    updateStockholding: <TField extends UpdateMsoStockholdingField>(msoProductId: number, field: TField, value: UpdateMsoStockholding[TField]) => any;
    addStockholdingOwnership: (msoProductId: number) => any;
    deleteAllDataForProduct: (msoProductId: number, source: HTMLElement | null) => any;
    deleteStockholdingOwnership: (msoProductId: number, rowIndex: number, source: HTMLElement | null) => any;
    updateStockholdingOwnership: <TField extends UpdateMsoStockholdingOwnershipField>(msoStockholdingId: number, rowIndex: number, field: TField, value: UpdateMsoStockholdingOwnership[TField]) => any
}

type UpdateMsoStockholdingOwnershipView = UpdateMsoStockholdingOwnership & {
    validationMessages: ModelValidation<UpdateMsoStockholdingOwnershipField>
    msoProductId: number;
};

type UpdateMsoStockholdingView = UpdateMsoStockholding & {
    stockholdingOwnerships: Array<UpdateMsoStockholdingOwnershipView>;
    validationMessages: ModelValidation<UpdateMsoStockholdingField>;
};

interface MsoStockholdingEditorProps {
    actions: MsoStockholdingEditorActions;
    deleteCtrl: UseConfirm;
    dataSubmission: DataSubmission<'MSO importer' | 'MSO refiner'>;
    focusedField: unknown | null;
    hideEEZ?: boolean;
    isDisabled: boolean;
    msoTypeLabel: 'Importer' | 'Refiner';
    portalData: UsePortalData;
    refData: UseMsoRefData;
    saveAttempted: boolean;
    stockholdings: Array<UpdateMsoStockholdingView>;
}

const HELP_CONTENT_MAP = {
    'density': (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
            <p>Report the weighted average density in kilograms per litre (kg/L) for each product during the month. To convert API Gravity to kg/L: Density (kg/L) = 141.5 ÷ (131.5 + API Gravity).</p>
            <p>If the density for each product is not readily available each month, the most recent density measurement should be used. If no measurement is available, a typical or average value may be entered, which should be reviewed and updated as appropriate.</p>
        </BoxedDiv>
    ),
    'holderOwnerStockVolumeImporter': (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
            <p>All stock that you own and hold under section 22 of the <em>Fuel Security Act</em> that is being counted towards your MSO (may include all land stock, stock on barges and stock on vessels travelling between domestic ports).</p>
            <p>Note: For stocks held in a Joint Venture (JV) or Joint Terminal (JT) (section 10 of the Rules), the stock must exist and must not be double counted.</p>
            <p>Entities that wish to report under s22 for JV and JT arrangements must have the details specified in your MSO compliance plan.</p>
            <p>Excludes stock that you own being held on behalf of another entity under either s23 or s24 arrangements.</p>
        </BoxedDiv>
    ),
    'holderOwnerStockVolumeRefiner': (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
            <p>All stock that you own and hold under section 22 of the <em>Fuel Security Act</em> that is being counted towards your MSO (may include all land stock, stock on barges and stock on vessels travelling between domestic ports).</p>
            <p>Excludes stock that you own being held on behalf of another entity.</p>
        </BoxedDiv>
    ),
    'stockOwnership': (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
            <ul>
                <li>
                    <strong>Entitled to take ownership of stock (s23)</strong><br/>Stock that you don't currently own, but you have a legally enforceable arrangement in place which allows you to count it towards your MSO under section 23 of the <em>Fuel Security Act</em>.
                </li>
                <li>
                    <strong>Reserved or quarantined stock (s24)</strong><br/>Stock that you don't own, but you have a legally enforceable arrangement in place which allows you to count it towards your MSO under section 24 of the <em>Fuel Security Act</em> (eg. oil stock tickets).
                </li>
            </ul>

            <br />

            <p>
                A new row will need to be added for each arrangement that is in place.
            </p>
        </BoxedDiv>
    ),
    'arrangementEntity': (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
            <p>Name of the entity listed in the legally enforceable arrangement (for section 23 or section 24 of the <em>Fuel Security Act</em>).</p>
        </BoxedDiv>
    ),
    'pipeline': (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
            <p>Stock stored within a pipeline that is being counted towards your MSO. It must meet the requirements under section 7 of the Rules and must also be listed in your compliance plan.</p>
        </BoxedDiv>
    ),
    'eez': (
        <BoxedDiv box={{ flex: 'column', width: '350px' }}>
            <p>Stock on a vessel within Australia's exclusive economic zone that is being counted towards your MSO. It must meet the requirements under section 9 of the Rules, with data collection and record keeping details to be listed in your compliance plan.</p>
        </BoxedDiv>
    ),
};

const MsoStockholdingEditor = ({
    actions, dataSubmission, deleteCtrl, focusedField, hideEEZ, isDisabled, msoTypeLabel,
    portalData, refData, saveAttempted, stockholdings
}: MsoStockholdingEditorProps) => {
    const hasExpiredData = useMemo(() => {
        return stockholdings.some(s => refData.productIdMap[s.msoProductId]?.productStatus === 'inactive');
    }, [refData, stockholdings]);

    return (
        <BoxedDiv box={{ flex: 'column' }}>
            <BoxedDiv box={{ marginTop: 6 }}>
                <H2>Stockholding - {msoTypeLabel}</H2>
            </BoxedDiv>

            <BoxedDiv box={{ marginTop: 2 }}>
                <p><Text>This section details any stock you are counting towards your MSO.</Text></p>
                <p><Text>Quantities should be reported to the nearest <strong>megalitre (ML)</strong>.</Text></p>
            </BoxedDiv>

            <BoxedDiv box={{ marginV: 4, overflow: 'auto' }}>
                <Table caption='Data for stockholdings' customWidth='fit-content'>
                    <thead>
                        <TR>
                            <ColumnHeader label='MSO Product' fixedWidth='230px' />

                            <ColumnHeader
                                $align='center'
                                fixedWidth='180px'
                                label={<span>Density (kg/L)<br />(optional)</span>}
                                Help={HELP_CONTENT_MAP['density']}
                            />

                            <ColumnHeader
                                $align='center'
                                fixedWidth='160px'
                                label='Holder and owner of stocks (s22)'
                                Help={HELP_CONTENT_MAP[`holderOwnerStockVolume${msoTypeLabel}`]}
                            />

                            <ColumnHeader
                                $align='center'
                                $width='160px'
                                label='Stock amount'
                            />

                            <ColumnHeader
                                $align='center'
                                fixedWidth='400px'
                                label='Entity the arrangement is with'
                                Help={HELP_CONTENT_MAP['arrangementEntity']}
                            />

                            <ColumnHeader
                                $align='center'
                                fixedWidth='410px'
                                label='Stock ownership'
                                Help={HELP_CONTENT_MAP['stockOwnership']}
                            />

                            <ColumnHeader
                                $align='center'
                                fixedWidth='160px'
                                label='Pipeline'
                                Help={HELP_CONTENT_MAP['pipeline']}
                            />
                            {
                                hideEEZ !== true &&
                                <ColumnHeader
                                    $align='center'
                                    fixedWidth='160px'
                                    label='EEZ stock'
                                    Help={HELP_CONTENT_MAP['eez']}
                                />
                            }

                            {
                                hasExpiredData ?
                                    <DeleteActionCellTH>
                                        <VisuallyHidden>Actions</VisuallyHidden>
                                    </DeleteActionCellTH> :
                                    null
                            }
                        </TR>
                    </thead>

                    <tbody>
                        {
                            refData.products.map((product, index) => (
                                <ProductRow
                                    key={index}
                                    actions={actions}
                                    dataSubmissionId={dataSubmission.id}
                                    focusedField={focusedField}
                                    hideEEZ={hideEEZ}
                                    isDisabled={isDisabled}
                                    onDelete={deleteCtrl.requestConfirmation}
                                    portalData={portalData}
                                    refData={refData}
                                    product={product}
                                    saveAttempted={saveAttempted}
                                    stockholding={stockholdings.find(s => s.msoProductId === product.id)}
                                    stockholdingIndex={index}
                                />
                            ))
                        }
                    </tbody>
                </Table>
            </BoxedDiv>
        </BoxedDiv>
    )
}

type OwnershipsProps = {
    actions: MsoStockholdingEditorActions;
    isDisabled: boolean;
    stockholdingIndex: number;
    onDelete: UseConfirm['requestConfirmation'];
    focusedField: unknown | null;
    ownerships: Array<UpdateMsoStockholdingOwnershipView>;
    saveAttempted: boolean;
    product: UseMsoRefData['products'][number];
}

const Ownerships = (props: OwnershipsProps) => {
    const ownerships = props.ownerships
        .map((ownership, rowIndex) => ({
            rowIndex,
            data: ownership,
        }))
        .filter(ownership => ownership.data.msoProductId === props.product.id && ownership.data.recordAction !== recordActionAsEnum('Delete'));

    return <>
        <TD colSpan={3}>
            <BoxedDiv box={{ flex: 'column' }}>
                {
                    ownerships
                        .map((ownership) => (
                            <Ownership
                                key={`${ownership.data.msoProductId}_${ownership.rowIndex}`}
                                {...props}
                                ownership={ownership}
                            />
                        ))
                }

                {
                    (!props.isDisabled && props.product.productStatus === 'active') ?
                        <BoxedSpan box={{ alignItems: 'center', flex: 'row', height: '52px' }}>
                            <AddButton
                                label='Add Entity'
                                onClick={() => props.actions.addStockholdingOwnership(props.product.id)}
                            >Add Entity</AddButton>
                        </BoxedSpan> :
                        null
                }
            </BoxedDiv>
        </TD>

    </>
}

type OwnershipProps = OwnershipsProps & {
    ownership: {
        data: UpdateMsoStockholdingOwnershipView,
        rowIndex: number;
    }
}

const Ownership = ({
    actions,
    focusedField,
    isDisabled,
    ownership,
    onDelete,
    product,
    saveAttempted,
    stockholdingIndex
}: OwnershipProps) => {
    const [deleteRef, setDeleteRef] = useState<HTMLElement | null>(null);
    const ownershipFF = useMemo(() => {
        return (
            isOwnershipFocusField(focusedField) &&
            focusedField.msoProductId === ownership.data.msoProductId &&
            focusedField.rowIndex === ownership.rowIndex
        ) ? focusedField : null
            ;
    }, [focusedField, ownership.data.msoProductId, ownership.rowIndex])

    const ownershipSelectCtrl = useSelectController({
        value: ownership.data.msoOwnershipType,
        options: [
            { label: 'Entitled to take ownership of stock (s23)', value: 1 },
            { label: 'Reserved or quarantined stock (s24)', value: 2 },
        ],
        onChange: v => actions.updateStockholdingOwnership(ownership.data.msoProductId, ownership.rowIndex, 'msoOwnershipType', !isNaN(Number(v)) ? Number(v) : undefined)
    })

    return (
        <BoxedDiv box={{ alignItems: 'center', flex: 'row', marginBottom: 1.5 }}>
            <BoxedSpan box={{ alignItems: 'center', flex: 'row', width: '160px', paddingRight: 2 }}>
                <Input
                    autoFocus={ownership.data.id == null}
                    kind='number'
                    disabled={isDisabled}
                    error={ownership.data.validationMessages.stockVolume?.tooltip.message}
                    forceError={saveAttempted}
                    label='Amount held'
                    onChange={v => actions.updateStockholdingOwnership(ownership.data.msoProductId, ownership.rowIndex, 'stockVolume', v)}
                    shouldFocus={ownershipFF?.field === 'stockVolume' && ownershipFF.rowIndex === ownership.rowIndex}
                    value={ownership.data.stockVolume}
                />
            </BoxedSpan>

            <BoxedSpan box={{ width: '450px', paddingLeft: 2, paddingRight: 1 }}>
                <Input
                    kind='string'
                    disabled={isDisabled}
                    error={ownership.data.validationMessages.entityName?.tooltip.message}
                    forceError={saveAttempted}
                    label='Name of entity the arrangement is with'
                    value={ownership.data.entityName}
                    onChange={v => actions.updateStockholdingOwnership(ownership.data.msoProductId, ownership.rowIndex, 'entityName', v)}
                    shouldFocus={ownershipFF?.field === 'entityName'}
                />
            </BoxedSpan>

            <BoxedSpan box={{ minWidth: '370px', paddingLeft: 2 }}>
                <Select
                    bare={true}
                    borderless={true}
                    error={ownership.data.validationMessages.msoOwnershipType?.tooltip.message}
                    focusless={true}
                    id={`${ownership.data.msoStockholdingId}_${stockholdingIndex}`}
                    isDisabled={isDisabled}
                    label='Stock ownership'
                    options={ownershipSelectCtrl.options}
                    onChange={ownershipSelectCtrl.onChange}
                    setFocused={ownershipFF?.field === 'msoOwnershipType'}
                    useTooltip={true}
                    value={ownershipSelectCtrl.value}
                />
            </BoxedSpan>

            <BoxedSpan box={{ alignItems: 'center', flex: 'row' }}>
                {
                    // Only show delete button if form is editable and product is active
                    (!isDisabled && product.productStatus === 'active') &&
                    <IconButton
                        color='dark-grey'
                        icon='trash'
                        label='Delete entitled'
                        onClick={() => onDelete(
                            'Are you sure you wish to delete this data?',
                            // TODO: fix
                            `${true ? 'Entitled to take ownership' : 'Reserved or quarantined stock'} record will be deleted`,
                            () => actions.deleteStockholdingOwnership(ownership.data.msoProductId, ownership.rowIndex, deleteRef)
                        )}
                        ref={setDeleteRef}
                        size='sm'
                        styles={{ marginLeft: '6px', marginRight: '-12px', padding: '4px' }}
                    />
                }
            </BoxedSpan>
        </BoxedDiv>
    )
}

interface ProductRowProps {
    actions: MsoStockholdingEditorActions;
    onDelete: UseConfirm['requestConfirmation'];
    dataSubmissionId: number;
    focusedField: unknown | null;
    hideEEZ?: boolean;
    isDisabled: boolean;
    portalData: UsePortalData;
    product: UseMsoRefData['products'][number];
    refData: UseMsoRefData;
    saveAttempted: boolean;
    stockholding: UpdateMsoStockholdingView | undefined;
    stockholdingIndex: number;
}

const ProductRow = ({
    actions,
    dataSubmissionId,
    focusedField,
    hideEEZ,
    isDisabled,
    onDelete,
    portalData,
    product,
    refData,
    saveAttempted,
    stockholding: maybeStockholding,
    stockholdingIndex
}: ProductRowProps) => {
    const stockholding = maybeStockholding || {
        dataSubmissionId,
        msoProductId: product.id,
        stockholdingOwnerships: [],
        validationMessages: {} as ModelValidation<UpdateMsoStockholdingField>,
    }

    const [deleteRef, setDeleteRef] = useState<HTMLElement | null>(null);

    const entitledType = useMemo(() => refData.stockOwnershipTypes.find(sot => sot.name === 'Entitled'), [refData]);
    const reservedType = useMemo(() => refData.stockOwnershipTypes.find(sot => sot.name === 'Reserved'), [refData]);

    const stockholdingFF = useMemo(() => (
        isStockholdingFocusField(focusedField) && focusedField.msoProductId === stockholding.msoProductId ? focusedField : null
    ), [focusedField, stockholding.msoProductId])

    if (
        product == null ||
        entitledType == null ||
        reservedType == null ||
        (
            (maybeStockholding == null || recordActionFromEnum(maybeStockholding.recordAction) === 'Delete') && product.productStatus === 'inactive'
        )
    ) {
        // TODO: log missing product
        return null;
    }

    const Help = portalData.productPortalData.find(ppd => ppd?.referenceCode === product.code)?.content

    return (
        <TR>
            <TH scope='row' verticalAlign='top'>
                <BoxedSpan box={{ alignItems: 'center', flex: 'row', width: '220px', height: '52px' }}>
                    <Text $noWrap={true}>{product.name}</Text>
                    {
                        !isDisabled && product.productStatus === 'inactive' ?
                            <BoxedSpan box={{ marginBottom: -0.5, marginLeft: 1 }}>
                                <FloatingMessage
                                    content={EXPIRED_OR_INTERNAL_PRODUCT}
                                    kind="warning"
                                    role="alert"
                                />
                            </BoxedSpan> :
                            Help != null ?
                                <TooltipHelp
                                    Help={<ReactMarkdown>{Help}</ReactMarkdown>}
                                /> :
                                null
                    }
                </BoxedSpan>
            </TH>

            <TD verticalAlign='top'>
                <Input
                    autoFocus={stockholdingIndex === 0}
                    kind='number'
                    disabled={isDisabled}
                    displayFixedDecimals={10}
                    error={stockholding.validationMessages.density?.tooltip.message}
                    forceError={saveAttempted}
                    label='Density (kg/L) (optional)'
                    value={stockholding.density}
                    onChange={v => actions.updateStockholding(stockholding.msoProductId, 'density', v)}
                    shouldFocus={stockholdingFF?.field === 'density'}
                />
            </TD>

            <TD verticalAlign='top'>
                <Input
                    kind='number'
                    disabled={isDisabled}
                    error={stockholding.validationMessages.holderOwnerStockVolume?.tooltip.message}
                    forceError={saveAttempted}
                    label='Holder and owner of stocks (s22)'
                    value={stockholding.holderOwnerStockVolume}
                    onChange={v => actions.updateStockholding(stockholding.msoProductId, 'holderOwnerStockVolume', v)}
                    shouldFocus={stockholdingFF?.field === 'holderOwnerStockVolume'}
                />
            </TD>

            <Ownerships
                actions={actions}
                isDisabled={isDisabled}
                onDelete={onDelete}
                focusedField={focusedField}
                ownerships={stockholding.stockholdingOwnerships}
                product={product}
                saveAttempted={saveAttempted}
                stockholdingIndex={stockholdingIndex}
            />

            <TD verticalAlign='top'>
                <Input
                    kind='number'
                    disabled={isDisabled}
                    error={stockholding.validationMessages.pipeline?.tooltip.message}
                    forceError={saveAttempted}
                    label='Pipeline'
                    value={stockholding.pipeline}
                    onChange={v => actions.updateStockholding(stockholding.msoProductId, 'pipeline', v)}
                    shouldFocus={stockholdingFF?.field === 'pipeline'}
                />
            </TD>

            {
                !hideEEZ &&
                <TD verticalAlign='top'>
                    <Input
                        kind='number'
                        disabled={isDisabled}
                        error={stockholding.validationMessages.eez?.tooltip.message}
                        forceError={saveAttempted}
                        label='EEZ stock'
                        value={stockholding.eez}
                        onChange={v => actions.updateStockholding(stockholding.msoProductId, 'eez', v)}
                        shouldFocus={stockholdingFF?.field === 'eez'}
                    />
                </TD>
            }

            {
                !isDisabled && product.productStatus === 'inactive' ?
                    <TD>
                        <DeleteRowButton
                            focusedField={focusedField}
                            onClick={() => onDelete(
                                'All data for this product will be deleted.',
                                'Are you sure you wish to delete this data?',
                                () => actions.deleteAllDataForProduct(product.id, deleteRef)
                            )}
                            productId={product.id}
                            ref={setDeleteRef}
                        />
                    </TD> :
                    null
            }

        </TR>
    );
}

interface DeleteRowProps {
    focusedField: unknown;
    onClick: () => any;
    productId: number;
}

const DeleteRowButton = React.forwardRef(({ focusedField, onClick, productId }: DeleteRowProps, ref) => {
    const focusable = useFocusable({
        setFocused: (
            isDeleteFocusField(focusedField) &&
            focusedField.kind === 'stockholding' &&
            focusedField.msoProductId === productId
        ),
    });

    const setRef = useCallback((el: HTMLElement | null) => {
        if (typeof ref === 'function') {
            ref(el);
        }
        focusable.setRef(el);
    }, [focusable, ref]);

    return (
        <IconButton
            color='dark-grey'
            icon='trash'
            label='Delete row for product'
            onClick={onClick}
            ref={setRef}
            size='sm'
        />
    );
});

export default MsoStockholdingEditor;

function isStockholdingFocusField(maybe?: unknown | null): maybe is StockholdingFocusField {
    if (maybe == null) {
        return false;
    }

    const asF = maybe as StockholdingFocusField;

    return (
        asF.kind === 'stockholding' &&
        asF.field != null &&
        asF.msoProductId != null
    );
}

function isOwnershipFocusField(maybe?: unknown | null): maybe is OwnershipFocusField {
    if (maybe == null) {
        return false;
    }

    const asF = maybe as OwnershipFocusField;

    return (
        asF.kind === 'ownership' &&
        asF.field != null &&
        asF.msoProductId != null &&
        asF.rowIndex != null
    );
}