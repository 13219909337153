import { asNumber } from "psims/lib/number";
import { DefProduct } from "psims/models/ref-data/def-product";
import { DefActivityField } from "psims/models/submission-types/def/def-activity";

type PartialActivity = {
  defProductId?: number | null;
  derivedFromBlending?: number | null;
  usedInBlending?: number | null;
}

type ProductStatus = 'active' | 'inactive' | 'inactive_with_data';

export type DefProductWithStatus = DefProduct &{
  productStatus: ProductStatus;
}

export const DATA_FIELDS = ['closingStocks', 'usedInBlending', 'derivedFromBlending', 'derivedFromOtherProduction', 'sales'] as const;

const FIELD_LABELS: {[key in DefActivityField]: string} = {
  closingStocks: 'closing stocks',
  derivedFromBlending: 'derived from blending',
  derivedFromOtherProduction: 'derived from other production',
  sales: 'sales',
  usedInBlending: 'used in blending',
};

export function getActivityFieldLabel(field: DefActivityField) {
  return FIELD_LABELS[field];
}

export function isDEFReferenceCode(referenceCode: string) {
  return referenceCode === 'DEF_DEF';
}

export function isDEFProduct(maybe: unknown): maybe is DefProduct {
  const product = maybe as DefProduct
  return product != null && isDEFReferenceCode(product.referenceCode);
}

export function getDEFProduct(defProducts: Array<DefProduct>) {
  return defProducts.find(p => isDEFReferenceCode(p.referenceCode));
}

export function isDEFActivity(activity: PartialActivity, defProducts: Array<DefProduct>) {
  const defProduct = getDEFProduct(defProducts);
  return activity.defProductId === defProduct?.id;
}

export function getDEFActivity(activities: Array<PartialActivity>, defProducts: Array<DefProduct>) {
  return activities.find(a => isDEFActivity(a, defProducts));
}

export function isTGUReferenceCode(referenceCode: string) {
  return referenceCode === 'DEF_TGU';
}

export function isTGUProduct(maybe: unknown): maybe is DefProduct {
  const product = maybe as DefProduct
  return product != null && isTGUReferenceCode(product.referenceCode);
}

export function getTGUProduct(defProducts: Array<DefProduct>) {
  return defProducts.find(p => isTGUReferenceCode(p.referenceCode));
}

export function isTGUActivity(activity: PartialActivity, defProducts: Array<DefProduct>) {
  const defProduct = getTGUProduct(defProducts);
  return activity.defProductId === defProduct?.id;
}

export function getTGUActivity(activities: Array<PartialActivity>, defProducts: Array<DefProduct>) {
  return activities.find(a => isTGUActivity(a, defProducts));
}

export function getProductForActivity(activity: {defProductId: number}, defProducts: Array<DefProduct>) {
  return defProducts.find(p => p.id === activity.defProductId);
}

// Relativity helpers
export function doesRelativityApply(activities: Array<PartialActivity>, defProducts: Array<DefProduct>) {
  const defActivity = getDEFActivity(activities, defProducts);
  const tguActivity = getTGUActivity(activities, defProducts);

  return (
    asNumber(defActivity?.derivedFromBlending) > 0 ||
    asNumber(tguActivity?.usedInBlending) > 0
  );
}
