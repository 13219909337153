import React from 'react';

import BaseSVG, { SVGProps } from './_base';

const FileUnknown = (props: SVGProps) => {
	return (
		<BaseSVG name='file-unknown' viewBox='0 0 576 512' {...props}>
			<path fill='currentColor' d="M0 0H224V160H384v38.6C310.1 219.5 256 287.4 256 368c0 59.5 29.6 112.1 74.8 144H0V0zM384 128H256V0L384 128zm48 384c-79.5 0-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144zM409.6 280c-23 0-41.6 18.6-41.6 41.6V328l32 0v-6.4c0-5.3 4.3-9.6 9.6-9.6h40.5c7.7 0 13.9 6.2 13.9 13.9c0 5.2-2.9 9.9-7.4 12.3l-32 16.8-8.6 4.5v9.7V376v16h32V378.9l23.5-12.3c15.1-7.9 24.5-23.6 24.5-40.6c0-25.4-20.6-45.9-45.9-45.9H409.6zM448 416H416v32h32V416z"/>
		</BaseSVG>
	);
};

export default FileUnknown;
