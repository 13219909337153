import { encodeEscapeChars } from "psims/lib/validation/string";
import { recordActionAsEnum } from "psims/models/api/data-submission-record-action";
import { MaybeUpdateMso, UpdateMso } from "psims/models/submission-types/mso/mso";
import { UpdateMsoImporterSubmission } from "psims/models/submission-types/mso/mso-importer-submission";
import { isUpdateMsoRefinerSubmission, UpdateMsoRefinerSubmission } from "psims/models/submission-types/mso/mso-refiner-submission";

type WithMsos<T> = T & {
    msos: Array<MaybeUpdateMso>;
}

export function mergeMsos<T>(submissionUpdate: WithMsos<T>, msos: Array<UpdateMso>) {
    return {
        ...submissionUpdate,
        msos: submissionUpdate.msos.map(m => ({
            ...(msos.find(mm => mm.msoOrganisationSettingId === m.msoOrganisationSettingId) || m),
            recordAction: recordActionAsEnum(m.id ? 'Update' : 'Create'),
        }))
    }
}

// Clense data - only include records with recordActions
export function cleanSubmission<T extends UpdateMsoImporterSubmission | UpdateMsoRefinerSubmission>(submissionUpdate: T): T {
    const result = {
        ...submissionUpdate,
        stockholdings: submissionUpdate.stockholdings
            .filter(sh => sh.recordAction != null)
            .map(sh => ({
                ...sh,
                stockholdingOwnerships: (sh.stockholdingOwnerships || [])
                .filter(so => so.recordAction != null)
            })),
        stocksOnBehalf: submissionUpdate.stocksOnBehalf
            .filter(sob => sob.recordAction != null),
        msoComment: submissionUpdate.msoComment?.recordAction != null ? {
            ...submissionUpdate.msoComment,
            comments: encodeEscapeChars(submissionUpdate.msoComment.comments?.trim()),
        } : null,
    };

    if (isUpdateMsoRefinerSubmission(submissionUpdate)) {
        return {
            ...result,
            stockRefineriesHeld: submissionUpdate.stockRefineriesHeld
                .filter(srh => srh.recordAction != null)
        }
    }

    return result;
}
