import { SPACE_BASE } from 'psims/constants/styles';
import randomID from 'psims/lib/random-id';
import React, { useState } from 'react';
import styled from 'styled-components';

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string | null;
  label?: string;
}

const Input = styled.input`
  height: 0;
  width: 0;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
`;

const LabelText = styled.span`
  font-weight: 600;
  margin-bottom: ${SPACE_BASE}px;
`;

const Field = styled.div`
  border: 1px solid var(--color-field-border);
  box-shadow: none;
  color: var(--color-black-80);
  cursor: pointer;
  padding: ${SPACE_BASE * 2}px;
  position: relative;
  transition: box-shadow 200ms ease-in-out;

  input:focus + & {
    box-shadow: var(--box-shadow-focus);
  }

  ${(props: {error?: string | null}) => `
    ${props.error != null ? 'box-shadow: var(--box-shadow-error)' : ''};
  `}

  &::after {
    border-radius: 4px;
    border: 1px solid var(--color-black-70);
    box-shadow: var(--box-shadow-lo);
    background: var(--color-white);
    color: var(--color-black-80);
    content: 'Browse';
    padding: 4px 16px;
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    margin: 8px;
    display: flex;
    align-items: center;
    transition: box-shadow 200ms ease-in-out;
  }

  input:focus + &::after,
  &:hover::after {
    box-shadow: var(--box-shadow-hi);
  }
`;

const Error = styled.span`
  color: var(--color-error-text);
  font-weight: 600;
  font-size: 14px;
  padding-top: ${SPACE_BASE}px;
`;

const FileInput = ({children, error, label, ...props}: React.PropsWithChildren<FileInputProps>) => {
  const [id] = useState(props.id || randomID());
  const [labelOrDefault] = useState(label || 'Select file');

  return (
    <Label htmlFor={id}>
      <LabelText>
        {labelOrDefault}
      </LabelText>

      <Input {...props} id={id} aria-errormessage={error || undefined} />

      <Field error={error}>
        {children}
      </Field>

      {
        error &&
        <Error>{error}</Error>
      }
    </Label>
  );
}

export default FileInput
;
