import { INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH } from "psims/constants/validation-messages";
import { ValidationResult } from "./mso-comment-validation";

export function getInlineMessage(validationResult: ValidationResult | null) {
  if (validationResult == null) {
    return undefined;
  }

  switch (validationResult.code) {
    case 'invalid_length':
      return INVALID_COMMENTS_LENGTH;
  
    case 'invalid_chars':
      return INVALID_COMMENTS_CHARACTERS;

    default:
      // TODO: use specific codes to say why comments required
      return 'Comments are required';
  }
}