import { BoxedDiv, BoxedSpan } from "psims/react/components/layout";
import { H2 } from "psims/react/components/typography";
import { UseFilterReportingHistory } from "./use-reporting-history-filter";
import Select from "psims/react/components/select";
import Button from "psims/react/components/button";
import Eraser from "psims/react/components/icons/eraser";
import { useMemo } from "react";

interface ReportingHistoryFilterProps {
    ctrl: UseFilterReportingHistory;
}

const ReportingHistoryFilter = (props: ReportingHistoryFilterProps) => {
    const vm = useVM(props);

    return (
        <div>
            <BoxedDiv box={{background: 'grey-faded', borderTop: {color: 'border-primary-faded', width: 3}, flex: 'column', paddingV: 3, paddingLeft:3}}>
                <BoxedDiv box={{flex: 'row', justifyContent: 'space-between', alignItems: "flex-start"}}>
                    <H2 marginV={1}>Filters</H2>
                    <Button $kind="text" onClick={() => {vm.resetFilters()}} marginRight={4}><BoxedSpan box={{marginRight: 1}}><Eraser size="sm"/></BoxedSpan> Reset</Button>
                </BoxedDiv>
                <BoxedDiv box={{flex: 'row'}}>
                    <Select
                        placeholder='All'
                        id='reportType'
                        label='Report type'
                        flexGrow={2}
                        marginLeft={2}
                        options={vm.selectReportTypeCtrl.options}
                        onChange={vm.selectReportTypeCtrl.onChange}
                        value={vm.selectReportTypeCtrl.value}
                            />
                    <Select
                        placeholder='All'
                        id='reportEntity'
                        label='Reporting entity'
                        flexGrow={2}
                        marginLeft={2}
                        options={vm.selectReportingEntityCtrl.options}
                        onChange={vm.selectReportingEntityCtrl.onChange}
                        value={vm.selectReportingEntityCtrl.value}
                    />
                    <Select
                        placeholder='All'
                        id='reportYear'
                        label='Reporting year'
                        flexGrow={2}
                        marginLeft={2}
                        options={vm.selectYearCtrl.options}
                        onChange={vm.selectYearCtrl.onChange}
                        value={vm.selectYearCtrl.value}
                    />
                    <Select
                        placeholder='All'
                        id='reportingFrequency'
                        label='Reporting frequency'
                        flexGrow={2}
                        marginLeft={2}
                        options={vm.selectFreqCtrl.options}
                        onChange={vm.selectFreqCtrl.onChange}
                        value={vm.selectFreqCtrl.value}
                    />
                    {
                        vm.showMonth &&
                        <Select
                            placeholder='All'
                            id='reportMonth'
                            label='Reporting month'
                            flexGrow={2}
                            marginLeft={2}
                            options={vm.selectReportMonthCtrl.options}
                            onChange={vm.selectReportMonthCtrl.onChange}
                            value={vm.selectReportMonthCtrl.value}
                        />
                    }
                    
                    <Select
                        placeholder='All'
                        id='reportStatus'
                        label='Status'
                        flexGrow={2}
                        marginLeft={2}
                        marginRight={5}
                        options={vm.selectStatusCtrl.options}
                        onChange={vm.selectStatusCtrl.onChange}
                        value={vm.selectStatusCtrl.value}
                    />
                </BoxedDiv>
            </BoxedDiv>
        </div>
    );
}

function useVM(
    {
        ctrl: {
            selectReportTypeCtrl,
            selectReportMonthCtrl,
            selectReportingEntityCtrl,
            selectStatusCtrl,
            selectYearCtrl,
            selectFreqCtrl,
            actions,
            query,
            resetFilters
        }
    }: ReportingHistoryFilterProps
) {

    const showMonth = useMemo(() => {
        return query.reportFreq !== 4;
    }, [query.reportFreq]);

    return {
        selectReportTypeCtrl,
        selectReportMonthCtrl,
        selectReportingEntityCtrl,
        selectStatusCtrl,
        selectYearCtrl,
        selectFreqCtrl,
        actions,
        query,
        showMonth,
        resetFilters
    };
}

export default ReportingHistoryFilter;