import { Dialog as ReachDialog, DialogProps as ReachDialogProps } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { SPACE_BASE } from 'psims/constants/styles';
import styled from 'styled-components';
import Button from './button';
import Close from './icons/close';

const StyledDialog = styled(ReachDialog)`
    display: flex;
    flex-direction: column-reverse;
    max-width: 750px;
    max-height: 70vh;
    overflow: auto;
    position: relative;

    @media (max-width: 1440px) {
        width: 75vw;
    }
`;

interface DialogProps extends ReachDialogProps {
    hideCloseButton?: boolean;
}

const Dialog = ({children, hideCloseButton, ...rest}: DialogProps) => {
    return (
        <StyledDialog {...rest}>
            {children}
            {!hideCloseButton && <CloseButton onDismiss={rest.onDismiss} />}
        </StyledDialog>
    )
}

interface CloseButtonProps {
    onDismiss: ReachDialogProps['onDismiss'];
}

const StyledCloseButton = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: ${SPACE_BASE * 2}px;
    width: 100%;

    & svg {
        color: var(--color-primary-interactive);
    }
`;

const CloseButton = ({onDismiss}: CloseButtonProps) => {
    return (
        <StyledCloseButton>
            <Button
                $kind='unstyled'
                aria-label='Close dialog'
                onClick={onDismiss}
                tabIndex={0}
            >
                <Close aria-hidden='true' size='lg' />
            </Button>
        </StyledCloseButton>
    )
}

export default Dialog;
