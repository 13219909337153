import { useEffect, useMemo, useState } from "react";
import EnsureId from "../shared/ensure-id";
import LoadSubmissionFailed from "../shared/load-submission-failed";
import LoadingSubmission from "../shared/loading-submission";
import useSubmissionLoadState from "../shared/use-submission-load-state";
import DefEditor from "./def-editor";
import useDefAPI, { UseDefAPI } from "./use-def-api";
import useDefRefData, { isUseDefRefData, UseDefRefData } from "./use-def-ref-data";

interface DefProps {
  id: number;
}

const DefPage = (props: DefProps) => {
  const vm = useVM(props);

  switch (vm.loadState) {
    case 'error':
      return <LoadSubmissionFailed submissionName='Diesel exhaust fluid' />
    case 'loaded':
      return <DefEditor
        // TODO: should type assert apiCtrl
        apiCtrl={vm.apiCtrl as PopulatedProps<UseDefAPI, 'submission'>}
        refData={vm.refData as UseDefRefData}
      />;
    default:
      return <LoadingSubmission submissionName='Diesel exhaust fluid' />;
  }
}

function useVM(props: DefProps) {
  const apiCtrl = useDefAPI({ id: props.id });
  const refData = useDefRefData({submission: apiCtrl.submission});
  const [loaded, setLoaded] = useState(false);

  const ready = useMemo(() => {
    return loaded && refData.defProducts.length > 0 && isUseDefRefData(refData);
  }, [loaded, refData])

  const loadState = useSubmissionLoadState({ submission: apiCtrl.submission, isDepsReady: ready, loadStatus: apiCtrl.loadStatus })

  const loadStateWithRefDataCheck = useMemo(() => {
    if (refData.status === 'ready' && refData.defProducts.length < 1) {
      return 'error';
    }

    return loadState;
  }, [loadState, refData.status, refData.defProducts.length]);

  useEffect(() => {
    if (apiCtrl.loadStatus === 'fetched') {
      setLoaded(true);
    }
  }, [apiCtrl.loadStatus]);

  return {
    apiCtrl,
    loadState: loadStateWithRefDataCheck as typeof loadState,
    refData,
  }
}

const Page = (props: DefProps) => <EnsureId id={props.id} Component={DefPage} />;

export default Page;
