import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { isEmpty } from 'psims/lib/empty';
import useFocusable from 'psims/react/util/use-focusable';
import useIsDirty from 'psims/react/util/use-is-dirty';
import useIsFocused from 'psims/react/util/use-is-focused';
import { FieldProps, fieldStyle } from 'psims/style/field';
import FormField, { FormFieldProps } from '../form-field';

interface TextareaProps extends FieldProps, FormFieldProps, Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'height' | 'width' | 'value'> {
	bare?: boolean;
	disableResize?: boolean;
	error?: string;
	forceError?: boolean;
	formatter?: (val: string) => string;
	id: string;
	initialDirty?: boolean;
	label: string;
	// ref?: (el: HTMLTextAreaElement | null) => any;
	setFocused?: boolean;
	value: string;
	hasCharacterLimit?: boolean;
}


const Textarea = React.forwardRef(({bare, error, forceError, initialDirty, hasCharacterLimit, onBlur, onFocus, value, ...rest}: TextareaProps, ref) => {
	const dirtyCtrl = useIsDirty(initialDirty, onBlur);
	const passedError = forceError || dirtyCtrl.isDirty ? error : undefined;
	const showCharacterCount = (hasCharacterLimit === undefined || hasCharacterLimit === true);
	
	if (bare) {
		return <TextareaBase error={passedError} onFocus={dirtyCtrl.handleFocus} value={value ?? ''} {...rest} ref={ref} />
	}

    let counterText = '';
	let hasExceededMaximum = false;
    if (showCharacterCount) {
        const currCharacters = value ? value.trim().length : 0;
        counterText = `${currCharacters} of 4000 characters used`;
        hasExceededMaximum = (currCharacters > 4000);
    }

	return (
		<FormField
			error={passedError}
			{...(showCharacterCount ? {statusText: {label: counterText, kind: hasExceededMaximum ? 'warn' : 'subtle'}} : {})}
			{...rest}
		>
			<TextareaBase error={passedError} onFocus={dirtyCtrl.handleFocus} value={value ?? ''} {...rest} ref={ref} />
		</FormField>
	);
});


const StyledTextarea = styled.textarea`${(props: TextareaBaseProps) => `
`}${fieldStyle}
	height: auto;
	font-family: inherit;
	${(props) => `${props.disableResize ? 'resize: none;' : ''}`}
`;

interface TextareaBaseProps extends TextareaProps {
	id: string;
	label: string;
}

const TextareaBase = React.forwardRef(({formatter, onBlur, onFocus, value, ...props}: TextareaBaseProps, ref) => {
	const {setRef: setFocusableRef} = useFocusable({setFocused: props.setFocused});
	const {isFocused, handleBlur, handleFocus} = useIsFocused({onBlur, onFocus});
	const hasCustomFormatting = typeof formatter === 'function';

	const displayValue = useMemo(() => {
		return hasCustomFormatting && !isEmpty(value) ? formatter!(value) : value ?? '';
	}, [formatter, hasCustomFormatting, value]);

	const consolidatedRef = useCallback((el: HTMLTextAreaElement | null) => {
		setFocusableRef(el);

		if (ref != null && typeof ref === 'function') {
			ref(el);
		}
	}, [ref, setFocusableRef]);

	return (
		<StyledTextarea
			aria-label={props.label}
			title={props.label}
			onBlur={handleBlur}
			onFocus={handleFocus}
			{...props}
			ref={consolidatedRef}
			value={isFocused ? (value ?? '') : displayValue}
		/>
	)
});

export default Textarea;
