import { INVALID_COMMENTS_CHARACTERS, INVALID_COMMENTS_LENGTH, INVALID_COMMENTS_REQUIRED } from "psims/constants/validation-messages";
import { isEmpty } from "psims/lib/empty";
import { validateCommentCharacters, validateCommentMaxLength } from "psims/lib/validation/comments";
import { UpdateFspEligibleTotals, UpdateFspEligibleTotalsField } from "psims/models/submission-types/fsp/eligible-totals";

type ValidationResultRequired = {
  code: 'invalid_required';
}

type ValidationResultInvalidChars = {
  code: 'invalid_chars';
}

type ValidationResultInvalidLength = {
  code: 'invalid_length';
}

export type ValidationResult = {
  field: UpdateFspEligibleTotalsField;
  message: string;
} & (ValidationResultRequired | ValidationResultInvalidChars | ValidationResultInvalidLength);

const COMMENTS_MESSAGES: {[k in ValidationResult['code']]: string} = {
  invalid_chars: INVALID_COMMENTS_CHARACTERS,
  invalid_length: INVALID_COMMENTS_LENGTH,
  invalid_required: INVALID_COMMENTS_REQUIRED,
}

export function validateFspEligibleTotals(draft: Partial<UpdateFspEligibleTotals>, isCommentsRequired: boolean) {
  const validationResults: Array<ValidationResult> = [];

  if (isCommentsRequired && isEmpty(draft.reportComments)) {
    validationResults.push({
      field: 'reportComments',
      code: 'invalid_required',
      message: COMMENTS_MESSAGES['invalid_required'],
    });
  }

  if (draft.reportComments == null) {
    return validationResults;
  }

  const trimmedComments = draft.reportComments.trim();

  if (validateCommentMaxLength(trimmedComments) != null) {
    validationResults.push({
      field: 'reportComments',
      code: 'invalid_length',
      message: COMMENTS_MESSAGES['invalid_length'],
    });
  }
  
  if (validateCommentCharacters(trimmedComments) != null) {
    validationResults.push({
      field: 'reportComments',
      code: 'invalid_chars',
      message: COMMENTS_MESSAGES['invalid_chars'],
    });
  }

  return validationResults;
}
