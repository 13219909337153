import { isEmpty } from "psims/lib/empty";
import SupportLink from "psims/react/components/support-link";

interface FileNotSupportedProps {
    fileName?: string | null;
}

const FileNotSupported = (props: FileNotSupportedProps) => {
    const prefix = isEmpty(props.fileName) ? 'The file you selected' : `File ${props.fileName}`;
    return <>
        <p>{prefix} is not a supported format.</p>
        <p>Please check your import file and try again. If not successful, contact the department at <SupportLink>PSIMSSupport@industry.gov.au</SupportLink>.</p>
    </>;
}

export default FileNotSupported;