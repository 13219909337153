// import { recordActionAsEnum} from "psims/models/api/data-submission-record-action";
import { is } from "psims/lib/type-assertions";
import { DefProduct } from "psims/models/ref-data/def-product";
import { DefActivity, DefActivityField } from "psims/models/submission-types/def/def-activity";
import { DefSubmission } from "psims/models/submission-types/def/def-submission";
import { Draft } from "psims/react/pages/primary-pages/data-submissions/shared/use-updateable-list";
import { DefValidationAlert, isDefValidationAlert } from "../types";
import { DATA_FIELDS, doesRelativityApply, isDEFActivity, isTGUReferenceCode } from "./util";

type ValidationAlertResultRelativity = {
  code: 'relativity_for_derived_def';
  relatedField: 'usedInBlending';
}

type ValidationAlertResultVariance = {
  code: 'percent_variance';
  field: DefActivityField;
  product: DefProduct;
  percentVariance: number | undefined | null;
}

export type ValidationAlertResult = {
  field: DefActivityField;
} & (ValidationAlertResultRelativity | ValidationAlertResultVariance)

const RELATIVITY_VALIDATION_ALERT: ValidationAlertResult = {
  code: 'relativity_for_derived_def',
  field: 'derivedFromBlending',
  relatedField: 'usedInBlending'
};

type DraftActivity = Draft<DefActivity> & {
  fieldsState: {[key in typeof DATA_FIELDS[number]]: 'changed' | 'unchanged'};
}

export function getValidationAlertsForActivity(
  activity: DraftActivity,
  allActivities: Array<Draft<DefActivity>>,
  products: Array<DefProduct>,
  submission: DefSubmission
): Array<ValidationAlertResult> {
  const targetProduct = products.find(p => p.id === activity.defProductId);
  const tguProduct = products.find(p => isTGUReferenceCode(p.referenceCode));

  const validationAlerts: Array<ValidationAlertResult> = [];

  if (targetProduct == null) {
    // Unexpected path - should log?
    return validationAlerts;
  }

  if (doesRelativityApply(allActivities, products)) {
    const tguDraft = allActivities.find(d => d.defProductId === tguProduct?.id);

    if (isDEFActivity(activity, products) && tguDraft != null) {
      if (tguDraft.usedInBlending) {
        const bounds = getDerivedDefFromBlendingBounds(tguDraft.usedInBlending);
        if (activity.derivedFromBlending == null || (activity.derivedFromBlending < bounds.min || activity.derivedFromBlending > bounds.max)) {
          validationAlerts.push(RELATIVITY_VALIDATION_ALERT);
        }
      }
    }
  }

  const apiValidationAlerts = submission.activities
    .filter(a => (
      a.validationAlerts != null &&
      a.id === activity.id
    ))
    .map(a => ({
      activity: a,
      vas: (a.validationAlerts || [])
        .filter(is)
        .filter(isDefValidationAlert)
        .map(validationAlert => mapValidationAlertToResult(validationAlert, targetProduct, submission.dataSubmission))
    }))
    .map(a => a.vas)
    .flat()
    .filter(va => activity.fieldsState[va.field] === 'unchanged');

  return [
    ...validationAlerts,
    ...apiValidationAlerts,
  ];
}

const TGU_TO_DEF_MULTIPLIER_BOUNDS = {
  min: 2.7,
  max: 2.9,
} as const;

function getDerivedDefFromBlendingBounds(tguUsed: number) {
  return {
    min: Math.round(tguUsed * TGU_TO_DEF_MULTIPLIER_BOUNDS.min),
    max: Math.round(tguUsed * TGU_TO_DEF_MULTIPLIER_BOUNDS.max),
  };
}

type WithVariances = {
  reportVariances?: {
    stockVarianceDef?: number | null;
    salesVarianceDef?: number | null;
    productionVarianceDef?: number | null;
  }
}

function getPercentVariance(dataSubmission: WithVariances, va: DefValidationAlert) {
  if (dataSubmission.reportVariances == null) {
    return null;
  }

  const {productionVarianceDef, salesVarianceDef, stockVarianceDef} = dataSubmission.reportVariances
  const {validationAlert} = va;

  if (validationAlert === 'DerivedFromOtherProductionVarianceApplied') {
    return productionVarianceDef;
  }
  
  if (validationAlert === 'SalesVarianceApplied') {
    return salesVarianceDef;
  }

  return stockVarianceDef;
}

function mapValidationAlertToResult(va: DefValidationAlert, product: DefProduct, dataSubmission: WithVariances): ValidationAlertResultVariance {
  return {
    code: 'percent_variance',
    field: va.validationAlert === 'SalesVarianceApplied' ? 'sales' : va.validationAlert === 'DerivedFromOtherProductionVarianceApplied' ? 'derivedFromOtherProduction' : 'closingStocks',
    product,
    percentVariance: getPercentVariance(dataSubmission, va),
  }
}

export function isValidationAlertResultRelativity(maybe?: unknown): maybe is ValidationAlertResultRelativity {
  const maybeAs = maybe as ValidationAlertResultRelativity;

  return (
    maybeAs != null &&
    maybeAs.code === 'relativity_for_derived_def'
  );
}

export function isValidationAlertResultPercentVariance(maybe?: unknown): maybe is ValidationAlertResultVariance {
  const maybeAs = maybe as ValidationAlertResult;

  return (
    maybeAs != null &&
    maybeAs.code === 'percent_variance' &&
    maybeAs.field != null &&
    maybeAs.product != null
  );
}
