import { OrganisationContact, UpdateOrganisationContact } from "psims/models/organisation-contact";
import { useReferenceData } from "psims/react/providers/api/reference-data";
import { useSelectController } from "psims/react/components/select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { asString } from "psims/lib/string";
import { validateEmail, validateFirstName, validateAddress1, validateLastName, validateCity, validatePostcode, validatePosition, validatePhone, validateAddress2 } from "psims/lib/validation/user";
import { DUPLICATE_EMAIL, INVALID_STATE } from "psims/constants/validation-messages";
import { recordActionAsEnum } from "psims/models/api/record-action";

type ValidationMessages = {
    [key in keyof OrganisationContact]?: string;
}

interface UseEditCompanyContactProps {
    organisationContact: UpdateOrganisationContact | null | undefined,
    isMandatory: boolean,
}

function useEditCompanyContact({organisationContact, isMandatory}: UseEditCompanyContactProps) {

    const [organisationContactUpdate, setOrganisationContactUpdate] = useState<UpdateOrganisationContact | null | undefined>(organisationContact);
    const {data: refData} = useReferenceData();
    const [excludedEmailAddress, setExcludedEmailAddress] = useState<string | null>(null);
    const stateOptions = useMemo(() => {
        if (refData?.states == null) {
            return [];
        }

        let options = refData.states
        .filter(state => state.name !== 'Other')
        .map(state => ({
            label: asString(state.name),
            value: state.id as number || null,
        }));

        options.unshift({label: 'Select', value: null});
        return options;
    }, [refData]);

    const validationMessages = useMemo(() => {
        const messages: ValidationMessages = {};
        
        if(isMandatory || isValidOptional(organisationContactUpdate!)) {
            const emailMsg = validateEmail(organisationContactUpdate?.email);
            if (emailMsg !== null) {
                messages.email = emailMsg;
            }
            else if (organisationContactUpdate?.email?.toLowerCase() === excludedEmailAddress?.toLowerCase()){
                messages.email = DUPLICATE_EMAIL
            }
        
            const firstNameMsg = validateFirstName(organisationContactUpdate?.firstName);
            if (firstNameMsg !== null) {
                messages.firstName = firstNameMsg;
            }
    
            const lastNameMsg = validateLastName(organisationContactUpdate?.lastName);
            if (lastNameMsg !== null) {
                messages.lastName = lastNameMsg;
            }

            if(organisationContactUpdate?.phoneNumber){
               const phoneNumberMsg = validatePhone(organisationContactUpdate?.phoneNumber);
               if (phoneNumberMsg !== null) {
                   messages.phoneNumber = phoneNumberMsg;
               } 
            }

            const positionMsg = validatePosition(organisationContactUpdate?.position);
            if (positionMsg !== null) {
                messages.position = positionMsg;
            }
    
            if(shouldValidateAddress(organisationContactUpdate!)){
                const address1Msg = validateAddress1(organisationContactUpdate?.addressLine1);
                if (address1Msg !== null) {
                    messages.addressLine1 = address1Msg;
                }

                const address2Msg = validateAddress2(organisationContactUpdate?.addressLine2, organisationContactUpdate?.addressLine1);
                if (address2Msg !== null) {
                    messages.addressLine2 = address2Msg;
                }
    
                const cityMsg = validateCity(organisationContactUpdate?.city);
                if (cityMsg !== null) {
                    messages.city = cityMsg;
                }
                    
                if (!Boolean(organisationContactUpdate?.stateId)) {
                    messages.stateId = INVALID_STATE;
                }
        
                const postcodeMsg = validatePostcode(organisationContactUpdate?.postcode);
                if (postcodeMsg !== null) {
                    messages.postcode = postcodeMsg;
                }
            }
            else{
                messages.addressLine1 = '';
                messages.addressLine2 = '';
                messages.city = '';
                messages.stateId = '';
                messages.postcode = '';
            }
        }

        return messages;
    }, [organisationContactUpdate, excludedEmailAddress, isMandatory]);

    const updateExcludedEmail = useCallback((email) => {
        if(organisationContactUpdate?.email === email){
            return;
        }

        setExcludedEmailAddress(email);
    }, [organisationContactUpdate?.email]);

    const selectStateCtrl = useSelectController({
        value: organisationContactUpdate?.stateId || null, //todo: needs to be change 
        onChange: stateId => updateOrganisationContact('stateId', stateId || undefined),
        options: stateOptions,
    });

    function remove(){
        updateOrganisationContact('firstName', '', true);
        updateOrganisationContact('lastName', '', true);
        updateOrganisationContact('position', '', true);
        updateOrganisationContact('phoneNumber', '', true);
        updateOrganisationContact('email', '', true);
        updateOrganisationContact('addressLine1', '', true);
        updateOrganisationContact('addressLine2', '', true);
        updateOrganisationContact('city', '', true);
        updateOrganisationContact('postcode', '', true);
        updateOrganisationContact('stateId', null, true);
    }

    function updateOrganisationContact<K extends keyof UpdateOrganisationContact>(field: K, value: UpdateOrganisationContact[K], toBeRemoved?:boolean) {
        setOrganisationContactUpdate(old => {
            if (old == null) {
                return null;
            }
            return {
                ...old,
                [field]: value,
                recordAction: old.id == null ? isValidContactToBeCreated(organisationContactUpdate!) ? recordActionAsEnum('Create') : undefined : toBeRemoved ? recordActionAsEnum('Delete') :  recordActionAsEnum('Update')
            };
        });
    }

    useEffect(() => {
        setOrganisationContactUpdate(organisationContact);
    }, [organisationContact])

    return {
        organisationContactUpdate,
        selectStateCtrl,
        validationMessages,
        updateOrganisationContact,
        updateExcludedEmail,
        remove,
    }
}

function isValidContactToBeCreated(organisationContactUpdate: UpdateOrganisationContact): boolean{
    if(organisationContactUpdate?.id){
        return false;
    }
    else if(organisationContactUpdate?.firstName &&
            organisationContactUpdate?.lastName
        )
        {
            return true;
        }

    return false;
}

function isValidOptional(organisationContactUpdate: UpdateOrganisationContact): boolean{
    if (organisationContactUpdate?.recordAction === recordActionAsEnum('Delete')){
        return false;
    }
    if(organisationContactUpdate?.id){
        return true;
    }
    else if(organisationContactUpdate?.firstName ||
            organisationContactUpdate?.lastName ||
            organisationContactUpdate?.email ||
            organisationContactUpdate?.addressLine1 ||
            organisationContactUpdate?.stateId ||
            organisationContactUpdate?.postcode ||
            organisationContactUpdate?.city ||
            organisationContactUpdate?.position
        )
        {
            return true;
        }

    return false;
}

function shouldValidateAddress(organisationContactUpdate: UpdateOrganisationContact): boolean{
    if (organisationContactUpdate?.addressLine1 ||
        organisationContactUpdate?.stateId ||
        organisationContactUpdate?.city ||
        organisationContactUpdate?.postcode){
            return true;
        }

    return false;
}

export default useEditCompanyContact;

export type UseEditCompanyContact = ReturnType<typeof useEditCompanyContact>;