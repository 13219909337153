import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BoxedDiv } from '../components/layout';
import { APP_NAME } from 'psims/constants/app';
import { getBreadcrumbs } from 'psims/constants/pages';
import { usePage } from '../providers/page';
import GlobalFooter from './global-footer';
import GlobalHeader from './global-header';
import UncaughtError from './uncaught-error';

interface PageProps {
	className?: string;
	hideNavbar?: boolean;
	hideUserBlock?: boolean;
};

const SkipToContent = styled.a`
	background: var(--color-white);
	height: 30px;
	left: 50%;
	padding: 8px;
	position: absolute;
	transform: translateY(-100%);
	transition: transform 0.3s;

	&:focus {
		transform: translateY(0%);
	}
`;

const StyledPage = styled.div`
	display: grid;
	grid-template-rows: auto 1fr auto;
	height: 100%;
	overflow: auto;
`;

const Page = (props: React.PropsWithChildren<PageProps>) => {
	const {page} = usePage();
	const breadcrumbs = getBreadcrumbs(page);

	useEffect(() => {
		document.title = `${APP_NAME} - ${page.label}`;
	}, [page.label]);

	return (
		<UncaughtError Wrapper={ErrorWrapper}>
			<StyledPage id='page' {...props}>
				<SkipToContent href='#main_content'>Skip to content</SkipToContent>
				<GlobalHeader breadcrumbs={breadcrumbs} hideNavbar={props.hideNavbar} hideUserBlock={props.hideUserBlock} />

				<BoxedDiv id="main_content" box={{flex: 'column', flexGrow: 1}}>
					{props.children}
				</BoxedDiv>

				<GlobalFooter />
			</StyledPage>
		</UncaughtError>
	);
};

const ErrorWrapper = ({children}: React.PropsWithChildren<{}>) => (
	<StyledPage id='page'>
		<SkipToContent href='#main_content'>Skip to content</SkipToContent>
		<GlobalHeader breadcrumbs={undefined} hideNavbar={true} hideUserBlock={true} />

		<BoxedDiv id="main_content" box={{flex: 'column', flexGrow: 1}}>
			{children}
		</BoxedDiv>

		<GlobalFooter />
	</StyledPage>
);

export default Page;
