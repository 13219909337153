export function compare<T>(a: T, b:T, treatEmptyAndNullishAsEqual?: boolean) {
  if (a === b) {
    return true;
  }

  if (treatEmptyAndNullishAsEqual) {
    if (
      (typeof a === 'string' && a.length === 0 && b == null) ||
      (typeof b === 'string' && b.length === 0 && a == null) ||
      (a == null && b == null)
    ) {
      return true;
    }
  }

  return false;
}