import { useCallback, useEffect, useMemo, useState } from "react";

import { localeNumberWithFixed } from "psims/lib/formatters/numbers";
import { FspDataSubmission } from "psims/models/submission-types/fsp/fsp-submission";
import useFieldState from "psims/react/util/use-field-state";
import { ViewMode } from "psims/react/pages/primary-pages/data-submissions/shared/use-view-mode";
import { UseEligibleProductions } from "../eligible-productions/use-eligible-productions";
import { isUpdateFspEligibleTotals } from "psims/models/submission-types/fsp/eligible-totals";
import { recordActionAsEnum } from "psims/models/api/data-submission-record-action";
import { validateFspEligibleTotals } from "./validate-total-quarterly-eligible-fssp";
import { encodeEscapeChars } from "psims/lib/validation/string";

interface UseTotalQuarterlyElibibleFsspProps {
  eligibleProductionsCtrl: UseEligibleProductions;
  isActivePage: boolean;
  submission: FspDataSubmission;
  viewMode: ViewMode;
}

// What I do:
// - provide view for total table
// - handle updating of totals comments
function useTotalQuarterlyElibibleFssp({eligibleProductionsCtrl, isActivePage, submission, viewMode}: UseTotalQuarterlyElibibleFsspProps) {
  const commentsCtrl = useFieldState({initialValue: isActivePage ? submission.totalEligibleProduction.reportComments : undefined});
  const [comments, setComments] = useState(isActivePage ? submission.totalEligibleProduction.reportComments : undefined);

  const isCommentsRequired = useMemo(() => {
    return eligibleProductionsCtrl.data.some(d => d.validationAlerts.length > 0);
  }, [eligibleProductionsCtrl.data]);

  const view = useMemo(() => {
    return {
      totalTonnes: localeNumberWithFixed(submission.totalEligibleProduction.totalEligibleTonnes, 3),
      totalKilolitres: localeNumberWithFixed(submission.totalEligibleProduction.totalEligibleKilolitres, 3),
    }
  }, [submission.totalEligibleProduction]);

  const validationResults = useMemo(() => {
    return validateFspEligibleTotals({reportComments: comments}, isCommentsRequired);
  }, [comments, isCommentsRequired]);

  const isValid = useMemo(() => {
    return validationResults.length === 0;
  }, [validationResults]);

  const hasUnsavedChanges = useMemo(() => {
    return commentsCtrl.dataState === 'changed';
  }, [commentsCtrl.dataState]);

  const commentsError = useMemo(() => {
    return validationResults[0]?.message;
  }, [validationResults]);

  const getRequestPayload = useCallback(() => {
    if (commentsCtrl.dataState === 'changed') {
      const payload = {
        concurrencyToken: submission.totalEligibleProduction.concurrencyToken,
        dataSubmissionId: submission.dataSubmission.id,
        recordAction:  recordActionAsEnum((submission.totalEligibleProduction.id || 0) > 0 ? 'Update' : 'Create'),
        reportComments: encodeEscapeChars(comments?.trim()),
        id: submission.totalEligibleProduction.id || null,
      }

      return isUpdateFspEligibleTotals(payload) ? payload : undefined;
    }

    return undefined;
  }, [comments, commentsCtrl.dataState, submission.dataSubmission.id, submission.totalEligibleProduction]);

  useEffect(() => {
    if (isActivePage) {
      setComments(submission.totalEligibleProduction.reportComments);
    }
  }, [isActivePage, submission.totalEligibleProduction.reportComments]);

  return {
    comments,
    commentsCtrl,
    commentsError,
    hasUnsavedChanges,
    isCommentsRequired,
    isValid,
    validationResults,
    view,
    viewMode,
    getRequestPayload,
    setComments,
  };
}

export default useTotalQuarterlyElibibleFssp

export type UseTotalQuarterlyElibibleFssp = ReturnType<typeof useTotalQuarterlyElibibleFssp>;
