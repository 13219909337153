import { assertArrayOfType } from "psims/lib/collections";
import { assertNotEmpty } from "psims/lib/empty";
import { assertContent, Content } from "./content";
import { assertCountry, Country } from "./country";
import { assertDocumentStatus, DocumentStatus } from "./document-status";
import { assertDocumentType, DocumentType } from "./document-type";
import { DefProduct } from "./def-product";
import { assertExternalReportStatus, ExternalReportStatus } from "./external-report-status";
import { assertMsoProduct, MsoProduct } from "./mso-product";
import { assertMsoStockOwnershipType, MsoStockOwnershipType } from "./mso-stock-ownership-type";
import { assertStorageType, StorageType } from "./mso-storage-type";
import { assertProductionArea, ProductionArea } from "./production-area";
import { assertProductionAreaState, ProductionAreaState } from "./production-area-state";
import { assertProductionProduct, ProductionProduct } from "./production-product";
import { assertProductionProductGroup, ProductionProductGroup } from "./production-product-group";
import { assertProductionType, ProductionType } from "./production-type";
import { assertRefineryProduct, RefineryProduct } from "./refinery-product";
import { assertRefineryProductGroup, RefineryProductGroup } from "./refinery-product-group";
import { assertRefineryType, RefineryType } from "./refinery-type";
import { assertReportFrequencyType, ReportFrequencyType } from "./report-frequency-type";
import { assertRoleType, RoleType } from "./role-type";
import { assertState, State } from "./state";
import { assertStockProduct, StockProduct } from "./stock-product";
import { assertStockProductGroup, StockProductGroup } from "./stock-product-group";
import { assertStockType, StockType } from "./stock-type";
import { SubmissionType } from "./submission-type";
import { assertUserStatus, UserStatus } from "./user-status";
import { assertWholesaleProduct, WholesaleProduct } from "./wholesale-product";
import { assertWholesaleProductGroup, WholesaleProductGroup } from "./wholesale-product-group";
import { assertWholesaleType, WholesaleType } from "./wholesale-type";
import { assertFspProduct, FspProduct } from "./fsp-product";
import { assertFspRefineryProduct, FspRefineryProduct } from "./fsp-refinery-product";

export type AllReferenceData = {
    content: Array<Content>;
    countries: Array<Country>;
    documentStatuses: Array<DocumentStatus>;
    documentTypes: Array<DocumentType>;
    defProducts: Array<DefProduct>;
    externalReportStatuses: Array<ExternalReportStatus>;
    fsspProducts: Array<FspProduct>;
    fsspRefineryProducts: Array<FspRefineryProduct>;
    msoProducts: Array<MsoProduct>
    msoStockOwnershipTypes: Array<MsoStockOwnershipType>;
    productionAreaStates: Array<ProductionAreaState>;
    productionAreas: Array<ProductionArea>;
    productionProductGroups: Array<ProductionProductGroup>;
    productionProducts: Array<ProductionProduct>;
    productionTypes: Array<ProductionType>;
    refineryProductGroups: Array<RefineryProductGroup>;
    refineryProducts: Array<RefineryProduct>;
    refineryTypes: Array<RefineryType>;
    reportFrequencyTypes: Array<ReportFrequencyType>;
    roleTypes: Array<RoleType>;
    states: Array<State>;
    stockProductGroups: Array<StockProductGroup>;
    stockProducts: Array<StockProduct>;
    stockTypes: Array<StockType>;
    storageTypes: Array<StorageType>;
    submissionTypes: Array<SubmissionType>;
    userStatuses: Array<UserStatus>;
    wholesaleProductGroups: Array<WholesaleProductGroup>;
    wholesaleProducts: Array<WholesaleProduct>;
    wholesaleTypes: Array<WholesaleType>;
}

export function assertAllReferenceData(maybe: unknown): asserts maybe is AllReferenceData {
    const maybeAs = maybe as AllReferenceData;

    assertNotEmpty(maybeAs, 'allReferenceData');
    assertArrayOfType(assertCountry, onlyActive(maybeAs.countries));
    assertArrayOfType(assertContent, maybeAs.content);
    assertArrayOfType(assertDocumentStatus, maybeAs.documentStatuses);
    assertArrayOfType(assertDocumentType, maybeAs.documentTypes);
    assertArrayOfType(assertExternalReportStatus, maybeAs.externalReportStatuses);
    assertArrayOfType(assertFspProduct, maybeAs.fsspProducts);
    assertArrayOfType(assertFspRefineryProduct, maybeAs.fsspRefineryProducts);
    assertArrayOfType(assertMsoProduct, onlyActive(maybeAs.msoProducts));
    assertArrayOfType(assertMsoStockOwnershipType, onlyActive(maybeAs.msoStockOwnershipTypes));
    assertArrayOfType(assertProductionAreaState, onlyActive(maybeAs.productionAreaStates));
    assertArrayOfType(assertProductionArea, onlyActive(maybeAs.productionAreas));
    assertArrayOfType(assertProductionProductGroup, onlyActive(maybeAs.productionProductGroups));
    assertArrayOfType(assertProductionProduct, onlyActive(maybeAs.productionProducts));
    assertArrayOfType(assertProductionType, onlyActive(maybeAs.productionTypes));
    assertArrayOfType(assertRefineryProductGroup, onlyActive(maybeAs.refineryProductGroups));
    assertArrayOfType(assertRefineryProduct, onlyActive(maybeAs.refineryProducts));
    assertArrayOfType(assertRefineryType, onlyActive(maybeAs.refineryTypes));
    assertArrayOfType(assertReportFrequencyType, maybeAs.reportFrequencyTypes);
    assertArrayOfType(assertRoleType, onlyActive(maybeAs.roleTypes));
    assertArrayOfType(assertState, onlyActive(maybeAs.states));
    assertArrayOfType(assertStockProductGroup, onlyActive(maybeAs.stockProductGroups));
    assertArrayOfType(assertStockProduct, onlyActive(maybeAs.stockProducts));
    assertArrayOfType(assertStockType, onlyActive(maybeAs.stockTypes));
    assertArrayOfType(assertStorageType, onlyActive(maybeAs.storageTypes));
    assertArrayOfType(assertUserStatus, onlyActive(maybeAs.userStatuses));
    assertArrayOfType(assertWholesaleProductGroup, onlyActive(maybeAs.wholesaleProductGroups));
    assertArrayOfType(assertWholesaleProduct, onlyActive(maybeAs.wholesaleProducts));
    assertArrayOfType(assertWholesaleType, onlyActive(maybeAs.wholesaleTypes));
}

interface WithIsActive {
    isActive?: boolean;
}

interface WithEffectiveDates {
    effectiveFrom?: string;
    effectiveTo?: string | null;
}

function isWithEffectiveDates(maybe?: unknown): maybe is WithEffectiveDates {
    const maybeAs = maybe as WithEffectiveDates;
    return (
        maybeAs != null &&
        maybeAs.effectiveFrom != null
    );
}

function onlyActive(list: Array<WithIsActive | WithEffectiveDates>) {
    return list.filter(x => isWithEffectiveDates(x) ? (
            (x.effectiveFrom != null && new Date() > new Date(x.effectiveFrom)) && (x.effectiveTo == null || new Date() < new Date(x.effectiveTo))
        ) :
        x.isActive
    );
}
