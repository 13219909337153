import { ButtonState } from "psims/react/components/button/button";
import { useMemo } from "react";
import { ViewMode } from "../../shared/use-view-mode";

import { UseMsoImporterAPI } from "./use-mso-importer-api";
import { UseMsoImporterNavigation } from "./use-mso-importer-navigation";
import { UseMsoImporterProgress } from "./use-mso-importer-progress";
import { UseMsoImporterValidation } from "./use-mso-importer-validation";

interface UseMsoImporterControlsProps {
    apiCtrl: UseMsoImporterAPI;
    navCtrl: UseMsoImporterNavigation;
    progressCtrl: UseMsoImporterProgress;
    validationCtrl: UseMsoImporterValidation;
    viewMode: ViewMode;
}

function useMsoImporterControls({apiCtrl, navCtrl, progressCtrl, validationCtrl, viewMode}: UseMsoImporterControlsProps) {
    const primaryControl = useMemo(() => {
        const {currentStep: {index, kind}} = progressCtrl;
        const nextStep = index + 1;
        const label = viewMode === 'edit' ?
            (kind === 'data' ? 'Save and continue' : 'Submit') :
            'Continue';
        const action = viewMode === 'edit' ? (kind === 'data' ?
                    () => navCtrl.saveAndGoToPage(nextStep) :
                    navCtrl.attemptSubmit
                ) :
                () => progressCtrl.goToStep(nextStep);
        const state: ButtonState = apiCtrl.isBusy ? 'loading' : 'normal';

        const isDisabled = apiCtrl.isBusy;

        const isShown = viewMode === 'edit' ? true : (
            kind !== 'submit'
        )

        return {
            action,
            isDisabled,
            isShown,
            label,
            state,
        }
    }, [apiCtrl, navCtrl, progressCtrl, viewMode]);

    const secondaryControl = useMemo(() => {
        const {currentStep: {index}} = progressCtrl;
        const nextStep = index - 1;
        const action = viewMode === 'edit' ?
            () => navCtrl.saveAndGoToPage(nextStep) :
            () => progressCtrl.goToStep(nextStep);

        const isDisabled = apiCtrl.isBusy || validationCtrl.notifications.length > 0;

        const isShown = index > 0;
        const label = 'Previous';
        const state: ButtonState = apiCtrl.isBusy ? 'loading' : 'normal';

        return {
            action,
            isDisabled,
            isShown,
            label,
            state,
        }
    }, [apiCtrl.isBusy, navCtrl, progressCtrl, validationCtrl.notifications.length, viewMode]);

    return {
        primaryControl,
        secondaryControl,
    };
}

export default useMsoImporterControls
