import { useCallback, useMemo, useState } from "react";

function useFocusedField<TField extends unknown>() {
    const [focusedField, setFocusedField] = useState<TField | null>(null);

    const triggerFocus = useCallback((field: TField | null) => {
        setFocusedField(null);

        if (field != null) {
            setTimeout(() => setFocusedField(field), 0);
        }
    }, []);

    return useMemo(() => ({
        focusedField,
        setFocusedField: triggerFocus,
    }), [focusedField, triggerFocus]);
}

export default useFocusedField;

export type UseFocusedField<T> = {
    focusedField: T | null;
    setFocusedField: (f: T | null) => void;
}
