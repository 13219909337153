import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";

import { useAPI } from ".";
import { useUser } from "../user";
import { Status } from "psims/types/async";
import { isArrayOfReportObligation, ReportingObligation } from "psims/models/reporting-obligation";
import { SubmissionType } from "psims/models/ref-data/submission-type";

export type ReportingObligationView = ReportingObligation & {
    reportingOrganisation: {name: string;};
    submissionType: SubmissionType;
}

type ReportingObligationsContext = {
    refetch: () => void;
    status: Status;
    reportingObligations: Array<ReportingObligation> | null
}

const context = createContext<ReportingObligationsContext | null>(null);

const ReportingObligationsProvider = ({children}: PropsWithChildren<{}>) => {
    const { user } = useUser();
    const [reportingObligations, setReportingObligations] = useState<Array<ReportingObligation> | null>(null);
    const [status, setStatus] = useState<Status>('init');
    const {api} = useAPI();

    const fetch = useCallback(() => {
        if (!user?.organisation?.id) {
            return;
        }

        setStatus('loading');
        api.getReportObligations()
        .then(response => {
            if (response?.isSuccessful && isArrayOfReportObligation(response.result)) {
                setReportingObligations(response.result);
                setStatus('fulfilled');
            } else {
                setStatus('error');
            }
        })
        .catch(() => {
            setStatus('error');
        });
    }, [user, api]);

    useEffect(() => {
        if (!user?.organisation?.id || reportingObligations !== null) {
            return;
        }

        fetch();
    }, [fetch, user?.organisation?.id, reportingObligations]);

    return <context.Provider value={{ reportingObligations, status, refetch: fetch}}>
        {children}
    </context.Provider>
}

export default ReportingObligationsProvider;

export function useReportingObligations() {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error('useReportingObligations must be used within ReportingObligationsProvider');
    }

    return {
        ...ctx,
    };
}
