import { UpdateFspEligibleProductionField } from "psims/models/submission-types/fsp/eligible-production";
import { Draft } from "./shared";

type ValidationAlertResultDisagree = {
  // code is made up - change to match validation alert used for CRM
  code: 'explain_disagreement';
}

export type ValidationAlertResult = {
  field: UpdateFspEligibleProductionField;
} & ValidationAlertResultDisagree;

export function getValidationAlertsForEligibleProduction(draft: Draft) {
  const validationAlerts: Array<ValidationAlertResult> = [];

  // Below is apparently not a validation alert - keeping around for a
  // bit in case someone changes their mind
  if (draft.data.volumeConfirmation === false) {
    validationAlerts.push({
      code: 'explain_disagreement',
      field: 'volumeConfirmation',
    });
  }

  return validationAlerts;
}
