import { useMemo } from "react";

import { any } from "psims/lib/collections";
import { is } from "psims/lib/type-assertions";
import { recordActionAsEnum } from "psims/models/api/data-submission-record-action";
import NotificationMessage, { NotificationPart } from "../../shared/notification-message";
import { UseSubmit } from "../../shared/use-submit";
import { UseMsoComment } from "../shared/mso-comment/use-mso-comment";
import { UseStorageSites } from "../shared/storage-sites/use-storage-sites";
import { UseRefinerAnnualActivities } from "./annual-activities/use-refiner-annual-activities";
import { UseRefinerAnnualActivityNavigation } from "./use-refiner-annual-activity-navigation";
import { UseRefinerAnnualActivityProgress } from "./use-refiner-annual-activity-progress";

interface UseRefinerAnnualActivityNotificationsProps {
  annualActivitiesCtrl: UseRefinerAnnualActivities;
  commentsCtrl: UseMsoComment;
  navCtrl: UseRefinerAnnualActivityNavigation;
  progressCtrl: UseRefinerAnnualActivityProgress;
  serviceMessages: Array<string>;
  storageSitesCtrl: UseStorageSites;
  submitCtrl: UseSubmit;
}

function useRefinerAnnualActivityNotifications({
  annualActivitiesCtrl,
  commentsCtrl,
  navCtrl,
  progressCtrl,
  serviceMessages,
  storageSitesCtrl,
  submitCtrl
}: UseRefinerAnnualActivityNotificationsProps) {
  const validationNotifications = useMemo(() => {
    if (progressCtrl.currentStep.index === 0) {
      return [
        ...(storageSitesCtrl.updateStorageSites
          .filter(s => s.data.recordAction !== recordActionAsEnum('Delete'))
          .map(s => {
            return Object.entries(s.validations)
              .filter(([k, v]) => v.notification.parts != null)
              .map(([k, v]) => v.notification)
              .filter(is)
          })
          .flat() as Array<{ parts: Array<NotificationPart> }>)
          .map(notification => ({ content: NotificationMessage({ parts: notification.parts }) })),
        ...(annualActivitiesCtrl.updateAnnualActivities
          .filter(a => a.data.recordAction !== recordActionAsEnum('Delete'))
          .map(s => {
            return Object.entries(s.validations)
              .filter(([k, v]) => v.notification.parts != null)
              // Don't show required message until save attempted
              .filter(([k, v]) => v.validationResult.code !== 'invalid_required' || navCtrl.lastSaveAttemptIndex != null)
              .map(([k, v]) => v.notification)
              .filter(is)
          })
          .flat() as Array<{ parts: Array<NotificationPart> }>)
          .map(notification => ({ content: NotificationMessage({ parts: notification.parts }) })),
        ...(commentsCtrl.data.recordAction === recordActionAsEnum('Delete') ? [] : [
          ...commentsCtrl.validations
            .filter(v => v.notification.parts != null)
            .map(v => v.notification.parts)
            .filter(is)
            .map(parts => ({ content: NotificationMessage({ parts }) }))
        ])
      ];
    }

    return [
      ...submitCtrl.notifications,
      ...(annualActivitiesCtrl.updateAnnualActivities
        .filter(s => s.data.recordAction !== recordActionAsEnum('Delete'))
        .map(s => {
          const { delete: d } = s.validations;
          if (d == null) {
            return [];
          }
          return [d.notification]
            .filter(is)
        })
        .flat() as Array<{ parts: Array<NotificationPart> }>)
        .map(notification => ({ content: NotificationMessage({ parts: notification.parts }) })),
      ...(storageSitesCtrl.updateStorageSites
        .filter(s => s.data.recordAction !== recordActionAsEnum('Delete'))
        .map(s => {
          const { delete: d } = s.validations;
          if (d == null) {
            return [];
          }
          return [d.notification]
            .filter(is)
        })
        .flat() as Array<{ parts: Array<NotificationPart> }>)
        .map(notification => ({ content: NotificationMessage({ parts: notification.parts }) })),
    ]
  }, [
    annualActivitiesCtrl.updateAnnualActivities,
    commentsCtrl.data,
    commentsCtrl.validations,
    navCtrl.lastSaveAttemptIndex,
    progressCtrl.currentStep.index,
    storageSitesCtrl.updateStorageSites,
    submitCtrl,
  ]);

  const infoNotifications = useMemo(() => {
    const messages: Array<{ content: JSX.Element }> = [];
    if (
      progressCtrl.currentStep.index === 0 &&
      any(
        annualActivitiesCtrl.updateAnnualActivities
          .map(a => a.data)
          .filter(a => a.recordAction !== recordActionAsEnum('Delete')),
        a => a.enforceableArrangements === true
      )) {
      messages.push({
        content: NotificationMessage({
          parts: [
            <span key={1}>You have selected 'Enforceable arrangements'. Please provide a copy of the legally enforceable arrangement/s to the department by uploading it to the Documents page under the Manage company menu.</span>
          ]
        })
      })
    }

    return messages
  }, [annualActivitiesCtrl.updateAnnualActivities, progressCtrl.currentStep.index]);

  const serviceNotifications = useMemo(() => {
    return serviceMessages.map(message => ({
      content: NotificationMessage({ parts: [message] })
    }));
  }, [serviceMessages]);

  return {
    infoNotifications,
    serviceNotifications,
    validationNotifications,
  };
}

export default useRefinerAnnualActivityNotifications

export type UseRefinerAnnualActivityNotifications = ReturnType<typeof useRefinerAnnualActivityNotifications>;
