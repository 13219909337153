import { MsoStockholdingVM, UpdateMsoStockholdingVM } from "psims/gen/xapi-client";
import { isWithUpdateable, WithUpdatable } from "psims/models/api/update";

type MaybeMsoStockholding = MsoStockholdingVM;

export type MsoStockholding = WithUpdatable<
    PopulatedProps<MaybeMsoStockholding, 'dataSubmissionId' | 'msoProductId'>
>;

export function isMsoStockholding(maybe?: MaybeMsoStockholding | null): maybe is MsoStockholding {
    return (
        isWithUpdateable<MaybeMsoStockholding>(maybe) &&
        maybe.dataSubmissionId != null &&
        maybe.msoProductId != null
    );
}

type MaybeUpdateMsoStockholding = UpdateMsoStockholdingVM;

export type UpdateMsoStockholding = PopulatedProps<MaybeUpdateMsoStockholding, "dataSubmissionId" | "msoProductId" | 'stockholdingOwnerships'>;

export type UpdateMsoStockholdingField = Exclude<keyof UpdateMsoStockholding, 'dataSubmissionId' | 'msoProductId' | 'stockholdingOwnerships' | 'id' | 'recordAction' | 'concurrencyToken'>;

export function isUpdateMsoStockholding(maybe?: unknown): maybe is UpdateMsoStockholding {
    const maybeAs = maybe as UpdateMsoStockholding;

    return (
        maybeAs != null &&
        maybeAs.dataSubmissionId != null &&
        maybeAs.msoProductId != null &&
        maybeAs.stockholdingOwnerships != null
    );
}
