import { FsspSubmissionVM, UpdateFsspSubmissionVM } from "psims/gen/xapi-client";
import { isArrayOfType } from "psims/lib/collections";
import { isRecordResult, RecordResult } from "psims/models/api/record-result";
import { isDataSubmission, isSubmittedDataSubmission, PopulatedDataSubmission } from "psims/models/data-submission";
import { FspEligibleProduction, isFspEligibleProduction, isUpdateFspEligibleProduction } from "./eligible-production";
import { isFspEligibleTotals } from "./eligible-totals";
import { isFspHydrocarbonBlendstock } from "./hydrocarbon-blendstock";
import { isFspPageData } from "./fsp-submission-form-data";
import { isFspRefineryOutput } from "./refinery-output";

export type FspDataSubmissionName = 'FSSP s19A';

export type FspDataSubmission = PopulatedProps<
  Omit<FsspSubmissionVM, 'dataSubmission'>,
  'fsspEligibleProductions' | 'hydrocarbonBlendstocks' | 'pageData' | 'refineryOutputs' | 'totalEligibleProduction'
> & {
  dataSubmission: PopulatedDataSubmission<FspDataSubmissionName>;
  fsspEligibleProductions: Array<FspEligibleProduction>;
};

export function isFspSubmission(maybe?: unknown): maybe is FspDataSubmission {
  const maybeAs = maybe as FspDataSubmission;

  return (
    maybeAs != null &&
    isDataSubmission(maybeAs.dataSubmission, 'FSSP s19A') &&
    isArrayOfType(isFspHydrocarbonBlendstock, maybeAs.hydrocarbonBlendstocks) &&
    isArrayOfType(isFspRefineryOutput, maybeAs.refineryOutputs) &&
    isArrayOfType(isFspEligibleProduction, maybeAs.fsspEligibleProductions) &&
    isFspEligibleTotals(maybeAs.totalEligibleProduction) &&
    isFspPageData(maybeAs.pageData)
  )
}

export type UpdateFspDataSubmission = PopulatedProps<
  UpdateFsspSubmissionVM,
  'dataSubmissionId' | 'fsspEligibleProductions'
>;

export function isUpdateFspDataSubmission(maybe?: unknown): maybe is UpdateFspDataSubmission {
  const maybeAs = maybe as UpdateFspDataSubmission;

  return (
    maybeAs != null &&
    maybeAs.dataSubmissionId != null &&
    isArrayOfType(isUpdateFspEligibleProduction, maybeAs.fsspEligibleProductions)
  )
}

export function isFspSubmitted(maybe?: unknown): maybe is FspDataSubmission & {dataSubmission: {status: 'Submitted'}} {
    return isFspSubmission(maybe) && isSubmittedDataSubmission(maybe.dataSubmission);
}

export function getRecordResults(maybe: unknown): Array<RecordResult> {
    if (!isFspSubmission(maybe)) {
        return [];
    }

    return [
        ...(maybe.dataSubmission.recordResult != null ? [maybe.dataSubmission.recordResult] : []).filter(isRecordResult),
        ...(maybe.fsspEligibleProductions || []).map(a => a.recordResult).filter(isRecordResult),
    ];
}
