import { useCallback, useEffect, useMemo, useState } from "react";
import produce from "immer";

import { recordActionAsEnum, recordActionFromEnum } from "psims/models/api/data-submission-record-action";
import { MsoStorageSite } from "psims/models/submission-types/mso/annual-activity/mso-storage-site";
import { UpdateMsoStorageSiteDraft, UpdateMsoStorageSiteField } from "psims/models/submission-types/mso/annual-activity/update-mso-storage-site";
import { validateStorageSite, ValidationResult } from "./storage-sites-validation";
import { getInlineMessage } from "./storage-site-inline-messages";
import { getNotificationMessage } from "./storage-site-notifications";
import { UseMsoAnnualActivityRefData } from "../use-mso-annual-activity-ref-data";
import { UseFocusedField } from "psims/react/util/use-focused-field";
import { FieldValidation, FocusField } from "../types";
import useTrackChanges from "../../../shared/use-track-changes";
import { cleanSubmissionEntityList } from "../../../shared/update-data-utils";
import { dateToLocalDateString, localDateStringToDate } from "psims/lib/date";
import { isEmpty } from "psims/lib/empty";

interface ProgressController {
  currentStep: {
    kind: 'data' | 'submit';
  };
  goToStep: (index: number) => any;
}

interface UseStorageSiteProps {
  dataSubmissionId: number;
  focusFieldCtrl: UseFocusedField<FocusField>;
  initialSites: Array<MsoStorageSite>;
  progressCtrl: ProgressController;
  refData: UseMsoAnnualActivityRefData;
}

function useStorageSites({dataSubmissionId, focusFieldCtrl, initialSites, progressCtrl, refData}: UseStorageSiteProps) {
  const initialSitesAsUpdate = useMemo(() => {
    return initialSites
      .filter(s => s.recordResult?.rowResult !== 'Deleted')
      .map(toUpdateMsoStorageSite)
  }, [initialSites]);

  const [data, setData] = useState(initialSitesAsUpdate);

  const changeCtrl = useTrackChanges({
    currentData: data,
    dataFields: ['storageFromDate', 'storageLocation', 'storagePrice', 'storageSite', 'storageToDate', 'storageTypeId'],
    initialData: initialSitesAsUpdate,
  });

  const addStorageSite = useCallback((msoProductId: number) => {
    setData(prev => produce(prev, draft => {
      draft.push({
        _id: Symbol(),
        dataSubmissionId,
        msoProductId,
        recordAction: recordActionAsEnum('Create'),
      });
    }));
  }, [dataSubmissionId]);

  const deleteStorageSite = useCallback((storageSite: UpdateMsoStorageSiteDraft) => {
    setData(prev => produce(prev, draft => {
      const idx = data.findIndex(s => s._id === storageSite._id);
      // When deleting a site with an id, we just update the recordAction.
      if (draft[idx].id != null) {
        draft[idx] = {
          ...storageSite,
          recordAction: recordActionAsEnum('Delete'),
        }

        return;
      }

      // When deleting a site without an id, i.e. unpersisted, remove from list completely
      draft.splice(idx, 1);
    }));
  }, [data]);

  const deleteAllStorageSitesForProduct = useCallback((msoProductId: number) => {
    setData(prev => {
      return prev
        .filter(d => d.id != null || d.msoProductId !== msoProductId)
        .map(d => {
          if (d.msoProductId !== msoProductId) {
            return d;
          }

          return {
            ...d,
            recordAction: recordActionAsEnum('Delete'),
          };
        })
    })
  }, []);


  const updateStorageSite = useCallback((storageSite: UpdateMsoStorageSiteDraft) => {
    setData(prev => produce(prev, draft => {
      const idx = data.findIndex(s => s._id === storageSite._id);
      draft[idx] = {
        ...storageSite,
        recordAction: recordActionAsEnum(storageSite.id == null ? 'Create' : 'Update'),
      }
    }));
  }, [data]);

  const dataView = useMemo(() => {
    // Keep track of inactive errors by type so we only show 1
    let haveRecordedInactiveError = false;
    return data
      .map(s => ({
        data: s,
        validations: validateStorageSite(s, refData),
      }))
      .map(s => ({
        data: s.data,
        validations: s.validations.reduce((map, validationResult) => {
          if (haveRecordedInactiveError && validationResult.key === 'delete') {
            return map;
          }

          const next = {
            ...map,
            [validationResult.key]: {
              inline: getInlineMessage({...validationResult}),
              notification: {
                parts: getNotificationMessage(
                  validationResult.key,
                  () => {
                    const {code} = validationResult;
                    if (code === 'inactive_product') {
                      if (progressCtrl.currentStep.kind === 'submit') {
                        progressCtrl.goToStep(0);
                      }
                      focusFieldCtrl.setFocusedField({field: 'delete', kind: 'storageSites', productId: s.data.msoProductId});
                    } else {
                      focusFieldCtrl.setFocusedField({_id: s.data._id, field: validationResult.key, kind: 'data'});
                    }
                  },
                  refData.productIdMap[s.data.msoProductId].name,
                  validationResult
                )
              },
              validationResult,
            }
        };

        if (validationResult.key === 'delete') {
          haveRecordedInactiveError = true;
        }

        return next;

      }, {} as {[key in UpdateMsoStorageSiteField | 'delete']?: FieldValidation<ValidationResult>})
    }))
  }, [data, focusFieldCtrl, progressCtrl, refData]);

  const requestEntities = useMemo(() => cleanSubmissionEntityList(data)
    .map(d => ({
      ...d,
      storageFromDate: d.storageFromDate ? localDateStringToDate(d.storageFromDate)?.toISOString() : null,
      storageToDate: d.storageToDate ? localDateStringToDate(d.storageToDate)?.toISOString() : null,
    })),
    [data]
  );

  const anyDeleted = useMemo(
    () => data.some(d => recordActionFromEnum(d.recordAction) === 'Delete'), 
    [data]
  );

  const anyInvalid = useMemo(
    () => dataView.some(v => !isEmpty(v.validations)),
    [dataView]
  );

  // Reset on page change
  useEffect(() => {
    if (progressCtrl.currentStep.kind !== 'data') {
      setData(initialSitesAsUpdate);
    }
  }, [initialSitesAsUpdate, progressCtrl]);

  return useMemo(() => ({
    addStorageSite,
    anyInvalid,
    deleteAllStorageSitesForProduct,
    deleteStorageSite,
    hasChanges: changeCtrl.hasChanges|| anyDeleted,
    requestEntities,
    updateStorageSite,
    updateStorageSites: dataView,
  }), [
    addStorageSite,
    anyInvalid,
    deleteAllStorageSitesForProduct,
    deleteStorageSite,
    changeCtrl.hasChanges,
    anyDeleted,
    requestEntities,
    updateStorageSite,
    dataView,
  ])
}

function toUpdateMsoStorageSite(storageSite: MsoStorageSite): UpdateMsoStorageSiteDraft {
  const {recordResult, ...data} = storageSite;

  return {
    ...data,
    storageFromDate: data.storageFromDate ? dateToLocalDateString(new Date(data.storageFromDate)) : data.storageFromDate,
    storageToDate: data.storageToDate ? dateToLocalDateString(new Date(data.storageToDate)) : data.storageToDate,
    recordAction: undefined,
    _id: Symbol(),
  };
}

export default useStorageSites;

export type UseStorageSites = ReturnType<typeof useStorageSites>;
