import { useCallback, useEffect, useState } from "react";

import { useAPI } from "psims/react/providers/api";
import { isWholesaleSubmission, MaybeWholesaleSubmission, UpdateWholesaleSubmission, WholesaleSubmission } from "psims/models/submission-types/wholesaling";
import { useLogger } from "psims/react/providers/logging";
import { APIResponse, isAPIResponse } from "psims/models/api/response";
import { UpdateDataSubmission } from "psims/models/data-submission";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { numberFieldValue } from "psims/lib/number";
import { encodeEscapeChars } from "psims/lib/validation/string";
import { isBusyStatus, SubmissionStatus } from "../shared/api";

interface UseWholesalingAPIProps {
    dataSubmissionId: number;
}

function useWholesalingAPI({dataSubmissionId}: UseWholesalingAPIProps) {
    const {api} = useAPI();
    const logger = useLogger({source: 'useWholesalingAPI'});
    const [loadStatus, setLoadStatus] = useState<SubmissionStatus>('init');
    const [submission, setSubmission] = useState<WholesaleSubmission | null>(null);
    const [updateResponse, setUpdateResponse] = useState<APIResponse<MaybeWholesaleSubmission> | null>(null);
    const [updateError, setUpdateError] = useState<unknown | null>(null);
    
    const loggerRef = useUpdatedRef(logger);

    const fetch = useCallback(() => {
        if (isBusyStatus(loadStatus)) {
            loggerRef.current.debug(`Aborting fetch due to busy status: ${loadStatus}`)
            return;
        }

        setLoadStatus('fetching');

        api.getWholesaling({id: dataSubmissionId})
        .then(response => {
            if (response !== null && response.isSuccessful && response.result !== undefined) {
                setSubmission(response.result as WholesaleSubmission);
                setLoadStatus('fetched');
            } else {
                setTimeout(() => {
                    setLoadStatus('fetch_failed');
                }, 750)
            }
        })
        .catch(() => {
            setTimeout(() => {
                loggerRef.current.warn('Failed to fetch wholesaling - using mock');
                setLoadStatus('fetch_failed');
            }, 750)
        });
    }, [api, dataSubmissionId, loggerRef, loadStatus])

    const updateDataSubmission = useCallback((updatePayload: UpdateDataSubmission) => {
        if (isBusyStatus(loadStatus)) {
            loggerRef.current.debug(`Aborting data submission (wholesaling) update due to busy status: ${loadStatus}`)
            return;
        }

        setLoadStatus('updating');
        setUpdateError(null);

        const requestBodyEncoded = {
            ...updatePayload,
            comments: encodeEscapeChars(updatePayload.comments?.trim())
        };

        const trackPageView = loggerRef.current.startTrackPage('Save - Wholesaling');

        api.updateWholesaleDataSubmission({requestBody: requestBodyEncoded})
            .then(res => {
                trackPageView();
                setLoadStatus('updated');
                setUpdateResponse(res);
                const sub = res?.result;
                if (isWholesaleSubmission(sub)) {
                    setSubmission(sub);
                }
            })
            .catch(e => {
                loggerRef.current.warn(`Failed to update data submission (wholesaling): ${JSON.stringify(e)}`);
                setLoadStatus('update_failed');
                setUpdateError(e);
            });
    }, [api, loggerRef, loadStatus]);

    const updateWholesale = useCallback((updatePayload: UpdateWholesaleSubmission) => {
        if (isBusyStatus(loadStatus)) {
            loggerRef.current.debug(`Aborting wholesaling update due to busy status: ${loadStatus}`)
            return;
        }

        let commentEncoded = updatePayload.wholesaleComment;
        if (commentEncoded) {
            commentEncoded = {
                ...commentEncoded,
                comments: encodeEscapeChars(commentEncoded.comments?.trim())
            }
        }
        const requestBodyEncoded: UpdateWholesaleSubmission = {
            ...updatePayload,
            wholesaleComment: commentEncoded
        };

        setLoadStatus('updating');
        setUpdateError(null);

        const trackPageView = loggerRef.current.startTrackPage('Save - Wholesaling');

        api.updateWholesale({requestBody: cleanUpdatePayload(requestBodyEncoded)})
            .then(res => {
                trackPageView();
                setLoadStatus('updated');
                setUpdateResponse(res);
                const sub = res?.result;
                if (isWholesaleSubmission(sub)) {
                    setSubmission(sub);
                }
            })
            .catch(e => {
                loggerRef.current.warn(`Failed to update wholesaling: ${JSON.stringify(e)}`);
                if (e.body && isAPIResponse(e.body)) {
                    setUpdateResponse(e.body);
                }
                setLoadStatus('update_failed');
                setUpdateError(e);
            });
    }, [api, loggerRef, loadStatus]);

    const submit = useCallback((submitPayload: UpdateDataSubmission) => {
        if (isBusyStatus(loadStatus)) {
            loggerRef.current.debug(`Aborting submit due to busy status: ${loadStatus}`)
            return;
        }

        setLoadStatus('submitting');
        setUpdateError(null);

        const requestBodyEncoded = {
            ...submitPayload,
            comments: encodeEscapeChars(submitPayload.comments?.trim())
        };

        api.submitWholesaling({requestBody: requestBodyEncoded})
            .then(res => {
                setLoadStatus('submitted');
                setUpdateResponse(res);
            })
            .catch(e => {
                loggerRef.current.warn(`Failed to submit wholesaling: ${JSON.stringify(e)}`);
                if (e.body && isAPIResponse(e.body)) {
                    setUpdateResponse(e.body);
                }
                setLoadStatus('submit_failed');
                setUpdateError(e);
            });
    }, [api, loadStatus, loggerRef]);

    const clearAllData = useCallback(() => { 
        const lastServiceSubmission = submission;

        if (lastServiceSubmission == null) {
            loggerRef.current.warn('Submission is null');
            return;
        }

        setLoadStatus('updating');
        setUpdateError(null);

        api.clearAllWholesale({requestBody: {id: lastServiceSubmission.dataSubmission.id, concurrencyToken: lastServiceSubmission.dataSubmission.concurrencyToken}})
            .then(response => {
                setLoadStatus('updated');
                setUpdateResponse(response);
                const sub = response?.result;
                if (isWholesaleSubmission(sub)) {
                    setSubmission(sub);
                }
            })
            .catch(e => {
                loggerRef.current.warn(`Failed to clear all wholesaling data: ${JSON.stringify(e)}`);
                if (e.body && isAPIResponse(e.body)) {
                    setUpdateResponse(e.body);
                }
                setLoadStatus('update_failed');
                setUpdateError(e);                
            });
    }, [api, loggerRef, submission]);

    useEffect(() => {
        fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        loadStatus,
        submission,
        updateError,
        updateResponse,
        fetch,
        submit,
        updateDataSubmission,
        updateWholesale,
        clearAllData,
    }
}

export type UseWholesalingAPI = ReturnType<typeof useWholesalingAPI>;

export default useWholesalingAPI;

function cleanUpdatePayload(payload: UpdateWholesaleSubmission) {
    return {
        ...payload,
        wholesales: payload.wholesales.map(w => ({
            ...w,
            nswWholesaleVolume: numberFieldValue(w.nswWholesaleVolume),
            ntWholesaleVolume: numberFieldValue(w.ntWholesaleVolume),
            qldWholesaleVolume: numberFieldValue(w.qldWholesaleVolume),
            saWholesaleVolume: numberFieldValue(w.saWholesaleVolume),
            tasWholesaleVolume: numberFieldValue(w.tasWholesaleVolume),
            vicWholesaleVolume: numberFieldValue(w.vicWholesaleVolume),
            waWholesaleVolume: numberFieldValue(w.waWholesaleVolume),
        }))
    }
}
