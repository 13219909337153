import { SPACE_BASE } from 'psims/constants/styles';
import React from 'react';
import styled from 'styled-components';
import VisuallyHidden from './visually-hidden';

type Alignment = 'center' | 'left' | 'right';

interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
    caption: string;
    cellBorder?: boolean;
    className?: string;
    compact?: boolean;
    fullWidth?: boolean;
    stickyHeader?: boolean;
}

const tableStyle = (props: {fullWidth?: boolean}) => ({
    ...(props.fullWidth ? {width: '100%'} : {}),
});

const StyledTable = styled.table(tableStyle);

export const Table = ({children, caption, className = '', ...rest}: React.PropsWithChildren<TableProps>) => {
    const cn = `${className}${
        rest.stickyHeader ? ' sticky-header' : ''
    }${
        rest.cellBorder ? ' cell-border' : ''
    }${
        rest.compact ? ' compact' : ''
    }`

    return <StyledTable className={cn} {...rest}>
        <caption>
            <VisuallyHidden>
                {caption}
            </VisuallyHidden>
        </caption>
        {children}
    </StyledTable>
}

const StyledTHead = styled.thead`
    :not(.sticky-header &) {
        background: var(--color-table-background);
        border-top: 1px solid var(--color-table-border);
        border-top: 4px solid var(--color-table-border);
    }
`;

export const THead = ({children}: React.PropsWithChildren<{}>) => {
    return <StyledTHead>{children}</StyledTHead>
}

interface TRProps extends React.HTMLAttributes<HTMLTableRowElement> {
    className?: string;
}

const trStyle = (props: TRProps) => ({
    verticalAlign: 'middle',
    border: 'none',
});

const StyledTR = styled.tr(trStyle);

export const TR = ({children, ...rest}: React.PropsWithChildren<TRProps>) => {
    return <StyledTR {...rest}>{children}</StyledTR>
}

interface THProps {
    $align?: Alignment;
    className?: string;
    maxWidth?: string;
    minWidth?: string;
    notFocusable?: boolean;
    rowSpan?: number
    scope?: 'col' | 'row';
    $width?: string;
}

const StyledTH = styled.th`${(props: THProps) => `
    .sticky-header thead & {
        background: var(--color-table-background);
        box-shadow: inset 0 4px 0 var(--color-table-border), inset 0 -1px 0 var(--color-table-border);
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .cell-border thead & {
        border-left: 1px solid var(--color-table-border);
        border-right: 1px solid var(--color-table-border);
        border-top: none !important;
    }

    table.compact tr thead & {
        padding: ${SPACE_BASE * 2}px;
    }

    ${props.$align != null ? `text-align: ${props.$align};` : ''}
    color: var(--color-table-header);
    font-weight: 400;
    thead & {
        font-size: 12px;
        font-weight: 700;
        padding: ${SPACE_BASE * 1.5}px ${SPACE_BASE * 3}px;
    }
    ${props.maxWidth != null ? `max-width: ${props.maxWidth};` : ''}
    ${props.minWidth != null ? `min-width: ${props.minWidth};` : ''}
    ${props.$width != null ? `
        width: ${props.$width};
        min-width: ${props.$width};
    ` : ''}
`}`;

export const TH = ({className, notFocusable, ...props}: React.PropsWithChildren<THProps>) => {
    const cn = `${className || ''}${notFocusable ? ' cell--not-focusable' : ''}`;
    return <StyledTH className={cn} {...props} />
}

interface TBodyProps {
    className?: string;
}

const StyledTBody = styled.tbody`
    & tr {
        border-bottom: 1px solid var(--color-table-border);
    }

    & tr td {
        padding: ${SPACE_BASE * 2}px ${SPACE_BASE * 3}px;
    }

    table.compact & tr td {
        padding: ${SPACE_BASE / 2}px ${SPACE_BASE}px;
    }
`;

export const TBody = ({children, ...rest}: React.PropsWithChildren<TBodyProps>) => {
    return <StyledTBody {...rest}>{children}</StyledTBody>
}

interface TDProps extends Omit<React.TdHTMLAttributes<HTMLTableCellElement>, 'align' | 'width'> {
    $align?: Alignment;
    className?: string;
    error?: boolean;
    notFocusable?: boolean;
    padding?: string;
    $width?: string;
}

const StyledTD = styled.td`${(props: TDProps) => `
    font-size: 16px;

    ${Boolean(props.$align) ? `
        text-align: ${props.$align};
    `: ''}

    ${Boolean(props.error) ? `
    &:not(.cell--not-focusable) {
        box-shadow: var(--box-shadow-error-inset);
    }
    ` : ''}

    .cell-border &:not(tfoot &) {
        border: 1px solid var(--color-table-border);

        &:not(.cell--not-focusable) {
            &:focus-within {
                box-shadow: var(--box-shadow-focus-inset);
            }
        }
    }

    ${(Boolean(props.padding) ? `
    padding: ${props.padding} !important;
    ` : '')}

    ${(Boolean(props.$width) ? `
    width: ${props.$width};
    ` : '')}

    & input {
        width: 100%;
    }
`}`;

export const TD = ({children, className, notFocusable, ...rest}: React.PropsWithChildren<TDProps>) => {
    const cn = `${className || ''}${notFocusable ? ' cell--not-focusable' : ''}`;
    return <StyledTD className={cn} {...rest}>{children}</StyledTD>
}

