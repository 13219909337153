import styled from "styled-components";

import { FsspCommitmentItem } from "psims/models/fssp-commitment";
import { BoxedDiv } from "psims/react/components/layout";
import Text from 'psims/react/components/text';
import { Table, TBody, TD, TH, THead, TR } from 'psims/react/components/table';
import Dialog from 'psims/react/components/dialog';
import { UseFsspCessations } from "./use-fssp-cessations";
import FsspCessations from "./fssp-cessations";
import { humanDate } from "psims/lib/formatters/datetime";
import VisuallyHidden from "psims/react/components/visually-hidden";
import Button from "psims/react/components/button";

const ActionCellTH = styled(TH)`
	table tr & {
		background: transparent;
		border: none !important;
		box-shadow: none;
		max-width: 200px;
		width: 200px;
		padding: 0 !important;
	}
`;

interface ResultFsspCommitmentProps {
	fsspCommitmentItems: Array<FsspCommitmentItem> | null,
	hasNoItems: boolean;
	showFsspCessationsDialog: boolean;
	fsspCessationsCtrl: UseFsspCessations
};

const ResultFsspCommitment = (props: ResultFsspCommitmentProps) => {
	return (
		<BoxedDiv box={{ flex: 'column', paddingV: 3 }}>
			<BoxedDiv box={{ flex: 'row' }}>
				{
					props.fsspCommitmentItems?.length !== 0 &&
					<Table
						caption='FSSP commitments' fullWidth={true}>
						<THead>
							<TR>
								<TH>Reporting entity</TH>
								<TH>Commitment from date</TH>
								<TH>Commitment to date</TH>
								<TH>Permanent cessation date</TH>
								<ActionCellTH>
									<VisuallyHidden>
										Actions
									</VisuallyHidden>
								</ActionCellTH>
							</TR>
						</THead>

						<TBody>
							{
								props.fsspCommitmentItems != null ? props.fsspCommitmentItems.map((fsspCommitment, index) => (
									<FsspCommitmentRow
										key={index}
										fsspCommitment={fsspCommitment}
										onClickShow={props.fsspCessationsCtrl.selectFsspCommitment}
									/>
								)) :
									null
							}
						</TBody>
					</Table>
				}

				{
					props.fsspCommitmentItems?.length === 0 &&
					<BoxedDiv
						box={{
							borderTop: { color: 'border-primary-faded', width: 4 },
							flex: 'row',
							flexGrow: 1,
							justifyContent: 'flex-start',
							marginTop: 4,
							paddingTop: 4,
						}}
					>
						<p>
							<Text weight='semibold'>
								{
									'There are no FSSP commitments.'
								}
							</Text>
						</p>
					</BoxedDiv>
				}

				<Dialog
					aria-label='FSSP cessations'
					aria-describedby='fssp_cessations_content'
					isOpen={props.showFsspCessationsDialog}
					onDismiss={props.fsspCessationsCtrl.cancel}
				>
					<FsspCessations ctrl={props.fsspCessationsCtrl} />
				</Dialog>

			</BoxedDiv>
		</BoxedDiv>
	);
}

export default ResultFsspCommitment;

interface FsspCommitmentRowProps {
	onClickShow: (selectedFsspCommitment: FsspCommitmentItem) => any;
	fsspCommitment: FsspCommitmentItem,
}

const FsspCommitmentRow = ({
	onClickShow,
	fsspCommitment,
}: FsspCommitmentRowProps) => {
	return (
		<TR>
			<TD>{fsspCommitment.organisationName}</TD>

			<TD>{humanDate(fsspCommitment.commitmentFrom)}</TD>

			<TD>{humanDate(fsspCommitment.commitmentTo)}</TD>

			<TD>{
				fsspCommitment.permenantCessationDate != null ?
				humanDate(fsspCommitment.permenantCessationDate) :
				''
			}</TD>

			<TD>
				{
					fsspCommitment.fsspTemporaryCessations.length > 0 ?
					<Button
						$kind="text"
						onClick={() => onClickShow(fsspCommitment)}
					>
						View cessations
					</Button> :
					null
				}
			</TD>
		</TR>
	);
}