import Providers from './providers';
import Router from './providers/router';

interface AppProps {};

const App = (props: AppProps) => {
	return (
		<Providers>
			<Router />
		</Providers>
	);
};

export default App;
