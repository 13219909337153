import React, { forwardRef } from 'react';
import BaseSVG, { InvertableSVGProps } from './_base';

const ExclamationTriangle = forwardRef<SVGSVGElement, InvertableSVGProps>((props, ref) => {
	return (
		<BaseSVG name='exclamation-triangle' viewBox='0 0 25 25' {...props} ref={ref}>
            <path d="M12.6,0A1.415,1.415,0,0,0,11.44.689L.187,19.662a1.34,1.34,0,0,0,0,1.371,1.417,1.417,0,0,0,1.222.683H23.919a1.417,1.417,0,0,0,1.222-.683,1.34,1.34,0,0,0,0-1.371L13.882.689A1.419,1.419,0,0,0,12.6,0Z" transform="translate(0 0)" fill={`${props.invert ? '#fff' : 'currentColor'}`}/> 
            <g transform="translate(11.083 4.761)">
                <path d="M2.623,1.224A1.27,1.27,0,0,1,1.311,2.448,1.27,1.27,0,0,1,0,1.224,1.27,1.27,0,0,1,1.311,0,1.27,1.27,0,0,1,2.623,1.224Z" transform="translate(0.497 11.821)" fill={`${props.invert ? 'currentColor' : '#fff'}`}/>
                <path d="M3.61,1.627A1.762,1.762,0,0,0,3.215.683,1.826,1.826,0,0,0,1.808,0,1.823,1.823,0,0,0,.432.647a1.767,1.767,0,0,0-.425.981,1.7,1.7,0,0,0,0,.294L.752,9.895a.957.957,0,0,0,.305.611,1.117,1.117,0,0,0,1.441.052.965.965,0,0,0,.365-.664L3.61,1.921A1.676,1.676,0,0,0,3.61,1.627Z" transform="translate(0 0)" fill={`${props.invert ? 'currentColor' : '#fff'}`}/>
            </g>
		</BaseSVG>
	);
});

export default ExclamationTriangle;
