import { FsspCommitmentVM, FsspTemporaryCessationVM } from "psims/gen/xapi-client";

export type FsspTemporaryCessations = Array<FsspTemporaryCessationVM>;
export type FsspTemporaryCessation = FsspTemporaryCessationVM;

export type FsspCommitmentItem = PopulatedProps<
    FsspCommitmentVM,
    'id' | 'commitmentFrom' | 'commitmentTo' | 'fsspTemporaryCessations' | 'organisationName'
> & {

};

export function mapApiResponseToFsspCommitmentItems(apiResult: Array<FsspCommitmentVM> | null | undefined): Array<FsspCommitmentItem> {
    if (apiResult == null) {
        throw new Error('Failed to parse Fss commitment items, Null or Undefined result');
    }

    return apiResult.filter(isFsspCommitmentItem).map((item) => ({
        ...item
    }))
}

export function isFsspCommitmentItem(maybe?: unknown): maybe is FsspCommitmentItem {
    const maybeAs = maybe as FsspCommitmentItem
    return (
        maybeAs != null &&
        maybeAs.id != null &&
        maybeAs.commitmentFrom != null &&
        maybeAs.commitmentTo != null &&
        maybeAs.organisationName != null &&
        maybeAs.fsspTemporaryCessations != null
    )
}


