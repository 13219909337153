import { useMemo } from "react";
import { ViewMode } from "../../shared/use-view-mode";
import useProgress, { PageSavedMap } from "../../shared/use-progress";
import { UseImporterAnnualActivityAPI } from "./use-importer-annual-activity-api";

interface UseImporterAnnualActivityProgressProps {
    apiCtrl: PopulatedProps<UseImporterAnnualActivityAPI, 'submission'>;
    viewMode: ViewMode;
}

const ORDERED_PAGE_SAVED_KEYS: Array<'dataPageSaved'> = ['dataPageSaved'];

function useImporterAnnualActivityProgress({apiCtrl, viewMode}: UseImporterAnnualActivityProgressProps) {
    const steps = useMemo(() => {
        return [
            {
                kind: 'data' as 'data',
                label: 'MSO importer - Annual activity',
                hasData: Boolean(apiCtrl.submission.submissionFormData.dataPageSaved),
            },
            {
                kind: 'submit' as 'submit',
                label: 'Declaration',
                hasData: null,
            }
        ];
    }, [apiCtrl.submission.submissionFormData.dataPageSaved]);

    const progressCtrl = useProgress({
        orderedFormDataPageSavedKeys: ORDERED_PAGE_SAVED_KEYS,
        steps,
        submissionFormData: apiCtrl.submission.submissionFormData as PageSavedMap<'dataPageSaved'>,
        submissionStatus: apiCtrl.submission.dataSubmission.status,
        viewMode,
    });

    return progressCtrl;
}

export default useImporterAnnualActivityProgress

export type UseImporterAnnualActivityProgress = ReturnType<typeof useImporterAnnualActivityProgress>;
