import React, { Dispatch, SetStateAction } from "react"
import ConfirmationDialog from 'psims/react/components/confirmation-dialog';
import { BoxedDiv } from "psims/react/components/layout";

type DeleteConfirmationState = 'idle' | 'showing_dialog' | 'confirmed' | 'reload' | 'cancelled';

interface DeleteConfirmationDialogProps {
    title: string;
    message?: string;
    cancelLabel?: string;
    confirmLabel?: string;
    deleteRequestState: DeleteRequestState;
    setDeleteRequestState: Dispatch<SetStateAction<DeleteRequestState>>;
}

export interface DeleteRequestState {
    deleteState: DeleteConfirmationState;
    id?: number;
    rowIndex?: number;
    message?: string;
    source?: HTMLElement | null;
}
export const idleDeleteRequest: DeleteRequestState = {
    deleteState: 'idle'
}

export const DeleteConfirmationDialog = ({title, message, cancelLabel, confirmLabel, deleteRequestState, setDeleteRequestState}: DeleteConfirmationDialogProps) => {
    const onCancel = () => {
        setDeleteRequestState({
            ...deleteRequestState,
            deleteState: 'cancelled' ,
        });
    }

    const onConfirm = () => {
        setDeleteRequestState({
            ...deleteRequestState,
            deleteState: 'confirmed'
        });
    }

    return (
        <ConfirmationDialog
            ariaLabel={title}
            body={
                <BoxedDiv box={{flex: 'column'}}>
                    <p>{deleteRequestState.message}</p>
                    <br />
                    <p>{message === undefined ? 'Are you sure you wish to delete this data?' : message}</p>
                </BoxedDiv>
            }
            cancelLabel={cancelLabel === undefined ? 'No' : cancelLabel}
            confirmLabel={confirmLabel === undefined ? 'Yes' : confirmLabel}
            isOpen={deleteRequestState.deleteState === 'showing_dialog'}
            onCancel={onCancel}
            onConfirm={onConfirm}
            title={title}
        />
    );
}
