import { MsoAnnualActivityImporterVM } from "psims/gen/xapi-client";
import { prefixedThrower } from "psims/lib/exception";

export type MsoAnnualActivityImporter = PopulatedProps<
  MsoAnnualActivityImporterVM,
  'concurrencyToken' | 'dataSubmissionId' | 'enforceableArrangements' | 'id' |
  'msoProductId' | 'recordResult' | 'totalImportVolumeOnBehalf' | 'totalImportVolumeOthers' |
  'totalImportVolumeSelf' | 'totalStorageCapacity'
>

export function assertMsoAnnualActivityImporter(maybe?: unknown): asserts maybe is MsoAnnualActivityImporter {
  const maybeAs = maybe as MsoAnnualActivityImporter;

  const assertionThrower = prefixedThrower('Failed to assert msoAnnualActivity: ');

  if (maybeAs == null) {
    assertionThrower('argument is null');
  }

  if (maybeAs.concurrencyToken == null) {
    assertionThrower('concurrencyToken is null');
  }
  
  if (maybeAs.dataSubmissionId == null) {
    assertionThrower('dataSubmissionId is null');
  }

  if (maybeAs.enforceableArrangements == null) {
    assertionThrower('enforceableArrangements is null');
  }

  if (maybeAs.id == null) {
    assertionThrower('id is null');
  }

  if (maybeAs.msoProductId == null) {
    assertionThrower('msoProductId is null');
  }

  if (maybeAs.totalImportVolumeOnBehalf == null) {
    assertionThrower('totalImportVolumeOnBehalf is null');
  }

  if (maybeAs.totalImportVolumeOthers == null) {
    assertionThrower('totalImportVolumeOther is null');
  }

  if (maybeAs.totalImportVolumeSelf == null) {
    assertionThrower('totalImportVolumeSelf is null');
  }

  if (maybeAs.totalStorageCapacity == null) {
    assertionThrower('totalStorageCapacity is null');
  }
}
