import { MsoSubmissionFormData } from "psims/models/submission-types/mso/mso-submission-form-data";
import { useMemo } from "react";
import { ViewMode } from "../../shared/use-view-mode";
import useProgress from "../../shared/use-progress";
import { UseRefinerAnnualActivityAPI } from "./use-refiner-annual-activity-api";

interface UseRefinerAnnualActivityProgressProps {
    apiCtrl: PopulatedProps<UseRefinerAnnualActivityAPI, 'submission'>;
    viewMode: ViewMode;
}

const ORDERED_PAGE_SAVED_KEYS: Array<'dataPageSaved'> = ['dataPageSaved'];

function useRefinerAnnualActivityProgress({apiCtrl, viewMode}: UseRefinerAnnualActivityProgressProps) {
    const steps = useMemo(() => {
        return [
            {
                kind: 'data' as 'data',
                label: 'MSO refiner - Annual activity',
                hasData: Boolean(apiCtrl.submission.submissionFormData.dataPageSaved),
            },
            {
                kind: 'submit' as 'submit',
                label: 'Declaration',
                hasData: null,
            }
        ];
    }, [apiCtrl.submission.submissionFormData.dataPageSaved]);

    const progressCtrl = useProgress({
        orderedFormDataPageSavedKeys: ORDERED_PAGE_SAVED_KEYS,
        steps,
        submissionFormData: apiCtrl.submission.submissionFormData as MsoSubmissionFormData,
        submissionStatus: apiCtrl.submission.dataSubmission.status,
        viewMode,
    });

    return useMemo(() => progressCtrl, [progressCtrl]);
}

export default useRefinerAnnualActivityProgress

export type UseRefinerAnnualActivityProgress = ReturnType<typeof useRefinerAnnualActivityProgress>;
