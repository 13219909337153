import Production from '../productions-shared';
import { ProductionProductGroup } from 'psims/models/ref-data/production-product-group';
import Text from "psims/react/components/text";
import { ApplicableFields, FieldConfig } from '../productions-shared/util';
import { BoxedDiv } from 'psims/react/components/layout';
import { isPlantProductionGroup } from 'psims/models/ref-data/plant-production-group';
import { getPlantProductionReferenceCode } from 'psims/models/ref-data/plant-production-product';
import { ProductionProduct } from 'psims/models/ref-data/production-product';

interface PlantProductionProps {
    id: number;
}

const SHORT_PRODUCT_NAME_MAP = {
  [getPlantProductionReferenceCode('but')]: 'Butane',
  [getPlantProductionReferenceCode('lpg')]: 'LPG mixtures',
  [getPlantProductionReferenceCode('ng')]: 'Natural gas',
  [getPlantProductionReferenceCode('ngl')]: 'Natural gas liquids',
  [getPlantProductionReferenceCode('prop')]: 'Propane',
}

function getUnits(referenceCode: string) {
  return getPlantProductionReferenceCode('ng') === referenceCode ?
    <span>000 m<sup>3</sup></span> :
    'Kilolitres';
}

function getName({referenceCode, productName}: ProductionProduct) {
  return SHORT_PRODUCT_NAME_MAP[referenceCode] || productName;
}

const applicableFields: ApplicableFields = (product) => {
  const {referenceCode} = product;
  return [
    {
        field: 'organisationProductionAreaId',
        columnHeader: 'Facility name',
        help: (
            <BoxedDiv box={{flex: 'column', width: '350px'}}>
              <p>A processing facility where hydrocarbon production streams, typically mixtures of gas and gas liquids, are separated into different marketable products for domestic sale or export.</p>
              <p>If an operator is reporting on behalf of all the owners of fuel produced by the plant, they should report the total production from the plant regardless of ownership.</p>
            </BoxedDiv>
        ),
        helpId: 'production_area_help',
        label: 'facility name',
    },
    ...(referenceCode === getPlantProductionReferenceCode('ng') ? [{
      field: 'grossCalorificValue',
      help: (
        <BoxedDiv box={{flex: 'column', width: '350px'}}>
          <p>Report the weighted average GCV in megajoules per cubic metre (MJ/m3) for each product during the month.</p>
          <p>If the GCV is not readily available each month, the most recent measurement should be used. If no measurement is available, a typical or average GCV may be entered, which should be reviewed and updated as appropriate.</p>
        </BoxedDiv>
      ),
      columnHeader: <span>Gross calorific value (Mj/m<sup>3</sup>)</span>,
      label: 'gross calorific value'
    } as FieldConfig] : []),
    {
        field: 'productionDensity',
        columnHeader: 'Average density (Kg/L)',
        help: (
          <BoxedDiv box={{flex: 'column', width: '350px'}}>
            <p>Report the weighted average density in kilograms per litre (Kg/L) for each product during the month.</p>
            <p>If the density is not readily available each month, the most recent density measurement should be used. If no measurement is available, a typical or average value may be entered, which should be reviewed and updated as appropriate.</p>
            <p>To convert API to Kg/L: <em>Density (Kg/L) = 141.5 ÷ (131.5 + API)</em></p>
          </BoxedDiv>
        ),
        helpId: 'density_help',
        label: 'average density',
    }, {
        field: 'openingStocks',
        columnHeader: <span>Opening stocks ({getUnits(referenceCode)})</span>,
        help: (
            <BoxedDiv box={{flex: 'column', width: '350px'}}>
              <p>The actual physical amount held in storage at the plant at the beginning and end of the month. For both opening and closing stocks only one figure is required for each product at each plant.</p>
              <p><strong>Include</strong> stock held in any tanks or facilities associated with the plant, including stabilisation units, holding tanks, etc.</p>
              <p>The opening stock of the current month should always be identical to the closing stock for the previous month.</p>
            </BoxedDiv>
        ),
        helpId: 'opening_stocks_help',
        label: 'opening stocks',
    }, { 
        field: 'produced',
        columnHeader: <span>{getName(product)} produced at the plant ({getUnits(referenceCode)})</span>,
        help: (
            <BoxedDiv box={{flex: 'column', width: '350px'}}>
			        <p>The total volume produced at the plant, <strong>excluding</strong> any water, sediment or volumes that were re-injected into the formation but <strong>including</strong> dissolved gases and contaminants.</p>
            </BoxedDiv>
        ),
        helpId: 'produced_help',
        label: 'produced at the plant',
    }, { 
        field: 'consumed',
        columnHeader: <span>{getName(product)} consumed as fuel at the plant ({getUnits(referenceCode)})</span>,
        help: (
            <BoxedDiv box={{flex: 'column', width: '350px'}}>
              <p>The total volume of product that was produced from the plant and was consumed as fuel to provide power at the plant.</p>
              <p><strong>Exclude</strong> any fuel that was not produced at the facility.</p>
            </BoxedDiv>
        ),
        helpId: 'consumed_help',
        label: 'consumed at the plant',
    }, { 
        field: 'delivered',
        columnHeader: <span>{getName(product)} delivered from the plant ({getUnits(referenceCode)})</span>,
        help: (
            <BoxedDiv box={{flex: 'column', width: '350px'}}>
              <p>The total volume of marketable product that was delivered for domestic consumption or export.</p>
              <p><strong>Exclude</strong> any amount re-injected, flared, consumed as fuel, destroyed or otherwise disposed of.</p>
            </BoxedDiv>
        ),
        helpId: 'delivered_help',
        label: 'delivered from the plant',
    }, {
        field: 'closingStocks',
        columnHeader: <span>Closing stocks ({getUnits(referenceCode)})</span>,
        help: (
            <BoxedDiv box={{flex: 'column', width: '350px'}}>
              <p>The actual physical amount held in storage at the plant at the beginning and end of the month. For both opening and closing stocks only one figure is required for each product at each plant.</p>
              <p><strong>Include</strong> stock held in any tanks or facilities associated with the plant, including stabilisation units, holding tanks, etc.</p>
              <p>The opening stock of the current month should always be identical to the closing stock for the previous month.</p>
            </BoxedDiv>
        ),
        helpId: 'closing_stocks_help',
        label: 'closing stocks'
    },
  ]
};

const FrontMatter = () => {
    return (
        <>
            <p>
                <Text>Density and volumetric information should be adjusted to standard temperature and pressure conditions (15°C & 1 atmosphere pressure (101.325kPa)).</Text>
            </p>

            <p>
                <Text>Information provided in the table below should only relate to activities within the calendar month identified.</Text>
            </p>
        </>
    );
};

function getGroupHeader(group: ProductionProductGroup) {
  return group.name;
}

const PlantProduction = ({id}: PlantProductionProps) => {
    return <Production
        id={id}
        productionTypeConfig={{
            applicableFields,
            FrontMatter,
            getGroupHeader,
            groupAsserter: isPlantProductionGroup,
            productionTypeName: 'Plant production',
        }}
    />
}

export default PlantProduction;
