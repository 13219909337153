import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPI } from "psims/react/providers/api";
import { useLogger } from "psims/react/providers/logging";
import { isBusyStatus, SubmissionStatus } from "psims/react/pages/primary-pages/data-submissions/shared/api";
import useUpdatedRef from "psims/react/util/use-updated-ref";
import { isOrganisation, Organisation } from "psims/models/organisation";

function useOrganisationAPI() {
    const {api} = useAPI();
    const logger = useLogger({source: 'useOrganisationAPI'});
    const [loadStatus, setLoadStatus] = useState<SubmissionStatus>('init');
    const loggerRef = useUpdatedRef(logger);
    const [organisations, setOrganisations] = useState<Array<Organisation> | null>(null);

    const isBusy = useMemo(() => {
        return isBusyStatus(loadStatus)
    }, [loadStatus]);

    const fetch = useCallback(() => {
        if (isBusy) {
            loggerRef.current.debug(`Aborting fetch due to busy status: ${loadStatus}`)
            return;
        }

        setLoadStatus('fetching');

        api.getOrganisations()
        .then(response => {
            if (response !== null && response.isSuccessful && response.result != null) {
                // Using 'as' is practically an escape hatch where we tell Typescript to just
                // trust us that the value is of a particular type:
                //
                // setOrganisations(response.result as Array<Organisation>);
                //
                // This can be a problem, especially with fetched JSON, for example if a value
                // that should exist for some reason doesn't, and elsewhere in the app we unsafely
                // (i.e. without null-checking) try to access it based on the type's contract saying
                // it will always exist.

                // Instead, we can use a type assertion as a filter to guarantee (based on the checks
                // performed in the type assertion implementation) that the array will contain values
                // that correctly adhere to the Type's contract:
                setOrganisations(response.result.filter(isOrganisation).sort((a, b) => {
                    return a.organisationType.localeCompare(b.organisationType) || a.name.localeCompare(b.name);
                }));

                setLoadStatus('fetched');
            } else {
                setTimeout(() => {
                    setLoadStatus('fetch_failed');
                }, 750)
            }
        })
        .catch(() => {
            setTimeout(() => {
                loggerRef.current.warn('Failed to fetch organisations');;
                setLoadStatus('fetch_failed');
            }, 750)
        });
    }, [api, isBusy, loggerRef, loadStatus])

    // Fetch organisations on load
    useEffect(() => {
        fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isBusy,
        loadStatus,
        fetch,
        organisations
    }
}

export default useOrganisationAPI;