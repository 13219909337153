import { ReactNode, useCallback, useMemo, useState } from "react";

function useConfirm() {
    const [message, setMessage] = useState<ReactNode>(null);
    const [confirmAction, setConfirmAction] = useState<(() => any) | null>(null);
    const [cancelAction, setCancelAction] = useState<(() => any) | null>(null);
    const [title, setTitle] = useState<ReactNode>(null);

    const clear = useCallback(() => {
        setCancelAction(null);
        setConfirmAction(null);
        setMessage(null);
    }, []);

    const requestConfirmation = useCallback((message: ReactNode, title: ReactNode, onConfirm: () => any, onCancel?: () => any) => {
        setConfirmAction(() => onConfirm);
        setCancelAction(() => onCancel ?? null);
        setMessage(message);
        setTitle(title);
    }, []);

    const cancel = useCallback(() => {
        cancelAction != null && cancelAction();
        clear();
    }, [cancelAction, clear]);

    const confirm = useCallback(() => {
        confirmAction != null && confirmAction();
        clear();
    }, [clear, confirmAction]);

    const confirmState = useMemo<'confirming' | 'idle'>(() => {
        return message != null && confirmAction != null ? 'confirming' : 'idle';
    }, [message, confirmAction])

    return useMemo(() => {
        return {
            confirmState,
            message,
            title,
            cancel,
            confirm,
            requestConfirmation,
        }
    }, [
        confirmState,
        message,
        title,
        cancel,
        confirm,
        requestConfirmation
    ]);
}

export default useConfirm

export type UseConfirm = ReturnType<typeof useConfirm>;
