import React, { forwardRef, PropsWithRef } from "react";

import './icons.css';

interface BaseSVGProps {
	name: string;
	viewBox: string;
}

export type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type SVGProps = PropsWithRef<{
	className?: string;
	color?: 'accent' | 'black' | 'green' | 'primary' | 'white' | 'warning';
	size: Size;
	ref?: (el: SVGElement | null) => any;
}>

export interface InvertableSVGProps extends SVGProps {
	invert?: boolean;
}

const BaseSVG = forwardRef<SVGSVGElement, React.PropsWithChildren<BaseSVGProps & SVGProps>>(({...props}, ref) => {
	return <svg
		aria-hidden="true"
		focusable="false"
		data-icon="user"
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox={props.viewBox}
		ref={ref}
		className={`icon icon-${props.name}${
			props.size ? ' icon-size--' + props.size : ''
			}${
				props.className ? ' ' + props.className : ''
			}${
				props.color ? ' icon-color--' + props.color : ''
			}`}>
		{props.children}
	</svg>;
})

export default BaseSVG;
