import { DataSubmission } from "psims/models/data-submission";
import useFocusedField, { UseFocusedField } from "psims/react/util/use-focused-field";
import useViewMode, { ViewMode } from "../../shared/use-view-mode";
import { CommentsFocusField, DeclarationFocusField } from "../../shared/types";
import useConfirm from "../../shared/use-confirm";
import useSubmit from "../../shared/use-submit";
import { StockholdingFocusField, OwnershipFocusField, StockOnBehalfFocusField, MsoFocusField, DeleteFocusField } from "../shared/types";
import useMsoRefData from "../shared/use-mso-ref-data";
import { UseMsoImporterAPI } from "./use-mso-importer-api";
import useMsoImporterControls from "./use-mso-importer-controls";
import useMsoImporterForm from "./use-mso-importer-form";
import useMsoImporterNavigation from "./use-mso-importer-navigation";
import useMsoImporterProgress from "./use-mso-importer-progress";
import useMsoImporterValidation from "./use-mso-importer-validation";
import useMsoImporterValidationAlerts from "./use-mso-importer-validation-alerts";
import useMsoImporterServiceMessages from "./use-mso-importer-service-messages";
import useClearData from "psims/react/pages/primary-pages/data-submissions/shared/use-clear-data";
import usePortalData from "../../shared/use-portal-data";

interface UseMsoImporterProps {
    apiCtrl: PopulatedProps<UseMsoImporterAPI, 'submission'>;
}

type FocusField = StockholdingFocusField | OwnershipFocusField | StockOnBehalfFocusField | MsoFocusField | CommentsFocusField | DeclarationFocusField | DeleteFocusField;

export type UseMsoImporterFocus = UseFocusedField<FocusField>;

function useMsoImporter({apiCtrl}: UseMsoImporterProps) {
    const {submission} = apiCtrl;

    const refData = useMsoRefData({
        dataSubmission: submission.dataSubmission,
    });

    const portalDataCtrl = usePortalData({submissionType: refData.submissionType});

    const focusFieldCtrl = useFocusedField<FocusField>();

    const deleteCtrl = useConfirm();

    const viewMode = useViewMode(submission);

    const progressCtrl = useMsoImporterProgress({apiCtrl, viewMode});

    const formCtrl = useMsoImporterForm({refData, submission});

    const validationCtrl = useMsoImporterValidation({focusFieldCtrl, formCtrl, progressCtrl, refData});

    const submitCtrl = useSubmit({
        apiCtrl,
        dataSubmission: submission.dataSubmission as DataSubmission<'MSO importer'>,
        focusFieldCtrl,
        hasExternalErrors: validationCtrl.notifications.length > 0,
    });
    
    const validationAlertsCtrl = useMsoImporterValidationAlerts({focusFieldCtrl, msoCtrl: formCtrl.msoCtrl, progressCtrl});

    const navCtrl = useMsoImporterNavigation({apiCtrl, formCtrl, msoCtrl: formCtrl.msoCtrl, progressCtrl, submitCtrl, validationCtrl, viewMode});

    const controlsCtrl = useMsoImporterControls({apiCtrl, navCtrl, progressCtrl, validationCtrl, viewMode});

    const serviceMessages = useMsoImporterServiceMessages({
        formCtrl,
        progressCtrl,
        submissionStatus: apiCtrl.loadStatus,
        submitCtrl,
        updateResponse: apiCtrl.updateResponse,
        serverError: apiCtrl.updateError
    });

    const clearDataCtrl = useClearData({
        onConfirm: () => {
            apiCtrl.clearAllData();
            submitCtrl.updateDeclaration(false);
        },
        submission: apiCtrl.submission?.dataSubmission,
    });

    const isDisabled = viewMode !== 'edit';
    
    return {
        apiCtrl,
        controlsCtrl,
        clearDataCtrl,
        deleteCtrl,
        focusFieldCtrl,
        formCtrl,
        isDisabled,
        navCtrl,
        portalDataCtrl,
        progressCtrl,
        refData,
        serviceMessages,
        submitCtrl,
        validationCtrl,
        validationAlertsCtrl,
        viewMode: viewMode as ViewMode
    };
}

export default useMsoImporter
