import React from 'react';
import { Route, Redirect, Switch } from 'react-router';

import Box from 'psims/react/components/box';
import Page from 'psims/react/blocks/page';
import Home from './home';
import UserManagement from './user-management';
import useQuery from 'psims/react/util/use-query';
import ReportingObligations from './reporting-obligations';
import TermsAndConditionsPage from './terms-and-conditions';
import { H2 } from 'psims/react/components/typography';
import MsoImporter from './data-submissions/mso/importer';
import MsoRefiner from './data-submissions/mso/refiner';
import CompanyOverview from './company-overview';
import ReportingHistory from './reporting-history';
import UncaughtError, { LoggerErrorWrapper } from 'psims/react/blocks/uncaught-error';
import MinimumStockObligationSettingsPage from './minimum-stock-obligation-settings';
import BIOPage from './data-submissions/bio';
import FLDPage from './data-submissions/fld';
import PLTPage from './data-submissions/plt';
import REFPage from './data-submissions/ref';
import STKPage from './data-submissions/stk';
import WHLPage from './data-submissions/whl';
import MsoAnnualActivityRefiner from './data-submissions/mso-annual-activity/refiner';
import MsoAnnualActivityImporter from './data-submissions/mso-annual-activity/importer';
import DocumentsPage from './documents';
import DEFPage from './data-submissions/def';
import RequestTemplatesPage from './request-templates';
import FSPPage from './data-submissions/fsp';
import FSSPCommitmentPage from './fssp-commitment';

interface PrimaryPagesProps {}

export const PrimaryPages = (props: PrimaryPagesProps) => {
	const query = useQuery();
	
	return (
		<Page>
			<UncaughtError Wrapper={LoggerErrorWrapper}>
				<Box flex='column' flexGrow={1} >
						<Switch>
							<Route path='/data-submissions/*'>
								<Switch>
									<Route path='/data-submissions/fuel-security-services-payment'>
										<FSPPage id={Number(query.id)} />
									</Route>

									<Route path='/data-submissions/plant-production'>
										<PLTPage id={Number(query.id)} />
									</Route>

									<Route path='/data-submissions/field-production'>
										<FLDPage id={Number(query.id)} />
									</Route>

									<Route path='/data-submissions/biofuel-production'>
										<BIOPage id={Number(query.id)} />
									</Route>

									<Route path='/data-submissions/refinery'>
										<REFPage id={Number(query.id)} />
									</Route>

									<Route path='/data-submissions/stockholding'>
										<STKPage id={Number(query.id)} />
									</Route>

									<Route path='/data-submissions/wholesaling'>
										<WHLPage id={Number(query.id)} />
									</Route>

									<Route path='/data-submissions/mso-importer'>
										<MsoImporter id={Number(query.id)} />
									</Route>

									<Route path='/data-submissions/mso-refiner'>
										<MsoRefiner id={Number(query.id)} />
									</Route>

									<Route path='/data-submissions/mso-annual-activity-importer'>
										<MsoAnnualActivityImporter id={Number(query.id)}/>
									</Route>

									<Route path='/data-submissions/mso-annual-activity-refiner'>
										<MsoAnnualActivityRefiner id={Number(query.id)}/>
									</Route>

									<Route path='/data-submissions/diesel-exhaust-fluid'>
										<DEFPage id={Number(query.id)}/>
									</Route>

								</Switch>
							</Route>

							<Route path='*'>
								<main>
									<Switch>
										<Route path='/home'>
											<Home />
										</Route>

										<Route path='/company-overview'>
											<CompanyOverview />
										</Route>

										<Route path='/minimum-stockholding-obligation-settings'>
											<MinimumStockObligationSettingsPage />
										</Route>

										<Route path='/reporting-history'>
											<ReportingHistory />
										</Route>

										<Route path='/user-management'>
											<UserManagement />
										</Route>

										<Route path='/reporting-obligations'>
											<ReportingObligations />
										</Route>

										<Route path='/documents'>
											<DocumentsPage />
										</Route>

										<Route path='/fssp-commitment'>
											<FSSPCommitmentPage />
										</Route>

										<Route path='/request-templates'>
											<RequestTemplatesPage />
										</Route>

										<Route path='/terms-and-conditions'>
											<TermsAndConditionsPage />
										</Route>

										<Route path='/'>
											<Redirect to='/home' />
										</Route>

										<Route path='*'>
											<NotFound />
										</Route>
									</Switch>
								</main>
							</Route>

							{/* <Route path='/modify-reporting-obligation'>
								<RequestToModify />
							</Route> */}

						</Switch>
				</Box>
			</UncaughtError>
		</Page>
	);
};

const NotFound = () => (
	<Box alignItems='center' flex='column'>
		<H2>Page not found</H2>
	</Box>
)
