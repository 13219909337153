/**
 * This controller encapsulates the standard controller for managing
 * the primary controls of all data submission types
 */
import { ButtonState } from "psims/react/components/button/button";
import { useMemo } from "react";
import { ViewMode } from "../shared/use-view-mode";

interface APIController {
  isBusy: boolean;
}

interface FormController {
  view: {
    Submit: {
      isValid: boolean;
    }
  }
}

interface ProgressController {
  currentStep: {
    index: number;
    kind: 'data' | 'submit';
  };
  goToStep: (index: number) => any;
}

interface SaveController {
  saveAndGoToPage: (nextStep: number) => any;
}

interface SubmitController {
  submit: () => any;
}

interface UseDataSubmissionControlsProps {
    apiCtrl: APIController;
    formCtrl: FormController;
    progressCtrl: ProgressController;
    saveCtrl: SaveController,
    submitCtrl: SubmitController;
    viewMode: ViewMode;
}

function useDataSubmissionControls({apiCtrl, formCtrl, progressCtrl, saveCtrl, submitCtrl, viewMode}: UseDataSubmissionControlsProps) {
    const primaryControl = useMemo(() => {
        const {currentStep: {index, kind}} = progressCtrl;
        const nextStep = index + 1;
        const label = viewMode === 'edit' ?
            (kind === 'data' ? 'Save and continue' : 'Submit') :
            'Continue';
        const action = viewMode === 'edit' ? (kind === 'data' ?
                    () => saveCtrl.saveAndGoToPage(nextStep) : 
                    submitCtrl.submit
                ) :
                () => progressCtrl.goToStep(nextStep);
        const state: ButtonState = apiCtrl.isBusy ? 'loading' : 'normal';

        const isDisabled = apiCtrl.isBusy || (
            progressCtrl.currentStep.kind === 'submit' &&
            !formCtrl.view.Submit.isValid
        );

        const isShown = viewMode === 'edit' ? true : (
            kind !== 'submit'
        )

        return {
            action,
            isDisabled,
            isShown,
            label,
            state,
        }
    }, [apiCtrl.isBusy, formCtrl.view, progressCtrl, saveCtrl, submitCtrl.submit, viewMode]);

    const secondaryControl = useMemo(() => {
        const {currentStep: {index}} = progressCtrl;
        const nextStep = index - 1;
        const action = viewMode === 'edit' ?
            () => saveCtrl.saveAndGoToPage(nextStep) :
            () => progressCtrl.goToStep(nextStep);

        const isDisabled = apiCtrl.isBusy;

        const isShown = index > 0;
        const label = 'Previous';
        const state: ButtonState = apiCtrl.isBusy ? 'loading' : 'normal';

        return {
            action,
            isDisabled,
            isShown,
            label,
            state,
        }
    }, [apiCtrl.isBusy, progressCtrl, saveCtrl, viewMode]);


    return useMemo(() => ({
        primaryControl,
        secondaryControl,
    }), [primaryControl, secondaryControl]);
}

export default useDataSubmissionControls
